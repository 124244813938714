import { useEffect } from 'react';
import { useState } from 'react';
import './styles.css';
import { GenButton, InputField } from '../../../../components';
import CustomCheckBox from '../../../../components/sharedComponents/CheckBox/CheckBox';
import OpsApiCalls from '../../redux/apiCalls';

export default function EditApplicationSchedulePopup(props) {
  const { data, setPopupStatus } = props;
  const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const [popupData, setpopupData] = useState({
    //state of element
    selectedWeekDays: 0,
    startTime: '',
    endTime: '',
  });
  const [submit, setSubmit] = useState(false);
  const getSelectedWeekdaysList = (value) => {
    //converts weekday integer to array based on day
    let selectedWeekdays = [];
    let idx = 6;
    while (idx) {
      if (!value) {
        selectedWeekdays.push(0);
      } else {
        selectedWeekdays.push(value & 1);
        value = value >> 1;
      }
      idx--;
    }
    selectedWeekdays.reverse();
    return selectedWeekdays;
  };

  const handleSelectedWeekdays = (e) => {
    //change status based on checkbox input
    let temp = popupData.selectedWeekDays;
    const idx = weekDays.indexOf(e.target.value);
    temp[idx] = !temp[idx];
    setpopupData({ ...popupData, selectedWeekDays: temp });
  };

  const handleSubmit = async () => {
    let apiData = { ...popupData };
    await OpsApiCalls.postApplicationPopupEditPopupSubmit(apiData);
    setPopupStatus(false);
  };

  useEffect(() => {
    let temp = data;
    temp.selectedWeekDays = getSelectedWeekdaysList(data.selectedWeekDays);
    temp.endTime = new Date(temp.endTime.slice(0, 19)).toLocaleTimeString();
    temp.startTime = new Date(temp.startTime.slice(0, 19)).toLocaleTimeString();
    setpopupData(temp);
  }, [data]);

  useEffect(() => {
    console.log(popupData);
  }, [popupData]);

  return (
    <div className="edit-app-popup-cont">
      <div className="edit-app-popup-title-cont">
        <span className="edit-app-popup-title">Edit Application Schedule</span>
      </div>
      <div className="edit-app-popup-middleArea">
        <div className="edit-app-popup-middleArea-s1">
          <div className="edit-app-popup-middleArea-title-cont">
            <span className="edit-app-popup-middleArea-title">
              Edit Weekdays
            </span>
          </div>
          <div className="edit-app-popup-checkbox-cont">
            {weekDays.map((weekDay, idx) => (
              <CustomCheckBox
                key={`${idx} add`}
                label={weekDay}
                name={weekDay}
                value={weekDay}
                handlerfunction={(e) => handleSelectedWeekdays(e)}
                checked={popupData.selectedWeekDays[idx]}
              />
            ))}
          </div>
          <div className="edit-app-popup-middleArea-title-cont">
            <span className="edit-app-popup-middleArea-title">
              Market Hours
            </span>
          </div>
          <div className="edit-app-popup-hours-container">
            <div className="edit-app-popup-hour">
              <InputField
                label="Start Time"
                type="time"
                value={popupData.startTime}
                onValueChange={(val) =>
                  setpopupData({ ...popupData, startTime: val })
                }
              />
              <InputField
                extStyles={{ margin: '0 0 0 1vw' }}
                label="End Time"
                type="time"
                value={popupData.endTime}
                onValueChange={(val) =>
                  setpopupData({ ...popupData, endTime: val })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="manage-conf-popup-buttonarea">
        <GenButton
          fill={true}
          title="SUBMIT"
          extStyle={{ margin: '0 1vw 0 auto', width: '10vw' }}
          clickAction={handleSubmit}
        />
        <GenButton
          fill={false}
          title="CLOSE"
          extStyle={{ margin: '0 1vw 0 1vw', width: '10vw' }}
          clickAction={() => {
            setPopupStatus(false);
          }}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import MakeRequest from '../../../utils/apiRequest';
import { TableSettings } from '../../../components/sharedComponents/customComponent';
import { FeedbackPopup } from '../../../components';
import './RiskMonitoringPreTradeHFT.css';
import { preTradeHFTLimits } from '../../../utils/constant';
import liveIcon from '../../../assets/img/GreenDot.svg';
import searchIcon from '../../../assets/img/search.svg';
import cancelIcon from '../../../assets/img/RedDot.svg';
import { ColumnArrangeIcon } from '../../../assets';
import UnitWindow from './unitWindow/unitWindow';
import { webSocketUrl } from '../../../config/apiConfig';
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { TableOrderChangeActions } from '../../../reducers/table_order_reducer';
import { useDispatch, useSelector } from 'react-redux';

var W3CWebSocket = require('websocket').w3cwebsocket;

const tableFixedColumnWidth = '12%';
let initAllTableGridColumnsConfig = ['14%'];
let hardcodeColumns = [
  { columnName: 'LastUpdateTime', variableName: 'timestamp', columnSeq: 1 }
]; // Sequence in this array should be in descending order of columnSeq

// let unitsList = ['Ones', 'Thousands', 'Lakhs', 'Crores'];
let unitColumnExcludeList = ['Entity', 'LastUpdateTime', 'Kill Switch Access', 'Span Id'];

const THEME = {
  Table: null,
  HeaderRow: `
        .th {
          border: 1px solid #a0a8ae;
          background-color: #F4F4F4;
          font-weight: 500;
          text-align: left;
        }
      `,
  BaseCell: `
        // &:not(:last-of-type) {
        //   border-right: 1px solid #a0a8ae;
        // }

        // text-align: left;
        font: normal normal normal 11px/14px Roboto;
        font-weight: regular;
        height: fit-content;
      `,
  Row: `
      cursor: pointer;

      text-align: right;

      .td:nth-of-type(1) {      // checkbox
        text-align: center;
      }

      &:nth-of-type(even) {
        background-color: #E4EBF2;
      }

      // &:nth-of-type(4) {
      //   .td:nth-of-type(6) {
      //     background-color: #dddddd;
      //   }
      // }

      .td {
        border-top: 1px solid #ffffff;    // #a0a8ae
        border-bottom: 1px solid #ffffff;   // #a0a8ae
        padding: 0rem 0rem;
      }

      // &:hover .td {
      //   border-top: 1px solid orange;
      //   border-bottom: 1px solid orange;
      // }
    `,
};

const RiskMonitoringPreTradeHFT = ({ tabSelect, client, setClient }) => {
  const [columns, setColumns] = useState([]);
  const [apiCallColumnForWB, setApiCallColumnForWB] = useState([]);
  const [liveWSData, setLiveWSData] = useState([]);
  const [tempTableTheme, setTempTableTheme] = useState(THEME);
  const [theme, setTheme] = useState({});
  const [allTableGridColumnsConfig, setAllTableGridColumnsConfig] = useState();
  const [lastUpdateTime, setLastUpdateTime] = useState('-');
  const [riskUnits, setRiskUnits] = useState({});
  const [searchString, setSearchString] = useState(null);
  const [unitsList, setUnitsList] = useState({});
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [riskConfig, setRiskConfig] = useState({});

  const [table_settings, set_table_settings] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [{ label: 'Reorder / Hide & Unhide', id: 20 }],
        },
      ],
    },
  ]);

  const dispatch = useDispatch();

  const tableColSequence = useSelector(
    (state) => state.TableOrderReducer.win_risk_monitoring_pre_trade
  );

  useEffect(() => {
    handleSequenceChange(
      tableColSequence.viewData,
      tableColSequence.staticData
    );
  }, [tableColSequence]);

  useEffect(() => {
    try {
      initialHeaderApiDataFetch();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    console.log(searchString);
    if (!searchString) {
    } else {
    }
  }, [searchString]);

  useEffect(() => {
    try {
      initialHeaderApiDataFetch();

      TableOrderChangeActions.set_window_OT_SA_data(
        dispatch,
        'risk_monitoring_pre_trade'
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      console.log('Connection made...');
      if (apiCallColumnForWB.length > 0) {
        if (!client || (client && !client.OPEN))
          setClient(new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol'));
        // wsFunctionInit(client);
      }
    } catch (error) {
      console.log(error);
    }
  }, [apiCallColumnForWB]);

  // Init client if client is not null
  useEffect(() => {
    if (client) {
      wsFunctionInit(client);
    }
  }, [client]);

  useEffect(() => {
    X();
  }, [tempTableTheme]);

  const X = () => {
    const newTheme = useTheme(tempTableTheme);
    setTheme(newTheme);
  };

  const initialHeaderApiDataFetch = async () => {
    const response2 = await MakeRequest(
      '/user/currency/getCurrencyUnits'
    ).get();
    if (response2.event !== undefined && !response2.event) {
      return;
    }
    const tempData2 = response2.data.data.data;

    let units = [];
    if (tempData2) {
      units = MakeUnits(tempData2);
    }

    const response3 = await MakeRequest(
      '/user/currency/getCurrencyConversionStruct?tableName=HFT_RISK_MONITORING_PRE_TRADE'
    ).get();

    if (response3.event !== undefined && !response3.event) {
      return;
    }
    const tempData3 = response3.data.data;

    let curSetUnits = [];
    if (tempData3) {
      curSetUnits = CurrentSetUnits(tempData3);
    }

    const response = await MakeRequest(
      '/user/dynamicTable/getTableStruct?tableName=HFT_RISK_MONITORING_PRE_TRADE'
    ).get();
    if (response.event !== undefined && !response.event) {
      return;
    }
    const tempData = response.data.data.viewData;

    if (tempData) {
      MakeColomns(tempData, units, curSetUnits);
    }

    const response1 = await MakeRequest('/admin/risk/configurationV2').get();
    if (response1.event !== undefined && !response1.event) {
      return;
    }
    const tempData1 = response1.data.data;

    if (tempData1) {
      MakeMapOfRiskConfig(tempData1);
    }
  };

  const CurrentSetUnits = (data) => {
    return data;
  };

  const MakeUnits = (data) => {
    let units = [];
    let factors = [];
    for (let i = 0; i < data.length; i++) {
      units.push(data[i].name);
      factors.push(data[i].factor);
    }
    let unitsData = {
      units: units,
      factors: factors,
    };
    setUnitsList(unitsData);
    return units;
  };

  function wsFunctionInit(client) {
    client.onerror = function () {};
    client.onopen = function () {
      let customerId = localStorage.getItem('customerId');
      const data = {
        eventType: 'RISK_SUBSCRIBE',
        payload: {
          userId: customerId,
          userType: 'ADMIN',
          isHFT: 1,
          preOrPostTrade: 1,
        },
      };

      client.send(JSON.stringify(data));
    };

    client.onclose = function () {
      console.log('WS connection was closed...');
    };

    client.onmessage = function (data) {
      let dataStr = JSON.parse(data.data);
      dataStr = dataStr.payload;
      if (dataStr.lastUpdateTime) {
        setLastUpdateTime(dataStr.lastUpdateTime);
      }
      dataStr = wsDataProcess(dataStr);
      let finalData = [...dataStr];
      setLiveWSData([...finalData]);
    };
  }

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    switch (temp_index[0]) {
      case 'P':
        // * set parent data
        break;
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          let tempColumns = [...table_settings];
          tempColumns.push({ type: 'COL_RE' });
          set_table_settings(tempColumns);
        }
        break;
      case 'B':
        const tempColumn = [...table_settings];
        tempColumn.pop();
        set_table_settings(tempColumn);
        break;
    }
  }

  const FilterData = (item) => {
    // console.log(searchString);

    if (searchString) {
      if (item.accountId.toLowerCase().includes(searchString.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const wsDataProcess = (data) => {
    // data = creatDummyData(10000000000, 1);

    let formattedData = [];
    for (let x = 0; x < data.strategyAccount.length; x++) {
      let strategyAcc = data.strategyAccount[x];

      let idOfSA = `sa-${strategyAcc.id}_${strategyAcc.riskNumbers.ssid}`;

      let parent = {
        id: idOfSA,
        name: strategyAcc.riskNumbers.accountId,
        percentageColumns: strategyAcc.riskNumbers.percUtilRequiredFields,
        mode: strategyAcc?.riskNumbers?.mode,
      };
      for (let i = 0; i < columns.length; i++) {
        if (
          columns[i].variableName === 'id' ||
          columns[i].variableName === 'name'
        ) {
          continue;
        }
        parent[columns[i].variableName] = 0;
        if (strategyAcc.riskNumbers[columns[i].variableName]) {
          parent[columns[i].variableName] =
            strategyAcc.riskNumbers[columns[i].variableName];
        }
        // for (let j = 0; j < strategyAcc.riskNumbers.length; j++) {
        //   if (clearingAcc.riskNumbers[j].name === columns[i].variableName) {
        //     parent[columns[i].variableName] = clearingAcc.riskNumbers[j].value;
        //     break;
        //   }
        // }
      }
      formattedData.push(parent);
    }
    // console.log(formattedData);
    return formattedData;
  };

  const ApplyUnits = async (unitObject) => {
    let tempRiskUnitObj = { ...riskUnits };

    let postArr = [];

    for (let i = 0; i < columns.length; i++) {
      if (unitObject[columns[i].columnName]) {
        tempRiskUnitObj[columns[i].columnName] =
          unitObject[columns[i].columnName];

        let temp = {
          columnName: columns[i].columnName,
          value: unitObject[columns[i].columnName],
          variableName: 'INR',
        };
        postArr.push(temp);
      }
    }
    setRiskUnits(tempRiskUnitObj);

    let postData = {
      tableName: 'HFT_RISK_MONITORING_PRE_TRADE',
      currencyConversionTableSeq: postArr,
    };

    try {
      let response = await MakeRequest(
        '/user/currency/setCurrencyConversionStruct'
      ).post(postData);

      if (response.status === 200) {
        setFeedbackMsg(
          <span style={{ color: 'green' }}>{'Units set successful !'}</span>
        );
      } else {
        setFeedbackMsg(
          <p>
            <span style={{ color: 'red' }}>{'Error : '}</span>
            {'Error in unit setting API'}
          </p>
        );
      }
    } catch (err) {
      setFeedbackMsg(
        <p>
          <span style={{ color: 'red' }}>{'Error : '}</span>
          {'Error while setting units'}
        </p>
      );
    }
  };

  const NumberFormatter = (riskMeasure, value) => {
    let unit = riskUnits[riskMeasure.columnName];
    let finalValue = value;
    if (typeof value === 'number') {
      if (unit) {
        let factor = 1;
        for (let i = 0; i < unitsList.units.length; i++) {
          if (unit === unitsList.units[i]) {
            factor = unitsList.factors[i];
            break;
          }
        }

        finalValue = value * factor;

        factor == 1
          ? (finalValue = finalValue.toFixed(0))
          : (finalValue = finalValue.toFixed(2));
      }
    }
    return finalValue;
  };

  const MakeMapOfRiskConfig = (data) => {
    let riskConfigTemp = {};
    let mapOfRiskIdPercentageUtil = {};

    let caData = data[1].clearingAccountRiskLimits;
    let saData = data[1].strategyAccountRiskLimits;

    let caParameters = data[1].clearingAccountRiskParameters;
    let saParameters = data[1].strategyAccountRiskParameters;

    for (let i = 0; i < caData.length; i++) {
      let str = `ca-${caData[i].dimensionValue}-${caData[i].riskParamId}`;
      riskConfigTemp[str] = Number(caData[i].riskParamValue);
    }

    for (let i = 0; i < caParameters.length; i++) {
      let str = `ca-${caParameters[i].riskParamId}`;
      mapOfRiskIdPercentageUtil[str] = Number(caParameters[i].validationValue);
    }

    for (let i = 0; i < saData.length; i++) {
      let str = `sa-${saData[i].dimensionValue}-${saData[i].riskParamId}`;
      riskConfigTemp[str] = Number(saData[i].riskParamValue);
    }

    for (let i = 0; i < saParameters.length; i++) {
      let str = `sa-${saParameters[i].riskParamId}`;
      mapOfRiskIdPercentageUtil[str] = Number(saParameters[i].validationValue);
    }

    let finalObj = {
      riskLimits: riskConfigTemp,
      mapOfRiskIdPercentageUtil: mapOfRiskIdPercentageUtil,
      saLimits: data[1].strategyAccountRiskLimits,
      limitColorCode: data.riskLevelInfo.colorCode,
    };

    // console.log(finalObj);

    setRiskConfig(finalObj);
  };

  //* L1-L6 risk config limits are taken for the margin Mandate and colour is shown according to
  //* that SA limits for all the risk params in pre trade
  const RangeColour = (riskMeasure, value, casa, accID, percentage) => {
    if (!percentage) {
      return '#ffffff';
    }

    if (riskConfig && riskConfig.saLimits && riskConfig.saLimits.length > 0) {
      let saLimits = riskConfig.saLimits;
      let limit = null;
      for (let i = 0; i < saLimits.length; i++) {
        if (saLimits[i].dimensionValue == accID) {
          limit = saLimits[i];
          break;
        }
      }

      percentage = Number(percentage.replace('%', ''));
      
      if (!limit) {
        return '#ffffff';
      }

      if(percentage<preTradeHFTLimits[0]){
        return "#00800080"
      }
      else if(percentage<preTradeHFTLimits[1]){
        return "#fffa66"
      }
      else if(percentage<preTradeHFTLimits[2]){
        return "#ffa500"
      }
      else if(percentage<preTradeHFTLimits[3]){
        return "#ff6666"
      }
      else{
        return "#ff0000"
      }

      // if (percentage < limit.L1) {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L0
      //   ) {
      //     return riskConfig.limitColorCode.L0;
      //   } else {
      //     return '#ffffff';
      //   }
      // } else if (percentage > limit.L1 && percentage <= limit.L2) {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L1
      //   ) {
      //     return riskConfig.limitColorCode.L1;
      //   } else {
      //     return '#ffffff';
      //   }
      // } else if (percentage > limit.L2 && percentage <= limit.L3) {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L2
      //   ) {
      //     return riskConfig.limitColorCode.L2;
      //   } else {
      //     return '#ffffff';
      //   }
      // } else if (percentage > limit.L3 && percentage <= limit.L4) {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L3
      //   ) {
      //     return riskConfig.limitColorCode.L3;
      //   } else {
      //     return '#ffffff';
      //   }
      // } else if (percentage > limit.L4 && percentage <= limit.L5) {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L4
      //   ) {
      //     return riskConfig.limitColorCode.L4;
      //   } else {
      //     return '#ffffff';
      //   }
      // } else if (percentage > limit.L5 && percentage <= limit.L6) {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L5
      //   ) {
      //     return riskConfig.limitColorCode.L5;
      //   } else {
      //     return '#ffffff';
      //   }
      // } else {
      //   if (
      //     riskConfig &&
      //     riskConfig.limitColorCode &&
      //     riskConfig.limitColorCode.L6
      //   ) {
      //     return riskConfig.limitColorCode.L6;
      //   } else {
      //     return '#ffffff';
      //   }
      // }
    } else {
      return '#ffffff';
    }
  };

  const MakeColomns = (colomnData, units, curSetUnits) => {
    // ? Formatting and setting column and filter objects
    let columnsArr = [];
    let filterArr = [];
    let tempRiskUnitsObj = {};

    for (let i = 0; i < hardcodeColumns.length; i++) {
      colomnData.splice(0, 0, hardcodeColumns[i]);
    }

    for (let i = 0; i < colomnData.length; i++) {
      let obj = {
        columnName: colomnData[i].columnName,
        variableName: colomnData[i].variableName,
      };
      columnsArr.push(obj);

      obj = {
        id: i,
        state: true,
        title: colomnData[i].columnName,
        columnName: colomnData[i].columnName,
        variableName: colomnData[i].variableName,
      };
      filterArr.push(obj);

      for (let j = 0; j < curSetUnits.length; j++) {
        if (curSetUnits[j].columnName === colomnData[i].columnName) {
          tempRiskUnitsObj[colomnData[i].columnName] = curSetUnits[j].value;
          break;
        }
      }
    }

    TableInitColumnDisplaySetter(columnsArr);

    setColumns(columnsArr);

    setApiCallColumnForWB(columnsArr);

    setRiskUnits(tempRiskUnitsObj);
  };

  const TableInitColumnDisplaySetter = (columns) => {
    let temp = [];

    for (let i = 0; i < initAllTableGridColumnsConfig.length; i++) {
      temp.push(initAllTableGridColumnsConfig[i]);
    }

    for (
      let i = 0;
      i < columns.length - initAllTableGridColumnsConfig.length + 0;
      i++
    ) {
      temp.push(tableFixedColumnWidth);
    }

    setAllTableGridColumnsConfig(temp);
    let tempTheme = { ...tempTableTheme };
    tempTheme.Table = TableColumnConfig(temp);
    setTempTableTheme(tempTheme);
  };

  const TableColumnConfig = (arr = allTableGridColumnsConfig) => {
    let startingPart = '--data-table-library_grid-template-columns: ';
    let endingPart =
      'minmax(20px, 1fr); scrollbar-width: thin; scrollbar-color: #D3D3D3 white;';
    let middlePart = '';
    for (let i = 0; i < arr.length; i++) {
      middlePart += arr[i];
      middlePart += ' ';
    }

    let finalStr = '';
    finalStr += startingPart + middlePart + endingPart;

    return finalStr;
  };

  const handleSequenceChange = (seq, orignalSeq) => {
    console.log(seq, orignalSeq);
    console.log(tempTableTheme.Table);
    let sequence = [...seq];
    let orignalSequence = [...orignalSeq];

    if (sequence.length > 0 && orignalSequence.length > 0) {
      for (let i = 0; i < sequence.length; i++) {
        sequence[i].columnSeq = sequence[i].columnSeq + hardcodeColumns.length;
        orignalSequence[i].columnSeq =
          orignalSequence[i].columnSeq + hardcodeColumns.length;
      }

      for (let i = 0; i < hardcodeColumns.length; i++) {
        sequence.splice(0, 0, hardcodeColumns[i]);
        orignalSequence.splice(0, 0, hardcodeColumns[i]);
      }

      // Set width of columns
      if (allTableGridColumnsConfig && allTableGridColumnsConfig.length > 0) {
        let tempTheme = { ...tempTableTheme };
        let tempConfigArr = [];
        tempConfigArr.push(allTableGridColumnsConfig[0]);

        for (let i = 0; i < sequence.length - 1; i++) {
          let index = -1;
          for (let j = 0; j < orignalSequence.length; j++) {
            if (orignalSequence[j].columnName === sequence[i].columnName) {
              index = j;
              break;
            }
          }
          tempConfigArr.push(allTableGridColumnsConfig[index + 1]);
        }
        console.log(tempConfigArr);

        let tableStr = TableColumnConfig(tempConfigArr);
        tempTheme.Table = tableStr;
        console.log(tempTheme.Table);
        setTempTableTheme(tempTheme);
      }

      // Set column sequence
      setColumns(sequence);
    }
  };

  function subsetColumnArrayForUnits(columns, unitColumnExcludeList) {
    let tempArr = [];
    for (let i = 0; i < columns.length; i++) {
      let found = false;
      for (let j = 0; j < unitColumnExcludeList.length; j++) {
        if (columns[i].columnName === unitColumnExcludeList[j]) {
          found = true;
          break;
        }
      }
      if (!found) {
        tempArr.push(columns[i]);
      }
    }
    return tempArr;
  }

  function percentageCalculator(attribute, includeArray, marginMandate, value) {
    // console.log(attribute, includeArray, marginMandate, value);
    if (
      includeArray &&
      includeArray.length > 0 &&
      attribute &&
      marginMandate !== 0
    ) {
      let curAttribute = attribute.variableName;
      let found = false;
      for (let i = 0; i < includeArray.length; i++) {
        if (includeArray[i] === curAttribute) {
          found = true;
          break;
        }
      }

      if (found) {
        if (marginMandate !== 0) {
          let percentage = ((value / marginMandate) * 100).toFixed(2);
          return percentage;
        }
      }
    }
    return null;
  }
  const data = { nodes: liveWSData };

  return (
    <div className="page-div">
      <div className="header-container-x">
        <div className="search">
          <div className="search-container-c">
            <img
              style={{
                height: '15px',
                width: '15px',
                marginRight: '0.5vw',
                marginLeft: '0.25vw',
              }}
              src={searchIcon}
              alt="searchIcon"
            />
            <input
              style={{ border: 'none' }}
              placeholder="Search (Name)"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="ldu-x">
          Last Updated Time : <b className="bTag-x">{lastUpdateTime}</b>
        </div>
        {unitsList.units &&
          unitsList.factors &&
          unitsList.units.length > 0 &&
          unitsList.factors.length > 0 &&
          riskUnits && (
            <>
              <UnitWindow
                riskMeasuresList={subsetColumnArrayForUnits(
                  columns,
                  unitColumnExcludeList
                )}
                unitsList={unitsList.units}
                factors={unitsList.factors}
                applyAction={ApplyUnits}
                existingRiskUnits={riskUnits}
              />
            </>
          )}
        <TableSettings
          menuOptions={table_settings}
          clickAction={colModifier}
          updateColCallBack={() => {}}
          extStyles={{ marginLeft: '1vw' }}
          screenName="risk_monitoring_pre_trade"
        />
      </div>
      <Table
        data={data}
        theme={theme}
        layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}
      >
        {(tableList) => (
          <>
            <Header>
              {columns.length > 0 && (
                <HeaderRow>
                  {columns.map((item) => (
                    <HeaderCell>
                      <div className="header-text_x">
                        {item.columnName}
                        {unitColumnExcludeList.includes(item.columnName)
                          ? ''
                          : riskUnits[item.columnName]
                          ? ` (${riskUnits[item.columnName]})`
                          : ' (Ones)'}
                      </div>
                    </HeaderCell>
                  ))}
                </HeaderRow>
              )}
            </Header>

            <Body>
              {tableList.map((item) => (
                <>
                  {FilterData(item) && (
                    <Row key={item.id} item={item}>
                      <Cell></Cell>
                      {columns.map((attribute) => (
                        <>
                          {attribute.variableName === 'killSwitchAccess' ? (
                            <div className="cell-tree-pre">
                              <Cell item={item}>
                                <div className="nameIconCell">
                                  <img
                                    src={
                                      item['killSwitchAccess'] != 0
                                        ? liveIcon
                                        : cancelIcon
                                    }
                                    className="modeIcon"
                                  />
                                </div>
                              </Cell>
                            </div>
                          ) : (
                            <>
                              {attribute.variableName === 'accountId' ? (
                                <div className="cell-tree-pre">
                                  <Cell item={item}>
                                    {' '}
                                    <div className="nameIconCellLeftAlign">
                                      <img
                                        src={
                                          item['mode'] == true
                                            ? liveIcon
                                            : cancelIcon
                                        }
                                        className="modeIcon"
                                      />
                                      {item[attribute.variableName]}
                                    </div>
                                  </Cell>
                                </div>
                              ) : (
                                <Cell>
                                  <div
                                    style={{
                                      backgroundColor: `${RangeColour(
                                        attribute,
                                        item[attribute.variableName],
                                        item['id'].substring(0, 2),
                                        item['id'].substring(3),
                                        percentageCalculator(
                                          attribute,
                                          item.percentageColumns,
                                          item['mandate'],
                                          item[attribute.variableName]
                                        )
                                      )}`,
                                    }}
                                    className="cell-x"
                                  >
                                    {!percentageCalculator(
                                      attribute,
                                      item.percentageColumns,
                                      item['mandate'],
                                      item[attribute.variableName]
                                    ) ? (
                                      <div>
                                        {NumberFormatter(
                                          attribute,
                                          item[attribute.variableName]
                                        )}
                                      </div>
                                    ) : (
                                      <>
                                        <div>
                                          {NumberFormatter(
                                            attribute,
                                            item[attribute.variableName]
                                          )}
                                        </div>
                                        <div className="percentage-text-x">
                                          (
                                          {percentageCalculator(
                                            attribute,
                                            item.percentageColumns,
                                            item['mandate'],
                                            item[attribute.variableName]
                                          )}
                                          %)
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </Cell>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </Row>
                  )}
                </>
              ))}
            </Body>
          </>
        )}
      </Table>
      <FeedbackPopup
        message={feedbackMsg}
        type="f"
        acceptAction={() => {
          setFeedbackMsg('');
        }}
      />
    </div>
  );
};

export default RiskMonitoringPreTradeHFT;

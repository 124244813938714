import { useEffect, useState } from 'react';
import Login from '../../components/loginScreen/Login';
import ForgotPassword from '../../components/loginScreen/ForgotPassword';
import PasswordReset from '../../components/loginScreen/PasswordReset';
import EsteeLogo from '../../assets/img/Strade_LOGO.svg';
import EnterOtp from '../../components/loginScreen/EnterOtp';
import { useLocation } from 'react-router-dom';
import './loginScreens.scss';
import FirstLoginChangePassword from '../../components/loginScreen/FirstLoginChangePassword';
import Popup from '../../components/sharedComponents/Popup/Popup';
import {
  DEALER_NEED_PASSWORD_CHANGE_HEADER,
  DEALER_NEED_PASSWORD_CHANGE_MESSAGE,
  PASSWORD_CHANGE_CONFIRM,
  PASSWORD_CHANGE_CONFIRM_HEADER,
  PASSWORD_CHANGE_ERR_HEADER,
  PASSWORD_EXPIRY_HEADER,
} from '../../utils/constant';
import LicenseKey from './LicenseKey';

const LoginWrapper = () => {
  const location = useLocation();
  const [section, setSection] = useState('login');
  const [tokenStr, setTokenStr] = useState('');
  const [otp, setOtp] = useState('');
  const [userId, setUserId] = useState(-1);
  const [oldPassword, setOldPassword] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    header: '',
    buttons: '',
    state: false,
  });

  useEffect(() => {
    switch (popup.type) {
      case 'changePassword':
        setPopup({
          ...popup,
          message: PASSWORD_CHANGE_CONFIRM,
          header: PASSWORD_CHANGE_CONFIRM_HEADER,
          buttons: 'singleButton',
        });
        break;
      case 'changePasswordError':
        setPopup({
          ...popup,
          header: PASSWORD_CHANGE_ERR_HEADER,
          buttons: 'singleButton',
        });
        break;
      case 'changePasswordPasswordExpiry':
        setPopup({
          ...popup,
          message: PASSWORD_EXPIRY_HEADER,
          header: PASSWORD_EXPIRY_HEADER,
          buttons: 'singleButton',
        });
        break;
      case 'changePasswordDealerFirstTimeLogin':
        setPopup({
          ...popup,
          message: DEALER_NEED_PASSWORD_CHANGE_MESSAGE,
          header: DEALER_NEED_PASSWORD_CHANGE_HEADER,
          buttons: 'singleButton',
        });
        break;
      case 'changePasswordDealerNeedPasswordChange':
        setPopup({
          ...popup,
          message: DEALER_NEED_PASSWORD_CHANGE_MESSAGE,
          header: DEALER_NEED_PASSWORD_CHANGE_HEADER,
          buttons: 'singleButton',
        });
        break;
      default:
        break;
    }
  }, [popup.type]);

  // Everytime on password reset (whether error or success), user will be redirected to login page
  let passwordChangeLoginRedirect = () => {
    window.location.reload(false);
  };

  const renderSection = () => {
    switch (section) {
      case 'login':
        return (
          <Login
            setSection={setSection}
            setTokenStr={setTokenStr}
            setOldPassword={setOldPassword}
            setUserId={setUserId}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      case '/forgotPassword':
        return <ForgotPassword setSection={setSection} />;
      case 'enterOtp':
        return <EnterOtp setSection={setSection} setOtp={setOtp} />;
      case 'resetPassword':
        return (
          <PasswordReset
            setSection={setSection}
            otp={otp}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      case 'changePassword':
        return (
          <FirstLoginChangePassword
            setSection={setSection}
            tokenStr={tokenStr}
            oldPassword={oldPassword}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      default:
        return (
          <Login
            setSection={setSection}
            setTokenStr={setTokenStr}
            setOldPassword={setOldPassword}
            setUserId={setUserId}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
    }
  };

  const actionOnpopup = () => {
    switch (popup.type) {
      case 'changePassword':
        passwordChangeLoginRedirect();
        break;
      case 'changePasswordError':
        setShowPopUp(false);
        setPopup({
          type: '',
          message: '',
          header: '',
          buttons: '',
          state: false,
        });
        break;
      case 'changePasswordPasswordExpiry':
      case 'changePasswordDealerFirstTimeLogin':
      case 'changePasswordDealerNeedPasswordChange':
        setSection('changePassword');
        setShowPopUp(false);
        setPopup({
          type: '',
          message: '',
          header: '',
          buttons: '',
          state: false,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSection(location.pathname);
  }, [location.pathname]);

  return (
    <div className="login-new" style={{ padding: '2rem 3rem' }}>
      <section className="login-main-wrapper">
        <div className="form-wraper" style={{ width: '100%' }}>
          <div className="center-contanier">
            <div className="text-center">
              <img src={EsteeLogo} alt="img" />
            </div>
            <div>{renderSection()}</div>
          </div>
        </div>

        {showPopUp ? (
          <Popup
            popClass="passwordchangepopup"
            actionButton={actionOnpopup}
            popupDetails={popup}
            header={popup && popup.header ? popup.header : ''}
          />
        ) : null}
      </section>
      <LicenseKey />
    </div>
  );
};

export default LoginWrapper;

import React, { useState, useEffect } from 'react';
import './styles.css';
import {
  DropDown,
  GenButton,
  InputField,
  RadioGroup,
  SearchMultiSelect,
  ShowMultiSelect,
  ToggleTab,
  TableGenerator,
} from '../../../../components';
import { EditSolid } from '../../../../assets';
import BanApiCalls from './api';
import DataModificationFunctions from './dataMorphing';
import {
  BAN_CREATE_SUCCESS,
  BAN_MODIFY_SUCCESS,
  UNBAN_CREATE_SUCCESS,
  UNBAN_MODIFY_SUCCESS,
} from '../../../../utils/constant';

// * type : 0 - feedback, 1 - create form, 2 - View Form, 3 - Edit form, 4 - Remarks
export default function BanSecPopup(props) {
  const {
    type,
    data,
    edit_ban,
    set_popup,
    setPopup,
    setShowPopUp,
    edit = false,
  } = props;

  function loadComp() {
    switch (type) {
      case 0:
        return <FeedBackComp data={data} />;
      case 1:
        return (
          <CreateBanUnBan
            set_popup={set_popup}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      case 2:
        return (
          <View
            data={data}
            edit_ban={edit_ban}
            set_popup={set_popup}
            editAllowed={edit}
          />
        );
      case 3:
        return (
          <CreateBanUnBan
            data={data}
            set_popup={set_popup}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      case 4:
        return <Remark data={data} />;
      default:
        return null;
    }
  }
  return loadComp();
}

// * show response of success or failure with feedback
function FeedBackComp(props) {
  const { data } = props;
  return (
    <div className="ban-sec-popup-cont feedback-response-text">
      {data.message}
    </div>
  );
}

// * create Ban or Un-ban
function CreateBanUnBan(props) {
  const { set_popup, data = {}, setPopup, setShowPopUp } = props;
  const [ban_data, set_ban_data] = useState({
    ban_or_unban: 'Ban',
    block_info: '',
    ban_type: { BUY: true },
    exchange: '',
    instrument: '',
    symbols: '',
    all_symbols: false,
    all_expiry: false,
    all_instrument: false,
    expiry_dates: '',
    ban_level: { Family: true },
    family_types: {},
    select_fam: { Custom: true },
    families: {},
    dealers: {},
    unban_families: [],
    remark: '',
    unban: { families: {}, dealers: {} },
  });
  const [options_data, set_options_data] = useState({
    ban_type: ['BUY', 'SELL', 'BOTH'],
    exchange: [],
    instruments: [],
    ban_level: ['Family', 'Dealer', 'Entity'],
    family_type: [],
    families: [],
    dealers: [],
    select_family: ['All', 'Custom'],
    banned_families: [],
    unban: { families: [], dealers: [] },
  });

  const [symbol_options, set_symbol_options] = useState([]);
  const [expiry_options, set_expiry_options] = useState([]);
  const [api_data, set_api_data] = useState({});
  const [error, set_error] = useState('');
  const [edit_setup_status, set_edit_setup_status] = useState(false);

  // * Entity related extensions
  const [entOptions, setEntOptions] = useState([]);
  const [slecEnts, setSelectEnts] = useState({});

  useEffect(() => {
    if (api_data && api_data.allEntities && api_data.allEntities.length > 0) {
      setEntOptions(api_data.allEntities);
    }
  }, [api_data]);

  useEffect(() => {
    const setup_initial_data = async () => {
      const res = await BanApiCalls.create_ban_data();
      set_api_data(res);
    };
    setup_initial_data();
  }, []);

  const initial_data_setup_for_edit = async () => {
    const view_data = await BanApiCalls.get_view_data(data.id);

    if (view_data.banUnbanReqType && view_data.banUnbanReqType === 'ENTITY') {
      let tempSelect = {};
      for (let item of view_data.familyDealer) {
        tempSelect[`${item.id}-${item.name}`] = true;
      }
      setSelectEnts(tempSelect);
    }

    const new_ban_data = {
      ...ban_data,
      ban_or_unban: view_data.banOrUnban ? 'Ban' : 'Unban',
      block_info: view_data.info,
      ban_type: { [view_data.banType]: true },
      exchange: Object.keys(api_data.exchanges || {}).filter(
        (item) => api_data.exchanges[item] === view_data.exchange
      )[0],
      instrument: view_data.allInstrument ? '' : view_data?.instrument,
      symbols: view_data.allSymbol ? '' : view_data?.symbol,
      ban_level: {
        [view_data.isFamily
          ? 'Family'
          : view_data.banUnbanReqType === 'ENTITY'
          ? 'Entity'
          : 'Dealer']: true,
      },
      expiry_dates: view_data.allExpiry ? '' : view_data?.expiry,
      families: {},
      dealers: {},
      entity: {},
      family_types: {},
      all_expiry: view_data.allExpiry,
      all_symbols: view_data.allSymbol,
      all_instrument: view_data.allInstrument,
    };

    if (view_data.isFamily) {
      for (let fam of view_data.familyDealer) {
        new_ban_data.families[fam.name] = true;
      }
    } else {
      if (view_data.banUnbanReqType === 'ENTITY') {
        for (let fam of view_data.familyDealer) {
          new_ban_data.entity[`${fam.id}+${fam.name}`] = true;
        }
      } else {
        for (let fam of view_data.familyDealer) {
          new_ban_data.dealers[fam.name] = true;
        }
      }
    }
    if (view_data.familyType) {
      for (let type of view_data.familyType) {
        new_ban_data.family_types[type.name] = true;
      }
    }
    if (!view_data.banOrUnban) {
      const temp_users = {};
      if (view_data.isFamily) {
        for (let fam of view_data.familyDealer) {
          temp_users[fam.name] = true;
        }
        new_ban_data.unban.families = temp_users;
      } else {
        for (let fam of view_data.familyDealer) {
          temp_users[fam.name] = true;
        }
        new_ban_data.unban.dealers = temp_users;
      }
    }

    set_ban_data(new_ban_data);
    set_edit_setup_status(true);
  };

  useEffect(() => {
    if (
      Object.keys(data).length > 0 &&
      Object.keys(api_data).length > 0 &&
      !edit_setup_status
    ) {
      initial_data_setup_for_edit();
    }
    if (Object.keys(api_data).length > 0) {
      let res = api_data.unban_data;
      let unban_options = {
        unban: {
          families: [],
          dealers: [],
        },
      };
      if (res && res.families && res.dealers) {
        unban_options = {
          unban: {
            families: res && res.families.map((item) => item.name),
            dealers: res && res.dealers.map((item) => item.name),
          },
        };
      }
      set_options_data({
        ...options_data,
        exchange: Object.keys(api_data.exchanges),
        instruments: api_data.instrumentTypes,
        family_type: api_data.allFamilyTypes.map((item) => item.name),
        dealers: api_data.allDealers.map((item) => item.name),
        ...unban_options,
      });
    }
  }, [api_data]);

  // ? settingup exchange , symbol, expiry based on selections
  useEffect(() => {
    const setup_symbols = async () => {
      const res = await BanApiCalls.get_securities(
        ban_data.exchange,
        ban_data.instrument
      );

      set_symbol_options(res);
    };

    if (
      ban_data.exchange &&
      ban_data.exchange.length > 0 &&
      ban_data.instrument.length > 0
    ) {
      setup_symbols();
    }
  }, [ban_data.exchange, ban_data.instrument]);

  useEffect(() => {
    const setup_expiry = async () => {
      const res = await BanApiCalls.get_expiry(
        ban_data.exchange,
        ban_data.instrument,
        ban_data.all_symbols || ban_data.symbols
      );
      set_expiry_options(res && res.map((item) => item.expiry.slice(0, 10)));
    };
    if (
      ban_data.exchange &&
      ban_data.exchange.length > 0 &&
      ban_data.instrument &&
      ban_data.instrument.length > 0 &&
      ((ban_data.symbols && ban_data.symbols.length > 0) ||
        ban_data.all_symbols)
    ) {
      setup_expiry();
    }
  }, [ban_data.exchange, ban_data.instrument, ban_data.symbols]);

  useEffect(() => {
    const res = DataModificationFunctions.families_filter_famType(
      api_data,
      ban_data.family_types
    );
    set_options_data({ ...options_data, families: res });
  }, [ban_data]);

  useEffect(() => {
    const load_init_unban_data = async () => {
      if (ban_data.ban_or_unban !== 'Ban') {
        const request_packet = {
          banType: Object.keys(ban_data.ban_type).filter(
            (item) => ban_data.ban_type[item]
          )[0],
          exchange: ban_data.exchange,
          instrumentType: ban_data.instrument,
          symbol: ban_data.symbols,
          expiry:
            ban_data.expiry_dates && ban_data.expiry_dates.length > 0
              ? ban_data.expiry_dates
              : null, //MM-DD-YYYY
          allSymbol: ban_data.all_symbols,
          allExpiry: ban_data.all_expiry,
          allInstrument: ban_data.all_instrument,
          banPage: false,
        };
        const res = await BanApiCalls.unban_calls(request_packet);

        if (res && res.families && res.dealers) {
          const new_api_data = {
            ...api_data,
            unban_data: {
              families: res.families,
              dealers: res.dealers,
            },
          };
          set_api_data(new_api_data);
        }
      }
    };

    if (ban_data.ban_or_unban !== 'Ban') {
      load_init_unban_data();
    }
  }, [
    ban_data.ban_type,
    ban_data.exchange,
    ban_data.instrument,
    ban_data.symbols,
    ban_data.all_symbols,
    ban_data.all_expiry,
    ban_data.all_instrument,
    ban_data.expiry_dates,
  ]);

  useEffect(() => {
    if (ban_data.select_fam['All']) {
      if (ban_data.ban_level['Family']) {
        let temp = {};
        for (let key of options_data.families) {
          temp[key] = true;
        }
        set_ban_data({ ...ban_data, families: temp });
      } else {
        let temp = {};
        for (let key of options_data.dealers) {
          temp[key] = true;
        }
        set_ban_data({ ...ban_data, dealers: temp });
      }
    }
  }, [ban_data.select_fam]);

  function ban_spec_comp() {
    switch (ban_data.ban_or_unban) {
      case 'Ban':
        if (
          (ban_data.ban_level && ban_data.ban_level['Family']) ||
          ban_data.ban_level['Dealer']
        ) {
          return (
            <div className="ban_sec-ban_level-cont">
              <div className="ban_sec_ban_level-title">
                <span className="ban-sec-internal-title">Ban Level</span>
                <RadioGroup
                  options={options_data.ban_level}
                  onSelect={(val) => {
                    if (!edit_setup_status) {
                      set_ban_data({ ...ban_data, ban_level: { [val]: true } });
                    }
                  }}
                  selected={ban_data.ban_level}
                  extStyles={{ width: '40%' }}
                  disabled={edit_setup_status}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <SearchMultiSelect
                  label="Family Type*"
                  options={options_data.family_type}
                  selected={ban_data.family_types}
                  onSelect={(item, state) => {
                    let tempState = ban_data.family_types;
                    if (state) {
                      tempState[item] = state;
                    } else {
                      delete tempState[item];
                    }

                    const filteredSelectedFamilies = DataModificationFunctions.selected_families_filter_famType(
                      api_data,
                      tempState,
                      Object.keys(ban_data.families)
                    );
                    set_ban_data({
                      ...ban_data,
                      family_types: tempState,
                      families: filteredSelectedFamilies,
                    });
                  }}
                  extStyles={{ margin: '2vh' }}
                />
                <ShowMultiSelect
                  data={Object.keys(ban_data.family_types)}
                  removeFunc={(val) => {
                    let temp = ban_data.family_types;
                    delete temp[val];
                    set_ban_data({
                      ...ban_data,
                      family_types: temp,
                    });
                  }}
                  label="Selected Family Types*"
                  extStyles={{ margin: '2vh' }}
                />
              </div>

              <hr style={{ margin: '1vh 0 1vh 0' }} />

              <RadioGroup
                label={
                  ban_data.ban_level['Family']
                    ? 'Select Families*'
                    : 'Select Dealers*'
                }
                selected={ban_data.select_fam}
                options={options_data.select_family}
                onSelect={(val) => {
                  set_ban_data({ ...ban_data, select_fam: { [val]: true } });
                }}
                extStyles={{ margin: '1vh 1vw 1vh 1vw', width: '40%' }}
              />

              {ban_data.select_fam['Custom'] && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SearchMultiSelect
                    label={ban_data.ban_level['Family'] ? 'Family*' : 'Dealers*'}
                    options={
                      ban_data.ban_level['Family']
                        ? options_data.families
                        : options_data.dealers
                    }
                    selected={
                      ban_data.ban_level['Family']
                        ? ban_data.families
                        : ban_data.dealers
                    }
                    onSelect={(item, state) => {
                      let tempState = ban_data.ban_level['Family']
                        ? ban_data.families
                        : ban_data.dealers;
                      if (state) {
                        tempState[item] = state;
                      } else {
                        delete tempState[item];
                      }
                      ban_data.ban_level['Family']
                        ? set_ban_data({ ...ban_data, families: tempState })
                        : set_ban_data({ ...ban_data, dealers: tempState });
                    }}
                    extStyles={{ margin: '2vh' }}
                  />
                  <ShowMultiSelect
                    data={Object.keys(
                      ban_data.ban_level['Family']
                        ? ban_data.families
                        : ban_data.dealers
                    )}
                    removeFunc={(val) => {
                      let temp = ban_data.ban_level['Family']
                        ? ban_data.families
                        : ban_data.dealers;
                      delete temp[val];
                      ban_data.ban_level['Family']
                        ? set_ban_data({
                            ...ban_data,
                            families: temp,
                          })
                        : set_ban_data({ ...ban_data, dealers: temp });
                    }}
                    label={
                      ban_data.ban_level['Family']
                        ? 'Selected Families*'
                        : 'Selected Dealers*'
                    }
                    extStyles={{ margin: '2vh' }}
                  />
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div className="ban_sec-ban_level-cont">
              <div className="ban_sec_ban_level-title">
                <span className="ban-sec-internal-title">Ban Level</span>
                <RadioGroup
                  options={options_data.ban_level}
                  onSelect={(val) =>
                    set_ban_data({ ...ban_data, ban_level: { [val]: true } })
                  }
                  selected={ban_data.ban_level}
                  extStyles={{ width: '40%' }}
                  disabled={edit_setup_status}
                />
              </div>
              <EntityExtension
                show_comp={ban_data.ban_level['Entity'] || false}
                entOptions={entOptions}
                selected={slecEnts}
                onSetEntOptions={(val) => setSelectEnts(val)}
              />
            </div>
          );
        }
        break;

      default:
        const options = ban_data.ban_level['Family']
          ? options_data.unban.families
          : options_data.unban.dealers;
        const selected_famdeal = ban_data.ban_level['Family']
          ? ban_data.unban.families
          : ban_data.unban.dealers;
        if (ban_data.ban_level['Family'] || ban_data.ban_level['Dealer']) {
          return (
            <div className="ban_sec-ban_level-cont">
              <div className="ban_sec_ban_level-title">
                <span className="ban-sec-internal-title">Ban Level</span>
                <RadioGroup
                  options={options_data.ban_level}
                  onSelect={(val) =>
                    set_ban_data({ ...ban_data, ban_level: { [val]: true } })
                  }
                  selected={ban_data.ban_level}
                  extStyles={{ width: '40%' }}
                  disabled={edit_setup_status}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <SearchMultiSelect
                  label={
                    ban_data.ban_level['Family']
                      ? 'Banned Families*'
                      : 'Banned Dealers*'
                  }
                  options={options}
                  selected={selected_famdeal}
                  onSelect={(item, state) => {
                    let tempState = ban_data;
                    if (state) {
                      if (ban_data.ban_level['Family']) {
                        tempState.unban.families[item] = state;
                      } else {
                        tempState.unban.dealers[item] = state;
                      }
                    } else {
                      if (ban_data.ban_level['Family']) {
                        delete tempState.unban.families[item];
                      } else {
                        delete tempState.unban.dealers[item];
                      }
                    }

                    set_ban_data({ ...ban_data, unban: tempState });
                  }}
                  extStyles={{ margin: '2vh' }}
                />
                <ShowMultiSelect
                  data={Object.keys(selected_famdeal)}
                  removeFunc={(val) => {
                    let temp = ban_data.unban;
                    if (ban_data.ban_level['Family']) {
                      delete temp.unban.families[val];
                    } else {
                      delete temp.unban.dealers[val];
                    }
                    set_ban_data({
                      ...ban_data,
                      unban: temp,
                    });
                  }}
                  label={
                    ban_data.ban_level['Family']
                      ? 'Unban Families*'
                      : 'Unban Dealers*'
                  }
                  extStyles={{ margin: '2vh' }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="ban_sec-ban_level-cont">
              <div className="ban_sec_ban_level-title">
                <span className="ban-sec-internal-title">Ban Level</span>
                <RadioGroup
                  options={options_data.ban_level}
                  onSelect={(val) =>
                    set_ban_data({ ...ban_data, ban_level: { [val]: true } })
                  }
                  selected={ban_data.ban_level}
                  extStyles={{ width: '40%' }}
                  disabled={edit_setup_status}
                />
              </div>
              <EntityExtension
                show_comp={ban_data.ban_level['Entity'] || false}
                entOptions={entOptions}
                selected={slecEnts}
                onSetEntOptions={(val) => setSelectEnts(val)}
              />
            </div>
          );
        }
        break;
    }
  }

  async function create_ban_unban() {
    try {
      if (ban_data.block_info.length === 0) {
        set_error('Block Info cannot be empty');
        return;
      } else {
        set_error('');
      }

      if (ban_data.ban_or_unban === 'Ban') {
        let request_packet = {
          name: ban_data.block_info,
          banType: Object.keys(ban_data.ban_type)[0],
          exchange: ban_data.exchange,
          instrumentType: ban_data.instrument,
          symbol: ban_data.symbols,
          expiry: ban_data.expiry_dates,
          allInstrument: ban_data.all_instrument,
          allSymbol: ban_data.all_symbols,
          allExpiry: ban_data.all_expiry,
          banPage: ban_data.ban_or_unban === 'Ban',
          familyTypes: [],
          families: [],
          dealers: [],
          entities: [],
          remarks: ban_data.remark,
        };

        for (let ele of Object.keys(slecEnts)) {
          const fobj = api_data.allEntities.filter(
            (item) =>
              item.accountName ===
              (ele && ele.includes('-')
                ? ele.split('-').slice(1).join('-')
                : '')
          )[0].entityDisplayId;
          request_packet.entities.push(fobj);
        }

        for (let ft in ban_data.family_types) {
          request_packet.familyTypes.push(
            api_data.allFamilyTypes.filter((item) => item.name === ft)[0].id
          );
        }

        for (let key in ban_data.families) {
          request_packet.families.push(
            api_data.allFamilies.filter((item) => item.name === key)[0].id
          );
        }
        for (let key in ban_data.dealers) {
          request_packet.dealers.push(
            api_data.allDealers.filter((item) => item.name === key)[0].id
          );
        }
        if (!validatePacket(request_packet)) {
          return;
        }
        let requ_resp;
        let isEdit = false;
        if (Object.keys(data).length > 0) {
          isEdit = true;
          requ_resp = await BanApiCalls.edit_ban_api({
            ...request_packet,
            banId: data.id,
          });
        } else {
          requ_resp = await BanApiCalls.create_ban(request_packet);
        }

        if (requ_resp && requ_resp.status >= 400) {
          set_error(requ_resp.data.code);
        } else {
          setPopup({
            banId: -1,
            header: isEdit ? BAN_MODIFY_SUCCESS : BAN_CREATE_SUCCESS,
            buttons: 'singleButton',
            type: 'CLOSE',
          });
          setShowPopUp(true);
          set_popup({
            state: false,
            type: -1,
            data: {},
          });
        }
      } else {
        let request_packet = {
          name: ban_data.block_info,
          banType: Object.keys(ban_data.ban_type)[0],
          exchange: ban_data.exchange,
          instrumentType: ban_data.instrument,
          symbol: ban_data.symbols,
          expiry: ban_data.expiry_dates,
          allSymbol: ban_data.all_symbols,
          allExpiry: ban_data.all_expiry,
          allInstrument: ban_data.all_instrument,
          banPage: ban_data.ban_or_unban === 'Ban',
          familyTypes: [],
          families: [],
          dealers: [],
          entities: [],
          remarks: ban_data.remark,
        };

        for (let ele of Object.keys(slecEnts)) {
          const fobj = api_data.allEntities.filter(
            (item) =>
              item.accountName ===
              (ele && ele.includes('-')
                ? ele.split('-').slice(1).join('-')
                : '')
          )[0].entityDisplayId;
          request_packet.entities.push(fobj);
        }

        if (ban_data.entity && Object.keys(ban_data.entity).length === 0) {
          for (let key in ban_data.unban.families) {
            request_packet.families.push(
              api_data.allFamilies.filter((item) => item.name === key)[0].id
            );
          }
          for (let key in ban_data.unban.dealers) {
            request_packet.dealers.push(
              api_data.allDealers.filter((item) => item.name === key)[0].id
            );
          }
        }

        for (let ft in ban_data.family_types) {
          request_packet.familyTypes.push(
            api_data.allFamilyTypes.filter((item) => item.name === ft)[0].id
          );
        }

        for (let key in ban_data.families) {
          request_packet.families.push(
            api_data.allFamilies.filter((item) => item.name === key)[0].id
          );
        }
        for (let key in ban_data.dealers) {
          request_packet.dealers.push(
            api_data.allDealers.filter((item) => item.name === key)[0].id
          );
        }
        if (!validatePacket(request_packet)) {
          return;
        }
        let requ_resp;
        let isEdit = false;
        if (Object.keys(data).length !== 0) {
          isEdit = true;
          requ_resp = await BanApiCalls.edit_ban_api({
            ...request_packet,
            banId: data.id,
          });
        } else {
          requ_resp = await BanApiCalls.create_ban({
            ...request_packet,
          });
        }

        if (requ_resp && requ_resp.status >= 400) {
          set_error(requ_resp.data.code);
        } else {
          setPopup({
            dealerId: -1,
            header: isEdit ? UNBAN_MODIFY_SUCCESS : UNBAN_CREATE_SUCCESS,
            buttons: 'singleButton',
            type: 'CLOSE',
          });
          setShowPopUp(true);
          set_popup({
            state: false,
            type: -1,
            data: {},
          });
        }
      }
    } catch (error) {
      set_error(error.message);
    }
  }

  function validatePacket(requestPacket) {
    if (
      requestPacket.families.length === 0 &&
      requestPacket.familyTypes.length === 0
    ) {
      if (
        requestPacket.dealers.length === 0 &&
        requestPacket.familyTypes.length === 0
      ) {
        if (requestPacket.entities.length === 0) {
          set_error('Please add some values');
          return false;
        }
      }
    }

    return true;
  }

  return (
    <div className="ban-sec-popup-cont">
      <div
        className={`ban-sec-popup-title${
          ban_data.ban_or_unban === 'Ban' ? '-red' : '-green'
        }`}
      >
        <ToggleTab
          disabled={Object.keys(data).length > 0}
          options={['Ban', 'Unban']}
          onSelect={(val) => set_ban_data({ ...ban_data, ban_or_unban: val })}
          selected={ban_data.ban_or_unban}
          extStyles={{ margin: 'auto 0 auto 2vh' }}
        />
        <span className="ban-sec-title-text">{`${ban_data.ban_or_unban} Securities`}</span>
      </div>
      <div style={{ height: '64vh', overflowY: 'scroll' }}>
        <div className="create-ban-sec1">
          <InputField
            label="Block Info"
            value={ban_data.block_info}
            onValueChange={(val) =>
              set_ban_data({ ...ban_data, block_info: val })
            }
            type="text"
            inputWidth="25vw"
          />
          <RadioGroup
            options={options_data.ban_type}
            selected={ban_data.ban_type}
            extStyles={{ width: '25vw' }}
            label="Ban Type*"
            onSelect={(val) =>
              set_ban_data({ ...ban_data, ban_type: { [val]: true } })
            }
          />
        </div>
        <div className="create-ban-sec1">
          <DropDown
            label="Exchange*"
            options={options_data.exchange}
            selected={ban_data.exchange}
            orientation="v"
            onSelect={(val) => {
              set_ban_data({
                ...ban_data,
                exchange: val,
                symbols: '',
                expiry_dates: '',
              });
            }}
          />
          <DropDown
            all_check={{
              state: ban_data.all_instrument,
              label: 'All Instruments*',
            }}
            trigger={(val) => {
              set_ban_data((oldState) => {
                return {
                  ...oldState,
                  all_instrument: val,
                  all_symbols: val,
                  all_expiry: val,
                  instrument: '',
                  symbols: '',
                  expiry_dates: '',
                };
              });
            }}
            disabled={
              ban_data.all_instrument || options_data.instruments.length === 0
            }
            options={options_data.instruments}
            selected={ban_data.instrument}
            orientation="v"
            onSelect={(val) =>
              set_ban_data({
                ...ban_data,
                instrument: val,
                symbols: '',
                expiry_dates: '',
              })
            }
            enable_search={true}
          />
          <DropDown
            all_check={{ state: ban_data.all_symbols, label: 'All Symbols*' }}
            trigger={(val) =>
              set_ban_data({ ...ban_data, all_symbols: val, symbols: null })
            }
            options={symbol_options}
            check_disabled={symbol_options && symbol_options.length === 0}
            selected={ban_data.symbols}
            orientation="v"
            disabled={ban_data.all_symbols || symbol_options.length === 0}
            onSelect={(val) =>
              set_ban_data({ ...ban_data, symbols: val, expiry_dates: '' })
            }
            enable_search={true}
          />
          <DropDown
            all_check={{ state: ban_data.all_expiry, label: 'All Expiry*' }}
            trigger={(val) =>
              set_ban_data({ ...ban_data, all_expiry: val, expiry_dates: null })
            }
            check_disabled={expiry_options && expiry_options.length === 0}
            options={expiry_options}
            selected={ban_data.expiry_dates}
            orientation="v"
            disabled={ban_data.all_expiry || expiry_options.length === 0}
            onSelect={(val) => set_ban_data({ ...ban_data, expiry_dates: val })}
          />
        </div>
        {ban_spec_comp()}
        <InputField
          label="Remarks"
          extStyles={{ margin: '0 auto 2vh auto', width: 'fit-content' }}
          inputWidth="58vw"
          type="text"
          onValueChange={(remark) =>
            set_ban_data({ ...ban_data, remark: remark })
          }
        />
      </div>
      <div className="create-ban-bottom-area">
        <span className="error-create-ban">{error}</span>
        <GenButton
          title="CREATE"
          fill={true}
          extStyle={{
            margin: '0 2vh 0 0',
            backgroundColor:
              ban_data.ban_or_unban === 'Ban' ? '#E44125' : '#30B212',
          }}
          clickAction={() => create_ban_unban()}
        />
      </div>
    </div>
  );
}

// * View for ban and Unban
function View({ data, edit_ban, editAllowed }) {
  const [view_data, set_view_data] = useState({});

  useEffect(() => {
    const apiCall = async () => {
      const res = await BanApiCalls.get_view_data(data.id);

      set_view_data(res);
    };
    apiCall();
  }, []);

  return (
    <div className="ban-sec-popup-cont">
      <div className={`ban-sec-view-title${data.banEn ? '-unban' : '-ban'}`}>
        <span className="ban-sec-view-text">
          View {data.banEn ? 'Unban' : 'Ban'}
        </span>
        <GenButton
          icon={EditSolid}
          title="EDIT"
          fill={false}
          clickAction={() => edit_ban(data.id)}
          disabled={!editAllowed}
        />
      </div>
      <div className="view-ban-table-cont">
        <div className="view-ban-table-header">
          <span className="view-ban-table-title">View Ban</span>
        </div>
        <div className="view-ban-table-cont-row-column">
          <div className="view-ban-table-cell">
            <span className="view-ban-table-text">Block Info</span>
            <span className="view-ban-table-text-2">{view_data['info']}</span>
          </div>
          <div className="view-ban-table-cell-1">
            <span className="view-ban-table-text">Ban Type</span>
            <span className="view-ban-table-text-2">
              {view_data['banType']}
            </span>
          </div>
          <div className="view-ban-table-cell">
            <span className="view-ban-table-text">Exchange</span>
            <span className="view-ban-table-text-2">
              {view_data['display_exchange']}
            </span>
          </div>
          <div className="view-ban-table-cell-1">
            <span className="view-ban-table-text">Instruments</span>
            <span className="view-ban-table-text-2">
              {view_data['instrument']}
            </span>
          </div>
          <div className="view-ban-table-cell">
            <span className="view-ban-table-text">Symbol</span>
            <span className="view-ban-table-text-2">{view_data['symbol']}</span>
          </div>
          <div className="view-ban-table-cell-1">
            <span className="view-ban-table-text">Expiry</span>
            <span className="view-ban-table-text-2">{view_data['expiry']}</span>
          </div>
        </div>
      </div>
      <div className="view-ban-table-cont">
        <div className="view-ban-table-header">
          <span className="view-ban-table-title">Ban Level</span>
        </div>
        <div className="view-ban-table-cont-row-column">
          <div className="view-ban-table-cell">
            <span className="view-ban-table-text">Family Type</span>
            <span className="view-ban-table-text-2">
              {view_data['familyType'] &&
                view_data['familyType'].map((item) => item.name).join(' ,')}
            </span>
          </div>
          <div className="view-ban-table-cell-1">
            <span className="view-ban-table-text">
              Selected{' '}
              {view_data.banUnbanReqType === 'ENTITY' ? 'Entity' : 'Family'}
            </span>
            <span className="view-ban-table-text-2">
              {view_data['familyDealer'] &&
                view_data['familyDealer'].map((item) => item.name).join(' ,')}
            </span>
          </div>
        </div>
      </div>
      <div className="view-ban-table-cont">
        <div className="view-ban-table-header">
          <span className="view-ban-table-title">Remark</span>
        </div>
        <div className="view-ban-table-cell-2">
          <p className="view-ban-table-text-3">
            {view_data['remarks'] &&
              view_data['remarks'].length > 0 &&
              view_data['remarks'][0].remark}
          </p>
        </div>
      </div>
    </div>
  );
}

// * use to show remarks
function Remark({ data }) {
  return (
    <div className="ban-sec-popup-cont">
      <TableGenerator
        heads={['Date Modified', 'User ID / Name', 'Remarks']}
        content={data.map((item) => {
          return (
            <tr>
              <td>
                <span className="remark-table-cell">
                  {new Date(item.time.slice(0, 22)).toLocaleString()}
                </span>
              </td>
              <td>
                <span className="remark-table-cell">{item.user}</span>
              </td>
              <td>
                <span className="remark-table-cell">{item.remark}</span>
              </td>
            </tr>
          );
        })}
      />
    </div>
  );
}

function EntityExtension(props) {
  const { show_comp, entOptions = [], selected, onSetEntOptions } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        visibility: show_comp ? 'visible' : 'collapse',
      }}
    >
      <SearchMultiSelect
        label={'Select Entities*'}
        options={entOptions.map(
          (item) => item.entityDisplayId + '-' + item.accountName
        )}
        selected={selected}
        onSelect={(item, state) => {
          let tempState = { ...selected };
          if (state) {
            tempState[item] = state;
          } else {
            delete tempState[item];
          }
          onSetEntOptions(tempState);
        }}
        extStyles={{ margin: '2vh' }}
      />
      <ShowMultiSelect
        data={Object.keys(selected)}
        removeFunc={(val) => {
          let temp = { ...selected };
          delete temp[val];
          onSetEntOptions(temp);
        }}
        label={'Selected Entities*'}
        extStyles={{ margin: '2vh' }}
      />
    </div>
  );
}

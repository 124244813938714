import React from 'react';
import { Logout } from '../components/header/Logout/Logout';
import {
  UserMaintenance,
  StrategyBuilder,
  StrategyView,
  ErrorPage,
  LiveTrading,
  LoginWrapper,
  OpsScreen,
} from '../viewScreen';
import RiskMonitoringParent from '../viewScreen/AdminDashboard/RiskMonitorParent/riskMonitor';
import { RiskConfigV2 } from '../viewScreen/AdminDashboard';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PreviewStrategyModal from '../components/strategyBuilder/create/previewStrategy/previewStrategyModal/PreviewStrategyModal';
import CreateStrategyModalV2 from '../components/LiveTrading/CreateEditStrategyModalV2/CreateEditStrategyModalV2';
import ManualOrder from '../components/LiveTrading/ManualOrder/ManualOrder';
import OrderBookTicker from '../components/LiveTrading/OrderBook/OrderBookTicker';
import MarketWatch from '../components/LiveTrading/MarktWatch/MarketWatch';
import RuleOpenOrder from '../components/LiveTrading/StrategyAnalyzer/RuleOrderBook/RuleOrderBook';
import TradingHeaderWindow from '../components/LiveTrading/TradingHeader/TradingHeaderWindow';
import StrategyAnalyzerWindow from '../components/LiveTrading/StrategyAnalyzer/StrategyAnalyzerWindow';
import OrderBookDrilldown from '../components/LiveTrading/OrderBook/OrderBookDrilldown';
import SquareOffWindow from '../components/LiveTrading/StrategyAnalyzer/CheckBoxBar/ForceSqOff/SquareOffWindow';
import AlertsAndNotification from '../components/AlertsAndNotification/AlertsAndNotification';
import AppEntry from '../viewScreen/AppEntry';
import MultiScreen from '../viewScreen/MultiScreen';
import MultScreenContainer from '../viewScreen/MultiScreen/rootScreen';

export default function Routes({ match, navbarRef }) {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppEntry />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/strategyBuilder/view',
          element: <StrategyView />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/strategyBuilder/create',
          element: <StrategyBuilder />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/forgotPassword',
          element: <LoginWrapper />,
          errorElement: <ErrorPage />,
        },
        { path: '/logout', element: <Logout />, errorElement: <ErrorPage /> },
        {
          path: '/risk/userMaintenance',
          element: <UserMaintenance />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/riskmonitoring',
          element: <RiskMonitoringParent />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/riskmonitoringmar',
          element: <RiskMonitoringParent />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/riskconfig',
          element: <RiskConfigV2 />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/riskconfig2',
          element: <RiskConfigV2 />,
          errorElement: <ErrorPage />,
        },
        { path: '/ops', element: <OpsScreen />, errorElement: <ErrorPage /> },
        {
          path: '/multiScreen',
          element: <MultiScreen />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/LiveTrading',
          element: <LiveTrading navbarRef={navbarRef} />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    {
      path: '/window',
      children: [
        {
          path: '/window/PreviewStrategyModal',
          element: <PreviewStrategyModal />,
        },
        {
          path:
            '/window/create-strategy-modal/:id/:strategyTemplateId/:isTemplate',
          element: <CreateStrategyModalV2 />,
        },
        {
          path: '/window/edit-strategy/:id/:strategyTemplateId',
          element: <CreateStrategyModalV2 />,
        },
        {
          path: '/window/trade-modify-strategy/:id/:strategyTemplateId',
          element: <CreateStrategyModalV2 />,
        },
        {
          path: '/window/manual-order/:strategyId',
          element: <ManualOrder />,
        },
        {
          path: '/window/manual-order',
          element: <ManualOrder />,
        },
        {
          path:
            '/window/order-book-ticker/:userId/:exchange/:dataFeedTicker/:esteeId/:securityName',
          element: <OrderBookTicker />,
        },
        {
          path: '/window/liveTrading',
          element: <MarketWatch marketWatch={true} />,
        },
        {
          path: '/window/rule/openOrderTrades/:securityId/:reqTypeEnum',
          element: <RuleOpenOrder />,
        },
        { path: '/window/trading', element: <TradingHeaderWindow /> },
        {
          path: '/window/strategy-analyzer',
          element: <StrategyAnalyzerWindow />,
        },
        {
          path: '/window/Order/OrderBookDrilldown/:type/:id',
          element: <OrderBookDrilldown />,
        },
        { path: '/window/manual-order/edit/:id', element: <ManualOrder /> },
        { path: '/window/squareOff', element: <SquareOffWindow /> },
        { path: '/window/alert', element: <AlertsAndNotification /> },
      ],
    },
    {
      path: '/multiWindow',
      element: <MultScreenContainer />,
      children: [
        {
          path: '/multiWindow/riskmonitoring',
          element: <RiskMonitoringParent sizeAdjustable={true} />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/multiWindow/riskconfig',
          element: <RiskConfigV2 />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/multiWindow/riskconfig2',
          element: <RiskConfigV2 />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/multiWindow/alert',
          errorElement: <ErrorPage />,
          element: <AlertsAndNotification />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

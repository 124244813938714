import React, { useEffect, useState, useRef } from 'react';
import './riskConfig.css';
import GenericHeader from '../../../components/sharedComponents/genHeader';
import TreeMenuGenerator from '../../../components/sharedComponents/genTreeMenu/genTreemenu';
import ExcellIcon from '../../../assets/img/spreadsheet_icon.svg';
import TableGenerator from '../../../components/sharedComponents/GenTable/genTableGenerator';
import { prepareData } from './riskConfigDataProcessor';
import TextField from '@mui/material/TextField';
import MakeRequest from '../../../utils/apiRequest';
import GenericPopup from '../../../components/sharedComponents/Popup/Popup.js';
import FileUploadComponent from '../../../components/FileUpload/FileUpload';
import filterIcon from '../../../assets/img/filter02.svg';
import { FeedbackPopup, GenButton, Switch } from '../../../components';
import ExcelPrevPopup from '../../../components/sharedComponents/customComponent/excelPrevPopup';

const heads = ['Trade Price Protection Limit', 'Limit (%)'];
const heads2 = [
  'Risk Measure',
  'Currency',
  'Limit Amount',
  // 'Limit (%)',
  'Urgent (%)',
  'Warning (%)',
  'Alert (%)',
];

const getHeads = (type) => {
  switch (type) {
    case 1:
      return heads;
    case 2:
      return heads2;
    case 3:
      return heads2;
  }
};

const navConst = { TRADE_PP: 1, RISK_PF: 2, RISK_PD: 3 };

const RiskConfigScr = () => {
  const [displayDataState, setDisplayDataState] = useState({
    hierarchy: [],
    dealHier: [],
    allRules: { [1]: [], [2]: [], [3]: [] },
    displayTable: [],
    tableContent: { adminLevel: [], familyLevel: {} },
    tempHold: [],
    currRules: [],
    currentSelection: { type: 1, familyId: -1 },
    dealerRiskParam: [],
    dealerRiskValues: [],
    dealers: [],
    hierSearch: '',
  });
  const [dealerToggle, setDealerToggle] = useState(false);
  const [message, setMessage] = useState({
    visible: false,
    title: '',
    message: '',
  });
  const inputFile = useRef(null);
  const [show, setShow] = useState(false);
  const [isSuccessUpload, setIsSuccessUpload] = useState(false);
  const [successUpdDBData, setSuccessUpdDBData] = useState(null);
  const [updateInfo, setUpdateInfo] = useState({
    type: '',
    id: -1,
  });

  useEffect(() => {
    try {
      initialApiDataFetch();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Selective Update on Successfull Risk Excel upload
  useEffect(() => {
    if (isSuccessUpload == true) {
      try {
        initialApiDataFetch();
      } catch (error) {
        console.log(error);
      }
      setIsSuccessUpload(false);
    }
  }, [isSuccessUpload]);

  useEffect(() => {
    if (displayDataState.dealHier.length > 0 && dealerToggle) {
      hierClickAction(navConst.RISK_PD, displayDataState.dealHier[0].id);
    } else if (displayDataState.hierarchy[0] && !dealerToggle) {
      hierClickAction(navConst.TRADE_PP, displayDataState.hierarchy[0].id);
    }
  }, [dealerToggle]);

  useEffect(() => {
    if (successUpdDBData) {
      if (updateInfo.type === 'F') {
        updateFamilyDataonConfigSuccess(successUpdDBData, updateInfo.id);
      } else if (updateInfo.type === 'P') {
        updateTradePriceProtectionDataonConfigSuccess(
          successUpdDBData,
          updateInfo.id
        );
      } else if (updateInfo.type === 'D') {
        updateDealerDataonConfigSuccess(successUpdDBData, updateInfo.id);
      }
    }
  }, [successUpdDBData]);

  const initialApiDataFetch = async (isDisplayUpdNeeded = true) => {
    const response = await MakeRequest('/admin/risk/configuration').get();
    if (response.event !== undefined && !response.event) {
      return;
    }
    const tempData = response.data.data.message;
    const result = prepareData(tempData, displayDataState);
    // console.log('Screen Update : ', result);
    if (isDisplayUpdNeeded) setDisplayDataState(result);

    return result;
  };

  const updateFamilyDataonConfigSuccess = (updatedData, familyId = -1) => {
    let displayState = [...displayDataState.displayTable];
    for (let displayRow of displayState) {
      let riskParamId = displayRow.riskParamId;
      // riskParamId = riskParamId && riskParamId.length > 1 ? riskParamId[0] : -1;
      if (
        updatedData.tableContent.familyLevel &&
        updatedData.tableContent.familyLevel[familyId] &&
        Array.isArray(updatedData.tableContent.familyLevel[familyId])
      ) {
        for (let famRisk of updatedData.tableContent.familyLevel[familyId]) {
          if (famRisk.riskParamId === riskParamId) {
            displayRow.limitAmount = famRisk.limitAmount;
            break;
          }
        }
        setDisplayDataState({
          ...displayDataState,
          displayTable: displayState ? displayState : [],
          tempHold: [],
          tableContent: {
            adminLevel: displayDataState.tableContent.adminLevel
              ? displayDataState.tableContent.adminLevel
              : [],
            familyLevel: updatedData.tableContent.familyLevel
              ? updatedData.tableContent.familyLevel
              : {},
          },
        });
      }
    }
  };

  const updateDealerDataonConfigSuccess = (updatedData, dealerId) => {
    let displayState = [...displayDataState.displayTable];
    for (let displayRow of displayState) {
      let riskParamId = displayRow.riskParamId;
      // riskParamId = riskParamId && riskParamId.length > 1 ? riskParamId[0] : -1;
      if (
        updatedData.dealerRiskValues &&
        Array.isArray(updatedData.dealerRiskValues)
      ) {
        for (let dealRisk of updatedData.dealerRiskValues) {
          if (
            dealRisk.dealerId === dealerId &&
            dealRisk.riskParamId === riskParamId
          ) {
            displayRow.limitAmount = dealRisk.limitAmount;
            break;
          }
        }
        setDisplayDataState({
          ...displayDataState,
          displayTable: displayState,
          tempHold: [],
          dealerRiskParam: updatedData.dealerRiskParam
            ? updatedData.dealerRiskParam
            : [],
          dealerRiskValues: updatedData.dealerRiskValues
            ? updatedData.dealerRiskValues
            : [],
        });
      }
    }
  };

  const updateTradePriceProtectionDataonConfigSuccess = (
    updatedData,
    familyId = -1
  ) => {
    let displayState = [...displayDataState.displayTable];
    for (let displayRow of displayState) {
      let riskParamId = displayRow.riskParamId;
      // riskParamId = riskParamId && riskParamId.length > 1 ? riskParamId[0] : -1;
      if (
        updatedData.tableContent.adminLevel &&
        Array.isArray(updatedData.tableContent.adminLevel)
      ) {
        for (let famRisk of updatedData.tableContent.adminLevel) {
          if (famRisk.riskParamId === riskParamId) {
            displayRow.limit = famRisk.limit;
            break;
          }
        }
        setDisplayDataState({
          ...displayDataState,
          displayTable: displayState,
          tempHold: [],
          tableContent: {
            adminLevel: updatedData.tableContent.adminLevel
              ? updatedData.tableContent.adminLevel
              : [],
            familyLevel: displayDataState.tableContent.familyLevel
              ? displayDataState.tableContent.familyLevel
              : {},
          },
        });
      }
    }
  };

  const handleCHeckChange = (target, state) => {
    const tempState = { ...displayDataState };
    for (let i = 0; i < tempState.currRules.length; i++) {
      if (tempState.currRules[i].id === target.id) {
        tempState.currRules[i].state = state;
      }
    }

    let riskParamName = '',
      riskParamValue = 0;
    if (tempState.currentSelection.type === navConst.TRADE_PP) {
      if (state) {
        let found = false;

        for (let i = 0; i < tempState.tableContent.adminLevel.length; i++) {
          if (tempState.tableContent.adminLevel[i].riskParamId === target.id) {
            riskParamName = `${target.id}-limitAmount`;
            riskParamValue = 0;
            tempState.displayTable.push(tempState.tableContent.adminLevel[i]);
            found = true;
          }
        }
        if (!found) {
          const obj = {
            riskParamId: target.id,
            limit: '',
            sno: tempState.displayTable.length + 1,
            tradeName: target.title,
            isPercentage: tempState.tradePPOptions[target.id].isPercentage,
          };
          tempState.displayTable.push(obj);
        }
      } else {
        const tempTable = tempState.displayTable.filter(
          (obj) => obj.riskParamId !== target.id
        );
        tempState.displayTable = tempTable;
      }
    } else {
      if (state) {
        let found = false;
        const searchSpace =
          tempState.tableContent.familyLevel[
            tempState.currentSelection.familyId
          ] || [];
        for (let i = 0; i < searchSpace.length; i++) {
          if (searchSpace[i].riskParamId === target.id) {
            riskParamName = `${target.id}-limitAmount`;
            riskParamValue = 0;
            tempState.displayTable.push(searchSpace[i]);
            found = true;
          }
        }
        if (!found) {
          riskParamName = `${target.id}-limitAmount`;
          riskParamValue = 0;
          const obj = {
            riskParamId: target.id,
            limit: '',
            sno: tempState.displayTable.length + 1,
            isPercentage: target.isPercentage,
            riskType: target.title,
            alert: 0,
            urgent: 0,
            warning: 0,
            familyId: tempState.currentSelection.familyId,
            limitAmount: 0,
            name: '',
          };
          tempState.displayTable.push(obj);
        }
      } else {
        const tempTable = tempState.displayTable.filter(
          (obj) => obj.riskParamId !== target.id
        );
        tempState.displayTable = tempTable;
      }
    }

    if (state) {
      setDisplayDataState(tempState);
      const entryObj = {
        target: {
          name: riskParamName,
          value: riskParamValue,
        },
      };
      tableDataChange(entryObj);
    } else {
      if (
        tempState &&
        tempState.tempHold &&
        tempState.tempHold.length &&
        target &&
        target.id &&
        !isNaN(target.id)
      )
        for (let i = 0; i < tempState.tempHold.length; i++) {
          if (tempState.tempHold[i].riskParamId === parseInt(target.id)) {
            tempState.tempHold.splice(i, 1);
            break;
          }
        }
      setDisplayDataState(tempState);
    }
  };

  const getColumnValue = (rowType, riskParamId, riskParamValue = 0) => {
    let value = riskParamValue;
    const temp = { ...displayDataState };
    switch (rowType) {
      case 'F':
      case 'D':
      case 'P':
        if (
          temp &&
          temp.tempHold &&
          Array.isArray(temp.tempHold) &&
          temp.tempHold.length
        ) {
          for (let riskUpd of temp.tempHold) {
            if (riskUpd.riskParamId === riskParamId) {
              value = riskUpd.limitAmount;
              break;
            }
          }
        }
        break;
    }

    return value;
  };

  const hierClickAction = (clickType, id) => {
    const temp = { ...displayDataState };
    const tempHi = temp.hierarchy;
    if (clickType === navConst.RISK_PF) {
      tempHi[0].selected = false;
      tempHi[0].children = tempHi[0].children.map((obj) => {
        return { ...obj, selected: obj.id === id };
      });
      if (temp.tableContent.familyLevel[id] === undefined) {
        const arr = temp.allRules[2].map((obj) => {
          return { ...obj, state: false };
        });
        temp.hierarchy = tempHi;
        temp.currentSelection.type = clickType;
        temp.tempHold = [];
        temp.currRules = arr;
        temp.displayTable = [];
        temp.currentSelection.familyId = id;
        setDisplayDataState(temp);
        return;
      }
      temp.displayTable = [...temp.tableContent.familyLevel[id]];
      const tempSet = new Set(temp.displayTable.map((obj) => obj.riskParamId));
      temp.currRules = temp.allRules[clickType].map((obj) => {
        return { ...obj, state: tempSet.has(obj.id) };
      });
    } else if (clickType === navConst.TRADE_PP) {
      tempHi[0].selected = true;
      tempHi[0].children = tempHi[0].children.map((obj) => {
        return { ...obj, selected: false };
      });

      temp.displayTable = [...temp.tableContent.adminLevel];

      const tempSet = new Set(
        temp.tableContent.adminLevel.map((obj) => obj.riskParamId)
      );
      temp.currRules = temp.allRules[clickType].map((obj) => {
        return { ...obj, state: tempSet.has(obj.id) };
      });
    } else if (clickType === navConst.RISK_PD) {
      temp.dealHier[0].selected = id === temp.dealHier[0].id;
      for (let i = 0; i < temp.dealHier[0].children.length; i++) {
        temp.dealHier[0].children[i].selected =
          id === temp.dealHier[0].children[i].id;
      }

      temp.displayTable = [];
      if (temp.dealerRiskValues) {
        for (let tempObj of temp.dealerRiskValues) {
          if (id === tempObj.dealerId) {
            temp.displayTable.push({
              ...tempObj,
              riskType:
                displayDataState.dealerRiskParam[tempObj.riskParamId]
                  .displayName,
              sno: temp.displayTable.length + 1,
            });
          }
        }
      }

      const tempSet = new Set();
      temp.displayTable.forEach((element) => {
        tempSet.add(element.riskParamId);
      });
      temp.currRules = [...temp.allRules[clickType]];
      for (let i = 0; i < temp.currRules.length; i++) {
        temp.currRules[i].state = tempSet.has(temp.currRules[i].id);
      }
    }

    temp.hierarchy = tempHi;
    temp.currentSelection.type = clickType;
    temp.tempHold = [];
    temp.currentSelection.familyId = id;
    setDisplayDataState(temp);
  };

  function tableDataGenerator() {
    const displayData = [...displayDataState.displayTable];
    if (
      dealerToggle &&
      displayDataState.currentSelection.type === navConst.RISK_PD
    ) {
      return displayData.map((obj) => (
        <tr key={`${obj.sno}_${obj.riskType}`} className="riskUtilTr">
          <td>{obj.riskType}</td>
          <td>{obj.Currency ? obj.Currency : 'INR'}</td>
          <td>
            {obj.isPercentage ? (
              'Na'
            ) : (
              <input
                className="inputField"
                name={`${obj.riskParamId}-limitAmount`}
                type="number"
                placeholder={obj.limitAmount}
                onChange={tableDataChange}
                value={getColumnValue('D', obj.riskParamId, obj.limitAmount)}
              />
            )}
          </td>
          {/* <td>
            {obj.isPercentage ? (
              <input
                className="inputField"
                name={`${obj.riskParamId}-limit`}
                type="number"
                placeholder={obj.limit}
                onChange={tableDataChange}
              />
            ) : (
              'Na'
            )}
          </td> */}
          <td>
            <input
              className="inputField"
              name={`${obj.riskParamId}-urgent`}
              type="number"
              placeholder={obj.urgent}
              onChange={tableDataChange}
            />
          </td>
          <td>
            <input
              className="inputField"
              name={`${obj.riskParamId}-warning`}
              type="number"
              placeholder={obj.warning}
              onChange={tableDataChange}
            />
          </td>
          <td>
            <input
              className="inputField"
              name={`${obj.riskParamId}-alert`}
              type="number"
              placeholder={obj.alert}
              onChange={tableDataChange}
            />
          </td>
        </tr>
      ));
    } else if (displayDataState.currentSelection.type === navConst.TRADE_PP) {
      return displayData.map((obj) => (
        <tr key={obj.sno} className="riskUtilTr">
          <td>{obj.tradeName}</td>
          <td>
            {obj.isPercentage ? (
              <input
                className="inputField"
                name={`${obj.riskParamId}-limit`}
                type="number"
                placeholder={obj.limit}
                onChange={tableDataChange}
                value={getColumnValue('P', obj.riskParamId, obj.limit)}
                max={100}
              />
            ) : (
              'Na'
            )}
          </td>
        </tr>
      ));
    } else if (displayDataState.currentSelection.type === navConst.RISK_PF) {
      return displayData.map((obj) => (
        <tr key={`${obj.sno}_${obj.riskType}`} className="riskUtilTr">
          <td>{obj.riskType}</td>
          <td>{obj.Currency ? obj.Currency : 'INR'}</td>
          <td>
            {obj.isPercentage ? (
              'Na'
            ) : (
              <input
                className="inputField"
                name={`${obj.riskParamId}-limitAmount`}
                type="number"
                placeholder={obj.limitAmount}
                value={getColumnValue('F', obj.riskParamId, obj.limitAmount)}
                onChange={tableDataChange}
                max={100}
              />
            )}
          </td>
          {/* <td>
            {obj.isPercentage ? (
              <input
                className="inputField"
                name={`${obj.riskParamId}-limit`}
                type="number"
                placeholder={obj.limitAmount}
                onChange={tableDataChange}
              />
            ) : (
              'Na'
            )}
          </td> */}
          {!['Max Order Value', 'Max Order Size'].includes(obj.riskType) ? (
            <td>
              <input
                className="inputField"
                name={`${obj.riskParamId}-urgent`}
                type="number"
                placeholder={obj.urgent}
                onChange={tableDataChange}
              />
            </td>
          ) : (
            <td>--</td>
          )}
          {!['Max Order Value', 'Max Order Size'].includes(obj.riskType) ? (
            <td>
              <input
                className="inputField"
                name={`${obj.riskParamId}-warning`}
                type="number"
                placeholder={obj.warning}
                onChange={tableDataChange}
              />
            </td>
          ) : (
            <td>--</td>
          )}
          {!['Max Order Value', 'Max Order Size'].includes(obj.riskType) ? (
            <td>
              <input
                className="inputField"
                name={`${obj.riskParamId}-alert`}
                type="number"
                placeholder={obj.alert}
                onChange={tableDataChange}
              />
            </td>
          ) : (
            <td>--</td>
          )}
        </tr>
      ));
    }
  }

  const tableDataChange = (evt) => {
    const tempState = { ...displayDataState };
    const tempArr = [...tempState.tempHold];
    const info = evt.target.name.split('-');
    let found = false,
      tarRiskId = parseInt(info[0]),
      fieldName = info[1],
      actualValue = {},
      valueChanged =
        evt.target.value.length > 0 ? parseInt(evt.target.value) : '';

    switch (displayDataState.currentSelection.type) {
      case navConst.TRADE_PP:
        for (let i = 0; i < tempState.tempHold.length; i++) {
          if (tempState.tempHold[i].riskParamId === parseInt(info)) {
            tempState.tempHold[i][fieldName] = valueChanged;
            found = true;
            break;
          }
        }
        if (!found) {
          tempState.tempHold.push({
            riskParamId: parseInt(info),
            [fieldName]: valueChanged,
          });
        }
        break;
      case navConst.RISK_PF:
        const editSpace =
          tempState.tableContent.familyLevel[
            displayDataState.currentSelection.familyId
          ] || [];
        for (let obj of editSpace) {
          if (obj.riskParamId === tarRiskId) {
            actualValue = obj;
            break;
          }
        }
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i].riskParamId === tarRiskId) {
            found = true;
            if (evt.target.value.length >= 0) {
              tempArr[i][fieldName] = valueChanged;
            } else {
              if (Object.keys(actualValue) > 0) {
                tempArr[i][fieldName] = parseInt(actualValue[fieldName]);
              } else {
                tempArr = tempArr.filter(
                  (obj) => obj.riskParamId !== tarRiskId
                );
              }
            }
          }
        }
        if (!found) {
          tempArr.push({
            riskParamId: tarRiskId,
            alert: actualValue.alert,
            warning: actualValue.warning,
            urgent: actualValue.urgent,
            limit:
              fieldName === 'limit'
                ? valueChanged
                : (actualValue.limit || '').length > 0
                ? parseInt(actualValue.limit)
                : 0,
            limitAmount:
              fieldName === 'limitAmount'
                ? valueChanged
                : (actualValue.limitAmount || '').length > 0
                ? parseInt(actualValue.limitAmount)
                : 0,
          });
        }
        tempState.tempHold = tempArr;
        break;
      case navConst.RISK_PD:
        const editDealerSpace = displayDataState.dealerRiskValues.filter(
          (dealerRiskVal) => {
            if (
              dealerRiskVal.dealerId ==
              displayDataState.currentSelection.familyId
            ) {
              return true;
            } else {
              return false;
            }
          }
        );
        for (let obj of editDealerSpace) {
          if (obj.riskParamId === tarRiskId) {
            actualValue = obj;
            break;
          }
        }
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i].riskParamId === tarRiskId) {
            tempArr[i][fieldName] = valueChanged;
            found = true;
          }
        }
        if (!found) {
          tempArr.push({
            riskParamId: tarRiskId,
            alert: actualValue.alert,
            warning: actualValue.warning,
            urgent: actualValue.urgent,
            limit:
              fieldName === 'limit'
                ? valueChanged
                : (actualValue.limit || '').length > 0
                ? parseInt(actualValue.limit)
                : 0,
            limitAmount:
              fieldName === 'limitAmount'
                ? valueChanged
                : (actualValue.limitAmount || '').length > 0
                ? parseInt(actualValue.limitAmount)
                : 0,
          });
        }
        tempState.tempHold = tempArr;
        break;
      default:
        break;
    }
    console.log(tempArr);
    setDisplayDataState(tempState);
  };

  const openFileSelect = () => {
    setShow(true);
  };

  const submitRiskConfig = () => {
    const data = { risk: [...displayDataState.tempHold] };
    const confRulesCount = displayDataState.currRules.filter(
      (obj) => obj.state
    ).length;

    switch (displayDataState.currentSelection.type) {
      case navConst.TRADE_PP:
        data.familyId = -1;
        break;
      case navConst.RISK_PD:
        data.dealerId = displayDataState.currentSelection.familyId;
        break;
      case navConst.RISK_PF:
        data.familyId = displayDataState.currentSelection.familyId;
        break;
    }

    let availCheck = true;
    for (let tempObj of data.risk) {
      availCheck =
        availCheck &&
        ((tempObj.limit != null && tempObj.limit >= 0 && tempObj.limit != '') ||
          (tempObj.limitAmount != null &&
            tempObj.limitAmount >= 0 &&
            tempObj.limitAmount != ''));
    }

    let isUWAInRange = true;
    let isUWANumeric = true;
    for (let tempObj of data.risk) {
      if (tempObj.urgent || tempObj.urgent === 0) {
        if (isNaN(tempObj.urgent)) {
          isUWANumeric = false;
          break;
        } else if (tempObj.urgent <= 0 || tempObj.urgent > 100) {
          isUWAInRange = false;
          break;
        }
      }

      if (tempObj.warning || tempObj.warning === 0) {
        if (isNaN(tempObj.warning)) {
          isUWANumeric = false;
          break;
        } else if (tempObj.warning <= 0 || tempObj.warning > 100) {
          isUWAInRange = false;
          break;
        }
      }

      if (tempObj.alert || tempObj.alert === 0) {
        if (isNaN(tempObj.alert)) {
          isUWANumeric = false;
          break;
        } else if (tempObj.alert <= 0 || tempObj.alert > 100) {
          isUWAInRange = false;
          break;
        }
      }
    }

    if (displayDataState.currRules.filter((obj) => obj.state).length === 0) {
      setMessage({
        visible: true,
        title: 'Try Again',
        message: 'No Rules Added',
      });
    } else if (data.risk.length === 0) {
      setMessage({
        visible: true,
        title: 'Try Again',
        message: 'Risk already configured',
      });
    } else if (displayDataState.tempHold.length !== confRulesCount && false) {
      // This check is not required
      setMessage({
        visible: true,
        title: 'Try Again',
        message: 'Need to configure all selected Rules',
      });
    } else if (!availCheck) {
      setMessage({
        visible: true,
        title: 'Try Again',
        message: 'Limit or Limit Amount Cannot be empty or negative',
      });
    } else if (!isUWANumeric) {
      setMessage({
        visible: true,
        title: 'Try Again',
        message: 'Urgent, Warning and Alert values must be numeric',
      });
    } else if (!isUWAInRange) {
      setMessage({
        visible: true,
        title: 'Try Again',
        message: 'Urgent, Warning and Alert values must be in range (0 - 100)',
      });
    } else {
      try {
        const tempFunc = async (data) => {
          // Save Dealer & Familt Id in localStorage for auto focus
          if (data.familyId && data.familyId != -1) {
            setUpdateInfo({
              type: 'F',
              id: data.familyId,
            });
          } else if (data.familyId && data.familyId == -1) {
            setUpdateInfo({
              type: 'P',
              id: data.familyId,
            });
          } else if (!data.familyId && data.dealerId) {
            setUpdateInfo({
              type: 'D',
              id: data.dealerId,
            });
          }

          let response = await MakeRequest('/admin/risk/configure/family').post(
            data
          );

          if (response.status === 200) {
            // TODO here call the update function
            let message =
              response &&
              response.data &&
              response.data.data &&
              response.data.data.message;

            setMessage({
              visible: true,
              title: 'Success',
              message: message ? message : 'Successfully updated configs',
            });
            // window.location.reload(true);
          } else {
            // response = response && response.message && response.message.response ? response.message.response : {};
            let message =
              response &&
              response.data &&
              response.data.data &&
              response.data.data.message
                ? response.data.data.message
                : 'Server Error';

            setMessage({
              visible: true,
              title: 'Try Again',
              message: message
                ? message
                : 'Some error occured please try again later',
            });
          }
        };
        tempFunc(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const toggleDealer = (evt, state) => {
    setDealerToggle(state);
  };

  const hierSelect = (state) => {
    if (state) {
      if (displayDataState.hierSearch.length > 0) {
        return [
          {
            ...displayDataState.dealHier[0],
            children: displayDataState.dealHier[0].children.filter(
              (obj) =>
                new RegExp(displayDataState.hierSearch, ['i']).test(obj.title)
              // obj.title.includes(displayDataState.hierSearch)
            ),
          },
        ];
      }
      return displayDataState.dealHier;
    } else {
      if (displayDataState.hierSearch.length > 0) {
        return [
          {
            ...displayDataState.hierarchy[0],
            children: displayDataState.hierarchy[0].children.filter(
              (obj) =>
                new RegExp(displayDataState.hierSearch, ['i']).test(obj.title)
              // obj.title.includes(displayDataState.hierSearch)
            ),
          },
        ];
      }
      return displayDataState.hierarchy;
    }
  };

  const handleHierSearch = (evt) => {
    setDisplayDataState({ ...displayDataState, hierSearch: evt.target.value });
  };

  const addRuleAvail = () => {
    if (displayDataState.dealHier.length > 0) {
      if (displayDataState.currentSelection.type === 3) {
        if (
          displayDataState.currentSelection.familyId ===
          displayDataState.dealHier[0].id
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const [riskPrev, setRiskPrev] = useState();
  const [riskPrevFeedBack, setRiskPrevFeedback] = useState('');
  // * Action can be : SUB: submit, EDT: edit, CAN: cancel
  async function callBackForPopupPrev(action = { action: 0, data: {} }) {
    switch (action.action) {
      case 'SUB':
        const res = await submitExcelprev(action.data);
        if (res) {
          setRiskPrev();
          setShow(false);
          setRiskPrevFeedback(res.data.data.message);
        }
        break;
      case 'EDT':
        setShow(true);
        setRiskPrev();
        break;
      case 'CAN':
        setRiskPrev();
        setShow(false);
        break;
    }
  }

  async function submitExcelprev(data) {
    try {
      const res = await MakeRequest('/excel/upload/riskSubmit').post({
        submitData: data,
      });
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  return (
    <div
      className="riskconfig-container-main"
      style={{
        border: '#cecece solid 0.2vh',
        borderRadius: '12px',
        boxShadow: '0 0 0 #00000000',
        backgroundColor: '#ffffff',
      }}
    >
      <GenericHeader
        primaryTitle="Risk Hierarchy"
        secondaryTitle="Risk Limits"
      />
      <div className="riskConfigBody">
        <div className="treeSection">
          <div className="searchArea">
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              onChange={handleHierSearch}
            />
            <Switch
              state={dealerToggle}
              onChange={(val) => setDealerToggle(val)}
              label="Show Dealer"
            />
          </div>
          <TreeMenuGenerator
            content={hierSelect(dealerToggle)}
            clickAction={hierClickAction}
          />
        </div>
        <div className="configEdit">
          <div className="buttonSection">
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
            />
            {addRuleAvail() ? (
              <GenButton
                icon={filterIcon}
                type="multiSelect"
                options={displayDataState.currRules}
                extStyle={{
                  icon: { height: '25px', width: '25px', paddingRight: '5px' },
                }}
                changeTrig={handleCHeckChange}
              />
            ) : (
              <div></div>
            )}
            {dealerToggle ? (
              <GenButton
                disabled={true}
                fill={false}
                icon={ExcellIcon}
                title="Upload Excel"
                clickAction={() => {}}
              />
            ) : (
              <GenButton
                fill={false}
                icon={ExcellIcon}
                title="Upload Excel"
                clickAction={openFileSelect}
              />
            )}
          </div>
          <TableGenerator
            heads={getHeads(displayDataState.currentSelection.type)}
            content={tableDataGenerator()}
            className="tableContainer bodyScrollTable riskConfigTable"
          />
          <GenButton
            title="Submit Changes"
            fill={false}
            clickAction={submitRiskConfig}
            extStyle={{ back: { width: '10vw' } }}
          />
        </div>
      </div>
      {message.visible && (
        <GenericPopup
          actionButton={async () => {
            setMessage({ ...message, visible: !message.visible });
            if (message.title === 'Success' || true) {
              const updateData = await initialApiDataFetch(false);
              setSuccessUpdDBData(updateData);
              // window.location.reload(true);
            }
          }}
          title={message.title}
          popupDetails={{ note: message.message, nameAccept: 'Check Again' }}
        />
      )}

      <FileUploadComponent
        show={show}
        setShow={setShow}
        screenType={'risk'}
        setSuccessUpload={setIsSuccessUpload}
        callBackFunc={(val) => {
          setShow(false);
          setRiskPrev(val);
        }}
      />
      <ExcelPrevPopup
        state={riskPrev !== undefined}
        data={riskPrev && riskPrev.data}
        callBackFunction={callBackForPopupPrev}
        type={2}
        previewTitle="Preview Risk Configurations"
      />
      <FeedbackPopup
        message={
          riskPrevFeedBack
            ? `${JSON.stringify(
                riskPrevFeedBack
              )}. Do you want to upload again ?`
            : ''
        }
        type="a"
        acceptAction={() => {
          setShow(true);
          setRiskPrevFeedback('');
        }}
        rejectAction={() => {
          setRiskPrevFeedback('');
        }}
      />
    </div>
  );
};

export default RiskConfigScr;

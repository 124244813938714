import { useEffect, useState } from 'react';
import './ReviewStrategy.scss';
import close from '../../../../../assets/img/cancel.png';
import StrategyBuilder from '../../../../../viewScreen/strategyBuilder/create/StrategyBuilder';
import {
  modifyTemplate,
  reset,
  setEditModify,
} from '../../../../../actions/strategyBuilderAction';
import { useDispatch } from 'react-redux';
import { approveRejectButton } from '../../../../../actions/alertAction';
import Hotkeys from 'react-hot-keys';
import {
  GenButton,
  GenericPopup,
  TabNavInner,
  FeedbackPopup,
} from '../../../../../components';
import {
  ModalContainer,
  ModalHeader,
  CrossIcon,
  ModalHeadText,
  ModalBody,
} from './components';
import { MakeRequest } from '../../../../../utils';
import Styled from 'styled-components';

const ButtonGroup = Styled.div`
display: flex;
flex-direction: row-reverse;
width: 100%;
background-color: #f4f4f4;
`;

const ReviewStrategy = ({
  setAlertModal,
  alertModal,
  setModal,
  modifyData,
  editModalSubmit,
  setEditModalSubmit,
  setSubmitMessageEdit,
  setIsSuccessDelete = () => {},
  templateId,
}) => {
  const [tabs, setTab] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [templates, setTemplates] = useState([]);
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState('');
  const [popupState, setPopupState] = useState('');
  const [pop, setPop] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });

  const handleClickAccept = (id) => {
    setPopupState(id);
    setPop({
      ...pop,
      message: `Are you sure you want to accept ${templateId} template?`,
      buttons: 'doubleButtons',
      nameAccept: 'Confirm',
      nameDecline: 'Cancel',
      state: true,
    });
  };

  const handleClickReject = (id) => {
    setPopupState(id);
    setPop({
      ...pop,
      message: `Are you sure you want to reject ${templateId} template?`,
      buttons: 'doubleButtons',
      nameAccept: 'Confirm',
      nameDecline: 'Cancel',
      state: true,
    });
  };
  const handlePopupAction = async () => {
    const result = await MakeRequest('/strategyTemplate/approval').post({
      action: popupState === 'accept',
      strategyTemplateId: templateId,
    });
    setFeedback(result.data.data.message);
    handlePopupClose();
  };

  const handlePopupClose = () => {
    setPopupState('');
    setPop({
      ...pop,
      state: false,
    });
  };

  useEffect(() => {
    const allTemplates = async () => {
      const {
        data: { data: response },
      } = await MakeRequest(
        `/strategyTemplate/approvalReturn/${templateId}`
      ).get();

      setTab(
        response.map((item) => {
          return { id: item.name, title: item.name };
        })
      );
      setSelectedTab(response[0].name);
      setTemplates(response);
    };
    if (templateId) allTemplates();
  }, [templateId]);

  useEffect(() => {
    const apiCalls = async () => {
      if (templates.length > 0) {
        const res = templates.filter((item) => item.name === selectedTab);
        const {
          data: { data: response },
        } = await MakeRequest(
          `/strategyTemplate/approvalReturn/${res[0].id}`
        ).get();
        dispatch(modifyTemplate(response[0], res[0].id));
      }
    };
    apiCalls();
  }, [selectedTab]);

  const handleClose = () => {
    let clearForm = {
      name: '',
      description: '',
      exchange: '',
      securities: [
        {
          instrument: '',
          securityName: '',
          expiry: '',
          strike: '',
          nickname: '',
          isAdditional: false,
        },
      ],
      constants: [],
      entryLegs: [
        {
          legId: 1,
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          position: [
            {
              orientation: 'Fwd',
              direction: 'BUY',
              security: '',
              pricing: [],
              quantity: [],
              totalQuantityLogic: [],
            },
          ],
          algoExecution: [],
        },
      ],
      exitCondition: {
        condition: [
          {
            type: 'OPERAND',
            trigger: '',
          },
        ],
        action: {
          enumName: '',
          args: [],
        },
      },
    };

    dispatch(reset(clearForm));
    setModal ? setModal(false) : setAlertModal(false);
    dispatch(setEditModify(false));
    dispatch(approveRejectButton(false));
    setIsSuccessDelete(true);
  };

  return (
    <GenericPopup
      type={1}
      state={alertModal}
      toggleState={() => setAlertModal(false)}
    >
      <ModalContainer>
        <Hotkeys
          keyName="esc"
          onKeyDown={(event) => {
            setAlertModal(false);
          }}
        />
        <ModalHeader>
          <ModalHeadText>
            {alertModal ? 'Review Strategy' : 'Edit Strategy'}
          </ModalHeadText>
          <CrossIcon alt="" src={close} onClick={() => handleClose()} />
        </ModalHeader>

        <ModalBody>
          <TabNavInner
            tabs={tabs}
            curSelect={selectedTab}
            setSelect={(val) => setSelectedTab(val)}
          />
          <StrategyBuilder
            modifyData={modifyData}
            setEditModal={setModal}
            setAlertModal={setAlertModal}
            editModalSubmit={editModalSubmit}
            setEditModalSubmit={setEditModalSubmit}
            setSubmitMessageEdit={setSubmitMessageEdit}
            setIsSuccessDelete={setIsSuccessDelete}
            hideApprove={tabs.length > 0}
            edit={true}
          />
          {tabs.length > 0 && (
            <ButtonGroup>
              <FeedbackPopup
                message={feedback}
                acceptAction={() => {
                  setFeedback('');
                  setAlertModal(false);
                }}
                type="f"
              />
              {pop.state && (
                <FeedbackPopup
                  message={pop.message}
                  acceptAction={handlePopupAction}
                  rejectAction={handlePopupClose}
                  type={'a'}
                />
              )}
              <GenButton
                title="Reject"
                fill={false}
                extStyle={{ margin: '1rem', width: '10vw' }}
                clickAction={() => handleClickReject('reject')}
              />
              <GenButton
                title="Accept"
                fill={true}
                extStyle={{ margin: '1rem', width: '10vw' }}
                clickAction={() => handleClickAccept('accept')}
              />
            </ButtonGroup>
          )}
        </ModalBody>
      </ModalContainer>
    </GenericPopup>
  );
};

export default ReviewStrategy;

import react, { useState } from 'react';
import './styles.scss';

export default function ButtonAddLeg({
  icon = {},
  hoverIcon,
  label = '',
  extStyles = {},
  clickAction = () => {},
  category = 'addLeg',
  disable = false,
}) {
  const [hover, setHover] = useState(false);

  function buttonLoader() {
    let contCss, iconCss, labelCss;
    switch (category) {
      case 'cat1':
        contCss = 'ButtonAddLeg-cat1-container';
        iconCss = 'ButtonAddLeg-cat1-icon';
        labelCss = 'ButtonAddLeg-cat1-label';
        break;
      case 'cat2':
        contCss = 'ButtonAddLeg-cat2-container';
        iconCss = 'ButtonAddLeg-cat2-icon';
        labelCss = 'ButtonAddLeg-cat2-label';
        break;
      case 'cat3':
        contCss = 'ButtonAddLeg-cat3-container';
        iconCss = 'ButtonAddLeg-cat3-icon';
        labelCss = 'ButtonAddLeg-cat3-label';
        break;
      default:
        contCss = 'ButtonAddLeg-container';
        iconCss = 'ButtonAddLeg-icon';
        labelCss = 'ButtonAddLeg-label';
        break;
    }
    const disableStyle = { ...extStyles, opacity: 0.5, cursor: 'not-allowed' };

    return (
      <div
        className={contCss}
        style={disable ? disableStyle : extStyles}
        onClick={() => !disable && clickAction()}
        onKeyDown={(e) =>
          e.key === 'Enter' && !disable ? clickAction() : null
        }
        tabIndex={0}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img className={iconCss} src={hover ? hoverIcon || icon : icon} />
        <span className={labelCss}>{label}</span>
      </div>
    );
  }

  return buttonLoader();
}

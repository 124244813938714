import React, { useEffect, useState, useRef } from 'react';
import './genTreemenu.css';
import RightArrow from '../../../assets/img/right-arrow-icon.svg';
import liveIcon from '../../../assets/img/GreenDot.svg';
import cancelIcon from '../../../assets/img/RedDot.svg';
import CreateTemplate from '../../../viewScreen/AdminDashboard/riskConfigInternalV2/createTemplate/createTemplate';

const TreeOption = (props) => {
  const [activeState, setActiveState] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [childrenState, setChildrenState] = useState([]);
  const {
    title,
    children,
    depth,
    found,
    selected,
    clickAction,
    data,
    isChildTabActive,
    showRightIcon,
    addTemplateSubmit,
    editTemplateSubmit,
    config,
    isHFT,
    templateId,
  } = props;

  useEffect(() => {
    if (!searchString) {
      setChildrenState(children);
    } else {
      let newArr = [];
      for (let i = 0; i < children.length; i++) {
        if (
          children[i].title.toLowerCase().includes(searchString.toLowerCase())
        ) {
          newArr.push(children[i]);
        }
      }
      setChildrenState(newArr);
    }
  }, [searchString]);

  const iconState = (state) => {
    if (state) {
      return 'rotate';
    } else {
      return 'noRotate';
    }
  };

  const showHideChild = (state) => {
    if (state) {
      return 'showTree';
    } else {
      return 'hide';
    }
  };

  const getIconToDisplay = (state) => {
    if (state) {
      return liveIcon;
    } else {
      return cancelIcon;
    }
  };

  const selectedState = (state) => {
    if (state) {
      return 'selected';
    } else {
      return '';
    }
  };

  const handleEnter = (e, data) => {
    var keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode == '13') {
      setActiveState(!activeState);
      clickAction(data['type'], data['id']);
    }
  };

  return (
    <div className="mainContainer" style={{ marginLeft: `${depth}rem` }}>
      <div
        tabIndex={
          isChildTabActive !== undefined ? (isChildTabActive ? '0' : '-1') : '0'
        }
        className={`${selectedState(props.data.selected)} treeOptCont`}
        onClick={() => {
          setActiveState(!activeState);
          clickAction(data['type'], data['id']);
        }}
        onKeyPress={(event) => {
          handleEnter(event, data);
        }}
      >
        {/* <p className="title">{title}</p>
        {showRightIcon && (
          <img src={getIconToDisplay(found)} className="rightIcon" />
        )}
        {childrenState && childrenState.length > 0 && (
          <img
            src={RightArrow}
            className={`iconObj ${iconState(activeState)}`}
          />
        )} */}

        {childrenState && childrenState.length > 0 ? (
          <img
            src={RightArrow}
            className={`iconObj ${iconState(activeState)}`}
          />
        ) : (
          <span className="iconObj"></span>
        )}

        {showRightIcon && (
          // <img
          //   src={Settings}
          //   className="rightIcon"
          //   style={{ height: '20px', width: '20px' }}
          // />

          <>
            <CreateTemplate
              addTemplateSubmit={addTemplateSubmit}
              editTemplateSubmit={editTemplateSubmit}
              templateId={templateId}
              config={config}
              isHFT={isHFT}
              editFlag={true}
            ></CreateTemplate>

            <CreateTemplate
              addTemplateSubmit={addTemplateSubmit}
              editTemplateSubmit={editTemplateSubmit}
              templateId={templateId}
              config={config}
              isHFT={isHFT}
              editFlag={false}
            ></CreateTemplate>
          </>
        )}

        <p className="title" style={{ margin: '0 auto 0 0' }}>
          {title}
        </p>
      </div>
      {childrenState !== undefined && (
        <div className={`${showHideChild(activeState)}`}>
          {depth === 0 && (
            <div className="search-container">
              <div className="search-container-child-left">
                <input
                  className="search-xy-3"
                  placeholder="Search"
                  style={{
                    marginLeft: `${depth + 1}rem`,
                    marginBottom: '0.75rem',
                    marginTop: '0.25rem',
                  }}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                ></input>
              </div>
              <div className="search-container-child-right">
                <CreateTemplate
                  addTemplateSubmit={addTemplateSubmit}
                  editTemplateSubmit={editTemplateSubmit}
                  templateId={null}
                  config={config}
                  isHFT={isHFT}
                  editFlag={false}
                ></CreateTemplate>
              </div>
            </div>
          )}

          {childrenState.length === 0 && (
            <div
              style={{
                marginLeft: `${depth + 1}rem`,
                marginBottom: '0.75rem',
                marginTop: '0.25rem',
              }}
            >
              No Record{' '}
            </div>
          )}

          {childrenState.length > 0 && (
            <>
              {depth != 2 ? (
                <>
                  {' '}
                  {children.map((obj) => (
                    <TreeOption
                      title={obj.title}
                      children={obj.children}
                      depth={depth + 1}
                      found={obj.found}
                      data={obj}
                      clickAction={props['clickAction']}
                      selected={obj['selected']}
                      templateId={obj['templateId']}
                      isHFT={isHFT}
                      config={config}
                      editTemplateSubmit={editTemplateSubmit}
                      addTemplateSubmit={addTemplateSubmit}
                      isChildTabActive={activeState ? true : false}
                      showRightIcon={depth === 1 ? true : false}
                    />
                  ))}{' '}
                </>
              ) : (
                <>
                  {' '}
                  {childrenState.map((obj) => (
                    <TreeOption
                      title={obj.title}
                      children={obj.children}
                      depth={depth + 1}
                      found={obj.found}
                      data={obj}
                      clickAction={props['clickAction']}
                      selected={obj['selected']}
                      templateId={obj['templateId']}
                      isHFT={isHFT}
                      config={config}
                      editTemplateSubmit={editTemplateSubmit}
                      addTemplateSubmit={addTemplateSubmit}
                      isChildTabActive={activeState ? true : false}
                      showRightIcon={depth === 1 ? true : false}
                    />
                  ))}{' '}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TreeOption;

import React from 'react';
import './logLive.css';
import logIcon from '../../../../assets/img/logRisk.svg';
import liveIcon from '../../../../assets/img/liveRisk.svg';
import { GenButton } from '../../../../components';

const LogLivePannel = (props) => {
  const { selected = [], requestAction, time, disabled } = props;

  return (
    <div
      id="mainPanel"
      style={{ visibility: disabled ? 'collapse' : 'visible' }}
    >
      <div className="options">
        <p className="textCont">{selected.size} Selected</p>
        <p className="textCont">|</p>
        <GenButton
          type="subButton"
          icon={logIcon}
          title="Log"
          clickAction={requestAction}
          params="log"
        />
        <GenButton
          type="subButton"
          icon={liveIcon}
          title="Live"
          clickAction={requestAction}
          params="live"
          className="liveButton"
        />
        <p className="textCont">{time}</p>
      </div>
    </div>
  );
};

export default LogLivePannel;

import React, { useEffect, useState } from 'react';
import './TableAlert.scss';

import { Add, Remove } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setNewExpandId } from '../../../actions/alertAction';
//creates rows in alert table

const TreeRows = ({
  noRecordMsg,
  loader,
  TableData,
  tableCoulumn,
  row,
  groupKey,
  grouping,
  tabType,
}) => {
  const {
    alertReducer: { isExpand /* , isGroupExpand */, isExpandNew },
  } = useSelector((state) => state);

  // const [collapse, setCollapse] = useState(true);
  const [collapseError, setCollapseError] = useState(true);

  const dispatch = useDispatch();
  /* const groupExpandFunction = (data) => {
         //  setCollapse(!collapse)
 
        
         dispatch(setExpandGroupId(groupKey))
     }*/
  const expandFunction = (data) => {
    // set expand keys in redux
    dispatch(setNewExpandId(data, tabType));
  };

  useEffect(() => {
    if (isExpand.includes(groupKey)) {
      setCollapseError(false);
    }
    // if (isGroupExpand.includes(groupKey)) {

    // setCollapse(false)
    // }
  }, [groupKey]);

  const childRows = (row, iteration) => {
    let chRow = [];

    chRow.push(
      <React.Fragment>
        <div
          className="row no-gutters parent"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <>
            <div className=" " style={{ minWidth: '1.5rem' }}>
              {row?.sameError?.length ? (
                <span
                  className={'cursor-pointer'}
                  onClick={() => expandFunction(row)}
                >
                  {!isExpandNew[tabType] ||
                  (isExpandNew[tabType] &&
                    !isExpandNew[tabType][row.uniqueKey]) ? (
                    <Add />
                  ) : (
                    <Remove />
                  )}
                </span>
              ) : (
                <span></span>
              )}
            </div>
          </>
          <div className="row-right">
            {tableCoulumn.map((rowData, index) => {
              let width = 10 * iteration + '%';
              let style = index === 0 ? { display: 'flex' } : {};
              return <div className=" ">{rowData.renderer(row)}</div>;
            })}
          </div>
        </div>

        {row.sameError?.map((childRowError, indexCh) => {
          let width = 10 * iteration + '%';

          return (
            <React.Fragment>
              <div
                className=""
                style={
                  !isExpandNew[tabType] ||
                  (isExpandNew[tabType] && !isExpandNew[tabType][row.uniqueKey])
                    ? { display: 'none' }
                    : {}
                }
              >
                {childRowError.sameError?.length > 0 ? (
                  childRows(childRowError, iteration + 1)
                ) : (
                  <div
                    className="row no-gutters children"
                    style={
                      !isExpandNew[tabType] ||
                      (isExpandNew[tabType] &&
                        !isExpandNew[tabType][row.uniqueKey])
                        ? { display: 'none' }
                        : {}
                    }
                  >
                    <div className=" " style={{ minWidth: '1.5rem' }}></div>

                    <div className="row-right">
                      {tableCoulumn.map((rowData, cindex) => {
                        let style =
                          cindex === 0
                            ? { display: 'flex', width: '24px' }
                            : {};
                        return (
                          <>
                            <div className=" ">
                              {rowData.renderer(childRowError)}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
    return chRow;
  };

  return <React.Fragment>{childRows(row, 0)}</React.Fragment>;
};
export default TreeRows;

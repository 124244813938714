import { MakeRequest } from '../../../../utils';

function MasterDataReducer(
  state = {
    strategyTemplates: [],
  },
  action
) {
  switch (action.type) {
    case 'MASTER_TEMPLATE_SV_UPDATE':
      return { ...state, strategyTemplates: action.payload };
    default:
      return state;
  }
}

class MasterDataActions {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  updateTemplate(data) {
    this.dispatch({ type: 'MASTER_TEMPLATE_SV_UPDATE', payload: data });
  }

  async submitApiCall(data, type) {
    try {
      let data1;
      switch (type) {
        case 'SUBMIT':
          data1 = await MakeRequest('/strategyTemplate/submitMaster').post(
            data
          );
          if (data1?.status === 400) {
            throw new Error(
              data1?.data?.data.join(' . ') ||
                'Some Error Occured, Please Try Again'
            );
          }
          break;
        case 'SAVE':
          data1 = await MakeRequest('/strategyTemplate/draftMaster').post(data);
          break;
        case 'UPDATE':
          data1 = await MakeRequest('/strategyTemplate/editMaster').post(data);
          break;
        default:
          break;
      }

      return { state: true, data1 };
    } catch (error) {
      return { state: false, data: error.message };
    }
  }
}

export { MasterDataReducer, MasterDataActions };

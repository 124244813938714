import axios from 'axios';
import { url } from '../config/apiConfig';

export const userData = (password, tokenStr="") => async (dispatch) => {
  const token = tokenStr == "" ? localStorage.getItem('token') : tokenStr;
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/token/change-password`,
      data: password,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'CHANGE_PASS',
        payload: true,
      });
      return {
        status: result.status,
        message: result.data && result.data.data && result.data.data.message ? result.data.data.message : 'Success'
      }
    }

    return {
      status: 400,
      message: 'Server Response not received'
    }
  } catch (err) {
    dispatch({
      type: 'CHANGE_PASS',
      payload: false,
    });
    return {
      status: err.response.status,
      message: err.response && err.response.data && err.response.data.data && err.response.data.data.message ? err.response.data.data.message : 'Server Error'
    }
  }
};

export const changePasswordUserLogin = (userId = -1) => async (dispatch) => {
  dispatch({
    type: 'IS_USER_LOGGEDIN',
    payload: true,
    custId: userId,
  });
}

export const clearToken = (value) => {
  return { type: 'IS_USER_LOGGEDIN', payload: value };
};

export const RISK_PARAMETER_HEADER = [
  'SNo.1',
  'Risk Measure',
  'Currency',
  'Limit Amount',
  'Limit (%)',
  'Urgent (%)',
  'Warning (%)',
  'Alert (%)',
];
export const ROLE_DASHBOARD_HEADER = [
  'SNo.',
  'Role ID',
  'Role Name',
  'Role Description',
  'Limit Amount',
  'Last Modified',
  'Actions',
];
export const TEAM_DASHBOARD_HEADER = [
  'SNo.',
  'Team ID',
  'Team Name',
  'List of Users',
  'List of Strategies',
  'Last Modified',
  'Actions',
];
export const USER_DASHBOARD_HEADER = [
  'SNo.',
  'User ID',
  'User Name',
  'Team',
  'Role',
  'Email ID',
  'Status',
  'Last Modified',
  'Actions',
];

//Password Config
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 100;

// Navigation Menu
export const USER_MAINTENANCE_HEADER = [
  'user dashboard',
  'team dashboard',
  ' role dashboard',
];
export const RISK_CONFIGURATION_HEADER = ['risk hierarchy', 'risk parameters'];
export const RISK_MONITORING_HEADER = ['risk dashboard'];
export const TRADING_HEADER = [
  'strategy analyzer',
  'risk viewer',
  'order/trades',
  'net position',
];
export const START_TIME_EPOCH = 'StartTimeInEpochMS';
export const END_TIME_EPOCH = 'EndTimeInEpochMS';

export const MENU_STRATEGY = [
  {
    label: 'View',
    path: '/strategyView',
    type: 'sideStrategyBuilder',
  },
  {
    label: 'Create',
    path: '/strategyBuilder',
    type: 'sideStrategyBuilder',
  },
];
export const MENU_ADMIN = process.env.REACT_APP_IS_HFT == "true" ? [
  {
    label: 'User Maintenance',
    path: '/UserMaintenance',
    type: 'sideAdmin',
  },
  {
    label: 'Risk Configuration',
    path: '/riskconfig',
    type: 'sideAdmin',
  },
  {
    label: 'Risk Monitoring',
    path: '/riskmonitoring',
    type: 'sideAdmin',
  },
] : [
  {
    label: 'User Maintenance',
    path: '/UserMaintenance',
    type: 'sideAdmin',
  },
  {
    label: 'Risk Configuration',
    path: '/riskconfig',
    type: 'sideAdmin',
  },
  {
    label: 'Risk Monitoring',
    path: '/riskmonitoring',
    type: 'sideAdmin',
  },
];

export const MENU_LIVE = [
  // {
  //     label : "",
  //     path:'/LiveTrading',
  //     type: ''
  // },
];

export const PAGE_NUMBER = 8; //page number for all the tables of user maintainance

export const TOAST_MESSAGE = 'Name cannot exceed 50 characters'; //toast message for MarketWatchPagination module

export const MARKET_PAGES = 5; //total pages for MarketWatch

export const INITIAL_MARKET_PAGE = 0; //initial page for MarketWatch

export const MARKET_TOAST_MESSAGE = 'Cannot add more than 50 securities!!'; //toast message for MarketWatch module

export const TOAST_SUBMIT_BUTTON = 'All Fields are Mandatory'; //submit button of strategy builder

// Static values for UIinput in shared component
export const UI_INPUTVALUE_ONE = [69, 187, 188, 189, 109];
export const UI_INPUTVALUE_TWO = [69, 187, 188];
export const UI_INPUTVALUE_THREE = [69, 187, 188, 189, 190, 109, 110];
export const SHOW_POSTS = [5, 10, 20, 30]; //order book dropdown
export const FULL_GRID = 12;
// Static Popup messages
export const CONFIRM_COPY = 'Do you want to copy strategy:';
export const CONFRIM_DELETE = 'Are you sure you want to delete?';
export const CREATE_SUCESS = 'Created Successfully';
export const CONFRIM_AFTER_DELETE = 'Deleted Successfully';
export const UPDATE_SUCESS = 'Updated successfully';
export const CA_TA_UNAVAILABLE =
  'Clearing or trading account data is not available. These fields are mandatory for edit.';
export const SELECT_TRADING = 'Please select a trading account';
export const NO_EMPTY_FIELDS = 'Fields can not be empty';
export const ENTER_USER_ACC = 'Enter Username/Account';
export const DONE = 'Successfully Done';
export const CONFRIM_CLEAR = 'Are you sure you want to clear data?';
export const DATA_SAVED = 'Data saved successfully';
export const NAME_UPDATED = 'Name updated successfully';
export const CONFIRM_ALGO =
  'Are You Sure That You Want To Enable Select Execution Algo?';
export const PASSWORD_CHANGE_CONFIRM_HEADER = 'Password changed successfully';
export const PASSWORD_CHANGE_ERR_HEADER = 'Password change error';
export const PASSWORD_CHANGE_CONFIRM =
  'You can use the new password to log in to your account.';
export const PASSWORD_EXPIRY_HEADER = 'Password Expired';
export const PASSWORD_EXPIRY_MESSAGE =
  'Please click on button below to change your password';
export const DEALER_NEED_PASSWORD_CHANGE_HEADER =
  'Dealer require password change';
export const DEALER_NEED_PASSWORD_CHANGE_MESSAGE =
  'Please click on button below to change your password';
export const LOG_CONFIRM_HEADER = 'Moving to LOG Status';
export const LOG_CONFIRM_MESSAGE =
  'Are you Sure ? following families will be moved to LOG status';
export const LIVE_CONFIRM_HEADER = 'Moving to LIVE Status';
export const LIVE_CONFIRM_MESSAGE =
  'Are you Sure ? following families will be moved to LIVE status';
export const NOTE_EQ_DELETE = 'NOTE : All Equations Will Be Deleted';
export const DEALER_CONFRIM_AFTER_DELETE = (dealerId = 'NA') =>
  `Dealer with Id ${dealerId} deleted successfully`;
export const DEALER_CREATE_SUCCESS = 'Dealer created successfully';
export const DEALER_MODIFY_SUCCESS = 'Dealer modified successfully';
export const DEALER_DELETE_CONFIRM = 'Do you want to delete dealer';
export const BAN_CONFRIM_AFTER_DELETE = (banId = 'NA') =>
  `Ban with Id ${banId} deleted successfully`;
export const BAN_CREATE_SUCCESS = 'Ban created successfully';
export const BAN_MODIFY_SUCCESS = 'Ban modified successfully';
export const BAN_DELETE_CONFIRM = 'Do you want to delete ban';
export const UNBAN_CONFRIM_AFTER_DELETE = (unbanId = 'NA') =>
  `Unban with Id ${unbanId} deleted successfully`;
export const UNBAN_CREATE_SUCCESS = 'Unban created successfully';
export const UNBAN_MODIFY_SUCCESS = 'Unban modified successfully';
export const UNBAN_DELETE_CONFIRM = 'Do you want to delete unban';
export const TIME_OUT = 2000;
export const DATE_TIME = 86400000;
export const PAGE_TITLES = {
  createEditStrategyPage: 'Create/Edit Strategy',
  manualOrder: 'Manual Order',
  marketWatch: 'Market Watch',
  marketWatchOB: 'Market Watch Order Book',
  strategyanalyzer: 'Strategy Analyzer',
  riskviewer: 'Risk Viewer',
  orderTrades: 'Order Trades',
  orerBookDrilldown: 'Order Book',
  alertAndNotification: 'Alert And Notifications',
};
export const passwordChangeEnums = {
  NO_CHANGE_REQUIRED: 0,
  DEALER_PASSWORD_EXPIRED: 1,
  DEALER_FIRST_TIME_LOGIN: 2,
  DEALER_NEED_PASSWORD_CHANGE: 3,
};
export const popUpTypeEnum = {
  LOG_LIVE_POPUP: 1,
  INVALID: 999,
};
export const ruleOBReqTypeEnum = {
  OPEN_ORDERS: 0,
  TRADES: 1,
};
export const POSITION_TYPE = {
  APPROVED: 1,
  UNAPPROVED: 2,
  TOTAL: 3,
}
export const RISK_MONITOR_TAB_ENUMS = {
  HFT_PRE_TRADE: 0,
  HFT_POST_TRADE: 1,
}
export const fixedSecurities = {
  "NIFTY": "IN0000000002IS04" ,
  "BANKNIFTY": "IN0000000001IS04",
  "FINNIFTY": "IN0000000123IS04",
  "MIDCPNIFTY": "IN0000000160IS04",
  "INDIAVIX": "IN0000000027IS04"
};

export const preTradeHFTLimits = [
  20,40,60,80
]

export const mapInstrumentEnum = {
  'CE': 'Call_Option',
  'PE': 'Put_Option',
  'Equity': 'Equity',
  'Future': 'Future',
  'Spread': 'Spread'
}

export const ST_ATP_Screens = {
  MARKET_WATCH : "Market Watch",
  CUSTOM_MARKET_WATCH: "Custom Market Watch",
}
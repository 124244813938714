const initialState = {
  createRoleData: false,
  featureData: [],
  ID: '',
  message: '',
};
const createRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_ROLEDATA':
      return {
        ...state,
        createRoleData: action.payload,
        ID: action.ID,
      };
    case 'FEATURE_REQ':
      return {
        ...state,
        featureData: action.payload,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...state,
        message: action.clearMessage,
        ID: action.clearId,
      };

    default:
      return state;
  }
};

export default createRoleReducer;

export function bannerReducer(
  state = { msgObj: {} },
  action = { type: 1, payload: {} }
) {
  switch (action.type) {
    case 'bannerReducer-updateMessage':
      return { msgObj: { type: action.payload.type, msg: action.payload.msg } };
    case 'bannerReducer-clear':
      return { msgObj: { type: '', msg: '' } };
    default:
      return state;
  }
}

export const bannerReducerAction = {
  raiseBanner: (dispatch, msg = '', type = 'err') => {
    dispatch({
      type: 'bannerReducer-updateMessage',
      payload: { type: type, msg: msg },
    });
  },
  clearBanner: (dispatch) => {
    dispatch({
      type: 'bannerReducer-clear',
      payload: {},
    });
  },
};

const initialState = {
  strategyTable: [],
  strategyState: [],
  strategyButton: [],
  order_book_posts_per_page: 5,
  strategyTableParam: {
    pageNumber: 1,
    postPerPage: 5,
    statusList: [
      'ACTIVE',
      'PAUSED',
      'COMPLETED',
      'CLOSED',
      'PENDING_START',
      'RESUME_IN_PROCESS',
      'PAUSE_IN_PROCESS',
      'SQUARE_OFF_IN_PROCESS',
      'MODIFY_IN_PROCESS',
      'CLOSE_IN_PROCESS',
      'TRADE_MODIFY_IN_PROCESS',
      'TRADE_MODIFY_ERROR',
      'TRADE_MODIFY_SUCCESS',
      'EA_TRADE_MODIFY_SUCCESSFUL',
      'ODIN_TRADE_MODIFY_IN_PROCESS',
      'ODIN_TRADE_MODIFY_SUCCESS'
    ],
  },
  Id: '',
  strategySearch: [],
  strategyAnalyzerButtonLoader: false,
  strategyTableLoaded: true,
  sortingType: '',
  sortColumn: '',
  searchBy: '',
  searchString: '',
  customInstruments: [],
  customSymbol: [],
  customExpires: [],
  cancelingPending: '',
  customStrike: [],
  editsubmitstrategy: [],
  strategyRuleId: [],
  sqAction: [],
  selectedStrategyList: [],
  editStrategy: {
    isComplete: '',
    isApproved: '',
    name: '',
    description: '',
    exchange: '',
    securities: [
      {
        tradingAccountId: '',
        instrument: '',
        securityName: '',
        expires: '',
        strike: '',
        nickname: '',
        isAdditional: false,
        lotSize: '',
      },
    ],
    constants: [],
    entryLegs: [
      {
        legId: 1,
        condition: [
          {
            type: 'OPERAND',
            trigger: '',
          },
        ],
        position: [
          {
            orientation: 'Fwd',
            direction: 'BUY',
            security: '',
            pricing: [],
            quantity: [],
            totalQuantityLogic: [],
            orderQty: '',
            totalQty: '',
            lotSize: [],
          },
        ],
        algoExecution: [],
      },
    ],
    exitCondition: {
      condition: [
        {
          type: 'OPERAND',
          trigger: '',
        },
      ],
      action: '',
    },
  },
  manualOrderStrategy: {
    exchange: '',
    strategyId: '',
    strategyName: '',
    tradingAccountData: null,
    security: [],
  },
  manualOrderForm: {
    exchange: '',
    tradingAccountId: '',
    strategyId: '',
    instrument: '',
    symbol: '',
    expiry: '',
    strike: '',
    direction: '',
    orderType: '',
    quantity: '',
    limitPrice: '',
    stopLoss: '',
  },
  securityNameOptions: [],
  expiryOptions: [],
  strikeOptions: [],
  directionList: [
    {
      key: 'BUY',
      name: 'BUY',
    },
    {
      key: 'SELL',
      name: 'SELL',
    },
  ],
  orderTypeList: [
    {
      key: 'Limit',
      name: 'LIMIT',
    },
    {
      key: 'Market',
      name: 'MARKET',
    },
  ],
};

const strategyAnalyzerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STRATEGY_TABLE':
      return {
        ...state,
        strategyTable: action.payload,
      };
    case 'STRATEGY_TABLE_LOADED':
      return {
        ...state,
        strategyTableLoaded: action.payload,
      };
    //strategy analyzer status
    case 'STRATEGY_STATE':
      let stratArr = [...state.strategyState];
      if (
        !stratArr.includes(
          (el) =>
            el.statusId !== action.payload.statusId &&
            el.status !== action.payload.status
        )
      ) {
        stratArr.push(action.payload);
      }
      return {
        ...state,
        strategyState: stratArr,
      };
    //strategy analyzer buttons
    case 'STRATEGY_BUTTON':
      return {
        ...state,
        strategyButton: action.payload,
        Id: action.strategyId,
      };
    //STRATEGY_ANALYZER_BUTTON_LOADER
    case 'STRATEGY_ANALYZER_BUTTON_LOADER':
      return {
        ...state,
        strategyAnalyzerButtonLoader: action.payload,
      };
    //strategy analyzer search
    case 'STRATEGY_SEARCH':
      return {
        ...state,
        strategySearch: action.payload,
      };
    case 'UPDATE_SORT_COLUMN_ANALYZER':
      return {
        ...state,
        sortingType: action.payload.sortingType,
        sortColumn: action.payload.sortColumn,
      };
    case 'UPDATE_POST_PER_PAGE_SA':
      const temp = {
        ...state,
        strategyTableParam: {
          ...state.strategyTableParam,
          postPerPage: action.payload,
        },
      };
      console.log('CHANGE ', action.payload);
      return temp;
    case 'UPDATE_POST_PER_PAGE_OB':
      return { ...state, order_book_posts_per_page: action.payload };
    case 'STRATEGY_SEARCHBY_SEARCHSTRING':
      return {
        ...state,
        searchBy: action.payload.field,
        searchString: action.payload.value,
      };
    //strategy analyzer edit
    case 'STRATEGY_EDIT':
      return {
        ...state,
        editStrategy: action.payload,
        Id: action.ID,
      };
    // strategy analyzer manual order
    case 'STRATEGY_MANUAL_ORDER':
      return {
        ...state,
        manualOrderStrategy: action.payload,
        customInstruments: action.instruments,
      };
    case 'UPDATE_MANUAL_ORDER_FORM':
      return {
        ...state,
        manualOrderForm: action.payload,
      };
    case 'GET_MO_STRATEGY_RULE_ID':
      return {
        ...state,
        strategyRuleId: action.payload,
      };
    case 'GET_MO_SECURITY_NAME':
      return {
        ...state,
        securityNameOptions: action.payload,
      };
    case 'GET_MO_EXPIRES':
      return {
        ...state,
        expiryOptions: action.payload,
      };
    case 'GET_MO_STRIKES':
      return {
        ...state,
        strikeOptions: action.payload,
      };
    case 'UPDATE_PAGENUMBER':
      state.strategyTableParam.pageNumber = action.payload;
      return {
        ...state,
      };
    case 'UPDATE_SEARCHBY':
      return {
        ...state,
        searchBy: action.payload,
      };
    case 'UPDATE_SEARCH_STRING':
      return {
        ...state,
        searchString: action.payload,
      };
    case 'EDIT_STRATEGY':
      return {
        ...state,
        editsubmitstrategy: action.payload,
        ID: action.ID,
      };
    case 'GET_ORDER':
      return {
        ...state,
        orderTradeData: action.payload,
      };
    case 'RESET_MANUAL_ORDER':
      return {
        ...state,
        manualOrderForm: action.payload,
      };
    case 'UPDATE_STATUS_LIST':
      state.strategyTableParam.statusList = action.payload;
      return {
        ...state,
      };
    case 'GET_SQUARE_OFF':
      return {
        ...state,
        sqAction: action.payload,
      };
    case 'SQ_DETAILS':
      return {
        ...state,
        selectedStrategyList: action.payload,
      };
    case 'CREATE_SYMBOL_DROPDOWN':
      return {
        ...state,
        customSymbol: action.payload,
      };
    case 'CREATE_EXPIRY_DROPDOWN':
      return {
        ...state,
        customExpires: action.payload,
      };
    case 'CREATE_STRIKE_DROPDOWN':
      return {
        ...state,
        customStrike: action.payload,
      };
    case 'CANCEL_ALL_PENDING':
      return {
        ...state,
        cancelingPending: action.payload,
      };
    default:
      return state;
  }
};

export default strategyAnalyzerReducer;

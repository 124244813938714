import React from 'react';
import './styles.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const GenericPopup = ({
  state = false,
  title,
  message,
  toggleState,
  type,
  sizeDialog = 'xl',
  children,
}) => {
  function contentGen() {
    if (type === 1) {
      return (
        <Dialog
          onClose={toggleState}
          open={state}
          maxWidth={sizeDialog}
          scroll="paper"
        >
          {children}
        </Dialog>
      );
    } else {
      return (
        <div>
          <Modal
            open={state}
            onClose={toggleState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {message}
              </Typography>
            </Box>
          </Modal>
        </div>
      );
    }
  }

  return contentGen();
};

export default GenericPopup;

import React, { useState } from 'react';
import '../../../viewScreen/loginScreens/loginScreens.scss';
import '../../loginScreen/LoginStyle.scss';
import './ChangePassword.scss';
import UiInput from '../../sharedComponents/Input';
import UiButton from '../../sharedComponents/Button';
import { Validation } from '../../../validations/Validations';
import { useDispatch } from 'react-redux';
import { userData, clearToken } from '../../../actions/changePasswordAction';
import { CloseButton } from 'react-bootstrap';

export const ChangePassword = ({ setPassModal, setShowPopUp, setPopup }) => {
  const [redir, setRedir] = useState(false);
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  // set data form the fields
  const inputHandler = (e) => {
    setError({ ...error, [e.target.name]: '' });
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };
  // check validation in change password fields
  const handleValidation = () => {
    let error = {};
    if (Validation.empty(data.oldPassword)) {
      error = { ...error, oldPassword: 'Please enter old password' };
    }
    if (Validation.empty(data.newPassword)) {
      error = {
        ...error,
        newPassword: 'Please enter your New Password',
      };
    } else if (!Validation.password(data.newPassword)) {
      error = {
        ...error,
        newPassword:
          'Min 8 characters, 1 uppercase & 1 lowercase, 1 number & 1 special character ',
      };
    } else if (data.newPassword === data.oldPassword && false) {
      error = {
        ...error,
        newPassword: 'New password can not be same as old password',
      };
    }
    if (data.newPassword !== data.confirmPassword) {
      error = {
        ...error,
        confirmPassword: 'Password does not match',
      };
    }
    if (Object.keys(error).length) {
      setError(error);
      return false;
    }
    setError();
    return true;
  };
  const handleSubmit = async (e) => {
    let isValid = handleValidation();
    if (isValid) {
      const finalData = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      const result = await dispatch(userData(finalData));
      if (result.status == 200) {
        setPopup({ type: 'changePassword' });
        setShowPopUp(true);
        settingPass();
      } else {
        setError({ ...error, newPassword: result.message });
      }
    }
  };

  const settingPass = () => {
    setPassModal(false);
  };
  return (
    <div className="logout-wrapper">
      <div className="modal-body">
        <CloseButton aria-label="Hide" onClick={() => settingPass()} />
        <h1>Change Password</h1>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Old Password</label>
          <UiInput
            type="password"
            name="oldPassword"
            className="form-control"
            handlerfunction={inputHandler}
          />
          {error && <span className="error-tag">{error.oldPassword}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">New Password</label>
          <UiInput
            type="password"
            name="newPassword"
            className="form-control"
            handlerfunction={inputHandler}
          />
          {error && <span className="error-tag">{error.newPassword}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Confirm Password</label>
          <UiInput
            type="password"
            name="confirmPassword"
            className="form-control"
            handlerfunction={inputHandler}
          />
          {error && <span className="error-tag">{error.confirmPassword}</span>}
        </div>
        <UiButton
          type="submit"
          className="btn   login "
          onClickFunction={handleSubmit}
          name="Submit"
        />
      </div>
    </div>
  );
};

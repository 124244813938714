import React from 'react';
import { useDispatch } from 'react-redux';
import close from '../../../../assets/img/cancel.png';
import Pause from '../../../../assets/img/Group 163012.svg';
import Play from '../../../../assets/img/Group 163013.svg';
import Switch from '../../../../assets/img/Group 163017.svg';
import Close from '../../../../assets/img/Group 163016.svg';
import './CheckBoxBar.scss';
import { strategyAnalyzerButton } from '../../../../actions/strategyAnalyzerAction';
import { configBools, status } from '../../../../config/apiConfig';
import { ErrorBoundary } from '../../../../components';

const CheckBoxBar = ({
  selectedId,
  setSelectedId,
  setPausePop,
  setSelectedSqOff,
  setButtonReq,
  selectBoxRef,
  setErrorMsg,
  setPopup,
}) => {
  const dispatch = useDispatch();

  //close bar on click of cross icon
  const clearFunction = () => {
    setSelectedId([]);
    setSelectedSqOff([]);
    localStorage.removeItem('forceSquareOff');
  };
  // function for buttons in CheckBoxBar when click on checkboxes
  const handleButtonClick = async (data) => {
    if (data.buttonType === 'SQOFF') {
      let newWindow = window.open(
        `/window/squareOff`,
        '_blank',
        'top=100, left=100, width=800, height=325, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
      );
    } else {
      let result = await dispatch(strategyAnalyzerButton(data));
      if (typeof Object.values(result)[0] == 'string') {
        setErrorMsg(Object.values(result)[0]);
      }
      if (
        data.buttonType === 'PAUSE' ||
        data.buttonType === 'RESUME' ||
        data.buttonType === 'CLOSE'
      ) {
        setPausePop(true);
        setPopup();
        setButtonReq(data.buttonType.toLowerCase());
      }
      setSelectedId([]);
      setSelectedSqOff([]);
    }
  };

  return (
    <ErrorBoundary>
      <div ref={selectBoxRef}>
        <div className="select-checkbox">
          <ul className="unslect-bar">
            <li className="n-ed">{selectedId.length} Selected</li>
            <li
              onClick={() =>
                handleButtonClick({
                  buttonType: 'PAUSE',
                  strategyList: selectedId,
                })
              }
            >
              <button className="status-button">
                <img src={Pause} alt="img" />
              </button>
            </li>
            <li
              onClick={() =>
                handleButtonClick({
                  buttonType: 'RESUME',
                  strategyList: selectedId,
                })
              }
            >
              <button className="status-button">
                <img src={Play} alt="img" />
              </button>
            </li>
            {configBools.enableSquareOffInAnalyzerTable ? (
              <li
                onClick={() =>
                  handleButtonClick({
                    buttonType: 'SQOFF',
                    strategyList: selectedId,
                  })
                }
              >
                <button className="sq-button">
                  <img src={Switch} className="sqrOff-image" alt="img" />
                </button>
              </li>
            ) : null}
            <li
              onClick={() =>
                handleButtonClick({
                  buttonType: 'CLOSE',
                  strategyList: selectedId,
                })
              }
            >
              <button className="sq-button">
                <img src={Close} className="sqrOff-image" alt="img" />
              </button>
            </li>
            <li className="close-right" onClick={() => clearFunction()}>
              <img src={close} className="cancel-img" alt="img" />
            </li>
          </ul>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default CheckBoxBar;

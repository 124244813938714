import './styles.css';
import ScreenPicker from '../screenPicket';
import { useEffect, useMemo, useState } from 'react';

export default function ScreenComp(props) {
  const { positioning, updatePosition, editState, removeItem } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [size, setSize] = useState({
    width: positioning.w,
    height: positioning.h,
  });
  const [position, setPosition] = useState({
    x: positioning.x,
    y: positioning.y,
  });
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  const onMouseDown = (e) => {
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
    setIsDragging(true);
    e.stopPropagation(); // Prevents the event from bubbling up and potentially starting a resize
  };

  const onResizeMouseDown = (e) => {
    setStartPos({
      x: e.clientX,
      y: e.clientY,
    });
    setIsResizing(true);
    e.stopPropagation(); // This prevents the drag action from being triggered
  };

  const onMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - startPos.x,
        y: e.clientY - startPos.y,
      });
    } else if (isResizing) {
      const newWidth = size.width + (e.clientX - startPos.x);
      const newHeight = size.height + (e.clientY - startPos.y);
      setSize({ width: newWidth, height: newHeight });
      setStartPos({ x: e.clientX, y: e.clientY }); // Update start position for continuous resizing
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  useEffect(() => {
    if (!size || !position) {
      return;
    }
    updatePosition({
      ...positioning,
      x: position.x,
      y: position.y,
      h: size.height,
      w: size.width,
    });
  }, [size, position]);

  useMemo(() => {
    setPosition({ x: positioning.x, y: positioning.y });
    setSize({
      width: positioning.w,
      height: positioning.h,
    });
  }, [positioning.x, positioning.y, positioning.w, positioning.h]);

  return (
    <div
      style={{
        position: 'absolute',
        top: `${position.y}px`,
        left: `${position.x}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
      }}
      className="screenComp-container"
    >
      {editState && (
        <div className="ScreenComp-buttongroup">
          <div
            className="screenComp-editIconReArrange ScreenComp-rearrange"
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onMouseDown={onMouseDown}
          ></div>
          <div
            className="screenComp-editIconReArrange ScreenComp-resize"
            onMouseDown={onResizeMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          ></div>
          <div
            className="screenComp-editIconReArrange ScreenComp-close"
            onClick={() => removeItem()}
          ></div>
        </div>
      )}

      {positioning.screen.length === 0 && (
        <ScreenPicker
          val={positioning.screen}
          setVal={(val) => updatePosition({ ...positioning, screen: val })}
        />
      )}
      <ScreenLoader screenId={positioning.screen} />
    </div>
  );
}

function ScreenLoader({ screenId }) {
  const pathState = {
    'Risk Monitoring': '/multiWindow/riskmonitoring',
    Alert: '/multiWindow/alert',
    'Risk Configuration': '/multiWindow/riskconfig',
  };

  return (
    <iframe
      src={`${window.location.href.split('/multiScreen')[0]}${
        pathState[screenId]
      }`}
      title="Embedded Website"
      style={{
        width: screenId ? '100%' : '0%',
        height: screenId ? '100%' : '0%',
        border: 'none',
      }}
    />
  );
}

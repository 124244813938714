import axios from 'axios';
import { url } from '../../config/apiConfig';

const client = axios.create({ baseURL: url, json: true });

export default {
  async execute(method, url, data) {
    let token = localStorage.getItem('token');
    return client({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async executeBlob(method, url, data) {
    let token = localStorage.getItem('token');
    return client({
      method,
      url,
      data,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getBlob(url) {
    return this.execute('get', url);
  },
  post(url, data) {
    return this.execute('post', url, data);
  },
};

const initialState = {
    createUserData: false,
  Id:''
  }
  const editReducer = (state = initialState, action) => {
    switch (action.type) {
      case "EDIT_DATA":
        return {
          ...state,
          createUserData: action.payload,
          Id:action.userId
        }
        case "CLEAR_EDIT":
          return {
          ...state,
          Id:action.clearMessage
          }
      
      default:
        return state
    }
  }
  
  export default editReducer
  
import React, { useEffect, useState } from 'react';
import './MarketWatchPagination.scss';
import UiInput from '../../../sharedComponents/Input';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSnackbar from '../../../sharedComponents/Toast/Toast';
import {TOAST_MESSAGE} from '../../../../utils/constant'
const MarketWatchPagination = ({
  pages,
  currentPage,
  setCurrentPage,
  updateName,
  setPrevPage,
  getName,
}) => {
  let prev = currentPage;
  const {
    liveTradingReducer: { pagename },
  } = useSelector((state) => state);
  const [pageTitle, setPagename] = useState(pagename);
  const dispatch = useDispatch();
  const [toaster, setToaster] = useState(false);
  const [disable, setDisable] = useState(true);
 
  useEffect(() => {
    setPagename(pagename);
  }, [pagename]);
  const handleInputValue = (e) => {  //funtion for page title
    let pageTitle = e.target.value.substring(0, 50);
    if (pageTitle.length >= 50) {
      setToaster(true);
    }
    setPagename(pageTitle);
  };
  const onblurFuntion = () => { // when focus moves out of input 
    if (pageTitle.length > 0) {
      dispatch(
        updateName({
          pageNumber: currentPage,
          pageName: pageTitle,
        })
      );
    }
  };

  return (
    <div className="market-pagination">
      <div className="updateName">
        <UiInput  //shared input component
          type="text"
          col="sm"
          className="input-btn "
          value={pageTitle}
          handlerfunction={handleInputValue}
          onblurFuntion={onblurFuntion}
          disabled={disable}
        />
        <span>
          <i
            className="fa fa-pencil"
            aria-hidden="true"
            onClick={() => setDisable(false)}
          ></i>
        </span>
       
      </div>
      {(() => {
        let pageSection = [];
        for (let i = 1; i <= pages; i++) {
          pageSection.push(
            <span
              key={i}
              className={`page-no ${
                i === currentPage ? 'active-page' : 'inactive-page'
              }`}
              onClick={() => {
                if (currentPage !== i) {
                  setPrevPage(prev);
                }
                dispatch(getName(i));
                setCurrentPage(i);
              }}
            >
              {i}
            </span>
          );
        }
        return <>{pageSection}</>;
      })()}
      {toaster ? (
        <SimpleSnackbar
          toaster={toaster}
          setToaster={setToaster}
          message={TOAST_MESSAGE}
        />
      ) : null}
    </div>
  );
};

export default MarketWatchPagination;

import './genTableGenerator.css';
import { AscIcon, DesIcon, NeuIcon } from '../../../assets';
import { TableRow, Table } from './tableStyledComps';
import Pagination from '@mui/material/Pagination';

export default function TableGenerator(props) {
  const {
    heads = [],
    content = [],
    isLTSc = false,
    striped = true,
    extStyles = {},
    pagesInfo,
    pageChange,
    sortObject = { enabledColumns: [] },
    toggledColumn = () => {},
    className = 'tableContainer',
    showNoOfEntries = false,
    totalEntries = 6,
    entriesPerPage = 6,
    version = 1,
    tableRef = null,
    paginationInfo = { total: 10, current: 2 },
    onPageChange = () => {},
  } = props;

  function loadHead(obj, index) {
    if (
      sortObject &&
      sortObject.enabledColumns &&
      sortObject.enabledColumns[index] &&
      sortObject.enabledColumns[index].state
    ) {
      return (
        <span className="tableGen-cell">
          <span className="TableGenerator-headText">{obj}</span>
          {sortObject &&
            sortObject.enabledColumns &&
            sortObject.enabledColumns[index] &&
            sortObject.enabledColumns[index].state && (
              <img
                src={
                  sortObject.enabledColumns[index].order === 'n'
                    ? NeuIcon
                    : sortObject.enabledColumns[index].order === 'a'
                    ? AscIcon
                    : DesIcon
                }
                className="GenTable-filIcon"
                onClick={() =>
                  toggledColumn(index, sortObject.enabledColumns[index].order)
                }
                alt="table"
              />
            )}
        </span>
      );
    } else {
      return <span className="TableGenerator-headText">{obj}</span>;
    }
  }

  function generateHeaders(arrOfHeads) {
    switch (version) {
      case 2:
        return arrOfHeads.map((obj, index) => obj);
      default:
        return arrOfHeads.map((obj, index) => (
          <th className="TableGenerator-th font-l1" style={{ width: '20vw' }}>
            {loadHead(obj, index)}
          </th>
        ));
    }
  }

  function generateBottomPages() {
    switch (version) {
      case 1:
        const arr = [];
        if (showNoOfEntries) {
          let firstEntryNumber = entriesPerPage * (pagesInfo.current - 1) + 1;
          arr.push(
            <p>
              Showing {firstEntryNumber} to{' '}
              {firstEntryNumber + content.length - 1} of {totalEntries} entries
            </p>
          );
        }
        for (let i = 1; i < pagesInfo.total + 1; i++) {
          arr.push(
            <p
              className={`tablenGen-currPage-${
                i === pagesInfo.current ? 'act' : 'ina'
              }`}
              onClick={() => pageChange(i)}
            >
              {i}
            </p>
          );
        }
        return arr.map((item) => item);
      case 2:
        return (
          <Pagination
            count={paginationInfo.total}
            page={paginationInfo?.current}
            variant="outlined"
            shape="rounded"
            onChange={(e, page) => onPageChange(page)}
          />
        );
      default:
        break;
    }
  }

  function loadTable() {
    switch (version) {
      case 2:
        return (
          <div style={{ ...extStyles, overflowY: 'auto' }} ref={tableRef}>
            <div className="gen-table-table-cont">
              <table style={{ width: '100%' }}>
                <thead style={{ position: 'sticky', top: '0' }}>
                  {heads.map((item) => item)}
                </thead>
                <tbody style={{ height: '30vh', overflowY: 'scroll' }}>
                  {content.map((item) => item)}
                </tbody>
              </table>
            </div>
            <div className="gen-table-pagination-container">
              {generateBottomPages()}
            </div>
          </div>
        );

      default:
        return (
          <div className={className} style={extStyles}>
            <Table>
              <thead>
                <TableRow>{generateHeaders(heads)}</TableRow>
              </thead>
              <tbody>{content}</tbody>
            </Table>
            <div
              className={`genTable-pager-cont ${
                showNoOfEntries ? 'genTable-pager-cont-extra' : ''
              }`}
            >
              {pagesInfo && generateBottomPages()}
            </div>
          </div>
        );
    }
  }

  return loadTable();
}

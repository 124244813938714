import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Row } from 'react-bootstrap';
import UiSelector from '../../sharedComponents/Dropdowns';
import UiInput from '../../sharedComponents/Input';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import './ManualOrder.scss';
import { strategyInstrumenType } from '../../../actions/strategyBuilderAction';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import { useLocation, useParams } from 'react-router-dom';
import {
  getUserExchangeAccount,
  getManualOrderData,
  getMarketSecurity,
} from '../../../actions/LiveTradingAction';
import Popup from '../../sharedComponents/Popup/Popup';
import { makeStyles } from '@material-ui/core/styles';
import SimpleReactValidator from 'simple-react-validator';
import {
  createCustomSecurities,
  editManualOrder,
  getManualOrderExpiresOptions,
  getManualOrderSecurityNameOptions,
  getManualOrderStrikesOptions,
  getStrategyRuleId,
  resetManualOrder,
  setManualOrderForm,
  strategyAnalyzerManualOrder,
  submitManualOrder,
} from '../../../actions/strategyAnalyzerAction';
import { seo } from '../../../utils/seo';
import { CONFRIM_CLEAR, PAGE_TITLES } from '../../../utils/constant';
import _ from 'lodash';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../../sharedComponents/keyPressEventHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: "path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z')",
        },
      },
    },
  },
}));

const ManualOrder = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const custId = localStorage.getItem('customerId');
  const { strategyId: paramStrategyId, id: paramId } = useParams();

  let strategyId = window.atob(paramStrategyId ? paramStrategyId : '');
  let orderId = window.atob(paramId ? paramId : '');

  const {
    strategyBuilderReducer: { instrumentOptions },
    liveTradingReducer: { accntName },
    strategyAnalyzerReducer: {
      manualOrderForm,
      securityNameOptions,
      expiryOptions,
      strikeOptions,
      customInstruments,
      directionList,
      orderTypeList,
      customSymbol,
      customExpires,
      customStrike,
      strategyRuleId,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [validateToasetr, setValidateToasetr] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [toasterMsg, setToasterMsg] = useState('');
  const [modalSubmitStrategy, setModalSubmitStrategy] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [message, setMessage] = useState('');
  const [formSubmit, setFormSubmit] = useState(false);
  const [modalClear, setModalClear] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState();
  const queryParams = new URLSearchParams(location.search);
  let directionValue = window.atob(
    queryParams.get('direction') ? queryParams.get('direction') : ''
  );
  let securityValue = window.atob(
    queryParams.get('security') ? queryParams.get('security') : ''
  );

  useEffect(() => {
    let security = [];
    if (directionValue) {
      let form = { ...manualOrderForm };
      form.direction = directionValue;
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: form,
      });
    }
    if (directionValue && securityValue) {
      security = securityValue.split('-');
      dispatch(getMarketSecurity(security[1]));
    }
    if (orderId) {
      dispatch(getManualOrderData(orderId));
      seo({ title: 'Edit Manual Order' });
    }
  }, [location]);

  useEffect(() => {
    dispatch(strategyInstrumenType());
    if (strategyId) {
      dispatch(strategyAnalyzerManualOrder(strategyId));
      seo({ title: 'Create Manual Order' });
    }
  }, [strategyId]);

  useEffect(() => {
    if (
      manualOrderForm.exchange !== '' &&
      manualOrderForm.tradingAccountId !== '' &&
      manualOrderForm.instrument &&
      !orderId &&
      !securityValue
    ) {
      dispatch(getManualOrderSecurityNameOptions(manualOrderForm.instrument));
    }
  }, [manualOrderForm.tradingAccountId, manualOrderForm.exchange]);

  useEffect(() => {
    dispatch(getUserExchangeAccount(custId));
    if (!orderId) {
      seo({ title: 'Create Manual Order' });
    }
  }, [custId]);

  // set default popups after modal
  useEffect(() => {
    switch (popup.type) {
      case 'submitStrategy':
        setPopup({
          ...popup,
          message: message.message,
          buttons: 'singleButton',
        });
        break;
      case 'clear':
        setPopup({
          ...popup,
          message: CONFRIM_CLEAR,
          buttons: 'doubleButtons',
          nameAccept: 'Confirm',
          nameDecline: 'Cancel',
        });
        break;
      default:
        break;
    }
  }, [popup.type, message]);

  // set functionalty for popup
  const actionOnpopup = (e, type) => {
    if (type == 'CLOSE') {
      setPopup({ state: false });
    } else {
      if (popup.type == 'clear') {
        handleClear(true);
      }
      setPopup({ state: false });
    }
  };
  //funtion for clear
  const handleClear = (clear) => {
    setModalClear(false);
    let clearForm = {};
    if (clear) {
      if (directionValue && securityValue) {
        clearForm = {
          ...manualOrderForm,
          tradingAccountId: '',
          strategyId: '',
          instrument: '',
          symbol: '',
          expiry: '',
          strike: '',
          orderType: '',
          quantity: '',
          limitPrice: '',
          stopLoss: '',
        };
        dispatch({
          type: 'GET_MO_STRATEGY_RULE_ID',
          payload: [],
        });
      } else if (strategyId) {
        clearForm = {
          ...manualOrderForm,
          instrument: '',
          symbol: '',
          expiry: '',
          strike: '',
          direction: '',
          orderType: '',
          quantity: '',
          limitPrice: '',
          stopLoss: '',
        };
      } else {
        clearForm = {
          exchange: '',
          tradingAccountId: '',
          strategyId: '',
          instrument: '',
          symbol: '',
          expiry: '',
          strike: '',
          direction: '',
          orderType: '',
          quantity: '',
          limitPrice: '',
          stopLoss: '',
        };
        dispatch({
          type: 'GET_MO_STRATEGY_RULE_ID',
          payload: [],
        });
      }
      dispatch(resetManualOrder(clearForm));
      setFormSubmit(false);
    }
  };

  const inputHandlerExchange = (e) => {
    //funtion to accept the exchange values
    let form = { ...manualOrderForm };
    form.exchange = e.target.value;
    form['strategyId'] = '';
    dispatch({
      type: 'GET_MO_STRATEGY_RULE_ID',
      payload: [],
    });
    dispatch(setManualOrderForm(form));
  };

  const inputHandlerAccount = (e) => {
    //funtion to accept the account values
    let form = { ...manualOrderForm };
    form['tradingAccountId'] = e.target.value;
    form['strategyId'] = '';
    dispatch({
      type: 'GET_MO_STRATEGY_RULE_ID',
      payload: [],
    });

    dispatch(getStrategyRuleId(e.target.value));
    dispatch(setManualOrderForm(form));
  };

  const inputHandlerStrategyId = (e) => {
    //funtion to accept the strategy id values
    let form = { ...manualOrderForm };
    form.strategyId = e.target.value;

    let tradingAccountId = -1;
    for (let info of strategyRuleId) {
      if (info.key == form.strategyId) {
        tradingAccountId = info.accountId;
        break;
      }
    }
    if (tradingAccountId != -1) form.tradingAccountId = String(tradingAccountId);
    dispatch(setManualOrderForm(form));
  };

  function inputHandler(e) {
    //funtion to accept the instrument, expiry, strike, direction, ordertype, quantity, limit price, stoploss one after the other values
    let form = { ...manualOrderForm };
    switch (e.target.name) {
      case 'instrument':
        let instrument = e.target.value;
        dispatch(getManualOrderSecurityNameOptions(instrument));
        dispatch(
          createCustomSecurities(e.target.name.toUpperCase(), instrument)
        );
        break;

      case 'expiry':
        let expiry = e.target.value;
        if (strategyId) {
          dispatch(createCustomSecurities(e.target.name.toUpperCase(), expiry));
        } else {
          dispatch(getManualOrderStrikesOptions({ expiry: expiry }));
        }
        break;

      case 'strike':
        form.strike = e.target.value;
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: form,
        });
        break;

      case 'direction':
        form.direction = e.target.value;
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: form,
        });
        break;

      case 'orderType':
        form.orderType = e.target.value;
        if (e.target.value === 'MARKET') {
          form.limitPrice = '';
        }
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: form,
        });
        break;

      case 'quantity':
        form.quantity = e.target.value.replace(/[^0-9]+/g, '');
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: form,
        });
        break;

      case 'limitPrice':
        form.limitPrice = e.target.value.replace(/[^0-9.]+/g, '');
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: form,
        });
        break;

      case 'stopLoss':
        form.stopLoss = e.target.value.replace(/[^0-9.]+/g, '');
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: form,
        });
        break;
      default:
        break;
    }
  }

  const submitForm = () => {
    //funtion to submit the form using validations
    setFormSubmit(true);
    if (validator.current.allValid()) {
      setValidateToasetr(false);
      handleSubmitStrategy();
    } else {
      if (
        validator.current.errorMessages.quantity &&
        manualOrderForm.quantity !== ''
      ) {
        setToasterMsg('Quantity should be more than 0');
      } else if (
        validator.current.errorMessages.limitPrice &&
        manualOrderForm.quantity !== ''
      ) {
        setToasterMsg('Negative values are not allowed');
      } else if (
        validator.current.errorMessages.stopLoss &&
        manualOrderForm.stopLoss !== ''
      ) {
        setToasterMsg('Negative values are not allowed');
      }
      setValidateToasetr(true);
      validator.current.showMessages();
      forceUpdate(1);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmitStrategy = async () => {
    let error = {};
    let maualMessage = null;
    if (Object.keys(error).length) {
      setToaster(true);
    } else {
      setToaster(false);
      if (orderId) {
        maualMessage = await dispatch(editManualOrder(orderId));
        if (maualMessage && maualMessage.status == 200) {
          setMessage(
            maualMessage.message ? maualMessage.message : 'Server Error'
          );
        } else {
          setMessage({
            message: maualMessage.message
              ? maualMessage.message
              : 'Server Error',
          });
        }
      } else {
        maualMessage = await dispatch(submitManualOrder());
        setMessage(maualMessage);
      }
      setFormSubmit(false);
      setModalSubmitStrategy(true);
      setPopup({ type: 'submitStrategy', state: true });
    }
  };

  const selectSearch = (inputValue) => {
    let symbol = inputValue;
    if (strategyId) {
      let form = { ...manualOrderForm };
      form.symbol = symbol;
      form.expiry = '';
      form.strike = '';
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: form,
      });
      dispatch(createCustomSecurities('SYMBOL', symbol));
    } else {
      dispatch(getManualOrderExpiresOptions(symbol));
    }
  };

  const clearFunc = () => {
    setPopup({ type: 'clear', state: true });
  };

  let filterClearingAccount = manualOrderForm.exchange
    ? accntName?.clearingAccountDetails
        ?.filter((value) => value.exchange === manualOrderForm.exchange.trim())
        ?.map((value) => value.clearingAccountId)
    : accntName?.clearingAccountDetails;
  let filterTradingAccount = accntName ? accntName.tradingAccountDetails : [];
  filterTradingAccount = filterTradingAccount?.filter(
    (values) => filterClearingAccount.indexOf(values.clearingAccountId) !== -1
  );
  validator.current.purgeFields();

  return (
    <div className="previewModalRootWrpaer manual-order some-random-class">
      <Hotkeys
        keyName="esc"
        onKeyDown={(event) => {
          handleEscapeKeyEvent(event);
        }}
      />
      <Helmet>
        <title>{PAGE_TITLES.manualOrder}</title>
      </Helmet>
      <div className="previewmodal">
        <div
          className="templateNameWrapper"
          id={
            manualOrderForm.direction === 'BUY'
              ? 'manual_ord_buy'
              : manualOrderForm.direction === 'SELL'
              ? 'manual_ord_sell'
              : ''
          }
        >
          <React.Fragment className="strtegy-exchange-container">
            <Row className="spaceContent">
              <Col sm="4">
                <div className="field">
                  <label>Exchange</label>
                  <UiSelector //shared component dropdown
                    single
                    role="exchange"
                    id="manual_ord_exchnage"
                    value={manualOrderForm.exchange.trim()}
                    listData={_.uniqBy(
                      accntName.clearingAccountDetails,
                      'exchange'
                    )?.map((el) => {
                      return {
                        id: el.exchange,
                        label: el.exchange,
                      };
                    })}
                    handlerFunction={inputHandlerExchange}
                    className={
                      validator.current.message(
                        //validation
                        'exchange',
                        manualOrderForm.exchange,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : strategyId ||
                          (directionValue && securityValue) ||
                          orderId
                        ? 'disable-pointers disable-color'
                        : ''
                    }
                    disable={strategyId || orderId}
                  />
                </div>
              </Col>
              <Col sm="4">
                <div className="field">
                  {' '}
                  <label>Account</label>
                  <UiSelector //shared component dropdown
                    single
                    role="account"
                    id="manual_ord_account"
                    value={manualOrderForm?.tradingAccountId}
                    listData={filterTradingAccount?.map((el) => {
                      return {
                        id: el.tradingAccountId,
                        label: el.accountName,
                      };
                    })}
                    handlerFunction={inputHandlerAccount}
                    className={
                      validator.current.message(
                        'account',
                        manualOrderForm.tradingAccountId,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : strategyId || orderId
                        ? 'disable-pointers'
                        : ''
                    }
                    disable={strategyId || orderId}
                  />
                </div>
              </Col>
              <Col sm="4">
                <div className="field">
                  {' '}
                  <label> Strategy Id</label>
                  <UiSelector //shared component dropdown
                    single
                    role="strategyId"
                    id="manual_ord_strategyId"
                    value={manualOrderForm?.strategyId}
                    listData={strategyRuleId?.map((el) => {
                      return {
                        id: el.key,
                        label: el.name,
                      };
                    })}
                    handlerFunction={inputHandlerStrategyId}
                    className={
                      validator.current.message(
                        'strategyId',
                        manualOrderForm.strategyId,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : strategyId || orderId
                        ? 'disable-pointers'
                        : ''
                    }
                    disable={strategyId || orderId}
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </div>

        <div className="middleWrapper">
          <div className="row-container-manual">
            <Row>
              <Col sm="2">
                <div className="manual-order-heading">Instrument</div>
                <div>
                  <>
                    <UiSelector //shared component dropdown
                      single
                      role="instrument"
                      id="manual_order_instrument"
                      disable={
                        orderId
                          ? true
                          : manualOrderForm.tradingAccountId &&
                            manualOrderForm.exchange
                          ? false
                          : true
                      }
                      value={manualOrderForm.instrument}
                      className={
                        validator.current.message(
                          'instrument',
                          manualOrderForm.instrument,
                          'required',
                          {
                            className: 'error_message',
                          }
                        ) && formSubmit
                          ? 'error-input'
                          : ''
                      }
                      listData={(strategyId
                        ? customInstruments
                        : instrumentOptions && instrumentOptions
                      )?.map((securities) => {
                        return {
                          id: securities.key,
                          label: securities.name,
                        };
                      })}
                      handlerFunction={inputHandler.bind({
                        manualOrderForm,
                      })}
                    />
                  </>
                </div>
              </Col>

              <Col sm="2">
                <div className="manual-order-heading">Symbol</div>
                <div>
                  <>
                    <Autocomplete //autocomplete react component for search and select
                      name="symbol"
                      className={
                        validator.current.message(
                          'symbol',
                          manualOrderForm.symbol,
                          'required',
                          {
                            className: 'error_message',
                          }
                        ) && formSubmit
                          ? 'error-input'
                          : ''
                      }
                      disabled={
                        orderId
                          ? true
                          : manualOrderForm.tradingAccountId &&
                            manualOrderForm.instrument &&
                            customSymbol
                          ? false
                          : true
                      }
                      id="combo-box-demo  manual_ord_symbol"
                      options={
                        (customSymbol || securityNameOptions) &&
                        (customSymbol.length || securityNameOptions)
                          ? (strategyId
                              ? customSymbol
                              : securityNameOptions
                            ).map((sym) => {
                              return {
                                id: sym,
                                label: sym,
                              };
                            })
                          : []
                      }
                      classes={{
                        clearIndicatorDirty: classes.clearIndicator,
                        popupIndicator: classes.popupIndicator,
                      }}
                      disableClearable={manualOrderForm.symbol ? false : true}
                      getOptionLabel={(option) => option.label}
                      inputValue={
                        manualOrderForm.symbol ? manualOrderForm.symbol : ''
                      }
                      onInputChange={(event, newInputValue) => {
                        if (event !== null) {
                          selectSearch(newInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          style={{ background: 'white' }}
                        />
                      )}
                    />
                  </>
                </div>
              </Col>
              <Col sm="2">
                <div className="manual-order-heading">Expiry</div>
                <div>
                  <>
                    <UiSelector //shared component dropdown
                      single
                      role="expiry"
                      id="manual_ord_expiry"
                      disable={
                        orderId
                          ? true
                          : manualOrderForm?.symbol &&
                            manualOrderForm?.instrument === 'Equity'
                          ? true
                          : manualOrderForm.tradingAccountId &&
                            manualOrderForm.symbol
                          ? false
                          : true
                      }
                      value={
                        (customExpires || expiryOptions) &&
                        (customExpires.length || expiryOptions.length) &&
                        (strategyId ? customExpires : expiryOptions)?.map(
                          (exp) => {
                            return {
                              id: exp.expiry,
                              label: moment(exp.expiry).format('DD MMM YYYY'),
                            };
                          }
                        )
                          ? manualOrderForm.expiry
                          : moment(manualOrderForm.expiry).format('DD MMM YYYY')
                      }
                      listData={
                        (customExpires || expiryOptions) &&
                        (customExpires.length || expiryOptions.length) &&
                        (strategyId ? customExpires : expiryOptions)?.map(
                          (exp) => {
                            return {
                              id: exp.expiry,
                              label: moment(exp.expiry).format('DD MMM YYYY'),
                            };
                          }
                        )
                      }
                      handlerFunction={inputHandler.bind({ manualOrderForm })}
                      className={
                        manualOrderForm?.instrument === 'Equity' &&
                        manualOrderForm?.symbol
                          ? ''
                          : validator.current.message(
                              'expiry',
                              manualOrderForm.expiry,
                              'required',
                              {
                                className: 'error_message',
                              }
                            ) && formSubmit
                          ? 'error-input'
                          : ''
                      }
                    />
                  </>
                </div>
              </Col>
              <Col sm="2">
                <div className="manual-order-heading">Strike</div>
                <div>
                  <>
                    <UiSelector //shared component dropdown
                      single
                      role="strike"
                      id="manual_ord_strike"
                      value={parseInt(manualOrderForm.strike)}
                      disable={
                        orderId
                          ? true
                          : (manualOrderForm.expiry &&
                              manualOrderForm?.instrument === 'Equity') ||
                            manualOrderForm?.instrument === 'Future'
                          ? true
                          : manualOrderForm.tradingAccountId &&
                            manualOrderForm.expiry
                          ? false
                          : true
                      }
                      listData={
                        (customStrike || strikeOptions) &&
                        (customStrike.length > 0 || strikeOptions.length > 0) &&
                        (strategyId ? customStrike : strikeOptions)?.map(
                          (str) => {
                            return {
                              id: str,
                              label: str,
                            };
                          }
                        )
                      }
                      handlerFunction={inputHandler.bind({ manualOrderForm })}
                      className={
                        (manualOrderForm?.instrument === 'Equity' &&
                          manualOrderForm?.symbol) ||
                        (manualOrderForm?.instrument === 'Future' &&
                          manualOrderForm?.symbol)
                          ? ''
                          : validator.current.message(
                              'exchange',
                              manualOrderForm.strike,
                              'required',
                              {
                                className: 'error_message',
                              }
                            ) && formSubmit
                          ? 'error-input'
                          : ''
                      }
                    />
                  </>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <Row>
            <Col sm="12"></Col>
          </Row>
          <div className="row-container-manual">
            <Row>
              <Col sm="2">
                <div className="manual-order-heading">Buy/Sell</div>
                <div>
                  <UiSelector //shared component dropdown
                    single
                    role="direction"
                    value={manualOrderForm.direction}
                    listData={directionList?.map((el) => {
                      return {
                        id: el.name,
                        label: el.key,
                      };
                    })}
                    handlerFunction={inputHandler.bind({ manualOrderForm })}
                    className={
                      validator.current.message(
                        'direction',
                        manualOrderForm.direction,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : directionValue && securityValue
                        ? 'disable-pointers'
                        : ''
                    }
                    disable={orderId}
                  />
                </div>
              </Col>

              <Col sm="2">
                <div className="manual-order-heading">Order Type</div>
                <div>
                  <UiSelector //shared component dropdown
                    single
                    role="orderType"
                    id="manual_ord_orderType"
                    value={(manualOrderForm.orderType = 'LIMIT')}
                    disable={true}
                    listData={orderTypeList?.map((el) => {
                      return {
                        id: el.name,
                        label: el.key,
                      };
                    })}
                    handlerFunction={inputHandler.bind({ manualOrderForm })}
                    className={
                      validator.current.message(
                        'orderType',
                        manualOrderForm.orderType,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : ''
                    }
                  />
                </div>
              </Col>
              <Col sm="2">
                <div className="manual-order-heading">Qty (LOTS)</div>
                <div>
                  <UiInput //shared component input
                    type="number"
                    role="quantity"
                    name="quantity"
                    id="manual_ord_quantity"
                    value={manualOrderForm.quantity}
                    className={
                      validator.current.message(
                        'quantity',
                        manualOrderForm.quantity,
                        'required|min:1,num',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : ''
                    }
                    handlerfunction={inputHandler.bind({ manualOrderForm })}
                  />
                </div>
              </Col>
              <Col sm="2">
                <div className="manual-order-heading">Limit Price</div>
                <div>
                  <UiInput //shared component input
                    type="number"
                    role="limitPrice"
                    name="limitPrice"
                    id="manual_ord_limitPrice"
                    decimalAllowed={true}
                    value={manualOrderForm.limitPrice}
                    className={
                      manualOrderForm.orderType === 'LIMIT'
                        ? validator.current.message(
                            'limitPrice',
                            manualOrderForm.limitPrice,
                            'required|numeric|min:0,num',
                            {
                              className: 'error_message',
                            }
                          ) && formSubmit
                          ? 'error-input'
                          : ''
                        : 'limit-disable'
                    }
                    handlerfunction={inputHandler.bind({ manualOrderForm })}
                    disabled={
                      manualOrderForm.orderType === 'MARKET' ||
                      manualOrderForm.orderType === ''
                    }
                  />
                </div>
              </Col>
              <Col sm="2">
                <div className="manual-order-heading">Stop Loss</div>
                <div>
                  <UiInput
                    type="number"
                    role="stopLoss"
                    name="stopLoss"
                    id="manual_ord_stopLoss"
                    decimalAllowed={true}
                    value={manualOrderForm.stopLoss}
                    className={
                      validator.current.message(
                        'stopLoss',
                        manualOrderForm.stopLoss,
                        'numeric|min:0,num',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : ''
                    }
                    handlerfunction={inputHandler.bind({ manualOrderForm })}
                  />
                </div>
              </Col>
              <Col sm="12">
                <div className="divider"></div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm="12"></Col>
          </Row>
          <div className="submitsbuttons modal-footer" id="modal_footer">
            <Button
              onClick={() => submitForm()}
              id={
                manualOrderForm.direction === 'BUY'
                  ? 'manual_ord_buy'
                  : manualOrderForm.direction === 'SELL'
                  ? 'manual_ord_sell'
                  : ''
              }
            >
              Submit
            </Button>
            {orderId ? null : (
              <Button
                className="transparentBtns"
                onClick={clearFunc}
                id={
                  manualOrderForm.direction === 'BUY'
                    ? 'manual_ord_buy_clear'
                    : manualOrderForm.direction === 'SELL'
                    ? 'manual_ord_sell_clear'
                    : ''
                }
              >
                Clear
              </Button>
            )}
          </div>
        </div>
      </div>

      {validateToasetr ? (
        <SimpleSnackbar
          toaster={validateToasetr}
          setToaster={setValidateToasetr}
          message={toasterMsg ? toasterMsg : 'Please fill mandatory fields.'}
        />
      ) : null}

      {popup.state ? (
        <Popup actionButton={actionOnpopup} popupDetails={popup} />
      ) : null}
    </div>
  );
};

export default ManualOrder;

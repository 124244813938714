import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MultiDropdown.scss';
import UiButton from '../Button';
import DynamicField from '../DynamicField/DynamicField';
import { Form } from 'react-bootstrap';
import SimpleSnackbar from '../Toast/Toast';
import moment from 'moment';
import { setUsedNickName } from '../../../actions/strategyBuilderAction';
import { START_TIME_EPOCH, END_TIME_EPOCH } from '../../../utils/constant';

export default function MultiDropdown(props) {
  const [selectedData, setSelectedData] = useState({});
  const [idx, setIdx] = useState([]);
  const [flag, setFlag] = useState(false);
  const [conditionName, setConditionName] = useState('');
  const [conditionSelected, setConditionSelected] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [marketFormData, setMarketFormData] = useState({});
  const [argumentArr, setArgumentArr] = useState([]);
  const [listData, setListData] = useState([]);
  const [filteredListData, setFilteredListData] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [error, setError] = React.useState(false);
  const [toastMsg, setToastMsg] = useState('');
  const [marketSearch, setMarketSearch] = useState('');
  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));
  let dropdownRef = useRef(null);
  let wrapperRef = useRef(null);
  const {
    strategyBuilderReducer: { strategyTemplateForm, isEdit },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    setListData(props.listData);
    setFilteredListData(props.listData);
  }, [props.listData]);

  useEffect(() => {
    if (argumentArr && argumentArr.length > 0) {
      let marketData;
      if (conditionSelected === 'Number') {
        let eName = '';
        if (selectedData.enumName !== 'CONSTANT') {
          eName = 'PARAMETER';
        } else {
          eName = 'CONSTANT';
        }
        marketData = {
          ...marketFormData,
          eqIndex: props.eqIndex,
          conditionName,
          type: 'CONSTANT',
          data: eName,
          arguements: [...argumentArr],
        };
        props.setEquationValues(
          { type: 'OPERAND', value: conditionName, enumName: eName },
          props.eqIndex
        );
      } else if (conditionSelected !== 'Bracket') {
        marketData = {
          ...marketFormData,
          eqIndex: props.eqIndex,
          conditionName,
          type: 'OPERAND',
          data: selectedData.enumName,
          displayName: selectedData.displayName
            ? selectedData.displayName
            : selectedData.enumName,
          description: selectedData.argDescription
            ? selectedData.argDescription
            : '',
          arguements: [...argumentArr],
        };
        props.setEquationValues(
          {
            type: 'OPERAND',
            value: conditionName,
            enumName: selectedData.enumName,
          },
          props.eqIndex
        );
      }

      setMarketFormData({ ...marketData });
    }
  }, [argumentArr]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Anything in here is fired on component unmount.
    };
  }, [marketFormData]);

  const handleClickOutside = (event) => {
    if (
      wrapperRef &&
      wrapperRef.current !== null &&
      !wrapperRef.current.contains(event.target)
    ) {
      setIsActive(false);
      setIdx([]);
    }
  };
  const setTime = (argument) => {
    let value = argument.argValue.split(':');
    let d = new Date();
    d.setHours(value[0], value[1], value[2]);
    if (argument.argName === START_TIME_EPOCH) {
      setStartTime(d);
    } else if (argument.argName === END_TIME_EPOCH) {
      setEndtime(d);
    }
  };
  //set time for timeTriggered
  useEffect(() => {
    strategyTemplateForm.entryLegs?.map((entryLeg) => {
      entryLeg.algoExecution?.map((algo) => {
        algo.arguements?.map((argument) => {
          if (argument.argName === START_TIME_EPOCH) {
            setTime(argument);
          } else if (argument.argName === END_TIME_EPOCH) {
            setTime(argument);
          }
        });
      });
    });
  }, [strategyTemplateForm]);

  useEffect(() => {
    if (props.editFlagIndex === props.eqIndex) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [props.editFlagIndex]);

  useEffect(() => {
    if (props.marketForm && props.marketForm?.length) {
      props.marketForm?.map((el, index) => {
        if (el.eqIndex === props.eqIndex && el.data !== '') {
          setSelectedAnswer(el);
          if (props.fieldId == 'pricingCondition' || isEdit) {
            if (!Object.keys(marketFormData).length) {
              setMarketFormData(el);
            }
            if (!Object.keys(selectedData).length) {
              setSelectedData({
                ...el,
                args:
                  el.arguements &&
                  el.arguements.map((el) => {
                    return {
                      ...el,
                      argDefault: '0',
                      argRange: [{ min: 1, max: 1000000000 }],
                      enumName: el.argName,
                      displayName: el.argName,
                      inputType: el.argType,
                      value: el.argValue,
                      type: 'INPUTBOX',
                    };
                  }),
                displayName: el.conditionName,
                enumName: el.data,
              });
            }
            if (!conditionSelected) {
              setConditionSelected(el.conditionName);
            }
            if (!conditionName) {
              setConditionName(el.conditionName);
            }
          }
        }
      });
    } else {
      setSelectedData({});
      setSelectedAnswer(null);
    }
  }, [props.marketForm]);

  //second dropdown handler function
  const listTwoHandler = (value, list, index) => {
    let condition = '';
    if (!conditionSelected) {
      condition = list.name;
    } else {
      condition = conditionSelected;
    }

    if (condition === 'Bracket') {
      let bracketType = '';
      let marketData = {};
      if (value.enumName === 'EA_OPERATOR_OPEN') {
        bracketType = 'OPEN';
      } else {
        bracketType = 'CLOSE';
      }
      marketData = {
        ...marketFormData,
        eqIndex: props.eqIndex,
        conditionName: value.displayName,
        type: 'BRACKET',
        data: bracketType,
        arguements: [],
      };
      props.setEquationValues(
        { type: 'BRACKET', value: bracketType, enumName: value.enumName },
        props.eqIndex
      );
      setMarketFormData({ ...marketData });
      props.setSelectedCondition(true);
      props.marketHandler(props.eqIndex, marketData);
      setFlag(false);
      setIsActive(false);
      dropdownRef = null;
      setMarketSearch('');
      setFilteredListData([...props.listData]);
      setIdx([]);
    } else {
      setMarketFormData({});
    }
    setArgumentArr([]);
    setFlag(true);
    setConditionName(value.displayName);
    props.setInputData(value);
    setSelectedData(value);
  };
  //first dropdown hanlder function
  const listOneHandler = (list, index) => {
    setConditionSelected(list.name);
    if (list.type) {
      let dataObj = {
        displayName: list.name,
        enumName: list.enumName,
        args: list.args,
      };
      let typeObj = {
        type: list.type,
        displayName: list.name,
        enumName: list.enumName,
        args: list.args,
      };
      setSelectedData(dataObj);
      setFlag(true);
      setConditionName(list.name);
      props.setInputData(typeObj);
    }
    let activeId = [...idx];
    activeId.includes(index)
      ? (activeId = activeId.filter((el) => el !== index))
      : activeId.push(index);
    setIdx(activeId);
  };

  const handleCancel = (data) => {
    if (!selectedAnswer) {
      setMarketFormData({});
      if (data === 'TOB + x' || data === 'TWAP' || data === 'MARKET') {
        setConditionSelected('');
      }

      setSelectedData({});
      setFlag(!flag);
      props.setInputData({});
      props.setSelectedCondition(false);
    } else {
      setFlag(!flag);
    }
  };
  //search handler for dropdown
  const handleSearch = async (e) => {
    let inputText = e.target.value;
    let check;
    let finalResult = [];
    let filteredResult = [];
    let activeId = [];
    let inputsearch = inputText.toLowerCase();

    if (inputsearch.trim().length > 0) {
      for (let i = 0; i < listData.length; i++) {
        for (let j = 0; j < listData[i].data.length; j++) {
          let newJ = listData[i].data;
          check = newJ[j]?.displayName
            .trim()
            .toLowerCase()
            .includes(inputsearch);
          if (check) {
            if (finalResult[i]) {
              finalResult[i] = [...finalResult[i], newJ[j]];
            } else {
              finalResult[i] = [newJ[j]];
            }
          }
        }
        activeId.push(i);
        if (finalResult[i]) {
          filteredResult = [
            ...filteredResult,
            { name: listData[i].name, data: finalResult[i] },
          ];
        }
      }

      setFilteredListData([...filteredResult]);
      setIdx(activeId);
    } else {
      setFilteredListData([...props.listData]);
      setIdx([]);
    }
    setMarketSearch(inputText);
  };
  // function to handle states after clicking done
  const handleDone = () => {
    let submittedArgs = marketFormData?.arguements
      ? marketFormData.arguements
      : [];
    let validate = submittedArgs.every((el) => el.argValue !== '');
    let validateNewTemplate = true;

    if (selectedData.enumName === 'NEW_TEMPLATE_PARAMETER') {
      let editIndex = strategyTemplateForm.constants.findIndex(
        (el) => el.name === submittedArgs[0]?.argValue
      );
      // if NEW_TEMPLATE_PARAMETER already exists
      if (editIndex > -1) {
        validateNewTemplate = false;
      }
    }
    // Atleast one argument parameter should be present in indicator & Number
    if (conditionSelected !== 'Bracket' && submittedArgs.length === 0) {
      validate = false;
    }

    if (conditionSelected === 'MARKET') {
      let marketData = {};
      marketData = {
        ...marketFormData,
        eqIndex: props.eqIndex,
        conditionName: selectedData.displayName,
        type: 'OPERAND',
        data: selectedData.enumName,
        arguements: [],
      };
      props.setEquationValues(
        { type: 'OPERAND', value: '', enumName: selectedData.enumName },
        props.eqIndex
      );
      setMarketFormData({ ...marketData });
      props.setSelectedCondition(true);
      props.marketHandler(props.eqIndex, marketData);
      setFlag(false);
      setIsActive(false);
      dropdownRef = null;
      setMarketSearch('');
      setFilteredListData([...props.listData]);
      setIdx([]);
    } else {
      if (validate && validateNewTemplate) {
        setError(false);
        setToastMsg('');
        if (!selectedAnswer) {
          setMarketSearch('');
          setFilteredListData([...props.listData]);
          setIdx([]);
          props.setSelectedCondition(true);
          props.marketHandler(props.eqIndex, marketFormData);
          setFlag(!flag);
          setIsActive(false);
          dropdownRef = null;
        } else {
          props.setSelectedCondition(true);
          props.marketForm &&
            props.marketForm.length &&
            props.marketForm.map((el, index) => {
              if (el.eqIndex === props.eqIndex) {
                props.marketHandler(index, marketFormData, 'EDIT');
              }
            });
          setFlag(!flag);
          setIsActive(false);
          dropdownRef = null;
        }
      } else {
        setError(true);
        if (!validate) {
          setToastMsg('All fields are required');
        } else if (!validateNewTemplate) {
          setToastMsg('Constant already exist');
        }
      }
    }
    props.setInputData({});
  };

  // function for handling market equation
  const handleMarketFormData = (e, inputType, idName, conditionType) => {
    let modifiedArgsValue = {};
    if (e.target.name === START_TIME_EPOCH) {
      setStartTime(e.target.value);
    } else if (e.target.name === END_TIME_EPOCH) {
      setEndtime(e.target.value);
    }
    if (inputType === 'date_time') {
      let argDetailFiltered = selectedData?.args?.filter((arg, argIndex) => {
        return arg.enumName === e.target.name;
      });
      modifiedArgsValue = {
        argName: e.target.name,
        argType: inputType,
        seqNum:
          argDetailFiltered && argDetailFiltered.length > 0
            ? argDetailFiltered[0].argSeqNum
            : 0,
        displayName:
          argDetailFiltered && argDetailFiltered.length > 0
            ? argDetailFiltered[0].displayName
            : e.target.name,
        description:
          argDetailFiltered &&
          argDetailFiltered.length &&
          argDetailFiltered[0].argDescription
            ? argDetailFiltered[0].argDescription
            : '',
        argValue: moment(e.target.value).format('H:mm:ss'),
      };
    } else {
      let argDetailFiltered = selectedData?.args?.filter((arg, argIndex) => {
        return arg.enumName === e.target.name;
      });
      modifiedArgsValue = {
        argName: e.target.name,
        argType: inputType,
        seqNum:
          argDetailFiltered && argDetailFiltered.length > 0
            ? argDetailFiltered[0].argSeqNum
            : 0,
        displayName:
          argDetailFiltered && argDetailFiltered.length > 0
            ? argDetailFiltered[0].displayName
            : e.target.name,
        description:
          argDetailFiltered &&
          argDetailFiltered.length &&
          argDetailFiltered[0].argDescription
            ? argDetailFiltered[0].argDescription
            : '',
        argValue: e.target.value,
      };
    }
    let argumentsData = [...argumentArr];
    if (conditionSelected === 'Number') {
      argumentsData = [modifiedArgsValue];
    } else {
      selectedData &&
        selectedData?.args &&
        selectedData?.args?.length &&
        selectedData?.args?.map((arg, argIndex) => {
          if (!argumentArr.length) {
            if (arg.enumName === e.target.name) {
              argumentsData[argIndex] = modifiedArgsValue;
            } else {
              if (arg.enumName === START_TIME_EPOCH) {
                argumentsData[argIndex] = {
                  argName: arg.enumName,
                  argType: arg.inputType,
                  seqNum: arg.argSeqNum ? arg.argSeqNum : 0,
                  displayName: arg.displayName ? arg.displayName : arg.enumName,
                  description: arg.argDescription ? arg.argDescription : '',
                  argValue: moment(startTime).format('H:mm:ss'),
                };
              } else if (arg.enumName === END_TIME_EPOCH) {
                argumentsData[argIndex] = {
                  argName: arg.enumName,
                  argType: arg.inputType,
                  seqNum: arg.argSeqNum ? arg.argSeqNum : 0,
                  displayName: arg.displayName ? arg.displayName : arg.enumName,
                  description: arg.argDescription ? arg.argDescription : '',
                  argValue: moment(endTime).format('H:mm:ss'),
                };
              } else {
                argumentsData[argIndex] = {
                  argName: arg.enumName,
                  argType: arg.inputType,
                  seqNum: arg.argSeqNum ? arg.argSeqNum : 0,
                  displayName: arg.displayName ? arg.displayName : arg.enumName,
                  description: arg.argDescription ? arg.argDescription : '',
                  argValue: '',
                };
              }
            }
          }
        });
    }
    if (argumentArr.length) {
      let check = argumentsData.findIndex(
        (el) => el?.argName === e.target.name
      );
      if (check === -1) {
        argumentsData = [
          ...argumentsData,
          {
            ...modifiedArgsValue,
          },
        ];
      } else {
        argumentsData = [...argumentsData];
        argumentsData[check] = {
          ...modifiedArgsValue,
        };
      }
    }
    if (inputType === 'security_nickname') {
      let nicknameObj = {
        condition: conditionType,
        id: conditionType + '-' + idName,
        value: e.target.value,
      };
      if (nicknameObj.condition.includes('entry')) {
        nicknameObj.condition = 'entry';
      } else if (nicknameObj.condition.includes('exit')) {
        nicknameObj.condition = 'exit';
      }
      dispatch(setUsedNickName(nicknameObj));
    }
    setArgumentArr(argumentsData);
  };

  //render function to display dynamic components
  function rederer() {
    let selectedAnswerValues = selectedAnswer
      ? selectedAnswer.data.startsWith('EA_EXEC_ALGO')
        ? strategyTemplateForm.entryLegs[0].algoExecution[0].arguements
        : selectedAnswer.arguements
      : [];
    if (conditionSelected === 'Number') {
      let form = selectedData;
      return (
        <DynamicField //shared dynamic component
          fieldProperties={form}
          selectedValues={
            selectedAnswerValues[0]?.argName.startsWith('EXISTING') &&
            !strategyTemplateForm.constants.find(
              (el) => el.name === selectedAnswerValues[0]?.argValue
            )
              ? null
              : selectedAnswerValues[0]
          }
          setMarketForm={props.setMarketForm}
          marketHandler={handleMarketFormData}
          fieldId={props.fieldId}
        />
      );
    } else {
      let form = selectedData.args;
      return form.map((i, formIndex) => {
        console.log(i);
        return (
          <React.Fragment key={formIndex}>
            {selectedAnswerValues[formIndex] ? (
              <DynamicField
                fieldProperties={i}
                displayLabel={true}
                selectedValues={selectedAnswerValues[formIndex]}
                setMarketForm={props.setMarketForm}
                marketHandler={handleMarketFormData}
                quantityLogicFlag={props.quantityLogicFlag}
                selectedDate={
                  i.enumName === START_TIME_EPOCH
                    ? startTime
                    : i.enumName === END_TIME_EPOCH
                    ? endTime
                    : ''
                }
                startTime={startTime}
                endTime={endTime}
                timeType={
                  i.enumName === START_TIME_EPOCH
                    ? 'startTime'
                    : i.enumName === END_TIME_EPOCH
                    ? 'endTime'
                    : ''
                }
                setTimeData={(time) =>
                  handleMarketFormData(
                    {
                      target: {
                        name: i.enumName,
                        value: time,
                      },
                    },
                    i.inputType
                  )
                }
                fieldId={props.fieldId}
              />
            ) : (
              <DynamicField
                fieldProperties={i}
                displayLabel={true}
                setMarketForm={props.setMarketForm}
                marketHandler={handleMarketFormData}
                quantityLogicFlag={props.quantityLogicFlag}
                selectedDate={
                  i.enumName === START_TIME_EPOCH
                    ? startTime
                    : i.enumName === END_TIME_EPOCH
                    ? endTime
                    : ''
                }
                startTime={startTime}
                endTime={endTime}
                timeType={
                  i.enumName === START_TIME_EPOCH
                    ? 'startTime'
                    : i.enumName === END_TIME_EPOCH
                    ? 'endTime'
                    : ''
                }
                setTimeData={(time) =>
                  handleMarketFormData(
                    {
                      target: {
                        name: i.enumName,
                        value: time,
                      },
                    },
                    i.inputType
                  )
                }
                fieldId={props.fieldId}
              />
            )}
          </React.Fragment>
        );
      });
    }
  }

  const removeSelectedAnswer = () => {
    props.marketForm &&
      props.marketForm.length &&
      props.marketForm.map((el, index) => {
        if (el.eqIndex == props.eqIndex) {
          props.marketHandler(index, 'REMOVE', el);
        }
      });
    setSelectedData({});
    setArgumentArr([]);
    setSelectedAnswer(null);
    setConditionSelected('');
    setIdx([]);
    setStartTime(new Date('2014-08-18T09:30:00'));
    setEndtime(new Date('2014-08-18T15:15:00'));
  };

  const editSelectedAnswer = () => {
    setFlag(true);
    props.setEditFlagIndex(props.eqIndex);
  };

  //function to show selected entries
  const renderSelectedAnswer = () => {
    let args = selectedAnswer
      ? selectedAnswer.data.startsWith('EA_EXEC_ALGO')
        ? strategyTemplateForm.entryLegs[0]?.algoExecution[0]?.arguements
        : selectedAnswer?.arguements
      : [];
    let argsValue = '';
    if (args && args?.length) {
      let modifiedArgsArr = args.map((arg, index) => arg.argValue);
      argsValue = modifiedArgsArr.join(',');
    }
    return (
      <>
        <button
          type="button"
          className="answerBtn"
          onClick={() => removeSelectedAnswer()}
        >
          <svg
            className="answerSvg"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            <path fill="none" d="M0 0h24v24H0z"></path>
          </svg>
        </button>
        <p onClick={() => editSelectedAnswer()}>
          {selectedAnswer && selectedAnswer?.conditionName === 'TOB + x'
            ? 'TOB + '
            : selectedAnswer?.conditionName}
          {argsValue ? `(${argsValue})` : ''}
        </p>
      </>
    );
  };

  return (
    <React.Fragment>
      {selectedAnswer ? (
        <div ref={wrapperRef}>
          <div className="selectedAnswer">{renderSelectedAnswer()}</div>
          {flag && Object.keys(selectedData).length && (
            <div className="multiDropdown multiDropdown-edit">
              <div className="formWrapper">
                <Form.Label>{conditionName}</Form.Label>
                {conditionSelected !== 'Bracket' ? rederer() : null}
                <div className="buttons-wrapper">
                  <UiButton
                    name="Cancel"
                    onClickFunction={() => handleCancel(conditionSelected)}
                  />
                  <UiButton name="Done" onClickFunction={handleDone} />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="multiDropdown" ref={wrapperRef}>
          <input
            placeholder={props.placeHolder ? props.placeHolder : ''}
            value={marketSearch}
            className="finalValueShow"
            id={props.id ? props.id : ''}
            onClick={() => setIsActive(true)}
            onChange={props.noInput ? null : (e) => handleSearch(e)}
          />
          {flag && Object.keys(selectedData).length ? (
            <div className="formWrapper">
              <Form.Label>{conditionName}</Form.Label>
              {conditionSelected !== 'Bracket' ? rederer() : null}
              <div className="buttons-wrapper">
                <UiButton
                  name="Cancel"
                  onClickFunction={() => handleCancel(conditionSelected)}
                />
                <UiButton name="Done" onClickFunction={handleDone} />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {filteredListData && filteredListData.length ? (
                <ul
                  ref={dropdownRef}
                  className={`toogledropdown mainWrapperDropdown ${
                    isActive ? 'active' : 'inactive'
                  }`}
                >
                  {filteredListData?.map((list, index) => {
                    return (
                      <li
                        key={index + 'firstlist'}
                        onClick={(e) => listOneHandler(list, index)}
                      >
                        <span>{list.name}</span>
                        {idx.includes(index) ? (
                          <ul>
                            {list.data?.map((i, index2) => {
                              return (
                                <li
                                  key={index2 + 'secondlist'}
                                  onClick={(e) =>
                                    listTwoHandler(i, list, index)
                                  }
                                >
                                  {i.displayName}
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </React.Fragment>
          )}
        </div>
      )}
      {error ? (
        <SimpleSnackbar
          toaster={error}
          setToaster={setError}
          message={toastMsg}
        />
      ) : null}
    </React.Fragment>
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import './LiveTradingHeader.scss';
import CreateStrategyModal from '../CreateEditStrategyModal/CreateEditStrategyModal';
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '../../../custom_Hooks/useShortcut';
import { GenButton, DropDown } from '../../../components';
import { createDropdown } from '../../../actions/LiveTradingAction';
import { useCallbackRef } from 'use-callback-ref';
import { permissionChecker } from '../../../viewScreen/Ops/redux';

const NewWindow = (id, isTemplate, strategyTemplateId) => {
  let strategyTemplate = strategyTemplateId ? strategyTemplateId : id;
  let newWindow = window.open(
    `/window/create-strategy-modal/${window.btoa(id)}/${window.btoa(
      strategyTemplate
    )}/${window.btoa(isTemplate)}`,
    '_blank',
    `top=0, left=0, width=${window.visualViewport.width * 0.75}, height=${
      window.visualViewport.height
    }, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes`
  );
};

export const LiveTradingHeader = ({ marketWatchHeaderRef }) => {
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState('view');
  const [manualOrderPermission, setManualOrderPermission] = useState('view');
  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'Live Trading',
      2,
      'Trading'
    );

    setPermission(res > 1 ? 'edit' : 'view');

    const manOrderRes = permissionChecker(
      userPermissions?.menu,
      'Manual Order',
      2,
      'Trading'
    );

    setManualOrderPermission(manOrderRes > 1 ? 'edit' : 'view');
  }, [userPermissions]);

  const [modal, setModal] = useState(false);
  const [headerWidth, setHeaderWidth] = useState('');
  // let currentShortCutKeys=[]
  const {
    liveTradingReducer: { dropdown },
    loginReducer: { userId },
    shortcutReducer,
  } = useSelector((state) => state);
  const keyBoardShortCuts = shortcutReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    let header_marketwatch = document.getElementsByClassName('dashboard')[0];
    setHeaderWidth(header_marketwatch.clientWidth);
    function resize() {
      setHeaderWidth('');
      let header_marketwatch = document.getElementsByClassName('dashboard')[0];
      setHeaderWidth(header_marketwatch.clientWidth);
    }
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  useEffect(() => {
    fetchDropDownList();
  }, []);

  const fetchDropDownList = () => {
    dispatch(createDropdown());
  };

  const handleManual = () => {
    // setToggleOrder(!toggleOrder)
    let newWindow = window.open(
      `/window/manual-order`,
      '_blank',
      'top=100, left=100, width=800, height=375, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  // Callback for manual order shortcut
  const manualOrderShortcut = (event) => {
    console.log(`key pressed: ${event.key}`);
    handleManual();
  };

  const triggerManualOrder = useCallbackRef(false, () => handleManual());

  // using custom hook for shortcut
  useKeyPress(
    keyBoardShortCuts.get('MANUAL_ORDER')?.keyCombination.split('+'),
    () => {
      triggerManualOrder.current = !triggerManualOrder.current;
    }
  );

  useKeyPress(['alt', 'h'], () => {
    document.getElementById('CreateStrategyButton').click();
  });

  const [filteredShortcuts, setFilteredShortcuts] = useState([]);

  useEffect(() => {
    if (shortcutReducer.size) {
      const newFilteredMap = new Map(
        Array.from(shortcutReducer.entries())
          .filter(([key, shortcut]) => shortcut.isRuleShortcut === true)
      );
      setFilteredShortcuts(newFilteredMap);
    }else{
      setFilteredShortcuts(shortcutReducer);
    }
  }, [shortcutReducer]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      Array.from(filteredShortcuts.entries()).forEach(([key, value]) => {
        const keys = value.keyCombination.split('+');
        const allKeysPressed = keys.every(
          key => event[key.toLowerCase() + 'Key'] || event.key.toLowerCase() === key.toLowerCase()
        );
        if (allKeysPressed) {
          const { id } = value.execOptions;
          let item1 = dropdown.find(obj => obj.id === id);
          if (item1) {
            NewWindow(item1.id, item1.isTemplate, item1.strategyTemplateId);
          }
        }
      });
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [shortcutReducer,filteredShortcuts]);

  return (
    <React.Fragment>
      {process.env.REACT_APP_IS_HFT == 'true' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '1vw 0',
            backgroundColor: '#F0F3F2',
          }}
        ></div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '1vw 0',
            backgroundColor: '#F0F3F2',
          }}
        >
          <DropDown
            id="CreateStrategyButton"
            selected="Create Strategy"
            disabled={permission !== 'edit'}
            options={dropdown.map((item) => item.name)}
            onSelect={(item) => {
              let item1 = dropdown.filter((obj) => obj.name === item)[0];
              NewWindow(item1.id, item1.isTemplate, item1.strategyTemplateId);
            }}
            extStyles={{ margin: '0 1vw 0 1vw' }}
            textExtStyles={{ margin: '0 0.5rem 0 0' }}
            enable_search={true}
            textLengthLimit={30}
          />
          {
            // ! Manual order disabled
            <GenButton
              fill={false}
              title="Manual Order"
              clickAction={handleManual}
              disabled={manualOrderPermission !== 'edit'}
              extStyle={{ width: '12vw', height: '4vh' }}
            />
          }
        </div>
      )}
      {modal && process.env.REACT_APP_IS_HFT == 'false' ? (
        <CreateStrategyModal
          userId={userId}
          modal={modal}
          setModal={setModal}
        />
      ) : null}
    </React.Fragment>
  );
};

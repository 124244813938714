import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import { Settings, DesIcon, AscIcon, BlackArrow } from '../../../../assets';
import { Switch } from '../../..';
import PageGroup from '../page_group';
import { DragDropList } from '..';
import {
  getMarketColumnArr,
  setMarketColArr,
} from '../../../LiveTrading/MarktWatch/apiCalls';
import { handleEnterWithCallback } from '../../keyPressEventHandler';
import { useDispatch, useSelector } from 'react-redux';
import { TableOrderChangeActions } from '../../../../reducers/table_order_reducer';
import { MARKET_PAGES } from '../../../../utils/constant';
import {
  ChildComp,
  ParentComp,
  ParentLeftIcon,
  ParentCenterText,
  SettingsContainer,
  SettingsIcons,
} from './components';
import ManageFormula from './ManageFormula';

export default function TableSettings({
  menuOptions = [],
  clickAction = () => {},
  updateColCallBack,
  requiredPagination,
  extStyles,
  screenName,
  allowed = true,
}) {
  const [open, setOpen] = useState(false);
  const contArea = useRef(null);
  const [pageInfo, setPageInfo] = useState({
    totalPages: MARKET_PAGES,
    curr: 1,
  });
  const [columnsList, setColumnList] = useState();
  const [apiColData, setApiColData] = useState();
  const {
    strategy_analyzer,
    order_trades,
    win_OT_AOO,
    win_OT_OMO,
    win_OT_T,
    win_SA_T,
    win_SA_O,
    win_risk_monitoring,
    win_risk_monitoring_pre_trade,
    net_position,
  } = useSelector((state) => state.TableOrderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    updateColumnList();
  }, [strategy_analyzer]);

  useEffect(() => {
    const clickOutside = (e) => {
      if (contArea.current && !contArea.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [contArea]);

  const updateColumnList = async () => {
    if (menuOptions[menuOptions.length - 1].type === 'COL_RE') {
      let data;

      const keyToColumnMappting = {
        STRATEGY_ANALYZER_TS: strategy_analyzer,
        ORDER_TRADES_TS: order_trades,
        OT_AOO: win_OT_AOO,
        OT_OMO: win_OT_OMO,
        OT_T: win_OT_T,
        SA_O: win_SA_O,
        SA_T: win_SA_T,
        risk_monitoring: win_risk_monitoring,
        risk_monitoring_pre_trade: win_risk_monitoring_pre_trade,
        NET_POSITION: net_position,
      };
      data = keyToColumnMappting[screenName]
        ? keyToColumnMappting[screenName]
        : await getMarketColumnArr('MARKET_WATCH', pageInfo.curr);
      // switch (screenName) {
      //   case 'STRATEGY_ANALYZER_TS':
      //     data = strategy_analyzer;
      //     break;
      //   case 'ORDER_TRADES_TS':
      //     data = order_trades;
      //     break;
      //   case 'OT_AOO':
      //     data = win_OT_AOO;
      //     break;
      //   case 'OT_OMO':
      //     data = win_OT_OMO;
      //     break;
      //   case 'OT_T':
      //     data = win_OT_T;
      //     break;
      //   case 'SA_O':
      //     data = win_SA_O;
      //     break;
      //   case 'SA_T':
      //     data = win_SA_T;
      //     break;
      //   case 'risk_monitoring':
      //     data = win_risk_monitoring;
      //     break;
      //   case 'risk_monitoring_pre_trade':
      //     data = win_risk_monitoring_pre_trade;
      //     break;
      //   case 'NET_POSITION':
      //     data = net_position;
      //     break;
      //   default:
      //     data = await getMarketColumnArr('MARKET_WATCH', pageInfo.curr);
      //     break;
      // }

      const tempColumns1 = (data.viewData.length > 0
        ? data.viewData
        : data.staticData
      ).map((item, index) => {
        return {
          id: `${index}-${item.columnName}`,
          title: item.columnName,
          visibility: true,
          ...item,
        };
      });
      if (tempColumns1.length < data.staticData.length) {
        for (let item of data.staticData) {
          if (
            tempColumns1.filter((item1) => item1.columnName === item.columnName)
              .length === 0
          ) {
            tempColumns1.push({
              ...item,
              id: `${item.columnName}`,
              title: item.columnName,
              visibility: false,
            });
          }
        }
      }
      setColumnList(tempColumns1);
      setApiColData(data);
    }
  };

  useEffect(() => {
    updateColumnList();
  }, [pageInfo, menuOptions]);

  function handleCallback(level, id, val) {
    clickAction(level, id, val);
  }

  async function apply() {
    // TODO make Set API call here
    let tempCols = columnsList
      .filter((item) => item.visibility)
      .map((item) => {
        return {
          columnName: item.columnName,
          variableName: item.variableName,
          columnSeq: item.columnSeq,
        };
      });
    tempCols = tempCols.sort((a, b) => a.columnSeq - b.columnSeq);
    tempCols = tempCols.map((item, index) => {
      return { ...item, columnSeq: index + 1 };
    });
    switch (screenName) {
      case 'STRATEGY_ANALYZER_TS':
        TableOrderChangeActions.update_SA_sequence(dispatch, tempCols);
        break;
      case 'ORDER_TRADES_TS':
        TableOrderChangeActions.set_OT_update_data(dispatch, tempCols);
        break;
      case 'OT_AOO':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'OT_OMO':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'OT_T':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'SA_O':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'SA_T':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'risk_monitoring':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'risk_monitoring_pre_trade':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          tempCols
        );
        break;
      case 'NET_POSITION':
        TableOrderChangeActions.updateNetPosition(dispatch, tempCols);
        break;
      default:
        await setMarketColArr({
          tableName: 'MARKET_WATCH',
          pageNum: pageInfo.curr,
          dynamicTableSeq: tempCols,
        });
        break;
    }

    updateColCallBack();
  }

  async function reset() {
    switch (screenName) {
      case 'STRATEGY_ANALYZER_TS':
        TableOrderChangeActions.update_SA_sequence(
          dispatch,
          strategy_analyzer.staticData
        );
        break;
      case 'ORDER_TRADES_TS':
        TableOrderChangeActions.set_OT_update_data(
          dispatch,
          order_trades.staticData
        );
        break;
      case 'OT_AOO':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_OT_AOO.staticData
        );
        break;
      case 'OT_OMO':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_OT_OMO.staticData
        );
        break;
      case 'OT_T':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_OT_T.staticData
        );
        break;
      case 'SA_O':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_SA_O.staticData
        );
        break;
      case 'SA_T':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_SA_T.staticData
        );
        break;
      case 'risk_monitoring':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_risk_monitoring.staticData
        );
        break;
      case 'risk_monitoring_pre_trade':
        TableOrderChangeActions.update_window_OT_SA_data(
          dispatch,
          screenName,
          win_risk_monitoring_pre_trade.staticData
        );
        break;
      case 'NET_POSITION':
        TableOrderChangeActions.updateNetPosition(
          dispatch,
          net_position.staticData
        );
        break;
      default:
        await setMarketColArr({
          tableName: 'MARKET_WATCH',
          pageNum: pageInfo.curr,
          dynamicTableSeq: apiColData.staticData,
        });
        break;
    }
    await updateColumnList();
    updateColCallBack();
  }

  return (
    <div className="table-settings" style={extStyles}>
      <SettingsContainer
        onClick={() => allowed && setOpen(!open)}
        tabIndex="0"
        onKeyDown={(event) => {
          if (allowed) {
            handleEnterWithCallback(event, () => {
              setOpen(!open);
            });
          }
        }}
      >
        <SettingsIcons src={Settings} alt="" />
      </SettingsContainer>
      {open && menuOptions[menuOptions.length - 1].type === 'MAIN' && (
        <div className="table-setting-dropdown-cont" ref={contArea}>
          {menuOptions[menuOptions.length - 1].content.map((item, index) => (
            <div
              style={{
                borderBottom:
                  index ===
                  menuOptions[menuOptions.length - 1].content.length - 1
                    ? ''
                    : '1px solid #cecece',
              }}
            >
              <ParentOption
                id={item.id}
                leftIcon={item.leftComp}
                label={item.label}
                rightComp={item.rightComp}
                moreOptions={item.moreOpt}
                callBack={handleCallback}
              />
            </div>
          ))}
        </div>
      )}
      {open && menuOptions[menuOptions.length - 1].type === 'COL_RE' && (
        <div className="table-setting-dropdown-cont" ref={contArea}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '6vh',
              cursor: 'pointer',
            }}
          >
            <img
              className="cr-backbutton"
              src={BlackArrow}
              onClick={() => clickAction('B')}
              alt="black arrow"
            />
            <span className="cr-title">Reorder / hide & Unhide</span>
          </div>

          {requiredPagination ? (
            <PageGroup
              totalPageCount={pageInfo.totalPages}
              current={pageInfo.curr}
              extStyles={{ width: '90%', margin: '0 auto' }}
              clickTrigger={(val) => setPageInfo({ ...pageInfo, curr: val })}
            />
          ) : null}

          <DragDropList
            extStyles={{
              height: '30vh',
              width: '100%',
              margin: '0.5vh 0 0.5vh 0',
            }}
            content={columnsList}
            onChange={(item) => {
              let temp = item.map((newItenm, index) => {
                return { ...newItenm, columnSeq: index + 1 };
              });
              setColumnList(temp);
            }}
          />
          <div className="colre-sec-buttons">
            <span className="colre-apply" onClick={apply}>
              Apply
            </span>
            <span className="colre-reset" onClick={reset}>
              Reset
            </span>
          </div>
        </div>
      )}
      {open && menuOptions[menuOptions.length - 1].type === 'MNG_FO' && (
        <ManageFormula setOpen={setOpen} clickAction={clickAction} />
      )}
    </div>
  );
}

function ParentOption({
  leftIcon,
  label,
  rightComp,
  moreOptions = [],
  callBack,
  id,
}) {
  const [expand, setExpand] = useState(false);

  function handleClick(type, val) {
    if (type === 'P' && rightComp === undefined) {
      callBack(type, id, undefined);

      if (rightComp === undefined && moreOptions.length > 0) {
        setExpand(!expand);
      }
    } else if (type === 'P-R') {
      callBack('P-R', id, val);
    } else if (type === 'C') {
      callBack(`C-${id}`, val, undefined);
    }
  }

  return (
    <React.Fragment>
      <ParentComp onClick={() => handleClick('P')} key="label">
        <ParentLeftIcon src={leftIcon} alt="left-icon" />
        <ParentCenterText>{label}</ParentCenterText>
        {moreOptions.length > 0 ? (
          <img
            src={expand ? AscIcon : DesIcon}
            className="famchevron-icon"
            alt="Expand Collapse"
          />
        ) : rightComp && rightComp.type === 'SWITCH' ? (
          <Switch
            extStyles={{ margin: 'auto' }}
            state={rightComp.state}
            onChange={(val) => handleClick('P-R', val)}
          />
        ) : (
          ''
        )}
      </ParentComp>
      {expand &&
        moreOptions.map((item) => (
          <ChildOption data={item} callBack={handleClick} />
        ))}
    </React.Fragment>
  );
}

function ChildOption({ data, callBack }) {
  return (
    <ChildComp onClick={() => callBack(`C`, data.id)} key={data.id}>
      <span className="child-label">{data.label}</span>
      <img src={DesIcon} className="child-icon" alt="childComp" />
    </ChildComp>
  );
}

const initialState = {
    createTeamData: false,
  Id:''
  }
  const editTeamReducer = (state = initialState, action) => {
    switch (action.type) {
      case "EDIT_TEAM":
        return {
          ...state,
          createTeamData: action.payload,
          Id:action.teamId
        }
        case "CLEAR_EDIT":
          return {
          ...state,
          Id:action.clearMessage
          }
      
      default:
        return state
    }
  }
  
  export default editTeamReducer
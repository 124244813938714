import React from 'react';
import './styles.css';
import PaginationGen2 from './pagination';

export default function TableGen2({
  data = {
    header: [
      {
        objKey: 'exchange',
        label: 'Exchange',
        headerAlignment: 'left',
        contentAlignment: 'right',
        headExtraStyle: {},
        contentExtraStyle: {},
      },
    ],
    data: [{ exchange: 'NSE' }],
  },
  tableSettings = {
    themeMode: 'dark',
    bordered: false,
    tableStyles: {},
  },
  tableAreaHeight = '35vh',
  containerHeight = '40vh',
  pagination = { pagesCount: 5, currentSelect: 1 },
  setPagination = (val) => {},
}) {
  function generateClass(className) {
    return `${className}${tableSettings.themeMode === 'dark' ? '-dark' : ''}`;
  }
  return (
    <div
      style={{
        ...tableSettings.tableStyles,
        height: containerHeight,
      }}
      className={generateClass('TableGen2-container')}
    >
      <div
        className={generateClass('TableGen2-tableSection')}
        style={{ height: tableAreaHeight }}
      >
        <table className={generateClass('TableGen2-table')}>
          <thead className={generateClass('TableGen2-thead')}>
            <tr className={generateClass('TableGen2-thead-tr')}>
              {data.header.map((item) => (
                <th
                  className={generateClass('TableGen2-thead-th')}
                  style={{
                    ...item.headExtraStyle,
                    border: tableSettings.bordered
                      ? '1px solid #cecece'
                      : '0px solid #cecece',
                  }}
                >
                  {item.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={generateClass('TableGen2-tbody')}>
            {data.data.map((row) => {
              return (
                <tr className={generateClass('TableGen2-tbody-tr')}>
                  {Object.keys(row).map((item, index) => {
                    return (
                      <td
                        style={{
                          ...data.header[index]?.contentExtraStyle,
                          border: tableSettings.bordered
                            ? '1px solid #cecece'
                            : '0px solid #cecece',
                          backgroundColor: (data.header[index]?.objKey != 'ltt' && (row[data.header[index]?.objKey]?.value)) ? row[data.header[index]?.objKey]?.color : undefined
                        }}
                        className={generateClass('TableGen2-tbody-tr-td')}
                      >
                        {(((typeof row[data.header[index]?.objKey] == 'object') && 'value' in row[data.header[index]?.objKey]) ? row[data.header[index]?.objKey].value : row[data.header[index]?.objKey])}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <PaginationGen2 data={pagination} setData={(val) => setPagination(val)} />
    </div>
  );
}

import ManageFormulaAPI from '../components/sharedComponents/customComponent/tableSettings/MWFormulaAPIs';
import { MakeRequest } from '../utils';
import { fetchValue, updateValue } from '../utils/dotKeyFunctions';

const initialData = {
  state: false,
  feedbackPopup: '',
  formData: {
    formTitle: 'Create Formula',
    formulaName: '',
    formulaDescription: '',
    pageNumber: 1,
    action: '',
    primarySecurity: {
      nickname: '',
      instrument: '',
      symbol: '',
      expiry: '',
      strike: '',
    },
    supportingSecurity: {
      show: false,
      supportArray: [],
    },
    formula: '',
  },
};

// Static data caching
let instrumentArr = [];

function CreateFormulaFormPopup(
  state = initialData,
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case 'CREATE_FORMULA_OPEN_POPUP':
      return { ...state, state: true };
    case 'CREATE_FORMULA_CLOSE_POPUP':
      const pg = state.formData.pageNumber;
      return { ...state, state: false, formData: {...action.payload, pageNumber:pg} };
    case 'CREATE_FORMULA_SET_FORM_DATA':
      return { ...state, formData: action.payload };
    case 'CREATE_FORMULA_SUCCESS':
      return {
        ...state,
        feedbackPopup: action.payload,
        formData: { ...state.formData, action: '' },
      };
    case 'CREATE_FORMULA_RESET':
      return {
        ...state,
        formData: {
          ...state.formData,
          actions: '',
        },
      };
    case 'CREATE_FORMULA_SET_PAGE_NUMBER':
      return {
        ...state,
        formData: {
          ...state.formData,
          pageNumber: action.payload,
        },
      };
    case 'CREATE_FORMULA_SET_FEEDBACK':
      return {
        ...state,
        feedbackPopup: `Quick Test Response : ${action.payload}`,
        formData: {
          ...state.formData,
          action: '',
        },
      };
    default:
      return state;
  }
}

async function fetchFormulaAndMorph(id, mode) {
  try {
    const formula = await ManageFormulaAPI('GET_SINGLE_FORMULA', id);

    const apiFormula = formula.data.data.data;
    if (instrumentArr.length == 0) {
      instrumentArr = await MakeRequest('/security/instrumenTypes').get();
      instrumentArr = instrumentArr.data.data;
    }
    apiFormula.securityList = apiFormula.securityList.map((item) => {
      return {
        ...item,
        instrument: instrumentArr.filter(
          (item1) => item1.key === item.instrument
        )[0].name,
      };
    });
    const primary = fetchValue(apiFormula, 'securityList').filter(
      (item) => item.isPrimary
    );
    const data = {
      id: id,
      formTitle: mode ? 'Edit Formula' : 'Create Formula',
      formulaName: fetchValue(apiFormula, 'name'),
      formulaDescription: fetchValue(apiFormula, 'description'),
      pageNumber: fetchValue(apiFormula, 'pageNum'),
      action: {
        submit: false,
        close: false,
        draft: false,
        quickTest: false,
      },
      primarySecurity: {
        nickname: primary[0].nickname,
        instrument: primary[0].instrument,
        symbol: primary[0].symbol,
        expiry: primary[0].expiry,
        strike: primary[0].strike,
      },
      supportingSecurity: {
        show: false,
        supportArray: fetchValue(apiFormula, 'securityList')
          .filter((item) => !item.isPrimary)
          .map((item) => {
            return {
              nickname: item.nickname,
              instrument: item.instrument,
              symbol: item.symbol,
              expiry: item.expiry,
              strike: item.strike,
            };
          }),
      },
      formula: fetchValue(apiFormula, 'formula'),
    };
    data.supportingSecurity.show =
      data.supportingSecurity.supportArray.length > 0;
    return data;
  } catch (error) {
    console.log(error.message);
    return {};
  }
}

class CreateFormulaActions {
  dispatch = null;
  constructor(dis) {
    this.dispatch = dis;
  }

  openPopup() {
    this.dispatch({ type: 'CREATE_FORMULA_OPEN_POPUP', payload: {} });
  }

  closePopup() {
    this.dispatch({
      type: 'CREATE_FORMULA_CLOSE_POPUP',
      payload: {
        formTitle: 'Create Formula',
        formulaName: '',
        formulaDescription: '',
        pageNumber: 1,
        action: {
          submit: false,
          close: false,
          draft: false,
          quickTest: false,
        },
        primarySecurity: {
          nickname: '',
          instrument: '',
          symbol: '',
          expiry: '',
          strike: '',
        },
        supportingSecurity: {
          show: false,
          supportArray: [],
        },
        formula: '',
      },
    });
  }

  updateFormData(data) {
    this.dispatch({ type: 'CREATE_FORMULA_SET_FORM_DATA', payload: data });
  }

  async createFormula(data, type) {
    try {
      let instruments = await MakeRequest('/security/instrumenTypes').get();
      instruments = instruments.data.data;
      const messageBody = {
        name: fetchValue(data, 'formulaName'),
        description: fetchValue(data, 'formulaDescription'),
        pageNum: fetchValue(data, 'pageNumber'),
        formula: fetchValue(data, 'formula'),
        securityList: [
          {
            nickname: fetchValue(data, 'primarySecurity.nickname'),
            exchange: 'NSE',
            instrument: fetchValue(data, 'primarySecurity.instrument'),
            symbol: fetchValue(data, 'primarySecurity.symbol'),
            expiry: fetchValue(data, 'primarySecurity.expiry'),
            strike: fetchValue(data, 'primarySecurity.strike'),
            isPrimary: true,
          },
        ],
      };
      for (let obj of fetchValue(data, 'supportingSecurity.supportArray')) {
        messageBody.securityList.push({
          nickname: fetchValue(obj, 'nickname'),
          exchange: 'NSE',
          instrument: fetchValue(obj, 'instrument'),
          symbol: fetchValue(obj, 'symbol'),
          expiry: fetchValue(obj, 'expiry'),
          strike: fetchValue(obj, 'strike'),
          isPrimary: false,
        });
      }
      for (let i = 0; i < messageBody.securityList.length; i++) {
        switch (messageBody.securityList[i].instrument) {
          case 'Future':
            messageBody.securityList[i].strike = '';
            break;
          case 'Equity':
            messageBody.securityList[i].strike = '';
            messageBody.securityList[i].expiry = '';
            break;
          default:
            break;
        }
      }
      switch (type) {
        case 'CREATE':
          let tempMessageBody12 = { ...messageBody };
          tempMessageBody12.securityList = tempMessageBody12.securityList.map(
            (item) => {
              return {
                ...item,
                instrument: instruments.filter(
                  (item1) => item1.name === item.instrument
                )[0].key,
              };
            }
          );

          const result = await ManageFormulaAPI('CREATE', tempMessageBody12);
          if (result.status === 400) {
            this.dispatch({
              type: 'CREATE_FORMULA_SUCCESS',
              payload: result.data.data.message,
            });
          } else {
            // * reseting the form
            // this.updateFormData({
            //   formTitle: 'Create Formula',
            //   formulaName: '',
            //   formulaDescription: '',
            //   pageNumber: 1,
            //   action: {
            //     submit: false,
            //     close: false,
            //     draft: false,
            //     quickTest: false,
            //   },
            //   primarySecurity: {
            //     nickname: '',
            //     instrument: '',
            //     symbol: '',
            //     expiry: '',
            //     strike: '',
            //   },
            //   supportingSecurity: {
            //     show: false,
            //     supportArray: [],
            //   },
            //   formula: '',
            // });
            // * Used to close the popup
            this.closePopup();
            this.dispatch({
              type: 'CREATE_FORMULA_SUCCESS',
              payload: result.data.data.message,
            });
          }
          break;
        case 'DRAFT':
          let tempMessageBody1 = { ...messageBody };
          tempMessageBody1.securityList = tempMessageBody1.securityList.map(
            (item) => {
              return {
                ...item,
                instrument: instruments.filter(
                  (item1) => item1.name === item.instrument
                )[0].key,
              };
            }
          );
          const result1 = await ManageFormulaAPI(
            'SAVE_DRAFT',
            tempMessageBody1
          );
          if (result1.status === 400) {
            this.dispatch({
              type: 'CREATE_FORMULA_SUCCESS',
              payload: result1.data.data.message,
            });
          } else {
            // * reseting the form
            // this.updateFormData({
            //   formTitle: 'Create Formula',
            //   formulaName: '',
            //   formulaDescription: '',
            //   pageNumber: 1,
            //   action: {
            //     submit: false,
            //     close: false,
            //     draft: false,
            //     quickTest: false,
            //   },
            //   primarySecurity: {
            //     nickname: '',
            //     instrument: '',
            //     symbol: '',
            //     expiry: '',
            //     strike: '',
            //   },
            //   supportingSecurity: {
            //     show: false,
            //     supportArray: [],
            //   },
            //   formula: '',
            // });
            // * Used to close the popup
            this.closePopup();
            this.dispatch({
              type: 'CREATE_FORMULA_SUCCESS',
              payload: result1.data.data.message,
            });
          }
          break;
        case 'MODIFY':
          let tempMessageBody = { ...messageBody };
          tempMessageBody.securityList = tempMessageBody.securityList.map(
            (item) => {
              return {
                ...item,
                instrument: instruments.filter(
                  (item1) => item1.name === item.instrument
                )[0].key,
              };
            }
          );

          const result2 = await ManageFormulaAPI('MODIFY', {
            ...tempMessageBody,
            id: fetchValue(data, 'id'),
          });
          if (result2.status === 400) {
            this.dispatch({
              type: 'CREATE_FORMULA_SUCCESS',
              payload: result2.data.data.message,
            });
          } else {
            // * reseting the form
            // this.updateFormData({
            //   formTitle: 'Create Formula',
            //   formulaName: '',
            //   formulaDescription: '',
            //   pageNumber: 1,
            //   action: {
            //     submit: false,
            //     close: false,
            //     draft: false,
            //     quickTest: false,
            //   },
            //   primarySecurity: {
            //     nickname: '',
            //     instrument: '',
            //     symbol: '',
            //     expiry: '',
            //     strike: '',
            //   },
            //   supportingSecurity: {
            //     show: false,
            //     supportArray: [],
            //   },
            //   formula: '',
            // });
            // * Used to close the popup
            this.closePopup();
            this.dispatch({
              type: 'CREATE_FORMULA_SUCCESS',
              payload: result2.data.data.message,
            });
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error.message);
      this.closePopup();
      this.dispatch({
        type: 'CREATE_FORMULA_SUCCESS',
        payload: error.message,
      });
    }
  }

  async saveAsDraft(data) {}

  hideFeedbackPopup(msg = '') {
    this.dispatch({ type: 'CREATE_FORMULA_SUCCESS', payload: msg });
  }

  async showEditFormula(id) {
    const formula = await fetchFormulaAndMorph(id, true);
    this.dispatch({
      type: 'CREATE_FORMULA_SET_FORM_DATA',
      payload: formula,
    });
    this.dispatch({ type: 'CREATE_FORMULA_OPEN_POPUP' });
  }

  async showCopyFormula(id) {
    const formula = await fetchFormulaAndMorph(id, true);

    this.dispatch({
      type: 'CREATE_FORMULA_SET_FORM_DATA',
      payload: { ...formula, id: undefined, formTitle: 'Create Formula' },
    });
    this.dispatch({ type: 'CREATE_FORMULA_OPEN_POPUP' });
  }

  async deleteFormula(id, pg) {
    const result = await ManageFormulaAPI('DELETE_FORM', { id, pageNum: pg });
    this.dispatch({
      type: 'CREATE_FORMULA_SUCCESS',
      payload: result.data.data.message,
    });
  }

  resetActions() {
    this.dispatch({ type: 'CREATE_FORMULA_RESET' });
  }

  changePage(pg) {
    this.dispatch({ type: 'CREATE_FORMULA_SET_PAGE_NUMBER', payload: pg });
  }

  async quickTest(dataToTest) {
    try {
      let instruments = await MakeRequest('/security/instrumenTypes').get();
      instruments = instruments.data.data;

      const messageBody = {
        id: fetchValue(dataToTest, 'id'),
        name: fetchValue(dataToTest, 'formulaName'),
        description: fetchValue(dataToTest, 'formulaDescription'),
        pageNum: fetchValue(dataToTest, 'pageNumber'),
        formula: fetchValue(dataToTest, 'formula'),
        securityList: [
          {
            nickname: fetchValue(dataToTest, 'primarySecurity.nickname'),
            exchange: 'NSE',
            instrument: instruments.filter(
              (item) =>
                item.name ===
                fetchValue(dataToTest, 'primarySecurity.instrument')
            )[0].key,
            symbol: fetchValue(dataToTest, 'primarySecurity.symbol'),
            expiry: fetchValue(dataToTest, 'primarySecurity.expiry'),
            strike: fetchValue(dataToTest, 'primarySecurity.strike'),
            isPrimary: true,
          },
        ],
      };
      for (let obj of fetchValue(
        dataToTest,
        'supportingSecurity.supportArray'
      )) {
        messageBody.securityList.push({
          nickname: fetchValue(obj, 'nickname'),
          exchange: 'NSE',
          instrument: instruments.filter(
            (item) => item.name === fetchValue(obj, 'instrument')
          )[0].key,
          symbol: fetchValue(obj, 'symbol'),
          expiry: fetchValue(obj, 'expiry'),
          strike: fetchValue(obj, 'strike'),
          isPrimary: false,
        });
      }
      const response = await MakeRequest(
        '/customMarketWatch/formula/quickTest'
      ).post(messageBody);
      this.dispatch({
        type: 'CREATE_FORMULA_SET_FEEDBACK',
        payload: fetchValue(response, 'data.data.message'),
      });
    } catch (error) {
      console.log(error.message);
    }
  }
}

export { CreateFormulaFormPopup, CreateFormulaActions };

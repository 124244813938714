import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { Modal } from 'react-bootstrap';
import './FileUpload.scss';
import closeIcon from '../../assets/img/closeIcon.svg';
import exIcon from '../../assets/img/ex-icon.png';
import uploadIcon from '../../assets/img/upload-icon.png';
import { url } from '../../config/apiConfig';
import axiosApi from './Api';
import axios from 'axios';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { ProgressBar } from 'react-bootstrap';
import greentick from '../../../src/assets/img/icons8-checked.svg';
import deleteTick from '../../../src/assets/img/icons8-cancel.svg';
import { GenericPopup } from '../../components';
import styled from 'styled-components';

const UploadCont = styled.div`
  height: fit-content;
  min-width: 40vw;
  width: fit-content;
  display: flex;
  flex-direction: row;
`;

const FileUploadComponent = ({
  show,
  setShow,
  screenType,
  quickUpload,
  strategyId,
  setSuccessUpload = () => {},
  rulePostBody = {},
  callBackFunc,
}) => {
  const [fileData, setFileData] = useState([]);
  const [excelData, setExcelData] = useState(false);
  const [uploadMsg, setUploadMsg] = useState([]);
  const [percent, setPercent] = useState(0);
  const [uploadStatus, setUploadStatus] = useState({
    pass: false,
    fail: false,
    drop: false,
  });
  const [reUpload, setReUpload] = useState(false);

  const handleClose = () => {
    setShow(false);
    setUploadStatus({ pass: false, fail: false, drop: false });
    setExcelData(false);
    setUploadMsg([]);
    setPercent(0);
    setReUpload(false);
    setFileData(null);
  };

  const getExcelFunction = async () => {
    await axiosApi
      .getBlob(
        `${url}/excel/create/${screenType}/${quickUpload ? 'quick/' : ''}${
          strategyId ? strategyId : ''
        }`
      )
      .then((res) => {
        if (res.status == 200) {
          var file = new Blob([new Uint8Array(res.data.data.data).buffer], {
            type: 'application/vnd.ms-excel',
          });
          var fileURL = URL.createObjectURL(file);

          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute(
            'download',
            `${screenType}${strategyId ? '_' + strategyId : ''}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const fileParams = ({ meta, file, allFiles }) => {
    const token = localStorage.getItem('token');
    let fileObj = new FormData();

    // In Case of Rule Instead of rule/<ruleId> pas rule/
    if (screenType && screenType.substr(0, 4) === 'rule') {
      screenType = 'rule';
    }

    fileObj.append('excelData', file);
    const specialRuleStatus = rulePostBody.specialRule;
    rulePostBody.specialRule =
      rulePostBody.quickUpload && rulePostBody.specialRule == 'TWAP'
        ? 'TWAP'
        : false;
    fileObj.append('body', JSON.stringify(rulePostBody));
    rulePostBody.specialRule = specialRuleStatus;
    setUploadStatus({ pass: false, fail: false, drop: false });
    setUploadMsg([]);
    setReUpload(true);
    setPercent(0);

    axios
      .post(`${url}/excel/upload/${screenType}`, fileObj, {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercent(percentCompleted);
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setUploadStatus({ ...uploadStatus, pass: true, fail: false });
          if (setSuccessUpload) {
            callBackFunc(res.data.data);
            setSuccessUpload(true);
          }
        }
      })
      .catch((err) => {
        if (err.status != 200) {
          setUploadStatus({ ...uploadStatus, fail: true, pass: false });

          if (
            err.response &&
            err.response.data &&
            err.response.data.data &&
            Object.keys(err.response.data.data).includes('data')
          ) {
            setUploadMsg(err.response.data.data.data);
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.data &&
            !Object.keys(err.response.data.data).includes('data')
          ) {
            setUploadMsg([{ message: err.response.data.data.message }]);
          }
        }
      });
  };

  const onFileChange = ({ meta, file }, status, allFiles) => {
    let fileObj = new FormData();
    fileObj.append('excelData', file);
    if (status == 'rejected_file_type') {
      setPercent(100);
      setUploadStatus({ ...uploadStatus, fail: true });
      setUploadMsg([
        { message: 'File Not supported! Please upload excel only.' },
      ]);
    }
    if (status === 'getting_upload_params') {
      setTimeout(() => {
        allFiles.forEach((f) => f.remove());
      }, 1000);
    }
    setFileData(file);
    setExcelData(true);
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const textMsg =
      files.length > 0 || reUpload ? 'Upload Again' : 'Select excel file';
    return (
      <>
        <label className="btn btn-danger mt-4">
          <img src={exIcon} />
          {textMsg}
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              try {
                getFilesFromEvent(e).then((chosenFiles) => {
                  onFiles(chosenFiles);
                });
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </label>
        <p className="or">OR</p>
        <label className="dropfile btn">
          <img src={uploadIcon} /> Drop excel file
        </label>
        <p className="downloadTemplate">
          DON’T HAVE EXCEL TEMPLATE?
          <br />
          <a href="javascript:void(0)" onClick={() => getExcelFunction()}>
            DOWNLOAD TEMPLATE
          </a>
        </p>
      </>
    );
  };

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }) => {
    return (
      <div className="fixed-excel-layout">
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

        {files.length > 0 && input}
        {fileData && fileData.name ? customPreview() : null}
      </div>
    );
  };

  const customPreview = () => {
    return (
      <>
        <h6>Uploaded File</h6>
        <div className="custom-preview">
          <span className="file-name">{fileData.name}</span>
          <span className="percent-symbol">{percent}%</span>
        </div>
      </>
    );
  };
  return (
    <GenericPopup state={show} toggleState={() => handleClose()} type={1}>
      <UploadCont className="upload-Modal">
        <div className="excelContent">
          <div className="modalHeader">
            <div className="closeWrapper">
              <img src={closeIcon} alt="img" onClick={() => handleClose()} />
            </div>
            <h1>Upload your Excel</h1>
          </div>
          <Modal.Body>
            <Dropzone
              onChangeStatus={onFileChange}
              InputComponent={selectFileInput}
              getUploadParams={fileParams}
              LayoutComponent={Layout}
              getDroppedOrSelectedFiles={fileParams}
              getFilesFromEvent={getFilesFromEvent}
              accept=".csv, .xlsx"
              maxFiles={1}
              inputContent="Drop A File"
              styles={{
                dropzone: {},
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            <div className="custom-progress-div">
              {excelData ? (
                <>
                  <ProgressBar
                    now={percent}
                    variant="warning"
                    className={
                      percent == 0
                        ? 'custom-progress-grey'
                        : percent > 0 && percent < 70
                        ? 'custom-progress-yellow'
                        : uploadStatus.pass
                        ? 'custom-progress-green'
                        : percent == 100 && uploadStatus.fail
                        ? 'custom-progress-fail'
                        : ''
                    }
                  />
                  <div>
                    {uploadStatus.pass ? (
                      <img
                        src={greentick}
                        className="file-pass-img"
                        alt="img"
                      />
                    ) : uploadStatus.fail ? (
                      <img
                        src={deleteTick}
                        className="file-fail-img"
                        alt="img"
                      />
                    ) : null}{' '}
                  </div>
                </>
              ) : null}
            </div>
          </Modal.Body>
        </div>
        {uploadMsg && uploadMsg.length > 0 ? (
          <div className="excelError">
            <h3>{uploadMsg.length == 1 ? 'Error !' : 'Errors !'}</h3>
            <div className="listing-error">
              {uploadMsg && uploadMsg.map((el) => <span>{el.message}</span>)}
            </div>
          </div>
        ) : null}
      </UploadCont>
    </GenericPopup>
  );
};

export default React.memo(FileUploadComponent);

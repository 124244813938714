import React, { useEffect, useState } from 'react';
import './styles.css';
import { DropdownIcon } from '../../../assets';
import ManageFormula from '../../sharedComponents/customComponent/tableSettings/ManageFormula';
import {
  CustomMarketWatchSecuritySearch,
  TableGen2,
} from '../../../components';
import useSwr, { mutate } from 'swr';
import { MakeRequest } from '../../../utils';
import { webSocketUrl } from '../../../config/apiConfig';
import { useDispatch } from 'react-redux';
import { CreateFormulaActions } from '../../../reducers/createFormulaForm';
import { fetchValue } from '../../../utils/dotKeyFunctions';

const W3CWebSocket = require('websocket').w3cwebsocket;

export default function CustomMarketWatch() {
  const dispatch = useDispatch();
  const [customMarketWatchState, setCustomMarketWatchState] = useState({
    searchParams: {
      searchValue: '',
      addedSecurities: {},
    },
  });

  const [tableData, setTableData] = useState({
    header: [],
    data: [],
  });

  const [tablePagination, setTablePagination] = useState({
    pagesCount: 5,
    currentSelect: 1,
  });

  const [wsClient, setWsCient] = useState();
  const [refreshWS, setRefreshWS] = useState(false);

  const pageChangeAction = new CreateFormulaActions(dispatch);

  useEffect(() => {
    pageChangeAction.changePage(tablePagination.currentSelect);
  }, [tablePagination.currentSelect]);

  const [
    subSecurities,
    subSecuritiesError,
    subSecuritesLoading,
  ] = APICalls.GetCurrentSecurities(tablePagination.currentSelect);

  const [
    pageFormulas,
    pageFormulaError,
    setPageFormula,
  ] = APICalls.GetAllFormulas(tablePagination.currentSelect);

  const [permission] = APICalls.GetPermission();

  function updateTableData() {
    const tempTData = {
      header: [
        {
          objKey: 'exchange',
          label: 'Exchange',
          headExtraStyle: { minWidth: '10vw' },
          headerAlignment: 'left',
          contentAlignment: 'right',
          contentExtraStyle: { color: 'yellow' },
        },
        {
          objKey: 'datafeedTicker',
          label: 'Security',
          headExtraStyle: { minWidth: '20vw' },
          headerAlignment: 'left',
          contentAlignment: 'right',
          contentExtraStyle: {},
        },
        {
          objKey: 'ltt',
          label: 'LTT',
          headExtraStyle: { minWidth: '10vw' },
          headerAlignment: 'left',
          contentAlignment: 'right',
          contentExtraStyle: {},
        },
      ],
      data: [],
    };
    if (pageFormulas && subSecurities) {
      for (let formula of pageFormulas) {
        if (formula.stateEnum === 1) {
          tempTData.header.push({
            objKey: formula?.id,
            label: formula?.name,
            headExtraStyle: { minWidth: '10vw' },
            headerAlignment: 'left',
            contentAlignment: 'right',
            contentExtraStyle: {},
          });
        }
      }
      for (let item of subSecurities.data) {
        tempTData.data.push(item);
      }
      setTableData(tempTData);
    }
  }

  function refreshTable() {
    mutate(
      `/customMarketWatch/watchlist/getSecurities/${tablePagination.currentSelect}`
    );
    mutate(
      `/customMarketWatch/formula/getAllFormula/${tablePagination.currentSelect}`
    );

    if (wsClient && wsClient.OPEN) {
      // Reinit
      wsClient.close();
      setRefreshWS(true);
    }
  }

  useEffect(() => {
    updateTableData();
  }, [pageFormulas, subSecurities]);

  const [websocketData, setWebsocketData] = useState();
  const blueShade = '#067BD8';
  const redShade = '#EA4242';

  useEffect(() => {
    const tempTableData = { ...tableData };
    for (let i = 0; i < tempTableData.data.length; i++) {
      //if (parseFloat(websocketData.payload.data[tempTableData.data[i].esteeId]) > tempTableData.data[i])
      tempTableData.data[i] = {
        ...tempTableData.data[i],
        // ...websocketData.payload.data[tempTableData.data[i].esteeId],
      };

      if (
        websocketData &&
        websocketData.payload &&
        websocketData.payload.data &&
        tempTableData.data[i] &&
        tempTableData.data[i].esteeId &&
        websocketData.payload.data[tempTableData.data[i].esteeId]
      ) {
        for (let fKey of Object.keys(
          websocketData.payload.data[tempTableData.data[i].esteeId]
        )) {
          if (tempTableData.data[i] && tempTableData.data[i][fKey]) {
            if (
              tempTableData.data[i][fKey].value <
              websocketData.payload.data[tempTableData.data[i].esteeId][fKey]
            ) {
              tempTableData.data[i][fKey].color = blueShade;
            } else {
              if (!tempTableData.data[i][fKey])
                tempTableData.data[i][fKey] = {};
              tempTableData.data[i][fKey].color = redShade;
            }
          } else {
            if (!tempTableData.data[i][fKey]) tempTableData.data[i][fKey] = {};
            tempTableData.data[i][fKey].color = blueShade;
          }
          if (!tempTableData.data[i][fKey]) tempTableData.data[i][fKey] = {};
          tempTableData.data[i][fKey].value =
            websocketData.payload.data[tempTableData.data[i].esteeId][fKey];
        }
      }
    }
  }, [websocketData]);

  useEffect(() => {
    const client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    setWsCient(client);
    setRefreshWS(false);
    client.onopen = () => {
      let userId = localStorage.getItem('customerId');
      const request = {
        eventType: 'CUSTOM_MW_SUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          pageNumber: tablePagination.currentSelect,
        },
      };
      client.send(JSON.stringify(request));
    };
    client.onerror = () => {};
    client.onclose = () => {};
    client.onmessage = (data) => {
      const responseData = JSON.parse(data.data);
      setWebsocketData(responseData);
    };
    return () => {
      client.close();
    };
  }, [tablePagination.currentSelect, refreshWS]);

  return (
    <div className="custom-marketwatch-container">
      <TopBar
        data={{
          ...customMarketWatchState,
          searchParams: {
            ...customMarketWatchState.searchParams,
            pageNum: tablePagination.currentSelect,
          },
        }}
        targetKey="searchParams"
        setData={(val) => setCustomMarketWatchState(val)}
        selectedSecurities={subSecurities}
        refreshTable={refreshTable}
        permission={permission}
      />
      <TableGen2
        data={tableData}
        tableSettings={{
          themeMode: 'dark',
          tableStyles: { width: '100%' },
          bordered: true,
        }}
        tableAreaHeight="55vh"
        containerHeight="60vh"
        pagination={tablePagination}
        setPagination={(val) =>
          setTablePagination({ ...tablePagination, currentSelect: val })
        }
      />
    </div>
  );
}

function TopBar({
  data,
  targetKey,
  setData,
  selectedSecurities,
  refreshTable,
  permission,
}) {
  return (
    <div className="custom-market-watch-topBar">
      <CustomDropdown permission={permission} />
      <CustomMarketWatchSecuritySearch
        data={data}
        objKey={targetKey}
        setData={(val) => setData(val)}
        selectedSecurities={selectedSecurities}
        refreshTable={refreshTable}
        disable={permission[1].access < 2}
      />
    </div>
  );
}

function CustomDropdown({ permission }) {
  const [openState, setOpenState] = useState(false);
  const usePermission = permission[1];

  return (
    <div
      className="custom-market-watch-ddContainer"
      onClick={() => {
        if (usePermission.access > 1) {
          setOpenState(true);
        }
      }}
    >
      <span className="custom-market-watch-dd-title">Manage Formula</span>
      <img
        src={DropdownIcon}
        alt="dropdownIcon"
        className="custom-market-watch-dd-icon"
      />
      {openState && (
        <ManageFormula
          setOpen={(val) => setOpenState(val)}
          extraStyles={{ left: 0, top: 0 }}
          backDisabled={true}
        />
      )}
    </div>
  );
}

const APICalls = {
  GetCurrentSecurities: (pageNumber) => {
    const { data, error, isLoading } = useSwr(
      `/customMarketWatch/watchlist/getSecurities/${pageNumber}`,
      async () => {
        const result = await MakeRequest(
          `/customMarketWatch/watchlist/getSecurities/${pageNumber}`
        ).get();

        return result.data.data;
      }
    );
    return [data, error, isLoading];
  },
  GetAllFormulas: (pageNumber) => {
    const { data, error, isLoading } = useSwr(
      `/customMarketWatch/formula/getAllFormula/${pageNumber}`,
      async () => {
        const result = await MakeRequest(
          `/customMarketWatch/formula/getAllFormula/${pageNumber}`
        ).get();

        return result.data.data.data;
      }
    );
    return [data, error, isLoading];
  },
  GetPermission: () => {
    const { data, error, isLoading } = useSwr('/ops/view?qType=U', async () => {
      const userId = localStorage.getItem('customerId');
      const data = await MakeRequest(
        `/ops/view?qType=U&itemId=${userId}`
      ).get();

      return [
        fetchValue(data, 'data.data.data.menu.2.subMenu.2'),
        fetchValue(data, 'data.data.data.menu.2.subMenu.3'),
      ];
    });
    return [data, error, isLoading];
  },
};

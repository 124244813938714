import React, { useState } from 'react';
import './styles.css';
import { GenButton, GenericPopup } from '../index';
import AppLogo from '../../assets/img/StradeLogo.svg';
import SideMenuIcon from '../../assets/img/Sidemenu_icon.svg';
import LoginWrapper from '../../viewScreen/loginScreens';
import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from '../../reducers/loginReducer';

const LPHeader = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { popupState } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const handleScrollButtonClick = (refToDiv) => {
    refToDiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleSideMenuClick = () => {
    setShowDrawer(true);
  };

  const handleAboutUsButtonClick = () => {
    const url = 'https://esteeadvisors.com/board-of-directors.php';
    window.open(url);
  };

  const scrollButtonStyle = {
    // back: {
    //   width: 'fit-content',
    // },
    text: {
      font: 'normal normal medium 18px/31px Poppins',
      color: '#171725',
    },
  };

  return (
    <div className="lp-header">
      <img
        src={SideMenuIcon}
        className="sidemenu-logo"
        onClick={() => handleSideMenuClick()}
        alt="Icon"
      />
      <img src={AppLogo} className="application-logo" alt="Icon" />
      <div className="navButtons">
        <span
          className="nav-item"
          onClick={() => handleScrollButtonClick(props.productAreaRef)}
        >
          Product Offerings
        </span>
        <span
          className="nav-item"
          onClick={() => handleScrollButtonClick(props.tradingInfraRef)}
        >
          Trading Infrastructure
        </span>
        <span className="nav-item" onClick={() => handleAboutUsButtonClick()}>
          About Us
        </span>
      </div>
      <Drawer
        anchor="left"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          sx: { width: '75vw' },
        }}
      >
        <img src={AppLogo} className="application-logo-drawer" />
        <div
          className="sideButtons"
          onClick={() => setShowDrawer(false)}
          onKeyDown={() => setShowDrawer(false)}
        >
          <span
            className="side-item"
            onClick={() => handleScrollButtonClick(props.productAreaRef)}
          >
            Product Offerings
          </span>
          <span
            className="side-item"
            onClick={() => handleScrollButtonClick(props.tradingInfraRef)}
          >
            Trading Infrastructure
          </span>
          <span
            className="side-item"
            onClick={() => handleAboutUsButtonClick()}
          >
            About Us
          </span>
        </div>
      </Drawer>
      {/* <GenButton
        fill={true}
        title="SCHEDULE A DEMO"
        extStyle={{ marginRight: '5vw' }}
        clickAction={props.handleClick}
      />
      <GenButton
        title="LOGIN"
        fill={true}
        extStyle={{ marginRight: '5vw' }}
        clickAction={() => loginActions.showPopup(dispatch)}
      /> */}

      <GenericPopup
        type={1}
        state={popupState}
        toggleState={() => {
          localStorage.removeItem('token');
          loginActions.hidePopup(dispatch);
        }}
      >
        <LoginWrapper />
      </GenericPopup>
    </div>
  );
};

export default LPHeader;

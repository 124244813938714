import { useState, useRef, useEffect } from 'react';
import './styles.css';
import {
  DropdownIcon,
  PlusCircle,
  Copy_role_Icon,
  Edit_role_Icon,
} from '../../../../assets';
import { GenSearchBox } from '../../../../components';

export default function ManageRoleDD(props) {
  const { roles, click = (type, id) => {} } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [search, setSearch] = useState('');
  const ref = useRef();

  useEffect(() => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);

  function copyUserRole(id) {
    click(1, id);
  }

  function editUserRole(id) {
    click(2, id);
  }

  return (
    <div className="manage-role-container">
      <div
        className="manage-role-button-container"
        onClick={() => setIsVisible(true)}
      >
        <span className="manage-role-text">MANAGE ROLE</span>
        <img
          src={DropdownIcon}
          alt="Dropdown"
          className="manage-role-icon image-blue-filter"
        />
      </div>
      {isVisible && (
        <div className="manage-role-dd-container" ref={ref}>
          <div className="manage-role-dd-s1">
            <GenSearchBox
              version={2}
              extStyles={{ margin: '0.5vh 0.4vw 0.5vh 0.4vw' }}
              message="Search"
              changeListener={(val) => setSearch(val)}
              value={search}
            />
            <div className="manage-role-add-cont" onClick={() => click(0, -1)}>
              <img
                src={PlusCircle}
                className="manage-role-add-icon"
                alt="roles"
              />
              <span className="manage-role-add-text">Add Role</span>
            </div>
          </div>
          <div className="manage-role-dd-select-area">
            {roles
              .filter(
                (role) =>
                  search === '' || role.title.toLowerCase().includes(search)
              )
              .map((item) => (
                <DDMenuOption
                  title={item.title}
                  callbackCopy={copyUserRole}
                  callbackEdit={editUserRole}
                  id={item.id}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

function DDMenuOption(props) {
  const { title, callbackCopy, callbackEdit, id } = props;
  const [hover, setHover] = useState(false);
  return (
    <div
      className={`dd-manage-role-sb${hover ? '-hover' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span className="ddmenuOption-title">{title}</span>
      {hover && (
        <img
          src={Copy_role_Icon}
          className="dd-menu-option-icon-hover-1"
          onClick={() => callbackCopy(id)}
          alt="Copy"
        />
      )}
      {hover && (
        <img
          src={Edit_role_Icon}
          className="dd-menu-option-icon-hover"
          onClick={() => callbackEdit(id)}
          alt="Edit"
        />
      )}
    </div>
  );
}

import { fetchValue } from '../utils/dotKeyFunctions';

const initialState = {
  strategyTemplateForm: {
    name: '',
    description: '',
    algoId: {
      CM: '',
      FO: '',
      CD: '',
    },
    sortingType: '',
    sortColumn: '',
    exchange: '',
    securityFilter: 'ALL',
    securities: [],
    securityConstraint: {},
    constants: [],
    entryLegs: [
      {
        legId: 1,
        condition: [
          {
            type: 'OPERAND',
            trigger: '',
          },
        ],
        position: [
          {
            orientation: 'Fwd',
            direction: 'BUY',
            security: '',
            pricing: [],
            quantity: [],
            totalQuantityLogic: [],
            lotSize: [],
            orderQty: '',
            totalQty: '',
          },
        ],
        algoExecution: [],
      },
    ],
    exitCondition: {
      condition: [
        {
          type: 'OPERAND',
          trigger: '',
        },
      ],
      action: {
        enumName: '',
        args: [],
      },
    },
    isWrittenEquation: true,
    analyzerCondition: {},
    constraintData: {},
  },
  nicknameUsed: [],
  instrumentOptions: [],
  securityNameOptions: [],
  expiresOptions: [[]],
  strikeOptions: [],
  exchangeOptions: [],
  positionOptions: [],
  marketTriggeredOperators: [],
  marketCondition: [],
  dataConditions: {},
  conditions: [],
  operation: [],
  exitConditions: [],
  sqActions: [],
  algo: [],
  pricingCondition: [],
  quantityCondition: [],
  totalQtyCondition: [],
  pricingQuantOperation: [],
  clearStats: false,
  prevPrice: [],
  exitToggle: false,
  errorInExit: [],
  pricingLogic: [],
  strategyView: [],
  strategyViewEditData: [],
  isEdit: false,
  modifyId: '',
  isCopy: false,
};

const strategyBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EXCHANGE':
      return {
        ...state,
        exchangeOptions: action.payload,
      };
    case 'SET_ALLSECURITYNAME':
      state.securityNameOptions = action.payload;
      return {
        ...state,
      };
    case 'GET_INSTRUMENT':
      return {
        ...state,
        instrumentOptions: action.payload,
      };
    case 'GET_SECURITY_NAME':
      state.securityNameOptions[action.index] = action.payload;
      return {
        ...state,
      };
    case 'GET_BUILDER':
      return {
        ...state,
        dataConditions: action.payload,
      };
    case 'GET_EXPIRES':
      state.expiresOptions[action.index] = action.payload;
      return {
        ...state,
      };

    case 'GET_STRIKES':
      state.strikeOptions[action.index] = action.payload;
      return {
        ...state,
      };

    case 'SET_USED_NICKNAME':
      return {
        ...state,
        nicknameUsed: action.payload,
      };

    case 'UPDATE_STRATEGY_FORM':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };

    case 'GET_CONDITIONS':
      try {
        let mainArr = [];
        let operations = [];
        let marketArr = [];
        let exitArr = [];
        let marketTriggeredOperatorsArr = [];
        let sqOffActions = [];
        let algoArr = [];
        let pricingArr = [];
        let quantityArr = [];
        let totalQtyArr = [];
        let pqOperations = [];
        let pricingCond = [];
        let uniqueTriggerOperators = [];
        if (state.dataConditions) {
          mainArr = state.dataConditions.condition.entryConditions.map((el) => {
            let obj = Object.keys(state.dataConditions.condition.conditions);
            let newObj = obj.find((elm) => elm === el);
            return {
              key: el,
              data: state.dataConditions.condition.conditions[`${newObj}`],
            };
          });
          let bracketObj = {
            type: 'bracket',
            displayName: 'Bracket',
            enumName: 'BRACKET',
            values: [
              {
                enumName: 'EA_OPERATOR_OPEN',
                displayName: '(',
                type: 'bracket',
              },
              {
                enumName: 'EA_OPERATOR_CLOSE',
                displayName: ')',
                type: 'bracket',
              },
            ],
          };
          algoArr = [
            {
              name: state.dataConditions.algoExecution.displayName,
              data: state.dataConditions.algoExecution.operands.indicators,
            },
          ];
          operations = state.dataConditions.condition.operation.values;
          pqOperations.push(state.dataConditions.position.pricing.operators);
          pqOperations.push(bracketObj);

          marketArr = [
            {
              name: 'Indicator',
              data: state.dataConditions.condition.conditions.marketTriggered
                .operands.indicators,
            },
            {
              name: 'Number',
              data: fetchValue(
                state,
                'dataConditions.condition.conditions.marketTriggered.operands.numbers.args.0.0.values'
              ),
            },
            { name: 'Bracket', data: bracketObj.values },
          ];

          pricingArr = [
            {
              name: 'Indicator',
              data: state.dataConditions.position.pricing.operands.indicators,
            },
            {
              name: 'Number',
              data: state.dataConditions.position.pricing.operands.numbers
                .args[0][0].values,
            },
            { name: 'Bracket', data: bracketObj.values },
          ];
          quantityArr = [
            {
              name: 'Indicator',
              data: state.dataConditions.position.quantity.operands.indicators,
            },
            { name: 'Bracket', data: bracketObj.values },
          ];
          totalQtyArr = [
            {
              name: 'Indicator',
              data: state.dataConditions.position.pricing.operands.indicators,
            },
            { name: 'Bracket', data: bracketObj.values },
          ];
          if (
            fetchValue(
              state,
              'dataConditions.position.quantity.operands.numbers.args.0.0.values',
              false
            )
          ) {
            let num = {
              name: 'Number',
              data: state.dataConditions.position.quantity.operands.numbers
                ?.args[0][0].values,
            };
            quantityArr.push(num);
          }
          marketTriggeredOperatorsArr =
            state.dataConditions.condition.conditions.marketTriggered.operators;

          marketTriggeredOperatorsArr.push(bracketObj);
          uniqueTriggerOperators = [
            ...new Map(
              marketTriggeredOperatorsArr.map((item) => [item['type'], item])
            ).values(),
          ];
          exitArr = state.dataConditions.condition.exitConditions.map((elm) => {
            let newObj = Object.keys(state.dataConditions.condition.conditions);
            let finalObj = newObj.find((i) => i === elm);
            return {
              key: elm,
              data: state.dataConditions.condition.conditions[`${finalObj}`],
            };
          });
          let newsqOff = state.dataConditions.actions.indicators.map((i) => {
            return {
              type: 'sqOff',
              name: i.displayName,
              enumName: i.enumName,
              data: [],
              args: i.args,
            };
          });
          sqOffActions = newsqOff;
          pricingCond = state.dataConditions.actions.indicators.map((i) => {
            return {
              type: 'sqOff',
              name: i.displayName,
              enumName: i.enumName,
              data: [],
              args: i.args[0],
            };
          });
        }
        return {
          ...state,
          conditions: [...mainArr],
          operation: [...operations],
          marketCondition: [...marketArr],
          marketTriggeredOperators: [...uniqueTriggerOperators],
          exitConditions: [...exitArr],
          sqActions: sqOffActions,
          algo: algoArr,
          pricingCondition: pricingArr,
          quantityCondition: quantityArr,
          totalQtyCondition: totalQtyArr,
          pricingQuantOperation: pqOperations,
          prevPrice: pricingCond,
        };
      } catch (error) {
        console.log(error);
        return {};
      }

    case 'UPDATE_FORM':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };

    case 'GET_TOTALQTY':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };

    case 'UPDATE_EXIT_FORM':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };

    case 'SUBMIT_DATA':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };

    case 'SAVE_AS_DRAFT':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };

    case 'RESET':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };
    case 'CLEAR_CONSTANTS':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };
    case 'UPDATE_CLEAR_STATUS':
      return {
        ...state,
        clearStats: action.payload,
      };
    case 'UPDATE_TOGGLE':
      return {
        ...state,
        exitToggle: action.payload,
      };
    case 'ERROR_IN_EXIT':
      return {
        ...state,
        errorInExit: action.payload,
      };
    case 'GET_PRICING':
      let priceTest = action.payload;
      let pricingLogicArr = [];
      pricingLogicArr = priceTest.indicators?.map((el) => {
        return {
          type: 'pricing',
          enumName: el.enumName,
          name: el.displayName,
          data: [],
          args: el.args,
          description: el.description ? el.description : '',
        };
      });
      return {
        ...state,
        pricingLogic: pricingLogicArr,
      };
    case 'UPDATE_PREVIEW_PRICE':
      return {
        ...state,
        strategyTemplateForm: action.payload,
      };
    case 'STRATEGY_VIEW':
      return {
        ...state,
        strategyView: action.payload,
      };
    case 'EDIT_DATA_FORM':
      return {
        ...state,
        strategyTemplateForm: action.payload,
        isEdit: action.checkEdit,
      };
    case 'SET_EDIT_MODIFY':
      return {
        ...state,
        isEdit: action.payload,
        modifyId: action.id,
      };
    case 'GET_MODIFY_ID':
      return {
        ...state,
        modifyId: action.payload,
      };
    case 'UPDATE_SORT_COLUMN_VIEW':
      return {
        ...state,
        sortingType: action.payload.sortingType,
        sortColumn: action.payload.sortColumn,
      };
    case 'UPDATE_COPY':
      return {
        ...state,
        isCopy: action.payload,
      };
    case 'SB_UPDATE_SECURITIES':
      const tempState = { ...state };
      tempState.strategyTemplateForm.securities = action.payload;
      return tempState;
    case 'SB_UPDATE_SECURITIES_CONSTRAINTS':
      const tempState1 = { ...state };
      tempState1.strategyTemplateForm.securityConstraint = action.payload;
      return tempState1;
    case 'SB_UPDATE_SECURITY_FILTER':
      const tempState2 = { ...state };
      tempState2.strategyTemplateForm.securityFilter = action.payload;
      return tempState2;
    case 'SB_UPDATE_SECURITY_CONSTRAINT':
      return {
        ...state,
        strategyTemplateForm: {
          ...state.strategyTemplateForm,
          constraintData: action.payload,
        },
      };
    default:
      return state;
  }
};

export default strategyBuilderReducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setExitConditions,
} from '../../../../../actions/strategyBuilderAction';
import MultiDropdown2 from '../../../../sharedComponents/MultiDropdown2/MultiDropdown2';
import './action.scss';

const Actions = ({ validator, formSubmit }) => {
  const {
    strategyBuilderReducer: {
      strategyTemplateForm,
      sqActions,
      isEdit
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [inputData, setInputData] = useState({});
  const [editFlagIndex, setEditFlagIndex] = useState(null);
  const [marketForm, setMarketForm] = useState([]);
  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));

  useEffect(()=>{
    if(isEdit && strategyTemplateForm.exitCondition  && strategyTemplateForm.exitCondition.action && strategyTemplateForm.exitCondition.action.enumName !== '' ){
      let finalAction ={
         conditionName:strategyTemplateForm.exitCondition.action.displayName,
         data:strategyTemplateForm.exitCondition.action.enumName,
         type:"OPERAND",
         eqIndex:1,
         arguements:strategyTemplateForm.exitCondition.action.args
       }
       setMarketForm([finalAction])
    }
  },[isEdit])
  
  const marketHandler = (index, data) => {
    let field = {
      enumName: data.data,
      displayName: data.conditionName,
      args: data.arguements,
    };
    dispatch(setExitConditions({ field, key: 'action' }));
    setMarketForm([data]);
  };

  let eqInd = 1;
  const equationHandler = (e, eqIndex) => {};

  return (
    <div>
      <div className="action">
        <p>Action</p>
      </div>
      <div className="square_off">
        <div className="head">
          <p>Square off</p>
        </div>
        <div
          className={
            validator.current.message(
                    'square_off',
                     strategyTemplateForm.exitCondition.action.enumName,
                     'required') && formSubmit
              ? 'error-input dropdown'
              :isApproveReject ? 'approveReject-pointer dropdown': 'dropdown'
          }
        >
         

          <MultiDropdown2
            eqIndex={eqInd}
            listData={sqActions}
            marketForm={marketForm}
            setEditFlagIndex={setEditFlagIndex}
            setMarketForm={setMarketForm}
            marketHandler={marketHandler}
            setInputData={setInputData}
            setSelectedCondition={setSelectedCondition}
            placeHolder={'TOB+x,Market'}
            setEquationValues={equationHandler}
            setStartTime={setStartTime}
            startTime={startTime}
            setEndtime={setEndtime}
            endTime={endTime}
            fieldId={'sqOff'}
            id='squareOff'
          />
          {}
        </div>
      </div>
    </div>
  );
};

export default Actions;

import React, { useEffect, useState } from 'react';
import './EntryCondition.scss';
import {
  displayData,
  updateConditions,
  setStrategyTemplateForm,
  setConditions,
  executeAlgo,
} from '../../../../actions/strategyBuilderAction';
import { useDispatch, useSelector } from 'react-redux';
import AddPosition from './Position/Position';
import AddCondition from './Condition/Condition';
import SimpleSnackbar from '../../../sharedComponents/Toast/Toast';
import Popup from '../../../sharedComponents/Popup/Popup';
import { CONFIRM_ALGO, NOTE_EQ_DELETE } from '../../../../utils/constant';
import {
  AddCircle,
  AddCircleIcon,
  AddLegIcon,
  ArrowDown,
  ArrowUp,
  DeleteIcon,
  EntryConditionIcon,
} from '../../../../assets';
import { ButtonAddLeg } from '../components';
import HeaderStrategyBuilder from '../../../../viewScreen/strategyBuilder/create/strategyAnalyzerFormula/header';
import SubtitleInfo from '../components/SubtitleWithInfo';

const EntryCondition = ({
  validator,
  formSubmit,
  modalClear,
  setModalClear,
}) => {
  const [flag, setFlag] = useState(false);
  const [times, setTime] = useState({ time: 0 });
  const [timesSecond, setTimeSecond] = useState({ time: 0 });
  const [pricing, setPricing] = useState(false);
  const [quantity, setQuantity] = useState(false);
  const [totalQuantityLogic, setTotalQty] = useState(false);
  const [marketForm, setMarketForm] = useState([]);
  const [modalAlgo, setModalAlgo] = useState(false);
  const [algoStatus, setAlgoStatus] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  const [inputs, setInputs] = useState({
    condition: [],
    start: '',
    end: '',
  });
  const {
    strategyBuilderReducer: {
      conditions: data,
      dataConditions: dataMain,
      strategyTemplateForm,
      algo,
      isEdit,
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);
  const [addCondition, setAddCondition] = useState(false);
  const [showCondition, setShowCondition] = useState([]);
  const [showAlgo, setShowAlgo] = useState(false);
  const [showLeg, setShowLeg] = useState({ 0: false });
  const [newData, setNewData] = useState([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    await dispatch(displayData()); //to organise data of strategyBuilder
  };

  useEffect(() => {
    let form = { ...strategyTemplateForm };
    form.isWrittenEquation = true;
    dispatch(setStrategyTemplateForm(form));
  }, []);

  useEffect(() => {
    if (Object.keys(dataMain).length) {
      fetchData();
    }
  }, [dataMain]);

  useEffect(() => {
    let updatedForm = marketForm.map((el) => {
      return {
        type: el.type,
        data: el.data,
        displayName: el.displayName ? el.displayName : el.data,
        description: el.description ? el.description : '',
        arguements: el.arguements,
      };
    });
    let field = updatedForm;
    let legIndex = 0;
    dispatch(setConditions({ field, legIndex, key: 'algoExecution' }));
  }, [marketForm]);
  useEffect(() => {
    if (!showAlgo) {
      setMarketForm([]);
      setAlgoStatus(false);
    }
    setShowCondition(data);
  }, [data, addCondition, showAlgo]);

  useEffect(() => {
    if (
      isEdit &&
      strategyTemplateForm.entryLegs[0].algoExecution &&
      strategyTemplateForm.entryLegs[0].algoExecution.length > 0
    ) {
      let foundArr = [];
      for (let j = 0; j < algo.length; j++) {
        for (let k = 0; k < algo[j].data.length; k++) {
          if (
            algo[j].data[k].enumName ===
            strategyTemplateForm.entryLegs[0].algoExecution[0].data.trim()
          ) {
            let finalObj = {
              ...strategyTemplateForm.entryLegs[0].algoExecution[0],
              conditionName: algo[j].data[k].displayName,
              eqIndex: 0,
            };
            foundArr.push(finalObj);
          }
        }
      }
      setMarketForm(foundArr);
      setShowAlgo(true);
    }
    // if()
  }, [isEdit]);

  useEffect(() => {
    switch (popup.type) {
      case 'modalAlgo':
        setPopup({
          ...popup,
          message: CONFIRM_ALGO,
          buttons: 'doubleButtons',
          note: NOTE_EQ_DELETE,
        });
        break;
    }
  }, [popup.type]);

  const actionOnpopup = (e, type) => {
    if (type == 'CLOSE') {
      setPopup({ state: false });
    } else {
      if (popup.type == 'modalAlgo') {
        handleAlgo();
      }
    }
  };

  useEffect(() => {
    if (strategyTemplateForm.isWrittenEquation) {
      return;
    }
    if (
      quantity ||
      pricing ||
      totalQuantityLogic ||
      strategyTemplateForm.entryLegs.length > 1
    ) {
      setModalAlgo(true);
      setPopup({ type: 'modalAlgo', state: true });
    }
  }, [showAlgo]);

  const handleTimeChange = (e) => {
    setTime({ ...times, time: e });
  };
  const handleTimeChanges = (e) => {
    setTimeSecond({ ...timesSecond, time: e });
  };

  const handleAlgoClick = (e) => {
    if (
      quantity ||
      pricing ||
      totalQuantityLogic ||
      strategyTemplateForm.entryLegs.length > 1
    ) {
      setModalAlgo(true);
      setPopup({ type: 'modalAlgo', state: true });
    }
    setShowAlgo(e.target.checked);
  };
  //execution algo input handler
  const marketHandler = (index, data) => {
    let marketFormData = [...marketForm];
    if (data === 'REMOVE') {
      marketFormData.splice(index, 1);
    } else {
      marketFormData = [data];
    }
    setMarketForm(marketFormData);
  };
  const inputHandler = (e) => {
    if (e.target.name === 'condition' && inputs.condition.length === 1) {
      let obj = { ...inputs };
      let conArr = obj.condition;
      conArr.push(e.target.value);
      setInputs(obj);
    } else if (e.target.name === 'condition') {
      setNewData([...newData, e.target.value]);
      setInputs({ ...inputs, [e.target.name]: [e.target.value] });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };
  //function to add leg
  const addLegHandler = () => {
    if (showAlgo) {
      setFlag(true);
    } else {
      let newObj = {
        legId: strategyTemplateForm.entryLegs.length + 1,
        condition: [
          {
            type: 'OPERAND',
            trigger: '',
          },
        ],
        position: [
          {
            orientation: 'Fwd',
            direction: 'BUY',
            security: '',
            pricing: [],
            quantity: [],
            totalQuantityLogic: [],
          },
        ],
        algoExecution: [],
      };
      let form = { ...strategyTemplateForm },
        idx = newObj.legId;
      setShowLeg({ ...showLeg, idx: true });
      form.entryLegs.push(newObj);
      dispatch(updateConditions(form));
    }
  };
  //function to remove leg
  const handleRemoveLeg = (index) => {
    let form = { ...strategyTemplateForm };
    if (index > -1) {
      form.entryLegs.splice(index, 1);
    }
    dispatch(updateConditions(form));
  };

  const handleCollapseLeg = (index) => {
    let newLeg = { ...showLeg };
    newLeg[index] = !showLeg[index];
    setShowLeg(newLeg);
  };

  //function to add another position
  function handleAdd(index) {
    let form = { ...strategyTemplateForm };
    form.entryLegs[index].position.push({
      orientation: 'Fwd',
      direction: 'BUY',
      security: '',
      pricing: [],
      totalQuantityLogic: [],
      quantity: [],
      lotSize: [],
    });
    dispatch(setStrategyTemplateForm(form));
  }

  const toggleFalse = () => {
    setShowAlgo(false);
  };

  const handleAlgoExec = (e) => {
    setModalAlgo(false);
    setShowAlgo(!showAlgo);
    setAlgoStatus(false);
  };

  const handleAlgo = (e) => {
    setModalAlgo(false);
    setPopup({ state: false });
    dispatch(executeAlgo());
    setAlgoStatus(true);
  };

  return (
    <React.Fragment>
      <div className="step_4">
        <HeaderStrategyBuilder
          icon={EntryConditionIcon}
          title="Define Entry Condition and Take Positions"
          switchState={strategyTemplateForm.isWrittenEquation}
          switchStateToggle={() => {
            let form = { ...strategyTemplateForm };
            form.isWrittenEquation = !form.isWrittenEquation;
            dispatch(setStrategyTemplateForm(form));
          }}
          switchLabel="IsWrittenEquation"
          switchExtStyles={{ margin: 'auto 1rem auto auto' }}
          extStyles={{ margin: '0 0' }}
        />
      </div>

      {strategyTemplateForm.entryLegs?.map((legs, idx) => {
        return (
          <div className="select-ex-algo">
            <div className="leg_1" key={idx}>
              <div className="leg-head">
                <span className="leg-id">{`LEG: ${idx + 1}`}</span>
                <div className="button-cont">
                  <ButtonAddLeg
                    icon={DeleteIcon}
                    label="Delete"
                    clickAction={() => handleRemoveLeg(idx)}
                    category="cat1"
                    extStyles={{ margin: '0 0.5rem' }}
                  />
                  <ButtonAddLeg
                    icon={showLeg[idx] ? ArrowDown : ArrowUp}
                    label={showLeg[idx] ? 'Expand' : 'Collapse'}
                    clickAction={() => handleCollapseLeg(idx)}
                    category="cat1"
                    extStyles={{ margin: '0 0.5rem' }}
                  />
                </div>
              </div>
              <div className={showLeg[idx] ? 'hide' : 'body-container-sbecbd'}>
                <SubtitleInfo
                  label={'Condition'}
                  info={
                    'Define custom entry conditions using technical indicators'
                  }
                />
                <AddCondition
                  legs={legs}
                  conditions={data}
                  legIndex={idx}
                  newData={newData}
                  setNewData={setNewData}
                  showCondition={showCondition}
                  inputHandler={inputHandler}
                  inputs={inputs}
                  setAddCondition={setAddCondition}
                  handleTimeChange={handleTimeChange}
                  handleTimeChanges={handleTimeChanges}
                  times={times}
                  timesSecond={timesSecond}
                  modalClear={modalClear}
                  setModalClear={setModalClear}
                  validator={validator}
                  formSubmit={formSubmit}
                />
                <SubtitleInfo
                  label={'Positions'}
                  info={`Pricing Logic: Computes the real-time price to buy/sell security using technical indicators 
                        Quantity Logic: Computes the real-time quantity to buy/sell using hedging functions
                        Execution Algo: Pre-built Algos which determine both pricing logic and quoting quantity'
                  `}
                  extStyles={{ margin: '0 0 -1rem 0' }}
                />
                <div className={isApproveReject ? 'approveReject-pointer' : ''}>
                  {legs.position &&
                    legs.position.length &&
                    legs.position?.map((positionRow, positionIndex) => {
                      return (
                        <AddPosition
                          algo={algo}
                          showAlgo={showAlgo}
                          setShowAlgo={setShowAlgo}
                          legIndex={idx}
                          positionIndex={positionIndex}
                          strategyTemplateForm={strategyTemplateForm}
                          positionRow={positionRow}
                          setPricing={setPricing}
                          setTotalQty={setTotalQty}
                          setQuantity={setQuantity}
                          algoStatus={algoStatus}
                          validator={validator}
                          formSubmit={formSubmit}
                          id="position"
                        />
                      );
                    })}
                  <div
                    className={
                      isApproveReject ? 'approveReject-pointer add' : 'add'
                    }
                  >
                    <ButtonAddLeg
                      icon={AddCircleIcon}
                      label="Add More"
                      clickAction={() => handleAdd(idx)}
                      category="cat1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="add_leg">
        <ButtonAddLeg
          icon={AddLegIcon}
          label="ADD LEG"
          extStyles={{ margin: '1rem auto' }}
          clickAction={() => addLegHandler()}
        />
        {flag ? (
          <SimpleSnackbar //shared toaster component
            toaster={flag}
            setToaster={setFlag}
            message={'execution algo is selected, cannot add another leg'}
          />
        ) : null}
      </div>
      {popup.state ? (
        <Popup
          popClass="algopopup"
          actionButton={actionOnpopup}
          popupDetails={popup}
        />
      ) : null}
    </React.Fragment>
  );
};

export default EntryCondition;

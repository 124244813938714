import React from 'react';
import CompLabel from '../customComponent/compLabel';
import './styles.css';

export default function Switch(props) {
  const {
    label,
    orientation = 'h',
    state = false,
    onChange = (va) => {},
    extStyles = {},
    disable = false,
  } = props;

  return (
    <span style={extStyles}>
      <CompLabel label={label} orientation={orientation}>
        <div
          className={`Switch-container-${state ? 'active' : 'inactive'}`}
          onClick={() => {
            if (!disable) onChange(!state);
          }}
          style={{ backgroundColor: disable ? '#dedede' : '' }}
        >
          <span className="switch-ball"></span>
          <span className={`switch-text-${state ? 'active' : 'inactive'}`}>
            {state ? 'Yes' : 'No'}
          </span>
        </div>
      </CompLabel>
    </span>
  );
}

import React, { useEffect, useRef } from 'react';
import { Logo } from '../../../assets';
import GenericPopup from '../genPopup';
import './styles.css';
import { GenButton } from '../..';

// * type = f: feedback with only accept action & a: for yes and no action
export default function FeedbackPopup({
  message = '',
  acceptAction = () => {},
  rejectAction = () => {},
  type = 'f',
  defFocus = 'n',
}) {
  return (
    <GenericPopup
      type={1}
      state={message.length !== 0}
      toggleState={type === 'f' ? acceptAction : rejectAction}
    >
      <div className="feedback-popup-main-container">
        <img src={Logo} className="feedback-logo" alt="feedback" />
        <span className="feedback-popupmessage">{message}</span>
        {type === 'f' && (
          <div className="feedback-popup-bottom">
            <GenButton
              uniqueId="FeedbackPopup-yes"
              type="1"
              title="OK"
              fill={true}
              clickAction={acceptAction}
            />
          </div>
        )}
        {type === 'a' && (
          <div className="feedback-popup-bottom">
            <GenButton
              uniqueId="FeedbackPopup-yes"
              type="1"
              title="YES"
              fill={true}
              extStyle={{ width: '8vw' }}
              clickAction={acceptAction}
            />
            <GenButton
              uniqueId="FeedbackPopup-no"
              type="1"
              title="NO"
              fill={false}
              extStyle={{ width: '8vw', marginLeft: '1rem' }}
              clickAction={rejectAction}
              focus={defFocus === 'n'}
            />
          </div>
        )}
      </div>
    </GenericPopup>
  );
}

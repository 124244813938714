import { useDispatch, useSelector } from 'react-redux';
import {
  setGroupedArr,
  updateAlertTable,
  resetNewExpandId,
} from '../../actions/alertAction';

export default function AlertHeader({
  liveData,
  elm,
  makeTree,
  setTreeValue,
  tabType,
}) {
  const {
    alertReducer: { groupedArr },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const groupingHandler = (elm, groupedData, liveData) => {
    let newArr = groupedData;
    if (
      !newArr.find((el) => el.id === elm.fieldEnum && el.tabKey === tabType)
    ) {
      newArr.push({
        id: elm.fieldEnum,
        content: elm.displayName,
        tabKey: tabType,
      });
      dispatch(setGroupedArr([...newArr]));
      let groupedDataObj = makeTree(
        newArr,
        { ...liveData },
        'from alert header=='
      );
      dispatch(updateAlertTable({ groupedDataObj, isGroup: true }));
      dispatch(resetNewExpandId(tabType));
      setTreeValue(groupedDataObj);
    }
  };
  return (
    <span
      className="cursor-pointer"
      onClick={() => {
        groupingHandler(elm, groupedArr, liveData);
      }}
    >
      {elm.displayName}
    </span>
  );
}

const DataModificationFunctions = {
  families_filter_famType: (api_data, selected_types) => {
    let temp_fam_types = {},
      displayable_families = [];
    if (api_data && api_data['allFamilyTypes']) {
      let selected_types_obj = {};
      for (let { id, name } of api_data['allFamilyTypes']) {
        temp_fam_types[name] = id;
      }
      for (let key of Object.keys(selected_types)) {
        selected_types_obj[temp_fam_types[key]] = true;
      }
      displayable_families = api_data.allFamilies
        .filter((item) => selected_types_obj[item.familyTypeId] || false)
        .map((item) => item.name);
    }

    return displayable_families;
  },
  selected_families_filter_famType: (
    api_data,
    selected_types,
    selected_fams
  ) => {
    let famName_to_famtypeID = {};
    if (api_data && api_data['allFamilies']) {
      for (let obj of api_data['allFamilies'])
        famName_to_famtypeID[obj.name] = obj.familyTypeId;
    }
    let selected_famtype_IDs = new Set();
    let selected_famtype_names = new Set(Object.keys(selected_types));
    if (api_data && api_data['allFamilyTypes']) {
      for (let obj of api_data['allFamilyTypes']) {
        if (selected_famtype_names.has(obj.name))
          selected_famtype_IDs.add(obj.id);
      }
    }
    let filtered_families = selected_fams.filter((a) =>
      selected_famtype_IDs.has(famName_to_famtypeID[a])
    );
    let filtered_family_map = {};
    for (let family of filtered_families) filtered_family_map[family] = true;
    return filtered_family_map;
  },
  change_api_data_to_fe_format: (data) => {
    let res = [];
    res =
      data &&
      data.map((item) => {
        return {
          id: item.id,
          info: item.info,
          security: item.securities,
          banType: item.banType,
          famOrDeal: item.familyDealer,
          status: item.status !== 'Banned',
          updatedAt: item.lastModified,
          type: 0,
          remarks: item.remarks,
          familyType: item.familyType,
          subData: item.unban.map((sub_item) => {
            return {
              id: sub_item.id,
              info: sub_item.info,
              security: sub_item.securities,
              banType: sub_item.banType,
              familyType: sub_item.familyType,
              famOrDeal: sub_item.familyDealer,
              status: sub_item.status === 'Unbanned',
              updatedAt: sub_item.lastModified,
              type: 2,
              remarks: sub_item.remarks,
            };
          }),
        };
      });
    return res;
  },
};

export default DataModificationFunctions;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  alertApproval,
  approveRejectButton,
} from '../../../../actions/alertAction';
import {
  clearStatus,
  editTemplateModify,
  reset,
  saveAsDraft,
  setEditModify,
  setErrorInExit,
  strategyView,
  submit,
} from '../../../../actions/strategyBuilderAction';
import ValidateStrategyTemplateForm from '../../../../utils/ValidateStrategyTemplateFrom';
import Popup from '../../../sharedComponents/Popup/Popup';
import SimpleSnackbar from '../../../sharedComponents/Toast/Toast';
import {
  TOAST_SUBMIT_BUTTON,
  DATA_SAVED,
  CONFRIM_CLEAR,
} from '../../../../utils/constant';
import './SubmitButtons.scss';
import { Dialog } from '@mui/material';
import { FeedbackPopup, GenButton } from '../../..';
import { MakeRequest } from '../../../../utils';

// Static data caching
let instrumentArr = [];

const SubmitButtons = ({
  validator,
  forceUpdate,
  setFormSubmit,
  setModalClear,
  setEditModal,
  setEditModalSubmit,
  setSubmitMessageEdit,
  setAlertModal,
  nameDuplicate,
  setIsSuccessDelete,
}) => {
  const [toaster, setToaster] = useState(false);
  const [toastMsg, setToastMsg] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [editSubmitMessage, setEditSubmitMessage] = useState('');
  const [submitMessage, setSubmitMessage] = useState({});
  const [validError, setValidError] = useState(false);
  const [approvePop, setApprovePop] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  const [open, setOpen] = useState(false);
  const [equationErrors, setEquationErrors] = useState([]);
  const dispatch = useDispatch();
  const {
    strategyBuilderReducer: { strategyTemplateForm, exitToggle, isEdit },
    alertReducer: { isApproveReject, templateId },
  } = useSelector((state) => state);

  useEffect(() => {
    switch (popup.type) {
      case 'submitCreate':
        setPopup({
          ...popup,
          message: submitMessage.message,
          buttons: 'singleButton',
        });
        break;
      case 'clearData':
        setPopup({ ...popup, message: CONFRIM_CLEAR, buttons: 'singleButton' });
        break;
      case 'alertApprove':
        setPopup({ ...popup, message: submitMessage, buttons: 'singleButton' });
        break;
      case 'approveSubmit':
        setPopup({
          ...popup,
          message: editSubmitMessage.message
            ? editSubmitMessage.message
            : DATA_SAVED,
          buttons: 'singleButton',
          popClass: 'save-modal',
        });
        break;
      case 'submitEdit':
        setPopup({
          ...popup,
          message: editSubmitMessage.message,
          buttons: 'singleButton',
        });
        break;
      default:
        break;
    }
  }, [popup.type, submitMessage, editSubmitMessage]);

  const approveForm = async (data) => {
    let submitObj = {
      action: data,
      strategyTemplateId: templateId,
    };
    const approval = await dispatch(alertApproval(submitObj));
    if (approval) {
      setSubmitMessage(approval.message);
      setApprovePop(true);
      setPopup({ type: 'alertApprove', state: true });
      if (typeof setIsSuccessDelete === 'function') {
        setIsSuccessDelete(true);
      }
    }
  };

  const submitForm = (setFormSubmit) => {
    setFormSubmit(true);
    handleSubmitData();
  };

  const syncSecurityPositionNickname = async (data) => {
    try {
      let tempStrategyTemplateForm = {...data};
      for (let [idx, security] of tempStrategyTemplateForm.securities.entries()) {
        let nickname = security.nickname;
        // Update in position
        for (let leg of tempStrategyTemplateForm.entryLegs) {
          for (let pos of leg.position) {
            if (pos && pos.securityIdx && pos.securityIdx == idx) {
              pos.security = nickname;
            }
          }
        }

        // Instrument mapping load
        if (instrumentArr.length == 0) {
          instrumentArr = await MakeRequest('/security/instrumenTypes').get();
          instrumentArr = instrumentArr.data.data;
        }
        // Correct instrument enum for api request
        security.instrument = instrumentArr?.filter(
          (item) => item.name === security.instrument || item.key === security.instrument
        )[0].key;
      }
      return tempStrategyTemplateForm;
    } catch (err) {
      return null;
    }
  }

  const handleSubmitData = async () => {
    let formValid = ValidateStrategyTemplateForm(
      strategyTemplateForm,
      exitToggle
    );
    if (!nameDuplicate) {
      // Sync position and security nickname
      let finalStrategyTemplateForm = await syncSecurityPositionNickname(strategyTemplateForm);
      if (!finalStrategyTemplateForm) {
        finalStrategyTemplateForm = {...strategyTemplateForm};
      }

      dispatch(setErrorInExit([]));
      if (isEdit) {
        let editResult = await dispatch(
          editTemplateModify(finalStrategyTemplateForm)
        );
        setSubmitMessageEdit(editResult);
        setEditSubmitMessage(editResult);
        setEditModalSubmit(true);
        setPopup({ type: 'submitEdit', state: true });
      } else {
        let submitResult = {}
        try {
          submitResult = await dispatch(submit(finalStrategyTemplateForm));
        } catch (err) {
          submitResult = err.message;
        }
        if (
          (submitResult.status === 'unsuccessful' ||
            submitResult.status === 'error') &&
          submitResult.errors.length > 0
        ) {
          setEquationErrors(submitResult.errors);
          setOpen(true);
        } else {
          setSubmitMessage(submitResult);
          setModalSubmit(true);
          setPopup({ type: 'submitCreate', state: true });
        }
      }
    } else {
      setPopup((old) => {
        return { ...old, message: 'Duplicate Name' };
      });
    }
  };

  const handleSaveAsDraft = async () => {
    if (isEdit) {
      const result = await dispatch(saveAsDraft(strategyTemplateForm));
      setModalSave(true);
      setEditSubmitMessage(result);
      setPopup({ type: 'submitEdit', state: true });
    } else {
      const result = await dispatch(saveAsDraft(strategyTemplateForm));
      setModalSave(true);
      setEditSubmitMessage(result);
      setPopup({ type: 'submitEdit', state: true });
    }
  };

  const handleClear = (clear) => {
    if (clear) {
      let clearForm = {
        name: '',
        description: '',
        exchange: '',
        algoId: {
          CM: '',
          CD: '',
          FO: '',
        },
        securityFilter: 'ALL',
        isWrittenEquation: true,
        securities: [
          {
            instrument: '',
            securityName: '',
            expiry: '',
            strike: '',
            nickname: '',
            isAdditional: false,
          },
        ],
        constants: [],
        entryLegs: [
          {
            legId: 1,
            condition: [
              {
                type: 'OPERAND',
                trigger: '',
              },
            ],
            position: [
              {
                orientation: 'Fwd',
                direction: 'BUY',
                security: '',
                pricing: [],
                quantity: [],
                totalQuantityLogic: [],
              },
            ],
            algoExecution: [],
          },
        ],
        exitCondition: {
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          action: {
            enumName: '',
            args: [],
          },
        },
      };
      dispatch(reset(clearForm));
      dispatch(clearStatus(true));
    }
    setModalClear(false);
    setFormSubmit(false);
  };

  const editClose = () => {
    if (isApproveReject) {
      setAlertModal(false);
      setModalClear(true);
      handleClear(true);
      dispatch(setEditModify(false));
      dispatch(approveRejectButton(false));
    } else {
      setEditModal(false);
      setModalClear(true);
      handleClear(true);
      dispatch(setEditModify(false));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="submitsbuttons">
      <Dialog onClose={handleClose} open={open}>
        <p className="error-heading">Errors!</p>
        <ul>
          {equationErrors.map((e) => {
            let i = 0;
            while (i < e[2].length) {
              if (e[2][i] == `'`) {
                break;
              }
              i++;
            }
            let si = e[0] + i + 2,
              ei = e[1] + i + 1;
            return (
              <li className="error-item">
                {e[2].substring(0, si)}
                <span className="highlight-text">{e[2].substring(si, ei)}</span>
                {e[2].substring(ei)}
              </li>
            );
          })}
        </ul>
      </Dialog>
      <div className="button_group">
        <GenButton
          fill={true}
          clickAction={() =>
            isApproveReject ? approveForm(true) : submitForm(setFormSubmit)
          }
          title={isApproveReject ? 'APPROVE' : 'SUBMIT'}
          extStyle={{ margin: '0 0.5em 0.5em auto', width: '10em' }}
        />
        <GenButton
          fill={false}
          clickAction={() =>
            isApproveReject ? approveForm(false) : handleSaveAsDraft()
          }
          title={isApproveReject ? 'DECLINE' : 'SAVE AS DRAFT'}
          extStyle={{ margin: '0 0.5em 0.5em 0.5em', width: '10em' }}
        />
        <GenButton
          fill={false}
          clickAction={() =>
            isEdit || isApproveReject
              ? editClose()
              : setPopup({ type: 'clearData', state: true })
          }
          title={isEdit || isApproveReject ? 'CLOSE' : 'CLEAR'}
          extStyle={{ margin: '0 0.5em 0.5em 0.5em', width: '10em' }}
        />
      </div>
      {toaster ? (
        <SimpleSnackbar
          toaster={toaster}
          setToaster={setToaster}
          message={toastMsg}
        />
      ) : null}

      {validError ? (
        <SimpleSnackbar
          toaster={validError}
          setToaster={setValidError}
          message={TOAST_SUBMIT_BUTTON}
        />
      ) : null}
      <FeedbackPopup
        type={'f'}
        message={popup.message}
        acceptAction={() => {
          setPopup({ ...popup,
            type: '',
            message: '',
            buttons: '',
            state: false,
          })
          setSubmitMessage({})
        }}
      />
    </div>
  );
};

export default SubmitButtons;

import React, { useEffect, useState } from 'react';
import './styles.css';
import Section5 from '../../../assets/img/section4.svg';
import { useMediaQuery } from 'react-responsive';
import StLogo1 from '../../../assets/img/stLogo.svg';
import InfraImg1 from '../../../assets/img/iconInfra1.svg';
import InfraImg2 from '../../../assets/img/iconInfra2.svg';
import InfraImg3 from '../../../assets/img/iconInfra3.svg';
import InfraImg4 from '../../../assets/img/iconInfra4.svg';
import InfraImg5 from '../../../assets/img/iconInfra5.svg';

export default function InfraPageLoad() {
  const [textDisplay, setTextDisplay] = useState({
    title: 'Ultra-low latency',
    desc: 'Co-location along with full DMA (Direct Market Access) solution. Single Threaded model architecture designed for High frequency trading (latency of the order of micro seconds)',
  });

  const showMobile = useMediaQuery({ query: '(min-width: 768px)' });

  return !showMobile ? (
    <div className="infraPage-main-cont">
      <span className="infraPage-title">Trading Infrastructure</span>
      <div className="infraPage-contArea">
        <div className="infraPage-leftSec">
          <div className="leftCenterLogo">
            <img src={StLogo1} className="leftSecInfra-cicon" />
            <img
              className="infra-img-1"
              src={InfraImg1}
              style={{ top: '0vh', left: '0vw' }}
              onClick={() =>
                setTextDisplay({
                  title: 'Multi Asset Classess',
                  desc: 'Equity, Futures, Options & Currency Derivatives ',
                })
              }
            />
            <img
              className="infra-img-1"
              src={InfraImg2}
              style={{ top: '0vh', right: '2vw' }}
              onClick={() =>
                setTextDisplay({
                  title: 'Multiple Exchange',
                  desc: 'Approved for algorithmic trading and licensed on NSE, BSE, MCX & MCX-SX',
                })
              }
            />
            <img
              className="infra-img-1"
              src={InfraImg3}
              style={{ top: '10vh', right: '-3vw' }}
              onClick={() =>
                setTextDisplay({
                  title: 'TBT Market Data',
                  desc: 'Advanced market Data API effeciently process Tick by Tick data and feeds it to algorithm. Enables effecient price discovery and low, affordable, bid and ask quotes',
                })
              }
            />
            <img
              className="infra-img-1"
              src={InfraImg4}
              style={{ top: '15vh', left: '15vw' }}
              onClick={() =>
                setTextDisplay({
                  title: 'Ultra-low latency',
                  desc: 'Co-location along with full DMA (Direct Market Access) solution. Single Threaded model architecture designed for High frequency trading (latency of the order of micro seconds)',
                })
              }
            />
            <img
              className="infra-img-1"
              src={InfraImg5}
              style={{ top: '10vh', left: '-4vw' }}
              onClick={() =>
                setTextDisplay({
                  title: 'Robust and Flexible OMS & RMS',
                  desc: 'Platform with capacity of 10MM+ order / day. Guaranteed VWAP execution for Indian markets real time performance analytics.',
                })
              }
            />
          </div>
        </div>
        <div className="infraPage-rightSec">
          <p className="infraPage-text-title">{textDisplay.title}</p>
          <p className="infraPage-text-body">{textDisplay.desc}</p>
        </div>
      </div>
    </div>
  ) : (
    <img src={Section5} className="lp-s5-img" />
  );
}

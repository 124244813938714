import axios from 'axios';
import { url } from '../config/apiConfig';
import { loginActions } from '../reducers/loginReducer';

export const login = (loginData) => async (dispatch) => {
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/login`,
      data: loginData,
    });

    if (result && result.data && result.data.data.token) {
      if (
        result.data &&
        result.data.data &&
        result.data.data.isPassExpiryIntimationReq
      ) {
        dispatch({
          type: 'ALERT_USER',
          payload: true,
          alertMessage: result.data.data.passExpiryMessage,
        });
      }
      if (!result.data.data.isFirstTimeLogin) {
        localStorage.setItem('token', result.data.data.token);
        localStorage.setItem('customerId', result.data.data.customerId);
        localStorage.setItem('userType', result.data.data.userType);
        dispatch({
          type: 'IS_USER_LOGGEDIN',
          payload: true,
          custId: result.data.data.customerId,
        });
      }
      return {
        isSuccess: true,
        userId: result.data.data.customerId,
        isFirstTimeLogin: result.data.data.isFirstTimeLogin,
        passwordChangeEnum: result.data.data.passwordChangeEnum,
        token: result.data.data.token,
      };
    } else {
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: false,
        error: result.data.data.message,
      });
      return {
        isSuccess: false,
        userId: -1,
        isFirstTimeLogin: false,
        passwordChangeEnum: 0,
        token: '',
      };
    }
  } catch (err) {
    if (err?.response?.data?.data?.isLicenseExpired) {
      loginActions.licencePopupState(dispatch, 1);
    }
    if (err && err.message && err.message === 'Network Error') {
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: false,
        status: 500,
        token: '',
        error: 'Server unavailable / down',
      });
      return {
        isSuccess: false,
        userId: -1,
        isFirstTimeLogin: false,
        passwordChangeEnum: 0,
        // errorUser: 'Server unavailable / down'
      };
    }
    if (err.response?.data.data) {
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: false,
        errorUser: err.response.data.data.message,
      });
    } else {
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: false,
        status: err.response?.status,
        token: '',
      });
    }
    if(err && err.message && err.message === 'Network Error') {
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: false,
        status: 500,
        token: '',
        error: 'Server unavailable / down'
      });
      return {
        isSuccess: false,
        userId: -1,
        isFirstTimeLogin: false,
        passwordChangeEnum: 0,
        // errorUser: 'Server unavailable / down'
      }
    }
    return {
      isSuccess: false,
      userId: -1,
      isFirstTimeLogin: false,
      passwordChangeEnum: 0,
    };
  }
};

export const logout = (userData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await axios({
      method: 'GET',
      url: `${url}/token/logout`,
      data: userData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result && result.data) {
      return result.status;
    }
  } catch (err) {
    return err && err.response && err.response.status
      ? err.response.status
      : 500;
  }
};

export const clearError = (value) => {
  return { type: 'CLEAR_ERR_LOGIN', error: value };
};
export const clearStatus = (value) => {
  return { type: 'CLEAR_STATUS', error: value };
};

export const search = (loginData) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'get',
      url: '/admin/users/2',
      data: loginData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search },
    });
    if (result && result.data && result.data.length) {
      localStorage.setItem('token', result.data.token);
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: true,
      });
    } else {
      dispatch({
        type: 'IS_USER_LOGGEDIN',
        payload: false,
      });
    }
  } catch (err) {
    dispatch({
      type: 'IS_USER_LOGGEDIN',
      payload: false,
    });
  }
};

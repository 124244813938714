import React, { useEffect, useState, useRef } from 'react';
import './createTemplate.css';
import copy from '../../../../assets/img/copyIcon2.svg';
import edit from '../../../../assets/img/editPencil.svg';
import dustbin from '../../../../assets/img/dustbin.svg';
import {
  GenericPopup,
  DropDown,
  ToggleTab,
  FeedbackPopup,
  GenButton,
} from '../../../../components';
import MakeRequest from '../../../../utils/apiRequest';

const columns = ['Risk Actions', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6'];
const rows = ['Alert', 'Email', 'Reject', 'Log'];
const maxCheckboxMatrixSize = 10;

const CreateTemplate = (props) => {
  const {
    addTemplateSubmit,
    editTemplateSubmit,
    config,
    isHFT,
    templateId,
    editFlag,
  } = props;

  const [inits, setInits] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [templateName, setTemplateName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [tradeType, setTradeType] = useState('Pre Trade');
  const [riskHierachySelected, setRiskHierachySelected] = useState('Select');
  const [disablePrePost, setDisablePrePost] = useState(false);
  const [preTradeAction, setPreTradeAction] = useState(rows);
  const [postTradeAction, setPostTradeAction] = useState(rows);
  const [selectionType, setSelectionType] = useState({});
  const [preTradeCheckboxMatrix, setPreTradeCheckboxMatrix] = useState({}); // -1 disabled, 0 unchecked, 1 checked
  const [postTradeCheckboxMatrix, setPostTradeCheckboxMatrix] = useState({}); // -1 disabled, 0 unchecked, 1 checked
  const [junk, setJunk] = useState(0);
  const [feedbackMsg, setFeedbackMsg] = useState('');

  useEffect(() => {
    if (config && config['0'] && config['1'] && inits) {
      init();
    }
  });

  useEffect(() => {
    if (templateId && open) {
      configureTemplate();
    }

    if (!templateId && open) {
      init();
    }
  }, [open]);

  const init = () => {
    let data = null;
    if (isHFT) {
      data = config['1'];
    } else {
      data = config['0'];
    }

    if (
      data.modes.length > 1 ||
      (data.modes.length == 1 && data.modes[0] == 'BOTH')
    ) {
      setDisablePrePost(false);
    } else {
      setDisablePrePost(true);
      if (data.modes[0] === 'POST_TRADE') {
        setTradeType('Post Trade');
      } else {
        setTradeType('Pre Trade');
      }
    }

    let preTradeAction = [];
    let postTradeAction = [];
    let selectionType = {};

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < data.actions.length; j++) {
        if (rows[i] === data.actions[j].displayName) {
          if (data.actions[j].prePostTradeFlag === 'BOTH') {
            preTradeAction.push(rows[i]);
            postTradeAction.push(rows[i]);
          } else if (data.actions[j].prePostTradeFlag === 'PRE_TRADE') {
            preTradeAction.push(rows[i]);
          } else if (data.actions[j].prePostTradeFlag === 'POST_TRADE') {
            postTradeAction.push(rows[i]);
          }
          selectionType[rows[i]] = data.actions[j].selectionType;
          break;
        }
      }
    }

    let matPre = checkboxInitlizer();
    let matPost = checkboxInitlizer();

    setPreTradeCheckboxMatrix({ ...matPre });
    setPostTradeCheckboxMatrix({ ...matPost });
    setPreTradeAction(preTradeAction);
    setPostTradeAction(postTradeAction);
    setSelectionType(selectionType);
    setInits(false);
  };

  const configureTemplate = async () => {
    console.log(templateId);

    const response = await MakeRequest(
      `/admin/risk/template/getTemplate/${templateId}`
    ).get();
    if (response.event !== undefined && !response.event) {
      return;
    }

    const templateData = response.data.data.data;

    if (editFlag) {
      setTemplateName(templateData.name);
      setDescription(templateData.description);
    }

    setRiskHierachySelected(templateData.dimensionIdDisplayName);
    setTradeType(
      templateData.prePostTradeFlag === 'PRE_TRADE' ? 'Pre Trade' : 'Post Trade'
    );

    let checkboxMatrix =
      (templateData.prePostTradeFlag === 'PRE_TRADE') === 'Pre Trade'
        ? preTradeCheckboxMatrix
        : postTradeCheckboxMatrix;

    let actionArray =
      templateData.prePostTradeFlag === 'PRE_TRADE'
        ? preTradeAction
        : postTradeAction;

    for (let i = 1; i <= 6; i++) {
      if (
        templateData &&
        templateData.actionMappings.length > 0 &&
        templateData.actionMappings[i - 1] &&
        templateData.actionMappings[i - 1].levelActionInfo.length > 0
      ) {
        for (
          let j = 0;
          j < templateData.actionMappings[i - 1].levelActionInfo.length;
          j++
        ) {
          if (
            actionArray.includes(
              templateData.actionMappings[i - 1].levelActionInfo[j].name
            )
          ) {
            let key = `${actionArray.indexOf(
              templateData.actionMappings[i - 1].levelActionInfo[j].name
            )}-${i}`;
            checkboxMatrix[key] = templateData.actionMappings[i - 1]
              .levelActionInfo[j].enabled
              ? 1
              : 0;
          }
        }
      }
    }

    if (templateData.prePostTradeFlag === 'PRE_TRADE') {
      setPreTradeCheckboxMatrix(checkboxMatrix);
    } else {
      setPostTradeCheckboxMatrix(checkboxMatrix);
    }

    setJunk(Math.random()); // So that checkbox is updated instantly
  };

  const checkboxInitlizer = () => {
    let mat = {};
    for (let i = 0; i < maxCheckboxMatrixSize; i++) {
      for (let j = 0; j < maxCheckboxMatrixSize; j++) {
        let key = `${i}-${j}`;
        mat[key] = 0;
      }
    }
    return mat;
  };

  const handleClose = () => {
    clear();
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const dustbinOnClick = () => {
    setOpen(false);
  };

  const prePostToggleClickAction = (val) => {
    setTradeType(val);
  };
  const selectRiskHierachyCallback = (data) => {
    console.log(data);
  };

  const clear = () => {
    console.log('Clear...');
    setRiskHierachySelected('Select');
    setDescription('');
    setTemplateName('');
    // setTradeType('Pre Trade');
    let mat = checkboxInitlizer();
    setPreTradeCheckboxMatrix({ ...mat });
    setPostTradeCheckboxMatrix({ ...mat });
  };

  const riskHierachyChange = (val) => {
    setRiskHierachySelected(val);
  };

  const riskHierachyDataFormatter = () => {
    let data = null;
    if (isHFT) {
      data = config['1'].riskHeirarchy;
    } else {
      data = config['0'].riskHeirarchy;
    }

    let rh = [];

    for (let i = 0; i < data.length; i++) {
      rh.push(data[i].displayName);
    }

    return rh;
  };

  const isCheckboxDisabled = (rowIndex, colIndex) => {
    let checkboxMatrix;
    if (tradeType === 'Pre Trade') {
      checkboxMatrix = preTradeCheckboxMatrix;
    } else {
      checkboxMatrix = postTradeCheckboxMatrix;
    }

    let key = `${rowIndex}-${colIndex}`;

    if (checkboxMatrix[key] === -1) {
      return true;
    } else {
      return false;
    }
  };

  const checkboxMaintain = (value, rowIndex, colIndex, action) => {
    // console.log(value, rowIndex, colIndex, action);
    let selType = selectionType[action];
    console.log(selType);
    let checkboxMatrix;
    if (tradeType === 'Pre Trade') {
      checkboxMatrix = preTradeCheckboxMatrix;
    } else {
      checkboxMatrix = postTradeCheckboxMatrix;
    }

    let key = `${rowIndex}-${colIndex}`;

    if (value) {
      checkboxMatrix[key] = 1;
      if (selType === 'SINGLE_SELECT') {
        for (let i = 0; i < maxCheckboxMatrixSize; i++) {
          if (i === colIndex) {
            continue;
          } else {
            let tempKey = `${rowIndex}-${i}`;
            checkboxMatrix[tempKey] = -1;
          }
        }
      }
    } else {
      checkboxMatrix[key] = 0;
      if (selType === 'SINGLE_SELECT') {
        for (let i = 0; i < maxCheckboxMatrixSize; i++) {
          if (i === colIndex) {
            continue;
          } else {
            let tempKey = `${rowIndex}-${i}`;
            checkboxMatrix[tempKey] = 0;
          }
        }
      }
    }

    if (tradeType === 'Pre Trade') {
      setPreTradeCheckboxMatrix(checkboxMatrix);
    } else {
      setPostTradeCheckboxMatrix(checkboxMatrix);
    }

    setJunk(Math.random()); // So that checkbox is updated instantly
  };

  const submit = async () => {
    let postData = {
      id: null,
      name: templateName,
      description: description,
      prePostTradeFlag: tradeType === 'Pre Trade' ? 'PRE_TRADE' : 'POST_TRADE',
      dimensionId: null,
      actionMappings: [],
    };

    let data = null;
    if (isHFT) {
      data = config['1'].riskHeirarchy;
    } else {
      data = config['0'].riskHeirarchy;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].displayName === riskHierachySelected) {
        postData.dimensionId = data[i].dimensionId;
        break;
      }
    }

    let checkboxMatrix =
      tradeType === 'Pre Trade'
        ? preTradeCheckboxMatrix
        : postTradeCheckboxMatrix;

    let actionArray =
      tradeType === 'Pre Trade' ? preTradeAction : postTradeAction;

    for (let i = 1; i <= 6; i++) {
      let temp = {
        columnName: `L${i}`,
        levelActionInfo: [],
      };
      if (actionArray.includes('Alert')) {
        let a = {
          name: 'Alert',
          enabled:
            checkboxMatrix[`${actionArray.indexOf('Alert')}-${i}`] === 1
              ? true
              : false,
        };
        temp.levelActionInfo.push(a);
      }
      if (actionArray.includes('Email')) {
        let e = {
          name: 'Email',
          enabled:
            checkboxMatrix[`${actionArray.indexOf('Email')}-${i}`] === 1
              ? true
              : false,
        };

        temp.levelActionInfo.push(e);
      }
      if (actionArray.includes('Reject')) {
        let r = {
          name: 'Reject',
          enabled:
            checkboxMatrix[`${actionArray.indexOf('Reject')}-${i}`] === 1
              ? true
              : false,
        };
        temp.levelActionInfo.push(r);
      }
      if (actionArray.includes('Log')) {
        let l = {
          name: 'Log',
          enabled:
            checkboxMatrix[`${actionArray.indexOf('Log')}-${i}`] === 1
              ? true
              : false,
        };
        temp.levelActionInfo.push(l);
      }

      postData.actionMappings.push(temp);
    }

    if (!editFlag) {
      // Create template
      let response = await MakeRequest('/admin/risk/template/submit').post(
        postData
      );

      if (response.status === 200) {
        // Success
        setFeedbackMsg(
          <span style={{ color: 'green' }}>
            {'Template created successfully !'}
          </span>
        );
        addTemplateSubmit();
        handleClose();
      } else {
        // Failure
        setFeedbackMsg(
          <p>
            <span style={{ color: 'red' }}>{'Error : '}</span>
            {response.data.data.message}
          </p>
        );
      }
    } else {
      // Edit Template
      postData.id = templateId;
      let response = await MakeRequest('/admin/risk/template/modify').post(
        postData
      );

      if (response.status === 200) {
        // Success
        setFeedbackMsg(
          <span style={{ color: 'green' }}>
            {'Template edited successfully !'}
          </span>
        );
        editTemplateSubmit();
        handleClose();
      } else {
        // Failure
        setFeedbackMsg(
          <p>
            <span style={{ color: 'red' }}>{'Error : '}</span>
            {response.data.data.message}
          </p>
        );
      }
    }
  };

  return (
    <div>
      <div>
        {editFlag && templateId ? (
          <img
            src={edit}
            style={{
              height: '15px',
              width: '15px',
              marginLeft: '10px',
            }}
            onClick={handleToggle}
          />
        ) : (
          <>
            {templateId ? (
              <img
                src={copy}
                style={{ height: '15px', width: '15px' }}
                onClick={handleToggle}
              />
            ) : (
              <GenButton
                type="1"
                title="Add Template"
                fill={false}
                // icon={Add}
                extStyle={{
                  width: '9vw',
                  height: '4vh',
                  marginRight: '1vw',
                  marginTop: '0.8vh',
                }}
                clickAction={handleToggle}
              />
            )}
          </>
        )}
      </div>
      <GenericPopup type={1} state={open}>
        <div className="feedback-popup-container-ct">
          <div className="title-container-ct">
            <div className="title-container-left-ct">Create Template</div>
            <div className="title-container-right-ct">
              <img
                src={dustbin}
                onClick={dustbinOnClick}
                style={{ width: '20px', height: '20px' }}
              />
            </div>
          </div>

          <div className="switch-container-ct">
            <div className="body-container-ct">
              <div className="body-container-left-ct">
                <div>Risk Hierachy</div>{' '}
                <div>
                  {config && config['0'] && config['1'] && (
                    <DropDown
                      options={riskHierachyDataFormatter()}
                      selected={riskHierachySelected}
                      orientation="v"
                      onSelect={(val) => {
                        riskHierachyChange(val);
                      }}
                      extStyles={{ width: '16vw' }}
                      ddExtStyles={{ width: '16vw' }}
                    />
                  )}
                </div>
              </div>
              <div className="body-container-right-ct">
                {' '}
                <ToggleTab
                  options={['Pre Trade', 'Post Trade']}
                  selected={tradeType}
                  extStyles={{ margin: '0 0 0 1vw', borderRadius: '5px' }}
                  disabled={disablePrePost}
                  onSelect={prePostToggleClickAction}
                />
              </div>
            </div>
            <div className="body-container-ct">
              <div className="body-container-left-ct">
                <div>Template Name</div>
                <div className="template-name-input">
                  <input
                    className="template-name-input"
                    placeholder="Enter Template Name"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="body-container-right-ct">
                <div>Description</div>
                <div className="description-input">
                  <input
                    className="description-input"
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="title2-container-ct">
            {columns.map((attribute, index) => (
              <div
                className={
                  attribute !== 'Risk Actions'
                    ? 'title2-container-child-right-ct'
                    : 'title2-container-child-left-ct'
                }
              >
                {attribute}
              </div>
            ))}
          </div>

          {(tradeType === 'Pre Trade' ? preTradeAction : postTradeAction).map(
            (record, rowIndex) => (
              <div
                className={
                  rowIndex % 2 === 1
                    ? 'body-row-container-white-ct'
                    : 'body-row-container-blue-ct'
                }
              >
                {columns.map((attribute, colIndex) => (
                  <div
                    className={
                      attribute !== 'Risk Actions'
                        ? 'body-row-container-child-right-ct'
                        : 'body-row-container-child-left-ct'
                    }
                  >
                    {attribute !== 'Risk Actions' ? (
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={
                          tradeType === 'Pre Trade'
                            ? preTradeCheckboxMatrix[
                                `${rowIndex}-${colIndex}`
                              ] === 1
                            : postTradeCheckboxMatrix[
                                `${rowIndex}-${colIndex}`
                              ] === 1
                        }
                        disabled={isCheckboxDisabled(rowIndex, colIndex)}
                        onChange={(e) =>
                          checkboxMaintain(
                            e.target.checked,
                            rowIndex,
                            colIndex,
                            record
                          )
                        }
                      />
                    ) : (
                      <div>{record}</div>
                    )}
                  </div>
                ))}
              </div>
            )
          )}

          <div className="feed-popup-bottom-ct">
            <GenButton
              type="1"
              title={editFlag ? 'Submit' : 'Submit'}
              fill={true}
              extStyle={{ width: '7vw', height: '4vh' }}
              clickAction={submit}
            />
            <GenButton
              type="1"
              title="CLEAR"
              fill={false}
              extStyle={{
                width: '7vw',
                height: '4vh',
                fontSize: '22px',
              }}
              clickAction={clear}
            />
            <GenButton
              type="1"
              title="CLOSE"
              fill={false}
              extStyle={{ width: '7vw', height: '4vh' }}
              clickAction={handleClose}
            />
          </div>
        </div>
      </GenericPopup>
      <FeedbackPopup
        message={feedbackMsg}
        type="f"
        acceptAction={() => {
          setFeedbackMsg('');
        }}
      />
    </div>
  );
};

export default CreateTemplate;

let pageNum = null;

// Key press handler with a callback function
const handleEnterWithCallback = (e, callBackFunc = () => {}) => {
  try {
    var keycode = e.keyCode ? e.keyCode : e.which;
    let key = e.key ? e.key : 'NA';
    if (keycode == '13' || key == 'Enter') {
      callBackFunc();
    }
  } catch (err) {
    console.log(err);
  }
};

const handleEscapeWithCallback = (e, callBackFunc = () => { }) => {
  try {
    var keycode = e.keyCode ? e.keyCode : e.which;
    let key = e.key ? e.key : 'NA';
    if (keycode == '27' || key == 'Escape') {
      
      callBackFunc();
    }
  } catch (err) {
    console.log(err);
  }
};

// Key press handler with a callback with particular value
const handleEnterWithCallbackAndValue = (
  e,
  callBackFunc = (value) => {},
  value
) => {
  try {
    var keycode = e.keyCode ? e.keyCode : e.which;
    let key = e.key ? e.key : 'NA';
    if (keycode == '13' || key == 'Enter') {
      if (Array.isArray(value) || typeof value == 'object') {
        if (typeof value === 'object') {
          callBackFunc(value);
        }
        callBackFunc(...value);
      } else {
        callBackFunc(value);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

// Key press handler for a modal open: //? Above can be used for this functionality
const handleEnterForModal = (e, callBack, callBackValue) => {
  try {
    e.stopPropagation();
    var keycode = e.keyCode ? e.keyCode : e.which;
    let key = e.key ? e.key : 'NA';
    if (keycode == '13' || key == 'Enter') {
      callBack(callBackValue);
    }
  } catch (err) {
    console.log(err);
  }
};

// Key handler with click on Active Element
const handleEnterWithActiveElementClick = (e) => {
  try {
    var keycode = e.keyCode ? e.keyCode : e.which;
    let key = e.key ? e.key : 'NA';
    if (keycode == '13' || key == 'Enter') {
      document.activeElement.click();
    }
  } catch (err) {
    console.log(err);
  }
};

// Set focus on Modal OK key
const setFocusOnModalKey = () => {
  let element = document.getElementsByClassName('popUpOK');
  if (element && element.length > 0) {
    element = element[0];
  }
  if (element && typeof element.focus === 'function') {
    element.focus();
  }
};

// Set focus on Modal with Class Name key
const setFocusOnModalKeyWithClassName = (className) => {
  let element = document.getElementsByClassName(className);
  if (element && element.length > 0) {
    element = element[0];
  }
  if (element && typeof element.focus === 'function') {
    element.focus();
  }
};

// Open Manual order form
const openManualOrder = (dir, security, exc) => {
  //function for opening manual order in new window for buying and selling a security
  window.open(
    `/window/manual-order?direction=${window.btoa(dir)}&security=${window.btoa(
      `${exc}-${security}`
    )}`,
    '_blank',
    'top=100, left=100, width=800, height=375, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
  );
};

// Order Book Tickers / Depth View
const handleOrderBookDepth = (row) => {
  let userId = localStorage.getItem('customerId');
  row.exchange = row && row.exchange ? row.exchange : 'NSE';

  let newWindow = window.open(
    `/window/order-book-ticker/${window.btoa(userId)}/${window.btoa(
      row.exchange
    )}/${window.btoa(row.security)}/${window.btoa(row.esteeId)}`,
    '_blank',
    'top=100, left=100, width=770, height=385, menubar=yes,toolbar=yes, scrollbars=no, resizable=yes'
  );
};

const openStrategyWindow = (id, isTemplate, strategyTemplateId, security) => {
  let strategyTemplate = strategyTemplateId ? strategyTemplateId : id;
  let newWindow = window.open(
    `/window/create-strategy-modal/${window.btoa(id)}/${window.btoa(
      strategyTemplate
    )}/${window.btoa(isTemplate)}?security=${window.btoa(
      security)}`,
    '_blank',
    'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
  );
};

// Enter key press handler for Manual Order
const handleEnterForMarketWatch = (e, row, type = '', shortcuts) => {

  if (type === 'MARKET_WATCH') {
    try {
      e.stopPropagation();
      var key = e.key ? e.key : e.which;

      // Enter key to open Order Book for security
      if (key === 'Enter') {
        handleOrderBookDepth(row);
      } else {
        // Assuming that keyboard sortcuts has all the data that we want

        // Check for the buy shortcut
        if (shortcuts && shortcuts.has('MANUAL_ORDER_BUY')) {
          let manualOrderBuyKey = shortcuts.get('MANUAL_ORDER_BUY').keyCombination;
          if (
            manualOrderBuyKey &&
            (key == manualOrderBuyKey ||
              String(key).toUpperCase() ==
                String(manualOrderBuyKey).toUpperCase())
          ) {
            // Buy Form Open
            console.log('Buy key pressed');
            openManualOrder(
              'BUY',
              row && row.security ? row.security : '',
              row && row.exchange ? row.exchange : ''
            );
          }
        }

        // Check for the sell shortcut
        if (shortcuts && shortcuts.has('MANUAL_ORDER_SELL')) {
          let manualOrderSellKey = shortcuts.get('MANUAL_ORDER_SELL').keyCombination;
          if (
            manualOrderSellKey &&
            (key == manualOrderSellKey ||
              String(key).toUpperCase() ==
                String(manualOrderSellKey).toUpperCase())
          ) {
            // Sell Form Open
            openManualOrder(
              'SELL',
              row && row.security ? row.security : '',
              row && row.exchange ? row.exchange : ''
            );
          }
        }

        // 2. Check for strategy template shortcuts, here we loop through available shortcuts to find if something fits
        for (const [_, value] of shortcuts) {
          if (value && value.isRuleShortcut) {
            // console.log(shortcuts);
            let keys = value.keyCombination.split('+');

            if (keys && keys.length == 1) {
              let shortcutKey = keys[0];
              if (
                key == shortcutKey ||
                String(key).toUpperCase() == String(shortcutKey).toUpperCase()
              ) {
                let { templateId, id, isTemplate } = value.execOptions;
                openStrategyWindow(id, isTemplate, templateId, row.esteeId);
              }
            } else if (keys && keys.length > 1) {
              let hasCtrl = false,
                hasAlt = false,
                hasShift = false;
              let mainKey;
              for (const shortcutKey of keys) {
                if (String(shortcutKey).toUpperCase() == 'CTRL') {
                  hasCtrl = true;
                } else if (String(shortcutKey).toUpperCase() == 'ALT') {
                  hasAlt = true;
                } else if (String(shortcutKey).toUpperCase() == 'SHIFT') {
                  hasShift = true;
                } else {
                  mainKey = String(shortcutKey).toUpperCase();
                }
              }

              if (
                e.shiftKey === hasShift &&
                e.ctrlKey === hasCtrl &&
                e.altKey === hasAlt &&
                (!mainKey || String(key).toUpperCase() == mainKey)
              ) {
                let { templateId, id, isTemplate } = value.execOptions;
                openStrategyWindow(id, isTemplate, templateId, row.esteeId);
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
};

const handleEscapeKeyEvent = (event) => {
  window.opener = null;
  window.open('', '_self');
  window.close();
};

const arrowKeyMarketWatchHandler = (event) => {
  try {
    let currPageNum = localStorage.getItem('MarketWatchFocusedPageNum');

    if (currPageNum) {
      pageNum = parseInt(currPageNum) + 1;
    } else {
      pageNum = 1;
    }
    let nextElementKey = 'MARKET_WATCH_ROW_' + pageNum;
    const nextElement = document.getElementById(nextElementKey);
    if (nextElement) {
      nextElement.focus();
      localStorage.setItem('MarketWatchFocusedPageNum', pageNum);
    }
    console.log('Found:', nextElement);
  } catch (err) {
    console.log(err);
  }
};

export {
  handleEnterWithCallback,
  handleEnterWithCallbackAndValue,
  handleEnterForModal,
  handleEnterWithActiveElementClick,
  handleEscapeWithCallback,
  setFocusOnModalKey,
  setFocusOnModalKeyWithClassName,
  handleEnterForMarketWatch,
  handleEscapeKeyEvent,
  arrowKeyMarketWatchHandler,
};

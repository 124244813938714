import { useState, useEffect, useRef } from 'react';
import {
  FeedbackPopup,
  GenericPopup,
  PillButton,
  RemarkButton,
  Switch,
  TableGenerator,
} from '../../../../components';
import './styles.css';
import {
  getDealers,
  toggleDealerState,
  deleteDealer,
  getSingleDealer,
  modifyDealer,
  getSegmentData,
} from './apis';
import { MakeRequest } from '../../../../utils';
import EditForm from './editPopup';
import ViewDealer from './viewDealer';
import FileUploadComponent from '../../../../components/FileUpload/FileUpload';
import Popup from '../../../../components/sharedComponents/Popup/Popup';
import {
  DEALER_CONFRIM_AFTER_DELETE,
  DEALER_DELETE_CONFIRM,
} from '../../../../utils/constant';
import { setFocusOnModalKeyWithClassName } from '../../../../components/sharedComponents/keyPressEventHandler';
import ExcelPrevPopup from '../../../../components/sharedComponents/customComponent/excelPrevPopup';
import UMButtonGroup from '../../../../components/sharedComponents/customComponent/UMButtonGroup';
import ResetDealerPassword from './resetDealerPassword';
import { useSelector } from 'react-redux';
import { permissionChecker } from '../../../Ops/redux';
import {
  TableDataCell,
  TableRow,
} from '../../../../components/sharedComponents/GenTable/tableStyledComps';

export default function DealerDashboard() {
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState('view');
  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'User Maintenance/Dealer Dashboard',
      2,
      'Risk'
    );

    setPermission(res > 1 ? 'edit' : 'view');
  }, [userPermissions]);

  const [search, setSearch] = useState('');
  const [dealers, setDealers] = useState([]);
  const [popupData, setPopupData] = useState({
    state: false,
    type: '',
    data: {},
  });
  const [segment, setSegments] = useState([]);
  const [show, setShow] = useState(false);
  const [isSuccessUpload, setIsSuccessUpload] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: 'doubleButtons',
    nameAccept: 'Yes',
    nameDecline: 'No',
    state: false,
    header: '',
    dealerId: -1,
  });
  const [showPopUp, setShowPopUp] = useState(false);

  const totalEntries = useRef(0);
  const [pagesInfo, setPagesInfo] = useState({});

  const entriesOnOnePage = 6;

  const handleShow = () => setShow(true);

  // set functionalty for popup
  const actionOnpopup = (e, type) => {
    if (type == 'CLOSE') {
      setPopup({
        type: '',
        message: '',
        buttons: 'doubleButtons',
        nameAccept: 'Yes',
        nameDecline: 'No',
        state: false,
        header: '',
        dealerId: -1,
      });
      setShowPopUp(false);
    } else {
      if (popup.type == 'deleteConfirm') {
        deleteDealerFun(popup.dealerId);
        setPopup({
          type: '',
          message: '',
          buttons: 'doubleButtons',
          nameAccept: 'Yes',
          nameDecline: 'No',
          state: false,
          header: '',
          dealerId: -1,
        });
        setShowPopUp(false);
      }
    }
  };

  async function updateDealerData(type, id) {
    switch (type) {
      case 'full':
        const result = await getDealers();
        setDealers(result);
        break;
      default:
        break;
    }
  }

  async function activator(id, state) {
    let res = await toggleDealerState(id, state);
    if (res) {
      await updateDealerData('full');
    } else {
      setPopupData({
        state: true,
        type: 'feedback',
        data: `Some error occured not able to activate Dealer with ID : ${id}`,
      });
    }
  }

  async function deleteDealerFun(dealerID) {
    let res = await deleteDealer(dealerID);
    if (res) {
      await updateDealerData('full');
      setPopup({
        ...popup,
        header: DEALER_CONFRIM_AFTER_DELETE(dealerID),
        buttons: 'singleButton',
      });
      setShowPopUp(true);
    } else {
      setPopup({
        ...popup,
        header: `Dealer with ID: ${dealerID} was not deleted`,
        buttons: 'singleButton',
      });
      setShowPopUp(true);
    }
  }

  function tableDataGen() {
    if (dealers.length === 0) {
      return [];
    }
    let dealerRes;
    if (search.length > 0) {
      dealerRes = dealers.filter((item) =>
        String(item['dealerName'])
          .toLowerCase()
          .includes(String(search).toLowerCase())
      );
    } else {
      dealerRes = dealers;
    }
    let tempDealersData = [];
    for (let i = 0; i < dealerRes.length; i++) {
      tempDealersData.push(
        <TableRow>
          <TableDataCell>{i + 1}</TableDataCell>
          <TableDataCell>{dealerRes[i].dealerId}</TableDataCell>
          <TableDataCell width="8vw">{dealerRes[i].dealerName}</TableDataCell>
          <TableDataCell>{dealerRes[i].userName}</TableDataCell>
          <TableDataCell>{dealerRes[i].nnfId}</TableDataCell>
          <TableDataCell>{dealerRes[i].expiry}</TableDataCell>
          <TableDataCell>{dealerRes[i].lastUpdated}</TableDataCell>
          <TableDataCell>
            {
              <Switch
                state={dealerRes[i].active}
                disable={permission === 'edit' ? false : true}
                onChange={(val) => activator(dealerRes[i].dealerId, val)}
              />
            }
          </TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={0}
              extStyles={{ margin: 'auto' }}
              clickAction={async () => {
                const dealerData = await getSingleDealer(dealerRes[i].dealerId);
                setPopupData({ state: true, type: 'view', data: dealerData });
              }}
            />
          </TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={1}
              extStyles={{ margin: 'auto' }}
              disabled={permission === 'edit' ? false : true}
              clickAction={async () => {
                const dealerData = await getSingleDealer(dealerRes[i].dealerId);
                setPopupData({
                  state: true,
                  type: 'edit',
                  data: { ...dealerData, id: dealerRes[i].dealerId },
                });
              }}
            />
          </TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={2}
              extStyles={{ margin: 'auto' }}
              disabled={permission === 'edit' ? false : true}
              clickAction={() => {
                setPopup({
                  ...popup,
                  dealerId: dealerRes[i].dealerId,
                  buttons: 'doubleButtons',
                  nameAccept: 'Yes',
                  nameDecline: 'No',
                  type: 'deleteConfirm',
                  header:
                    DEALER_DELETE_CONFIRM +
                    ` with id ${dealerRes[i].dealerId} ?`,
                });
                setShowPopUp(true);
                setTimeout(() => {
                  setFocusOnModalKeyWithClassName('No');
                }, 500);
              }}
            />
          </TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={3}
              disabled={permission === 'edit' ? false : true}
              extStyles={{ margin: 'auto' }}
              clickAction={async () => {
                setPopupData({
                  state: true,
                  type: 'resetPassword',
                  data: dealerRes[i].dealerId,
                });
              }}
            />
          </TableDataCell>
          <TableDataCell>
            <RemarkButton
              title={
                dealerRes[i].remarks.length > 0 &&
                dealerRes[i].remarks[0].remarks
              }
              onClickAction={() =>
                setPopupData({
                  state: true,
                  type: 'remark',
                  data: dealerRes[i].remarks,
                })
              }
            />
          </TableDataCell>
        </TableRow>
      );
    }

    let startIndex = entriesOnOnePage * (pagesInfo.current - 1);
    let endIndex = Math.min(
      startIndex + entriesOnOnePage,
      tempDealersData.length
    );

    totalEntries.current = tempDealersData.length;
    return tempDealersData.slice(startIndex, endIndex);
  }

  async function updateSegments() {
    const segments = await getSegmentData();
    setSegments(segments);
  }

  useEffect(() => {
    updateDealerData('full');
    updateSegments();
  }, []);

  useEffect(() => {
    initializeCurrentPageInfo();
  }, [dealers]);

  // Selective Update on Successfull Dealer Excel upload
  useEffect(() => {
    if (isSuccessUpload === true) {
      try {
        updateDealerData('full');
        updateSegments();
      } catch (error) {
        console.log(error);
      }
      setIsSuccessUpload(false);
    }
  }, [isSuccessUpload]);

  function popupDataGen() {
    switch (popupData.type) {
      case 'remark':
        let rows = [];
        if (popupData.type === 'remark') {
          for (const remark of popupData.data) {
            rows.push(
              <tr>
                <td>{remark.remarkTime}</td>
                <td>{remark.dealerInfo}</td>
                <td>{remark.remarks}</td>
              </tr>
            );
          }
        }
        return (
          <div style={{ height: '80vh', width: '60vw' }}>
            <TableGenerator
              heads={['Date Modified', 'Dealer ID/Name', 'Remarks']}
              content={rows}
              extStyles={{ margin: '1vh' }}
            />
          </div>
        );
      case 'feedback':
        return (
          <span className="dealerDashboard-feedback-popup">
            {popupData.data}
          </span>
        );
      case 'create':
        return (
          <EditForm
            edit={false}
            editData={{}}
            submit={(val) => createDealerFun(val)}
            segments={segment}
            setPopupData={setPopupData}
            updateDealerData={updateDealerData}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      case 'edit':
        return (
          <EditForm
            edit={true}
            editData={popupData.data}
            submit={(val) => modifyDealerFun(val)}
            segments={segment}
            setPopupData={setPopupData}
            updateDealerData={updateDealerData}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        );
      case 'view':
        return (
          <ViewDealer
            data={popupData.data}
            nav={() =>
              setPopupData({ state: true, type: 'edit', data: popupData.data })
            }
            edit={permission === 'edit'}
          />
        );
      case 'resetPassword':
        return (
          <ResetDealerPassword
            dealerId={popupData.data}
            closePopup={() => setPopupData({ ...popupData, state: false })}
          />
        );
      default:
        return <div></div>;
    }
  }

  async function createDealerFun(dealerObj) {}

  async function modifyDealerFun(dealerObj) {
    dealerObj.giveUpStatus = dealerObj.giveUpStatus ? '1' : '0';
    dealerObj.status = dealerObj.status ? '1' : '0';

    dealerObj.expiry =
      typeof dealerObj.expiry === 'string'
        ? dealerObj.expiry
        : new Date(dealerObj.expiry).toISOString().slice(0, 10);

    dealerObj.segmentDetails = dealerObj.segments.map((item) => {
      return {
        ...item,
        segment: Object.entries(segment).filter(
          (seg) => seg[1] === item.segment
        )[0][0],
      };
    });

    // Object.entries(segment).filter(
    //   (item) => item[1] == dealerObj.segment
    // )[0][0];
    dealerObj.remarks = dealerObj.remark;
    const res = await modifyDealer(dealerObj);
    if (res) {
      setPopupData({ ...popupData, state: false });
      await updateDealerData('full');
      setPopupData({
        state: true,
        type: 'feedback',
        data: `Dealer Modified`,
      });
    } else {
      setPopupData({
        state: true,
        type: 'feedback',
        data: `Some Error Occured`,
      });
    }
  }

  const initializeCurrentPageInfo = () => {
    // set the current page to 1
    handlePageChange(1);
  };

  const handlePageChange = (id) => {
    updatePagesInfoGivenCurrent(id);
  };

  const updatePagesInfoGivenCurrent = (current) => {
    let totalPages = Math.ceil(totalEntries.current / entriesOnOnePage);
    console.log(`total entries on this page are ${totalEntries.current}`);
    setPagesInfo({ current: current, total: totalPages });
  };

  const [dealPrev, setDealPrev] = useState();
  const [dealPrevFeed, setDealPrevFeed] = useState('');
  // * Action can be : SUB: submit, EDT: edit, CAN: cancel
  async function dealPrevCallBack(action = { action: 0, data: {} }) {
    switch (action.action) {
      case 'SUB':
        const res = await submitExcelprev(action.data);
        try {
          if (res) {
            setDealPrev();
            setShow(false);
            await updateDealerData('full');
            setDealPrevFeed(res.data.data.message);
          }
        } catch (error) {
          console.log(error);
          setDealPrevFeed('Something Went wrong');
        }
        break;
      case 'EDT':
        setDealPrev();
        break;
      case 'CAN':
        setDealPrev();
        setShow(false);
        break;
      default:
        break;
    }
  }

  async function submitExcelprev(data) {
    try {
      const res = await MakeRequest('/excel/upload/dealerSubmit').post({
        submitData: data,
      });
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  return (
    <div>
      <UMButtonGroup
        createOnClick={() =>
          setPopupData({ state: true, type: 'create', data: {} })
        }
        onSearchChange={setSearch}
        searchVal={search}
        searchPlaceHolder={'Search Dealer Name'}
        onUploadExec={() => handleShow()}
        onExportExec={() =>
          MakeRequest('/excel/generate/dealer').download('dealers.xlsx')
        }
        extStyles={{ margin: '1vh 0 1vh 0' }}
        edit={permission === 'edit'}
      />
      <TableGenerator
        heads={[
          'SNo.',
          'Dealer ID',
          'Name',
          'User Name',
          'NNF code',
          'Dealer Expiry',
          'Last Modified',
          'Active',
          'View',
          'Edit',
          'Delete',
          'Reset Password',
          'Remarks',
        ]}
        content={tableDataGen()}
        extStyles={{ margin: '1vh 0 2vh 0' }}
        className="tableContainer bodyScrollTable"
        pagesInfo={pagesInfo}
        pageChange={handlePageChange}
        showNoOfEntries={true}
        totalEntries={totalEntries.current}
        entriesPerPage={entriesOnOnePage}
      />
      {showPopUp ? (
        <Popup
          popClass="dealerPopup"
          actionButton={actionOnpopup}
          popupDetails={popup}
          header={popup.header}
        />
      ) : null}
      <GenericPopup
        state={popupData.state}
        toggleState={() => setPopupData({ ...popupData, state: false })}
        type={1}
      >
        {popupDataGen()}
      </GenericPopup>
      <FileUploadComponent
        show={show}
        setShow={setShow}
        screenType={'dealer'}
        setSuccessUpload={setIsSuccessUpload}
        callBackFunc={(val) => setDealPrev(val)}
      />
      <FeedbackPopup
        message={
          dealPrevFeed
            ? `${JSON.stringify(dealPrevFeed)}. Do you want to upload again ?`
            : ''
        }
        type="a"
        acceptAction={() => {
          setShow(true);
          setDealPrevFeed('');
        }}
        rejectAction={() => {
          setDealPrevFeed('');
        }}
      />
      <ExcelPrevPopup
        state={dealPrev !== undefined}
        data={dealPrev && dealPrev.data}
        callBackFunction={dealPrevCallBack}
        previewTitle="Preview Dealer"
      />
    </div>
  );
}

import React, { useState } from 'react';
import './EnterTemplateDetails.scss';
import { TemplateIcon } from '../../../../assets';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { setStrategyTemplateForm } from '../../../../actions/strategyBuilderAction';
import SimpleSnackbar from '../../../sharedComponents/Toast/Toast';
import InputField from '../../../sharedComponents/inputField';
import HeaderStrategyBuilder from '../../../../viewScreen/strategyBuilder/create/strategyAnalyzerFormula/header';

const EnterTemplateDetails = ({ validator, formSubmit }) => {
  const [toaster, setToaster] = useState(false);
  const [disToaster, setDisToaster] = useState(false);
  const dispatch = useDispatch();
  const {
    strategyBuilderReducer: { strategyTemplateForm },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);

  const inputNameHandler = (e) => {
    let form = { ...strategyTemplateForm };
    if (e.length >= 50) {
      setToaster(true);
      e = e.substring(0, 50);
    }
    form['name'] = e;
    dispatch(setStrategyTemplateForm(form));
  };

  const inputDesHandler = (e) => {
    let form = { ...strategyTemplateForm };
    if (e.length >= 200) {
      setDisToaster(true);
      e = e.substring(0, 200);
    }
    form['description'] = e;
    dispatch(setStrategyTemplateForm(form));
  };

  const inputAlgoId_CMHandler = (e) => {
    let form = { ...strategyTemplateForm };
    form['algoId']['CM'] = e;
    dispatch(setStrategyTemplateForm(form));
  };

  const inputAlgoId_FOHandler = (e) => {
    let form = { ...strategyTemplateForm };
    form['algoId']['FO'] = e;
    dispatch(setStrategyTemplateForm(form));
  };

  const inputAlgoId_CDHandler = (e) => {
    let form = { ...strategyTemplateForm };
    form['algoId']['CD'] = e;
    dispatch(setStrategyTemplateForm(form));
  };

  let { name, description, algoId } = strategyTemplateForm;

  return (
    <div className="step_1">
      <HeaderStrategyBuilder
        title="Enter Template Details"
        icon={TemplateIcon}
        extStyles={{ margin: '0 0 1rem 0' }}
      />
      <div className="temp-fields-cont">
        <div className="temp-field name-field">
          <InputField
            label="Strategy Template Name"
            orientation="v"
            inputWidth="100%"
            placeHolder=""
            type="text"
            value={name}
            onValueChange={inputNameHandler}
            className={
              validator.current.message(
                'name',
                name,
                'required|alpha_num_dash_space|max:50',
                {
                  className: 'error_message',
                }
              ) && formSubmit
                ? 'error-input'
                : isApproveReject
                ? 'approveReject-pointer'
                : ''
            }
          />
        </div>
        <div className="temp-field des-field">
          <InputField
            label="Strategy Description"
            orientation="v"
            inputWidth="100%"
            placeHolder=""
            type="text"
            value={description}
            onValueChange={inputDesHandler}
            className={
              validator.current.message(
                'description',
                description,
                'required|alpha_num_dash_space|max:200',
                {
                  className: 'error_message',
                }
              ) && formSubmit
                ? 'error-input'
                : isApproveReject
                ? 'approveReject-pointer'
                : ''
            }
          />
        </div>
      </div>
      <div className="temp-fields-algoID">
        <div className="temp-field">
          <InputField
            label="Algo ID CM"
            orientation="v"
            inputWidth="100%"
            placeHolder=""
            type="text"
            value={algoId && algoId['CM']}
            onValueChange={inputAlgoId_CMHandler}
            className={
              validator.current.message(
                'algoId_CM',
                algoId && algoId['CM'],
                'required|numeric',
                {
                  className: 'error_message',
                }
              ) && formSubmit
                ? 'error-input'
                : isApproveReject
                ? 'approveReject-pointer'
                : ''
            }
          />
        </div>
        <div className="temp-field">
          <InputField
            label="Algo ID FO"
            orientation="v"
            inputWidth="100%"
            placeHolder=""
            type="text"
            value={algoId && algoId['FO']}
            onValueChange={inputAlgoId_FOHandler}
            className={
              validator.current.message(
                'algoId_FO',
                algoId && algoId['FO'],
                'required|numeric',
                {
                  className: 'error_message',
                }
              ) && formSubmit
                ? 'error-input'
                : isApproveReject
                ? 'approveReject-pointer'
                : ''
            }
          />
        </div>
        <div className="temp-field">
          <InputField
            label="Algo ID CD"
            orientation="v"
            inputWidth="100%"
            placeHolder=""
            type="text"
            value={algoId && algoId['CD']}
            onValueChange={inputAlgoId_CDHandler}
            className={
              validator.current.message(
                'algoId_CD',
                algoId && algoId['CD'],
                'required|numeric',
                {
                  className: 'error_message',
                }
              ) && formSubmit
                ? 'error-input'
                : isApproveReject
                ? 'approveReject-pointer'
                : ''
            }
          />
        </div>
      </div>
      {toaster ? (
        <SimpleSnackbar
          toaster={toaster}
          setToaster={setToaster}
          message={'Strategy Template Name cannot exceed 50 characters'}
        />
      ) : null}
      {disToaster ? (
        <SimpleSnackbar
          toaster={disToaster}
          setToaster={setDisToaster}
          message={'Discription cannot exceed 200 characters'}
        />
      ) : null}
    </div>
  );
};

export default EnterTemplateDetails;

import useSwr from 'swr';
import { MakeRequest } from '../../../utils';
import { fetchValue } from '../../../utils/dotKeyFunctions';
import MultiCheck from '../../../components/sharedComponents/multiOptSelect';

const API = {
  GetAllList: () => {
    const { data, isLoading, error } = useSwr(
      '/admin/risk/configurationV2',
      async () => {
        const response = await MakeRequest('/admin/risk/configurationV2').get();
        return fetchValue(
          response,
          'data.data.1.clearingAccountRiskParameters'
        );
      }
    );
    return [data, isLoading, error];
  },
  GetSelected: () => {
    const { data, isLoading, error } = useSwr(
      '/admin/dataInitBaseParams',
      async () => {
        const response = await MakeRequest('/admin/dataInitBaseParams').get();

        return fetchValue(response, 'data.data.baseConfigParams')
          .filter(
            (item) => item.Parameter === 'strade_kill_switch_risk_param_id'
          )[0]
          .Value.split(',')
          .map((item) => parseInt(item));
      }
    );
    return [data, isLoading, error];
  },
};

export default function HFTDisplayDropdown() {
  const [allData] = API.GetAllList();
  const [Selected] = API.GetSelected();

  function getOptions() {
    if (allData && Selected) {
      let temp = [];
      for (let ite of allData) {
        temp.push({
          state: Selected.includes(ite.riskParamId),
          title: ite.displayName,
        });
      }
      return temp;
    }
  }

  return (
    <MultiCheck
      label="Kill Switch Risk ID"
      options={getOptions()}
      compSettings={{
        type: 2,
        inputWidth: '20vw',
        maxTextAllowed: 20,
        orientation: 'v',
      }}
    />
  );
}

import MakeRequest from '../utils/apiRequest';

const initialState = {
  flag: false,
  isUserLoggedIn: false,
  errorPass: '',
  errorUser: '',
  status: '',
  userId: '',
  alertMessage: '',
  popupState: false,
  licenseState: false,
  licensePopupState: 0,
  licenseDaysRemaining: 0,
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_USER_LOGGEDIN':
      return {
        ...state,
        isUserLoggedIn: action.payload,
        errorPass: action.error,
        errorUser: action.errorUser,
        status: action.status,
        userId: action.custId,
      };
    case 'CLEAR_ERR_LOGIN':
      return {
        ...state,
        errorPass: action.error,
        errorUser: action.error,
      };
    case 'CLEAR_STATUS':
      return {
        ...state,
        status: action.error,
      };
    case 'ALERT_USER':
      return {
        ...state,
        alertMessage: action.alertMessage,
      };
    case 'CLEAR_ALERT':
      return {
        ...state,
        alertMessage: '',
      };
    case 'LOGIN_SET_POPUPSTATE':
      return { ...state, popupState: action.payload };
    case 'TOGGLE_LICENSE':
      return { ...state, licenseState: action.payload };
    case 'LICENSE_POPUP_STATE':
      return { ...state, licensePopupState: action.payload };
    case 'LICENSE_DAYS_REMAIN':
      return { ...state, licenseDaysRemaining: action.payload };
    default:
      return state;
  }
};

export const loginActions = {
  hidePopup: (dispatch) => {
    dispatch({ type: 'LOGIN_SET_POPUPSTATE', payload: false });
  },
  showPopup: (dispatch) => {
    dispatch({ type: 'LOGIN_SET_POPUPSTATE', payload: true });
  },
  activateLicense: async (dispatch, key) => {
    try {
      const response = await MakeRequest('/superadmin/license/renew').post({
        license: key,
      });
      if (response.status === 201) {
        dispatch({ type: 'TOGGLE_LICENSE', payload: true });
      } else {
        dispatch({ type: 'TOGGLE_LICENSE', payload: false });
      }
    } catch (error) {}
  },
  licencePopupState: (dispatch, actionState) => {
    dispatch({ type: 'LICENSE_POPUP_STATE', payload: actionState });
  },
  licenseDaysRemain: (dispatch, days) => {
    dispatch({ type: 'LICENSE_DAYS_REMAIN', payload: days });
  },
};

export default loginReducer;

import { Col, Row } from 'react-bootstrap';
import '../../strategyBuilder/create/previewStrategy/PreviewStrategy.scss';
import Table from '../../sharedComponents/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { getquantityLiveTrading } from '../../../actions/LiveTradingAction';
import { InputField } from '../..';
import './CreateEditStrategyModalV2.scss';
import { useEffect, useState } from 'react';
import Logger from '../../../utils/logger';
import { DropdownIcon } from '../../../assets';

export default function OrderDetailsCreateStrategyV2({
  prevTableData,
  extData,
}) {
  const {
    strategyBuilderReducer: { securityNameOptions },
    liveTradingReducer: { strategy, pricingLogic },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [positionData, setPositionData] = useState([]);
  const [tableToggleState, setTableToggleState] = useState(true);

  let table_coulumn = [
    {
      lebel: 'Direction',
      renderHtml: (params) => {
        let index = params.legIndex;
        return <div className = {`direction ${params.orientation ? params.orientation.toLowerCase() : ''}`}>{params.orientation}</div>;
      },
    },
    {
      lebel: 'Security',
      renderHtml: (params) => {
        let index = params.legIndex;
        return <>{strategy.securities[params.securityIdx]?.nickname}</>;
      },
    },
    {
      lebel: 'Order Qty',
      renderHtml: (params, ind) => {
        let index = params.legIndex;
        return (
          <InputField
            value={
              strategy.entryLegs[params.legIndex].position[params.positionIndex]
                .orderQty
            }
            placeHolder=""
            disabled={fieldDisabled(params, ind, 'orderQty')}
            onValueChange={(val) => {
              inputHandler({ target: { value: val } }, params, 'orderQty');
              if (extData.specialRule === 'SPREAD') {
                switch (ind) {
                  case 0:
                    if (prevTableData[1].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value:
                              val *
                              strategy.securities.find(
                                (el) => el.nickname === params.security
                              ).lotSize,
                          },
                        },
                        positionData[1],
                        'orderQty'
                      );
                    }
                    break;

                  default:
                    if (prevTableData[0].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value:
                              val *
                              strategy.securities.find(
                                (el) => el.nickname === params.security
                              ).lotSize,
                          },
                        },
                        positionData[0],
                        'orderQty'
                      );
                    } else {
                      inputHandler(
                        {
                          target: {
                            value:
                              (val *
                                strategy.securities.find(
                                  (el) => el.nickname === params.security
                                ).lotSize) /
                              strategy.securities[0].lotSize,
                          },
                        },
                        positionData[0],
                        'orderQty'
                      );
                    }
                    break;
                }
              }
            }}
            inputWidth="10vw"
          />
        );
      },
    },
    {
      lebel: 'Total Qty',
      renderHtml: (params, ind) => {
        let index = params.legIndex;

        return (
          <>
            {params.totalQuantityLogic &&
            params.totalQuantityLogic.length > 0 ? (
              <p>Logic</p>
            ) : (
              <InputField
                value={
                  strategy.entryLegs[params.legIndex].position[
                    params.positionIndex
                  ].totalQty
                }
                placeHolder=""
                inputWidth="10vw"
                disabled={fieldDisabled(params, ind)}
                onValueChange={(val) => {
                  switch (extData.specialRule) {
                    case 'SPREAD':
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'totalQty'
                      );
                      switch (ind) {
                        case 0:
                          if (prevTableData[1].instrument === 'Equity') {
                            inputHandler(
                              {
                                target: {
                                  value:
                                    val *
                                    strategy.securities.find(
                                      (el) => el.nickname === params.security
                                    ).lotSize,
                                },
                              },
                              positionData[1],
                              'totalQty'
                            );
                          }
                          break;
                        case 1:
                          if (prevTableData[0].instrument === 'Equity') {
                            inputHandler(
                              {
                                target: {
                                  value:
                                    val *
                                    strategy.securities.find(
                                      (el) => el.nickname === params.security
                                    ).lotSize,
                                },
                              },
                              positionData[0],
                              'totalQty'
                            );
                          } else {
                            inputHandler(
                              {
                                target: {
                                  value:
                                    (val *
                                      strategy.securities.find(
                                        (el) => el.nickname === params.security
                                      ).lotSize) /
                                    strategy.securities[0].lotSize,
                                },
                              },
                              positionData[0],
                              'totalQty'
                            );
                          }
                          break;
                        default:
                          break;
                      }
                      break;
                    case 'POV':
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'orderQty'
                      );
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'totalQty'
                      );
                      break;
                    default:
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'totalQty'
                      );
                      break;
                  }
                }}
              />
            )}
          </>
        );
      },
    },
    {
      lebel: 'Pending Qty',
      renderHtml: (params) => {
        return (
          <>
            {strategy.entryLegs[params.legIndex].position[params.positionIndex]
              .pendingQty || 0}
          </>
        );
      },
    },
    {
      lebel: 'Traded Qty',
      renderHtml: (params) => {
        return (
          <>
            {strategy.entryLegs[params.legIndex].position[params.positionIndex]
              .tradedQty || 0}
          </>
        );
      },
    },
    {
      lebel: 'LOT Size',
      renderHtml: (params) => {
        let index = params.legIndex;
        let newSecurity =
          securityNameOptions &&
          securityNameOptions?.length &&
          securityNameOptions[0] !== null &&
          strategy.securities.find((el) => el.nickname === params.security);
        if (!newSecurity) return <p>#</p>;
        if (
          newSecurity &&
          newSecurity.lotSize &&
          newSecurity.instrument != 'Equity'
        ) {
          return <p id={`lot_size${index}`}>{newSecurity.lotSize}</p>;
        } else {
          return <p>#</p>;
        }
      },
    },
  ];

  function inputHandler(e, params, preveiewQty) {
    let values = parseInt(e.target.value);
    let totalObj = {
      legIndex: params.legIndex,
      positionIndex: params.positionIndex,
      key: 'position',
      value: values,
      preveiewQty,
    };
    dispatch(getquantityLiveTrading(totalObj));
  }

  function fieldDisabled(obj, index, fieldName) {
    if (extData.specialRule === 'SPREAD') {
      if (prevTableData[index]?.instrument === 'Equity') {
        if (
          prevTableData[0].instrument === 'Equity' &&
          prevTableData[1].instrument === 'Equity'
        ) {
          return false;
        } else {
          return true;
        }
      } else if (
        prevTableData[index]?.instrument === 'Future' &&
        index === 0 &&
        prevTableData[0]?.instrument === 'Future' &&
        prevTableData[1]?.instrument === 'Future'
      ) {
        return true;
      } else {
        return false;
      }
    } else if (extData.specialRule === 'POV' && fieldName === 'orderQty') {
      return true;
    }
    return false;
  }

  useEffect(() => {
    try {
      switch (extData.specialRule) {
        case 'SPREAD':
          if (
            (prevTableData.length >= 2 && prevTableData[1]?.instrument && prevTableData[0]?.instrument) &&
            ((strategy.securities[0]?.instrument === 'Equity' && strategy.securities[0].lotSize == '') || strategy.securities[0].lotSize) &&
            ((strategy.securities[1]?.instrument === 'Equity' && strategy.securities[1].lotSize == '') || strategy.securities[1].lotSize) &&
            strategy.entryLegs[0].position[0].totalQty *
              (strategy.securities[0].instrument === 'Equity' ? 1 : strategy.securities[0].lotSize) !==
              strategy.entryLegs[0].position[1].totalQty *
                (strategy.securities[1].instrument === 'Equity' ? 1 : strategy.securities[1].lotSize)
          ) {
            for (
              let entryLegIdx = 0;
              entryLegIdx < strategy.entryLegs.length;
              entryLegIdx++
            ) {
              for (
                let posIdx = 0;
                posIdx < strategy.entryLegs[entryLegIdx].position.length;
                posIdx++
              ) {
                let val = 1;

                switch (posIdx) {
                  case 0:
                    if (prevTableData[1].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value: strategy.securities[0].instrument == 'Equity' ? 1 : strategy.securities[0].lotSize,
                          },
                        },
                        positionData[1],
                        'totalQty'
                      );
                      inputHandler(
                        { target: { value: val } },
                        positionData[0],
                        'totalQty'
                      );

                      inputHandler(
                        {
                          target: {
                            value: strategy.securities[0].instrument == 'Equity' ? 1 : strategy.securities[0].lotSize,
                          },
                        },
                        positionData[1],
                        'orderQty'
                      );
                      inputHandler(
                        { target: { value: val } },
                        positionData[0],
                        'orderQty'
                      );
                    } else {
                      inputHandler(
                        {
                          target: {
                            value: strategy.securities[0].instrument == 'Equity' ? 1 : strategy.securities[0].lotSize,
                          },
                        },
                        positionData[1],
                        'totalQty'
                      );
                      inputHandler(
                        { target: { value: strategy.securities[1].instrument == 'Equity' ? 1 : strategy.securities[1].lotSize } },
                        positionData[0],
                        'totalQty'
                      );

                      inputHandler(
                        {
                          target: {
                            value: strategy.securities[0].instrument == 'Equity' ? 1 : strategy.securities[0].lotSize,
                          },
                        },
                        positionData[1],
                        'orderQty'
                      );
                      inputHandler(
                        { target: { value: strategy.securities[1].instrument == 'Equity' ? 1 : strategy.securities[1].lotSize } },
                        positionData[0],
                        'orderQty'
                      );
                    }
                    break;
                }
              }
            }
          }
          break;
        default:
          break;
      }
      const tableData = [...strategy.entryLegs];
      const tempPosData = [];
      tableData.forEach((entryLeg, legIndex) => {
        entryLeg.position?.map((val, key) =>
          tempPosData.push({
            ...val,
            legIndex,
            positionIndex: key,
            legId: entryLeg.legId,
            algo: entryLeg.algoExecution,
            security: strategy?.securities[val?.securityIdx]?.nickname,
          })
        );
      });
      setPositionData(tempPosData);
    } catch (err) {
      Logger('OrderDetailsCreateStrategyV2', err);
    }
  }, [strategy]);

  return (
    <div className="orderDetailTable orderDetailTable2">
      <div
        className="OrderDetailsCreateStrategyV2-title-container"
        onClick={() => setTableToggleState((old) => !old)}
        tabIndex={'0'}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Enter':
              setTableToggleState((old) => !old);
              break;
            default:
              break;
          }
        }}
      >
        <span className="OrderDetailsCreateStrategyV2-title">
          Order Details
        </span>
        <img
          src={DropdownIcon}
          className="OrderDetailsCreateStrategyV2-title-dropdown"
        />
      </div>
      {tableToggleState && (
        <Table
          className={'responsive table-striped'}
          showHeader={true}
          tableCoulumn={table_coulumn}
          showPagination={false}
          TableData={positionData}
          id="orderDetails"
        />
      )}
    </div>
  );
}

import React from 'react'
import { Form } from 'react-bootstrap'
import { handleEnterWithCallbackAndValue } from './keyPressEventHandler';

const Toggle = ({ id, showLabel, label, handleOnOff,disabled, checked,className }) => {
    return (
        <Form>
            {showLabel?
            <Form.Check 
            type="switch"
            disabled={disabled}
            id={id}
            onClick={(e)=>handleOnOff(e)}
            onKeyPress={(e) => {
              e.target.checked = !e.target.checked;
              handleEnterWithCallbackAndValue(e, handleOnOff, e)
            }}
            label={label?"On":"Off"}
            className={className}
            checked={checked}
    /> 
    :
    <Form.Check 
                type="switch"
                id={id}
                onClick={(e)=>handleOnOff(e)}
                disabled={disabled}
                checked={checked}
                className={className}
                
        /> 
            }
            
        </Form>
    )
}

Toggle.defaultProps = {
    showLabel: true,
    disabled:false
   
  }
export default Toggle

import React, { useEffect, useState } from 'react';
import './ExitCondition.scss';
import Actions from './action/Actions';
import Condition from './condition/Condition';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStatus,
  exitToggleButton,
  setErrorInExit,
  setExitConditions,
} from '../../../../actions/strategyBuilderAction';
import { TIME_OUT } from '../../../../utils/constant';
import { ExitConditionIcon } from '../../../../assets';
import HeaderStrategyBuilder from '../../../../viewScreen/strategyBuilder/create/strategyAnalyzerFormula/header';

const ExitCondition = ({ validator, formSubmit }) => {
  const [showExit, setShowExit] = useState(false);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const {
    strategyBuilderReducer: {
      conditions: data,
      dataConditions: dataMain,
      strategyTemplateForm,
      clearStats,
      exitToggle,
      isEdit,
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);

  useEffect(() => {
    if (clearStats) {
      clearFunction();
    }
  }, [clearStats]);

  useEffect(() => {
    let keyObj = [
      {
        type: 'OPERAND',
        trigger: '',
      },
    ];
    dispatch(setErrorInExit([]));
    dispatch(setExitConditions({ field: keyObj, key: 'condition' }));
  }, [exitToggle]);
  useEffect(() => {
    if (
      isEdit &&
      strategyTemplateForm.exitCondition.action &&
      strategyTemplateForm.exitCondition.action.enumName &&
      strategyTemplateForm.exitCondition.action.args
    ) {
      setShowExit(true);
    }
  }, [isEdit]);
  const handleAlgoClick = (e) => {
    setShowExit(e);
    dispatch(exitToggleButton(e));
  };

  const clearFunction = () => {
    setFlag(!flag);
    setTimeout(() => {
      dispatch(clearStatus(false));
    }, TIME_OUT);
  };

  return (
    <div>
      <HeaderStrategyBuilder
        icon={ExitConditionIcon}
        title="Define Exit Condition and Action"
        switchState={showExit}
        switchStateToggle={handleAlgoClick}
        extStyles={{ margin: '0 0' }}
      />

      {showExit ? (
        <div key={flag} className="condition">
          <Condition validator={validator} formSubmit={formSubmit} />
          <Actions
            validator={validator}
            showExit={showExit}
            flag={flag}
            formSubmit={formSubmit}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ExitCondition;

import React from 'react';
import { Image } from 'react-bootstrap';
const Images = ({ src, handleImages, alt, style, className }) => {
  return (
    <React.Fragment>
      <Image
        className={className ? className : ''}
        src={src}
        onClick={handleImages ? () => handleImages('') : null}
        alt={alt ? alt : ''}
        style={style ? style : ''}
      />
    </React.Fragment>
  );
};

export default Images;

import './styles.css';
import {
  WhiteDeleteIcon,
  WhiteEditIcon,
  WhiteViewIcon,
  Reset_login,
  Reset_password,
  Delete_Disabled,
  Edit_Disabled,
  View_Disabled,
  LiveActive,
  LiveInActive,
  CopyActive,
  CopyInActive,
  PauseActiveIcon,
  PauseInActiveIcon,
  SwitchIconActive,
  SwitchIconInActive,
} from '../../../../assets';

const icons = {
  active: [
    WhiteViewIcon,
    WhiteEditIcon,
    WhiteDeleteIcon,
    Reset_password,
    Reset_login,
    LiveActive,
    CopyActive,
    PauseActiveIcon,
    SwitchIconActive,
  ],
  inactive: [
    View_Disabled,
    Edit_Disabled,
    Delete_Disabled,
    Reset_password,
    Reset_login,
    LiveInActive,
    CopyInActive,
    PauseInActiveIcon,
    SwitchIconInActive,
  ],
};

export default function PillButton({
  iconType = 0,
  clickAction = () => {},
  extStyles = {},
  disabled = false,
  extClass = '',
}) {
  return (
    <div
      className={`PillButton-container ${
        disabled && 'PillButton-dis'
      } ${extClass}`}
      onClick={() => {
        if (!disabled) {
          clickAction();
        }
      }}
      style={extStyles}
      tabIndex={1}
    >
      <img
        src={disabled ? icons.inactive[iconType] : icons.active[iconType]}
        className="PillButton-icon"
        alt="pillIcon"
      />
    </div>
  );
}

import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import './styles.css';

const ToolTip = styled(Tooltip)`
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.9);
`;

const DispText = styled.span`
  color: white;
  display: flex;
`;

export default function ({
  pos = 'top',
  children,
  tooltip = '',
  containerRef,
}) {
  return (
    <OverlayTrigger
      key="top"
      placement={pos}
      container={containerRef}
      overlay={
        <ToolTip>
          <DispText>{tooltip}</DispText>
        </ToolTip>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import DynamicComponent from '../DynamicField/DynamicField';
import './StaticCondition.scss';

const StaticCondition = ({
  inputString,
  args,
  inputObject,
  formInputHandler,
  setStartTime,
  setEndtime,
  startTime,
  endTime,
  selectedDate,
  selectedTrigger,
  idx,
  fieldName,
  selectedValueObj,
  exitCond
}) => {
  function replaceJSX(str, find) {
    let parts = str.split(find);
    const result = [];
    for (let i = 0; i < parts.length; i++) {
      let checkThePosibilities = parts[i].split(inputObject.end);
      if (checkThePosibilities.length > 1) {
        if( parts[i].split(inputObject.end)[1]!== ""){
          result.push(
            <div className="staticConditionText">
              <Form>
                <Container>
                  <Row className="justify-content-md-auto">
                    <Col>{parts[i].split(inputObject.end)[1]}</Col>
                  </Row>
                </Container>
              </Form>
            </div>
          );
        }
        
      } else {
        result.push(
          <div className="staticConditionText">
            <Container>
              <Row className="justify-content-md-auto">
                <Col>{parts[i].split(inputObject.end)[0]}</Col>
              </Row>
            </Container>
          </div>
        );
      }
      // find the argument name

      const arg = parts[i + 1] ? parts[i + 1].split(inputObject.end)[0] : '';
      if (arg)
        if (selectedTrigger !== 'MARKET_TRIGGERED') {
          // TODO:: with the arg variable find the attributes from arg object that is mentioned in the builderObject, call the actual dynamic field component and pass the properties, apply the css as per the requirment.
          if (arg === 'startTime') {
            result.push(
              <DynamicComponent
                marketHandler={formInputHandler}
                selectedDate={startTime}
                startTime={startTime}
                endTime={endTime}
                timeType={'startTime'}
                setTimeData={setStartTime}
                fieldProperties={args[arg]}
                fieldName={fieldName}
                staticArg={arg}
                idx={idx}
                exitCond={exitCond}
              />
            );
          } else if (arg === 'endTime') {
            result.push(
              <DynamicComponent
                marketHandler={formInputHandler}
                selectedDate={endTime}
                startTime={startTime}
                endTime={endTime}
                timeType={'endTime'}
                setTimeData={setEndtime}
                fieldProperties={args[arg]}
                fieldName={fieldName}
                staticArg={arg}
                idx={idx}
                exitCond={exitCond}
              />
            );
          } else {
            let argValueObj = {argValue:selectedValueObj[arg]}
            result.push(
              <DynamicComponent
                marketHandler={formInputHandler}
                selectedDate={selectedDate}
                setStartTime={setStartTime}
                fieldProperties={args[arg]}
                fieldName={fieldName}
                staticArg={arg}
                idx={idx}
                selectedValues={argValueObj}
                selectedTrigger={selectedTrigger}
              />
            );
          }
        } else {
          result.push(
            <DynamicComponent
              marketHandler={formInputHandler}
              selectedDate={selectedDate}
              setStartTime={setStartTime}
              fieldProperties={args[arg]}
            />
          );
        }
    }
    return result;
  }
  const renderDynamic = () => replaceJSX(inputString, inputObject.start);
  return (    
    <span>{renderDynamic()}</span>
  );
};

export default StaticCondition;


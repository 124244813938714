import React, { useEffect, useState, useRef } from 'react';
import './genTreemenu.css';
import RightArrow from '../../../assets/img/right-arrow-icon.svg';
import liveIcon from '../../../assets/img/GreenDot.svg';
import cancelIcon from '../../../assets/img/RedDot.svg';

const TreeOption = (props) => {
  const [activeState, setActiveState] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [childrenState, setChildrenState] = useState([]);
  const {
    title,
    children,
    depth,
    found,
    selected,
    clickAction,
    data,
    isChildTabActive,
    showRightIcon,
  } = props;

  useEffect(() => {
    if (!searchString) {
      setChildrenState(children);
    } else {
      let newArr = [];
      for (let i = 0; i < children.length; i++) {
        if (
          children[i].title.toLowerCase().includes(searchString.toLowerCase())
        ) {
          newArr.push(children[i]);
        }
      }
      setChildrenState(newArr);
    }
  }, [searchString]);

  const iconState = (state) => {
    if (state) {
      return 'rotate';
    } else {
      return 'noRotate';
    }
  };

  const showHideChild = (state) => {
    if (state) {
      return 'showTree';
    } else {
      return 'hide';
    }
  };

  const getIconToDisplay = (state) => {
    if (state) {
      return liveIcon;
    } else {
      return cancelIcon;
    }
  };

  const selectedState = (state) => {
    if (state) {
      return 'selected';
    } else {
      return '';
    }
  };

  const handleEnter = (e, data) => {
    var keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode == '13') {
      setActiveState(!activeState);
      clickAction(data['type'], data['id']);
    }
  };

  return (
    <div className="mainContainer" style={{ marginLeft: `${depth}rem` }}>
      <div
        tabIndex={
          isChildTabActive !== undefined ? (isChildTabActive ? '0' : '-1') : '0'
        }
        className={`${selectedState(props.data.selected)} treeOptCont`}
        onClick={() => {
          setActiveState(!activeState);
          clickAction(data['type'], data['id']);
        }}
        onKeyPress={(event) => {
          handleEnter(event, data);
        }}
      >
        {/* <p className="title">{title}</p>
        {showRightIcon && (
          <img src={getIconToDisplay(found)} className="rightIcon" />
        )}
        {childrenState && childrenState.length > 0 && (
          <img
            src={RightArrow}
            className={`iconObj ${iconState(activeState)}`}
          />
        )} */}

        {childrenState && childrenState.length > 0 ? (
          <img
            src={RightArrow}
            className={`iconObj ${iconState(activeState)}`}
          />
        ) : (
          <span className="iconObj"></span>
        )}

        {showRightIcon && (
          <img src={getIconToDisplay(found)} className="rightIcon" />
        )}

        <p className="title" style={{ margin: '0 auto 0 0' }}>
          {title}
        </p>
      </div>
      {childrenState !== undefined && (
        <div className={`${showHideChild(activeState)}`}>
          {depth === 0 && (
            <div>
              <input
                className="search-xy"
                placeholder="Search"
                style={{
                  marginLeft: `${depth + 1}rem`,
                  marginBottom: '0.75rem',
                  marginTop: '0.25rem',
                }}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              ></input>
            </div>
          )}

          {childrenState.length === 0 && (
            <div
              style={{
                marginLeft: `${depth + 1}rem`,
                marginBottom: '0.75rem',
                marginTop: '0.25rem',
              }}
            >
              No Record{' '}
            </div>
          )}

          {childrenState.length > 0 && (
            <>
              {childrenState.map((obj) => (
                <TreeOption
                  title={obj.title}
                  children={obj.children}
                  depth={depth + 1}
                  found={obj.found}
                  data={obj}
                  clickAction={props['clickAction']}
                  selected={obj['selected']}
                  isChildTabActive={activeState ? true : false}
                  showRightIcon={true}
                />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TreeOption;

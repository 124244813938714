export const GET_SINGLE_FAMILY = 'GET_SINGLE_FAMILY';
export const GET_ALL_FAMILY = 'GET_ALL_FAMILY';
export const GET_ALL_FAMILY_TYPE = 'GET_ALL_FAMILY_TYPE';
export const GET_ALL_ENTITY_TYPE = 'GET_ALL_ENTITY_TYPE';
export const GET_FAMILY_DEALER = 'GET_FAMILY_DEALER';
export const UPDATE_FAMILY_FORM = 'UPDATE_FAMILY_FORM';
export const GET_ALL_FAMILY_SEGMENT = 'GET_ALL_FAMILY_SEGMENT'
export const CREATE_FAMILYDATA = 'CREATE_FAMILYDATA'
export const GET_ALL_FAMILY_EMS = 'GET_ALL_FAMILY_EMS'
export const GET_ALL_FAMILY_EXCHANGES = 'GET_ALL_FAMILY_EXCHANGES'
export const GET_ALERTDATA = 'GET_ALERTDATA'
export const GET_ALL_ALERTDATA = 'GET_ALL_ALERTDATA'
export const POST_APPROVAL = 'POST_APPROVAL'
export const SET_GROUP_ID = 'SET_GROUP_ID'
export const SET_GROUP_DATA_ARRAY = 'SET_GROUP_DATA_ARRAY'
export const SET_APPROVE_REJECT='SET_APPROVE_REJECT'
export const SET_SELECTED_ALERTS = 'SET_SELECTED_ALERTS'
export const CANCEL_ALL_PENDING = 'CANCEL_ALL_PENDING'
export const SET_ALERT_EXPAND = 'SET_ALERT_EXPAND'
export const SET_ALERT_EXPAND_GROUP = 'SET_ALERT_EXPAND_GROUP'
export const UPDATE_TEMPLATE_ID = 'UPDATE_TEMPLATE_ID'
export const UPDATE_IN_TEMPLATE = 'UPDATE_IN_TEMPLATE'
export const UPDATE_ALERT_TABLE = 'UPDATE_ALERT_TABLE'
export const UPDATE_ALERT_LIVE_DATA = 'UPDATE_ALERT_LIVE_DATA'
export const CREATE_BAN_SECURITY_LIST = 'CREATE_BAN_SECURITY_LIST'
export const GET_ALL_BAN = 'GET_ALL_BAN'
export const SET_EDIT_FAMILY = 'SET_EDIT_FAMILY'
export const RESET_FAMILY_DATA = 'RESET_FAMILY_DATA'
export const SET_ALERT_EXPAND_NEW = 'SET_ALERT_EXPAND_NEW'
import React, { useEffect, useState, useRef } from 'react';
import MakeRequest from '../../../utils/apiRequest';
import { TableSettings } from '../../../components/sharedComponents/customComponent';
import searchIcon from '../../../assets/img/search.svg';
import { FeedbackPopup, GenButton } from '../../../components';
import './RiskMonitoring.css';
import liveIcon from '../../../assets/img/GreenDot.svg';
import cancelIcon from '../../../assets/img/RedDot.svg';
import ArrowDown from '../../../assets/img/arrow_down.svg';
import ArrowUp from '../../../assets/img/Arrow_Up.svg';
import logRiskIcon from '../../../assets/img/logRisk.svg';
import liveRiskIcon from '../../../assets/img/liveRisk.svg';
import UnitWindow from './unitWindow/unitWindow';
import Tooltip from '@mui/material/Tooltip';
import { ColumnArrangeIcon } from '../../../assets';
import { webSocketUrl } from '../../../config/apiConfig';
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
} from '@table-library/react-table-library/table';
import { useTree } from '@table-library/react-table-library/tree';
import { useTheme } from '@table-library/react-table-library/theme';
import {
  useRowSelect,
  HeaderCellSelect,
  CellSelect,
  SelectClickTypes,
} from '@table-library/react-table-library/select';
import { TableOrderChangeActions } from '../../../reducers/table_order_reducer';
import { useDispatch, useSelector } from 'react-redux';

var W3CWebSocket = require('websocket').w3cwebsocket;

const tableFixedColumnWidth = '10%';
let initAllTableGridColumnsConfig = ['5%', '6%', '12%'];
let hardcodeColumns = [
  { columnName: 'LastUpdateTime', variableName: 'timestamp', columnSeq: 3 },
  { columnName: 'Name', variableName: 'name', columnSeq: 2 },
  { columnName: 'Id', variableName: 'id', columnSeq: 1 },
]; // Sequence in this array should be in descending order of columnSeq

// let unitsList = ['Ones', 'Thousands', 'Lakhs', 'Crores'];
let unitColumnExcludeList = ['Id', 'Name', 'LastUpdateTime'];

const THEME = {
  Table: null,
  HeaderRow: `
        .th {
          border-bottom: 0px solid #a0a8ae;
          background-color: #F4F4F4;
          font-weight: 500;
          text-align: left;
        }
      `,
  BaseCell: `
        &:not(:last-of-type) {
          border-right: 1px solid #a0a8ae;
        }


        // text-align: left;
        font: normal normal normal 11px/14px Roboto;
        font-weight: regular;
        height: fit-content;
      `,
  Row: `
      cursor: pointer;

      text-align: right;

      .td:nth-of-type(1) {      // checkbox
        text-align: center;
      }

      .td:nth-of-type(2) {      // StrategyID
        text-align: center;
        
      }

      &:nth-of-type(even) {
            background-color: #E4EBF2;
        }


      // &:nth-of-type(4) {
      //   .td:nth-of-type(6) {
      //     background-color: #dddddd;
      //   }
      // }

      
      // &:not(:last-of-type) .td {
      //   border-bottom: 1px solid #a0a8ae;
      // }

      .td {
        border-top: 1px solid #ffffff;    // #a0a8ae
        border-bottom: 1px solid #ffffff;   // #a0a8ae
        padding: 0rem 0rem;
      }

      // &:hover .td {
      //   border-top: 1px solid orange;
      //   border-bottom: 1px solid orange;
      // }
    `,
};

const RiskMonitor = ({ tabSelect, client, setClient, showLogLive = true }) => {
  const [columns, setColumns] = useState([]);
  const [apiCallColumnForWB, setApiCallColumnForWB] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [liveWSData, setLiveWSData] = useState([]);
  const [tempTableTheme, setTempTableTheme] = useState(THEME);
  const [theme, setTheme] = useState({});
  const [selected, setSelected] = useState([]);
  const [allTableGridColumnsConfig, setAllTableGridColumnsConfig] = useState();
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [lastUpdateTime, setLastUpdateTime] = useState('-');
  const [mapOfPrevValues, setMapOfPrevValues] = useState({});
  const [mapOfDeltaValues, setMapOfDeltaValues] = useState({});
  const [riskUnits, setRiskUnits] = useState({});
  const [riskConfig, setRiskConfig] = useState({});
  const [riskIDriskName, setRiskIDriskName] = useState(null);
  const [liveLogAction, setLiveLogAction] = React.useState('');
  const [searchString, setSearchString] = useState(null);
  const [unitsList, setUnitsList] = useState({});
  const [openLogLiveConformationMsg, setOpenLogLiveConformationMsg] =
    React.useState('');
  const [riskDenominator, setRiskDenominator] = useState({});

  const [table_settings, set_table_settings] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [{ label: 'Reorder / Hide & Unhide', id: 20 }],
        },
      ],
    },
  ]);

  const dispatch = useDispatch();

  const tableColSequence = useSelector(
    (state) => state.TableOrderReducer.win_risk_monitoring
  );

  useEffect(() => {
    handleSequenceChange(
      tableColSequence.viewData,
      tableColSequence.staticData
    );
  }, [tableColSequence]);

  useEffect(() => {
    console.log(searchString);
    // if (!searchString) {
    // } else {
    // }
  }, [searchString]);

  useEffect(() => {
    try {
      initialHeaderApiDataFetch();

      TableOrderChangeActions.set_window_OT_SA_data(
        dispatch,
        'risk_monitoring'
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      console.log('Connection made...');
      if (apiCallColumnForWB.length > 0) {
        if (!client || (client && !client.OPEN))
          setClient(new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol'));
        // wsFunctionInit(client);
      }
    } catch (error) {
      console.log(error);
    }
  }, [apiCallColumnForWB]);

  // Init client if client is not null
  useEffect(() => {
    if (client) {
      wsFunctionInit(client);
    }
  }, [client]);

  useEffect(() => {
    X();
  }, [tempTableTheme]);

  const X = () => {
    const newTheme = useTheme(tempTableTheme);
    setTheme(newTheme);
  };

  const initialHeaderApiDataFetch = async () => {
    const response2 = await MakeRequest(
      '/user/currency/getCurrencyUnits'
    ).get();
    if (response2.event !== undefined && !response2.event) {
      return;
    }
    const tempData2 = response2.data.data.data;

    let units = [];
    if (tempData2) {
      units = MakeUnits(tempData2);
    }

    const response3 = await MakeRequest(
      '/user/currency/getCurrencyConversionStruct?tableName=RISK_MONITORING_POST_TRADE'
    ).get();

    if (response3.event !== undefined && !response3.event) {
      return;
    }
    const tempData3 = response3.data.data;

    let curSetUnits = [];
    if (tempData3) {
      curSetUnits = CurrentSetUnits(tempData3);
    }

    const response = await MakeRequest(
      `/user/dynamicTable/getTableStruct?tableName=${process.env.REACT_APP_IS_HFT == 'true' ? 'RISK_MONITORING_POST_TRADE' : 'RISK_MONITORING_POST_TRADE_NON_HFT'}`
    ).get();
    if (response.event !== undefined && !response.event) {
      return;
    }
    const tempData = response.data.data.viewData;

    if (tempData) {
      MakeColomns(tempData, units, curSetUnits);
    }

    const response1 = await MakeRequest('/admin/risk/configurationV2').get();
    if (response1.event !== undefined && !response1.event) {
      return;
    }
    const tempData1 = response1.data.data;

    if (tempData1) {
      MakeMapOfRiskConfig(tempData1);
    }
  };

  const CurrentSetUnits = (data) => {
    return data;
  };

  const MakeUnits = (data) => {
    let units = [];
    let factors = [];
    for (let i = 0; i < data.length; i++) {
      units.push(data[i].name);
      factors.push(data[i].factor);
    }
    let unitsData = {
      units: units,
      factors: factors,
    };
    setUnitsList(unitsData);
    return units;
  };

  function wsFunctionInit(client) {
    client.onerror = function () {};
    client.onopen = function () {
      let customerId = localStorage.getItem('customerId');
      const data = {
        eventType: 'RISK_SUBSCRIBE',
        payload: { userId: customerId, userType: 'ADMIN', isHFT: 1 },
      };

      client.send(JSON.stringify(data));
    };

    client.onclose = function () {
      console.log('WS connection was closed...');
    };

    client.onmessage = function (data) {
      let dataStr = JSON.parse(data.data);
      dataStr = dataStr.payload;
      if (dataStr.lastUpdateTime) {
        setLastUpdateTime(dataStr.lastUpdateTime);
      }
      dataStr = wsDataProcess(dataStr);
      let finalData = [...dataStr];
      // console.log(dataStr);
      setLiveWSData([...finalData]);
    };
  }

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    switch (temp_index[0]) {
      case 'P':
        // * set parent data
        break;
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          let tempColumns = [...table_settings];
          tempColumns.push({ type: 'COL_RE' });
          set_table_settings(tempColumns);
        }
        break;
      case 'B':
        const tempColumn = [...table_settings];
        tempColumn.pop();
        set_table_settings(tempColumn);
        break;
    }
  }

  const creatDummyData = (max = 1000, type = 1) => {
    let data = null;

    if (type === 1) {
      data = {
        clearingAccount: [
          {
            id: 1166,
            mode: 1,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1028,
              },
              {
                id: 2,
                name: 'accID',
                value: 20,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: 1172,
            mode: 1,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1027,
              },
              {
                id: 2,
                name: 'accID',
                value: 21,
              },
              {
                id: 3,
                name: 'MTDPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'FTDPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: 1173,
            mode: 1,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1030,
              },
              {
                id: 2,
                name: 'accID',
                value: 30,
              },
              {
                id: 3,
                name: 'SqOffPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'ExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
        ],
        strategyAccount: [
          {
            id: '1166_1028',
            mode: 1,
            clearingAccountId: 1166,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1030,
              },
              {
                id: 2,
                name: 'accID',
                value: 30,
              },
              {
                id: 3,
                name: 'MarginTPlusFive',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'DailyExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'ExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: '1172_1021',
            mode: 1,
            clearingAccountId: 1172,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1030,
              },
              {
                id: 2,
                name: 'accID',
                value: 30,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'DailyExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'ExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: '1173_1021',
            mode: 1,
            clearingAccountId: 1173,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1028,
              },
              {
                id: 2,
                name: 'accID',
                value: 20,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: '1166_1029',
            mode: 1,
            clearingAccountId: 1166,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1028,
              },
              {
                id: 2,
                name: 'accID',
                value: 20,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: '1172_1022',
            mode: 1,
            clearingAccountId: 1172,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1028,
              },
              {
                id: 2,
                name: 'accID',
                value: 20,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
          {
            id: '1173_1022',
            mode: 1,
            clearingAccountId: 1173,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1028,
              },
              {
                id: 2,
                name: 'accID',
                value: 20,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
        ],
      };
    } else if (type === 2) {
      data = {
        clearingAccount: [
          {
            id: 1166,
            mode: 1,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1028,
              },
              {
                id: 2,
                name: 'accID',
                value: 20,
              },
              {
                id: 3,
                name: 'mandate',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'Margin',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'MarginTPlusOne',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
        ],
        strategyAccount: [
          {
            id: '1166_1028',
            mode: 1,
            clearingAccountId: 1166,
            riskNumbers: [
              {
                id: 1,
                name: 'strategyID',
                value: 1030,
              },
              {
                id: 2,
                name: 'accID',
                value: 30,
              },
              {
                id: 3,
                name: 'MarginTPlusFive',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 4,
                name: 'DailyExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 5,
                name: 'ExpiryPnL',
                value: Math.floor((Math.random() - 0.5) * 2 * max),
              },
              {
                id: 6,
                name: 'mode',
                value: Math.floor(Math.random() * 2),
              },
            ],
          },
        ],
      };
    }
    return data;
  };

  const FilterData = (item) => {
    // console.log(searchString);

    if (searchString) {
      if (item.name.toLowerCase().includes(searchString.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const wsDataProcess = (data) => {
    try {
    // create dummy data
    // data = creatDummyData(10000000000, 1);
    let formattedData = [];
    let mapOfCurrentValues = {};
    let mapOfCurrentValuesKeys = [];

    let tempRiskDenominator = {};

    let riskIdName = {};

    for (let x = 0; x < data.clearingAccount.length; x++) {
      let clearingAcc = data.clearingAccount[x];

      let idOfCA = `ca-${clearingAcc.id}`;

      let parent = {
        id: idOfCA,
        name: clearingAcc.name,
        timestamp: clearingAcc.timestamp
      };
      if (clearingAcc.mode) {
        parent['mode'] = 1;
      }
      for (let i = 0; i < columns.length; i++) {
        if (
          columns[i].variableName === 'id' ||
          columns[i].variableName === 'name' ||
          columns[i].variableName === 'timestamp'
        ) {
          continue;
        }
        parent[columns[i].variableName] = {
          value: 0,
          percentage: 0,
        };
        // riskIdName[columns[i].variableName] = -2;
        for (let j = 0; j < clearingAcc.riskNumbers.length; j++) {
          if (clearingAcc.riskNumbers[j].name === columns[i].variableName) {
            tempRiskDenominator[`${idOfCA}-${columns[i].variableName}`] =
              clearingAcc.riskNumbers[j].marginMandateValue
                ? clearingAcc.riskNumbers[j].marginMandateValue
                : 1;
            parent[columns[i].variableName] = {
              value: clearingAcc.riskNumbers[j].value,
              percentage: clearingAcc.riskNumbers[j].perc,
            };
            riskIdName[columns[i].variableName] = clearingAcc.riskNumbers[j].id;
            break;
          }
        }

        let key = `${idOfCA}-${columns[i].variableName}`;
        mapOfCurrentValues[key] = parent[columns[i].variableName];
        mapOfCurrentValuesKeys.push(key);
      }

      let arr = [];
      let cnt = 0;
      for (let c = 0; c < data.strategyAccount.length; c++) {
        if (data.strategyAccount[c].clearingAccountId == clearingAcc.id) {
          let strategyAcc = data.strategyAccount[c];

          let idOfSA = `sa-${strategyAcc.id}`;

          let child = {
            id: idOfSA,
            name: strategyAcc.name,
            timestamp: strategyAcc.timestamp
          };
          if (strategyAcc.mode) {
            child['mode'] = 1;
          }
          cnt = cnt + 1;
          for (let i = 0; i < columns.length; i++) {
            if (
              columns[i].variableName === 'id' ||
              columns[i].variableName === 'name' ||
              columns[i].variableName === 'timestamp'
            ) {
              continue;
            }
            child[columns[i].variableName] = {
              value: 0,
              percentage: 0,
            };
            for (let j = 0; j < strategyAcc.riskNumbers.length; j++) {
              if (strategyAcc.riskNumbers[j].name === columns[i].variableName) {
                tempRiskDenominator[`${idOfSA}-${columns[i].variableName}`] =
                  strategyAcc.riskNumbers[j].marginMandateValue
                    ? strategyAcc.riskNumbers[j].marginMandateValue
                    : 1;
                // child[columns[i].variableName] =
                //   strategyAcc.riskNumbers[j].value;

                child[columns[i].variableName] = {
                  value: strategyAcc.riskNumbers[j].value,
                  percentage: strategyAcc.riskNumbers[j].perc,
                };
                break;
              }
            }

            let key = `${idOfSA}-${columns[i].variableName}`;
            mapOfCurrentValues[key] = child[columns[i].variableName];
            mapOfCurrentValuesKeys.push(key);
          }

          arr.push(child);
        }
      }

      if (arr.length > 0) {
        parent['nodes'] = arr;
      }
      formattedData.push(parent);
    }

    if (!riskIDriskName) {
      setRiskIDriskName(riskIdName);
    }

    setRiskDenominator(tempRiskDenominator);

    // ? Compute MapOfDeltaValues (Which is -1, 0, 1 based on prev and new map values) and set it to state variable

    let mpDelta = {};
    let mpPrevValue = {};
    for (let i = 0; i < mapOfCurrentValuesKeys.length; i++) {
      let key = mapOfCurrentValuesKeys[i];
      let prevValue = mapOfPrevValues[key] ? mapOfPrevValues[key] : 0;
      let newValue = mapOfCurrentValues[key] ? mapOfCurrentValues[key] : 0;

      let val = 0;
      if (prevValue > newValue) {
        val = -1;
        mpDelta[key] = val;
      } else if (prevValue < newValue) {
        val = 1;
        mpDelta[key] = val;
      } else {
        val = 0;
        mpDelta[key] = val;
      }
      mpPrevValue[key] = newValue;

      if (key === 'ca-1166-mandate') {
        // console.log(prevValue, newValue);
      }
    }

    // ! This setting state is taking time
    // setMapOfDeltaValues({ ...mpDelta });
    // setMapOfPrevValues({ ...mpPrevValue });

    // console.log(formattedData);

    return formattedData;
  } catch (err) {
    console.log(err);
  }
  };

  const ApplyUnits = async (unitObject) => {
    let tempRiskUnitObj = { ...riskUnits };

    let postArr = [];

    for (let i = 0; i < columns.length; i++) {
      if (unitObject[columns[i].columnName]) {
        tempRiskUnitObj[columns[i].columnName] =
          unitObject[columns[i].columnName];

        let temp = {
          columnName: columns[i].columnName,
          value: unitObject[columns[i].columnName],
          variableName: 'INR',
        };
        postArr.push(temp);
      }
    }
    setRiskUnits(tempRiskUnitObj);

    let postData = {
      tableName: 'RISK_MONITORING_POST_TRADE',
      currencyConversionTableSeq: postArr,
    };

    try {
      let response = await MakeRequest(
        '/user/currency/setCurrencyConversionStruct'
      ).post(postData);

      if (response.status === 200) {
        setFeedbackMsg(
          <span style={{ color: 'green' }}>{'Units set successful !'}</span>
        );
      } else {
        setFeedbackMsg(
          <p>
            <span style={{ color: 'red' }}>{'Error : '}</span>
            {'Error in unit setting API'}
          </p>
        );
      }
    } catch (err) {
      setFeedbackMsg(
        <p>
          <span style={{ color: 'red' }}>{'Error : '}</span>
          {'Error while setting units'}
        </p>
      );
    }
  };

  const NumberFormatter = (riskMeasure, value) => {
    let unit = riskUnits[riskMeasure.columnName];
    let finalValue = value;
    if (typeof value === 'number') {
      if (unit) {
        let factor = 1;
        for (let i = 0; i < unitsList.units.length; i++) {
          if (unit === unitsList.units[i]) {
            factor = unitsList.factors[i];
            break;
          }
        }

        finalValue = value * factor;

        factor == 1
          ? (finalValue = finalValue.toFixed(0))
          : (finalValue = finalValue.toFixed(2));
      }
    }
    return finalValue;
  };

  const PercentUtilization = (riskMeasure, value, casa, accID, percentage) => {
    // const riskId = riskIDriskName[riskMeasure.variableName];
    // if (riskId == -1) {
    //   return Math.infinity;
    // }
    // let key = `${casa}-${accID}-${riskMeasure.variableName}`;
    // let riskLimit = riskDenominator[key];
    // let key2 = `${casa}-${riskId}`;
    // let percentageUtil = riskConfig.mapOfRiskIdPercentageUtil[key2];
    // let percentage = ((value / riskLimit) * 100).toFixed(0);

    // if (percentageUtil === 2) {
    //   percentage = -1 * percentage;
    // }
    return percentage;
  };

  const MakeMapOfRiskConfig = (data) => {
    let riskConfigTemp = {};
    let mapOfRiskIdPercentageUtil = {};

    let caData = data[1].clearingAccountRiskLimits;
    let saData = data[1].strategyAccountRiskLimits;

    let caParameters = data[1].clearingAccountRiskParameters;
    let saParameters = data[1].strategyAccountRiskParameters;

    for (let i = 0; i < caData.length; i++) {
      let str = `ca-${caData[i].dimensionValue}-${caData[i].riskParamId}`;
      riskConfigTemp[str] = Number(caData[i].riskParamValue);
    }

    for (let i = 0; i < caParameters.length; i++) {
      let str = `ca-${caParameters[i].riskParamId}`;
      mapOfRiskIdPercentageUtil[str] = Number(caParameters[i].validationValue);
    }

    for (let i = 0; i < saData.length; i++) {
      let str = `sa-${saData[i].dimensionValue}-${saData[i].riskParamId}`;
      riskConfigTemp[str] = Number(saData[i].riskParamValue);
    }

    for (let i = 0; i < saParameters.length; i++) {
      let str = `sa-${saParameters[i].riskParamId}`;
      mapOfRiskIdPercentageUtil[str] = Number(saParameters[i].validationValue);
    }

    let finalObj = {
      riskLimits: riskConfigTemp,
      mapOfRiskIdPercentageUtil: mapOfRiskIdPercentageUtil,
      caLimits: data[1].clearingAccountRiskLimits,
      limitColorCode: data.riskLevelInfo.colorCode,
    };

    // console.log(finalObj);

    setRiskConfig(finalObj);
  };

  const RangeColour = (riskMeasure, value, casa, accID, percentage) => {
    
    if (!percentage) {
      return '#ffffff';
    }

    const riskId = riskIDriskName[riskMeasure.variableName];
    if (!riskId || riskId == -1) {
      return '#ffffff';
    }

    if (riskConfig && riskConfig.caLimits && riskConfig.caLimits.length > 0) {
      let caLimits = riskConfig.caLimits;
      let limit = null;
      for (let i = 0; i < caLimits.length; i++) {
        if (
          Number(caLimits[i].riskParamId) === Number(riskId) &&
          Number(caLimits[i].dimensionValue) === Number(accID)
        ) {
          limit = caLimits[i];
          break;
        }
      }

      if (percentage) percentage = Number(percentage.replace('%', ''));
      
      
      if (!limit) {
        return '#ffffff';
      }

      if (percentage <= limit.L1) {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L0
        ) {
          return riskConfig.limitColorCode.L0;
        } else {
          return '#00800050';
        }
      } else if (percentage > limit.L1 && percentage <= limit.L2) {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L1
        ) {
          return riskConfig.limitColorCode.L1;
        } else {
          return '#00ff0050';
        }
      } else if (percentage > limit.L2 && percentage <= limit.L3) {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L2
        ) {
          return riskConfig.limitColorCode.L2;
        } else {
          return '#fffa66';
        }
      } else if (percentage > limit.L3 && percentage <= limit.L4) {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L3
        ) {
          return riskConfig.limitColorCode.L3;
        } else {
          return '#f8bc66';
        }
      } else if (percentage > limit.L4 && percentage <= limit.L5) {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L4
        ) {
          return riskConfig.limitColorCode.L4;
        } else {
          return '#ffa500';
        }
      } else if (percentage > limit.L5 && percentage <= limit.L6) {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L5
        ) {
          return riskConfig.limitColorCode.L5;
        } else {
          return '#ff6666';
        }
      } else {
        if (
          riskConfig &&
          riskConfig.limitColorCode &&
          riskConfig.limitColorCode.L6
        ) {
          return riskConfig.limitColorCode.L6;
        } else {
          return '#ff0000';
        }
      }
    } else {
      return '#ffffff';
    }
  };

  const MakeColomns = (colomnData, units, curSetUnits) => {
    // ? Formatting and setting column and filter objects
    let columnsArr = [];
    let filterArr = [];
    let tempRiskUnitsObj = {};

    for (let i = 0; i < hardcodeColumns.length; i++) {
      colomnData.splice(0, 0, hardcodeColumns[i]);
    }

    for (let i = 0; i < colomnData.length; i++) {
      let obj = {
        columnName: colomnData[i].columnName,
        variableName: colomnData[i].variableName,
      };
      columnsArr.push(obj);

      obj = {
        id: i,
        state: true,
        title: colomnData[i].columnName,
        columnName: colomnData[i].columnName,
        variableName: colomnData[i].variableName,
      };
      filterArr.push(obj);

      for (let j = 0; j < curSetUnits.length; j++) {
        if (curSetUnits[j].columnName === colomnData[i].columnName) {
          tempRiskUnitsObj[colomnData[i].columnName] = curSetUnits[j].value;
          break;
        }
      }
    }

    TableInitColumnDisplaySetter(columnsArr);

    console.log(columnsArr);
    setColumns(columnsArr);
    setFilterList(filterArr);

    setApiCallColumnForWB(columnsArr);
    setRiskUnits(tempRiskUnitsObj);
  };

  const TableInitColumnDisplaySetter = (columns) => {
    let temp = [];

    for (let i = 0; i < initAllTableGridColumnsConfig.length; i++) {
      temp.push(initAllTableGridColumnsConfig[i]);
    }

    for (
      let i = 0;
      i < columns.length - initAllTableGridColumnsConfig.length + 1;
      i++
    ) {
      temp.push(tableFixedColumnWidth);
    }

    setAllTableGridColumnsConfig(temp);
    let tempTheme = { ...tempTableTheme };
    tempTheme.Table = TableColumnConfig(temp);
    setTempTableTheme(tempTheme);
  };

  const TableColumnConfig = (arr = allTableGridColumnsConfig) => {
    let startingPart = '--data-table-library_grid-template-columns: ';
    let endingPart =
      'minmax(20px, 1fr); scrollbar-width: thin; scrollbar-color: #D3D3D3 ;';
    let middlePart = '';
    for (let i = 0; i < arr.length; i++) {
      middlePart += arr[i];
      middlePart += ' ';
    }

    let finalStr = '';
    finalStr += startingPart + middlePart + endingPart;

    return finalStr;
  };

  const alterTableAttributeCongif = (filterArr) => {
    let tempTheme = { ...tempTableTheme };

    let tempConfigArr = [];
    tempConfigArr.push(allTableGridColumnsConfig[0]);
    for (let i = 0; i < filterArr.length; i++) {
      if (filterArr[i].state) {
        tempConfigArr.push(allTableGridColumnsConfig[i + 1]);
      }
    }

    let tableStr = TableColumnConfig(tempConfigArr);
    tempTheme.Table = tableStr;
    setTempTableTheme(tempTheme);
  };

  const handleCheckChange = (target, state) => {
    console.log(target, state);

    if (
      target.variableName === 'id' ||
      target.variableName === 'name' ||
      target.variableName === 'mode' ||
      target.variableName === 'timestamp'
    ) {
      return;
    }

    let columnsArrTemp = [...columns];
    let filterArrTemp = [...filterList];
    if (!state) {
      for (let i = 0; i < columnsArrTemp.length; i++) {
        if (columnsArrTemp[i].variableName === target.variableName) {
          columnsArrTemp.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < filterArrTemp.length; i++) {
        if (filterArrTemp[i].variableName === target.variableName) {
          filterArrTemp[i].state = false;
          break;
        }
      }
    } else {
      let index = -1;
      for (let i = 0; i < filterArrTemp.length; i++) {
        if (filterArrTemp[i].variableName === target.variableName) {
          filterArrTemp[i].state = true;
          index = i;
          break;
        }
      }
      let obj = {
        columnName: filterArrTemp[index].columnName,
        variableName: filterArrTemp[index].variableName,
      };
      columnsArrTemp.splice(index, 0, obj);
    }
    alterTableAttributeCongif(filterArrTemp);
    setColumns(columnsArrTemp);
    setFilterList(filterArrTemp);
  };

  const handleSequenceChange = (seq, orignalSeq) => {
    let sequence = [...seq];
    let orignalSequence = [...orignalSeq];

    if (sequence.length > 0 && orignalSequence.length > 0) {
      for (let i = 0; i < sequence.length; i++) {
        sequence[i].columnSeq = sequence[i].columnSeq + hardcodeColumns.length;
        orignalSequence[i].columnSeq =
          orignalSequence[i].columnSeq + hardcodeColumns.length;
      }

      for (let i = 0; i < hardcodeColumns.length; i++) {
        sequence.splice(0, 0, hardcodeColumns[i]);
        orignalSequence.splice(0, 0, hardcodeColumns[i]);
      }

      // Set width of columns
      if (allTableGridColumnsConfig && allTableGridColumnsConfig.length > 0) {
        let tempTheme = { ...tempTableTheme };
        let tempConfigArr = [];
        tempConfigArr.push(allTableGridColumnsConfig[0]);

        for (let i = 0; i < sequence.length; i++) {
          let index = -1;
          for (let j = 0; j < orignalSequence.length; j++) {
            if (orignalSequence[j].columnName === sequence[i].columnName) {
              index = j;
              break;
            }
          }
          tempConfigArr.push(allTableGridColumnsConfig[index + 1]);
        }

        let tableStr = TableColumnConfig(tempConfigArr);
        tempTheme.Table = tableStr;
        setTempTableTheme(tempTheme);
      }

      // Set column sequence
      setColumns(sequence);
    }
  };

  const openLiveLogConformation = (type) => {
    setLiveLogAction(type);
    setOpenLogLiveConformationMsg(
      `Are you sure to ${type} ${selected.length} selected accounts?`
    );
  };

  const logLiveAction = async (type) => {
    let data = {
      riskDimensionEnum: 'STRATEGY_ACCOUNT',
      riskDimensionValueArr: [...selected],
      operationTypeEnum: null,
    };

    if (type === 'log') {
      data.operationTypeEnum = 0;
    } else if (type === 'live') {
      data.operationTypeEnum = 1;
    }

    let response = await MakeRequest('/admin/risk/monitor/log-live-V2').post(
      data
    );

    if (response.status === 200) {
      // Success
      setFeedbackMsg(
        <span style={{ color: 'green' }}>{response.data.data.message}</span>
      );
    } else {
      // Failure
      setFeedbackMsg(
        <p>
          <span style={{ color: 'red' }}>{'Error : '}</span>
          {response.data.data.message}
        </p>
      );
    }
  };

  const data = { nodes: liveWSData };

  const tree = useTree(
    data,
    {
      // state: {
      //   ids: ['3'],
      // },
      onChange: onTreeChange,
    },
    // {
    //   clickType: TreeExpandClickTypes.ButtonClick,
    //   treeYLevel: 1,
    // },
    {
      treeIcon: {
        margin: '10px',
        size: '15px',
        noIconMargin: '5px',
        // iconDefault: <ArrowDown />,
        // iconRight: <ArrowUp />,
        // iconDown: <FolderOpenIcon />,

        iconDown: <img src={ArrowUp} />,
        iconRight: <img src={ArrowDown} />,
      },
    }
  );

  const select = useRowSelect(
    data,
    {
      onChange: onSelectChange,
    },
    {
      clickType: SelectClickTypes.ButtonClick,
    }
  );

  function onSelectChange(action, state) {
    console.log(action, state);
    if (!showLogLive || (localStorage.getItem('userType') != 'admin')) return;

    let idSet = [];
    for (let i = 0; i < state.ids.length; i++) {
      let prefix = state.ids[i].substring(0, 2);
      if (prefix === 'sa') {
        let sa = state.ids[i].substring(3);
        idSet.push(sa);
      }
    }
    setSelected(idSet);
  }

  function onTreeChange(action, state) {
    console.log(action, state);
  }

  function subsetColumnArrayForUnits(columns, unitColumnExcludeList) {
    let tempArr = [];
    for (let i = 0; i < columns.length; i++) {
      let found = false;
      for (let j = 0; j < unitColumnExcludeList.length; j++) {
        if (columns[i].columnName === unitColumnExcludeList[j]) {
          found = true;
          break;
        }
      }
      if (!found) {
        tempArr.push(columns[i]);
      }
    }
    return tempArr;
  }

  return (
    <div className="page-div">
      <div className="header-container">
        <div className="search">
          <div className="search-container-x">
            <img
              style={{
                height: '15px',
                width: '15px',
                marginRight: '0.5vw',
                marginLeft: '0.25vw',
              }}
              src={searchIcon}
              alt=""
            />
            <input
              style={{ border: 'none' }}
              placeholder="Search (Name)"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            ></input>
          </div>
        </div>

        <div className="ldu">
          Last Updated Time : <b className="bTag">{lastUpdateTime}</b>
        </div>
        {unitsList.units &&
          unitsList.factors &&
          unitsList.units.length > 0 &&
          unitsList.factors.length > 0 &&
          riskUnits && (
            <>
              <UnitWindow
                riskMeasuresList={subsetColumnArrayForUnits(
                  columns,
                  unitColumnExcludeList
                )}
                unitsList={unitsList.units}
                factors={unitsList.factors}
                applyAction={ApplyUnits}
                existingRiskUnits={riskUnits}
              />
            </>
          )}
        <TableSettings
          menuOptions={table_settings}
          clickAction={colModifier}
          updateColCallBack={() => {}}
          extStyles={{ marginLeft: '1vw' }}
          screenName="risk_monitoring"
        />
      </div>
      {selected.length > 0 && (
        <div id="mainPanelLogLive">
          <div className="optionsLogLive">
            <p className="textCont2">{selected.length} Selected</p>
            <div className="btn">
              <GenButton
                type="subButton"
                fill={false}
                icon={logRiskIcon}
                title="Log"
                clickAction={() => openLiveLogConformation('log')}
                params="log"
              />
            </div>
            <div className="btn">
              <GenButton
                type="subButton"
                icon={liveRiskIcon}
                fill={false}
                title="Live"
                clickAction={() => openLiveLogConformation('live')}
                params="live"
                className="liveButton"
              />
            </div>
          </div>
        </div>
      )}

      <Table
        data={data}
        theme={theme}
        layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}
        select={select}
      >
        {(tableList) => (
          <>
            <Header>
              {columns.length > 0 && (
                <HeaderRow>
                  <HeaderCellSelect>
                    <div className="header-select"></div>
                  </HeaderCellSelect>
                  {columns.map((item) => (
                    <HeaderCell>
                      <Tooltip title={item.columnName} arrow>
                        <div className="header-text">
                          {item.columnName}
                          {unitColumnExcludeList.includes(item.columnName)
                            ? ''
                            : riskUnits[item.columnName]
                            ? ` (${riskUnits[item.columnName]})`
                            : ' (Ones)'}
                        </div>
                      </Tooltip>
                    </HeaderCell>
                  ))}
                </HeaderRow>
              )}
            </Header>

            <Body>
              {tableList.map((item) => (
                <>
                  {FilterData(item) && (
                    <Row key={item.id} item={item}>
                      <Cell />
                        <div className="cell-select">
                          <CellSelect item={item} />
                        </div>

                      {columns.map((attribute) => (
                        <>
                          {attribute.variableName === 'name' ? (
                            <Tooltip title={item[attribute.variableName]} arrow>
                              <div className="cell-tree">
                                <Cell item={item}>
                                  <div className="nameIconCellLeftAlign">
                                    <img
                                      src={
                                        item['mode'] == true
                                          ? liveIcon
                                          : cancelIcon
                                      }
                                      className="modeIcon"
                                      alt="searchIcon"
                                    />
                                    {item[attribute.variableName]}
                                  </div>
                                </Cell>
                              </div>
                            </Tooltip>
                          ) : (
                            <>
                              {attribute.variableName === 'id' ? (
                                <Cell>
                                  <Tooltip
                                    title={item[
                                      attribute.variableName
                                    ].substring(3)}
                                    arrow
                                  >
                                    <div className="cell">
                                      {item[attribute.variableName].substring(
                                        3
                                      )}
                                    </div>
                                  </Tooltip>
                                </Cell>
                              ) : ( attribute.variableName === 'timestamp' ? (
                                  <Cell>
                                      <div className="cell" style={{minWidth: "50px"}}>
                                        {
                                          (attribute.variableName in item) ? item[attribute.variableName] : '-NA-'
                                        }
                                      </div>
                                  </Cell>
                                ) : (
                                  <Cell>
                                  <Tooltip
                                    title={item[attribute.variableName]?.value}
                                    arrow
                                  >
                                    <div
                                      style={{
                                        backgroundColor: `${(attribute.variableName && item[attribute.variableName] && item[attribute.variableName].value) ? RangeColour(
                                          attribute,
                                          item[attribute.variableName].value,
                                          item['id'].substring(0, 2),
                                          item['id'].substring(3),
                                          item[attribute.variableName]
                                            .percentage
                                        ) : '#ffffff'}`,
                                      }}
                                      className={`cell`}
                                    >
                                      {PercentUtilization(
                                        attribute,
                                        item[attribute.variableName]?.value,
                                        item['id'].substring(0, 2),
                                        item['id'].substring(3),
                                        item[attribute.variableName]?.percentage
                                      ) === 0 ||
                                      !PercentUtilization(
                                        attribute,
                                        item[attribute.variableName]?.value,
                                        item['id'].substring(0, 2),
                                        item['id'].substring(3),
                                        item[attribute.variableName]?.percentage
                                      ) ? (
                                        NumberFormatter(
                                          attribute,
                                          item[attribute.variableName]?.value
                                        )
                                      ) : (
                                        <>
                                          <div>
                                            {NumberFormatter(
                                              attribute,
                                              item[attribute.variableName]?.value
                                            )}
                                          </div>
                                          <div className="percentage-text">
                                            (
                                            {PercentUtilization(
                                              attribute,
                                              item[attribute.variableName]
                                                ?.value,
                                              item['id'].substring(0, 2),
                                              item['id'].substring(3),
                                              item[attribute.variableName]
                                                ?.percentage
                                            )}
                                            )
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </Tooltip>
                                </Cell>
                                )
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </Row>
                  )}
                </>
              ))}
            </Body>
          </>
        )}
      </Table>

      <div className="noteTextRM">
        Note: NA in % utilization denotes risk limit not configured
      </div>
      <FeedbackPopup
        message={openLogLiveConformationMsg}
        type="a"
        acceptAction={() => {
          setOpenLogLiveConformationMsg('');
          logLiveAction(liveLogAction);
        }}
        rejectAction={() => {
          setOpenLogLiveConformationMsg('');
        }}
      />
      <FeedbackPopup
        message={feedbackMsg}
        type="f"
        acceptAction={() => {
          setFeedbackMsg('');
        }}
      />
    </div>
  );
};

export default RiskMonitor;

import './styles.css';
import CompLabel from '../customComponent/compLabel';
import CheckBox from '../check_box';
import IconddInternal from './iconDropdown';
import { GenButton } from '../..';

export default function DropDown({
  id = '',
  label = '',
  selected = '',
  options = [],
  optionDisplayFunction = (val) => val,
  onSelect,
  orientation = 'h',
  disabled = false,
  extStyles = {},
  ddExtStyles = {},
  compLabelExtStyles = {},
  textExtStyles = {},
  all_check,
  trigger = (state) => {},
  enable_search = false,
  class_name = '',
  type = 'planeDropDown',
  settings = {},
  settingModifier = () => {},
  error = false,
}) {
  function loadContent() {
    if (Object.keys(settings).length > 0) {
      return (
        <IconddInternal settings={settings} settingModifier={settingModifier} />
      );
    } else {
      return (
        <div style={extStyles} className={class_name}>
          {all_check && all_check.state !== undefined && (
            <CheckBox
              state={all_check.state}
              trigger={trigger}
              label={all_check.label}
            />
          )}
          <CompLabel
            label={label}
            orientation={orientation}
            extStyles={compLabelExtStyles}
          >
            <GenButton
              allSelect={all_check}
              uniqueId={id}
              type={type}
              title={optionDisplayFunction(selected)}
              options={options}
              changeTrig={(val, state) => onSelect(val, state)}
              disabled={disabled}
              enable_search={enable_search}
              extStyle={{ text: textExtStyles, back: ddExtStyles }}
              error={error}
              optionDisplayFunction={optionDisplayFunction}
            />
          </CompLabel>
        </div>
      );
    }
  }

  return loadContent();
}

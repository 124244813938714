import React, { useState, useEffect, useRef } from 'react';
import { InputField, DropDown, GenButton } from './../../../../components';
import DynamicField from '../../../../components/sharedComponents/DynamicField/DynamicField';
import { FileUploadIcon } from '../../../../assets';
import { OpsReducerActions } from '../../redux';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';

const SelParamTradePopup = ({ handleTradeMatchClick, setSelParPopup }) => {
  const { popupValues } = useSelector((state) => state.OpsReducer);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [params, setParams] = useState(popupValues);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const dispatch = useDispatch();

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setSelectedFile1(file);
  };

  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
  };

  const [formData, setFormData] = useState({
    selectedDate: params.selectedDate,
    selectedStartTime: params.selectedStartTime,
    selectedEndTime: params.selectedEndTime,
    selectedExchange: params.selectedExchange,
    selectedSegment: params.selectedSegment,
    selectedEMS: params.selectedEMS,
  });

  const handleInputChange = async ({ name, value }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const updateParam = async () =>
      await OpsReducerActions.updateParamsPopup({ dispatch, data: formData });
    updateParam();
  }, [formData, dispatch]);

  useEffect(() => {
    const filter = async () => {
      if (
        formData.selectedEMS.length === 0 ||
        formData.selectedEMS[0].exchange !== formData.selectedExchange ||
        formData.selectedEMS[0].segment !== formData.selectedSegment
      ) {
        let resetEMS = params.ems.filter((item) => {
          return (
            item.exchange === formData.selectedExchange &&
            item.segment === formData.selectedSegment
          );
        });

        setFormData({ ...formData, selectedEMS: resetEMS });
      }
    };
    filter();
  }, [formData.selectedExchange, formData.selectedSegment]);

  return (
    <div className="select-param-popup-cont">
      <div className="select-param-popup-title-container">
        <span className="select-param-popup-title">Select Params</span>
      </div>
      <div className="select-param-popup-middlearea">
        <div className="select-param-popup-middlearea-1">
          <div className="input-1">
            <InputField
              type="date"
              label="Date"
              onValueChange={(value) =>
                handleInputChange({ name: 'selectedDate', value })
              }
              value={formData.selectedDate}
            />
          </div>
          <div className="input-2">
            <DynamicField
              fieldProperties={{
                type: 'INPUTBOX',
                inputType: 'date_time',
                displayName: 'Start Time',
              }}
              displayLabel={true}
              selectedDate={formData.selectedStartTime}
              startTime={formData.selectedStartTime}
              endTime={formData.selectedEndTime}
              timeType={'startTime'}
              setTimeData={(time) => {
                // console.log(time);
                handleInputChange({
                  name: 'selectedStartTime',
                  value: time._d,
                });
              }}
            />
          </div>
          <div className="input-3">
            <DynamicField
              fieldProperties={{
                type: 'INPUTBOX',
                inputType: 'date_time',
                displayName: 'End Time',
              }}
              displayLabel={true}
              selectedDate={formData.selectedEndTime}
              startTime={formData.selectedStartTime}
              endTime={formData.selectedEndTime}
              timeType={'endTime'}
              setTimeData={(time) =>
                handleInputChange({ name: 'selectedEndTime', value: time._d })
              }
            />
          </div>
          <div className="input-4">
            <DropDown
              selected={formData.selectedExchange}
              label="Exchange"
              options={params.exchange}
              orientation="v"
              type="planeDropDown"
              textLengthLimit={15}
              onSelect={(value) =>
                handleInputChange({ name: 'selectedExchange', value })
              }
            />
          </div>
          <div className="input-5">
            {' '}
            <DropDown
              selected={formData.selectedSegment}
              onSelect={(value) =>
                handleInputChange({ name: 'selectedSegment', value })
              }
              label="Segment"
              orientation="v"
              options={params.segment}
              type="planeDropDown"
              textLengthLimit={15}
            />
          </div>
        </div>
        <div className="input-6-7">
          <div className="input-6">
            <DropDown
              onSelect={(item) => {
                if (item.title === 'All') {
                  setFormData({
                    ...formData,
                    selectedEMS: formData.selectedEMS.map((it1) => {
                      return { ...it1, state: !item.state };
                    }),
                  });
                } else {
                  let temp = [];
                  for (var i = 0; i < formData.selectedEMS.length; i++) {
                    if (formData.selectedEMS[i].title === item.title) {
                      let newData = {
                        ...item,
                        state: !formData.selectedEMS[i].state,
                      };
                      temp.push(newData);
                    } else temp.push(formData.selectedEMS[i]);
                  }

                  setFormData({ ...formData, selectedEMS: temp });
                }
              }}
              label="EMS"
              options={formData.selectedEMS}
              selected="Show Options"
              type="multiSelect"
              orientation="v"
              textLengthLimit={15}
              extStyles={{
                text: { color: '#cecece' },
                back: { borderColor: '#cecece' },
              }}
            />
          </div>
        </div>
        <div className="upload-file">
          <p>Exchange Trade File</p>
          <div className="upload-file-container">
            {
              <input
                type="text"
                className="file-upload-placeholder"
                placeholder={
                  selectedFile1 && selectedFile1.name
                    ? selectedFile1.name
                    : 'Please select a .csv file to upload'
                }
                readOnly
              />
            }
            <label htmlFor="file-upload-1" className="file-upload-label">
              <img src={FileUploadIcon} alt="" className="image-blue-filter" />
            </label>
            <input
              accept=".csv"
              id="file-upload-1"
              type="file"
              className="file-upload-input"
              ref={fileInputRef1}
              onChange={handleFileChange1}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <div className="upload-file">
          <p>Token File</p>
          <div className="upload-file-container">
            {
              <input
                type="text"
                className="file-upload-placeholder"
                placeholder={
                  selectedFile2 && selectedFile2.name
                    ? selectedFile2.name
                    : 'Please select a .csv file to upload'
                }
                readOnly
              />
            }
            <label htmlFor="file-upload-2" className="file-upload-label">
              <img src={FileUploadIcon} alt="" className="image-blue-filter" />
            </label>
            <input
              accept=".csv"
              id="file-upload-2"
              type="file"
              className="file-upload-input"
              ref={fileInputRef2}
              onChange={handleFileChange2}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
      <div className="select-param-popup-buttonarea">
        <GenButton
          fill={true}
          title="MATCH TRADES"
          extStyle={{ margin: '0 2vw 0 0' }}
          clickAction={() => {
            handleTradeMatchClick({ formData, selectedFile1, selectedFile2 });
          }}
        />
        <GenButton
          fill={false}
          title="CLOSE"
          extStyle={{ margin: '0 2vw 0 0' }}
          clickAction={() => setSelParPopup(false)}
        />
      </div>
    </div>
  );
};

export default SelParamTradePopup;

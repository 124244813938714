import { useEffect, useState } from 'react';
import { TableEntries } from '../../../sharedComponents/TableEntries/TableEntries';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../sharedComponents/Table/Table';
import UiInput from '../../../sharedComponents/Input';
import Images from '../../../sharedComponents/Images';
import cross from '../../../../assets/img/icons8-multiply-26.png';
import Moment from 'react-moment';
import './StrategyView.scss';
import Popup from '../../../sharedComponents/Popup/Popup';
import {
  builderObject,
  displayData,
  modifyTemplate,
  strategyView,
  viewEntriesDelete,
  liveEntires,
  reset,
  setEditModify,
  updateSortCulmnView,
  updateCopy,
  saveAsDraft,
} from '../../../../actions/strategyBuilderAction';
import { CircularProgress } from '@material-ui/core';
import ReviewStrategy from './reviewStrategy/ReviewStrategy';
import { CONFIRM_COPY } from '../../../../utils/constant';
import { Button, PillButton } from '../../../../components';
import { AddCircle, DropdownIcon } from '../../../../assets';
import { MasterTemplatePopup } from './masterDataPopUp';
import { MasterDataActions } from './masterDataRedux';
import { MakeRequest } from '../../../../utils';
import { TableHeadText } from '../../../sharedComponents/GenTable/tableStyledComps';

const StrategyViewData = () => {
  const {
    strategyBuilderReducer: {
      strategyView: viewData,
      dataConditions,
      strategyTemplateForm,
      sortColumn,
      isCopy,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const masterTempAction = new MasterDataActions(dispatch);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [userShow, setUserShow] = useState([...viewData]);
  const [searchUser, setSearchUser] = useState('');
  const [loader, setLoader] = useState(false);
  const [showPosts] = useState([5, 10, 20, 30]);
  const [currentPosts, setCurrentPosts] = useState([...setCurrentPostfun()]);
  const [liveModal, setLiveModal] = useState(false);
  const [submitMessage, setSubmitMessage] = useState({});
  const [copyModal, setCopyModal] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [deleteStrategy, setDeleteStrategy] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deleteMsg, setDeleteMsg] = useState('');
  const [delPopUp, setDelPopUp] = useState(false);
  const [copyId, setCopyId] = useState('');
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });

  const [masTempPopup, setMasTempPopup] = useState({
    popup: false,
    editId: false,
  });

  useEffect(() => {
    dispatch(strategyView());
    dispatch(builderObject());
    return () => {
      handleClose(true);
    };
  }, []);

  useEffect(() => {
    setUserShow([...viewData]);
  }, [viewData]);

  useEffect(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsData;
    if (userShow) {
      currentPostsData = userShow.slice(indexOfFirstPost, indexOfLastPost);
    }
    setCurrentPosts([...currentPostsData]);
  }, [postsPerPage, currentPage, userShow]);

  useEffect(() => {
    if (Object.keys(dataConditions).length) {
      dispatch(displayData());
    }
  }, [dataConditions, dispatch]);

  useEffect(() => {
    if (currentPosts.length === 0 && currentPage !== 1) {
      paginate(currentPage - 1);
    }
  }, [currentPosts, currentPage]);

  useEffect(() => {
    const fetchMasterTemplateData = async () => {
      const strategyTemplates = await MakeRequest(
        '/strategyTemplate/unmappedTemplates'
      ).get();
      masterTempAction.updateTemplate(strategyTemplates.data.data);
    };
    fetchMasterTemplateData();
  }, []);

  // set default popups after modal
  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (popup.type) {
      case 'copyhandler':
        setPopup({
          ...popup,
          message: CONFIRM_COPY + ' ' + copyId,
          buttons: 'doubleButtons',
          nameAccept: 'Confirm',
          nameDecline: 'Cancel',
        });
        break;
      case 'deltedata':
        setPopup({
          ...popup,
          message: deleteMsg,
          buttons: 'doubleButtons',
          nameAccept: 'Confirm',
          nameDecline: 'Cancel',
        });
        break;
      case 'deleteConfirm':
        setPopup({
          ...popup,
          message: deleteMsg.message,
          buttons: 'singleButton',
        });
        break;
      case 'liveModal':
        setPopup({
          ...popup,
          message: submitMessage && submitMessage.message,
          buttons: 'singleButton',
        });
        break;
      case 'editView':
        setPopup({
          ...popup,
          message: submitMessage.message,
          buttons: 'singleButton',
        });
        break;
    }
  }, [popup.type, submitMessage]);

  // set functionalty for popup
  const actionOnpopup = (e, type) => {
    if (type === 'CLOSE') {
      setPopup({ state: false });
      window.location.reload();
    } else {
      // eslint-disable-next-line default-case
      switch (popup.type) {
        case 'copyhandler':
          copyHandler(copyId);
          break;
        case 'deltedata':
          handleStartegyDel();
          break;
        case 'editView':
          submitFinalFunc();
          break;
      }
    }
  };

  const handleShowPost = (e) => {
    if (e.target.value !== '') {
      setPostsPerPage(e.target.value);
      paginate(1);
    }
  };

  const sortingFuction = (type, column) => {
    dispatch(
      updateSortCulmnView({ sortColumn: column.field, sortingType: type })
    );
  };

  // Search function for table data
  const handleSearch = (text) => {
    let inputText = text.target.value;
    let da;
    let finalResult = [];
    let inputsearch = inputText.toLowerCase();

    if (inputsearch.trim().length > 0) {
      for (let i = 0; i < viewData.length; i++) {
        da = viewData[i]?.name?.toLowerCase().includes(inputsearch);
        if (da === true) {
          finalResult.push(viewData[i]);
        }
      }
      setUserShow([...finalResult]);
    } else {
      setUserShow([...viewData]);
    }
    setSearchUser(inputText);
    paginate(1);
  };

  // clear text in search field
  const handleClearSearch = () => {
    setSearchUser('');
    paginate(1);
    setUserShow([...viewData]);
  };

  // set number in the field for the  posts show in the table
  function setCurrentPostfun() {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsdata;
    if (viewData) {
      currentPostsdata = viewData.slice(indexOfFirstPost, indexOfLastPost);
    }
    return currentPostsdata;
  }
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // close button  function for clear form
  const handleClose = (clear) => {
    if (clear) {
      let clearForm = {
        name: '',
        description: '',
        exchange: '',
        securities: [
          {
            instrument: '',
            securityName: '',
            expiry: '',
            strike: '',
            nickname: '',
            isAdditional: false,
          },
        ],
        constants: [],
        entryLegs: [
          {
            legId: 1,
            condition: [
              {
                type: 'OPERAND',
                trigger: '',
              },
            ],
            position: [
              {
                orientation: 'Fwd',
                direction: 'BUY',
                security: '',
                pricing: [],
                quantity: [],
                totalQuantityLogic: [],
              },
            ],
            algoExecution: [],
          },
        ],
        exitCondition: {
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          action: {
            enumName: '',
            args: [],
          },
        },
      };
      dispatch(reset(clearForm));
    }
  };
  const editButton = async (id) => {
    const {
      data: { data: response },
    } = await MakeRequest(`/strategyTemplate/approvalReturn/${id}`).get();

    dispatch(modifyTemplate(response && response[0], id));
    setModal(true);
  };
  const copyHandler = async (data) => {
    const {
      data: { data: response },
    } = await MakeRequest(`/strategyTemplate/copy`).post({
      templateId: data,
    });

    dispatch(modifyTemplate(response, data.id));
    setCopyModal(false);
    setCopyId('');
    dispatch(updateCopy(true));
    setPopup({
      state: true,
      message: `Strategy ${copyId} has been successfully copied.`,
      buttons: 'singleButton',
    });
  };
  // create new copy of listing and create modal to confrim

  const copyPopUpHandler = async (data) => {
    if (data) {
      let templateData = { ...strategyTemplateForm };
      let submitResult = await dispatch(saveAsDraft(templateData));
      setSubmitMessage(submitResult);
      setModalSubmit(true);
      setCopyModal(false);
      setCopyId('');

      if (submitResult) {
        dispatch(strategyView());
        dispatch(updateCopy(false));
        dispatch(setEditModify(false));
        setPopup({ type: 'editView', state: true });
      }
    } else {
      handleClose();
      setCopyModal(false);
      dispatch(setEditModify(false));
      setCopyId('');
    }
  };

  // Delete data from the row in the table
  const deleteButton = (id) => {
    setDeleteId(id);
    setDeleteMsg(`Do you want to delete: ${id}`);
    setDeleteStrategy(true);
    setPopup({ type: 'deltedata', state: true });
  };
  // set message after delte the entry
  const handelStrategyDelete = async () => {
    let deleteResult = await dispatch(viewEntriesDelete(deleteId));
    setDeleteMsg(deleteResult);
    setDelPopUp(true);
    setDeleteId('');
    setPopup({ type: 'deleteConfirm', state: true });
  };

  // set modal after submit the copy
  const modalSubmitHandler = (data) => {
    if (isCopy) {
      dispatch(updateCopy(false));
      setModalSubmit(data);
    } else {
      setModalSubmit(data);
    }
  };

  // set entry to live
  const liveButton = async (data) => {
    let submitResult = await dispatch(liveEntires(data));
    setLiveModal(true);
    setPopup({ type: 'liveModal', state: true });
    setSubmitMessage(submitResult);
  };

  const [dropdownSelect, setDropdownSelect] = useState();
  function triggereDropdownMaster(indexPos) {
    const arrayPos = postsPerPage * (currentPage - 1) + indexPos;
    if (dropdownSelect && viewData[arrayPos].id !== dropdownSelect) {
      let tempPosts = [...currentPosts];
      const pos = viewData.findIndex((t) => t.id === dropdownSelect);
      let len = viewData[pos].childTemplates.length;
      tempPosts.splice(pos + 1, len);
      len = arrayPos > pos ? len : 0;
      setDropdownSelect(viewData[arrayPos - len].id);
      const childArray = [...viewData[arrayPos - len].childTemplates];
      Array.prototype.splice.apply(
        tempPosts,
        [indexPos - len + 1, 0].concat(childArray)
      );
      setCurrentPosts(tempPosts);
    } else if (dropdownSelect && viewData[arrayPos].id === dropdownSelect) {
      setDropdownSelect();
      let tempPosts = [...currentPosts];
      tempPosts.splice(indexPos + 1, viewData[arrayPos].childTemplates.length);
      setCurrentPosts(tempPosts);
    } else {
      setDropdownSelect(viewData[arrayPos].id);
      const tempArr = [...currentPosts];
      const childArray = [
        ...viewData[arrayPos].childTemplates.map((item) => {
          return { ...item, child: true };
        }),
      ];
      Array.prototype.splice.apply(
        tempArr,
        [indexPos + 1, 0].concat(childArray)
      );
      setCurrentPosts(tempArr);
    }
  }

  //display table data
  const table_coulumn = [
    {
      lebel: (
        <TableHeadText className="table-header">Template ID</TableHeadText>
      ),
      renderHtml: (params, index) => params.id,
    },
    {
      lebel: (
        <TableHeadText className="table-header">Template Name</TableHeadText>
      ),
      renderHtml: (params, index) => (
        <div className="strategyViewTd">
          <span className="strategyViewV2_td">{params.name}</span>
          {params.childTemplates && params.childTemplates.length > 0 && (
            <img
              src={DropdownIcon}
              alt="Dropdown"
              className={`dropdownStratView-${
                dropdownSelect && dropdownSelect === params.id
                  ? 'active'
                  : 'inactive'
              }`}
              onClick={() => triggereDropdownMaster(index)}
            />
          )}
        </div>
      ),
    },
    {
      lebel: (
        <TableHeadText className="table-header">Description</TableHeadText>
      ),
      renderHtml: (params) => (
        <span className="strategyViewV2_td">{params.description}</span>
      ),
    },
    {
      lebel: <TableHeadText className="table-header">Status</TableHeadText>,
      renderHtml: (params) => (
        <span className="strategyViewV2_td">{params.status}</span>
      ),
    },
    {
      lebel: (
        <TableHeadText className="table-header">Last Modified</TableHeadText>
      ),
      renderHtml: (params) => (
        <Moment format="DD MMM YYYY  h:mm A" className="strategyViewV2_td">
          {params.updatedAt}
        </Moment>
      ),
    },
    {
      lebel: <TableHeadText className="table-header">Edit</TableHeadText>,
      renderHtml: (params) => (
        <PillButton
          iconType={1}
          clickAction={() => {
            if (
              params &&
              params.childTemplates &&
              params.childTemplates.length > 0
            ) {
              setMasTempPopup({ popup: true, editId: params.id });
            } else {
              editButton(params.id);
            }
          }}
          disabled={
            !params
              ? true
              : params.childTemplates && params.childTemplates.length > 0
              ? false
              : params.buttonStatus.isEdit === true
              ? false
              : true
          }
        />
      ),
    },
    {
      lebel: <TableHeadText className="table-header">Copy</TableHeadText>,
      renderHtml: (params) => {
        return (
          <PillButton
            iconType={6}
            disabled={params.buttonStatus.isCopy !== true}
            clickAction={() => {
              setCopyId(params.id);
              setPopup({ type: 'copyhandler', state: true });
            }}
          />
        );
      },
    },
    {
      lebel: <TableHeadText className="table-header">Live</TableHeadText>,
      renderHtml: (params) => {
        return (
          <PillButton
            iconType={5}
            disabled={params.buttonStatus.isApprove !== true}
            clickAction={() => liveButton({ id: params.id, action: true })}
          />
        );
      },
    },
    {
      lebel: <TableHeadText className="table-header">Delete</TableHeadText>,
      renderHtml: (params) => {
        return (
          <PillButton
            iconType={2}
            disabled={params.buttonStatus.isDelete !== true}
            clickAction={(e) => deleteButton(params.id)}
          />
        );
      },
    },
  ];

  const handleClearField = () => {
    submitMessage.status === 'error' ? handleClose(false) : handleClose(true);
  };

  const submitFinalFunc = () => {
    modalSubmitHandler();
    setModalSubmit(false);
    handleClearField();
    if (setModal) {
      setModal(false);
    }
  };

  const handleStartegyDel = () => {
    setDeleteStrategy(false);
    handelStrategyDelete();
  };

  return (
    <>
      <div className="strategyView">
        <div className="col-12">
          <div className="strategyView-s1">
            <div>
              <TableEntries
                role="postPerPage"
                value={postsPerPage}
                postList={showPosts}
                postHandler={handleShowPost}
              />
            </div>
            <Button
              settings={{
                fill: false,
                styles: { margin: 'auto 1vw auto 1vw' },
                title: 'MASTER TEMPLATE',
                icon: AddCircle,
              }}
              click={() =>
                setMasTempPopup({ popup: !masTempPopup.popup, editId: false })
              }
            />
            <div className="search">
              <label>Search</label>
              <UiInput
                type="text"
                value={searchUser}
                handlerfunction={handleSearch}
              />
              {searchUser ? (
                <div className="icon">
                  <Images
                    src={cross}
                    style={{ height: '20px', width: '20px' }}
                    handleImages={handleClearSearch}
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-12">
          {loader ? (
            <CircularProgress disableShrink size={25} />
          ) : (
            <Table
              TableData={currentPosts}
              tableCoulumn={table_coulumn}
              showPagination={true}
              postsPerPage={postsPerPage}
              totalPosts={userShow}
              paginate={paginate}
              sorting={true}
              sortColumn={sortColumn}
              sortingFuction={sortingFuction}
              currentPage={currentPage}
              postsLength={userShow.length}
              elipsisPagination={true}
              id="strtegyView"
            ></Table>
          )}
        </div>
      </div>

      {modal ? (
        <ReviewStrategy
          alertModal={modal}
          setAlertModal={setModal}
          editModalSubmit={modalSubmit}
          setEditModalSubmit={setModalSubmit}
          setSubmitMessageEdit={setSubmitMessage}
        />
      ) : null}

      {popup.state ? (
        <Popup actionButton={actionOnpopup} popupDetails={popup} />
      ) : null}
      <MasterTemplatePopup
        popupState={masTempPopup}
        setPopupState={setMasTempPopup}
      />
    </>
  );
};

export default StrategyViewData;

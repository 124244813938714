import axios from "axios";
import { url } from "../config/apiConfig";

export const forgotPassword = (userPass) => async (dispatch) => {
  try {
    const result = await axios({
      method: "POST",
      url: `${url}/login/forgot/send-otp`,
      data: userPass
    });
    if (result.data.code === "SUCCESS") {
      dispatch({
        type: "FORGOT_PASSWORD",
        payload: true,
        user: userPass.username,
        number: result.data.data.mobile,
        email: result.data.data.email,
      });
      return {
        isUserValid: true,
        message: result?.data?.data?.message,
      }
    }
    return {
      isUserValid: false,
      message: result?.data?.data?.message,
    }
  } catch (err) {
    dispatch({
      type: "FORGOT_PASSWORD",
      payload: false,
      error: err && err.response && err.response.data && err.response.data.data && err.response.data.data.message ? err.response.data.data.message : 'Server Error',
    });
    return {
      isUserValid: false,
      message: err.response.data.data.message,
    }
  }
};

export const otpCheck = (userOtp) => async (dispatch) => {
  try {
    const result = await axios({
      method: "POST",
      url: `${url}/login/forgot/check-otp`,
      data: userOtp,
    });
    if (result && result.data && result.data.code === "SUCCESS") {
      dispatch({
        type: "OTP_CHECK",
        payload: true,
      });
      return {
        isOtpValid: true,
        message: result?.data?.data?.message,
      }
    }
    return {
      isOtpValid: false,
      message: result?.data?.data?.message,
    }
  } catch (err) {
    dispatch({
      type: "OTP_CHECK",
      payload: false,
      message: err.response.data.data.message,
    });
    return {
      isOtpValid: false,
      message: err.response.data.data.message,
    }
  }
};

export const clearUserData = (value) => {
  return {
    type: "CLEAR_USER",
    clearUser: value.clear,
    clearData: value.user,
  };
};
export const clearOtpError = (value) => {
  return {
    type: "CLEAR_ERR",
    error: value,
  };
};
export const clearUserError = (value) => {
  return {
    type: "CLEAR_USER_ERR",
    payload: value,
  };
};

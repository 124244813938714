import { useCallback, useEffect, useRef, useState } from 'react';
import {
  BlackArrow,
  ColumnArrangeIcon,
  DesIcon,
  SettingsIcon,
} from '../../../assets';
import './styles.css';
import { DragDropList } from '../../sharedComponents/customComponent';
import { GenButton } from '../..';

const parentOptions = [
  [
    {
      icon: ColumnArrangeIcon,
      label: 'Columns',
      openState: false,
      children: [{ label: 'Rearrange & Visibility' }],
    },
  ],
];

export default function TableSettingsV2({
  optionsSet = 0,
  columnsToRearrange = [
    { id: 1, title: 'Some title', visibility: false, sequence: 1 },
  ],
  onColumnsToRearrange = (newColumnsSequence) => {},
  saveColumnOrder = () => {},
  resetColumnOrder = () => {},
}) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [renderdecision, setRenderDecision] = useState('p');
  const [displayOptions, setDisplayOptions] = useState(
    parentOptions[optionsSet]
  );
  const [secondaryDisp, setSecondaryDisp] = useState();

  useEffect(() => {
    function onClickHandler(e) {
      if (dropdownRef && !dropdownRef?.current?.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', onClickHandler);
    return () => {
      document.removeEventListener('mousedown', onClickHandler);
    };
  }, []);

  function loadData() {
    switch (renderdecision) {
      case 'p':
        return displayOptions?.map((item, index) => {
          return (
            <>
              <div
                className="TableSettingsV2-parent-container gen-border"
                onClick={() => {
                  let temp = [...displayOptions];
                  temp[index].openState = !item.openState;
                  setDisplayOptions(temp);
                }}
                key={item.label}
              >
                <img
                  src={ColumnArrangeIcon}
                  alt={item.label}
                  className="TableSettingsV2-parent-icon"
                />
                <span className="TableSettingsV2-parent-label font-l1">
                  {item.label}
                </span>
              </div>
              {item.openState &&
                item?.children.map((child, childIndex) => {
                  return (
                    <div
                      className="TableSettingsV2-child-contaner gen-border"
                      key={child.label}
                      onClick={() => handleChildSelection(child.label)}
                    >
                      <span className="TableSettingsV2-child-label font-l1">
                        {child.label}
                      </span>
                      <img
                        className="TableSettingsV2-child-img"
                        alt={child.label}
                        src={DesIcon}
                      />
                    </div>
                  );
                })}
            </>
          );
        });
      case 'c':
        switch (secondaryDisp) {
          case 'collumns-rearrange':
            return (
              <ColumnReArrange
                columns={columnsToRearrange}
                onColumnChange={onColumnsToRearrange}
                saveColumnOrder={saveColumnOrder}
                resetColumnOrder={resetColumnOrder}
                goBack={() => setRenderDecision('p')}
              />
            );
          default:
            break;
        }
        return secondaryDisp;
      default:
        break;
    }
  }

  const handleChildSelection = useCallback((key) => {
    switch (key) {
      case 'Rearrange & Visibility':
        setRenderDecision('c');
        setSecondaryDisp('collumns-rearrange');
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className="TableSettingsV2-conaitner" ref={dropdownRef}>
      <div
        className="TableSettingsV2-gear-conatiner"
        tabIndex={0}
        onClick={() => setOpen((old) => !old)}
      >
        <img
          src={SettingsIcon}
          alt="settingsIcon"
          className="TableSettingsV2-main-icon"
        />
      </div>
      {open && (
        <div className="TableSettingsV2-dropdown" ref={dropdownRef}>
          {loadData()}
        </div>
      )}
    </div>
  );
}

function ColumnReArrange({
  columns = [],
  onColumnChange = () => {},
  saveColumnOrder,
  resetColumnOrder,
  goBack = () => {},
}) {
  return (
    <div className="ColumnReArrange-container">
      <div className="ColumnReArrange-h1">
        <img
          src={BlackArrow}
          alt="blackArrow"
          className="ColumnReArrange-icon"
          onClick={() => goBack()}
        />
        <span className="ColumnReArrange-title font-l1">
          Rearrange & Visibility
        </span>
      </div>
      <div className="ColumnReArrange-h2">
        <DragDropList
          content={columns}
          onChange={(val) => onColumnChange(val)}
        />
      </div>
      <div className="ColumnReArrange-h3">
        <GenButton fill={true} title="SAVE" clickAction={saveColumnOrder} />
        <GenButton fill={false} title="RESET" clickAction={resetColumnOrder} />
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import './styles.css';
import { CheckBox } from '../../../components';
import { SearchIcon } from '../../../assets';
import CompLabel from '../customComponent/compLabel';

export default function SearchMultiSelect(props) {
  const {
    options = [],
    onSelect = (item, state) => {},
    selected = {},
    extStyles = {},
    contStyles = {},
    label = '',
    multiselect = true,
    custom = false,
    searchCallback = () => {},
    optionDisplayFunction = (val) => val,
  } = props;
  const [search, setSearch] = useState('');
  const [currentFocus, setCurrentFocus] = useState(false);

  return (
    <CompLabel label={label} extStyles={extStyles}>
      <div className="mulSeSelec-cont" style={contStyles}>
        <div className="SearchMultiSelect-searchArea">
          <span
            className={`mul-select-search-cont ${
              currentFocus ? 'active-mul-select' : ''
            }`}
          >
            <img
              src={SearchIcon}
              className="mul-select-search-icon"
              alt="SearchIcon"
            />
            <input
              onChange={(val) => {
                searchCallback(val.target.value);
                setSearch(val.target.value);
              }}
              onFocus={() => setCurrentFocus(true)}
              onBlur={() => setCurrentFocus(false)}
              className="mul-select-search-input"
              placeholder="Search"
            />
          </span>
        </div>
        <div className="SearchMultiSelect-bodyarea">
          {multiselect && !custom && (
            <div className="mul-select-options-area">
              {(search.length > 0
                ? options.filter((item) =>
                    item.toLowerCase().includes(search.toLowerCase())
                  )
                : options
              ).map((item) => (
                <CheckBox
                  label={item}
                  state={selected[item]}
                  trigger={(val) => onSelect(item, !selected[item])}
                  extStyles={{ margin: '0.3rem 0.5rem' }}
                />
              ))}
            </div>
          )}
          {!multiselect && !custom && (
            <div className="mul-select-options-area">
              {(search.length > 0
                ? options.filter((item) =>
                    item.toLowerCase().includes(search.toLowerCase())
                  )
                : options
              ).map((item) => (
                <p
                  className="font-l1 mul-select-opt-option"
                  onClick={() => onSelect(item)}
                >
                  {item}
                </p>
              ))}
            </div>
          )}
          {custom && (
            <div className="mul-select-options-area">
              {options.map((item) => item)}
            </div>
          )}
        </div>
      </div>
    </CompLabel>
  );
}

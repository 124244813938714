import OpsApiCalls from './apiCalls';

export function OpsReducer(
  state = {
    applicationSchedule: [
      // {
      //   application: 'First content',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },]
    ],
    application: [
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },
      // {
      //   application: '123',
      //   daysOfWeek: ['Monday','Tuesday','Wednesday','Thursday','Friday'],
      //   startTime: '1234',
      //   stopTime: '1234',
      // },]
    ],
    manage_config: {
      application_config: [],
      application_schedule: [],
    },
    userMaintenance: {
      table: [],
      manage_roles: [],
    },
    userPermissions: {},
    // heartbeatData: [],
    dataValidation: [],
    popupValues: [],
  },
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case 'OPS_UM_TABLE_REFRESH':
      return {
        ...state,
        userMaintenance: { ...state.userMaintenance, table: action.payload },
      };
    case 'OPS_UM_TABLE_ROLES_ALL':
      return {
        ...state,
        userMaintenance: {
          ...state.userMaintenance,
          manage_roles: action.payload,
        },
      };
    case 'OPS_USER_PERMISSIONS':
      return { ...state, userPermissions: action.payload };
    case 'UPDATE_APPLICATION_TABLES_DATA':
      return {
        ...state,
        application:
          action.payload &&
          action.payload.data &&
          action.payload.data.length > 0 &&
          action.payload.data[0],
        applicationSchedule:
          action.payload &&
          action.payload.data &&
          action.payload.data.length > 1 &&
          action.payload.data[1],
      };
    // case 'OPS_HEARTBEAT':
    //   return { ...state, heartbeatData: action.payload};

    //Intial Ops Valiation data load
    case 'OPS_VALIDATION':
      let newData;
      //Change object data to an array data
      newData = Object.keys(action.payload).map((key) => {
        let newDrop;
        newDrop = action.payload[key].map((item) => {
          return { ...item, validationResult: [] };
        });

        return { name: key, drop: newDrop };
      });
      return { ...state, dataValidation: newData };

    //Node data updation on getting results
    case 'OPS_VALIDATION_UPDATE':
      const { NodeID, type } = action.payload.identity;
      const data = action.payload.data.message;
      const idx = state.dataValidation.findIndex((node) => {
        return node.name === type;
      });
      const nodeIdx = state.dataValidation[idx].drop.findIndex((node) => {
        return node.NodeID === NodeID;
      });
      let newDataValidation = state.dataValidation;
      newDataValidation[idx].drop[nodeIdx].validationResult = data;
      return { ...state, dataValidation: newDataValidation };
    case 'OPS_POPUP_INITIALIZE':
      let newPopupData = action.payload;
      newPopupData.ems = newPopupData.ems.map((item) => {
        item.state = false;
        item.title = item.emsId;
        return item;
      });
      newPopupData.selectedDate = newPopupData.date;
      newPopupData.selectedExchange =
        newPopupData.exchange.length > 0 ? 'NSE' : '';
      newPopupData.selectedSegment =
        newPopupData.segment.length > 0 ? 'FO' : '';
      // newPopupData.selectedMatch =
      //   newPopupData.matchBy.length > 0 ? newPopupData.matchBy[0] : '';
      newPopupData.selectedStartTime = new Date(
        newPopupData.date[6] +
          newPopupData.date[7] +
          newPopupData.date[8] +
          newPopupData.date[9] +
          '-' +
          newPopupData.date[3] +
          newPopupData.date[4] +
          '-' +
          newPopupData.date[0] +
          newPopupData.date[1] +
          'T' +
          newPopupData.startTime
      );
      newPopupData.selectedEndTime = new Date(
        newPopupData.date[6] +
          newPopupData.date[7] +
          newPopupData.date[8] +
          newPopupData.date[9] +
          '-' +
          newPopupData.date[3] +
          newPopupData.date[4] +
          '-' +
          newPopupData.date[0] +
          newPopupData.date[1] +
          'T' +
          newPopupData.endTime
      );

      newPopupData.selectedDate = newPopupData.date;
      newPopupData.selectedEMS = newPopupData.ems
        .filter((item) => {
          return item.exchange === 'NSE' && item.segment === 'FO';
        })
        .map((item) => {
          return { ...item, state: true };
        });
      return { ...state, popupValues: { ...newPopupData } };
    case 'OPS_POPUP_UPDATE':
      return {
        ...state,
        popupValues: {
          ...state.popupValues,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export const OpsReducerActions = {
  refreshOpsUserMainTableData: async (dispatch) => {
    const data = await OpsApiCalls.getUserMainTableData();
    dispatch({ type: 'OPS_UM_TABLE_REFRESH', payload: data });
  },
  fetchAllRole: async (dispatch) => {
    const data = await OpsApiCalls.getAllRoles();
    dispatch({
      type: 'OPS_UM_TABLE_ROLES_ALL',
      payload: data.map((item) => {
        return { id: item.id, title: item.name };
      }),
    });
  },
  fetchUserPermissions: async (dispatch, userId) => {
    try {
      const res = await OpsApiCalls.getUserForId(userId);

      dispatch({ type: 'OPS_USER_PERMISSIONS', payload: res });
    } catch (error) {
      console.log(error);
    }
  },
  updateApplicationTablesData: (dispatch, data) => {
    try {
      dispatch({ type: 'UPDATE_APPLICATION_TABLES_DATA', payload: data });
    } catch (error) {
      console.log(error);
    }
  },
  // fetchHeartbeatData: async (dispatch) => {
  //   try{
  //     const data = await OpsApiCalls.getHeartbeatData();
  //     dispatch({ type: 'OPS_HEARTBEAT', payload: data });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // updateHeartbeatData: async(dispatch,data) => {
  //   dispatch({type: 'OPS_HEARTBEAT', payload: data});
  // }
  fetchValidationData: async ({ data, dispatch }) => {
    try {
      dispatch({ type: 'OPS_VALIDATION', payload: data });
    } catch (error) {
      console.log(error);
    }
  },
  updateValidationResults: async ({ data, dispatch, identity }) => {
    try {
      dispatch({ type: 'OPS_VALIDATION_UPDATE', payload: { data, identity } });
    } catch (error) {
      console.log(error);
    }
  },
  initializeParamsPopup: async ({ data, dispatch }) => {
    try {
      dispatch({ type: 'OPS_POPUP_INITIALIZE', payload: data });
    } catch (error) {
      console.log(error);
    }
  },
  updateParamsPopup: async ({ data, dispatch }) => {
    try {
      dispatch({ type: 'OPS_POPUP_UPDATE', payload: data });
    } catch (error) {
      console.log(error);
    }
  },
};

export function permissionChecker(state = [], target, type = 1, parentname) {
  if (!state) {
    return 0;
  }
  if (type === 1) {
    let obj = state.filter((item) => item.mainSc.title === target);
    return obj[0]?.mainSc?.access;
  } else {
    let obj = state?.filter((item) => item.mainSc.title === parentname);
    let obj2 = obj[0]?.subMenu.filter((item) => item.title === target);
    return obj2 ? obj2[0]?.access : 0;
  }
}

export function permissionsAnalyzer(permissions, availTabs) {
  try {
    const allowedTabs = [];
    for (let tab of availTabs) {
      const permission = permissions?.filter(
        (item) => item.mainSc.title === tab.permissionKey
      )[0];

      if (permission?.mainSc?.access >= 1) {
        const newTab = { ...tab };
        newTab.dropdown = tab.dropdown.filter(
          (subMenu) =>
            permission.subMenu.filter(
              (sub) => sub.title.includes(subMenu.title) && sub.access >= 1
            ).length >= 1
        );
        allowedTabs.push(newTab);
      }
    }

    return allowedTabs;
  } catch (error) {
    console.log(error);
    return availTabs;
  }
}

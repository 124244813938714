import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './LoginStyle.scss';
import { Form } from 'react-bootstrap';
import UiInput from '../sharedComponents/Input';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { userData } from '../../actions/resetPasswordAction';
import { Validation } from '../../validations/Validations';
import { CircularProgress } from '@material-ui/core';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const PasswordReset = ({ username, otp, setPopup, setShowPopUp }) => {
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const inputHandler = (e) => {
    setError('');
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };
  const handleValidation = () => {
    let error = {};
    if (Validation.empty(data.newPassword)) {
      error = {
        ...error,
        newPassword: 'Please enter your new password',
      };
    } else if (Validation.empty(data.confirmPassword)) {
      error = {
        ...error,
        confirmPassword: 'Please enter your new password again',
      };
    } else if (!Validation.password(data.newPassword)) {
      error = {
        ...error,
        newPassword:
          'Min 8 characters, 1 uppercase & 1 lowercase, 1 number & 1 special character ',
      };
    } else if(username && username == data.newPassword) {
      error = {
          ...error,
          newPassword: 'Password can not be same as username',
      };
    }
    if (data.newPassword !== data.confirmPassword) {
      error = {
        ...error,
        both: 'Password does not match',
      };
    }
    if (Object.keys(error).length) {
      setError(error);
      return false;
    }
    setError();
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (isValid) {
      const finalData = {
        username: username,
        password: data.newPassword,
        otp: otp,
      };
      try {
        setLoader(true);
        let result = await dispatch(userData(finalData));
        if (result.status == 200) {
          setLoader(false);
          setPopup({type: "changePassword"})
          setShowPopUp(true);
        } else {
          setLoader(false);
          setPopup({type: "changePasswordError", message: result.message})
          setShowPopUp(true);
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
        setError({ ...error, message: error.response.data.message });
      }
    }
    e.preventDefault();
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <div style={{fontWeight:"bold", fontSize:"16px", marginLeft:"auto", marginRight:"auto", fontFamily: "Roboto, sans-serif", color: "#34395F", width: "100%"}}>
        <p style={{fontWeight:"bold", marginLeft: "auto", marginRight: "auto", width:"fit-content", fontSize:"24px"}}>
          Change Password
        </p>
      </div>
      <div className={(error && (error.newPassword || error.both) && !error.confirmPassword) ? "form-error form-group": "form-group"}>
        <Form.Label className="d-inline-block">New Password</Form.Label>
        <Tooltip
          title="Password should consist minimum of 8 characters (combination of Uppercase and lowercase letters, numbers and special characters)"
          fontSize="small"
          style={{ marginTop: '4px', marginLeft: '5px', color: '#0078D7' }}
          placement="top"
          arrow
        >
          <InfoOutlinedIcon />
        </Tooltip>
        <UiInput
          type="password"
          id="newPassword"
          name="newPassword"
          className="form-control w-auto h-auto d-inline-block"
          handlerfunction={inputHandler}
        />
        {(error && error.newPassword) && (<span className="error-tag"><WarningAmberIcon sx={{fontSize: '16px', mr: '7px', mt: '4px'}} />{error.newPassword}</span>)}
      </div>
      <div className={(error && (error.confirmPassword || error.both) && !error.newPassword) ? "form-error form-group": "form-group"}>
        <Form.Label>Confirm Password</Form.Label>
        <UiInput
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          className="form-control w-auto h-auto d-inline-block"
          handlerfunction={inputHandler}
        />
        {(error && (error.confirmPassword || error.both) && !error.newPassword) && (<span className="error-tag"><WarningAmberIcon sx={{fontSize: '16px', mr: '7px', mt: '4px'}} />{error.confirmPassword ? error.confirmPassword : error.both}</span>)}
      </div>
      { 
        <button
          type="submit"
          id="resetPassword"
          className="btn btn-primary login"
          disabled={loader}
        >
          {
            loader ? 
            (
              <CircularProgress disableShrink size={25} color="white"/>
            ) :
            (
              'Confirm'
            )
          }
        </button>
      }
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.forgotReducer.username,
  };
};
export default connect(mapStateToProps, null)(PasswordReset);

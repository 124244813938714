import { MakeRequest } from '../utils';

export async function getFamilyType() {
  try {
    const res = await MakeRequest('/family/familyType').get();
    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllDealers() {
  try {
    const res = await MakeRequest('/dealer/allDealers').get();
    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getEntityTypes() {
  try {
    const res = await MakeRequest('/family/entityType').get();
    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getExchanges() {
  try {
    const res = await MakeRequest('/security/exchanges').get();
    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getSegment() {
  try {
    const res = await MakeRequest('/family/segment').get();

    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getEms() {
  try {
    const res = await MakeRequest('/admin/EMS').get();

    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllFamilies() {
  try {
    const res = await MakeRequest('/family/allFamilies').get();

    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getStrategyTemplate(id, isTemplate) {
  try {
    let strategyUrl =
      isTemplate === 'true'
        ? `/strategyTemplate/return/${id}`
        : `/strategy/return/${id}`;
    const res = await MakeRequest(strategyUrl).get();
    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getSecurityDataForEsteeId(esteeId) {
  try {
    let secDataForEsteeIdUrl = '/security/esteeId?esteeId=' + esteeId;
    const res = await MakeRequest(secDataForEsteeIdUrl).get();
    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log(res);
      return {};
    }
  } catch (err) {
    console.log(err);
    return {};
  }
}

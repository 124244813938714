import './styles.css';

export default function TableV2({
  extStyles,
  extraHeaders = [],
  properties = [
    {
      head: {},
      content: (val) => {},
    },
  ],
  data = [],
  columnContraint = [],
}) {
  return (
    <div className="TableV2-tableContainer" style={extStyles}>
      <table className="TableV2-table">
        {extraHeaders.length > 0 && (
          <thead className="TableV2_thead">
            {extraHeaders.map((item) => (
              <th className="TableV2_th font-l1 ">{item}</th>
            ))}
          </thead>
        )}
        <thead className="TableV2_thead">
          {properties.map((item) => (
            <th className="TableV2_th font-l1 ">{item.head}</th>
          ))}
        </thead>
        <tbody className="TableV2_tbody">
          {data.map((dataObject, dataObjectIndex, allDataObjects) => (
            <tr className="TableV2_tr">
              {properties.map((property, propertyIndex) => (
                <td className="TableV2_td">
                  {property.content(
                    dataObject,
                    dataObjectIndex,
                    allDataObjects,
                    columnContraint[propertyIndex]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NewDropdownIcon } from '../../../../assets';
import './styles.css';
import { useDispatch } from 'react-redux';
import { Loading } from '../../../../assets';

import { OpsReducerActions } from '../../redux';
import OpsApiCalls from '../../redux/apiCalls';
import { GenToolTip } from '../../../../components';

//Func to check status of Node
/*
0->grey
1->red
2->yellow
3->green
*/
const checkNode = ({ node }) => {
  if (node.validationResult.length === 0) {
    return 0;
  }
  if (node.validationResult.length > 0) {
    let calc = node.validationResult.filter(
      (item) => item.Severity === 'Error'
    );
    if (calc.length !== 0) return 1;
    calc = node.validationResult.filter((item) => item.Severity === 'Warning');
    if (calc.length !== 0) return 2;
    calc = node.validationResult.filter((item) =>
      item.ValidationStatus.includes('All Good')
    );
    if (calc.length > 0 && calc.length === node.validationResult.length)
      return 3;
    else return 2;
  }
};

export const SimpleDropdown = ({ data, handleNodeChange, date }) => {
  //State to check which node is active
  const [activeNode, setActiveNode] = useState({ locationCode: '', id: -1 });

  const { dataValidation } = useSelector((state) => state.OpsReducer);

  //Func to handle node change
  const onChange = ({ locationCode, id }) => {
    //Find the node that was clicked from store
    const retData = dataValidation.filter((item) => {
      return item.name === locationCode;
    });
    const node = retData[0].drop.filter((item) => {
      return item.NodeID.toString() === id.toString();
    });
    //Send its data to DataValidation comp and change Node info on screen
    let state = '';
    // console.log();
    let status = checkNode({ node: node[0] });
    if (status === 0) {
      state = 'Not Validated';
    } else if (status === 1) {
      state = 'Validation Failed';
    } else if (status === 2) {
      state = 'Validation Completed Successfully with Warnings';
    } else {
      state = 'Validation Completed Successfully without any error';
    }
    handleNodeChange({
      data: {
        rightUpSecTab: {
          location: node[0].ExchangeCode,
          node: node[0].NodeDisplayName,
          target: node[0].DataStoreType,
          procedure: node[0].SPName,
          status: state,
        },
        rightDownSecTab: node[0].validationResult,
      },
    });
    setActiveNode({ locationCode, id });
  };

  return (
    <ul className="simple-dropdown">
      {data.map(({ name, drop }, index) => {
        return (
          <li>
            <DataHeader
              activeNode={activeNode}
              key={index}
              onChange={onChange}
              data={{ drop, name }}
              date={date}
              handleNodeChange={handleNodeChange}
            />
          </li>
        );
      })}
    </ul>
  );
};

//Func to handle each main heading
const DataHeader = ({ data: { name, drop }, activeNode, onChange, date }) => {
  //State to check if dropdown heading has been clicked
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [loadingNodes, setLoadingNodes] = useState([]);
  //Handle heading click
  const handleClick = () => {
    setDropdownStatus(!dropdownStatus);
  };
  //Handle child node click
  const handleNodeClick = ({ locationCode, id }) => {
    onChange({ locationCode, id });
  };
  //Check status of each node to get status of Main Location
  let mainStatus = 0;

  drop.map((node) => {
    if (checkNode({ node }) === 3) mainStatus = 3;
  });
  drop.map((node) => {
    if (checkNode({ node }) === 2) mainStatus = 2;
  });
  drop.map((node) => {
    if (checkNode({ node }) === 1) mainStatus = 1;
  });

  const dispatch = useDispatch();
  //Heading start button Click
  const handleMainValidationStart = async () => {
    const promises = drop.map(async (node) => {
      const result = await handleNodeValidationStartTemp({
        date,
        data: {
          DataStoreType: node.DataStoreType,
          ExchangeCode: node.ExchangeCode,
          IsEOD: node.IsEOD,
          LocationCode: node.LocationCode,
          NodeDisplayName: node.NodeDisplayName,
          NodeID: node.NodeID,
          SPName: node.SPName,
        },
      });
      return result;
    });

    const results = await Promise.all(promises);
  };

  //Handle Main Heading Start Click
  const handleNodeValidationStartTemp = async ({ date, data }) => {
    setLoadingNodes((prevLoadingNodes) => [...prevLoadingNodes, data]);

    await resultapiCall({ date, data });

    setLoadingNodes((prevLoadingNodes) =>
      prevLoadingNodes.filter((item) => item.NodeID !== data.NodeID)
    );
  };
  //Handle Node Start Click
  const handleNodeValidationStart = async ({ date, data }) => {
    await resultapiCall({ date, data });

    onChange({ locationCode: data.LocationCode, id: data.NodeID });
  };

  //API call for validation Results
  const resultapiCall = async ({ date, data }) => {
    try {
      //Add date to data
      const newData = { ...data, validationDate: date };

      const retData = await OpsApiCalls.postDataValidation(newData);

      await OpsReducerActions.updateValidationResults({
        data: retData,
        dispatch,
        identity: { NodeID: data.NodeID, type: data.LocationCode },
      });
    } catch (error) {
      console.log(error);
    }
  };

  //return a single heading + its children
  return (
    <div className="single-list-container">
      <div className="head-item" onClick={handleClick}>
        <GenerateItem
          head={name}
          status={mainStatus}
          mainDropdown={true}
          dropdownStatus={dropdownStatus}
          handleMainValidationStart={handleMainValidationStart}
        />
      </div>
      {dropdownStatus == true ? (
        <ul>
          {drop.map((data) => {
            //Get status of Node
            let flag = checkNode({ node: data });
            let load =
              loadingNodes.filter((item) => {
                return item.NodeID === data.NodeID;
              }).length > 0;
            return (
              <li
                key={data.NodeID}
                onClick={() => {
                  handleNodeClick({
                    locationCode: data.LocationCode,
                    id: data.NodeID,
                  });
                }}
              >
                <GenerateItem
                  mainLoad={load}
                  head={data.NodeDisplayName}
                  status={flag}
                  mainDropdown={false}
                  activeNode={activeNode}
                  parent={data.LocationCode}
                  id={data.NodeID}
                  date={date}
                  data={data}
                  onChange={onChange}
                  key={data.NodeID}
                  handleNodeValidationStart={handleNodeValidationStart}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

//Func to generate heading, status , start button, dropdown icon
const GenerateItem = ({
  mainLoad,
  head,
  status,
  mainDropdown,
  dropdownStatus,
  activeNode,
  parent,
  id,
  date,
  data,
  handleNodeValidationStart,
  handleMainValidationStart,
}) => {
  //State for loading till results are received
  const [isLoading, setIsLoading] = useState(false);
  let colorScheme = ['back-grey', 'back-red', 'back-yellow', 'back-green'];
  return (
    <div
      className={`list-item ${
        mainDropdown === true ? 'main-item' : 'child-item'
      } ${mainDropdown === true && dropdownStatus === true && ' drop-focus'} ${
        mainDropdown === false &&
        activeNode.locationCode === parent &&
        activeNode.id === id &&
        'active-node'
      }`}
    >
      <GenToolTip tooltip={head}>
        <div className="list-item-head">
          {head.length > 20 ? head.slice(0, 20) + '...' : head}
        </div>
      </GenToolTip>
      <div className="list-item-func">
        {!isLoading && (mainDropdown || !mainLoad) ? (
          <div
            className={`status ${colorScheme[status]}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          ></div>
        ) : (
          <>
            <img src={Loading} alt="Load" className="load-icon" />
          </>
        )}

        <button
          className={`start-button btn btn-primary ${
            !mainDropdown && 'hover-show'
          }`}
          id={`${
            !isLoading && (mainDropdown || !mainLoad) ? '' : 'no-functionality'
          }`}
          onClick={async (e) => {
            //Stop the parent click event
            e.stopPropagation();
            if (!(!isLoading && (mainDropdown || !mainLoad))) return;
            setIsLoading(true);
            if (mainDropdown) {
              await handleMainValidationStart();
            } else {
              await handleNodeValidationStart({ date, data });
            }

            setIsLoading(false);
          }}
        >
          Start
        </button>

        {mainDropdown && (
          <div className="dropdown-icon-container">
            <img src={NewDropdownIcon} alt="" className="icon" />
          </div>
        )}
      </div>
    </div>
  );
};

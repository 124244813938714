import React, { Fragment, useEffect, useState } from 'react';
import bellicon from '../../../assets/img/bellicon.svg';
import './AlertAndNotificationButton.scss';
import { Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allAlertData } from '../../../actions/alertAction';
import {
  heartbeatPublishToBEInterval,
  webSocketUrl,
} from '../../../config/apiConfig';
import useKeyPress from '../../../custom_Hooks/useShortcut';
import { useSnackbar } from 'notistack';
import { logout } from '../../../actions/loginAction';
import styled from 'styled-components';
import WindowConfirmation from '../../AlertsAndNotification/WidowConfirmation';
import { setFocusOnModalKeyWithClassName } from '../../sharedComponents/keyPressEventHandler';

var W3CWebSocket = require('websocket').w3cwebsocket;

const BellContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1vw;
  cursor: pointer;
  ${(props) => props.style}
`;

const AlertNumber = styled.span`
  position: absolute;
  top: -0.8vh;
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.9);
  right: -0.8vh;
  background-color: #ea4242;
  color: white;
  border-radius: 2vh;
  height: 2.5vh;
  width: 2.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
`;

const AlertAndNotificationButton = ({ extStyles = {} }) => {
  const {
    alertReducer: { allAlertsData: notification },
    shortcutReducer,
  } = useSelector((state) => state);

  const token = localStorage.getItem('token');
  const [liveData, setLiveData] = useState();
  const [groupData, setGroupData] = useState([]);
  const [count, setCount] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [confirmationWindow, setConfirmationWindow] = useState([]);

  function checkPersistentPopupallowed() {
    try {
      const allowed =
        localStorage.getItem('ENABLE_PERSISTENT_POPUP') === 'true';
      return allowed;
    } catch (error) {
      console.log(
        'checkPersistentPopupallowed AlertAndNotificationButton.js failed'
      );
      console.log(error);
    }
  }

  const handleAlertIcon = (errorIdentifier = 'RISK') => {
    dispatch(allAlertData());
    let newWindow = window.open(
      `/window/alert/?errorType=${errorIdentifier}`,
      '_blank',
      'top=100, left=100, width=1857, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };
  const dispatch = useDispatch();

  const logoutUserHelper = () => {
    try {
      let userId = localStorage.getItem('customerId');
      dispatch(logout({ userId: userId }));
      localStorage.clear();
      // handleEscapeKeyEvent({});
      window.location.href = '/';
    } catch (err) {
      localStorage.clear();
      window.location.href = '/';
      console.log(`Error encoutered in UI logout: ${err.message}`);
    }
  };

  useEffect(() => {
    dispatch(allAlertData());
  }, []);

  useEffect(() => {
    var client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    var uiHBClient = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    funInitialise(client, 'ALERT_SUBSCRIBE');
    funInitialise(uiHBClient, 'USER_DISABLED_LOGOUT');
  }, [token]);

  function alertSnackbar(header, message, errorIdentifier = 'RISK') {
    return (
      <div>
        <p
          onClick={() => handleAlertIcon(errorIdentifier)}
          style={{ fontWeight: 'bold', marginBottom: '0px', cursor: 'pointer' }}
        >
          {header}
        </p>
        <p
          onClick={() => handleAlertIcon(errorIdentifier)}
          style={{
            marginBottom: '0px',
            maxWidth: '300px',
            overflow: 'clip',
            cursor: 'pointer',
          }}
        >
          {message && message.length > 150
            ? message.substr(0, 150) + '...'
            : message}
        </p>
      </div>
    );
  }

  function addConfirmPopup(msg) {
    setConfirmationWindow((old) => [...old, { message: msg.message }]);
  }

  function funInitialise(client, eventType = 'ALERT_SUBSCRIBE') {
    client.onerror = function () {};
    let userId = localStorage.getItem('customerId');
    let userType = localStorage.getItem('userType');

    client.onopen = function () {
      let userId = localStorage.getItem('customerId');
      let userType = localStorage.getItem('userType');
      const data = {
        eventType:
          eventType === 'ALERT_SUBSCRIBE'
            ? 'ALERT_SUBSCRIBE'
            : 'UI_NOTIFICATION_SUBSCRIBE',
        payload: { userId: parseInt(userId), userType },
      };

      client.send(JSON.stringify(data));

      // Send regular heartbeat to backend
      setInterval(() => {
        const hbPkt = {
          eventType: 'HEARTBEAT_PING',
          payload: {
            userId: parseInt(userId),
            userType: userType,
          },
        };

        client.send(JSON.stringify(hbPkt));
      }, heartbeatPublishToBEInterval);
    };

    client.onclose = function () {};

    client.onmessage = function (data) {
      try {
        let dataStr = JSON.parse(data.data);
        for (let msg of dataStr?.payload?.data) {
          if (
            msg.isWindowConfirmationReq &&
            localStorage.getItem('ENABLE_PERSISTENT_POPUP') === 'true'
          ) {
            addConfirmPopup(msg);
            setTimeout(() => {
              setFocusOnModalKeyWithClassName('GenButton-cont-fill');
            }, 500);
          }
        }

        if (
          dataStr.eventType == 'ALERT_UPDATE' ||
          dataStr.eventType == 'ERROR_UPDATE'
        ) {
          let webObj = { ...dataStr.payload };
          let WebData = webObj.data ? webObj.data : [];
          if (WebData.length > 0) {
            for (let i = 0; i < WebData.length; i++) {
              WebData[i]['sameChild'] = 'noChild';
              WebData[i]['sameError'] = [];
              for (let k = i + 1; k < webObj.data.length; k++) {
                if (WebData[i].message === WebData[k].message) {
                  if (k != i + 1) {
                    let sameObj = { ...WebData[k] };
                    sameObj['sameChild'] = k;
                    WebData[i]['sameError'].push(sameObj);
                  }
                  WebData.splice(k, 1);
                }
              }
            }
            for (let i = 0; i < WebData.length; i++) {
              for (let k = i + 1; k < webObj.data.length; k++) {
                if (WebData[i].message === WebData[k].message) {
                  WebData.splice(k, 1);
                }
              }
            }

            let enableFatalUIPopup = localStorage.getItem(
              'ENABLE_ALERT_FATAL_POPUP'
            );
            if (!dataStr.isFirstMessage && enableFatalUIPopup == 'true') {
              let errorAlertData = WebData.filter((item) => {
                return item.isNew == true;
              });
              errorAlertData.map((item) => {
                if (item.message)
                  enqueueSnackbar(
                    alertSnackbar(
                      item.errorCode ? item.errorCode : 'Error',
                      item.message,
                      item.errorIdentifier
                    ),
                    {
                      autoHideDuration: 5000,
                      preventDuplicate: true,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }
                  );
              });
            }
          }
          webObj.data = WebData;
          setLiveData(webObj);
        } else if (dataStr.eventType == 'USER_DISABLED_LOGOUT') {
          // Logout user
          logoutUserHelper();
        }
      } catch (err) {
        console.log(err);
      }
    };
  }

  useEffect(() => {
    if (liveData) {
      let countArr = notification.filter(
        (el) => el.alertType == 'GENERAL_MESSAGE' && el.isRead == false
      );
      let strategyCount = notification.filter(
        (el) =>
          el.alertType == 'STRATEGY_TEMPLATE_APPROVAL' &&
          !el.alertData.isApproved
      ).length;

      setCount(countArr.length + strategyCount);
    } else {
      let countArr = notification.filter((el) => el.isRead == false);
      setCount(countArr);
    }
  }, [liveData]);

  useKeyPress(
    shortcutReducer.get('ALERT') &&
      shortcutReducer.get('ALERT').keyCombination.split('+'),
    () => handleAlertIcon()
  );

  return (
    <Fragment>
      <BellContainer onClick={() => handleAlertIcon()} style={extStyles}>
        {count > 0 && <AlertNumber>{count}</AlertNumber>}
        <Image src={bellicon} alt="" className="bellicon" />
      </BellContainer>
      <WindowConfirmation
        message={
          checkPersistentPopupallowed()
            ? confirmationWindow[confirmationWindow.length - 1]?.message
            : ''
        }
        setOk={() => {
          setConfirmationWindow((old) => {
            const temp = [...old];
            temp.pop();
            return temp;
          });
          setTimeout(() => {
            setFocusOnModalKeyWithClassName('GenButton-cont-fill');
          }, 100);
        }}
      />
    </Fragment>
  );
};

export default AlertAndNotificationButton;

import styled from 'styled-components';
import '../../../../global.css';
import { GenButton, InputField, GenToolTip, Switch } from '../../..';
import { fetchValue, updateValue } from '../../../../utils/dotKeyFunctions';
import { AddIcon, InfoIcon, SecuritiesIcon } from '../../../../assets';
import { useRef } from 'react';
import { NickDDOptionsCob } from '../../../../components/sharedV2';

const CreateFormulaCont = styled.div`
  height: 80vh;
  width: 70vw;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8vh;
  width: 70vw;
  background-color: var(--primary-color);
`;

const HeaderText = styled.span`
  font-size: var(--font-size);
  font-family: var(--def-font);
  color: #fff;
  margin-left: 1vw;
`;

const BodyContainer = styled.div`
  height: 64vh;
  width: 70vw;
  overflow-y: auto;
`;

const ButtonGroup = styled.div`
  height: 8vh;
  width: 70vw;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const Section1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
`;

export default function CreateFormula({
  data = {
    formTitle: 'Edit Formula',
    formulaName: '',
    formulaDescription: '',
    pageNumber: 1,
    action: {
      submit: false,
      close: false,
      draft: false,
      quickTest: false,
    },
    primarySecurity: {
      nickName: '',
      instrument: '',
      symbol: '',
      expiry: '',
      strike: '',
    },
    supportingSecurity: {
      show: false,
      supportArray: [
        {
          nickName: '',
          instrument: '',
          symbol: '',
          expiry: '',
          strike: '',
        },
      ],
    },
    formula: '',
  },
  setData = () => {},
}) {
  function updateValueObj(key, value) {
    setData(updateValue(data, key, value));
  }

  return (
    <CreateFormulaCont>
      <HeaderContainer>
        <HeaderText>{`${fetchValue(data, 'formTitle')}`}</HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <Section1>
          <InputField
            value={`${fetchValue(data, 'formulaName')}`}
            onValueChange={(val) => updateValueObj('formulaName', val)}
            extStyles={{ margin: '0 0 0 1vw' }}
            inputWidth={'20vw'}
            placeHolder="Formula Name"
            type="text"
          />
          <InputField
            value={fetchValue(data, 'formulaDescription')}
            onValueChange={(val) => updateValueObj('formulaDescription', val)}
            extStyles={{ margin: '0 0 0 1vw' }}
            inputWidth={'45vw'}
            placeHolder="Description"
            type="text"
          />
        </Section1>
        <TitleComp icon={SecuritiesIcon} title="Securities" />
        <Section1>
          <SecondaryTitle
            title="Primary Securities"
            toolTip="Non-mandatory fields can be added from market watch"
          />
        </Section1>
        <NickDDOptionsCob
          data={data}
          objKey="primarySecurity"
          setData={setData}
        />
        <Section1>
          <SecondaryTitle
            title="Supporting Securities"
            toolTip="Primary Security symbol will be used"
          />
          <Switch
            state={fetchValue(data, 'supportingSecurity.show')}
            onChange={(val) => updateValueObj('supportingSecurity.show', val)}
            switchBox={true}
            extStyles={{ margin: '0 0 0 1vw' }}
          />
        </Section1>
        {fetchValue(data, 'supportingSecurity.show') &&
          fetchValue(data, 'supportingSecurity.supportArray').map(
            (item, index) => (
              <NickDDOptionsCob
                data={data}
                objKey={`supportingSecurity.supportArray.${index}`}
                setData={(val) => setData(val)}
                triggerDelete={(indexPos) => {
                  const newObj = fetchValue(
                    data,
                    `supportingSecurity.supportArray`
                  ).filter((item, currentIndex) => currentIndex !== indexPos);
                  updateValueObj(`supportingSecurity.supportArray`, newObj);
                }}
              />
            )
          )}
        {fetchValue(data, 'supportingSecurity.show') && (
          <GenButton
            title="Add More"
            fill={false}
            extStyle={{ width: '10vw', margin: '1vw' }}
            icon={AddIcon}
            clickAction={() =>
              updateValueObj(`supportingSecurity.supportArray`, [
                ...fetchValue(data, 'supportingSecurity.supportArray'),
                {
                  nickName: '',
                  instrument: '',
                  symbol: '',
                  expiry: '',
                  strike: '',
                },
              ])
            }
          />
        )}
        <TitleComp icon={SecuritiesIcon} title="Formula" />
        <Section1>
          <InputField
            label="Enter Formula"
            value={fetchValue(data, 'formula')}
            onValueChange={(val) => updateValueObj('formula', val)}
            extStyles={{ margin: '0 0 0 1vw' }}
            inputWidth="66vw"
            type="text"
          />
        </Section1>
      </BodyContainer>
      <ButtonGroup>
        <GenButton
          title="CLOSE"
          fill={false}
          clickAction={() => setData(updateValue(data, 'action', 'CLOSE'))}
          extStyle={{ width: '12vw', margin: '0 0.5vw 0 0.5vw' }}
        />
        <GenButton
          title="SAVE AS DRAFT"
          fill={false}
          clickAction={() => setData(updateValue(data, 'action', 'DRAFT'))}
          extStyle={{ width: '12vw', margin: '0 0.5vw 0 0.5vw' }}
        />
        <GenButton
          title="SUBMIT"
          fill={false}
          clickAction={() => setData(updateValue(data, 'action', 'SUBMIT'))}
          extStyle={{ width: '12vw', margin: '0 0.5vw 0 0.5vw' }}
        />
        <GenButton
          title="QUICK TEST"
          fill={true}
          clickAction={() => setData(updateValue(data, 'action', 'QUICK_TEST'))}
          extStyle={{ width: '12vw', margin: '0 0.5vw 0 0.5vw' }}
        />
      </ButtonGroup>
    </CreateFormulaCont>
  );
}

const TitleCompCont = styled.div`
  background-color: #f4f4f4;
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
  ${(props) => props.style}
`;

const TitleCompImg = styled.img`
  margin: 0 0 0 1vw;
  height: 3vh;
  width: 3vh;
`;

const TitleText = styled.span`
  font-family: var(--def-font);
  font-size: var(--font-size);
  font-weight: 500;
  margin: 0 0 0 1vw;
`;

function TitleComp({ icon, title, style }) {
  return (
    <TitleCompCont style={style}>
      <TitleCompImg src={icon} />
      <TitleText>{title}</TitleText>
    </TitleCompCont>
  );
}

const SecondTitleCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SecondTitleText = styled.span`
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.2);
  font-weight: 400;
  margin: 0 0 0 1vw;
`;

const SecondTitleImg = styled.img`
  height: 3vh;
  width: 3vh;
  margin: 0 0 0 1vw;
`;

function SecondaryTitle({ title, toolTip }) {
  const ref = useRef(null);
  return (
    <SecondTitleCont ref={ref}>
      <SecondTitleText>{title}</SecondTitleText>
      <GenToolTip containerRef={ref} tooltip={toolTip}>
        <SecondTitleImg src={InfoIcon} />
      </GenToolTip>
    </SecondTitleCont>
  );
}

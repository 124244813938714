import React, { useState, useEffect } from 'react';
import './styles.css';
import { GenSearchBox } from '../../../components';

// * 'data' format
// ? {id: 0, label: "Label", inputType: "DROPDOWN", value: "", extData: [options]}
// ? {id: 1, label: "Label", inputType: "TEXT", value: "", extData: {}}

function DynamicFieldEditor(props) {
  const { data, setData, title, extStyle } = props;
  const [state, setState] = useState(data);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <div className="dynamic-field-editor-container" style={extStyle}>
      <div className="dynamic-field-editor-container-s1">
        <span className="dynamic-field-editor-title">{title}</span>
        <GenSearchBox
          version={2}
          extStyles={{ width: '44%' }}
          message="Search"
          value={search}
          changeListener={(val) => setSearch(val)}
        />
      </div>
      <div className="dynamic-field-editor-container-s2">{}</div>
    </div>
  );
}

export default DynamicFieldEditor;

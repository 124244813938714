import React from 'react';
import { Button } from 'react-bootstrap';

const UiButton = ({ //component that is being used in the entire website for buttons
  name,  //accepting props
  onClickFunction,
  type,
  className,
  disabled,
  size,
}) => {
  return (
    <React.Fragment>
      {onClickFunction ? (
        <Button // if we need to use onClick funtion
          className={className ? className : ''}
          size={size ? size : ''}
          disabled={disabled ? disabled : ''}
          onClick={(e) => {
            e.stopPropagation();
            onClickFunction(e);
          }}
        >
          {name}
        </Button>
      ) : (
        <Button //if we don't need any onClick funtion
          type={type}
          size={size ? size : ''}
          className={className ? className : ''}
        >
          {name}
        </Button>
      )}
    </React.Fragment>
  );
};
export default UiButton;

import React, { useState } from 'react';
import {
  InputGroup,
  FormControl,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  UI_INPUTVALUE_ONE,
  UI_INPUTVALUE_TWO,
  UI_INPUTVALUE_THREE,
} from '../../utils/constant';
import SimpleSnackbar from './Toast/Toast';

export default function UiInput({
  create,
  type,
  name,
  className,
  handlerfunction,
  value,
  disabled,
  size,
  min,
  max,
  placeholder,
  checked,
  onClickHandler,
  argRange,
  lebel,
  onblurFuntion,
  onMouseOverFunction,
  newTemplateNameConstants,
  decimalAllowed,
  onKeyPress,
  onKeyUp,
  id,
  description,
}) {
  const [toaster, setToaster] = useState(false);
  const [toasterMsg, setToasterMsg] = useState('');

  const onchangeHandler = (eventData) => {
    let value = eventData.target.value;

    if ((min || min === 0) && value < min) {
      setToaster(true);
      setToasterMsg(`Can not enter value less than ${min}`);
    }
    if (max && value > max) {
      setToaster(true);
      setToasterMsg(`Can not enter value more than ${max}`);
    }

    if (type === 'int' && min < 0 && value === '-')
      eventData['target']['value'] = '-';
    else if (type === 'int' && value / 1 !== 0)
      eventData['target']['value'] = parseInt(value);
    else eventData['target']['value'] = value;
    handlerfunction(eventData);
  };
  const onBlurHandler = (eventData) => {
    let value = eventData.target.value;

    if (min || min === 0) {
      value <= min ? (value = min) : (value = value);
    }
    if (max) {
      value >= max ? (value = max) : (value = value);
    }
    eventData['target']['value'] = value;
    handlerfunction(eventData);
  };

  function onWheel(event) {
    event.preventDefault();
  }

  return (
    <React.Fragment>
      {description && (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top_algo`} className="">
              <div className="">
                <p>{description ? description : ' '}</p>
              </div>
            </Tooltip>
          }
        >
          <Form.Label htmlFor="basic-url">{lebel}</Form.Label>
        </OverlayTrigger>
      )}
      {lebel && !description && (
        <Form.Label htmlFor="basic-url">{lebel}</Form.Label>
      )}
      {create ? (
        <InputGroup className={className ? className : ''} hasValidation>
          <FormControl
            type={type}
            name={name}
            id={id ? id : ''}
            size={size ? size : ''}
            placeholder={placeholder ? placeholder : ''}
            onChange={handlerfunction ? (e) => handlerfunction(e) : null} //handlerfuntion will be used instead onChange funtion in the components where input will be used as a shared compnent
            autoComplete="off"
            min={0} //resticting the minimum value to 0
            onWheel={(e) => onWheel(e)}
          />
        </InputGroup>
      ) : (
        <InputGroup>
          <FormControl
            type={type}
            size={size ? size : ''}
            min={min ? min : ''}
            maxLength={max ? max : ''}
            id={id ? id : ''}
            name={name}
            placeholder={placeholder ? placeholder : ''}
            value={value}
            className={className ? className : ''}
            onChange={(e) => {
              argRange ? handlerfunction(e, argRange) : onchangeHandler(e);
            }}
            onKeyDown={(e) =>
              type === 'number'
                ? decimalAllowed
                  ? UI_INPUTVALUE_ONE.includes(e.keyCode) && e.preventDefault()
                  : newTemplateNameConstants
                  ? UI_INPUTVALUE_TWO.includes(e.keyCode) && e.preventDefault()
                  : UI_INPUTVALUE_THREE.includes(e.keyCode) &&
                    e.preventDefault()
                : null
            }
            autoComplete="off"
            disabled={disabled}
            checked={checked}
            onClick={onClickHandler ? (e) => onClickHandler(e) : null}
            onBlur={
              onblurFuntion ? (e) => onblurFuntion(e) : (e) => onBlurHandler(e)
            }
            onMouseOver={
              onMouseOverFunction ? (e) => onMouseOverFunction(e) : null
            }
            onKeyPress={onKeyPress ? (e) => onKeyPress(e) : null}
            onKeyUp={onKeyUp ? (e) => onKeyUp(e) : null}
            style={{ borderRadius: '4px' }}
            onWheel={(e) => onWheel(e)}
          />
        </InputGroup>
      )}
      {toaster && (
        <SimpleSnackbar //toaster for min and max validation
          toaster={toaster}
          setToaster={setToaster}
          message={toasterMsg}
        />
      )}
    </React.Fragment>
  );
}

const initialState = {
    createRoleData: false,
  Id:''
  }
  const editRoleReducer = (state = initialState, action) => {
    switch (action.type) {
      case "EDIT_ROLE":
        return {
          ...state,
          createRoleData: action.payload,
          Id:action.roleId
        }
        case "CLEAR_EDIT":
          return {
          ...state,
          Id:action.clearMessage
          }
      
      default:
        return state
    }
  }
  
  export default editRoleReducer
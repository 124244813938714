import { useEffect, useState } from 'react';
import {
  PageSizePicker,
  StrategyAnalyzerSearch,
  StrategyAnalyzerTable,
  TableSettingsV2,
} from '../../../components/sharedV2';
import './styles.css';
import { fetchValue, updateValue } from '../../../utils/dotKeyFunctions';
import useSWRMutation from 'swr/mutation';
import { MakeRequest } from '../../../utils';
import { mutate } from 'swr';
import { FeedbackPopup, GenButton, PillButton } from '../../../components';
import { ExcelIcon } from '../../../assets';
import StradeLogo from '../../../assets/img/Strade_LOGO.svg';
import GenericPopup from '../../../components/sharedComponents/genPopup';
import { bannerReducerAction } from '../../../reducers/bannerReducer';
import { useDispatch } from 'react-redux';

export default function StrategyAnalyzerV2({ analyzerTabObj }) {
  const [pageSpecs, setPageSpecs] = useState({
    currentPage: 1,
    pageSize:
      localStorage.getItem('analyzerPageSize') &&
      !isNaN(localStorage.getItem('analyzerPageSize'))
        ? parseInt(localStorage.getItem('analyzerPageSize'))
        : 5,
    allowedPageSizes: [5, 10, 20, 40, 100, 110, 150, 190],
    search: {
      category: 'Strategy Template',
      categories: [
        'Strategy Template',
        'Strategy Security',
        'Strategy Name',
        'Strategy ID',
        'Account ID',
      ],
      value: '',
    },
  });
  const [feedback, setFeedback] = useState('');
  const [columnOrder, setColumnOrder] = useState([]);
  const [multiIDSelect, setMultiIdSelect] = useState([]);
  const [multiIDSelectData, setMultiIdSelectData] = useState([]);
  const [popupVisible, setPopupVisible] = useState('');
  const [tableData, setTableData] = useState([]);
  const [columnSequenceApiData, columnSequenceApiDataTrigger] =
    APIs.GetColumnSequenceAndStructure();
  const dispatch = useDispatch();

  async function strategyAction(strategyIds, action) {
    try {
      if (action === 'EXPORT') {
        await MakeRequest(
          `/excel/generate/analyzer?strategyList=${strategyIds.join(',')}`
        ).download('Rules.zip');
        return;
      }
      const response = await MakeRequest('/strategyAnalyzer/button').post({
        buttonType: action,
        strategyList: strategyIds,
      });

      const respMessage = response?.data?.data;
      if (typeof respMessage === 'string') {
        setFeedback(respMessage);
      } else {
        if (respMessage?.statusCode !== 200) {
          const message = Object.keys(respMessage).reduce((old, ruleId) => {
            if (!isNaN(ruleId)) {
              old += ` Rule ID ${ruleId} : ${respMessage[ruleId]?.error}\n`;
            }
            return old;
          }, '');
          setFeedback(message);
        } else {
          setFeedback('SUCCESS');
        }
      }
      setMultiIdSelect([]);
      setMultiIdSelectData([]);
    } catch (error) {
      console.log(error);
    }
  }

  async function generateTableDataFromLeg(rulesData) {
    setTableData([]);
    const newData = rulesData.map((rule) => {
      const { id, strategyName, legData } = rule;
      const formattedLegData = legData.map((leg) => ({
        legNumber: leg.legNumber,
        security: leg.security,
        tradedQty: leg.tradedQty,
        direction: leg.direction,
      }));
      return { id, strategyName, tableData: formattedLegData };
    });
    setPopupVisible('RULECLOSE');
    setTableData(newData);
    setMultiIdSelect([]);
    setMultiIdSelectData([]);
  }

  useEffect(() => {
    columnSequenceApiDataTrigger({ key: analyzerTabObj?.key });
    setPageSpecs((old) => {
      const temp = { ...old };
      temp.currentPage = 1;
      return temp;
    });
    setMultiIdSelect([]);
    setMultiIdSelectData([]);
  }, [analyzerTabObj]);

  useEffect(() => {
    mutate('/strategyAnalyzer/table');
    setMultiIdSelect([]);
    setMultiIdSelectData([]);
  }, [pageSpecs.search.value, pageSpecs.search.category]);

  useEffect(() => {
    setMultiIdSelect([]);
    setMultiIdSelectData([]);
  }, [pageSpecs.pageSize]);

  useEffect(() => {
    if (!columnSequenceApiData) {
      return;
    }
    let tableStaticView = columnSequenceApiData?.staticData,
      tableDynamicView = columnSequenceApiData?.viewData,
      tempColumnOrder = [];
    tempColumnOrder = tableStaticView?.map((item) => ({
      id: item.variableName,
      title: item.columnName,
      visibility: false,
      sequence: item.columnSeq || 999,
      columnSize: item.colLen,
    }));

    tempColumnOrder = tempColumnOrder
      ?.map((item) => ({
        ...item,
        visibility: tableDynamicView.find(
          (searchItem) => searchItem.variableName === item.id
        )
          ? true
          : false,
        sequence:
          tableDynamicView.find(
            (searchItem) => searchItem.variableName === item.id
          )?.columnSeq || 999,
        columnSize:
          tableDynamicView.find(
            (searchItem) => searchItem.variableName === item.id
          )?.colLen || 100,
      }))
      ?.sort((a, b) => a.sequence - b.sequence);

    setColumnOrder(tempColumnOrder);
  }, [columnSequenceApiData]);

  return (
    <div className="StrategyAnalyzerV2-container">
      <div className="StrategyAnalyzerV2-s1">
        <PageSizePicker
          id="StrategyAnalyzerV2-pageSize"
          currentSize={pageSpecs.pageSize}
          handleSelection={(val) => {
            localStorage.setItem('analyzerPageSize', val);
            setPageSpecs((old) => {
              return { ...old, pageSize: val };
            });
          }}
        />
        <StrategyAnalyzerSearch
          category={fetchValue(pageSpecs, 'search.category')}
          setCategory={(val) =>
            setPageSpecs(updateValue(pageSpecs, 'search.category', val))
          }
          search={fetchValue(pageSpecs, 'search.value')}
          setSearch={(val) =>
            setPageSpecs(updateValue(pageSpecs, 'search.value', val))
          }
          extClass="StrategyAnalyzerV2-searchbar"
          categories={fetchValue(pageSpecs, 'search.categories', [])}
        />
        <div className="StrategyAnalyzerV2-allCheckBox">
          <span className="StrategyAnalyzerV2-allcheck-label font-l1">
            Apply to all checked
          </span>
          <PillButton
            iconType={5}
            extClass="StrategyAnalyzerV2-pill-btn"
            disabled={multiIDSelect.length === 0}
            clickAction={() => strategyAction(multiIDSelect, 'RESUME')}
          />
          <PillButton
            iconType={7}
            extClass="StrategyAnalyzerV2-pill-btn"
            disabled={multiIDSelect.length === 0}
            clickAction={() => strategyAction(multiIDSelect, 'PAUSE')}
          />
          <PillButton
            iconType={2}
            extClass="StrategyAnalyzerV2-pill-btn"
            disabled={multiIDSelect.length === 0}
            clickAction={() =>
              //strategyAction(multiIDSelect, 'CLOSE')
              generateTableDataFromLeg(multiIDSelectData)
            }
          />
          <GenButton
            title={'EXPORT EXCEL'}
            fill={false}
            icon={ExcelIcon}
            clickAction={() => strategyAction(multiIDSelect, 'EXPORT')}
          />
        </div>
        <TableSettingsV2
          optionsSet={0}
          columnsToRearrange={columnOrder}
          onColumnsToRearrange={(val) => {
            setColumnOrder(
              val.map((item, index) => ({ ...item, sequence: index + 1 }))
            );
          }}
          saveColumnOrder={async () => {
            const columns = columnOrder
              .filter((item) => item.visibility)
              .map((item, index) => ({
                columnName: item.title,
                variableName: item.id,
                columnSeq: index + 1,
                colLen: item.columnSize,
              }));
            await MakeRequest('/user/dynamicTable/setTableStruct').post({
              dynamicTableSeq: columns,
              tableName: 'STRATEGY_ANALYZER',
              tabId: analyzerTabObj.key,
            });
            columnSequenceApiDataTrigger({ key: analyzerTabObj?.key });
            bannerReducerAction.raiseBanner(
              dispatch,
              'Strategy Analyzer Tab Sequence saved',
              'success'
            );
          }}
          resetColumnOrder={async () => {
            await MakeRequest('/user/dynamicTable/setTableStruct').post({
              dynamicTableSeq: columnSequenceApiData.staticData,
              tableName: 'STRATEGY_ANALYZER',
              tabId: analyzerTabObj.key,
            });
            columnSequenceApiDataTrigger({ key: analyzerTabObj?.key });
            bannerReducerAction.raiseBanner(
              dispatch,
              'Strategy Analyzer Tab Sequence Reset',
              'success'
            );
          }}
        />
      </div>
      <div className="StrategyAnalyzerV2-s2">
        <StrategyAnalyzerTable
          pageSpecs={{
            current: pageSpecs.currentPage,
            pageSize: pageSpecs.pageSize,
          }}
          setPageSpec={(val) => {
            setPageSpecs((old) => {
              const tempData = { ...old };
              tempData.currentPage = val?.current;
              tempData.pageSize = val?.pageSize;
              return tempData;
            });
          }}
          columnsOrder={columnOrder}
          searchValue={{
            value: pageSpecs.search.value,
            category: pageSpecs.search.category,
          }}
          setMultiIds={setMultiIdSelect}
          setMultiIdsData={setMultiIdSelectData}
          tabId={analyzerTabObj?.key}
        />
      </div>
      <FeedbackPopup
        message={feedback}
        acceptAction={() => setFeedback('')}
        type="f"
      />
      <GenericPopup
        type={1}
        state={popupVisible.length != 0}
        toggleState={() => setPopupVisible('')}
      >
        <div className="closeRule-drilldown-container">
          <img src={StradeLogo} className="logo" />
          <span className="closeRule-drilldown-title">
            Are you sure you want to Close the following Strategies? :
          </span>
          <div className="table-container">
            {tableData.map((item, index) => (
              <div key={index} className="strategy-container">
                <div className="strategy-id">
                  Strategy ID: {item.id} &nbsp; &nbsp; &nbsp; Strategy Name:{' '}
                  {item.strategyName}
                </div>
                <div>
                  <table className="leg-data-table">
                    <thead>
                      <tr>
                        <th>Leg Number</th>
                        <th>Security</th>
                        <th>Traded Qty</th>
                        <th>Buy/Sell</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.tableData.map((leg, legIndex) => (
                        <tr key={legIndex}>
                          <td>{leg.legNumber}</td>
                          <td>{leg.security}</td>
                          <td>{leg.tradedQty}</td>
                          <td
                            className={`direction ${leg.direction.toLowerCase()}`}
                          >
                            {leg.direction}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
          <div className="feedback-popup-bottom-New">
            <GenButton
              type="1"
              title="YES"
              fill={true}
              extStyle={{ width: '8vw' }}
              clickAction={() => {
                const strategyIds = tableData.map((item) => item.id);
                setPopupVisible('');
                setTableData([]);
                strategyAction(strategyIds, 'CLOSE');
              }}
            />
            <GenButton
              type="1"
              title="NO"
              fill={false}
              extStyle={{ width: '8vw', marginLeft: '2rem' }}
              clickAction={() => {
                setTableData([]);
                setPopupVisible('');
              }}
              focus={true}
            />
          </div>
        </div>
      </GenericPopup>
    </div>
  );
}

const APIs = {
  GetColumnSequenceAndStructure: () => {
    async function fetcher(url, { arg }) {
      const response = await MakeRequest(url).get({
        tableName: 'STRATEGY_ANALYZER',
        tabId: arg?.key,
      });
      return response?.data?.data;
    }
    const { data, trigger } = useSWRMutation(
      '/user/dynamicTable/getTableStruct',
      fetcher
    );
    return [data, trigger];
  },
};

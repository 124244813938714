const initialState = {
  data: [],
  fetched: false,
  addruled: [],
  dataTA: [],
  risks: [],
  import: [],
  ID: '',
  teamID: '',
  tradingID: '',
  user: '',
  type: '',
  formRiskData: [],
  selectedCusTradingAccounts: [],
  selectedData: '',
  selectedIds: null,
  riskAdded: false,
  loader: true,
};

const riskConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TEAMS':
      const mainArr = [
        { ...state.risks.adminRisk?.adminData, teams: action.teams },
      ];
      const computedArr = mainArr.map((el) => {
        const adminRiskData = state.risks.adminRisk?.adminRisk;
        const teamData = el.teams?.map((elt) => {
          const teamRiskData = state.risks.teamRisk.find(
            (eltn) => eltn.teamData.id === elt.id
          ).teamRisk;
          const customerData = elt.customerData.map((cust) => {
            const tradingAcc = state.risks.customerRisk.find(
              (elcust) => elcust.id === cust.id
            ).tradingAccountData;
            return {
              ...cust,
              tradingAccount: tradingAcc,
            };
          });
          return {
            ...elt,
            teamRisks: teamRiskData,
            customerData,
          };
        });
        return {
          ...el,
          teams: teamData,
          adminRisks: adminRiskData,
        };
      });
      return {
        ...state,
        data: [...computedArr],
        fetched: action.payload,
      };
    case 'ADD_RULE':
      return {
        ...state,
        addruled: action.payload,
      };

    case 'GET_TADATA':
      return {
        ...state,
        dataTA: action.taData,
        loader: action.payload,
      };
    case 'GET_USERID':
      return {
        ...state,
        ID: action.Id,
      };
    case 'GET_TEAMID':
      return {
        ...state,
        teamID: action.teamId,
      };
    case 'GET_RISKS':
      return {
        ...state,
        risks: action.riskData,
      };
    case 'GET_TA_ID':
      return {
        ...state,
        tradingID: action.tradingId,
      };
    case 'GET_USERRISK':
      return {
        ...state,
        userRisk: action.risk,
      };
    case 'GET_USERNAME':
      return {
        ...state,
        user: action.payload,
      };
    case 'GET_TYPE': {
      return {
        ...state,
        type: action.typeData,
      };
    }
    case 'SET_FORMDATA': {
      let formRiskData = null;
      let selectedCusTradingAccounts = null;
      const adminId = action.adminId;
      const teamId = action.teamId;
      const custId = action.custId;
      const tradingId = action.tradingId;
      const adminData = state.data.find((el) => el.id === adminId);
      switch (action.userType) {
        case 'customer':
          const teamData = adminData.teams.find((elt) => elt.id === teamId);
          const custData = teamData.customerData.find(
            (elc) => elc.id === custId
          );
          formRiskData = custData.tradingAccount[0].risk
            ? custData.tradingAccount[0].risk
            : [];
          selectedCusTradingAccounts = custData.tradingAccount;
          break;
        case 'team':
          const currentTeamData = adminData.teams.find(
            (elt) => elt.id === teamId
          );
          formRiskData = currentTeamData.teamRisks
            ? currentTeamData.teamRisks
            : [];
          break;
        case 'admin':
          formRiskData = adminData.adminRisks ? adminData.adminRisks : [];
          break;
        case 'trading':
          const currentTeamTradingData = adminData.teams.find(
            (elt) => elt.id === teamId
          );
          const currentCustData = currentTeamTradingData.customerData.find(
            (elc) => elc.id === custId
          );
          const tradingData = currentCustData.tradingAccount.find(
            (elc) => elc.id === tradingId
          );
          formRiskData = tradingData.risk;
          break;
        default:
          break;
      }
      return {
        ...state,
        formRiskData: formRiskData ? formRiskData : [],
        selectedCusTradingAccounts: selectedCusTradingAccounts
          ? selectedCusTradingAccounts
          : state.selectedCusTradingAccounts,
        selectedData: action.selectedData,
        selectedIds: {
          adminId,
          teamId,
          custId,
          tradingId,
          userType: action.userType,
        },
      };
    }
    case 'IMPORT_DATA':
      return {
        ...state,
        importData: action.import,
      };
    case 'SET_RISK':
      return {
        ...state,
        riskAdded: action.payload,
      };
    case 'CLEAR_RISK_ADDED':
      return {
        ...state,
        riskAdded: action.payload,
      };
    case 'CLEAR_RISKFORM':
      let clearRiskForm = [];
      return {
        ...state,
        formRiskData: clearRiskForm,
      };

    default:
      return state;
  }
};

export default riskConfigReducer;

import React, { useState } from 'react';
import TableSettings from '../tableSettings';
import {
  DarkmodeIcon,
  FontSizeIcon,
  ColumnArrangeIcon,
} from '../../../../assets';

export default function MarkWatcSH(props) {
  const { callbackSetData = () => {}, extStyles = {}, allowed = true } = props;
  const [colData, setColData] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 0,
          label: 'Dark Mode',
          leftComp: DarkmodeIcon,
          rightComp: { type: 'SWITCH', state: false },
          moreOpt: [],
        },
        {
          id: 1,
          label: 'Font Size',
          leftComp: FontSizeIcon,
          rightComp: undefined,
          moreOpt: [],
        },
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [
            { label: 'Reorder / Hide & Unhide', id: 20 },
            { label: 'Manage Formulas', id: 21 },
          ],
        },
      ],
    },
  ]);

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    let tempColumns = [...colData];
    switch (temp_index[0]) {
      case 'P':
        for (let i = 0; i < tempColumns[0].content.length; i++) {
          if (tempColumns[0].content[i].id === id) {
            if (temp_index.length > 1 && temp_index[1] === 'R') {
              tempColumns[0].content[i].rightComp.state = val;
            } else {
              // TODO : trigger click here according to ID
            }
          }
        }
        setColData(tempColumns);
        break;
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          tempColumns.push({ type: 'COL_RE' });
        } else if (temp_index[1] === '2' && id === 21) {
          tempColumns.push({ type: 'MNG_FO' });
        }
        setColData(tempColumns);
        break;
      case 'B':
        console.log('Back Clicked');
        tempColumns.pop();
        setColData(tempColumns);
        break;
      default:
        break;
    }
  }

  return (
    <TableSettings
      menuOptions={colData}
      clickAction={colModifier}
      requiredPagination={true}
      updateColCallBack={callbackSetData}
      extStyles={extStyles}
      allowed={allowed}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { login, clearError } from '../../actions/loginAction';
import { Validation } from '../../validations/Validations';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import UiInput from '../sharedComponents/Input';
import { clearUserData } from '../../actions/forgotPasswordAction';
import Line from '../../assets/img/Line 252.svg';
import { CircularProgress } from '@material-ui/core';
import { passwordChangeEnums } from '../../utils/constant';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function Login({
  isLoggedin,
  logError,
  userError,
  apiStatus,
  setOldPassword,
  setSection,
  setUserId,
  setTokenStr,
  setPopup,
  setShowPopUp,
}) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleValidation = () => {
    let error = {};
    if (Validation.empty(username)) {
      error = { ...error, username: 'Please enter your username' };
    } else if (!Validation.username(username)) {
      error = {
        ...error,
        username: 'Please Enter Valid Username',
      };
    }
    if (Validation.empty(password)) {
      error = {
        ...error,
        password: 'Please enter your Password',
      };
    }

    if (Object.keys(error).length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isvalid = handleValidation();
    if (isvalid) {
      setLoader(true);
      let result = await dispatch(login({ username, password }));
      setLoader(false);
      if (result.isSuccess && result.isFirstTimeLogin) {
        if (
          result.passwordChangeEnum ===
          passwordChangeEnums.DEALER_FIRST_TIME_LOGIN
        ) {
          setBtnDisabled(true);
          setPopup({ type: 'changePasswordDealerFirstTimeLogin' });
          setShowPopUp(true);
        } else if (
          result.passwordChangeEnum ===
          passwordChangeEnums.DEALER_NEED_PASSWORD_CHANGE
        ) {
          setBtnDisabled(true);
          setPopup({ type: 'changePasswordDealerNeedPasswordChange' });
          setShowPopUp(true);
        } else if (
          result.passwordChangeEnum ===
          passwordChangeEnums.DEALER_PASSWORD_EXPIRED
        ) {
          setBtnDisabled(true);
          setPopup({ type: 'changePasswordPasswordExpiry' });
          setShowPopUp(true);
        }
        localStorage.removeItem('token');
        setTokenStr(result.token);
        setOldPassword(password);
        setUserId(result.userId);
      }
    }
    if (localStorage.getItem('token')) {
      localStorage.getItem('userType') === 'admin' ||
      localStorage.getItem('userType') === 'risk'
        ? navigate('/risk/userMaintenance')
        : navigate('/LiveTrading');
      window.location.reload();
    }
  };

  const userHandler = (e) => {
    setUserName(e.target.value);
    setError({ ...error, username: '' });
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
    setError({ ...error, password: '' });
  };

  useEffect(() => {
    if (logError) {
      setError({ ...error, password: logError });
    } else if (userError) {
      setError({ ...error, username: userError });
    }
    dispatch(clearUserData({ user: false, clear: '' }));
  }, [isLoggedin, navigate, logError, userError, apiStatus]);

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <h1>Login</h1>
      <div className={'line_wrapper'}>
        <img src={Line} alt="" className="line-img" />
      </div>
      <div
        className={
          error && error.username ? 'form-error form-group' : 'form-group'
        }
      >
        <Form.Label htmlFor="exampleInputEmail1">Username</Form.Label>
        <UiInput
          type="text"
          id="userId"
          className="form-control w-auto h-auto d-inline-block"
          handlerfunction={userHandler}
        />
        <p className="error-tag-wrapper">
          {error && error.username && (
            <span className="error-tag">
              <WarningAmberIcon
                sx={{ fontSize: '16px', mr: '7px', mt: '4px' }}
              />
              {error.username}
            </span>
          )}
        </p>
      </div>
      <div
        className={
          error && error.password ? 'form-error form-group' : 'form-group'
        }
      >
        <Form.Label htmlFor="exampleInputPassword1">Password</Form.Label>
        <UiInput
          type="password"
          id="password"
          className="form-control w-auto h-auto d-inline-block"
          handlerfunction={passwordHandler}
        />
        <div className="error-fpass">
          <p className="error-tag-wrapper">
            {error && error.password && (
              <span className="error-tag">
                <WarningAmberIcon
                  sx={{ fontSize: '16px', mr: '7px', mt: '4px' }}
                />
                {error.password}
              </span>
            )}
          </p>
          <div className="text-right">
            <small
              style={{ display: 'inline-block' }}
              id="emailHelp"
              className="form-text"
              onClick={() => navigate('/forgotPassword')}
            >
              Forgot Password ?
            </small>
          </div>
        </div>
      </div>
      <button
        type="submit"
        id="login"
        className="btn btn-primary login "
        disabled={loader || btnDisabled}
        style={{ borderRadius: '5px' }}
      >
        {loader ? (
          <CircularProgress disableShrink size={25} color="white" />
        ) : (
          'Login'
        )}
      </button>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedin: state.loginReducer.isUserLoggedIn,
    logError: state.loginReducer.errorPass,
    userError: state.loginReducer.errorUser,
    apiStatus: state.loginReducer.status,
  };
};

export default connect(mapStateToProps, null)(Login);

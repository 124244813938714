import React from 'react';

export default class ErrorBoundary extends React.Component {
  // * Add API to report error in FE
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state['hasError'] || false) {
      return <p>Something went wrong</p>;
    } else {
      return this.props.children;
    }
  }
}

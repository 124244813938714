import React, { useEffect, useRef, useState } from 'react'
import { useDetectOutsideClick } from "../../../../utils/useDetectOutsideClick"
import UiInput from '../../../../components/sharedComponents/Input'
import CustomCheckBox from '../../../../components/sharedComponents/CheckBox/CheckBox'
import dropdownbtn from '../../../../assets/img/dropdownbtn.svg'


import './AddRisk.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateRule } from '../../../../actions/LiveTradingAction'
import { useLocation } from 'react-router'

const AddRisk = ({ ruleList, addRuleId, handleRuleId }) => {

  const [searchRule, setSearchRule] = useState("")
  const [ruleShow, setRuleShow] = useState([])
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const dropdownShow = () => setIsActive(!isActive)
  const dispatch = useDispatch()
  const {
    liveTradingReducer:{
      showRule,risks
    }
  } = useSelector((state) => state);
  const location = useLocation()
  useEffect(() => {
    handleSearch("");
  }, [risks]);

  useEffect(()=>{
    if(location.pathname ==='/window/trading'){
      setRuleShow([...ruleList])
    }
  },[ruleList,location.pathname])

  const handleSearch = (text) => {
    let inputText = ''
    if(text && text.target && text.target.value){
      inputText = text.target.value
    }
    let teams
    let finalResult = []
    let inputsearch = inputText.toLowerCase()
    let newRuleList = [...ruleList]

    if (inputText !== "") {
      if (inputsearch.trim().length > 0) {
        for (let i = 0; i < newRuleList.length; i++) {
          teams = newRuleList[i].riskParamName.toLowerCase().includes(inputsearch)
          if (teams === true) {
            finalResult.push(newRuleList[i])
          }
        }
        setRuleShow(finalResult)
      }
    } else {
      setRuleShow(newRuleList)
    }
    setRuleShow([])
    setSearchRule(inputText)
  }
  return (
    <div className="riskmontlist">
         <div className="add-rule-wrapper">
                <div className="text-left">
                    <button className="add-rule-btn" onClick={dropdownShow}><img src={dropdownbtn} /></button>
                    <div ref={dropdownRef} className={` toogledropdown dropdown-rule  ${isActive ? "active" : "inactive"}`}> 
                        <div className="filter-wrapper">
                          <UiInput type="text" placeholder=" Search - Filter" className="text-center" value={searchRule}  handlerfunction={handleSearch}  />
                          </div>
                          <div className="addCheckBox">

                        {ruleShow.length>0 ? (ruleShow && ruleShow.map((list, index) => ( <>
                              
                                       <CustomCheckBox key={ruleList.id} label={list.riskParamName} value={list.id} handlerfunction={handleRuleId} checked={addRuleId.includes(list.id)} />
                            
                            </>)))
                             : <label> No Data </label>
                             }
                          </div>
                    
            </div>
        </div>
    </div>
    </div>
)
}

export default AddRisk
  
export function seo(data = {}) {
    data.title = data.title || 'eSTee';
    data.metaDescription =
      data.metaDescription || 'Web site created using create-react-app';
  
    document.title = data.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', data.metaDescription);
  }
  
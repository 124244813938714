import React from 'react';
import { useState, useEffect, useRef } from 'react';
import './styles.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import dropDownIcon from '../../../../assets/img/dropDownIcon.svg';
import OffIcon from '../../../../assets/img/off-icon.svg';
import RefreshIcon from '../../../../assets/img/refresh-icon.svg';
import DropdownLinkerIcon from '../../../../assets/img/dropdown-linker-icon.svg';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { apiConfig } from '../../../../config/apiConfig';
import OpsApiCalls from '../../redux/apiCalls';
import { useDispatch } from 'react-redux';
import { OpsReducerActions } from '../../redux';
import { GenToolTip, FeedbackPopup } from '../../../../components';

const MenuItems = (props) => {
  const dispatch = useDispatch();
  const { keyData, valueData, handleSwitch } = props;
  const [dropdownStatus, setDropdownStatus] = useState(false);

  const getApplicationIDs = (keyData, valueData) => {
    //returns list of all application IDs present in children of current key
    if (typeof valueData !== 'object' && keyData !== 'status') {
      return [keyData];
    }
    const applicationIDs = [];
    Object.entries(valueData).map(([key, value]) => {
      const result = getApplicationIDs(key, value);
      applicationIDs.push(...result);
    });
    return applicationIDs;
  };

  const handleStatusChange = async () => {
    // switch on application all IDs of any specific key
    const applicationIDs = getApplicationIDs(keyData, valueData);
    try {
      const result = await OpsApiCalls.postSwitchStatusHeartBeatData(
        applicationIDs
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    //loads dropdown and sets data for both tables
    setDropdownStatus((prev) => !prev);
    fetchData();
  };

  const fetchData = async () => {
    //sets data for both application tables
    const applicationIDs = getApplicationIDs(keyData, valueData);
    OpsReducerActions.updateApplicationTablesData(dispatch, []);

    try {
      const data = await OpsApiCalls.getApplicationTableData(applicationIDs);
      OpsReducerActions.updateApplicationTablesData(dispatch, data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`menu-items`}>
      <div className="item-data">
        <div className="item-data-value" onClick={handleClick}>
          {keyData !== 'Location' && (
            <img
              src={DropdownLinkerIcon}
              className="dropdown-linker-icon"
              alt="dropdown-icon"
            />
          )}
          {keyData && String(keyData).length > 15 ? (
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id="ops-dropdown-tooltip">
                  <div className="tooltip-data">
                    <p>{keyData}</p>
                  </div>
                </Tooltip>
              }
            >
              <span>{String(keyData).slice(0, 15) + '...'}</span>
            </OverlayTrigger>
          ) : (
            keyData
          )}
          {/* {keyData.length>15?`${keyData.substring(0,12)}...`:keyData} */}
        </div>
        <div className="icons">
          {typeof valueData !== 'object' && (
            <GenToolTip tooltip="Enable Auto Start">
              <div
                className="icon-container"
                onClick={() => {
                  handleStatusChange();
                  handleSwitch();
                }}
              >
                <img src={OffIcon} className="icon off-icon" alt="offIcon" />
              </div>
            </GenToolTip>
          )}

          {keyData === 'Location' && (
            <div className="icon-container">
              <img src={RefreshIcon} className="icon" alt="switch-icon" />
            </div>
          )}
          <div
            className={`icon-container status`}
            style={
              (typeof valueData !== 'object' && valueData) || valueData?.status
                ? { backgroundColor: 'green' }
                : { backgroundColor: 'red' }
            }
          ></div>
          {typeof valueData === 'object' && valueData !== null && (
            <div className="icon-container" onClick={handleClick}>
              <img src={dropDownIcon} className="icon" />
            </div>
          )}
        </div>
      </div>
      {typeof valueData === 'object' &&
        valueData !== null &&
        dropdownStatus && <Dropdown data={valueData} />}
    </div>
  );
};

const Dropdown = React.memo((props) => {
  const { data } = props;
  const dropdownRef = useRef(null);
  const [keyList, setKeyList] = useState([]);
  const [visibleKeyList, setVisibleKeyList] = useState([]);

  const [feedbackPopup, setFeedbackPopup] = useState({
    state: false,
    allowed: false,
    key: null,
  });

  useEffect(() => {
    if (!feedbackPopup.state && feedbackPopup.allowed) {
      OpsApiCalls.postEnableAutoStart(feedbackPopup.key);
    }
  }, [feedbackPopup]);

  const loadMoreItems = () => {
    let len = Math.min(visibleKeyList.length + 100, keyList.length);
    const item = keyList.slice(visibleKeyList.length, len);
    setVisibleKeyList((prevItems) => prevItems.concat(item));
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const target = e.target;
      if (
        target.scrollHeight - target.scrollTop === target.clientHeight &&
        e.deltaY > 0
      ) {
        // at the bottom, trying to scroll down
        target.scrollTop = target.scrollHeight;
      } else if (target.scrollTop === 0 && e.deltaY < 0) {
        // at the top, trying to scroll up
        target.scrollTop = 0;
      }

      const dropdown = dropdownRef.current;
      const scrollTop = dropdown.scrollTop;
      const scrollHeight = dropdown.scrollHeight;
      const clientHeight = dropdown.clientHeight;
      const remainingScroll = scrollHeight - scrollTop;
      if (remainingScroll <= clientHeight + 10) {
        // Reached the bottom of the dropdown, load more items
        loadMoreItems();
      }
    };

    const dropdownElement = dropdownRef.current;

    if (dropdownElement) {
      dropdownElement.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, [visibleKeyList]);

  useEffect(() => {
    //set key list with all keys present in data
    if (Object.keys(data).length) {
      let temp = Object.keys(data);
      setKeyList(temp);
    }
  }, [data]);

  useEffect(() => {
    //select 50 initial keys from key list to be displayed
    if (keyList && keyList.length) {
      let len = Math.min(keyList.length, 50);
      let temp = keyList.slice(0, len);
      setVisibleKeyList(temp);
    }
  }, [keyList]);

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div className="dropdown" ref={dropdownRef}>
      {visibleKeyList
        .filter((key) => key !== 'status')
        .map((key, idx) => {
          if (typeof Object.values(data)[0] !== 'object') {
            return (
              <MenuItems
                keyData={key}
                valueData={data[key]}
                key={key + idx}
                handleSwitch={() =>
                  setFeedbackPopup({ state: true, allowed: false, key: key })
                }
              />
            );
          } else {
            return (
              <MenuItems
                keyData={key}
                valueData={data[key]}
                key={key + idx}
                handleSwitch={() => {}}
              />
            );
          }
        })}
      <FeedbackPopup
        type="a"
        message={
          feedbackPopup.state
            ? `Do you want to enable auto heartbeat on ${feedbackPopup.key} ?`
            : ''
        }
        acceptAction={() =>
          setFeedbackPopup({
            state: false,
            allowed: true,
            key: feedbackPopup.key,
          })
        }
        rejectAction={() =>
          setFeedbackPopup({
            state: false,
            allowed: false,
            key: null,
          })
        }
      />
    </div>
  );
});

const updateData = (initialData, eventData) => {
  //update status of heartbeat data
  let status = 1;
  let updatedData = {};

  for (const [key, value] of Object.entries(initialData)) {
    if (key === 'Location' && !Object.keys(value).length) {
      updatedData = initialData;
      status = 0;
    } else if (key === 'status') {
      updatedData.status = initialData.status;
    } else if (typeof value === 'object' && Object.keys(value).length) {
      const [childData, childStatus] = updateData(value, eventData);
      updatedData[key] = childData;
      status = status && childStatus;
    } else if (Number.isInteger(value)) {
      updatedData[key] = eventData.has(key) ? 1 : 0;
      status = status && updatedData[key];
    }
  }

  updatedData.status = status;
  return [updatedData, status];
};

export default function NestedDropdown(props) {
  const { dataReceived = {} } = props;

  const [finalData, setFinalData] = useState();
  const [client, setClient] = useState(); //web socket client
  const [websocketData, setWebsocketData] = useState(new Set());
  const requestBody = {
    eventType: 'APPLICATION_HEARTBEAT',
    payload: {
      userId: Number(localStorage.getItem('customerId'))
        ? parseInt(localStorage.getItem('customerId'))
        : localStorage.getItem('customerId'),
      userType: 'DEALER',
    },
  };

  const handleWebSocketMessage = (messageData, finalData) => {
    //updates heartbeat data based on data received from web socket
    if (finalData && Object.keys(finalData.Location).length) {
      const updatedData = updateData({ ...finalData }, messageData);
      if (JSON.stringify(updatedData[0]) !== JSON.stringify(finalData)) {
        setFinalData(updatedData[0]);
      }
    }
  };

  useEffect(() => {
    if (client === undefined) {
      setClient(new W3CWebSocket(`ws://${apiConfig().websocket}`));
      return;
    }
    try {
      if (client) {
        client.onopen = () => {
          client.send(JSON.stringify(requestBody));
        };

        client.onmessage = (message) => {
          try {
            const result = JSON.parse(message.data);
            if (result['status'] === 200) {
              const messageData = new Set(
                result && result.payload ? result.payload['IDs'] : []
              );
              setWebsocketData(messageData);
            }
          } catch (error) {
            console.log(error);
          }
        };
        client.onclose = () => {
          console.log('Disconnected from WebSocket');
        };
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      if (client) {
        client.close();
      }
    };
  }, [client]);

  useEffect(() => {
    //set initial data
    setFinalData({ Location: dataReceived });
  }, [dataReceived]);

  useEffect(() => {
    //update heartbeat data when data is received from web socket
    handleWebSocketMessage(websocketData, finalData);
  }, [websocketData, finalData]);

  if (!finalData) {
    return null; // Render nothing if finalData is empty
  }
  return (
    <div className="nestedDropdown">
      {finalData && <Dropdown data={finalData} />}
    </div>
  );
}

import { CREATE_BAN_SECURITY_LIST, GET_ALL_BAN } from '../actions/types';

const initialState = {
  createBlockList: {
    name: '',
    banType: '',
    exchange: '',
    instrumentType: '',
    allSymbol: false,
    allExpiry: false,
    familyTypeId: [],
    familyId: [],
    symbol: '',
    expiry: '',
  },
  exchanges: [],
  instrumentTypes: [],

  banList: [],
  selectedSymbol: '',
  selectedOptionType: '',
  symbol: [],
  optionType: [],
  expiry: [],
  strike: [],
  banEditForm: false,
};

const banSecurityReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BAN_SECURITY_LIST:
      return {
        ...state,
        createBlockList: action.payload,
      };
    case 'GET_EXCHANGE':
      return {
        ...state,
        exchanges: action.payload.exchange,
      };
    case 'GET_INSTRUMENT_TYPES':
      return {
        ...state,
        instrumentTypes: action.payload.instrumentType,
      };
    case GET_ALL_BAN:
      return {
        ...state,
        banList: action.payload,
      };
    case 'GET_SYMBOL':
      return {
        ...state,
        symbol: action.payload,
      };
    case 'SET_SYMBOL_VALUE':
      return {
        ...state,
        selectedSymbol: action.payload,
      };
    case 'GET_OPTION_TYPE':
      return {
        ...state,
        optionType: action.payload,
      };
    case 'SET_OPTION_TYPE_VALUE':
      return {
        ...state,
        selectedOptionType: action.payload,
      };
    case 'GET_EXPIRY':
      return {
        ...state,
        expiry: action.payload,
      };

    case 'GET_STRIKE':
      return {
        ...state,
        strike: action.payload,
      };

    case 'BAN_EDIT_FORM':
      return {
        ...state,
        banEditForm: action.payload,
      };
    case 'CLEAR_BAN_SECURITY_LIST':
      return {
        ...state,
        createBlockList: action.payload,
      };
    default:
      return state;
  }
};

export default banSecurityReducer;

import { useEffect, useState } from 'react';
import {
  ButtonTabNav,
  GenButton,
  GenericPopup,
  InputField,
  PillButton,
  SearchMultiSelect,
  ShowMultiSelect,
} from '../../../components';
import './styles.css';
import StrategyAnalyzerV2 from '../../../viewScreen/LiveTrading/strategyAnalyzer';
import { MakeRequest } from '../../../utils';
import useSWRImmutable from 'swr/immutable';
import { EditBlue } from '../../../assets';
import { updateValue } from '../../../utils/dotKeyFunctions';
import { bannerReducerAction } from '../../../reducers/bannerReducer';
import { useDispatch } from 'react-redux';
import { mutate } from 'swr';

export default function StrategyAnalyzerTabMan() {
  const [apiTabData] = APIs.GetTabs();
  const [apiTemplateData] = APIs.GetAllTemplates();
  const [tabSelected, setTabSelected] = useState({
    options: [],
    selected: { label: 'SA 1', key: 'sa1' },
  });
  const [popupState, setPopupState] = useState(false);

  useEffect(() => {
    if (apiTabData && apiTabData.length > 0) {
      setTabSelected((old) => ({
        ...old,
        options: apiTabData
          .sort((a, b) => a.seqNum - b.seqNum)
          .map((item) => ({
            label: item.name,
            key: item.id,
            seqNum: item.seqName,
            templates: item.templates,
          })),
        selected: {
          label: apiTabData[0].name,
          key: apiTabData[0].id,
          seqNum: apiTabData[0].seqName,
          templates: apiTabData[0].templates,
        },
      }));
    }
  }, [apiTabData]);

  return (
    <div className="StrategyAnalyzerTabMan-container">
      <div className="StrategyAnalyzerTabMan-top-section">
        <ButtonTabNav
          options={tabSelected.options}
          selected={tabSelected.selected}
          onSelect={(val) =>
            setTabSelected((old) => ({ ...old, selected: val }))
          }
        />
        <GenButton
          fill={false}
          title="Manage Tab"
          icon={EditBlue}
          extStyle={{ padding: 0, margin: '0 1rem 0 0' }}
          clickAction={() => setPopupState((old) => !old)}
        />
      </div>
      <div className="StrategyAnalyzerTabMan-divider"></div>
      <StrategyAnalyzerV2 analyzerTabObj={tabSelected.selected} />
      <GenericPopup
        state={popupState}
        type={1}
        toggleState={() => setPopupState(false)}
      >
        <ManageStratgyAnalyzerTab
          allTabsInfo={apiTabData}
          allTemplates={apiTemplateData}
          cancelTab={() => setPopupState(false)}
        />
      </GenericPopup>
    </div>
  );
}

function ManageStratgyAnalyzerTab({
  allTabsInfo = [],
  allTemplates = [],
  cancelTab = () => {},
}) {
  const [popupState, setPopupState] = useState({
    allTemples: [],
    tabs: [
      {
        id: -1,
        name: '',
        seqNum: 0,
        templates: { abc: true },
        err: false,
      },
    ],
  });
  const [changeFlag, setChangeFlag] = useState(true);
  const dispatch = useDispatch();

  async function submit() {
    const payloadObject = [];
    for (let item of popupState.tabs) {
      const tempTab = {
        id: -1,
        name: 'All',
        seqNum: 0,
        templates: [],
      };
      tempTab.id = item.id;
      tempTab.name = item.name;
      tempTab.seqNum = item.seqNum;
      tempTab.templates = Object.entries(item.templates)
        .filter((item) => item[1])
        .map((template) => {
          const [key, value] = template;
          if (!value) {
            return;
          }
          const id = parseInt(key.split('-')[0]);
          const name = key.split('-').slice(1).join('-');

          return { id, name };
        });
      payloadObject.push(tempTab);
    }
    const response = await MakeRequest('/strategyAnalyzer/tab').post(
      payloadObject
    );
    bannerReducerAction.raiseBanner(
      dispatch,
      response.data.data.message,
      'success'
    );
    cancelTab();
    mutate('/strategyAnalyzer/tab');
  }

  useEffect(() => {
    if (!changeFlag) {
      return;
    }
    const tempDict = {},
      tabs = [...popupState.tabs];
    for (let tabI = 0; tabI < tabs.length; tabI++) {
      const tempTab = tabs[tabI];
      if (tempDict[tempTab.seqNum] && tempDict[tempTab.seqNum] !== tabI) {
        tabs[tabI].err = true;
      } else {
        tempDict[tempTab.seqNum] = tabI;
        tabs[tabI].err = false;
      }
    }
    console.log('Change Check');
    console.log(tabs);

    setPopupState((old) => ({ ...old, tabs }));
    setChangeFlag((old) => !old);
  }, [popupState]);

  useEffect(() => {
    mutate('/strategyAnalyzer/tab');
  }, []);

  useEffect(() => {
    if (!allTabsInfo || !allTemplates) {
      return;
    }
    const tempPopupstate = {
      allTemples: allTemplates.map((item) => `${item.id}-${item.name}`),
      tabs: [],
    };

    for (let tab of allTabsInfo) {
      const tempTab = {
        id: tab.id,
        name: tab.name,
        seqNum: tab.seqNum,
        templates: {},
      };
      for (let templateOfTab of tab.templates) {
        tempTab.templates[`${templateOfTab.id}-${templateOfTab.name}`] = true;
      }
      tempPopupstate.tabs.push(tempTab);
    }
    setPopupState(tempPopupstate);
    setChangeFlag(true);
  }, [allTabsInfo, allTemplates]);

  return (
    <div className="ManageStratgyAnalyzerTab-container">
      <div className="ManageStratgyAnalyzerTab-header">
        <span className="ManageStratgyAnalyzerTab-header-text">
          Manage Strategy Analyzer Tabs
        </span>
      </div>
      <div className="ManageStratgyAnalyzerTab-body">
        {popupState.tabs?.map((item, index) => {
          if (item.name === 'All') {
            return <></>;
          }
          return (
            <TabContainer
              error={item.err}
              index={index}
              removeTab={() =>
                setPopupState((old) => ({
                  ...old,
                  tabs: old.tabs.filter((item, tabIndex) => tabIndex !== index),
                }))
              }
              dataObject={item}
              allStrategyTemplates={popupState.allTemples}
              mapedStrategyTemplate={item.templates}
              sequence={item.seqNum}
              name={item.name}
              updateFunc={(object) => {
                setPopupState((old) => {
                  let tempState = { ...old };
                  tempState = updateValue(tempState, `tabs.${index}`, object);
                  return tempState;
                });
                setChangeFlag(true);
              }}
            />
          );
        })}
        <GenButton
          title="ADD TAB"
          fill={false}
          extStyle={{ width: '8rem' }}
          clickAction={() => {
            setPopupState((old) => ({
              ...old,
              tabs: [
                ...old.tabs,
                { id: null, name: '', seqNum: old.tabs.length, templates: {} },
              ],
            }));
            setChangeFlag(true);
          }}
        />
      </div>
      <div className="ManageStratgyAnalyzerTab-footer">
        <GenButton
          title="CANCEL"
          fill={false}
          extStyle={{ width: '8rem', margin: '0 1rem' }}
          clickAction={() => cancelTab()}
        />
        <GenButton
          title="SUBMIT"
          fill={true}
          extStyle={{ width: '8rem', margin: '0 0rem' }}
          clickAction={() => submit()}
        />
      </div>
    </div>
  );
}

function TabContainer({
  dataObject,
  index = 0,
  mapedStrategyTemplate = {},
  allStrategyTemplates = [],
  updateFunc = () => {},
  removeTab = () => {},
  error = false,
}) {
  return (
    <div
      className="TabContainer-container"
      style={{ border: error ? '1px solid red' : '1px solid #cecece' }}
    >
      <div className="TabContainer-header">
        <span className="TabContainer-header-text">{`TAB ${index}`}</span>
        <PillButton
          iconType={2}
          extStyles={{ margin: 'auto 1rem auto auto' }}
          clickAction={removeTab}
        />
      </div>
      <div className="TabContainer-body">
        <InputField
          label="Tab Name"
          orientation="v"
          extStyles={{ margin: '0.5rem 1rem' }}
          inputWidth="25rem"
          type="string"
          onValueChange={(val) => {
            updateFunc({
              ...dataObject,
              name: val,
            });
          }}
          value={dataObject.name}
        />
        <InputField
          label="Sequence"
          orientation="v"
          extStyles={{ margin: '0.5rem 1rem' }}
          inputWidth="25rem"
          type="number"
          onValueChange={(val) => {
            updateFunc({
              ...dataObject,
              seqNum: val,
            });
          }}
          value={dataObject.seqNum}
        />
        <SearchMultiSelect
          label="Map Strategy Teamplate"
          extStyles={{ margin: '0.5rem 1rem' }}
          contStyles={{ height: '10rem', width: '25rem' }}
          options={allStrategyTemplates}
          selected={mapedStrategyTemplate}
          onSelect={(item, state) => {
            const newTemplates = { ...dataObject.templates };
            newTemplates[item] = state;
            updateFunc({ ...dataObject, templates: newTemplates });
          }}
        />
        <ShowMultiSelect
          label="Mapped Templates"
          extStyles={{ margin: '0.5rem 1rem' }}
          contStyle={{ height: '10rem', width: '25rem' }}
          data={Object.entries(mapedStrategyTemplate)
            .filter((item) => item[1])
            .map((item) => item[0])}
          removeFunc={(item) => {
            const newTemplates = { ...dataObject.templates };
            newTemplates[item] = false;
            updateFunc({ ...dataObject, templates: newTemplates });
          }}
        />
      </div>
    </div>
  );
}

const APIs = {
  GetTabs: () => {
    const { data, error, isLoading } = useSWRImmutable(
      '/strategyAnalyzer/tab',
      async () => {
        const response = await MakeRequest('/strategyAnalyzer/tab').get();
        if (response.status === 200) {
          return response.data.data.data;
        } else {
          throw Error('Something went Wrong');
        }
      }
    );
    return [data, error, isLoading];
  },
  GetAllTemplates: () => {
    const { data, error, isLoading } = useSWRImmutable(
      '/dealer/templates',
      async () => {
        const response = await MakeRequest('/strategy/returnForUser').get();

        if (response.status === 200) {
          return response.data.data;
        } else {
          throw Error('Something went Wrong');
        }
      }
    );
    return [data, error, isLoading];
  },
};

import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import TableGenerator from '../../../components/sharedComponents/GenTableExtended';
import {
  TableDataCell,
  TableRow,
} from '../../../components/sharedComponents/GenTable/tableStyledComps';

import Popup from '../../../components/sharedComponents/Popup/Popup';
import { GenButton, DropDown } from '../../../components';
import { PlayIcon, SquareCheck } from '../../../assets';
import GenericSwitch from '../../../components/sharedComponents/GenSwitch/GenericSwitch';
import './styles.css';
import OpsApiCalls from '../redux/apiCalls';

const MissingTrades = ({ data }) => {
  const { notFoundTrades } = data;

  const [displayData, setDisplayData] = useState([]); //All data in object form
  const [showButtons, setShowButtons] = useState(true); //show how many selected and export/import buttons
  const [sortColumn, setSortColumn] = useState({
    trade_sort_columns: [],
  }); //sorting state of each column
  const [dataState, setDataState] = useState({
    tradeInfo: {},
    sortState: false, //sorting state
    heads: {
      trade: [], //Heading name
      dataType: [], //column datatype
    },
  }); //State management and dataTypes of columns in table

  const [showPopUp, setShowPopUp] = useState(false); //Popup state
  const [popupMessage, setPopupMessage] = useState(''); //Popup message
  const [popupStatus, setPopupStatus] = useState(''); //Popup state(success/failed)
  const [selectedOptions, setSelectedOptions] = useState({}); //state to hold the selected options for each row
  const [visibleData, setVisibleData] = useState([]); // Data to be displayed in the table
  const [pageSize, setPageSize] = useState(20); // Number of rows to load per page
  const [currentPage, setCurrentPage] = useState(1); // Current page of loaded data
  const [scrollPosition, setScrollPosition] = useState(0); // Scroll position
  const tableContainerRef = useRef(); // Reference to the table container element
  const [tableContent, setTableContent] = useState([]); //Data in HTML for rows
  const [select, setSelect] = useState(new Set()); //Keep track of idx of selected rows
  let selectRef = useRef();
  selectRef.current = select;
  let displayDataRef = useRef();
  displayDataRef.current = displayData;

  //handle all rows select
  const selectAllHandle = (arg1, arg2) => {
    console.log(displayDataRef);
    try {
      if (arg2 && displayDataRef.current) {
        setSelect(
          (prev) =>
            new Set(displayDataRef.current.tradeData.map((obj) => obj.idx))
        );
      } else {
        setSelect((prev) => new Set());
      }
    } catch (error) {
      console.log(error);
    }
    setShowButtons(true);
  };

  //Handle single row select
  const handleSelection = (objSelected, state) => {
    if (state) {
      setSelect((prev) => new Set(prev).add(objSelected.idx));
    } else {
      setSelect((prev) => {
        const next = new Set(prev);
        next.delete(objSelected.idx);
        return next;
      });
    }
    setShowButtons(true);
  };

  const handleScroll = () => {
    const tableContainer = tableContainerRef.current;
    if (
      tableContainer.scrollTop + tableContainer.clientHeight + 20 >=
      tableContainer.scrollHeight
    ) {
      // User has scrolled to the bottom
      setCurrentPage((prevPage) => prevPage + 1); // Load the next page
    }
    setScrollPosition(tableContainer.scrollTop); // Update the scroll position
  };

  useEffect(() => {
    tableContainerRef.current = document.getElementsByClassName(
      'infinite-scroll-table'
    )[0];
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
      tableContainer.scrollTop = scrollPosition; // Set the scroll position when component mounts
    }

    return () => {
      if (tableContainer)
        tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //Prepare heading Data
  const prepareHeads = () => {
    const tempState = {
      heads: {
        trade: [],
        dataType: [],
      },
    };

    tempState.heads.trade.push(
      <GenericSwitch
        checkBox={true}
        clickAction={selectAllHandle}
        className="only-checkbox"
        title={''}
        bright={false}
        obj={[]}
      />
    );

    const head = notFoundTrades[1];
    const dataTypes = notFoundTrades[0];
    tempState.heads.trade = [...tempState.heads.trade, ...head];
    tempState.heads.dataType = dataTypes;

    setDataState(tempState);
  };

  //Prepare rows data
  const prepData = () => {
    let newData = [];
    let initialSelectedOption = {};
    for (var i = 2; i < notFoundTrades.length; i++) {
      newData.push({
        values: notFoundTrades[i],
        idx: i,
      });
      initialSelectedOption[i] = 0;
    }
    setSelectedOptions(initialSelectedOption);
    let temp = displayData;
    temp.tradeData = newData.map((item) => {
      return { ...item, state: false };
    });

    setDisplayData({ ...temp });
  };

  //Reload table on change
  useEffect(() => {
    const asyncSetSortTable = async () => {
      if (displayData.length === 0) setTableContent([]);
      else {
        let k = await tableSortDatagenerator(selectRef.current);
        setTableContent(k);
      }
    };
    asyncSetSortTable();
  }, [displayData, sortColumn, select, currentPage, selectedOptions]);
  // useEffect(() => {
  //   const asyncSetTable = async () => {
  //     if (displayData.length === 0) setTableContent([]);
  //     else {
  //       let k = await tableDatagenerator(selectRef.current);
  //       setTableContent(k);
  //     }
  //   };
  //   asyncSetTable();
  // }, [select, currentPage, selectedOptions]);

  //Prepare data and heads on data load
  useEffect(() => {
    prepData();
    prepareHeads();
    setCurrentPage(1);
    setScrollPosition(0);
    setSelect(new Set());

    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  }, [notFoundTrades]);

  //Func to determine state of column on sorting button click
  function toggledColumn(index, order) {
    let tempObj = {
      ...sortColumn,
    };
    for (let i = 0; i < tempObj.trade_sort_columns.length; i++) {
      tempObj.trade_sort_columns[i].order = 'n';
    }
    if (order === 'n') {
      tempObj.trade_sort_columns[index].order = 'a';
      setSortColumn(tempObj);
    } else if (order === 'a') {
      tempObj.trade_sort_columns[index].order = 'd';
      setSortColumn(tempObj);
    } else if (order === 'd') {
      tempObj.trade_sort_columns[index].order = 'n';
      setSortColumn(tempObj);
    }
  }

  //Deetermine sortState once all heading are prepared
  useEffect(() => {
    const sortTableObj = {
      trade_sort_columns: [],
    };
    const heads = dataState.heads.trade;
    sortTableObj.trade_sort_columns.push({
      state: false,
      order: 'n',
    });
    for (let i = 1; i < heads.length; i++) {
      sortTableObj.trade_sort_columns.push({
        state: true,
        order: 'n',
        type: dataState.heads.dataType[i - 1],
      });
    }

    setSortColumn(sortTableObj);
  }, [dataState]);

  const updateVisibleData = () => {
    // Calculate the range of data to be displayed based on the current page and page size

    const newData = tableContent;
    setVisibleData(newData);
  };
  useEffect(() => {
    // Update the visible data when the complete data set or page size changes
    updateVisibleData();
  }, [tableContent, pageSize, currentPage]);

  const asyncMergeSort = async (arr, compareFunc) => {
    if (arr.length <= 1) return arr;

    const mid = Math.floor(arr.length / 2);
    const left = arr.slice(0, mid);
    const right = arr.slice(mid);

    const sortedLeft = await asyncMergeSort(left, compareFunc);
    const sortedRight = await asyncMergeSort(right, compareFunc);

    return asyncMerge(sortedLeft, sortedRight, compareFunc);
  };

  const asyncMerge = async (left, right, compareFunc) => {
    const result = [];
    let leftIdx = 0;
    let rightIdx = 0;

    while (leftIdx < left.length && rightIdx < right.length) {
      if ((await compareFunc(left[leftIdx], right[rightIdx])) <= 0) {
        result.push(left[leftIdx]);
        leftIdx++;
      } else {
        result.push(right[rightIdx]);
        rightIdx++;
      }
    }

    return result.concat(left.slice(leftIdx)).concat(right.slice(rightIdx));
  };

  // Asynchronous sorting function returns HTML Objects to load in table
  const asyncSort = async ({ endIndex, selected }) => {
    let tempData = displayData.tradeData.slice();
    const asyncPart = async () => {
      // console.log('Itna Sort:', tempData);

      for (let i = 1; i < sortColumn.trade_sort_columns.length; i++) {
        if (
          sortColumn.trade_sort_columns[i].state &&
          sortColumn.trade_sort_columns[i].order === 'a'
        ) {
          tempData = tempData.filter((item) => item.values.length > 0);
          tempData = await asyncMergeSort(tempData, async (a, b) => {
            // console.log(a, b);
            if (sortColumn.trade_sort_columns[i].type.toString() === '1') {
              const stringA = String(a.values[i - 1]).toLowerCase();
              const stringB = String(b.values[i - 1]).toLowerCase();
              return stringA.localeCompare(stringB);
            } else if (
              sortColumn.trade_sort_columns[i].type.toString() === '2'
            ) {
              return (
                parseInt(a.values[i - 1], 10) - parseInt(b.values[i - 1], 10)
              );
            } else if (
              sortColumn.trade_sort_columns[i].type.toString() === '3'
            ) {
              const dateA = new Date(a.values[i - 1]);
              const dateB = new Date(b.values[i - 1]);
              return dateA - dateB;
            } else if (
              sortColumn.trade_sort_columns[i].type.toString() === '4'
            ) {
              return (
                a.values[i - 1][selectedOptions[a.idx]] -
                b.values[i - 1][selectedOptions[b.idx]]
              );
            } else {
              // Handle other data types if needed
              return 0;
            }
          });
        } else if (
          sortColumn.trade_sort_columns[i].state &&
          sortColumn.trade_sort_columns[i].order === 'd'
        ) {
          tempData = tempData.filter((item) => item.values.length > 0);
          tempData = await asyncMergeSort(tempData, async (a, b) => {
            if (sortColumn.trade_sort_columns[i].type.toString() === '1') {
              const stringA = String(a.values[i - 1]).toLowerCase();
              const stringB = String(b.values[i - 1]).toLowerCase();
              return stringB.localeCompare(stringA);
            } else if (
              sortColumn.trade_sort_columns[i].type.toString() === '2'
            ) {
              return (
                parseInt(b.values[i - 1], 10) - parseInt(a.values[i - 1], 10)
              );
            } else if (
              sortColumn.trade_sort_columns[i].type.toString() === '3'
            ) {
              const dateA = new Date(a.values[i - 1]);
              const dateB = new Date(b.values[i - 1]);
              return dateB - dateA;
            } else if (
              sortColumn.trade_sort_columns[i].type.toString() === '4'
            ) {
              return (
                b.values[i - 1][selectedOptions[b.idx]] -
                a.values[i - 1][selectedOptions[a.idx]]
              );
            } else {
              // Handle other data types if needed
              return 0;
            }
          });
        }
      }
      // await new Promise((resolve) => setTimeout(resolve, 10000));
    };
    const syncPart = () => {
      let ret = [];
      for (var i = 0; i < tempData.length; i++) {
        if (i > endIndex) break;
        let obj = tempData[i];
        ret.push(
          <TableRow key={obj.id} className="">
            <TableDataCell>
              <GenericSwitch
                checkBox={true}
                state={
                  (selected.size > 0 || selected.size === 0) &&
                  selected.has(obj.idx)
                }
                className="only-checkbox"
                obj={obj}
                clickAction={handleSelection}
                title={''}
              />
            </TableDataCell>
            {/* {
            {for(let i=0;i<obj.values.length;i++){
              <TableDataCell className={'urgent'} width="9vw" key={i}>
              {value}
            </TableDataCell>
            }}
          } */}
            {obj.values.map((value, index) => {
              // console.log('rendering again', value);

              return dataState.heads.dataType[index].toString() === '4' ? (
                <TableDataCell
                  className={'urgent no-padding'}
                  width="9vw"
                  key={index}
                >
                  <DropDown
                    selected={value[selectedOptions[obj.idx]]}
                    label=""
                    // extStyles={{ zIndex: '0' }}
                    options={value || []}
                    orientation="h"
                    type="planeDropDown"
                    textLengthLimit={15}
                    onSelect={(val) => {
                      for (var j = 0; j < value.length; j++) {
                        if (value[j] === val) {
                          // console.log('found', obj.idx, j);
                          setSelectedOptions((prevState) => ({
                            ...prevState,
                            [obj.idx]: j,
                          }));

                          break;
                        }
                      }
                    }}
                  />
                </TableDataCell>
              ) : (
                <TableDataCell className={'urgent'} width="9vw" key={index}>
                  {value}
                </TableDataCell>
              );
            })}
          </TableRow>
        );
      }
      return ret;
    };
    await asyncPart();
    return syncPart();
  };

  //Func to handle Insert in DB
  const handleInsertClick = async () => {
    try {
      let tempData = displayData.tradeData.filter((item) =>
        select.has(item.idx)
      );
      let postData = [];
      // console.log(dataState);
      tempData.map((item) => {
        let newObj = [];
        // console.log(dataState.heads.dataType);
        for (var j = 1; j < dataState.heads.trade.length; j++) {
          if (dataState.heads.dataType[j - 1].toString() === '4') {
            newObj.push(item.values[j - 1][selectedOptions[item.idx]]);
          } else {
            newObj.push(item.values[j - 1]);
          }
        }
        postData.push(newObj);
      });

      postData = { trades: postData };
      const retData = await OpsApiCalls.postSelectedTrades(postData);

      setPopupMessage(retData.message);
      setPopupStatus(retData.status);
      setShowPopUp(true);
    } catch (error) {
      console.log(error);
    }
  };

  const tableSortDatagenerator = async (selected) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    let displayDataValues = displayData.tradeData.slice();

    if (displayDataValues == null) return <></>;

    const tempData = await asyncSort({ endIndex, selected });
    return tempData;
  };
  //Func to pass in popup to close it
  const actionOnAlertpopup = () => {
    setShowPopUp(false);
  };

  return (
    <>
      <div className="missing-trades-container">
        <div className="database-trades">
          <div className="heading-container">
            <p className="main-heading">
              Missing Trades in DB: {data.notFoundCount}
            </p>
          </div>

          <div className="misc-line"></div>
          <div className="selection-container">
            <div
              className={`selection-body ${showButtons ? 'show-buttons' : ''}`}
            >
              <div className="selection-left">
                <p>{select.size} Selected</p>
                <GenButton
                  fill={false}
                  title="Insert Order in DB"
                  extStyle={{
                    margin: '0 1vw 0 1vw',
                    fontSize: '1vh',
                    padding: '0.3vh',
                  }}
                  icon={PlayIcon}
                  clickAction={() => handleInsertClick()}
                />
                <GenButton
                  fill={false}
                  title="Export"
                  extStyle={{
                    margin: '0 1vw 0 0',
                    fontSize: '1vh',
                    padding: '0.3vh',
                  }}
                  // clickAction={() => setSelParPopup(!selParPopup)}
                  icon={SquareCheck}
                />
              </div>
              <div className="selection-right">
                <button
                  className="btn"
                  onClick={() => setShowButtons(false)}
                  style={{ fontSize: '2vh' }}
                >
                  X
                </button>
              </div>
            </div>
          </div>
          <div className="database-content">
            <div className="content-table">
              {/* <Suspense fallback={<LoadingSpinner />} onError={ErrorFallback}> */}
              {
                <TableGenerator
                  heads={dataState.heads.trade}
                  content={visibleData}
                  extStyles={{ height: '100%' }}
                  // pagesInfo={pagesInfo}
                  // pageChange={handlePageChange}
                  toggledColumn={toggledColumn}
                  className="riskMonitorContainer bodyScrollTable infinite-scroll-table"
                  sortObject={{
                    enabledColumns: sortColumn.trade_sort_columns,
                  }}
                  showNoOfEntries={false}
                />
              }
              {/* </Suspense> */}
            </div>
          </div>
        </div>
      </div>

      {showPopUp && (
        <Popup
          actionButton={actionOnAlertpopup}
          popupDetails={{
            type: 'alertUser',
            message: '',
            buttons: 'singleButton',
            state: false,
            nameDecline: 'Close',
          }}
          popClass={
            popupStatus === 200
              ? 'successful popupPadding'
              : 'failed popupPadding'
          }
          header={popupMessage}
        />
      )}
    </>
  );
};

export default MissingTrades;

import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { SearchIcon } from '../../../assets';
import { fetchValue, updateValue } from '../../../utils/dotKeyFunctions';
import { MakeRequest } from '../../../utils';
import FeedbackPopup from '../popupFeedback';
import useSwr from 'swr';

export default function CustomMarketWatchSearch({
  data,
  objKey,
  setData,
  selectedSecurities,
  refreshTable,
  disable = false,
}) {
  const [localState, setLocalState] = useState({
    focused: false,
    errorMsg: '',
    feedBackPopup: '',
    securities: [],
  });
  const [listOffset, setListOffset] = useState(0);

  useEffect(() => {
    if (fetchValue(data, `${objKey}.searchValue`).length === 0) {
      setListOffset(0);
    }
  }, [fetchValue(data, `${objKey}.searchValue`)]);

  function handleIntersection(items) {
    const target = items[0];
    if (target.isIntersecting && localState.securities.length % 8 === 0) {
      setListOffset(listOffset + 8);
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });
    if (localState.securities.length > 0) {
      observer.observe(
        document.getElementById(
          localState.securities[localState.securities.length - 1].title
        )
      );
    }
    return () => {
      observer.disconnect();
    };
  }, [localState.securities]);

  const inputFieldRef = useRef(null);
  const dropdownRef = useRef(null);

  // * This will watch the click outside and close the popup
  const closeDropdownOnOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputFieldRef.current &&
      !inputFieldRef.current.contains(event.target)
    ) {
      setLocalState({ ...localState, focused: false });
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick);
    };
  }, []);

  const [searchData, searchDataError, searchDataLoading] = API.GetSearchResults(
    fetchValue(data, `${objKey}.searchValue`),
    listOffset
  );
  useEffect(() => {
    if (searchDataError) {
      setLocalState({ ...localState, securities: [] });
    }
  }, [searchDataError]);

  useEffect(() => {
    if (searchData && selectedSecurities) {
      const checkDataFeedticker = {};
      for (let dataFeed of selectedSecurities.data) {
        checkDataFeedticker[dataFeed.datafeedTicker] = true;
      }
      const tempLocalState = {
        ...localState,
        securities: [],
      };

      for (let item of searchData) {
        tempLocalState.securities.push({
          ...item,
          title: item.displayName,
          addAvailable: !checkDataFeedticker[item.dataFeedTicker],
        });
      }
      if (listOffset !== 0) {
        tempLocalState.securities = [
          ...tempLocalState.securities,
          ...localState.securities,
        ];
      }

      tempLocalState.errorMsg = '';
      setLocalState(tempLocalState);
    }
  }, [searchData]);

  async function triggerAdd(dataFeedTicker) {
    try {
      const result = await MakeRequest(
        '/customMarketWatch/watchlist/subscribe'
      ).post({
        dataFeedTicker: dataFeedTicker,
        exchange: 'NSE',
        pageNum: data.searchParams.pageNum,
      });

      if (result.status === 200) {
        setLocalState({
          ...localState,
          feedBackPopup: result.data.data.message,
        });
      } else {
        setLocalState({
          ...localState,
          feedBackPopup: result.data.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function triggerRemove(dataFeedTicker) {
    try {
      const result = await MakeRequest(
        '/customMarketWatch/watchlist/unsubscribe'
      ).post({
        dataFeedTicker: dataFeedTicker,
        exchange: 'NSE',
        pageNum: data.searchParams.pageNum,
      });

      if (result.status === 200) {
        setLocalState({
          ...localState,
          feedBackPopup: result.data.data.message,
        });
      } else {
        setLocalState({
          ...localState,
          feedBackPopup: result.data.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="CustomMarketWatchSearch-mainContainer">
      <div
        ref={inputFieldRef}
        className={`CustomMarketWatchSearch-${
          localState.focused ? 'searchContainer-focused' : 'searchContainer'
        }`}
      >
        <img
          src={SearchIcon}
          alt="Search Icon"
          className="CustomMarketWatchSearch-searchIcon"
        />
        <input
          type="text"
          className="CustomMarketWatchSearch-inputBox"
          onFocus={() => {
            if (!disable) {
              setLocalState({ ...localState, focused: true, securities: [] });
            }
          }}
          value={fetchValue(data, `${objKey}.searchValue`)}
          placeholder="Search Securities to add"
          onChange={(val) => {
            setListOffset(0);
            if (!disable) {
              setData(
                updateValue(
                  { ...data },
                  `${objKey}.searchValue`,
                  val.target.value
                )
              );
            }
          }}
        />
      </div>

      <div
        ref={dropdownRef}
        className="CustomMarketWatchSearch-searchableArea"
        style={{ visibility: localState.focused ? 'visible' : 'hidden' }}
      >
        {localState.errorMsg.length > 0 ? (
          <span className="CustomMarketWatchSearch-errorMessage">
            {localState.errorMsg}
          </span>
        ) : (
          localState.securities.map((item) => (
            <SecurityOption
              title={item.title}
              onAdd={(val) => {
                triggerAdd(val);
              }}
              onRemove={(val) => {
                triggerRemove(val);
              }}
              addAvailable={item.addAvailable}
              item={item}
            />
          ))
        )}
      </div>
      <FeedbackPopup
        message={localState.feedBackPopup}
        type="f"
        acceptAction={() => {
          setLocalState({ ...localState, feedBackPopup: '' });
          refreshTable();
        }}
      />
    </div>
  );
}

function SecurityOption({ title, onAdd, onRemove, addAvailable, item }) {
  return (
    <div className="SecurityOption-container" key={title} id={title}>
      <span className="SecurityOption-title">{title}</span>
      {addAvailable ? (
        <span
          className="SecurityOption-add"
          onClick={() => onAdd(item.dataFeedTicker)}
        >
          +
        </span>
      ) : (
        <span
          className="SecurityOption-remove"
          onClick={() => onRemove(item.dataFeedTicker)}
        >
          -
        </span>
      )}
    </div>
  );
}

const API = {
  GetSearchResults: (searchparam, offset) => {
    const { data, error, isLoading } = useSwr(
      `/security/getAllSecuritiesV2?searchString=${searchparam}&size=8&offset=${offset}`,
      async () => {
        let result;
        if (searchparam) {
          result = await MakeRequest(
            `/security/getAllSecuritiesV2?searchString=${searchparam}&size=8&offset=${offset}`
          ).get();
        }
        if (result?.status === 200) {
          return result.data.data;
        } else {
          // throw new Error('No Data Found');
          return []
        }
      }
    );
    return [data, error, isLoading];
  },
};

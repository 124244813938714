import { Fragment, useEffect, useState } from 'react';
import { GenButton } from '../../components';
import ScreenComp from './screen';
import './styles.css';
import useSWR from 'swr';
import { MakeRequest } from '../../utils';

export default function MultiScreen() {
  const [editing, setEditing] = useState(false);
  const [screenState, setScreenState] = useState([]);

  const [initData] = Apis.GetMultiScreenLayout();

  useEffect(() => {
    if (initData) {
      setScreenState(
        initData.map((item) => {
          return { ...item, screen: item.screenName };
        })
      );
    }
  }, [initData]);

  function updatePosition(valueObject, position) {
    setScreenState((val) => {
      const data = val.map((value, index) => {
        if (index === position) {
          return valueObject;
        } else {
          return value;
        }
      });
      return data;
    });
  }

  return (
    <div className="multiScreen-container">
      {editing && (
        <Fragment>
          <GenButton
            fill={false}
            title="ADD SCREEN"
            extStyle={{ margin: '1rem 0 auto auto', zIndex: 1, width: '10rem' }}
            clickAction={() =>
              setScreenState((old) => [
                ...old,
                { x: 0, y: 0, h: 200, w: 400, screen: '' },
              ])
            }
          />
          <GenButton
            fill={false}
            title="RESET"
            extStyle={{
              margin: '1rem 1rem auto 1rem',
              zIndex: 1,
              width: '10rem',
            }}
            clickAction={() => {
              setScreenState((old) =>
                old.map((item, index) => {
                  return {
                    ...item,
                    x: 0 + index * 20,
                    y: 0 + index * 20,
                    h: 200,
                    w: 400,
                  };
                })
              );
            }}
          />
          <GenButton
            fill={true}
            title="SAVE"
            extStyle={{
              margin: '1rem 1rem auto 0',
              zIndex: 1,
              width: '10rem',
            }}
            clickAction={() => {
              setEditing(false);
              Apis.SetScreenLayout(screenState);
            }}
          />
        </Fragment>
      )}
      {!editing && (
        <GenButton
          fill={true}
          title="EDIT"
          extStyle={{
            margin: '1rem 1rem auto auto',
            zIndex: 1,
            width: '10rem',
          }}
          clickAction={() => setEditing(true)}
        />
      )}
      <div className="multiScreen-screenArea">
        {screenState.map((item, index) => (
          <ScreenComp
            positioning={item}
            updatePosition={(val) => updatePosition(val, index)}
            removeItem={() =>
              setScreenState((old) => {
                const newArr = old.filter((item, ind) => ind !== index);
                return newArr;
              })
            }
            editState={editing}
          />
        ))}
      </div>
    </div>
  );
}

const Apis = {
  GetMultiScreenLayout: () => {
    const { data, error, isLoading } = useSWR('/user/multiscreen', async () => {
      const response = await MakeRequest('/user/multiscreen').get();
      return response?.data?.data?.data;
    });
    return [data, error, isLoading];
  },
  SetScreenLayout: async (layout) => {
    const response = await MakeRequest('/user/multiscreen').post({
      args: layout.map((item) => {
        return { ...item, screenName: item.screen };
      }),
    });
    return response;
  },
};

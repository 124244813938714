import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const useKeyPress = (keys, callback, node = null) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event) => {
      try {
        if (keys.length === 1) {
          if (keys.some((key) => event.key === key)) {
            callbackRef.current(event);
          }
        } else {
          const supportKey = keys[0];
          switch (supportKey) {
            case 'shift':
              if (event.shiftKey === true) {
                if (keys.some((key) => event.key === key)) {
                  callbackRef.current(event);
                }
              }
              break;
            case 'alt':
              if (event.altKey === true) {
                if (keys.some((key) => event.key === key)) {
                  callbackRef.current(event);
                }
              }
              break;
            case 'cltr':
              if (event.cltrtKey === true) {
                if (keys.some((key) => event.key === key)) {
                  callbackRef.current(event);
                }
              }
              break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () =>
      targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyPress;

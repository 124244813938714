import { useState } from 'react';
import { ExitConditionIcon } from '../../../../assets';
import HeaderStrategyBuilder from './header';
import './styles.css';
import FormulaGroup from './formulaGroup';
import { useSelector } from 'react-redux';

export default function StrategyAnalyzerFormula() {
  const { strategyTemplateForm } = useSelector(
    (state) => state.strategyBuilderReducer
  );

  const analyzerAvailable = strategyTemplateForm?.analyzerCondition
    ? Object.keys(strategyTemplateForm?.analyzerCondition)?.length > 0
    : false;
  const [display, setDisplay] = useState(analyzerAvailable);

  return (
    <div className="StrategyAnalyzerFormula">
      <HeaderStrategyBuilder
        title="Define Strategy Analyzer Formula"
        icon={ExitConditionIcon}
        switchState={display}
        switchStateToggle={(val) => setDisplay(val)}
      />
      {display && <FormulaGroup />}
    </div>
  );
}

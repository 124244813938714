import BootstrapTreeTable from 'bootstrap-react-treetable/dist/BootstrapTreeTable';
import React, { useEffect, useState } from 'react';
import '../../LiveTrading/TradingHeader/TradingHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  liveTradingData,
  riskParams,
  
} from '../../../actions/LiveTradingAction';
import './RiskViewer.scss';
import AddRisk from './AddRisk/AddRisk';
import Checkbox from './Checkbox';
import { useLocation } from 'react-router-dom';
import { webSocketUrl } from '../../../config/apiConfig';
var W3CWebSocket = require('websocket').w3cwebsocket;

const RiskViewerWindow = () => {
  const {
    liveTradingReducer: { risks },
  } = useSelector((state) => state);
  const [addRuleId, setAddRuleId] = useState([]);
  const [selectedNods, setSelectedNods] = useState([]);
  const [addRuleIdForClearing, setaddRuleIdForClearing] = useState([]);
  const [liveData, setLiveData] = useState({});
  const [tableCol, setTableCol] = useState([]);
  const [treeValue, setTreevalue] = useState([]);
  const dispatch = useDispatch();
  const [pathName, setPathName] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    if (client) {
      client.onopen = () => {
        let userId = localStorage.getItem('customerId');
      let userType = localStorage.getItem('userType');
        const data = {
          eventType: 'RISK_SUBSCRIBE',
          payload: { userId: parseInt(userId), userType},
        };
        client.send(JSON.stringify(data));
      };
      client.onmessage = function (data) {
        try{
              let dataStr = JSON.parse(data.data);
             
              setLiveData(dataStr);
              dispatch(liveTradingData(dataStr));
              if (dataStr.eventType === 'MARKETDATA_START') {
              }
            
            client.onclose = () => {};
        }catch(err){
          console.log("err",err)
        }
      };
    }
    dispatch(riskParams());
  }, []);

  const getDeta = (payLoad) => {
    let riskNumbers = [];
    let rdeta = [];

    if (payLoad) {
      if (typeof payLoad.admin === 'object') {
        let tempData = payLoad.admin;
        let riskNumbers = tempData.riskNumbers;

        let dataObj = {};
        riskNumbers.map((el) => {
          dataObj[el.name] = Math.abs(el.value);
          return el;
        });
        dataObj['name'] = tempData.username;
        dataObj['status'] = tempData.mode;
        dataObj['detaObj'] = { userType: 'admin', adminId: tempData.id };
        rdeta = {
          data: dataObj,
          children: [],
        };
        let teamChild = [];
        for (let key in payLoad.admin.teams) {
          let riskNumbersTeam = payLoad.admin.teams[key].riskNumbers;
          let dataObjForTeam = {};
          riskNumbersTeam.map((el) => {
            dataObjForTeam[el.name] = Math.abs(el.value);
            return el;
          });
          dataObjForTeam['name'] = payLoad.admin.teams[key]?.teamName;
          dataObjForTeam['status'] = payLoad.admin.teams[key].mode;
          dataObjForTeam['detaObj'] = {
            userType: 'team',
            adminId: tempData.id,
            teamId: payLoad.admin.teams[key].id,
          };
          let aaccountChild = [];
          for (let key2 in payLoad.admin.teams[key].users) {
          
            let riskNumbersAccount =
              payLoad.admin.teams[key].users[key2].riskNumbers;
            let dataObjForAccount = {};
            riskNumbersAccount.map((el) => {
              dataObjForAccount[el.name] = Math.abs(el.value);
              return el;
            });
            dataObjForAccount['name'] =
              payLoad.admin.teams[key].users[key2].userName +
              '-' +
              'AC_' +
              payLoad.admin.teams[key].users[key2].account;

            dataObjForAccount['status'] =
              payLoad.admin.teams[key].users[key2].mode;
            dataObjForAccount['detaObj'] = {
              userType: 'customer',
              adminId: payLoad.admin.id,
              teamId: payLoad.admin.teams[key].id,
              custId: payLoad.admin.teams[key].users[key2].id,
              tradingId:
                'payLoad.admin.teams[key].users[key2].accounts[key3].id',
            };

            aaccountChild.push({
              data: dataObjForAccount,
              children: [],
            });
          }
          teamChild.push({
            data: dataObjForTeam,
            children: aaccountChild,
          });
        }
        rdeta.children = teamChild;
      }
    }
    riskNumbers.push(rdeta);
    return riskNumbers;
  };

  

  useEffect(() => {
    let riskDeta = [];
    if (
      liveData !== undefined &&
      Object.keys(liveData).length &&
      (liveData.code !== 404 || liveData.status === 200)
    ) {
      riskDeta = getDeta(liveData.payload);
      setTreevalue(riskDeta);
    }
  }, [liveData]);
 
  const handleRuleIdForClearing = (e) => {
    let val = e.target.value;
    let newVal = Number(val);
    let check = addRuleIdForClearing.find((el) => Number(el) === Number(val));
    if (check === newVal) {
      setaddRuleIdForClearing((addRuleIdForClearing) => [
        ...addRuleIdForClearing.filter((el) => Number(el) !== Number(val)),
      ]);
    } else {
      setaddRuleIdForClearing((addRuleIdForClearing) => [
        ...addRuleIdForClearing,
        Number(val),
      ]);
    }
  };
  useEffect(() => {
    if(risks.length > 0){
      setAddRuleId([...risks.map((el) => el.id)]);
      setaddRuleIdForClearing([...risks.map((el) => el.id)]);
    }
    
  }, [risks]);

  useEffect(() => {
    let obj = [];
    let temp = {
     
      fixedWidth: true,
      styleClass: 'check',
      renderer: (dataRow) => {
        return (
          <Checkbox
            dataRow={dataRow}
            treeValue={treeValue}
            selectedNods={selectedNods}
            setSelectedNods={setSelectedNods}
          />
        );
      },
    };
    risks
      .filter((el) => addRuleIdForClearing.includes(el.id))
      .map((data, index) => {
        let newObj = {
          dataField: data.riskParamName,
          heading: data.riskParamName,
          renderer: (dataRow) => {
            return (
              <div className={dataRow.data.detaObj.userType}>
                {dataRow.data[data.riskParamName]
                  ? dataRow.data[data.riskParamName]
                  : 'N/A'}
              </div>
            );
          },
        };
        obj.push(newObj);
      });
    obj.unshift(temp);
    setTableCol(obj);
  }, [addRuleIdForClearing, risks]);
  const treeControls = {};
  useEffect(() => {
    let pathAdress = location.pathname === '/window/trading';
    if (pathAdress) {
      setPathName(true);
    }
  }, [location.pathname]);
  return (
    <>
      <div className="risk-viewer-table risk-viewer-window">
        <div className="monitor-tree-table risk-Dashboard">
          <div className="text-left">
            <AddRisk
              ruleList={risks}
              addRuleId={[...addRuleIdForClearing]}
              handleRuleId={handleRuleIdForClearing}
            />
          </div>
          <div key={tableCol} className="risk-table">
            {treeValue.length > 0  && risks.length > 0? (
              <BootstrapTreeTable
                columns={tableCol}
                tableData={treeValue}
                control={treeControls}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskViewerWindow;

import React from 'react';

import './styles.css';
import {
  Table,
  TableHeader,
  TableDataCell,
  TableHeadText,
  TableRow,
} from '../../../../components/sharedComponents/GenTable/tableStyledComps';

const SimpleTable = (props) => {
  const rows = props.data;

  return (
    <Table>
      <TableRow>
        <TableHeader>
          <TableHeadText>Severity</TableHeadText>
        </TableHeader>
        <TableHeader>
          <TableHeadText>Validation Key</TableHeadText>
        </TableHeader>
        <TableHeader>
          <TableHeadText>Validation Status</TableHeadText>
        </TableHeader>
      </TableRow>
      <tbody>
        {rows.map((row, index) => {
          const { Severity, ValidationKey, ValidationStatus } = row;
          return (
            <TableRow>
              <TableDataCell>{Severity}</TableDataCell>
              <TableDataCell>{ValidationKey}</TableDataCell>
              <TableDataCell>{ValidationStatus}</TableDataCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
};

export default SimpleTable;

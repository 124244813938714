import { useState, useEffect, useRef } from 'react';
import './styles.css';
import GenericSwitch from '../GenSwitch/GenericSwitch';
import { DropdownIcon } from '../../../assets';
import {
  handleEnterWithActiveElementClick,
  handleEnterWithCallback,
} from '../keyPressEventHandler';
import SyncLoader from 'react-spinners/SyncLoader';
import { SearchMultiSelect } from '../..';

const buttonTypes = new Set([
  'multiSelect',
  'ddButton',
  'subButton',
  'planeDropDown',
]);
const dropDownEnabled = new Set(['multiSelect', 'planeDropDown']);

export default function ({
  uniqueId = '',
  disabled = false,
  type = '',
  title = '',
  clickAction = () => {},
  options,
  icon,
  fill,
  changeTrig,
  params,
  enableState = true,
  allSelect = false,
  extStyle = { back: {}, icon: {}, text: {} },
  enable_search = false,
  className = '',
  focus = false,
  loading = false,
  error = false,
  optionDisplayFunction = (val) => val,
}) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const dropDownRef = useRef(null);
  const buttonRef = useRef(null);

  function dropDownStatus(category) {
    return dropDownEnabled.has(category);
  }

  function dropDownMenuGen(options) {
    return (
      <div
        className="dropdownMenu"
        ref={dropDownRef}
        style={{ width: extStyle.back?.width }}
      >
        {type === 'multiSelect' && allSelect && (
          <GenericSwitch
            title="All Select"
            checkBox={true}
            state={options.reduce(
              (accumulator, currentValue) => accumulator && currentValue.state,
              true
            )}
            clickAction={(obj, state) => {
              for (let item of options) {
                changeTrig(item, state);
              }
            }}
            extStyle={{ margin: '0.5rem 0rem' }}
          />
        )}

        {type === 'multiSelect' &&
          options.map((obj) => (
            <GenericSwitch
              title={obj.title}
              checkBox={true}
              state={obj.state}
              clickAction={changeTrig}
              obj={obj}
              extStyle={{ margin: '0.5rem 0rem' }}
            />
          ))}
        {type === 'planeDropDown' && (
          <>
            {!enable_search &&
              options.map((obj) => (
                <p
                  onClick={() => changeTrig(obj)}
                  className="planeTextOpt font-l1"
                >
                  {optionDisplayFunction(obj)}
                </p>
              ))}
            {enable_search && (
              <SearchMultiSelect
                options={options}
                multiselect={false}
                optionDisplayFunction={optionDisplayFunction}
                onSelect={(val) => changeTrig(val)}
              />
            )}
          </>
        )}
      </div>
    );
  }

  function clickTriggered() {
    if (dropDownStatus(type)) {
      setDropdownStatus(!dropdownStatus);
    } else {
      clickAction(params);
    }
  }

  function contentLoader() {
    if (fill !== undefined) {
      if (title) {
        return (
          <div
            ref={buttonRef}
            tabIndex="0"
            className={`GenButton-cont-${
              fill
                ? `fill${disabled || loading ? '-disabled' : ''}`
                : `emt${disabled || loading ? '-disabled' : ''}`
            }`}
            style={extStyle}
            onClick={() => {
              if (!disabled && !loading) {
                clickAction();
              }
            }}
            onKeyPress={(event) => {
              if (!disabled) {
                handleEnterWithCallback(event, (event) => clickAction(event));
              }
            }}
            id={uniqueId}
          >
            {icon && (
              <img className="GenButton-icon-fill" src={icon} alt="fill-icon" />
            )}
            {title && !loading && (
              <span className={`GenButton-text-${fill ? 'fill' : 'emt'}`}>
                {title}
              </span>
            )}
            <SyncLoader
              color={fill ? '#ffffff' : '#0078d7'}
              loading={loading}
              cssOverride={{
                margin: '0.5em 1.5em',
                height: '1em',
                width: '3.5em',
              }}
            />
          </div>
        );
      } else {
        return (
          <div
            id={uniqueId}
            tabIndex="0"
            className="GenButton-cont-icon"
            style={extStyle}
            onClick={clickAction}
            onKeyPress={(event) =>
              handleEnterWithCallback(event, (event) => clickAction(event))
            }
          >
            {icon && (
              <img className="GenButton-icon-only" src={icon} alt="button" />
            )}
          </div>
        );
      }
    } else {
      return (
        <div className={'buttonContainer ' + className}>
          <div
            tabIndex="0"
            className={`centerContent contentPadding ${type} ${
              !enableState || disabled ? 'GenericButton-disable' : ''
            } ${error ? 'GenericButton-error' : ''}`}
            style={extStyle.back}
            id={uniqueId}
            onClick={() => clickTriggered()}
            onKeyPress={(event) => handleEnterWithActiveElementClick(event)}
          >
            {icon && <img src={icon} id="icon" style={extStyle.icon} />}
            {title && (
              <span
                className="GenericButton-selection font-l1"
                style={extStyle.text}
              >
                {title}
              </span>
            )}
            {dropDownStatus(type) && <img src={DropdownIcon} id="icon" />}
          </div>
          {dropDownStatus(type) && dropdownStatus && dropDownMenuGen(options)}
        </div>
      );
    }
  }

  useEffect(() => {
    if (focus) {
      buttonRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleClickAway = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropdownStatus(!dropDownStatus);
      }
    };

    if (dropDownStatus(type)) {
      document.addEventListener('mousedown', handleClickAway);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [dropDownRef]);

  return contentLoader();
}

import { MakeRequest } from '../../../utils';

const OpsApiCalls = {
  getUserMainTableData: async () => {
    try {
      const result = await MakeRequest('/ops/userMaintenance').get();
      return result.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  createUserInitialMenu: async () => {
    try {
      const result = await MakeRequest('/ops/view?qType=F').get();
      return result.data.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAllRoles: async () => {
    try {
      const result = await MakeRequest('/ops/allRoles').get();

      return result.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  getMenuRespectToRole: async (rolesArray = []) => {
    try {
      const result = await MakeRequest(
        `/ops/view?qType=R&itemId=${rolesArray.join(',')}`
      ).get();
      return result.data.data.data.menu;
    } catch (error) {
      console.log(error);
    }
  },
  getUserForId: async (id) => {
    try {
      const result = await MakeRequest(`/ops/view?qType=U&itemId=${id}`).get();
      return result.data.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  getResetLoginAttempts: async (userId) => {
    try {
      const result = await MakeRequest(
        `/ops/resetLogin?userId=${userId}`
      ).get();

      if (result.status === 500) {
        return { state: false, message: result.data.error.message };
      } else {
        return { state: true, message: result.data.data };
      }
    } catch (error) {
      console.log(error);
    }
  },
  postCreateUser: async (requestObject) => {
    try {
      const result = await MakeRequest(`/ops/createUser`).post(requestObject);
      if (result.status === 500) {
        return { status: result.status, message: result.data.error.message };
      } else {
        return { status: result.status, message: result.data.data };
      }
    } catch (error) {
      console.log(error);
    }
  },
  getHeartbeatData: async () => {
    try {
      const result = await MakeRequest('/ops/heartbeatTree').get();
      return result.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  getApplicationTableData: async (applicationIDs) => {
    try {
      const result = await MakeRequest(`/ops/applicationInfo?`).post({
        applicationIDs: applicationIDs,
      });
      return result && result.data;
    } catch (error) {
      console.log(error);
    }
  },
  postApplicationPopupEditPopupSubmit: async (data) => {
    try {
      const result = await MakeRequest('/ops/submitSchedule').post(data);
      return result;
    } catch (error) {
      console.log(error);
    }
  },
  postSwitchStatusHeartBeatData: async (applicationIDs) => {
    try {
      const result = await MakeRequest('/ops/applicationSwitch').post({
        applicationIDs: applicationIDs,
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  },
  postEnableAutoStart: async (appID) => {
    try {
      const result = await MakeRequest('/ops/enableAutoStart').post({
        appID,
      });
      return result;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  // Data Validation API calls
  getLocationDropdownData: async () => {
    try {
      const result = await MakeRequest('/ops/datavalidation').get();
      return result.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  postDataValidation: async (requestObject) => {
    try {
      const result = await MakeRequest(`/ops/executeValidation`).post(
        requestObject
      );

      if (result.status === 500) {
        return { status: result.status, message: result.data.error.message };
      } else {
        return { status: result.status, message: result.data.data };
      }
    } catch (error) {
      console.log(error);
    }
  },
  //Trade Match API Calls
  getParamsData: async () => {
    try {
      const result = await MakeRequest('/ops/tradeMatchParams').get();

      return result.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  postTradeMatch: async (requestObject) => {
    try {
      const result = await MakeRequest(`/excel/upload/tradeMatch`).post(
        requestObject
      );

      if (result.status === 500) {
        return { status: result.status, message: result.data.error.message };
      } else {
        return { status: result.status, message: result.data.data };
      }
    } catch (error) {
      console.log(error);
    }
  },
  postSelectedTrades: async (requestObject) => {
    const result = await MakeRequest(`/ops/addTradesToDB`).post(requestObject);
    console.log(result);
    if (result.status === 200) {
      return { status: result.status, message: result.data.code };
    } else if (result.status === 400) {
      return { status: result.status, message: result.data.code };
    } else {
      return { status: result.status, message: result.data.error.message };
    }

    // return { status: result.status, message: result.data.error.message };
  },
};

export default OpsApiCalls;

import {
  GET_ALL_FAMILY,
  GET_SINGLE_FAMILY,
  GET_ALL_FAMILY_TYPE,
  GET_ALL_ENTITY_TYPE,
  GET_FAMILY_DEALER,
  UPDATE_FAMILY_FORM,
  GET_ALL_FAMILY_SEGMENT,
  CREATE_FAMILYDATA,
  GET_ALL_FAMILY_EMS,
  GET_ALL_FAMILY_EXCHANGES,
  RESET_FAMILY_DATA,
  SET_EDIT_FAMILY

} from '../actions/types';

const initialState = {
  familyList: [],
  familyTypeList: [],
  entityTypeList: [],
  familyDealersList: [],
  familySegmentList: [],
  ID: '',
  familyEmsList: [],
  familyExchangeList: [],
  familyEdit:false,
  // Single Family object
  family: {
    familyDisplayId: '',
    familyName: '',
    familyType: {
      id: '',
      name: '',
    },
    dealerList: [],
    active: false,
    entityList: [
      {
        entityDisplayId: '',
        entityName: '',
        entityType: '',
        exchange: '',
        segment: '',
        pan: '',
        ucc: '',
        cpCode: '',
        ems: '',
      },
    ],
    entityListLength:'',
  },
  // Single Family object
};
const familyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAMILY:
      return {
        ...state,
        familyList: action.payload,
      };
    case GET_SINGLE_FAMILY:
      return {
        ...state,
        family: action.payload,
      };
    case GET_ALL_FAMILY_TYPE:
      return {
        ...state,
        familyTypeList: action.payload,
      };
    case GET_ALL_ENTITY_TYPE:
      return {
        ...state,
        entityTypeList: action.payload,
      };
    case GET_FAMILY_DEALER:
      return {
        ...state,
        familyDealersList: action.payload,
      };
    case UPDATE_FAMILY_FORM:
      return {
        ...state,
        family: action.payload,
      };
    case GET_ALL_FAMILY_SEGMENT:
      return {
        ...state,
        familySegmentList: action.payload,
      };
    case CREATE_FAMILYDATA:
      return {
        ...state,
        family: action.payload,
      };
    case GET_ALL_FAMILY_EMS:
      return {
        ...state,
        familyEmsList: action.payload,
      };
    case GET_ALL_FAMILY_EXCHANGES:
      return {
        ...state,
        familyExchangeList: action.payload,
      };
    case RESET_FAMILY_DATA:
      return {
        ...state,
        family: action.payload,
      }
      case SET_EDIT_FAMILY:
        return {
          ...state,
          familyEdit: action.payload,
        }
    default:
      return state;
  }
};

export default familyReducer;

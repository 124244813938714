const initialState = {
  createUserData: false,
  data: [],
  clearingData: [],
  tradingData: [],
  ID: '',
  message: '',
  userData: {
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    role: '',
    clearingAccountDetails: [],
    tradingAccountDetails: [],
  },
  isUserEdit:false
};
const createReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_USERDATA':
      return {
        ...state,
        createUserData: action.payload,
        ID: action.id,
        message: action.errorMessage,
      };

    case 'ROLE':
      return {
        ...state,
        data: action.payload,
      };

    case 'CLEARING_REQ':
      return {
        ...state,
        clearingData: action.payload,
      };
    case 'TRADING_REQ':
      return {
        ...state,
        tradingData: action.payload,
      };

    case 'CLEAR_MESSAGE':
      return {
        ...state,
        message: action.clearMessage,
        ID: action.clearId,
      };

    case 'CLEAR_USERNAME_ERROR':
      return {
        ...state,
        message: action.clearMessage,
      };
      case 'UPDATE_USERDATA':
        return {
          ...state,
          userData: action.payload,
        };
        case 'UPDATE_ISEDIT':
          return {
            ...state,
            isUserEdit: action.payload,
          };
      case 'ISEDIT_FALSE':
        return {
          ...state,
          isUserEdit: action.payload,
        };
    default:
      return state;
  }
};

export default createReducer;

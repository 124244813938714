import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../sharedComponents/Table/Table';
import '../../OrderBook/OrderBook.scss';
import {
  ruleOBOrderDrillDown,
  ruleOBUpdatePageNumberOrder,
  ruleOBUpdatePostPerPageOrder,
} from '../../../../actions/LiveTradingAction';
import { TableEntries } from '../../../sharedComponents/TableEntries/TableEntries';
import { PAGE_TITLES, ruleOBReqTypeEnum } from '../../../../utils/constant';
import refresh from '../../../../assets/img/refresh.png';
import Popup from '../../../sharedComponents/Popup/Popup';
import { seo } from '../../../../utils/seo';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../../../sharedComponents/keyPressEventHandler';

const RuleOpenOrder = (props) => {
  const tableRef = useRef(null);
  const drilldownHeaderRef = useRef(null);
  const {
    liveTradingReducer: {
      ruleOBPostPerPage,
      ruleOBPageNumber,
      ruleOBOrderDrillData,
      ruleOBDrillLoaded,

      // postPerPage,
      // pageNumber,
      // orderDrillData,
      // drillLoaded,
    },
  } = useSelector((state) => state);
  const [showPosts] = useState([5, 10, 20, 30]);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [strategyData, setStrategyData] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([...setCurrentPostfun()]);
  const [userShow, setUserShow] = useState([...strategyData]);
  const [showPopup, setShowPopup] = useState({ status: false, message: '' });
  const dispatch = useDispatch();
  let securityId = window.atob(
    props.match.params.securityId ? props.match.params.securityId : ''
  );
  let reqTypeEnum = window.atob(
    props.match.params.reqTypeEnum ? props.match.params.reqTypeEnum : ''
  );
  const urlParams = new URLSearchParams(window.location.search);
  let accountId = urlParams.get('accId');
  let ruleId = urlParams.get('ruleId');

  const handleScroll = (e) => {
    let drilldownHeaderHeight =
      drilldownHeaderRef &&
      drilldownHeaderRef.current &&
      drilldownHeaderRef.current.offsetHeight;

    if (tableRef.current && drilldownHeaderRef.current) {
      drilldownHeaderRef.current.style.position = 'sticky';
      drilldownHeaderRef.current.style.top = '0px';
      drilldownHeaderRef.current.style.background = 'white';
      drilldownHeaderRef.current.style.zIndex = '2';

      if (tableRef.current.querySelector('thead')) {
        tableRef.current.querySelector('thead').style.position = 'sticky';
        tableRef.current.querySelector('thead').style.top =
          drilldownHeaderHeight + 'px';
        tableRef.current.querySelector('thead').style.zIndex = '2';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      handleScroll(e);
    });
    return () => {
      window.removeEventListener('scroll', (e) => {
        handleScroll(e);
      });
    };
  }, []);

  const handleShowPost = (e) => {
    if (e.target.value !== '') {
      dispatch(ruleOBUpdatePageNumberOrder(1));
      dispatch(ruleOBUpdatePostPerPageOrder(Number(e.target.value)));
      setPostsPerPage(e.target.value);
      dispatch(
        ruleOBOrderDrillDown({
          positionId: securityId,
          accountId: accountId,
          ruleId: ruleId,
          reqTypeEnum: reqTypeEnum,
        })
      );
    }
  };
  useEffect(() => {
    fetchDrildownData();
  }, []);

  const fetchDrildownData = () => {
    dispatch(
      ruleOBOrderDrillDown({
        positionId: securityId,
        accountId: accountId,
        ruleId: ruleId,
        reqTypeEnum: reqTypeEnum,
      })
    );
    seo({ title: 'Current Orders' });
  };

  let table_coulumn = [
    {
      lebel: 'Exchange',
      renderHtml: (params) => {
        return <>{params.Exchange}</>;
      },
    },
    {
      lebel: 'Account ID',
      renderHtml: (params) => {
        return <>{params.AccountId}</>;
      },
    },
    {
      lebel: 'Order ID',
      renderHtml: (params) => {
        return <>{params.OrderId}</>;
      },
    },
    {
      lebel: 'Rule ID',
      renderHtml: (params) => {
        return <>{params.RuleId}</>;
      },
    },
    {
      lebel: 'Security',
      renderHtml: (params) => {
        return <>{params.Security}</>;
      },
    },
    {
      lebel: 'Status',
      renderHtml: (params) => {
        return <>{params.Status}</>;
      },
    },
    {
      lebel: 'Currency',
      renderHtml: (params) => {
        return <>{params.Currency}</>;
      },
    },
    {
      lebel: 'Order Px',
      renderHtml: (params) => {
        return <>{params.OrderPrice}</>;
      },
    },
    {
      lebel: 'Order Qty',
      renderHtml: (params) => {
        return <>{params.OrderQty}</>;
      },
    },
    {
      lebel: 'Traded Px',
      renderHtml: (params) => {
        return <>{params.TradedPrice}</>;
      },
    },
    {
      lebel: 'Traded Qty',
      renderHtml: (params) => {
        return <>{params.TradedQty}</>;
      },
    },
    {
      lebel: 'Traded Type',
      renderHtml: (params) => {
        return <>{params.BuySellIndicator === 1 ? 'Buy' : 'Sell'}</>;
      },
    },
    {
      lebel: 'Update Time',
      renderHtml: (params) => {
        return (
          <>
            {params.LastUpdateTime &&
              params.LastUpdateTime.split('T').length > 1 &&
              params.LastUpdateTime.split('T')[1]}
          </>
        );
      },
    },
  ];

  const paginate = (ruleOBPageNumber) => {
    dispatch(ruleOBUpdatePageNumberOrder(ruleOBPageNumber));
    fetchDrildownData();
  };
  function setCurrentPostfun() {
    const indexOfLastPost = ruleOBPageNumber * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsdata;
    if (strategyData) {
      currentPostsdata = strategyData.slice(indexOfFirstPost, indexOfLastPost);
    }
    return currentPostsdata;
  }
  useEffect(() => {
    if (userShow && currentPosts.length) {
      let currPage = userShow.length % currentPosts.length;
      if (currPage === 0) {
        paginate(
          ruleOBPageNumber === 1 ? ruleOBPageNumber : ruleOBPageNumber - 1
        );
      } else {
        paginate(ruleOBPageNumber);
      }
    }
    setCurrentPostfun();
  }, [postsPerPage]);
  return (
    <div className="order-book drilldown" ref={tableRef}>
      <Hotkeys
        keyName="esc"
        onKeyDown={(event) => {
          handleEscapeKeyEvent(event);
        }}
      />
      <Helmet>
        <title>{PAGE_TITLES.orerBookDrilldown}</title>
      </Helmet>
      {showPopup.status && (
        <Popup
          message={showPopup.message}
          setShowPopUp={() => setShowPopup({ status: false, message: '' })}
        />
      )}
      <div className="table-counter-drilldown" ref={drilldownHeaderRef}>
        <Row>
          <Col md={4}>
            <div className="d-flex align-items-center">
              <TableEntries
                role="postPerPage"
                postList={showPosts}
                postHandler={handleShowPost}
                paginate={paginate}
                currentPage={ruleOBPageNumber}
                value={postsPerPage}
              />
              <div className="buttons-wrapperdrill">
                {
                  <>
                    <button
                      className="refresh"
                      onClick={() => fetchDrildownData()}
                    >
                      <img src={refresh} alt="img" />
                      Refresh
                    </button>{' '}
                  </>
                }
              </div>
            </div>
          </Col>
          <Col md={4} className="heading-wrapper">
            {
              <div>
                <h3>
                  {`ALL ${
                    reqTypeEnum == ruleOBReqTypeEnum.OPEN_ORDERS
                      ? 'Open Orders'
                      : 'Trades'
                  } for Rule ${ruleId}`}
                </h3>
              </div>
            }
          </Col>
          <Col md={4}></Col>
        </Row>
      </div>
      <div className="col-12">
        {ruleOBDrillLoaded ? (
          <Table
            tableCoulumn={table_coulumn}
            className={'table table-striped'}
            TableData={ruleOBOrderDrillData ? ruleOBOrderDrillData.entries : []}
            showPagination={true}
            paginate={paginate}
            postsPerPage={ruleOBPostPerPage}
            currentPage={ruleOBPageNumber}
            postsLength={
              ruleOBOrderDrillData ? ruleOBOrderDrillData.numOfEntries : 0
            }
            elipsisPagination={true}
          />
        ) : (
          <Spinner animation="border" variant="dark" />
        )}
      </div>
    </div>
  );
};

export default RuleOpenOrder;

import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import './styles.css';
import useSWR, { useSWRConfig } from 'swr';
import { MakeRequest } from '../../../utils';
import { webSocketUrl } from '../../../config/apiConfig';
import { ruleOBReqTypeEnum } from '../../../utils/constant';
import PillButton from '../../sharedComponents/customComponent/pill_button';
import { Pagination } from '@material-ui/lab';
import useKeyPress from '../../../custom_Hooks/useShortcut';
import FeedbackPopup from '../../sharedComponents/popupFeedback';
import StradeLogo from '../../../assets/img/Strade_LOGO.svg';
import {
  GenButton,
  GenToolTip,
  GenericPopup,
  InputField,
  MultiSelect,
  CheckBox,
} from '../../../components';

const W3CWebSocket = require('websocket').w3cwebsocket;

export default function StrategyAnalyzerTable({
  pageSpecs = { current: 1, pageSize: 5 },
  setPageSpec = (val) => {},
  editAllowed = true, // TODO change this to 'false'
  columnsOrder = [],
  searchValue = { value: '', category: '' },
  setMultiIds,
  setMultiIdsData,
  tabId = -1,
}) {
  const { mutate } = useSWRConfig();
  const [tableSpecs, setTableSpecs] = useState({
    totalEnteries: 100,
    statusSelected: [
      'ACTIVE',
      'PAUSED',
      'COMPLETED',
      'CLOSED',
      'PENDING_START',
      'RESUME_IN_PROCESS',
      'PAUSE_IN_PROCESS',
      'SQUARE_OFF_IN_PROCESS',
      'MODIFY_IN_PROCESS',
      'CLOSE_IN_PROCESS',
      'TRADE_MODIFY_IN_PROCESS',
      'TRADE_MODIFY_ERROR',
      'TRADE_MODIFY_SUCCESS',
      'EA_TRADE_MODIFY_SUCCESSFUL',
      'ODIN_TRADE_MODIFY_IN_PROCESS',
      'ODIN_TRADE_MODIFY_SUCCESS',
    ],
    allStatuses: [
      'ACTIVE',
      'PAUSED',
      'COMPLETED',
      'CLOSED',
      'PENDING_START',
      'RESUME_IN_PROCESS',
      'PAUSE_IN_PROCESS',
      'SQUARE_OFF_IN_PROCESS',
      'MODIFY_IN_PROCESS',
      'CLOSE_IN_PROCESS',
      'TRADE_MODIFY_IN_PROCESS',
      'TRADE_MODIFY_ERROR',
      'TRADE_MODIFY_SUCCESS',
      'EA_TRADE_MODIFY_SUCCESSFUL',
      'ODIN_TRADE_MODIFY_IN_PROCESS',
      'ODIN_TRADE_MODIFY_SUCCESS',
    ],
  });

  const [status, setStatus] = useState([
    { state: true, title: 'ACTIVE' },
    { state: true, title: 'PAUSED' },
    { state: true, title: 'COMPLETED' },
    { state: true, title: 'CLOSED' },
    { state: true, title: 'PENDING_START' },
    { state: true, title: 'RESUME_IN_PROCESS' },
    { state: true, title: 'PAUSE_IN_PROCESS' },
    { state: true, title: 'SQUARE_OFF_IN_PROCESS' },
    { state: true, title: 'MODIFY_IN_PROCESS' },
    { state: true, title: 'CLOSE_IN_PROCESS' },
    { state: true, title: 'TRADE_MODIFY_IN_PROCESS' },
    { state: true, title: 'TRADE_MODIFY_ERROR' },
    { state: true, title: 'TRADE_MODIFY_SUCCESS' },
    { state: true, title: 'EA_TRADE_MODIFY_SUCCESSFUL' },
    { state: true, title: 'ODIN_TRADE_MODIFY_IN_PROCESS' },
    { state: true, title: 'ODIN_TRADE_MODIFY_SUCCESS' },
  ]);

  const inlineRef = useRef(null);
  const [wsClient, setWsClient] = useState(null);
  const [dataFeed, setDataFeed] = useState([]);
  const [popupMsg, setPopupMsg] = useState('');
  const [popupVisible, setPopupVisible] = useState('');
  const [tableData, setTableData] = useState([]);
  const [displayColumns, setDisplayColumns] = useState();
  const [inlineEditState, setInlineEditState] = useState({
    visible: false,
    id: -1,
    position: -1,
  });
  const [tabFocus, setTabFocus] = useState(0);
  const [openOrderTabCount, setOpenOrderTabCound] = useState(0);
  const [tradesCount, setTradesCount] = useState(0);
  const anaylzerTableRef = useRef(null);
  const staticColumns = [
    {
      header: (item, index, allItems) => {
        return (
          <div className="StrategyAnalyzerTable-th" style={{ width: `3rem` }}>
            <span className="StrategyAnalyzerTable-th-title">
              <CheckBox
                state={
                  allItems?.length !== 0
                    ? allItems?.reduce(
                        (aggregator, currentVal) =>
                          aggregator && (currentVal?.checked || false),
                        true
                      )
                    : false
                }
                trigger={(val) => {
                  setDataFeed((old) => {
                    const temp = [...old];
                    for (let i = 0; i < temp.length; i++) {
                      temp[i].checked = val;
                    }
                    if (val) {
                      setMultiIds((old) => allItems.map((item) => item.id));
                    } else {
                      setMultiIds((old) => []);
                    }
                    return temp;
                  });
                }}
              />
            </span>
          </div>
        );
      },
      columnArrangeId: 'checkBox',
      enabled: true,
      sequence: 1,
      contentStyle: (data) => ({ width: '3rem' }),
      content: (data, index) => {
        return (
          <CheckBox
            state={data?.checked || false}
            trigger={(val) => {
              setDataFeed((old) => {
                const temp = [...old];
                temp[index].checked = val;
                return temp;
              });
              if (val) {
                setMultiIds((old) => [...old, data.id]);
                setMultiIdsData((old) => [...old, data]);
              } else {
                setMultiIds((old) => old.filter((item) => item !== data.id));
                setMultiIdsData((old) =>
                  old.filter((item) => item.id !== data.id)
                );
              }
            }}
          />
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">ID</span>
        </div>
      ),
      columnArrangeId: 'id',
      enabled: false,
      content: (data, index) => {
        return data?.id;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Strategy</span>
        </div>
      ),
      columnArrangeId: 'strategyName',
      enabled: false,
      content: (data, index) => {
        return data?.strategyName;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">#Legs</span>
        </div>
      ),
      columnArrangeId: 'numberOfLegs',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-legs-container">
            <span className="StrategyAnalyzerTable-legs-number font-l1">
              {data?.legData?.length}
            </span>
            {data?.legData?.length > 1 && (
              <span
                className="StrategyAnalyzerTable-legs-expand font-l1"
                onClick={(e) => {
                  setDataFeed((old) => {
                    const temp = [...old],
                      target = e.target.innerHTML;
                    temp[index].openStatus =
                      target === 'Collapse' ? false : true;
                    return temp;
                  });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setDataFeed((old) => {
                      const temp = [...old],
                        target = e.target.innerHTML;
                      temp[index].openStatus =
                        target === 'Collapse' ? false : true;
                      return temp;
                    });
                  }
                }}
              >
                {data?.openStatus ? 'Collapse' : 'Expand'}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">AccID</span>
        </div>
      ),
      columnArrangeId: 'entityDisplayId',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data?.legData.map((item) => (
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {item.accountId}
                </span>
              ))
            ) : (
              <span className="StrategyAnalyzerTable-accId font-l1">
                {data?.legData[0].accountId}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Security</span>
        </div>
      ),
      columnArrangeId: 'security',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data?.legData.map((item) => (
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {item.security}
                </span>
              ))
            ) : (
              <span className="StrategyAnalyzerTable-accId font-l1">
                {data?.legData[0].security}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Open-Od</span>
        </div>
      ),
      columnArrangeId: 'openOrders',
      enabled: false,
      content: (data, index, rowId) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data?.legData.map((item, index) => (
                <span
                  className="StrategyAnalyzerTable-legs-expand font-l1"
                  id={`${rowId}-${index}`}
                  onClick={() =>
                    handleRuleOpenOrder(
                      item.securityId,
                      item.accountId,
                      data.id,
                      ruleOBReqTypeEnum.OPEN_ORDERS
                    )
                  }
                  tabIndex={1}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleRuleOpenOrder(
                        item.securityId,
                        item.accountId,
                        data.id,
                        ruleOBReqTypeEnum.OPEN_ORDERS
                      );
                    }
                  }}
                >
                  {item?.openOrders}
                </span>
              ))
            ) : (
              <span
                className="StrategyAnalyzerTable-legs-expand font-l1"
                onClick={() =>
                  handleRuleOpenOrder(
                    data.legData[0].securityId,
                    data.legData[0].accountId,
                    data.id,
                    ruleOBReqTypeEnum.OPEN_ORDERS
                  )
                }
                tabIndex={1}
                id={`${rowId}-0`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleRuleOpenOrder(
                      data.legData[0].securityId,
                      data.legData[0].accountId,
                      data.id,
                      ruleOBReqTypeEnum.OPEN_ORDERS
                    );
                  }
                }}
              >
                {data?.legData[0]?.openOrders}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Trades</span>
        </div>
      ),
      columnArrangeId: 'tradedQty',
      enabled: false,
      content: (data, index, rowId) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data.legData.map((item, index) => (
                <span
                  className="StrategyAnalyzerTable-legs-expand font-l1"
                  onClick={() =>
                    handleRuleOpenOrder(
                      item.securityId,
                      item.accountId,
                      data.id,
                      ruleOBReqTypeEnum.TRADES
                    )
                  }
                  tabIndex={1}
                  id={`${rowId}-t-${index}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleRuleOpenOrder(
                        item.securityId,
                        item.accountId,
                        data.id,
                        ruleOBReqTypeEnum.TRADES
                      );
                    }
                  }}
                >
                  {item?.tradedQty}
                </span>
              ))
            ) : (
              <span
                className="StrategyAnalyzerTable-legs-expand font-l1"
                onClick={() =>
                  handleRuleOpenOrder(
                    data.legData[0].securityId,
                    data.legData[0].accountId,
                    data.id,
                    ruleOBReqTypeEnum.TRADES
                  )
                }
                tabIndex={1}
                id={`${rowId}-t-0`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleRuleOpenOrder(
                      data.legData[0].securityId,
                      data.legData[0].accountId,
                      data.id,
                      ruleOBReqTypeEnum.TRADES
                    );
                  }
                }}
              >
                {data?.legData[0]?.tradedQty}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Pend-Qty</span>
        </div>
      ),
      columnArrangeId: 'pendingQty',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data.legData.map((item) => (
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {isNaN(Math.abs(item.totalQty) - Math.abs(item?.tradedQty))
                    ? 'NA'
                    : Math.abs(item.totalQty) - Math.abs(item?.tradedQty)}
                </span>
              ))
            ) : (
              <span className="StrategyAnalyzerTable-accId font-l1">
                {isNaN(
                  Math.abs(data?.legData[0].totalQty) -
                    Math.abs(data?.legData[0]?.tradedQty)
                )
                  ? 'NA'
                  : Math.abs(data?.legData[0].totalQty) -
                    Math.abs(data?.legData[0]?.tradedQty)}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Tot-Qty</span>
        </div>
      ),
      columnArrangeId: 'totalQty',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data.legData.map((item) => (
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {item?.totalQty}
                </span>
              ))
            ) : (
              <span className="StrategyAnalyzerTable-accId font-l1">
                {data?.legData[0]?.totalQty}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{
            width: `${item.size}px`,
            overflow: 'visible',
            zIndex: 'inherit',
          }}
        >
          <MultiSelect
            compSettings={{
              type: 2,
              maxTextAllowed: 15,
            }}
            options={status}
            placeHolder="Status"
            onSelect={(val, newState) => {
              changeStatus(val, newState);
            }}
            extStyles={{
              width: '90%',
              overflowY: 'visible',
              margin: 'auto 0.5rem auto 0.5rem',
            }}
          />
        </div>
      ),
      columnArrangeId: 'status',
      enabled: false,
      content: (data, index) => {
        return <span className="font-l1">{data?.status?.status}</span>;
      },
      contentStyle: (data) => ({
        padding: '0.3rem 1rem',
        backgroundColor:
          data?.status?.status === 'COMPLETED'
            ? '#6FC276'
            : data?.status?.status === 'CLOSED'
            ? '#f47174'
            : '#ffffff00',
      }),
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Action</span>
        </div>
      ),
      columnArrangeId: 'pause',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-action-area">
            {data?.status?.enableResume ? (
              <PillButton
                iconType={5}
                clickAction={() => {
                  strategyAction([data.id], 'RESUME');
                }}
                disabled={!data?.status?.enableResume}
                extClass="StrategyAnalyzerTable-pill-spacing"
              />
            ) : (
              <PillButton
                iconType={7}
                clickAction={() => {
                  strategyAction([data.id], 'PAUSE');
                }}
                disabled={!data?.status?.enablePause}
                extClass="StrategyAnalyzerTable-pill-spacing"
              />
            )}
            {/* <PillButton
              iconType={1}
              clickAction={() => {}}
              disabled={!data.status.enableTradeModify}
              extClass="StrategyAnalyzerTable-pill-spacing"
            /> */}
            <PillButton
              iconType={1}
              clickAction={async () => {
                strategyAction([data.id], 'MODIFY');
              }}
              disabled={!data?.status?.enableModify}
              extClass="StrategyAnalyzerTable-pill-spacing"
            />
            <PillButton
              iconType={2}
              clickAction={() => {
                generateTableDataFromLeg([data]);
              }}
              disabled={!data?.status?.enableClose}
              extClass="StrategyAnalyzerTable-pill-spacing"
            />
            {/*
             * * Commented Copy rule feature till fix is deployed
             */}
            <PillButton
              iconType={6}
              clickAction={() => {
                strategyAction([data.id], 'COPYRULE');
              }}
              extClass="StrategyAnalyzerTable-pill-spacing"
            />
            {/* <PillButton
              iconType={8}
              clickAction={() => {}}
              disabled={!data.status.enableSquareOff}
              extClass="StrategyAnalyzerTable-pill-spacing"
            /> */}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Des-Sp</span>
        </div>
      ),
      columnArrangeId: 'spreadDesired',
      enabled: false,
      content: (data, index) => {
        return data?.desiredSpread;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Act-Sp</span>
        </div>
      ),
      columnArrangeId: 'spreadActual',
      enabled: false,
      content: (data, index) => {
        return data?.actualSpread;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Cap-Sp</span>
        </div>
      ),
      columnArrangeId: 'spreadCaptured',
      enabled: false,
      content: (data, index) => {
        return data?.capturedSpread;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">PnL</span>
        </div>
      ),
      columnArrangeId: 'pnl',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data.legData.map((item) => (
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {item?.pnl}
                </span>
              ))
            ) : (
              <span className="StrategyAnalyzerTable-accId font-l1">
                {data?.legData[0]?.pnl}
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Pend-Qty</span>
        </div>
      ),
      columnArrangeId: 'border-sa',
      enabled: false,
      content: (data, index) => {
        return data.pendingQty;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">AccName</span>
        </div>
      ),
      columnArrangeId: 'accountName',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data.legData.map((item) => (
                <GenToolTip
                  tooltip={item.accountName}
                  containerRef={anaylzerTableRef}
                >
                  <span className="StrategyAnalyzerTable-accId font-l1">
                    {item.accountName
                      ? item.accountName.length > 15
                        ? item.accountName.slice(0, 15)
                        : item.accountName
                      : 'NA'}
                  </span>
                </GenToolTip>
              ))
            ) : (
              <GenToolTip
                tooltip={data?.legData[0].accountName}
                containerRef={anaylzerTableRef}
              >
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {data?.legData[0].accountName
                    ? data?.legData[0].accountName.length > 15
                      ? data?.legData[0].accountName.slice(0, 15)
                      : data?.legData[0].accountName
                    : 'NA'}
                </span>
              </GenToolTip>
            )}
          </div>
        );
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">BS-Qty</span>
        </div>
      ),
      columnArrangeId: 'desiredQty',
      enabled: false,
      content: (data, index) => {
        return data?.desiredQty;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Exe-Qty</span>
        </div>
      ),
      columnArrangeId: 'executedPortfolioQty',
      enabled: false,
      content: (data, index) => {
        return data?.executedPortfolioQty;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">BCmp</span>
        </div>
      ),
      columnArrangeId: 'marketEntryPrice',
      enabled: false,
      content: (data, index) => {
        return data?.marketEntryPrice;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">SCmp</span>
        </div>
      ),
      columnArrangeId: 'marketExitPrice',
      enabled: false,
      content: (data, index) => {
        return data?.marketExitPrice;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">BS-Px</span>
        </div>
      ),
      columnArrangeId: 'desiredEntry',
      enabled: false,
      content: (data, index) => {
        return data?.desiredEntry;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">SL</span>
        </div>
      ),
      columnArrangeId: 'desiredStopLossExit',
      enabled: false,
      content: (data, index) => {
        return data?.desiredStopLossExit;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">LTP</span>
        </div>
      ),
      columnArrangeId: 'desiredTargetProfitExit',
      enabled: false,
      content: (data, index) => {
        return data?.desiredTargetProfitExit;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">ExeB-Px</span>
        </div>
      ),
      columnArrangeId: 'avgExecutedEntryPrice',
      enabled: false,
      content: (data, index) => {
        return data?.avgExecutedEntryPrice;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">ExeS-Px</span>
        </div>
      ),
      columnArrangeId: 'avgExecutedExitPrice',
      enabled: false,
      content: (data, index) => {
        return data?.avgExecutedExitPrice;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">MTM</span>
        </div>
      ),
      columnArrangeId: 'mtm',
      enabled: false,
      content: (data, index) => {
        return data?.mtm;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Slippage</span>
        </div>
      ),
      columnArrangeId: 'executedSlippage',
      enabled: false,
      content: (data, index) => {
        return data?.executedSlippage;
      },
    },
    {
      header: (item) => (
        <div
          className="StrategyAnalyzerTable-th"
          style={{ width: `${item.size}px` }}
        >
          <span className="StrategyAnalyzerTable-th-title">Symbol</span>
        </div>
      ),
      columnArrangeId: 'symbol',
      enabled: false,
      content: (data, index) => {
        return (
          <div className="StrategyAnalyzerTable-accidContainer">
            {data?.openStatus ? (
              data.legData.map((item) => (
                <span className="StrategyAnalyzerTable-accId font-l1">
                  {item?.symbol}
                </span>
              ))
            ) : (
              <span className="StrategyAnalyzerTable-accId font-l1">
                {data?.legData[0]?.symbol}
              </span>
            )}
          </div>
        );
      },
    },
  ];
  const [apiData] = API.FetchInitialTableInfo(
    pageSpecs.current,
    pageSpecs.pageSize,
    status.filter((item) => item.state).map((item) => item.title),
    searchValue.value,
    searchValue.category,
    tabId
  );

  function updateWithLiveDate(liveData) {
    try {
      const tempOldDataFeed = [...dataFeed];
      for (let i = 0; i < tempOldDataFeed.length; i++) {
        const newLegData = liveData[tempOldDataFeed[i].id].legData;
        delete liveData[tempOldDataFeed[i].id].legData;
        tempOldDataFeed[i] = {
          ...tempOldDataFeed[i],
          ...liveData[tempOldDataFeed[i].id],
        };
        for (let j = 0; j < tempOldDataFeed[i].legData.length; j++) {
          if (newLegData[tempOldDataFeed[i]?.legData[j]?.securityId]) {
            tempOldDataFeed[i].legData[j] = {
              ...tempOldDataFeed[i]?.legData[j],
              ...newLegData[tempOldDataFeed[i]?.legData[j].securityId],
            };
          } else {
            // console.log('Some data not found');
          }
        }
      }
      setDataFeed((oldDataFeed) =>
        oldDataFeed.map((item, index) => ({
          ...item,
          ...tempOldDataFeed[index],
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  function handleRuleOpenOrder(id, accId = 0, ruleId = 0, type = 0) {
    window.open(
      `/window/Order/OrderBookDrilldown/${window.btoa(type)}/${window.btoa(
        id
      )}?accId=${accId}&ruleId=${ruleId}`,
      '_blank',
      'top=100, left=100, width=1857, height=600, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  }

  async function generateTableDataFromLeg(rulesData) {
    setTableData([]);
    const newData = rulesData.map((rule) => {
      const { id, strategyName, legData } = rule;
      const formattedLegData = legData.map((leg) => ({
        legNumber: leg.legNumber,
        security: leg.security,
        tradedQty: leg.tradedQty,
        direction: leg.direction,
      }));
      return { id, strategyName, tableData: formattedLegData };
    });
    setPopupVisible('RULECLOSE');
    setTableData(newData);
  }

  async function strategyAction(strategyIds, action) {
    try {
      if (action === 'COPYRULE') {
        try {
          return openWindow('copyStrategy', strategyIds[0], undefined);
        } catch (error) {
          console.log(error);
        }
      } else if (action === 'MODIFY') {
        try {
          const check = await MakeRequest(
            `/strategy/editWindowInstance/${strategyIds[0]}`
          ).get();
          if (check.data.data.valid) {
            return openWindow('editStrategy', strategyIds[0], undefined);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        const response = await MakeRequest('/strategyAnalyzer/button').post({
          buttonType: action,
          strategyList: strategyIds,
        });

        const msgData = response?.data?.data || {};
        if (msgData?.statusCode !== 200) {
          const message = Object.keys(msgData).reduce((old, ruleId) => {
            if (!isNaN(ruleId)) {
              old += ` Rule ID ${ruleId} : ${msgData[ruleId]?.error}\n`;
            }
            return old;
          }, '');
          setPopupMsg(message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function openWindow(windowType, id, strategyTemplateId) {
    switch (windowType) {
      case 'copyStrategy':
        let strategyTemplate = strategyTemplateId ? strategyTemplateId : id;
        window.open(
          `/window/create-strategy-modal/${window.btoa(id)}/${window.btoa(
            strategyTemplate
          )}/${window.btoa(true)}`,
          '_blank',
          'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
        );
        break;
      case 'editStrategy':
        window.open(
          `/window/edit-strategy/${window.btoa(id)}/${window.btoa(
            strategyTemplateId
          )}`,
          '_blank',
          'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
        );
        break;
      case 'tradeModif':
        window.open(
          `/window/trade-modify-strategy/${window.btoa(id)}/${window.btoa(
            strategyTemplateId
          )}`,
          '_blank',
          'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
        );
        break;

      default:
        break;
    }
  }

  function changeStatus(val, newState) {
    setStatus((old) => {
      const temp = [...old];
      for (let i = 0; i < old.length; i++) {
        if (temp[i].title === val) {
          temp[i].state = newState;
        }
      }
      return temp;
    });
  }

  function hideInlinePopup() {
    setInlineEditState({
      visible: false,
      id: -1,
      position: -1,
    });
    mutate('/strategyAnalyzer/table');
  }

  function rowGenerator(data, index) {
    return (
      <Fragment>
        <div
          id={`SAT-${index}`}
          className={`StrategyAnalyzerTable-tr ${
            inlineEditState.id.id === data.id ? 'active-inline' : ''
          }`}
          tabIndex={0}
          onClick={() => setTabFocus(index)}
        >
          {displayColumns?.map((item) => {
            return (
              <div
                className="StrategyAnalyzerTable-td"
                style={{
                  width: `${item.size}px`,
                  overflow: 'hidden',
                  ...(item.contentStyle ? item.contentStyle(data) : {}),
                }}
                ref={inlineRef}
              >
                {item.content(data, index, `SAT-${index}`)}
              </div>
            );
          })}
        </div>
        {inlineEditState.visible &&
          inlineEditState.id.id === data.id &&
          inlineEditState.id.constants?.filter(
            (item) => !item.name.includes('$')
          ).length > 0 && (
            <InlineStrategyEditor
              data={{ ...inlineEditState }}
              triggerSubmit={hideInlinePopup}
              tabFocus={tabFocus}
            />
          )}
      </Fragment>
    );
  }

  function keyDownHandler(event) {
    switch (event.key) {
      case 'ArrowDown':
        setTabFocus((old) => (old + 1 === pageSpecs.pageSize ? 0 : old + 1));
        event.preventDefault();
        break;
      case 'ArrowUp':
        setTabFocus((old) =>
          old - 1 === -1 ? pageSpecs.pageSize - 1 : old - 1
        );
        event.preventDefault();
        break;
      case 'Tab':
        break;
      default:
        break;
    }
  }

  function handleClickOutside(event) {
    if (
      anaylzerTableRef.current &&
      !anaylzerTableRef.current.contains(event.target)
    ) {
      setInlineEditState({
        visible: false,
        id: -1,
        position: -1,
      });
    }
  }

  useKeyPress(['alt', 'o'], () => {
    const doc = document.getElementById(
      `SAT-${tabFocus || 0}-${openOrderTabCount}`
    );
    if (doc) {
      doc.focus();
    }
    setOpenOrderTabCound((old) =>
      old + 1 === dataFeed[tabFocus ? tabFocus : 0].legData.length ? 0 : old + 1
    );
  });

  useKeyPress(['alt', 't'], () => {
    const doc = document.getElementById(
      `SAT-${tabFocus || 0}-t-${tradesCount}`
    );
    if (doc) {
      doc.focus();
    }
    setTradesCount((old) =>
      old + 1 === dataFeed[tabFocus ? tabFocus : 0].legData.length ? 0 : old + 1
    );
  });

  useKeyPress(['alt', 'r'], () => {
    strategyAction([dataFeed[tabFocus || 0].id], 'RESUME');
  });

  useKeyPress(['alt', 'p'], () => {
    strategyAction([dataFeed[tabFocus || 0].id], 'PAUSE');
  });

  useKeyPress(['alt', 'c'], () => {
    strategyAction([dataFeed[tabFocus || 0].id], 'COPYRULE');
  });

  useKeyPress(['alt', 'q'], () => {
    const rowSel = document.activeElement.id.includes('SAT')
      ? document.activeElement.id.split('-')[1]
      : -1;
    if (rowSel !== -1) {
      setInlineEditState((old) => ({
        visible: true,
        id: dataFeed[parseInt(rowSel)],
        position: parseInt(rowSel),
      }));
    } else {
      document.getElementById(`SAT-${inlineEditState.position}`).focus();
      setInlineEditState((old) => ({
        visible: false,
        id: -1,
        position: parseInt(rowSel),
      }));
    }
    setDataFeed((old) => [...old]);
  });

  useKeyPress(['alt', 'x'], () => {
    generateTableDataFromLeg([dataFeed[tabFocus || 0]]);
    //strategyAction([dataFeed[tabFocus || 0].id], 'CLOSE');
  });

  useKeyPress(['alt', 's'], () => {
    const clickable = document.getElementById('inline-submit');
    clickable.click();
    mutate('/strategyAnalyzer/table');
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (apiData) {
      if (wsClient) {
        wsClient.close();
      }
      setWsClient(new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocal'));
    }

    return () => {
      setWsClient(null);
    };
  }, [apiData, pageSpecs.current, pageSpecs.pageSize]);

  useEffect(() => {
    if (wsClient) {
      wsClient.onopen = () => {
        if (wsClient.readyState === wsClient.OPEN) {
          const data = {
            eventType: 'ANALYZER_SUBSCRIBE',
            payload: {
              userId: parseInt(localStorage.getItem('customerId')),
              strategyIdList: JSON.stringify(apiData?.strategyIdList),
            },
          };
          wsClient.send(JSON.stringify(data));
        } else {
          console.log('CLIENT ready state is NOT OPEN');
        }
      };
      wsClient.onmessage = (data) => {
        try {
          if (data.data === 'REFRESH FLAG DISABLED') {
            mutate('/strategyAnalyzer/table');
            return;
          }
          let dataStr = JSON.parse(data.data);
          let webObj = { ...dataStr.payload };
          updateWithLiveDate(webObj);
          if (webObj.refreshFlag) {
            // dispatch(strategyAnalyzerTable());
            // TODO update strategy table api here
            const data = {
              eventType: 'ANALYZER_REFRESHED',
              payload: {
                userId: parseInt(localStorage.getItem('customerId')),
              },
            };
            wsClient.send(JSON.stringify(data));
          }
        } catch (error) {
          console.log(
            `Error in analyser subscribe request: ${JSON.stringify(error)}`
          );
        }
      };
      wsClient.onclose = () => {};
    }
    return () => {
      wsClient?.close();
    };
  }, [wsClient]);

  useEffect(() => {
    if (!columnsOrder || columnsOrder.length === 0) {
      return;
    }
    let tempColumns = staticColumns
      .map((column) => {
        let newColumn = {
          ...column,
        };
        const colOrderInfo = columnsOrder.find(
          (findItem) => findItem.id === column.columnArrangeId
        );
        newColumn.enabled =
          colOrderInfo?.visibility || column?.enabled || false;
        newColumn.sequence = colOrderInfo?.sequence || column.sequence || 999;
        newColumn.size = colOrderInfo?.columnSize || 100;
        return newColumn;
      })
      .sort((a, b) => a.sequence - b.sequence)
      .filter((item) => item.enabled);
    setDisplayColumns(tempColumns);
  }, [columnsOrder]);

  useEffect(() => {
    setTableSpecs((old) => ({
      ...old,
      totalEnteries: apiData?.totalStrategies,
    }));
    setDataFeed(
      apiData?.analyzerObject?.map((item) => {
        return item.data;
      })
    );
  }, [apiData]);

  useEffect(() => {
    const doc = document.getElementById(`SAT-${tabFocus}`);
    if (doc) {
      doc.focus();
    }
  }, [tabFocus]);

  useEffect(() => {
    mutate('/strategyAnalyzer/table');
    hideInlinePopup();
    setMultiIds([]);
    setMultiIdsData([]);
  }, [pageSpecs.current, pageSpecs.pageSize, status]);

  useEffect(() => {
    hideInlinePopup();
  }, [searchValue]);

  return (
    <div className="StrategyAnalyzerTable-mainContainer" ref={anaylzerTableRef}>
      <div className="StrategyAnalyzerTable-tablecontainer">
        <div className="StrategyAnalyzerTable-table" onKeyDown={keyDownHandler}>
          <div className="StrategyAnalyzerTable-thead">
            {displayColumns?.map((item, index) =>
              item?.header(item, index, dataFeed)
            )}
          </div>
          <div className="StrategyAnalyzerTable-tbody">
            {dataFeed?.map((item, index) => rowGenerator(item, index))}
          </div>
        </div>
      </div>
      <div className="StrategyAnalyzerTable-pagination">
        <Pagination
          count={Math.ceil(tableSpecs.totalEnteries / pageSpecs.pageSize)}
          page={pageSpecs.current}
          variant="outlined"
          shape="rounded"
          onChange={(e, page) => {
            setMultiIds([]);
            setMultiIdsData([]);
            setPageSpec({ current: page, pageSize: pageSpecs.pageSize });
            hideInlinePopup();
          }}
        />
      </div>
      <FeedbackPopup message={popupMsg} acceptAction={() => setPopupMsg('')} />
      <GenericPopup
        type={1}
        state={popupVisible.length != 0}
        toggleState={() => setPopupVisible('')}
      >
        <div className="closeRule-drilldown-container">
          <img src={StradeLogo} className="logo" />
          <span className="closeRule-drilldown-title">
            Are you sure you want to Close the following Strategies? :
          </span>
          <div className="table-container">
            {tableData.map((item, index) => (
              <div key={index} className="strategy-container">
                <div className="strategy-id">
                  Strategy ID: {item.id} &nbsp; &nbsp; &nbsp; Strategy Name:{' '}
                  {item.strategyName}
                </div>
                <div>
                  <table className="leg-data-table">
                    <thead>
                      <tr>
                        <th>Leg Number</th>
                        <th>Security</th>
                        <th>Traded Qty</th>
                        <th>Buy/Sell</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.tableData.map((leg, legIndex) => (
                        <tr key={legIndex}>
                          <td>{leg.legNumber}</td>
                          <td>{leg.security}</td>
                          <td>{leg.tradedQty}</td>
                          <td
                            className={`direction ${leg.direction.toLowerCase()}`}
                          >
                            {leg.direction}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
          <div className="feedback-popup-bottom-New">
            <GenButton
              type="1"
              title="YES"
              fill={true}
              extStyle={{ width: '8vw' }}
              clickAction={() => {
                const strategyIds = tableData.map((item) => item.id);
                setPopupVisible('');
                setTableData([]);
                strategyAction(strategyIds, 'CLOSE');
              }}
            />
            <GenButton
              type="1"
              title="NO"
              fill={false}
              extStyle={{ width: '8vw', marginLeft: '2rem' }}
              clickAction={() => {
                setTableData([]);
                setPopupVisible('');
              }}
              focus={true}
            />
          </div>
        </div>
      </GenericPopup>
    </div>
  );
}

function InlineStrategyEditor(props) {
  const {
    data: { visible = false, id },
    triggerSubmit,
    tabFocus,
  } = props;

  useEffect(() => {
    const focusable = document.getElementById('inlineEdit-0');
    focusable?.focus();
  }, []);

  const [tempConstState, setTempConstState] = useState(
    id.constants
      .filter((item) => !item.name.includes('$'))
      .map((item) => ({ label: item.name, value: item.value }))
  );
  const [feedback, setFeedback] = useState('');

  async function triggereSubmit() {
    try {
      const response = await MakeRequest('/strategy/quickModify').post({
        id: id.id,
        constants: tempConstState.map((item) => ({
          name: item.label,
          value: item.value,
        })),
      });
      if (response.status !== 200) {
        setFeedback(response.data.data.message);
      } else {
        triggerSubmit();
        const doc = document.getElementById(`SAT-${tabFocus}`);
        if (doc) {
          doc.focus();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const focusable = document.getElementById('inlineEdit-0');
    focusable?.focus();
  }, []);

  return (
    <div
      style={{
        visibility:
          visible && tempConstState.length > 0 ? 'visible' : 'collapse',
      }}
      className="InlineStrategyEditor-container"
    >
      <div className="InlineStrategyEditor-container-input">
        {tempConstState.map((item, index) => (
          <InputField
            inputFieldId={`inlineEdit-${index}`}
            extStyles={{ margin: 'auto 1.5rem auto auto' }}
            label={item.label}
            value={item.value}
            orientation="h"
            type="string"
            onValueChange={(val) =>
              setTempConstState((old) =>
                old.map((oldItem, oldIndex) => {
                  if (oldIndex === index) {
                    const temp = { ...oldItem };
                    temp.value = val;
                    return temp;
                  } else {
                    return { ...oldItem };
                  }
                })
              )
            }
          />
        ))}
      </div>
      {tempConstState.length > 0 && (
        <GenButton
          fill={true}
          title="Submit"
          clickAction={triggereSubmit}
          uniqueId={'inline-submit'}
        />
      )}
      <FeedbackPopup
        message={feedback}
        type="f"
        acceptAction={() => {
          setFeedback('');
          triggerSubmit();
          const doc = document.getElementById(`SAT-${tabFocus}`);
          if (doc) {
            doc.focus();
          }
        }}
      />
    </div>
  );
}

const API = {
  FetchInitialTableInfo: (
    pageNumber,
    pageSize,
    statusList,
    searchValue = '',
    searchCategory = '',
    tabId = -1
  ) => {
    const { data, error, isLoading } = useSWR(
      '/strategyAnalyzer/table',
      async () => {
        try {
          let result = {};
          if (
            searchValue &&
            searchValue.length > 0 &&
            searchCategory &&
            searchCategory.length > 0
          ) {
            const category = {
              'Strategy Template': 'STRATEGY_TEMPLATE',
              'Strategy Security': 'STRATEGY_SECURITY',
              'Strategy Name': 'STRATEGY_NAME',
              'Strategy ID': 'STRATEGY_ID',
              'Account ID': 'STRATEGY_ACCOUNT',
            };
            result = await MakeRequest('/strategyAnalyzer/search').post({
              pageNumber: pageNumber,
              entriesPerPage: pageSize,
              statusList: statusList,
              field: category[searchCategory],
              value: searchValue,
              tabId,
            });
          } else {
            result = await MakeRequest('/strategyAnalyzer/table').post({
              pageNumber: pageNumber,
              entriesPerPage: pageSize,
              statusList: statusList,
              tabId,
            });
          }
          const res = result?.data?.data;
          return res;
        } catch (error) {
          console.log(error);
        }
      },
      { revalidateOnFocus: false }
    );
    return [data, error, isLoading];
  },
};

import React from 'react';
import './ErrorPage.scss';
import UiButton from '../../components/sharedComponents/Button';
import { useDispatch } from 'react-redux';
import { clearStatus } from '../../actions/loginAction';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage({ setError, apiStatus }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errorHandle = () => {
    if (localStorage.getItem('token')) {
      localStorage.getItem('userType') === 'admin'
        ? navigate('/risk/userMaintenance')
        : navigate('/LiveTrading');
    } else {
      setError(false);
      dispatch(clearStatus(''));
    }
  };

  return (
    <React.Fragment id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>We are sorry, Page not found!</h2>
        {apiStatus ? (
          <p>The page is currently under maintenance</p>
        ) : (
          <p>
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
        )}

        <UiButton
          name="Back To Homepage"
          className="error-btn"
          onClickFunction={errorHandle}
        />
      </div>
    </React.Fragment>
  );
}

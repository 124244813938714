import React from 'react';
import UiSelector from '../Dropdowns';
import './TableEntries.scss';
import lodash from 'lodash';

export const TableEntries = React.memo(
  (props) => {
    const { role, postList, value, postHandler } = props;
    return (
      <div>
        <div className="analyzer-left">
          <p className="font-l1">Show</p>
          <UiSelector //shared component dropdown
            className="select"
            single
            role={role}
            value={value}
            listData={postList.map((i) => {
              return {
                id: i,
                label: i,
              };
            })}
            handlerFunction={postHandler}
          />
          <p className="font-l1">Entries</p>
        </div>
      </div>
    );
  },
  (oldProp, newProp) => {
    let res = lodash.isEqual(oldProp.postHandler, newProp.postHandler);
    return res;
  }
);

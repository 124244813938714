import React from 'react';
import { Modal, Container, Col, Row } from 'react-bootstrap';
import './MarketWatchModal.scss';
import moment from 'moment';
import closeSvg from '../../../assets/img/closeSvg.svg';

const MarketWatchModal = ({ modal, data, setModal }) => {
  const handleClose = () => setModal(false); // funtion to close the modal
  return (
    <div className="market-click-modal">
      <Modal
        show={modal}
        size="lg"
        aria-labelledby=""
        centered
        onHide={handleClose}
        className="market-click-modal"
      >
        <Modal.Header>
          <Modal.Title>Ticker</Modal.Title>
          <span className="close">
            <img src={closeSvg} alt="close" />
          </span>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <Container>
              <Row className="row-one">
                <Col>
                  <span className="ticker-titles">Exchange</span>
                  <span id="ticker-time">
                    {data.exchange ? data.exchange : ''}
                  </span>
                </Col>
                <Col xs={1.5}>
                  <span className="ticker-titles">Security</span>{' '}
                  <span id="ticker-time">
                    {data.security ? data.security : ''}
                  </span>
                </Col>
                <Col>
                  <span id="ticker-time">{moment().format('h:mm:ss A')}</span>
                </Col>
              </Row>
              <Row className="row-two">
                <Col>
                  <span className="ticker-titles">BidPx</span>
                  {data &&
                    data.orderBook &&
                    data.orderBook.length &&
                    data.orderBook.map((el) => {
                      return (
                        <Row>
                          <Col>
                            <span className="ticker-green">
                              {el['bidPrice']}
                            </span>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
                <Col>
                  <span className="ticker-titles">BidQty</span>
                  {data &&
                    data.orderBook &&
                    data.orderBook.length &&
                    data.orderBook.map((el) => {
                      return (
                        <Row>
                          <Col>
                            <span className="ticker-green">{el['bidQty']}</span>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
                <Col>
                  <span className="ticker-titles">AskPx</span>
                  {data &&
                    data.orderBook &&
                    data.orderBook.length &&
                    data.orderBook.map((el) => {
                      return (
                        <Row>
                          <Col>
                            <span className="ticker-red">{el['askPrice']}</span>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
                <Col>
                  <span className="ticker-titles">AskQty</span>
                  {data &&
                    data.orderBook &&
                    data.orderBook.length &&
                    data.orderBook.map((el) => {
                      return (
                        <Row>
                          <Col>
                            <span className="ticker-red">{el['askQty']}</span>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>

              <Row className="row-three">
                <Col md={3}>
                  <span className="ticker-titles">TBuyQty</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-green">16792</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">TSellQty</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-red">200367</span>
                </Col>
              </Row>

              <Row className="row-four">
                <Col md={3}>
                  <span className="ticker-titles">LTPx</span>
                  <span id="ticker-time">{data.ltpx ? data.ltpx : ''}</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">LTT</span>
                  <span id="ticker-time">{data.ltt ? data.ltt : ''}</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">TTQ</span>
                  <span id="ticker-time">{data.ttq ? data.ttq : ''}</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">OI</span>
                  <span id="ticker-time">{data.oi ? data.oi : ''}</span>
                </Col>
              </Row>
              <Row className="row-four">
                <Col md={3}>
                  <span className="ticker-titles">LTQty</span>
                  <span id="ticker-time">{data.ltq ? data.ltq : ''}</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">ATPx</span>
                  <span id="ticker-time">{data.atpx ? data.atpx : ''}</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">TTV(m)</span>
                  <span id="ticker-time">{data.ttv ? data.ttv : ''}</span>
                </Col>
                <Col md={3}>
                  <span className="ticker-titles">VWAP</span>
                  <span id="ticker-time">{data.vwap ? data.vwap : ''}</span>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row className="row-one">
                <Col>
                  <span className="ticker-titles">Fetching data...</span>
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MarketWatchModal;

import './styles.css';
import { DropdownIcon } from '../../../../assets';

export default function RemarkButton(props) {
  const { title = '', onClickAction = () => {}, extStyles = {} } = props;

  return (
    <div
      className="remark-button-cont"
      onClick={onClickAction}
      style={extStyles}
    >
      <span className="remark-btn-title">
        {title.length === 0 && 'NA'}
        {title.length > 10 ? `${title.slice(0, 10)}...` : title}
      </span>
      <img src={DropdownIcon} className="remark-btn-icon" />
    </div>
  );
}

import 'date-fns';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import './TimePicker.scss';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import clock from '../../../assets/img/clock.svg';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    componentStyle: {
      verticalAlign: 'middle',

      '& fieldset': {
        border: 'solid 2px #0070D8',
      },
      '& .MuiInputBase-input': {
        backgroundColor: '#e9ecef',
      },
    },
  })
);

// material ui time picker shared component
export default function MaterialUIPickers({
  selectedDate,
  setTimeData,
  startTime,
  endTime,
  timeType,
  exitCond,
  isReadOnly = false,
}) {
  const [selectedTime, setSelectedTime] = React.useState();
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles({
    color: '#e9ecef',
  });

  useEffect(() => {
    //setting the end time and start time
    if (timeType === 'startTime') {
      setSelectedTime(selectedDate);
    } else if (timeType === 'endTime') {
      setSelectedTime(selectedDate);
    }
  }, [selectedDate]);

  const handleDateChange = (date) => {
    if (exitCond) {
      setError(false);
      setSelectedTime(date);
      setTimeData(date);
    } else {
      if (timeType === 'startTime') {
        const currDate = new Date();

        // First Time Set Only: As date is used as state first then it is changed to moment
        if (endTime.setDate) endTime.setDate(currDate.getDate());
        if (endTime.setMonth) endTime.setMonth(currDate.getMonth());
        if (endTime.setFullYear) endTime.setFullYear(currDate.getFullYear());

        if (date > endTime) {
          setError(true);
        } else {
          setError(false);
          setSelectedTime(date);
          // If only a valid Time, then only set anything in Parent State
          if (date && date._isValid) {
            setTimeData(date);
          }
        }
      } else if (timeType === 'endTime') {
        const currDate = new Date();

        // First Time Set Only: As date is used as state first then it is changed to moment
        if (startTime.setDate) startTime.setDate(currDate.getDate());
        if (startTime.setMonth) startTime.setMonth(currDate.getMonth());
        if (startTime.setFullYear)
          startTime.setFullYear(currDate.getFullYear());

        if (date < startTime) {
          setError(true);
        } else {
          setError(false);
          setSelectedTime(date);
          // If only a valid Time, then only set anything in Parent State
          if (date && date._isValid) {
            setTimeData(date);
          }
        }
      }
    }
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justify="space-around">
          {/* <div style={{ border: 'solid #000 2px' }}> */}
          <KeyboardTimePicker
            style={{
              width: '140px',
              borderRadius: '8px',
            }}
            margin="normal"
            id="time-picker"
            ampm={false}
            value={selectedTime}
            onChange={handleDateChange}
            // onClick={() => setOpen(!open)}
            onClose={() => setOpen(false)}
            open={open}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            views={['hours', 'minutes', 'seconds']}
            format="HH:mm:ss"
            InputProps={{
              readOnly: isReadOnly,
              className: isReadOnly ? classes.componentStyle : '',
            }}
            keyboardIcon={
              <img
                onClick={() => {
                  if (!isReadOnly) setOpen(!open);
                }}
                onClose={() => {
                  if (!isReadOnly) setOpen(false);
                }}
                src={clock}
                alt="calendar"
                width="20px"
                height="20px"
                style={{ marginRight: '10px' }}
              />
            }
          />
          {/* </div> */}
        </Grid>
      </MuiPickersUtilsProvider>
      {/* {error ? ( //toaster for start time greater time greater than end time
        <SimpleSnackbar
          toaster={error}
          setToaster={setError}
          message={'Start time should not be greater than End time'}
        />
      ) : null} */}
    </>
  );
}

import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import userDataReducer from './userReducer';
import createReducer from './createReducer';
import forgotReducer from './forgotReducer';
import editReducer from './editReducer';
import roleDataReducer from './roleReducer';
import editRoleReducer from './editRoleReducer';
import teamDashboardReducer from './teamDashboardReducer';
import createRoleReducer from './createRoleReducer';
import editTeamReducer from './editTeamReducer';
import riskConfigReducer from './riskConfigReducer';
import riskMonitReducer from './riskMonitReducer';
import strategyBuilderReducer from './strategyBuilderReducer';
import changePassword from './changePasswordReducer';
import liveTradingReducer from './liveTradingReducer';
import strategyAnalyzerReducer from './strategyAnalyzerReducer';
import familyReducer from './familyReducer';
import banSecurityReducer from './banSecurityReducer';
import alertReducer from './alertReducer';
import shortcutReducer from './shortcutReducer';
import { TableOrderReducer } from './table_order_reducer';
import { OpsReducer } from '../viewScreen/Ops/redux';
import { MasterDataReducer } from '../components/strategyBuilder/strategyView/viewData/masterDataRedux';
import { CreateFormulaFormPopup } from './createFormulaForm';
import { CustomMarketWatchReducer } from './customMarketWatchState';
import { bannerReducer } from './bannerReducer';

const rootReducer = combineReducers({
  CustomMarketWatchReducer,
  CreateFormulaFormPopup,
  TableOrderReducer,
  loginReducer,
  userDataReducer,
  createReducer,
  forgotReducer,
  editReducer,
  roleDataReducer,
  teamDashboardReducer,
  editRoleReducer,
  createRoleReducer,
  editTeamReducer,
  riskConfigReducer,
  riskMonitReducer,
  strategyBuilderReducer,
  changePassword,
  liveTradingReducer,
  strategyAnalyzerReducer,
  familyReducer,
  banSecurityReducer,
  alertReducer,
  shortcutReducer,
  OpsReducer,
  MasterDataReducer,
  bannerReducer,
});

export default rootReducer;

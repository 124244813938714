import React, { useEffect, useRef, useState } from 'react';
import './AddAlert.scss';
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick';
import CustomCheckBox from '../../../components/sharedComponents/CheckBox/CheckBox';
import filterIcon from '../../../assets/img/filter02.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import GenericSearchBox from '../../sharedComponents/genSearchBox/genericSearchBox';
import { GenButton } from '../..';

const AddAlert = ({
  ruleList,
  addRuleId,
  handleRuleId,
  eventkeyName,
  tabKey,
  setaddRuleIdForClearing,
  addRuleIdForClearing,
}) => {
  const [searchRule, setSearchRule] = useState('');
  const [initialData, setInitialData] = useState([]);
  const [ruleShow, setRuleShow] = useState([]);
  const [checkedRules, setCheckedRules] = useState([]);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const dropdownShow = () => setIsActive(!isActive);
  const dispatch = useDispatch();

  const {
    alertReducer: { alertTableData },
  } = useSelector((state) => state);

  const location = useLocation();

  useEffect(() => {
    let newRule = [...ruleList];
    let newArray = newRule.filter((el) => el.tabEnum.trim() == tabKey.trim());
    if (tabKey === 'STRATEGY_TEMPLATE_APPROVAL') {
      let filterArr = newArray[0]?.fields.filter((el) => el.type != 'ACTION');
      setRuleShow(filterArr);
      setInitialData(filterArr);
      setaddRuleIdForClearing(filterArr.map((elm) => elm.fieldEnum));
    } else {
      setRuleShow(newArray[0]?.fields);
      setInitialData(newArray[0]?.fields);
      setaddRuleIdForClearing(newArray[0]?.fields.map((elm) => elm.fieldEnum));
    }
  }, [tabKey]);

  useEffect(() => {
    setCheckedRules([...addRuleIdForClearing]);
  }, [addRuleIdForClearing]);

  const handleSearch = (text) => {
    let inputText = text;
    let displayData;
    let finalResult = [];
    let inputsearch = inputText.toLowerCase();
    setRuleShow([]);
    let newRuleList = [...initialData];
    setSearchRule(inputText);
    if (inputText !== '') {
      if (inputsearch.trim().length > 0) {
        for (let i = 0; i < newRuleList.length; i++) {
          displayData = newRuleList[i].displayName
            .toLowerCase()
            .includes(inputsearch);
          if (displayData === true) {
            finalResult.push(newRuleList[i]);
          }
        }
        setRuleShow(finalResult);
      }
    } else {
      setRuleShow(newRuleList);
    }
  };

  const handleRuleIdForClearing = (e) => {
    let val = e.target.value;
    let check = checkedRules.find((el) => el === val);
    if (check === val) {
      setCheckedRules((checkedRules) => [
        ...checkedRules.filter((el) => el !== val),
      ]);
    } else {
      setCheckedRules((checkedRules) => [...checkedRules, val]);
    }
  };

  const handleApply = (e) => {
    setaddRuleIdForClearing(checkedRules);
  };

  return (
    <div className="add-alert-wrapper">
      <div className="add-rule-wrapper">
        <div className="text-left">
          <button className="add-rule-btn" onClick={dropdownShow}>
            <img className="button-image" src={filterIcon} />
          </button>
          <div
            ref={dropdownRef}
            className={` toogledropdown dropdown-rule  ${
              isActive ? 'active' : 'inactive'
            }`}
          >
            <GenericSearchBox
              changeListener={handleSearch}
              message="Search"
              className=" searchBox"
            />

            <div className="addCheckBox">
              {ruleShow?.length > 0 ? (
                ruleShow &&
                ruleShow.map((list, index) => (
                  <CustomCheckBox //shared checkbox component
                    key={list.fieldEnum}
                    label={list.displayName}
                    value={list.fieldEnum}
                    handlerfunction={handleRuleIdForClearing}
                    checked={checkedRules.includes(list.fieldEnum)}
                  />
                ))
              ) : (
                <label> No Data </label>
              )}
            </div>

            <div className="lineBreak"></div>
            <div className="applyButton">
              <GenButton
                title="Apply"
                clickAction={handleApply}
                extStyle={{
                  back: { backgroundColor: '#2F78D7', borderRadius: '6px' },
                  text: {
                    color: '#ffffff',
                    font: 'normal normal 500 14px/19px Roboto',
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAlert;

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './PreviewStrategyModal.scss';
import Table from '../../../../sharedComponents/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import UiInput from '../../../../sharedComponents/Input';
import MultiDropdown from '../../../../sharedComponents/MultiDropdown/MultiDropdown';
import {
  getquantityPreview,
  setPreviewPricing,
} from '../../../../../actions/strategyBuilderAction';

const OrderDetails = () => {
  const [marketForm, setMarketForm] = useState([]);
  const [editFlagIndex, setEditFlagIndex] = useState(null);
  const [inputData, setInputData] = useState({});
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [equationValues, setEquationValues] = useState([
    { type: 'OPERAND', enumName: '', value: '' },
  ]);
  const [priceData, setPriceData] = useState(null);
  const [fieldIndex, setFieldIndex] = useState('');
  const {
    strategyBuilderReducer: { strategyTemplateForm, pricingLogic, isEdit },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);
  const marketHandler = (index, data) => {
    let field = {
      enumName: data.data,
      displayName: data.conditionName,
      args: data.arguements,
    };
    let marketFormData = [];
    if (data === 'REMOVE') {
      marketFormData = [];
    } else {
      let newIndex = '';
      if (index > 0) {
        newIndex = 0;
      } else {
        newIndex = index;
      }
      if (!marketFormData[newIndex]) {
        marketFormData[newIndex] = data;
      } else {
        marketFormData.splice(newIndex, 1, data);
      }
    }
    let legInd = priceData?.legIndex;
    let positionInd = priceData?.positionIndex;
    dispatch(
      setPreviewPricing({
        field: marketFormData,
        key: 'pricing',
        legInd,
        positionInd,
      })
    );
  };

  const equationHandler = (data, eqIndex) => {
    let newEquation = [...equationValues];
    if (data === 'REMOVE') {
      let obj = newEquation[eqIndex];
      newEquation[eqIndex] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };
    } else {
      if (!newEquation[eqIndex]) {
        newEquation[eqIndex] = data;
      } else {
        newEquation.splice(eqIndex, 1, data);
      }
    }
    setEquationValues(newEquation);
  };
  // useEffect(() => {
  // }, [marketForm]);

  const inputHandler = (e, params, preveiewQty) => {
    let newR = e.target.value.replace(/[^0-9]+/g, '');
    let values = parseInt(newR);
    let totalObj = {
      legIndex: params.legIndex,
      positionIndex: params.positionIndex,
      key: 'position',
      value: values,
      preveiewQty,
    };
    dispatch(getquantityPreview(totalObj));
  };
  const pricingHandler = (data, ind) => {
    setPriceData(data);
    setFieldIndex(ind);
  };
  let table_coulumn = [
    {
      lebel: 'Leg#',
      renderHtml: (params) => {
        let index = params.legIndex;
        return (
          <>
            <span id={`preview_leg_${index}`}>Leg{params.legId}</span>
          </>
        );
      },
    },
    {
      lebel: 'Buy/Sell',
      renderHtml: (params) => {
        let index = params.legIndex;
        return (
          <>
            <p>
              <span
                id={`preview_buySell_${index}`}
                className={params.direction === 'BUY' ? 'green' : 'red'}
              >
                {params.direction}
              </span>
              {params.security}
            </p>
          </>
        );
      },
    },
    {
      lebel: 'Order Qty',
      renderHtml: (params) => {
        let index = params.legIndex;
        return (
          <>
            <UiInput
              type="number"
              name="OrderQty"
              id={`preview_orderQty_${index}`}
              handlerfunction={(e) => inputHandler(e, params, 'orderQty')}
              value={params.orderQty}
              disabled={isApproveReject}
            />
          </>
        );
      },
    },
    {
      lebel: 'Total Qty',
      renderHtml: (params) => {
        let index = params.legIndex;
        return (
          <>
            {params.quantity.length > 1 ||
            (params.quantity.length === 1 &&
              params.quantity[0]?.data !== '') ? (
              <p>Logic</p>
            ) : (
              <UiInput
                type="number"
                name="TotalQty"
                id={`preview_totalQty_${index}`}
                handlerfunction={(e) => inputHandler(e, params, 'totalQty')}
                value={params.totalQty}
                disabled={isApproveReject}
              />
            )}
          </>
        );
      },
    },
    {
      lebel: 'Price',
      renderHtml: (params, ind) => {
        return (
          <div
            onClick={() => pricingHandler(params, ind)}
            onMouseOver={() => pricingHandler(params, ind)}
            id={`preview_price_${ind}`}
            className={
              ind === fieldIndex &&
              inputData &&
              inputData.displayName === 'MARKET'
                ? 'market'
                : ind === fieldIndex &&
                  inputData &&
                  inputData.displayName === 'TOB + x'
                ? 'Tob'
                : inputData && inputData.displayName
                ? 'pointer'
                : isApproveReject
                ? 'approveReject-pointer'
                : ''
            }
          >
            {params.algo.length > 0 ? (
              params.algo[0].data ? (
                <p>{params.algo[0].data}</p>
              ) : (
                <p>algo logic</p>
              )
            ) : (params.pricing.length > 1 ||
                (params.pricing.length === 1 &&
                  params.pricing[0]?.data !== '')) &&
              !(params.pricing[0].eqIndex === ind) ? (
              <p>Logic</p>
            ) : (
              <MultiDropdown
                listData={pricingLogic}
                marketForm={params.pricing}
                eqIndex={ind}
                editFlagIndex={editFlagIndex}
                setEditFlagIndex={setEditFlagIndex}
                setMarketForm={setMarketForm}
                marketHandler={marketHandler}
                setInputData={setInputData}
                setSelectedCondition={setSelectedCondition}
                placeHolder={'Select'}
                setEquationValues={equationHandler}
                fieldId={'entry'}
                id={`preview_price_${ind}`}
                noInput
              />
            )}
          </div>
        );
      },
    },
    {
      lebel: 'LOT Size',
      renderHtml: (params) => {
        let index = params.legIndex;
        return (
          <p id={`preview_lotSize_${index}`}>
            {params.lotSize ? params.lotSize : 1}
          </p>
        );
      },
    },
  ];

  let tableData = [...strategyTemplateForm.entryLegs];
  let postionData = [];
  let newTable = tableData.forEach((element, legIndex) => {
    element.position?.map((val, key) =>
      postionData.push({
        ...val,
        legIndex,
        positionIndex: key,
        legId: element.legId,
        algo: element.algoExecution,
      })
    );
  });

  return (
    <div className="orderDetailTable">
      <div className="sectionHeadings">
        <h3>Order Details</h3>
      </div>
      <Table
        className={'responsive table-striped'}
        showHeader={true}
        tableCoulumn={table_coulumn}
        showPagination={false}
        TableData={postionData}
        id="preview_orderTable"
      ></Table>
    </div>
  );
};

export default OrderDetails;

import React from 'react';
import './styles.css';
import Arrow from '../../assets/img/arrow.svg';

const AnimatedButton2 = (props) => {
  return (
    <span className="animButton-back2" onClick={props.onClick}>
      <p className="animBText">Download Brochure</p>
      {/* <span className="arrow-circle">
        <img src={Arrow} className="arrow-icon" />
      </span> */}
    </span>
  );
};


export default AnimatedButton2;

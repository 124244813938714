// ! save this in environment variables
const envUrl = '192.168.200.103:8891';
const url = `http://${envUrl}`;
const webSocketUrl = `//${envUrl}`;

let status = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'PAUSED', name: 'PAUSED' },
  { id: 'COMPLETED', name: 'COMPLETED' },
  { id: 'CLOSED', name: 'CLOSED' },
  { id: 'IN_PROCESS', name: 'IN PROCESS' },
  { id: 'PENDING_START', name: 'PENDING START' },
];

const apiConfig = (debug = false) => {
  if (debug) {
    return {
      api: `http://${envUrl}`,
      websocket: `//${envUrl}`,
    };
  } else {
    return {
      api: `http://${envUrl}`,
      websocket: `//${envUrl}`,
    };
  }
};

const configBools = {
  enableSquareOffInAnalyzerTable: false,
};

const heartbeatPublishToBEInterval = 10000; // In millisecond

export {
  webSocketUrl,
  url,
  status,
  apiConfig,
  configBools,
  heartbeatPublishToBEInterval,
};

import { useEffect, useRef, useState } from 'react';
import { Label } from '../internalComponents';
import './styles.scss';

export default function V2DropDown({
  label = '',
  error = '',
  warning = '',
  value = '',
  enableSearch = true,
  setValue = () => {},
  extStyles = { mainContainer: {}, inputContainer: {} },
  options = [],
  disabled = false,
  orientation = 'h',
}) {
  const [onFocus, setOnFocus] = useState(false);
  const [curArrowSelect, setCurArrowSelect] = useState(-1);
  const [dispOptions, setDispOptions] = useState(options);
  const divRef = useRef(null);
  const inputRef = useRef(null);

  function handleClickOutside(event) {
    if (
      divRef.current &&
      inputRef.current &&
      !divRef.current.contains(event.target) &&
      !inputRef.current.contains(event.target)
    ) {
      setOnFocus(false);
    }
  }

  function handleSelection(value) {
    if (typeof dispOptions[0] === 'string') {
      setValue(value.toString());
    } else {
      setValue(value);
    }
  }

  const handleKeyDown = (e) => {
    if (onFocus) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
        const currentIndex = curArrowSelect;
        const lastIndex = dispOptions.length - 1;
        const newIndex =
          e.key === 'ArrowUp'
            ? currentIndex > 0
              ? currentIndex - 1
              : lastIndex
            : currentIndex < lastIndex
            ? currentIndex + 1
            : 0;
        const computedStyle = window.getComputedStyle(divRef.current);
        const fontSizeInPixels = parseFloat(computedStyle.fontSize);
        divRef.current.scrollTop = newIndex * fontSizeInPixels;
        setCurArrowSelect(newIndex);
      } else if (e.key === 'Enter') {
        handleSelection(dispOptions[curArrowSelect]);
      }
    }
  };

  useEffect(() => {
    console.log(options);
    let tempOptions = [...options];
    if (value && enableSearch) {
      tempOptions = tempOptions.filter((item) =>
        typeof item === 'string'
          ? item.toLowerCase().includes(value.toLowerCase())
          : parseInt(item).toString().includes(value)
      );
    }
    setDispOptions(tempOptions);
  }, [options, value]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Label
      label={label}
      extStyles={{ ...extStyles.mainContainer, position: 'relative' }}
      orientation={orientation}
    >
      <div onKeyDown={handleKeyDown}>
        {enableSearch ? (
          <input
            ref={inputRef}
            className="adv-inputbox"
            value={value}
            type="string"
            onFocus={() => setOnFocus(true)}
            onChange={(e) => !disabled && setValue(e.target.value)}
            style={{
              ...extStyles.inputContainer,
              backgroundColor: disabled ? '#e6e6e6' : '#fff',
            }}
          />
        ) : (
          <div
            ref={inputRef}
            className="V2DropDown-selection-container"
            onFocus={() => setOnFocus(true)}
            style={{
              ...extStyles.inputContainer,
              backgroundColor: disabled ? '#e6e6e6' : '#fff',
            }}
            tabIndex={0}
          >
            <span className="V2DropDown-selection">{value}</span>
          </div>
        )}

        {!disabled && onFocus && (
          <div
            className="adv-dropdown-options"
            style={{
              width: extStyles.inputContainer.width || '10vw',
              top:
                label.length === 0 || orientation === 'h' ? '2.5rem' : '4.5rem',
            }}
            ref={divRef}
          >
            {dispOptions.map((item, index) => (
              <span
                className="option"
                key={item}
                onClick={() => handleSelection(item)}
                style={{
                  backgroundColor:
                    index === curArrowSelect ? '#cecece' : '#fff',
                }}
              >
                {item}
              </span>
            ))}
            {dispOptions.length === 0 && (
              <span className="option">No Options</span>
            )}
          </div>
        )}
      </div>
    </Label>
  );
}

import '../../entryCondition/Condition/Condition.scss';
import './Condition.scss';
import Dropdowns from '../../../../sharedComponents/Dropdowns';
import NewDropdown from '../../../../sharedComponents/NewDropdown/NewDropdown';
import React, { useEffect, useState } from 'react';
import SimpleSnackbar from '../../../../sharedComponents/Toast/Toast';
import StaticCondition from '../../../../sharedComponents/StaticCondition/StaticCondition';
import info from '../../../../../assets/img/info.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddCircleIcon,
  DeleteHoverIcon,
  DeleteIcon,
} from '../../../../../assets';
import {
  exitUpdateConditions,
  setConstants,
  setErrorInExit,
  setExitConditions,
  updateConditions,
} from '../../../../../actions/strategyBuilderAction';
import { GenButton } from '../../../..';

const Condition = ({ validator, formSubmit }) => {
  const {
    strategyBuilderReducer: {
      strategyTemplateForm,
      operation,
      exitConditions,
      marketCondition,
      marketTriggeredOperators,
      errorInExit,
      isEdit,
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [formInput, setFormInput] = useState({});
  const [inputData, setInputData] = useState({});
  const [marketForm, setMarketForm] = useState([]);
  const [fieldIndex, setFieldIndex] = useState();
  const [fieldValue, setFieldValue] = useState('');
  const [editFlagIndex, setEditFlagIndex] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [changes, setChanges] = useState(false);
  const [trailingData, setTrailingData] = useState({});
  const [targetData, setTargetData] = useState({});
  const [inputFieldIndex, setInputFieldIndex] = useState('');
  const [conditionValue, setConditionValue] = useState('');
  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));
  const [error, setError] = useState(false);
  const [deleteHover, setDeleteHover] = useState({});
  const [errorInCondition, setErrorInCondition] = useState([]);

  const [fields, setFields] = useState([
    {
      type: 'OPERAND',
      trigger: '',
    },
  ]);
  const [equationValues, setEquationValues] = useState([
    { type: 'OPERAND', enumName: '', value: '' },
  ]);
  useEffect(() => {
    let error = [...errorInCondition];
    strategyTemplateForm.exitCondition.condition.map((condition, index) => {
      if (index % 2 === 0) {
        if (condition.trigger === 'TRAILING_STOP_LOSS') {
          if (
            !(
              condition.trailingStopLoss.activateAt &&
              condition.trailingStopLoss.profitIncrease &&
              condition.trailingStopLoss.tsl
            )
          ) {
            error = ['trailingStopLoss', ...error];
          } else {
            error = error.filter((el) => el !== 'trailingStopLoss');
          }
        } else if (condition.trigger === 'TARGET_PROFIT') {
          if (!condition.targetProfit.targetProfit) {
            error = ['targetProfit', ...error];
          } else {
            error = error.filter((el) => el !== 'targetProfit');
          }
        } else if (condition.trigger === 'MARKET_TRIGGERED') {
          if (
            !(condition.marketTriggered && condition.marketTriggered.length)
          ) {
            error = ['marketTriggered', ...error];
          } else {
            error = error.filter((el) => el !== 'marketTriggered');
          }
        }
      }
    });
    setErrorInCondition([...error]);
    dispatch(setErrorInExit([...new Set(error)]));
  }, [strategyTemplateForm]);

  useEffect(() => {
    setErrorInCondition([...errorInExit]);
  }, [errorInExit]);

  // TODO
  useEffect(() => {
    let ind = '';
    if (fieldIndex === 1) {
      ind = 2;
    } else if (fieldIndex === 2) {
      ind = 4;
    } else if (fieldIndex === 3) {
      ind = 6;
    } else if (fieldIndex === 4) {
      ind = 8;
    } else {
      ind = fieldIndex;
    }
    if (isEdit) {
      let field = strategyTemplateForm.exitCondition.condition;
      if (fieldValue) {
        let obj = exitConditions.find((el) => el.key === fieldValue);
        let timeForm = {
          startTime: moment(startTime).format('H:mm:ss'),
          endTime: moment(endTime).format('H:mm:ss'),
        };
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${fieldValue}`]: timeForm,
        };
        field[ind] = keyObj;
      }

      dispatch(setExitConditions({ field, key: 'condition' }));
    } else {
      let field = fields;
      if (fieldValue) {
        let obj = exitConditions.find((el) => el.key === fieldValue);
        let timeForm = {
          startTime: moment(startTime).format('H:mm:ss'),
          endTime: moment(endTime).format('H:mm:ss'),
        };
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${fieldValue}`]: timeForm,
        };
        field[ind] = keyObj;
      }
      dispatch(setExitConditions({ field, key: 'condition' }));
    }
  }, [startTime, endTime]);

  useEffect(() => {
    let ind = '';
    if (fieldIndex === 1) {
      ind = 2;
    } else if (fieldIndex === 2) {
      ind = 4;
    } else if (fieldIndex === 3) {
      ind = 6;
    } else if (fieldIndex === 4) {
      ind = 8;
    } else {
      ind = fieldIndex;
    }
    if (fieldValue) {
      let obj = exitConditions.find((el) => el.key === fieldValue);
      let field = [];
      if (isEdit) {
        field = strategyTemplateForm.exitCondition.condition;
      } else {
        field = fields;
      }
      let updatedMarketForm = marketForm.map((el, index) => {
        return {
          type: el.type,
          data: el.data,
          arguements: el.arguements,
        };
      });
      let keyObj = {
        type: 'OPERAND',
        trigger: obj.data.enumName,
        [`${fieldValue}`]: [...updatedMarketForm],
      };
      field[ind] = keyObj;
      dispatch(setExitConditions({ field, key: 'condition' }));
    }
    if (
      marketForm[marketForm.length - 1]?.conditionName ===
      'New Template Parameter'
    ) {
      let obj = {
        name: marketForm[marketForm.length - 1].arguements[0].argValue,
        value: null,
      };
      dispatch(setConstants({ obj, key: 'constants' }));
    }
  }, [marketForm]);

  useEffect(() => {
    let ind = '';
    if (inputFieldIndex === 1) {
      ind = 2;
    } else if (inputFieldIndex === 2) {
      ind = 4;
    } else if (inputFieldIndex === 3) {
      ind = 6;
    } else if (inputFieldIndex === 4) {
      ind = 8;
    } else {
      ind = inputFieldIndex;
    }
    if (fieldValue && !isEdit) {
      let obj = exitConditions.find((el) => el.key === conditionValue);
      let field = fields;
      if (Object.keys(trailingData).length && obj?.key === 'trailingStopLoss') {
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data?.enumName,
          [`${conditionValue}`]: trailingData,
        };
        field[ind] = keyObj;
      }
      if (Object.keys(targetData).length && obj?.key === 'targetProfit') {
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data?.enumName,
          [`${conditionValue}`]: targetData,
        };
        field[ind] = keyObj;
      }
      dispatch(setExitConditions({ field, key: 'condition' }));
      setConditionValue('');
    } else if (fieldValue && isEdit) {
      let obj = exitConditions.find((el) => el.key === conditionValue);
      let field = strategyTemplateForm.exitCondition.condition;
      if (Object.keys(trailingData).length && obj?.key === 'trailingStopLoss') {
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data?.enumName,
          [`${conditionValue}`]: trailingData,
        };
        field[ind] = keyObj;
      }
      if (Object.keys(targetData).length && obj?.key === 'targetProfit') {
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data?.enumName,
          [`${conditionValue}`]: targetData,
        };
        field[ind] = keyObj;
      }
      dispatch(setExitConditions({ field, key: 'condition' }));
      setConditionValue('');
    }
  }, [formInput, targetData, trailingData]);

  // useEffect(() => {
  // }, [fieldIndex, fieldValue]);
  // useEffect(()=>{
  // },[marketForm])

  const setTime = (argument) => {
    let value = argument.startTime.split(':');
    let d = new Date();
    if (value.length > 2) {
      d.setHours(value[0], value[1], value[2]);
    }
    setStartTime(d);
  };

  useEffect(() => {
    if (isEdit) {
      let editMarket = strategyTemplateForm.exitCondition.condition.find(
        (el) => el.trigger === 'MARKET_TRIGGERED'
      );
      let newOperationalArr = marketTriggeredOperators.map((opt) => {
        return {
          type: opt.type,
          displayName: opt.displayName,
          data: opt.values,
        };
      });
      let conditionArray = [...marketCondition, ...newOperationalArr];
      if (editMarket && editMarket?.marketTriggered) {
        let foundArray = [];
        for (let i = 0; i < editMarket?.marketTriggered.length; i++) {
          for (let j = 0; j < conditionArray.length; j++) {
            for (let k = 0; k < conditionArray[j].data.length; k++) {
              if (
                conditionArray[j].data[k].enumName ===
                  editMarket.marketTriggered[i].data.trim() ||
                (editMarket.marketTriggered[i].data.trim() === 'PARAMETER' &&
                  conditionArray[j].data[k].enumName ===
                    editMarket.marketTriggered[i]?.arguements[0]?.argName)
              ) {
                let finalObj = {
                  ...editMarket?.marketTriggered[i],
                  conditionName: conditionArray[j].data[k].displayName,
                };
                foundArray.push(finalObj);
              }
            }
          }
        }
        let finalEditMarket = foundArray.map((el, index) => {
          return {
            ...el,
            eqIndex: index,
          };
        });
        let eqArr = foundArray.map((elm, index) => {
          return {
            enumName: elm.data,
            type: elm.type === 'OPERATION' ? 'OPERATOR' : 'OPERAND',
            value: elm.conditionName,
          };
        });
        setMarketForm(finalEditMarket);
        setEquationValues(eqArr);
      }
      let trailingEdit = strategyTemplateForm.exitCondition.condition.find(
        (el) => el.trigger === 'TRAILING_STOP_LOSS'
      );
      if (trailingEdit && trailingEdit.trailingStopLoss) {
        setTrailingData(trailingEdit.trailingStopLoss);
      }
      let targetEdit = strategyTemplateForm.exitCondition.condition.find(
        (el) => el.trigger === 'TARGET_PROFIT'
      );
      if (targetEdit && targetEdit.targetProfit) {
        setTargetData(targetEdit.targetProfit);
      }
      let timeEdit = strategyTemplateForm.exitCondition.condition.find(
        (el) => el.trigger === 'TIME_TRIGGERED_EXIT'
      );
      if (timeEdit && timeEdit.timeTriggeredExit) {
        setTime(timeEdit.timeTriggeredExit);
      }
    }
  }, [isEdit]);

  const marketHandler = (index, data, cancelObj, operation = 'NEW') => {
    let marketFormData = [...marketForm];
    if (data === 'REMOVE') {
      let obj = marketFormData[index];
      if (obj.conditionName === 'New Template Parameter') {
        let removeData = {
          name: obj.arguements[0].argValue,
          value: null,
        };
        dispatch(
          setConstants({
            obj: removeData,
            legIndex: '',
            key: 'constants',
            remove: true,
          })
        );
      }
      marketFormData[index] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };

      if (
        cancelObj &&
        cancelObj.data == 'PARAMETER' &&
        cancelObj.arguements.length &&
        cancelObj.arguements[0].argName.startsWith('NEW_TEMPLATE')
      ) {
        let tempForm = { ...strategyTemplateForm };
        let conditionIndex = '';
        let argIndex = [];
        let condObj = null;
        let newTriggerCond = [];
        let newPriceArr = [];
        let exitCondIndex = '';
        let newExitCondObj = null;
        let newExitTrigger = [];

        //removing for entry condition
        for (let i = 0; i < strategyTemplateForm.entryLegs.length; i++) {
          //finding market Trigger condition
          condObj = strategyTemplateForm.entryLegs[i].condition.find(
            (trig) => trig.trigger === 'MARKET_TRIGGERED'
          );
          if (condObj) {
            conditionIndex =
              strategyTemplateForm.entryLegs[i].condition.indexOf(condObj);
            // marketTriggered arguements after removing new template
            newTriggerCond = condObj.marketTriggered.map((el, argInd) => {
              if (
                el.data === 'PARAMETER' &&
                el.arguements.length &&
                el.arguements[0].argName.startsWith('EXISTING') &&
                el.arguements[0].argValue === cancelObj.arguements[0].argValue
              ) {
                return { type: 'CONSTANT', data: '', arguements: [] };
              }
              return el;
            });
            condObj.marketTriggered = newTriggerCond;
            tempForm.entryLegs[i].condition[conditionIndex] = condObj;
          }

          //finding template parameter in position and pricing
          for (
            let pos = 0;
            pos < strategyTemplateForm.entryLegs[i].position.length;
            pos++
          ) {
            newPriceArr = strategyTemplateForm.entryLegs[i]?.position[
              pos
            ]?.pricing?.map((p, posInd) => {
              if (
                p.data === 'PARAMETER' &&
                p.arguements.length &&
                p.arguements[0].argName.startsWith('EXISTING') &&
                p.arguements[0].argValue === cancelObj.arguements[0].argValue
              ) {
                return {
                  type: 'CONSTANT',
                  data: '',
                  arguements: [],
                };
              }
              return p;
            });
            tempForm.entryLegs[i].position[pos].pricing = newPriceArr;
          }
        }
        //removing template in exit condition
        if (
          strategyTemplateForm.exitCondition.condition &&
          strategyTemplateForm.exitCondition.condition.length &&
          Object.keys(strategyTemplateForm.exitCondition.condition[0]).length >
            2
        ) {
          newExitCondObj = strategyTemplateForm.exitCondition.condition.find(
            (ex) => ex.trigger === 'MARKET_TRIGGERED'
          );
          exitCondIndex =
            strategyTemplateForm.exitCondition.condition.indexOf(
              newExitCondObj
            );
          newExitTrigger = newExitCondObj.marketTriggered.map((el, exInd) => {
            if (
              el.data === 'PARAMETER' &&
              el.arguements.length &&
              el.arguements[0].argName.startsWith('EXISTING') &&
              el.arguements[0].argValue === cancelObj.arguements[0].argValue
            ) {
              argIndex.push(exInd);
              return { type: 'CONSTANT', data: '', arguements: [] };
            }
            return el;
          });
          newExitCondObj.marketTriggered = newExitTrigger;
          tempForm.exitCondition.condition[exitCondIndex] = newExitCondObj;
          for (let k = 0; k < argIndex.length; k++) {
            marketFormData[argIndex[k]] = {
              ...marketFormData[argIndex[k]],
              data: '',
              arguements: [],
            };
          }
        }
        dispatch(updateConditions(tempForm));
      }
    } else {
      if (!marketFormData[index]) {
        marketFormData[index] = data;
      } else {
        marketFormData.splice(index, 1, data);
      }
    }
    setMarketForm(marketFormData);
  };

  const inputHandler = (e, index) => {
    setFieldIndex(index);
    setFieldValue(e.target.value);
    let keyObj = {};
    let newObj = {};
    let defaultObj = {
      type: 'OPERAND',
      trigger: '',
    };
    let val = e.target.value;
    let obj = exitConditions.find((el) => el.key === val);
    if (val !== '') {
      if (val === 'timeTriggeredExit') {
        // TODO
        let keys = Object.keys(obj.data.args);
        keys.forEach((el) => (keyObj[el] = obj.data.args[el].value));
        keyObj = {
          startTime: moment(startTime).format('H:mm:ss'),
        };
        newObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${val}`]: keyObj,
        };
      } else if (val !== 'marketTriggered') {
        let keys = Object.keys(obj.data.args);
        keys.forEach(
          (el) =>
            (keyObj[el] = obj.data.args[el].value
              ? obj.data.args[el].value
              : '')
        );
      } else if (val === 'marketTriggered') {
        handleEquationClose();
      }
      newObj = {
        type: 'OPERAND',
        trigger: obj.data.enumName,
        [`${val}`]: keyObj,
      };
    }
    if ((fields.length === 1 || index === 0) && !isEdit) {
      let field = fields;
      if (val !== '') {
        field[index] = newObj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      } else {
        field[index] = defaultObj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      }
    } else if (isEdit && index === 0) {
      let field = strategyTemplateForm.exitCondition.condition;
      if (val !== '') {
        field[index] = newObj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      } else {
        field[index] = defaultObj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      }
    } else {
      let newIndex = 0;
      let field;
      if (isEdit) {
        field = strategyTemplateForm.exitCondition.condition;
      } else {
        field = fields;
      }
      if (index === 1 || index > 1) {
        if (index === 1) {
          newIndex = 2;
        }
        if (index === 2) {
          newIndex = 4;
        }
        if (index === 3) {
          newIndex = 6;
        }
        if (index === 4) {
          newIndex = 8;
        }

        if (val !== '') {
          field[newIndex] = newObj;
          dispatch(setExitConditions({ field, key: e.target.name }));
        } else {
          field[newIndex] = defaultObj;
          dispatch(setExitConditions({ field, key: e.target.name }));
        }
      } else {
        let final = field.find(
          (elm) => elm.type === 'OPERAND' && elm.trigger === ''
        );
        if (val !== '') {
          field[field.indexOf(final)] = newObj;
          dispatch(setExitConditions({ field, key: e.target.name }));
        } else {
          field[2] = defaultObj;
          dispatch(setExitConditions({ field, key: e.target.name }));
        }
      }
    }
    setChanges(!changes);
  };

  const timeHandler = (e, inputType, index) => {
    setInputFieldIndex(index);
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  const trailingHandler = (e, inputType, index, fieldName) => {
    setInputFieldIndex(index);
    setConditionValue(fieldName);
    let newVal = e.target.value;
    let finalTrail = '';
    if (newVal.includes('.')) {
      finalTrail =
        newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2);
    } else {
      finalTrail = newVal;
    }
    setTrailingData({ ...trailingData, [e.target.name]: finalTrail });
  };

  const targetHandler = (e, inputType, index, fieldName) => {
    setInputFieldIndex(index);
    setConditionValue(fieldName);
    let newVal = e.target.value;
    let finalTarget = '';
    if (newVal.includes('.')) {
      finalTarget =
        newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2);
    } else {
      finalTarget = newVal;
    }
    setTargetData({ ...targetData, [e.target.name]: finalTarget });
  };

  const clearConstantHandler = (form, index, argArr) => {
    if (form.exitCondition.condition[index].trigger === 'MARKET_TRIGGERED') {
      let eqObj = form.exitCondition.condition[index];
      for (let i = 0; i < eqObj.marketTriggered.length; i++) {
        if (
          eqObj.marketTriggered[i].data === 'PARAMETER' &&
          eqObj.marketTriggered[i].arguements.length &&
          eqObj.marketTriggered[i].arguements[0].argName.startsWith(
            'NEW_TEMPLATE'
          )
        ) {
          argArr.push(eqObj.marketTriggered[i].arguements[0]);
        }
      }
    }
  };

  function handleRemove(index, field) {
    // updating errorInExitCondition in store on removing condition
    let error = [...errorInExit];
    error = error.filter((el) => el !== Object.keys(field)[2]);
    setErrorInCondition([...error]);
    dispatch(setErrorInExit([...new Set(error)]));
    let argArr = [];

    let form = { ...strategyTemplateForm };
    if (index === 1) {
      let newIdx = index + 1;
      //for removing constants on deleting equation
      clearConstantHandler(form, newIdx, argArr);
      form.exitCondition.condition.splice(index, newIdx);
    } else if (index > 1 && index < 3) {
      let newIdx = index + 1;
      clearConstantHandler(form, newIdx + 1, argArr);
      form.exitCondition.condition.splice(newIdx, 2);
    } else if (index > 2) {
      let newIdx = index + 2;
      clearConstantHandler(form, newIdx + 1, argArr);
      form.exitCondition.condition.splice(newIdx, 2);
    } else if (index === 0 && form.exitCondition.condition.length > 1) {
      clearConstantHandler(form, index, argArr);
      form.exitCondition.condition.splice(index, 2);
    } else if (index === 0) {
      //for removing constants on deleting equation
      clearConstantHandler(form, index, argArr);
      form.exitCondition.condition = [{ type: 'OPERAND', trigger: '' }];
    }
    //setting new constants
    for (let k = 0; k < argArr.length; k++) {
      form.constants = form.constants.filter(
        (el) => el.name !== argArr[k].argValue
      );
    }
    dispatch(exitUpdateConditions(form));
    if (field.trigger === 'TARGET_PROFIT') {
      setTargetData({});
    } else if (field.trigger === 'TRAILING_STOP_LOSS') {
      setTrailingData({});
    } else if (field.trigger === 'TIME_TRIGGERED') {
      setFormInput({});
    }
  }

  const handleAdd = () => {
    const ops = {
      type: 'OPERATION',
      value: '',
    };
    const row = {
      type: 'OPERAND',
      trigger: '',
    };
    let form = { ...strategyTemplateForm };
    form.exitCondition.condition.push(ops, row);
    dispatch(exitUpdateConditions(form));
  };

  const operationHandler = (e, index) => {
    let obj = {
      type: 'OPERATION',
      value: e.target.value,
    };
    let newIndex = '';
    if (isEdit) {
      let field = strategyTemplateForm.exitCondition.condition;
      if (index === 1 || index > 1) {
        if (index === 1) {
          newIndex = 1;
        }
        if (index === 2) {
          newIndex = 3;
        }
        if (index === 3) {
          newIndex = 5;
        }
        if (index === 4) {
          newIndex = 7;
        }

        field[newIndex] = obj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      } else {
        let final = field.find(
          (elm) => elm.type === 'OPERATION' && elm.value === ''
        );
        field[field.indexOf(final)] = obj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      }
    } else {
      let field = fields;
      if (index === 1 || index > 1) {
        if (index === 1) {
          newIndex = 1;
        }
        if (index === 2) {
          newIndex = 3;
        }
        if (index === 3) {
          newIndex = 5;
        }
        if (index === 4) {
          newIndex = 7;
        }

        field[newIndex] = obj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      } else {
        let final = field.find(
          (elm) => elm.type === 'OPERATION' && elm.value === ''
        );
        field[field.indexOf(final)] = obj;
        dispatch(setExitConditions({ field, key: e.target.name }));
      }
    }
  };

  const handleEquationValue = () => {
    let equationData = [...equationValues];
    let marketArr = [...marketForm];
    let operandMarketValue = {
      arguements: [],
      conditionName: '',
      data: '',
      eqIndex: equationData.length + 1,
      type: 'OPERAND',
    };
    let operationMarketValue = {
      arguements: [],
      conditionName: '',
      data: '',
      eqIndex: equationData.length + 1,
      type: 'OPERATOR',
    };
    let operatorObj = {
      type: 'OPERATOR',
      enumName: '',
      value: '',
    };
    let operandObj = {
      type: 'OPERAND',
      enumName: '',
      value: '',
    };
    if (
      equationData[equationData.length - 1] &&
      (equationData[equationData.length - 1]['type'] === 'OPERATOR' ||
        equationData[equationData.length - 1]['value'] === 'OPEN' ||
        equationData[equationData.length - 1]['type'] === 'OPERATION')
    ) {
      marketArr = [...marketArr, operandMarketValue];
      equationData = [...equationData, operandObj];
    } else {
      marketArr = [...marketArr, operationMarketValue];
      equationData = [...equationData, operatorObj];
    }
    setMarketForm(marketArr);
    setEquationValues(equationData);
  };

  const equationHandler = (data, eqIndex) => {
    let newEquation = [...equationValues];
    if (data === 'REMOVE') {
      let obj = newEquation[eqIndex];
      newEquation[eqIndex] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };
    } else {
      if (!newEquation[eqIndex]) {
        newEquation[eqIndex] = data;
      } else {
        newEquation.splice(eqIndex, 1, data);
      }
    }
    setEquationValues(newEquation);
  };

  const handleEquationClose = (idx) => {
    let form = { ...strategyTemplateForm };
    let ind = '';
    let eqArgArr = [];
    if (idx === 1) {
      ind = 2;
    } else if (idx === 2) {
      ind = 4;
    } else if (idx === 3) {
      ind = 6;
    } else if (idx === 4) {
      ind = 8;
    } else {
      ind = idx;
    }
    if (
      form.exitCondition.condition[ind] &&
      Object.keys(form.exitCondition.condition[ind]).length > 2 &&
      form.exitCondition.condition[ind].trigger === 'MARKET_TRIGGERED'
    ) {
      let eqObj = form.exitCondition.condition[ind];
      for (let i = 0; i < eqObj.marketTriggered.length; i++) {
        if (
          eqObj.marketTriggered[i].data === 'PARAMETER' &&
          eqObj.marketTriggered[i].arguements.length &&
          eqObj.marketTriggered[i].arguements[0].argName.startsWith(
            'NEW_TEMPLATE'
          )
        ) {
          eqArgArr.push(eqObj.marketTriggered[i].arguements[0]);
        }
      }
      for (let k = 0; k < eqArgArr.length; k++) {
        form.constants = form.constants.filter(
          (el) => el.name !== eqArgArr[k].argValue
        );
      }
      dispatch(updateConditions(form));
    }
    setEquationValues([
      {
        type: 'OPERAND',
        enumName: '',
        value: '',
      },
    ]);
    setMarketForm([]);
  };

  const handleCondition = (trigger, key) => {
    if (trigger) {
      let fieldTriggerValue;
      let fieldTriggerArr = trigger.split('_');
      if (fieldTriggerArr.length === 3) {
        fieldTriggerValue =
          fieldTriggerArr[0].toLowerCase() +
          fieldTriggerArr[1].charAt(0) +
          fieldTriggerArr[1].slice(1).toLowerCase() +
          fieldTriggerArr[2].charAt(0) +
          fieldTriggerArr[2].slice(1).toLowerCase();
      } else {
        fieldTriggerValue =
          fieldTriggerArr[0].toLowerCase() +
          fieldTriggerArr[1].charAt(0) +
          fieldTriggerArr[1].slice(1).toLowerCase();
      }
      setFieldIndex(key);
      setFieldValue(fieldTriggerValue);
    }
  };

  return (
    <div>
      <div className="main">
        <p className="heading">Condition</p>
        <img src={info} className="info" />
      </div>
      {strategyTemplateForm.exitCondition.condition
        .map((elm, indx) => ({ ...elm, key: indx }))
        ?.filter((elm) => elm.type !== 'OPERATION')
        .map((field, idx) => {
          let firstTrigger = Object.keys(
            strategyTemplateForm?.exitCondition?.condition[0]
              ? strategyTemplateForm?.exitCondition?.condition[0]
              : {}
          )[2];
          let secondTrigger = Object.keys(
            strategyTemplateForm?.exitCondition?.condition[2]
              ? strategyTemplateForm?.exitCondition?.condition[2]
              : {}
          )[2];
          let thirdTrigger = Object.keys(
            strategyTemplateForm?.exitCondition?.condition[4]
              ? strategyTemplateForm?.exitCondition?.condition[4]
              : {}
          )[2];
          let fourthTrigger = Object.keys(
            strategyTemplateForm?.exitCondition?.condition[6]
              ? strategyTemplateForm?.exitCondition?.condition[6]
              : {}
          )[2];
          let triggerArr = [
            firstTrigger,
            secondTrigger,
            thirdTrigger,
            fourthTrigger,
          ].filter((el) => el);

          let newExitConditions = [];
          newExitConditions = exitConditions.map((el) => {
            let preSelected = false;
            triggerArr.includes(el.key)
              ? (preSelected = true)
              : (preSelected = false);
            return { ...el, preSelected };
          });
          return (
            <React.Fragment>
              <div
                className={
                  isApproveReject
                    ? `approveReject-pointer App exitCondition ${field.trigger}-${idx}`
                    : `App exitCondition ${field.trigger}-${idx}`
                }
                onClick={() => handleCondition(field.trigger, idx)}
              >
                <div className="inputs">
                  {idx > 0 ? (
                    <div className="ops">
                      <Dropdowns
                        single
                        role="condition"
                        id="exitCondition"
                        listData={operation?.map((el, indx) => {
                          return {
                            id: el.enumName.trim(),
                            label: el.displayName.trim(),
                          };
                        })}
                        value={
                          strategyTemplateForm.exitCondition.condition[
                            field.key - 1
                          ]?.value
                        }
                        handlerFunction={operationHandler}
                        idx={idx}
                      />
                    </div>
                  ) : null}
                  {idx === 0 ||
                  strategyTemplateForm.exitCondition.condition[field.key - 1]
                    ?.value ? (
                    <div
                      className={
                        isApproveReject
                          ? 'approveReject-pointer first'
                          : 'first'
                      }
                    >
                      <Dropdowns
                        single
                        role="condition"
                        listData={newExitConditions?.map((el, indx) => {
                          return {
                            id: el.key.trim(),
                            label: el.data.displayName.trim(),
                            preSelected: el.preSelected,
                          };
                        })}
                        handlerFunction={inputHandler}
                        value={Object.keys(field)[2]}
                        idx={idx}
                      />
                    </div>
                  ) : null}

                  <div className="sepratewithcross">
                    {field.trigger === 'MARKET_TRIGGERED' ? (
                      <div
                        className={
                          validator.current.message(
                            'marketTriggrredCondition',
                            strategyTemplateForm.exitCondition.condition[
                              field.key
                            ] &&
                              strategyTemplateForm.exitCondition.condition[
                                field.key
                              ].marketTriggered,
                            'required|min:1,array'
                          ) && formSubmit
                            ? 'error-input sepratewithcross'
                            : isApproveReject
                            ? 'approveReject-pointer'
                            : 'sepratewithcross'
                        }
                      >
                        <NewDropdown
                          listData={marketCondition}
                          marketForm={marketForm}
                          editFlagIndex={editFlagIndex}
                          setEditFlagIndex={setEditFlagIndex}
                          setMarketForm={setMarketForm}
                          marketHandler={marketHandler}
                          setInputData={setInputData}
                          setSelectedCondition={setSelectedCondition}
                          placeHolder={'Add Indicator,Number,Bracket'}
                          setEquationValues={equationHandler}
                          componentKey={'exitCondition'}
                        />
                      </div>
                    ) : null}
                    {field.trigger === 'TIME_TRIGGERED_EXIT' &&
                    exitConditions.length ? (
                      <div className="condition">
                        <StaticCondition
                          formInputHandler={timeHandler}
                          className="finalValueShow"
                          inputString={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.inputString
                          }
                          args={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.args
                          }
                          inputObject={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.inputObject
                          }
                          selectedTrigger={field.trigger}
                          fieldName={Object.keys(field)[2]}
                          setStartTime={setStartTime}
                          startTime={startTime}
                          setEndtime={setEndtime}
                          endTime={endTime}
                          idx={idx}
                          selectedValueObj={field.timeTriggeredExit}
                          exitCond={true}
                        />
                      </div>
                    ) : null}
                    {field.trigger === 'TRAILING_STOP_LOSS' &&
                    exitConditions.length ? (
                      <div
                        className={
                          validator.current.message(
                            'trailingStopLossConditionActivateAt',
                            strategyTemplateForm.exitCondition.condition[
                              field.key
                            ].trailingStopLoss?.activateAt,
                            'required'
                          ) &&
                          validator.current.message(
                            'trailingStopLossConditionProfitIncrease',
                            strategyTemplateForm.exitCondition.condition[
                              field.key
                            ].trailingStopLoss?.profitIncrease,
                            'required'
                          ) &&
                          validator.current.message(
                            'trailingStopLossConditionTsl',
                            strategyTemplateForm.exitCondition.condition[
                              field.key
                            ].trailingStopLoss?.tsl,
                            'required'
                          ) &&
                          formSubmit
                            ? 'error-input condition'
                            : 'condition'
                        }
                      >
                        <StaticCondition
                          formInputHandler={trailingHandler}
                          className="finalValueTrailing"
                          inputString={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.inputString
                          }
                          args={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.args
                          }
                          inputObject={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.inputObject
                          }
                          selectedTrigger={field.trigger}
                          fieldName={Object.keys(field)[2]}
                          idx={idx}
                          selectedValueObj={field.trailingStopLoss}
                        />
                      </div>
                    ) : null}
                    {field.trigger === 'TARGET_PROFIT' &&
                    exitConditions.length ? (
                      <div
                        className={
                          validator.current.message(
                            'targetProfit',
                            strategyTemplateForm.exitCondition.condition[
                              field.key
                            ].targetProfit?.targetProfit,
                            'required'
                          ) && formSubmit
                            ? 'error-input condition'
                            : 'condition'
                        }
                      >
                        <StaticCondition
                          formInputHandler={targetHandler}
                          className="finalValueProfit"
                          inputString={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.inputString
                          }
                          args={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.args
                          }
                          inputObject={
                            exitConditions.find(
                              (el) => el.data.enumName === field.trigger
                            ).data.inputObject
                          }
                          selectedTrigger={field.trigger}
                          fieldName={Object.keys(field)[2]}
                          idx={idx}
                          selectedValueObj={field.targetProfit}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="circle">
                    {strategyTemplateForm.exitCondition.condition.length > 1 ||
                    Object.keys(
                      strategyTemplateForm?.exitCondition?.condition[0]
                        ? strategyTemplateForm?.exitCondition?.condition[0]
                        : {}
                    ).length > 2 ? (
                      <div
                        className="closeEnty"
                        onMouseEnter={() =>
                          setDeleteHover({ ...deleteHover, [idx]: true })
                        }
                        onMouseLeave={() =>
                          setDeleteHover({ ...deleteHover, [idx]: false })
                        }
                      >
                        <img
                          src={
                            Object.hasOwn(deleteHover, idx) &&
                            deleteHover[idx] == true
                              ? DeleteHoverIcon
                              : DeleteIcon
                          }
                          alt="close"
                          onClick={() => handleRemove(idx, field)}
                          className="cross_icon"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}

      <div className={isApproveReject ? 'approveReject-pointer add' : 'add'}>
        {strategyTemplateForm.exitCondition.condition.length > 6 ? null : (
          <GenButton
            icon={AddCircleIcon}
            title="Add More"
            clickAction={() => handleAdd()}
            extStyle={{
              back: {
                boxShadow: 'inset 1px 3px 4px #90BFED46, 3px 4px 6px #ACC9E545',
                border: '1px solid #A7C5DB67',
                borderRadius: '20px',
                margin: '0.3vh 0 2.5vh 0',
              },
              text: {
                fontWeight: '550',
                fontSize: '1.9vh',
                color: '#000',
              },
              icon: { width: '2vh', height: '2vh', marginRight: '0.8vh' },
            }}
            disabled={
              Object.keys(
                strategyTemplateForm?.exitCondition?.condition[
                  strategyTemplateForm?.exitCondition?.condition?.length - 1
                ]
                  ? strategyTemplateForm?.exitCondition?.condition[
                      strategyTemplateForm?.exitCondition?.condition?.length - 1
                    ]
                  : {}
              ).length < 3
            }
          />
        )}
      </div>
      {error ? (
        <SimpleSnackbar
          toaster={error}
          setToaster={setError}
          message={'Time selected is not correct'}
        />
      ) : null}
    </div>
  );
};

export default Condition;

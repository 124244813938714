import React from 'react';
import { Form } from 'react-bootstrap';

export default function UiSelector({
  id,
  idx,
  disable,
  handlerFunction,
  multiple,
  predefineLabel,
  drop,
  isActive,
  listData,
  flagForNoData,
  role,
  className,
  errorInCondition,
  value,
  selectedData,
  selectedName,
  single,
  selectIds,
  optionName,
}) {
  const disableClass = disable ? 'disabled' : '';

  function handleMultipleCheckEnter(e, i) {
    e.stopPropagation();
    var keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode === '13') {
      e.target.checked = !e.target.checked;
      handlerFunction(e, i);
    }
  }

  function multipleComp() {
    return (
      <>
        <span className="anchor" id={id || ''}>
          {predefineLabel || 'Select'}
        </span>
        <ul
          ref={drop}
          className={`toogledropdown items z-in ${
            isActive ? 'active' : 'inactive'
          }`}
        >
          {listData &&
            listData?.map((i) => (
              <>
                <li
                  key={`${i.id}  "mulitple"`}
                  onChange={(e) => handlerFunction(e, i)}
                >
                  <input
                    tabIndex="0"
                    type="checkbox"
                    value={i.id}
                    defaultChecked={selectIds?.includes(i.id)}
                    onKeyPress={(e) => handleMultipleCheckEnter(e, i)}
                  />
                  <span>{i.label} </span>
                </li>
              </>
            ))}
          {flagForNoData ? (
            <li className="error-tag"> no account available</li>
          ) : (
            ''
          )}
        </ul>
      </>
    );
  }

  function singleComp() {
    return (
      <Form.Control
        as="select"
        name={role}
        id={id || ''}
        className={`UiSelector-label ${className} ${disableClass}`}
        style={{
          borderRadius: '4px',
          border: errorInCondition
            ? errorInCondition.includes(value)
              ? '1px solid red'
              : ''
            : '',
        }}
        disabled={disable ? 'disabled' : ''}
        onChange={(e) => handlerFunction(e, idx)}
        onMouseDown={(e) => (disable ? e.preventDefault() : null)}
      >
        {selectedData && selectedName ? (
          <option value={selectedData}>{selectedName}</option>
        ) : (
          <option value="">{optionName ? optionName : 'Select'}</option>
        )}
        {single &&
          listData &&
          listData.map((i) => (
            <option
              key={`${i.id}"single"`}
              selected={value === i.id}
              value={i.id}
              disabled={i?.preSelected ? i?.preSelected : false}
            >
              {i.label}
            </option>
          ))}

        {value && (listData?.length === 0 || !listData) ? (
          <option selected>
            {value === 'Invalid date' ? 'Select' : value}
          </option>
        ) : (
          ''
        )}
      </Form.Control>
    );
  }

  return multiple ? multipleComp() : singleComp();
}

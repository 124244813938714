import axios from 'axios';
import { url } from '../config/apiConfig';
import _ from 'lodash';
import { CANCEL_ALL_PENDING } from './types';
import { MakeRequest } from '../utils';
// strategyAnalyzer table
export const strategyAnalyzerTable = () => async (dispatch, getState) => {
  let { strategyTableParam } = getState().strategyAnalyzerReducer;
  dispatch({
    type: 'STRATEGY_TABLE_LOADED',
    payload: false,
  });
  try {
    const result = {};
    // const result = await MakeRequest('/strategyAnalyzer/table').post({
    //   pageNumber: strategyTableParam.pageNumber,
    //   entriesPerPage: strategyTableParam.postPerPage,
    //   statusList: strategyTableParam.statusList,
    // });
    if (result) {
      if (result.data.data) {
        for (let i = 0; i < result.data.data.analyzerObject.length; i++) {
          let status = '';
          try {
            status = result.data.data.analyzerObject[i]?.data.status.status
              ? result.data.data.analyzerObject[i]?.data.status.status
              : '';
          } catch (error) {
            status = '';
            console.log(error);
          }

          result.data.data.analyzerObject[i]['status'] = status;
        }
        dispatch({
          type: 'STRATEGY_TABLE',
          payload: result.data.data,
        });
      } else {
        dispatch({
          type: 'STRATEGY_TABLE',
          payload: '',
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'STRATEGY_TABLE',
      payload: [],
    });
  }
  dispatch({
    type: 'STRATEGY_TABLE_LOADED',
    payload: true,
  });
};

//strategyAnalyzer button
export const strategyAnalyzerButton =
  (buttonData) => async (dispatch, getState) => {
    const { strategyTable } = getState().strategyAnalyzerReducer;
    const analyzer = strategyTable.analyzerObject;
    dispatch({
      type: 'STRATEGY_ANALYZER_BUTTON_LOADER',
      payload: true,
    });
    const token = localStorage.getItem('token');
    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/strategyAnalyzer/button`,
        data: buttonData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        if (
          result.data.data &&
          (typeof Object.values(result.data.data)[0] == 'string' ? false : true)
        ) {
          for (let i = 0; i < buttonData.strategyList.length; i++) {
            let status = '';
            try {
              const statusResult = await axios({
                method: 'GET',
                url: `${url}/strategyAnalyzer/state/${buttonData.strategyList[i]}`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              status = statusResult ? statusResult.data.data.status : '';
            } catch (error) {
              console.log(error);
            }
            if (analyzer) {
              let stratObj = analyzer.findIndex(
                (el) => el.data.id === buttonData.strategyList[i]
              );
              analyzer[stratObj].status = status;
            }
          }
          dispatch({
            type: 'STRATEGY_TABLE',
            payload: { ...strategyTable, analyzerObject: analyzer },
          });
        }
        if (result.status != 200)
          dispatch({
            type: 'STRATEGY_BUTTON',
            payload: true,
            strategyId: result.data.data,
          });
      }
      dispatch({
        type: 'STRATEGY_ANALYZER_BUTTON_LOADER',
        payload: false,
      });
      return result.data.data;
    } catch (err) {
      dispatch({
        type: 'STRATEGY_ANALYZER_BUTTON_LOADER',
        payload: false,
      });
      if (err.response && err.response.status != 200) {
        try {
          dispatch({
            type: 'STRATEGY_BUTTON',
            errorMessage: err.response.data.error.message,
          });
        } catch (error) {
          console.log(error);
        }
      }

      return {
        message:
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'Getting Some Server Error',
        status: 'FAILED',
      };
    }
  };

//strategyAnalyzer search
export const strategyAnalyzerSearch = (data) => async (dispatch, getState) => {
  let { strategyTableParam } = getState().strategyAnalyzerReducer;
  if (data && data.field && data.field.id) data.field = data.field.id;
  data.statusList = strategyTableParam.statusList;
  const token = localStorage.getItem('token');
  dispatch({
    type: 'STRATEGY_TABLE_LOADED',
    payload: false,
  });

  dispatch({
    type: 'STRATEGY_SEARCHBY_SEARCHSTRING',
    payload: data,
  });
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategyAnalyzer/search`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { ...data, tabId: 123 },
    });
    if (result) {
      if (result.data.data) {
        for (let i = 0; i < result.data.data.analyzerObject.length; i++) {
          let status = '';
          try {
            // const statusResult = await axios({
            //   method: 'GET',
            //   url: `${url}/strategyAnalyzer/state/${result.data.data.analyzerObject[i]?.data.id}`,
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // });
            // status = statusResult ? statusResult.data.data.status : '';
            status = result.data.data.analyzerObject[i]?.data.status.status
              ? result.data.data.analyzerObject[i]?.data.status.status
              : '';
          } catch (error) {
            status = '';
            console.log(error);
          }

          result.data.data.analyzerObject[i]['status'] = status;
        }
        dispatch({
          type: 'STRATEGY_TABLE',
          payload: result.data.data,
        });
      } else {
        dispatch({
          type: 'STRATEGY_TABLE',
          payload: '',
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'STRATEGY_TABLE',
      payload: [],
    });
  }
  dispatch({
    type: 'STRATEGY_TABLE_LOADED',
    payload: true,
  });
};

//action for updating the column while sorting
export const updateSortCulmnAnalyzer = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_SORT_COLUMN_ANALYZER',
    payload: data,
  });
};

//updating the posts per page
export const updatePostPerPage = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_POST_PER_PAGE_SA',
    payload: data,
  });
};

export const orderbook_UPPP = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_POST_PER_PAGE_OB',
    payload: data,
  });
};

//edit in strategy analyzer
export const strategyAnalyzerEdit = (strategyId) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/strategy/return/${strategyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      if (result.data.data) {
        let finalObj = { ...result.data.data };
        dispatch({
          type: 'STRATEGY_EDIT',
          payload: finalObj,
          ID: finalObj,
        });
      } else {
        dispatch({
          type: 'STRATEGY_EDIT',
          import: '',
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'STRATEGY_EDIT',
      payload: {},
    });
  }
};

//manual order create in strategy analyzer
export const strategyAnalyzerManualOrder = (orderId) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/manualOrder/strategyObject/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      if (result.data.data) {
        let manualOrderForm = {
          exchange: result.data.data.exchange,
          tradingAccountId: result.data.data.tradingAccountData.id,
          strategyId: result.data.data.strategyId,
          instrument: '',
          symbol: '',
          expiry: '',
          strike: '',
          direction: '',
          orderType: '',
          quantity: '',
        };
        let callOptionArr = [];
        let putOptionArr = [];
        let futureArr = [];
        let equityArr = [];

        let instrumentArr = [];
        result.data.data.security.map((i) => {
          instrumentArr.push({
            key: i.instrument,
            name:
              i.instrument === 'Call_Option'
                ? 'CE'
                : i.instrument === 'Put_Option'
                ? 'PE'
                : i.instrument,
          });
          if (i.instrument === 'Call_Option') {
            callOptionArr.push(i.securityName);
          }
          if (i.instrument === 'Put_Option') {
            putOptionArr.push(i.securityName);
          }
          if (i.instrument === 'Future') {
            futureArr.push(i.securityName);
          }
          if (i.instrument === 'Equity') {
            equityArr.push(i.securityName);
          }
        });
        let symbolArr = [];
        result.data.data.security.map((i) => {
          symbolArr.push(i.securityName);
        });
        let expiryArr = [];
        result.data.data.security.map((i) => {
          expiryArr.push({ expiry: i.expiry });
        });
        let strikeArr = [];
        result.data.data.security.map((i) => {
          strikeArr.push(i.strike);
        });

        //setting manualOrderStrategy
        dispatch({
          type: 'STRATEGY_MANUAL_ORDER',
          payload: result.data.data,
          instruments: [...new _.uniqBy(instrumentArr, 'key')],
          symbol: [...new Set(symbolArr)],
          expires: expiryArr,
          strikes: strikeArr,
        });
        // setting manualOrder form data
        dispatch({
          type: 'UPDATE_MANUAL_ORDER_FORM',
          payload: manualOrderForm,
        });
      } else {
        dispatch({
          type: 'STRATEGY_MANUAL_ORDER',
          payload: null,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'STRATEGY_MANUAL_ORDER',
      payload: null,
    });
  }
};

export const updatePageNumber = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_PAGENUMBER',
    payload: data,
  });
};
export const updateSearchBy = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_SEARCHBY',
    payload: data,
  });
};
export const updateSearchString = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_SEARCH_STRING',
    payload: data,
  });
};

// set values in manual-order form
export const setManualOrderForm = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_MANUAL_ORDER_FORM',
    payload: data,
  });
};

// get strategy rule ID for the given trading account
export const getStrategyRuleId = (tradingAccountId) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/manualOrder/return/manualStrategy/${tradingAccountId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (result) {
      if (result.data && result.data.data && Array.isArray(result.data.data)) {
        dispatch({
          type: 'GET_MO_STRATEGY_RULE_ID',
          payload: result.data.data.map((item) => ({
            key: item.id,
            name: item.name,
            accountId: item.tradingAccountId,
          })),
        });
      } else {
        dispatch({
          type: 'GET_MO_STRATEGY_RULE_ID',
          payload: [
            {
              key: result.data.data.id,
              name: result.data.data.name,
              accountId: result.data.data.tradingAccountId,
            },
          ],
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'GET_MO_STRATEGY_RULE_ID',
      payload: [],
    });
  }
};

// get securityname dropdownData
export const getManualOrderSecurityNameOptions =
  (instrument) => async (dispatch, getState) => {
    let { manualOrderForm } = getState().strategyAnalyzerReducer;
    let form = { ...manualOrderForm };
    const token = localStorage.getItem('token');
    let selectedSymbol = '';
    if (manualOrderForm.symbol) {
      selectedSymbol = manualOrderForm.symbol;
      form.instrument = instrument;
      form.symbol = '';
      form.expiry = '';
      form.strike = '';
    } else {
      form.instrument = instrument;
      form.symbol = '';
      form.expiry = '';
      form.strike = '';
    }

    dispatch({
      type: 'UPDATE_MANUAL_ORDER_FORM',
      payload: form,
    });

    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/securities`,
        params: {
          exchange: form.exchange.trim(),
          instrument: instrument,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'GET_MO_SECURITY_NAME',
          payload: result.data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_MO_SECURITY_NAME',
        payload: [],
      });
    }
  };

// get expiry dropdownData
export const getManualOrderExpiresOptions =
  (symbol) => async (dispatch, getState) => {
    let { manualOrderForm } = getState().strategyAnalyzerReducer;
    let form = { ...manualOrderForm };
    const token = localStorage.getItem('token');
    form.symbol = symbol;
    form.expiry = '';
    form.strike = '';
    dispatch({
      type: 'UPDATE_MANUAL_ORDER_FORM',
      payload: form,
    });
    if (manualOrderForm.instrument !== 'Equity') {
      try {
        const result = await axios({
          method: 'GET',
          url: `${url}/security/expires`,
          params: {
            exchange: form.exchange.trim(),
            instrument: form.instrument,
            security: symbol,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (result) {
          dispatch({
            type: 'GET_MO_EXPIRES',
            payload: result.data.data,
          });
          dispatch(
            getManualOrderStrikesOptions({
              expiry: result.data.data[0] ? result.data.data[0].expiry : '',
            })
          );
        }
      } catch (error) {
        dispatch({
          type: 'GET_MO_EXPIRES',
          payload: [],
        });
      }
    }
  };

// get strike dropdownData
export const getManualOrderStrikesOptions =
  (data) => async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    let { manualOrderForm } = getState().strategyAnalyzerReducer;
    let form = { ...manualOrderForm };
    form.expiry = data.expiry;
    form.strike = '';
    dispatch({
      type: 'UPDATE_MANUAL_ORDER_FORM',
      payload: form,
    });
    if (manualOrderForm.instrument !== 'Future') {
      try {
        const result = await axios({
          method: 'GET',
          url: `${url}/security/strikes`,
          params: {
            exchange: form.exchange.trim(),
            instrument: form.instrument,
            security: form.symbol,
            expiry: data.expiry,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (result) {
          dispatch({
            type: 'GET_MO_STRIKES',
            payload: result.data.data,
          });
          form.strike = result.data.data[0]
            ? result.data.data[0].toString()
            : '';
          dispatch({
            type: 'UPDATE_MANUAL_ORDER_FORM',
            payload: form,
          });
        }
      } catch (error) {
        dispatch({
          type: 'GET_MO_STRIKES',
          payload: [],
        });
      }
    }
  };
//edit submit
export const editSubmit = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  const token = localStorage.getItem('token');
  const customerId = localStorage.getItem('customerId');
  templateForm['strategyTemplateId'] = parseInt(data.strategyTemplateId);
  templateForm['id'] = parseInt(data.id);
  templateForm['userId'] = parseInt(customerId);
  let newLegs = templateForm.entryLegs.map((el) => {
    return {
      ...el,
      position: el.position.map((i) => {
        return {
          ...i,
          pricing: i.pricing.map((elm) => {
            return {
              data: elm.data,
              type: elm.type,
              arguements: elm.arguements,
            };
          }),
        };
      }),
    };
  });
  let newExitCondition = templateForm.exitCondition;

  // check if exit condition is not specified
  if (!templateForm.exitCondition?.action?.enumName) {
    newExitCondition['action'] = null;
  }
  if (
    templateForm.exitCondition?.condition &&
    templateForm.exitCondition?.condition?.length &&
    !templateForm.exitCondition?.condition[0].trigger
  ) {
    newExitCondition['condition'] = null;
  }

  templateForm['entryLegs'] = newLegs;
  templateForm['exitCondition'] = newExitCondition;
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategy/modify`,
      data: templateForm,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      if (result.data.data) {
        dispatch({
          type: 'EDIT_STRATEGY',
          payload: result.data.data,
          ID: result.data.data.id,
        });
        return {
          message: result.data.data.message,
          status: 'success',
        };
      } else {
        return {
          message: 'Getting Some Server Error. Please Try Again ',
          status: 'success',
        };
      }
    }
  } catch (err) {
    dispatch({
      type: 'EDIT_STRATEGY',
      payload: [],
    });
    return {
      message: 'Getting some server error. Please Try Again ',
      status: 'error',
    };
  }
};

// clear manual order
export const resetManualOrder = (data) => async (dispatch) => {
  dispatch({
    type: 'RESET_MANUAL_ORDER',
    payload: data,
  });
};
export const updateStatusList = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_STATUS_LIST',
    payload: data,
  });
};

export const submitManualOrder = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  const { manualOrderForm } = getState().strategyAnalyzerReducer;
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/manualOrder/place`,
      data: manualOrderForm,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      return { message: result.data.data.message, status: 'SUCCESS' };
    }
  } catch (err) {
    return {
      message:
        err.response.data.data && err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting Some Server Error',
      status: 'FAILED',
    };
  }
};

export const editManualOrder =
  (manualOrderId) => async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    let { manualOrderForm } = getState().strategyAnalyzerReducer;
    manualOrderForm.previousOrderId = manualOrderId;
    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/manualOrder/edit`,
        data: manualOrderForm,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        return {
          status: 200,
          message: result.data.data,
        };
      }

      return {
        status: 400,
        message: 'No response from server',
      };
    } catch (err) {
      return {
        status: 400,
        message:
          err &&
          err.response &&
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.message
            ? err.response.data.data.message
            : 'Server Error',
      };
    }
  };

export const getSqOff = () => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  let newsqOff = [];
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/strategy/pricingFunction`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        methodCallLocation: 'STRATEGY_ANALYZER',
      },
    });
    if (result) {
      newsqOff = result.data.data.indicators.map((i) => {
        return {
          type: 'sqOff',
          name: i.displayName,
          enumName: i.enumName,
          data: [],
          args: i.args,
        };
      });
      dispatch({
        type: 'GET_SQUARE_OFF',
        payload: newsqOff,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_SQUARE_OFF',
      payload: [],
    });
  }
};
export const sqOffDetails = (data) => async (dispatch) => {
  dispatch({
    type: 'SQ_DETAILS',
    payload: data,
  });
};
export const createCustomSecurities =
  (type, value) => async (dispatch, getState) => {
    const { manualOrderStrategy, manualOrderForm } =
      getState().strategyAnalyzerReducer;
    let security = manualOrderStrategy.security;
    let form = { ...manualOrderForm };
    let payload = [];

    if (type === 'INSTRUMENT') {
      payload = [
        ...new Set(
          security &&
            security
              .map((el) => (el.instrument === value ? el.securityName : ''))
              .filter((elm) => elm)
        ),
      ];
      dispatch({
        type: 'CREATE_SYMBOL_DROPDOWN',
        payload,
      });
    } else if (type === 'SYMBOL') {
      payload = [
        ...new Map(
          security &&
            security
              .map((el) =>
                el.securityName === value && el.expiry !== ''
                  ? { expiry: el.expiry }
                  : ''
              )
              .filter((elm) => elm)
              .map((item) => [item[item.expiry], item])
        ).values(),
      ];
      dispatch({
        type: 'CREATE_EXPIRY_DROPDOWN',
        payload,
      });
      form.expiry = payload[0] ? payload[0].expiry : '';
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: form,
      });
      payload = [
        ...new Set(
          security &&
            security
              .map((el) =>
                el.expiry === form.expiry ? parseInt(el.strike) : ''
              )
              .filter((elm) => elm)
        ),
      ];
      dispatch({
        type: 'CREATE_STRIKE_DROPDOWN',
        payload,
      });
      form.strike = payload[0] ? payload[0] : '';
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: form,
      });
    } else if (type === 'EXPIRY') {
      payload = [
        ...new Set(
          security &&
            security
              .map((el) => (el.expiry === value ? parseInt(el.strike) : ''))
              .filter((elm) => elm)
        ),
      ];
      dispatch({
        type: 'CREATE_STRIKE_DROPDOWN',
        payload,
      });
      form.expiry = value;
      form.strike = payload[0] ? payload[0] : '';
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: form,
      });
    }
  };

export const cancelAll = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/orders/cancelAllPending`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: CANCEL_ALL_PENDING,
        payload: result.data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CANCEL_ALL_PENDING,
      payload: [],
    });
  }
};

import { Outlet } from 'react-router-dom';
import './styles.css';

export default function MultScreenContainer() {
  return (
    <div className="multiScreenContainer">
      <Outlet />
    </div>
  );
}

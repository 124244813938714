const initialState = {
  data: [],
  strategyid: [],
  mapStrategy: [],
  userlist: [],
  ID: '',
  createteam: false,
  delete: false,
  teamData: {
    strategyGroups: [],
    teamName: '',
    teamDescription: '',
    strategyTemplates: [],
    customers: [],
  },
  isTeamEdit:false,
};

const teamDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERDATA':
      return {
        ...state,
        data: action.payload,
      };
    case 'STRATEGY_ID':
      return {
        ...state,
        strategyid: action.payload,
      };
    case 'MAP_STRATEGY':
      return {
        ...state,
        mapStrategy: action.payload,
      };
    case 'USER_LIST':
      return {
        ...state,
        userlist: action.payload,
      };
    case 'CREATE_TEAM':
      return {
        ...state,
        createteam: action.payload,
        ID: action.ID,
      };
    case 'TEAM_DELETE': {
      const filterData = state.data.filter(
        (item) => item.id !== action.payload.userId
      );
      return {
        ...state,
        delete: action.payload,
        data: filterData,
      };
    }
    case 'CLEAR_MESSAGE':
      return {
        ...state,
        message: action.clearMessage,
        ID: action.clearId,
      };

    case 'CLEAR_USER_DELETE':
      return {
        ...state,
        delete: action.clearMessage,
      };
      case 'TEAM_DATA_UPDATE':
          return{
              ...state,
              teamData:action.payload
          }
          case "UPDATE_TEAM":{
         
            return {
              ...state,
              teamData: action.payload
            }
           }
          case "UPDATE_TEAM_EDIT":{
            return {
              ...state,
              isTeamEdit: action.payload
            }
           }
           case "AFTER_TEAM_DATA_UPDATE":{
            return {
              ...state,
              isTeamEdit: action.payload
            }
           }
           
    default:
      return state;
  }
};

export default teamDashboardReducer;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TableComp from '../../sharedComponents/Table/Table';
import { netPosition } from '../../../actions/LiveTradingAction';
import './NetPosition.scss';
import '../../sharedComponents/GenTable/tableStyledComps';
import FileUploadComponent from '../../../components/FileUpload/FileUpload';
import ExcelPrevPopup from '../../../components/sharedComponents/customComponent/excelPrevPopup';
import { FeedbackPopup, GenSearchBox, GenButton } from '../../../components';
import { TableSettings } from '../../../components/sharedComponents/customComponent';
import refresh from '../../../assets/img/refresh.png';
import exicon from '../../../assets/img/ex-icon.png';
import moment from 'moment';
import { MakeRequest } from '../../../utils';
import { ColumnArrangeIcon, RefreshIcon } from '../../../assets';
import { TableOrderChangeActions } from '../../../reducers/table_order_reducer';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { POSITION_TYPE } from '../../../utils/constant';

const NetPosition = (props) => {
  const { editAllowed } = props;
  const {
    liveTradingReducer: { netPositionData, netPositionLoaded },
  } = useSelector((state) => state);

  const [searchAcc, setSearchAcc] = useState('');
  const [pageNum, setPageNum] = useState(1);
  var [lazyLoadWait, setLazyLoadWait] = useState(false); //? To Remove: Used for Eralier DB version Net Position not required now
  const numEntries = 50;
  const [positionPrev, setPositionPrev] = useState();
  const [positionPrevFeedBack, setPositionPrevFeedback] = useState('');
  const [positionUpdate, setPositionUpdate] = useState({});
  const [netPositionShow, setNetPositionShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [aggregatedValues, setAggregatedValues] = useState({
    AccountId: { data: null },
    ExchangeName: {
      data: null,
    },
    UserId: {
      data: null,
    },
    Segment: {
      data: null,
    },
    updateTime: {
      data: null,
    },
    Account_Name: {
      data: 'Total',
    },
    MarketType: {
      data: null,
    },
    SecurityName: {
      data: null,
    },
    ExpiryDate: {
      data: null,
    },
    StrikePrice: {
      data: null,
    },
    OptionType: {
      data: null,
    },
    netPosition: {
      className: '',
      data: 0,
    },
    mtmValue: {
      className: '',
      data: 0,
    },
    netValue: {
      className: '',
      data: 0,
    },
    buyQtyInLots: {
      className: '',
      data: 0,
    },

    buyQty: {
      className: '',
      data: 0,
    },
    buyValue: {
      className: '',
      data: 0,
    },
    buyAveragePrice: {
      className: '',
      data: 0,
    },
    sellAveragePrice: {
      className: '',
      data: 0,
    },
    ltp: {
      className: '',
      data: 0,
    },
    lotSize: {
      className: '',
      data: 0,
    },
    sellQtyInLots: {
      className: '',
      data: 0,
    },
    sellQty: {
      data: 0,
    },
    sellValue: {
      data: 0,
    },
  });

  const dispatch = useDispatch();
  const [client, setClient] = useState();
  const [errorState, setErrorState] = useState({
    message: '',
    data: {},
    dataSec: {},
  });
  const [positionType, setPositionType] = useState(POSITION_TYPE.TOTAL);

  useEffect(() => {
    setIsLoading(true);
    dispatch(netPosition());

    setIsLoading(false);
    TableOrderChangeActions.setNetPositionInitData(dispatch);
  }, []);

  useEffect(() => {
    updateNetPositionData(positionUpdate);
  }, [positionUpdate]);

  // NetPosition Data update based on netPositionData[positionType] change [Through API Call]
  useEffect(() => {
    refreshNetPosition();
  }, [netPositionData]);

  // NetPosition Data update based on pageNum Change
  useEffect(() => {
    refreshNetPosition();
  }, [pageNum]);

  // NetPosition Data update based on search string Change
  useEffect(() => {
    refreshNetPosition();
  }, [searchAcc]);

  // Position type switching also refreshes position | at ui cached level
  useEffect(() => {
    refreshNetPosition();
  }, [positionType]);

  // Function to update NetPositionData from web sockets live updates
  const updateNetPositionData = async (updPositionMap) => {
    // Realtime update removed
    return;
  };

  async function callBackForPopupPrev(action = { action: 0, data: {} }) {
    switch (action.action) {
      case 'SUB':
        const res = await submitExcelprev(action.data);
        if (res) {
          if (res.status === 500) {
            setPositionPrev();
            setShow(false);
            setPositionPrevFeedback(res.data.error.message);
          } else if (res.status === 400) {
            setPositionPrev();
            setShow(false);
            setErrorState({
              message: res.data.data.message,
              data: res.data.data.data,
              dataSec: res.data.data.dataSec,
            });
          } else {
            setPositionPrev();
            setShow(false);
            setErrorState({ message: '', data: {} });
            setPositionPrevFeedback(res.data.data.message);
          }
        }
        break;
      case 'EDT':
        setShow(true);
        setPositionPrev();
        break;
      case 'CAN':
        setPositionPrev();
        setShow(false);
        break;
      default:
        break;
    }
  }

  async function submitExcelprev(data) {
    try {
      const res = await MakeRequest('/excel/upload/positionSubmit').post({
        submitData: data,
      });
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  // Function to refresh the DB table
  function refreshNetPosition() {
    let netPositionTempData = [];
    if (
      netPositionData[positionType] &&
      Array.isArray(netPositionData[positionType])
    ) {
      let netPositionAggregatedValues = {
        AccountId: { data: null },
        ExchangeName: {
          data: null,
        },
        UserId: {
          data: null,
        },
        Segment: {
          data: null,
        },
        updateTime: {
          data: null,
        },
        Account_Name: {
          data: 'Total',
        },
        MarketType: {
          data: null,
        },
        SecurityName: {
          data: null,
        },
        ExpiryDate: {
          data: null,
        },
        StrikePrice: {
          data: null,
        },
        OptionType: {
          data: null,
        },
        netPosition: {
          className: '',
          data: 0,
        },
        mtmValue: {
          className: '',
          data: 0,
        },
        netValue: {
          className: '',
          data: 0,
        },
        buyQtyInLots: {
          className: '',
          data: 0,
        },

        buyQty: {
          className: '',
          data: 0,
        },
        buyValue: {
          className: '',
          data: 0,
        },
        buyAveragePrice: {
          className: '',
          data: 0,
        },
        sellAveragePrice: {
          className: '',
          data: 0,
        },
        ltp: {
          className: '',
          data: 0,
        },
        lotSize: {
          className: '',
          data: 0,
        },
        sellQtyInLots: {
          className: '',
          data: 0,
        },
        sellQty: {
          data: 0,
        },
        sellValue: {
          data: 0,
        },
      };

      // Calculate aggregated value and apply filtering based on searchString, pagenumber, etc here
      for (
        let posIdx = 0;
        searchAcc !== ''
          ? netPositionTempData.length < pageNum * numEntries &&
            posIdx < netPositionData[positionType].length
          : posIdx < pageNum * numEntries &&
            posIdx < netPositionData[positionType].length;
        posIdx++
      ) {
        if (searchAcc === '') {
          // netPositionAggregatedValues.netPosition.data += parseInt(
          //   netPositionData[positionType][posIdx].netPosition
          // );
          netPositionAggregatedValues.mtmValue.data += parseFloat(
            netPositionData[positionType][posIdx].mtmValue
          );
          netPositionAggregatedValues.netValue.data += parseFloat(
            netPositionData[positionType][posIdx].netValue
          );
          // netPositionAggregatedValues.buyQty.data += parseFloat(
          //   netPositionData[positionType][posIdx].buyQty
          // );
          netPositionAggregatedValues.buyValue.data += parseFloat(
            netPositionData[positionType][posIdx].buyValue
          );
          netPositionAggregatedValues.sellValue.data += parseFloat(
            netPositionData[positionType][posIdx].sellValue
          );

          // netPositionAggregatedValues.buyQtyInLots.data += parseFloat(
          //   netPositionData[positionType][posIdx].buyQtyInLots
          // );
          // netPositionAggregatedValues.sellQtyInLots.data += parseFloat(
          //   netPositionData[positionType][posIdx].sellQtyInLots
          // );
          // netPositionAggregatedValues.sellQty.data += parseFloat(
          //   netPositionData[positionType][posIdx].sellQty
          // );

          netPositionTempData.push(netPositionData[positionType][posIdx]);
        } else {
          if (
            String(netPositionData[positionType][posIdx].Account_Name)
              .toLowerCase()
              .includes(searchAcc.toLowerCase()) ||
            String(netPositionData[positionType][posIdx].SecurityName)
              .toLowerCase()
              .includes(searchAcc.toLowerCase())
          ) {
            // netPositionAggregatedValues.netPosition.data += parseInt(
            //   netPositionData[positionType][posIdx].netPosition
            // );
            netPositionAggregatedValues.mtmValue.data += parseFloat(
              netPositionData[positionType][posIdx].mtmValue
            );
            netPositionAggregatedValues.netValue.data += parseFloat(
              netPositionData[positionType][posIdx].netValue
            );
            // netPositionAggregatedValues.buyQty.data += parseFloat(
            //   netPositionData[positionType][posIdx].buyQty
            // );
            netPositionAggregatedValues.buyValue.data += parseFloat(
              netPositionData[positionType][posIdx].buyValue
            );
            netPositionAggregatedValues.sellValue.data += parseFloat(
              netPositionData[positionType][posIdx].sellValue
            );
            // netPositionAggregatedValues.buyQtyInLots.data += parseFloat(
            //   netPositionData[positionType][posIdx].buyQtyInLots
            // );
            // netPositionAggregatedValues.sellQtyInLots.data += parseFloat(
            //   netPositionData[positionType][posIdx].sellQtyInLots
            // );
            // netPositionAggregatedValues.sellQty.data += parseFloat(
            //   netPositionData[positionType][posIdx].sellQty
            // );
            netPositionTempData.push(netPositionData[positionType][posIdx]);
          }
        }
      }

      // Round off to 2 decimal places
      netPositionAggregatedValues.netValue.data = parseFloat(
        netPositionAggregatedValues.netValue.data
      ).toFixed(4);
      netPositionAggregatedValues.mtmValue.data = parseFloat(
        netPositionAggregatedValues.mtmValue.data
      ).toFixed(4);
      netPositionAggregatedValues.buyValue.data = parseFloat(
        netPositionAggregatedValues.buyValue.data
      ).toFixed(4);
      netPositionAggregatedValues.sellValue.data = parseFloat(
        netPositionAggregatedValues.sellValue.data
      ).toFixed(4);

      // Add Class to Net Position for text color
      // if (netPositionAggregatedValues.netPosition.data > 0) {
      //   // netPositionAggregatedValues.netPosition.data = "+ " + netPositionAggregatedValues.netPosition.data;
      //   netPositionAggregatedValues.netPosition.className =
      //     'position-book-row-green';
      // } else if (netPositionAggregatedValues.netPosition.data < 0) {
      //   // netPositionAggregatedValues.netPosition.data = "- " + netPositionAggregatedValues.netPosition.data;
      //   netPositionAggregatedValues.netPosition.className =
      //     'position-book-row-red';
      // }

      if (netPositionAggregatedValues.netValue.data > 0) {
        // netPositionAggregatedValues.netValue.data = "+ " + netPositionAggregatedValues.netValue.data;
        netPositionAggregatedValues.netValue.className =
          'position-book-row-green';
      } else if (netPositionAggregatedValues.netValue.data < 0) {
        // netPositionAggregatedValues.netValue.data = "- " + netPositionAggregatedValues.netValue.data;
        netPositionAggregatedValues.netValue.className =
          'position-book-row-red';
      }

      if (netPositionAggregatedValues.mtmValue.data > 0) {
        netPositionAggregatedValues.mtmValue.data =
          netPositionAggregatedValues.mtmValue.data;
        netPositionAggregatedValues.mtmValue.className =
          'position-book-row-green';
      } else if (netPositionAggregatedValues.mtmValue.data < 0) {
        netPositionAggregatedValues.mtmValue.data =
          netPositionAggregatedValues.mtmValue.data;
        netPositionAggregatedValues.mtmValue.className =
          'position-book-row-red';
      }

      setNetPositionShow(netPositionTempData);
      setAggregatedValues(netPositionAggregatedValues);
    }
  }

  // Function Used to set table data
  let parsedNetPositionData = () => {
    let parsedData = [];
    if (netPositionShow && netPositionShow.length > 0) {
      parsedData = netPositionShow.map((positionRow, index) => {
        return positionRow.isFocused
          ? {
              ...positionRow,
              className: 'netPositionFocusedRow',
            }
          : {
              ...positionRow,
              className: 'netPositionRow',
            };
      });
    } else {
      // Custom Call | Create netPositionShow from netPositionData[positionType]
      //? To Be Tested: Not required and may lead to infinite recursion
      // dispatch(netPosition());
      // refreshNetPosition();
      // return parsedNetPositionData();
    }
    return parsedData;
  };

  // Function to check if scrolled to bottom or not
  function isContentScrolledToBottom(element) {
    const rest = element.scrollHeight - element.scrollTop;
    return Math.abs(element.clientHeight - rest) < 1;
  }

  // Lazy loading implementation here: Based on if scroll hit the bottom
  let handleTableScroll = (event) => {
    const element = event.target;

    if (isContentScrolledToBottom(element) && !lazyLoadWait) {
      // setLazyLoadWait(true);
      setPageNum(pageNum + 1);
      setTimeout(() => {
        setLazyLoadWait(false);
      }, 1000);
    }
  };

  const openFileSelect = () => {
    setShow(true);
  };

  const refreshPositionBook = async () => {
    setIsLoading(true);
    if (client) client.close();
    setAggregatedValues({
      AccountId: { data: null },
      ExchangeName: {
        data: null,
      },
      UserId: {
        data: null,
      },
      Segment: {
        data: null,
      },
      updateTime: {
        data: null,
      },
      AccountId: {
        data: null,
      },
      Account_Name: {
        data: 'Total',
      },
      MarketType: {
        data: null,
      },
      SecurityName: {
        data: null,
      },
      ExpiryDate: {
        data: null,
      },
      StrikePrice: {
        data: null,
      },
      OptionType: {
        data: null,
      },
      netPosition: {
        className: '',
        data: 0,
      },
      mtmValue: {
        className: '',
        data: 0,
      },
      netValue: {
        className: '',
        data: 0,
      },
      buyQtyInLots: {
        className: '',
        data: 0,
      },

      buyQty: {
        className: '',
        data: 0,
      },
      buyValue: {
        className: '',
        data: 0,
      },
      buyAveragePrice: {
        className: '',
        data: 0,
      },
      sellAveragePrice: {
        className: '',
        data: 0,
      },
      ltp: {
        className: '',
        data: 0,
      },
      lotSize: {
        className: '',
        data: 0,
      },
      sellQtyInLots: {
        className: '',
        data: 0,
      },
    });
    setNetPositionShow([]);
    dispatch(netPosition('1'));
    setIsLoading(false);
  };

  const [table_coulumn, setTableColumn] = useState([
    {
      lebel: 'Exchange',
      field: 'ExchangeName',
      className: 'netposition_exchange',
      sort: true,
      renderHtml: (params) => {
        return <>{params.ExchangeName}</>;
      },
    },
    {
      lebel: 'User ID',
      field: 'UserId',
      className: 'netposition_userid',
      sort: true,
      renderHtml: (params) => {
        return <>{params.UserId}</>;
      },
    },
    {
      lebel: 'Segment',
      field: 'Segment',
      className: 'netposition_segment',
      sort: true,
      renderHtml: (params) => {
        return <>{params.Segment}</>;
      },
    },
    {
      lebel: 'Update Time',
      field: 'updateTime',
      className: 'netposition_update_time',
      sort: true,
      renderHtml: (params) => {
        return <>{moment(params.updateTime).format('hh:mm:ss A')}</>;
      },
    },
    {
      lebel: 'Entity ID',
      field: 'AccountId',
      className: 'netposition_account_id',
      sort: true,
      renderHtml: (params) => {
        return <>{params.Account_ID}</>;
      },
    },
    {
      lebel: 'Entity Name',
      field: 'Account_Name',
      className: 'netposition_account_id',
      sort: true,
      renderHtml: (params) => {
        return <>{params.Account_Name}</>;
      },
    },
    {
      lebel: 'MKT',
      field: 'MarketType',
      className: 'netposition_market_type',
      sort: true,
      renderHtml: (params) => {
        return <>{params.MarketType}</>;
      },
    },
    {
      lebel: 'Trading Symbol',
      field: 'SecurityName',
      className: 'netposition_symbol',
      sort: true,
      renderHtml: (params) => {
        return <>{params.SecurityName}</>;
      },
    },
    {
      lebel: 'Ser/Exp',
      field: 'ExpiryDate',
      className: 'netposition_expiry',
      sort: true,
      renderHtml: (params) => {
        return <>{params.ExpiryDate}</>;
      },
    },
    {
      lebel: 'Strike Price',
      field: 'StrikePrice',
      className: 'netposition_strike_price',
      sort: true,
      renderHtml: (params) => {
        return <>{params.StrikePrice}</>;
      },
    },
    {
      lebel: 'Option',
      field: 'OptionType',
      className: 'netposition_option_type',
      sort: true,
      renderHtml: (params) => {
        return <>{params.OptionType}</>;
      },
    },
    {
      lebel: 'Net Qty',
      field: 'netPosition',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return (
          <span
            className={
              params.netPosition && parseFloat(params.netPosition) > 0
                ? 'position-book-row-green'
                : 'position-book-row-red'
            }
          >
            {params.netPosition}
          </span>
        );
      },
    },
    {
      lebel: 'MTM/GL',
      field: 'mtmValue',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <>{params.mtmValue}</>;
      },
    },
    {
      lebel: 'Net Val',
      field: 'netValue',
      className: 'number-aligned',
      sort: true,

      renderHtml: (params) => {
        return (
          <span
            className={
              params.netValue && parseFloat(params.netValue) > 0
                ? 'position-book-row-green'
                : 'position-book-row-red'
            }
          >
            {params.netValue}
          </span>
        );
      },
    },
    {
      lebel: 'Buy Quantity in Lots',
      field: 'buyQtyInLots',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.buyQtyInLots}</span>;
      },
    },
    {
      lebel: 'Buy Quantity',
      field: 'buyQty',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.buyQty}</span>;
      },
    },
    {
      lebel: 'Buy Value',
      field: 'buyValue',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.buyValue}</span>;
      },
    },
    {
      lebel: 'Buy Avg Price',
      field: 'buyAveragePrice',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.buyAveragePrice}</span>;
      },
    },
    {
      lebel: 'Sell Quantity in Lots',
      field: 'sellQtyInLots',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.sellQtyInLots}</span>;
      },
    },
    {
      lebel: 'Sell Quantity',
      field: 'sellQty',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.sellQty}</span>;
      },
    },
    {
      lebel: 'Sell Value',
      field: 'sellValue',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.sellValue}</span>;
      },
    },
    {
      lebel: 'Sell Avg Price',
      field: 'sellAveragePrice',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.sellAveragePrice}</span>;
      },
    },
    {
      lebel: 'Last Traded Price',
      field: 'ltp',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span className="">{params.ltp}</span>;
      },
    },
    {
      lebel: 'Lot Size',
      field: 'lotSize',
      className: 'number-aligned',
      sort: true,
      renderHtml: (params) => {
        return <span>{params.lotSize}</span>;
      },
    },
  ]);

  const [table_footer, setTableFooter] = useState(
    Object.values(aggregatedValues)
  );

  const [show, setShow] = useState(false);
  const showPopUp = useMemo(() => show, [show]);
  const fileUploadCallback = useCallback((val) => {
    setShowPopup(false);
    setPositionPrev(val);
  }, []);
  const setShowPopup = useCallback((val) => setShow(val), []);

  const [table_settings, set_table_settings] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [{ label: 'Reorder / Hide & Unhide', id: 20 }],
        },
      ],
    },
  ]);

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    switch (temp_index[0]) {
      case 'P':
        // * set parent data
        break;
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          let tempColumns = [...table_settings];
          tempColumns.push({ type: 'COL_RE' });
          set_table_settings(tempColumns);
        }
        break;
      case 'B':
        const tempColumn = [...table_settings];
        tempColumn.pop();
        set_table_settings(tempColumn);
        break;
      default:
        break;
    }
  }

  const { viewData } = useSelector(
    (state) => state.TableOrderReducer.net_position
  );

  useEffect(() => {
    const tempMap = {};

    for (let column of viewData) {
      tempMap[column.variableName] = column.columnSeq;
    }
    let columnsDisp = [...table_coulumn];
    for (let i = 0; i < columnsDisp.length; i++) {
      columnsDisp[i].colSeq = tempMap[columnsDisp[i].field];
    }
    columnsDisp = columnsDisp.sort((a, b) => a.colSeq - b.colSeq);
    // console.log(table_footer);
    setTableColumn(columnsDisp);
  }, [viewData]);

  useEffect(() => {
    const tempMap = {};
    for (let column of viewData) {
      tempMap[column.variableName] = column.columnSeq;
    }
    let tempAggregate = { ...aggregatedValues };

    for (let column of table_coulumn) {
      try {
        if (column.colSeq) {
          tempAggregate[column.field].colSeq = column.colSeq;
        } else {
          tempAggregate[column.field].colSeq = -1;
        }
      } catch (error) {
        console.log(error);
      }
    }
    setTableFooter(
      Object.values(tempAggregate).sort((a, b) => a.colSeq - b.colSeq)
    );
  }, [viewData, aggregatedValues, table_coulumn]);

  let handlePositionTypeChange = (event) => {
    try {
      if (event.target && event.target.value && !isNaN(event.target.value)) {
        switch (parseInt(event.target.value)) {
          case POSITION_TYPE.APPROVED:
            setPositionType(POSITION_TYPE.APPROVED);
            break;
          case POSITION_TYPE.UNAPPROVED:
            setPositionType(POSITION_TYPE.UNAPPROVED);
            break;
          case POSITION_TYPE.TOTAL:
            setPositionType(POSITION_TYPE.TOTAL);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={'position-book'}>
      <div className="position-book-search-container">
        <div className="position-book-refresh">
          <GenButton
            title="Refresh"
            fill={false}
            icon={RefreshIcon}
            clickAction={() => {
              if (!isLoading) {
                refreshPositionBook();
              }
            }}
            disabled={isLoading}
          />
        </div>

        <div className="position-type-toggler-container">
          <ToggleButtonGroup
            color="primary"
            value={positionType}
            exclusive
            onChange={handlePositionTypeChange}
            aria-label="Platform"
            style={{ height: '4vh' }}
          >
            <ToggleButton
              value={POSITION_TYPE.APPROVED}
              style={{ fontSize: '2vh' }}
            >
              Approved
            </ToggleButton>
            <ToggleButton
              value={POSITION_TYPE.UNAPPROVED}
              style={{ fontSize: '2vh' }}
            >
              Unapproved
            </ToggleButton>
            <ToggleButton
              className="total"
              value={POSITION_TYPE.TOTAL}
              style={{ fontSize: '2vh' }}
            >
              Total
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <GenSearchBox
          value={searchAcc}
          changeListener={(val) => setSearchAcc(val)}
          version={1}
          message="Search eg: Info use, nifty fut gold, gold mcx"
          extStyles={{ width: '30vw', height: '4vh' }}
        />
        <div
          style={{
            marginRight: '15px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <GenButton
            fill={false}
            icon={exicon}
            title="UPLOAD POSITIONS"
            extStyle={{ margin: '0 1vw 0 0' }}
            clickAction={openFileSelect}
            disabled={!editAllowed}
          />
          <GenButton
            fill={false}
            icon={exicon}
            title="EXPORT EXCEL"
            clickAction={async () => {
              const res = await MakeRequest(
                `/excel/generate/position-book/${positionType}`
              ).download('PositionBook.xlsx');
              console.log(res);
            }}
            extStyle={{ margin: 'auto' }}
          />
          <TableSettings
            menuOptions={table_settings}
            clickAction={colModifier}
            updateColCallBack={() => {}}
            screenName="NET_POSITION"
            allowed={editAllowed}
          />
        </div>
      </div>
      <div
        className="position-book-table"
        onScroll={(event) => {
          handleTableScroll(event);
        }}
      >
        {netPositionLoaded ? (
          <TableComp
            tableCoulumn={table_coulumn}
            className={'table table-striped'}
            TableData={parsedNetPositionData()}
            sorting={false}
            tableFooter={table_footer}
            toDisplayFooter={true}
          ></TableComp>
        ) : (
          <Spinner animation="border" variant="dark" />
        )}
        {/* {!netPositionLoaded ? (
          <Spinner animation="border" variant="dark" />
        ) : null} */}
      </div>
      <FileUploadComponent
        show={showPopUp}
        setShow={setShowPopup}
        screenType="position"
        callBackFunc={fileUploadCallback}
      />
      <ExcelPrevPopup
        state={positionPrev !== undefined}
        data={positionPrev && positionPrev.data}
        callBackFunction={callBackForPopupPrev}
        type={2}
        previewTitle="Position Upload Preview"
      />
      <FeedbackPopup
        message={errorState.message}
        type="a"
        acceptAction={async () => {
          const apiRes = await submitExcelprev({
            data: errorState.data,
            dataSec: errorState.dataSec,
            override: 1,
          });
          setErrorState({ message: '', data: {} });
        }}
        rejectAction={async () => {
          const apiRes = await submitExcelprev({
            data: errorState.data,
            dataSec: errorState.dataSec,
            override: 2,
          });
          setErrorState({ message: '', data: {} });
        }}
      />
      <FeedbackPopup
        message={
          positionPrevFeedBack
            ? `${JSON.stringify(
                positionPrevFeedBack
              )}. Do you want to upload again ?`
            : ''
        }
        type="a"
        acceptAction={() => {
          setShow(true);
          setPositionPrevFeedback('');
        }}
        rejectAction={() => {
          setPositionPrevFeedback('');
        }}
      />
    </div>
  );
};

export default NetPosition;

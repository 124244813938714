import { useState, useEffect } from 'react';
import { 
  GenButton,
  TableGenerator,
  PillButton,
  RemarkButton,
  GenericPopup,
  RemarkTable,
  Switch,
  FeedbackPopup, 
} from '../../../../components';
import { toggleDealerState } from '../../../AdminDashboard/UserMaintenance/dealerDashboard/apis';
import { AddCircle } from '../../../../assets';
import { useSelector, useDispatch } from 'react-redux';
import { ManageRoleDD,UMformGen } from '../../../Ops/customComp';
import { OpsReducerActions, permissionChecker } from '../../../Ops/redux';
import ViewOpsUser from '../../../Ops/customComp/viewOpsUser';
import OpsApiCalls from '../../../Ops/redux/apiCalls';
import CreateRole from '../../../Ops/customComp/createRole';
import { MakeRequest } from '../../../../utils';
import CreateUserForm from '../../../Ops/customComp/popupFormGen';
import ResetDealerPassword from '../../../AdminDashboard/UserMaintenance/dealerDashboard/resetDealerPassword';
import { TableDataCell, TableHeadText, TableHeader } from '../../../../components/sharedComponents/GenTable/tableStyledComps';
import { TableRow } from '@mui/material';

export default function UserDashboard() {
  const [disp_table, set_disp_table] = useState([]);
  const [disp_table_head, set_disp_tableHead] = useState([]);
  const [popup, setPopup] = useState({ visible: false, formType: 1 }); // TODO 'formType' is unnecessary can be removed later on
  const [fbPopupData, setfbPopupData] = useState([]);
  const { userMaintenance } = useSelector((state) => state.OpsReducer);
  const dispatch = useDispatch();
  const [viewUser, setViewUser] = useState({});

  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState('view');
  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'User Maintenance',
      2,
      'Ops'
    );
    setPermission(res > 1 ? 'edit' : 'view');
  }, [userPermissions]);

  const [popupData, setPopupData] = useState({
    state: false,
    type: '',
    message: '',
    formType: 'a',
    data: {},
  });
  const [createRole, setCreateRole] = useState({ type: -1, data: {} });
  const [feedback, setFeedback] = useState('');
  const [editUser, setEditUser] = useState({ state: false, data: {} });

  async function updateOpsData(res, item) {
    if (res) {
      await OpsReducerActions.refreshOpsUserMainTableData(dispatch);
    } else {
      setPopupData({
        state: true,
        type: 'feedback',
        data: `Some error occured not able to activate Dealer with ID : ${item.id}`,
      });
    }
  }

  function genTableContent(data) {
    const temp = [];
    const tableHeads = [
      <TableHeader>
        <TableHeadText>User ID</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>Role</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>User Name</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>User Status</TableHeadText>
      </TableHeader>,
      <TableHeader>
      <TableHeadText>Password Status</TableHeadText>
        </TableHeader>,
      <TableHeader>
        <TableHeadText>Last Modified</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>View</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>Edit</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>Delete</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>Reset Password</TableHeadText>
      </TableHeader>,
      <TableHeader>
        <TableHeadText>Reset Login Attempts</TableHeadText>
      </TableHeader>,
        <TableHeader>
      <TableHeadText>Remarks</TableHeadText>
      </TableHeader>,
    ];

    for (let item of data) {
      temp.push(
        <TableRow>
          <TableDataCell>{item.id}</TableDataCell>
          <TableDataCell>{item.role.join(', ')}</TableDataCell>
          <TableDataCell>{item.name}</TableDataCell>
          <TableDataCell>{
              <Switch
                state={item.status === 'Active'}
                disable={permission === 'edit' ? false : true}
                onChange={async (val) => {
                  const res = await toggleDealerState(item.id, val);
                  updateOpsData(res, item);
                }}
              />
            }</TableDataCell>
          <TableDataCell>{item.pwdStatus}</TableDataCell>
          <TableDataCell>{item.lastMod}</TableDataCell>
          <TableDataCell><PillButton
              iconType={0}
              extStyles={{ margin: 'auto' }}
              disabled={item.enableView !== 1}
              clickAction={async () => {
                const res = await OpsApiCalls.getUserForId(item.id);
                setViewUser(res);
              }}
            /></TableDataCell>
          <TableDataCell><PillButton
              iconType={1}
              extStyles={{ margin: 'auto' }}
              disabled={item.enableEdit !== 1}
              clickAction={() => openEdituser(item.id)}
            /></TableDataCell>
          <TableDataCell><PillButton
              iconType={2}
              extStyles={{ margin: 'auto' }}
              disabled={item.enableDelete !== 1}
              clickAction={() =>
                setFeedback({
                  id: item.id,
                  msg: `Confirm to delete user ${item.name} ?`,
                })
              }
            /></TableDataCell>
          <TableDataCell><PillButton
              iconType={3}
              extStyles={{ margin: 'auto' }}
              clickAction={async () => {
                setPopupData({
                  state: true,
                  type: 'resetPassword',
                  data: item.id,
                });
              }}
            /></TableDataCell>
          <TableDataCell><PillButton
              iconType={4}
              extStyles={{ margin: 'auto' }}
              clickAction={async () => {
                setPopupData({
                  state: true,
                  type: 'resetLoginAttempts',
                  data: item.id,
                  message: 'Are you sure you want to reset login attempts?',
                  formType: 'a',
                });
              }}
            /></TableDataCell>
          <TableDataCell><RemarkButton
              onClickAction={() => {
                setfbPopupData(
                  item.remarks.map((item) => {
                    return {
                      date: item.date,
                      userName: item.name,
                      remark: item.remark,
                    };
                  })
                );
              }}
              title={
                (item &&
                  item.remarks &&
                  item.remarks[0] &&
                  item.remarks[0].remark) ||
                ''
              }
            /></TableDataCell>
        </TableRow>
      );
    }
    set_disp_tableHead(tableHeads);
    set_disp_table(temp);
  }

  async function openEdituser(userId) {
    const res = await MakeRequest(`/ops/view?qType=U&itemId=${userId}`).get();
    const roles = await OpsApiCalls.getAllRoles();
    const apiData = res.data.data.data;
    const userDataFormat = {
      userId: apiData.userId,
      roles: roles.map((item) => {
        return {
          state: apiData.role.includes(item.name),
          title: item.name,
          id: item.id,
        };
      }),
      userName: apiData.userName,
      fullName: apiData.fullName,
      email: apiData.email,
      mobNo: apiData.mobile,
      state: apiData.status,
      menu: apiData.menu,
    };
    setEditUser({ state: true, data: userDataFormat });
  }

  async function openCreateRolePopup(type, id) {
    switch (type) {
      case 0: // this is to Create Role
        const result = await OpsApiCalls.createUserInitialMenu();
        setCreateRole({ type: 0, data: result });
        break;
      case 1: // this is to Copy Role
        const result1 = await OpsApiCalls.getMenuRespectToRole([id]);
        setCreateRole({ type: 1, data: result1 });
        break;
      case 2: // this is to Edit Role
        const result2 = await MakeRequest(
          `/ops/view?qType=R&itemId=${id}`
        ).get();
        setCreateRole({
          type: 2,
          data: { roleId: id, ...result2.data.data.data },
        });
        break;
      default:
        break;
    }
  }

  function popupDataGen() {
    switch (popupData.type) {
      case 'resetPassword':
        return (
          <ResetDealerPassword
            dealerId={popupData.data}
            closePopup={() => setPopupData({ ...popupData, state: false })}
          />
        );
      case 'resetLoginAttempts':
        return (
          <FeedbackPopup
            message={popupData.message}
            type={popupData.formType}
            acceptAction={async () => {
              if (popupData.formType === 'a') {
                await OpsApiCalls.getResetLoginAttempts(popupData.data);
                setPopupData({
                  ...popupData,
                  formType: 'f',
                  message: 'Login attempts have been reset successfully',
                });
              } else {
                setPopupData({ ...popupData, state: false });
              }
            }}
            rejectAction={() => setPopupData({ ...popupData, state: false })}
          />
        );
      default:
        return <div></div>;
    }
  }

  useEffect(() => {
    genTableContent(userMaintenance.table);
  }, [userMaintenance.table]);

  useEffect(() => {
    OpsReducerActions.refreshOpsUserMainTableData(dispatch);
    OpsReducerActions.fetchAllRole(dispatch);
  }, []);

  return (
    <div className="ops-usermaintanance">
      <div className="ops-usermain-s1">
        <GenButton
          containerStyle={{ height: '4vh' }}
          fill={false}
          title="CREATE USER"
          icon={AddCircle}
          extStyle={{ margin: '0 1vw 0 1vw' }}
          clickAction={() => setPopup({ visible: true, formType: 1 })}
        />
        <ManageRoleDD
          roles={userMaintenance.manage_roles}
          click={(type, id) => openCreateRolePopup(type, id)}
        />
      </div>
      <div className="ops-usermain-s2">
        <TableGenerator
          heads={disp_table_head}
          content={disp_table}
          version={2}
          extStyles={{
            height: '70vh',
            width: '94vw',
            margin: '1vh auto 1vh auto',
          }}
        />
      </div>
      <GenericPopup
        state={popup.visible || editUser.state}
        toggleState={() => setPopup({ ...popup, visible: false })}
        type={1}
      >
        <CreateUserForm
          edit={editUser.state}
          data={editUser.data}
          togglepopup={() => {
            setPopup({ ...popup, visible: false });
            setEditUser({ state: false, data: {} });
          }}
        />
      </GenericPopup>
      <GenericPopup
        state={fbPopupData.length > 0}
        toggleState={() => setfbPopupData([])}
        type={1}
      >
        <RemarkTable
          data={{
            heads: ['Date Modified', 'User ID/Name', 'Remarks'],
            data: fbPopupData,
          }}
        />
      </GenericPopup>
      <GenericPopup
        state={Object.keys(viewUser).length > 0}
        toggleState={() => setViewUser({})}
        type={1}
      >
        <ViewOpsUser
          data={viewUser}
          toggle={() => setViewUser({})}
          editCall={(val) => openEdituser(val)}
        />
      </GenericPopup>
      <GenericPopup
        state={popupData.state}
        toggleState={() => setPopupData({ ...popupData, state: false })}
        type={1}
      >
        {popupDataGen()}
      </GenericPopup>

      <GenericPopup
        state={createRole.type !== -1}
        toggleState={() => setCreateRole({ type: -1, data: {} })}
        type={1}
      >
        <CreateRole
          initData={createRole.data}
          type={createRole.type}
          togglePopup={() => setCreateRole({ type: -1, data: {} })}
          feedbackPopup={(val) => setFeedback(val)}
        />
      </GenericPopup>
      <FeedbackPopup
        type={typeof feedback === 'string' ? 'f' : 'a'}
        message={typeof feedback === 'string' ? feedback : feedback.msg}
        acceptAction={async () => {
          if (typeof feedback === 'object') {
            await MakeRequest(`/ops/deleteUser?userId=${feedback.id}`).get();
            OpsReducerActions.refreshOpsUserMainTableData(dispatch);
          }
          setFeedback('');
        }}
        rejectAction={() => setFeedback('')}
      />
    </div>
  );
}

const initialData = {
  pageNumber: 1,
  pagename: 'Some Random name',
};

function CustomMarketWatchReducer(
  state = initialData,
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case 'CUSTOM_MARKET_WATCH_STATE_SET_PAGE_NUMBER':
      return { ...state, pageNumber: action.payload };
    default:
      return state;
  }
}

class CustMarkWatchAction {
  dispatch = null;
  constructor(dis) {
    this.dispatch = dis;
  }

  setPageNumber(pageNumber) {
    this.dispatch({
      type: 'CUSTOM_MARKET_WATCH_STATE_SET_PAGE_NUMBER',
      payload: pageNumber,
    });
  }
}

export { CustomMarketWatchReducer, CustMarkWatchAction };

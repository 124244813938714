import React, { useRef } from 'react';
import { LiveTradingHeader } from '../../components/LiveTrading/LiveHeader/LiveTradingHeader';
import MarketWatch from '../../components/LiveTrading/MarktWatch/MarketWatch';
import QuickWatch from './QuickWatch';

const LiveTrading = () => {
  const scrollRef = useRef(null);
  const tableRef = useRef(null);
  const analyzereRef = useRef(null);
  const marketWatchHeaderRef = useRef(null);
  const selectBoxRef = useRef(null);

  localStorage.setItem('MarketWatchFocusedPageNum', 0);

  return (
    <div className="dashboard" style={{ width: '98vw' }}>
      <LiveTradingHeader marketWatchHeaderRef={marketWatchHeaderRef} />
      <MarketWatch
        scrollRef={scrollRef}
        tableRef={tableRef}
        analyzereRef={analyzereRef}
        selectBoxRef={selectBoxRef}
      />
      <QuickWatch />
    </div>
  );
};

export default LiveTrading;

import './styles.css';

export default function ({
  id = '',
  options = [
    { label: 'Title 1', key: '0' },
    { label: 'Title 2', key: '1' },
  ],
  selected = {},
  onSelect = (val) => {},
  extStyles = {},
}) {
  function handleKeyDown(event, item) {
    if (event.key === 'Enter') {
      onSelect(item);
    }
  }
  return (
    <div className="buttonTabNav-container" style={extStyles}>
      {options.map((item, index, allItems) => (
        <div
          className={`buttonTabNav-tab-container ${
            item.key === selected.key ? 'buttonTabNav-actve' : ''
          }`}
          key={`${id}-${index}`}
          id={`${id}-${index}`}
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e, item)}
          onClick={() => handleKeyDown({ key: 'Enter' }, item)}
        >
          <span className="font-l1 buttonTabNav-tab-text">{item.label}</span>
        </div>
      ))}
    </div>
  );
}

import { useRef } from 'react';
import { GenToolTip } from '../../..';
import { CancelIcon } from '../../../../assets';
import CompLabel from '../compLabel';
import './styles.css';

export default function Show_Multi_Select({
  data = [],
  removeFunc,
  label,
  extStyles,
  contStyle = {},
}) {
  const ref = useRef(null);
  return (
    <div style={extStyles} ref={ref}>
      <CompLabel label={label}>
        <div className="show_multi_select_cont" style={contStyle}>
          {data.map((item) => (
            <GenToolTip tooltip={item} containerRef={ref}>
              <div className="show-multiselect-text-cont">
                <div className="Show_Multi_Select-textContainer">
                  <span className="font-l1 show-multiselect-text">{item}</span>
                </div>
                <img
                  src={CancelIcon}
                  className="show-multiselect-cross"
                  onClick={() => removeFunc(item)}
                  alt="multiCoss"
                />
              </div>
            </GenToolTip>
          ))}
        </div>
      </CompLabel>
    </div>
  );
}

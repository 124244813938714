import { useState, useEffect } from 'react';
import './styles.css';
import { Button } from '../../../../components';
import { EditSolid } from '../../../../assets';
import UMMenuGen from '../UMMenuGen';

export default function ViewOpsUser(props) {
  const { data = {}, editCall = () => {}, toggle = () => {} } = props;

  const editbuttonSettings = {
    fill: false,
    icon: EditSolid,
    title: 'Edit',
    styles: { margin: '0 1vw 0 0' },
  };

  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    const tdata = [];
    console.log(data);
    tdata.push(['User Name', data.userName]);
    tdata.push(['Full Name of User', data.fullName]);
    tdata.push(['Email ID', data.email]);
    tdata.push(['Mobile No', data.mobile]);
    tdata.push(['Roles', data.role && data.role.join(', ')]);
    tdata.push(['Active', data.status ? 'Yes' : 'No']);
    setDisplayData(tdata);
  }, [data]);

  function addViewTables() {
    if (displayData.length === 0) {
      return <></>;
    }
    return (
      <div className="middle-container">
        <table className="view-table-table">
          <thead className="view-table-thead">
            <tr>
              <th className="view-table-title-tableheader" colSpan={3}>
                User Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="view-table-td">
                {cellInfo(displayData[0][0], displayData[0][1])}
              </td>
              <td className="view-table-td">
                {cellInfo(displayData[1][0], displayData[1][1])}
              </td>
              <td className="view-table-td">
                {cellInfo(displayData[2][0], displayData[2][1])}
              </td>
            </tr>
            <tr>
              <td className="view-table-td">
                {cellInfo(displayData[3][0], displayData[3][1])}
              </td>
              <td className="view-table-td">
                {cellInfo(displayData[4][0], displayData[4][1])}
              </td>
              <td className="view-table-td">
                {cellInfo(displayData[5][0], displayData[5][1])}
              </td>
            </tr>
          </tbody>
        </table>
        <UMMenuGen
          data={data.menu}
          settings={{
            type: 2,
            view: true,
            style: {
              margin: '2vh auto 0 auto',

              width: '59vw',
            },
          }}
        />
      </div>
    );
  }

  function cellInfo(key, val) {
    return (
      <div className="um-view-c1-container">
        <span className="um-view-text-head">{key}</span>
        <span className="um-view-text-body">{val}</span>
      </div>
    );
  }

  return (
    <div className="viewOpsUser-container">
      <div className="viewOpsUser-title-container">
        <span className="viewOpsUser-title-text">View</span>
        <Button
          settings={editbuttonSettings}
          click={() => editCall(data.userId)}
        />
      </div>
      <div className="viewOpsUser-middle-container">{addViewTables()}</div>
      <div className="footer-container">
        <Button
          settings={{
            fill: false,
            title: 'CLOSE',
            styles: { margin: 'auto 1vw auto auto', width: '10vw' },
          }}
          click={toggle}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import MissingTrades from '../subScreenLayout/missingTrades';
import { LoadingCircle } from '../../../assets';
import { GenButton, GenericPopup } from '../../../components';
import SelParamTradePopup from './../customComp/SelectParamPopup';
import { OpsReducerActions } from '../redux';
import OpsApiCalls from '../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { MakeRequest } from '../../../utils';
const OpsTradeMatch = () => {
  const [selParPopup, setSelParPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);

  // const [tabSelected, setTabSelected] = useState(0);
  const dispatch = useDispatch();
  const { popupValues } = useSelector((state) => state.OpsReducer);
  useEffect(() => {
    //Fetch popupData
    const apiCall = async () => {
      try {
        const data = await OpsApiCalls.getParamsData();
        await OpsReducerActions.initializeParamsPopup({ dispatch, data });
      } catch (error) {
        console.log(error);
      }
    };
    //Api call to get location data
    if (popupValues.length == 0) apiCall();
  }, []);

  //Func to handle posting of params
  const handleTradeMatchClick = async (formDetails) => {
    setLoading((prevState) => true);
    try {
      const data = { ...formDetails.formData };
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };
      const fileObj = new FormData();
      fileObj.append('tradeDate', data.selectedDate);
      fileObj.append('exchange', data.selectedExchange);
      fileObj.append('segment', data.selectedSegment);
      fileObj.append(
        'startTime',
        data.selectedStartTime.toLocaleTimeString([], options)
      );
      fileObj.append(
        'endTime',
        data.selectedEndTime.toLocaleTimeString([], options)
      );
      let newEms = data.selectedEMS.filter((item) => {
        return item.state;
      });
      let sendEms = newEms.map((item) => item.emsId);
      fileObj.append('ems', JSON.stringify(sendEms));
      fileObj.append('excelData', formDetails.selectedFile1);
      fileObj.append('excelData', formDetails.selectedFile2);
      const retData = await OpsApiCalls.postTradeMatch(fileObj);
      setLoading((prevState) => false);
      setTableData(retData.message.data);
    } catch (error) {
      setLoading((prevState) => false);
    }
  };

  async function matchTrades() {
    setLoading((prevState) => true);
    const response = await MakeRequest('/ops/tradeMatchDefault').post({});
    setLoading((prevState) => false);
    setTableData(response.data.data.data);
  }

  return (
    <div className="ops-tradematch-container">
      <div className="ops-tradematch-buttons">
        <GenButton
          fill={false}
          title="Select Params"
          extStyle={{ margin: '0 1vw 0 0' }}
          clickAction={() => setSelParPopup(!selParPopup)}
        />
        <GenButton
          fill={false}
          title="Match Trades"
          extStyle={{ margin: '0 1vw 0 0' }}
          clickAction={() => matchTrades()}
        />
      </div>
      <div className="ops-tradematch-tabs">
        {/* <div className="tabs-container">
          <TabNavInner
            tabs={[
              { id: 0, title: 'Matching Result' },
              { id: 1, title: 'Missing Trades in DB' },
            ]}
            curSelect={tabSelected}
            setSelect={(val) => setTabSelected(val)}
          />
        </div> */}
        {/* <div className="tab-content-container">
          {tabSelected === 0 && <MatchingResult />}
          {tabSelected === 1 && <MissingTrades />}
        </div> */}
        {loading && (
          <img
            src={LoadingCircle}
            alt="Loading..."
            className="center-loader"
          ></img>
        )}
        {!loading && tableData && (
          <MissingTrades data={tableData} loading={loading} />
        )}
      </div>
      <GenericPopup
        state={selParPopup}
        type={1}
        toggleState={() => setSelParPopup(!selParPopup)}
        extStyle={{ borderRadius: '1vh 1vh 0 0' }}
      >
        <SelParamTradePopup
          setSelParPopup={setSelParPopup}
          handleTradeMatchClick={(data) => {
            setSelParPopup(false);
            handleTradeMatchClick(data);
          }}
        />
      </GenericPopup>
    </div>
  );
};

export default OpsTradeMatch;

import './styles.css';
import { V2DropDown } from '../../../advComponents';
import { useEffect, useState } from 'react';
import { GenButton } from '../../../components';
import useSWR from 'swr';
import { MakeRequest } from '../../../utils';

export default function ScreenPicker({ val, setVal }) {
  const [tempState, setTempState] = useState(val);
  const [screenOptions, setScreenOptions] = useState([]);
  const [data, error, loading] = GetAvailableScreens();

  useEffect(() => {
    if (data?.length > 0) {
      setScreenOptions(['Alert', ...data]);
    }
  }, [data]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <V2DropDown
        label="Pick a screen"
        value={tempState}
        setValue={(val) => setTempState(val)}
        options={screenOptions}
        extStyles={{
          mainContainer: { margin: '2vh auto 0 auto' },
          inputContainer: { width: '15vw' },
        }}
        orientation="v"
      />
      <GenButton
        title="APPLY"
        fill={true}
        extStyle={{ margin: '2vh 0 0 15px' }}
        clickAction={() => setVal(tempState)}
      />
    </div>
  );
}

function GetAvailableScreens() {
  const { data, error, isLoading } = useSWR(
    '/user/multiscreen/availableScreens',
    async () => {
      const response = await MakeRequest(
        '/user/multiscreen/availableScreens'
      ).get();
      return response?.data?.data?.data?.menu.map((item) => item.title);
    }
  );
  return [data, error, isLoading];
}

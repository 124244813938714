import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './LogLiveFamily.scss';

const LogLiveFamily = ({ familyList }) => {
  const [familyDisplay, setFamilyDisplay] = useState([[]]);

  useEffect(() => {
    let familyArr = familyList;
    let famDisplayData = [];

    if (familyArr && Array.isArray(familyArr) && (familyArr.length % 4 != 0 || familyArr.length === 0)) {
      let appendCount = (parseInt(familyArr.length / 4) + 1) * 4 - familyArr.length;
      for (let idx = 0; idx < appendCount; idx++) {
        familyArr.push('');
      }
    }

    for (let idx = 0 ; idx < familyArr.length ; idx++) {
      let rowIdx = parseInt((idx) / 4);
      if (famDisplayData.length <= rowIdx) {
        famDisplayData.push([]);
      }
      famDisplayData[rowIdx].push(familyArr[idx]);
    }

    setFamilyDisplay(famDisplayData);

  }, [familyList])
  console.log("Family Log-Live List:", familyList)
  return (
    <div className={`family-name-log-live`}>
      <div className="family-name-log-live-header">
        <span className="family-name-log-live-header-text">Families</span>
      </div>
      <div className="family-name-log-live-list">
        {
          familyDisplay.map((familyNameList, idx) => {
            return (
              <div className='family-name-log-live-list-member-row'>
              {
                familyNameList.map((familyName, idx1) => {
                  return (
                    <div className={`family-name-log-live-list-member ${(idx1 + 1) % 4 == 0 ? 'family-name-log-live-list-member-last' : ''}`}>
                      <span className="family-name-log-live-list-member-text">
                        {
                          (familyName && String(familyName).length > 13) ?
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip key={`log-live_${idx}`} id="tooltip-top_algo">
                                  <div className={idx}>
                                    <p>
                                      {familyName}
                                    </p>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span key={`log-live_${idx}`}>
                                {String(familyName).slice(0, 10) + '...'}
                              </span>
                            </OverlayTrigger> :
                            familyName
                        }
                      </span>
                    </div>
                  )
                })

              }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default LogLiveFamily;
import React, { useEffect, useState } from 'react';
import {
  otpCheck,
  forgotPassword,
  clearUserData,
  clearOtpError,
} from '../../actions/forgotPasswordAction';
import { useDispatch, connect } from 'react-redux';
import { Validation } from '../../validations/Validations';
import OtpSent from './OtpSent';
import Line from '../../assets/img/Line 252.svg';
import './LoginStyle.scss';
import UiInput from '../sharedComponents/Input';
import OtpInput from 'react-otp-input';
import { CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const EnterOtp = ({ setSection, setOtp, correctOtp, errorOtp, user, mobile, email }) => {
  const [error, setError] = useState('');
  const [editPopup, setEditPopup] = useState(false);
  const [otpResendFlag, setOTPResendFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otpData, setOtpData] = useState({
    username: user,
    otp: '',
    resendDisabled: true,
  });
  const [otpCountdown, setOtpCountdown] = useState(30)
  const dispatch = useDispatch();
  const handleOtp = async (e) => {
    try {
    e.preventDefault();
    const isvalid = handleValidation();
    if (isvalid) {
      setLoader(true);
      let result = await dispatch(otpCheck(otpData));
      setLoader(false)
      if(result.isOtpValid) {
        setOtp(otpData.otp);
      }
      else {
        setError({...error, otp: result.message})
      }
    }
  } catch(err) {
    setLoader(false);
  }
  };
  const handleValidation = () => {
    let error = {};
    if (Validation.empty(otpData.otp)) {
      error = { ...error, otp: 'Please enter your OTP' };
    } else if (otpData.otp.length != 4){
      error = { ...error, otp: 'Please enter complete OTP'}
    }
    if (Object.keys(error).length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  const handleResend = () => {
    if (!otpData.resendDisabled) {
      setOTPResendFlag(true);
      dispatch(forgotPassword({ username: user }));
      setEditPopup(true);
      dispatch(clearOtpError(''));
      setOtpData({ ...otpData, otp: '', resendDisabled: true});
      setOtpCountdown(30);
      setError({ ...error, otp: '' });
    }
  };

  const handleEditUser = () => {
    dispatch(clearUserData(false));
    setSection('/forgotPassword');
    dispatch(clearOtpError(''));
  };
  const handleChange = (e) => {
    setOtpData({ ...otpData, otp: e });
    setError({ ...error, otp: '' });
  };
  useEffect(() => {
    if (correctOtp) {
      setSection('resetPassword');
    }
    if (errorOtp) {
      setError({ ...error, otp: errorOtp });
      dispatch(clearOtpError(''));
    }
  }, [correctOtp, errorOtp]);

  useEffect(() => {
    if (!otpCountdown) {
      otpData.resendDisabled = false;
      return;
    }
    const intervalId = setInterval(() => {
      setOtpCountdown(otpCountdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [otpCountdown]);

  let getFormattedEmail = (emailStr) => {
    if (String(email).includes("@")) {
      let emailParam = String(email).split("@");

      let hashedMailHead = emailParam[0];
      let emailTail = emailParam[1];

      if( hashedMailHead ) {
        if (String(hashedMailHead).length > 2) {
          hashedMailHead = `${String(hashedMailHead).substr(0, 2)}*******`;
        } else if (String(hashedMailHead).length >= 1) {
          hashedMailHead = `${String(hashedMailHead).substr(0, 1)}*******`;
        }
      } else {
        return `Invalid Email Id: ${emailStr}`;
      }

      return `${hashedMailHead}@${emailTail}`;
    } else {
      return `Invalid Email Id: ${emailStr}`;
    }
  }

  return (
    <form className='otp-wrapper'>
      <h1>Enter OTP</h1>
      <div className={'line_wrapper'}>
        <img src={Line} alt="" className="line-img" />
      </div>
      <div style={{marginBottom: '10px'}}>
        <ArrowBackIcon sx={{fontSize:'24px', mr: '10px', mt: '2px', ml: '-3px', cursor: 'pointer'}} onClick={() => handleEditUser()}/><small>{user}</small>
      </div>
      <p className="otp-message" style={{ textAlign: 'left' }}>
        OTP has been {otpResendFlag ? 'resent' : 'sent'} on your registered mail id {getFormattedEmail(email)}
      </p>
      <div className={(error && error.otp) ? "form-error form-group": "form-group"}>
        <label htmlFor="exampleInputEmail1">Enter OTP</label>
        <OtpInput
          containerStyle={{ justifyContent: 'space-around', marginBottom: '10px'}}
          inputStyle={{ width: '2rem', margin: '0 1rem', height: '2rem', border: '1px solid #3334395F' }}
          focusStyle={{border: '1px solid #34395F'}}
          value={otpData.otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span>-</span>}
          isInputSecure
        />
        {error && error.otp && (<span className="error-tag"><WarningAmberIcon sx={{fontSize: '16px', mr: '7px', mt: '4px'}} />{error.otp}</span>)}
        <div className="text-right" style={{marginBottom: '20px'}}>
          <small
            id="otp"
            className={(otpCountdown > 0 ? "resend-disabled form-text" : "form-text")}
            onClick={() => handleResend()}
          >
            <small id="resendBtn">Resend OTP</small>
            <small id="resendCountdown">
              {otpCountdown > 0 ? ('(00:' + (otpCountdown > 9 ? otpCountdown: `0${otpCountdown}`) + ')') : ''}
            </small>
          </small>
        </div>
      </div>
      <button
        type="submit"
        id="submitOtp"
        className="btn btn-primary"
        onClick={(e) => handleOtp(e)}
        disabled={loader}
      >
        {
          loader ? 
          (
            <CircularProgress disableShrink size={25} color="white"/>
          ) :
          (
            'Reset Password'
          )
        }
      </button>
      {editPopup ? <OtpSent setEditPopup={setEditPopup} /> : null}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    correctOtp: state.forgotReducer.otpCheck,
    errorOtp: state.forgotReducer.message,
    user: state.forgotReducer.username,
    mobile: state.forgotReducer.number,
    email: state.forgotReducer.email,
  };
};

export default connect(mapStateToProps, null)(EnterOtp);

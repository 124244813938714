import { Validation } from '../validations/Validations';

const SecuritiesValidations = (strategyTemplateForm) => {
  let error = {};
  strategyTemplateForm.securities.forEach((el) => {
    if (Validation.empty(el.instrument)) {
      error = { ...error, instrument: 'Please enter your instrument' };
    }
    // if (Validation.empty(el.securityName)) {
    //   error = { ...error, securityName: 'Please enter your securityName' };
    // }
    // if (!el.instrument == 'Equity') {
    //   if (Validation.empty(el.expiry)) {
    //     error = { ...error, expiry: 'Please enter your expiry' };
    //   }
    // }
    // if (!(el.instrument == 'Equity' || el.instrument == 'Future')) {
    //   if (Validation.empty(el.strike)) {
    //     error = { ...error, strike: 'Please enter your strike' };
    //   }
    // }
    if (Validation.empty(el.nickname)) {
      error = { ...error, nickname: 'Please enter your nickname' };
    }
  });

  if (Object.keys(error).length) {
    return false;
  } else {
    return true;
  }
};

export default SecuritiesValidations;

import React from 'react';
import './styles.css';
import { GenButton, DynamicFieldEditor } from '../../../../components';

export default function ManageConfigPopup(props) {
  return (
    <div className="manage-conf-popup-cont">
      <div className="manage-conf-popup-title-container">
        <span className="manage-conf-popup-title">Manage Configurations</span>
      </div>
      <div className="manage-conf-popup-middlearea">
        <div className="manage-conf-popup-middlearea-s1">
          <DynamicFieldEditor
            title="Application Config"
            extStyle={{ margin: '1vh auto 1vh auto', width: '32vw' }}
          />
          <DynamicFieldEditor
            title="Application Schedule"
            extStyle={{ margin: '1vh auto 1vh auto', width: '32vw' }}
          />
        </div>
      </div>
      <div className="manage-conf-popup-buttonarea">
        <GenButton
          fill={false}
          title="VALIDATE"
          extStyle={{ margin: '0 1vw 0 1vw', width: '10vw' }}
        />
        <GenButton
          fill={false}
          title="REFRESH"
          extStyle={{ margin: '0 1vw 0 1vw', width: '10vw' }}
        />
        <GenButton
          fill={true}
          title="SUBMIT"
          extStyle={{ margin: '0 1vw 0 auto', width: '10vw' }}
        />
      </div>
    </div>
  );
}

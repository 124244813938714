import React, { useState } from 'react';
import './unitWindow.css';
import Settings from '../../../../assets/img/settings.svg';
import {
  GenericPopup,
  Switch,
  DropDown,
  GenButton,
} from '../../../../components';

const UnitWindow = (props) => {
  const {
    riskMeasuresList,
    unitsList,
    factors,
    existingRiskUnits,
    applyAction,
    unitColumnExcludeList,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [quickUnitChangeToggle, setQuickUnitChangeToggle] = useState(true);
  const [riskUnits, setRiskUnits] = useState({});
  const [globalRiskUnit, setGlobalRiskUnit] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const apply = () => {
    applyAction(riskUnits);
    handleClose();
  };
  const resetDropDown = () => {
    console.log(existingRiskUnits);
    let riskObj = { ...existingRiskUnits };
    setRiskUnits(riskObj);
  };
  const changeUnit = (unit, riskIndex) => {
    let tempRiskObj = { ...riskUnits };
    tempRiskObj[riskMeasuresList[riskIndex].columnName] = unit;
    setRiskUnits(tempRiskObj);
  };
  const globalUnitChange = (unit) => {
    let riskObj = {};
    for (let i = 0; i < riskMeasuresList.length; i++) {
      riskObj[riskMeasuresList[i].columnName] = unit;
    }
    setRiskUnits(riskObj);
    setGlobalRiskUnit(unit);
  };

  return (
    <div>
      <div className="btn-x">
        <GenButton
          type="subButton"
          fill={false}
          icon={Settings}
          title="Units"
          clickAction={handleToggle}
          params="Units"
        />
      </div>

      <GenericPopup type={1} state={open}>
        <div className="feedback-popup-container-x">
          <div className="title-container-x">Risk Measure Units</div>
          <div className="switch-container-x">
            <Switch
              label="Quick Unit Change"
              state={quickUnitChangeToggle}
              onChange={(val) => setQuickUnitChangeToggle(val)}
            />
          </div>
          <div className="title2-container-x">
            <div className="title2-container-child-left-x">Risk Measures</div>
            <div className="title2-container-child-right-x">
              <span className="global-unit-text-x">Units</span>
              <DropDown
                options={unitsList}
                disabled={!quickUnitChangeToggle}
                selected={globalRiskUnit}
                orientation="v"
                onSelect={(val) => {
                  globalUnitChange(val);
                }}
              />
            </div>
          </div>

          {riskMeasuresList.map((riskMeasure, index) => (
            <div className="body-row-container-x">
              <div className="body-row-container-child-left-x">
                {riskMeasure.columnName}
              </div>
              <div className="body-row-container-child-right-x">
                <DropDown
                  options={unitsList}
                  disabled={quickUnitChangeToggle}
                  selected={
                    riskUnits[riskMeasure.columnName]
                      ? riskUnits[riskMeasure.columnName]
                      : existingRiskUnits[riskMeasure.columnName]
                  }
                  orientation="v"
                  extStyles={{ width: '12vw' }}
                  ddExtStyles={{ width: '12vw' }}
                  onSelect={(val) => {
                    changeUnit(val, index);
                  }}
                />
              </div>
            </div>
          ))}

          <div className="feed-popup-bottom-x">
            <GenButton
              type="1"
              title="APPLY"
              fill={true}
              extStyle={{ width: '7vw', height: '3.3vh' }}
              clickAction={apply}
            />
            <GenButton
              type="1"
              title="RESET"
              fill={false}
              extStyle={{ width: '7vw', height: '3.5vh' }}
              clickAction={resetDropDown}
            />
            <GenButton
              type="1"
              title="CLOSE"
              fill={false}
              extStyle={{ width: '7vw', height: '3.5vh' }}
              clickAction={handleClose}
            />
          </div>
        </div>
      </GenericPopup>
    </div>
  );
};

export default UnitWindow;

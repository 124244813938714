import styled from 'styled-components';
import '../../../global.css';
import { useRef, useEffect } from 'react';

const IconDDMContainer = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  ${(props) => props.styles}
`;

const IconDDMContainer2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.active ? '#000' : '#cecece')};
  border-radius: 0.5vh;
  height: 4vh;
  width: 4vh;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 4.5vh;
  right: 0;
  height: fit-content;
  max-height: 40vh;
  width: 10vw;
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  height: 3vh;
  width: 3vh;
`;

const DDContainer = styled.div`
  height: 5vh;
  width: 100%;
  ${(props) => props.showBorder && 'border-bottom: 1px solid #cecece;'}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: white;
  border-radius: 0.5vh;
`;
const DDText = styled.span`
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  margin-left: 0.5vw;
  color: ${(props) => (props.selected ? 'var(--primary-color)' : '#cecece')};
`;

export default function IconddInternal({
  settings = {
    icon: {},
    options: ['option 1', 'option 2', 'Option 3'],
    selected: 'option 1',
    extStyles: {},
    visible: true,
  },
  settingModifier,
}) {
  const contRef = useRef(null);
  useEffect(() => {
    const clickOutside = (e) => {
      if (contRef.current && !contRef.current.contains(e.target)) {
        settingModifier({ ...settings, visible: false });
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [contRef, settingModifier, settings]);

  return (
    <IconDDMContainer styles={settings.extStyles}>
      <IconDDMContainer2
        onClick={() =>
          settingModifier({ ...settings, visible: !settings.visible })
        }
        active={settings.visible}
      >
        <Icon src={settings.icon} />
      </IconDDMContainer2>
      <Dropdown visible={settings.visible} ref={contRef}>
        {(settings.options || []).map((item, index) => (
          <DDContainer
            showBorder={index !== settings.options.length - 1}
            onClick={() => settingModifier({ ...settings, selected: item })}
          >
            <DDText selected={item === settings.selected}>{item}</DDText>
          </DDContainer>
        ))}
      </Dropdown>
    </IconDDMContainer>
  );
}

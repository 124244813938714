import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TableComp from '../../sharedComponents/Table/Table';
import './OrderBook.scss';
import {
  orderTrade,
  updatePageNumberOrder,
  updatePostPerPageOrder,
} from '../../../actions/LiveTradingAction';
import exicon from '../../../assets/img/ex-icon.png';
import { TableEntries } from '../../sharedComponents/TableEntries/TableEntries';
import { useLocation } from 'react-router';
import { updateSortCulmnAnalyzer } from '../../../actions/strategyAnalyzerAction';
import { MARKET_PAGES, SHOW_POSTS } from '../../../utils/constant';
import { GenButton, GenSearchBox } from '../..';
import { MakeRequest } from '../../../utils';
import { TableSettings } from '../../../components/sharedComponents/customComponent';
import { ColumnArrangeIcon } from '../../../assets';
import { TableOrderChangeActions } from '../../../reducers/table_order_reducer';

const OrderBook = (props) => {
  const { editAllowed } = props;
  const {
    liveTradingReducer: {
      orderTradeData,
      postPerPage,
      pageNumber,
      orderBookloaded,
    },
    strategyAnalyzerReducer: { sortingType, sortColumn },
  } = useSelector((state) => state);

  const [showPosts] = useState(SHOW_POSTS);
  const [postsPerPage, setPostsPerPage] = useState(MARKET_PAGES); //state where one needs to change the count of entries that are to be diplayed
  const [strategyData, setStrategyData] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([...setCurrentPostfun()]);
  const [userShow, setUserShow] = useState([...strategyData]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [orderwindow, setOrderwindow] = useState(false);
  const [searchAcc, setSearchAcc] = useState('');
  const [toUpdate, setToUpdate] = useState(false);

  useEffect(() => {
    let orderPath = location.pathname;
    if (orderPath === '/window/trading') {
      setOrderwindow(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setCurrentPostfun();
    if (userShow && currentPosts.length) {
      let currPage = userShow.length % currentPosts.length;
      if (currPage === 0) {
        paginate(pageNumber === 1 ? pageNumber : pageNumber - 1);
      } else {
        paginate(pageNumber);
      }
    }
  }, [postsPerPage]);

  useEffect(() => {
    if (toUpdate) {
      setToUpdate(false);
      dispatch(orderTrade('', true));
    }
  }, [toUpdate]);

  useEffect(() => {
    if (searchAcc === '') {
      dispatch(orderTrade());
    }
  }, [searchAcc]);

  // update number or posts
  const handleShowPost = (e) => {
    if (e.target.value !== '') {
      dispatch(updatePageNumberOrder(1));
      dispatch(updatePostPerPageOrder(Number(e.target.value)));
      setPostsPerPage(e.target.value);
      dispatch(orderTrade());
    }
  };
  // open orders in new window
  const handleOpenOrder = (id, type, accId = 0) => {
    let newWindow = window.open(
      `/window/Order/OrderBookDrilldown/${window.btoa(type)}/${window.btoa(
        id
      )}?accId=${accId}`,
      '_blank',
      'top=100, left=100, width=1857, height=600, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  // Display table data

  const [table_column, set_table_column] = useState({
    display: [
      {
        lebel: 'Exchange',
        field: 'Exchange',
        sort: true,
        renderHtml: (params) => {
          return <>{params.Exchange}</>;
        },
      },
      {
        lebel: 'Entity ID',
        field: 'AccountId',
        sort: true,
        renderHtml: (params) => {
          return <>{params.AccountName.split('-')[0]}</>;
        },
      },
      {
        lebel: 'Entity Name',
        field: 'AccountName',
        sort: true,
        renderHtml: (params) => {
          return <>{params.AccountName.split('-')[1]}</>;
        },
      },
      {
        lebel: 'Security',
        field: 'Security',
        sort: true,
        renderHtml: (params) => {
          return <>{params.Security}</>;
        },
      },
      {
        lebel: 'Currency',
        field: 'Currency',
        sort: true,
        renderHtml: (params) => {
          return <>{params.Currency}</>;
        },
      },
      {
        lebel: 'All Open Orders',
        field: 'OpenOrders',
        sort: true,
        renderHtml: (params) => {
          return (
            <a
              onClick={() =>
                handleOpenOrder(params.Security, 0, params.AccountId)
              }
            >
              {params.OpenOrders}
            </a>
          );
        },
      },
      {
        lebel: 'Open Manual Orders',
        field: 'OpenManualOrders',
        sort: true,
        renderHtml: (params) => {
          return (
            <a
              onClick={() =>
                handleOpenOrder(params.Security, 1, params.AccountId)
              }
            >
              {params.OpenManualOrders}
            </a>
          );
        },
      },
      {
        lebel: 'Trades',
        field: 'Trades',
        sort: true,
        renderHtml: (params) => {
          return (
            <a
              onClick={() =>
                handleOpenOrder(params.Security, 2, params.AccountId)
              }
            >
              {params.Trades}
            </a>
          );
        },
      },
      {
        lebel: 'Net Position',
        field: 'NetQuantity',
        sort: true,
        renderHtml: (params) => {
          return <>{params.NetQuantity}</>;
        },
      },
      {
        lebel: 'PnL (R.)',
        field: 'realisedPnl',
        sort: true,
        renderHtml: (params) => {
          return <>{params.realisedPnl}</>;
        },
      },
      {
        lebel: 'PnL (UnR.)',
        field: 'unrealisedPnl',
        sort: true,
        renderHtml: (params) => {
          return <>{params.unrealisedPnl}</>;
        },
      },
      {
        lebel: 'Last Update Time',
        field: 'LastUpdateTime',
        sort: true,
        renderHtml: (params) => {
          return <>{params.LastUpdateTime}</>;
        },
      },
    ],
    orignal: [
      {
        col_id: 'Exchange',
        lebel: 'Exchange',
        field: 'Exchange',
        sort: true,
        renderHtml: (params) => {
          return <>{params.Exchange}</>;
        },
      },
      {
        col_id: 'AccountId',
        lebel: 'Entity ID',
        field: 'AccountId',
        sort: true,
        renderHtml: (params) => {
          return <>{params.AccountName.split('-')[0]}</>;
        },
      },
      {
        col_id: 'AccountName',
        lebel: 'Entity Name',
        field: 'AccountName',
        sort: true,
        renderHtml: (params) => {
          return <>{params.AccountName.split('-')[1]}</>;
        },
      },
      {
        col_id: 'Security',
        lebel: 'Security',
        field: 'Security',
        sort: true,
        renderHtml: (params) => {
          return <>{params.Security}</>;
        },
      },
      {
        col_id: 'Currency',
        lebel: 'Currency',
        field: 'Currency',
        sort: true,
        renderHtml: (params) => {
          return <>{params.Currency}</>;
        },
      },
      {
        col_id: 'OpenOrders',
        lebel: 'All Open Orders',
        field: 'OpenOrders',
        sort: true,
        renderHtml: (params) => {
          return (
            <a
              onClick={() =>
                handleOpenOrder(params.Security, 0, params.AccountId)
              }
            >
              {params.OpenOrders}
            </a>
          );
        },
      },
      {
        col_id: 'OpenManualOrders',
        lebel: 'Open Manual Orders',
        field: 'OpenManualOrders',
        sort: true,
        renderHtml: (params) => {
          return (
            <a
              onClick={() =>
                handleOpenOrder(params.Security, 1, params.AccountId)
              }
            >
              {params.OpenManualOrders}
            </a>
          );
        },
      },
      {
        col_id: 'Trades',
        lebel: 'Trades',
        field: 'Trades',
        sort: true,
        renderHtml: (params) => {
          return (
            <a
              onClick={() =>
                handleOpenOrder(params.Security, 2, params.AccountId)
              }
            >
              {params.Trades}
            </a>
          );
        },
      },
      {
        col_id: 'NetQuantity',
        lebel: 'Net Position',
        field: 'NetQuantity',
        sort: true,
        renderHtml: (params) => {
          return <>{params.NetQuantity}</>;
        },
      },
      // {
      //   lebel: 'PnL',
      //   field: 'NetPositions',
      //   sort: true,
      //   renderHtml: (params) => {
      //     return <>{params.NetPositions}</>;
      //   },
      // },
      {
        col_id: 'LastUpdateTime',
        lebel: 'Last Update Time',
        field: 'LastUpdateTime',
        sort: true,
        renderHtml: (params) => {
          return <>{params.LastUpdateTime}</>;
        },
      },
      {
        col_id: 'realisedPnl',
        lebel: 'PnL (R.)',
        field: 'realisedPnl',
        sort: true,
        renderHtml: (params) => {
          return <>{params.realisedPnl}</>;
        },
      },
      {
        col_id: 'unrealisedPnl',
        lebel: 'PnL (UnR.)',
        field: 'unrealisedPnl',
        sort: true,
        renderHtml: (params) => {
          return <>{params.unrealisedPnl}</>;
        },
      },
    ],
  });
  // update number for pagination
  const paginate = (pageNumber) => {
    dispatch(updatePageNumberOrder(pageNumber));
    setToUpdate(true);
  };
  // set number in the field for the  posts show in the table
  function setCurrentPostfun() {
    const indexOfLastPost = pageNumber * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsdata;
    if (strategyData) {
      currentPostsdata = strategyData.slice(indexOfFirstPost, indexOfLastPost);
    }
    return currentPostsdata;
  }

  // sorting function in the table column
  const sortByName = (type, array, key) => {
    var byName = array.slice(0);
    let stringColumn = ['Exchange', 'Security', 'Currency'];

    if (key) {
      if (stringColumn.indexOf(key) !== -1) {
        if (type === 'desc') {
          byName.sort(function (a, b) {
            var x = a[key]?.toLowerCase();
            var y = b[key]?.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
        } else {
          byName.sort(function (a, b) {
            var x = a[key]?.toLowerCase();
            var y = b[key]?.toLowerCase();
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
      } else {
        if (type === 'desc') {
          byName.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
          });
        } else {
          byName.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
      }
    }
    return byName;
  };

  const sortingFuction = (type, column) => {
    dispatch(
      updateSortCulmnAnalyzer({ sortColumn: column.field, sortingType: type })
    );
  };

  let finalTableData = sortByName(
    sortingType,
    orderTradeData ? orderTradeData.entries : [],
    sortColumn
  );

  const { order_trades } = useSelector((state) => state.TableOrderReducer);

  const [table_settings, set_table_settings] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [{ label: 'Reorder / Hide & Unhide', id: 20 }],
        },
      ],
    },
  ]);

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    switch (temp_index[0]) {
      case 'P':
        // * set parent data
        break;
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          let tempColumns = [...table_settings];
          tempColumns.push({ type: 'COL_RE' });
          set_table_settings(tempColumns);
        }
        break;
      case 'B':
        const tempColumn = [...table_settings];
        tempColumn.pop();
        set_table_settings(tempColumn);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    TableOrderChangeActions.set_OT_init_data(dispatch);
  }, []);

  useEffect(() => {
    const temp = [];

    for (let column of order_trades.viewData) {
      const newColumn = table_column.orignal.filter(
        (item) => item.col_id === column.variableName
      )[0];
      if (newColumn) {
        temp.push(newColumn);
      }
    }
    set_table_column({ ...table_column, display: temp });
  }, [order_trades.viewData]);

  return (
    <div className={orderwindow ? 'orderWindow order-book' : 'order-book'}>
      <div className="search-order">
        <div>
          <TableEntries
            role="postPerPage"
            postList={showPosts}
            value={postsPerPage}
            postHandler={handleShowPost}
            paginate={paginate}
            currentPage={pageNumber}
          />
        </div>

        <GenSearchBox
          value={searchAcc}
          changeListener={(val) => setSearchAcc(val)}
          extStyles={{ margin: 'auto' }}
          message="Search"
        />

        <div style={{ marginLeft: 'auto', marginRight: '-15vw' }}>
          <GenButton
            title="VIEW TRADES"
            clickAction={() => handleOpenOrder(-1, 2, -1)}
            fill={false}
            extStyle={{ margin: '1vh', width: '7.5vw' }}
          />
        </div>

        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          <GenButton
            fill={false}
            icon={exicon}
            title="EXPORT EXCEL"
            clickAction={async () => {
              const res = await MakeRequest(
                '/excel/generate/order-book'
              ).download('OrderBook.xlsx');
              console.log(res);
            }}
            extStyle={{ margin: 'auto' }}
          />
        </div>
        <TableSettings
          menuOptions={table_settings}
          clickAction={colModifier}
          updateColCallBack={() => {}}
          screenName="ORDER_TRADES_TS"
          allowed={editAllowed}
        />
      </div>
      <div>
        {orderBookloaded ? (
          <div style={{ overflowX: 'auto' }}>
            <TableComp
              tableCoulumn={table_column.display}
              className={'table table-striped'}
              TableData={finalTableData}
              sorting={true}
              sortColumn={sortColumn}
              sortingFuction={sortingFuction}
              showPagination={true}
              paginate={paginate}
              postsPerPage={postPerPage}
              currentPage={pageNumber}
              postsLength={orderTradeData && orderTradeData.numOfEntries}
              elipsisPagination={true}
            ></TableComp>
          </div>
        ) : (
          <Spinner animation="border" variant="dark" />
        )}
      </div>
    </div>
  );
};

export default OrderBook;

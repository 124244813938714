import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './SquareOffWindow.scss';
import SimpleSnackbar from '../../../../sharedComponents/Toast/Toast';
import SimpleReactValidator from 'simple-react-validator';
import UiInput from '../../../../sharedComponents/Input';
import MultiDropdown from '../../../../sharedComponents/MultiDropdown/MultiDropdown';
import {
  getSqOff,
  strategyAnalyzerButton,
} from '../../../../../actions/strategyAnalyzerAction';
import { seo } from '../../../../../utils/seo';
import Popup from '../../../../sharedComponents/Popup/Popup';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../../../../sharedComponents/keyPressEventHandler';

const SquareOffWindow = () => {
  const {
    strategyAnalyzerReducer: { sqAction },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [validateToasetr, setValidateToasetr] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [modalSubmitStrategy, setModalSubmitStrategy] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [modalClear, setModalClear] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState();
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [inputData, setInputData] = useState({});
  const [editFlagIndex, setEditFlagIndex] = useState(null);
  const [marketForm, setMarketForm] = useState([]);
  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));
  const [message, setMessage] = useState('')
  const [popup, setPopup] = useState({type:'', message:'', buttons:'', state: false});
  let sqData = JSON.parse(localStorage.getItem('forceSquareOff'));
  const [squareSelected, setSqaureSelected] = useState({
    enumName: '',
    type: '',
    args: '',
  });

  const marketHandler = (index, data) => {
    let field = {
      enumName: data.data,
      type: data.type,
      args: data.arguements,
    };
    setSqaureSelected(field);
    setMarketForm([data]);
  };

  let eqInd = 1;
  const equationHandler = (e, eqIndex) => {};
  const handleClear = (clear) => {
    let clearForm = {};
    if (clear) {
      clearForm = {};
      setFormSubmit(false);
    }
    setModalClear(false);
  };

  useEffect(() => {
    dispatch(getSqOff());
    seo({ title: 'Force Square-off' });
  }, []);

// set default popups after modal
useEffect(() => {
  switch(popup.type){
    case 'squreoff':
    setPopup({...popup, message:message ? msgFunction() : 'Successfully submitted data', buttons:'singleButton'})
    break;
  }
}, [popup.type, message])

// set functionalty for popup
const actionOnpopup = (e, type) => {
    if(type == 'CLOSE'){
      setPopup({state:false})
    } 
}

  const submitForm = () => {
    if (validator.current.allValid()) {
      setValidateToasetr(false);
      handleSubmitStrategy();
    } else {
      setValidateToasetr(true);
      validator.current.showMessages();
      forceUpdate(1);
      window.scrollTo(0, 0);
    }
    setFormSubmit(true);
  };

  const handleSubmitStrategy = async () => {
    let error = {};
    if (Object.keys(error).length) {
      setToaster(true);
    } else {
      setToaster(false);
      let sqMessage = await dispatch(
        strategyAnalyzerButton({
          buttonType: 'SQOFF',
          strategyList: sqData.id,
          action: squareSelected,
        })
      );
      if (typeof Object.values(sqMessage)[0] == 'string') {
        setMessage(Object.values(sqMessage)[0])
      }
      else{
      let btnType = 'sqOff Request sent Successfully'
      setMessage(btnType)
      }
      setFormSubmit(false);
      setModalSubmitStrategy(true);
      setPopup({type:'squreoff', state:true})
    }
  };
  const msgFunction = () =>{
    let strategyId = JSON.parse(localStorage.getItem('forceSquareOff'))?.name
    return(
      <>
        <span className="button-req">{message}</span>
        {!message.includes('INVALID')? <p>
          Strategy IDs:{' '}
          {strategyId &&
            strategyId.length &&
            strategyId
              .map((el) => {
                return `${el.id} - ${el.name}`;
              })
              .join(', ')}
        </p> : null}
        
        </>
    )
  }
  validator.current.purgeFields();
  return (
    <div className="previewModalRootWrpaer sq-off-window">
      <Hotkeys
         keyName="esc"
         onKeyDown={(event)=>{handleEscapeKeyEvent(event)}}
         />
      <div className="previewmodal">
        <div className="templateNameWrapper">
          <React.Fragment className="strtegy-exchange-container">
            <Row>
              <Col sm="6">
                <div className="field">
                  <label>List of Strategy ID</label>
                  <UiInput
                    className="disable-pointers"
                    value={sqData?.name
                      ?.map((i, index) => { 
                        return i.id;
                      })
                      .join(', ')}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </div>
        <div className="middleWrapper">
          <Row>
            <Col>
              <Col md={3} className="sqoff-order-heading">
                Execution Logic
              </Col>
              <Col md={3} className="sqOff-box">
                <div
                className="dropdown"
                  className={
                    validator.current.message(
                      'force_square_off',
                      squareSelected.enumName,
                      'required'
                    ) && formSubmit
                      ? 'error-input dropdown'
                      : 'dropdown'
                  }
                >
                  <MultiDropdown
                    eqIndex={eqInd}
                    listData={sqAction}
                    marketForm={marketForm}
                    setEditFlagIndex={setEditFlagIndex}
                    setMarketForm={setMarketForm}
                    marketHandler={marketHandler}
                    setInputData={setInputData}
                    setSelectedCondition={setSelectedCondition}
                    placeHolder={'TOB+x,Market'}
                    setEquationValues={equationHandler}
                    setStartTime={setStartTime}
                    startTime={startTime}
                    setEndtime={setEndtime}
                    endTime={endTime}
                    fieldId={'sqOff'}
                  />
                  {}
                </div>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col sm="12"></Col>
          </Row>
          <div className="submitsbuttons modal-footer">
            
            <Button onClick={() => submitForm()}>Submit</Button>
          </div>
        </div>
      </div>
      
        {popup.state ? (
          <Popup
          actionButton = {actionOnpopup}
          popupDetails = {popup}
        />
    ) : null}
      {validateToasetr ? (
        <SimpleSnackbar
          toaster={validateToasetr}
          setToaster={setValidateToasetr}
          message={'Please fill mandatory fields.'}
        />
      ) : null}

    </div>
  );
};

export default SquareOffWindow;

import React, { useEffect, useState } from 'react';
import {
  forgotPassword,
  clearUserError,
} from '../../actions/forgotPasswordAction';
import { useDispatch, connect } from 'react-redux';
import UiButton from '../sharedComponents/Button';
import UiInput from '../sharedComponents/Input';
import './LoginStyle.scss';
import Line from '../../assets/img/Line 252.svg';
import { CircularProgress } from '@material-ui/core';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ForgotPassword = ({ setSection, error, correctUser, user, otp }) => {
  const [userName, setUsername] = useState(null);
  const [userError, setError] = useState();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (text) => {
    setUsername({ username: text.target.value });
    setError('');
  };

  const handleUsername = async (e) => {
    e.preventDefault();
    if (userName === null) {
      setError('Please enter Username');
    } else if (userName && userName.username === '') {
      setError('Please enter Username');
    } else {
      setLoader(true);
      let result = await dispatch(forgotPassword(userName));
      if (!result.isUserValid) {
        setError(result.message ? result.message : 'Server Error!');
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (correctUser) {
      setSection('enterOtp');
    }
    if (user) {
      setUsername({ username: user });
    }
    if (error) {
      setError(error);
      dispatch(clearUserError(''));
    }
  }, [user, correctUser, error]);

  return (
    <form className="forgot-wrapper">
      <h1>Forgot Password ?</h1>
      <div className={'line_wrapper'}>
        <img src={Line} alt="" className="line-img" />
      </div>
      <p>
        Enter your username and we will send an OTP to your registered email id
        to reset your password.
      </p>
      <div className={userError ? 'form-error form-group' : 'form-group'}>
        <label htmlFor="exampleInputEmail1">Username</label>
        <UiInput
          type="text"
          className="form-control w-auto h-auto d-inline-block "
          name="forgotpassword"
          id="forgotpassword"
          value={userName ? userName.userName : user}
          handlerfunction={handleInputChange}
        />
        {userError && (
          <span className="error-tag">
            <WarningAmberIcon sx={{ fontSize: '16px', mr: '7px', mt: '4px' }} />
            {userError}
          </span>
        )}
        <p
          style={{ color: '#0078D7', textAlign: 'right', cursor: 'pointer' }}
          onClick={() => {
            // history.push('/');
          }}
        ></p>
        <div className="text-right">
          <small
            style={{ display: 'inline-block' }}
            className="form-text"
            onClick={() => {
              // history.push('/');
            }}
          >
            Back to Login
          </small>
        </div>
      </div>
      {/* <UiButton
        name="Send OTP"
        id="sendOTP"
        className="btn btn-primary"
        onClickFunction={handleUsername}
      /> */}
      <button
        type="submit"
        id="sendOTP"
        className="btn btn-primary login"
        disabled={loader}
        onClick={handleUsername}
      >
        {loader ? (
          <CircularProgress disableShrink size={25} color="white" />
        ) : (
          'Send OTP'
        )}
      </button>
    </form>
  );
};
const mapStateToProps = (state) => {
  return {
    correctUser: state.forgotReducer.data,
    error: state.forgotReducer.errors,
    user: state.forgotReducer.username,
  };
};

export default connect(mapStateToProps, null)(ForgotPassword);

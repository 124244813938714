import React, { useEffect, useState } from 'react';
import './Position.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setStrategyTemplateForm,
  setBuilderPositions,
  setConstants,
  setUsedNickName,
  setConditions,
} from '../../../../../actions/strategyBuilderAction';
import LogicalCondtitions from './LogicalConditions';
import MultiDropdown2 from '../../../../sharedComponents/MultiDropdown2/MultiDropdown2';
import UiSelector from '../../../../sharedComponents/Dropdowns';
import Switch from '../../../../sharedComponents/switchOnOff';
import NewDropdown from '../../../../sharedComponents/NewDropdown/NewDropdown';
import { ButtonAddLeg, SwitchSB } from '../../components';
import { DeleteBinHover, DeleteIcon } from '../../../../../assets';

const AddPosition = (props) => {
  const {
    legIndex,
    setShowAlgo,
    showAlgo,
    algo,
    positionIndex,
    positionRow,
    setQuantity,
    setPricing,
    setTotalQty,
    algoStatus,
    validator,
    formSubmit,
    id,
  } = props;
  const [pricingLogicFlag, setPricingLogicFlag] = useState(false);
  const [totalQtyLogicFlag, setTotalQtyLogicFlag] = useState(false);
  const [quantityLogicFlag, setQuantityLogicFlag] = useState(false);
  const [showAlgoFlag, setShowAlgoFlag] = useState(false);
  const [marketForm, setMarketForm] = useState([]);
  const [marketFormP, setMarketFormP] = useState([]);
  const [editFlagIndex, setEditFlagIndex] = useState(null);
  const [inputData, setInputData] = useState({});
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [equationValues, setEquationValues] = useState([
    { type: 'OPERAND', enumName: '', value: '' },
  ]);
  const [equationValuesP, setEquationValuesP] = useState([
    { type: 'OPERAND', enumName: '', value: '' },
  ]);
  const dispatch = useDispatch();

  const {
    strategyBuilderReducer: {
      strategyTemplateForm,
      pricingQuantOperation,
      quantityCondition,
      totalQtyCondition,
      pricingCondition,
      isEdit,
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);

  const marketHandler = (index, data) => {
    let marketFormData = [...marketForm];
    if (data === 'REMOVE') {
      let obj = marketFormData[index];
      if (obj.conditionName === 'New Template Parameter') {
        let removeData = {
          name: obj.arguements[0].argValue,
          value: null,
        };
        dispatch(
          setConstants({
            obj: removeData,
            legIndex: '',
            key: 'constants',
            remove: true,
          })
        );
      }
      marketFormData[index] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };
    } else {
      if (!marketFormData[index]) {
        marketFormData[index] = data;
        if (data?.conditionName === 'New Template Parameter') {
          let obj = {
            name: data.arguements[0].argValue,
            value: null,
          };
          dispatch(
            setConstants({
              obj,
              legIndex,
              key: 'constants',
              remove: false,
              edit: false,
            })
          );
        }
      } else {
        // editing data
        if (data?.conditionName === 'New Template Parameter') {
          let editIndex;
          if (
            strategyTemplateForm.constants &&
            strategyTemplateForm.constants.length
          ) {
            editIndex = strategyTemplateForm.constants.findIndex(
              (el) => el.name === marketFormData[index]?.arguements[0]?.argValue
            );
          }
          let obj = {
            name: data.arguements[0].argValue,
            value: null,
          };
          if (editIndex > -1) {
            dispatch(
              setConstants({
                obj,
                legIndex,
                key: 'constants',
                remove: false,
                edit: true,
                editIndex,
              })
            );
          } else {
            dispatch(
              setConstants({
                obj,
                legIndex,
                key: 'constants',
                remove: false,
                edit: false,
              })
            );
          }
        }
        marketFormData.splice(index, 1, data);
      }
    }
    setMarketForm(marketFormData);
  };

  const equationHandler = (data, eqIndex) => {
    let newEquation = [...equationValues];
    if (data === 'REMOVE') {
      let obj = newEquation[eqIndex];
      newEquation[eqIndex] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };
    } else {
      if (!newEquation[eqIndex]) {
        newEquation[eqIndex] = data;
      } else {
        newEquation.splice(eqIndex, 1, data);
      }
    }
    setEquationValues(newEquation);
  };
  const handleRemove = (i) => {
    //funtion to remove the position
    let form = { ...strategyTemplateForm };
    form.entryLegs[legIndex].position.splice(i, 1);
    dispatch(setStrategyTemplateForm(form));
  };
  const tabHandler = (orientation, index) => {
    //funtion for handling buy sell
    let form = { ...strategyTemplateForm };
    form.entryLegs[legIndex].position[index].orientation = orientation;
    dispatch(setStrategyTemplateForm(form));
  };

  useEffect(() => {
    try {
      if (quantityLogicFlag) {
        let updatedPosition = marketForm.map((el, index) => {
          return {
            type: el.type,
            data: el.data,
            arguements: el.arguements,
          };
        });
        dispatch(
          setBuilderPositions({
            updatedPosition,
            legIndex: legIndex || 0,
            positionIndex,
            key: 'position',
            subKey: 'quantity',
          })
        );
      }
      if (totalQtyLogicFlag) {
        let updatedPosition = marketForm.map((el, index) => {
          return {
            type: el.type,
            data: el.data,
            arguements: el.arguements,
          };
        });
        dispatch(
          setBuilderPositions({
            updatedPosition,
            legIndex: legIndex || 0,
            positionIndex,
            key: 'position',
            subKey: 'totalQuantityLogic',
          })
        );
      }
      if (
        marketForm[marketForm.length - 1]?.conditionName ===
        'New Template Parameter'
      ) {
        let obj = {
          name: marketForm[marketForm.length - 1].arguements[0].argValue,
          value: null,
        };
        dispatch(setConstants({ obj, legIndex, key: 'constants' }));
      }
      let updatedForm = marketForm.map((el) => {
        return {
          type: el.type,
          data: el.data,
          displayName: el.displayName ? el.displayName : el.data,
          description: el.description ? el.description : '',
          arguements: el.arguements,
        };
      });
      let field = updatedForm;
      let legIndex = 0;
      dispatch(setConditions({ field, legIndex, key: 'algoExecution' }));
    } catch (error) {
      console.log(error.message);
    }
  }, [marketForm]);
  const inputHandler = (e) => {
    //funtion to take input for the input fields in the dropdown
    let form = { ...strategyTemplateForm };
    if (e.target.value !== '') {
      let scurityNickName = strategyTemplateForm.securities.filter(
        (value) => !value.isAdditional && value.nickname.length
      )[e.target.value].nickname;
      let positionLotSize = strategyTemplateForm.securities.filter(
        (value) => !value.isAdditional && value.nickname.length
      )[e.target.value].lotSize;
      form.entryLegs[legIndex].position[positionIndex].securityIdx = Number(
        e.target.value
      );
      form.entryLegs[legIndex].position[positionIndex].security =
        scurityNickName;
      form.entryLegs[legIndex].position[positionIndex].lotSize =
        positionLotSize;
      let nicknameObj = {
        condition: 'position',
        id: `position${positionIndex}`,
        value: e.target.value,
      };

      dispatch(setUsedNickName(nicknameObj));
      dispatch(setStrategyTemplateForm(form));
    } else if (e.target.value === '') {
      let scurityNickName = strategyTemplateForm.securities.filter(
        (value) => !value.isAdditional && value?.nickname.length
      )[e.target.value]?.nickname;
      form.entryLegs[legIndex].position[positionIndex].security =
        scurityNickName;
      dispatch(setStrategyTemplateForm(form));
    }
  };
  useEffect(() => {
    if (
      strategyTemplateForm.entryLegs[legIndex].position[positionIndex]
        .security === ''
    ) {
      setPricingLogicFlag(false);
      setQuantityLogicFlag(false);
      setTotalQtyLogicFlag(false);
      setTotalQty(false);
      setQuantity(false);
      setPricing(false);
    }
    if (showAlgo && algoStatus) {
      setPricingLogicFlag(false);
      setQuantityLogicFlag(false);
      setTotalQtyLogicFlag(false);
      setTotalQty(false);
      setQuantity(false);
      setPricing(false);
    }
  }, [strategyTemplateForm, algoStatus]);
  useEffect(() => {
    let currPosition =
      strategyTemplateForm.entryLegs[legIndex].position[positionIndex];

    if (
      isEdit &&
      currPosition &&
      currPosition.quantity &&
      currPosition.quantity.length > 0
    ) {
      let quantOperations = pricingQuantOperation.map((el) => {
        return {
          type: el.type,
          displayName: el.displayName,
          data: el.values,
          enumName: el.enumName ? el.enumName : '',
        };
      });
      let quantDropDown = [...quantityCondition, ...quantOperations];
      if (
        currPosition &&
        currPosition.quantity &&
        currPosition.quantity.length > 0
      ) {
        let foundQuant = [];
        for (let i = 0; i < currPosition.quantity.length; i++) {
          for (let j = 0; j < quantDropDown.length; j++) {
            for (let k = 0; k < quantDropDown[j].data.length; k++) {
              if (
                quantDropDown[j].data[k].enumName ===
                  currPosition.quantity[i].data?.trim() ||
                (currPosition.quantity[i].data?.trim() === 'PARAMETER' &&
                  quantDropDown[j].data[k].enumName ===
                    currPosition.quantity[i]?.arguements[0]?.argName)
              ) {
                let finalObj = {
                  ...currPosition.quantity[i],
                  conditionName: quantDropDown[j].data[k].displayName,
                };
                foundQuant.push(finalObj);
              }
            }
          }
        }
        let finalQuant = foundQuant.map((el, index) => {
          return {
            ...el,
            eqIndex: index,
          };
        });

        let eqArr = foundQuant.map((elm, index) => {
          return {
            enumName: elm.data,
            type: elm.type === 'OPERATION' ? 'OPERATOR' : 'OPERAND',
            value: elm.conditionName,
          };
        });

        setMarketForm(finalQuant);
        setEquationValues(eqArr);
      }
      setQuantityLogicFlag(true);
      setQuantity(true);
    }
    if (
      isEdit &&
      currPosition &&
      currPosition.pricing &&
      currPosition.pricing.length > 0
    ) {
      let pricingOperations = pricingQuantOperation.map((el) => {
        return {
          type: el.type,
          displayName: el.displayName,
          data: el.values,
          enumName: el.enumName ? el.enumName : '',
        };
      });
      let pricingDropDown = [...pricingCondition, ...pricingOperations];
      if (
        currPosition &&
        currPosition.pricing &&
        currPosition.pricing.length > 0
      ) {
        let foundPricing = [];
        for (let i = 0; i < currPosition.pricing.length; i++) {
          for (let j = 0; j < pricingDropDown.length; j++) {
            for (let k = 0; k < pricingDropDown[j].data.length; k++) {
              if (
                pricingDropDown[j].data[k].enumName ===
                  currPosition.pricing[i].data?.trim() ||
                (currPosition.pricing[i].data?.trim() === 'PARAMETER' &&
                  pricingDropDown[j].data[k].enumName ===
                    currPosition.pricing[i]?.arguements[0]?.argName)
              ) {
                let finalObj = {
                  ...currPosition.pricing[i],
                  conditionName: pricingDropDown[j].data[k].displayName,
                };
                foundPricing.push(finalObj);
              }
            }
          }
        }
        let finalPricing = foundPricing.map((el, index) => {
          return {
            ...el,
            eqIndex: index,
          };
        });

        let eqArr = foundPricing.map((elm, index) => {
          return {
            enumName: elm.data,
            type: elm.type === 'OPERATION' ? 'OPERATOR' : 'OPERAND',
            value: elm.conditionName,
          };
        });

        setMarketFormP(finalPricing);
        setEquationValuesP(eqArr);
      }
      setPricingLogicFlag(true);
      setPricing(true);
    }
    if (
      isEdit &&
      currPosition &&
      currPosition.totalQuantityLogic &&
      currPosition.totalQuantityLogic.length > 0
    ) {
      let totalQtyOperations = pricingQuantOperation.map((el) => {
        return {
          type: el.type,
          displayName: el.displayName,
          data: el.values,
          enumName: el.enumName ? el.enumName : '',
        };
      });
      let totalQtyDropDown = [...quantityCondition, ...totalQtyOperations];
      if (
        currPosition &&
        currPosition.totalQuantityLogic &&
        currPosition.totalQuantityLogic.length > 0
      ) {
        let foundTotalQuant = [];
        for (let i = 0; i < currPosition.totalQuantityLogic.length; i++) {
          for (let j = 0; j < totalQtyDropDown.length; j++) {
            for (let k = 0; k < totalQtyDropDown[j].data.length; k++) {
              if (
                totalQtyDropDown[j].data[k].enumName ===
                  currPosition.totalQuantityLogic[i].data?.trim() ||
                (currPosition.totalQuantityLogic[i].data?.trim() ===
                  'PARAMETER' &&
                  totalQtyDropDown[j].data[k].enumName ===
                    currPosition.totalQuantityLogic[i]?.arguements[0]?.argName)
              ) {
                let finalObj = {
                  ...currPosition.totalQuantityLogic[i],
                  conditionName: totalQtyDropDown[j].data[k].displayName,
                };
                foundTotalQuant.push(finalObj);
              }
            }
          }
        }
        let finalTotalQuant = foundTotalQuant.map((el, index) => {
          return {
            ...el,
            eqIndex: index,
          };
        });

        let eqArr = foundTotalQuant.map((elm, index) => {
          return {
            enumName: elm.data,
            type: elm.type === 'OPERATION' ? 'OPERATOR' : 'OPERAND',
            value: elm.conditionName,
          };
        });

        setMarketForm(finalTotalQuant);
        setEquationValues(eqArr);
      }
      setTotalQtyLogicFlag(true);
      setTotalQty(true);
    }
  }, [isEdit]);

  return (
    <div
      className={
        isApproveReject
          ? 'approveReject-pointer close_position'
          : 'close_position'
      }
      key={`${positionRow.orientation}-${positionIndex}`}
    >
      <div className={positionRow.orientation === 'Fwd' ? 'buy' : 'buy sell'}>
        <div className="position-parent-container">
          <div className="position-container">
            <SwitchSB
              setting={{ label1: 'Fwd', label2: 'Rev' }}
              clickAction={(val) => tabHandler(val, positionIndex)}
              selection={positionRow.orientation}
              extStyles={{ margin: 'auto auto auto 0.5rem', width: '6rem' }}
            />
            <div className={isEdit ? 'select show-select' : 'select'}>
              <UiSelector
                extStyles={{
                  height: '90%',
                  width: '90%',
                  margin: 'auto',
                }}
                single
                role="nickname"
                optionName="Select Security"
                id={id + positionIndex}
                className="uiSelector-position"
                listData={strategyTemplateForm.securities
                  ?.filter(
                    (value) => !value.isAdditional && value.nickname?.length
                  )
                  ?.map((security, positionIndex) => {
                    return {
                      id: positionIndex,
                      label: security.nickname,
                    };
                  })}
                value={
                  isEdit
                    ? strategyTemplateForm.securities
                        .filter(
                          (value) =>
                            !value.isAdditional && value.nickname.length
                        )
                        .findIndex(
                          (el) =>
                            el.nickname ===
                            strategyTemplateForm.entryLegs[legIndex].position[
                              positionIndex
                            ].security
                        )
                    : strategyTemplateForm.entryLegs[legIndex].position[
                        positionIndex
                      ].security
                }
                handlerFunction={inputHandler.bind(positionIndex)}
              />
            </div>
            <div className="toggle ex-algo-wrapper">
              <span className="logic-label">Select Execution Algo</span>
              <div
                className={
                  isApproveReject ? 'approveReject-pointer switch' : 'switch'
                }
              >
                <Switch
                  id={'algoExecution-' + legIndex + positionIndex}
                  state={showAlgoFlag}
                  onChange={(e) => {
                    setShowAlgoFlag(e);
                    setShowAlgo(e);
                  }}
                  className={isApproveReject ? 'approveReject-pointer' : ''}
                  disable={
                    isApproveReject || strategyTemplateForm.isWrittenEquation
                  }
                />
              </div>
            </div>
            {(!showAlgo &&
              !algoStatus &&
              strategyTemplateForm.entryLegs[legIndex].position[positionIndex]
                .security) ||
            (showAlgo &&
              !algoStatus &&
              strategyTemplateForm.entryLegs[legIndex].position[positionIndex]
                .security) ? (
              <>
                <div
                  className={
                    isApproveReject ? 'approveReject-pointer toggle' : 'toggle'
                  }
                >
                  <div className="div">
                    <span className="logic-label">Define Quantity Logic</span>
                  </div>

                  <div
                    className={
                      isApproveReject
                        ? 'approveReject-pointer switch2'
                        : 'switch2'
                    }
                  >
                    <Switch
                      id={'quantityLogic_' + legIndex + positionIndex}
                      state={quantityLogicFlag}
                      onChange={(e) => {
                        setQuantityLogicFlag(e);
                        setQuantity(e);
                      }}
                      className={isApproveReject ? 'approveReject-pointer' : ''}
                      disabled={showAlgo || isApproveReject}
                    />
                  </div>
                </div>
                <div className="toggle">
                  <div className="div">
                    <span className="logic-label">Define Pricing Logic</span>
                  </div>

                  <div
                    className={
                      isApproveReject
                        ? 'approveReject-pointer switch3'
                        : 'switch3'
                    }
                  >
                    <Switch
                      id={'pricingLogic_' + legIndex}
                      state={pricingLogicFlag}
                      onChange={(e) => {
                        setPricingLogicFlag(e);
                        setPricing(e);
                      }}
                      className={isApproveReject ? 'approveReject-pointer' : ''}
                      disabled={showAlgo || isApproveReject}
                    />
                  </div>
                </div>
                <div className="toggle">
                  <div className="div">
                    <span className="logic-label">Define Total Qty Logic</span>
                  </div>

                  <div
                    className={
                      isApproveReject
                        ? 'approveReject-pointer switch4'
                        : 'switch4'
                    }
                  >
                    <Switch
                      id={'totalQtyLogic_' + legIndex + positionIndex}
                      state={totalQtyLogicFlag}
                      onChange={(e) => {
                        setTotalQtyLogicFlag(e);
                        setTotalQty(e);
                      }}
                      className={isApproveReject ? 'approveReject-pointer' : ''}
                      disabled={showAlgo || isApproveReject}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {showAlgoFlag && (
            <div className="postion-row">
              <div className="col-2">
                <div className="logic-label">Execution Algo :</div>
              </div>
              <div className="col-10" style={{ display: 'flex' }}>
                <div
                  className={
                    validator.current.message(
                      'algoExecution' + legIndex,
                      strategyTemplateForm.entryLegs[legIndex].algoExecution,
                      'required|min:1,array'
                    ) && formSubmit
                      ? 'error-input toggle-dropdown'
                      : isApproveReject
                      ? 'approveReject-pointer toggle-dropdown'
                      : 'toggle-dropdown'
                  }
                >
                  <MultiDropdown2
                    key="execAlgoOperand"
                    eqIndex={0}
                    listData={algo}
                    marketForm={marketForm}
                    editFlagIndex={editFlagIndex}
                    setEditFlagIndex={setEditFlagIndex}
                    setMarketForm={setMarketForm}
                    marketHandler={marketHandler}
                    setInputData={setInputData}
                    setSelectedCondition={setSelectedCondition}
                    placeHolder={'Select Execution Algo'}
                    setEquationValues={equationHandler}
                    fieldId={'exAlgo'}
                  />
                </div>
              </div>
            </div>
          )}
          {quantityLogicFlag && (
            <div className="postion-row">
              <div className="col-2">
                <div className="logic-label">Quantity Logic :</div>
              </div>
              <div className="col-10" style={{ display: 'flex' }}>
                <div
                  className={
                    validator.current.message(
                      'legPosition' + positionIndex,
                      strategyTemplateForm.entryLegs[legIndex].position[
                        positionIndex
                      ].quantity,
                      'required|min:1,array'
                    ) && formSubmit
                      ? 'error-input sepratewithcross'
                      : isApproveReject
                      ? 'approveReject-pointer sepratewithcross'
                      : 'sepratewithcross'
                  }
                >
                  <NewDropdown
                    id={`${legIndex}+Quantity Logic`}
                    listData={quantityCondition}
                    marketForm={marketForm}
                    editFlagIndex={editFlagIndex}
                    setEditFlagIndex={setEditFlagIndex}
                    setMarketForm={setMarketForm}
                    marketHandler={marketHandler}
                    setInputData={setInputData}
                    setSelectedCondition={setSelectedCondition}
                    placeHolder={'Add Indicator, Number, Bracket'}
                    setEquationValues={equationHandler}
                    legIndex={legIndex}
                    positionIndex={positionIndex}
                    componentKey={'quantity'}
                    extStyles={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
          )}
          {pricingLogicFlag && (
            <div className="postion-row">
              <div className="col-2">
                <div className="logic-label">Pricing Logic :</div>
              </div>
              <div
                className={
                  validator.current.message(
                    'legPosition' + positionIndex,
                    strategyTemplateForm.entryLegs[legIndex].position[
                      positionIndex
                    ].pricing,
                    'required|min:1,array'
                  ) && formSubmit
                    ? 'error-input col-10'
                    : isApproveReject
                    ? 'approveReject-pointer col-10'
                    : 'col-10'
                }
                style={{ display: 'flex' }}
              >
                <LogicalCondtitions
                  id={`${legIndex}+Pricing Logic`}
                  legIndex={legIndex}
                  positionIndex={positionIndex}
                  positionRow={positionRow}
                  pricingLogicFlag={pricingLogicFlag}
                  marketFormData={marketFormP}
                />
              </div>
            </div>
          )}
          {totalQtyLogicFlag && (
            <div className="postion-row">
              <div className="col-2">
                <div className="logic-label">Total Quantity Logic :</div>
              </div>
              <div className="col-10" style={{ display: 'flex' }}>
                <div
                  className={
                    validator.current.message(
                      'legPosition' + positionIndex,
                      strategyTemplateForm.entryLegs[legIndex].position[
                        positionIndex
                      ].totalQuantityLogic,
                      'required|min:1,array'
                    ) && formSubmit
                      ? 'error-input sepratewithcross'
                      : isApproveReject
                      ? 'approveReject-pointer sepratewithcross'
                      : 'sepratewithcross'
                  }
                >
                  <NewDropdown
                    id={`${legIndex}+Total Quantity Logic`}
                    listData={totalQtyCondition}
                    marketForm={marketForm}
                    editFlagIndex={editFlagIndex}
                    setEditFlagIndex={setEditFlagIndex}
                    setMarketForm={setMarketForm}
                    marketHandler={marketHandler}
                    setInputData={setInputData}
                    setSelectedCondition={setSelectedCondition}
                    placeHolder={'Add Indicator, Number, Bracket'}
                    setEquationValues={equationHandler}
                    legIndex={legIndex}
                    positionIndex={positionIndex}
                    componentKey={'totalQuantityLogic'}
                    extStyles={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="circle_Position">
        {positionIndex > 0 && (
          <ButtonAddLeg
            category="cat3"
            icon={DeleteIcon}
            hoverIcon={DeleteBinHover}
            clickAction={() => handleRemove(positionIndex)}
            extStyles={{ margin: '0.5rem 0.5rem auto auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default AddPosition;

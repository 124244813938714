import { useEffect, useState } from 'react';
import OrderBook from '../../../components/LiveTrading/OrderBook/OrderBook';
import TradingHeader from '../../../components/LiveTrading/TradingHeader/TradingHeader';
import RiskMonitoringUtil from '../../AdminDashboard/RiskMonitorInternal/riskMonitorUtil';
import RiskMonitor from '../../AdminDashboard/RiskMonitorParent/riskMonitor';
import StrategyAnalyzerV2 from '../strategyAnalyzer';
import './styles.css';
import { permissionChecker } from '../../Ops/redux';
import { useSelector } from 'react-redux';
import NetPosition from '../../../components/LiveTrading/NetPosition/NetPosition';
import StrategyAnalyzerTabMan from '../../../components/LiveTrading/StrategyAnalyzer';

export default function () {
  const [permission, setPermission] = useState();
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [dashboard, setDashboard] = useState(
    process.env.REACT_APP_IS_HFT === 'true' ? 'netposition' : 'strategyanalyzer'
  );

  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'Live Trading',
      2,
      'Trading'
    );

    setPermission(res > 1 ? 'edit' : 'view');
  }, []);

  function renderContent() {
    switch (dashboard) {
      case 'strategyanalyzer':
        return <StrategyAnalyzerTabMan />;
      case 'riskviewer':
        return process.env.REACT_APP_IS_MARG_VERSION == 'true' ? (
          <RiskMonitor
            showLogLive={false}
            isLiveTradingScreen={true}
            editAllowed={permission === 'edit'}
          />
        ) : (
          <RiskMonitoringUtil
            showLogLive={true}
            isLiveTradingScreen={true}
            editAllowed={permission === 'edit'}
          />
        );
      case 'order/trades':
        return <OrderBook editAllowed={permission === 'edit'} />;
      case 'netposition':
        return <NetPosition editAllowed={permission === 'edit'} />;
      default:
        return <></>;
    }
  }
  return (
    <div className="QuickWatch-container">
      <TradingHeader setDashboard={setDashboard} />
      {renderContent()}
    </div>
  );
}

import { useEffect, useState } from 'react';
import './assets/scss/style.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { inactivitySetup } from './utils/utilityFunctions';
import { logout } from './actions/loginAction';
import Popup from './components/sharedComponents/Popup/Popup';
import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';
import jwtDecode from 'jwt-decode';
import './global.css';
import Routes from './routes/Routes';
import { updateValue } from './utils/dotKeyFunctions';

export default function App() {
  const { alertMessage } = useSelector((state) => state.loginReducer);

  const dispatch = useDispatch();
  const [popup, setPopup] = useState({
    type: 'sessionExpired',
    message: `Please login again to continue. User will be auto logged out in 5 sec`,
    buttons: 'singleButton',
    state: false,
    header: 'Session Expired',
  });

  const StyledSnackbarProvider = styled(SnackbarProvider)`
    &.SnackbarItem-contentRoot {
      background-color: white;
      color: red;
    }
    &.SnackbarContent-root {
      background-color: white;
      color: red;
      border-left-style: solid;
      border-left-width: 8px;
      border-left-color: red;
    }
  `;

  // set functionalty for popup
  const actionOnpopup = (e, type) => {
    if (type === 'CLOSE') {
      logoutUser();
      setPopup(updateValue(popup, 'state', false));
    }
  };

  // Logout / Session expired popup
  const sessionExpiredAction = () => {
    setTimeout(() => {
      logoutUser();
    }, 5000);
  };

  const actionOnAlertpopup = () => {
    dispatch({
      type: 'CLEAR_ALERT',
    });
  };

  useEffect(() => {
    if (window.location.pathname !== '/' && !localStorage.getItem('token')) {
      logoutUser();
    }
    inactivitySetup(
      logoutUser,
      (val) => setPopup(updateValue(popup, 'state', val)),
      sessionExpiredAction
    );
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      return;
    }
    const token = jwtDecode(localStorage.getItem('token'));
    const expiry = new Date(token.exp * 1000);
    const now = new Date();
    if (expiry < now) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  }, []);

  function logoutUser() {
    let userId = localStorage.getItem('customerId');
    dispatch(logout({ userId: userId }));
    localStorage.clear();
    window.location.href = '/';
  }

  return (
    <StyledSnackbarProvider maxSnack={2}>
      <Routes />
      {popup.state ? (
        <Popup
          popClass="sessionExpired"
          actionButton={actionOnpopup}
          popupDetails={popup}
          header={popup.header}
          isSessionExpiredPopup={true}
        />
      ) : null}
      {alertMessage && alertMessage !== '' ? (
        <Popup
          popClass="alertUser"
          actionButton={actionOnAlertpopup}
          popupDetails={{
            type: 'alertUser',
            message: alertMessage,
            buttons: 'singleButton',
            state: false,
            header: 'ALERT!!!',
          }}
          header="ALERT!!!"
          headerCSS={{
            color: 'red',
            fontSize: '24px',
          }}
        />
      ) : null}
    </StyledSnackbarProvider>
  );
}

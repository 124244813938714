import Style from 'styled-components';

export const ModalContainer = Style.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  width: 90vw;
  position: relative;
`;

export const ModalHeader = Style.div`
height: 9vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-color);  
  position: relative;
`;
export const ModalHeadText = Style.span`
    font-family: var(--def-font);
    font-size: calc(var(--font-size) * 1.2);
    color: white;
    margin-left: 1vw;
`;

export const CrossIcon = Style.img`
  height: 1.2rem;
  width: 1.2rem;
  margin-left: auto;
  margin-right: 1vw;
`;

export const ModalBody = Style.div`
  height: 81vh;
  width: 100%;
  overflow-y: scroll;
`;

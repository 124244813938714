import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import greentick from '../../../assets/img/icons8-checked.svg';
import deleteTick from '../../../assets/img/icons8-cancel.svg';
import './RiskViewer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight,faSortDown } from '@fortawesome/free-solid-svg-icons'
const Checkbox = ({dataRow,selectedNods,setSelectedNods}) => {
    const dispatch = useDispatch()
    const sideTreeData = useSelector(state => state.riskMonitReducer)

    useEffect(() => {
      let adminChildArr =[]
      let adminChildObj ={}
      let tempChildArr = []
      let tempChildObj = {}
      for(let j =0; j<sideTreeData.liveData.length; j++){
        adminChildObj ={
          id:sideTreeData.liveData[j].data.nodeId,
          child: sideTreeData.liveData[j].children
        }
        adminChildArr.push(adminChildObj)
        let children = sideTreeData.liveData[j].children
        tempChildObj = {}
        for(let k = 0; k<children.length; k++){
          tempChildObj ={
            id:children[k].data.nodeId,
            child: children[k].data.child
          }
          tempChildArr.push(tempChildObj)
        }
      }
      for(let i = 0; i<adminChildArr?.length;i++)
      {
       if(sideTreeData.selectedNods.includes(adminChildArr[i].id)){
         let count = 0
         for(let c= 0; c<adminChildArr[i].child.length;c++){
           if(sideTreeData.selectedNods.includes(adminChildArr[i].child[c].data.nodeId)){
             count ++
           }
         }
         if(count === 0){
           let newArr = sideTreeData.selectedNods.filter(el => el !== adminChildArr[i].id )
           setSelectedNods(newArr)
           dispatch({type:"SELECTED_NODS_UPDATE",payload:newArr})
         }
       }
     } 
      for(let i = 0; i<tempChildArr?.length;i++)
       {
        if(sideTreeData.selectedNods.includes(tempChildArr[i].id)){
          let count = 0
          for(let c= 0; c<tempChildArr[i].child.length;c++){
            if(sideTreeData.selectedNods.includes(tempChildArr[i].child[c])){
              count ++
            }
          }
          if(count === 0){
            let newArr = sideTreeData.selectedNods.filter(el => el !== tempChildArr[i].id )
            setSelectedNods(newArr)
            dispatch({type:"SELECTED_NODS_UPDATE",payload:newArr})
          }
        }
      } 
   }, [selectedNods,sideTreeData])
    
     let newcheck = []

    let checked = false
   if( dataRow.data.detaObj.userType !== 'customer'){

     dataRow.children?.forEach(value=> {
       if(!sideTreeData.selectedNods.indexOf(value.data.nodeId) !== -1)
      {
        checked = false
        return false
      }
      else{
        checked = true
      }
    })
    if(!checked){
      checked = sideTreeData.selectedNods.indexOf(dataRow.data.nodeId) !== -1
    }
  }
    else{
      checked = sideTreeData.selectedNods.indexOf(dataRow.data.nodeId) !== -1
    }

    let expand =dataRow.children.length > 0 &&  dataRow.children[0].visible;
    
    return <div className={dataRow.data.detaObj.userType + " index-column"}> 
    
  
    <span className="index-name">
      {dataRow.children.length > 0 ?
      
       <span className="treeIcon">{expand? <FontAwesomeIcon icon={faSortDown} />:<FontAwesomeIcon icon={faAngleRight} />}</span> 
       :""}
     
     {dataRow.data.name}</span>
      <span>
        {(dataRow.data.status === 1) ? (<img src={greentick} alt="img" />) : ''}
        {(dataRow.data.status === 0) ? (<img width="14s" src={deleteTick} alt="img" />) : ''}
      </span>
    </div>
  }

  export default Checkbox
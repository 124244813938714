import React, { useState, useEffect } from 'react';
import GenericSearchBox from '../genSearchBox/genericSearchBox';
import './styles.css';
import ToggleTabs from '../toggleTab';

export default function ExcelPreview(props) {
  const { data = [], extStyles = {}, type = 1, previewTitle } = props;
  const [config, setConfig] = useState({
    filterSelect: 'All',
    success: 0,
    failed: 1,
    total: 1,
  });
  const colorPick = ['#0078D7', '#BC1BD1', '#4B44E3', '#F37C21', '#30B212'];
  const [search, setSearch] = useState('');
  const [dispTable, setDispTable] = useState();

  useEffect(() => {
    setDispTable(loadContent());
  }, []);

  useEffect(() => {
    setDispTable(loadContent());
  }, [search, config]);

  useEffect(() => {
    if (Array.isArray(data) && previewTitle === 'Preview Stratagies') {
      setConfig({
        ...config,
        total: data?.length,
        success: data.filter((item) => item.errorMessage?.length === 0)?.length,
        failed: data.filter((item) => item.errorMessage?.length !== 0)?.length,
      });
    }
  }, [data]);

  function loadHeader() {
    if (type === 1) {
      return (
        <tr>
          <th
            className="excel-prev-head-td"
            style={{
              width: '8%',
              whiteSpace: 'nowrap',
              backgroundColor: '#effcff',
            }}
          >
            <div className="excel-prev-th">S. No.</div>
          </th>
          <th
            className="excel-prev-head-td"
            style={{
              width: '92%',
              backgroundColor: '#effcff',
            }}
          >
            <div className="excel-prev-th">
              {previewTitle || 'Preview Family'}
            </div>
          </th>
        </tr>
      );
    } else {
      return (
        <thead style={{ backgroundColor: '#effcff' }}>
          <tr style={{ backgroundColor: '#effcff' }}>
            <th
              className="excel-prev-td sticky-col"
              style={{
                whiteSpace: 'nowrap',
                width: '5vw',
                height: '6vh',
                top: 0,
                left: 0,
                zIndex: 1,
                background: '#effcff',
              }}
            >
              <span className="excel-prev-th">S. No.</span>
            </th>
            <th
              className="excel-prev-td sticky-col"
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                height: '6vh',
                top: 0,
                left: '5vw',
                zIndex: 1,
                background: '#effcff',
                borderRight: 'none',
              }}
              colSpan={3}
            >
              <span className="excel-prev-th">{previewTitle}</span>
            </th>
            <th
              className="sticky-col"
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                height: '6vh',
                top: 0,
                left: '20vw',
                zIndex: 1,
                background: '#effcff',
                borderRight: 'none',
              }}
            >
              <GenericSearchBox
                extStyles={{}}
                version={1}
                message="Search"
                value={search}
                changeListener={(val) => setSearch(val)}
              />
            </th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
            <th style={{ backgroundColor: '#effcff' }}></th>
          </tr>
        </thead>
      );
    }
  }

  function validateDataObj(data) {
    for (let item of data) {
      let temp = Object.keys(item);
      let allowedKeys = ['singleFields', 'table', 'remarks', 'errorMessage'];
      for (let key of temp) {
        if (!allowedKeys.includes(key)) {
          return false;
        }
      }
      for (let obj of item.singleFields) {
        let allKey1 = ['param', 'value'];
        let tempKeys = Object.keys(obj);
        for (let i of tempKeys) {
          if (!allKey1.includes(i)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  function loadContent() {
    if (type === 1 && validateDataObj(data)) {
      return data
        .filter((item) => {
          switch (config.filterSelect) {
            case 'All':
              return true;
            case 'Success':
              return item.errorMessage?.length === 0;
            default:
              return item.errorMessage?.length !== 0;
          }
        })
        .map((item, index) => (
          <tr>
            <td className="excel-prev-td">
              <span className="excel-prev-sno">{index + 1}</span>
            </td>
            <td className="excel-prev-td">
              {index !== 0 && <div className="empty-row-excel-prev"></div>}
              <div className="excel-prev-tabContainer">
                {item.singleFields.map((field, index1) => (
                  <span className="excel-prev-key-val-cont">
                    <span className="excel-prev-t1-t1">{field.param}</span>
                    <span
                      className="excel-prev-t1-t2"
                      style={{
                        backgroundColor: `${colorPick[index1 % 5]}20`,
                        color: `${colorPick[index1 % 5]}`,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {field.value}
                    </span>
                  </span>
                ))}
              </div>
              <table className="excel-prev-table2">
                <tr>
                  {item.table.title.map((it) => (
                    <th className="excel-prev-th1">
                      <span className="excel-prev-th1-text">{it}</span>
                    </th>
                  ))}
                </tr>
                {item.table.entries.map((columns) => (
                  <tr>
                    {columns.map((tCell) => (
                      <td className="excel-prev-td">
                        <span className="excel-prev-td-text">{tCell}</span>
                      </td>
                    ))}
                  </tr>
                ))}
              </table>
              {item?.errorMessage?.length > 0 ? (
                <table
                  className="excel-prev-table2"
                  style={{ margin: '0.5rem 0' }}
                >
                  <thead>
                    <th className="excel-prev-th1">
                      <span className="excel-prev-th1-text">
                        Error Messages
                      </span>
                    </th>
                  </thead>
                  <tbody>
                    {item.errorMessage.map((errMsg) => (
                      <tr className="error-msgContainer">
                        <td className="excel-prev-td">
                          <span className="excel-prev-td-text">{errMsg}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <span
                  className="excel-prev-t1-t2"
                  style={{
                    backgroundColor: `${colorPick[4]}20`,
                    color: `${colorPick[4]}`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  SUCCESS
                </span>
              )}
              {item.remarks && (
                <span className="excel-prev-remark-cont">
                  <p className="excel-prev-remark-title">Remarks :</p>
                  <p className="excel-prev-remark-val">{item.remarks}</p>
                </span>
              )}
            </td>
          </tr>
        ));
    } else if (type === 2) {
      return (
        <tbody>
          <tr>
            <th
              style={{
                minWidth: '5vw',
                position: 'sticky',
                top: 0,
                left: 0,
                backgroundColor: 'white',
              }}
            ></th>

            {data &&
              data.titleRow &&
              data.titleRow.map((item, index) => (
                <th
                  className={`excel-prev-th2 ${
                    index === 0 || index === 1 ? 'sticky-col' : ''
                  }`}
                  style={{
                    top: index === 0 || index === 1 ? 0 : '',
                    left: index === 0 ? '5vw' : index === 1 ? '15vw' : '',
                    backgroundColor:
                      index === 0 || index === 1 ? '#f8f8f8' : '',
                    zIndex: index === 0 || index === 1 ? 1 : 0,
                  }}
                >
                  <span className="excel-prev-th1-text">{item}</span>
                </th>
              ))}
          </tr>
          {data &&
            data.rows &&
            data.rows
              .filter((item) => {
                if (search?.length > 0) {
                  if (
                    item[0].toString().includes(search) ||
                    item[1].toString().includes(search)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              })
              .map((item, index) => (
                <tr>
                  <td
                    className="excel-prev-td"
                    style={{
                      minWidth: '5vw',
                      position: 'sticky',
                      top: 0,
                      left: 0,
                      backgroundColor: 'white',
                      zIndex: 1,
                    }}
                  >
                    <span className="excel-prev-sno">{index + 1}</span>
                  </td>

                  {item.map((it2, index) => (
                    <td
                      className={`excel-prev-td ${
                        index === 0 || index === 1 ? 'sticky-col' : ''
                      }`}
                      style={{
                        minWidth: '10vw',
                        width:
                          index === 0 || index === 1 ? '10vw' : 'fit-content',
                        top: index === 0 || index === 1 ? 0 : '',
                        left: index === 0 ? '5vw' : index === 1 ? '15vw' : '0',
                        backgroundColor:
                          index === 0 || index === 1 ? '#ffffff' : '',
                        zIndex: index === 0 || index === 1 ? 1 : 0,
                        textAlign: 'center',
                      }}
                    >
                      <span className="excel-prev-td-text">{it2}</span>
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      );
    }
  }

  return (
    <div style={{ ...extStyles, position: 'relative' }}>
      {previewTitle === 'Preview Stratagies' && (
        <div className="excelPreview-selection-container">
          <ToggleTabs
            options={['All', 'Error', 'Success']}
            selected={config.filterSelect}
            onSelect={(val) => setConfig({ ...config, filterSelect: val })}
          />
          <span className="excel-prev-key-val-cont">
            <span className="excel-prev-t1-t1">Total Rules</span>
            <span
              className="excel-prev-t1-t2"
              style={{
                backgroundColor: `${colorPick[0]}20`,
                color: `${colorPick[0]}`,
                whiteSpace: 'nowrap',
              }}
            >
              {config.total}
            </span>
          </span>
          <span className="excel-prev-key-val-cont">
            <span className="excel-prev-t1-t1">Failed Rules</span>
            <span
              className="excel-prev-t1-t2"
              style={{
                backgroundColor: `${colorPick[1]}20`,
                color: `${colorPick[1]}`,
                whiteSpace: 'nowrap',
              }}
            >
              {config.failed}
            </span>
          </span>
          <span className="excel-prev-key-val-cont">
            <span className="excel-prev-t1-t1">Success Rules</span>
            <span
              className="excel-prev-t1-t2"
              style={{
                backgroundColor: `${colorPick[4]}20`,
                color: `${colorPick[4]}`,
                whiteSpace: 'nowrap',
              }}
            >
              {config.success}
            </span>
          </span>
        </div>
      )}

      <table className="excel-prev-table">
        {loadHeader()}
        {dispTable}
      </table>
    </div>
  );
}

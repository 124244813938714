import React, { useEffect, useState } from 'react';
// import EsteeLogo from '../../../assets/img/Logo1.svg';
import { Form } from 'react-bootstrap';
import UiButton from '../Button';
import EsteeLogo from '../../../assets/img/Strade_LOGO.svg';
import { popUpTypeEnum } from '../../../utils/constant';
import LogLiveFamily from '../../AdminDashboard/RiskMonitoring/popUpLogLive/LogLiveFamily';
import './Popup.scss';

const Popup = ({
  popClass,
  popupDetails,
  actionButton,
  note,
  message,
  setShowPopUp,
  header = '',
  typeEnum = -1,
  typeEnumData = [],
  isSessionExpiredPopup = false,
}) => {
  const [sessionExpireMessage, setSessionExpireMessage] = useState(
    `Please login again to continue. User will be auto logged out in 5 sec`
  );

  useEffect(() => {
    if (isSessionExpiredPopup) {
      let secRemaining = 5;
      let functMsg = setInterval(() => {
        secRemaining -= 1;
        setSessionExpireMessage(
          `Please login again to continue. User will be auto logged out in ${secRemaining} sec`
        );
      }, 1000);
    }
  }, []);

  return (
    <div
      className={`create-user-modal colormodal-global ${
        popClass ? popClass : ''
      }`}
    >
      <div className="popup success all-popups ">
        <div className="create_2 " style={{ whiteSpace: 'initial' }}>
          <Form>
            <div className="text-center" style={{ marginBottom: '5px' }}>
              <img src={EsteeLogo} alt="img" />
            </div>
            {header && header != '' ? (
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                {header ? (
                  <p
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '200px',
                      overflowWrap: 'break-word',
                      wordWrap: 'break-word',
                      wordBreak: 'break-word',
                    }}
                  >
                    {header}
                  </p>
                ) : (
                  ''
                )}
              </div>
            ) : null}
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              {isSessionExpiredPopup
                ? sessionExpireMessage
                : popupDetails && popupDetails.message
                ? popupDetails.message
                : message}
            </div>
            {popupDetails && popupDetails.note ? (
              <Form.Label>
                <strong>{popupDetails.note ? popupDetails.note : null}</strong>
              </Form.Label>
            ) : null}
            {
              // Type Specific handling -> {For Log Live This info is above the confirm buttons}
              typeEnum != -1 && typeEnum == popUpTypeEnum.LOG_LIVE_POPUP ? (
                <LogLiveFamily familyList={typeEnumData} />
              ) : null
            }
            {popupDetails && popupDetails.buttons == 'doubleButtons' ? (
              <div className="buttons text-center mt-3 all-buttons">
                <UiButton
                  type="submit"
                  className={`btn btn-primary mr-3 ${
                    popupDetails.nameAccept ? popupDetails.nameAccept : 'Yes'
                  }`}
                  name={
                    popupDetails && popupDetails.nameAccept
                      ? popupDetails.nameAccept
                      : 'Yes'
                  }
                  onClickFunction={(e) => actionButton(e, 'YES')}
                />
                <UiButton
                  className={`btn btn-primary ${
                    popupDetails.nameDecline ? popupDetails.nameDecline : 'No'
                  }`}
                  name={
                    popupDetails && popupDetails.nameDecline
                      ? popupDetails.nameDecline
                      : 'No'
                  }
                  onClickFunction={(e) => actionButton(e, 'CLOSE')}
                />
              </div>
            ) : (
              <div className="buttons text-center mt-3 all-buttons">
                <UiButton
                  className="btn btn-primary col-sm-3 mr-2 popUpOK"
                  name="ok"
                  onClickFunction={(e) => {
                    actionButton ? actionButton(e, 'CLOSE') : setShowPopUp();
                    if (popupDetails.type === 'submitCreate') {
                      window.location.reload();
                    }
                  }}
                />
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Popup;

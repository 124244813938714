import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import {
  USER_MAINTENANCE_HEADER,
  RISK_CONFIGURATION_HEADER,
  RISK_MONITORING_HEADER,
  TRADING_HEADER,
} from '../../../utils/constant';
import './InnerNavbar.scss';
import file from '../../../assets/img/file.png';
import FileUploadComponent from '../../FileUpload/FileUpload';
import newWindowIcon from '../../../assets/img/newWindowIcon.svg';

const InnerNavbar = ({
  navClass,
  setDashboard,
  activeDashboard,
  fileUplaod,
  newWindow,
  newWindowImage,
  id,
}) => {
  const location = useLocation();
  const [screen, setScreen] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    menuHeading();
  }, [location.pathname]);

  const menuHeading = () => {
    switch (location.pathname) {
      case '/UserMaintenance':
        setScreen(USER_MAINTENANCE_HEADER);
        break;
      case '/riskconfig':
        setScreen(RISK_CONFIGURATION_HEADER);
        break;
      case '/riskmonitoring':
        setScreen(RISK_MONITORING_HEADER);
        break;
      case '/LiveTrading':
        process.env.REACT_APP_IS_HFT == 'true'
          ? setScreen(TRADING_HEADER.slice(3))
          : setScreen(TRADING_HEADER);
        break;
      case '/window/trading':
        setScreen(TRADING_HEADER);
        break;
      default:
        break;
    }
  };
  const tabHandler = (tab) => {
    let tabName = tab.replace(/ +/g, '');
    if (setDashboard) {
      setDashboard(tabName.toLowerCase());
    }
  };

  return (
    <React.Fragment>
      <Nav
        key={screen}
        className={navClass ? `${navClass} dashboard-menu` : 'dashboard-menu'}
        id={id ? id : null}
      >
        {screen?.map((list) => {
          return (
            <Nav.Item>
              <Nav.Link
                className={`${
                  activeDashboard === list.replace(/ +/g, '') ? 'active' : ''
                }`}
                onClick={() => tabHandler(list)}
              >
                {list}
              </Nav.Link>
            </Nav.Item>
          );
        })}
        {fileUplaod ? (
          <Nav.Item>
            <Nav.Link onClick={() => setShow(true)}>
              <img src={file} alt="uploadFile" className="clearing-acc" />
            </Nav.Link>
          </Nav.Item>
        ) : null}
        {newWindowImage ? (
          <Nav.Item className="ml-auto">
            <div className="newWindow">
              <img
                src={newWindowIcon}
                alt="newWindowIcon"
                onClick={newWindow}
              />
            </div>
          </Nav.Item>
        ) : null}
      </Nav>
      {show ? (
        <FileUploadComponent
          show={setShow}
          setShow={setShow}
          screenType={'risk'}
        />
      ) : null}
    </React.Fragment>
  );
};

export default InnerNavbar;

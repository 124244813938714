import React, { useEffect, useState } from 'react';
import { Button, Modal, Container, Col, Row } from 'react-bootstrap';
import './PreviewStrategyModal.scss';
import UiSelector from '../../../../sharedComponents/Dropdowns';
import UiInput from '../../../../sharedComponents/Input';
import { useSelector, useDispatch } from 'react-redux';
import info from '../../../../../assets/img/info.svg';
import Toggle from '../../../../sharedComponents/Toggle';
import Table from '../../../../sharedComponents/Table/Table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import OrderDetails from './OrderDetails';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import MaterialUIPickers from '../../../../sharedComponents/TimePicker/TimePicker';
import {
  setStrategyTemplateForm,
  UpdateRow,
  getStrategySecurityNameOptions,
  getStrategyExpiresOptions,
  getStrategyStrikesOptions,
  setConditions,
  setDataExchange,
  setConstants,
} from '../../../../../actions/strategyBuilderAction';
import SimpleSnackbar from '../../../../sharedComponents/Toast/Toast';
import { makeStyles } from '@material-ui/core/styles';
import { getPricingLogic } from '../../../../../actions/LiveTradingAction';
import {
  START_TIME_EPOCH,
  END_TIME_EPOCH,
} from '../../../../../utils/constant';
import { GenericPopup } from '../../../../../components';
import CloseIcon from '../../../../../assets/img/close.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: "path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z')",
        },
      },
    },
  },
}));

const PreviewStrategyModal = ({ setModal }) => {
  const classes = useStyles();
  const {
    strategyBuilderReducer: {
      instrumentOptions,
      securityNameOptions,
      expiresOptions,
      strikeOptions,
      strategyTemplateForm,
      exchangeOptions,
      isEdit,
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);
  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));
  const dispatch = useDispatch();
  const [toaster, setToaster] = useState(false);
  const [assistBtn, setAssistBtn] = useState(false);

  useEffect(() => {
    strategyTemplateForm.entryLegs?.map((entryLeg) => {
      entryLeg.algoExecution?.map((algo) => {
        algo.arguements?.map((argument) => {
          if (argument.argName === START_TIME_EPOCH) {
            setTime(argument);
          } else if (argument.argName === END_TIME_EPOCH) {
            setTime(argument);
          }
        });
      });
    });
    dispatch(getPricingLogic());
  }, []);

  const inputHandlerExchange = (e) => {
    let form = { ...strategyTemplateForm };
    form.exchange = e.target.value;
    let securities =
      form.securities &&
      form.securities.map((el) => {
        return { ...el, securityName: '', expiry: '', strike: '' };
      });
    form.securities = securities;
    dispatch(setStrategyTemplateForm(form));
    dispatch(setDataExchange({ exchange: e.target.value }));
  };
  let { name } = strategyTemplateForm;

  const handleClose = () => setModal(false);

  function inputHandler(e) {
    let index = this.index;
    switch (e.target.name) {
      case 'instrument':
        let instrument = e.target.value;
        dispatch(
          getStrategySecurityNameOptions({
            instrument,
            index,
            key: e.target.name,
          })
        );
        dispatch(UpdateRow({ value: '', index, key: 'securityName' }));
        dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
        dispatch(UpdateRow({ value: '', index, key: 'strike' }));
        break;

      case 'securityName':
        let securityName = e.target.value;
        dispatch(
          getStrategyExpiresOptions({ securityName, index, key: e.target.name })
        );
        break;

      case 'expiry':
        let expiry = e.target.value;
        dispatch(
          getStrategyStrikesOptions({ expiry, index, key: e.target.name })
        );
        break;
      default:
        dispatch(
          UpdateRow({ value: e.target.value, index, key: e.target.name })
        );
    }
  }

  let tableData = [...strategyTemplateForm.securities];
  let newTable = tableData.map((val, key) => (val = { ...val, key }));

  const inputNameHandler = (e) => {
    let form = { ...strategyTemplateForm };
    form[e.target.name] = e.target.value;
    dispatch(setStrategyTemplateForm(form));
    if (form.name.length > 40) {
      setToaster(true);
    }
  };

  const selectSearch = (e, inputValue, value) => {
    if (e && (e.type === 'click' || e.type === 'change')) {
      let securityName = inputValue;
      dispatch(
        getStrategyExpiresOptions({
          securityName,
          index: value,
          key: 'securityName',
        })
      );
      dispatch(UpdateRow({ value: '', index: value, key: 'expiry' }));
      dispatch(UpdateRow({ value: '', index: value, key: 'strike' }));
    }
    if (
      e &&
      (e.type === 'click' || e.type === 'change') &&
      value === 0 &&
      assistBtn
    ) {
      let securityName = inputValue;
      for (let i = 0; i < strategyTemplateForm.securities.length; i++) {
        let index = i;
        dispatch(
          getStrategyExpiresOptions({
            securityName,
            index,
            key: 'securityName',
          })
        );
        dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
        dispatch(UpdateRow({ value: '', index, key: 'strike' }));
      }
    }
  };

  const setTime = (argument) => {
    let value = argument.argValue.split(':');
    let d = new Date();
    d.setHours(value[0], value[1], value[2]);
    if (argument.argName === START_TIME_EPOCH) {
      setStartTime(d);
    } else if (argument.argName === END_TIME_EPOCH) {
      setEndtime(d);
    }
  };

  const handleTimeDetails = (argument, time) => {
    let fieldData = strategyTemplateForm.entryLegs[0]?.algoExecution[0];
    let algoArgument =
      strategyTemplateForm.entryLegs[0]?.algoExecution[0]?.arguements.map(
        (arg) => {
          if (
            arg.argName === START_TIME_EPOCH &&
            argument.argName === START_TIME_EPOCH
          ) {
            setStartTime(time);
            return {
              ...arg,
              argValue: moment(time).format('HH:mm:ss'),
            };
          } else if (
            arg.argName === END_TIME_EPOCH &&
            argument.argName === END_TIME_EPOCH
          ) {
            setEndtime(time);
            return {
              ...arg,
              argValue: moment(time).format('HH:mm:ss'),
            };
          } else {
            return { ...arg };
          }
        }
      );
    let field = [{ ...fieldData, arguements: algoArgument }];
    dispatch(setConditions({ field, legIndex: 0, key: 'algoExecution' }));
  };

  let table_coulumn = [
    {
      lebel: 'Instrument',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            <UiSelector
              single
              role="instrument"
              id={`instrument_${index}`}
              disable={strategyTemplateForm.exchange ? false : true}
              value={params.instrument}
              listData={
                instrumentOptions &&
                instrumentOptions.map((securities) => {
                  return {
                    id: securities.key,
                    label: securities.name,
                  };
                })
              }
              handlerFunction={inputHandler.bind({ params, index })}
              className={isApproveReject ? 'approveReject-pointer ' : ''}
            />
          </>
        );
      },
    },
    {
      lebel: 'Symbol',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            <Autocomplete
              disabled={
                strategyTemplateForm.securities[index].instrument &&
                securityNameOptions &&
                securityNameOptions?.length
                  ? false
                  : true
              }
              id={`combo-box-demo symbol_${index}`}
              options={
                securityNameOptions[index] &&
                securityNameOptions[index]?.map((sym) => {
                  return {
                    id: sym,
                    label: sym,
                  };
                })
              }
              classes={{
                clearIndicatorDirty: classes.clearIndicator,
                popupIndicator: classes.popupIndicator,
              }}
              value={params.securityName ? params.securityName : null}
              inputValue={params.securityName ? params.securityName : ''}
              getOptionLabel={(option) => (option?.label ? option.label : '')}
              onInputChange={(e, newInputValue) =>
                selectSearch(e, newInputValue, index)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={params.securityName}
                  placeholder="Select"
                  className={isApproveReject ? 'approveReject-pointer ' : ''}
                />
              )}
            />
          </>
        );
      },
    },

    {
      lebel: 'Expiry',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            <UiSelector
              single
              role="expiry"
              id={`expiry_${index}`}
              disable={
                strategyTemplateForm.securities[index].securityName &&
                strategyTemplateForm.securities[index]?.instrument === 'Equity'
                  ? true
                  : strategyTemplateForm.securities[index].securityName
                  ? false
                  : true
              }
              value={params.expiry}
              listData={
                expiresOptions[index] &&
                expiresOptions[index].length > 0 &&
                expiresOptions[index]?.map((exp) => {
                  return {
                    id: exp.expiry,
                    label: moment(exp.expiry).format('DD MMM YYYY'),
                  };
                })
              }
              handlerFunction={inputHandler.bind({ params, index })}
              className={isApproveReject ? 'approveReject-pointer ' : ''}
            />
          </>
        );
      },
    },
    {
      lebel: 'Strike',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            <UiSelector
              single
              id={`strike_${index}`}
              role="strike"
              value={parseInt(params.strike)}
              disable={
                (strategyTemplateForm.securities[index].expiry &&
                  strategyTemplateForm.securities[index]?.instrument ===
                    'Equity') ||
                strategyTemplateForm.securities[index]?.instrument === 'Future'
                  ? true
                  : strategyTemplateForm.securities[index].expiry
                  ? false
                  : true
              }
              listData={
                strikeOptions[index] &&
                strikeOptions[index].map((str) => {
                  return {
                    id: str,
                    label: str,
                  };
                })
              }
              handlerFunction={inputHandler.bind({ params, index })}
              className={isApproveReject ? 'approveReject-pointer ' : ''}
            />
          </>
        );
      },
    },
    {
      lebel: 'Nick Name',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <label
            id={`nickName_${index}`}
            className={isApproveReject ? 'approveReject-pointer ' : ''}
          >
            {params.nickname}
          </label>
        );
      },
    },
  ];

  const templateValidtaion = (e, list, index) => {
    let values = e.target.value;
    let constObj = list;
    constObj.value = values;
    dispatch(
      setConstants({
        obj: constObj,
        key: 'constants',
        remove: false,
        edit: true,
        editIndex: index,
      })
    );
  };
  //assist toggle funtion
  const handleOnOffAssist = (e) => {
    setAssistBtn(e.target.checked);
  };

  return (
    <GenericPopup state={true} toggleState={handleClose} type={1}>
      <div className="previewTemplate-container">
        <div className="previewTemplate-header">
          <span className="previewTemplate-head">
            Preview Strategy Template Design
          </span>
          <img
            src={CloseIcon}
            alt="close-icon"
            className="previewTemplate-close"
            onClick={handleClose}
          />
        </div>
        <div className="previewTemplate-body">
          <div
            className={
              isApproveReject
                ? 'approveReject-pointer previewmodal-stb'
                : 'previewmodal-stb'
            }
          >
            <div className="templateName-wrapper">
              <div className="templateName-container">
                <span className="templateName-label">Exchange</span>
                <UiSelector
                  single
                  role="exchange"
                  id="preview_exchage"
                  value={strategyTemplateForm.exchange}
                  listData={exchangeOptions?.map((el) => {
                    return {
                      id: el,
                      label: el,
                    };
                  })}
                  handlerFunction={inputHandlerExchange}
                  className={
                    isApproveReject
                      ? 'templateName-dropdowncontainer approveReject-pointer '
                      : 'templateName-dropdowncontainer'
                  }
                />
              </div>
              <div className="templateName-container">
                <span className="templateName-label">Account</span>
                <UiSelector
                  id="preview_account"
                  className={
                    isApproveReject
                      ? 'templateName-dropdowncontainer approveReject-pointer '
                      : 'templateName-dropdowncontainer'
                  }
                />
              </div>
              <div className="templateName-container">
                <span className="templateName-label">Strategy Name</span>
                <UiInput //shared input component and calling the function to accept the input data
                  name="name"
                  value={name}
                  id="preview_strategyName"
                  handlerfunction={inputNameHandler}
                  max={50}
                  className={
                    isApproveReject
                      ? 'templateName-dropdowncontainer approveReject-pointer'
                      : 'templateName-dropdowncontainer'
                  }
                />
              </div>
            </div>
            <div className="middleWrapper">
              <div className="sectionHeadings">
                <h3>Securities to Buy/Sell</h3>
                <div className="assist">
                  <p>Assist</p>
                  <img src={info} alt="info" />
                  <Toggle
                    id={'Assist'}
                    handleOnOff={handleOnOffAssist}
                    showLabel={false}
                    disabled={isApproveReject}
                  />
                </div>
              </div>
              <Table
                className={'responsive table-striped'}
                tableCoulumn={table_coulumn}
                showPagination={false}
                TableData={newTable.filter((value) => !value.isAdditional)}
                id="preview_securityTable"
              ></Table>

              {newTable.filter((value) => value.isAdditional).length > 0 ? (
                <div className="sectionHeadings">
                  <h3> Additional Securities to check conditions</h3>
                </div>
              ) : (
                ' '
              )}

              {newTable.filter((value) => value.isAdditional).length > 0 ? (
                <Table
                  className={'responsive'}
                  showHeader={false}
                  tableCoulumn={table_coulumn}
                  showPagination={false}
                  TableData={newTable.filter((value) => value.isAdditional)}
                  id="preview_additionalTable"
                ></Table>
              ) : (
                ''
              )}

              <Col sm="12">
                <div className="newTemplateName">
                  {strategyTemplateForm.constants &&
                    strategyTemplateForm.constants.map((list, index) => {
                      return (
                        <div key={list}>
                          <p>{list.name}</p>
                          <UiInput
                            type="number"
                            name="templateName"
                            handlerfunction={(e) =>
                              templateValidtaion(e, list, index)
                            }
                            value={list.value}
                            newTemplateNameConstants
                          />
                        </div>
                      );
                    })}
                </div>
              </Col>
              <div className="divider"></div>

              <Row>
                <Col sm="12">
                  {strategyTemplateForm.entryLegs?.map((entryLeg) => {
                    return (
                      <div className="preview-algo">
                        {entryLeg.algoExecution?.map((algo) => {
                          return (
                            <table className="responsive table-striped table">
                              <tr>
                                {algo.arguements?.map((argument) => {
                                  return (
                                    <td style={{ borderTop: 'none' }}>
                                      {argument.argName === START_TIME_EPOCH ||
                                      argument.argName === END_TIME_EPOCH ? (
                                        <>
                                          <Form.Label htmlFor="basic-url">
                                            {argument.argName?.replace(
                                              'InEpochMS',
                                              ''
                                            )}
                                          </Form.Label>
                                          <MaterialUIPickers //shared time picker component
                                            selectedDate={
                                              argument.argName ===
                                              START_TIME_EPOCH
                                                ? new Date(startTime)
                                                : argument.argName ===
                                                  END_TIME_EPOCH
                                                ? new Date(endTime)
                                                : ''
                                            }
                                            startTime={startTime}
                                            endTime={endTime}
                                            timeType={
                                              argument.argName ===
                                              START_TIME_EPOCH
                                                ? 'startTime'
                                                : argument.argName ===
                                                  END_TIME_EPOCH
                                                ? 'endTime'
                                                : ''
                                            }
                                            setTimeData={(time) =>
                                              handleTimeDetails(argument, time)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <UiInput
                                          lebel={argument.argName}
                                          type="text"
                                          value={argument.argValue}
                                        />
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </table>
                          );
                        })}
                      </div>
                    );
                  })}
                </Col>

                <Col sm="12">
                  <OrderDetails />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal.Footer className="submitsbuttons">
          <Button className="transparentBtns" disabled>
            Clear
          </Button>
          <Button className="transparentBtns" disabled>
            Save as default
          </Button>
          <Button disabled>Submit</Button>
        </Modal.Footer>
        {toaster ? (
          <SimpleSnackbar
            toaster={toaster}
            setToaster={setToaster}
            message={'Strategy Template Name cannot exceed 50 characters'}
          />
        ) : null}
      </div>
    </GenericPopup>
  );
};

export default PreviewStrategyModal;

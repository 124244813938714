import { DropDownV2 } from '../index';
import './styles.css';

export default function PageSizePicker({
  id = '',
  currentSize = 5,
  availableSizes = [5, 10, 15, 30, 50, 100],
  handleSelection = () => {},
}) {
  return (
    <div className="PageSizePicker-container">
      <span className="PageSizePicker-label font-l1">Show</span>
      <DropDownV2
        id={id}
        value={currentSize}
        options={availableSizes}
        setValue={handleSelection}
        extClass='PageSizePicker-ddClass'
      />
      <span className="PageSizePicker-label font-l1">entries</span>
    </div>
  );
}

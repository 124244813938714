const initialState = {
  data: false,
  errors: "",
  username: "",
  message: "",
  otpCheck: false,
  number: "",
  email: "",
};
const forgotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORGOT_PASSWORD":
      return {
        ...state,
        data: action.payload,
        errors: action.error,
        username: action.user,
        number: action.number,
        email: action.email,
      };
    case "OTP_CHECK":
      return {
        ...state,
        data: false,
        otpCheck: action.payload,
        message: action.message,
      };
    case "CLEAR_USER":
      return {
        ...state,
        data: action.clearData,
        otpCheck: action.clearData,
        username: action.clearUser,
        number: action.clearUser,
        email: action.clearUser,
      };
    case "CLEAR_ERR":
      return {
        ...state,
        message: action.error,
      };
    case "CLEAR_USER_ERR":
      return {
        ...state,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default forgotReducer;

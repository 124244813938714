import React from 'react';
import './styles.css';

export default function PageGroup(props) {
  const {
    totalPageCount = 1,
    current = 1,
    extStyles = {},
    clickTrigger = () => {},
  } = props;
  return (
    <div style={extStyles} className="PageGroup-container">
      {Array.from(Array(totalPageCount).keys())
        .map((num) => num + 1)
        .map((item) => (
          <span
            className={`PageGroup-${item === current ? 'cur' : 'nor'}`}
            onClick={() => clickTrigger(item)}
          >
            {item}
          </span>
        ))}
    </div>
  );
}

import { MakeRequest } from '../../../../utils';

export default async function ManageFormulaAPI(API, body) {
  try {
    let urlMethod = { url: '', method: '' };
    switch (API) {
      case 'CREATE':
        urlMethod = {
          url: '/customMarketWatch/formula/create',
          method: 'POST',
        };
        break;
      case 'MODIFY':
        urlMethod = {
          url: '/customMarketWatch/formula/edit',
          method: 'POST',
        };
        break;
      case 'SAVE_DRAFT':
        urlMethod = {
          url: '/customMarketWatch/formula/saveDraft',
          method: 'POST',
        };
        break;
      case 'DELETE_FORM':
        urlMethod = {
          url: '/customMarketWatch/formula/delete',
          method: 'POST',
        };
        break;
      case 'GET_ALL_FORMULA':
        urlMethod = {
          url: `/customMarketWatch/formula/getAllFormula/${body}`,
          method: 'GET',
        };
        break;
      case 'GET_SINGLE_FORMULA':
        urlMethod = {
          url: `/customMarketWatch/formula/getFormula/${body}`,
          method: 'GET',
        };
        break;
      case 'GET_PI_WATCH_LIST':
        urlMethod = {
          url: `/customMarketWatch/watchlist/pageInfo/${body}`,
          method: 'GET',
        };
        break;
      case 'UPDATE_WATCHLIST_NAME':
        urlMethod = {
          url: `/customMarketWatch/watchlist/updatePageName`,
          method: 'POST',
        };
        break;
      case 'SUBSCRIBE_WATCH LIST_SECURITY':
        urlMethod = {
          url: `/customMarketWatch/watchlist/subscribe`,
          method: 'POST',
        };
        break;
      case 'UNSUBSCRIBE_WATCH LIST_SECURITY':
        urlMethod = {
          url: `/customMarketWatch/watchlist/unsubscribe`,
          method: 'POST',
        };
        break;
      default:
        break;
    }
    switch (urlMethod.method) {
      case 'POST':
        return await MakeRequest(urlMethod.url).post(body);
      default:
        return await MakeRequest(urlMethod.url).get();
    }
  } catch (error) {
    console.log(error.message);
    return { errorMessage: error.message };
  }
}

import React, { useState, useEffect } from 'react';
import './styles.css';

export default function ViewDataTable(props) {
  const {
    title = '',
    content = [],
    extStyles,
    columns = 1,
    subRowsContent = [],
    subColumns = 1,
  } = props;
  const [disp_table, set_disp_table] = useState([]);
  const [sub_disp_table, set_sub_disp_table] = useState([]);

  useEffect(() => {
    let i = 0;
    const display_table = [];
    const sub_display_table = [];
    while (i < content.length) {
      const temp_row = [];
      for (let j = 0; j < columns; j++) {
        temp_row.push(
          <td
            className="view-data-table-td"
            style={{ width: `${100 / columns}%` }}
          >
            {content[i]}
          </td>
        );
        i += 1;
      }
      display_table.push(<tr>{temp_row.map((item) => item)}</tr>);
    }
    i = 0;
    while (i < subRowsContent.length) {
      let temp_sub_row = [];
      for (let j = 0; j < columns; j++) {
        temp_sub_row.push(
          <td
            className="view-data-table-td"
            style={{ width: `${100 / columns}%` }}
          >
            {subRowsContent[i]}
          </td>
        );
        i += 1;
      }
      sub_display_table.push(<tr>{temp_sub_row.map((item) => item)}</tr>);
    }
    set_disp_table(display_table);
    if (subRowsContent.length) set_sub_disp_table(sub_display_table);
  }, [content]);

  return (
    <div className="view-data-container" style={extStyles}>
      <div className="view-data-title">
        <span className="view-data-title-text">{title}</span>
      </div>
      <table className="view-data-table-table">
        {disp_table.map((item) => item)}
      </table>
      {sub_disp_table && sub_disp_table.length ? (
        <table className="view-data-table-table">
          {sub_disp_table.map((item) => item)}
        </table>
      ) : null}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import './styles.css';
import { InputField, Button } from '../../../../components';
import { Delete_Disabled } from '../../../../assets';
import UMMenuGen from '../UMMenuGen';
import { MakeRequest } from '../../../../utils';
import { useDispatch } from 'react-redux';
import { OpsReducerActions } from '../../redux';

export default function CreateRole(props) {
  const {
    initData = {
      name: '',
      description: '',
      menu: [],
      remark: '',
    },
    type = 0,
    togglePopup = () => {},
  } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState(initData);
  const [error, setError] = useState('');

  useEffect(() => {
    if (Object.keys(initData).includes('name')) {
      setData({
        roleName: initData.name,
        roleDescription: initData.description,
        menu: initData.menu,
        remark: '',
      });
    } else {
      setData({
        roleName: '',
        roleDescription: '',
        menu: initData,
        remark: '',
      });
    }
  }, [initData]);

  async function submitAction() {
    if (type === 0 || type === 1) {
      await MakeRequest('/ops/createRole').post(data);
    } else {
      await MakeRequest('/ops/modifyRole').post({
        id: initData.roleId,
        ...data,
      });
    }
    togglePopup();
    OpsReducerActions.fetchAllRole(dispatch);
  }

  return (
    <div className="create-role-container">
      <div className="create-role-head">
        <span className="create-role-title">
          {type === 0
            ? 'Create Role'
            : type === 1
            ? 'Create Role'
            : 'Edit Role'}
        </span>
        {(type === 1 || type === 2) && (
          <div className="create-role-delete">
            <img src={Delete_Disabled} alt="Delete" />
          </div>
        )}
      </div>
      <div className="create-role-body">
        <div className="input-container">
          <InputField
            label="Role Name"
            type="text"
            inputWidth="15vw"
            extStyles={
              {
                // margin: 'auto',
              }
            }
            value={data.name}
            onValueChange={(val) => setData({ ...data, name: val })}
          />
          <InputField
            label="Role Description"
            type="text"
            inputWidth="30vw"
            extStyles={
              {
                // margin: 'auto',
              }
            }
            value={data.description}
            onValueChange={(val) => setData({ ...data, description: val })}
          />
        </div>

        <UMMenuGen
          settings={{
            type: 2,
            style: {
              width: '48vw',
              gridColumn: 'span 2',
              margin: '1vh auto 1vh auto',
            },
          }}
          data={data.menu}
          changeMenu={(val) => setData({ ...data, menu: val })}
        />
        <div className="remark-container">
          <InputField
            label="Remark"
            type="text"
            inputWidth="48vw"
            extStyles={{
              margin: '1vh auto 1vh auto',
              gridColumn: 'span 2',
            }}
            value={data.roleDescription}
            onValueChange={(val) => setData({ ...data, roleDescription: val })}
          />
        </div>
      </div>
      <div className="create-role-foot">
        <span className="create-role-error-message">{error}</span>
        <Button
          settings={{
            fill: true,
            title: Object.keys(initData).includes('name') ? 'EDIT' : 'SUBMIT',
            styles: { width: '10vw', margin: '0 1vw 0 auto' },
          }}
          click={submitAction}
        />
        <Button
          settings={{
            fill: false,
            title: 'CLOSE',
            styles: { width: '10vw', margin: '0 1vw 0 1vw' },
          }}
          click={togglePopup}
        />
      </div>
    </div>
  );
}

// (WebData[i].applicationId ==  WebData[k].applicationId) && (WebData[i].errorCodeId ==  WebData[k].errorCodeId) &&   (WebData[i]['errorType'] ==  WebData[k]['errorType']) &&  (WebData[i].identifier== WebData[k].identifier)
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Tab, NavItem, NavLink, Nav } from 'react-bootstrap';
import { CONFRIM_DELETE, FULL_GRID, PAGE_TITLES } from '../../utils/constant';
import './AlertsAndNotification.scss';
import { webSocketUrl } from '../../config/apiConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  alertData,
  allAlertData,
  deleteAlert,
  liveDataUpdate,
  markAsRead,
  resetExpand,
  setGroupedArr,
  updateAlertTable,
  updateInTemplate,
  resetNewExpandId,
} from '../../actions/alertAction';
import TableAlert from './TableAlert/TableAlert';
import TreeTable from './TableAlert/TreeTable';
import Moment from 'react-moment';
import AddAlert from './AddAlert/AddAlert';
import { setGroupId } from '../../actions/alertAction';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Popup from '../sharedComponents/Popup/Popup';
import cancelRed from '../../assets/img/cancelRed.svg';
import AlertHeader from './AlertHeader';
import _ from 'lodash';
import SimpleSnackbar from '../sharedComponents/Toast/Toast';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../sharedComponents/keyPressEventHandler';
import deleteImage from '../../assets/img/delete01.svg';
import deletedImage from '../../assets/img/deleted01.svg';
import { GenButton } from '..';
import { ExcelIcon } from '../../assets';
import { MakeRequest } from '../../utils';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  // change background colour if draggin
  background: isDragging ? 'grey' : '#EAE8E8',
  border: '1px solid #C3C3C3',
  borderRadius: '10px',
  boxShadow: '0 3px 6px #00000029',
  height: '31px',
  display: 'flex',
  alignItems: 'center',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  padding: grid,

  alignItems: 'center',
});

var W3CWebSocket = require('websocket').w3cwebsocket;

const AlertsAndNotification = () => {
  const {
    alertReducer: {
      alertTableData,
      allAlertsData,
      approve,
      groupIds,
      groupedArr,
      alertTable,
      alertLiveData,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    setGenData(allAlertsData);
  }, [allAlertsData]);
  const urlParams = new URLSearchParams(window.location.search);
  let errorWindowType = urlParams.get('errorType');

  const dispatch = useDispatch();

  const [liveData, setLiveData] = useState(null);
  const [genData, setGenData] = useState();
  const token = localStorage.getItem('token');
  const [key, setKey] = useState('RISK');
  const [columnData, setColumnData] = useState([]);
  const [treeValue, setTreeValue] = useState([]);
  const [addRuleIdForClearing, setaddRuleIdForClearing] = useState([]);
  const [alertPop, setAlertPop] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertDelete, setAlertDelete] = useState(false);
  const [tabType, setTabType] = useState('RISK');
  const [checkedAlert, setCheckedAlert] = useState([]);
  const [mouseOnTrash, setMouseOnTrash] = useState(false);

  const [genToast, setGenToast] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isSuccessDelete, setIsSuccessDelete] = useState(false);

  const [toastMsg, setToatMsg] = useState('');
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      groupedArr,
      result.source.index,
      result.destination.index
    );

    dispatch(setGroupedArr(items));
  };

  // Set window on load
  useEffect(() => {
    setKey(errorWindowType ? errorWindowType : 'RISK');
  }, []);

  // Selective Update on Successfull Dealer Excel upload
  useEffect(() => {
    if (isSuccessDelete == true) {
      try {
        // Update general & strategy template data
        dispatch(allAlertData());
      } catch (error) {
        console.log(error);
      }
      setIsSuccessDelete(false);
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    var client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    funInitialise(client);
  }, [token]);

  function funInitialise(client) {
    let userId = localStorage.getItem('customerId');
    let userType = localStorage.getItem('userType');
    client.onerror = function () {};

    client.onopen = function () {
      const data = {
        eventType: 'ALERT_SUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          userType: userType,
        },
      };

      client.send(JSON.stringify(data));
    };

    client.onclose = function () {};

    client.onmessage = function (data) {
      let dataStr = JSON.parse(data.data);
      let webObj = { ...dataStr.payload };
      const key = 'errorTime';
      let WebDataLive = webObj.data
        ? [...new Map(webObj.data.map((item) => [item[key], item])).values()]
        : [];
      let WebData = [];

      if (WebDataLive.length > 0) {
        WebData = localStorage.getItem('WebData')
          ? JSON.parse(localStorage.getItem('WebData'))
          : [];

        if (WebData.length >= 0) {
          for (let w = 0; w < WebDataLive.length; w++) {
            let match = false;
            const isExistInWebData = WebData.find(
              (el) => el.errorTime === WebDataLive[w].errorTime
            );
            if (isExistInWebData) {
              match = true;
            }
            if (!match) {
              WebDataLive[w]['sameError'] = [];
              WebData.push(WebDataLive[w]);
            }
          }
        } else {
          let compareData = [...WebDataLive];
          for (let w = 0; w < WebDataLive.length; w++) {
            for (let i = 0; i < compareData.length; i++) {
              if (
                compareData[i] &&
                w !== i &&
                !(
                  compareData[i].errorTime === WebDataLive[w].errorTime &&
                  compareData[i].message === WebDataLive[w].message
                )
              ) {
                compareData[i]['sameError'] = [];
                WebData.push(compareData[i]);
                compareData.splice(i, 1);
              }
            }
          }
        }
        const webObjDataLocal = [
          ...new Map(WebData.map((item) => [item[key], item])).values(),
        ].map((el, index) => {
          return { ...el, uniqueKey: `${index}` };
        });
        const sortedDataArray = _.orderBy(
          webObjDataLocal,
          (a) => new Date(a.errorDateTime),
          ['desc']
        );
        localStorage.setItem('WebData', JSON.stringify(sortedDataArray));
        webObj.data = sortedDataArray;
        if (tabType !== 'STRATEGY_TEMPLATE_APPROVAL') {
          setLiveData(webObj);
          dispatch(liveDataUpdate(webObj));
        }
      }
    };
  }

  const makeTree = (newArr, liveData, flagkey) => {
    let groupedDataObj = [];

    //check if any group is selected or not with respect to selected tab

    if (newArr.length > 0 && newArr.find((el) => el.tabKey === tabType)) {
      let formattedArray = [];
      if (liveData && liveData.data) {
        let i = 0;

        //filter groups based on tab
        const selectedGroupArr = newArr.filter((el) => el.tabKey === tabType);

        // // filter groups based on tab type and create groups
        do {
          if (i === 0) {
            const liveDataForSelectedTab = liveData.data.filter(
              (el) => el.errorIdentifier === tabType
            );
            const firstSortedArr = _.groupBy(
              liveDataForSelectedTab,
              selectedGroupArr[0].id
            );
            // console.log('liveData.data', liveDataForSelectedTab.length)
            const keysArray = Object.keys(firstSortedArr);
            keysArray.forEach((innerEl, index) => {
              formattedArray.push({
                ...firstSortedArr[innerEl][0],
                uniqueKey: `${index}`,
                sameError: [...firstSortedArr[innerEl].slice(1)],
              });
            });
          } else if (i === 1) {
            formattedArray.forEach((innerEl, index) => {
              const firstSortedArr = _.groupBy(
                innerEl.sameError,
                selectedGroupArr[1].id
              );
              const keysArray = Object.keys(firstSortedArr);
              let innerFormattedArr = [];
              keysArray.forEach((innerEl, innerIndex) => {
                innerFormattedArr.push({
                  ...firstSortedArr[innerEl][0],
                  uniqueKey: `${index}${innerIndex}`,
                  sameError: [...firstSortedArr[innerEl].slice(1)],
                });
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerFormattedArr,
              };
            });
          } else if (i === 2) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                const firstSortedArr = _.groupBy(
                  innerElN.sameError,
                  selectedGroupArr[2].id
                );
                const keysArray = Object.keys(firstSortedArr);
                let innerFormattedArr = [];
                keysArray.forEach((innerEl, innerIndex) => {
                  innerFormattedArr.push({
                    ...firstSortedArr[innerEl][0],
                    uniqueKey: `${index}${indexN}${innerIndex}`,
                    sameError: [...firstSortedArr[innerEl].slice(1)],
                  });
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerFormattedArr,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          } else if (i === 3) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                innerElN.sameError.forEach((innerElNN, indexNN) => {
                  const firstSortedArr = _.groupBy(
                    innerElNN.sameError,
                    selectedGroupArr[3].id
                  );
                  const keysArray = Object.keys(firstSortedArr);
                  let innerFormattedArr = [];
                  keysArray.forEach((innerEl, innerIndex) => {
                    innerFormattedArr.push({
                      ...firstSortedArr[innerEl][0],
                      uniqueKey: `${index}${indexN}${indexNN}${innerIndex}`,
                      sameError: [...firstSortedArr[innerEl].slice(1)],
                    });
                  });
                  innerElN.sameError[indexNN] = {
                    ...innerElNN,
                    sameError: innerFormattedArr,
                  };
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerElN.sameError,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          } else if (i === 4) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                innerElN.sameError.forEach((innerElNN, indexNN) => {
                  innerElNN.sameError.forEach((innerElNNN, indexNNN) => {
                    const firstSortedArr = _.groupBy(
                      innerElNNN.sameError,
                      selectedGroupArr[4].id
                    );
                    const keysArray = Object.keys(firstSortedArr);
                    let innerFormattedArr = [];
                    keysArray.forEach((innerEl, innerIndex) => {
                      innerFormattedArr.push({
                        ...firstSortedArr[innerEl][0],
                        uniqueKey: `${index}${indexN}${indexNN}${indexNNN}${innerIndex}`,
                        sameError: [...firstSortedArr[innerEl].slice(1)],
                      });
                    });
                    innerElNN.sameError[indexNNN] = {
                      ...innerElNNN,
                      sameError: innerFormattedArr,
                    };
                  });
                  innerElN.sameError[indexNN] = {
                    ...innerElNN,
                    sameError: innerElNN.sameError,
                  };
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerElN.sameError,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          } else if (i === 5) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                innerElN.sameError.forEach((innerElNN, indexNN) => {
                  innerElNN.sameError.forEach((innerElNNN, indexNNN) => {
                    innerElNNN.sameError.forEach((innerElNNNN, indexNNNN) => {
                      const firstSortedArr = _.groupBy(
                        innerElNNNN.sameError,
                        selectedGroupArr[5].id
                      );
                      const keysArray = Object.keys(firstSortedArr);
                      let innerFormattedArr = [];
                      keysArray.forEach((innerEl, innerIndex) => {
                        innerFormattedArr.push({
                          ...firstSortedArr[innerEl][0],
                          uniqueKey: `${index}${indexN}${indexNN}${indexNNN}${indexNNNN}${innerIndex}`,
                          sameError: [...firstSortedArr[innerEl].slice(1)],
                        });
                      });
                      innerElNNN.sameError[indexNNNN] = {
                        ...innerElNNNN,
                        sameError: innerFormattedArr,
                      };
                    });
                    innerElNN.sameError[indexNNN] = {
                      ...innerElNNN,
                      sameError: innerElNNN.sameError,
                    };
                  });
                  innerElN.sameError[indexNN] = {
                    ...innerElNN,
                    sameError: innerElNN.sameError,
                  };
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerElN.sameError,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          } else if (i === 6) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                innerElN.sameError.forEach((innerElNN, indexNN) => {
                  innerElNN.sameError.forEach((innerElNNN, indexNNN) => {
                    innerElNNN.sameError.forEach((innerElNNNN, indexNNNN) => {
                      innerElNNNN.sameError.forEach(
                        (innerElNNNNN, indexNNNNN) => {
                          const firstSortedArr = _.groupBy(
                            innerElNNNNN.sameError,
                            selectedGroupArr[6].id
                          );
                          const keysArray = Object.keys(firstSortedArr);
                          let innerFormattedArr = [];
                          keysArray.forEach((innerEl, innerIndex) => {
                            innerFormattedArr.push({
                              ...firstSortedArr[innerEl][0],
                              uniqueKey: `${index}${indexN}${indexNN}${indexNNN}${indexNNNN}${indexNNNNN}${innerIndex}`,
                              sameError: [...firstSortedArr[innerEl].slice(1)],
                            });
                          });
                          innerElNNNN.sameError[indexNNNNN] = {
                            ...innerElNNNNN,
                            sameError: innerFormattedArr,
                          };
                        }
                      );
                      innerElNNN.sameError[indexNNNN] = {
                        ...innerElNNNN,
                        sameError: innerElNNNN.sameError,
                      };
                    });
                    innerElNN.sameError[indexNNN] = {
                      ...innerElNNN,
                      sameError: innerElNNN.sameError,
                    };
                  });
                  innerElN.sameError[indexNN] = {
                    ...innerElNN,
                    sameError: innerElNN.sameError,
                  };
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerElN.sameError,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          } else if (i === 7) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                innerElN.sameError.forEach((innerElNN, indexNN) => {
                  innerElNN.sameError.forEach((innerElNNN, indexNNN) => {
                    innerElNNN.sameError.forEach((innerElNNNN, indexNNNN) => {
                      innerElNNNN.sameError.forEach(
                        (innerElNNNNN, indexNNNNN) => {
                          innerElNNNNN.sameError.forEach(
                            (innerElNNNNNN, indexNNNNNN) => {
                              const firstSortedArr = _.groupBy(
                                innerElNNNNNN.sameError,
                                selectedGroupArr[7].id
                              );
                              const keysArray = Object.keys(firstSortedArr);
                              let innerFormattedArr = [];
                              keysArray.forEach((innerEl, innerIndex) => {
                                innerFormattedArr.push({
                                  ...firstSortedArr[innerEl][0],
                                  uniqueKey: `${index}${indexN}${indexNN}${indexNNN}${indexNNNN}${indexNNNNN}${indexNNNNNN}${innerIndex}`,
                                  sameError: [
                                    ...firstSortedArr[innerEl].slice(1),
                                  ],
                                });
                              });
                              innerElNNNNN.sameError[indexNNNNNN] = {
                                ...innerElNNNNNN,
                                sameError: innerFormattedArr,
                              };
                            }
                          );
                          innerElNNNN.sameError[indexNNNNN] = {
                            ...innerElNNNNN,
                            sameError: innerElNNNNN.sameError,
                          };
                        }
                      );
                      innerElNNN.sameError[indexNNNN] = {
                        ...innerElNNNN,
                        sameError: innerElNNNN.sameError,
                      };
                    });
                    innerElNN.sameError[indexNNN] = {
                      ...innerElNNN,
                      sameError: innerElNNN.sameError,
                    };
                  });
                  innerElN.sameError[indexNN] = {
                    ...innerElNN,
                    sameError: innerElNN.sameError,
                  };
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerElN.sameError,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          } else if (i === 8) {
            formattedArray.forEach((innerEl, index) => {
              innerEl.sameError.forEach((innerElN, indexN) => {
                innerElN.sameError.forEach((innerElNN, indexNN) => {
                  innerElNN.sameError.forEach((innerElNNN, indexNNN) => {
                    innerElNNN.sameError.forEach((innerElNNNN, indexNNNN) => {
                      innerElNNNN.sameError.forEach(
                        (innerElNNNNN, indexNNNNN) => {
                          innerElNNNNN.sameError.forEach(
                            (innerElNNNNNN, indexNNNNNN) => {
                              innerElNNNNNN.sameError.forEach(
                                (innerElNNNNNNN, indexNNNNNNN) => {
                                  const firstSortedArr = _.groupBy(
                                    innerElNNNNNNN.sameError,
                                    selectedGroupArr[8].id
                                  );
                                  const keysArray = Object.keys(firstSortedArr);
                                  let innerFormattedArr = [];
                                  keysArray.forEach((innerEl, innerIndex) => {
                                    innerFormattedArr.push({
                                      ...firstSortedArr[innerEl][0],
                                      uniqueKey: `${index}${indexN}${indexNN}${indexNNN}${indexNNNN}${indexNNNNN}${indexNNNNNN}${indexNNNNNNN}${innerIndex}`,
                                      sameError: [
                                        ...firstSortedArr[innerEl].slice(1),
                                      ],
                                    });
                                  });
                                  innerElNNNNNN.sameError[indexNNNNNNN] = {
                                    ...innerElNNNNNNN,
                                    sameError: innerFormattedArr,
                                  };
                                }
                              );
                              innerElNNNNN.sameError[indexNNNNNN] = {
                                ...innerElNNNNNN,
                                sameError: innerElNNNNNN.sameError,
                              };
                            }
                          );
                          innerElNNNN.sameError[indexNNNNN] = {
                            ...innerElNNNNN,
                            sameError: innerElNNNNN.sameError,
                          };
                        }
                      );
                      innerElNNN.sameError[indexNNNN] = {
                        ...innerElNNNN,
                        sameError: innerElNNNN.sameError,
                      };
                    });
                    innerElNN.sameError[indexNNN] = {
                      ...innerElNNN,
                      sameError: innerElNNN.sameError,
                    };
                  });
                  innerElN.sameError[indexNN] = {
                    ...innerElNN,
                    sameError: innerElNN.sameError,
                  };
                });
                innerEl.sameError[indexN] = {
                  ...innerElN,
                  sameError: innerElN.sameError,
                };
              });
              formattedArray[index] = {
                ...innerEl,
                sameError: innerEl.sameError,
              };
            });
          }
          i++;
        } while (selectedGroupArr[i]);
      }

      groupedDataObj = formattedArray;
    } else {
      if (liveData && liveData.data) {
        let formattedArray = [];
        let defaultLiveData = liveData.data
          .filter((el) => el.errorIdentifier === tabType)
          .map((el) => {
            return {
              ...el,
              keyForDefaultSort: `${el.applicationId}${el.errorCodeId}${el.errorType}`,
            };
          });
        const firstSortedArr = _.groupBy(defaultLiveData, 'keyForDefaultSort');
        const keysArray = Object.keys(firstSortedArr);
        keysArray.forEach((innerEl, index) => {
          formattedArray.push({
            ...firstSortedArr[innerEl][0],
            uniqueKey: `${index}`,
            sameError: [...firstSortedArr[innerEl].slice(1)],
          });
        });
        groupedDataObj = formattedArray;
      }
    }
    return groupedDataObj;
  };

  useEffect(() => {
    dispatch(alertData());
    dispatch(allAlertData());
  }, []);

  useEffect(() => {
    localStorage.setItem('WebData', JSON.stringify([]));
    return () => {
      //alert("d")
      localStorage.setItem('WebData', JSON.stringify([]));
    };
  }, []);

  useEffect(() => {
    let gen = allAlertsData;
    setGenData(gen);
  }, [allAlertsData]);

  const dateTime = (dataRow) => {
    return (
      <>
        <div className={dataRow?.isChild ? `child-box date-time` : `date-time`}>
          <Moment format="DD MMM YYYY  h:mm:ss A">
            {dataRow?.errorDateTime}
          </Moment>
        </div>
      </>
    );
  };
  //this function is designed to render html in headers
  const dataRender = (dataRow, fieldType) => {
    return (
      <>
        <div
          className={
            dataRow?.isChild ? `child-box ${fieldType} ` : `${fieldType} `
          }
        >
          {dataRow && dataRow[fieldType]}
        </div>
      </>
    );
  };

  useEffect(() => {
    // load table headers
    if (tabType != 'STRATEGY_TEMPLATE_APPROVAL') {
      let foundObj = alertTableData?.find((el) => el.tabEnum == key);
      if (foundObj && foundObj.fields) {
        let colArr = foundObj.fields
          ?.filter((list) => addRuleIdForClearing.includes(list.fieldEnum))
          .map((elm, ind) => {
            if (elm.fieldEnum === 'errorDateTime') {
              return {
                dataField: elm.fieldEnum,
                heading: (
                  <AlertHeader
                    liveData={alertLiveData}
                    elm={elm}
                    makeTree={makeTree}
                    setTreeValue={setTreeValue}
                    tabType={tabType}
                  />
                ),
                styleClass: 'check',
                renderer: dateTime,
              };
            } else {
              return {
                dataField: elm.fieldEnum,
                heading: (
                  <AlertHeader
                    liveData={alertLiveData}
                    elm={elm}
                    makeTree={makeTree}
                    setTreeValue={setTreeValue}
                    tabType={tabType}
                  />
                ),
                renderer: (dataRow) => dataRender(dataRow, elm.fieldEnum),
              };
            }
          });
        setColumnData(colArr);
      }
      if (tabType === 'GENERAL_MESSAGE') {
        let generalData = dataType('GENERAL_MESSAGE');

        setTreeValue(generalData);
      } else {
        let groupedDataObj = makeTree(
          groupedArr,
          alertLiveData,
          'from useEffect 504'
        );

        if (
          groupedArr.length &&
          groupedArr.find((el) => el.tabKey === tabType)
        ) {
          dispatch(updateAlertTable({ groupedDataObj, isGroup: true }));
        } else {
          dispatch(updateAlertTable({ groupedDataObj, isGroup: false }));
        }
      }
    }
  }, [
    alertTableData,
    key,
    liveData,
    addRuleIdForClearing,
    tabType,
    groupedArr,
  ]);

  const listenToScroll = (position) => {
    setScrollPosition(position);
  };

  useEffect(() => {
    dispatch(setGroupId(addRuleIdForClearing));
  }, [addRuleIdForClearing]);

  const treeControls = {};
  const dataType = (data) => {
    switch (data) {
      case 'GENERAL_MESSAGE':
        return genData
          .map((el) => {
            if (el.alertType == data) {
              let generalObj = { ...el.alertData };
              generalObj['id'] = el.id;
              generalObj['isRead'] = el.isRead;
              return generalObj;
            }
          })
          .filter((elm) => elm);

      case 'STRATEGY_TEMPLATE_APPROVAL':
        return genData
          .map((el) => {
            if (
              el.alertType == data &&
              el.alertData.strategyTemplateData != null
            ) {
              let templateObj = { ...el.alertData.strategyTemplateData };
              templateObj['templateId'] = el.alertData.strategyTemplateId;
              // return templateObj;
              return templateObj;
            }
          })
          .filter((elm) => elm);
      default:
        return <></>;
    }
  };

  /* mark read and unread notifications ******/
  const handleMarkAsRead = async () => {
    if (checkedAlert && checkedAlert.length > 0) {
      const readResult = await dispatch(markAsRead(checkedAlert));
      setAlertMsg(readResult);
      setAlertPop(true);
    } else {
      setGenToast(true);
      setToatMsg('Select at least one message');
    }
  };

  const handleDeletePop = () => {
    if (checkedAlert && checkedAlert.length > 0) {
      setAlertDelete(true);
    } else {
      setGenToast(true);
      setToatMsg('Select at least one message');
    }
  };

  const handleDeleteAlert = async (data) => {
    if (data) {
      const deleteResult = await dispatch(deleteAlert(checkedAlert));
      setAlertMsg(deleteResult);
      setAlertPop(true);
    }
    setAlertDelete(false);
    setIsSuccessDelete(true); // Delete in [Message delete case] to refresh the data
  };

  const tabHandle = (tabEl) => {
    setKey(tabEl.tabEnum);
    setTabType(tabEl.tabEnum);
    let newArr = [];
    dispatch(resetExpand([]));
    if (tabEl.tabEnum !== tabType) {
      dispatch(updateAlertTable({ groupedDataObj: [], isGroup: false }));
    }
    //for template tab
    if (tabEl.tabEnum == 'STRATEGY_TEMPLATE_APPROVAL') {
      dispatch(updateInTemplate(true));
    } else {
      dispatch(updateInTemplate(false));
    }
  };
  const scrollRef = useRef(null);

  const toggleMouseOnTrash = () => {
    setMouseOnTrash(true);
  };

  const toggleMouseOffTrash = () => {
    setMouseOnTrash(false);
  };

  return (
    <div className="alert-notify">
      <Hotkeys
        keyName="esc"
        onKeyDown={(event) => {
          handleEscapeKeyEvent(event);
        }}
      />
      <Helmet>
        <title>{PAGE_TITLES.alertAndNotification}</title>
      </Helmet>
      <Tab.Container
        id="left-tabs-example"
        activeKey={key}
        defaultActiveKey="RISk"
      >
        <Row>
          <Col sm={FULL_GRID}>
            <Nav className="flextab alert-nav-header">
              {alertTableData &&
                alertTableData.map((tabEl) => {
                  return (
                    <React.Fragment key={tabEl.tabEnum}>
                      <NavItem onClick={() => tabHandle(tabEl)}>
                        <NavLink eventKey={tabEl.tabEnum}>
                          {tabEl.tabEnum === tabType &&
                          alertTable &&
                          alertTable.length ? (
                            <span key={JSON.stringify(alertLiveData?.data)}>
                              {
                                alertLiveData?.data.filter(
                                  (el) => el.errorIdentifier === tabEl.tabEnum
                                ).length
                              }
                            </span>
                          ) : tabEl.tabEnum === 'RISK' ||
                            (tabEl.tabEnum === 'PLATFORM' &&
                              alertLiveData?.data?.length) ? (
                            <span key={JSON.stringify(alertLiveData)}>
                              {
                                alertLiveData?.data?.filter(
                                  (el) => el.errorIdentifier === tabEl.tabEnum
                                ).length
                              }
                            </span>
                          ) : (tabEl.tabEnum === 'GENERAL_MESSAGE' ||
                              tabEl.tabEnum === 'STRATEGY_TEMPLATE_APPROVAL') &&
                            Object.keys(genData).length ? (
                            <span>
                              {tabEl.tabEnum === 'GENERAL_MESSAGE'
                                ? genData.filter(
                                    (el) =>
                                      el.alertType === 'GENERAL_MESSAGE' &&
                                      !el.isRead
                                  ).length
                                : genData.filter(
                                    (el) =>
                                      el.alertType ===
                                        'STRATEGY_TEMPLATE_APPROVAL' &&
                                      el.alertData.strategyTemplateData != null
                                  ).length}
                            </span>
                          ) : (
                            <span>0</span>
                          )}
                          {tabEl.tabName}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
            </Nav>
          </Col>
          <Col sm={FULL_GRID}>
            <div className="riskTableHead" ref={scrollRef}>
              {alertTableData.length > 0 ? (
                <AddAlert
                  ruleList={alertTableData}
                  addRuleId={[...addRuleIdForClearing]}
                  tabKey={key}
                  setaddRuleIdForClearing={setaddRuleIdForClearing}
                  addRuleIdForClearing={addRuleIdForClearing}
                />
              ) : null}

              <div className="groupDiv">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {groupedArr
                          .filter((elm) => elm.tabKey === tabType)
                          .map((el, index) => (
                            <Draggable
                              key={el.id}
                              draggableId={el.id}
                              index={index}
                            >
                              {/* Draggable components*/}
                              {(provided, snapshot) => (
                                <>
                                  <div
                                    className="btnchip"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    onClick={() => {
                                      let newarray = groupedArr.filter(
                                        (item) =>
                                          !(
                                            item.tabKey === tabType &&
                                            item.id === el.id
                                          )
                                      );
                                      let newTree = makeTree(
                                        newarray,
                                        alertLiveData,
                                        'from drag 781'
                                      );
                                      dispatch(resetNewExpandId(tabType));
                                      if (newarray.length > 0) {
                                        dispatch(
                                          updateAlertTable({
                                            newTree,
                                            isGroup: true,
                                          })
                                        );
                                      } else {
                                        dispatch(
                                          updateAlertTable({
                                            newTree,
                                            isGroup: false,
                                          })
                                        );
                                      }
                                      // setTreeValue(newTree)
                                      dispatch(setGroupedArr(newarray));
                                    }}
                                  >
                                    {el.content}
                                    <span className="remove">
                                      <img
                                        src={cancelRed}
                                        alt="img"
                                        height="15px"
                                        width="15px"
                                      />
                                    </span>
                                  </div>
                                  {groupedArr.length - 1 != index ? (
                                    <i className="fa fa-long-arrow-right"></i>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              {key === 'GENERAL_MESSAGE' ? (
                <div className="ruleAction">
                  <div className="markAsRead">
                    <button
                      className="markread"
                      onClick={() => handleMarkAsRead()}
                    >
                      Mark as Read
                    </button>
                  </div>
                  <div className="trashButton">
                    <button
                      className="trash"
                      onClick={() => handleDeletePop()}
                      onMouseEnter={toggleMouseOnTrash}
                      onMouseLeave={toggleMouseOffTrash}
                    >
                      {mouseOnTrash ? (
                        <img src={deletedImage} />
                      ) : (
                        <img src={deleteImage} />
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: '20vw',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {(tabType === 'RISK' || tabType === 'PLATFORM') && (
                    <GenButton
                      icon={ExcelIcon}
                      fill={false}
                      title="EXPORT EXCEL"
                      clickAction={async () => {
                        await MakeRequest(
                          `/excel/generate/alert?type=${tabType}`
                        ).download('Alerts');
                      }}
                      extStyle={{ margin: 'auto' }}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              key={`${JSON.stringify(alertTable)}${JSON.stringify(
                columnData
              )} ${JSON.stringify(groupedArr)} ${tabType} `}
              className="risk-table"
            >
              {alertTable &&
              alertTable.length > 0 &&
              key !== 'STRATEGY_TEMPLATE_APPROVAL' &&
              key !== 'GENERAL_MESSAGE' &&
              columnData ? (
                <>
                  {!groupedArr.includes((el) => el.tabKey === tabType) ? (
                    <TreeTable
                      tableCoulumn={columnData}
                      TableData={alertTable}
                      listenToScroll={listenToScroll}
                      scrollPosition={scrollPosition}
                      control={treeControls}
                      grouping={false}
                      tabType={tabType}
                      tableHeight={
                        window.innerHeight -
                        (scrollRef.current &&
                          scrollRef.current.offsetHeight + 65)
                      }
                    />
                  ) : (
                    <TreeTable
                      tableCoulumn={columnData}
                      TableData={alertTable}
                      listenToScroll={listenToScroll}
                      scrollPosition={scrollPosition}
                      control={treeControls}
                      tabType={tabType}
                      grouping={
                        groupedArr.find((el) => el.tabKey === tabType)
                          ? true
                          : false
                      }
                      tableHeight={
                        window.innerHeight -
                        (scrollRef.current &&
                          scrollRef.current.offsetHeight + 65)
                      }
                    />
                  )}
                </>
              ) : (allAlertsData.length > 0 &&
                  key === 'STRATEGY_TEMPLATE_APPROVAL') ||
                key === 'GENERAL_MESSAGE' ? (
                <>
                  <TableAlert
                    table_column={alertTableData}
                    eventkeyName={key}
                    liveData={dataType(tabType)}
                    tabKey={key}
                    checkedAlert={checkedAlert}
                    setCheckedAlert={setCheckedAlert}
                    tableHeight={
                      window.innerHeight -
                      (scrollRef.current && scrollRef.current.offsetHeight + 65)
                    }
                    setIsSuccessDelete={setIsSuccessDelete} // Delete in Strategy Template to refresh the data
                  />
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      </Tab.Container>
      {/* Popup component to show alert messages */}
      {alertDelete ? (
        <Popup
          popupDetails={{ message: CONFRIM_DELETE, buttons: 'doubleButtons' }}
          actionButton={() => {
            handleDeleteAlert(true);
          }}
        />
      ) : null}
      {alertPop ? (
        <Popup
          popupDetails={{ message: alertMsg.message }}
          actionButton={() => {
            setCheckedAlert([]);
            setAlertPop(false);
          }}
        />
      ) : null}
      {genToast ? (
        <SimpleSnackbar
          toaster={genToast}
          setToaster={setGenToast}
          message={toastMsg}
        />
      ) : null}
    </div>
  );
};

export default AlertsAndNotification;

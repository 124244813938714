const initialState = {
  data: [],
  user: [],
  delete: false,
  block: false,
  unblock: false
}
const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER":
      return {
        ...state,
        data: action.payload
      }
    case "GET_USERNAME":
      return {
        ...state,
        user: action.payload
      }
    case "GET_USERDELETE": {
      const filterData = state.data.filter(
        item => item.id !== action.payload.userId
      )
      return {
        ...state,
        delete: action.payload,
        data: filterData
      }
    }
    case "GET_USERBLOCKUNBLOCK":
      return {
        ...state,
        block:action.payload
      }
    case "GET_USERUNBLOCK":
      return {
        ...state,
        unblock: action.payload
      }
      case "CLEAR_DELETE":
        return {
          ...state,
          delete: action.clearMessage
        }
      
    default:
      return state
  }
}

export default userDataReducer

import './masterDataPopUp.css';
import GenericPopup from '../../../sharedComponents/genPopup';
import {
  GenToolTip,
  DropDown,
  FeedbackPopup,
  GenButton,
  InputField,
  SearchMultiSelect,
  ShowMultiSelect,
} from '../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { MasterDataActions } from './masterDataRedux';
import { MakeRequest } from '../../../../utils';
import Styled from 'styled-components';
import Popup from '../../../sharedComponents/Popup/Popup';

const ErrorMessage = Styled.div`
  font-familyt: var(--def-font);
  font-size: var(--font-size);
  color: red;
  margin: 0 auto 0 1vw;
  max-width: 20vw;

`;

export function MasterTemplatePopup(props) {
  const { popupState, setPopupState } = props;
  const { popup, editId } = popupState;
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    mappedStrategyTempOptions: [],
    mappedStragey: {},
    baseTemplate: '',
    templateName: '',
    description: '',
  });
  const { strategyTemplates } = useSelector((state) => state.MasterDataReducer);
  const actions = new MasterDataActions(dispatch);
  const [errorText, setError] = useState('');
  const contRef = useRef(null);
  const [pop, setPop] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  useEffect(() => {
    setFormState({
      ...formState,
      mappedStrategyTempOptions: strategyTemplates,
    });
  }, [strategyTemplates]);

  const handlePopupClose = () => {
    setPop({
      type: '',
      message: '',
      buttons: '',
      state: false,
    });
    setPopupState({ popup: !popup, editId: false });
  };

  function clearForm(formStatus) {
    if (formStatus) {
      setFormState({
        ...formState,
        mappedStragey: {},
        baseTemplate: '',
        templateName: '',
        description: '',
      });
    }
  }
  async function makeCall(type) {
    const responsePacket = {
      mappedTemplates: [46892, 46893],
      baseTemplateID: 46892,
      name: 'Master 01',
      description: 'master01',
    };
    responsePacket.name = formState.templateName;
    responsePacket.description = formState.description;
    const names = Object.keys(formState.mappedStragey).filter(
      (item) => formState.mappedStragey[item]
    );
    responsePacket.mappedTemplates = formState.mappedStrategyTempOptions
      .filter((item) => names.includes(item.name))
      .map((item) => item.id);

    responsePacket.baseTemplateID = formState.mappedStrategyTempOptions.filter(
      (item) => item.name === formState.baseTemplate
    )[0].id;
    if (editId) {
      responsePacket.templateId = editId;
    }
    const res = await actions.submitApiCall(responsePacket, type);

    if (res.state) {
      setPop({
        ...pop,
        message: `Your Master template '${formState.templateName}' is successfully created`,
        nameAccept: 'CLOSE',
        nameDecline: 'CLOSE',
        state: true,
      });
    } else {
      setError(res.data);
    }
  }

  async function setEditData(id) {
    let editData = await MakeRequest(
      `/strategyTemplate/returnMaster?templateId=${id}`
    ).get();
    editData = editData.data.data;
    const obj = {
      ...formState,
      templateName: editData.name,
      description: editData.description,
      mappedStrategyTempOptions: [
        ...formState.mappedStrategyTempOptions,
        ...editData.mappedTemplates,
      ],
    };

    obj.baseTemplate = obj.mappedStrategyTempOptions.filter(
      (item) => item.id === editData.baseTemplateID
    )[0]?.name;

    for (const item of editData.mappedTemplates) {
      obj.mappedStragey[item.name] = true;
    }

    setFormState(obj);
  }

  useEffect(() => {
    if (editId && formState.mappedStrategyTempOptions.length > 0) {
      setEditData(editId);
    } else if (editId === false) {
      clearForm(true);
    }
  }, [editId]);

  return (
    <GenericPopup
      state={popup}
      toggleState={() => setPopupState({ popup: !popup, editId: false })}
      type={1}
    >
      <div className="master-temp-main-cont">
        <div className="master-temp-main-head">
          <span className="master-temp-main-head-text">
            Create Master Template
          </span>
        </div>
        <div className="master-temp-main-body">
          <InputField
            label="Template Name"
            inputWidth="15vw"
            extStyles={{ margin: 'auto' }}
            value={formState.templateName}
            onValueChange={(val) =>
              setFormState({ ...formState, templateName: val })
            }
            type="text"
          />
          <InputField
            label="Description"
            inputWidth="30vw"
            extStyles={{ margin: 'auto' }}
            value={formState.description}
            onValueChange={(val) =>
              setFormState({ ...formState, description: val })
            }
            type="text"
          />
          <SearchMultiSelect
            extStyles={{ width: '15vw', margin: 'auto' }}
            label="Map Strategy Templates"
            options={formState.mappedStrategyTempOptions.map(
              (item) => item.name || ''
            )}
            onSelect={(item, state) => {
              setFormState({
                ...formState,
                mappedStragey: { ...formState.mappedStragey, [item]: state },
                baseTemplate:
                  !state && formState.baseTemplate === item
                    ? ''
                    : formState.baseTemplate,
              });
            }}
            selected={formState.mappedStragey}
          />
          <ShowMultiSelect
            extStyles={{ width: '30vw', margin: 'auto' }}
            label="Mapped Templates"
            data={Object.keys(formState.mappedStragey).filter(
              (item) => formState.mappedStragey[item]
            )}
            removeFunc={(val) =>
              setFormState({
                ...formState,
                baseTemplate:
                  formState.baseTemplate === val ? '' : formState.baseTemplate,
                mappedStragey: { ...formState.mappedStragey, [val]: false },
              })
            }
          />
          <DropDown
            ddExtStyles={{ width: '15vw' }}
            extStyles={{ margin: 'auto' }}
            label="Select Base Template"
            orientation="v"
            options={Object.keys(formState.mappedStragey).filter(
              (item) => formState.mappedStragey[item]
            )}
            onSelect={(val) =>
              setFormState({ ...formState, baseTemplate: val })
            }
            selected={formState.baseTemplate}
            enable_search={true}
          />
        </div>
        <div className="master-temp-main-foot" ref={contRef}>
          <GenToolTip tooltip={errorText} containerRef={contRef}>
            <ErrorMessage>{`${errorText.slice(0, 100)}...`}</ErrorMessage>
          </GenToolTip>
          <GenButton
            title={editId ? 'UPDATE' : 'SUBMIT'}
            extStyle={{ margin: '0 0.5vw 0 0.5vw', width: '10vw' }}
            fill={true}
            clickAction={() => makeCall(editId ? 'UPDATE' : 'SUBMIT')}
          />
          <GenButton
            title="SAVE AS DRAFT"
            extStyle={{ margin: '0 0.5vw 0 0.5vw', width: '10vw' }}
            fill={false}
            clickAction={() => makeCall('SAVE')}
          />
          <GenButton
            title="CLOSE"
            extStyle={{ margin: '0 0.5vw 0 0.5vw', width: '10vw' }}
            fill={false}
            clickAction={() => setPopupState({ popup: !popup, editId: false })}
          />
          {pop.state ? (
            <FeedbackPopup
              message={pop.message}
              acceptAction={handlePopupClose}
            />
          ) : null}
        </div>
      </div>
    </GenericPopup>
  );
}

import '../../../global.css';
import styled from 'styled-components';

export const LocationText = styled.span`
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: 0 1vw 0 1vw;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #cecece;
`;

import React, { useEffect, useState, useRef } from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  strategyAnalyzerSearch,
  strategyAnalyzerTable,
  updatePageNumber,
  updatePostPerPage,
  updateSearchBy,
  updateSearchString,
  updateSortCulmnAnalyzer,
  updateStatusList,
} from '../../../actions/strategyAnalyzerAction';
import UiSelector from '../../sharedComponents/Dropdowns';
import Table from '../../sharedComponents/Table/Table';
import './StrategyAnalyzer.scss';
import Pen from '../../../assets/img/pen_blue.svg';
import EditPen from '../../../assets/img/Group 162767.svg';
import CheckBoxBar from './CheckBoxBar/CheckBoxBar';
import Search from '../../../assets/img/icon-search.png';

import {
  strategyAnalyzerEdit,
  sqOffDetails,
} from '../../../actions/strategyAnalyzerAction';
import ActionButtons from './ActionButtons/ActionButtons';
import Dropdown from 'react-bootstrap/Dropdown';
import { status } from '../../../config/apiConfig';
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import filter from '../../../assets/img/filter.svg';
import { webSocketUrl } from '../../../config/apiConfig';
import { TableEntries } from '../../sharedComponents/TableEntries/TableEntries';
import Popup from '../../sharedComponents/Popup/Popup';
var W3CWebSocket = require('websocket').w3cwebsocket;

const StrategyAnalyzerWindow = ({ analyzereRef, selectBoxRef }) => {
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [selectedBox, setSelectedBox] = useState(false);
  const [searchingBy, setSearchBy] = useState({ id: 'STRATEGY_NAME', name: 'Strategy Name' });
  const [toaster, setToaster] = useState(false);
  const [toasterMsg, setToasterMsg] = useState('');
  const [liveData, setLiveData] = useState();
  const [plShow, setPlShow] = useState(true);
  const [grossShow, setGrossShow] = useState(true);
  const [selectedId, setSelectedId] = useState([]);
  const [pausePop, setPausePop] = useState(false);
  const [buttonReq, setButtonReq] = useState('');
  const [selectedSqOff, setSelectedSqOff] = useState([]);
  const {
    strategyAnalyzerReducer: {
      strategyTable,
      strategyState,
      strategyTableParam,
      strategyAnalyzerButtonLoader,
      sortingType,
      sortColumn,
      strategyTableLoaded,
      searchBy,
      searchString,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const [strategyData, setStrategyData] = useState([]);
  const [userShow, setUserShow] = useState([...strategyData]);
  const [currentPosts, setCurrentPosts] = useState([...setCurrentPostfun()]);
  const [showPosts] = useState([5, 10, 20, 30]);
  const [expand, setExpand] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [rowIndex, setRowIndex] = useState('');
  const userId = localStorage.getItem('customerId');
  const [tradeModTog, setTradeModTog] = useState(false);

  // function for strategy analyzer open in new window
  const NewWindow = (id, strategyTemplateId) => {
    let newWindow = window.open(
      `/window/edit-strategy/${window.btoa(id)}/${window.btoa(
        strategyTemplateId
      )}`,
      '_blank',
      'top=100, left=100, width=1857, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
    dispatch(strategyAnalyzerEdit(id));
  };

  // function to open trade-modification window
  const NewTradeModificationWindow = (id, strategyTemplateId) => {
    let newWindow = window.open(
      `/window/trade-modify-strategy/${window.btoa(id)}/${window.btoa(
        strategyTemplateId
      )}`,
      '_blank',
      'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
    dispatch(strategyAnalyzerEdit(id));
  };

  useEffect(() => {
    var client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    funInitialise(client);
  }, []);

  // websocket for strategy analyzer data
  function funInitialise(client) {
    client.onerror = function () {};

    try {
      if (strategyTable.length > 0) {
        console.log('strategyTable', strategyTable);
        client.onopen = function () {
          const data = {
            eventType: 'ANALYZER_SUBSCRIBE',
            payload: {
              userId: parseInt(userId),
              strategyIdList: JSON.stringify(strategyTable.strategyIdList),
            },
          };
          client.send(JSON.stringify(data));
        };

        client.onclose = function () {};

        client.onmessage = function (data) {
          let dataStr = JSON.parse(data.data);
          let webObj = { ...dataStr.payload };
          setLiveData(webObj);
        };
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const [statusData, setStatusData] = useState([
    'ACTIVE',
    'PAUSED',
    'COMPLETED',
    'CLOSED',
    'IN_PROCESS',
    'TRADE_MODIFY_ERROR',
    'TRADE_MODIFY_SUCCESS',
    'EA_TRADE_MODIFY_SUCCESSFUL',
    'ODIN_TRADE_MODIFY_IN_PROCESS',
    'ODIN_TRADE_MODIFY_SUCCESS',
  ]);

  useEffect(() => {
    setCheckedStatus(status);
    localStorage.removeItem('forceSquareOff');
  }, []);

  const [checkedStatus, setCheckedStatus] = useState([]);

  // set default popups after modal
  useEffect(() => {
    switch (popup.type) {
      case 'pausePopup':
        setPopup({ ...popup, message: messageFunc(), buttons: 'singleButton' });
        break;
      default:
        break;
    }
  }, [popup.type]);

  // set functionalty for popup
  const actionOnpopup = (e, type) => {
    if (type === 'CLOSE') {
      setPopup({ state: false });
    }
  };

  // change status on click on pause and resume button in table row
  const handleStatusData = (e, item) => {
    let setObj = status.find((el) => el.id === item.id);

    let checked = e.target.checked;
    if (checked) {
      if (item.label === 'IN PROCESS') {
        let value =
          'RESUME_IN_PROCESS,PAUSE_IN_PROCESS,SQUARE_OFF_IN_PROCESS,MODIFY_IN_PROCESS,CLOSE_IN_PROCESS,TRADE_MODIFY_IN_PROCESS';
        value = value.split(',').map((el) => el.trim());
        setStatusData([...statusData, setObj.id]);
        dispatch(
          updateStatusList([...strategyTableParam.statusList, ...value])
        );
      } else {
        setStatusData([...statusData, setObj.id]);
        dispatch(
          updateStatusList([...strategyTableParam.statusList, setObj.id])
        );
      }
    } else {
      let tempData = statusData.filter((el) => {
        return el !== setObj.id;
      });
      setStatusData(tempData);
      let tempStatus = strategyTableParam.statusList.filter((el) => {
        return !el.includes(setObj.id);
      });
      dispatch(updateStatusList(tempStatus));
    }
    if (searchBy !== '' && searchString !== '') {
      dispatch(
        strategyAnalyzerSearch({
          field: searchingBy.id,
          value: searchString,
          pageNumber: 1,
          entriesPerPage: strategyTableParam.postPerPage,
        })
      );
    } else {
      dispatch(strategyAnalyzerTable());
    }
  };

  // update data in the table on new update by websocket
  useEffect(() => {
    let client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    if (client && strategyTable.length > 0) {
      client.onopen = () => {
        if (client.readyState === client.OPEN) {
          const data = {
            eventType: 'ANALYZER_SUBSCRIBE',
            payload: {
              userId: parseInt(userId),
              strategyIdList: JSON.stringify(strategyTable.strategyIdList),
            },
          };

          client.send(JSON.stringify(data));
        }
      };
      client.onmessage = (data) => {
        let dataStr = JSON.parse(data.data);
        let webObj = { ...dataStr.payload };
        setLiveData(webObj);
        if (webObj.refreshFlag) {
          dispatch(strategyAnalyzerTable());
          const data = {
            eventType: 'ANALYZER_REFRESHED',
            payload: {
              userId: parseInt(userId),
            },
          };
          client.send(JSON.stringify(data));
        }
      };
      client.onclose = () => {};
    }
    return () => {
      client.close();
    };
  }, [strategyTable]);

  useEffect(() => {
    localStorage.setItem('header', 'strategyanalyzer');
    return () => {
      dispatch(updatePageNumber(1));
      dispatch(updatePostPerPage(5));
      localStorage.removeItem('forceSquareOff');
    };
  }, []);

  useEffect(() => {
    setFlag(!flag);
  }, [strategyState, strategyAnalyzerButtonLoader]);
  // set data in the search field
  const handleSearchBy = (el) => {
    setSearchBy(el);
    dispatch(updateSearchBy(el.id));
    dispatch(updateSearchString(''));
  };
  // handle search functionalty
  const searchHandler = (e) => {
    let eventData = { ...e };
    if (searchingBy.id === 'STRATEGY_ID') {
      eventData['target']['value'] = e.target.value.replace(/[^0-9.]/g, '');
    }
    dispatch(updateSearchString(eventData.target.value));
  };
  useEffect(() => {
    if (searchString === '') {
      dispatch(strategyAnalyzerTable());
    }
  }, [searchString]);

  let searchDropdown = [
    { id: 'STRATEGY_TEMPLATE', name: 'Strategy Template' },
    { id: 'STRATEGY_SECURITY', name: 'Strategy Security' },
    { id: 'STRATEGY_NAME', name: 'Strategy Name' },
    { id: 'STRATEGY_ID', name: 'Strategy Id' },
  ];
  // handle single checkbox
  const handleCheckbox = (e, id, stratName) => {
    let selectedArr = [...selectedId];
    let nameArr = [...selectedSqOff];
    if (e.target.checked) {
      selectedArr.push(id);
      nameArr.push({ id, name: stratName });
      setSelectedId(selectedArr);
      setSelectedSqOff(nameArr);
    } else {
      let listIndex = selectedArr.indexOf(id);
      let nameIndex = nameArr.findIndex((el) => el.id === id);
      if (listIndex > -1) {
        selectedArr.splice(listIndex, 1);
        setSelectedId(selectedArr);
      }
      if (nameIndex > -1) {
        nameArr.splice(nameIndex, 1);
        setSelectedSqOff(nameArr);
      }
    }
    setSelectedBox(true);
    dispatch(sqOffDetails({ id: selectedArr, name: nameArr }));
    localStorage.setItem(
      'forceSquareOff',
      JSON.stringify({ id: selectedArr, name: nameArr })
    );
  };
  // handle main checkbox in the header for all checkbox
  const handleCheckboxAll = (e) => {
    if (e.target.checked) {
      setSelectedId(strategyTable.strategyIdList);
      let strategyArr = strategyTable.analyzerObject.map((el) => {
        return {
          id: el.data.id,
          name: el.data.strategyName,
        };
      });
      setSelectedSqOff(strategyArr);
      dispatch(
        sqOffDetails({ id: strategyTable.strategyIdList, name: strategyArr })
      );
      localStorage.setItem(
        'forceSquareOff',
        JSON.stringify({ id: strategyTable.strategyIdList, name: strategyArr })
      );
    } else {
      setSelectedId([]);
      setSelectedSqOff([]);
      dispatch(sqOffDetails({ id: [], name: [] }));
      localStorage.setItem(
        'forceSquareOff',
        JSON.stringify({ id: [], name: [] })
      );
    }
    setSelectedBox(true);
  };
  // expand data on legs coloumn
  const expandHandler = (index) => {
    if (rowIndex !== index) {
      setExpand(true);
    } else {
      setExpand(!expand);
    }
    setRowIndex(index);
  };

  // show data in the table
  let table_coulumn = [
    {
      lebel: (
        <span className="theadcheckbox">
          <label className="containerr">
            <input
              type="checkbox"
              checked={
                strategyTable.strategyIdList?.length &&
                selectedId?.length === strategyTable.strategyIdList?.length
              }
              id=""
              name=""
              value=""
              onClick={(e) => handleCheckboxAll(e)}
            />
            <span className="checkmark"></span>
          </label>
        </span>
      ),
      className: '',
      renderHtml: (params, index, totalPosts) => (
        <span>
          <label className="containerr">
            <input
              type="checkbox"
              checked={selectedId.includes(params.listId)}
              id=""
              name=""
              value=""
              onClick={(e) =>
                handleCheckbox(e, params.listId, params.data.strategyName)
              }
            />
            <span className="checkmark"></span>
          </label>
        </span>
      ),
    },

    {
      lebel: 'ID',
      value: 'id',
      renderHtml: (params, index, totalPosts) => <span>{params.data.id}</span>,
    },
    {
      lebel: 'Strategy Name',
      renderHtml: (params, index, totalPosts) => (
        <span>{params.data.strategyName}</span>
      ),
    },
    {
      lebel: '#Legs',
      renderHtml: (params, index, totalPosts) => {
        return (
          <>
            <span> {params.data?.legData?.length}</span>
            {params.data?.legData?.length > 2 ? (
              <p className="expand-btn" onClick={() => expandHandler(index)}>
                <u>{expand && rowIndex === index ? 'Collapse' : 'Expand'}</u>
              </p>
            ) : null}
          </>
        );
      },
    },
    {
      lebel: 'Security',
      renderHtml: (params, index, totalPosts) => {
        const securityRender = (params) => {
          let ruleList = [];
          if (params && params.data && params.data?.legData) {
            let legsData = params.data?.legData;
            if (legsData.length > 2 && rowIndex === index && expand) {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li> {data.security} </li>;
                })
              );
            } else if (legsData.length > 2) {
              ruleList.push(
                legsData?.map((data, index) => {
                  if (index < 2) {
                    return <li> {data.security} </li>;
                  }
                })
              );
            } else {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li> {data.security} </li>;
                })
              );
            }
          }
          return <>{ruleList}</>;
        };
        return <ul>{securityRender(params)}</ul>;
      },
    },
    {
      lebel: 'Open Ord.',
      renderHtml: (params, index, totalPosts) => {
        let newObj = {};
        let finalObj = {};
        if (
          params.legData &&
          params.legData.legData &&
          Object.keys(params.legData.legData).length
        ) {
          newObj = params.legData.legData;
          let index = Object.keys(params.legData.legData)[0];
          finalObj = newObj[index];
        }

        const openRender = (params, finalObj) => {
          let ruleList = [];
          if (params && params.data && params.data.legData) {
            let legsData = params.data?.legData;
            if (legsData.length > 2 && rowIndex === index && expand) {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li>{finalObj?.openOrders}</li>;
                })
              );
            } else if (legsData.length > 2) {
              ruleList.push(
                legsData?.map((data, index) => {
                  if (index < 2) {
                    return <li>{finalObj?.openOrders}</li>;
                  }
                })
              );
            } else {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li>{finalObj?.openOrders}</li>;
                })
              );
            }
          }
          return <>{ruleList}</>;
        };
        return <ul>{openRender(params, finalObj)}</ul>;
      },
    },
    {
      lebel: 'Traded Qty.',
      renderHtml: (params, index, totalPosts) => {
        let newObj = {};
        let finalObj = {};
        if (
          params.legData &&
          params.legData.legData &&
          Object.keys(params.legData.legData).length
        ) {
          newObj = params.legData.legData;
          let index = Object.keys(params.legData.legData)[0];
          finalObj = newObj[index];
        }
        const tradedRender = (params, finalObj) => {
          let ruleList = [];
          if (params && params.data && params.data.legData) {
            let legsData = params.data.legData;
            if (legsData.length > 2 && rowIndex === index && expand) {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li>{finalObj?.tradedQty}</li>;
                })
              );
            } else if (legsData.length > 2) {
              ruleList.push(
                legsData?.map((data, index) => {
                  if (index < 2) {
                    return <li>{finalObj?.tradedQty}</li>;
                  }
                })
              );
            } else {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li>{finalObj?.tradedQty}</li>;
                })
              );
            }
          }
          return <>{ruleList}</>;
        };
        return <ul>{tradedRender(params, finalObj)}</ul>;
      },
    },
    {
      lebel: 'Total Qty.',
      renderHtml: (params, index, totalPosts) => {
        const totalRender = (params) => {
          let ruleList = [];
          if (params && params.data && params.data?.legData) {
            let legsData = params.data?.legData;
            if (legsData.length > 2 && rowIndex === index && expand) {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li>{data.totalQty}</li>;
                })
              );
            } else if (legsData.length > 2) {
              ruleList.push(
                legsData?.map((data, index) => {
                  if (index < 2) {
                    return <li>{data.totalQty}</li>;
                  }
                })
              );
            } else {
              ruleList.push(
                legsData?.map((data, index) => {
                  return <li>{data.totalQty}</li>;
                })
              );
            }
          }
          return <>{ruleList}</>;
        };
        return <ul>{totalRender(params)}</ul>;
      },
    },
    {
      lebel: (
        <div
          id="list1"
          className="dropdown-check-list custom-dropdwon  select-check-box-custom dropdown-toggle"
          onClick={() => {
            setIsActive(true);
          }}
        >
          <UiSelector
            multiple
            listData={
              status.length > 0 &&
              status.map((i) => {
                return {
                  id: i.id,
                  label: i.name,
                };
              })
            }
            handlerFunction={handleStatusData}
            drop={dropdownRef}
            isActive={isActive}
            selectIds={statusData}
            predefineLabel={'Status'}
          />
          <img src={filter} />
        </div>
      ),

      renderHtml: (params, index, totalPosts) => {
        return (
          <>
            <span
              className={
                params.legData?.status?.status === 'PAUSED'
                  ? 'in-pause'
                  : 'status-color'
              }
            >
              {' '}
              {params.legData?.status?.status}
            </span>
          </>
        );
      },
    },

    {
      lebel: 'Pause/ Resume',
      renderHtml: (params, index, totalPosts) => {
        return (
          <ActionButtons params={params.legData?.status} label="PAUSE/RESUME" />
        );
      },
    },
    {
      lebel: tradeModTog ? 'Trade Modify' : 'Edit',
      renderHtml: (params, index, totalPosts) => {
        return (
          <>
            {tradeModTog ? (
              params.legData?.status?.enableTradeModify || true ? (
                <span
                  // className="in-edit"
                  onClick={(e) =>
                    NewTradeModificationWindow(
                      params.listId,
                      params.data.strategyTemplateId
                    )
                  }
                >
                  {' '}
                  <img src={Pen} className="cursor-pointer" />
                </span>
              ) : (
                <span>
                  {' '}
                  <img src={EditPen} />
                </span>
              )
            ) : params.legData.status?.enableModify === true ? (
              <span
                className="in-edit"
                onClick={(e) =>
                  NewWindow(params.listId, params.data.strategyTemplateId)
                }
              >
                {' '}
                <img src={EditPen} className="cursor-pointer" />
              </span>
            ) : (
              <span
                onClick={(e) =>
                  NewWindow(params.listId, params.data.strategyTemplateId)
                }
              >
                {' '}
                <img src={Pen} className="cursor-pointer" />
              </span>
            )}
          </>
        );
      },
    },
    {
      lebel: 'Close',
      renderHtml: (params, index, totalPosts) => {
        return <ActionButtons params={params.legData?.status} label="CLOSE" />;
      },
    },
  ];

  // function for how many posts show in the table
  const handleShowPost = (e) => {
    if (e.target.value !== '') {
      dispatch(updatePageNumber(1));
      dispatch(updatePostPerPage(Number(e.target.value)));
      setPostsPerPage(e.target.value);
      if (searchBy !== '' && searchString !== '') {
        dispatch(
          strategyAnalyzerSearch({
            field: searchBy,
            value: searchString,
            pageNumber: strategyTableParam.pageNumber,
            entriesPerPage: strategyTableParam.postPerPage,
          })
        );
      } else {
        dispatch(strategyAnalyzerTable());
      }
      setSelectedId([]);
      setSelectedSqOff([]);
      localStorage.setItem(
        'forceSquareOff',
        JSON.stringify({ id: [], name: [] })
      );
    }
  };

  useEffect(() => {
    if (strategyTable && strategyTable.analyzerObject) {
      let tableData = strategyTable.analyzerObject;
      let strategyArray = [];
      let newTable = tableData.forEach((el, index) => {
        strategyArray.push({
          ...el,
          listId: strategyTable.strategyIdList[index],
        });
      });
      setStrategyData(strategyArray);
    }
  }, [strategyTable]);

  function setCurrentPostfun() {
    const indexOfLastPost = strategyTableParam.pageNumber * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsdata;
    if (strategyData) {
      currentPostsdata = strategyData.slice(indexOfFirstPost, indexOfLastPost);
    }
    return currentPostsdata;
  }

  useEffect(() => {
    if (userShow && currentPosts.length) {
      let currPage = userShow.length % currentPosts.length;
      if (currPage === 0) {
        paginate(
          strategyTableParam.pageNumber === 1
            ? strategyTableParam.pageNumber
            : strategyTableParam.pageNumber - 1
        );
      } else {
        paginate(strategyTableParam.pageNumber);
      }
    }
  }, [postsPerPage]);

  const paginate = (pageNumber) => {
    dispatch(updatePageNumber(pageNumber));
    if (searchBy !== '' && searchString !== '') {
      dispatch(
        strategyAnalyzerSearch({
          field: searchingBy,
          value: searchString,
          pageNumber: pageNumber,
          entriesPerPage: strategyTableParam.postPerPage,
        })
      );
    } else {
      dispatch(strategyAnalyzerTable());
    }
  };

  useEffect(() => {
    const indexOfLastPost = strategyTableParam.pageNumber * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsData;
    if (userShow) {
      currentPostsData = [...userShow];
    }
    setCurrentPosts([...currentPostsData]);
  }, [strategyTableParam.pageNumber, userShow, postsPerPage]);

  useEffect(() => {
    setUserShow([...strategyData]);
  }, [strategyData]);

  let tableData =
    strategyData && strategyData.length > 0
      ? strategyData?.map((el) => {
          el['legData'] = liveData ? liveData[el.listId] : [];
          return el;
        })
      : [];
  // sorting for status column
  const sortByName = (type, array, key) => {
    var byName = array.slice(0);
    let stringColumn = ['status'];

    if (key) {
      if (stringColumn.indexOf(key) !== -1) {
        if (type === 'desc') {
          byName.sort(function (a, b) {
            var x = a[key]?.toLowerCase();
            var y = b[key]?.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
        } else {
          byName.sort(function (a, b) {
            var x = a[key]?.toLowerCase();
            var y = b[key]?.toLowerCase();
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
      } else {
        if (type === 'desc') {
          byName.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
          });
        } else {
          byName.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
      }
    }

    return byName;
  };

  const sortingFuction = (type, column) => {
    dispatch(
      updateSortCulmnAnalyzer({ sortColumn: column.field, sortingType: type })
    );
  };

  tableData = sortByName(sortingType, tableData, sortColumn);

  const filterTableColumn = (table_coulumn) => {
    let columns = table_coulumn.filter((el) =>
      !plShow ? el.lebel !== 'P/L' : el
    );
    table_coulumn = columns.filter((el) =>
      !grossShow ? el.lebel !== 'Gross Exposure' : el
    );
    return table_coulumn;
  };
  const fetchSearchedData = () => {
    dispatch(updatePageNumber(1));
    dispatch(
      strategyAnalyzerSearch({
        field: searchingBy.id,
        value: searchString,
        pageNumber: 1,
        entriesPerPage: strategyTableParam.postPerPage,
      })
    );
  };

  const messageFunc = () => {
    let strategyId = JSON.parse(localStorage.getItem('forceSquareOff'))?.name;
    return (
      <>
        <span className="button-req">{buttonReq} </span>
        <p>
          Strategy IDs:{' '}
          {strategyId &&
            strategyId.length &&
            strategyId
              .map((el) => {
                return `${el.id} - ${el.name}`;
              })
              .join(', ')}
        </p>
      </>
    );
  };
  return (
    <div className="analyzer">
      <div className="analyzer-header" ref={analyzereRef}>
        <TableEntries
          role="postPerPage"
          value={postsPerPage}
          postList={showPosts}
          postHandler={handleShowPost}
        />

        <Nav.Item className=" main_search">
          <div className="search_bar">
            <div className="box">
              <Dropdown>
                <Dropdown.Toggle className="search" id="dropdown-basic">
                  {searchingBy.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {searchDropdown.map((el, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={el.id}
                      onClick={(e) => handleSearchBy(el)}
                    >
                      {el.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <input
                type="text"
                name="search"
                value={searchString}
                readOnly={searchingBy.name === 'Search By'}
                onChange={(e) => searchHandler(e)}
                onClick={(e) => {
                  if (searchingBy.name === 'Search By') {
                    setToaster(true);
                    setToasterMsg('Please select search criteria');
                  }
                }}
                onKeyPress={(e) => {
                  if (e.code === 'Enter') {
                    if (searchString) {
                      fetchSearchedData();
                    } else {
                      setToaster(true);
                      setToasterMsg('Please enter any value to search');
                    }
                  }
                }}
              />
              <div
                className="icon"
                onClick={() => {
                  if (searchString) {
                    fetchSearchedData();
                  } else {
                    setToaster(true);
                    setToasterMsg('Please enter any value to search');
                  }
                }}
              >
                <img src={Search} alt="search icon" />
              </div>
            </div>
          </div>
        </Nav.Item>
        <div className="analyzer-right"></div>
      </div>
      {selectedId.length > 0 ? (
        <CheckBoxBar
          setPausePop={setPausePop}
          setPopup={setPopup}
          setSelectedSqOff={setSelectedSqOff}
          selectedBox={selectedBox}
          setSelectedBox={setSelectedBox}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setButtonReq={setButtonReq}
          selectBoxRef={selectBoxRef}
        />
      ) : null}
      <div className="row">
        <div className="col-12">
          {strategyTableLoaded ? (
            <Table
              TableData={tableData}
              tableCoulumn={filterTableColumn(table_coulumn)}
              className={'table table-striped table-main'} //table main class added
              rowBodyClass={tableData.length > 0 ? 'active' : ''}
              showPagination={true}
              postsPerPage={postsPerPage}
              paginate={paginate}
              currentPage={strategyTableParam.pageNumber}
              sorting={true}
              sortColumn={sortColumn}
              sortingFuction={sortingFuction}
              postsLength={strategyTable.totalStrategies}
              maxPosts={showPosts[showPosts.length - 1]}
              elipsisPagination={true}
            ></Table>
          ) : (
            <Spinner animation="border" variant="dark" />
          )}
        </div>
      </div>
      {toaster ? (
        <SimpleSnackbar
          toaster={toaster}
          setToaster={setToaster}
          message={toasterMsg}
        />
      ) : null}

      {popup.state ? (
        <Popup actionButton={actionOnpopup} popupDetails={popup} />
      ) : null}
    </div>
  );
};

export default StrategyAnalyzerWindow;

import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import LandingPage from '../landingPage';
import Layout from '../../Layout';

export default function AppEntry() {
  const token = localStorage.getItem('token');
  return (
    <Fragment>
      {token ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <LandingPage />
      )}
    </Fragment>
  );
}

import React, { useEffect, useState } from 'react';
import style from './styles.module.css';
import { InputField } from '../../../components';
import { fetchValue, updateValue } from '../../../utils/dotKeyFunctions';
import { MakeRequest } from '../../../utils';
import { DeleteBlueStroke } from '../../../assets';
import { V2DropDown } from '../../../advComponents';
import Logger from '../../../utils/logger';

export default function NISESDDCombo({ data, objKey, setData, triggerDelete }) {
  const [ddStates, setDdStates] = useState({
    instrument: { disabled: false, options: [] },
    symbol: {
      disabled: true,
      options: [],
    },
    expiry: {
      disabled: true,
      options: [],
    },
    strike: {
      disabled: true,
      options: [],
    },
    delete: { disabled: true },
  });

  useEffect(() => {
    let tempData = { ...ddStates };
    switch (fetchValue(data, `${objKey}.instrument`)) {
      case 'CE':
        tempData = updateValue(tempData, 'symbol.disabled', false);
        tempData = updateValue(tempData, 'expiry.disabled', false);
        tempData = updateValue(tempData, 'strike.disabled', false);
        break;
      case 'PE':
        tempData = updateValue(tempData, 'symbol.disabled', false);
        tempData = updateValue(tempData, 'expiry.disabled', false);
        tempData = updateValue(tempData, 'strike.disabled', false);
        break;
      case 'Future':
        tempData = updateValue(tempData, 'symbol.disabled', false);
        tempData = updateValue(tempData, 'expiry.disabled', false);
        tempData = updateValue(tempData, 'strike.disabled', true);
        break;
      case 'Equity':
        tempData = updateValue(tempData, 'symbol.disabled', false);
        tempData = updateValue(tempData, 'expiry.disabled', true);
        tempData = updateValue(tempData, 'strike.disabled', true);
        break;
      default:
        tempData = updateValue(tempData, 'instrument.disabled', false);
        tempData = updateValue(tempData, 'symbol.disabled', true);
        tempData = updateValue(tempData, 'expiry.disabled', true);
        tempData = updateValue(tempData, 'strike.disabled', true);
        break;
    }

    setDdStates(tempData);
  }, [data]);
  const [ready, setReady] = useState(false);

  async function updateSymbols(selectedInst) {
    if (selectedInst) {
      const symbols = await MakeRequest(
        `/security/securities?exchange=NSE&instrument=${
          ddStates.instrument.options.filter(
            (item) => item.name === selectedInst
          )[0]?.key
        }`
      ).get();

      if (symbols.status === 200) {
        setDdStates(updateValue(ddStates, 'symbol.options', symbols.data.data));
        setData(
          updateValue(
            data,
            `${objKey}.symbol`,
            fetchValue(data, objKey).symbol
              ? fetchValue(data, objKey).symbol
              : ''
          )
        );
      }
    } else {
      setData(
        updateValue(
          data,
          `${objKey}.symbol`,
          fetchValue(data, objKey).symbol ? fetchValue(data, objKey).symbol : ''
        )
      );
      setDdStates(updateValue(ddStates, 'symbol.disabled', true));
    }
  }

  async function updateExpiry(selectedInst, selectedSymbol) {
    try {
      if (selectedInst === 'Equity') {
        setData(updateValue(data, `${objKey}.expiry`, ''));

        setDdStates(updateValue(ddStates, 'expiry.options', []));
        return;
      }
      if (selectedInst && selectedSymbol) {
        const expiry = await MakeRequest(
          `/security/expires?exchange=NSE&instrument=${
            ddStates.instrument.options.filter(
              (item) => item.name === selectedInst
            )[0]?.key
          }&security=${selectedSymbol}`
        ).get();
        if (expiry.status === 200) {
          setDdStates(
            updateValue(
              ddStates,
              'expiry.options',
              expiry.data.data.map((item) => item.expiry)
            )
          );
          setData(
            updateValue(
              data,
              `${objKey}.expiry`,
              fetchValue(data, objKey).expiry
                ? fetchValue(data, objKey).expiry
                : ''
            )
          );
        }
      } else {
        setData(
          updateValue(
            data,
            `${objKey}.expiry`,
            fetchValue(data, objKey).expiry
              ? fetchValue(data, objKey).expiry
              : ''
          )
        );
        setDdStates(updateValue(ddStates, 'expiry.disabled', true));
      }
    } catch (error) {
      Logger(
        'src/components/sharedV2/NickInsSymbExpiStriDDCombo/index.jsx 120',
        error
      );
    }
  }

  async function updateStrike(selectedInst, selectedSymbol, selectedExpiry) {
    try {
      if (
        selectedInst &&
        selectedExpiry &&
        selectedSymbol &&
        selectedInst !== 'Future' &&
        selectedInst !== 'Equity'
      ) {
        const strikes = await MakeRequest(
          `/security/strikes?exchange=NSE&instrument=${
            ddStates.instrument.options.filter(
              (item) => item.name === selectedInst
            )[0]?.key
          }&security=${selectedSymbol}&expiry=${selectedExpiry}`
        ).get();

        if (strikes.status === 200) {
          setData(
            updateValue(
              data,
              `${objKey}.strike`,
              fetchValue(data, objKey).strike
                ? fetchValue(data, objKey).strike
                : ''
            )
          );
          const val = Array.isArray(strikes.data.data) ? strikes.data.data : [];
          setDdStates(updateValue(ddStates, 'strike.options', val));
        }
      } else {
        setData(
          updateValue(
            data,
            `${objKey}.strike`,
            fetchValue(data, objKey).strike
              ? fetchValue(data, objKey).strike
              : ''
          )
        );
        setDdStates(updateValue(ddStates, 'strike.disabled', true));
      }
    } catch (error) {
      Logger(
        'src/components/sharedV2/NickInsSymbExpiStriDDCombo/index.jsx 151',
        error
      );
    }
  }

  useEffect(() => {
    updateSymbols(fetchValue(data, objKey).instrument);
    updateExpiry(
      fetchValue(data, objKey).instrument,
      fetchValue(data, objKey).symbol
    );
    updateStrike(
      fetchValue(data, objKey).instrument,
      fetchValue(data, objKey).symbol,
      fetchValue(data, objKey).expiry
    );
  }, [fetchValue(data, objKey).instrument, ready]);

  useEffect(() => {
    updateExpiry(
      fetchValue(data, objKey).instrument,
      fetchValue(data, objKey).symbol
    );
    updateStrike(
      fetchValue(data, objKey).instrument,
      fetchValue(data, objKey).symbol,
      fetchValue(data, objKey).expiry
    );
    Logger(
      'src/components/sharedV2/NickInsSymbExpiStriDDCombo/index.jsx 183',
      'Called for symbol'
    );
  }, [fetchValue(data, objKey).symbol, ready]);

  useEffect(() => {
    updateStrike(
      fetchValue(data, objKey).instrument,
      fetchValue(data, objKey).symbol,
      fetchValue(data, objKey).expiry
    );
    Logger(
      'src/components/sharedV2/NickInsSymbExpiStriDDCombo/index.jsx 195',
      'Called for expiry'
    );
  }, [fetchValue(data, objKey).expiry, ready]);

  useEffect(() => {
    const fetchInstruments = async () => {
      const data = await MakeRequest('/security/instrumenTypes').get();
      setDdStates(updateValue(ddStates, 'instrument.options', data.data.data));
      setReady(true);
    };
    fetchInstruments();
  }, []);

  return (
    <div className={style.container}>
      <InputField
        label="Nick Name"
        value={fetchValue(data, objKey).nickname}
        onValueChange={(val) =>
          setData(updateValue(data, `${objKey}.nickname`, val))
        }
        extStyles={{ margin: '0 0 0 1vw' }}
        inputWidth="12.5vw"
        type="text"
      />
      <V2DropDown
        label="Instrument"
        value={fetchValue(data, objKey).instrument}
        setValue={(val) =>
          setData(updateValue(data, `${objKey}.instrument`, val))
        }
        disabled={ddStates.instrument.disabled}
        extStyles={{
          inputContainer: { width: '11vw' },
          mainContainer: { margin: '0 0 0 1vw' },
        }}
        options={ddStates.instrument.options.map((item) => item.name)}
        orientation="v"
      />
      <V2DropDown
        label="Symbol"
        value={fetchValue(data, objKey).symbol}
        setValue={(val) => setData(updateValue(data, `${objKey}.symbol`, val))}
        disabled={ddStates.symbol.disabled}
        extStyles={{
          inputContainer: { width: '11vw' },
          mainContainer: { margin: '0 0 0 1vw' },
        }}
        options={ddStates.symbol.options}
        orientation="v"
      />
      <V2DropDown
        label="Expiry"
        value={fetchValue(data, objKey).expiry}
        setValue={(val) => setData(updateValue(data, `${objKey}.expiry`, val))}
        disabled={ddStates.expiry.disabled}
        extStyles={{
          inputContainer: { width: '11vw' },
          mainContainer: { margin: '0' },
        }}
        options={ddStates.expiry.options}
        orientation="v"
      />
      <V2DropDown
        label="Strike"
        value={fetchValue(data, objKey).strike}
        setValue={(val) => setData(updateValue(data, `${objKey}.strike`, val))}
        disabled={ddStates.strike.disabled}
        extStyles={{ inputContainer: { width: '11vw' } }}
        options={ddStates.strike.options}
        orientation="v"
      />
      {triggerDelete && (
        <img
          className={style.deleteIcon}
          alt="Delete"
          src={DeleteBlueStroke}
          onClick={() => {
            const indexpos = objKey.split('.');
            triggerDelete(Number.parseInt(indexpos[indexpos.length - 1]));
          }}
        />
      )}
    </div>
  );
}

import moment from 'moment';
import { handleEscapeKeyEvent } from '../components/sharedComponents/keyPressEventHandler';

export function convertDate(strVal) {
  try {
    return strVal.slice(0, 10);
  } catch (error) {
    console.log(error);
    return '';
  }
}

export function convertUTCDateToISTDateString(dateStr) {
  try {
    if (String(dateStr).includes('+')) {
      dateStr = dateStr.split('+')[0];
    }
    let date = new Date(dateStr);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    return date.toLocaleString();
  } catch (err) {
    return dateStr;
  }
}

export function inactivitySetup(logout, setShowPopup, sessionExpiredAction) {
  let time;
  window.onload = resetTimer;
  window.onscroll = resetTimer;
  document.onscroll = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(() => {
      if (
        localStorage.getItem('customerId') &&
        !['/', '/forgotPassword'].includes(window.location.pathname)
      ) {
        sessionExpiredAction();
        setShowPopup(true);
      }
    }, 60000000);
  }
  // Token check at regular interval
  if (true) {
    setInterval(() => {
      if (
        !localStorage.getItem('token') &&
        !['/', '/forgotPassword'].includes(window.location.pathname)
      ) {
        if (
          window.location.pathname &&
          window.location.pathname.includes('/window/')
        ) {
          handleEscapeKeyEvent({});
        } else {
          sessionExpiredAction();
          setShowPopup(true);
        }
      }
    }, 60000);
  }
}

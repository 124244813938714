import { MakeRequest } from '../../../../utils';

export async function getDealers(params) {
  try {
    let result = await MakeRequest('/dealer/allDealers').get();
    return result
      ? result.data
        ? result.data.data
          ? result.data.data
          : []
        : []
      : [];
  } catch (error) {
    console.log(error);
  }
}

export async function toggleDealerState(dealerId, status) {
  try {
    let req = await MakeRequest(
      `/dealer/updateStatus?status=${status}&dealerId=${dealerId}`
    ).get();
    return req && req.status === 200 ? true : false;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteDealer(dealerId) {
  try {
    let res = await MakeRequest(`/dealer/delete?dealerId=${dealerId}`).get();
    return res.status === 200 ? true : false;
  } catch (error) {
    console.log(error);
  }
}

export async function createDealer(dealerObj) {
  try {
    let res = await MakeRequest(`/dealer/submit`).post(dealerObj);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function getSingleDealer(param) {
  try {
    let result = await MakeRequest(
      `/dealer/singleDealer?dealerId=${param}`
    ).get();
    return result
      ? result.data
        ? result.data.data
          ? result.data.data
          : []
        : []
      : [];
  } catch (error) {
    console.log(error);
  }
}

export async function modifyDealer(dealerObj) {
  try {
    let res = await MakeRequest(`/dealer/modify`).post(dealerObj);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function getSegmentData() {
  try {
    const result = await MakeRequest('/family/segment').get();
    return result && result.data && result.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function resetDealerPassword(dealerObj) {
  try{
    const result = await MakeRequest('/dealer/resetPassword').post(dealerObj);
    return result && result.data && result.data.data && result.data.data.message;
  } catch(error) {
    console.log(error);
  }
}

import { MakeRequest } from '../../../utils';

export async function getMarketColumnArr(tableName, pageNum) {
  try {
    let result;
    if (pageNum) {
      result = await MakeRequest(
        `/user/dynamicTable/getTableStruct?tableName=${tableName}&pageNum=${pageNum}`
      ).get();
    } else {
      result = await MakeRequest(
        `/user/dynamicTable/getTableStruct?tableName=${tableName}`
      ).get();
    }

    if (result.status === 200) {
      return result.data.data;
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function setMarketColArr(colData) {
  try {
    const result = await MakeRequest(`/user/dynamicTable/setTableStruct`).post(
      colData
    );
    if (result.status === 200) {
      return result.data.data;
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

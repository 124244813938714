import React, { useState, useEffect } from 'react';
import { OpsReducerActions } from '../redux';
import DateTimePick from '../../../components/sharedComponents/dataTimePicker';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import OpsApiCalls from '../redux/apiCalls';
import * as C from './components';
import { SimpleDropdown } from '../customComp/simpleDropdown';
import SimpleTable from '../customComp/simpleTable';
import { InputField } from '../../../components';

//Func to get today's date
function getFormattedDate(today) {
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '-' + mm + '-' + yyyy;
  return formattedToday;
}

export default function OpsDataValidation(props) {
  const formattedToday = getFormattedDate(new Date());
  const dispatch = useDispatch();
  useEffect(() => {
    //Fetch and store data on initial load
    const apiCall = async () => {
      try {
        const data = await OpsApiCalls.getLocationDropdownData();
        await OpsReducerActions.fetchValidationData({ data, dispatch });
      } catch (error) {
        console.log(error);
      }
    };
    //Api call to get location data
    apiCall();
  }, []);

  //States for date,node info and validation results
  const [date, setDate] = useState(formattedToday);
  const [rightUpSecTab, setRightUpSecTab] = useState(null);
  const [rightDownSecTab, setRightDownSecTab] = useState(null);

  //Func to change Node details when a different node is clicked
  const handleNodeChange = ({ data }) => {
    setRightUpSecTab(data.rightUpSecTab);
    setRightDownSecTab(data.rightDownSecTab);
  };

  //Screen
  return (
    <div className="ops-datavalidation-container">
      <div className="ops-datavalidation-location">
        <C.Header>
          <C.LocationText>Location</C.LocationText>
          <InputField
            type="date"
            onValueChange={(val) => setDate(val)}
            value={date}
            label="Validation date"
            orientation="h"
            inputWidth="11.17vw"
            extStyles={{ margin: '1vh 1vw 1vh auto' }}
          />
        </C.Header>
        <div className="validation-dropdown">
          <CreateDropdown handleNodeChange={handleNodeChange} date={date} />
        </div>
      </div>
      <div className="ops-datavalidation-info">
        <div className="ops-datavalidation-node-info">
          {rightUpSecTab === null ? (
            <div className="no-info">Select a Validator for more info</div>
          ) : (
            <NodeInfo rightUpSecTab={rightUpSecTab} />
          )}
        </div>
        <div className="ops-datavalidation-node-results">
          {rightDownSecTab !== null ? (
            <SimpleTable data={rightDownSecTab} />
          ) : (
            <div className="no-info">Validate to see results here</div>
          )}
        </div>
      </div>
    </div>
  );
}

//Func to create dropdown
function CreateDropdown({ handleNodeChange, date }) {
  const { dataValidation } = useSelector((state) => state.OpsReducer);
  return (
    <SimpleDropdown
      date={date}
      data={dataValidation}
      handleNodeChange={handleNodeChange}
    />
  );
}

//Func to display Node Info
function NodeInfo({ rightUpSecTab }) {
  return (
    <ul className="validator-info">
      <li>
        Location/Exchange:&nbsp;{' '}
        {rightUpSecTab.location ? rightUpSecTab.location : 'Not Defined'}
      </li>
      <li>
        Validation Node:&nbsp;{' '}
        {rightUpSecTab.node ? rightUpSecTab.node : 'Not Defined'}
      </li>
      <li>
        Target Data Store:&nbsp;{' '}
        {rightUpSecTab.target ? rightUpSecTab.target : 'Not Defined'}
      </li>
      <li>
        Store Procedure Used:&nbsp;{' '}
        {rightUpSecTab.procedure ? rightUpSecTab.procedure : 'Not Defined'}
      </li>
      <li>
        Validation Status:&nbsp;{' '}
        {rightUpSecTab.status ? rightUpSecTab.status : 'Not Defined'}
      </li>
    </ul>
  );
}

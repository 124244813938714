import { Col, Row } from 'react-bootstrap';
import '../../strategyBuilder/create/previewStrategy/PreviewStrategy.scss';
import Table from '../../sharedComponents/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import {
  getquantityLiveTrading,
  setStrategyDirection,
} from '../../../actions/LiveTradingAction';
import './CreateEditStrategyModal.scss';
import { DropDown, InputField } from '../../../components';
import { useEffect } from 'react';

const OrderDetailsCreateStrategy = ({
  formSubmit,
  validator,
  orderDetailsError,
  strategyId,
  isReadOnly = false,
  prevTableData,
  extData,
  isEdit
}) => {
  const {
    strategyBuilderReducer: { securityNameOptions },
    liveTradingReducer: { strategy, pricingLogic },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      switch (extData.specialRule) {
        case 'SPREAD':
          if (strategy.securities[0].lotSize && strategy.securities[1].lotSize && strategy.entryLegs[0].position[0].totalQty * strategy.securities[0].lotSize !== strategy.entryLegs[0].position[1].totalQty * strategy.securities[1].lotSize) {
            for (let entryLegIdx = 0 ; entryLegIdx < strategy.entryLegs.length ; entryLegIdx++) {
              for (let posIdx = 0 ; posIdx < strategy.entryLegs[entryLegIdx].position.length ; posIdx++) {
                let val = 1;
                
                switch (posIdx) {
                  case 0:
                    if (prevTableData[1].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value:
                            strategy.securities[0].lotSize,
                          },
                        },
                        postionData[1],
                        'totalQty'
                      );
                      inputHandler(
                        { target: { value: val } },
                        postionData[0],
                        'totalQty'
                      );

                      inputHandler(
                        {
                          target: {
                            value:
                            strategy.securities[0].lotSize,
                          },
                        },
                        postionData[1],
                        'orderQty'
                      );
                      inputHandler(
                        { target: { value: val } },
                        postionData[0],
                        'orderQty'
                      );
                    } else {
                      inputHandler(
                        {
                          target: {
                            value:
                            strategy.securities[0].lotSize,
                          },
                        },
                        postionData[1],
                        'totalQty'
                      );
                      inputHandler(
                        { target: { value: strategy.securities[1].lotSize } },
                        postionData[0],
                        'totalQty'
                      );

                      inputHandler(
                        {
                          target: {
                            value:
                            strategy.securities[0].lotSize,
                          },
                        },
                        postionData[1],
                        'orderQty'
                      );
                      inputHandler(
                        { target: { value: strategy.securities[1].lotSize } },
                        postionData[0],
                        'orderQty'
                      );
                    }
                    break;
                  case 1:
                    break;
                    if (prevTableData[0].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value:
                            strategy.securities[0].lotSize,
                          },
                        },
                        postionData[0],
                        'totalQty'
                      );
                    } else {
                      inputHandler(
                        {
                          target: {
                            value:
                            strategy.securities[0].lotSize,
                          },
                        },
                        postionData[0],
                        'totalQty'
                      );
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
          break;
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }, [strategy])

  const inputHandler = (e, params, preveiewQty) => {
    let values = parseInt(e.target.value);
    let totalObj = {
      legIndex: params.legIndex,
      positionIndex: params.positionIndex,
      key: 'position',
      value: values,
      preveiewQty,
    };
    dispatch(getquantityLiveTrading(totalObj));
  };

  function fieldDisabled(obj, index, fieldName) {
    if (extData.specialRule === 'SPREAD') {
      if (prevTableData[index].instrument === 'Equity') {
        if (
          prevTableData[0].instrument === 'Equity' &&
          prevTableData[1].instrument === 'Equity'
        ) {
          return false;
        } else {
          return true;
        }
      } else if (
        prevTableData[index].instrument === 'Future' &&
        index === 0 &&
        prevTableData[0].instrument === 'Future' &&
        prevTableData[1].instrument === 'Future'
      ) {
        return true;
      } else {
        return false;
      }
    } else if (extData.specialRule === 'POV' && fieldName === 'orderQty') {
      return true;
    }
    return false;
  }

  const directionHandler = (data) => {
    dispatch(
      setStrategyDirection({
        field: data.val,
        key: 'direction',
        legId: data.legIndex,
        priceIndex: data.priceIndex,
      })
    );
  };
  let table_coulumn = [
    {
      lebel: 'Buy/Sell',
      renderHtml: (params) => {
        let index = params.legIndex;
        return (
          <>
            <DropDown
              options={['BUY', 'SELL']}
              disabled={isEdit}
              selected={`${params.direction}`}
              textLengthLimit={20}
              orientation="v"
              onSelect={(val) => {
                if (extData && extData.specialRule === 'SPREAD') {
                  directionHandler({
                    val,
                    priceIndex: params.positionIndex,
                    legIndex: params.legIndex,
                  });
                  try {
                    directionHandler({
                      val: (val == 'BUY') ? 'SELL' : 'BUY',
                      priceIndex: params.positionIndex == 0 ? 1 : 0,
                      legIndex: params.legIndex,
                    });
                  } catch (err) {
                    console.log("SPREAD direction handling err: ", err);
                  }
                  return;
                }
                directionHandler({
                  val,
                  priceIndex: params.positionIndex,
                  legIndex: params.legIndex,
                });
              }}
              extStyles={{ width: '10vw' }}
              ddExtStyles={{ width: '10vw' }}
              textExtStyles={
                params.direction == 'BUY'
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            />
          </>
        );
      },
    },
    {
      lebel: 'Security',
      renderHtml: (params) => {
        let index = params.legIndex;
        return <>{params.security}</>;
      },
    },
    {
      lebel: 'Order Qty',
      renderHtml: (params, ind) => {
        let index = params.legIndex;
        return (
          <InputField
            value={
              strategy.entryLegs[params.legIndex].position[params.positionIndex]
                .orderQty
            }
            placeHolder=""
            disabled={fieldDisabled(params, ind, 'orderQty')}
            onValueChange={(val) => {
              inputHandler({ target: { value: val } }, params, 'orderQty');
              if (extData.specialRule === 'SPREAD') {
                switch (ind) {
                  case 0:
                    if (prevTableData[1].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value:
                              val *
                              strategy.securities.find(
                                (el) => el.nickname === params.security
                              ).lotSize,
                          },
                        },
                        postionData[1],
                        'orderQty'
                      );
                    }
                    break;

                  default:
                    if (prevTableData[0].instrument === 'Equity') {
                      inputHandler(
                        {
                          target: {
                            value:
                              val *
                              strategy.securities.find(
                                (el) => el.nickname === params.security
                              ).lotSize,
                          },
                        },
                        postionData[0],
                        'orderQty'
                      );
                    } else {
                      inputHandler(
                        {
                          target: {
                            value:
                              (val *
                                strategy.securities.find(
                                  (el) => el.nickname === params.security
                                ).lotSize) /
                              strategy.securities[0].lotSize,
                          },
                        },
                        postionData[0],
                        'orderQty'
                      );
                    }
                    break;
                }
              }
            }}
            inputWidth="10vw"
          />
        );
      },
    },
    {
      lebel: 'Total Qty',
      renderHtml: (params, ind) => {
        let index = params.legIndex;

        return (
          <>
            {params.quantity.length > 0 ? (
              <p>Logic</p>
            ) : (
              <InputField
                value={
                  strategy.entryLegs[params.legIndex].position[
                    params.positionIndex
                  ].totalQty
                }
                placeHolder=""
                inputWidth="10vw"
                disabled={fieldDisabled(params, ind)}
                onValueChange={(val) => {
                  switch (extData.specialRule) {
                    case 'SPREAD':
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'totalQty'
                      );
                      switch (ind) {
                        case 0:
                          if (prevTableData[1].instrument === 'Equity') {
                            inputHandler(
                              {
                                target: {
                                  value:
                                    val *
                                    strategy.securities.find(
                                      (el) => el.nickname === params.security
                                    ).lotSize,
                                },
                              },
                              postionData[1],
                              'totalQty'
                            );
                          }
                          break;
                        case 1:
                          if (prevTableData[0].instrument === 'Equity') {
                            inputHandler(
                              {
                                target: {
                                  value:
                                    val *
                                    strategy.securities.find(
                                      (el) => el.nickname === params.security
                                    ).lotSize,
                                },
                              },
                              postionData[0],
                              'totalQty'
                            );
                          } else {
                            inputHandler(
                              {
                                target: {
                                  value:
                                    (val *
                                      strategy.securities.find(
                                        (el) => el.nickname === params.security
                                      ).lotSize) /
                                    strategy.securities[0].lotSize,
                                },
                              },
                              postionData[0],
                              'totalQty'
                            );
                          }
                          break;
                        default:
                          break;
                      }
                      break;
                    case 'POV':
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'orderQty'
                      );
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'totalQty'
                      );
                      break;
                    default:
                      inputHandler(
                        { target: { value: val } },
                        params,
                        'totalQty'
                      );
                      break;
                  }
                }}
              />
            )}
          </>
        );
      },
    },
    {
      lebel: 'Pending Qty',
      renderHtml: (params) => {
        return (
          <>
            {strategy.entryLegs[params.legIndex].position[params.positionIndex]
              .pendingQty || 0}
          </>
        );
      },
    },
    {
      lebel: 'Traded Qty',
      renderHtml: (params) => {
        return (
          <>
            {strategy.entryLegs[params.legIndex].position[params.positionIndex]
              .tradedQty || 0}
          </>
        );
      },
    },
    // {
    //   lebel: 'Price',
    //   renderHtml: (params, ind) => {
    //     return (
    //       <div
    //         onClick={() => priceHandler(params, ind)}
    //         onMouseOver={() => priceHandler(params, ind)}
    //         id={`price_${ind}`}
    //         className={
    //           ind === fieldIndex &&
    //           inputData &&
    //           inputData.displayName === 'MARKET'
    //             ? 'market'
    //             : ind === fieldIndex &&
    //               inputData &&
    //               inputData.displayName === 'TOB + x'
    //             ? 'Tob'
    //             : inputData && inputData.displayName
    //             ? 'pointer'
    //             : ''
    //         }
    //       >
    //         {params.algo.length > 0 ? (
    //           params.algo[0].displayName ? (
    //             <p>{params.algo[0].displayName}</p>
    //           ) : params.algo[0].data ? (
    //             <p>{params.algo[0].data}</p>
    //           ) : (
    //             <p>algo logic</p>
    //           )
    //         ) : params.pricing.length > 0 &&
    //           !(params.pricing[0].eqIndex === ind) ? (
    //           <p>Logic</p>
    //         ) : (
    //           <div
    //             className={
    //               validator.current.message(
    //                 'price',
    //                 strategy.entryLegs[params.legIndex].position[
    //                   params.positionIndex
    //                 ].pricing,
    //                 'required|min:1,array',
    //                 {
    //                   className: 'error_message',
    //                 }
    //               ) && formSubmit
    //                 ? 'error-input'
    //                 : ''
    //             }
    //           >
    //             <MultiDropdown
    //               key={'pricingCondition'}
    //               listData={pricingLogic}
    //               eqIndex={ind}
    //               marketForm={params.pricing}
    //               editFlagIndex={editFlagIndex}
    //               setEditFlagIndex={setEditFlagIndex}
    //               setMarketForm={setMarketForm}
    //               marketHandler={marketHandler}
    //               setInputData={setInputData}
    //               setSelectedCondition={setSelectedCondition}
    //               placeHolder={'Select'}
    //               setEquationValues={equationHandler}
    //               fieldId={'pricingCondition'}
    //               inputData={inputData}
    //               role="price"
    //               noInput
    //             />
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      lebel: 'LOT Size',
      renderHtml: (params) => {
        let index = params.legIndex;
        let newSecurity =
          securityNameOptions &&
          securityNameOptions?.length &&
          securityNameOptions[0] !== null &&
          strategy.securities.find((el) => el.nickname === params.security);
        if (!newSecurity) return <p>#</p>;
        if (
          newSecurity &&
          newSecurity.lotSize &&
          newSecurity.instrument != 'Equity'
        ) {
          return <p id={`lot_size${index}`}>{newSecurity.lotSize}</p>;
        } else {
          return <p>#</p>;
        }
      },
    },
  ];

  let tableData = [...strategy.entryLegs];

  let postionData = [];

  tableData.forEach((element, legIndex) => {
    element.position?.map((val, key) =>
      postionData.push({
        ...val,
        legIndex,
        positionIndex: key,
        legId: element.legId,
        algo: element.algoExecution,
      })
    );
  });

  // useEffect(() => {
  //   if (prevTableData && postionData && postionData.length > 0) {
  //   }
  // }, [prevTableData]);

  return (
    <div className="orderDetailTable orderDetailTable2">
      <Row>
        <Col sm="12">
          <div className="alogapplied"></div>
        </Col>
      </Row>
      <div className="sectionHeadings">
        <h3>Order Details</h3>
      </div>
      <Table
        className={'responsive table-striped'}
        showHeader={true}
        tableCoulumn={table_coulumn}
        showPagination={false}
        TableData={postionData}
        id="orderDetails"
      ></Table>
    </div>
  );
};

export default OrderDetailsCreateStrategy;

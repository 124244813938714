import React, { useState, useEffect, Fragment } from 'react';
import info from '../../../../assets/img/info.svg';
import './SelectSecurities.scss';
import './styles.css';
import { Image, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../../sharedComponents/dropdown/index.jsx';
import { DropDownV2 } from '../../../sharedV2/index.jsx';
import { MakeRequest } from '../../../../utils';
import HeaderStrategyBuilder from '../../../../viewScreen/strategyBuilder/create/strategyAnalyzerFormula/header.jsx';
import {
  CheckBox,
  GenButton,
  GenToolTip,
  InputField,
  PillButton,
  TableV3,
} from '../../../';
import {
  strategyInstrumenType,
  strategyExchange,
  builderObject,
  updateSecurities,
  updateSecurityConstaints,
} from '../../../../actions/strategyBuilderAction';
import { AddCircleIcon, SecuritiesIcon } from '../../../../assets';
import useSWRImmutable from 'swr/immutable';

export default function SelectSecurities({ edit = false }) {
  const [instApiData] = Api.GetInstrumentData();
  const { isApproveReject } = useSelector((state) => state.alertReducer);
  const {
    strategyTemplateForm,
    exchangeOptions,
    dataConditions: { securityConditions },
    modifyId,
  } = useSelector((state) => state.strategyBuilderReducer);
  const dispatch = useDispatch();

  const [options, setOptions] = useState({ instrument: [], exchange: [] });
  const [newTableData, setNewTableData] = useState([]);
  const [addSecurity, setAddSecurity] = useState([]);
  const [constraintData, setConstraintData] = useState([
    {},
    {},
    {
      selection: strategyTemplateForm?.constraintData?.instrumentConstraint,
      options:
        securityConditions && securityConditions['Instruments']
          ? securityConditions['Instruments']
          : [],
    },
    {},
    {
      selection: strategyTemplateForm?.constraintData?.symbolConstraint,
      options:
        securityConditions && securityConditions['Symbols']
          ? securityConditions['Symbols']
          : [],
    },
    {
      selection: strategyTemplateForm?.constraintData?.expiryConstraint,
      options:
        securityConditions && securityConditions['Expiry']
          ? securityConditions['Expiry']
          : [],
    },
    {
      selection: strategyTemplateForm?.constraintData?.strikeConstraint,
      options:
        securityConditions && securityConditions['Strike']
          ? securityConditions['Strike']
          : [],
    },
    {
      selection: strategyTemplateForm?.constraintData?.buySellConstraint,
      options:
        securityConditions && securityConditions['Buy/Sell']
          ? securityConditions['Buy/Sell']
          : [],
    },
  ]);
  const [changeFlag, setChangeFlag] = useState(false);
  const [addChangeFlag, setAddChangeFlag] = useState(false);
  const [enableConstraint, setEnableConstraint] = useState(false);
  const [addSecurityLater, setAddSecurityLater] = useState(false);
  const [symbols, setSymbols] = useState({
    selection: strategyTemplateForm.securityFilter,
    options: ['FO', 'All'],
  });
  const [editUpdateFlag, setEditUpdateFlag] = useState(false);
  const [additionalSecuriteisColumns] = useState([
    {
      head: 'Nick Name',
      content: (data, dataIndex) => {
        return (
          <InputField
            inputWidth="10rem"
            type={'text'}
            value={data.nickname}
            onValueChange={(val) => {
              setAddSecurity((old) =>
                old.map((oldVal, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    return { ...oldVal, nickname: val };
                  } else {
                    return oldVal;
                  }
                })
              );
            }}
          />
        );
      },
    },
    {
      head: 'Instrument Allowed',
      content: (data, dataIndex) => {
        return (
          <DropDown
            disabled={data.instrumentAllowed.options.length === 0}
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            type="multiSelect"
            options={data.instrumentAllowed.options}
            selected={data.instrumentAllowed.options
              .filter((item) => item.state)
              .map((item) => item.title)
              .join(', ')}
            onSelect={(val) => {
              setAddChangeFlag(true);
              setAddSecurity((oldSecurities) => {
                return oldSecurities.map((oldSecurity, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const temp = { ...oldSecurity };
                    temp.instrumentAllowed.options =
                      oldSecurity.instrumentAllowed.options.map((oldOption) => {
                        if (oldOption.title === val.title) {
                          return { ...oldOption, state: !val.state };
                        } else {
                          return oldOption;
                        }
                      });
                    return temp;
                  } else {
                    return oldSecurity;
                  }
                });
              });
            }}
          />
        );
      },
    },
    {
      head: 'Instrument',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.instrument.selection}
            options={data.instrumentAllowed.options
              .filter((item) => item.state)
              .map((item) => item.title)}
            onSelect={(val) => {
              setAddChangeFlag(true);
              setAddSecurity((old) => {
                return old.map((oldData, oldIndex, allOldData) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      instrument: {
                        ...oldData.instrument,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                });
              });
            }}
          />
        );
      },
    },
    {
      head: 'Exchange',
      content: (data, dataIndex) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.exchange.selection}
            options={data.exchange.options}
            onSelect={(val) => {
              setAddChangeFlag(true);
              setAddSecurity((old) =>
                old.map((oldData, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      exchange: {
                        ...oldData.exchange,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
          />
        );
      },
    },
    {
      head: 'Symbol',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.symbol.selection}
            options={data.symbol.options}
            enable_search={true}
            onSelect={(val) => {
              setAddChangeFlag(true);
              setAddSecurity((old) =>
                old.map((oldData, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      symbol: {
                        ...oldData.symbol,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
            disabled={data.symbol.options.length === 0}
          />
        );
      },
    },
    {
      head: 'Expiry',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.expiry.selection}
            options={data.expiry.options}
            enable_search={true}
            onSelect={(val) => {
              setAddChangeFlag(true);
              setAddSecurity((old) =>
                old.map((oldData, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      expiry: {
                        ...oldData.expiry,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
            disabled={data.expiry.options.length === 0}
          />
        );
      },
    },
    {
      head: 'Strike',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.strike.selection}
            options={data.strike.options}
            onSelect={(val) => {
              setAddChangeFlag(true);
              setAddSecurity((old) =>
                old.map((oldData, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      strike: {
                        ...oldData.strike,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
            disabled={data.strike.options.length === 0}
          />
        );
      },
    },
    {
      head: 'Action',
      content: (data, dataIndex) => {
        return (
          <PillButton
            iconType={2}
            extStyles={{ margin: 'auto' }}
            clickAction={() =>
              setAddSecurity((old) =>
                old.filter((item, index) => index !== dataIndex)
              )
            }
          />
        );
      },
    },
  ]);

  const tableV2Properties = [
    {
      head: 'Nick Name',
      content: (data, dataIndex) => {
        return (
          <InputField
            inputWidth="10rem"
            type="text"
            value={data.nickname}
            onValueChange={(val) => {
              setChangeFlag(true);
              setNewTableData((old) =>
                old.map((oldVal, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    return { ...oldVal, nickname: val };
                  } else {
                    return oldVal;
                  }
                })
              );
            }}
          />
        );
      },
    },
    {
      head: 'Instrument Allowed',
      content: (data, dataIndex) => {
        return (
          <DropDown
            disabled={data?.instrumentAllowed?.options?.length === 0}
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            type="multiSelect"
            options={data.instrumentAllowed.options}
            selected={data.instrumentAllowed.options
              ?.filter((item) => item.state)
              ?.map((item) => item.title)
              ?.join(', ')}
            onSelect={(val, newState) => {
              setChangeFlag(true);
              setNewTableData((old) =>
                old.map((oldData, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      instrumentAllowed: {
                        ...oldData.instrumentAllowed,
                        options: oldData.instrumentAllowed.options.map(
                          (oldOption) => {
                            if (oldOption.title === val.title) {
                              return { ...oldOption, state: newState };
                            } else {
                              return oldOption;
                            }
                          }
                        ),
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
            all_check={true}
          />
        );
      },
    },
    {
      head: 'Instrument',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.instrument.selection}
            options={data.instrument.options}
            onSelect={(val) => {
              setChangeFlag(true);

              setNewTableData((old) =>
                old.map((oldData, oldIndex, allOldData) => {
                  if (
                    oldIndex === dataIndex ||
                    constraint.selection === 'Same in All Legs'
                  ) {
                    const tempData = {
                      ...oldData,
                      instrument: {
                        ...oldData.instrument,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    if (constraint.selection === 'Alternative (PE/CE)') {
                      const temp = { ...oldData };
                      if (oldIndex === 1) {
                        temp.instrument.selection = val === 'CE' ? 'PE' : 'CE';
                      } else {
                        temp.instrument.selection =
                          allOldData[oldIndex - 1].instrument.selection === 'CE'
                            ? 'PE'
                            : 'CE';
                      }

                      return temp;
                    }
                    return oldData;
                  }
                })
              );
            }}
            disabled={
              isDisabledField(2, dataIndex, constraint.selection) ||
              data.instrument?.options?.length === 0
            }
          />
        );
      },
    },
    {
      head: 'Exchange',
      content: (data, dataIndex) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.exchange.selection}
            options={data.exchange.options}
            onSelect={(val) => {
              setChangeFlag(true);
              setNewTableData((old) =>
                old.map((oldData, oldIndex) => {
                  if (oldIndex === dataIndex) {
                    const tempData = {
                      ...oldData,
                      exchange: {
                        ...oldData.exchange,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
          />
        );
      },
    },
    {
      head: 'Symbol',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.symbol.selection}
            options={data.symbol.options}
            enable_search={true}
            onSelect={(val) => {
              setChangeFlag(true);

              setNewTableData((old) =>
                old.map((oldData, oldIndex) => {
                  if (
                    oldIndex === dataIndex ||
                    constraint.selection === 'Same in All Legs'
                  ) {
                    const tempData = {
                      ...oldData,
                      symbol: {
                        ...oldData.symbol,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    return oldData;
                  }
                })
              );
            }}
            disabled={
              isDisabledField(4, dataIndex, constraint.selection) ||
              data.symbol?.options?.length === 0
            }
          />
        );
      },
    },
    {
      head: 'Expiry',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            error={dropdownErrorCheck(
              data.expiry.options,
              data.expiry.selection
            )}
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.expiry.selection}
            options={data.expiry.options}
            enable_search={true}
            onSelect={(val) => {
              setChangeFlag(true);
              setNewTableData((old) =>
                old.map((oldData, oldIndex) => {
                  if (
                    oldIndex === dataIndex ||
                    constraint.selection === 'Same in All Legs'
                  ) {
                    const tempData = {
                      ...oldData,
                      expiry: {
                        ...oldData.expiry,
                        selection: val,
                      },
                    };
                    return tempData;
                  } else {
                    if (constraint.selection === 'Alternate Same') {
                      const temp = { ...oldData };
                      temp.expiry.selection =
                        oldIndex - 2 >= 0
                          ? allData[oldIndex - 2].expiry.selection
                          : oldData.expiry.selection;
                      return temp;
                    }
                    return oldData;
                  }
                })
              );
            }}
            disabled={
              isDisabledField(5, dataIndex, constraint.selection) ||
              data.expiry?.options?.length === 0
            }
            optionDisplayFunction={(val) => {
              if (typeof val === 'string' && val.length === 0) {
                return val;
              }
              const date = new Date(val);
              return date.toLocaleString();
            }}
          />
        );
      },
    },
    {
      head: 'Strike',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            error={dropdownErrorCheck(
              data.strike.options,
              data.strike.selection
            )}
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.strike.selection}
            options={data.strike.options}
            onSelect={(val) => {
              setChangeFlag(true);
              setNewTableData((old) =>
                old.map((oldData, oldIndex) => {
                  const tempData = { ...oldData };
                  if (oldIndex === dataIndex) {
                    tempData.strike.selection = val;
                    return tempData;
                  }
                  switch (constraint.selection) {
                    case 'Same in All Legs':
                      if (oldIndex > 0) {
                        tempData.strike.selection =
                          allData[oldIndex - 1].strike.selection;
                      }
                      return tempData;
                    case 'Alternate Same':
                      if (oldIndex > 1) {
                        tempData.strike.selection =
                          allData[oldIndex - 2].strike.selection;
                      }
                      return tempData;
                    case 'Arithmetic Progression':
                      if (oldIndex > 1) {
                        const difference =
                          allData[oldIndex - 1].strike.selection -
                          allData[oldIndex - 2].strike.selection;
                        tempData.strike.selection =
                          allData[oldIndex - 1].strike.selection + difference;
                      }
                      return tempData;
                    default:
                      return tempData;
                  }
                })
              );
            }}
            disabled={
              isDisabledField(6, dataIndex, constraint.selection) ||
              data.strike?.options?.length === 0
            }
          />
        );
      },
    },
    {
      head: 'Buy/Sell',
      content: (data, dataIndex, allData, constraint) => {
        return (
          <DropDown
            class_name="ddExtraStyles-strategyBuilder"
            ddExtStyles={{ width: '9rem' }}
            selected={data.buySell.selection}
            options={data.buySell.options}
            onSelect={(val) => {
              setNewTableData((old) =>
                old.map((oldData, oldIndex) => {
                  const tempData = { ...oldData };
                  if (oldIndex === dataIndex) {
                    tempData.buySell.selection = val;
                    return tempData;
                  } else {
                    switch (constraint.selection) {
                      case 'Same in All Legs':
                        if (oldIndex > 0) {
                          tempData.buySell.selection =
                            allData[oldIndex - 1].buySell.selection;
                        }
                        return tempData;
                      case 'Alternate Same':
                        if (oldIndex > 0) {
                          tempData.buySell.selection =
                            allData[oldIndex - 1].buySell.selection === 'Buy'
                              ? 'Sell'
                              : 'Buy';
                        }
                        return tempData;
                      default:
                        return tempData;
                    }
                  }
                })
              );
            }}
            disabled={isDisabledField(7, dataIndex, constraint.selection)}
          />
        );
      },
    },
    {
      head: 'Action',
      content: (data, dataIndex) => {
        return (
          <PillButton
            iconType={2}
            extStyles={{ margin: 'auto' }}
            clickAction={() =>
              setNewTableData((old) =>
                old.filter((item, index) => index !== dataIndex)
              )
            }
          />
        );
      },
    },
  ];
  const constraintHeaders = [
    'Constraints',
    <></>,
    <DropDownV2
      extClass="ddExtraStyles-strategyBuilder"
      options={constraintData[2].options}
      value={constraintData[2].selection}
      extStyles={{ width: '9rem' }}
      setValue={(val) => {
        setConstraintData((old) =>
          old.map((item, index) => {
            if (index === 2) {
              return { ...item, selection: val };
            } else {
              return item;
            }
          })
        );
      }}
    />,
    <></>,
    <DropDownV2
      extStyles={{ width: '9rem' }}
      extClass="ddExtraStyles-strategyBuilder"
      options={constraintData[4].options}
      value={constraintData[4].selection}
      setValue={(val) => {
        setConstraintData((old) =>
          old.map((item, index) => {
            if (index === 4) {
              return { ...item, selection: val };
            } else {
              return item;
            }
          })
        );
      }}
    />,
    <DropDownV2
      extStyles={{ width: '9rem' }}
      extClass="ddExtraStyles-strategyBuilder"
      options={constraintData[5].options}
      value={constraintData[5].selection}
      setValue={(val) =>
        setConstraintData((old) =>
          old.map((item, index) => {
            if (index === 5) {
              return { ...item, selection: val };
            } else {
              return item;
            }
          })
        )
      }
    />,

    <DropDownV2
      extStyles={{ width: '9rem' }}
      extClass="ddExtraStyles-strategyBuilder"
      options={constraintData[6].options}
      value={constraintData[6].selection}
      setValue={(val) =>
        setConstraintData((old) =>
          old.map((item, index) => {
            if (index === 6) {
              return { ...item, selection: val };
            } else {
              return item;
            }
          })
        )
      }
    />,
    <DropDownV2
      extStyles={{ width: '9rem' }}
      extClass="ddExtraStyles-strategyBuilder"
      options={constraintData[7].options}
      value={constraintData[7].selection}
      setValue={(val) =>
        setConstraintData((old) =>
          old.map((item, index) => {
            if (index === 7) {
              return { ...item, selection: val };
            } else {
              return item;
            }
          })
        )
      }
    />,
    <></>,
  ];

  function dropdownErrorCheck(options, value) {
    try {
      if (options?.length === 0) {
        return false;
      }
      const tempOptions = options.map((item) => item.toString());
      return !tempOptions.includes(value.toString());
    } catch (error) {
      console.log(error);
      return true;
    }
  }

  async function prepareEditdata() {
    try {
      if (!modifyId) {
        return;
      }
      const response = await MakeRequest(
        `/strategyTemplate/approvalReturn/${modifyId}`
      ).get();
      const instrumentTypes = await MakeRequest(
        '/security/instrumenTypes',
        true,
        true
      ).get();
      const instData = instrumentTypes.data.data;
      const securities =
        response.data.data && response.data.data[0]?.securities;
      const tempPrimarySecurites = securities?.filter(
        (item) => !item.isAdditional
      );
      setNewTableData(
        tempPrimarySecurites?.map((item) => ({
          nickname: item.nickname,
          instrumentAllowed: {
            selection: '',
            options: instData.map((instItem) => {
              const available =
                item.instrumentAllowed.filter(
                  (filIte) => filIte.key === instItem.key
                ).length > 0;
              return {
                title: instItem.name,
                state: available,
              };
            }),
            disabled: false,
          },
          instrument: {
            selection: instData.filter(
              (filItem) => filItem.key === item.instrument
            )[0].name,
            options: [],
            disabled: false,
          },
          exchange: {
            selection: item.exchange,
            options: exchangeOptions || [],
            disabled: false,
          },
          symbol: {
            selection: item.securityName,
            options: [],
            disabled: false,
          },
          expiry: { selection: item.expiry, options: [], disabled: false },
          strike: {
            selection: item.strike,
            options: [],
            disabled: false,
          },
          buySell: {
            selection: item.buySell,
            options: ['Buy', 'Sell'],
            disabled: false,
          },
        }))
      );
      const tempAddSecurites = securities?.filter((item) => item.isAdditional);
      setAddSecurity(
        tempAddSecurites?.map((item) => ({
          nickname: item.nickname,
          instrumentAllowed: {
            selection: '',
            options: options?.instrument?.map((item) => ({
              title: item.name,
              state: true,
            })),
            disabled: false,
          },
          instrument: {
            selection: item.instrument,
            options: [],
            disabled: false,
          },
          exchange: {
            selection: item.exchange,
            options: exchangeOptions || [],
            disabled: false,
          },
          symbol: {
            selection: item.securityName,
            options: [],
            disabled: false,
          },
          expiry: { selection: item.expiry, options: [], disabled: false },
          strike: {
            selection: item.strike,
            options: [],
            disabled: false,
          },
          buySell: {
            selection: item?.buySell,
            options: ['Buy', 'Sell'],
            disabled: false,
          },
        }))
      );
    } catch (error) {
      console.log(error);
    }

    setEditUpdateFlag(true);
    setChangeFlag(true);
  }

  function applyConstrints() {
    if (!enableConstraint) {
      return false;
    }
    switch (constraintData[2].selection) {
      case 'Same in All Legs':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            const temp = { ...oldItem };
            temp.instrument.selection = allOldData[0].instrument.selection;
            return temp;
          })
        );
        break;
      case 'Alternative (PE/CE)':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            const temp = { ...oldItem };
            if (oldIndex > 0) {
              temp.instrument.selection =
                allOldData[oldIndex - 1].instrument.selection === 'CE'
                  ? 'PE'
                  : 'CE';
            } else {
              return temp;
            }
            return temp;
          })
        );
        break;
      default:
        break;
    }
    switch (constraintData[4].selection) {
      case 'Same in All Legs':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            const temp = { ...oldItem };
            temp.symbol.selection = allOldData[0].symbol.selection;
            return temp;
          })
        );
        break;
      default:
        break;
    }
    switch (constraintData[5].selection) {
      case 'Same in All Legs':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            const temp = { ...oldItem };
            if (
              temp?.instrument?.selection === 'Future' ||
              temp?.instrument?.selection === 'CE' ||
              temp?.instrument?.selection === 'PE'
            ) {
              temp.expiry.selection = allOldData[0].expiry.selection;
            }
            return temp;
          })
        );
        break;
      case 'Alternate Same':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            if (oldIndex > 1) {
              const temp = { ...oldItem };
              if (
                temp?.instrument?.selection === 'Future' ||
                temp?.instrument?.selection === 'CE' ||
                temp?.instrument?.selection === 'PE'
              ) {
                temp.expiry.selection =
                  allOldData[oldIndex - 2].expiry.selection;
              }

              return temp;
            }
            return oldItem;
          })
        );
        break;
      default:
        break;
    }
    switch (constraintData[6].selection) {
      case 'Same in All Legs':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            const temp = { ...oldItem };
            if (
              temp?.instrument?.selection === 'CE' ||
              temp?.instrument?.selection === 'PE'
            ) {
              temp.strike.selection = allOldData[0].strike.selection;
            }
            return temp;
          })
        );
        break;
      case 'Alternate Same':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            if (oldIndex > 1) {
              const temp = { ...oldItem };
              if (
                temp?.instrument?.selection === 'CE' ||
                temp?.instrument?.selection === 'PE'
              ) {
                temp.strike.selection =
                  allOldData[oldIndex % 2 === 0 ? 0 : 1].strike.selection;
              }
              return temp;
            } else {
              return oldItem;
            }
          })
        );
        break;
      case 'Arithmetic Progression':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            if (oldIndex > 1) {
              const temp = { ...oldItem };
              if (
                temp?.instrument?.selection === 'CE' ||
                temp?.instrument?.selection === 'PE'
              ) {
                const difference =
                  parseInt(allOldData[1].strike.selection) -
                  parseInt(allOldData[0].strike.selection);
                temp.strike.selection =
                  parseInt(allOldData[oldIndex - 1].strike.selection) +
                  difference;
              }
              return temp;
            } else {
              return oldItem;
            }
          })
        );
        break;
      default:
        break;
    }
    switch (constraintData[7].selection) {
      case 'Same in All Legs':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            const temp = { ...oldItem };
            temp.buySell.selection = allOldData[0].buySell.selection;
            return temp;
          })
        );
        break;
      case 'Alternate Same':
        setNewTableData((old) =>
          old?.map((oldItem, oldIndex, allOldData) => {
            if (oldIndex > 0) {
              const temp = { ...oldItem };
              temp.buySell.selection =
                allOldData[oldIndex - 1].buySell.selection === 'Buy'
                  ? 'Sell'
                  : 'Buy';
              return temp;
            } else {
              return oldItem;
            }
          })
        );
        break;
      default:
        break;
    }
  }

  async function updateSymbolsAtIndex(index, allData) {
    const tempRowData = allData.filter(
      (item, itemIndex) => itemIndex === index
    )[0];
    if (tempRowData.instrument.selection && tempRowData.exchange.selection) {
      const response = await MakeRequest(
        '/security/securities',
        true,
        true
      ).get({
        exchange: tempRowData.exchange.selection,
        instrument: options.instrument?.filter(
          (item) =>
            item.name === tempRowData.instrument.selection ||
            item.key === tempRowData.instrument.selection
        )[0].key,
        securityFilter: strategyTemplateForm.securityFilter,
      });
      return allData.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            symbol: { ...item.symbol, options: response.data.data },
          };
        } else {
          return item;
        }
      });
    } else {
      return allData;
    }
  }

  async function updateNicknameAtIndex(index, allData) {
    try {
      const tempRowData = allData.filter(
        (item, itemIndex) => itemIndex === index
      )[0];
      const acitiveInstrument = options.instrument.filter(
        (item) =>
          item.name === tempRowData.instrument.selection ||
          item.key === tempRowData.instrument.selection
      )[0].key;

      const response = await MakeRequest('/security/nickname', true, true).get({
        exchange: tempRowData.exchange.selection,
        instrument: acitiveInstrument,
        security: tempRowData.symbol.selection,
        expiry: ['Equity'].includes(acitiveInstrument)
          ? ''
          : tempRowData.expiry.selection,
        strike: ['Equity', 'Future'].includes(acitiveInstrument)
          ? ''
          : parseInt(tempRowData.strike.selection) !== NaN
          ? tempRowData.strike.selection
          : '',
      });
      return allData.map((item, itemIndex) => {
        if (itemIndex === index && response.status !== 500) {
          return {
            ...item,
            nickname: response.data.code,
          };
        } else {
          return item;
        }
      });
    } catch (error) {
      return allData;
    }
  }

  async function updateExpiryAtIndex(index, allData) {
    try {
      const tempRowData = allData.filter(
        (item, itemIndex) => itemIndex === index
      )[0];
      let newAllData = [...allData];
      let expiryOptions = [];
      if (
        tempRowData.instrument.selection !== 'Equity' &&
        tempRowData.instrument.selection &&
        tempRowData.exchange.selection &&
        tempRowData.symbol.selection
      ) {
        const response = await MakeRequest('/security/expires', true, true).get(
          {
            exchange: tempRowData.exchange.selection,
            instrument: options.instrument?.filter(
              (item) =>
                item.name === tempRowData.instrument.selection ||
                item.key === tempRowData.instrument.selection
            )[0].key,
            security: tempRowData.symbol.selection,
          }
        );

        if (response.status !== 400) {
          expiryOptions = response?.data?.data?.map(
            (respItem) => respItem.expiry
          );
        }
        newAllData[index].expiry.options = expiryOptions;
      }

      if (tempRowData.instrument.selection === 'Equity') {
        newAllData[index].expiry.selection = '';
        newAllData[index].expiry.options = [];
        newAllData[index].expiry.disabled = false;
      } else {
        switch (constraintData[5].selection) {
          case 'Same in All Legs':
            newAllData[index].expiry.selection = newAllData[0].expiry.selection;
            newAllData[index].expiry.options = expiryOptions;
            newAllData[index].expiry.disabled = true;
            break;
          case 'Alternate Same':
            if (index > 2) {
              newAllData[index].expiry.selection =
                newAllData[index - 2].expiry.selection;
              newAllData[index].expiry.options = expiryOptions;
              newAllData[index].expiry.disabled = true;
            }
            break;
          default:
            break;
        }
      }
      return newAllData;
    } catch (error) {
      console.log(error);
      return allData;
    }
  }

  async function updateStrikeAtIndex(index, allData) {
    try {
      const tempRowData = allData.filter(
        (item, itemIndex) => itemIndex === index
      )[0];
      let newAllData = [...allData];

      if (
        tempRowData.instrument.selection !== 'Equity' &&
        tempRowData.instrument.selection !== 'Future' &&
        tempRowData.instrument.selection &&
        tempRowData.exchange.selection &&
        tempRowData.symbol.selection &&
        tempRowData.expiry.selection
      ) {
        const date = new Date(tempRowData.expiry.selection);
        const response = await MakeRequest('/security/strikes', true, true).get(
          {
            exchange: tempRowData.exchange.selection,
            instrument: options.instrument?.filter(
              (item) =>
                item.name === tempRowData.instrument.selection ||
                item.key === tempRowData.instrument.selection
            )[0].key,
            security: tempRowData.symbol.selection,
            expiry: date.toISOString(),
          }
        );
        let responseData = response?.status !== 400 ? response?.data?.data : [];
        newAllData[index].strike.options = responseData;
      }

      switch (tempRowData.instrument.selection) {
        case 'Future':
        case 'Equity':
          newAllData[index].strike.selection = '';
          newAllData[index].strike.options = [];
          newAllData[index].strike.disabled = true;
          break;
        default:
          switch (constraintData[6].selection) {
            case 'Same in All Legs':
              newAllData[index].strike.selection =
                index > 0
                  ? newAllData[0].strike.selection
                  : newAllData[index].strike.selection;
              break;
            case 'Alternate Same':
              newAllData[index].strike.selection =
                index > 2
                  ? newAllData[index - 2].strike.selection
                  : newAllData[index].strike.selection;
              break;
            case 'Arithmetic Progression':
              newAllData[index].strike.selection =
                index > 1
                  ? (
                      parseInt(newAllData[1].strike.selection) -
                      parseInt(newAllData[0].strike.selection) +
                      parseInt(newAllData[index - 1].strike.selection)
                    ).toString()
                  : newAllData[index].strike.selection;
              break;
          }
          break;
      }

      return newAllData;
    } catch (error) {
      console.log(error);
    }
  }

  function isDisabledField(column, legNo, columnCondition) {
    switch (column) {
      case 2:
        if (
          (columnCondition == 'Alternative (PE/CE)' ||
            columnCondition == 'Same in All Legs') &&
          legNo >= 1
        ) {
          return true;
        } else {
          return false;
        }
      case 4:
        if (columnCondition == 'Same in All Legs' && legNo >= 1) {
          return true;
        } else {
          return false;
        }
      case 6:
        if (columnCondition == 'Same in All Legs' && legNo >= 1) {
          return true;
        } else if (
          (columnCondition == 'Alternate Same' ||
            columnCondition == 'Arithmetic Progression') &&
          legNo >= 2
        ) {
          return true;
        } else {
          return false;
        }
      case 5:
        if (columnCondition == 'Same in All Legs' && legNo >= 1) {
          return true;
        } else if (columnCondition == 'Alternate Same' && legNo >= 2) {
          return true;
        } else {
          return false;
        }
      case 7:
        if (
          (columnCondition == 'Alternate Same' ||
            columnCondition == 'Same in All Legs') &&
          legNo >= 1
        ) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  async function updateTableDataFromAPIs(tableData) {
    try {
      let dataArray = tableData ? [...tableData] : [];
      for (let i = 0; i < dataArray.length; i++) {
        dataArray = await updateSymbolsAtIndex(i, dataArray);
        dataArray = await updateNicknameAtIndex(i, dataArray);
        dataArray = await updateExpiryAtIndex(i, dataArray);
        dataArray = await updateStrikeAtIndex(i, dataArray);
        dataArray[i].instrument.options = dataArray[
          i
        ].instrumentAllowed?.options
          ?.filter((item) => item.state)
          ?.map((item) => item.title);
      }
      applyConstrints();
      setChangeFlag(false);
      setNewTableData(dataArray);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateTableDataFromAPIsAddi() {
    let dataArray = [...addSecurity];

    for (let i = 0; i < dataArray.length; i++) {
      dataArray = await updateSymbolsAtIndex(i, dataArray);
      dataArray = await updateNicknameAtIndex(i, dataArray);
      dataArray = await updateExpiryAtIndex(i, dataArray);
      dataArray = await updateStrikeAtIndex(i, dataArray);
      dataArray[i].instrument.options = dataArray[i].instrumentAllowed.options
        .filter((item) => item.state)
        .map((item) => item.title);
    }
    setAddChangeFlag(false);
    setAddSecurity(dataArray);
  }

  function addSecurityRow() {
    const tempObj = {
      nickname: '',
      instrumentAllowed: {
        selection: '',
        options: options.instrument?.map((item) => ({
          title: item.name,
          state: true,
        })),
        disabled: false,
      },
      instrument: {
        selection: '',
        options: options.instrument?.map((item) => item.name),
        disabled: false,
      },
      exchange: {
        selection: 'NSE',
        options: exchangeOptions || [],
        disabled: false,
      },
      symbol: { selection: '', options: [], disabled: false },
      expiry: { selection: '', options: [], disabled: false },
      strike: {
        selection: '',
        options: [],
        disabled: false,
      },
      buySell: {
        selection: '',
        options: ['Buy', 'Sell'],
        disabled: false,
      },
    };
    if (enableConstraint) {
      switch (constraintData[2].selection) {
        case 'Same in All Legs':
          tempObj.instrument.selection = newTableData[0].instrument.selection;
          break;
        case 'Alternative (PE/CE)':
          tempObj.instrument.selection =
            newTableData[newTableData.length - 1].instrument.selection === 'CE'
              ? 'PE'
              : 'CE';
        default:
          break;
      }
      switch (constraintData[4].selection) {
        case 'Same in All Legs':
          tempObj.symbol.selection = newTableData[0].symbol.selection;
          break;
        default:
          break;
      }
      switch (constraintData[5].selection) {
        case 'Same in All Legs':
          tempObj.expiry.selection = newTableData[0].expiry.selection;
          break;
        case 'Alternate Same':
          if (newTableData.length >= 2) {
            tempObj.expiry.selection =
              newTableData[newTableData.length - 2].expiry.selection;
          }
          break;
        default:
          break;
      }
      switch (constraintData[6].selection) {
        case 'Same in All Legs':
          tempObj.strike.selection = newTableData[0].strike.selection;
          break;
        case 'Alternate Same':
          if (newTableData.length >= 2) {
            tempObj.strike.selection =
              newTableData[newTableData.length - 2].strike.selection;
          }
          break;
        case 'Arithmetic Progression':
          if (newTableData.length > 2) {
            tempObj.strike.selection =
              newTableData[newTableData.length - 1].strike.selection +
              (newTableData[1].strike.selection -
                newTableData[0].strike.selection);
          }
          break;
        default:
          break;
      }

      switch (constraintData[7].selection) {
        case 'Same in All Legs':
          tempObj.buySell.selection = newTableData[0].buySell.selection;
          break;
        case 'Alternate Same':
          if (newTableData.length >= 1) {
            tempObj.buySell.selection =
              newTableData[newTableData.length - 1].buySell.selection === 'Buy'
                ? 'Sell'
                : 'Buy';
          }
          break;
        default:
          break;
      }
    }
    setNewTableData((old) => [...old, tempObj]);
    setChangeFlag((old) => !old);
  }

  function addAdditionalSecurityRow() {
    const tempObj = {
      nickname: '',
      instrumentAllowed: {
        selection: '',
        options: options.instrument?.map((item) => ({
          title: item.name,
          state: true,
        })),
        disabled: false,
      },
      instrument: { selection: '', options: [], disabled: false },
      exchange: {
        selection: 'NSE',
        options: exchangeOptions || [],
        disabled: false,
      },
      symbol: { selection: '', options: [], disabled: false },
      expiry: { selection: '', options: [], disabled: false },
      strike: {
        selection: '',
        options: [],
        disabled: false,
      },
      buySell: {
        selection: '',
        options: ['Buy', 'Sell'],
        disabled: false,
      },
    };
    setAddSecurity((old) => [...old, tempObj]);
    setChangeFlag((old) => !old);
  }

  useEffect(() => {
    const temp = async () => {
      dispatch(strategyExchange());
      dispatch(builderObject());
      dispatch(strategyInstrumenType());

      if (
        edit &&
        strategyTemplateForm.securities.length > 0 &&
        !editUpdateFlag
      ) {
        setNewTableData(await prepareEditdata(strategyTemplateForm.securities));
        setAddSecurity(
          await prepareEditdata(strategyTemplateForm.securities, true)
        );
        setEditUpdateFlag(true);
      } else {
        addSecurityRow();
      }
    };
    temp();
  }, []);

  useEffect(() => {
    setOptions((old) => ({ ...old, instrument: instApiData }));
  }, [instApiData]);

  useEffect(() => {
    setOptions((old) => ({ ...old, exchange: exchangeOptions }));
  }, [exchangeOptions]);

  useEffect(() => {
    setNewTableData((old) => {
      if (!Array.isArray(old)) {
        return old;
      }
      const tempOldSt = old?.map((item) => {
        const oldItem = { ...item };
        oldItem.instrumentAllowed.options = options?.instrument?.map(
          (opIt) => ({
            title: opIt.name,
            state: true,
          })
        );
        oldItem.exchange.options = options.exchange;
        return oldItem;
      });
      return tempOldSt;
    });
  }, [options]);

  useEffect(() => {
    dispatch({ type: 'SB_UPDATE_SECURITY_FILTER', payload: symbols.selection });
  }, [symbols]);

  useEffect(() => {
    updateSecurityConstaints(dispatch, enableConstraint ? constraintData : []);
  }, [constraintData, enableConstraint]);

  useEffect(() => {
    if (!enableConstraint) {
      const tempConstData = [...constraintData];
      tempConstData[2].selection = tempConstData[2].options[0];
      tempConstData[4].selection = tempConstData[4].options[0];
      tempConstData[5].selection = tempConstData[5].options[0];
      tempConstData[6].selection = tempConstData[6].options[0];
      tempConstData[7].selection = tempConstData[7].options[0];
      setConstraintData(tempConstData);
      setChangeFlag((old) => !old);
    }
  }, [enableConstraint]);

  useEffect(() => {
    if (edit !== undefined && edit && !editUpdateFlag) {
      prepareEditdata();
    }
  }, [editUpdateFlag]);

  useEffect(() => {
    if (securityConditions) {
      const tempData = [
        {},
        {},
        {
          selection:
            strategyTemplateForm?.constraintData?.instrumentConstraint ||
            securityConditions['Instruments'][0],
          options: securityConditions['Instruments'],
        },
        {},
        {
          selection:
            strategyTemplateForm?.constraintData?.symbolConstraint ||
            securityConditions['Symbols'][0],
          options: securityConditions['Symbols'],
        },
        {
          selection:
            strategyTemplateForm?.constraintData?.expiryConstraint ||
            securityConditions['Expiry'][0],
          options: securityConditions['Expiry'],
        },
        {
          selection:
            strategyTemplateForm?.constraintData?.strikeConstraint ||
            securityConditions['Strike'][0],
          options: securityConditions['Strike'],
        },
        {
          selection:
            strategyTemplateForm?.constraintData?.buySellConstraint ||
            securityConditions['Buy/Sell'][0],
          options: securityConditions['Buy/Sell'],
        },
      ];
      const enabled =
        tempData.reduce((acc, cur) => acc + cur.selection, '').length > 0;
      setEnableConstraint(enabled);
      setConstraintData(tempData);
    }
  }, [securityConditions]);

  useEffect(() => {
    applyConstrints();
    setChangeFlag((old) => !old);
  }, [constraintData]);

  useEffect(() => {
    updateSecurities(dispatch, newTableData, addSecurity, options.instrument);
  }, [newTableData, addSecurity]);

  useEffect(() => {
    if (changeFlag) updateTableDataFromAPIs(newTableData);
  }, [newTableData, changeFlag]);

  useEffect(() => {
    if (addChangeFlag) {
      updateTableDataFromAPIsAddi();
    }
  }, [addSecurity, addChangeFlag]);

  return (
    <div className="step_3">
      <HeaderStrategyBuilder title="Select Securities" icon={SecuritiesIcon} />
      <div className="SelectSecurities-container">
        <div className="buy-sell-cont">
          <div className="buy_sell">
            <GenToolTip tooltip="These securities can be used for building logic and taking positions (buy/sell).">
              <span className="buy-sell-label font-l1">
                Securities to Buy/Sell
              </span>
            </GenToolTip>
          </div>
          <div className="security-filter">
            <DropDown
              label="Symbols"
              ddExtStyles={{ width: '9rem' }}
              options={symbols.options}
              selected={symbols.selection}
              onSelect={(val) =>
                setSymbols((old) => ({ ...old, selection: val }))
              }
            />
          </div>
          <div className="sec-later-cont">
            <CheckBox
              state={addSecurityLater}
              trigger={() => setAddSecurityLater((old) => !old)}
              label="Add Security Later"
              extStyles={{ margin: 'auto 1rem' }}
            />
            <CheckBox
              state={enableConstraint}
              trigger={() => setEnableConstraint((old) => !old)}
              label="Enable Constraint"
              extStyles={{ margin: 'auto 1rem' }}
            />
          </div>
        </div>
        <div className="table-cont">
          <TableV3
            extraHeaders={enableConstraint ? constraintHeaders : []}
            extStyles={{
              margin: '1rem 0',
              height: 'fit-content',
            }}
            properties={tableV2Properties}
            data={newTableData}
            columnContraint={constraintData}
          />
          <GenButton
            icon={AddCircleIcon}
            fill={false}
            title="Add Security"
            clickAction={addSecurityRow}
          />
        </div>
        <div
          className={
            isApproveReject ? 'approveReject-pointer additional' : 'additional'
          }
        >
          <span className="font-l1">
            Additional Securities to check conditions
          </span>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                <div className="tooltipinnerwrapper">
                  These securities can only be used for building logic. These
                  securities can only be used for building logic.
                </div>
              </Tooltip>
            }
          >
            <Image src={info} className="info" />
          </OverlayTrigger>
        </div>
        <div className="stategy-builder-addSecurity">
          {addSecurity?.length > 0 && (
            <TableV3
              extStyles={{
                margin: '1rem 0',
                height: 'fit-content',
              }}
              properties={additionalSecuriteisColumns}
              data={addSecurity}
            />
          )}

          <GenButton
            icon={AddCircleIcon}
            fill={false}
            title="Add Security"
            clickAction={addAdditionalSecurityRow}
          />
        </div>
      </div>
    </div>
  );
}

const Api = {
  GetInstrumentData: () => {
    const { data, error, isLoading } = useSWRImmutable(
      '/security/instrumenTypes',
      async () => {
        try {
          const respData = await MakeRequest(
            '/security/instrumenTypes',
            true,
            true
          ).get();
          return respData.status === 200 ? respData.data.data : [];
        } catch (error) {
          return [];
        }
      }
    );
    return [data, error, isLoading];
  },
};

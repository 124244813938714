const initialState = {
  data: [],
  webData: [],
  selectedData: { admin: [], team: [], tradingAccount: [], customer: [] },
  formRiskData: [],
  risks: [],
  selectedCusTradingAccounts: [],
  monitMessage: '',
  selectedNods: [],
  liveData: [],
};

const riskMonitReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TEAMS':
      const mainArr = [
        { ...state.risks.adminRisk?.adminData, teams: action.teams },
      ];
      const computedArr = mainArr.map((el) => {
        const adminRiskData = state.risks.adminRisk?.adminRisk;
        const teamData = el.teams?.map((elt) => {
          const teamRiskData = state.risks.teamRisk.find(
            (eltn) => eltn.teamData.id === elt.id
          ).teamRisk;
          const customerData = elt.customerData.map((cust) => {
            const tradingAcc = state.risks.customerRisk.find(
              (elcust) => elcust.id === cust.id
            ).tradingAccountData;
            return {
              ...cust,
              tradingAccount: tradingAcc,
            };
          });
          return {
            ...elt,
            teamRisks: teamRiskData,
            customerData,
          };
        });
        return {
          ...el,
          teams: teamData,
          adminRisks: adminRiskData,
        };
      });
      return {
        ...state,
        data: [...computedArr],
        fetched: action.payload,
      };
    case 'SET_LIVEDATA':
      return {
        ...state,
        webData: action.payload,
      };
    case 'GET_RISKS':
      return {
        ...state,
        risks: action.riskData,
      };

    case 'SELECTED_NODS_UPDATE':
      return {
        ...state,
        selectedNods: action.payload,
      };

    case 'SET_SELECT_LIVEDATA':
      let formRiskData = null;
      let selectedCusTradingAccounts = null;
      const adminId = action.payload.adminId;
      const teamId = action.payload.teamId;
      let selectedDataTemp = state.selectedData;
      switch (action.payload.userType) {
        case 'customer':
          const findexist = selectedDataTemp.tradingAccount.find(
            (el) => el.custId === eval(action.payload.custId)
          );
          if (findexist) {
            //filter through custId
            selectedDataTemp = {
              ...selectedDataTemp,
              tradingAccount: selectedDataTemp.tradingAccount.filter(
                (el) => el.custId !== eval(action.payload.custId)
              ),
            };
          } else {
            selectedDataTemp = {
              ...selectedDataTemp,
              tradingAccount: [
                ...selectedDataTemp.tradingAccount,
                {
                  tradingAccountId: Number(action.payload.tradingId),
                  custId: eval(action.payload.custId),
                },
              ],
            };
          }

          for (let i = 0; i < state.liveData.length; i++) {
            let children = state.liveData[i].children;
            if (state.liveData[i].data.nodeId === adminId) {
              for (let l = 0; l < children.length; l++) {
                if (state.liveData[i].children[l].data.nodeId === teamId) {
                  let inCustArr = [];
                  for (let m = 0; m < children[l].children.length; m++) {
                    inCustArr = [
                      ...inCustArr,
                      ...selectedDataTemp.tradingAccount.map(
                        (el) =>
                          el.custId ===
                          eval(children[l].children[m].data.detaObj.custId)
                      ),
                    ];
                  }
                  if (
                    inCustArr.every((el) => el === false) ||
                    selectedDataTemp.tradingAccount.length === 0
                  ) {
                    selectedDataTemp = {
                      ...selectedDataTemp,
                      team: selectedDataTemp.team.filter(
                        (el) => el.teamId !== Number(children[l].data.nodeId)
                      ),
                    };
                  }
                }
              }
              if (selectedDataTemp.team.length === 0) {
                selectedDataTemp = {
                  ...selectedDataTemp,
                  admin: selectedDataTemp.admin.filter(
                    (el) => el.adminId !== Number(state.liveData[i].data.nodeId)
                  ),
                };
              }
            }
          }

          break;
        case 'team':
          let newTeamObj = {};
          let newteamArr = [];
          const findexistteam = selectedDataTemp.team.find(
            (el) => el.teamId === Number(action.payload.teamId)
          );
          if (findexistteam) {
            selectedDataTemp = {
              ...selectedDataTemp,
              team: selectedDataTemp.team.filter(
                (el) => el.teamId !== Number(action.payload.teamId)
              ),
            };
          } else {
            selectedDataTemp = {
              ...selectedDataTemp,
              team: [...selectedDataTemp.team, { teamId: Number(teamId) }],
            };
          }
          for (let j = 0; j < state.liveData.length; j++) {
            let children = state.liveData[j].children;
            newTeamObj = {};
            for (let k = 0; k < children.length; k++) {
              if (state.liveData[j].children[k].data.nodeId === teamId) {
                for (let c = 0; c < children[k].children.length; c++) {
                  if (findexistteam) {
                   
                    selectedDataTemp = {
                      ...selectedDataTemp,
                      tradingAccount: selectedDataTemp.tradingAccount.filter(
                        (el) =>
                          el.custId !==
                          eval(children[k].children[c].data.detaObj.custId)
                      ),
                    };

                    if (
                      selectedDataTemp.team.includes(
                        (el) =>
                          el.teamId === Number(children[k].data.detaObj.teamId)
                      ) ||
                      selectedDataTemp.team.length === 0
                    ) {
                      selectedDataTemp = {
                        ...selectedDataTemp,
                        admin: selectedDataTemp.admin.filter(
                          (el) => el.adminId !== Number(action.payload.adminId)
                        ),
                      };
                    }
                  } else {
                    let inTrading = selectedDataTemp.tradingAccount.find(
                      (el) =>
                        el.custId ===
                        eval(children[k].children[c].data.detaObj.custId)
                    );
                    if (!inTrading) {
                      selectedDataTemp = {
                        ...selectedDataTemp,
                        tradingAccount: [
                          ...selectedDataTemp.tradingAccount,
                          {
                            tradingAccountId: Number(
                              children[k].children[c].data.detaObj.tradingId
                            ),
                            custId: eval(
                              children[k].children[c].data.detaObj.custId
                            ), //tradingId diff
                          },
                        ],
                      };
                    }
                  }

                  newTeamObj = {
                    tradingAccount: Number(
                      children[k].children[c].data.detaObj.tradingId
                    ),
                  };
                  newteamArr.push(newTeamObj);
                }
              }
            }
          }

          break;
        case 'admin':
          let teamArr = [];
          let childArr = [];
          let tempTeamObj = {};
          let tempChildObj = {};
          const findexistAdmin = selectedDataTemp.admin.find(
            (el) => el.adminId === Number(adminId)
          );
          for (let j = 0; j < state.liveData.length; j++) {
            let children = state.liveData[j].children;
            tempChildObj = {};
            for (let k = 0; k < children.length; k++) {
              if (
                state.liveData[j].data.nodeId === adminId &&
                !findexistAdmin
              ) {
                let inTeam = selectedDataTemp.team.find(
                  (el) => el.teamId === Number(children[k].data.nodeId)
                );
                if (!inTeam) {
                  selectedDataTemp = {
                    ...selectedDataTemp,
                    team: [
                      ...selectedDataTemp.team,
                      { teamId: Number(children[k].data.nodeId) },
                    ],
                  };
                }
                tempTeamObj = {
                  teamId: Number(children[k].data.nodeId),
                };
                teamArr.push(tempTeamObj);

                for (let c = 0; c < children[k].children.length; c++) {
                  let inTradingadmin = selectedDataTemp.tradingAccount.find(
                    (el) =>
                      el.custId ===
                      eval(children[k].children[c].data.detaObj.custId)
                  );

                  if (!inTradingadmin) {
                    selectedDataTemp = {
                      ...selectedDataTemp,
                      tradingAccount: [
                        ...selectedDataTemp.tradingAccount,
                        {
                          tradingAccountId: Number(
                            children[k].children[c].data.detaObj.tradingId
                          ),
                          custId: eval(
                            children[k].children[c].data.detaObj.custId
                          ), //tradingID diff
                        },
                      ],
                    };
                  }
                  tempChildObj = {
                    tradingAccount: Number(
                      children[k].children[c].data.detaObj.tradingId
                    ),
                  };
                  childArr.push(tempChildObj);
                }
              } else if (
                state.liveData[j].data.nodeId === adminId &&
                findexistAdmin
              ) {
                selectedDataTemp = {
                  ...selectedDataTemp,
                  team: selectedDataTemp.team.filter(
                    (el) =>
                      el.teamId !== Number(children[k].data.detaObj.teamId)
                  ),
                };

                for (let c = 0; c < children[k].children.length; c++) {
                  // for filter through custId
                  selectedDataTemp = {
                    ...selectedDataTemp,
                    tradingAccount: selectedDataTemp.tradingAccount.filter(
                      (el) =>
                        el.custId !==
                        eval(children[k].children[c].data.detaObj.custId)
                    ),
                  };

                  tempChildObj = {
                    tradingAccount: Number(
                      children[k].children[c].data.detaObj.tradingId
                    ),
                  };
                  childArr.push(tempChildObj);
                }
              }
            }
          }
          if (findexistAdmin) {
            selectedDataTemp = {
              ...selectedDataTemp,
              admin: selectedDataTemp.admin.filter(
                (el) => el.adminId !== Number(adminId)
              ),
            };
          } else {
            selectedDataTemp = {
              ...selectedDataTemp,
              admin: [...selectedDataTemp.admin, { adminId: Number(adminId) }],
            };
          }
          break;
        case 'trading':
         
          break;
        default:
          break;
      }
      return {
        ...state,
        formRiskData: formRiskData ? formRiskData : [],
        selectedCusTradingAccounts: selectedCusTradingAccounts
          ? selectedCusTradingAccounts
          : state.selectedCusTradingAccounts,
        selectedData: { ...selectedDataTemp },
      };
    case 'RISK_MONIT':
      return {
        ...state,
        monitMessage: action.mess,
      };

    case 'CLEAR_RISK_MONIT':
      return {
        ...state,
        monitMessage: action.mess,
      };
    case 'GET_LIVE_DATA':
      return {
        ...state,
        liveData: action.payload,
      };

    case 'CLEAR_SELECTED_DATA':
      return {
        ...state,
        selectedData: action.payload,
      };

    default:
      return state;
  }
};

export default riskMonitReducer;

import React, { useState, useEffect } from 'react';
import './styles.css';
import TblExtention from './tblExtention/tblExtention.jsx';
import Table from 'react-bootstrap/Table';
import { AscIcon, DesIcon, NeuIcon } from '../../../assets';

// * const pagesInfo = { current: 0, total: 5 };
// when showNoOfEntries == true, always pass a valid totalEntries and entriesPerPage as well
const TableGenerator = (props) => {
  const {
    heads = [],
    headExtention = [],
    applyCommonRiskLimitCallback,
    content = [],
    isLTSc = false,
    striped = true,
    extStyles = {},
    pagesInfo,
    pageChange,
    sortObject = { enabledColumns: [] },
    toggledColumn = () => {},
    className = 'tableContainer',
    showNoOfEntries = false,
    totalEntries = 6,
    entriesPerPage = 6,
  } = props;

  // useEffect(() => {
  //   console.log(sortObject);
  // }, [sortObject]);

  // * const enabledColumn = [{ state: true, order: 'n' }];

  function loadHead(obj, index) {
    if (
      sortObject &&
      sortObject.enabledColumns &&
      sortObject.enabledColumns[index] &&
      sortObject.enabledColumns[index].state
    ) {
      return (
        <span className="tableGen-cell">
          <span className="thHeads">{obj}</span>
          {sortObject &&
            sortObject.enabledColumns &&
            sortObject.enabledColumns[index] &&
            sortObject.enabledColumns[index].state && (
              <img
                src={
                  sortObject.enabledColumns[index].order === 'n'
                    ? NeuIcon
                    : sortObject.enabledColumns[index].order === 'a'
                    ? AscIcon
                    : DesIcon
                }
                className="GenTable-filIcon"
                onClick={() =>
                  toggledColumn(index, sortObject.enabledColumns[index].order)
                }
              />
            )}
        </span>
      );
    } else if (
      headExtention &&
      headExtention.length > 0 &&
      index &&
      index >= 0
    ) {
      if (headExtention[index] && headExtention[index].title) {
        return (
          <div className="thHeads-x">
            {obj}
            <TblExtention
              title={headExtention[index].title}
              config={headExtention[index].config}
              applyCommonRiskLimitCallback={applyCommonRiskLimitCallback}
            />
          </div>
        );
      } else {
        return <div className="thHeads">{obj}</div>;
      }
    } else {
      return <span className="thHeads">{obj}</span>;
    }
  }

  function generateHeaders(arrOfHeads) {
    return arrOfHeads.map((obj, index) => (
      <th className={`thHeadMain ${isLTSc && 'risk-internal-live-trade-th'}`}>
        {loadHead(obj, index)}
      </th>
    ));
  }

  function generateBottomPages() {
    const arr = [];

    if (showNoOfEntries) {
      let firstEntryNumber = entriesPerPage * (pagesInfo.current - 1) + 1;
      arr.push(
        <p>
          Showing {firstEntryNumber} to {firstEntryNumber + content.length - 1}{' '}
          of {totalEntries} entries
        </p>
      );
    }

    for (let i = 1; i < pagesInfo.total + 1; i++) {
      arr.push(
        <p
          className={`tablenGen-currPage-${
            i === pagesInfo.current ? 'act' : 'ina'
          }`}
          onClick={() => pageChange(i)}
        >
          {i}
        </p>
      );
    }
    return arr.map((item) => item);
  }

  return (
    <div className={className} style={extStyles}>
      <TableGen striped={striped}>
        <thead>
          <tr>{generateHeaders(heads)}</tr>
        </thead>
        <tbody>{content}</tbody>
      </TableGen>
      <div
        className={`genTable-pager-cont ${
          showNoOfEntries ? 'genTable-pager-cont-extra' : ''
        }`}
      >
        {pagesInfo && generateBottomPages()}
      </div>
    </div>
  );
};

// * this is made to give
const TableGen = (props) => {
  const { striped, children } = props;

  function gen() {
    switch (striped) {
      case true:
        return (
          <Table striped hover bordered={true}>
            {children}
          </Table>
        );
      default:
        return <Table hover>{children}</Table>;
    }
  }

  return gen();
};

export default TableGenerator;

import React, { useState, useEffect, useRef } from 'react';
import './RiskMonitoring.scss';
import RiskMonitoringUtil from '../RiskMonitorInternal/riskMonitorUtil'; // Pre Trade component
import RiskMonitoring from '../RiskMonitoring/RiskMonitoring'; // Post Trade component
import RiskMonitoringPreTradeHFT from '../RiskMonitoringPreTradeHFT/RiskMonitoringPreTradeHFT'; // Post Trade component
import { TabNavInner } from '../../../components';

function RiskMonitor({
  sizeAdjustable = false,
  showLogLive = true,
  isLiveTradingScreen = false,
}) {
  let [tabSelect, setTabSelect] = useState(0);
  const [client, setClient] = useState(null);
  const isHFT =
    process.env.REACT_APP_IS_HFT === 'true' ||
    process.env.REACT_APP_IS_MARG_VERSION == 'true'
      ? true
      : false;
  const tabs = isHFT
    ? [
        { id: 0, title: 'Pre Trade' },
        { id: 1, title: 'Post Trade' },
      ]
    : [{ id: 0, title: 'Pre Trade' }];

  // Close websocket on tab change
  useEffect(() => {
    closeWebsocket();
  }, [tabSelect]);

  function closeWebsocket() {
    if (client && client.OPEN) {
      client.close();
      setClient(null);
    }
  }
  const extStyles = sizeAdjustable
    ? {
        marginTop: '-10px !important',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 #00000000',
        height: '100%',
        width: '100%',

        zIndex: '0',
      }
    : {
        marginTop: '-10px !important',
        border: 'solid #cecece 0.2vh',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 #00000000',
      };

  console.log(
    `sizeAdjustable: ${sizeAdjustable} , tabSelect: ${tabSelect}, isHFT: ${isHFT}`
  );

  return (
    <div className={`dashboard risk-monitering`} style={extStyles}>
      {!sizeAdjustable && (
        <TabNavInner
          tabs={tabs}
          curSelect={tabSelect}
          setSelect={setTabSelect}
        />
      )}
      <div>
        {tabSelect ? (
          <RiskMonitoring
            tabSelect={tabSelect}
            client={client}
            setClient={setClient}
            showLogLive={showLogLive}
          />
        ) : isHFT ? (
          <RiskMonitoringPreTradeHFT
            tabSelect={tabSelect}
            client={client}
            setClient={setClient}
          />
        ) : (
          <RiskMonitoringUtil
            showLogLive={showLogLive}
            isLiveTradingScreen={isLiveTradingScreen}
          />
        )}
      </div>
    </div>
  );
}

export default RiskMonitor;

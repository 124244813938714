import React from "react"
import { Form } from 'react-bootstrap'
import CheckBox from './CheckBox.scss'

const CustomCheckBox = ({ key, label, value, handlerfunction, checked, name, disable, callBack=()=>{} }) => {
  return (
    <Form>
      <Form.Group className="customCheckbox" controlId="formBasicCheckbox">
        <Form.Check disabled={disable ? true : false} key={key} value={value ? value : ''} name={name ? name : ''} onChange={(e) => handlerfunction(e)} checked={checked ? checked : ''}
          type="checkbox"
          label={label} 
          onKeyPress={(event) => {
            if (event.key == 'Enter') {
              event.target.checked = !event.target.checked;
              callBack(event);
            }
          }} />
      </Form.Group>
    </Form>
  )
}

export default CustomCheckBox

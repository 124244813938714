import axios from 'axios';
import { url } from '../config/apiConfig';
import {
  GET_ALERTDATA,
  GET_ALL_ALERTDATA,
  POST_APPROVAL,
  SET_GROUP_ID,
  SET_APPROVE_REJECT,
  SET_GROUP_DATA_ARRAY,
  SET_SELECTED_ALERTS,
  SET_ALERT_EXPAND,
  SET_ALERT_EXPAND_GROUP,
  UPDATE_TEMPLATE_ID,
  UPDATE_IN_TEMPLATE,
  UPDATE_ALERT_TABLE,
  UPDATE_ALERT_LIVE_DATA,
  SET_ALERT_EXPAND_NEW,
} from './types';

export const alertData = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/user/alert/design`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALERTDATA,
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALERTDATA,
      payload: [],
    });
  }
};

export const allAlertData = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/user/alerts`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_ALERTDATA,
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_ALERTDATA,
      payload: [],
    });
  }
};

//submit
export const alertApproval = (alertApprovalData) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategyTemplate/approval`,
      data: alertApprovalData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch(saveTemplateId(''));
      dispatch({
        type: POST_APPROVAL,
        payload: true,
      });
      // dispatch(approveRejectButton(false))
    }
    return { message: result.data.data.message, status: 'SUCCESS' };
  } catch (err) {
    if (err.response.data.data) {
      dispatch({
        type: POST_APPROVAL,
        errorMessage: err.response.data.data.message,
        payload: false,
      });
    }
    return {
      message: err.response.data.data.message
        ? err.response.data.data.message
        : 'Getting Some Server Error!',
      status: 'FAIL',
    };
  }
};

export const saveTemplateId = (id) => async (dispatch) => {
  dispatch({
    type: UPDATE_TEMPLATE_ID,
    payload: id,
  });
};
//grouping
export const setGroupId = (data) => async (dispatch) => {
  dispatch({
    type: SET_GROUP_ID,
    payload: data,
  });
};

export const setGroupedArr = (data) => async (dispatch) => {
  dispatch({
    type: SET_GROUP_DATA_ARRAY,
    payload: data,
  });
};
export const approveRejectButton = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_APPROVE_REJECT,
    payload: data,
  });
};

export const markAsRead = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/user/alert/markAsRead`,
      data: {
        alertId: data,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch(allAlertData());
      return { message: result.data.data.message, status: 'SUCCESS' };
    }
  } catch (error) {
    if (error.response.status === 500) {
      return {
        message: 'Getting some server error',
        status: 'FAIL',
      };
    } else {
      return {
        message: error.response.data.data.message
          ? error.response.data.data.message
          : 'Getting some server error',
        status: 'FAIL',
      };
    }
  }
};
export const deleteAlert = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/user/alert/delete`,
      data: {
        alertId: data,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch(allAlertData());
      return {
        message: result.data.data.message,
        status: 'SUCCESS',
      };
    }
  } catch (error) {
    if (error.response.status === 500) {
      return {
        message: 'Getting some server error',
        status: 'FAIL',
      };
    } else {
      return {
        message: error.response.data.data.message
          ? error.response.data.data.message
          : 'Getting some server error',
        status: 'FAIL',
      };
    }
  }
};

export const selectedAlerts = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_ALERTS,
    payload: data,
  });
};
export const setExpandId = (data) => async (dispatch, getState) => {
  const { isExpand } = getState().alertReducer;
  let newExp = [...isExpand];
  let index = newExp.findIndex((el) => el === data);
  if (index > -1) {
    newExp.splice(index, 1);
  } else {
    newExp.push(data);
  }
  dispatch({
    type: SET_ALERT_EXPAND,
    payload: newExp,
  });
};
export const resetExpand = (data) => async (dispatch) => {
  dispatch({
    type: SET_ALERT_EXPAND,
    payload: data,
  });
};

export const setNewExpandId = (data, tabType) => async (dispatch, getState) => {
  const { isExpandNew } = getState().alertReducer;
  let flag = false;
  if (isExpandNew[tabType] && isExpandNew[tabType][data.uniqueKey]) {
    flag = !isExpandNew[tabType][data.uniqueKey];
  } else {
    flag = true;
  }
  const newExp = {
    ...isExpandNew,
    [tabType]: {
      ...(isExpandNew[tabType] ? isExpandNew[tabType] : {}),
      [data.uniqueKey]: flag,
    },
  };
  dispatch({
    type: SET_ALERT_EXPAND_NEW,
    payload: newExp,
  });
};

export const resetNewExpandId = (tabType) => async (dispatch, getState) => {
  const { isExpandNew } = getState().alertReducer;
  const newExp = { ...isExpandNew, [tabType]: {} };
  dispatch({
    type: SET_ALERT_EXPAND_NEW,
    payload: newExp,
  });
};

export const setExpandGroupId = (data) => async (dispatch, getState) => {
  const { isGroupExpand } = getState().alertReducer;
  let newExp = [...isGroupExpand];
  let index = newExp.findIndex((el) => el == data);
  if (index > -1) {
    newExp.splice(index, 1);
  } else {
    newExp.push(data);
  }
  dispatch({
    type: SET_ALERT_EXPAND_GROUP,
    payload: newExp,
  });
};
export const updateInTemplate = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_IN_TEMPLATE,
    payload: data,
  });
};

export const updateAlertTable = (data) => async (dispatch, getState) => {
  const { alertTable, groupedArr } = getState().alertReducer;
  let newData = [];

  /*if(alertTable && alertTable.length && data.groupedDataObj && data.groupedDataObj.length && !data.isGroup){
     newData = [...alertTable]
    for(let i=0; i<data.groupedDataObj.length;i++){
      if(!alertTable.find((elm) => ((elm.applicationId ==  data.groupedDataObj[i].applicationId) && (elm.errorCodeId ==  data.groupedDataObj[i].errorCodeId) &&   (elm['errorType'] ==  data.groupedDataObj[i]['errorType']) &&  (elm.identifier== data.groupedDataObj[i].identifier)))){
        newData.push(data.groupedDataObj[i])
      }
      else{
        let dateIndex = alertTable.findIndex((elm) => ((elm.applicationId ==  data.groupedDataObj[i].applicationId) && (elm.errorCodeId ==  data.groupedDataObj[i].errorCodeId) &&   (elm['errorType'] ==  data.groupedDataObj[i]['errorType']) &&  (elm.identifier == data.groupedDataObj[i].identifier)))
        
        if(dateIndex>-1){

          let updatedData = {...data.groupedDataObj[i]}
         
          newData[dateIndex]= updatedData
        }
      }
     
    }
  }
  else{
    newData = data.groupedDataObj
  }*/
  dispatch({
    type: UPDATE_ALERT_TABLE,
    payload: data.groupedDataObj,
  });
};

export const liveDataUpdate = (data) => async (dispatch, getState) => {
  const { alertLiveData } = getState().alertReducer;
  let alertData = null;
  /*if(Object.keys(alertLiveData).length){
    let inLocal = {...alertLiveData}
    data.data.forEach((elm)=>{
      if(!inLocal.data.find((el)=>(elm.applicationId ==  el.applicationId) && (elm.errorCodeId ==  el.errorCodeId) &&   (elm['errorType'] ==  el['errorType']) &&  (elm.identifier== el.identifier)))
      inLocal.data.push(elm)
    })
    alertData = inLocal
   }
   else {
    alertData = data
   }*/
  alertData = data;
  dispatch({
    type: UPDATE_ALERT_LIVE_DATA,
    payload: alertData,
  });
};

import React from 'react';
import './styles.css';

// * the structure of the tab array is as follows
// * {id: 0, title: "title" }

export default function TabNavInner({ tabs = [], curSelect, setSelect }) {
  return (
    <div className="tabNavInner-back">
      {tabs.map((item) => (
        <span
          key={item.id}
          className={`tabNavInner-title ${
            item.id === curSelect ? 'tabNavInner-titleactive' : ''
          }`}
          onClick={() => setSelect(item.id)}
          tabIndex={1}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setSelect(item.id);
            }
          }}
        >
          {item.title}
        </span>
      ))}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import './styles.css';
import { Settings } from '../../../../assets';
import { GenButton, GenericPopup, Switch } from '../../../../components';
import { fetchValue, updateValue } from '../../../../utils/dotKeyFunctions';
import { V2DropDown } from '../../../../advComponents';
import useSWRImmutable from 'swr/immutable';
import { MakeRequest } from '../../../../utils';

export default function RiskMeasureUnits({ styles = {}, type = 'Family' }) {
  const [availableUnits] = APIs.GetUnits();
  const [columns] =
    type === 'Family' ? APIs.GetColumnFamily() : APIs.GetColumnDealer();

  useEffect(() => {
    const tempData = {
      quickUnitChange: false,
      quickUnit: '',
      quickOptions: [],
      riskMeasure: [],
    };

    if (columns?.length > 0 && availableUnits?.length > 0) {
      tempData.riskMeasure = columns.map((item) => {
        return {
          title: item.columnName,
          selected: item.value,
          options: availableUnits.map((unit) => unit.name),
        };
      });
      tempData.quickOptions = availableUnits.map((unit) => unit.name);
      setMeasurementState(tempData);
    }
  }, [columns, availableUnits]);

  const [popupState, setPopupState] = useState(false);
  const [measurementState, setMeasurementState] = useState({
    quickUnitChange: false,
    quickUnit: '',
    quickOptions: [],
    riskMeasure: [],
  });

  useEffect(() => {
    if (measurementState?.quickUnit) {
      setMeasurementState((old) => {
        return {
          ...old,
          riskMeasure: old.riskMeasure.map((item) => {
            return { ...item, selected: old.quickUnit };
          }),
        };
      });
    }
  }, [measurementState?.quickUnit]);

  async function handleSubmit() {
    const url = `/user/currency/setCurrencyConversionStruct`;
    const requestBody = {
      tableName:
        type === 'Family'
          ? 'NON_HFT_RISK_MONITORING_FAMILY'
          : 'NON_HFT_RISK_MONITORING_DEALER',
      currencyConversionTableSeq: measurementState.riskMeasure.map((item) => {
        return {
          columnName: item.title,
          value: item.selected,
        };
      }),
    };
    const response = await MakeRequest(url).post(requestBody);
    console.log(response);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <div
        className="settings-container"
        style={styles}
        onClick={() => setPopupState((oldState) => !oldState)}
      >
        <img src={Settings} className="settings-icon" alt="settings icon" />
      </div>
      <GenericPopup
        state={popupState}
        type={1}
        toggleState={() => setPopupState((oldState) => !oldState)}
      >
        <div className="riskMeasPopCont">
          <div className="riskMeasPopCont-titleBar">
            <span className="riskMeasPopCont-title">Risk Measure Units</span>
          </div>
          <div className="riskMeasPopCont-content">
            <Switch
              label="Quick Unit Change"
              state={fetchValue(measurementState, 'quickUnitChange')}
              onChange={(val) =>
                setMeasurementState((old) =>
                  updateValue(old, 'quickUnitChange', val)
                )
              }
              extStyles={{ margin: '0.5em 1em 0.5em auto' }}
            />
            <table className="riskMeasPopCont-table">
              <thead className="riskMeasPopCont-thead">
                <th className="riskMeasPopCont-th">Risk Measures</th>
                <th className="riskMeasPopCont-th">
                  {measurementState.quickUnitChange ? (
                    <V2DropDown
                      label="Units"
                      value={measurementState.quickUnit}
                      enableSearch={false}
                      options={measurementState.quickOptions}
                      setValue={(val) =>
                        setMeasurementState((old) =>
                          updateValue(old, `quickUnit`, val)
                        )
                      }
                      extStyles={{ inputContainer: { width: '10vw' } }}
                    />
                  ) : (
                    'Units'
                  )}
                </th>
              </thead>
              <tbody className="riskMeasPopCont-tbody">
                {measurementState.riskMeasure.map((item, index) => (
                  <tr>
                    <td className="riskMeasPopCont-td">{item.title}</td>
                    <td className="riskMeasPopCont-td">
                      <V2DropDown
                        value={item.selected}
                        options={item.options}
                        enableSearch={false}
                        setValue={(val) =>
                          setMeasurementState((old) =>
                            updateValue(
                              old,
                              `riskMeasure.${index}.selected`,
                              val
                            )
                          )
                        }
                        extStyles={{ inputContainer: { width: '10vw' } }}
                        disabled={measurementState.quickUnitChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="riskMeasPopCont-btnGrp">
            <GenButton
              fill={true}
              title="APPLY"
              extStyle={{ margin: '0 0.5em 0 auto' }}
              clickAction={() => handleSubmit()}
            />
            <GenButton
              fill={false}
              title="CLOSE"
              extStyle={{ margin: '0 0.5em 0 0.5em' }}
              clickAction={() => setPopupState((oldState) => !oldState)}
            />
            <GenButton
              fill={false}
              title="RESET"
              extStyle={{ margin: '0 0.5em 0 0.5em' }}
            />
          </div>
        </div>
      </GenericPopup>
    </React.Fragment>
  );
}

const APIs = {
  GetUnits: () => {
    const { data, error, isLoading } = useSWRImmutable(
      '/user/currency/getCurrencyUnits',
      async () => {
        const response = await MakeRequest(
          '/user/currency/getCurrencyUnits'
        ).get();
        return response.data.data.data;
      }
    );
    return [data, error, isLoading];
  },
  GetColumnFamily: () => {
    const { data, error, isLoading } = useSWRImmutable(
      '/user/currency/getCurrencyConversionStruct?tableName=NON_HFT_RISK_MONITORING_FAMILY',
      async () => {
        const response = await MakeRequest(
          '/user/currency/getCurrencyConversionStruct?tableName=NON_HFT_RISK_MONITORING_FAMILY'
        ).get();
        // Filter required data
        if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
          let filteredResp = response.data.data.filter((item) => {
            if (item.columnName && (item.columnName.includes('Executed') || item.columnName.includes('Open'))) {
              return false;
            }
            return true;
          })
          return filteredResp;
        }
        return response.data.data;
      }
    );
    return [data, error, isLoading];
  },
  GetColumnDealer: () => {
    const { data, error, isLoading } = useSWRImmutable(
      '/user/currency/getCurrencyConversionStruct?tableName=NON_HFT_RISK_MONITORING_DEALER',
      async () => {
        const response = await MakeRequest(
          '/user/currency/getCurrencyConversionStruct?tableName=NON_HFT_RISK_MONITORING_DEALER'
        ).get();

        // Filter required data
        if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
          let filteredResp = response.data.data.filter((item) => {
            if (item.columnName && (item.columnName.includes('Executed') || item.columnName.includes('Open'))) {
              return false;
            }
            return true;
          })
          return filteredResp;
        }

        return response.data.data;
      }
    );
    return [data, error, isLoading];
  },
};

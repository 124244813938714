import { GenButton } from '../../index';

export default function Button(props) {
  const {
    settings = { fill: false, styles: {}, title: '', icon: {} },
    click = () => {},
  } = props;
  return (
    <GenButton
      fill={settings.fill || false}
      extStyle={settings.styles}
      title={settings.title || ''}
      clickAction={click}
      icon={settings.icon}
    />
  );
}

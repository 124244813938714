import React from 'react';
import {
  CheckedIcon,
  CheckRadio,
  UnCheckedIcon,
  UnCheckRadio,
  UnCheckedIconDis,
  UnCheckRadioDis,
} from '../../../assets';
import './styles.css';

export default function CheckBox({
  state = false,
  trigger = (val) => {},
  label,
  extStyles,
  radio = false,
  disabled = false,
}) {
  return (
    <div
      className={`CheckBox-container${radio ? '-radio' : ''}`}
      style={extStyles}
      onClick={() => !disabled && trigger(!state)}
    >
      <img
        src={
          radio
            ? disabled
              ? UnCheckRadioDis
              : state
              ? CheckRadio
              : UnCheckRadio
            : disabled
            ? UnCheckedIconDis
            : state
            ? CheckedIcon
            : UnCheckedIcon
        }
        className="CheckBox-img"
        alt="checkbox"
      />
      <span className="font-l1">{label}</span>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { ErrorBoundary, GenButton } from '../../..';
import ExcelPreview from '../../excelPreview';
import GenericPopup from '../../genPopup';

import './styles.css';

// * dummy data for testing
const dummyData = [
  {
    singleFields: [
      { param: 'Family ID', value: '2090' },
      { param: 'Family Name', value: 'Stock Alpha' },
      { param: 'Family Type', value: 'Family 1' },
      { param: 'Give up Enabled', value: 'Yes' },
      { param: 'Dealer 1D', value: '98990' },
      { param: 'Active', value: 'Yes' },
    ],
    table: {
      title: [
        'Entity ID',
        'Entity Name',
        'Some Name',
        'Entity Type',
        'Exchange',
        'PAN',
      ],
      entries: [
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
      ],
    },
    remarks:
      'Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark Some Remark ',
  },
  {
    singleFields: [
      { param: 'Family ID', value: '2090' },
      { param: 'Family Name', value: 'Stock Alpha' },
      { param: 'Family Type', value: 'Family 1' },
      { param: 'Give up Enabled', value: 'Yes' },
      { param: 'Dealer 1D', value: '98990' },
      { param: 'Active', value: 'Yes' },
    ],
    table: {
      title: [
        'Entity ID',
        'Entity Name',
        'Some Name',
        'Entity Type',
        'Exchange',
        'PAN',
      ],
      entries: [
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
      ],
    },
    remarks: 'Some Remark',
  },
  {
    singleFields: [
      { param: 'Family ID', value: '2090' },
      { param: 'Family Name', value: 'Stock Alpha' },
      { param: 'Family Type', value: 'Family 1' },
      { param: 'Give up Enabled', value: 'Yes' },
      { param: 'Dealer 1D', value: '98990' },
      { param: 'Active', value: 'Yes' },
    ],
    table: {
      title: [
        'Entity ID',
        'Entity Name',
        'Some Name',
        'Entity Type',
        'Exchange',
        'PAN',
      ],
      entries: [
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
      ],
    },
    remarks: 'Some Remark',
  },
  {
    singleFields: [
      { param: 'Family ID', value: '2090' },
      { param: 'Family Name', value: 'Stock Alpha' },
      { param: 'Family Type', value: 'Family 1' },
      { param: 'Give up Enabled', value: 'Yes' },
      { param: 'Dealer 1D', value: '98990' },
      { param: 'Active', value: 'Yes' },
    ],
    table: {
      title: [
        'Entity ID',
        'Entity Name',
        'Some Name',
        'Entity Type',
        'Exchange',
        'PAN',
      ],
      entries: [
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
        [1, 'Akshay', 'ABC', 'ET Type', 'NSE', 'CUPFE1234F'],
      ],
    },
    remarks: 'Some Remark',
  },
];

export default function ExcelPrevPopup(props) {
  const {
    state = false,
    data = [],
    type = 1,
    callBackFunction = () => {},
    previewTitle,
  } = props;

  function submit() {
    callBackFunction({ action: 'SUB', data: data[1] });
  }

  function edit() {
    callBackFunction({ action: 'EDT' });
  }

  function cancel() {
    callBackFunction({ action: 'CAN' });
  }

  return (
    <GenericPopup state={state} type={1}>
      <div className="excel-prev-1-cont">
        <ErrorBoundary>
          <ExcelPreview
            data={data && data[0]}
            type={type}
            extStyles={{
              margin: '2vh auto auto auto',
              width: '94%',
              height: '78vh',
              overflowY: 'scroll',
            }}
            previewTitle={previewTitle}
          />
        </ErrorBoundary>
        <div className="excel-prev-1-btn-group">
          <GenButton
            title="CLOSE"
            fill={0}
            extStyle={{ margin: '0 1vw 0 1vw', width: '8vw' }}
            clickAction={() => cancel()}
          />
          <GenButton
            title={
              previewTitle === 'Position Upload Preview'
                ? 'UPLOAD AGAIN'
                : 'EDIT'
            }
            clickAction={() => edit()}
            fill={0}
            extStyle={{ margin: '0 1vw 0 1vw', width: '8vw' }}
          />
          <GenButton
            title="SUBMIT"
            clickAction={() => submit()}
            fill={1}
            extStyle={{ margin: '0 1vw 0 1vw', width: '8vw' }}
          />
        </div>
      </div>
    </GenericPopup>
  );
}

import { useEffect, useState } from 'react';
import './styles.css';
import LogicalConditions from '../../../../components/strategyBuilder/create/entryCondition/Position/LogicalConditions';
import { useDispatch, useSelector } from 'react-redux';

export default function FormulaGroup({}) {
  const [dspFormula, setDispFormula] = useState([]);
  const { dataConditions, strategyTemplateForm } = useSelector(
    (state) => state.strategyBuilderReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (
        dataConditions?.analyzerColumnNames &&
        strategyTemplateForm?.analyzerCondition
      ) {
        setDispFormula(
          dataConditions?.analyzerColumnNames.map((item) => {
            return {
              label: item.displayName,
              value: strategyTemplateForm?.analyzerCondition[item.key],
              key: item.key,
            };
          })
        );
      } else {
        console.log(dataConditions?.analyzerColumnNames);
        console.log(strategyTemplateForm?.analyzerCondition);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataConditions]);

  useEffect(() => {
    if (dspFormula) {
      const temp = { ...strategyTemplateForm, analyzerCondition: {} };
      for (let item of dspFormula) {
        temp.analyzerCondition[item.key] = item.value;
      }
      dispatch({ type: 'UPDATE_STRATEGY_FORM', payload: temp });
    }
  }, [dspFormula]);

  return (
    <div id="FormulaGroup-container">
      <span id="FormulaGroup-colNam" className="font-l1">
        Column Name
      </span>
      <span id="FormulaGroup-exp" className="font-l1">
        Expression
      </span>
      {dspFormula.map((item, index) => (
        <>
          <span style={{ margin: 'auto auto auto 1rem' }} className="font-l1">
            {item.label}
          </span>
          <LogicalConditions
            style={{ margin: 'auto 0 auto 1rem' }}
            value={item.value}
            setValue={(val) =>
              setDispFormula((old) =>
                old.map((oldItem, oldIndex) => {
                  if (oldIndex !== index) {
                    return oldItem;
                  } else {
                    return { ...oldItem, value: val };
                  }
                })
              )
            }
            pricingLogicFlag={false}
          />
        </>
      ))}
    </div>
  );
}

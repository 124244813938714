import { Switch } from '../../../../components';
import './styles.css';

export default function HeaderStrategyBuilder({
  title = 'Default title',
  icon,
  switchState,
  switchStateToggle = () => {},
  switchLabel = '',
  switchExtStyles = {},
  extStyles = {},
}) {
  return (
    <div className="HeaderStrategyBuilder-container" style={extStyles}>
      <img
        src={icon}
        alt="HeaderStrategyBuilder-img"
        id="HeaderStrategyBuilder-img"
      />
      <span id="HeaderStrategyBuilder-title">{title}</span>
      {switchState !== undefined && (
        <Switch
          state={switchState}
          onChange={(val) => switchStateToggle(val)}
          extStyles={{ margin: 'auto 1rem', ...switchExtStyles }}
          label={switchLabel}
        />
      )}
    </div>
  );
}

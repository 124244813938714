/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import './StrategyAnalyzer.scss';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  strategyAnalyzerSearch,
  strategyAnalyzerTable,
  updatePageNumber,
  updatePostPerPage,
  updateSearchBy,
  updateSearchString,
  updateStatusList,
} from '../../../actions/strategyAnalyzerAction';
import UiSelector from '../../sharedComponents/Dropdowns';
import Pen from '../../../assets/img/pen_blue.svg';
import EditPen from '../../../assets/img/Group 162767.svg';
import Switch from '../../../assets/img/switch_off.svg';
import squareDisabled from '../../../assets/img/sqwhite.svg';
import CheckBoxBar from './CheckBoxBar/CheckBoxBar';
import Search from '../../../assets/img/icon-search.png';
import {
  strategyAnalyzerEdit,
  sqOffDetails,
} from '../../../actions/strategyAnalyzerAction';
import ActionButtons from './ActionButtons/ActionButtons';
import { configBools, status } from '../../../config/apiConfig';
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import filter from '../../../assets/img/filter.svg';
import { webSocketUrl } from '../../../config/apiConfig';
import { TableEntries } from '../../sharedComponents/TableEntries/TableEntries';
import { handleEnterWithCallbackAndValue } from '../../sharedComponents/keyPressEventHandler';
import { ruleOBReqTypeEnum } from '../../../utils/constant';
import { SearchFunction } from './helperFunctions/supportColumns';
import {
  CheckBox,
  FeedbackPopup,
  GenButton,
  GenToolTip,
  TableGenerator,
} from '../..';
import { TableSettings } from '../../sharedComponents/customComponent';
import { ColumnArrangeIcon, ExcelIcon } from '../../../assets';
import { TableOrderChangeActions } from '../../../reducers/table_order_reducer';
import {
  TableDataCell,
  TableHeadText,
  TableHeader,
  TableRow,
} from '../../sharedComponents/GenTable/tableStyledComps';
import styled from 'styled-components';
import { MakeRequest } from '../../../utils';

const InfoText = styled.span`
  font-family: var(--def-font);
  font-size: var(--font-size);
`;

const TextCont = styled.div`
  display: flex;
  flex-direction: column;
`;

let W3CWebSocket = require('websocket').w3cwebsocket;

const StrategyAnalyzer = ({ analyzereRef, selectBoxRef, editAllowed }) => {
  const {
    strategyAnalyzerReducer: {
      strategyTable,
      strategyState,
      strategyTableParam,
      strategyAnalyzerButtonLoader,
      sortingType,
      sortColumn,
      searchBy,
      searchString,
    },
  } = useSelector((state) => state);

  const { strategy_analyzer: tableOrder } = useSelector(
    (state) => state.TableOrderReducer
  );

  const [master_state, set_master_state] = useState({
    post_per_page: strategyTableParam.postPerPage,
    search_by: { id: 'STRATEGY_NAME', name: 'Strategy Name' },
    popup_message: '',
    toaster: { state: false, message: '' },
  });

  const [table_settings, set_table_settings] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [{ label: 'Reorder / Hide & Unhide', id: 20 }],
        },
      ],
    },
  ]);

  const apply_new_font = useCallback((val, extStyles = {}) => {
    return (
      <span className="font-l1" style={extStyles}>
        {val}
      </span>
    );
  }, []);

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    switch (temp_index[0]) {
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          let tempColumns = [...table_settings];
          tempColumns.push({ type: 'COL_RE' });
          set_table_settings(tempColumns);
        }
        break;
      case 'B':
        const tempColumn = [...table_settings];
        tempColumn.pop();
        set_table_settings(tempColumn);
        break;
      default:
        break;
    }
  }

  const master_state_updater = useCallback(
    (key, value) => {
      const actions_available = [
        'POST_PER_PAGE',
        'SEARCH_BY',
        'POPUP_MESSAGE',
        'TOASTER_STATE',
        'TOASTER_MSG',
        'TOASTER',
      ];
      if (!actions_available.includes(key)) {
        console.error('Action for MASTER_STATE not supported');
      }
      switch (key) {
        case 'POST_PER_PAGE':
          set_master_state({ ...master_state, post_per_page: value });
          break;
        case 'SEARCH_BY':
          set_master_state({ ...master_state, search_by: value });
          break;
        case 'POPUP_MESSAGE':
          set_master_state({ ...master_state, popup_message: value });
          break;
        case 'TOASTER_STATE':
          set_master_state({
            ...master_state,
            toaster: { ...master_state.toaster, state: value },
          });
          break;
        case 'TOASTER_MSG':
          set_master_state({
            ...master_state,
            toaster: { ...master_state.toaster, message: value },
          });
          break;
        case 'TOASTER':
          set_master_state({
            ...master_state,
            toaster: { ...value },
          });
          break;
        default:
          break;
      }
    },
    [master_state]
  );

  const static_data = useMemo(() => {
    return {
      show_posts: [5, 10, 20, 30, 100, 200],
      plShow: true,
      grossShow: true,
    };
  }, []);

  const [liveData, setLiveData] = useState();
  const [selectedId, setSelectedId] = useState([]);
  const [pausePop, setPausePop] = useState(false);
  const [buttonReq, setButtonReq] = useState('');
  const [selectedSqOff, setSelectedSqOff] = useState([]);

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const [strategyData, setStrategyData] = useState([]);
  const [userShow, setUserShow] = useState([...strategyData]);
  const [currentPosts, setCurrentPosts] = useState([...setCurrentPostfun()]);
  const [expand, setExpand] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dropdownRef = useRef(null);

  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [rowIndex, setRowIndex] = useState(-1);

  const userId = useMemo(() => localStorage.getItem('customerId'), []);
  const [tradeModTog, setTradeModTog] = useState(false);

  function openWindow(windowType, id, strategyTemplateId) {
    switch (windowType) {
      case 'editStrategy':
        window.open(
          `/window/edit-strategy/${window.btoa(id)}/${window.btoa(
            strategyTemplateId
          )}`,
          '_blank',
          'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
        );
        dispatch(strategyAnalyzerEdit(id));
        break;

      case 'tradeModif':
        window.open(
          `/window/trade-modify-strategy/${window.btoa(id)}/${window.btoa(
            strategyTemplateId
          )}`,
          '_blank',
          'top=100, left=100, width=800, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
        );
        dispatch(strategyAnalyzerEdit(id));
        break;

      default:
        break;
    }
  }

  const handleRuleOpenOrder = (id, accId = 0, ruleId = 0, type = 0) => {
    window.open(
      `/window/Order/OrderBookDrilldown/${window.btoa(type)}/${window.btoa(
        id
      )}?accId=${accId}&ruleId=${ruleId}`,
      '_blank',
      'top=100, left=100, width=1857, height=600, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  const sqOffHandler = (params) => {
    dispatch(
      sqOffDetails({
        id: selectedId.length > 0 ? selectedId : [params.listId],
        name: [params.data.strategyName],
      })
    );
    localStorage.setItem(
      'forceSquareOff',
      JSON.stringify({
        id: selectedId.length > 0 ? selectedId : [params.listId],
        name: [{ id: params.data.id, name: params.data.strategyName }],
      })
    );

    window.open(
      `/window/squareOff`,
      '_blank',
      'top=100, left=100, width=800, height=325, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  const [statusData, setStatusData] = useState([
    'ACTIVE',
    'PAUSED',
    'COMPLETED',
    'CLOSED',
    'IN_PROCESS',
    'TRADE_MODIFY_ERROR',
    'TRADE_MODIFY_SUCCESS',
    'EA_TRADE_MODIFY_SUCCESSFUL',
    'ODIN_TRADE_MODIFY_IN_PROCESS',
    'ODIN_TRADE_MODIFY_SUCCESS',
  ]);

  useEffect(() => {
    localStorage.removeItem('forceSquareOff');
    TableOrderChangeActions.set_SA_initial_data(dispatch);
  }, []);

  const handleStatusData = (e, item) => {
    let setObj = status.find((el) => el.id === item.id);
    let checked = e.target.checked;
    if (checked) {
      if (item.label === 'IN PROCESS') {
        let value =
          'RESUME_IN_PROCESS,PAUSE_IN_PROCESS,SQUARE_OFF_IN_PROCESS,MODIFY_IN_PROCESS,CLOSE_IN_PROCESS,TRADE_MODIFY_IN_PROCESS';
        value = value.split(',').map((el) => el.trim());
        setStatusData([...statusData, setObj.id]);
        dispatch(
          updateStatusList([...strategyTableParam.statusList, ...value])
        );
      } else {
        setStatusData([...statusData, setObj.id]);
        dispatch(
          updateStatusList([...strategyTableParam.statusList, setObj.id])
        );
      }
    } else {
      let tempData = statusData.filter((el) => {
        return el !== setObj.id;
      });
      setStatusData(tempData);
      let tempStatus = strategyTableParam.statusList.filter((el) => {
        return !el.includes(setObj.id);
      });
      dispatch(updateStatusList(tempStatus));
    }
    if (searchBy != '' && searchString != '') {
      dispatch(
        strategyAnalyzerSearch({
          field: master_state.search_by.id,
          value: searchString,
          pageNumber: 1,
          entriesPerPage: strategyTableParam.postPerPage,
        })
      );
    } else {
      dispatch(strategyAnalyzerTable());
    }
  };

  useEffect(() => {
    let client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    if (client) {
      client.onopen = () => {
        for (let i = 0; i < 5; i++) {
          let count = 0;
          if (client.readyState === client.OPEN) {
            const data = {
              eventType: 'ANALYZER_SUBSCRIBE',
              payload: {
                userId: parseInt(userId),
                strategyIdList: JSON.stringify(strategyTable.strategyIdList),
              },
            };
            client.send(JSON.stringify(data));
            count++;
          } else {
            console.log('CLIENT ready state is NOT OPEN');
          }
          if (count > 0) break;
        }
      };
      client.onmessage = (data) => {
        try {
          let dataStr = JSON.parse(data.data);
          let webObj = { ...dataStr.payload };
          setLiveData(webObj);
          if (webObj.refreshFlag) {
            dispatch(strategyAnalyzerTable());
            const data = {
              eventType: 'ANALYZER_REFRESHED',
              payload: {
                userId: parseInt(userId),
              },
            };
            client.send(JSON.stringify(data));
          }
        } catch (error) {
          console.log(
            `Error in analyser subscribe request: ${JSON.stringify(error)}`
          );
        }
      };
      client.onclose = () => {};
    }
    return () => {
      client.close();
    };
  }, [strategyTable]);

  useEffect(() => {
    localStorage.setItem('header', 'strategyAnalyzer');
    return () => {
      localStorage.removeItem('forceSquareOff');
    };
  }, []);

  useEffect(() => {
    setFlag(!flag);
  }, [strategyState, strategyAnalyzerButtonLoader]);

  const handleSearchBy = useCallback((el) => {
    master_state_updater('SEARCH_BY', el);
    dispatch(updateSearchBy(el.id));
    dispatch(updateSearchString(''));
  }, []);

  const searchHandler = useCallback(
    (e) => {
      let eventData = { ...e };
      if (master_state.search_by.id === 'STRATEGY_ID') {
        eventData['target']['value'] = e.target.value.replace(/[^0-9.]/g, '');
      }
      dispatch(updateSearchString(eventData.target.value));
    },
    [master_state.search_by.id]
  );

  useEffect(() => {
    if (searchString === '') {
      dispatch(strategyAnalyzerTable());
    }
  }, [searchString]);

  let searchDropdown = [
    { id: 'STRATEGY_TEMPLATE', name: 'Strategy Template' },
    { id: 'STRATEGY_SECURITY', name: 'Strategy Security' },
    { id: 'STRATEGY_NAME', name: 'Strategy Name' },
    { id: 'STRATEGY_ID', name: 'Strategy Id' },
    { id: 'STRATEGY_ACCOUNT', name: 'Account' },
  ];

  const handleCheckbox = useCallback(
    (e, id, stratName) => {
      let selectedArr = [...selectedId];
      let nameArr = [...selectedSqOff];
      if (e.target.checked) {
        selectedArr.push(id);
        nameArr.push({ id, name: stratName });
        setSelectedId(selectedArr);
        setSelectedSqOff(nameArr);
      } else {
        let listIndex = selectedArr.indexOf(id);
        let nameIndex = nameArr.findIndex((el) => el.id === id);
        if (listIndex > -1) {
          selectedArr.splice(listIndex, 1);
          setSelectedId(selectedArr);
        }
        if (nameIndex > -1) {
          nameArr.splice(nameIndex, 1);
          setSelectedSqOff(nameArr);
        }
      }
      dispatch(sqOffDetails({ id: selectedArr, name: nameArr }));
      localStorage.setItem(
        'forceSquareOff',
        JSON.stringify({ id: selectedArr, name: nameArr })
      );
    },
    [setSelectedId, setSelectedSqOff, selectedId, selectedSqOff]
  );

  const handleCheckboxAll = useCallback(
    (e) => {
      let strategyArr = strategyTable.analyzerObject.map((el) => {
        return {
          id: el.data.id,
          name: el.data.strategyName,
        };
      });
      if (e.target.checked) {
        setSelectedId(strategyTable.strategyIdList);
        setSelectedSqOff(strategyArr);
        dispatch(
          sqOffDetails({ id: strategyTable.strategyIdList, name: strategyArr })
        );
        localStorage.setItem(
          'forceSquareOff',
          JSON.stringify({
            id: strategyTable.strategyIdList,
            name: strategyArr,
          })
        );
      } else {
        setSelectedId([]);
        setSelectedSqOff([]);
        dispatch(sqOffDetails({ id: [], name: [] }));
        localStorage.setItem(
          'forceSquareOff',
          JSON.stringify({ id: [], name: [] })
        );
      }
      setSelectedSqOff(strategyArr);
    },
    [
      setSelectedId,
      setSelectedSqOff,
      strategyTable.analyzerObject,
      setSelectedId,
    ]
  );

  function prepareAccountId(params, indi) {
    let ruleList = [];
    let {
      data: { legData = [] },
    } = params;
    ruleList = legData
      .filter((item, index) => (expand && rowIndex === indi ? true : index < 2))
      .map((data) => {
        if (data.accountId && data.entityDisplayId) {
          if (data.entityDisplayId.length > 15) {
            return (
              <GenToolTip
                tooltip={data.entityDisplayId}
                containerRef={analyzereRef}
              >
                <InfoText>
                  {apply_new_font(
                    data.accountId
                      ? `${data.entityDisplayId.slice(0, 15)}...`
                      : 'NA',
                    {
                      whiteSpace: 'nowrap',
                    }
                  )}
                </InfoText>
              </GenToolTip>
            );
          } else {
            return (
              <InfoText>
                {apply_new_font(data.accountId ? data.entityDisplayId : 'NA', {
                  whiteSpace: 'nowrap',
                })}
              </InfoText>
            );
          }
        } else {
          return (
            <InfoText>
              {apply_new_font(data.accountId ? data.entityDisplayId : 'NA', {
                whiteSpace: 'nowrap',
              })}
            </InfoText>
          );
        }
      });
    return <TextCont>{ruleList.map((item) => item)}</TextCont>;
  }

  function getTableColumns() {
    return [
      {
        table_sort_id: 'id',
        className: 'border-sa',
        lebel: <TableHeadText>ID</TableHeadText>,
        value: 'id',
        renderHtml: (params, index, totalPosts) =>
          apply_new_font(params.data.id),
      },
      {
        table_sort_id: 'strategyName',
        lebel: <TableHeadText>Strategy</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) =>
          apply_new_font(params.data.strategyName, {
            color: '#0078d7',
            whiteSpace: 'nowrap',
          }),
      },
      {
        table_sort_id: 'numberOfLegs',
        lebel: <TableHeadText>#Legs</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return (
            <>
              {apply_new_font(params.data?.numberOfLegs)}
              {getExpandColl(params, index)}
            </>
          );
        },
      },
      {
        table_sort_id: 'entityDisplayId',
        lebel: <TableHeadText>Acc. ID</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, indi, totalPosts) => {
          return prepareAccountId(params, indi);
        },
      },
      {
        table_sort_id: 'security',
        lebel: <TableHeadText>Security</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, indi, totalPosts) => {
          let ruleList = [];
          let legsData = (params && params.data && params.data.legData) || [];
          ruleList = legsData
            .filter((item, index) => {
              if (expand && rowIndex == indi) {
                return true;
              } else {
                return index < 2;
              }
            })
            .map((data) => {
              return (
                <InfoText>
                  {apply_new_font(data.security, { whiteSpace: 'nowrap' })}
                </InfoText>
              );
            });

          return <TextCont>{ruleList.map((item) => item)}</TextCont>;
        },
      },
      {
        table_sort_id: 'openOrders',
        lebel: <TableHeadText>Open Ord.</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          let newObj = {};
          let indexArr = [];
          let finalArr = [];
          if (
            params.legData &&
            params.legData.legData &&
            Object.keys(params.legData.legData).length
          ) {
            newObj = params.legData.legData;
            for (let i = 0; i < Object.keys(newObj).length; i++) {
              let innerIndex = Object.keys(params.legData.legData)[i];
              indexArr.push(Object.keys(params.legData.legData)[i]);
              finalArr.push(params.legData.legData[innerIndex]);
            }
          }

          const openRender = (params) => {
            let ruleList = [];
            if (params && params.data && params.data.legData) {
              let legsData = params.data?.legData;
              if (legsData.length > 2 && rowIndex == index && expand) {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    return (
                      <a
                        onClick={(event) => {
                          let securityId =
                            params.data.legData[index].securityId;
                          let accountId = params.data.legData[index].accountId;
                          let ruleId = params.data.id;

                          handleRuleOpenOrder(
                            securityId,
                            accountId,
                            ruleId,
                            ruleOBReqTypeEnum.OPEN_ORDERS
                          );
                        }}
                        className="expandableList"
                      >
                        <li
                          style={{ color: 'blue', textDecoration: 'underline' }}
                          className="expandableList"
                        >
                          {data?.openOrders}
                        </li>
                      </a>
                    );
                  })
                );
              } else if (legsData.length > 2) {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    if (index < 2) {
                      return (
                        <a
                          onClick={(event) => {
                            let securityId =
                              params.data.legData[index].securityId;
                            let accountId =
                              params.data.legData[index].accountId;
                            let ruleId = params.data.id;

                            handleRuleOpenOrder(
                              securityId,
                              accountId,
                              ruleId,
                              ruleOBReqTypeEnum.OPEN_ORDERS
                            );
                          }}
                          className="expandableList"
                        >
                          <li
                            style={{
                              color: 'blue',
                              textDecoration: 'underline',
                            }}
                            className="expandableList"
                          >
                            {data?.openOrders}
                          </li>
                        </a>
                      );
                    }
                  })
                );
              } else {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    let securityId = params.data.legData[index].securityId;
                    let accountId = params.data.legData[index].accountId;
                    let ruleId = params.data.id;
                    return (
                      <a
                        onClick={(event) => {
                          handleRuleOpenOrder(
                            securityId,
                            accountId,
                            ruleId,
                            ruleOBReqTypeEnum.OPEN_ORDERS
                          );
                        }}
                        className="expandableList"
                        tabIndex="0"
                        onKeyPress={(e) =>
                          handleEnterWithCallbackAndValue(
                            e,
                            (securityId, accountId, ruleId) => {
                              handleRuleOpenOrder(
                                securityId,
                                accountId,
                                ruleId,
                                ruleOBReqTypeEnum.OPEN_ORDERS
                              );
                            },
                            [securityId, accountId, ruleId]
                          )
                        }
                      >
                        <li
                          style={{ color: 'blue', textDecoration: 'underline' }}
                          className="expandableList"
                        >
                          {data?.openOrders}
                        </li>
                      </a>
                    );
                  })
                );
              }
            }
            return <>{ruleList}</>;
          };
          return <ul>{openRender(params)}</ul>;
        },
      },
      {
        table_sort_id: 'tradedQty',
        lebel: <TableHeadText>Traded Pos.</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          let newObj = {};
          let indexArr = [];
          let finalArr = [];
          if (
            params.legData &&
            params.legData.legData &&
            Object.keys(params.legData.legData).length
          ) {
            newObj = params.legData.legData;

            for (let i = 0; i < Object.keys(newObj).length; i++) {
              let innerIndex = Object.keys(params.legData.legData)[i];
              indexArr.push(Object.keys(params.legData.legData)[i]);
              finalArr.push(params.legData.legData[innerIndex]);
            }
          }
          const tradedRender = (params) => {
            let ruleList = [];
            if (params && params.data && params.data.legData) {
              let legsData = params.data.legData;
              if (legsData.length > 2 && rowIndex == index && expand) {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    return (
                      <a
                        onClick={(event) => {
                          let securityId =
                            params.data.legData[index].securityId;
                          let accountId = params.data.legData[index].accountId;
                          let ruleId = params.data.id;

                          handleRuleOpenOrder(
                            securityId,
                            accountId,
                            ruleId,
                            ruleOBReqTypeEnum.TRADES
                          );
                        }}
                        className="expandableList strategy_analyzer_traded_qty"
                        tabIndex="0"
                      >
                        <li
                          style={{ color: 'blue', textDecoration: 'underline' }}
                          className="expandableList"
                        >
                          {data?.tradedQty}
                        </li>
                      </a>
                    );
                  })
                );
              } else if (legsData.length > 2) {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    if (index < 2) {
                      return (
                        <a
                          onClick={(event) => {
                            let securityId =
                              params.data.legData[index].securityId;
                            let accountId =
                              params.data.legData[index].accountId;
                            let ruleId = params.data.id;

                            handleRuleOpenOrder(
                              securityId,
                              accountId,
                              ruleId,
                              ruleOBReqTypeEnum.TRADES
                            );
                          }}
                          className="expandableList strategy_analyzer_traded_qty"
                          tabIndex="0"
                        >
                          <li
                            style={{
                              color: 'blue',
                              textDecoration: 'underline',
                            }}
                            className="expandableList"
                          >
                            {data?.tradedQty}
                          </li>
                        </a>
                      );
                    }
                  })
                );
              } else {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    let securityId = params.data.legData[index].securityId;
                    let accountId = params.data.legData[index].accountId;
                    let ruleId = params.data.id;

                    return (
                      <a
                        onClick={(event) => {
                          handleRuleOpenOrder(
                            securityId,
                            accountId,
                            ruleId,
                            ruleOBReqTypeEnum.TRADES
                          );
                        }}
                        className="expandableList strategy_analyzer_traded_qty"
                        tabIndex="0"
                        onKeyPress={(e) =>
                          handleEnterWithCallbackAndValue(
                            e,
                            (securityId, accountId, ruleId) => {
                              handleRuleOpenOrder(
                                securityId,
                                accountId,
                                ruleId,
                                ruleOBReqTypeEnum.TRADES
                              );
                            },
                            [securityId, accountId, ruleId]
                          )
                        }
                      >
                        <li
                          style={{ color: 'blue', textDecoration: 'underline' }}
                          className="expandableList"
                        >
                          {data?.tradedQty}
                        </li>
                      </a>
                    );
                  })
                );
              }
            }
            return <>{ruleList}</>;
          };
          return <ul>{tradedRender(params)}</ul>;
        },
      },
      {
        table_sort_id: 'pendingQty',
        className: 'border-sa',
        lebel: <TableHeadText>Pending Qty.</TableHeadText>,
        renderHtml: (params, index, totalPosts) => {
          let newObj = {};
          let indexArr = [];
          let finalArr = [];
          if (
            params.legData &&
            params.legData.legData &&
            Object.keys(params.legData.legData).length
          ) {
            newObj = params.legData.legData;

            for (let i = 0; i < Object.keys(newObj).length; i++) {
              let innerIndex = Object.keys(params.legData.legData)[i];
              indexArr.push(Object.keys(params.legData.legData)[i]);
              finalArr.push(params.legData.legData[innerIndex]);
            }
          }
          const pendingRender = (params) => {
            let ruleList = [];
            if (params && params.data && params.data?.legData) {
              let legsData = params.data?.legData;
              if (legsData.length > 2 && rowIndex == index && expand) {
                finalArr.forEach((data1, index) => {
                  const data2 = legsData[index];
                  ruleList.push(
                    <li>
                      {isNaN(
                        Math.abs(data2.totalQty) - Math.abs(data1?.tradedQty)
                      )
                        ? 'NA'
                        : Math.abs(data2.totalQty) - Math.abs(data1?.tradedQty)}
                    </li>
                  );
                });
              } else if (legsData.length > 2) {
                finalArr.forEach((data1, index) => {
                  const data2 = legsData[index];
                  if (index < 2) {
                    ruleList.push(
                      <li>
                        {isNaN(
                          Math.abs(data2.totalQty) - Math.abs(data1?.tradedQty)
                        )
                          ? 'NA'
                          : Math.abs(data2.totalQty) -
                            Math.abs(data1?.tradedQty)}
                      </li>
                    );
                  }
                });
              } else {
                finalArr.forEach((data1, index) => {
                  const data2 = legsData[index];
                  ruleList.push(
                    <li>
                      {isNaN(
                        Math.abs(data2.totalQty) - Math.abs(data1?.tradedQty)
                      )
                        ? 'NA'
                        : Math.abs(data2.totalQty) - Math.abs(data1?.tradedQty)}
                    </li>
                  );
                });
              }
            }
            return <>{ruleList}</>;
          };
          return <ul>{pendingRender(params)}</ul>;
        },
      },
      {
        table_sort_id: 'totalQty',
        lebel: <TableHeadText>Total</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          const totalRender = (params) => {
            let ruleList = [];
            if (params && params.data && params.data?.legData) {
              let legsData = params.data?.legData;
              if (legsData.length > 2 && rowIndex == index && expand) {
                ruleList.push(
                  legsData?.map((data, index) => {
                    return <li>{data.totalQty}</li>;
                  })
                );
              } else if (legsData.length > 2) {
                ruleList.push(
                  legsData?.map((data, index) => {
                    if (index < 2) {
                      return <li>{data.totalQty}</li>;
                    }
                  })
                );
              } else {
                ruleList.push(
                  legsData?.map((data, index) => {
                    return <li>{data.totalQty}</li>;
                  })
                );
              }
            }
            return <>{ruleList}</>;
          };
          return <ul>{totalRender(params)}</ul>;
        },
      },
      {
        table_sort_id: 'status',
        lebel: (
          <div
            id="list1"
            className="dropdown-check-list custom-dropdwon  select-check-box-custom dropdown-toggle"
            onClick={() => {
              setIsActive(true);
            }}
          >
            <UiSelector
              multiple
              listData={
                status.length > 0 &&
                status.map((i) => {
                  return {
                    id: i.id,
                    label: i.name,
                  };
                })
              }
              handlerFunction={handleStatusData}
              drop={dropdownRef}
              isActive={isActive}
              selectIds={statusData}
              predefineLabel={<TableHeadText>Status</TableHeadText>}
            />
            <img src={filter} />
          </div>
        ),
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params.legData?.status?.status, {
            color:
              params.legData?.status?.status === 'PAUSED'
                ? '#800000'
                : '#0078d7',
          });
        },
      },
      {
        table_sort_id: 'pause',
        lebel: <TableHeadText>Action</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return (
            <div className="sa-action-container">
              <ActionButtons
                allowed={editAllowed}
                label="PAUSE/RESUME"
                enablePause={params?.legData?.status?.enablePause}
                enableResume={params?.legData?.status?.enableResume}
                listId={params.listId}
                enableClose={params?.legData?.status?.enableClose}
                strategyName={params.data.strategyName}
                dataId={params.data.id}
              />
              {tradeModTog ? (
                params.legData?.status?.enableTradeModify ? (
                  <span
                    className="pill-container"
                    onClick={async (e) => {
                      try {
                        const check = await MakeRequest(
                          `/strategy/editWindowInstance/${params.data.strategyTemplateId}`
                        ).get();
                        editAllowed &&
                          openWindow(
                            'tradeModif',
                            params.listId,
                            params.data.strategyTemplateId
                          );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <img src={Pen} className=".pill-icon" tabIndex="0" alt="" />
                  </span>
                ) : (
                  <span>
                    <img
                      src={EditPen}
                      alt=""
                      style={{ height: '2.8vh', width: '2.8vh' }}
                    />
                  </span>
                )
              ) : params.legData?.status?.enableModify ? (
                <span
                  onClick={async (e) => {
                    try {
                      const check = await MakeRequest(
                        `/strategy/editWindowInstance/${params.listId}`
                      ).get();

                      if (check.data.data.valid) {
                        return openWindow(
                          'editStrategy',
                          params.listId,
                          params.data.strategyTemplateId
                        );
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <img
                    src={Pen}
                    className="cursor-pointer"
                    style={{ height: '2.8vh', width: '2.8vh' }}
                    alt=""
                    onKeyPress={(event) =>
                      handleEnterWithCallbackAndValue(
                        event,
                        async (e) => {
                          try {
                            const check = await MakeRequest(
                              `/strategy/editWindowInstance/${params.listId}`
                            ).get();

                            if (check.data.data.valid) {
                              return openWindow(
                                'editStrategy',
                                params.listId,
                                params.data.strategyTemplateId
                              );
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        },
                        event
                      )
                    }
                  />
                </span>
              ) : (
                <span>
                  <img
                    src={EditPen}
                    alt=""
                    style={{ height: '2.8vh', width: '2.8vh' }}
                  />
                </span>
              )}
              <ActionButtons
                label="CLOSE"
                enablePause={params?.legData?.status?.enablePause}
                enableResume={params?.legData?.status?.enableResume}
                listId={params.listId}
                enableClose={params?.legData?.status?.enableClose}
                strategyName={params.data.strategyName}
                dataId={params.data.id}
                allowed={editAllowed}
              />
            </div>
          );
        },
      },
      configBools.enableSquareOffInAnalyzerTable
        ? {
            table_sort_id: 'sq-off',
            lebel: <TableHeadText>Sq. Off</TableHeadText>,
            className: 'border-sa',
            renderHtml: (params, index, totalPosts) => {
              return (
                <>
                  {params.legData?.status?.enableSquareOff ? (
                    <span
                      className="cursor-pointer"
                      onClick={() => sqOffHandler(params)}
                    >
                      <img
                        src={Switch}
                        tabIndex="0"
                        style={{ height: '2.8vh', width: '2.8vh' }}
                        alt="Switch-icon"
                      />
                    </span>
                  ) : (
                    <span>
                      <img
                        src={squareDisabled}
                        style={{ height: '2.8vh', width: '2.8vh' }}
                        alt="square-disabled"
                      />
                    </span>
                  )}
                </>
              );
            },
          }
        : null,
      {
        table_sort_id: 'spreadDesired',
        lebel: <TableHeadText>Desired Sp.</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params.legData?.desiredSpread);
        },
      },
      {
        table_sort_id: 'spreadActual',
        lebel: <TableHeadText>Actual Sp.</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params.legData?.actualSpread);
        },
      },
      {
        table_sort_id: 'spreadCaptured',
        lebel: <TableHeadText>Captured Sp.</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params.legData?.capturedSpread);
        },
      },
      {
        table_sort_id: 'pnl',
        lebel: <TableHeadText>PnL</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          let newObj = {};
          let indexArr = [];
          let finalArr = [];
          if (
            params.legData &&
            params.legData.legData &&
            Object.keys(params.legData.legData).length
          ) {
            newObj = params.legData.legData;

            for (let i = 0; i < Object.keys(newObj).length; i++) {
              let innerIndex = Object.keys(params.legData.legData)[i];
              indexArr.push(Object.keys(params.legData.legData)[i]);
              finalArr.push(params.legData.legData[innerIndex]);
            }
          }
          const pnlRender = (params) => {
            let ruleList = [];
            if (params && params.data && params.data.legData) {
              let legsData = params.data.legData;
              if (legsData.length > 2 && rowIndex == index && expand) {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    return <InfoText>{data?.pnl}</InfoText>;
                  })
                );
              } else if (legsData.length > 2) {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    if (index < 2) {
                      return <InfoText>{data?.pnl}</InfoText>;
                    }
                  })
                );
              } else {
                ruleList.push(
                  finalArr?.map((data, index) => {
                    return <InfoText>{data?.pnl}</InfoText>;
                  })
                );
              }
            }
            return <>{ruleList}</>;
          };
          return <TextCont>{pnlRender(params)}</TextCont>;
        },
      },
      {
        table_sort_id: 'pendingQty',
        lebel: <TableHeadText>Pending Qty.</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.data?.pendingQty);
        },
      },
      {
        table_sort_id: 'accountName',
        lebel: <TableHeadText>Acc. Name</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, indi, totalPosts) => {
          let ruleList = [];
          let legsData = (params && params.data && params.data.legData) || [];
          ruleList = legsData
            .filter((item, index) => {
              if (expand && rowIndex === indi) {
                return true;
              } else {
                return index < 2;
              }
            })
            .map((data) => {
              return (
                <GenToolTip
                  tooltip={data.accountName}
                  containerRef={analyzereRef}
                >
                  <InfoText>
                    {apply_new_font(
                      data.accountName
                        ? data.accountName.length > 15
                          ? data.accountName.slice(0, 15)
                          : data.accountName
                        : 'NA',
                      { whiteSpace: 'nowrap' }
                    )}
                  </InfoText>
                </GenToolTip>
              );
            });
          return <TextCont>{ruleList.map((item) => item)}</TextCont>;
        },
      },
      {
        table_sort_id: 'desiredQty',
        lebel: <TableHeadText>Input Qty</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.desiredQty);
        },
      },
      {
        table_sort_id: 'executedPortfolioQty',
        lebel: <TableHeadText>Exe. Qty</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.executedPortfolioQty);
        },
      },
      {
        table_sort_id: 'marketEntryPrice',
        lebel: <TableHeadText>Buy Pr</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.marketEntryPrice);
        },
      },
      {
        table_sort_id: 'marketExitPrice',
        lebel: <TableHeadText>Sell Pr</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.marketExitPrice);
        },
      },
      {
        table_sort_id: 'desiredEntry',
        lebel: <TableHeadText>Entry</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.desiredEntry);
        },
      },
      {
        table_sort_id: 'desiredStopLossExit',
        lebel: <TableHeadText>LossExit</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.desiredStopLossExit);
        },
      },
      {
        table_sort_id: 'desiredTargetProfitExit',
        lebel: <TableHeadText>ProfitExit</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.desiredTargetProfitExit);
        },
      },
      {
        table_sort_id: 'avgExecutedEntryPrice',
        lebel: <TableHeadText>Exe. Buy Pr</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.avgExecutedEntryPrice);
        },
      },
      {
        table_sort_id: 'avgExecutedExitPrice',
        lebel: <TableHeadText>Exe.Sell Pr</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.avgExecutedExitPrice);
        },
      },
      {
        table_sort_id: 'mtm',
        lebel: <TableHeadText>MTM</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.mtm);
        },
      },
      {
        table_sort_id: 'executedSlippage',
        lebel: <TableHeadText>Slippage</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          return apply_new_font(params?.legData?.executedSlippage);
        },
      },
      {
        table_sort_id: 'symbol',
        lebel: <TableHeadText>Symbol</TableHeadText>,
        className: 'border-sa',
        renderHtml: (params, index, totalPosts) => {
          let ruleList = [];
          let legsData = (params && params.data && params.data.legData) || [];
          ruleList = legsData
            .filter((item, index) => {
              if (expand && rowIndex === index) {
                return true;
              } else {
                return index < 2;
              }
            })
            .map((data) => {
              return (
                <InfoText>
                  {apply_new_font(data?.symbol, { whiteSpace: 'nowrap' })}
                </InfoText>
              );
            });

          return <TextCont>{ruleList.map((item) => item)}</TextCont>;
        },
      },
    ];
  }

  function getExpandColl(params, index) {
    if (params.data?.legData?.length > 2) {
      return (
        <p
          className="expand-btn"
          onClick={() => {
            setRowIndex(index);
            if (rowIndex !== index) {
              setExpand(true);
            } else {
              setExpand(!expand);
            }
          }}
        >
          <u>
            {apply_new_font(
              expand && rowIndex === index ? 'Collapse' : 'Expand'
            )}
          </u>
        </p>
      );
    }
  }

  const handleShowPost = (e) => {
    if (e.target.value !== '') {
      dispatch(updatePageNumber(1));
      dispatch(updatePostPerPage(parseInt(e.target.value)));

      if (searchBy !== '' && searchString !== '') {
        dispatch(
          strategyAnalyzerSearch({
            field: searchBy,
            value: searchString,
            pageNumber: strategyTableParam.pageNumber,
            entriesPerPage: e.target.value,
          })
        );
      } else {
        dispatch(strategyAnalyzerTable());
      }
      setSelectedId([]);
      setSelectedSqOff([]);
      localStorage.setItem(
        'forceSquareOff',
        JSON.stringify({ id: [], name: [] })
      );
    }
  };

  useEffect(() => {
    if (strategyTable && strategyTable.analyzerObject) {
      let tableData = strategyTable.analyzerObject;
      let strategyArray = tableData.map((el, index) => {
        return {
          ...el,
          listId: strategyTable.strategyIdList[index],
        };
      });

      setStrategyData(strategyArray);
    }
  }, [strategyTable]);

  function setCurrentPostfun() {
    const indexOfLastPost =
      strategyTableParam.pageNumber * strategyTableParam.postPerPage;
    const indexOfFirstPost = indexOfLastPost - strategyTableParam.postPerPage;
    let currentPostsdata;
    if (strategyData) {
      currentPostsdata = strategyData.slice(indexOfFirstPost, indexOfLastPost);
    }
    return currentPostsdata;
  }

  const paginate = useCallback(
    (pageNumber) => {
      dispatch(updatePageNumber(pageNumber));
      if (searchBy !== '' && searchString !== '') {
        dispatch(
          strategyAnalyzerSearch({
            field: master_state.search_by,
            value: searchString,
            pageNumber: pageNumber,
            entriesPerPage: strategyTableParam.postPerPage,
          })
        );
      } else {
        dispatch(strategyAnalyzerTable());
      }
    },
    [
      searchBy,
      searchString,
      master_state.search_by,
      strategyTableParam.postPerPage,
    ]
  );

  useEffect(() => {
    const indexOfLastPost =
      strategyTableParam.pageNumber * strategyTableParam.postPerPage;
    const indexOfFirstPost = indexOfLastPost - strategyTableParam.postPerPage;
    let currentPostsData;
    if (userShow) {
      currentPostsData = [...userShow];
    }
    setCurrentPosts([...currentPostsData]);
  }, [strategyTableParam.pageNumber, userShow, strategyTableParam.postPerPage]);

  useEffect(() => {
    setUserShow([...strategyData]);
  }, [strategyData]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (strategyData && strategyData.length > 0) {
      setTableData(
        strategyData.map((el) => {
          el['legData'] = liveData ? liveData[el.listId] : [];
          return el;
        })
      );
    } else {
      setTableData([]);
    }
  }, [strategyData, liveData]);

  useEffect(() => {
    setTableData(sortByName(sortingType, tableData, sortColumn));
  }, [sortingType, sortColumn]);

  const sortByName = (type, array, key) => {
    var byName = array.slice(0);
    let stringColumn = ['status'];

    if (key) {
      if (stringColumn.indexOf(key) != -1) {
        if (type === 'desc') {
          byName.sort(function (a, b) {
            var x = a[key]?.toLowerCase();
            var y = b[key]?.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
        } else {
          byName.sort(function (a, b) {
            var x = a[key]?.toLowerCase();
            var y = b[key]?.toLowerCase();
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
      } else {
        if (type === 'desc') {
          byName.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
          });
        } else {
          byName.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
      }
    }

    return byName;
  };

  const filterTableColumn = (table_coulumn) => {
    let columns = table_coulumn.filter((el) =>
      !static_data.plShow ? el.lebel !== 'P/L' : el
    );
    table_coulumn = columns.filter((el) =>
      !static_data.grossShow ? el.lebel !== 'Gross Exposure' : el
    );
    return table_coulumn;
  };

  const fetchSearchedData = useCallback(() => {
    dispatch(updatePageNumber(1));
    dispatch(
      strategyAnalyzerSearch({
        field: master_state.search_by.id,
        value: searchString,
        pageNumber: 1,
        entriesPerPage: strategyTableParam.postPerPage,
      })
    );
  }, [master_state.search_by.id, searchString, strategyTableParam.postPerPage]);

  const messageFunc = () => {
    let strategyId = JSON.parse(localStorage.getItem('forceSquareOff'))?.name;
    return (
      <>
        <span className="button-req">Rules {buttonReq} </span>
        <p>
          With Strategy IDs:{' '}
          {strategyId &&
            strategyId.length &&
            strategyId
              .map((el) => {
                return `${el.id} - ${el.name}`;
              })
              .join(', ')}
        </p>
      </>
    );
  };

  const [table_coulumn, set_table_column] = useState({
    display: getTableColumns(),
    all: getTableColumns(),
  });

  useEffect(() => {
    if (table_coulumn.all.length > 0) {
      const newTableColumns = [];
      for (let i of tableOrder?.viewData || []) {
        newTableColumns.push(
          table_coulumn.all.filter((item) => {
            return item && item.table_sort_id === i.variableName;
          })[0]
        );
      }
      set_table_column({ ...table_coulumn, display: newTableColumns });
    }
  }, [tableOrder]);

  useEffect(() => {
    set_table_column({
      display: getTableColumns(),
      all: getTableColumns(),
    });
  }, [rowIndex, expand, isActive, editAllowed]);

  return (
    <div className="analyzer">
      <div className="analyzer-header" ref={analyzereRef}>
        <TableEntries
          role="postPerPage"
          value={strategyTableParam.postPerPage}
          postList={static_data.show_posts}
          postHandler={handleShowPost}
        />
        <Nav.Item className=" main_search">
          <SearchFunction
            search_by_name={master_state.search_by.name}
            searchDropdown={searchDropdown}
            handleSearchBy={handleSearchBy}
            searchString={searchString}
            searchHandler={searchHandler}
            setToastInfo={(val) => master_state_updater('TOASTER', val)}
            fetchSearchedData={fetchSearchedData}
            Search={Search}
          />
        </Nav.Item>
        {selectedId.length > 0 && (
          <GenButton
            fill={false}
            icon={ExcelIcon}
            title="EXPORT EXCEL"
            clickAction={() => {
              MakeRequest(
                `/excel/generate/analyzer?strategyList=${selectedId.join(',')}`
              ).download('Rules.zip');
            }}
            extStyle={{ margin: '0.5em 1em 0.5em 0' }}
          />
        )}
        <TableSettings
          menuOptions={table_settings}
          clickAction={colModifier}
          updateColCallBack={() => {}}
          extStyles={{}}
          screenName="STRATEGY_ANALYZER_TS"
          allowed={editAllowed}
        />
      </div>
      {selectedId.length > 0 ? (
        <CheckBoxBar
          setPausePop={setPausePop}
          setSelectedSqOff={setSelectedSqOff}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setButtonReq={setButtonReq}
          selectBoxRef={selectBoxRef}
          setErrorMsg={setErrorMsg}
          setPopup={() => master_state_updater('POPUP_MESSAGE', messageFunc())}
        />
      ) : null}

      <div className="row">
        <div className="col-12">
          <div style={{ overflowX: 'auto' }}>
            <StrategyAnalyzerTable
              content={tableData}
              arrangement={[
                {
                  table_sort_id: 'check-box',
                  lebel: (
                    <CheckBox
                      state={
                        strategyTable.strategyIdList?.length &&
                        selectedId?.length ===
                          strategyTable.strategyIdList?.length
                      }
                      trigger={(val) =>
                        handleCheckboxAll({ target: { checked: val } })
                      }
                      extStyles={{ margin: '0 0 0 0.6rem' }}
                    />
                  ),
                  className: 'border-sa',
                  renderHtml: (params, index, totalPosts) => (
                    <CheckBox
                      state={selectedId.includes(params.listId)}
                      trigger={(val) =>
                        handleCheckbox(
                          { target: { checked: val } },
                          params.listId,
                          params.data.strategyName
                        )
                      }
                    />
                  ),
                },
                ...filterTableColumn(table_coulumn.display),
              ]}
              paginationInfo={{
                total: Math.ceil(
                  strategyTable.totalStrategies / strategyTableParam.postPerPage
                ),
                current: strategyTableParam.pageNumber,
              }}
              onPageChange={paginate}
            />
          </div>
        </div>
      </div>
      {master_state.toaster.state ? (
        <SimpleSnackbar
          toaster={master_state.toaster.state}
          setToaster={(val) => master_state_updater('TOASTER_STATE', val)}
          message={master_state.toaster.message}
        />
      ) : null}
      <FeedbackPopup
        type="f"
        message={master_state.popup_message}
        acceptAction={() => master_state_updater('POPUP_MESSAGE', '')}
      />
    </div>
  );
};

function StrategyAnalyzerTable({
  arrangement = [],
  content = [],
  paginationInfo,
  onPageChange,
}) {
  function genContent() {
    const response = [];
    for (let contItem of content) {
      response.push(
        <TableRow>
          {arrangement.map((rowItem) => (
            <TableDataCell>{rowItem.renderHtml(contItem)}</TableDataCell>
          ))}
        </TableRow>
      );
    }
    return response;
  }

  function getHeaders() {
    return arrangement.map((item) => <TableHeader>{item.lebel}</TableHeader>);
  }

  return (
    <TableGenerator
      extStyles={{ width: '100%', margin: '1rem auto 1rem auto' }}
      heads={getHeaders()}
      content={genContent()}
      version={2}
      paginationInfo={paginationInfo}
      onPageChange={onPageChange}
    />
  );
}

export default React.memo(StrategyAnalyzer);

import { useEffect, useRef, useState } from 'react';
import { DropdownIcon } from '../../../assets';
import './styles.css';

export default function DropDown({
  id = 'testDropDown',
  value = 'Some Option',
  setValue = () => {},
  options = ['some', 'some other options', 'some more Options'],
  extClass = '',
  extStyles = {},
  disabled = false,
}) {
  const [dropDownState, setDropDownState] = useState(false);
  const [focusIndex, setFocusIndex] = useState(-1);
  const buttonRef = useRef(null);
  const dropDownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef && dropDownRef) {
        if (
          !buttonRef?.current?.contains(event.target) &&
          !dropDownRef?.current?.contains(event.target)
        ) {
          setDropDownState(false);
          setFocusIndex(-1);
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
  }, [buttonRef, dropDownRef]);

  function handleKeyDown(event) {
    const activeElement = document.activeElement.id;
    switch (event.key) {
      case 'Enter':
        if (focusIndex > 0) {
          setDropDownState(false);
          setValue(options[focusIndex]);
        } else {
          setDropDownState((old) => !old);
        }

        break;
      case 'ArrowDown':
        if (activeElement === id && !dropDownState) {
          const activeSelection = document.getElementById(
            `${id}-DropDownV2-text`
          )?.innerText;
          const newIndex = options.findIndex((val) => val === activeSelection);
          setValue(options[newIndex + 1 === options.length ? 0 : newIndex + 1]);
        }
        setFocusIndex((old) => {
          if (old < options.length - 1) {
            return old + 1;
          } else {
            return 0;
          }
        });
        event.preventDefault();
        break;
      case 'ArrowUp':
        if (activeElement === id && !dropDownState) {
          const activeSelection = document.getElementById(
            `${id}-DropDownV2-text`
          )?.innerText;
          const newIndex = options.findIndex((val) => val === activeSelection);
          setValue(
            options[newIndex - 1 === -1 ? options.length - 1 : newIndex - 1]
          );
        }
        setFocusIndex((old) => {
          if (old >= 1) {
            return old - 1;
          } else {
            return options.length - 1;
          }
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const doc = document.getElementById(`${id}-${focusIndex}`);
    if (doc) {
      doc.focus();
    }
  }, [focusIndex, id]);

  return (
    <div
      className={`DropDownV2-container ${disabled && 'disabled'} ${extClass} `}
      ref={buttonRef}
      onClick={() => !disabled && setDropDownState((old) => !old)}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      id={id}
      style={extStyles}
    >
      <span id={`${id}-DropDownV2-text`} className="font-l1 DropDownV2-text">
        {value}
      </span>
      <img className="DropDownV2-icon" src={DropdownIcon} alt="dropIcon" />
      <div
        className="DropDownV2-dropDownArea"
        ref={dropDownRef}
        style={{
          height: dropDownState ? 'fit-content' : '0rem',
          visibility: dropDownState ? 'visible' : 'hidden',
          width: buttonRef.current?.clientWidth,
        }}
      >
        {options.map((item, index) => (
          <div
            key={item}
            className={`DropDownV2-itemContainer ${
              focusIndex === index && 'focused'
            }`}
            onClick={() => !disabled && setValue(item)}
            onFocus={() => setFocusIndex(index)}
            tabIndex={-1}
            id={`${id}-${index}`}
          >
            <span className="DropDownV2-item-text font-l1">{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

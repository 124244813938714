import React, { useState, useEffect, Fragment } from 'react';
import './FamilyDashboard.scss';
import {
  singlefamilyData,
  deleteFamily,
} from '../../../../actions/familyAction';
import { useDispatch, useSelector } from 'react-redux';
import ViewFamilyModal from './ViewFamilyModal/ViewFamilyModal';
import FileUploadComponent from '../../../FileUpload/FileUpload';
import { EditSolid } from '../../../../assets';
import {
  GenButton,
  GenericPopup,
  TableGenerator,
  FeedbackPopup,
  RemarkButton,
  PillButton,
  ViewDisplayTable,
} from '../../../../components';
import { MakeRequest } from '../../../../utils';
import CreateEditFamPopup from '../../../../viewScreen/AdminDashboard/UserMaintenance/family/createEditFamPopup';
import { getAllFamilies } from '../../../../glb_apis';
import ExcelPrevPopup from '../../../sharedComponents/customComponent/excelPrevPopup';
import UMButtonGroup from '../../../sharedComponents/customComponent/UMButtonGroup';
import { addShortcutAction } from '../../../../reducers/shortcutReducer';
import { permissionChecker } from '../../../../viewScreen/Ops/redux';
import {
  TableDataCell,
  TableRow,
} from '../../../sharedComponents/GenTable/tableStyledComps';

const FamilyDashboard = () => {
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState('view');
  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'User Maintenance/Family Dashboard',
      2,
      'Risk'
    );

    setPermission(res > 1 ? 'edit' : 'view');
  }, [userPermissions]);

  const [cevManager, setCevManager] = useState({ id: -1, state: false });
  const [viewModal, setViewModal] = useState(false);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [show, setShow] = useState(false);
  const [isSuccessUpload, setIsSuccessUpload] = useState(false);
  const [remarkPopupData, setRemarkPopup] = useState({
    state: false,
    id: -1,
    data: [],
  });

  const [feedbackpopup, setFeedbackPopup] = useState('');
  const [allFamilies, setAllFamilies] = useState([]);

  const [currentFamilies, setCurrentFamilies] = useState([]);
  const [pagesInfo, setPagesInfo] = useState(null);

  const [deletePopMessage, setDeletePopMessage] = useState({
    message: '',
    id: '',
  });
  const [famPrev, setFamPrev] = useState();
  const [famPrevFeedBack, setFamPrevFeedback] = useState('');

  // * Action can be : SUB: submit, EDT: edit, CAN: cancel
  async function callBackForPopupPrev(action = { action: 0, data: {} }) {
    switch (action.action) {
      case 'SUB':
        const res = await submitExcelprev(action.data);
        if (res) {
          setFamPrev();
          setShow(false);
          setFamPrevFeedback(res.data.data.message);
        }
        break;
      case 'EDT':
        setFamPrev();
        break;
      case 'CAN':
        setFamPrev();
        setShow(false);
        break;
      default:
        break;
    }
  }

  async function submitExcelprev(data) {
    try {
      const res = await MakeRequest('/excel/upload/familySubmit').post({
        submitData: data,
      });
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  const [viewFamily, setViewFamily] = useState({ state: false, id: -1 });

  // max entries that can be shown on one page
  const entriesOnOnePage = 7;

  useEffect(() => {
    updateFamData();
    addShortcutAction(dispatch);
  }, []);

  useEffect(() => {
    initializeCurrentPageInfo();
  }, [allFamilies]);

  useEffect(() => {
    updateFamData();
  }, [searchKey, permission]);

  async function updateFamData() {
    let temp_all_fam = await getAllFamilies();
    const rows = [];

    if (searchKey.length > 0) {
      temp_all_fam = temp_all_fam.filter(
        (item) =>
          String(item.familyName)
            .toLowerCase()
            .includes(String(searchKey).toLowerCase()) ||
          item.entities
            .join(',')
            .toLowerCase()
            .includes(String(searchKey).toLowerCase())
      );
    }

    for (let item of temp_all_fam) {
      rows.push(
        <TableRow>
          <TableDataCell>{rows.length + 1}</TableDataCell>
          <TableDataCell>{item.familyDisplayId}</TableDataCell>
          <TableDataCell>{item.familyName}</TableDataCell>
          <TableDataCell>{item.familyType}</TableDataCell>
          <TableDataCell>
            {item.dealers.join(', ').length > 15
              ? `${item.dealers.join(', ').slice(0, 15)}...`
              : item.dealers.join(', ')}
          </TableDataCell>
          <TableDataCell width="8vw">
            {item.entities.join(', ').length > 15
              ? `${item.entities.join(', ').slice(0, 15)}...`
              : item.entities.join(', ')}
          </TableDataCell>
          <TableDataCell width="10vw">{item.updatedAt}</TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={0}
              clickAction={() =>
                setViewFamily({ state: true, id: item.familyId })
              }
              extStyles={{ margin: 'auto' }}
            />
          </TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={1}
              clickAction={() => handleEditTeam(item.familyId)}
              extStyles={{ margin: 'auto' }}
              disabled={permission === 'edit' ? false : true}
            />
          </TableDataCell>
          <TableDataCell>
            <PillButton
              iconType={2}
              clickAction={() =>
                setDeletePopMessage({
                  message: `Want to delete ${item.familyName} ?`,
                  id: item.familyId,
                })
              }
              extStyles={{ margin: 'auto' }}
              disabled={permission === 'edit' ? false : true}
            />
          </TableDataCell>
          <TableDataCell>
            <RemarkButton
              title={
                item.remarks.length > 0 ? item.remarks[0].remark : 'No Remark'
              }
              onClickAction={() =>
                setRemarkPopup({ state: true, id: -1, data: item.remarks })
              }
            />
          </TableDataCell>
        </TableRow>
      );
    }

    setAllFamilies(rows);
  }

  const initializeCurrentPageInfo = () => {
    // set the current page to 1
    handlePageChange(1);
  };

  const handleViewTeam = (id) => {
    dispatch(singlefamilyData(id));
    setViewModal(true);
  };

  const handleEditTeam = (id) => {
    setCevManager({ id: id, state: true });
  };

  function remarkPopupTableGen() {
    const arr = [];
    if (remarkPopupData.data.length > 0) {
      for (let item of remarkPopupData.data) {
        arr.push(
          <tr>
            <td>{item.remarkDate}</td>
            <td>{`${item.dealerId} - ${item.dealerName}`}</td>
            <td>{item.remark}</td>
          </tr>
        );
      }
      return (
        <div style={{ height: '80vh', width: '70vw' }}>
          <TableGenerator
            heads={['Date Modified', 'ID/Name', 'Remarks']}
            content={arr}
          />
        </div>
      );
    }
  }

  const handlePageChange = (id) => {
    updatePagesInfoGivenCurrent(id);
    updateCurrentFamiliesGivenCurrent(id);
  };

  const updatePagesInfoGivenCurrent = (current) => {
    let totalPages = Math.ceil(allFamilies.length / entriesOnOnePage);
    setPagesInfo({ current: current, total: totalPages });
  };

  const updateCurrentFamiliesGivenCurrent = (current) => {
    let startIndex = entriesOnOnePage * (current - 1);
    let endIndex = Math.min(startIndex + entriesOnOnePage, allFamilies.length);
    setCurrentFamilies(allFamilies.slice(startIndex, endIndex));
  };

  return (
    <Fragment>
      <div
        className="familyTabContainer"
        style={{
          backgroundColor: '#fff',
          borderRadius: '1vh',
          overflowY: 'scroll',
        }}
      >
        <UMButtonGroup
          createOnClick={() => setCevManager({ id: -1, state: true })}
          onSearchChange={(val) => setSearchKey(val)}
          searchVal={searchKey}
          searchPlaceHolder={'Search Family or Entity Name'}
          onUploadExec={() => setShow(true)}
          onExportExec={async () => {
            await MakeRequest('/excel/generate/family').download(
              'FamilyDownload.xlsx'
            );
          }}
          extStyles={{ margin: '1vh 0 1vh 0' }}
          edit={permission === 'edit' ? true : false}
        />
        <TableGenerator
          heads={[
            'S. No.',
            'Family ID',
            'Family Name',
            'Family Type',
            'Dealers',
            'Entities',
            'Last Modified',
            'View',
            'Edit',
            'Delete',
            'Remarks',
          ]}
          content={currentFamilies}
          extStyles={{ margin: '1vh 1vw 2vh 1vw' }}
          pagesInfo={pagesInfo}
          pageChange={handlePageChange}
          className="tableContainer bodyScrollTable"
          showNoOfEntries={true}
          totalEntries={allFamilies.length}
          entriesPerPage={entriesOnOnePage}
        />
      </div>
      <FileUploadComponent
        show={show}
        setShow={setShow}
        screenType={'family'}
        setSuccessUpload={setIsSuccessUpload}
        callBackFunc={(val) => setFamPrev(val)}
      />
      {viewModal && (
        <ViewFamilyModal viewModal={viewModal} setViewModal={setViewModal} />
      )}

      <GenericPopup
        type={1}
        state={viewFamily.state}
        toggleState={() =>
          setViewFamily({
            state: false,
            id: -1,
          })
        }
      >
        <FamilyDisplay
          familyId={viewFamily.id}
          hidePopup={() =>
            setViewFamily({
              state: false,
              id: -1,
            })
          }
          handleEdit={handleEditTeam}
          editDisabled={permission === 'edit' ? false : true}
        />
      </GenericPopup>
      <GenericPopup
        type={1}
        state={remarkPopupData.state}
        toggleState={() =>
          setRemarkPopup({
            ...remarkPopupData,
            state: !remarkPopupData.state,
            data: [],
          })
        }
      >
        {remarkPopupTableGen()}
      </GenericPopup>
      <GenericPopup
        type={1}
        state={cevManager.state}
        toggleState={() => setCevManager({ id: -1, state: false })}
      >
        <CreateEditFamPopup
          editData={cevManager.id}
          closeForm={() => setCevManager({ id: -1, state: false })}
          feedback={(val) => setFeedbackPopup(val)}
        />
      </GenericPopup>
      <FeedbackPopup
        message={feedbackpopup}
        type="f"
        acceptAction={() => {
          setFeedbackPopup('');
          updateFamData();
        }}
      />
      <FeedbackPopup
        message={deletePopMessage.message}
        type="a"
        acceptAction={() => {
          dispatch(deleteFamily(deletePopMessage.id));
          setDeletePopMessage({ message: '', id: -1 });
          setFeedbackPopup('Deleted Successfully !');
          updateFamData();
        }}
        rejectAction={() => setDeletePopMessage({ message: '', id: -1 })}
      />
      <FeedbackPopup
        message={
          famPrevFeedBack
            ? `${JSON.stringify(
                famPrevFeedBack
              )}. Do you want to upload again ?`
            : ''
        }
        type="a"
        acceptAction={() => {
          setShow(true);
          setFamPrevFeedback('');
          updateFamData();
        }}
        rejectAction={() => {
          setFamPrevFeedback('');
          updateFamData();
        }}
      />
      <ExcelPrevPopup
        state={famPrev !== undefined}
        data={famPrev && famPrev.data}
        callBackFunction={callBackForPopupPrev}
        previewTitle="Preview Families"
      />
    </Fragment>
  );
};

export default FamilyDashboard;

function FamilyDisplay(props) {
  const {
    familyId = -1,
    hidePopup = () => {},
    handleEdit = () => {},
    editDisabled = false,
  } = props;
  const [famData, setFamData] = useState({
    sec1: [],
    sec2: [],
    sec3: [],
    sec4: [],
    sec5: [],
    sec6: [],
  });
  const [isHFTMode, setIsHFTMode] = useState(
    process.env.REACT_APP_IS_HFT == 'true' ? true : false
  );

  useEffect(() => {
    const fetchFamData = async () => {
      const res = await getSingleFamily(familyId);
      const temp = {
        sec1: [],
        sec2: [],
        sec3: [],
        sec4: [],
        sec5: [],
        sec6: [],
      };
      temp.sec1.push(prepareEntry('Family ID', res.familyDisplayId));
      temp.sec1.push(prepareEntry('Family Name', res.familyName));
      temp.sec1.push(prepareEntry('Family Type', res.familyType));

      if (!isHFTMode && res.dealers && Array.isArray(res.dealers)) {
        for (let dealer of res.dealers) {
          temp.sec2.push(prepareValueEntry(dealer));
        }
      }

      if (!isHFTMode)
        temp.sec3.push(prepareEntry('HFT', res.isHFT ? 'On' : 'Off'));
      temp.sec3.push(prepareEntry('Active', res.active ? 'Yes' : 'No'));
      temp.sec3.push(
        prepareEntry('Give Up Enabled', res.giveUpStatus ? 'Yes' : 'No')
      );

      for (let entity of res.entities) {
        let activeStatus = true;
        for (let subEntity of entity.subEntities) {
          activeStatus = subEntity.activeStatus;
        }

        const tempEnt = [];
        tempEnt.push(prepareEntry('Active', activeStatus ? 'Yes' : 'No'));
        tempEnt.push(prepareEntry('Entity ID', entity.entityDisplayId));
        tempEnt.push(prepareEntry('Entity Type', entity.entityType));
        tempEnt.push(prepareEntry('Exchange', entity.exchange));
        let tempSubEnt = [];
        for (let subEntity of entity.subEntities) {
          tempSubEnt.push(prepareEntry('Segment', subEntity.segment));
          tempSubEnt.push(prepareEntry('Entity Name', subEntity.entityName));
          tempSubEnt.push(prepareEntry('UCC', subEntity.ucc));
          tempSubEnt.push(prepareEntry('PAN', subEntity.pan));
          tempSubEnt.push(prepareEntry('CP Code', subEntity.cpCode));
          if (!isHFTMode)
            tempSubEnt.push(
              prepareEntry(
                'EMS',
                subEntity.ems.map((item) => item.applicationName).join(', ')
              )
            );
        }
        temp.sec5.push({ data: tempEnt, subEntity: tempSubEnt });
      }
      if (res && res.remarks && res.remarks.length) {
        res.remarks.sort((a, b) => a.remarkdId - b.remarkId);
      }
      temp.sec6.push(
        <span className="family-view-td-value">
          {res.remarks && res.remarks.length > 0 && res.remarks[0].remark}
        </span>
      );
      setFamData(temp);
    };

    fetchFamData();
  }, []);

  function prepareEntry(key, value) {
    return (
      <div className="family-view-td-cont">
        <span className="family-view-td-key">{key}</span>
        <span className="family-view-td-value">{value}</span>
      </div>
    );
  }

  function prepareValueEntry(value) {
    return (
      <div className="family-view-td-cont">
        <span className="family-view-td-value">{value}</span>
      </div>
    );
  }

  return (
    <div className="familyDashboard-view">
      <div className="familyDashboard-top-bar">
        <span className="familyDashboard-text">View Family</span>
        <GenButton
          title="Edit"
          icon={EditSolid}
          fill={false}
          clickAction={() => {
            hidePopup();
            handleEdit(familyId);
          }}
          extStyle={{ marginRight: '1vw' }}
          disabled={editDisabled}
        />
      </div>
      <div className="familyDashboard-view-body">
        <ViewDisplayTable
          title="Family Details"
          extStyles={{ margin: '1.5vh 2vh' }}
          columns={3}
          content={famData.sec1}
        />

        <ViewDisplayTable
          title="Status"
          extStyles={{ margin: '3vh 2vh' }}
          columns={3}
          content={famData.sec3}
        />

        {!isHFTMode ? (
          <ViewDisplayTable
            title="Mapped Dealers"
            extStyles={{ margin: '3vh 2vh' }}
            columns={6}
            content={famData.sec2}
          />
        ) : null}

        {famData.sec5.map((item, index) => (
          <ViewDisplayTable
            title={`Entity ${index + 1}`}
            extStyles={{ margin: '3vh 2vh' }}
            columns={6}
            content={item.data}
            subRowsContent={item.subEntity}
            subColumns={!isHFTMode ? 6 : 4}
          />
        ))}
        <ViewDisplayTable
          title="Remarks"
          extStyles={{ margin: '3vh 2vh' }}
          columns={1}
          content={famData.sec6}
        />
      </div>
      <div className="familyDashboard-view-bottomBar">
        <GenButton
          title="Close"
          fill={false}
          clickAction={() => hidePopup()}
          extStyle={{ margin: 'auto 1vw auto auto' }}
        />
      </div>
    </div>
  );
}

async function getSingleFamily(id) {
  try {
    const result = await MakeRequest(
      `/family/singleFamily?familyId=${id}`
    ).get();
    return result.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

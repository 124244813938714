//load table for genral message and template approval
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';
import {
  builderObject,
  modifyTemplate,
} from '../../../actions/strategyBuilderAction';
import { useDispatch, useSelector } from 'react-redux';
import ReviewStrategy from '../../strategyBuilder/strategyView/viewData/reviewStrategy/ReviewStrategy';
import {
  approveRejectButton,
  saveTemplateId,
  selectedAlerts,
  setGroupId,
} from '../../../actions/alertAction';
import Styled from 'styled-components';
import '../../../global.css';
import { GenButton } from '../../../components';
import { MakeRequest } from '../../../utils';

const AlertTHead = Styled.th`
  font-family: var(--def-font) !important;
  color: #3d97de !important;
  background-color: #f4f4f4;
  font-size: 1rem !important;
  border: 1px solid #ebebeb !important;
`;

const AlertTData = Styled.td`
border: 1px solid ${(props) =>
  props.$border ? '#ebebeb' : '#00000000'} !important;
font-family: var(--def-font) !important;
font-size: 0.8rem !important;
`;

const TableAlert = ({
  table_column,
  eventkeyName,
  liveData,
  tabKey,
  checkedAlert,
  setCheckedAlert,
  tableHeight,
  setIsSuccessDelete = () => {},
}) => {
  const {
    alertReducer: { selectedIds, isApproveReject },
  } = useSelector((state) => state);

  const [alertModal, setAlertModal] = useState(false);
  const [addRuleIdForClearing] = useState([]);
  const [tooltipMessage, setTooltipMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [templateId, setTemplateId] = useState(-1);
  const dispatch = useDispatch();

  const handlePopup = async (id) => {
    const {
      data: { data: response },
    } = await MakeRequest(`/strategyTemplate/approvalReturn/${id}`).get();
    setTemplateId(id);

    dispatch(modifyTemplate(response[0], id));
    dispatch(approveRejectButton(true));
    setAlertModal(true);
    dispatch(saveTemplateId(id));
  };

  useEffect(() => {
    dispatch(setGroupId(addRuleIdForClearing));
    if (tabKey === 'STRATEGY_TEMPLATE_APPROVAL') {
      dispatch(builderObject(setIsLoading, true));
    }
  }, [addRuleIdForClearing]);

  const handleTemplates = (e, type, data) => {
    if (e.target.checked) {
      if (type === 'All') {
        let selectedArr = [];
        if (tabKey === 'GENERAL_MESSAGE') {
          selectedArr = liveData.map((el) => el.id);
        } else {
          selectedArr = liveData.map((el) => el.createdByUserId);
        }
        setCheckedAlert(selectedArr);
        dispatch(selectedAlerts(selectedArr));
      } else {
        let singleSelected = [...selectedIds];
        if (tabKey === 'GENERAL_MESSAGE') {
          singleSelected.push(data.id);
        } else {
          singleSelected.push(data.createdByUserId);
        }
        setCheckedAlert(singleSelected);
        dispatch(selectedAlerts(singleSelected));
      }
    } else {
      if (type === 'All') {
        let clearArr = [];
        setCheckedAlert(clearArr);
        dispatch(selectedAlerts(clearArr));
      } else {
        let index = '';
        if (tabKey === 'GENERAL_MESSAGE') {
          index = selectedIds.indexOf(data.id);
        } else {
          index = selectedIds.indexOf(data.createdByUserId);
        }
        if (index > -1) {
          selectedIds.splice(index, 1);
        }
        setCheckedAlert(selectedIds);
        dispatch(selectedAlerts(selectedIds));
      }
    }
  };
  return (
    <div>
      <div className="alertlist">
        <div
          className="alertTable"
          key={addRuleIdForClearing}
          style={{ height: tableHeight ? tableHeight : '' }}
        >
          <Table className="table">
            <thead>
              <tr>
                {tabKey === 'GENERAL_MESSAGE' ? (
                  <AlertTHead>
                    <input
                      type="checkbox"
                      checked={checkedAlert.length === liveData.length}
                      className="input-check"
                      onClick={(e) => handleTemplates(e, 'All')}
                    />
                  </AlertTHead>
                ) : null}
                {eventkeyName &&
                  table_column &&
                  table_column.map((el) => {
                    return (
                      <>
                        {el.tabEnum === eventkeyName
                          ? el.fields.map((e) => {
                              return <AlertTHead>{e.displayName}</AlertTHead>;
                            })
                          : null}
                      </>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {liveData &&
                liveData?.map((data) => {
                  return (
                    <tr>
                      {tabKey === 'GENERAL_MESSAGE' ? (
                        <AlertTData $border>
                          <input
                            type="checkbox"
                            checked={checkedAlert.includes(
                              data.createdByUserId || data.id
                            )}
                            className="input-check"
                            onClick={(e) => handleTemplates(e, 'Single', data)}
                          />
                        </AlertTData>
                      ) : null}
                      {eventkeyName &&
                        table_column &&
                        table_column.map((el) => {
                          return (
                            <>
                              {el.tabEnum === eventkeyName
                                ? el.fields.map((e) => {
                                    return (
                                      <AlertTData
                                        $border={true}
                                        className={
                                          !data.isRead &&
                                          tabKey === 'GENERAL_MESSAGE'
                                            ? 'not-read'
                                            : ''
                                        }
                                      >
                                        {data[e.fieldEnum] ? (
                                          e.fieldEnum === 'errorDateTime' ? (
                                            <>
                                              <Moment format="DD MMM YYYY  h:mm A">
                                                {data?.errorDateTime}
                                              </Moment>
                                            </>
                                          ) : (
                                            <div
                                              onMouseOver={() => {
                                                e.fieldEnum &&
                                                  e.fieldEnum ===
                                                    'description' &&
                                                  setTooltipMessage({
                                                    message:
                                                      data && data[e.fieldEnum],
                                                    id: data.templateId,
                                                  });
                                              }}
                                              onMouseOut={() => {
                                                e.fieldEnum === 'description' &&
                                                  setTooltipMessage(null);
                                              }}
                                            >
                                              {e.fieldEnum === 'description' ? (
                                                <>
                                                  {data[e.fieldEnum]}
                                                  {data.templateId ===
                                                  (tooltipMessage &&
                                                    tooltipMessage.id) ? (
                                                    <div
                                                      className={
                                                        (data &&
                                                          data.templateId) ===
                                                        (tooltipMessage &&
                                                          tooltipMessage.id)
                                                          ? 'tooptip-custom'
                                                          : ''
                                                      }
                                                    >
                                                      {data.templateId ===
                                                      (tooltipMessage &&
                                                        tooltipMessage.id)
                                                        ? tooltipMessage?.message
                                                        : null}
                                                    </div>
                                                  ) : null}
                                                </>
                                              ) : (
                                                data[e.fieldEnum]
                                              )}
                                            </div>
                                          )
                                        ) : e.actionName ? (
                                          <>
                                            <GenButton
                                              title={e.actionName}
                                              clickAction={() =>
                                                handlePopup(data.templateId)
                                              }
                                              disabled={isLoading}
                                              fill={true}
                                            />
                                          </>
                                        ) : (
                                          'N/A'
                                        )}
                                      </AlertTData>
                                    );
                                  })
                                : null}
                            </>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>

      {isApproveReject ? (
        <ReviewStrategy
          alertModal={alertModal}
          setAlertModal={setAlertModal}
          setIsSuccessDelete={setIsSuccessDelete}
          templateId={templateId}
        />
      ) : null}
    </div>
  );
};

export default TableAlert;

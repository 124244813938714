import React from 'react';
import './styles.css';
import Arrow from '../../assets/img/arrow.svg';

const AnimatedButton = (props) => {
  return (
    <span className="animButton-back" onClick={props.onClick}>
      <p className="animBText">Schedule a demo</p>
      <span className="arrow-circle">
        <img src={Arrow} className="arrow-icon" />
      </span>
    </span>
  );
};

export default AnimatedButton;

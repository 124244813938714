import { useSnackbar } from 'notistack';
import { AppHeaderV2 } from './components/sharedComponents/customComponent';
import './global.css';
import LicenseKey from './viewScreen/loginScreens/LicenseKey';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { bannerReducerAction } from './reducers/bannerReducer';

export default function Layout(props) {
  const { enqueueSnackbar } = useSnackbar(0);
  const { msgObj } = useSelector((state) => state.bannerReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (msgObj.type?.length > 0 && msgObj.msg?.length > 0) {
      enqueueSnackbar(msgObj.msg, {
        variant: msgObj?.type || 'error',
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
      bannerReducerAction.clearBanner(dispatch);
    }
  }, [msgObj]);

  return (
    <div className="app-container-main">
      <AppHeaderV2 />
      <div className="main-container-app-area">{props.children}</div>
      <LicenseKey />
    </div>
  );
}

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import close from '../../../assets/img/close.png';
import './Toast.scss';

export default function SimpleSnackbar({ toaster, setToaster, message }) {
  const handleClose = (reason) => { //close the toaster
    if (reason === 'clickaway') {
      return;
    }
    setToaster(false);
  };

  return (
    <div>
      <Snackbar   //toaster shared component
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={toaster}
        autoHideDuration={3000} //automatic close 
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              {/*toaster's cross icon*/}
              <img src={close} alt="close" /> 
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

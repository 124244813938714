import React, { useEffect, useState } from 'react';
import FamilyDashboard from '../../../components/AdminDashboard/UserMaintenance/FamilyDashboard/FamilyDashboard';
import BanSecurity from './banSecurity';
import { TabNavInner } from '../../../components';
import DealerDashboard from './dealerDashboard';
import UserDashboard from './User Dashboard/userDashboard';
import './styles.css';
import { useSelector } from 'react-redux';

const UserMaintenance = () => {
  const [dashboard, setDashboard] = useState('familydashboard');
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [displayTabs, setDisplayTabs] = useState(
    process.env.REACT_APP_IS_HFT === 'true'
      ? [{ id: 0, title: 'Family Dashboard' }]
      : [
          { id: 0, title: 'Family Dashboard' },
          { id: 1, title: 'Ban Securities' },
          { id: 2, title: 'Dealer Dashboard' },
          { id: 3, title: 'User Dashboard' },
        ]
  );

  useEffect(() => {
    const localDashboard = localStorage.getItem('dashBoard');
    if (localDashboard) {
      setDashboard(localDashboard);
    }
  }, [dashboard]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('dashBoard');
    };
  }, []);

  useEffect(() => {
    if (userPermissions?.menu && process.env.REACT_APP_IS_HFT !== 'true') {
      const availMenu = userPermissions.menu.filter(
        (item) => item.mainSc.title === 'Risk'
      )[0].subMenu;
      const temp = [];
      if (
        availMenu.filter((item) =>
          item.title.includes('User Maintenance/Family Dashboard')
        )[0].access > 0
      ) {
        temp.push({ id: 0, title: 'Family Dashboard' });
      }
      if (
        availMenu.filter((item) =>
          item.title.includes('User Maintenance/Ban Securities')
        )[0].access > 0
      ) {
        temp.push({ id: 1, title: 'Ban Securities' });
      }
      if (
        availMenu.filter((item) =>
          item.title.includes('User Maintenance/Dealer Dashboard')
        )[0].access > 0
      ) {
        temp.push({ id: 2, title: 'Dealer Dashboard' });
      }
      temp.push({ id: 3, title: 'User Dashboard' });
      setDisplayTabs(temp);
    }
  }, [userPermissions]);

  const [tabSelect, setTabSelect] = useState(0);

  function renderComp() {
    switch (tabSelect) {
      case 0:
        return <FamilyDashboard />;
      case 1:
        return <BanSecurity />;
      case 2:
        return <DealerDashboard />;
      case 3:
        return <UserDashboard />;
      default:
        break;
    }
  }

  return (
    <div className="userMain-container" style={{ backgroundColor: '#ffffff' }}>
      <TabNavInner
        tabs={displayTabs}
        curSelect={tabSelect}
        setSelect={setTabSelect}
      />
      <div>{renderComp()}</div>
    </div>
  );
};

export default UserMaintenance;

import React,{useState} from 'react';
import { GenButton,InputField } from '../../../../components';
import StradeLogo from '../../../../assets/img/Strade_LOGO.svg';
import { resetDealerPassword } from './apis';
import { Spinner } from 'react-bootstrap';

export default function ResetDealerPassword(props){
    const {dealerId,closePopup} = props;
    const [confirmationPopup,setConfirmationPopup] = useState(false);
    const [remarks,setRemarks] = useState("");
    const [msg,setMsg] = useState("Are you sure you want to reset password?");
    const [loader,setLoader] = useState(false);
    async function resetPassword () {
        setLoader(true);
        const result = await resetDealerPassword({
            dealerId: dealerId,
            remarks: remarks
        })
        setLoader(false);
        setMsg(result?result:"Password reset unsuccessful");
        setConfirmationPopup(true);
    }
    return (
        <div
            style={{
                width: '31vw',
                padding: '3vw 5vw',
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    
                }}>
                <img src={StradeLogo} style={{display:'block',margin: '2vh 0 6vh 0',width:'15vw'}}/>
                <span
                        style={{
                            display: 'block',
                            fontSize: '1.1vw',
                            fontWeight: '600',
                            textAlign: 'center',
                            marginBottom: '1vh'
                        }}>{msg}</span>
            </div>
        {!confirmationPopup?(
            <div
                style={{
                    marginTop: '2vh',
                }}>
                <div>
                    <div 
                        style={{
                            border: '1px solid #efefef',
                        }}>
                        <div
                            style={{
                                background: '#D3D3D3',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span
                                style={{
                                    fontSize: '1.1vw',
                                    fontWeight: '600',
                                    marginLeft: '1vw',
                                }}>Remarks</span>
                        </div>
                        <InputField 
                            type="textarea"
                            extStyles={{ 
                            }}
                            placeHolder=""
                            onValueChange={setRemarks}
                            inputWidth="20.9vw"
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: '2vw 1vw 0 1vw',
                        justifyContent: 'space-between'
                    }}
                >
                    <GenButton
                    fill={true}
                    title="Yes"
                    extStyle={{ width: '8vw', height: '3vw'}}
                    clickAction={() => resetPassword(true)}
                    />
                    <GenButton
                    fill={false}
                    title="No"
                    extStyle={{width: '8vw', height: '3vw' }}
                    clickAction={() => closePopup()}
                    />
                </div>
                {loader && (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                )}
            </div>  
        ):(
            <div>
                <div
                    style={{
                        height: '12vh',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center', 
                        justifyContent: 'center',  
                    }}
                >
                    <GenButton
                    fill={true}
                    title="Okay"
                    extStyle={{width: '20vw', height: '5vh' }}
                    clickAction={() => closePopup()}
                    />
                </div>
            </div>
        )
        }
       </div>     
    )
}
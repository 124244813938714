import React from 'react';
import './styles.css';

export default function PaginationGen2({
  data = { pagesCount: 5, currentSelect: 1 },
  setData,
}) {
  const pages = [];
  for (let i = 0; i < data.pagesCount; i++) {
    pages.push(i + 1);
  }
  return (
    <div className="paginationGen2-container">
      {pages.map((item) => (
        <span
          className={`paginationGen2-page-${
            item === data.currentSelect ? 'selected' : 'notselected'
          }`}
          onClick={() => item !== data.currentSelect && setData(item)}
        >
          {item}
        </span>
      ))}
    </div>
  );
}

import axios from 'axios';
import { apiConfig } from '../config/apiConfig';
import CacheManager from './cacheManager';

const token = localStorage.getItem('token');

const MakeRequest = (address, exact = true, cache = false) => {
  const header = {
    headers: {
      Authorization: `Bearer ${token || localStorage.getItem('token')}`,
    },
  };

  const request = {
    get: async (queryParam) => {
      let res, query, apiUrl;
      if (queryParam) {
        query = Object.entries(queryParam)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');
        apiUrl = `${apiConfig().api}${address}?${query}`;
      } else {
        apiUrl = `${apiConfig().api}${address}`;
      }
      try {
        const [cacheRes, available] = CacheManager().get(apiUrl);
        if (cache && available) {
          res = cacheRes;
        } else {
          res = await axios.get(apiUrl, header);
          if (cache) CacheManager().set(apiUrl, res);
        }
        return res;
      } catch (error) {
        const [cacheRes, available] = CacheManager().get(apiUrl);
        if (cache && available) {
          return cacheRes;
        } else {
          if (cache) CacheManager().set(apiUrl, error.response);
          return error.response;
        }
      }
    },
    delete: async (queryParam) => {
      try {
        let res;
        if (queryParam) {
          const query = Object.entries(queryParam)
            .map(([key, val]) => `${key}=${val}`)
            .join('&');
          res = await axios.delete(
            `${apiConfig().api}${address}?${query}`,
            header
          );
        } else {
          res = await axios.delete(`${apiConfig().api}${address}`, header);
        }

        return res;
      } catch (error) {
        return error.response;
      }
    },
    post: async (body) => {
      try {
        return await axios.post(
          `${exact ? apiConfig().api + address : address}`,
          body,
          header
        );
      } catch (error) {
        return error.response;
      }
    },
    download: async (fileName) => {
      try {
        const response = await axios.get(`${apiConfig().api}${address}`, {
          headers: {
            Authorization: `Bearer ${token || localStorage.getItem('token')}`,
          },
        });

        var file = new Blob([new Uint8Array(response.data.data.data).buffer], {
          type: 'application/vnd.ms-excel',
        });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        throw Error(error);
      }
    },
  };

  return request;
};

export default MakeRequest;

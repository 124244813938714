//Load table for alert and notification
import React, { useRef, useEffect } from 'react';
import './TableAlert.scss';
import TreeRows from './TreeRows';
const TableComp = ({
  TableData,
  tableCoulumn,
  noRecordMsg,
  tableName,
  style,
  loader,
  grouping,
  tableHeight,
  listenToScroll,
  scrollPosition,
  tabType,
}) => {
  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.addEventListener('scroll', listenToScrollLocal);
    setTimeout(() => {
      if (tableRef && tableRef.current) {
        tableRef.current.scrollTo(0, scrollPosition);
      }
    }, 0);
  }, []);

  useEffect(() => {
    return () => {
      if (tableRef && tableRef.current) {
        tableRef.current.removeEventListener('scroll', listenToScrollLocal);
      }
    };
  }, []);

  const listenToScrollLocal = () => {
    if (tableRef && tableRef.current) {
      listenToScroll(tableRef.current.scrollTop);
    }
  };

  return (
    <div className="genric-table">
      <div
        className="over-table alertTable "
        ref={tableRef}
        style={{
          ...style,
          overflowY: tableName === 'marketWatchTable' ? 'revert' : '',
          overflowX: tableName === 'marketWatchTable' ? 'auto' : '',
          margin: 'auto',
          height: tableHeight ? tableHeight : '',
        }}
      >
        <div
          className="row no-gutters thead-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.8rem',
          }}
        >
          <div className=" table_head" style={{ minWidth: '1.5rem' }}></div>
          <div className="row-right">
            {tableCoulumn.map((headerData) => {
              return (
                <div className={`table_head ${headerData.dataField}`}>
                  {headerData.heading}
                </div>
              );
            })}
          </div>
        </div>
        {TableData && TableData.length > 0 ? (
          <>
            {TableData.map((row, index) => {
              return (
                <TreeRows
                  key={index + 'rowcol'}
                  groupKey={index}
                  grouping={grouping}
                  noRecordMsg={noRecordMsg}
                  row={row}
                  loader={loader}
                  TableData={TableData}
                  tableCoulumn={tableCoulumn}
                  tabType={tabType}
                />
              );
            })}
          </>
        ) : (
          <div>
            {noRecordMsg ? (
              loader ? (
                ''
              ) : (
                <h5>{noRecordMsg}</h5>
              )
            ) : (
              <h5>No records found</h5>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
TableComp.defaultProps = {
  style: {},
};

export default TableComp;

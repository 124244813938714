const initialState = {
    data: [],
    delete:false,
    role:{
      roleName: "",
      roleDescription: "",
      features: []
    },
    isRoleEdit: false
}

const roleDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ROLE":
        return {
          ...state,
          data: action.payload
        }
        case "GET_ROLEDELETE": {
          const filterRoleData = state.data.filter(
            item => item.id !== action.payload.userId
          )
          return {
            ...state,
            delete: action.payload,
            data: filterRoleData
          }
        }
        case "UPDATE_ROLE":{
         return {
           ...state,
           role: action.payload
         }
        }
        case "UPDATE_ROLE_EDIT":{
          return {
            ...state,
            isRoleEdit: action.payload
          }
         }
    
    default:
      return state
    }
}

export default roleDataReducer
import { useState, useEffect, useRef } from 'react';
import LogLivePannel from './logLiveActPan/logLivePannel';
import './RiskMonitoring.scss';
import GenericSwitch from '../../../components/sharedComponents/GenSwitch/GenericSwitch';
import TableGenerator from '../../../components/sharedComponents/GenTable/genTableGenerator';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import LogIcon from '../../../assets/img/logRisk.svg';
import LiveIcon from '../../../assets/img/liveRisk.svg';
import GenericPopup from '../../../components/sharedComponents/genPopup';
import MakeRequest from '../../../utils/apiRequest';
import { apiConfig } from '../../../config/apiConfig';
import Popup from '../../../components/sharedComponents/Popup/Popup';
import {
  LIVE_CONFIRM_HEADER,
  LIVE_CONFIRM_MESSAGE,
  LOG_CONFIRM_HEADER,
  LOG_CONFIRM_MESSAGE,
  popUpTypeEnum,
} from '../../../utils/constant';
import { setFocusOnModalKeyWithClassName } from '../../../components/sharedComponents/keyPressEventHandler';
import { GenSearchBox, ToggleTab } from '../../../components';
import { useSelector } from 'react-redux';
import { permissionChecker } from '../../Ops/redux';
import RiskMeasureUnits from './riskMeasureUnits';
import { TableSettingsV2 } from '../../../components/sharedV2';
import useSWRImmutable from 'swr/immutable';

function RiskMonitoringUtil(props) {
  const { showLogLive = false, isLiveTradingScreen = false } = props;
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState(1);
  useEffect(() => {
    if (userPermissions.menu) {
      setPermission(
        permissionChecker(userPermissions.menu, 'Risk Monitoring', 2, 'Risk')
      );
    }
  }, [userPermissions]);
  let [option, setOption] = useState('Family');
  let [displayData, setDisplayData] = useState();

  const [columnToFactorFamily, setColumnToFactorFamily] = useState([]);
  const [columnToFactorDealer, setColumnToFactorDealer] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: 'doubleButtons',
    nameAccept: 'Yes',
    nameDecline: 'No',
    state: false,
    header: '',
    typeEnum: -1,
    typeEnumData: [],
  });
  const [actionType, setActionType] = useState('live');
  const [familyNameArr, setFamilyNameArr] = useState([]);
  const [displayTime, setDisplayTime] = useState('');

  let displayDataRef = useRef();
  displayDataRef.current = displayData;

  const selectAllHandle = (arg1, arg2) => {
    try {
      if (arg2 && displayDataRef.current) {
        setSelect(
          (prev) =>
            new Set(
              displayDataRef.current.familyRiskData.map((obj) => obj.familyId)
            )
        );
      } else {
        setSelect((prev) => new Set());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [select, setSelect] = useState(new Set());
  let selectRef = useRef();
  selectRef.current = select;

  const [dataState, setDataState] = useState({
    familyInfo: {},
    dealerInfo: {},
    sortState: false,
    riskLimitInfo: {},
    dealerRiskLimitInfo: {},
    searchKey: '',
    heads: {
      family: [
        showLogLive ? (
          'Family Name'
        ) : (
          <GenericSwitch
            checkBox={true}
            clickAction={selectAllHandle}
            title={'Family Name'}
            bright={false}
            obj={displayData ? displayData.familyRiskData : []}
          />
        ),
        'Mode',
      ],
      dealer: ['Dealer Name'],
    },
  });

  const [client, setClient] = useState();
  const [popupState, setPopupState] = useState({
    state: false,
    title: '',
    message: '',
  });
  const requestBody = {
    eventType: 'RISK_SUBSCRIBE',
    payload: {
      userId: Number(localStorage.getItem('customerId'))
        ? parseInt(localStorage.getItem('customerId'))
        : localStorage.getItem('customerId'),
      userType: 'DEALER',
    },
  };

  useEffect(() => {
    switch (popup.type) {
      case 'logConfirm':
        setPopup({
          ...popup,
          message: LOG_CONFIRM_MESSAGE,
          buttons: 'doubleButtons',
          nameAccept: 'Yes',
          nameDecline: 'No',
          header: LOG_CONFIRM_HEADER,
          typeEnum: popUpTypeEnum.LOG_LIVE_POPUP,
          typeEnumData: popup.typeEnumData,
        });
        setTimeout(() => {
          setFocusOnModalKeyWithClassName('No');
        }, 500);
        break;
      case 'liveConfirm':
        setPopup({
          ...popup,
          message: LIVE_CONFIRM_MESSAGE,
          buttons: 'doubleButtons',
          nameAccept: 'Yes',
          nameDecline: 'No',
          header: LIVE_CONFIRM_HEADER,
          typeEnum: popUpTypeEnum.LOG_LIVE_POPUP,
          typeEnumData: popup.typeEnumData,
        });
        setTimeout(() => {
          setFocusOnModalKeyWithClassName('No');
        }, 500);
        break;
      default:
        break;
    }
  }, [popup.type]);

  useEffect(() => {
    const tempAsyncFunc = async () => {
      const tempResult = await MakeRequest(`/admin/risk/configurationV2`).get();
      if (tempResult.data) {
        prepareLimitData(tempResult.data.data['0']);
      }
    };
    tempAsyncFunc();
  }, [columnToFactorDealer, columnToFactorFamily]);

  useEffect(() => {
    if (client === undefined) {
      setClient(new W3CWebSocket(`ws://${apiConfig().websocket}`));
      return;
    }
    try {
      client.onopen = () => {
        client.send(JSON.stringify(requestBody));
      };
      client.onmessage = (message) => {
        try {
          const result = JSON.parse(message.data);
          setDisplayTime(result.payload.lastUpdateTime);
          if (result['status'] !== 400 && result['status'] !== 500) {
            prepWebsocketData(result);
          } else {
            setPopupState({
              state: true,
              title: 'Warning',
              message: 'No Data available , please try after some time.',
            });
          }
        } catch (error) {
          console.log(error);
        }
      };
    } catch (error) {
      console.log(error);
    }
    return () => client.close();
  }, [client]);

  const prepWebsocketData = (responseData) => {
    if (responseData.code !== undefined && responseData.code === 404) {
      return;
    }
    const temp = {};

    if (responseData.payload['familyRiskNumbers']) {
      temp.familyRiskData = responseData.payload['familyRiskNumbers'].map(
        (obj) => {
          return { ...obj, state: false };
        }
      );
    }
    if (responseData.payload['dealerRiskNumbers']) {
      temp.dealerRiskData = responseData.payload['dealerRiskNumbers'];
    }

    setDisplayData({ ...temp });
  };

  useEffect(() => {
    async function makeFactorMap() {
      const response = await MakeRequest(
        '/user/currency/getCurrencyUnits'
      ).get();
      const response2 = await MakeRequest(
        '/user/currency/getCurrencyConversionStruct?tableName=NON_HFT_RISK_MONITORING_FAMILY'
      ).get();
      let columns_family = response2.data.data;
      const response3 = await MakeRequest(
        '/user/currency/getCurrencyConversionStruct?tableName=NON_HFT_RISK_MONITORING_DEALER'
      ).get();
      let columns_dealer = response3.data.data;
      let availableUnits = response.data.data.data;
      let factor_map = {};
      if (availableUnits?.length > 0) {
        for (let i = 0; i < availableUnits.length; i++) {
          factor_map[availableUnits[i].name] = availableUnits[i].factor;
        }
      }
      let column_to_factor_family = [];
      if (columns_family?.length > 0) {
        column_to_factor_family = columns_family.map((item) => {
          return {
            factor: factor_map[item.value],
            unit: item.value,
          };
        });
      }
      let column_to_factor_dealer = [];
      if (columns_dealer?.length > 0) {
        column_to_factor_dealer = columns_dealer.map((item) => {
          return {
            factor: factor_map[item.value],
            unit: item.value,
          };
        });
      }
      setColumnToFactorFamily(column_to_factor_family);
      setColumnToFactorDealer(column_to_factor_dealer);
    }
    async function updateColumnData() {}
    updateColumnData();
    makeFactorMap();
  }, []);

  const prepareLimitData = (response) => {
    const tempState = { ...dataState };
    if (columnToFactorDealer.length == 0 || columnToFactorFamily.length == 0)
      return;
    tempState.familyInfo = {};
    if (response.families) {
      for (let tempObj of response.families) {
        tempState.familyInfo[tempObj.id] = tempObj.name;
      }
    }

    tempState.dealerInfo = {};
    if (response.dealers) {
      for (let tempObj of response.dealers) {
        tempState.dealerInfo[tempObj.id] = tempObj.name;
      }
    }

    tempState.riskLimitInfo = {};
    if (response.familyRiskLimits) {
      for (let tempLim of response.familyRiskLimits) {
        if (tempState.riskLimitInfo[tempLim.dimensionValue]) {
          tempState.riskLimitInfo[tempLim.dimensionValue][tempLim.riskParamId] =
            {
              L1: tempLim.L1,
              L2: tempLim.L2,
              L3: tempLim.L3,
              L4: tempLim.L4,
              L5: tempLim.L5,
              L6: tempLim.L6,
            };
        } else {
          tempState.riskLimitInfo[tempLim.dimensionValue] = {
            [tempLim.riskParamId]: {
              L1: tempLim.L1,
              L2: tempLim.L2,
              L3: tempLim.L3,
              L4: tempLim.L4,
              L5: tempLim.L5,
              L6: tempLim.L6,
            },
          };
        }
      }
    }

    tempState.dealerRiskLimitInfo = {};
    if (response.dealerRiskLimits) {
      for (let tempLim of response.dealerRiskLimits) {
        if (tempState.dealerRiskLimitInfo[tempLim.dimensionValue]) {
          tempState.dealerRiskLimitInfo[tempLim.dimensionValue][
            tempLim.riskParamId
          ] = {
            L1: tempLim.L1,
            L2: tempLim.L2,
            L3: tempLim.L3,
            L4: tempLim.L4,
            L5: tempLim.L5,
            L6: tempLim.L6,
          };
        } else {
          tempState.dealerRiskLimitInfo[tempLim.dimensionValue] = {
            [tempLim.riskParamId]: {
              L1: tempLim.L1,
              L2: tempLim.L2,
              L3: tempLim.L3,
              L4: tempLim.L4,
              L5: tempLim.L5,
              L6: tempLim.L6,
            },
          };
        }
      }
    }
    tempState.heads.family = [...tempState.heads.family];
    if (response.familyRiskParameters) {
      for (let obj of response.familyRiskParameters) {
        if (obj.isRiskMonitorDisplay === 1) {
          if (
            (!obj.displayName.includes('Executed Turnover Limit') &&
              !obj.displayName.includes('Open Order Limit')) ||
            false
          ) {
            tempState.heads.family.push(
              `${obj.displayName}${
                obj.currency ? ' (' + obj.currency + ')' : ''
              }`.replace('Family ', '')
            );
            tempState.heads.family.push(
              `${obj.displayName} Utilization${
                obj.currency ? ' (' + obj.currency + ')' : ''
              }`
                .replace('Limit', '')
                .replace('Family ', '')
            );
          }
        }
      }
    }

    tempState.heads.dealer = [...tempState.heads.dealer];
    tempState.heads.dealer.push('Mode');
    if (response.dealerRiskParameters) {
      for (let obj of response.dealerRiskParameters) {
        if (obj.isRiskMonitorDisplay === 1) {
          if (
            (!obj.displayName.includes('Executed Turnover Limit') &&
              !obj.displayName.includes('Open Order Limit')) ||
            false
          ) {
            tempState.heads.dealer.push(
              `${obj.displayName}${
                obj.currency ? ' (' + obj.currency + ')' : ''
              }`.replace('Dealer ', '')
            );
            tempState.heads.dealer.push(
              `${obj.displayName} Utilization${
                obj.currency ? ' (' + obj.currency + ')' : ''
              }`
                .replace('Limit', '')
                .replace('Dealer ', '')
            );
          }
        }
      }
    }
    for (let i = 2; i < tempState.heads.family.length; i++) {
      if (
        columnToFactorFamily.length == 0 ||
        tempState.heads.family[i].includes(columnToFactorFamily[i - 2]?.unit)
      )
        continue;
      tempState.heads.family[i] =
        tempState.heads.family[i].slice(0, -5) +
        ` (${columnToFactorFamily[i - 2]?.unit}) ` +
        tempState.heads.family[i].slice(-5);
    }
    for (let i = 2; i < tempState.heads.dealer.length; i++) {
      if (
        columnToFactorDealer.length == 0 ||
        tempState.heads.dealer[i].includes(columnToFactorDealer[i - 2]?.unit)
      )
        continue;
      tempState.heads.dealer[i] =
        tempState.heads.dealer[i].slice(0, -5) +
        ` (${columnToFactorDealer[i - 2]?.unit}) ` +
        tempState.heads.dealer[i].slice(-5);
    }
    setDataState(tempState);
  };

  const handleSelection = (objSelected, state) => {
    if (state) {
      setSelect((prev) =>
        new Set(prev).add(
          objSelected.familyId ? objSelected.familyId : objSelected.dealerId
        )
      );
    } else {
      setSelect((prev) => {
        const next = new Set(prev);
        next.delete(
          objSelected.familyId ? objSelected.familyId : objSelected.dealerId
        );
        return next;
      });
    }
  };

  const getFamilyName = (obj) => {
    if (obj.familyName) {
      return obj.familyName;
    } else if (obj.dealerName) {
      return obj.dealerName;
    }
    return 'NoNameAvailable';
  };

  const [sortColumn, setSortColumn] = useState({
    fam_sort_columns: [],
    dealer_sort_columns: [],
  });

  function toggledColumn(index, order) {
    let tempObj = {
      ...sortColumn,
    };
    if (option === 'Family') {
      for (let i = 0; i < tempObj.fam_sort_columns.length; i++) {
        tempObj.fam_sort_columns[i].order = 'n';
      }
      if (order === 'n') {
        tempObj.fam_sort_columns[index].order = 'a';
        setSortColumn(tempObj);
      } else if (order === 'a') {
        tempObj.fam_sort_columns[index].order = 'd';
        setSortColumn(tempObj);
      } else if (order === 'd') {
        tempObj.fam_sort_columns[index].order = 'n';
        setSortColumn(tempObj);
      }
    } else {
      for (let i = 0; i < tempObj.dealer_sort_columns.length; i++) {
        tempObj.dealer_sort_columns[i].order = 'n';
      }
      if (order === 'n') {
        tempObj.dealer_sort_columns[index].order = 'a';
        setSortColumn(tempObj);
      } else if (order === 'a') {
        tempObj.dealer_sort_columns[index].order = 'd';
        setSortColumn(tempObj);
      } else if (order === 'd') {
        tempObj.dealer_sort_columns[index].order = 'n';
        setSortColumn(tempObj);
      }
    }
  }

  useEffect(() => {
    const sortTableObj = {
      fam_sort_columns: [],
      dealer_sort_columns: [],
    };
    const heads =
      option === 'Family' ? dataState.heads.family : dataState.heads.dealer;

    for (let i = 0; i < heads.length; i++) {
      sortTableObj.fam_sort_columns.push({ state: true, order: 'n' });
    }
    sortTableObj.fam_sort_columns[1].state = false;

    for (let i = 0; i < heads.length; i++) {
      sortTableObj.dealer_sort_columns.push({ state: true, order: 'n' });
    }

    setSortColumn(sortTableObj);
  }, [dataState]);

  const colorDecide = (params) => {
    let tempData = [...params];
    let temp =
      option === 'Family'
        ? dataState.riskLimitInfo
        : dataState.dealerRiskLimitInfo;
    let searchKey = option === 'Family' ? 'familyId' : 'dealerId';
    for (let i = 0; i < tempData.length; i++) {
      if (temp[tempData[i][searchKey]] !== undefined) {
        for (let j = 0; j < tempData[i].riskNumbers.length; j++) {
          if (temp[tempData[i][searchKey]][tempData[i].riskNumbers[j].id]) {
            let t = temp[tempData[i][searchKey]][tempData[i].riskNumbers[j].id];
            let name = tempData[i].riskNumbers[j].name;
            if (name.indexOf('Utilisation') !== -1) {
              let maxv = tempData[i].riskNumbers[j - 1].value;
              let curv = tempData[i].riskNumbers[j].value;
              let temv = parseInt((curv / maxv) * 100);

              if (temv < t.L1) {
                tempData[i].riskNumbers[j].stColor = '#00800050';
              }
              if (temv >= t.L1) {
                tempData[i].riskNumbers[j].stColor = '#00ff0050';
              }
              if (temv >= t.L2) {
                tempData[i].riskNumbers[j].stColor = '#fffa66';
              }
              if (temv >= t.L3) {
                tempData[i].riskNumbers[j].stColor = '#f8bc66';
              }
              if (temv >= t.L4) {
                tempData[i].riskNumbers[j].stColor = '#ffa500';
              }
              if (temv >= t.L5) {
                tempData[i].riskNumbers[j].stColor = '#ff6666';
              }
              if (temv >= t.L6) {
                tempData[i].riskNumbers[j].stColor = '#ff0000';
              }
            }
          }
        }
      } else {
        for (let j = 0; j < tempData[i].riskNumbers.length; j++) {
          tempData[i].riskNumbers[j].stColor = '#ffffff';
        }
      }
    }
    return tempData;
  };

  function formatIndianCurrency(num) {
    // console.log("Number: ->", num)
    return num;

    // TODO: Akshay -> To fix it under proper buig with proper unit testing
    // Split the number into integer and fractional parts
    let [integerPart, fractionalPart] = num.toString().split('.');

    // Format the integer part
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);
    if (otherDigits !== '') {
      lastThreeDigits = ',' + lastThreeDigits;
    }
    let formattedIntegerPart =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;

    // Combine the formatted integer part with the fractional part (if it exists)
    let formattedNumber = fractionalPart
      ? `${formattedIntegerPart}.${fractionalPart}`
      : formattedIntegerPart;

    return formattedNumber;
  }

  function tableDatagenerator(selected) {
    let displayDataValues =
      option === 'Family'
        ? displayData
          ? displayData.familyRiskData
          : []
        : displayData
        ? displayData.dealerRiskData
        : [];
    if (dataState.searchKey !== '' && displayData) {
      if (option === 'Family') {
        displayDataValues = displayData.familyRiskData.filter(
          // (obj) => getFamilyName(obj).search(`/${dataState.searchKey}/i`) >= 0
          (obj) =>
            new RegExp(dataState.searchKey, ['i']).test(getFamilyName(obj))
        );
      } else {
        displayDataValues = displayData.dealerRiskData.filter((obj) =>
          new RegExp(dataState.searchKey, ['i']).test(getFamilyName(obj))
        );
      }
    }

    displayDataValues = colorDecide(displayDataValues);

    if (selected.size > 0 || selected.size === 0) {
      for (let i = 0; i < displayDataValues.length; i++) {
        displayDataValues[i].state = selected.has(
          displayDataValues[i].familyId
        );
      }
    }

    let tempData = displayDataValues;
    if (option === 'Family') {
      if (
        sortColumn.fam_sort_columns[0] &&
        sortColumn.fam_sort_columns[0].state
      ) {
        if (sortColumn.fam_sort_columns[0].order === 'd') {
          tempData.sort((a, b) =>
            a.familyName > b.familyName
              ? -1
              : b.familyName > a.familyName
              ? 1
              : 0
          ); // * dsc
        } else if (sortColumn.fam_sort_columns[0].order === 'a') {
          tempData.sort((a, b) =>
            a.familyName > b.familyName
              ? 1
              : b.familyName > a.familyName
              ? -1
              : 0
          ); // * Asc
        }
      }
    } else {
      if (
        sortColumn.dealer_sort_columns[0] &&
        sortColumn.dealer_sort_columns[0].state
      ) {
        if (sortColumn.dealer_sort_columns[0].order === 'd') {
          tempData.sort((a, b) =>
            a.dealerName > b.dealerName
              ? -1
              : b.dealerName > a.dealerName
              ? 1
              : 0
          ); // * dsc
        } else if (sortColumn.dealer_sort_columns[0].order === 'a') {
          tempData.sort((a, b) =>
            a.dealerName > b.dealerName
              ? 1
              : b.dealerName > a.dealerName
              ? -1
              : 0
          ); // * Asc
        }
      }
    }

    if (option === 'Family') {
      for (let i = 2; i < sortColumn.fam_sort_columns.length; i++) {
        if (
          sortColumn.fam_sort_columns[i].state &&
          sortColumn.fam_sort_columns[i].order === 'a'
        ) {
          tempData = tempData.filter((item) => item.riskNumbers.length > 0);
          tempData.sort((a, b) =>
            a.riskNumbers[i - 2].value > b.riskNumbers[i - 2].value
              ? 1
              : b.riskNumbers[i - 2].value > a.riskNumbers[i - 2].value
              ? -1
              : 0
          ); // * Asc
        } else if (
          sortColumn.fam_sort_columns[i].state &&
          sortColumn.fam_sort_columns[i].order === 'd'
        ) {
          tempData = tempData.filter((item) => item.riskNumbers.length > 0);
          tempData.sort((a, b) =>
            a.riskNumbers[i - 2].value > b.riskNumbers[i - 2].value
              ? -1
              : b.riskNumbers[i - 2].value > a.riskNumbers[i - 2].value
              ? 1
              : 0
          ); // * dsc
        }
      }
    } else {
      for (let i = 1; i < sortColumn.dealer_sort_columns.length; i++) {
        if (
          sortColumn.dealer_sort_columns[i].state &&
          sortColumn.dealer_sort_columns[i].order === 'a'
        ) {
          tempData = tempData.filter((item) => item.riskNumbers.length > 0);
          tempData.sort((a, b) =>
            a.riskNumbers[i - 1].value > b.riskNumbers[i - 1].value
              ? 1
              : b.riskNumbers[i - 1].value > a.riskNumbers[i - 1].value
              ? -1
              : 0
          ); // * Asc
        } else if (
          sortColumn.dealer_sort_columns[i].state &&
          sortColumn.dealer_sort_columns[i].order === 'd'
        ) {
          tempData = tempData.filter((item) => item.riskNumbers.length > 0);
          tempData.sort((a, b) =>
            a.riskNumbers[i - 1].value > b.riskNumbers[i - 1].value
              ? -1
              : b.riskNumbers[i - 1].value > a.riskNumbers[i - 1].value
              ? 1
              : 0
          ); // * dsc
        }
      }
    }

    return tempData.map((obj) => (
      <tr key={obj.id} className="riskUtilTr">
        <td>
          <GenericSwitch
            checkBox={true}
            state={select.has(obj.familyId ? obj.familyId : obj.dealerId)}
            obj={obj}
            clickAction={handleSelection}
            title={getFamilyName(obj)}
          />
        </td>
        <td>
          <img
            src={obj.mode ? LiveIcon : LogIcon}
            className="logLiveIcon-icon"
            alt="logLiveIcon"
          />
        </td>
        {obj.riskNumbers.map((riskNum, i) => (
          <td style={{ backgroundColor: riskNum.stColor, textAlign: 'right' }}>
            {i % 2 === 0
              ? formatIndianCurrency(
                  parseInt(riskNum.value) *
                    (option == 'Family'
                      ? columnToFactorFamily[i]?.factor
                      : columnToFactorDealer[i]?.factor)
                )?.toFixed(2)
              : obj.riskNumbers[i - 1].value == 0
              ? `0 (0.00%)`
              : `${formatIndianCurrency(
                  parseInt(riskNum.value) *
                    (option == 'Family'
                      ? columnToFactorFamily[i]?.factor
                      : columnToFactorDealer[i]?.factor)
                )?.toFixed(2)} (${
                  obj.riskNumbers[i - 1] &&
                  parseFloat(
                    parseFloat(
                      parseFloat(riskNum.value) / obj.riskNumbers[i - 1].value
                    ) * 100
                  ).toFixed(2)
                }%)`}
          </td>
        ))}
        {obj.riskNumbers.length == 0
          ? dataState.heads.family
              .slice(2, dataState.heads.family.length)
              .map((item, idx) => <td></td>)
          : null}
      </tr>
    ));
  }

  const logLiveAction = async (direc) => {
    const request = { familyId: Array.from(select) };
    const familyNameList = Array.from(select).map((familyId) =>
      dataState && dataState.familyInfo && dataState.familyInfo[familyId]
        ? dataState.familyInfo[familyId]
        : familyId
    );
    setFamilyNameArr(familyNameList);
    setActionType(direc);

    switch (direc) {
      case 'live':
        setPopup({ type: 'liveConfirm', typeEnumData: familyNameArr });
        setShowPopUp(true);
        break;
      case 'log':
        setPopup({ type: 'logConfirm', typeEnumData: familyNameArr });
        setShowPopUp(true);
        break;
      default:
        break;
    }
  };

  const actionOnpopup = async (e, type) => {
    if (type === 'CLOSE') {
      setShowPopUp(false);
      setPopup({
        type: '',
        message: '',
        buttons: 'doubleButtons',
        nameAccept: 'Yes',
        nameDecline: 'No',
        state: false,
        header: '',
        typeEnum: -1,
        typeEnumData: familyNameArr,
      });
      // handleChangeSuccess();
    } else if (type === 'YES') {
      // Ignore below currently
      let request, response;
      if (option === 'Family') {
        request = { familyId: Array.from(select) };
        response = await MakeRequest(`/admin/risk/monitor/${actionType}`).post(
          request
        );
      } else {
        request = {
          riskDimensionEnum: 'DEALER',
          riskDimensionValueArr: Array.from(select),
          operationTypeEnum: actionType === 'log' ? 0 : 1,
        };
        response = await MakeRequest('/admin/risk/monitor/log-live-V2').post(
          request
        );
      }

      setShowPopUp(false);
      setPopup({
        type: '',
        message: '',
        buttons: 'doubleButtons',
        nameAccept: 'Yes',
        nameDecline: 'No',
        state: false,
        header: '',
        typeEnum: -1,
        typeEnumData: familyNameArr,
      });
      setSelect(new Set());
      if (response.status === 200) {
        setPopupState({
          state: true,
          title: (
            <div>
              <img src={LiveIcon} /> Success
            </div>
          ),
          message: response.data.data.message,
        });
      } else {
        setPopupState({
          state: true,
          title: (
            <div>
              <img src={LogIcon} /> Failed
            </div>
          ),
          message: 'Action Failed, Please try Again after some time',
        });
      }
    }
  };

  return (
    <div
      style={{
        height: isLiveTradingScreen ? 'fit-content' : '100%',
      }}
    >
      <div
        className={`toggleStyle ${
          isLiveTradingScreen ? 'risk-monitoring-live-trade-toggle-style' : ''
        }`}
      >
        <ToggleTab
          options={['Family', 'Dealer']}
          onSelect={(tab) => {
            setOption(tab);
            setSelect(new Set());
          }}
          selected={option}
        />
        <RiskMeasureUnits styles={{ margin: '0 1em 0 1em' }} type={option} />
        <GenSearchBox
          value={dataState.searchKey}
          changeListener={(val) =>
            setDataState({ ...dataState, searchKey: val })
          }
          message={option === 'Family' ? 'Search Families' : 'Search Dealers'}
          extStyles={{ margin: 'auto 1rem' }}
        />

        <LogLivePannel
          selected={select}
          requestAction={logLiveAction}
          time={displayTime}
          disabled={permission !== 2}
        />
        <TableSettingsV2 />
      </div>
      <TableGenerator
        heads={
          option === 'Family' ? dataState.heads.family : dataState.heads.dealer
        }
        content={tableDatagenerator(selectRef.current)}
        isLTSc={isLiveTradingScreen}
        sortObject={{
          enabledColumns:
            option === 'Family'
              ? sortColumn.fam_sort_columns
              : sortColumn.dealer_sort_columns,
        }}
        toggledColumn={toggledColumn}
        className="riskMonitorContainer bodyScrollTable"
      />

      {showPopUp ? (
        <Popup
          popClass="riskLogLivePopup"
          actionButton={actionOnpopup}
          popupDetails={popup}
          header={popup.header}
          typeEnum={popup.typeEnum}
          typeEnumData={familyNameArr}
        />
      ) : null}

      <GenericPopup
        state={popupState.state}
        title={popupState.title}
        message={popupState.message}
        toggleState={() =>
          setPopupState({ ...popupState, state: !popupState.state })
        }
      />
    </div>
  );
}

const initialLoadFunctions = {
  riskColumnData: async (category) => {
    try {
      let response, tempResponse;
      switch (category) {
        case 'family':
          tempResponse = await MakeRequest(
            '/user/dynamicTable/getTableStruct?tableName=NON_HFT_RISK_MONITORING_FAMILY&pageNum=1'
          ).get();
          response = tempResponse;
          break;
        case 'dealer':
          tempResponse = await MakeRequest(
            '/user/dynamicTable/getTableStruct?tableName=NON_HFT_RISK_MONITORING_DEALER&pageNum=1'
          ).get();

          response = tempResponse;
          break;
        default:
          break;
      }
      response = await MakeRequest(
        '/user/dynamicTable/getTableStruct?tableName=MARKET_WATCH&pageNum=1'
      );
    } catch (error) {
      console.log(error);
      return {};
    }
  },
};

export default RiskMonitoringUtil;

import React from 'react';
import './styles.css';

// * title: type = 0: text title, 1: component title
export default function ContainerWindow(props) {
  const {
    children,
    title = { type: 0, content: '' },
    extStyles = {},
    contClass = '',
  } = props;
  return (
    <div style={extStyles} className="container-window">
      <div className="container-window-header">
        {title.type === 0 ? (
          <p className="container-window-header-text">{title.content}</p>
        ) : (
          title.content
        )}
      </div>
      <div className={contClass}>{children}</div>
    </div>
  );
}

import React from "react"
import UiButton from "../sharedComponents/Button"
import './LoginStyle.scss'
const OtpSent = (props) => {
    const handlePopup = () => {
        props.setEditPopup(false)
    }
  
  return (
    <div className="create-user-modal colormodal-global otp">
      <div className="popup success otp-popups">
        <div className="create_2 ">
         
          <form className="otp-popup">
           <h2>Otp sent successfully  </h2>        
            <div className="buttons text-center mt-3 otp-buttons ">
              <UiButton className="btn btn-primary col-sm-3" onClickFunction={handlePopup} name="Ok"/>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default OtpSent

import { useRef, useState } from 'react';
import CompLabel from '../customComponent/compLabel';
import './styles.css';

export default function InputField({
  inputFieldId = '',
  label = '',
  value,
  onValueChange,
  orientation = 'v',
  extStyles = {},
  compLabelExtStyles = { position: 'relative' },
  type = 'number',
  inputWidth = '8vw',
  placeHolder = 'Enter Value',
  validationFunction,
  disabled = false,
  class_name = '',
}) {
  const newFormatter = (date) => {
    let yyyy = date.substring(0, 4);
    let mm = date.substring(5, 7);
    let dd = date.substring(8, 10);
    return dd + '-' + mm + '-' + yyyy;
  };
  const dateRef = useRef(null);

  // * validation function must return a object with state and message
  const [validState, setValidState] = useState({ state: true, message: '' });
  
  const handleChange = (event) => {
    if (validationFunction !== undefined) {
      const valid = validationFunction(event.target.value);
      setValidState(valid);
    }

    switch (type) {
      case 'number':
        onValueChange(
          event.target.value.length > 0 ? parseInt(event.target.value) : 0
        );
        break;
      case 'date':
        onValueChange(newFormatter(event.target.value));
        break;
      default:
        onValueChange(event.target.value);
        break;
    }
  };

  function displayDate(date) {
    const parts = date.split('-');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const outputDate = `${year}-${month}-${day}`;
    return outputDate;
  }

  return (
    <div
      className={`inputField-container-${orientation} ${class_name}`}
      style={extStyles}
    >
      <CompLabel
        label={label}
        orientation={orientation}
        extStyles={compLabelExtStyles}
      >
        {type === 'date' ? (
          <div className="input-date-container">
            <input
              type={type}
              placeholder={placeHolder}
              onChange={handleChange}
              style={{ width: inputWidth }}
              className={`inputField-input-${
                !validState.state ? 'error' : 'norm'
              } ${disabled ? 'input-disabled' : 'input-enabled'}`}
              disabled={disabled}
              ref={dateRef}
              value={displayDate(value)}
            />
          </div>
        ) : (
          <input
            id={inputFieldId}
            type={type}
            placeholder={placeHolder}
            value={value}
            onChange={handleChange}
            style={{ width: inputWidth }}
            className={`inputField-input-${
              !validState.state ? 'error' : 'norm'
            } ${disabled ? 'input-disabled' : 'input-enabled'}`}
            disabled={disabled}
          />
        )}

        {!validState.state && (
          <p className="inputField-error-message">{validState.message}</p>
        )}
      </CompLabel>
    </div>
  );
}

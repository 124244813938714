import React, { useState } from 'react';
import Pagination from '../pagination/Pagination';
import './Table.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import MuiPagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { handleEnterForMarketWatch } from '../keyPressEventHandler';
import { useSelector } from 'react-redux';
import {
  Table,
  TableDataCell,
  TableRow,
  TableHeader,
  TableHeadText,
} from '../../sharedComponents/GenTable/tableStyledComps';

const useStyles = makeStyles({
  icon: (props) => ({
    fontSize: 14,
    verticalAlign: 'middle !important',
    color: props.theme === 'dark' ? '#ffffff' : null,
  }),
});

const TableHeader1 = ({
  sortingFuction,
  sorting,
  headerData,
  sortColumn,
  theme,
}) => {
  const [sortOrder, setSortType] = useState('');
  let iconSection = <UnfoldMoreIcon />;

  const classes = useStyles({ theme });

  switch (sortOrder) {
    case 'asc':
      iconSection = <ArrowDropUpIcon className={classes.icon} />;
      break;
    case '':
      iconSection = <UnfoldMoreIcon className={classes.icon} />;
      break;
    case 'desc':
      iconSection = <ArrowDropDownIcon className={classes.icon} />;
      break;
    default:
      iconSection = <UnfoldMoreIcon className={classes.icon} />;
      break;
  }
  if (sorting) {
    if (sortColumn !== headerData.field) {
      iconSection = <UnfoldMoreIcon className={classes.icon} />;
    }
  }

  return (
    <TableHeader
      width="5vw"
      backgroundColor={theme === 'dark' ? '#25282e' : null}
      onClick={() => {
        if (sorting) {
          sortingFuction(sortOrder, headerData);

          switch (sortOrder) {
            case 'asc':
              setSortType('desc');
              break;
            case '':
              setSortType('desc');
              break;
            case 'desc':
              setSortType('asc');
              break;
            default:
              setSortType('');
          }
        }
      }}
      key={headerData.lebel}
    >
      {headerData.description ? (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip
              key={`strategy_analyzer_${headerData.lebel}`}
              id="tooltip-top_algo"
            >
              <div className={`strategy_analyzer_class_${headerData.lebel}`}>
                <p>{headerData.description}</p>
              </div>
            </Tooltip>
          }
        >
          <TableHeadText
            textColor={theme === 'dark' ? '#ffffff' : null}
            key={`strategy_analyzer_${headerData.lebel}`}
          >
            {String(headerData.lebel)}
          </TableHeadText>
        </OverlayTrigger>
      ) : (
        <TableHeadText textColor={theme === 'dark' ? '#ffffff' : null}>
          {headerData.lebel}
        </TableHeadText>
      )}
      {headerData.sort && sorting ? iconSection : ''}
    </TableHeader>
  );
};

const TableComp = ({
  showHeader,
  showPagination,
  rowBodyClass,
  TableData,
  rowHeaderClass,
  className,
  tableCoulumn,
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  onClickHandler,
  onDblClickHandler,
  rowFocused,
  noRecordMsg,
  tableName,
  showLeftModal,
  showRightModal,
  style,
  sorting,
  sortingFuction,
  loader,
  sortColumn,
  postsLength,
  maxPosts,
  elipsisPagination,
  theme,
  id,
  toDisplayFooter,
  tableFooter,
  type = '',
}) => {
  const { shortcutReducer } = useSelector((state) => state);

  return (
    <div className="genric-table">
      <div
        className="over-table"
        style={{
          ...style,
          overflowY: tableName === 'marketWatchTable' ? 'revert' : '',
          overflowX: tableName === 'marketWatchTable' ? 'auto' : '',
        }}
      >
        <Table className={className}>
          {showHeader ? (
            <thead>
              {tableCoulumn?.map((headerData) => {
                return (
                  <TableHeader1 //table header component
                    sortColumn={sortColumn}
                    sortingFuction={sortingFuction}
                    sorting={sorting}
                    headerData={headerData}
                    theme={theme}
                  />
                );
              })}
            </thead>
          ) : (
            ''
          )}
          <tbody>
            {TableData && TableData.length > 0 ? (
              TableData.map((row, index) => {
                return (
                  <>
                    <TableRow
                      tabIndex={type === 'MARKET_WATCH' ? '0' : '-1'}
                      onFocus={(event) => {
                        if (
                          !showLeftModal &&
                          !showRightModal &&
                          onClickHandler
                        ) {
                          onClickHandler(event, row);
                        }
                      }}
                      key={index}
                      onDoubleClick={(event) => {
                        if (
                          !showLeftModal &&
                          !showRightModal &&
                          onDblClickHandler
                        ) {
                          onDblClickHandler(event, row);
                        }
                      }}
                      onMouseDown={(event) => {
                        if (
                          !showLeftModal &&
                          !showRightModal &&
                          onClickHandler
                        ) {
                          onClickHandler(event, row);
                        }
                      }}
                      onKeyDown={(event) =>
                        handleEnterForMarketWatch(
                          event,
                          row,
                          type,
                          shortcutReducer
                        )
                      }
                      onKeyPress={(event) =>
                        handleEnterForMarketWatch(event, row, type)
                      }
                    >
                      {tableCoulumn.map((rowData) => {
                        return (
                          <TableDataCell
                            backgroundColor={
                              theme === 'dark' ? '#25282e' : null
                            }
                            fontColor={theme === 'dark' ? '#ffffff' : null}
                            key={rowData.value}
                            className={
                              rowData.className ? rowData.className : ''
                            }
                            style={{
                              background:
                                rowData?.color && rowData?.color(row)
                                  ? rowData.color(row) === 'black'
                                    ? rowFocused &&
                                      rowFocused.esteeId === row.esteeId
                                      ? '#303030'
                                      : ''
                                    : rowData.color(row)
                                  : rowFocused &&
                                    rowFocused.esteeId === row.esteeId
                                  ? '#303030'
                                  : '',
                            }}
                          >
                            {rowData.renderHtml ? (
                              rowData.renderHtml(row, index, totalPosts)
                            ) : (
                              <>
                                {row[rowData.value]
                                  ? row[rowData.value]
                                  : 'N/A'}
                              </>
                            )}
                          </TableDataCell>
                        );
                      })}
                    </TableRow>
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableHeadText colSpan={tableCoulumn?.length}>
                  {noRecordMsg ? (
                    loader ? (
                      ''
                    ) : (
                      <h5>{noRecordMsg}</h5>
                    )
                  ) : (
                    <h5>No records found</h5>
                  )}
                </TableHeadText>
              </TableRow>
            )}
          </tbody>
          {toDisplayFooter && tableFooter ? (
            <tfoot>
              <tr className={rowHeaderClass ? rowHeaderClass : ''}>
                {tableFooter.map((item, index) => {
                  return (
                    <th className={item.className ? item.className : ''}>
                      {item != null && item.data ? item.data : ''}
                    </th>
                  );
                })}
              </tr>
            </tfoot>
          ) : null}
        </Table>
      </div>
      {showPagination ? (
        <div className="pagination paginate align-items-center">
          <div className="enteries">
            {TableData.length > 0 ? (
              <p className={postsLength ? '' : 'no-status'}>
                {`Showing ${postsPerPage * currentPage - (postsPerPage - 1)} to 
                ${
                  postsPerPage * currentPage <
                  (totalPosts ? totalPosts.length : postsLength)
                    ? postsPerPage * currentPage
                    : totalPosts
                    ? totalPosts.length
                    : postsLength
                }
                of ${totalPosts ? totalPosts.length : postsLength} entries`}
              </p>
            ) : null}
          </div>
          <div className="peview text-right d-flex align-items-center">
            {elipsisPagination && postsLength ? (
              <MuiPagination
                count={Math.ceil(postsLength / postsPerPage)}
                page={currentPage}
                onChange={(e, value) => paginate(value)}
              />
            ) : totalPosts ? (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalPosts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : postsLength ? (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={postsLength}
                paginate={paginate}
                currentPage={currentPage}
                maxPosts={maxPosts}
              />
            ) : null}
          </div>
          <div style={{ width: '215px' }} className="table-pagination"></div>
        </div>
      ) : null}
    </div>
  );
};

TableComp.defaultProps = {
  showHeader: true,
  showPagination: false,
  style: {},
  sorting: false,
  sortColumn: '',
  sortingFuction: () => {},
};

export default TableComp;

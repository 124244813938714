import React, { useState } from 'react';
import './styles.css';
import { TabNavInner } from '../../../components';
import Heartbeat from './ops_heartbeat';
import OpsDataValidation from './ops_datavalidation';
import OpsTradeMatch from './ops_tradematch';
import UserMaintanance from './ops_usermain';

export default function OpsScreen(props) {
  const [tabSelected, setTabSelected] = useState(0);
  return (
    <div className="ops-main-container">
      <TabNavInner
        tabs={[
          { id: 0, title: 'Data Validation' },
          { id: 1, title: 'Ops User Heartbeat' },
          { id: 2, title: 'Trade Match' },
        ]}
        curSelect={tabSelected}
        setSelect={(val) => setTabSelected(val)}
      />
      {tabSelected === 1 && <Heartbeat />}
      {tabSelected === 0 && <OpsDataValidation />}
      {tabSelected === 2 && <OpsTradeMatch />}
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import './styles.css';
import CheckedIcon from '../../../assets/img/checked.svg';
import UncheckedIcon from '../../../assets/img/unchecked.svg';
import CompLabel from '../customComponent/compLabel';
import { DropdownIcon } from '../../../assets';
import { GenSearchBox } from '../..';

export default function MultiCheck(props) {
  const {
    label = '',
    options = [],
    onSelect = (val) => {},
    extStyles = {},
    placeHolder = 'SELECT',
    compSettings = {
      inputWidth: '10vw',
      maxTextAllowed: 10,
      orientation: 'v',
      type: 1,
    },
  } = props;

  const ref = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);

  const selected = options
    .filter((item) => item.state)
    .map((item) => item.title)
    .join(', ');

  function loadComponent() {
    switch (compSettings.type) {
      case 1:
        return (
          <div
            className="multiCheck-container"
            style={{ ...extStyles, width: compSettings.inputWidth }}
          >
            {label.length > 0 && <p className="multiCheck-label">{label}</p>}
            <div className="multiCheck-options">
              {options.map((item) => (
                <CheckBox
                  state={item.state || false}
                  label={item.title || ''}
                  onSelect={(val) => onSelect(val, !item.state)}
                />
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <CompLabel
            orientation={compSettings.orientation}
            label={label}
            extStyles={extStyles}
          >
            <div
              className="multiCheck-select-box"
              onClick={() => setVisible(true)}
              ref={ref}
              style={{ width: compSettings?.inputWidth || '90%' }}
            >
              <span className="multiCheck-select-selected">{selected}</span>
              <img
                alt="ddIcon"
                src={DropdownIcon}
                className="multicheck-select-ddicon"
              />
              {visible && (
                <div className="dd-multiselect-area">
                  <GenSearchBox version={2} />
                  <div className="dd-meultiselect-scrollable">
                    {options.map((item) => (
                      <CheckBox
                        state={item.state || false}
                        label={item.title || ''}
                        onSelect={(val) => onSelect(val, !item.state)}
                        extStyles={{
                          margin: '1vh auto 1vh 0.5vw',
                          width: 'fit-content',
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </CompLabel>
        );
      default:
        break;
    }
  }
  return loadComponent();
}

export function CheckBox(props) {
  const { state = false, label = '', onSelect, extStyles = {} } = props;
  return (
    <span
      className="multiCheck-option-cont"
      onClick={() => onSelect(label)}
      style={extStyles}
    >
      <img
        className="multicheck-opt-check"
        src={state ? CheckedIcon : UncheckedIcon}
        alt="check"
      />
      {label.length > 0 && <p className="multicheck-opt-label">{label}</p>}
    </span>
  );
}

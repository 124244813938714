import { useState, useEffect, useCallback } from 'react';
import './styles.css';
import {
  InputField,
  MultiSelect,
  Switch,
  Button,
} from '../../../../components';
import { UMMenueGen } from '../index';
import OpsApiCalls from '../../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { OpsReducerActions } from '../../redux';
import { MakeRequest } from '../../../../utils';

export default function CreateUserForm(props) {
  const { edit, data = {}, togglepopup = () => {} } = props;
  const dispatch = useDispatch();

  const { userMaintenance } = useSelector((state) => state.OpsReducer);

  const [displayDataState, setDataState] = useState({
    userName: '',
    fullName: '',
    email: '',
    mobNo: '',
    state: '',
    remark: '',
    roles: [],
    menu: [],
  });
  const [roleChanged, setRoleChanged] = useState(false);

  const [error, setError] = useState('');

  async function initDataLoad() {
    const allRoles = userMaintenance.manage_roles;
    const allMenu = await OpsApiCalls.createUserInitialMenu();
    const tempData = {
      ...displayDataState,
      roles: allRoles.map((item) => {
        return { ...item, state: false };
      }),
      menu: allMenu,
    };
    setDataState(tempData);
  }

  useEffect(() => {
    if (Object.keys(data).length !== 0 && edit) {
      setDataState(data);
    } else {
      initDataLoad();
    }
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      if (
        displayDataState.roles &&
        displayDataState.roles.length > 0 &&
        roleChanged
      ) {
        const ids =
          displayDataState.roles &&
          displayDataState.roles
            .filter((item) => item.state)
            .map((item) => item.id);

        const result = await OpsApiCalls.getMenuRespectToRole(ids);
        setDataState({ ...displayDataState, menu: result });
        setRoleChanged(false);
      }
    };
    apiCall();
  }, [displayDataState, roleChanged]);

  const mulSelRoles = useCallback(
    (val) => {
      const roles = displayDataState.roles.map((item) => {
        if (item.title === val) {
          return { ...item, state: !item.state };
        } else {
          return item;
        }
      });
      setDataState({ ...displayDataState, roles: roles });
      setRoleChanged(true);
    },
    [displayDataState, setDataState, setRoleChanged]
  );

  return (
    <div className="UM-cuf">
      <div className="UM-cuf-title-cont">
        <span className="UM-cuf-title">Create User</span>
      </div>
      <div className="UM-cuf-container">
        <div className="align-horizontally">
          <InputField
            label="User Name"
            inputWidth="15vw"
            extStyles={{ margin: '1vh auto 1vh auto' }}
            placeHolder="Enter Dealer Name"
            onValueChange={(val) =>
              setDataState({ ...displayDataState, userName: val })
            }
            value={displayDataState.userName}
            type="text"
          />
          <InputField
            label="Full Name of User"
            inputWidth="15vw"
            placeHolder="Enter User Name"
            extStyles={{ margin: '1vh auto 1vh auto' }}
            onValueChange={(val) =>
              setDataState({ ...displayDataState, fullName: val })
            }
            value={displayDataState.fullName}
            type="text"
          />
          <InputField
            label="Email ID"
            inputWidth="15vw"
            placeHolder="Enter Email ID"
            extStyles={{ margin: '1vh auto 1vh auto' }}
            onValueChange={(val) =>
              setDataState({ ...displayDataState, email: val })
            }
            value={displayDataState.email}
            type="text"
          />
        </div>
        <div className="align-horizontally">
          <InputField
            label="Mobile No."
            inputWidth="15vw"
            placeHolder="Enter Mobile No"
            extStyles={{ margin: '1vh auto 1vh auto' }}
            onValueChange={(val) =>
              setDataState({ ...displayDataState, mobNo: val })
            }
            value={displayDataState.mobNo}
            type="text"
          />
          <MultiSelect
            label="Role"
            compSettings={{ type: 2, inputWidth: '15vw', maxTextAllowed: 15 }}
            options={displayDataState.roles}
            extStyles={{ margin: '1vh auto 1vh auto' }}
            onSelect={mulSelRoles}
          />
          <Switch
            label="Active"
            extStyles={{ margin: 'auto' }}
            state={displayDataState.state}
            onChange={(val) =>
              setDataState({ ...displayDataState, state: val })
            }
          />
        </div>
        <UMMenueGen
          data={displayDataState.menu}
          settings={{
            type: 1,
            style: {
              margin: '2vh auto 0 auto',
              gridColumn: '1/ span 3',
              width: '48vw',
            },
          }}
          changeMenu={(val) => {}}
        />
        <InputField
          label="Remark"
          inputWidth="48vw"
          placeHolder="Enter Remark"
          extStyles={{ margin: '1vh auto 1vh auto', gridColumn: '1 / span 3' }}
          onValueChange={(val) =>
            setDataState({ ...displayDataState, remark: val })
          }
          value={displayDataState.remark}
          type="text"
        />
      </div>
      <div className="umb-popupform-footer">
        <span className="unb-error-message">{error}</span>
        <Button
          settings={{
            fill: true,
            title: edit ? 'EDIT' : 'CREATE',
            styles: { margin: '0 1vw 0 auto', width: '10vw' },
          }}
          click={async () => {
            const request = {
              name: displayDataState.fullName,
              username: displayDataState.userName,
              email: displayDataState.email,
              mobile: displayDataState.mobNo,
              status: displayDataState.state ? 1 : 0,
              role: displayDataState.roles
                .filter((item) => item.state)
                .map((item) => item.id),
              remarks: displayDataState.remark,
            };
            let result;

            if (edit) {
              result = await MakeRequest('/ops/modifyUser').post({
                ...request,
                id: data.userId,
              });
            } else {
              result = await OpsApiCalls.postCreateUser(request);
            }

            if (result.status === 200) {
              OpsReducerActions.refreshOpsUserMainTableData(dispatch);
              togglepopup();
            } else {
              setError(result.message);
            }
          }}
        />

        <Button
          settings={{
            fill: false,
            title: 'CLOSE',
            styles: { margin: '0 1vw 0 1vw', width: '10vw' },
          }}
          click={togglepopup}
        />
      </div>
    </div>
  );
}

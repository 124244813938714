import React, { useEffect, useState, useRef } from 'react';
import './TradingHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import { strategyAnalyzerTable } from '../../../actions/strategyAnalyzerAction';
import RiskViewerWindow from '../RiskViewer/RiskViewerWindow';
import StrategyAnalyzerWindow from '../StrategyAnalyzer/StrategyAnalyzerWindow';
import OrderBook from '../OrderBook/OrderBook';
import InnerNavbar from '../../sharedComponents/InnerNavbar/InnerNavbar';
import { PAGE_TITLES } from '../../../utils/constant';

const TradingWindowHeader = () => {
  const [dashboard, setDashboard] = useState(localStorage.getItem('header'));
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const tableRef = useRef(null);
  const analyzereRef = useRef(null);
  const selectBoxRef = useRef(null);

  const {
    strategyAnalyzerReducer: { searchString },
  } = useSelector((state) => state);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      handleScroll(e);
    });
    return () => {
      window.removeEventListener('scroll', (e) => {
        handleScroll(e);
      });
    };
  }, []);

  useEffect(() => {
    if (searchString === '') {
      dispatch(strategyAnalyzerTable());
    }
  }, [searchString]);

  const handleScroll = (e) => {
    let scrollRefHeight =
      scrollRef && scrollRef.current && scrollRef.current.offsetHeight;
    let analyzereRefHeight =
      analyzereRef && analyzereRef.current && analyzereRef.current.offsetHeight;

    if (scrollRef?.current && analyzereRef?.current && tableRef?.current) {
      scrollRef.current.style.position = 'sticky';
      scrollRef.current.style.top = '0px';
      scrollRef.current.style.zIndex = '9';

      analyzereRef.current.style.position = 'sticky';
      analyzereRef.current.style.background = 'white';
      analyzereRef.current.style.top = scrollRefHeight + 'px';
      analyzereRef.current.style.zIndex = '9';

      if (selectBoxRef?.current) {
        selectBoxRef.current.style.position = 'sticky';
        selectBoxRef.current.style.background = 'white';
        selectBoxRef.current.style.top =
          scrollRefHeight + analyzereRefHeight + 'px';
        selectBoxRef.current.style.zIndex = '1';
      }

      tableRef.current.querySelector('thead').style.position = 'sticky';
      tableRef.current.querySelector('thead').style.top =
        scrollRefHeight +
        analyzereRefHeight +
        (selectBoxRef?.current && selectBoxRef?.current?.offsetHeight
          ? selectBoxRef?.current?.offsetHeight
          : 0) +
        'px';
      tableRef.current.querySelector('thead').style.zIndex = '1';
    }
  };

  const renderComponent = () => {
    switch (dashboard) {
      case 'strategyanalyzer':
        return (
          <StrategyAnalyzerWindow
            selectBoxRef={selectBoxRef}
            analyzereRef={analyzereRef}
          />
        );
      case 'riskviewer':
        return <RiskViewerWindow />;
      case 'order/trades':
        return <OrderBook />;

      default:
        return (
          <StrategyAnalyzerWindow
            selectBoxRef={selectBoxRef}
            analyzereRef={analyzereRef}
          />
        );
    }
  };

  return (
    <React.Fragment>
      <title>{PAGE_TITLES[dashboard]}</title>
      <div className="trading-container" ref={tableRef}>
        <div className="TradingWindowHeader-header">
          <InnerNavbar
            navClass="trading-dashboard-menu"
            id="scrollheder"
            setDashboard={setDashboard}
            activeDashboard={dashboard}
          />
        </div>
        <div className="TradingWindowHeader-body">{renderComponent()}</div>
      </div>
    </React.Fragment>
  );
};

export default TradingWindowHeader;

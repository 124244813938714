import { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../sharedComponents/Table/Table';
import './OrderBook.scss';
import {
  orderDrillDown,
  cancelManualOrder,
  updatePageNumberOrder,
  updatePostPerPageOrder,
  ruleOBOrderDrillDown,
  ruleOBUpdatePageNumberOrder,
  ruleOBUpdatePostPerPageOrder,
} from '../../../actions/LiveTradingAction';
import { TableEntries } from '../../sharedComponents/TableEntries/TableEntries';
import { PAGE_TITLES, ruleOBReqTypeEnum } from '../../../utils/constant';
import Pen from '../../../assets/img/pen_blue.svg';
import Close from '../../../assets/img/Group 162770.svg';
import refresh from '../../../assets/img/refresh.png';
import exicon from '../../../assets/img/ex-icon.png';
import Popup from '../../sharedComponents/Popup/Popup';
import './OrderBook.scss';
import { seo } from '../../../utils/seo';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../../sharedComponents/keyPressEventHandler';
import { UnCheckedIcon, CheckedIcon, ColumnArrangeIcon } from '../../../assets';
import {
  GenButton,
  DropDown,
  GenericPopup,
  ViewDisplayTable,
  InputField,
  Switch,
  MultiSelect,
} from '../..';
import MakeRequest from '../../../utils/apiRequest';
import StradeLogo from '../../../assets/img/Strade_LOGO.svg';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import { TableSettings } from '../../sharedComponents/customComponent';
import { TableOrderChangeActions } from '../../../reducers/table_order_reducer';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const tradeStatusFilterList = [
  'All',
  'Cancelled',
  'Executed',
  'Partially Filled',
  'Rejected',
  'TRADE_MODIFIED',
  'ODIN_TRADE_MODIFIED',
];

const AllOpenOrder = (props) => {
  const [stateSelection, setStateSelection] = useState({
    selected: {},
    options: tradeStatusFilterList.map((item) => {
      return { title: item, state: true };
    }),
  });
  const urlParams = new URLSearchParams(window.location.search);
  let accountId = urlParams.get('accId');
  if (accountId == -1) {
    accountId = null;
  }
  let ruleId = urlParams.get('ruleId');
  const tableRef = useRef(null);
  const drilldownHeaderRef = useRef(null);
  const {
    liveTradingReducer: {
      postPerPage,
      pageNumber,
      orderDrillData,
      drillLoaded,
      ruleOBPostPerPage,
      ruleOBPageNumber,
      ruleOBOrderDrillData,
      ruleOBDrillLoaded,
    },
  } = useSelector((state) => state);
  const [showPosts] = useState([5, 10, 20, 30]);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [strategyData, setStrategyData] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([...setCurrentPostfun()]);
  const [userShow, setUserShow] = useState([...strategyData]);
  const [successPopup, setSuccessPopup] = useState({
    type: '',
    message: '',
    buttons: 'singleButton',
    state: false,
    header: '',
  });
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [showPopup, setShowPopup] = useState({ status: false, message: '' });
  const [trade_mod_info, set_trade_mod_info] = useState({
    pts: [],
    infoData: {},
  });
  const [modify_popup, set_modify_popup] = useState({ state: false, data: [] });
  const [modif_acc, set_modif_acc] = useState({
    prev_acc: '',
    selected_acc: '',
    remark: '',
    isInternalModify: false,
    accounts: [],
  });
  const [error, setError] = useState('');
  const [toaster, setToaster] = useState(false);
  const [toastMsg, setToastMsg] = useState('');
  const [tradeStatusFilterSelected, setTradeStatusFilterSelected] = useState(
    'All'
  );
  const [tradesFilter, setTradesFilter] = useState([]);
  const [tradeModifyLoader, setTradeModifyLoader] = useState(false);
  const [apiTradeSummary, setAPITradeSummary] = useState({});

  // set functionalty for popup
  const actionOnpopup = () => {
    // if (type == 'CLOSE') {
    setSuccessPopup({
      type: '',
      message: '',
      buttons: 'singleButton',
      state: false,
      header: '',
    });
    setShowSuccessPopUp(false);
    // }
  };

  function get_common_acc() {
    let acc =
      trade_mod_info.pts.length > 0 ? trade_mod_info.pts[0].AccountName : -1;
    if (acc === -1) {
      return (
        <span className="orderbook-error-text">No Common Account ID !</span>
      );
    }
    for (let order of trade_mod_info.pts) {
      if (order.AccountName !== acc) {
        return (
          <span className="orderbook-error-text">No Common Account ID !</span>
        );
      }
    }
    return <span className="orderbook-acc-text">{acc}</span>;
  }

  const dispatch = useDispatch();
  const { id: paramId, type: paramType } = useParams();

  let security = window.atob(paramId ? paramId : '');
  let drillDownType = window.atob(paramType ? paramType : '');

  if (security == -1) {
    security = null;
  }

  const handleScroll = (e) => {
    let drilldownHeaderHeight =
      drilldownHeaderRef &&
      drilldownHeaderRef.current &&
      drilldownHeaderRef.current.offsetHeight;

    if (tableRef.current && drilldownHeaderRef.current) {
      drilldownHeaderRef.current.style.position = 'sticky';
      drilldownHeaderRef.current.style.top = '0px';
      drilldownHeaderRef.current.style.background = 'white';
      drilldownHeaderRef.current.style.zIndex = '2';

      if (tableRef.current.querySelector('thead')) {
        tableRef.current.querySelector('thead').style.position = 'sticky';
        tableRef.current.querySelector('thead').style.top =
          drilldownHeaderHeight + 'px';
        tableRef.current.querySelector('thead').style.zIndex = '2';
      }
    }
  };

  useEffect(() => {
    if (trade_mod_info.pts.length == 0) {
      let tradeModInfo = {
        buyQty: 0,
        buyValue: 0,
        buyLotQty: 0,
        sellQty: 0,
        sellValue: 0,
        sellLotQty: 0,
        lotSize: 1,
      };

      if (
        orderDrillData &&
        orderDrillData.entries &&
        Array.isArray(orderDrillData.entries)
      ) {
        for (let tradeInfo of orderDrillData.entries) {
          tradeModInfo.lotSize = tradeInfo.LotSize;
          if (tradeInfo.BuySellIndicator === 1) {
            tradeModInfo.buyQty += tradeInfo.TradedQty;
            tradeModInfo.buyValue +=
              tradeInfo.TradedQty * tradeInfo.TradedPrice;
            if (tradeInfo.LotSize) {
              tradeModInfo.buyLotQty += parseInt(
                tradeInfo.TradedQty / tradeInfo.LotSize
              );
            } else {
              tradeModInfo.buyLotQty = -1;
            }
          } else {
            tradeModInfo.sellQty += tradeInfo.TradedQty;
            tradeModInfo.sellValue +=
              tradeInfo.TradedQty * tradeInfo.TradedPrice;
            if (tradeInfo.LotSize) {
              tradeModInfo.sellLotQty += parseInt(
                tradeInfo.TradedQty / tradeInfo.LotSize
              );
            } else {
              tradeModInfo.sellLotQty = -1;
            }
          }
        }
        set_trade_mod_info({
          ...trade_mod_info,
          pts: [],
          infoData: tradeModInfo,
        });
      } else if (
        ruleId &&
        ruleOBOrderDrillData &&
        ruleOBOrderDrillData.entries &&
        Array.isArray(ruleOBOrderDrillData.entries)
      ) {
        for (let tradeInfo of ruleOBOrderDrillData.entries) {
          tradeModInfo.lotSize = tradeInfo.LotSize;
          if (tradeInfo.BuySellIndicator === 1) {
            tradeModInfo.buyQty += tradeInfo.TradedQty;
            tradeModInfo.buyValue +=
              tradeInfo.TradedQty * tradeInfo.TradedPrice;
            if (tradeInfo.LotSize) {
              tradeModInfo.buyLotQty += parseInt(
                tradeInfo.TradedQty / tradeInfo.LotSize
              );
            } else {
              tradeModInfo.buyLotQty = -1;
            }
          } else {
            tradeModInfo.sellQty += tradeInfo.TradedQty;
            tradeModInfo.sellValue +=
              tradeInfo.TradedQty * tradeInfo.TradedPrice;
            if (tradeInfo.LotSize) {
              tradeModInfo.sellLotQty += parseInt(
                tradeInfo.TradedQty / tradeInfo.LotSize
              );
            } else {
              tradeModInfo.sellLotQty = -1;
            }
          }
        }
        set_trade_mod_info({
          ...trade_mod_info,
          pts: [],
          infoData: tradeModInfo,
        });
      }
    }
  }, [orderDrillData, ruleOBOrderDrillData]);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const { data } = await MakeRequest(
          `/admin/user/${localStorage.getItem('customerId')}`
        ).get();
        if (orderDrillData && orderDrillData.entries)
          setTradesFilter(orderDrillData.entries);
        else if (
          ruleId &&
          ruleOBOrderDrillData &&
          ruleOBOrderDrillData.entries
        ) {
          setTradesFilter(ruleOBOrderDrillData.entries);
        }
        const temp_accs = {
          selected_acc: '',
          accounts: [...data.data.tradingAccountDetails],
        };
        set_modif_acc(temp_accs);
      } catch (error) {
        console.log(error.response);
      }
    };
    loadAccounts();
  }, []);

  async function partially_modify_order() {
    setTradeModifyLoader(true);
    try {
      const req_pack = {
        newAccountId: '',
        trades: [],
        remarks: '',
        isInternalModify: false,
      };
      req_pack.isInternalModify = modif_acc.isInternalModify;
      for (let trade of trade_mod_info.pts) {
        // Validate if ODIN_TRADE_MODIFIED status trade is being trade modifie
        if (
          trade.Status == 'ODIN_TRADE_MODIFIED' &&
          !modif_acc.isInternalModify
        ) {
          setError(
            'Trade with status ODIN_TRADE_MODIFIED cannot be Trade modify, please check "To Same Family" flag'
          );
          return;
        }

        req_pack.trades.push({
          Account_ID: trade.AccountId,
          ContractId: trade.ContractId,
          Estee_ID: trade.Estee_ID,
          Buy_Sell_Indicator: trade.BuySellIndicator,
          Traded_Price: trade.TradedPrice,
          Traded_Quantity: trade.TradedQty,
          execid: trade.ExecId,
          Sub_Strategy_ID: trade.StrategyId,
          Exchange_Order_ID: trade.ExchangeOrderId,
          Link_ID: trade.RuleId.split('-')[0],
          IsManual: trade.IsManual != null ? trade.IsManual : false,
          Status: trade.Status,
        });
      }
      if (modif_acc.selected_acc.length === 0) {
        setError('Need to select new Account');
        return;
      }
      req_pack.newAccountId = modif_acc.accounts.filter((item) => {
        return (
          item.tradingAccountId + ':' + item.accountName ===
          modif_acc.selected_acc
        );
      })[0].tradingAccountId;
      req_pack.remarks = modif_acc.remark;
      // if (!req_pack.remarks || (req_pack.remarks && req_pack.remarks.length === 0)) {
      //   setError('Remarks Cannot be empty');
      //   return;
      // }
      let acc =
        trade_mod_info.pts.length > 0 ? trade_mod_info.pts[0].AccountId : -1;
      if (acc === -1) {
        setError('Need to add atlease one Order');

        return;
      }
      for (let order of trade_mod_info.pts) {
        if (order.AccountId !== acc) {
          setError('Multiple Account IDs detected');
          return;
        }
      }
      try {
        const res = await MakeRequest('/orders/orderBook/drilldown/edit').post(
          req_pack
        );
        if (res.status === 400) {
          setError(res.data.data.message);
          setSuccessPopup({
            type: '',
            message: '',
            buttons: 'singleButton',
            state: false,
            header: '',
          });
          setShowSuccessPopUp(false);
        } else {
          // set show popup true
          setSuccessPopup({
            ...successPopup,
            header: 'TRADE MODIFY REQ SENT TO STRATEGY',
            buttons: 'singleButton',
            message: res.data.data.message,
          });
          setShowSuccessPopUp(true);
          set_modify_popup({ ...modify_popup, state: false });
        }
      } catch (error) {
        setError(error.response.message);
      }
    } catch (err) {}
    setTradeModifyLoader(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      handleScroll(e);
    });
    return () => {
      window.removeEventListener('scroll', (e) => {
        handleScroll(e);
      });
    };
  }, []);

  const handleShowPost = (e) => {
    if (e.target.value !== '') {
      if (!ruleId) {
        dispatch(updatePageNumberOrder(1));
        dispatch(updatePostPerPageOrder(Number(e.target.value)));
        setPostsPerPage(e.target.value);
        dispatch(
          orderDrillDown(
            {
              type: Number(drillDownType),
              security: security,
              accountId: accountId,
            },
            stateSelection.options.map((item) => {
              if (item.title !== 'All' && item.state) {
                return item.title;
              }
            })
          )
        );
      } else {
        dispatch(ruleOBUpdatePageNumberOrder(1));
        dispatch(ruleOBUpdatePostPerPageOrder(Number(e.target.value)));
        setPostsPerPage(e.target.value);
        dispatch(
          ruleOBOrderDrillDown(
            {
              positionId: security,
              accountId: accountId,
              ruleId: ruleId,
              reqTypeEnum: drillDownType,
            },
            stateSelection.options
              .filter((item) => item.title !== 'All')
              .map((item) => {
                if (item.title !== 'All' && item.state) {
                  return item.title;
                }
              })
          )
        );
      }
    }
  };
  useEffect(() => {
    fetchDrildownData();
  }, [drillDownType]);

  const fetchDrildownData = () => {
    if (!ruleId) {
      dispatch(
        orderDrillDown(
          {
            type: Number(drillDownType),
            security: security,
            accountId: accountId,
          },
          stateSelection.options
            .filter((item) => item.title !== 'All' && item.state)
            .map((item) => item.title)
        )
      );
      seo({ title: 'Current Orders' });
    } else {
      dispatch(
        ruleOBOrderDrillDown(
          {
            positionId: security,
            accountId: accountId,
            ruleId: ruleId,
            reqTypeEnum: drillDownType,
          },
          stateSelection.options
            .filter((item) => item.title !== 'All' && item.state)
            .map((item) => item.title)
        )
      );
      seo({ title: 'Current Orders' });
    }
  };
  const editManualWindow = (id) => {
    let newWindow = window.open(
      `/window/manual-order/edit/${window.btoa(id)}`,
      '_blank',
      'top=100, left=100, width=800, height=375, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  const getCancelManualoOrderApi = (orderId) => {
    dispatch(cancelManualOrder([orderId])).then((response) => {
      setShowPopup({ status: true, message: response.message });
    });
  };

  function new_font(content, ext_styles = {}) {
    return (
      <span className="order-trades-new-font" style={ext_styles}>
        {content}
      </span>
    );
  }

  function table_col_val(header, key, columnId) {
    return {
      col_id: columnId,
      lebel: new_font(header, { whiteSpace: 'nowrap' }),
      className: 'tradebook_exchange',
      renderHtml: (params) => {
        return new_font(typeof key === 'string' ? params[key] : key, {
          whiteSpace: 'nowrap',
          margin: '0 0.5vw 0 0.5vw',
        });
      },
    };
  }

  const filterTableColumn = (table_coulumn) => {
    let columns = table_coulumn.filter((el) =>
      drillDownType !== '1' || (ruleId && drillDownType === '1')
        ? el.lebel !== 'Edit'
        : el
    );
    table_coulumn = columns.filter((el) =>
      drillDownType !== '1' || (ruleId && drillDownType === '1')
        ? el.lebel !== 'Cancel'
        : el
    );

    return table_coulumn;
  };
  const paginate = (pageNumber) => {
    console.log('Paginate function called');
    if (!ruleId) {
      dispatch(updatePageNumberOrder(pageNumber));
    } else {
      dispatch(ruleOBUpdatePageNumberOrder(pageNumber));
    }
    fetchDrildownData();
  };

  function setCurrentPostfun() {
    const indexOfLastPost = ruleId
      ? ruleOBPageNumber * postsPerPage
      : pageNumber * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsdata;
    if (strategyData) {
      currentPostsdata = strategyData.slice(indexOfFirstPost, indexOfLastPost);
    }
    return currentPostsdata;
  }

  useEffect(() => {
    if (userShow && currentPosts.length) {
      let currPage = userShow.length % currentPosts.length;
      if (!ruleId) {
        if (currPage === 0) {
          paginate(pageNumber === 1 ? pageNumber : pageNumber - 1);
        } else {
          paginate(pageNumber);
        }
      } else {
        if (currPage === 0) {
          paginate(
            ruleOBPageNumber === 1 ? ruleOBPageNumber : ruleOBPageNumber - 1
          );
        } else {
          paginate(ruleOBPageNumber);
        }
      }
    }
    setCurrentPostfun();
  }, [postsPerPage]);

  const {
    order_trades,
    win_OT_AOO,
    win_OT_OMO,
    win_OT_T,
    win_SA_T,
    win_SA_O,
  } = useSelector((state) => state.TableOrderReducer);

  const [table_column, set_table_column] = useState({
    display: [
      table_col_val('Exchange', 'Exchange', 'Exchange'),
      table_col_val('Exchange Segment', 'ExchangeSegment', 'ExchangeSegment'),
      table_col_val('Old Entity ID', 'OldAccountName', 'OldAccountName'),
      table_col_val('Entity ID', 'AccountName', 'AccountName'),
      table_col_val('Participant Code', 'ParticipantId', 'ParticipantId'),
      table_col_val('User ID', 'UserId', 'UserId'),
      table_col_val('Order ID', 'OrderId', 'Order ID'),
      table_col_val('Exchange Order No', 'ExchangeOrderId', 'ExchangeOrderId'),
      table_col_val('Exec Id', 'ExecId', 'ExecId'),
      table_col_val('Rule ID', 'RuleId', 'RuleId'),
      table_col_val('Security', 'Security', 'Security'),
      table_col_val('Trade Status', 'Status', 'Status'),
      table_col_val('Currency', 'Currency', 'Currency'),
      table_col_val('Order Price', 'OrderPrice', 'OrderPrice'),
      table_col_val('Order Qty', 'OrderQty', 'OrderQty'),
      table_col_val('Trigger Price', 'TradedPrice', 'TradedPrice'),
      table_col_val('Traded Qty', 'TradedQty', 'TradedQty'),
      {
        col_id: 'TradedQtyInLots',
        lebel: new_font('Traded Qty in Lots', { whiteSpace: 'nowrap' }),
        className: 'tradebook_exchange',
        renderHtml: (params) => {
          return new_font(
            params.LotSize ? params.TradedQty / params.LotSize : -1,
            {
              whiteSpace: 'nowrap',
              margin: '0 0.5vw 0 0.5vw',
            }
          );
        },
      },
      {
        col_id: 'BuySellIndicator',
        lebel: new_font('Buy/Sell', { whiteSpace: 'nowrap' }),
        className: 'tradebook_exchange',
        renderHtml: (params) => {
          return new_font(params.BuySellIndicator === 1 ? 'Buy' : 'Sell', {
            whiteSpace: 'nowrap',
            margin: '0 0.5vw 0 0.5vw',
          });
        },
      },
      table_col_val(
        'Exchange Account ID',
        'exchangeAccountId',
        'exchangeAccountId'
      ),
      table_col_val('Exchange Timestamp', 'LastUpdateTime', 'LastUpdateTime'),
      table_col_val('Remarks', 'Remarks', 'Remarks'),
      {
        col_id: 'editButton',
        lebel: 'Edit',
        renderHtml: (params) => {
          return (
            <>
              <span onClick={() => editManualWindow(params.OrderId)}>
                <img src={Pen} className="cursor-pointer" alt="img" />
              </span>
            </>
          );
        },
      },
      {
        col_id: 'cancelButton',
        lebel: 'Cancel',
        renderHtml: (params) => {
          return (
            <>
              <span
                onClick={() => {
                  getCancelManualoOrderApi([params.OrderId]);
                }}
              >
                <img src={Close} alt="img" />
              </span>
            </>
          );
        },
      },
    ],
    orignal: [
      table_col_val('Exchange', 'Exchange', 'Exchange'),
      table_col_val('Exchange Segment', 'ExchangeSegment', 'ExchangeSegment'),
      table_col_val('Old Entity ID', 'OldAccountName', 'OldAccountName'),
      table_col_val('Entity ID', 'AccountId', 'AccountId'),
      {
        col_id: 'AccountName',
        lebel: new_font('Entity Name', { whiteSpace: 'nowrap' }),
        className: 'tradebook_exchange',
        renderHtml: (params) => {
          return new_font(params['AccountName'].split('-')[1], {
            whiteSpace: 'nowrap',
            margin: '0 0.5vw 0 0.5vw',
          });
        },
      },
      table_col_val('Participant Code', 'ParticipantId', 'ParticipantId'),
      table_col_val('User ID', 'UserId', 'UserId'),
      table_col_val('Order ID', 'OrderId', 'Order ID'),
      table_col_val('Exchange Order No', 'ExchangeOrderId', 'ExchangeOrderId'),
      table_col_val('Exec Id', 'ExecId', 'ExecId'),
      table_col_val('Rule ID', 'RuleId', 'RuleId'),
      table_col_val('Security', 'Security', 'Security'),
      table_col_val('Trade Status', 'Status', 'Status'),
      table_col_val('Currency', 'Currency', 'Currency'),
      table_col_val('Order Price', 'OrderPrice', 'OrderPrice'),
      table_col_val('Order Qty', 'OrderQty', 'OrderQty'),
      table_col_val('Trigger Price', 'TradedPrice', 'TradedPrice'),
      table_col_val('Traded Qty', 'TradedQty', 'TradedQty'),
      {
        col_id: 'TradedQtyInLots',
        lebel: new_font('Traded Qty in Lots', { whiteSpace: 'nowrap' }),
        className: 'tradebook_exchange',
        renderHtml: (params) => {
          return new_font(
            params.LotSize ? params.TradedQty / params.LotSize : -1,
            {
              whiteSpace: 'nowrap',
              margin: '0 0.5vw 0 0.5vw',
            }
          );
        },
      },
      {
        col_id: 'BuySellIndicator',
        lebel: new_font('Buy/Sell', { whiteSpace: 'nowrap' }),
        className: 'tradebook_exchange',
        renderHtml: (params) => {
          return new_font(params.BuySellIndicator === 1 ? 'Buy' : 'Sell', {
            whiteSpace: 'nowrap',
            margin: '0 0.5vw 0 0.5vw',
          });
        },
      },
      table_col_val(
        'Exchange Account ID',
        'exchangeAccountId',
        'exchangeAccountId'
      ),
      table_col_val('Exchange Timestamp', 'LastUpdateTime', 'LastUpdateTime'),
      table_col_val('Remarks', 'Remarks', 'Remarks'),
      {
        col_id: 'editButton',
        lebel: 'Edit',
        renderHtml: (params) => {
          return (
            <>
              <span onClick={() => editManualWindow(params.OrderId)}>
                <img src={Pen} className="cursor-pointer" alt="img" />
              </span>
            </>
          );
        },
      },
      {
        col_id: 'cancelButton',
        lebel: 'Cancel',
        renderHtml: (params) => {
          return (
            <>
              <span
                onClick={() => {
                  getCancelManualoOrderApi([params.OrderId]);
                }}
              >
                <img src={Close} alt="img" />
              </span>
            </>
          );
        },
      },
    ],
  });
  const [table_settings, set_table_settings] = useState([
    {
      type: 'MAIN',
      content: [
        {
          id: 2,
          label: 'Columns',
          leftComp: ColumnArrangeIcon,
          rightComp: undefined,
          moreOpt: [{ label: 'Reorder / Hide & Unhide', id: 20 }],
        },
      ],
    },
  ]);

  async function colModifier(level, id, val) {
    const temp_index = level.split('-');
    switch (temp_index[0]) {
      case 'P':
        // * set parent data
        break;
      case 'C':
        if (temp_index[1] === '2' && id === 20) {
          let tempColumns = [...table_settings];
          tempColumns.push({ type: 'COL_RE' });
          set_table_settings(tempColumns);
        }
        break;
      case 'B':
        const tempColumn = [...table_settings];
        tempColumn.pop();
        set_table_settings(tempColumn);
        break;
    }
  }
  const CheckBox = styled.img`
    height: 4vh !important;
    width: 4vh !important;
  `;

  useEffect(() => {
    TableOrderChangeActions.set_window_OT_SA_data(
      dispatch,
      table_type_identifier()
    );

    const temp = {
      lebel: '',
      renderHtml: (params) => {
        const checked =
          trade_mod_info.pts.filter(
            (item) => item.ExchangeOrderId === params.ExchangeOrderId
          ).length > 0;

        return (
          <>
            {checked ? (
              <CheckBox
                src={CheckedIcon}
                onClick={() => {
                  let tradeModInfo = {
                    buyQty: 0,
                    buyValue: 0,
                    buyLotQty: 0,
                    sellQty: 0,
                    sellValue: 0,
                    sellLotQty: 0,
                    lotSize: 1,
                  };
                  for (let trade of trade_mod_info.pts) {
                    if (trade.ExchangeOrderId !== params.ExchangeOrderId) {
                      tradeModInfo.lotSize = trade.LotSize;
                      if (trade.BuySellIndicator == 1) {
                        tradeModInfo.buyQty += trade.Traded_Quantity;
                        tradeModInfo.buyValue +=
                          trade.Traded_Quantity * trade.TradedPrice;
                        if (trade.LotSize) {
                          tradeModInfo.buyLotQty += parseInt(
                            trade.Traded_Quantity / trade.LotSize
                          );
                        } else {
                          tradeModInfo.buyLotQty = -1;
                        }
                      } else {
                        tradeModInfo.sellQty += trade.Traded_Quantity;
                        tradeModInfo.sellValue +=
                          trade.Traded_Quantity * trade.TradedPrice;
                        if (trade.LotSize) {
                          tradeModInfo.sellLotQty += parseInt(
                            trade.Traded_Quantity / trade.LotSize
                          );
                        } else {
                          tradeModInfo.sellLotQty = -1;
                        }
                      }
                    }
                  }
                  set_trade_mod_info({
                    ...trade_mod_info,
                    pts: trade_mod_info.pts.filter(
                      (item) => item.ExchangeOrderId !== params.ExchangeOrderId
                    ),
                    infoData: tradeModInfo,
                  });
                }}
              />
            ) : (
              <CheckBox
                src={UnCheckedIcon}
                onClick={() => {
                  // Check if trade is in Executed / PartiallyFilled / ODIN / TRADE_MODIFY state
                  if (
                    ![
                      'Executed',
                      'Partially Filled',
                      'TRADE_MODIFIED',
                      'ODIN_TRADE_MODIFIED',
                    ].includes(params.Status)
                  ) {
                    return;
                  }
                  let tradeModInfo = {
                    buyQty: 0,
                    buyValue: 0,
                    buyLotQty: 0,
                    sellQty: 0,
                    sellValue: 0,
                    sellLotQty: 0,
                    lotSize: 1,
                  };
                  let allTrades = [...trade_mod_info.pts, params];
                  for (let trade of allTrades) {
                    tradeModInfo.lotSize = trade.LotSize;
                    if (trade.BuySellIndicator == 1) {
                      tradeModInfo.buyQty += trade.Traded_Quantity;
                      tradeModInfo.buyValue +=
                        trade.Traded_Quantity * trade.TradedPrice;
                      if (trade.LotSize) {
                        tradeModInfo.buyLotQty += parseInt(
                          trade.Traded_Quantity / trade.LotSize
                        );
                      } else {
                        tradeModInfo.buyLotQty = -1;
                      }
                    } else {
                      tradeModInfo.sellQty += trade.Traded_Quantity;
                      tradeModInfo.sellValue +=
                        trade.Traded_Quantity * trade.TradedPrice;
                      if (trade.LotSize) {
                        tradeModInfo.sellLotQty += parseInt(
                          trade.Traded_Quantity / trade.LotSize
                        );
                      } else {
                        tradeModInfo.sellLotQty = -1;
                      }
                    }
                  }
                  set_trade_mod_info({
                    ...trade_mod_info,
                    pts: [...trade_mod_info.pts, params],
                    infoData: tradeModInfo,
                  });
                }}
              />
            )}
          </>
        );
      },
    };
    if (drillDownType === '2' || (ruleId && drillDownType === '1')) {
      set_table_column({
        display: [temp, ...table_column.display],
        orignal: [temp, ...table_column.orignal],
      });
    }
  }, []);

  useEffect(() => {
    const temp = [];

    let check_array;
    switch (table_type_identifier()) {
      case 'OT_AOO':
        check_array = win_OT_AOO;
        break;
      case 'OT_OMO':
        check_array = win_OT_OMO;
        break;
      case 'OT_T':
        check_array = win_OT_T;
        break;
      case 'SA_O':
        check_array = win_SA_O;
        break;
      case 'SA_T':
        check_array = win_SA_T;
        break;
      default:
        check_array = order_trades;
        break;
    }

    for (let column of check_array.viewData) {
      const newColumn = table_column.orignal.filter(
        (item) => item.col_id === column.variableName
      )[0];
      if (newColumn) {
        temp.push(newColumn);
      }
    }
    if (table_type_identifier() === 'OT_OMO') {
      check_array.staticData = [
        ...check_array.staticData,
        {
          col_id: 'editButton',
          lebel: 'Edit',
          renderHtml: (params) => {
            return (
              <>
                <span onClick={() => editManualWindow(params.OrderId)}>
                  <img src={Pen} className="cursor-pointer" alt="img" />
                </span>
              </>
            );
          },
        },
        {
          col_id: 'cancelButton',
          lebel: 'Cancel',
          renderHtml: (params) => {
            return (
              <>
                <span
                  onClick={() => {
                    getCancelManualoOrderApi([params.OrderId]);
                  }}
                >
                  <img src={Close} alt="img" />
                </span>
              </>
            );
          },
        },
      ];
      temp.push({
        col_id: 'editButton',
        lebel: 'Edit',
        renderHtml: (params) => {
          return (
            <>
              <span onClick={() => editManualWindow(params.OrderId)}>
                <img src={Pen} className="cursor-pointer" alt="img" />
              </span>
            </>
          );
        },
      });
      temp.push({
        col_id: 'cancelButton',
        lebel: 'Cancel',
        renderHtml: (params) => {
          return (
            <>
              <span
                onClick={() => {
                  getCancelManualoOrderApi([params.OrderId]);
                }}
              >
                <img src={Close} alt="img" />
              </span>
            </>
          );
        },
      });
    }
    set_table_column({ ...table_column, display: temp });
  }, [
    order_trades.viewData,
    win_OT_AOO.viewData,
    win_OT_OMO.viewData,
    win_OT_T.viewData,
    win_SA_O.viewData,
    win_SA_T.viewData,
  ]);

  function table_type_identifier() {
    if (!ruleId) {
      if (drillDownType === '0') {
        return 'OT_AOO';
      } else if (drillDownType === '1') {
        return 'OT_OMO';
      } else {
        return 'OT_T';
      }
    } else {
      if (drillDownType == ruleOBReqTypeEnum.OPEN_ORDERS) {
        return 'SA_O';
      } else {
        return 'SA_T';
      }
    }
  }

  function prepareTradeModSummarEntryHelper(value, style = {}) {
    return (
      <div
        className="trade-mod-view-td-cont"
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          className="trade-mod-view-td-key"
          style={{ fontSize: '12px', ...style }}
        >
          {value}
        </span>
      </div>
    );
  }

  function prepareTradeModSummarEntry(args) {
    let data = [];

    data.push(prepareTradeModSummarEntryHelper('Buy', { color: 'green' }));
    data.push(
      prepareTradeModSummarEntryHelper(
        'Total Buy Qty: ' + apiTradeSummary.buyQty
      )
    );

    data.push(
      prepareTradeModSummarEntryHelper(
        'Total Buy Qty (In lots): ' +
          apiTradeSummary.buyQty / apiTradeSummary.lotSize
      )
    );

    data.push(
      prepareTradeModSummarEntryHelper(
        'Avg Buy Price: ' +
          (apiTradeSummary.buyQty == 0
            ? 0
            : parseFloat(
                apiTradeSummary.buyValue / apiTradeSummary.buyQty
              ).toFixed(4))
      )
    );

    data.push(prepareTradeModSummarEntryHelper('Sell', { color: 'red' }));

    data.push(
      prepareTradeModSummarEntryHelper(
        'Total Sell Qty: ' + apiTradeSummary.sellQty
      )
    );

    data.push(
      prepareTradeModSummarEntryHelper(
        'Total Sell Qty (In lots): ' +
          apiTradeSummary.sellQty / apiTradeSummary.lotSize
      )
    );
    data.push(
      prepareTradeModSummarEntryHelper(
        'Avg Sell Price: ' +
          (apiTradeSummary.sellQty == 0
            ? 0
            : parseFloat(
                apiTradeSummary.sellValue / apiTradeSummary.sellQty
              ).toFixed(4))
      )
    );

    return data;
  }

  function tradeStatusOnSelect(val) {
    let tempArr = [];
    for (let i = 0; i < orderDrillData.entries.length; i++) {
      if (orderDrillData.entries[i].Status == val) {
        tempArr.push(orderDrillData.entries[i]);
      }
    }
    setTradesFilter(tempArr);
    setTradeStatusFilterSelected(val);
  }

  async function fetchTradeSummaryFromBE() {
    try {
      let res = await MakeRequest('/orders/orderBook/drilldown/summary').post({
        exchangeOrderId: trade_mod_info.pts.map((item) => item.ExchangeOrderId),
      });

      if (res.data && res.data.data && res.data.data.data) {
        setAPITradeSummary(res.data.data.data);
      }
    } catch (err) {
      if (
        err.response.data &&
        err.response.data.data &&
        err.response.data.data.data
      ) {
        setAPITradeSummary(err.response.data.data.data);
      }
    }
  }

  console.log(drillDownType);

  return (
    <div className="order-book drilldown" ref={tableRef}>
      <Hotkeys
        keyName="esc"
        onKeyDown={(event) => {
          handleEscapeKeyEvent(event);
        }}
      />
      <Helmet>
        <title>{PAGE_TITLES.orerBookDrilldown}</title>
      </Helmet>
      {showPopup.status && (
        <Popup
          message={showPopup.message}
          setShowPopUp={() => setShowPopup({ status: false, message: '' })}
        />
      )}
      {showSuccessPopUp ? (
        <Popup
          popClass="dealerPopup"
          actionButton={actionOnpopup}
          popupDetails={successPopup}
          header={successPopup.header}
        />
      ) : null}
      <div className="table-counter-drilldown" ref={drilldownHeaderRef}>
        <Row>
          <Col md={2}>
            <div className="d-flex align-items-center">
              <TableEntries
                role="postPerPage"
                postList={showPosts}
                postHandler={handleShowPost}
                paginate={paginate}
                currentPage={!ruleId ? pageNumber : ruleOBPageNumber}
                value={postsPerPage}
              />
              <div className="buttons-wrapperdrill">
                {drillDownType === '0' || drillDownType === '1' ? (
                  <>
                    <button
                      className="refresh"
                      onClick={() => fetchDrildownData()}
                    >
                      <img src={refresh} alt="img" />
                      Refresh
                    </button>{' '}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Col>
          <Col md={6} className="heading-wrapper">
            {!(drillDownType === '2' || (drillDownType === '1' && ruleId)) ? (
              <div>
                <h3>
                  {!ruleId
                    ? drillDownType === '0'
                      ? 'All Open Orders'
                      : drillDownType === '1'
                      ? 'Open Manual Orders'
                      : ''
                    : `ALL ${
                        drillDownType == ruleOBReqTypeEnum.OPEN_ORDERS
                          ? 'Open Orders'
                          : 'Trades'
                      } for Rule ${ruleId}`}
                </h3>
              </div>
            ) : (
              <h3 className="Trades">
                <div className="total">
                  <ShowTitleWithValue
                    data={{
                      title: 'Total Buy QTY',
                      value: trade_mod_info.infoData.buyQty,
                      type: 1,
                    }}
                  />
                  <ShowTitleWithValue
                    data={{
                      title: 'Total Buy QTY(Lots):',
                      value: trade_mod_info.infoData.buyLotQty,
                      type: 1,
                    }}
                  />
                  <ShowTitleWithValue
                    data={{
                      title: 'Avg Buy Px:',
                      value: trade_mod_info.infoData.buyQty
                        ? parseFloat(
                            trade_mod_info.infoData.buyValue /
                              trade_mod_info.infoData.buyQty
                          ).toFixed(4)
                        : '0.0000',
                      type: 1,
                    }}
                  />
                  <ShowTitleWithValue
                    data={{
                      title: 'Total Sell QTY:',
                      value: trade_mod_info.infoData.sellQty,
                      type: 0,
                    }}
                  />
                  <ShowTitleWithValue
                    data={{
                      title: 'Total Sell QTY(Lots):',
                      value: trade_mod_info.infoData.sellLotQty,
                      type: 0,
                    }}
                  />
                  <ShowTitleWithValue
                    data={{
                      title: 'Avg Sell Px:',
                      value: trade_mod_info.infoData.sellQty
                        ? parseFloat(
                            trade_mod_info.infoData.sellValue /
                              trade_mod_info.infoData.sellQty
                          ).toFixed(4)
                        : '0.0000',
                      type: 0,
                    }}
                  />
                </div>
              </h3>
            )}
          </Col>
          <Col md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <TableSettings
                menuOptions={table_settings}
                clickAction={colModifier}
                updateColCallBack={() => {}}
                screenName={table_type_identifier()}
              />
              <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                {((!ruleId && drillDownType === '2') ||
                  (ruleId && drillDownType == '1')) && (
                  <>
                    <MultiSelect
                      label="Selection Type"
                      options={stateSelection.options}
                      compSettings={{
                        type: 2,
                        inputWidth: '14vw',
                        maxTextAllowed: 15,
                      }}
                      onSelect={(select) => {
                        const state = stateSelection.options.map((item) => {
                          if (select === 'All') {
                            return {
                              ...item,
                              state: !stateSelection.options[0].state,
                            };
                          } else if (item.title !== select) {
                            return item;
                          } else {
                            return { ...item, state: !item.state };
                          }
                        });

                        for (let i = 1; i < state.length; i++) {
                          if (!state[i].state) {
                            state[0].state = false;
                            break;
                          } else {
                            state[0].state = true;
                          }
                        }
                        setStateSelection({
                          ...stateSelection,
                          options: state,
                        });
                      }}
                    />
                    <GenButton
                      fill={false}
                      title={'Apply'}
                      clickAction={() => {
                        if (!ruleId) {
                          dispatch(
                            orderDrillDown(
                              {
                                type: Number(drillDownType),
                                security: security,
                                accountId: accountId,
                              },
                              stateSelection.options
                                .filter(
                                  (item) => item.state && item.title !== 'All'
                                )
                                .map((item) => item.title)
                            )
                          );
                        } else {
                          dispatch(
                            ruleOBOrderDrillDown(
                              {
                                positionId: security,
                                accountId: accountId,
                                ruleId: ruleId,
                                reqTypeEnum: drillDownType,
                              },
                              stateSelection.options
                                .filter(
                                  (item) => item.state && item.title !== 'All'
                                )
                                .map((item) => item.title)
                            )
                          );
                        }
                      }}
                    />
                  </>
                )}
              </div>
              {((!ruleId && drillDownType === '2') ||
                (ruleId && drillDownType == '1')) && (
                <GenButton
                  fill={false}
                  title="Modify"
                  clickAction={async () => {
                    if (
                      Array.isArray(trade_mod_info.pts) &&
                      trade_mod_info.pts.length == 0
                    ) {
                      setToastMsg('No trades selected');
                      setToaster(true);
                    } else {
                      // Fetch trade summary from backend
                      await fetchTradeSummaryFromBE();

                      set_modify_popup({ ...modify_popup, state: true });
                    }
                  }}
                />
              )}
              <div style={{ marginRight: '15px', paddingLeft: '10px' }}>
                <GenButton
                  fill={false}
                  icon={exicon}
                  title="EXPORT EXCEL"
                  clickAction={async () => {
                    // POST - API Update
                    let res = null;
                    if (!ruleId) {
                      switch (drillDownType) {
                        case '0':
                          res = await MakeRequest(
                            `/excel/generate/trade-book/${accountId}/${security}/0?isRuleTradeBook=false&pageNum=${pageNumber}&recordCount=${postPerPage}&status=${stateSelection.options
                              .filter((item) => item.title !== 'All')
                              .map((item) => item.title)
                              .join(',')}`
                          ).download('OpenOrders.xlsx');
                          break;
                        case '1':
                          res = await MakeRequest(
                            `/excel/generate/trade-book/${accountId}/${security}/1?isRuleTradeBook=false&pageNum=${pageNumber}&recordCount=${postPerPage}&status=${stateSelection.options
                              .filter((item) => item.title !== 'All')
                              .map((item) => item.title)
                              .join(',')}`
                          ).download('OpenManualOrders.xlsx');
                          break;
                        case '2':
                          res = await MakeRequest(
                            `/excel/generate/trade-book/${accountId}/${security}/2?isRuleTradeBook=false&pageNum=${pageNumber}&recordCount=${postPerPage}&status=${stateSelection.options
                              .filter((item) => item.title !== 'All')
                              .map((item) => item.title)
                              .join(',')}`
                          ).download('TradeBook.xlsx');
                          break;
                        default:
                          break;
                      }
                    } else {
                      switch (drillDownType) {
                        case '0':
                          res = await MakeRequest(
                            `/excel/generate/trade-book/${accountId}/${security}/0?ruleId=${ruleId}&isRuleTradeBook=true&pageNum=${ruleOBPageNumber}&recordCount=${ruleOBPostPerPage}&status=${stateSelection.options
                              .filter((item) => item.title !== 'All')
                              .map((item) => item.title)
                              .join(',')}`
                          ).download(`Rule_${ruleId}_OpenOrders.xlsx`);
                          break;
                        case '1': // show check box
                          res = await MakeRequest(
                            `/excel/generate/trade-book/${accountId}/${security}/1?ruleId=${ruleId}&isRuleTradeBook=true&pageNum=${ruleOBPageNumber}&recordCount=${ruleOBPostPerPage}&status=${stateSelection.options
                              .filter((item) => item.title !== 'All')
                              .map((item) => item.title)
                              .join(',')}`
                          ).download(`Rule_${ruleId}_TradeBook.xlsx`);
                          break;
                        default:
                          break;
                      }
                    }
                  }}
                  extStyle={{ margin: 'auto' }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="col-12">
        {(!ruleId && drillLoaded) || (ruleId && ruleOBDrillLoaded) ? (
          <Table
            tableCoulumn={filterTableColumn(
              (!ruleId && drillDownType === '2') ||
                (ruleId && drillDownType === '1')
                ? [
                    {
                      lebel: '',
                      renderHtml: (params) => {
                        const checked =
                          trade_mod_info.pts.filter(
                            (item) =>
                              item.ExchangeOrderId === params.ExchangeOrderId
                          ).length > 0;

                        return (
                          <>
                            {checked ? (
                              <CheckBox
                                src={CheckedIcon}
                                onClick={() => {
                                  let tradeModInfo = {
                                    buyQty: 0,
                                    buyValue: 0,
                                    buyLotQty: 0,
                                    sellQty: 0,
                                    sellValue: 0,
                                    sellLotQty: 0,
                                    lotSize: 1,
                                  };
                                  for (let trade of trade_mod_info.pts) {
                                    if (
                                      trade.ExchangeOrderId !==
                                      params.ExchangeOrderId
                                    ) {
                                      tradeModInfo.lotSize = trade.LotSize;
                                      if (trade.BuySellIndicator == 1) {
                                        tradeModInfo.buyQty += trade.TradedQty;
                                        tradeModInfo.buyValue += parseFloat(
                                          trade.TradedQty * trade.TradedPrice
                                        );
                                        if (trade.LotSize) {
                                          tradeModInfo.buyLotQty += parseInt(
                                            trade.TradedQty / trade.LotSize
                                          );
                                        } else {
                                          tradeModInfo.buyLotQty = -1;
                                        }
                                      } else {
                                        tradeModInfo.sellQty += trade.TradedQty;
                                        tradeModInfo.sellValue += parseFloat(
                                          trade.TradedQty * trade.TradedPrice
                                        );
                                        if (trade.LotSize) {
                                          tradeModInfo.sellLotQty += parseInt(
                                            trade.TradedQty / trade.LotSize
                                          );
                                        } else {
                                          tradeModInfo.sellLotQty = -1;
                                        }
                                      }
                                    }
                                  }
                                  set_trade_mod_info({
                                    ...trade_mod_info,
                                    pts: trade_mod_info.pts.filter(
                                      (item) =>
                                        item.ExchangeOrderId !==
                                        params.ExchangeOrderId
                                    ),
                                    infoData: tradeModInfo,
                                  });
                                }}
                              />
                            ) : (
                              <CheckBox
                                src={UnCheckedIcon}
                                onClick={() => {
                                  // Check if trade is in Executed / PartiallyFilled / ODIN / TRADE_MODIFY state
                                  if (
                                    ![
                                      'Executed',
                                      'Partially Filled',
                                      'TRADE_MODIFIED',
                                      'ODIN_TRADE_MODIFIED',
                                    ].includes(params.Status)
                                  ) {
                                    return;
                                  }

                                  let tradeModInfo = {
                                    buyQty: 0,
                                    buyValue: 0,
                                    buyLotQty: 0,
                                    sellQty: 0,
                                    sellValue: 0,
                                    sellLotQty: 0,
                                    lotSize: 1,
                                  };

                                  let allTradesWithCheckExOrdId = orderDrillData.entries.filter(
                                    (trade) =>
                                      trade.ExchangeOrderId ===
                                      params.ExchangeOrderId
                                  );
                                  let allTrades = [
                                    ...trade_mod_info.pts,
                                    ...allTradesWithCheckExOrdId,
                                  ];
                                  for (let trade of allTrades) {
                                    tradeModInfo.lotSize = trade.LotSize;
                                    if (trade.BuySellIndicator == 1) {
                                      tradeModInfo.buyQty += trade.TradedQty;
                                      tradeModInfo.buyValue += parseFloat(
                                        trade.TradedQty * trade.TradedPrice
                                      );
                                      if (trade.LotSize) {
                                        tradeModInfo.buyLotQty += parseInt(
                                          trade.TradedQty / trade.LotSize
                                        );
                                      } else {
                                        tradeModInfo.buyLotQty = -1;
                                      }
                                    } else {
                                      tradeModInfo.sellQty += trade.TradedQty;
                                      tradeModInfo.sellValue += parseFloat(
                                        trade.TradedQty * trade.TradedPrice
                                      );
                                      if (trade.LotSize) {
                                        tradeModInfo.sellLotQty += parseInt(
                                          trade.TradedQty / trade.LotSize
                                        );
                                      } else {
                                        tradeModInfo.sellLotQty = -1;
                                      }
                                    }
                                  }
                                  set_trade_mod_info({
                                    ...trade_mod_info,
                                    pts: [...trade_mod_info.pts, params],
                                    infoData: tradeModInfo,
                                  });
                                }}
                              />
                            )}
                          </>
                        );
                      },
                    },
                    ...table_column.display,
                  ]
                : table_column.display
            )}
            className={'table table-striped'}
            TableData={
              !ruleId
                ? orderDrillData
                  ? tradeStatusFilterSelected === 'All'
                    ? orderDrillData.entries
                    : tradesFilter
                  : []
                : ruleOBOrderDrillData
                ? ruleOBOrderDrillData.entries
                : []
            }
            showPagination={true}
            paginate={paginate}
            postsPerPage={!ruleId ? postPerPage : ruleOBPostPerPage}
            currentPage={!ruleId ? pageNumber : ruleOBPageNumber}
            postsLength={
              !ruleId
                ? orderDrillData
                  ? orderDrillData.numOfEntries
                  : 0
                : ruleOBOrderDrillData
                ? ruleOBOrderDrillData.numOfEntries
                : []
            }
            elipsisPagination={true}
          />
        ) : (
          <Spinner animation="border" variant="dark" />
        )}
      </div>
      {toaster ? (
        <SimpleSnackbar
          toaster={toaster}
          setToaster={setToaster}
          message={toastMsg}
        />
      ) : null}
      <GenericPopup
        type={1}
        state={modify_popup.state}
        toggleState={() => set_modify_popup({ state: false })}
      >
        <div className="orderbook-drilldown-container">
          <img src={StradeLogo} className="logo" />
          <span className="orderbook-drilldown-title">Moving Below Trades</span>
          <ViewDisplayTable
            title="Order Id"
            columns={4}
            content={trade_mod_info.pts.map((item) => (
              <span
                style={{
                  display: 'block',
                  width: '100%',
                  textAlign: 'center',
                  margin: '2vh 0',
                  fontSize: '12px',
                }}
              >
                {item.ExchangeOrderId}
              </span>
            ))}
            extStyles={{
              width: '60vw',
              margin: 'auto',
              maxHeight: '22vh',
              overflowY: 'auto',
            }}
          />
          <ViewDisplayTable
            title="Summary"
            columns={4}
            content={prepareTradeModSummarEntry(trade_mod_info.infoData)}
            extStyles={{
              width: '60vw',
              margin: 'auto',
              marginTop: '3vh',
              maxHeight: '22vh',
              overflowY: 'auto',
            }}
          />
          <div className="orderbook-sec1">
            <span className="orderbook-supporttext item1">From</span>
            <span className="orderbook-supporttext">To</span>
            <p>
              <span className="orderbook-accountlabel">Account ID: </span>
              <span>{get_common_acc()}</span>
            </p>
            <div className="orderbook-sec-1-1">
              <div className="switch-cont">
                <Switch
                  state={modif_acc.isInternalModify}
                  onChange={(val) =>
                    set_modif_acc({ ...modif_acc, isInternalModify: val })
                  }
                  label="Same Family"
                  // disable={true}
                />
              </div>
              <DropDown
                options={modif_acc.accounts.map(
                  (item) => item.tradingAccountId + ':' + item.accountName
                )}
                onSelect={(val) =>
                  set_modif_acc({ ...modif_acc, selected_acc: val })
                }
                label="Account ID/ Name"
                selected={
                  modif_acc.selected_acc ? modif_acc.selected_acc : 'Select'
                }
                disabled={modif_acc.accounts.length === 0}
                enable_search="true"
              />
            </div>
          </div>
          <ViewDisplayTable
            title="Remarks"
            columns={1}
            content={[
              <InputField
                label=""
                orientation="h"
                inputWidth={'59.8vw'}
                placeHolder=""
                value={modif_acc.remark}
                type="text"
                onValueChange={(val) =>
                  set_modif_acc({ ...modif_acc, remark: val })
                }
              />,
            ]}
            extStyles={{ width: '60vw', margin: 'auto' }}
          />
          {tradeModifyLoader ? (
            <div
              style={{
                margin: '3vh auto',
                width: '60vw',
                margin: 'auto',
                marginTop: '3vh',
              }}
            >
              <div style={{ margin: 'auto', width: 'fit-content' }}>
                <CircularProgress disableShrink size={25} />
              </div>
            </div>
          ) : (
            <GenButton
              title="SUBMIT"
              clickAction={partially_modify_order}
              extStyle={{
                back: {
                  margin: '3vh auto',
                  width: '60vw',
                  backgroundColor: '#F37C20',
                  borderRadius: '5px',
                },
                text: { fontSize: '2.6vh', color: 'white', margin: '1vh 0' },
              }}
              className="tradeModSubmit"
            />
          )}
        </div>
        <span
          className="orderbook-error-text"
          style={{ margin: '2vh auto 2vh auto' }}
        >
          {error}
        </span>
      </GenericPopup>
    </div>
  );
};

const STCont = styled.div`
  display: flex;
  flex-direction: column;
`;

const STTitle = styled.span`
  font-family: var(--def-font);
  font-size: var(--font-size);
  font-weight: 500;
  color: ${(prop) => (prop.val === 1 ? 'green' : 'red')};
`;

const STValue = styled.span`
  font-family: var(--def-font);
  font-size: var(--font-size);
`;

function ShowTitleWithValue({
  data = { title: 'Some title comes here', value: 123, type: 1 },
}) {
  return (
    <STCont>
      <STTitle val={data.type}>{data.title}</STTitle>
      <STValue>{data.value}</STValue>
    </STCont>
  );
}

export default AllOpenOrder;

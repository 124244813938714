import React, { Fragment, useRef, useState } from 'react';
import './styles.css';
import { FeedbackPopup, GenToolTip, GenericPopup } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { AlertIcon, StradeLogo } from '../../assets';
import { loginActions } from '../../reducers/loginReducer';
import { MakeRequest } from '../../utils';

export default function LicenseKey() {
  const { licensePopupState, licenseDaysRemaining } = useSelector(
    (state) => state.loginReducer
  );
  const dispatch = useDispatch();
  const [keyState, setKeyState] = useState('');
  const [errorState, setErrorState] = useState('');
  const [successPopup, setSuccessPopup] = useState('');
  const errorRef = useRef(null);

  function populateContent() {
    switch (licensePopupState) {
      case 1:
        return (
          <div className="LicenseKey-container-1">
            <img
              className="LicenseKey-companyLogo"
              alt="company-logo"
              src={StradeLogo}
            />
            <span className="LicenseKey-text1">License Key Expired</span>
            <span className="LicenseKey-text2">
              Please Activate your license key
            </span>
            <div className="LicenseKey-button-container1">
              <span
                className="LicenseKey-button-fill"
                onClick={() => loginActions.licencePopupState(dispatch, 2)}
              >
                Activate
              </span>
              <span
                className="LicenseKey-button-hollow"
                onClick={() => loginActions.licencePopupState(dispatch, 0)}
              >
                Cancel
              </span>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="LicenseKey-container-2">
            <img
              className="LicenseKey-companyLogo"
              alt="company-logo"
              src={StradeLogo}
            />
            <div className="LicenseKey-inputField-container">
              <span className="LicenseKey-label">Enter License Key</span>
              <input
                className="LicenseKey-input"
                type="text"
                value={keyState}
                onChange={(e) => setKeyState(e.target.value)}
              />
              {errorState.length > 0 ? (
                <GenToolTip containerRef={errorRef} tooltip={errorState}>
                  <div className="LicenseKey-errorContainer" ref={errorRef}>
                    <img
                      src={AlertIcon}
                      alt="alertIcon"
                      className="LicenseKey-errorIcon"
                    />
                    <span className="LicenseKey-error">
                      {`${errorState.slice(0, 40)}...`}
                    </span>
                  </div>
                </GenToolTip>
              ) : (
                <span></span>
              )}
            </div>
            <div className="LicenseKey-button-container1">
              <span
                className="LicenseKey-button-fill"
                onClick={async () => {
                  setErrorState('');
                  const response = await MakeRequest(
                    '/superadmin/license/renew'
                  ).post({ license: keyState });

                  if (response.status === 400) {
                    setErrorState(response.data.data.message);
                  } else {
                    setSuccessPopup(response.data.data.message);
                    loginActions.licencePopupState(dispatch, 0);
                  }
                }}
              >
                Activate
              </span>
              <span
                className="LicenseKey-button-hollow"
                onClick={() => loginActions.licencePopupState(dispatch, 0)}
              >
                Cancel
              </span>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="LicenseKey-container-1">
            <img
              className="LicenseKey-companyLogo"
              alt="company-logo"
              src={StradeLogo}
            />
            <span className="LicenseKey-text1">
              {`License Key will expire in ${licenseDaysRemaining} days`}{' '}
            </span>
            <span className="LicenseKey-text2">
              Please Activate your license key
            </span>
            <div className="LicenseKey-button-container1">
              <span
                className="LicenseKey-button-fill"
                onClick={() => loginActions.licencePopupState(dispatch, 2)}
              >
                Activate
              </span>
              <span
                className="LicenseKey-button-hollow"
                onClick={() => loginActions.licencePopupState(dispatch, 0)}
              >
                Cancel
              </span>
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  }

  return (
    <Fragment>
      <GenericPopup state={licensePopupState >= 1} type={1}>
        {populateContent()}
      </GenericPopup>
      <FeedbackPopup
        message={successPopup}
        acceptAction={() => setSuccessPopup('')}
      />
    </Fragment>
  );
}

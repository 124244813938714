import axios from 'axios';
import { url } from '../config/apiConfig';
import { MakeRequest } from '../utils';

export const strategyExchange = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/exchanges`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_EXCHANGE',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_EXCHANGE',
      payload: {
        name: '',
        description: '',
        sortingType: '',
        sortColumn: '',
        exchange: '',
        securities: [
          {
            instrument: '',
            securityName: '',
            expiry: '',
            strike: '',
            nickname: '',
            isAdditional: false,
            lotSize: '',
          },
        ],
        constants: [],
        entryLegs: [
          {
            legId: 1,
            condition: [
              {
                type: 'OPERAND',
                trigger: '',
              },
            ],
            position: [
              {
                orientation: 'Fwd',
                direction: 'BUY',
                securityIdx: '',
                security: '',
                pricing: [],
                quantity: [],
                lotSize: [],
                orderQty: '',
                totalQty: '',
              },
            ],
            algoExecution: [],
          },
        ],
        exitCondition: {
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          action: {
            enumName: '',
            args: [],
          },
        },
        isRuleReloadReq: false,
        ssid: '',
      },
    });
  }
};
// step 3 Select instrumenTypes
export const strategyInstrumenType = (templateId) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/instrumenTypes`,
      params: {
        templateId: templateId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_INSTRUMENT',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_INSTRUMENT',
      payload: [],
    });
  }
};
// step 3 Select SecurityName

export const getStrategySecurityNameOptions =
  (data) => async (dispatch, getState) => {
    let { strategyTemplateForm } = getState().strategyBuilderReducer;
    let templateForm = { ...strategyTemplateForm };
    const token = localStorage.getItem('token');
    templateForm.securities[data.index][data.key] = data.instrument;
    if (data.instrument === 'Equity') {
      templateForm.securities[data.index].expiry = '';
      templateForm.securities[data.index].strike = '';
    }
    if (data.instrument === 'Future') {
      templateForm.securities[data.index].strike = '';
    }
    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: templateForm,
    });

    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/securities`,
        params: {
          exchange: templateForm.exchange,
          instrument: data.instrument,
          securityFilter: templateForm.securityFilter,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'GET_SECURITY_NAME',
          payload: result.data.data,
          index: data.index,
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_SECURITY_NAME',
        payload: [],
        index: data.index,
      });
    }
  };
// step 3 Select expiry
export const getStrategyExpiresOptions =
  (data) => async (dispatch, getState) => {
    let { strategyTemplateForm } = getState().strategyBuilderReducer;
    let templateForm = { ...strategyTemplateForm };

    const token = localStorage.getItem('token');
    templateForm.securities[data.index][data.key] = data.securityName;
    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/nickname`,
        params: {
          exchange: templateForm.exchange.trim(),
          instrument: templateForm.securities[data.index].instrument,
          security: templateForm.securities[data.index].securityName,
          expiry: templateForm.securities[data.index].expiry,
          strike: templateForm.securities[data.index].strike,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (
        result &&
        result.data &&
        result.data.code &&
        result.data.code != 'INVALID'
      ) {
        let prevNickname = templateForm.securities[data.index].nickname;
        templateForm.entryLegs.map((a) =>
          a.position.map((b) => {
            if (b.security === prevNickname) {
              b.security = result.data.code;
              b.securityIdx = Number(data.index);
            }
          })
        );
        templateForm.securities[data.index].nickname = result.data.code;
      }
    } catch (e) {}
    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: templateForm,
    });
    let currentSec = templateForm.securities[data.index].nickname;
    for (let i = 0; i < strategyTemplateForm.entryLegs.length; i++) {
      strategyTemplateForm.entryLegs[i].position.map((el) => {
        if (
          el.security === currentSec &&
          currentSec !== '' &&
          templateForm.securities[data.index].instrument === 'Equity'
        ) {
          el.lotSize = '';
        }
        return el;
      });
    }

    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/expires`,
        params: {
          exchange: templateForm.exchange,
          instrument: templateForm.securities[data.index].instrument,
          security: data.securityName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'GET_EXPIRES',
          payload: result.data.data,
          index: data.index,
        });
        //for automatically changing the lot size in position
        let currentSec = templateForm.securities[data.index].nickname;

        for (let i = 0; i < strategyTemplateForm.entryLegs.length; i++) {
          strategyTemplateForm.entryLegs[i].position.map((el) => {
            if (el.security === currentSec && currentSec !== '') {
              el.lotSize = result.data.data[0].lotSize;
            }
            return el;
          });
        }
        // autoselecting 1st value in expiry dropdown & fetching strike list
        dispatch(
          getStrategyStrikesOptions({
            expiry: result.data.data[0].expiry,
            index: data.index,
            key: 'expiry',
            lotSize: result.data.data[0].lotSize,
            lotKey: 'lotSize',
          })
        );
      }
    } catch (error) {
      dispatch({
        type: 'GET_EXPIRES',
        payload: [],
        index: data.index,
      });
    }
  };
// step 3 Select striKe
export const getStrategyStrikesOptions =
  (data) => async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    let { strategyTemplateForm } = getState().strategyBuilderReducer;
    let templateForm = { ...strategyTemplateForm };

    templateForm.securities[data.index][data.key] = data.expiry;
    templateForm.securities[data.index][data.lotKey] = data.lotSize;

    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/strikes`,
        params: {
          exchange: templateForm.exchange,
          instrument: templateForm.securities[data.index].instrument,
          security: templateForm.securities[data.index].securityName,
          expiry: data.expiry,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'GET_STRIKES',
          payload: result.data.data,
          index: data.index,
        });
        // autoselecting 1st value in strike dropdown
        dispatch(
          UpdateRow({
            value: result.data.data[0].toString(),
            index: data.index,
            key: 'strike',
          })
        );
      }
    } catch (error) {
      dispatch({
        type: 'GET_STRIKES',
        payload: [],
        index: data.index,
      });
    }
    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/nickname`,
        params: {
          exchange: templateForm.exchange.trim(),
          instrument: templateForm.securities[data.index].instrument,
          security: templateForm.securities[data.index].securityName,
          expiry: templateForm.securities[data.index].expiry,
          strike: templateForm.securities[data.index].strike,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (
        result &&
        result.data &&
        result.data.code &&
        result.data.code != 'INVALID'
      ) {
        let prevNickname = templateForm.securities[data.index].nickname;
        templateForm.entryLegs.map((a) =>
          a.position.map((b) => {
            if (b.security === prevNickname) {
              b.security = result.data.code;
              b.securityIdx = Number(data.index);
            }
          })
        );
        templateForm.securities[data.index].nickname = result.data.code;
      }
    } catch (e) {}

    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: templateForm,
    });
  };

export const getNickname = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  templateForm.securities[data.index].strike = data.strike;
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/nickname`,
      params: {
        exchange: templateForm.exchange.trim(),
        instrument: templateForm.securities[data.index].instrument,
        security: templateForm.securities[data.index].securityName,
        expiry: templateForm.securities[data.index].expiry,
        strike: templateForm.securities[data.index].strike,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (
      result &&
      result.data &&
      result.data.code &&
      result.data.code != 'INVALID'
    ) {
      let prevNickname = templateForm.securities[data.index].nickname;
      templateForm.entryLegs.map((a) =>
        a.position.map((b) => {
          if (b.security === prevNickname) {
            b.security = result.data.code;
            b.securityIdx = Number(data.index);
          }
        })
      );
      templateForm.securities[data.index].nickname = result.data.code;
    }
  } catch (e) {}

  dispatch({
    type: 'UPDATE_STRATEGY_FORM',
    payload: templateForm,
  });
};
//step 4
export const UpdateRow = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };

  try {
    templateForm.securities[data.index][data.key] = data.value;
    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: templateForm,
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: {},
    });
  }
};

export const setStrategyTemplateForm = (Form) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_STRATEGY_FORM',
    payload: Form,
  });
};

export const setSecurityName = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_SECURITY_NAME',
    payload: data,
  });
};

export const setExpiry = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_EXPIRY',
    payload: data,
  });
};

export const setStrike = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_STRIKE',
    payload: data,
  });
};
export const clearConstants = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };

  templateForm.constants = [];

  dispatch({
    type: 'CLEAR_CONSTANTS',
    payload: templateForm,
  });
};
export const clearStatus = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CLEAR_STATUS',
    payload: data,
  });
};
//module 4 actions

export const updateConditions = (Form) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_FORM',
    payload: Form,
  });
};

export const setConditions = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  templateForm.entryLegs[data.legIndex][data.key] = data.field;
  dispatch({
    type: 'UPDATE_FORM',
    payload: templateForm,
  });
};

export const setConstants = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  let index = templateForm[data.key].findIndex(
    (el) => el.name === data.obj.name
  );
  if (data?.remove) {
    if (index > -1) {
      templateForm[data.key].splice(index, 1);
    } else {
      templateForm[data.key].push(data.obj);
    }
  } else {
    if (data?.edit) {
      templateForm[data.key][data?.editIndex] = data.obj;
    } else {
      if (index > -1) {
        templateForm[data.key][index] = data.obj;
      } else {
        templateForm[data.key].push(data.obj);
      }
    }
  }
  dispatch({
    type: 'UPDATE_FORM',
    payload: templateForm,
  });
};

export const builderObject =
  (setIsLoading = () => {}, toSet = false) =>
  async (dispatch) => {
    const token = localStorage.getItem('token');
    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/strategyTemplate/builderObject`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'GET_BUILDER',
          payload: result.data.data,
        });
        if (toSet) {
          setIsLoading(false);
        }
      }
    } catch (error) {
      dispatch({
        type: 'GET_BUILDER',
        payload: {},
      });
    }
  };

export const exchangevalue = (value) => {
  return {
    type: 'SET_EXCHANGE',
    payload: value,
  };
};

export const nickName = (value) => {
  return {
    type: 'SET_NICKNAME',
    payload: value,
  };
};

export const setUsedNickName = (value) => async (dispatch, getState) => {
  let { nicknameUsed } = getState().strategyBuilderReducer;
  let checkIndex = nicknameUsed.findIndex((el) => el.id === value.id);
  if (checkIndex > -1) {
    nicknameUsed[checkIndex] = value;
  } else {
    nicknameUsed.push(value);
  }
  return {
    type: 'SET_USED_NICKNAME',
    payload: nicknameUsed,
  };
};

export const displayData = (value) => {
  return {
    type: 'GET_CONDITIONS',
    payload: true,
  };
};

export const setBuilderPositions = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  templateForm.entryLegs[data.legIndex][data.key][data.positionIndex][
    data.subKey
  ] = data.updatedPosition;
  dispatch({
    type: 'UPDATE_FORM',
    payload: templateForm,
  });
};

// Module 5

export const exitUpdateConditions = (Form) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_EXIT_FORM',
    payload: Form,
  });
};

export const setExitConditions = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  templateForm.exitCondition[data.key] = data.field;

  dispatch({
    type: 'UPDATE_EXIT_FORM',
    payload: templateForm,
  });
};

//submit
export const submit = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm, isCopy } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  const token = localStorage.getItem('token');
  templateForm.exitCondition[data.key] = data.field;

  dispatch({
    type: 'SET_EDIT_MODIFY',
    payload: false,
    id: '',
  });

  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategyTemplate/submit`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch(strategyView());
      dispatch(updateCopy(false));
      return {
        message: result.data.data.message,
        status: result.data.data.valid === true ? 'success' : 'unsuccessful',
        errors: Object.hasOwn(result.data.data, 'expressionErrors')
          ? result.data.data.expressionErrors
          : [],
      };
    } else {
      if (isCopy) {
        return {
          message: result.data.data.message
            ? `${result.data.data.message}, Could not copy !`
            : 'Getting Some Server Error. Please Try Again ',
          status: result.data.data.valid === true ? 'success' : 'unsuccessful',
          errors: Object.hasOwn(result.data.data, 'expressionErrors')
            ? result.data.data.expressionErrors
            : [],
        };
      } else {
        return {
          message: result.data.data.message
            ? result.data.data.message
            : 'Getting Some Server Error. Please Try Again ',
          status: result.data.data.valid === true ? 'success' : 'unsuccessful',
          errors: Object.hasOwn(result.data.data, 'expressionErrors')
            ? result.data.data.expressionErrors
            : [],
        };
      }
    }
  } catch (err) {
    if (isCopy) {
      return {
        message: err.response.data.data.message
          ? `${err.response.data.data.message}, Could not copy !`
          : 'Getting Some Server Error. Please Try Again ',
        status: err.response.data.data.valid === true ? 'success' : 'error',
        errors: Object.hasOwn(err.response.data.data, 'expressionErrors')
          ? err.response.data.data.expressionErrors
          : [],
      };
    } else {
      return {
        message: err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting Some Server Error. Please Try Again ',
        status: err.response.data.data.valid === true ? 'success' : 'error',
        errors: Object.hasOwn(err.response.data.data, 'expressionErrors')
          ? err.response.data.data.expressionErrors
          : [],
      };
    }
  }
};

export const submitV2 = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/testing/equationSubmit`,
      data: {
        equation: data.entryLegs[0].condition[0].marketTriggered,
        isLogicalExpression: true,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};

//save as draft
export const saveAsDraft = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm, modifyId, strategyView, isEdit, isCopy } =
    getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  let viewTable = [...strategyView];
  const token = localStorage.getItem('token');
  let draftData = data;
  delete draftData.sortingType;
  delete draftData.sortColumn;

  if (strategyView.find((el) => el.id === modifyId) && isEdit && !isCopy) {
    draftData['existingDraftFlag'] = true;
    draftData['id'] = modifyId;
  }
  templateForm.exitCondition[data?.key] = data?.field;

  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategyTemplate/saveDraft`,
      data: draftData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'SAVE_AS_DRAFT',
        payload: templateForm,
      });
      return {
        message: result.data.data.message,
        status: 'success',
      };
    } else {
      if (isCopy) {
        return {
          message: result.data.data.message
            ? `${result.data.data.message}, Could not copy !`
            : 'Getting Some Server Error. Please Try Again ',
          status: result.data.data.valid === true ? 'success' : 'unsuccessful',
        };
      } else {
        return {
          message: result.data.data.message
            ? result.data.data.message
            : 'Getting Some Server Error. Please Try Again ',
          status: result.data.data.valid === true ? 'success' : 'unsuccessful',
        };
      }
    }
  } catch (err) {
    dispatch({
      type: 'SAVE_AS_DRAFT',
      payload: [],
    });
    if (isCopy) {
      return {
        message: err.response.data.data.message
          ? `${err.response.data.data.message}, Could not copy !`
          : 'Getting Some Server Error. Please Try Again ',
        status: 'error',
      };
    } else {
      return {
        message: err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting Some Server Error. Please Try Again ',
        status: 'error',
      };
    }
  }
};

//clear
export const reset = (data) => async (dispatch) => {
  let templateForm = data;
  dispatch({
    type: 'RESET',
    payload: templateForm,
  });
};

//clear after algo selection
export const executeAlgo = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  let entryLeg = templateForm.entryLegs.filter((el) => el.legId === 1);
  let newArr = [];
  entryLeg[0].position.map((i) => {
    let finalObj = {
      direction: i.direction,
      orientation: i.orientation,
      pricing: [],
      quantity: [],
      totalQuantityLogic: [],
      security: i.security,
    };
    newArr.push(finalObj);
  });
  entryLeg[0]['position'] = newArr;
  templateForm['entryLegs'] = entryLeg;
  dispatch({
    type: 'UPDATE_FORM',
    payload: templateForm,
  });
};

// exit toggle
export const exitToggleButton = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_TOGGLE',
    payload: data,
  });
};

// error in exit condition
export const setErrorInExit = (data) => async (dispatch) => {
  dispatch({
    type: 'ERROR_IN_EXIT',
    payload: data,
  });
};

// entry condtion get totalQty
export const getquantityPreview = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  templateForm.entryLegs[data.legIndex][data.key][data.positionIndex][
    data.preveiewQty
  ] = data.value;

  dispatch({
    type: 'GET_TOTALQTY',
    payload: templateForm,
  });
};

//pricing for preview
export const setPreviewPricing = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };

  templateForm.entryLegs[data.legInd].position[data.positionInd].pricing =
    data.field;

  dispatch({
    type: 'UPDATE_PREVIEW_PRICE',
    payload: templateForm,
  });
};

// Rechange api's on Exchange
export const setDataExchange = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm } = getState().strategyBuilderReducer;
  let securityOption = [];
  for (let i = 0; i < strategyTemplateForm.securities.length; i++) {
    try {
      if (strategyTemplateForm.securities[i].instrument) {
        const result = await MakeRequest('/security/securities').get({
          exchange: data.exchange,
          instrument: strategyTemplateForm.securities[i].instrument,
          securityFilter: strategyTemplateForm.securityFilter,
        });
        if (result) {
          securityOption[i] = result.data.data;
        }
      } else {
        securityOption[i] = null;
      }
    } catch (error) {
      securityOption[i] = null;
    }
  }

  dispatch({
    type: 'SET_ALLSECURITYNAME',
    payload: securityOption,
  });
};

// Strategy view Api

export const strategyView = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await MakeRequest('/strategyTemplate/return').get();
    if (data) {
      dispatch({
        type: 'STRATEGY_VIEW',
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'STRATEGY_VIEW',
      payload: [],
    });
  }
};

export const modifyTemplate = (response, id) => async (dispatch, getState) => {
  const { strategyTemplateForm } = getState().strategyBuilderReducer;
  let editForm = { ...strategyTemplateForm };

  dispatch({
    type: 'GET_MODIFY_ID',
    payload: id,
  });

  try {
    if (response) {
      editForm.name = response.name.trim();
      editForm.description = response.description.trim();
      editForm.algoId = response.algoId;
      editForm.securityFilter = response.securityFilter;
      editForm.isWrittenEquation = response.isWrittenEquation;
      editForm.exchange = response.exchange.trim();
      editForm.securities = response.securities.map((item) => ({
        instrument: item.instrument,
        securityName: item.securityName,
        expiry: item.expiry,
        strike: item.strike,
        nickname: item.nickname,
        isAdditional: item.isAdditional,
        lotSize: item.lotSize,
        tradingAccountId: item.tradingAccountId,
        buySell: item.buySell,
        exchange: item.exchange,
        instrumentAllowed: item?.instrumentAllowed?.map((it2) => it2.name),
      }));
      editForm.constants = response.constants;
      editForm.entryLegs = response.entryLegs;
      editForm.exitCondition = response.exitCondition;
      editForm.isApproved = response.isApproved;
      editForm.isComplete = response.isComplete;
      editForm.analyzerCondition = response.analyzerCondition;
      const constraintData = {
        buySellConstraint: response.securities[0].buySellConstraint,
        expiryConstraint: response.securities[0].expiryConstraint,
        instrumentConstraint: response.securities[0].instrumentConstraint,
        strikeConstraint: response.securities[0].strikeConstraint,
        symbolConstraint: response.securities[0].symbolConstraint,
      };
      editForm.constraintData = constraintData;

      dispatch({
        type: 'EDIT_DATA_FORM',
        payload: editForm,
        checkEdit: true,
      });
      dispatch(setDataExchange(editForm));
    }
  } catch (error) {
    dispatch({
      type: 'EDIT_DATA_FORM',
      payload: {
        name: '',
        description: '',
        algoId: {
          CM: '',
          FO: '',
          CD: '',
        },
        sortingType: '',
        sortColumn: '',
        exchange: '',
        securityFilter: 'ALL',
        isWrittenEquation: true,
        securities: [
          {
            instrument: '',
            securityName: '',
            expiry: '',
            strike: '',
            nickname: '',
            isAdditional: false,
            lotSize: '',
          },
        ],
        constants: [],
        entryLegs: [
          {
            legId: 1,
            condition: [
              {
                type: 'OPERAND',
                trigger: '',
              },
            ],
            position: [
              {
                orientation: 'Fwd',
                direction: 'BUY',
                securityIdx: '',
                security: '',
                pricing: [],
                quantity: [],
                lotSize: [],
                orderQty: '',
                totalQty: '',
              },
            ],
            algoExecution: [],
          },
        ],
        exitCondition: {
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          action: {
            enumName: '',
            args: [],
          },
        },
      },
      checkEdit: false,
    });
    console.log(error);
  }
};

//edit template
export const editTemplateModify = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm, modifyId } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  const token = localStorage.getItem('token');
  templateForm.exitCondition[data.key] = data.field;
  templateForm = { ...templateForm, id: modifyId };
  dispatch(setEditModify(false));
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategyTemplate/modify`,
      data: templateForm,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result) {
      dispatch(strategyView());
      return {
        message: result.data.data.message,
        status: 'success',
      };
    } else {
      return {
        message: 'Getting Some Server Error. Please Try Again ',
        status: 'error',
      };
    }
  } catch (err) {
    return {
      message: err.response.data.data.message
        ? err.response.data.data.message
        : 'Getting Some Server Error. Please Try Again ',
      status: err.response.data.data.valid === true ? 'success' : 'error',
    };
  }
};

//edit template
export const editTemplate = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm, modifyId } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  const token = localStorage.getItem('token');
  templateForm.exitCondition[data.key] = data.field;
  // let newLegs = templateForm.entryLegs.map((el) => {
  //   return {
  //     ...el,
  //     position: el.position.map((i) => {
  //       return {
  //         ...i,
  //         pricing: i.pricing.map((elm) => {
  //           return {
  //             type: elm.type,
  //             data: elm.data,
  //             args: elm.arguements,
  //           };
  //         }),
  //       };
  //     }),
  //   };
  // });
  // templateForm['entryLegs'] = newLegs;
  templateForm = { ...templateForm };

  console.log('templateForm', data);
  dispatch(setEditModify(false));
  try {
    const result = await axios({
      method: 'POST',
      // url: `${url}/strategyTemplate/modify`,
      url: `${url}/strategy/modify`,

      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result) {
      dispatch(strategyView());
      return {
        message: result.data.data.message,
        status: 'success',
      };
    } else {
      return {
        message: 'Getting Some Server Error. Please Try Again ',
        status: 'error',
      };
    }
  } catch (err) {
    return {
      message: err.response.data.data.message
        ? err.response.data.data.message
        : 'Getting Some Server Error. Please Try Again ',
      status: err.response.data.data.valid === true ? 'success' : 'error',
    };
  }
};

//trade modify template
export const tradeModifyTemplate = (data) => async (dispatch, getState) => {
  let { strategyTemplateForm, modifyId } = getState().strategyBuilderReducer;
  let templateForm = { ...strategyTemplateForm };
  const token = localStorage.getItem('token');
  templateForm.exitCondition[data.key] = data.field;
  let newLegs = templateForm.entryLegs.map((el) => {
    return {
      ...el,
      position: el.position.map((i) => {
        return {
          ...i,
          pricing: i.pricing.map((elm) => {
            return {
              type: elm.type,
              data: elm.data,
              args: elm.arguements,
            };
          }),
        };
      }),
    };
  });
  templateForm['entryLegs'] = newLegs;
  templateForm = { ...templateForm };

  console.log('templateForm', data);
  dispatch(setEditModify(false));
  try {
    const result = await axios({
      method: 'POST',
      // url: `${url}/strategyTemplate/modify`,
      url: `${url}/strategy/tradeModify`,

      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result) {
      dispatch(strategyView());
      return {
        message: result.data.data.message,
        status: 'success',
      };
    } else {
      return {
        message: 'Getting Some Server Error. Please Try Again ',
        status: 'error',
      };
    }
  } catch (err) {
    return {
      message: err.response.data.data.message
        ? err.response.data.data.message
        : 'Getting Some Server Error. Please Try Again ',
      status: err.response.data.data.valid === true ? 'success' : 'error',
    };
  }
};

export const viewEntriesDelete = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/strategyTemplate/delete/${data}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      if (result.status === 200) {
        dispatch(strategyView());
      }
      return { message: result.data.data.message, status: 'SUCCESS' };
    } else {
      dispatch({
        type: 'VIEW_ENTRY_DELETE',
        payload: false,
      });
      return {
        message: result.data.data.message
          ? result.data.data.message
          : 'Getting Some Server Error',
        status: 'FAILED',
      };
    }
  } catch (error) {
    dispatch({
      type: 'VIEW_ENTRY_DELETE',
      payload: false,
    });
    return {
      message:
        error.response.data.error && error.response.data.error.message
          ? error.response.data.error.message
          : 'Getting Some Server Error',
      status: 'FAILED',
    };
  }
};
export const liveEntires = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/strategyTemplate/request/approval/${data.id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch(strategyView());
      return { message: result.data.data.message, status: 'SUCCESS' };
    }
  } catch (error) {
    dispatch({
      type: 'LIVE_DETAILS',
      action: {},
    });
    return {
      message: error.response.data.data.message
        ? error.response.data.data.message
        : 'Getting Some Server Error',
      status: 'UNSUCCESSFUL',
    };
  }
};

export const setEditModify = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'SET_EDIT_MODIFY',
    payload: data,
    id: '',
  });
};

export const updateSortCulmnView = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_SORT_COLUMN_VIEW',
    payload: data,
  });
};
export const updateCopy = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_COPY',
    payload: data,
  });
};

/**
 * updates securities in the redux state
 */
export function updateSecurities(
  dispatch,
  mainSecurity,
  additionalSecurity,
  instrumentData
) {
  try {
    if (!mainSecurity || !additionalSecurity) {
      return;
    }
    const payloadObject = [];
    for (let item of mainSecurity) {
      payloadObject.push({
        instrumentAllowed: item.instrumentAllowed?.options
          ?.filter((item) => item.state)
          ?.map((item) => item.title),
        instrument:
          instrumentData?.filter(
            (filterItem) =>
              filterItem.name === item.instrument?.selection ||
              filterItem.key === item.instrument?.selection
          )[0]?.name || '',
        securityName: item.symbol.selection,
        expiry: item.expiry.selection,
        strike: item.strike.selection,
        nickname: item.nickname,
        isAdditional: false,
        lotSize: '',
        tradingAccountId: '',
        exchange: item.exchange.selection,
        buySell: item.buySell.selection,
      });
    }
    for (let item of additionalSecurity) {
      payloadObject.push({
        instrumentAllowed: item.instrumentAllowed.options
          .filter((item) => item.state)
          .map((item) => item.title),
        instrument:
          instrumentData.filter(
            (itemFilter) =>
              itemFilter.name === item.instrument.selection ||
              itemFilter.key === item.instrument.selection
          )[0]?.name || '',
        securityName: item.symbol.selection,
        expiry: item.expiry.selection,
        strike: item.strike.selection,
        nickname: item.nickname,
        isAdditional: true,
        lotSize: '',
        tradingAccountId: '',
        exchange: item.exchange.selection,
        buySell: item.buySell.selection,
      });
    }
    dispatch({ type: 'SB_UPDATE_SECURITIES', payload: payloadObject });
  } catch (error) {
    console.log(error);
  }
}

export function updateSecurityConstaints(dispatch, constaintInfo) {
  const constaintData = {};

  if (constaintInfo.length > 0) {
    constaintData.instrument = constaintInfo[2].selection;
    constaintData.symbol = constaintInfo[4].selection;
    constaintData.expiry = constaintInfo[5].selection;
    constaintData.strike = constaintInfo[6].selection;
    constaintData.buySell = constaintInfo[7].selection;
    dispatch({
      type: 'SB_UPDATE_SECURITIES_CONSTRAINTS',
      payload: constaintData,
    });
  } else {
    dispatch({ type: 'SB_UPDATE_SECURITIES_CONSTRAINTS', payload: {} });
  }
}

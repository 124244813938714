import React from 'react';
import './styles.css';

export default function CompLabel(props) {
  const { label = '', orientation = 'v', extStyles = {},/*Custom style for label*/labelStyles={} } = props;

  function getOrientation() {
    return orientation === 'h' ? 'c-h' : 'c-v';
  }

  return (
    <div className={`CompLabel-${getOrientation()}`} style={extStyles}>
      {label.length > 0 && <p className="CompLabel-label" style={labelStyles}>{label}</p>}
      {props.children}
    </div>
  );
}

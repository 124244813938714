import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Spinner } from 'react-bootstrap';
import './MarketWatch.scss';
import UiButton from '../../sharedComponents/Button';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../sharedComponents/Table/Table';
import newWindowIcon from '../../../assets/img/newWindowIcon.svg';
import { useLocation } from 'react-router-dom';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import {
  liveTradingData,
  getAllSecurities,
  unsubscribeSecurities,
  subscribeSecurities,
  riskParams,
  updateName,
  getName,
  updateSortCulmn,
  updateMarketData,
  updateFixedMarketData,
  cleanMarketData,
} from '../../../actions/LiveTradingAction';
import TradingHeader from '../TradingHeader/TradingHeader';
import StrategyAnalyzer from '../StrategyAnalyzer/StrategyAnalyzer.jsx';
import OrderBook from '../OrderBook/OrderBook';
import MarketWatchPagination from './MarketWatchPagination/MarketWatchPagination';
import MarketWatchModal from '../MarketWatchModal/MarketWatchModal';
import Popup from '../../sharedComponents/Popup/Popup';
import { webSocketUrl } from '../../../config/apiConfig';
import RiskMonitoringUtil from '../../../viewScreen/AdminDashboard/RiskMonitorInternal/riskMonitorUtil';
import RiskMonitoringParent from '../../../viewScreen/AdminDashboard/RiskMonitorParent/riskMonitor';
import {
  MARKET_PAGES,
  INITIAL_MARKET_PAGE,
  MARKET_TOAST_MESSAGE,
  NAME_UPDATED,
  TIME_OUT,
  PAGE_TITLES,
  ST_ATP_Screens,
} from '../../../utils/constant';
import NetPosition from '../NetPosition/NetPosition';
import { MarkWatchTSH } from '../../sharedComponents/customComponent';
import { getMarketColumnArr } from './apiCalls';
import { handleEnterWithCallback } from '../../sharedComponents/keyPressEventHandler';
import useKeyPress from '../../../custom_Hooks/useShortcut';
import { permissionChecker } from '../../../viewScreen/Ops/redux';
import { FeedbackPopup, GenSearchBox, GenericPopup } from '../..';
import { fixedSecurities } from '../../../utils/constant';
import { CreateFormulaActions } from '../../../reducers/createFormulaForm';
import CreateFormula from '../../sharedComponents/customComponent/tableSettings/CreateFormula';
import { fetchValue } from '../../../utils/dotKeyFunctions';
import { TabNavInner } from '../../../components';
import CustomMarketWatch from '../customMarketwatch';
import useSWR from 'swr';
import { MakeRequest } from '../../../utils';
import StrategyAnalyzerTabMan from '../StrategyAnalyzer/index.jsx';
import StrategyAnalyzerV2 from '../../../viewScreen/LiveTrading/strategyAnalyzer/index.jsx';

var W3CWebSocket = require('websocket').w3cwebsocket;

function MarketWatchPermission() {
  const { data, error, isLoading } = useSWR('/ops/view?qType=U', async () => {
    const userId = localStorage.getItem('customerId');
    const data = await MakeRequest(`/ops/view?qType=U&itemId=${userId}`).get();
    return [
      fetchValue(data, 'data.data.data.menu.2.subMenu.2'),
      fetchValue(data, 'data.data.data.menu.2.subMenu.3'),
    ];
  });
  return [data, error, isLoading];
}

export default function MarketWatch(props) {
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState('view');

  const [
    marketWatchPermission,
    marketWatchPermissionError,
    marketWatchPermissionLoading,
  ] = MarketWatchPermission();

  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'Live Trading',
      2,
      'Trading'
    );

    setPermission(res > 1 ? 'edit' : 'view');
  }, [userPermissions]);

  const {
    liveTradingReducer: {
      searchSecurities,
      searchSecurityName,
      securityLoaded,
      sortingType,
      sortColumn,
      marketData,
      fixedMarketData,
    },
    shortcutReducer,
  } = useSelector((state) => state);
  const [showLoader, setShowLoader] = useState(false);
  const [securityPayload, setSecurityPayload] = useState({
    searchString: '',
    size: 8,
    offset: 0,
  });
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const [dashboard, setDashboard] = useState(
    process.env.REACT_APP_IS_HFT === 'true' ? 'netposition' : 'strategyanalyzer'
  );
  const [showLeftModal, setShowLeftModal] = useState(false);
  const [showRightModal, setShowRightModal] = useState(false);
  const [position, setPosition] = useState(null);
  const [rowSelected, setRowSelected] = useState(null);
  const [rowFocused, setRowFocused] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [pages, setPages] = useState(MARKET_PAGES);
  const [prevPage, setPrevPage] = useState(INITIAL_MARKET_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [client, setClient] = useState();
  const [loader, setLoader] = useState(false);
  const [pathName, setPathName] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  const location = useLocation();
  const [tableCol, setTableCol] = useState([]);
  const [curColArr, setCurColArr] = useState([]);

  const [mwTabs, setMwTabs] = useState({
    tabs: [
      { id: 0, title: 'Market Watch' },
      { id: 1, title: 'Custom Market Watch' },
    ],
    currentSelect: 'none',
  });

  useEffect(() => {
    const res = async () => {
      await rearrangeColumns();
    };
    res();
    dispatch(cleanMarketData());
    let userId = localStorage.getItem('customerId');
    setSRQueue((old) => [
      ...old,
      {
        eventType: 'MARKETDATA_UNSUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          pageNumber: currentPage,
        },
      },
      {
        eventType: 'MARKETDATA_UNSUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          pageNumber: prevPage,
        },
      },
      {
        eventType: 'MARKETDATA_SUBSCRIBE',
        payload: { userId: parseInt(userId), pageNumber: currentPage },
      },
    ]);
  }, [currentPage]);

  useEffect(() => {
    if (curColArr && curColArr.length > 0) {
      const defFields = [
        {
          lebel: 'Exchange',
          field: 'exchange',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.exchange}</p>
              </>
            );
          },
        },
        {
          lebel: 'Security',
          field: 'security',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.security}</p>
              </>
            );
          },
        },
        {
          lebel: 'CCY',
          field: 'currency',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.currency}</p>
              </>
            );
          },
        },
        {
          lebel: 'LTPx',
          field: 'ltpx',
          sort: true,
          color: (params) => params.ltpxColor,
          renderHtml: (params) => {
            return (
              <>
                <p
                  style={{ textAlign: 'right' }}
                  className={loader ? 'inLoad' : ''}
                >
                  {params.ltpx}
                </p>
              </>
            );
          },
        },
        {
          lebel: 'LTQ',
          sort: true,
          color: (params) => params.ltqColor,
          field: 'ltq',
          renderHtml: (params) => {
            return (
              <>
                <p
                  style={{ textAlign: 'right' }}
                  className={loader ? 'inLoad' : ''}
                >
                  {params.ltq}
                </p>
              </>
            );
          },
        },
        {
          lebel: 'BidPx',
          field: 'bidPrice',
          sort: true,
          color: (params) => params.bidPriceColor,
          renderHtml: (params) => {
            return (
              <>
                <p
                  style={{ textAlign: 'right' }}
                  className={loader ? 'inLoad' : ''}
                >
                  {params.bidPrice}
                </p>
              </>
            );
          },
        },
        {
          lebel: 'BidQty',
          sort: true,
          color: (params) => params.bidQtyColor,
          field: 'bidQty',
          renderHtml: (params) => {
            return (
              <>
                <p
                  style={{ textAlign: 'right' }}
                  className={loader ? 'inLoad' : ''}
                >
                  {params.bidQty}
                </p>
              </>
            );
          },
        },
        {
          lebel: 'AskPx',
          sort: true,
          color: (params) => params.askPriceColor,
          field: 'askPrice',
          renderHtml: (params) => {
            return (
              <>
                <p
                  style={{ textAlign: 'right' }}
                  className={loader ? 'inLoad' : ''}
                >
                  {params.askPrice}
                </p>
              </>
            );
          },
        },
        {
          lebel: 'AskQty',
          sort: true,
          color: (params) => params.askQtyColor,
          field: 'askQty',
          renderHtml: (params) => {
            return (
              <>
                <p
                  style={{ textAlign: 'right' }}
                  className={loader ? 'inLoad' : ''}
                >
                  {params.askQty}
                </p>
              </>
            );
          },
        },
        {
          lebel: 'LTT',
          field: 'ltt',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.ltt}</p>
              </>
            );
          },
        },
        {
          lebel: 'Bid IV',
          field: 'bidIv',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.bidIv}</p>
              </>
            );
          },
        },
        {
          lebel: 'Ask IV',
          field: 'askIv',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.askIv}</p>
              </>
            );
          },
        },
        {
          lebel: 'OI',
          field: 'oi',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.oi}</p>
              </>
            );
          },
        },
        {
          lebel: 'Delta',
          field: 'delta',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.delta}</p>
              </>
            );
          },
        },
        {
          lebel: 'Gamma',
          field: 'gamma',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.gamma}</p>
              </>
            );
          },
        },
        {
          lebel: 'Theta',
          field: 'theta',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.theta}</p>
              </>
            );
          },
        },
        {
          lebel: 'Vega',
          field: 'vega',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.vega}</p>
              </>
            );
          },
        },
        {
          lebel: 'Rho',
          field: 'rho',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.rho}</p>
              </>
            );
          },
        },
        {
          lebel: 'Vol',
          field: 'ttv',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.ttv}</p>
              </>
            );
          },
        },
        {
          lebel: 'Vwap',
          field: 'vwap',
          sort: true,
          renderHtml: (params) => {
            return (
              <>
                <p className={loader ? 'inLoad' : ''}>{params.vwap}</p>
              </>
            );
          },
        },
      ];
      let newFieldArr = [];

      // const colNewArr = curColArr.sort((a, b) => a.columnSeq - b.columnSeq);
      for (let i = 0; i < defFields.length; i++) {
        const tempObj = defFields[i];

        const obj = curColArr.filter(
          (item) => item.variableName === defFields[i].field
        )[0];

        tempObj.columnSeq = obj && obj.columnSeq;
        newFieldArr.push(tempObj);
      }
      newFieldArr = newFieldArr.filter((item) => item.columnSeq);

      const colNewArr = newFieldArr.sort((a, b) => a.columnSeq - b.columnSeq);
      setTableCol(colNewArr);
    }
  }, [curColArr, loader]);

  useKeyPress(
    shortcutReducer.get('MARKET_WATCH') &&
      shortcutReducer.get('MARKET_WATCH').keyCombination.split('+'),
    () => {
      document.getElementById('MARKET_WATCH_ROW_1')?.focus();
    }
  );

  async function rearrangeColumns() {
    if (currentPage > 0) {
      const res = await getMarketColumnArr('MARKET_WATCH', currentPage);
      setCurColArr(res.viewData);
    }
  }

  useEffect(() => {
    openConnection();
    localStorage.setItem('subscribedSecurity', JSON.stringify([]));
    dispatch(getName(currentPage));
    dispatch(riskParams());
    initialiseSocket();

    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    if (position) {
      // after position is being set, then open the modal
      setShowRightModal(true);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Anything in here is fired on component unmount.
    };
  }, [position]);

  useEffect(() => {
    let pathAdress = location.pathname === '/window/liveTrading';
    let tradingPathAdress = location.pathname === '/window/trading';
    if (pathAdress) {
      setPathName(true);
    }
    if (tradingPathAdress) {
      setPathName(true);
    }
  }, [location.pathname]);

  const openConnection = () => {
    let clientObj = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    setClient(clientObj);
  };

  useEffect(() => {
    switch (popup.type) {
      case 'updateName':
        setPopup({ ...popup, message: NAME_UPDATED, buttons: 'singleButton' });
        break;
      default:
        setPopup({ type: '', message: '', buttons: '', state: false });
    }
  }, [popup.type]);

  const actionOnpopup = (e, type) => {
    if (type === 'CLOSE') {
      setPopup({ state: false });
    }
  };

  const [wsSRQueue, setSRQueue] = useState([]);

  useEffect(() => {
    if (client?.readyState === client?.OPEN && wsSRQueue?.length > 0) {
      for (const item of wsSRQueue) {
        client.send(JSON.stringify(item));
      }
      setSRQueue([]);
    }
  }, [client?.readyState, wsSRQueue]);

  useEffect(() => {
    if (client) {
      let userId = localStorage.getItem('customerId');
      if (mwTabs.currentSelect === ST_ATP_Screens.CUSTOM_MARKET_WATCH) {
        setSRQueue((old) => [
          ...old,
          {
            eventType: 'MARKETDATA_UNSUBSCRIBE',
            payload: {
              userId: parseInt(userId),
              pageNumber: currentPage,
            },
          },
        ]);
      } else if (mwTabs.currentSelect === ST_ATP_Screens.MARKET_WATCH) {
        setSRQueue((old) => [
          ...old,
          {
            eventType: 'MARKETDATA_SUBSCRIBE',
            payload: { userId: parseInt(userId), pageNumber: currentPage },
          },
        ]);
      }
    }
  }, [mwTabs]);

  function initialiseSocket(toReset = false) {
    let userId = localStorage.getItem('customerId');
    if (client) {
      client.close();
    }
    let clientObj = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');

    if (clientObj.readyState === clientObj.OPEN) {
      if (toReset === true) {
        // setSRQueue((old) => [
        //   ...old,
        //   {
        //     eventType: 'MARKETDATA_UNSUBSCRIBE',
        //     payload: {
        //       userId: parseInt(userId),
        //       pageNumber: prevPage,
        //     },
        //   },
        //   {
        //     eventType: 'MARKETDATA_SUBSCRIBE',
        //     payload: { userId: parseInt(userId), pageNumber: currentPage },
        //   },
        // ]);
        return;
      }

      if (prevPage) {
        setSRQueue((old) => [
          ...old,
          {
            eventType: 'MARKETDATA_UNSUBSCRIBE',
            payload: {
              userId: parseInt(userId),
              pageNumber: prevPage,
            },
          },
        ]);
        dispatch(cleanMarketData());
        setLoader(true);
        localStorage.setItem('subscribedSecurity', JSON.stringify([]));
      } else {
        // setSRQueue((old) => [
        //   ...old,
        //   {
        //     eventType: 'MARKETDATA_SUBSCRIBE',
        //     payload: { userId: parseInt(userId), pageNumber: currentPage },
        //   },
        // ]);
      }
    } else {
    }
    clientObj.onopen = () => {
      dispatch(cleanMarketData());
    };
    clientObj.onmessage = (data) => {
      let dataStr = JSON.parse(data.data);

      if (dataStr.status === 200) {
        let liveDataObject = { ...dataStr.payload };
        delete liveDataObject.fixedData;
        delete liveDataObject.userId;

        dispatch(liveTradingData(dataStr));
        if (dataStr.eventType === 'MARKETDATA_START') {
          dataStr.payload.watchList = dataStr.payload.watchList.filter(
            (item) => item.dataFeedTicker
          );
          localStorage.setItem(
            'subscribedSecurity',
            JSON.stringify([...dataStr.payload.watchList])
          );
        }
        if (dataStr.eventType === 'MARKET_UPDATE') {
          for (let secKey of Object.keys(liveDataObject)) {
            if (fixedSecurities[secKey]) {
              dispatch(updateFixedMarketData(liveDataObject[secKey]));
              continue;
            }
            dispatch(updateMarketData(liveDataObject[secKey]));
          }
          if (loader) {
            setLoader(false);
          }
        }
      }
    };
    clientObj.onclose = () => {
      if (prevPage) {
        setTimeout(() => {
          openConnection();
        }, TIME_OUT);
      }
    };
    clientObj.onerror = () => {
      clientObj.close();
    };
    setClient(clientObj);
  }

  useEffect(() => {
    let userId = localStorage.getItem('customerId');
    setSRQueue((old) => [
      ...old,
      {
        eventType: 'MARKETDATA_UNSUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          pageNumber: prevPage,
        },
      },
    ]);
  }, [currentPage]);

  const addOrRemoveSecurity = (security) => {
    if (subscribedSecurity?.length >= 50 && !security.isSubscribed) {
      return setToaster(true);
    } else {
      let subscribedSecurity = localStorage.getItem('subscribedSecurity')
        ? JSON.parse(localStorage.getItem('subscribedSecurity'))
        : [];
      const payload = {
        exchange: security?.exchange,
        dataFeedTicker: security?.dataFeedTicker,
        pageNumber: currentPage,
      };

      if (security.isSubscribed) {
        dispatch(unsubscribeSecurities(payload));
        subscribedSecurity = subscribedSecurity.filter((el) => {
          return el.dataFeedTicker !== security?.dataFeedTicker;
        });
      } else {
        dispatch(subscribeSecurities(payload));
        subscribedSecurity.push(security);
      }
      localStorage.setItem(
        `subscribedSecurity`,
        JSON.stringify(subscribedSecurity)
      );

      dispatch(cleanMarketData());
      let userId = localStorage.getItem('customerId');
      setSRQueue((old) => [
        ...old,
        {
          eventType: 'MARKETDATA_UNSUBSCRIBE',
          payload: {
            userId: parseInt(userId),
            pageNumber: currentPage,
          },
        },
        {
          eventType: 'MARKETDATA_UNSUBSCRIBE',
          payload: {
            userId: parseInt(userId),
            pageNumber: prevPage,
          },
        },
      ]);

      setTimeout(() => {
        let userId = localStorage.getItem('customerId');
        setSRQueue((old) => [
          ...old,
          {
            eventType: 'MARKETDATA_SUBSCRIBE',
            payload: { userId: parseInt(userId), pageNumber: currentPage },
          },
        ]);
      }, 500);
    }
  };

  const handleSearch = async (e) => {
    // funtion for searching the securities
    let inputText = e;
    let payload = securityPayload;
    payload.searchString = inputText;
    if (searchSecurityName !== inputText) {
      payload.offset = 0;
    }
    if (inputText.trim().length > 2) {
      dispatch(getAllSecurities(payload));
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
    setSecurityPayload(payload);
    setSearchTerm(inputText);
  };

  const MarketWatchNewWindow = (id) => {
    window.open(
      `/window/liveTrading`,
      '_blank',
      'top=100, left=100, width=1857, height=500, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  // Order Book Tickers / Depth View
  const handleOrderBookDepth = (row) => {
    let userId = localStorage.getItem('customerId');
    row.exchange = row && row.exchange ? row.exchange : 'NSE';

    window.open(
      `/window/order-book-ticker/${window.btoa(userId)}/${window.btoa(
        row.exchange
      )}/${window.btoa(row.security)}/${window.btoa(row.esteeId)}/${window.btoa(
        row.security
      )}`,
      '_blank',
      'top=100, left=100, width=770, height=385, menubar=yes,toolbar=yes, scrollbars=no, resizable=yes'
    );
  };

  const handleDoubleClick = (event, row) => {
    if (event.type === 'dblclick') {
      handleOrderBookDepth(row);
    }
  };

  const handleMouseClick = (event, row) => {
    //funtion to handle left and right mouse click

    if (showLeftModal) {
      // fetchOrderBook('UNSUBSCRIBE', rowSelected);
      setShowLeftModal(false);
      setRowSelected(null);
    } else if (showRightModal) {
      setShowRightModal(false);
      setPosition(null);
      setRowSelected(null);
    } else {
      if (event.button === 2) {
        setRowSelected(row);
        let layerX = event && event.nativeEvent && event.nativeEvent.layerX;
        let layerY = event && event.nativeEvent && event.nativeEvent.layerY;
        setPosition({ layerX, layerY });
      }
      if (event.button === 0) {
        setRowFocused(row);
      }
      if (event.type === 'focus') {
        setRowFocused(row);
      }
    }
  };

  const handleLeftModalClose = () => {
    setShowLeftModal(false);
    setRowSelected(null);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef &&
      dropdownRef.current !== null &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowLoader(false);
    }
    if (
      tableWrapperRef &&
      tableWrapperRef.current !== null &&
      !tableWrapperRef.current.contains(event.target)
    ) {
      setRowFocused(null);
    }
  };

  const openManualOrder = (dir, security, exc) => {
    //function for opening manual order in new window for buying and selling a security
    window.open(
      `/window/manual-order?direction=${window.btoa(
        dir
      )}&security=${window.btoa(`${exc}-${security}`)}`,
      '_blank',
      'top=100, left=100, width=800, height=375, menubar=yes,toolbar=yes, scrollbars=yes, resizable=yes'
    );
  };

  const rightModalClickhandler = (event, operation, rowSelected) => {
    //right click content menue for buying, selling, removing all or removing one security
    if (operation === 'buySecurity') {
      openManualOrder('BUY', rowSelected?.security, rowSelected?.exchange);
    } else if (operation === 'sellSecurity') {
      openManualOrder('SELL', rowSelected?.security, rowSelected?.exchange);
    } else if (operation === 'removeSecurity') {
      const securityPayload = {
        exchange: rowSelected?.exchange,
        dataFeedTicker: rowSelected?.security,

        pageNumber: currentPage,
      };
      dispatch(unsubscribeSecurities(securityPayload));
      let subscribedSecurity = localStorage.getItem('subscribedSecurity')
        ? JSON.parse(localStorage.getItem('subscribedSecurity'))
        : [];
      subscribedSecurity = subscribedSecurity.filter((el) => {
        return el.dataFeedTicker !== securityPayload?.dataFeedTicker;
      });
      localStorage.setItem(
        'subscribedSecurity',
        JSON.stringify(subscribedSecurity)
      );
    } else if (operation === 'removeAll') {
      const securityPayload = {
        removeAll: true,
        pageNumber: currentPage,
      };
      dispatch(unsubscribeSecurities(securityPayload));
      localStorage.setItem('subscribedSecurity', JSON.stringify([]));
    }
    handleMouseClick(event, null);
    dispatch(cleanMarketData());
    let userId = localStorage.getItem('customerId');
    setSRQueue((old) => [
      ...old,
      {
        eventType: 'MARKETDATA_UNSUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          pageNumber: currentPage,
        },
      },
      {
        eventType: 'MARKETDATA_UNSUBSCRIBE',
        payload: {
          userId: parseInt(userId),
          pageNumber: prevPage,
        },
      },
    ]);

    setTimeout(() => {
      let userId = localStorage.getItem('customerId');
      setSRQueue((old) => [
        ...old,
        {
          eventType: 'MARKETDATA_SUBSCRIBE',
          payload: { userId: parseInt(userId), pageNumber: currentPage },
        },
      ]);
    }, 500);
  };

  const rightModalData = () => {
    return (
      <>
        <p>{rowSelected ? rowSelected.security : ''}</p>
        <ul>
          <li
            onMouseDown={(event) =>
              rightModalClickhandler(event, 'buySecurity', rowSelected)
            }
          >
            Buy Security
          </li>
          <li
            onMouseDown={(event) =>
              rightModalClickhandler(event, 'sellSecurity', rowSelected)
            }
          >
            Sell Security
          </li>
          <li
            onMouseDown={(event) =>
              rightModalClickhandler(event, 'removeSecurity', rowSelected)
            }
          >
            Remove Security
          </li>
          <li
            onMouseDown={(event) =>
              rightModalClickhandler(event, 'removeAll', rowSelected)
            }
          >
            Remove All
          </li>
        </ul>
      </>
    );
  };

  let niftyData =
    typeof fixedMarketData == 'object' && Object.values(fixedMarketData).length
      ? fixedMarketData
      : {};

  let subscribedSecurity = localStorage.getItem('subscribedSecurity')
    ? JSON.parse(localStorage.getItem('subscribedSecurity'))
    : [];

  let securityList = searchSecurities.map((security) => {
    let newObj = {
      isSubscribed: false,
      displayName: security.displayName,
      dataFeedTicker: security.dataFeedTicker,
      exchange: security.exchange,
    };
    for (let j = 0; j < subscribedSecurity.length; j++) {
      if (subscribedSecurity[j].dataFeedTicker === security.dataFeedTicker) {
        newObj = {
          ...newObj,
          isSubscribed: true,
        };
      }
    }
    return newObj;
  });

  const sortingFuction = (type, column) => {
    //sorting funtion for table
    dispatch(updateSortCulmn({ sortColumn: column.field, sortingType: type }));
  };

  const createFormulapopup = useSelector(
    (glbState) => glbState.CreateFormulaFormPopup
  );
  const creFormAction = new CreateFormulaActions(dispatch);

  useEffect(() => {
    switch (fetchValue(createFormulapopup, 'formData.action')) {
      case 'SUBMIT':
        const data = fetchValue(createFormulapopup, 'formData');
        if (data.id) {
          creFormAction.createFormula(data, 'MODIFY');
        } else {
          creFormAction.createFormula(data, 'CREATE');
        }
        break;
      case 'DRAFT':
        creFormAction.createFormula(
          fetchValue(createFormulapopup, 'formData'),
          'DRAFT'
        );
        break;
      case 'CLOSE':
        creFormAction.closePopup();
        break;
      case 'QUICK_TEST':
        creFormAction.quickTest(fetchValue(createFormulapopup, 'formData'));
        break;
      default:
        break;
    }
  }, [createFormulapopup]);

  function setDataFormulaAction(data) {
    creFormAction.updateFormData(data);
  }

  useEffect(() => {
    if (marketWatchPermission && marketWatchPermission.length > 0) {
      const tempTabs = [];
      if (marketWatchPermission[0].access >= 1) {
        tempTabs.push({ id: tempTabs.length, title: ST_ATP_Screens.MARKET_WATCH });
      }
      if (marketWatchPermission[1].access >= 1) {
        tempTabs.push({ id: tempTabs.length, title: ST_ATP_Screens.CUSTOM_MARKET_WATCH });
      }
      setMwTabs({
        tabs: tempTabs,
        currentSelect: tempTabs.length > 0 ? tempTabs[0].title : 'none',
      });
    }
  }, [marketWatchPermission]);

  return (
    <div className={pathName ? 'windowLiveTrading' : ''}>
      <Helmet>
        <title>{PAGE_TITLES.marketWatch}</title>
      </Helmet>
      {0 ? (
        <Spinner animation="border" role="status"></Spinner>
      ) : (
        <div className="market-watch-wrapper">
          <div
            className="market-watch"
            onMouseDown={(event) => {
              if (showLeftModal || showRightModal) {
                handleMouseClick(event, null);
              }
            }}
            onContextMenu={(event) => {
              event.preventDefault();
              return false;
            }}
          >
            <TabNavInner
              tabs={mwTabs.tabs}
              curSelect={mwTabs.tabs.findIndex(
                (tab) => tab.title === mwTabs.currentSelect
              )}
              setSelect={(val) => {
                setMwTabs({ ...mwTabs, currentSelect: mwTabs.tabs[val].title });
              }}
            />

            {mwTabs.currentSelect === ST_ATP_Screens.MARKET_WATCH && (
              <Row>
                <Col sm="12">
                  <div className="market-watch-header">
                    <div className="market-watch-header-start">
                      <div className="nifty-liveInHeader">
                        <div className="nifties nifties--small">
                          <p>NIFTY</p>
                          <h3
                            className={
                              niftyData['NIFTY'] &&
                              niftyData['NIFTY'].percent < 0
                                ? 'red'
                                : 'green'
                            }
                          >
                            {niftyData['NIFTY']?.price} (
                            {niftyData['NIFTY'] &&
                            niftyData['NIFTY'].percent &&
                            !isNaN(niftyData['NIFTY'].percent)
                              ? niftyData['NIFTY'].percent > 0
                                ? niftyData['NIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0] +
                                  '.' +
                                  niftyData['NIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                                : niftyData['NIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0]
                                    ?.slice(1) +
                                  '.' +
                                  niftyData['NIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                              : 'NA'}
                            )
                          </h3>
                        </div>
                        <div className="nifties">
                          <p>BANK NIFTY</p>
                          <h3
                            className={
                              niftyData['BANKNIFTY'] &&
                              niftyData['BANKNIFTY'].percent < 0
                                ? 'red'
                                : 'green'
                            }
                          >
                            {niftyData['BANKNIFTY']?.price} (
                            {niftyData['BANKNIFTY'] &&
                            niftyData['BANKNIFTY'].percent &&
                            !isNaN(niftyData['BANKNIFTY'].percent)
                              ? niftyData['BANKNIFTY'].percent > 0
                                ? niftyData['BANKNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0] +
                                  '.' +
                                  niftyData['BANKNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                                : niftyData['BANKNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0]
                                    ?.slice(1) +
                                  '.' +
                                  niftyData['BANKNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                              : 'NA'}
                            )
                          </h3>
                        </div>
                        <div className="nifties">
                          <p>FIN NIFTY</p>
                          <h3
                            className={
                              niftyData['FINNIFTY'] &&
                              niftyData['FINNIFTY'].percent < 0
                                ? 'red'
                                : 'green'
                            }
                          >
                            {niftyData['FINNIFTY']?.price} (
                            {niftyData['FINNIFTY'] &&
                            niftyData['FINNIFTY'].percent &&
                            !isNaN(niftyData['FINNIFTY'].percent)
                              ? niftyData['FINNIFTY'].percent > 0
                                ? niftyData['FINNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0] +
                                  '.' +
                                  niftyData['FINNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                                : niftyData['FINNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0]
                                    ?.slice(1) +
                                  '.' +
                                  niftyData['FINNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                              : 'NA'}
                            )
                          </h3>
                        </div>
                        <div className="nifties">
                          <p>NIFTY MID</p>
                          <h3
                            className={
                              niftyData['MIDCPNIFTY'] &&
                              niftyData['MIDCPNIFTY'].percent < 0
                                ? 'red'
                                : 'green'
                            }
                          >
                            {niftyData['MIDCPNIFTY']?.price} (
                            {niftyData['MIDCPNIFTY'] &&
                            niftyData['MIDCPNIFTY'].percent &&
                            !isNaN(niftyData['MIDCPNIFTY'].percent)
                              ? niftyData['MIDCPNIFTY'].percent > 0
                                ? niftyData['MIDCPNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0] +
                                  '.' +
                                  niftyData['MIDCPNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                                : niftyData['MIDCPNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[0]
                                    ?.slice(1) +
                                  '.' +
                                  niftyData['MIDCPNIFTY'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                              : 'NA'}
                            )
                          </h3>
                        </div>
                        <div className="nifties nifities--small">
                          <p>INDIA VIX</p>
                          <h3
                            className={
                              niftyData['INDIAVIX'] &&
                              niftyData['INDIAVIX'].percent < 0
                                ? 'red'
                                : 'green'
                            }
                          >
                            {niftyData['INDIAVIX']?.price} (
                            {niftyData['INDIAVIX'] &&
                            niftyData['INDIAVIX'].percent &&
                            !isNaN(niftyData['INDIAVIX'].percent)
                              ? niftyData['INDIAVIX'].percent > 0
                                ? niftyData['INDIAVIX'].percent
                                    ?.toString()
                                    ?.split('.')[0] +
                                  '.' +
                                  niftyData['INDIAVIX'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                                : niftyData['INDIAVIX'].percent
                                    ?.toString()
                                    ?.split('.')[0]
                                    ?.slice(1) +
                                  '.' +
                                  niftyData['INDIAVIX'].percent
                                    ?.toString()
                                    ?.split('.')[1]
                                    ?.slice(0, 1) +
                                  '%'
                              : 'NA'}
                            )
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="market-watch-header-end">
                      <MarkWatchTSH
                        callbackSetData={rearrangeColumns}
                        allowed={permission === 'edit'}
                      />
                      {!props.marketWatch ? (
                        <div
                          className="newWindowButton"
                          tabIndex={0}
                          onKeyPress={(event) => {
                            handleEnterWithCallback(
                              event,
                              MarketWatchNewWindow
                            );
                          }}
                        >
                          <img
                            className="newWindowIcon"
                            src={newWindowIcon}
                            alt="newWindowIcon"
                            onClick={MarketWatchNewWindow}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="market-watch-header">
                    <div className="market-watch-header-middle">
                      <div
                        ref={dropdownRef}
                        className="px-0 dropBox market-input"
                        onClick={() => {
                          if (securityPayload.searchString.length > 2) {
                            setShowLoader('true');
                          }
                        }}
                      >
                        <GenSearchBox
                          value={searchTerm}
                          changeListener={handleSearch}
                          message="Search eg: infy bse, nifty fut gold, gold mcx"
                          extStyles={{ width: '32vw' }}
                          ddExtStyles={{ width: '30vw' }}
                        />
                        {/* <span className="security-count">
                          {subscribedSecurity &&
                            subscribedSecurity?.length + '/50'}
                        </span> */}
                        {showLoader ? (
                          <div
                            className={`${showLoader ? 'active' : 'inactive'}`}
                            style={{ zIndex: 9 }}
                          >
                            {securityList && securityList.length ? (
                              <div
                                className="List liststyle"
                                style={{
                                  width: '31vw',
                                  height:
                                    securityList.length < 8 ? 'auto' : '337px',
                                }}
                                onScroll={(e) => {
                                  const bottom =
                                    e.target.scrollHeight -
                                      Math.ceil(e.target.scrollTop) ===
                                    e.target.clientHeight;
                                  if (bottom) {
                                    let newSecurityPayload = securityPayload;
                                    newSecurityPayload.offset =
                                      securityPayload.offset +
                                      securityPayload.size;
                                    setSecurityPayload(newSecurityPayload);
                                    if (
                                      newSecurityPayload.searchString.trim()
                                        .length > 2
                                    ) {
                                      dispatch(
                                        getAllSecurities(newSecurityPayload)
                                      );
                                    }
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    height:
                                      securityList.length < 8
                                        ? 'auto'
                                        : '420px',
                                    width: '100%',
                                  }}
                                >
                                  {securityList.map((securityRow, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="ListItem listRow"
                                      >
                                        <span
                                          className={
                                            securityRow.isSubscribed
                                              ? 'security_name'
                                              : ''
                                          }
                                        >
                                          {`${securityRow.displayName}`}{' '}
                                        </span>
                                        <UiButton //shared component for button
                                          className={
                                            securityRow.isSubscribed
                                              ? 'removeSecurity'
                                              : 'addSecurity'
                                          }
                                          name={
                                            securityRow.isSubscribed ? '-' : '+'
                                          }
                                          onClickFunction={() =>
                                            addOrRemoveSecurity(
                                              securityRow,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <>{!securityLoaded ? <p>Loading...</p> : ''}</>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="market-watch-table">
                  <div className="live-trading-data" ref={tableWrapperRef}>
                    <Table
                      className={'responsive'}
                      showHeader={true}
                      tableCoulumn={tableCol}
                      showPagination={false}
                      TableData={
                        loader
                          ? []
                          : Object.values(marketData).sort((a, b) =>
                              a.security.localeCompare(b.security)
                            )
                      }
                      rowBodyClass={'rowBodyClass'}
                      noRecordMsg={'Add Securities to this Page'}
                      onClickHandler={handleMouseClick}
                      onDblClickHandler={handleDoubleClick}
                      tableName="marketWatchTable"
                      showLeftModal={showLeftModal}
                      showRightModal={showRightModal}
                      style={{ minHeight: '300px' }}
                      sorting={true}
                      sortColumn={sortColumn}
                      sortingFuction={sortingFuction}
                      loader={loader}
                      rowFocused={rowFocused}
                      theme={'dark'}
                      type="MARKET_WATCH"
                      id="MARKET_WATCH_ROW"
                    ></Table>
                    {loader ? (
                      <Spinner animation="border" variant="light" />
                    ) : null}
                    {showLeftModal ? (
                      <MarketWatchModal
                        modal={showLeftModal}
                        data={orderDetails}
                        setModal={handleLeftModalClose}
                      />
                    ) : null}
                    {showRightModal ? (
                      <div
                        style={{
                          top: `${
                            position && position.layerY ? position.layerY : 0
                          }px`,
                          left: `${
                            position && position.layerX
                              ? position.layerX < 30
                                ? '50px'
                                : position.layerX > 850
                                ? '65%'
                                : `${position.layerX}px`
                              : 50
                          }`,
                        }}
                        className="right-modal"
                      >
                        {rightModalData()}
                      </div>
                    ) : null}
                  </div>
                  {pages && pages > 0 ? (
                    <MarketWatchPagination
                      pages={pages}
                      currentPage={currentPage}
                      setPages={setPages}
                      setCurrentPage={setCurrentPage}
                      updateName={updateName}
                      setPrevPage={setPrevPage}
                      getName={getName}
                    />
                  ) : null}
                </Col>
              </Row>
            )}
            {mwTabs.currentSelect === ST_ATP_Screens.CUSTOM_MARKET_WATCH && (
              <CustomMarketWatch />
            )}
            {toaster ? (
              <SimpleSnackbar
                toaster={toaster}
                setToaster={setToaster}
                message={MARKET_TOAST_MESSAGE}
              />
            ) : null}
          </div>
          {/* {!props.marketWatch ? (
            <div className="trading-container" ref={props.tableRef}>
              <div className="trading-header">
                <TradingHeader
                  dashboard={dashboard}
                  setDashboard={setDashboard}
                  pathName={pathName}
                  scrollRef={props.scrollRef}
                  extStyles={{ width: '100%' }}
                />
              </div>
              <div className="trading-body">{renderComponent()}</div>
            </div>
          ) : null} */}

          {popup.state ? (
            <Popup actionButton={actionOnpopup} popupDetails={popup} />
          ) : null}
          <GenericPopup
            state={createFormulapopup.state}
            type={1}
            toggleState={(val) =>
              val ? creFormAction.openPopup() : creFormAction.closePopup()
            }
          >
            <CreateFormula
              data={createFormulapopup.formData}
              setData={(val) => setDataFormulaAction(val)}
            />
          </GenericPopup>
          <FeedbackPopup
            message={fetchValue(createFormulapopup, 'feedbackPopup')}
            type="f"
            acceptAction={() => {
              if (
                fetchValue(createFormulapopup, 'feedbackPopup').includes(
                  'Quick Test Response'
                )
              ) {
                creFormAction.hideFeedbackPopup();
              } else {
                creFormAction.hideFeedbackPopup();
                creFormAction.resetActions();
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

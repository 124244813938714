import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import './riskConfig.css';
import TreeMenuGenerator from '../../../components/sharedComponents/genTreeMenu/genTreemenu';
import TreeMenuGenerator2 from '../../../components/sharedComponents/genTreeMenu_2/genTreemenu';
import TreeMenuGenerator3 from '../../../components/sharedComponents/genTreeMenu_3/genTreemenu';
import MakeRequest from '../../../utils/apiRequest';
import GenericPopup from '../../../components/sharedComponents/Popup/Popup.js';
import FileUploadComponent from '../../../components/FileUpload/FileUpload';
import filterIcon from '../../../assets/img/filter02.svg';
import { FeedbackPopup, TableGenerator } from '../../../components';
import ExcelPrevPopup from '../../../components/sharedComponents/customComponent/excelPrevPopup';
import {
  WindowContainer,
  ToggleTab,
  GenTableExtended,
} from '../../../components';
import { ExcelIcon } from '../../../assets';
import { DropDown, GenButton } from '../../../components';
import HFTDisplayDropdown from './HFTDisplayDropDown';

const navConst = { TRADE_PP: 1, RISK_PF: 2, RISK_PD: 3 };

const dummySeletTemplateData = {
  id: 1,
  title: 'Select Template',
  type: 1,
  selected: true,
  found: false,
  children: [
    {
      id: 1,
      selected: false,
      type: 2,
      title: 'Template 1',
      found: false,
    },
    {
      id: 2,
      selected: false,
      type: 2,
      title: 'Template 2',
      found: false,
    },
    {
      id: 3,
      selected: false,
      type: 2,
      title: 'Template 3',
      found: false,
    },
  ],
};

const RiskConfigScr = () => {
  const [displayDataState, setDisplayDataState] = useState({
    hierarchy: [],
    dealHier: [],
    allRules: { 1: [], 2: [], 3: [] },
    displayTable: [],
    tableContent: { adminLevel: [], familyLevel: {} },
    tempHold: [],
    currRules: [],
    currentSelection: { type: 1, familyId: -1 },
    dealerRiskParam: [],
    dealerRiskValues: [],
    dealers: [],
    hierSearch: '',
  });
  const [dealerToggle, setDealerToggle] = useState(false);
  const [message, setMessage] = useState({
    visible: false,
    title: '',
    message: '',
  });
  const inputFile = useRef(null);
  const [show, setShow] = useState(false);
  const [isSuccessUpload, setIsSuccessUpload] = useState(false);
  const [successUpdDBData, setSuccessUpdDBData] = useState(null);
  const [updateInfo, setUpdateInfo] = useState({
    type: '',
    id: -1,
  });

  const [isHFT, setisHFT] = useState(
    process.env.REACT_APP_IS_HFT == 'true' || (process.env.REACT_APP_IS_MARG_VERSION == 'true') ? true : false
  );
  const [familyDealerToggle, setFamilyDealerToggle] = useState('Family');
  const [tradeType, setTradeType] = useState('Pre Trade');
  const [disableTradeType, setDisableTradeType] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [riskParameterFilter, setRiskParameterFilter] = useState([]);
  const [selectionHierachyLevel, setSelectionHierachyLevel] = useState(-1);
  const [selectionId, setSelectionId] = useState(-1);
  const [tableChangeData, setTableChangeData] = useState([]);
  const [addRiskParamList, setAddRiskParamList] = useState([]);
  const [removeRiskParamList, setRemoveRiskParamList] = useState([]);
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [callApi, setCallApi] = useState(true);
  const [tableChangeObject, setTableChangeObject] = useState({});
  const [hasValuesChanged, setHasValuesChanged] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const [templateAvailable, setTemplateAvailable] = useState({});
  const [templateSelected, setTemplateSelected] = useState({
    selectedName: 'Select Template',
    selectedId: -1,
  });
  const [createTemplateConfig, setCreateTemplateConfig] = useState([]);
  const [headerActionInfo, setHeaderActionInfo] = useState([]);
  const [tppToggleState, setTppToggleState] = useState({});
  const [junk, setJunk] = useState([]);

  const [riskParameterFilterDifference, setRiskParameterFilterDifference] =
    useState();

  useEffect(() => {
    try {
      if (callApi) {
        initialApiDataFetch();
        setCallApi(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [callApi]);

  useEffect(() => {
    setTradeType('Pre Trade');
    setSelectionHierachyLevel(-1);
    setSelectionId(-1);
    setTemplateAvailable({});
    setTemplateSelected({
      selectedName: 'Select Template',
      selectedId: -1,
    });
  }, [isHFT]);

  useEffect(() => {
    setSelectionHierachyLevel(-1);
    setSelectionId(-1);
    setTemplateAvailable({});
    setTemplateSelected({
      selectedName: 'Select Template',
      selectedId: -1,
    });
  }, [familyDealerToggle]);

  // Selective Update on Successfull Risk Excel upload
  useEffect(() => {
    if (isSuccessUpload == true) {
      try {
        // setTimeout(() => {
        initialApiDataFetch();
        // }, 3000)
      } catch (error) {
        console.log(error);
      }
      setIsSuccessUpload(false);
    }
  }, [isSuccessUpload]);

  const initialApiDataFetch = async (isDisplayUpdNeeded = true) => {
    const response1 = await MakeRequest('/admin/risk/configurationV2').get();
    if (response1.event !== undefined && !response1.event) {
      return;
    }
    const tempData1 = response1.data.data;
    const result1 = processData(tempData1);
    if (isDisplayUpdNeeded) setApiData(result1);

    await GetAllTemplates();

    const response3 = await MakeRequest(
      '/admin/risk/template/templateInfo'
    ).get();
    if (response3.event !== undefined && !response3.event) {
      return;
    }

    const tempData3 = response3.data.data.data;
    setCreateTemplateConfig(tempData3);

    return result1;

    // return result;
  };

  const GetAllTemplates = async () => {
    const response2 = await MakeRequest(
      '/admin/risk/template/getAllTemplates'
    ).get();
    if (response2.event !== undefined && !response2.event) {
      return;
    }
    const tempData2 = response2.data.data.data.riskHeirarchy;

    const result2 = processTemplateData(tempData2);
    setTemplateData(result2);
    return result2;
  };

  const GetTemplateData = async (templateId) => {
    const response1 = await MakeRequest(
      `/admin/risk/template/getTemplate/${templateId}`
    ).get();
    if (response1.event !== undefined && !response1.event) {
      return;
    }

    const tempData1 = response1.data.data.data;

    return tempData1;
  };

  const processTemplateData = (data) => {
    let res = {
      manageTemplateData: null,
      selectTemplateFamily: null,
      selectTemplateDealer: null,
      selectTemplateTPP: null,
      selectTemplateCA: null,
      selectTemplateSA: null,
    };

    let mtd = {
      id: 1,
      title: 'Manage Risk Action',
      type: 1,
      selected: true,
      found: false,
      templateId: -1,
      children: [],
    };

    for (let i = 0; i < data.length; i++) {
      let st = {
        dropDownList: [],
        ids: [],
      };

      let chdArr = [];
      for (let j = 0; j < data[i].templates.length; j++) {
        st.dropDownList.push(data[i].templates[j].templateName);
        st.ids.push(data[i].templates[j].templateId);

        let temp = {
          id: data[i].templates[j].templateId,
          selected: false,
          type: 3,
          title: data[i].templates[j].templateName,
          found: false,
          templateId: data[i].templates[j].templateId,
        };
        chdArr.push(temp);
      }

      let ch = {
        id: i + 1,
        title: data[i].displayName,
        type: 2,
        selected: true,
        found: false,
        templateId: -1,
        children: chdArr,
      };

      if (data[i].isHFT === isHFT) {
        mtd.children.push(ch);
      }

      if (data[i].dimensionId === 'FAMILY') {
        res.selectTemplateFamily = st;
      } else if (data[i].dimensionId === 'DEALER') {
        res.selectTemplateDealer = st;
      } else if (data[i].dimensionId === 'TRADE_PRICE_PROTECTION') {
        res.selectTemplateTPP = st;
      } else if (data[i].dimensionId === 'CLEARING_ACCOUNT') {
        res.selectTemplateCA = st;
      } else if (data[i].dimensionId === 'STRATEGY_ACCOUNT') {
        res.selectTemplateSA = st;
      }
    }

    res.manageTemplateData = mtd;

    return res;
  };

  const processData = (data) => {
    // Set initial toggle values for TPP toggle btn
    let tppParam = data[1].tradeProtectionRiskParameters;
    let tppLimit = data[1].tradeProtectionRiskLimits;

    let tppToggleObj = {};
    for (let i = 0; i < tppParam.length; i++) {
      let limit = null;
      for (let j = 0; j < tppLimit.length; j++) {
        if (
          Number(tppLimit[j].riskParamId) === Number(tppParam[i].riskParamId)
        ) {
          limit = tppLimit[j];
          break;
        }
      }

      if (limit) {
        tppToggleObj[tppParam[i].riskParamId] = limit.isPercentage
          ? '%'
          : 'Abs';
      } else {
        tppToggleObj[tppParam[i].riskParamId] = '%';
      }
    }

    setTppToggleState(tppToggleObj);

    // Family hierachy data processing
    let tradeProtectionRiskLimits = data[0].tradeProtectionRiskLimits;

    let familyHierachy = {
      id: 1,
      title: 'All Families',
      type: 1,
      selected: true,
      found: tradeProtectionRiskLimits && tradeProtectionRiskLimits.length > 0,
      children: [],
    };

    let familyRiskLimits = data[0].familyRiskLimits;
    const familyRiskSet = new Set();
    for (let i = 0; i < familyRiskLimits.length; i++) {
      familyRiskSet.add(familyRiskLimits[i].dimensionValue);
    }

    let temp = data[0].families;
    for (let i = 0; i < temp.length; i++) {
      let item = {
        id: temp[i].id,
        selected: false,
        type: 2,
        title: temp[i].name,
        found: familyRiskSet.has(temp[i].id),
      };

      familyHierachy.children.push(item);
    }

    data[0].familyHierachy = familyHierachy;

    // Dealer hierachy data processing

    let dealerHierachy = {
      id: 1,
      title: 'All Dealers',
      type: 1,
      selected: true,
      found: false,
      children: [],
    };

    let dealerRiskLimits = data[0].dealerRiskLimits;
    const dealerRiskSet = new Set();
    for (let i = 0; i < dealerRiskLimits.length; i++) {
      dealerRiskSet.add(dealerRiskLimits[i].dimensionValue);
    }

    temp = data[0].dealers;
    for (let i = 0; i < temp.length; i++) {
      let item = {
        id: temp[i].id,
        selected: false,
        type: 2,
        title: temp[i].name,
        found: dealerRiskSet.has(temp[i].id),
      };

      dealerHierachy.children.push(item);
    }

    data[0].dealerHierachy = dealerHierachy;

    // HFT hierachy data processing

    let clearingAccounts = data[1].clearingAccounts;
    let strategies = data[1].strategies;
    let strategyAccounts = data[1].strategyAccounts;

    let clearingAccRiskLimits = data[1].clearingAccountRiskLimits;
    let strategyRiskLimits = data[1].strategyRiskLimits;
    let strategyAccountRiskLimits = data[1].strategyAccountRiskLimits;

    const clearingAccRiskSet = new Set();
    for (let i = 0; i < clearingAccRiskLimits.length; i++) {
      clearingAccRiskSet.add(Number(clearingAccRiskLimits[i].dimensionValue));
    }

    const strategyRiskSet = new Set();
    for (let i = 0; i < strategyRiskLimits.length; i++) {
      strategyRiskSet.add(Number(strategyRiskLimits[i].dimensionValue));
    }

    const strategyAccountRiskSet = new Set();
    for (let i = 0; i < strategyAccountRiskLimits.length; i++) {
      strategyAccountRiskSet.add(
        Number(strategyAccountRiskLimits[i].dimensionValue)
      );
    }

    let AllFamilyHFTonHierachy = {
      id: 1,
      selected: true,
      type: 1,
      title: 'All HFT',
      found: false,
      children: [],
    };

    let clearingAccountsProcessedList = [];
    for (let x = 0; x < clearingAccounts.length; x++) {
      let clearingAcc = clearingAccounts[x];

      let clearingAccHierachy = {
        id: Number(clearingAcc.id),
        title: clearingAcc.name,
        type: 2,
        selected: false,
        found: clearingAccRiskSet.has(Number(clearingAcc.id)),
        children: [],
      };

      // for (let i = 0; i < strategies.length; i++) {
      // let strategy = strategies[i];
      // if (Number(strategy.clearingAccountId) === Number(clearingAcc.id)) {
      // let strategyHierachy = {
      //   id: Number(strategy.id),
      //   selected: false,
      //   type: 3,
      //   title: strategy.name,
      //   found: strategyRiskSet.has(Number(strategy.id)),
      //   children: [],
      // };

      for (let j = 0; j < strategyAccounts.length; j++) {
        let strategyAccount = strategyAccounts[j];
        if (
          Number(strategyAccount.clearingAccountId) === Number(clearingAcc.id)
        ) {
          let strategyAccountHierachy = {
            id: strategyAccount.id,
            selected: false,
            type: 4,
            title: strategyAccount.name,
            found: strategyAccountRiskSet.has(Number(strategyAccount.id)),
          };

          // strategyHierachy.children.push(strategyAccountHierachy);

          clearingAccHierachy.children.push(strategyAccountHierachy);
        }
      }

      // clearingAccHierachy.children.push(strategyHierachy);
      // }
      // }

      clearingAccountsProcessedList.push(clearingAccHierachy);
    }

    AllFamilyHFTonHierachy.children = clearingAccountsProcessedList;

    data[1].AllFamilyHFTonHierachy = AllFamilyHFTonHierachy;

    // Table header object data creation
    let tableHeaderObject = {
      allFamilies: [],
      families: [],
      dealers: [],
      clearingAccounts: [],
      strategies: [],
      strategyAccounts: [],
    };

    const MakeTableHeaderObjectData = (entityData) => {
      let arr = [];
      for (let i = 0; i < entityData.length; i++) {
        let obj = {
          title: null,
          config: {},
        };

        let rawData = entityData[i];
        if (rawData.levelActionInfo && rawData.levelActionInfo.length > 0) {
          obj.title = rawData.variableKey;
          for (let j = 0; j < rawData.levelActionInfo.length; j++) {
            obj.config[rawData.levelActionInfo[j].name] =
              rawData.levelActionInfo[j].enabled;
          }
        }
        arr.push(obj);
      }
      return arr;
    };

    tableHeaderObject.allFamilies = MakeTableHeaderObjectData(
      data.riskLevelInfo[0].allFamilies.columns
    );
    tableHeaderObject.families = MakeTableHeaderObjectData(
      data.riskLevelInfo[0].families.columns
    );
    tableHeaderObject.dealers = MakeTableHeaderObjectData(
      data.riskLevelInfo[0].dealers.columns
    );
    tableHeaderObject.clearingAccounts = MakeTableHeaderObjectData(
      data.riskLevelInfo[1].clearingAccounts.columns
    );
    tableHeaderObject.strategies = MakeTableHeaderObjectData(
      data.riskLevelInfo[1].strategies.columns
    );
    tableHeaderObject.strategyAccounts = MakeTableHeaderObjectData(
      data.riskLevelInfo[1].strategyAccounts.columns
    );

    data.tableHeaderObject = tableHeaderObject;

    return data;
  };

  const getHeads = () => {
    let header = [];
    let headerObjData = null;

    if (apiData) {
      let headerData = apiData.riskLevelInfo;
      let headerObjectData = apiData.tableHeaderObject;
      if (isHFT) {
        headerData = headerData[1];
        if (selectionHierachyLevel === 1) {
          return [];
        } else if (selectionHierachyLevel === 2) {
          headerData = headerData.clearingAccounts.columns;
          headerObjData = headerObjectData.clearingAccounts;
        } else if (selectionHierachyLevel === 3) {
          headerData = headerData.strategies.columns;
          headerObjData = headerObjectData.strategies;
        } else if (selectionHierachyLevel === 4) {
          headerData = headerData.strategyAccounts.columns;
          headerObjData = headerObjectData.strategyAccounts;
        } else {
          return [];
        }
      } else {
        headerData = headerData[0];
        if (familyDealerToggle == 'Family') {
          if (selectionHierachyLevel === 1) {
            headerData = headerData.allFamilies.columns;
            headerObjData = headerObjectData.allFamilies;
          } else if (selectionHierachyLevel === 2) {
            headerData = headerData.families.columns;
            headerObjData = headerObjectData.families;
          } else {
            return [];
          }
        } else {
          if (selectionHierachyLevel === 1) {
            return [];
          } else if (selectionHierachyLevel === 2) {
            headerData = headerData.dealers.columns;
            headerObjData = headerObjectData.dealers;
          } else {
            return [];
          }
        }
      }

      for (let i = 0; i < headerData.length; i++) {
        header.push(headerData[i].columnName);
      }
    }

    let obj = {
      header: header,
      headerObjData: headerObjData,
    };

    return obj;
  };

  // const updateFamilyDataonConfigSuccess = (updatedData, familyId = -1) => {
  //   let displayState = [...displayDataState.displayTable];
  //   for (let displayRow of displayState) {
  //     let riskParamId = displayRow.riskParamId;
  //     // riskParamId = riskParamId && riskParamId.length > 1 ? riskParamId[0] : -1;
  //     if (
  //       updatedData.tableContent.familyLevel &&
  //       updatedData.tableContent.familyLevel[familyId] &&
  //       Array.isArray(updatedData.tableContent.familyLevel[familyId])
  //     ) {
  //       for (let famRisk of updatedData.tableContent.familyLevel[familyId]) {
  //         if (famRisk.riskParamId === riskParamId) {
  //           displayRow.limitAmount = famRisk.limitAmount;
  //           break;
  //         }
  //       }
  //       setDisplayDataState({
  //         ...displayDataState,
  //         displayTable: displayState ? displayState : [],
  //         tempHold: [],
  //         tableContent: {
  //           adminLevel: displayDataState.tableContent.adminLevel
  //             ? displayDataState.tableContent.adminLevel
  //             : [],
  //           familyLevel: updatedData.tableContent.familyLevel
  //             ? updatedData.tableContent.familyLevel
  //             : {},
  //         },
  //       });
  //     }
  //   }
  // };

  // const updateDealerDataonConfigSuccess = (updatedData, dealerId) => {
  //   let displayState = [...displayDataState.displayTable];
  //   for (let displayRow of displayState) {
  //     let riskParamId = displayRow.riskParamId;
  //     // riskParamId = riskParamId && riskParamId.length > 1 ? riskParamId[0] : -1;
  //     if (
  //       updatedData.dealerRiskValues &&
  //       Array.isArray(updatedData.dealerRiskValues)
  //     ) {
  //       for (let dealRisk of updatedData.dealerRiskValues) {
  //         if (
  //           dealRisk.dealerId === dealerId &&
  //           dealRisk.riskParamId === riskParamId
  //         ) {
  //           displayRow.limitAmount = dealRisk.limitAmount;
  //           break;
  //         }
  //       }
  //       setDisplayDataState({
  //         ...displayDataState,
  //         displayTable: displayState,
  //         tempHold: [],
  //         dealerRiskParam: updatedData.dealerRiskParam
  //           ? updatedData.dealerRiskParam
  //           : [],
  //         dealerRiskValues: updatedData.dealerRiskValues
  //           ? updatedData.dealerRiskValues
  //           : [],
  //       });
  //     }
  //   }
  // };

  // const updateTradePriceProtectionDataonConfigSuccess = (
  //   updatedData,
  //   familyId = -1
  // ) => {
  //   let displayState = [...displayDataState.displayTable];
  //   for (let displayRow of displayState) {
  //     let riskParamId = displayRow.riskParamId;
  //     // riskParamId = riskParamId && riskParamId.length > 1 ? riskParamId[0] : -1;
  //     if (
  //       updatedData.tableContent.adminLevel &&
  //       Array.isArray(updatedData.tableContent.adminLevel)
  //     ) {
  //       for (let famRisk of updatedData.tableContent.adminLevel) {
  //         if (famRisk.riskParamId === riskParamId) {
  //           displayRow.limit = famRisk.limit;
  //           break;
  //         }
  //       }
  //       setDisplayDataState({
  //         ...displayDataState,
  //         displayTable: displayState,
  //         tempHold: [],
  //         tableContent: {
  //           adminLevel: updatedData.tableContent.adminLevel
  //             ? updatedData.tableContent.adminLevel
  //             : [],
  //           familyLevel: displayDataState.tableContent.familyLevel
  //             ? displayDataState.tableContent.familyLevel
  //             : {},
  //         },
  //       });
  //     }
  //   }
  // };

  const handleCheckChange = (target, state) => {

    let riskParams = [...riskParameterFilter];
    for (let i = 0; i < riskParams.length; i++) {
      if (riskParams[i].id === target.id) {
        riskParams[i].state = state;
        break;
      }
    }

    let add = [...addRiskParamList];
    let rem = [...removeRiskParamList];

    if (state) {
      const index = rem.indexOf(target.id);
      if (index > -1) {
        rem.splice(index, 1);
      } else {
        add.push(target.id);
      }
    } else {
      const index = add.indexOf(target.id);
      if (index > -1) {
        add.splice(index, 1);
      } else {
        rem.push(target.id);
      }
    }

    setAddRiskParamList(add);
    setRemoveRiskParamList(rem);
    setRiskParameterFilter(riskParams);
  };

  // const handleCHeckChange = (target, state) => {
  //   const tempState = { ...displayDataState };
  //   for (let i = 0; i < tempState.currRules.length; i++) {
  //     if (tempState.currRules[i].id === target.id) {
  //       tempState.currRules[i].state = state;
  //     }
  //   }

  //   let riskParamName = '',
  //     riskParamValue = 0;
  //   if (tempState.currentSelection.type === navConst.TRADE_PP) {
  //     if (state) {
  //       let found = false;

  //       for (let i = 0; i < tempState.tableContent.adminLevel.length; i++) {
  //         if (tempState.tableContent.adminLevel[i].riskParamId === target.id) {
  //           riskParamName = `${target.id}-limitAmount`;
  //           riskParamValue = 0;
  //           tempState.displayTable.push(tempState.tableContent.adminLevel[i]);
  //           found = true;
  //         }
  //       }
  //       if (!found) {
  //         const obj = {
  //           riskParamId: target.id,
  //           limit: '',
  //           sno: tempState.displayTable.length + 1,
  //           tradeName: target.title,
  //           isPercentage: tempState.tradePPOptions[target.id].isPercentage,
  //         };
  //         tempState.displayTable.push(obj);
  //       }
  //     } else {
  //       const tempTable = tempState.displayTable.filter(
  //         (obj) => obj.riskParamId !== target.id
  //       );
  //       tempState.displayTable = tempTable;
  //     }
  //   } else {
  //     if (state) {
  //       let found = false;
  //       const searchSpace =
  //         tempState.tableContent.familyLevel[
  //           tempState.currentSelection.familyId
  //         ] || [];
  //       for (let i = 0; i < searchSpace.length; i++) {
  //         if (searchSpace[i].riskParamId === target.id) {
  //           riskParamName = `${target.id}-limitAmount`;
  //           riskParamValue = 0;
  //           tempState.displayTable.push(searchSpace[i]);
  //           found = true;
  //         }
  //       }
  //       if (!found) {
  //         riskParamName = `${target.id}-limitAmount`;
  //         riskParamValue = 0;
  //         const obj = {
  //           riskParamId: target.id,
  //           limit: '',
  //           sno: tempState.displayTable.length + 1,
  //           isPercentage: target.isPercentage,
  //           riskType: target.title,
  //           alert: 0,
  //           urgent: 0,
  //           warning: 0,
  //           familyId: tempState.currentSelection.familyId,
  //           limitAmount: 0,
  //           name: '',
  //         };
  //         tempState.displayTable.push(obj);
  //       }
  //     } else {
  //       const tempTable = tempState.displayTable.filter(
  //         (obj) => obj.riskParamId !== target.id
  //       );
  //       tempState.displayTable = tempTable;
  //     }
  //   }

  //   if (state) {
  //     setDisplayDataState(tempState);
  //     const entryObj = {
  //       target: {
  //         name: riskParamName,
  //         value: riskParamValue,
  //       },
  //     };
  //     tableDataChange(entryObj);
  //   } else {
  //     if (
  //       tempState &&
  //       tempState.tempHold &&
  //       tempState.tempHold.length &&
  //       target &&
  //       target.id &&
  //       !isNaN(target.id)
  //     )
  //       for (let i = 0; i < tempState.tempHold.length; i++) {
  //         if (tempState.tempHold[i].riskParamId === parseInt(target.id)) {
  //           tempState.tempHold.splice(i, 1);
  //           break;
  //         }
  //       }
  //     setDisplayDataState(tempState);
  //   }
  // };

  // const hierClickAction = (clickType, id) => {
  //   const temp = { ...displayDataState };
  //   const tempHi = temp.hierarchy;
  //   if (clickType === navConst.RISK_PF) {
  //     tempHi[0].selected = false;
  //     tempHi[0].children = tempHi[0].children.map((obj) => {
  //       return { ...obj, selected: obj.id === id };
  //     });
  //     if (temp.tableContent.familyLevel[id] === undefined) {
  //       const arr = temp.allRules[2].map((obj) => {
  //         return { ...obj, state: false };
  //       });
  //       temp.hierarchy = tempHi;
  //       temp.currentSelection.type = clickType;
  //       temp.tempHold = [];
  //       temp.currRules = arr;
  //       temp.displayTable = [];
  //       temp.currentSelection.familyId = id;
  //       setDisplayDataState(temp);
  //       return;
  //     }
  //     temp.displayTable = [...temp.tableContent.familyLevel[id]];
  //     const tempSet = new Set(temp.displayTable.map((obj) => obj.riskParamId));
  //     temp.currRules = temp.allRules[clickType].map((obj) => {
  //       return { ...obj, state: tempSet.has(obj.id) };
  //     });
  //   } else if (clickType === navConst.TRADE_PP) {
  //     tempHi[0].selected = true;
  //     tempHi[0].children = tempHi[0].children.map((obj) => {
  //       return { ...obj, selected: false };
  //     });

  //     temp.displayTable = [...temp.tableContent.adminLevel];

  //     const tempSet = new Set(
  //       temp.tableContent.adminLevel.map((obj) => obj.riskParamId)
  //     );
  //     temp.currRules = temp.allRules[clickType].map((obj) => {
  //       return { ...obj, state: tempSet.has(obj.id) };
  //     });
  //   } else if (clickType === navConst.RISK_PD) {
  //     temp.dealHier[0].selected = id === temp.dealHier[0].id;
  //     for (let i = 0; i < temp.dealHier[0].children.length; i++) {
  //       temp.dealHier[0].children[i].selected =
  //         id === temp.dealHier[0].children[i].id;
  //     }

  //     temp.displayTable = [];
  //     if (temp.dealerRiskValues) {
  //       for (let tempObj of temp.dealerRiskValues) {
  //         if (id === tempObj.dealerId) {
  //           temp.displayTable.push({
  //             ...tempObj,
  //             riskType:
  //               displayDataState.dealerRiskParam[tempObj.riskParamId]
  //                 .displayName,
  //             sno: temp.displayTable.length + 1,
  //           });
  //         }
  //       }
  //     }

  //     const tempSet = new Set();
  //     temp.displayTable.forEach((element) => {
  //       tempSet.add(element.riskParamId);
  //     });
  //     temp.currRules = [...temp.allRules[clickType]];
  //     for (let i = 0; i < temp.currRules.length; i++) {
  //       temp.currRules[i].state = tempSet.has(temp.currRules[i].id);
  //     }
  //   }

  //   temp.hierarchy = tempHi;
  //   temp.currentSelection.type = clickType;
  //   temp.tempHold = [];
  //   temp.currentSelection.familyId = id;
  //   setDisplayDataState(temp);
  // };

  const selectTemplateCallback = (args) => {
    // console.log(args);
  };

  const prePostToggleClickAction = (val) => {
    setTradeType(val);
    hierachyClickAction(selectionHierachyLevel, selectionId, val);
    tableDataGenerator();
  };

  const GetTemplateId = (arr, dimensionId) => {
    let templateId = -1;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].dimensionValue === dimensionId) {
        templateId = arr[i].templateId;
        break;
      }
    }
    return templateId;
  };

  const hierachyClickAction = (clickType, id, currentTradeType = tradeType) => {
    setSelectionHierachyLevel(clickType);
    setSelectionId(id);
    setTableChangeData([]);
    setTableChangeObject({});
    setAddRiskParamList([]);
    setRemoveRiskParamList([]);
    setHasValuesChanged(false);

    currentTradeType = 'Pre Trade';

    let riskParameters = [];
    let parameters = [],
      limits = [],
      entities = [],
      availableTemplates = {};

    if (isHFT) {
      if (clickType == 1) {
        riskParameters = [];
        setTemplateAvailable({});
        setTemplateSelected({
          selectedName: 'Select Template',
          selectedId: -1,
        });
      } else if (clickType === 2) {
        parameters = apiData[1].clearingAccountRiskParameters;
        limits = apiData[1].clearingAccountRiskLimits;
        entities = apiData[1].clearingAccounts;
        availableTemplates = templateData.selectTemplateCA;
        currentTradeType = 'Post Trade';
        setDisableTradeType(true);
        setTemplateAvailable(templateData.selectTemplateCA);
        setTemplateSelected({
          selectedName: 'Select Template',
          selectedId: -1,
        });
      } else if (clickType === 3) {
        parameters = apiData[1].strategyRiskParameters;
        limits = apiData[1].strategyRiskLimits;
        entities = apiData[1].strategies;
        availableTemplates = {};
        setTemplateAvailable({});
        setTemplateSelected({
          selectedName: 'Select Template',
          selectedId: -1,
        });
      } else if (clickType === 4) {
        parameters = apiData[1].strategyAccountRiskParameters;
        limits = apiData[1].strategyAccountRiskLimits;
        entities = apiData[1].strategyAccounts;
        availableTemplates = templateData.selectTemplateSA;
        currentTradeType = 'Pre Trade';
        setDisableTradeType(true);
        setTemplateAvailable(templateData.selectTemplateSA);
        setTemplateSelected({
          selectedName: 'Select Template',
          selectedId: -1,
        });
      }
    } else {
      if (familyDealerToggle === 'Family') {
        if (clickType == 1) {
          parameters = apiData[0].tradeProtectionRiskParameters;
          limits = apiData[0].tradeProtectionRiskLimits;
          availableTemplates = templateData.selectTemplateTPP;
          id = -1;
          currentTradeType = 'Pre Trade';
          setDisableTradeType(true);
          setTemplateAvailable(templateData.selectTemplateTPP);
          setTemplateSelected({
            selectedName: 'Select Template',
            selectedId: -1,
          });
        } else if (clickType == 2) {
          parameters = apiData[0].familyRiskParameters;
          limits = apiData[0].familyRiskLimits;
          entities = apiData[0].families;
          availableTemplates = templateData.selectTemplateFamily;
          currentTradeType = 'Pre Trade';
          setDisableTradeType(true);
          setTemplateAvailable(templateData.selectTemplateFamily);
          setTemplateSelected({
            selectedName: 'Select Template',
            selectedId: -1,
          });
        }
      } else if (familyDealerToggle === 'Dealer') {
        if (clickType == 1) {
          riskParameters = [];
          availableTemplates = {};
          setTemplateAvailable({});
          setTemplateSelected({
            selectedName: 'Select Template',
            selectedId: -1,
          });
        } else if (clickType == 2) {
          parameters = apiData[0].dealerRiskParameters;
          limits = apiData[0].dealerRiskLimits;
          entities = apiData[0].dealers;
          availableTemplates = templateData.selectTemplateDealer;
          currentTradeType = 'Pre Trade';
          setDisableTradeType(true);
          setTemplateAvailable(templateData.selectTemplateDealer);
          setTemplateSelected({
            selectedName: 'Select Template',
            selectedId: -1,
          });
        }
      }
    }

    const limitSet = new Set();
    for (let i = 0; i < limits.length; i++) {
      if (limits[i].dimensionValue == id) {
        limitSet.add(limits[i].riskParamId);
      }
    }

    for (let i = 0; i < parameters.length; i++) {
      let temp = {
        id: parameters[i].riskParamId,
        title: parameters[i].displayName,
        state: limitSet.has(parameters[i].riskParamId),
      };

      if (
        currentTradeType == 'Pre Trade' &&
        parameters[i].prePostTradeEnum != 'POST_TRADE'
      ) {
        // console.log('Pre filter insert...');
        riskParameters.push(temp);
      } else if (
        currentTradeType == 'Post Trade' &&
        parameters[i].prePostTradeEnum != 'PRE_TRADE'
      ) {
        // console.log('Post filter insert...');
        riskParameters.push(temp);
      }
    }

    let templateId = GetTemplateId(entities, id);

    if (
      templateId &&
      availableTemplates &&
      availableTemplates.dropDownList &&
      availableTemplates.dropDownList.length > 0
    ) {
      let templateName =
        availableTemplates.dropDownList[
          availableTemplates.ids.indexOf(templateId)
        ];
      templateSelect(templateName, templateId);
    } else {
      setTemplateSelected({
        selectedName: 'Select Template',
        selectedId: -1,
      });
    }

    setRiskParameterFilterDifference(riskParameters.map((item) => item.state));
    setTradeType(currentTradeType);
    setRiskParameterFilter(riskParameters);
    tableDataGenerator(currentTradeType);
  };

  const tableDataFormatter = (
    parameters,
    limits,
    id,
    type,
    currentTradeType = tradeType
  ) => {
    let tableData = [];
    for (let i = 0; i < limits.length; i++) {
      let limit = limits[i];
      let parameter = null;

      if (type === 1) {
        let obj = {
          assetClassId: limit.riskParamId,
          limit: limit.riskParamValue,
          tradeName: null,
          sno: i + 1,
          isPercentage: null,
          riskParamId: limit.riskParamId,
          canChange: null,
        };

        for (let j = 0; j < parameters.length; j++) {
          if (limit.riskParamId === parameters[j].riskParamId) {
            parameter = parameters[j];
            break;
          }
        }

        obj.tradeName = parameter.displayName;
        obj.isPercentage = parameter.isPercentage;
        obj.canChange = parameter.isRiskLevelSetReq;

        let found = false;
        for (let j = 0; j < riskParameterFilter.length; j++) {
          if (riskParameterFilter[j].id == limit.riskParamId) {
            found = riskParameterFilter[j].state;
            break;
          }
        }

        if (found) {
          if (
            currentTradeType == 'Pre Trade' &&
            parameter.prePostTradeEnum != 'POST_TRADE'
          ) {
            tableData.push(obj);
          } else if (
            currentTradeType == 'Post Trade' &&
            parameter.prePostTradeEnum != 'PRE_TRADE'
          ) {
            tableData.push(obj);
          }
        }

        // tableData.push(obj);
      } else if (type === 2) {
        let obj = {
          assetClassId: limit.riskParamId,
          limitAmount: limit.riskParamValue,
          L1: limit.L1,
          L2: limit.L2,
          L3: limit.L3,
          L4: limit.L4,
          L5: limit.L5,
          L6: limit.L6,
          riskType: null,
          sno: i + 1,
          isPercentage: null,
          riskParamId: limit.riskParamId,
          canChange: null,
        };

        for (let j = 0; j < parameters.length; j++) {
          if (limit.riskParamId === parameters[j].riskParamId) {
            parameter = parameters[j];
            break;
          }
        }

        obj.riskType = parameter.displayName;
        obj.isPercentage = parameter.isPercentage;
        obj.canChange = parameter.isRiskLevelSetReq;

        let found = false;
        for (let j = 0; j < riskParameterFilter.length; j++) {
          if (riskParameterFilter[j].id == limit.riskParamId) {
            found = riskParameterFilter[j].state;
            break;
          }
        }

        // dimension Value ID can be in different datatypes
        if (limit.dimensionValue == id && found) {
          if (
            currentTradeType == 'Pre Trade' &&
            parameter.prePostTradeEnum != 'POST_TRADE'
          ) {
            tableData.push(obj);
          } else if (
            currentTradeType == 'Post Trade' &&
            parameter.prePostTradeEnum != 'PRE_TRADE'
          ) {
            tableData.push(obj);
          }
        }
      } else if (type === 3) {
        let obj = {
          assetClassId: limit.riskParamId,
          limitAmount: limit.riskParamValue,
          urgent: limit.urgent,
          warning: limit.warning,
          alert: limit.alert,
          riskType: null,
          sno: i + 1,
          isPercentage: null,
          riskParamId: limit.riskParamId,
          canChange: null,
        };

        for (let j = 0; j < parameters.length; j++) {
          if (limit.riskParamId === parameters[j].riskParamId) {
            parameter = parameters[j];
            break;
          }
        }

        obj.riskType = parameter.displayName;
        obj.isPercentage = parameter.isPercentage;
        obj.canChange = parameter.isRiskLevelSetReq;

        let found = false;
        for (let j = 0; j < riskParameterFilter.length; j++) {
          if (riskParameterFilter[j].id == limit.riskParamId) {
            found = riskParameterFilter[j].state;
            break;
          }
        }

        // dimension Value ID can be in different datatypes
        if (limit.dimensionValue == id && found) {
          if (
            currentTradeType == 'Pre Trade' &&
            parameter.prePostTradeEnum != 'POST_TRADE'
          ) {
            tableData.push(obj);
          } else if (
            currentTradeType == 'Post Trade' &&
            parameter.prePostTradeEnum != 'PRE_TRADE'
          ) {
            tableData.push(obj);
          }
        }
      }
    }

    // Puting in rows for new added risk params (with default values in them)
    for (let i = 0; i < addRiskParamList.length; i++) {
      let obj = {};
      if (type == 1) {
        obj = {
          assetClassId: addRiskParamList[i],
          limit: Number(
            apiData.riskLevelInfo[0].allFamilies.columns[1].defaultValue
          ),
          tradeName: null,
          sno: limits.length + 1,
          isPercentage: null,
          riskParamId: addRiskParamList[i],
          canChange: null,
        };
      } else if (type == 2) {
        obj = {
          assetClassId: addRiskParamList[i],
          limitAmount: Number(
            apiData.riskLevelInfo[0].families.columns[1].defaultValue
          ),
          L1: Number(apiData.riskLevelInfo[0].families.columns[2].defaultValue),
          L2: Number(apiData.riskLevelInfo[0].families.columns[3].defaultValue),
          L3: Number(apiData.riskLevelInfo[0].families.columns[4].defaultValue),
          L4: Number(apiData.riskLevelInfo[0].families.columns[5].defaultValue),
          L5: Number(apiData.riskLevelInfo[0].families.columns[6].defaultValue),
          L6: Number(apiData.riskLevelInfo[0].families.columns[7].defaultValue),
          riskType: null,
          sno: limits.length + 1,
          isPercentage: null,
          riskParamId: addRiskParamList[i],
          canChange: null,
        };
      } else if (type == 3) {
        obj = {
          assetClassId: addRiskParamList[i],
          limitAmount: Number(
            apiData.riskLevelInfo[1].clearingAccounts.columns[1].defaultValue
          ),
          urgent: Number(
            apiData.riskLevelInfo[1].clearingAccounts.columns[4].defaultValue
          ),
          warning: Number(
            apiData.riskLevelInfo[1].clearingAccounts.columns[3].defaultValue
          ),
          alert: Number(
            apiData.riskLevelInfo[1].clearingAccounts.columns[2].defaultValue
          ),
          riskType: null,
          sno: limits.length + 1,
          isPercentage: null,
          riskParamId: addRiskParamList[i],
          canChange: null,
        };
      }

      let parameter = null;
      for (let j = 0; j < parameters.length; j++) {
        if (addRiskParamList[i] === parameters[j].riskParamId) {
          parameter = parameters[j];
          break;
        }
      }

      obj.riskType = parameter.displayName;
      obj.isPercentage = parameter.isPercentage;
      obj.canChange = parameter.isRiskLevelSetReq;

      tableData.push(obj);
    }

    return tableData;
  };

  function isMarketHour() {
    try {
      let tradingStartTime = moment('09:15', 'HH:mm');
      let tradingEndTime = moment('15:30', 'HH:mm');
      let curTime = moment();
      if (
        curTime < tradingStartTime ||
        curTime > tradingEndTime ||
        apiData.isTradingHoliday
      ) {
        return false;
      }
      return true;
    } catch (err) {
      console.log(
        `${new Date().toISOString()}: error: Method <isMarketHour> failed with error: ${
          err.message
        }`
      );
      return true;
    }
  }

  const absPerToggleChange = (riskParamId, val) => {
    let obj = { ...tppToggleState };
    obj[riskParamId] = val;
    setTppToggleState(obj);
    TPPContentChange(null, 'absPer', val, riskParamId);
  };

  const TPPContentChange = (evt, fldName = null, val = null, riskId = null) => {
    let info, tarRiskId, fieldName, valueChanged;
    if (evt) {
      info = evt.target.name.split('-');
      tarRiskId = parseInt(info[0]);
      fieldName = info[1];

      valueChanged = evt.target.value;
      console.log(evt.target.value);
      if (valueChanged === '') {
        valueChanged = '0';
      }
    } else {
      tarRiskId = riskId;
      fieldName = fldName;
      valueChanged = val;
    }

    // let t = String(valueChanged);
    // if (isLetter(t[t.length() - 1])) {
    //   console.log('WTF!');
    // }

    //? This is commented to allow decimal values
    // if (valueChanged && parseInt(valueChanged)) {
    //   valueChanged = String(parseInt(valueChanged));
    // }

    let tableChangeObjectCopy = { ...tableChangeObject };
    tableChangeObjectCopy[`${tarRiskId}-${fieldName}`] = valueChanged;

    setTableChangeObject(tableChangeObjectCopy);

    setHasValuesChanged(true);

    console.log(tarRiskId, fieldName, valueChanged);

    let tableRiskObj = null;
    for (let i = 0; i < tableChangeData.length; i++) {
      if (tableChangeData[i].riskParamId === tarRiskId) {
        tableRiskObj = tableChangeData[i];
        break;
      }
    }

    if (tableRiskObj) {
      tableRiskObj.from =
        fieldName === 'from' ? valueChanged : tableRiskObj.from;
      tableRiskObj.to = fieldName === 'to' ? valueChanged : tableRiskObj.to;
      tableRiskObj.limit =
        fieldName === 'limit' ? valueChanged : tableRiskObj.limit;
      tableRiskObj.absPer =
        fieldName === 'absPer' ? valueChanged : tableRiskObj.absPer;
    } else {
      let riskObj = {
        riskParamId: tarRiskId,
        from: fieldName === 'from' ? valueChanged : null,
        to: fieldName === 'to' ? valueChanged : null,
        limit: fieldName === 'limit' ? valueChanged : null,
        absPer: fieldName === 'absPer' ? valueChanged : null,
      };
      tableChangeData.push(riskObj);
    }

    let temp = [...tableChangeData];

    setTableChangeData(temp);
  };

  function TPPContent() {
    try {
      let tppParamaters = apiData[1].tradeProtectionRiskParameters;
      let tppLimits = apiData[1].tradeProtectionRiskLimits;
      let tppData = [];

      for (let i = 0; i < tppParamaters.length; i++) {
        let obj = { ...tppParamaters[i] };
        let limit = null;
        for (let j = 0; j < tppLimits.length; j++) {
          if (Number(tppLimits[j].riskParamId) == Number(obj.riskParamId)) {
            limit = tppLimits[j];
            break;
          }
        }

        if (limit) {
          obj.dimensionValue = limit.dimensionValue;
          obj.riskParamValue = limit.riskParamValue;
          obj.lowerSlabLimit = limit.lowerSlabLimit;
          obj.upperSlabLimit = limit.upperSlabLimit;
          obj.isPercentage =
            limit.isPercentage || limit.isPercentage == false
              ? limit.isPercentage
              : true;
        } else {
          obj.isPercentage = true;
        }

        tppData.push(obj);
      }

      let tableContent = [];
      for (let idx = 0; idx < tppData.length; idx++) {
        let row = tppData[idx];
        tableContent.push(
          <tr key={`${row.displayName}`} className="riskUtilTr">
            <td>{row.displayName}</td>
            <td>
              <input
                className="inputFieldValue"
                name={`${row.riskParamId}-from`}
                type="text"
                // placeholder={obj.limitAmount}
                onChange={TPPContentChange}
                // value={obj.limitAmount}
                disabled={
                  row.displayName === 'Cash' || row.displayName === 'Futures'
                }
                value={
                  tableChangeObject[`${row.riskParamId}-from`]
                    ? tableChangeObject[`${row.riskParamId}-from`]
                    : row.lowerSlabLimit
                }
              />
            </td>

            <td>
              <input
                className="inputFieldValue"
                name={`${row.riskParamId}-to`}
                type="text"
                // placeholder={obj.limitAmount}
                onChange={TPPContentChange}
                // value={obj.limitAmount}
                disabled={
                  row.displayName === 'Cash' || row.displayName === 'Futures'
                }
                value={
                  tableChangeObject[`${row.riskParamId}-to`]
                    ? tableChangeObject[`${row.riskParamId}-to`]
                    : row.upperSlabLimit
                }
              />
            </td>

            <td>
              {row.isAbsPercToggleEnabled ? (
                <div>
                  <ToggleTab
                    options={['Abs', '%']}
                    selected={tppToggleState[row.riskParamId]}
                    extStyles={{ margin: '0 0 0 1vw', borderRadius: '5px' }}
                    // disabled={disableTradeType}
                    onSelect={(val) => absPerToggleChange(row.riskParamId, val)}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </td>

            <td>
              <input
                className="inputFieldValue"
                name={`${row.riskParamId}-limit`}
                type="text"
                // placeholder={obj.limitAmount}
                onChange={TPPContentChange}
                // value={obj.limitAmount}
                // disabled={!obj.canChange}
                value={
                  tableChangeObject[`${row.riskParamId}-limit`]
                    ? tableChangeObject[`${row.riskParamId}-limit`]
                    : row.riskParamValue
                }
              />
              {tppToggleState[row.riskParamId] === '%' && <span> %</span>}
            </td>
          </tr>
        );
      }

      return tableContent;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  function tableDataGenerator(currentTradeType = tradeType) {
    if (apiData) {
      if (isHFT) {
        let displayData = [];
        if (selectionHierachyLevel === 1) {
        } else if (selectionHierachyLevel === 2) {
          displayData = tableDataFormatter(
            apiData[1].clearingAccountRiskParameters,
            apiData[1].clearingAccountRiskLimits,
            selectionId,
            2,
            currentTradeType
          );
        } else if (selectionHierachyLevel === 3) {
          displayData = tableDataFormatter(
            apiData[1].strategyRiskParameters,
            apiData[1].strategyRiskLimits,
            selectionId,
            2,
            currentTradeType
          );
        } else if (selectionHierachyLevel === 4) {
          displayData = tableDataFormatter(
            apiData[1].strategyAccountRiskParameters,
            apiData[1].strategyAccountRiskLimits,
            selectionId,
            2,
            currentTradeType
          );
        } else {
          return [];
        }

        if (selectionHierachyLevel == 4)
          return displayData.map((obj) => (
            <tr key={`${obj.sno}_${obj.riskType}`} className="riskUtilTr">
              <td>{obj.riskType}</td>
              <td>
                {obj.isPercentage ? (
                  <div className="riskUtilTr">
                    <input
                      className="inputFieldValue"
                      name={`${obj.riskParamId}-limitAmount`}
                      type="text"
                      placeholder={obj.limitAmount}
                      onChange={tableDataChange}
                      // value={obj.limitAmount}
                      // disabled={!obj.canChange}
                      value={
                        tableChangeObject[`${obj.riskParamId}-limitAmount`] || tableChangeObject[`${obj.riskParamId}-limitAmount`] == ''
                          ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          : obj.limitAmount
                      }
                      // style={{ width: '85%', marginRight: '0.5vw' }}
                    />
                    <span>%</span>
                  </div>
                ) : (
                  <input
                    className="inputFieldValue"
                    name={`${obj.riskParamId}-limitAmount`}
                    type="text"
                    placeholder={obj.limitAmount}
                    onChange={tableDataChange}
                    // value={obj.limitAmount}
                    // disabled={!obj.canChange}
                    value={
                      tableChangeObject[`${obj.riskParamId}-limitAmount`] || tableChangeObject[`${obj.riskParamId}-limitAmount`] == ''
                        ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                        : obj.limitAmount
                    }
                    // style={{ width: '85%', marginRight: '0.5vw' }}
                  />
                )}
              </td>
            </tr>
          ));
        else
          return displayData.map((obj) => (
            <tr key={`${obj.sno}_${obj.riskType}`} className="riskUtilTr">
              <td>{obj.riskType}</td>
              <td>
                {obj.isPercentage ? (
                  <div className="riskUtilTr">
                    <input
                      className="inputFieldValue"
                      name={`${obj.riskParamId}-limitAmount`}
                      type="text"
                      placeholder={obj.limitAmount}
                      onChange={tableDataChange}
                      // value={obj.limitAmount}
                      // disabled={!obj.canChange}
                      value={
                        tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          : obj.limitAmount
                      }
                      style={{ width: '85%', marginRight: '0.5vw' }}
                    />
                    <span>%</span>
                  </div>
                ) : (
                  <input
                    className="inputFieldValue"
                    name={`${obj.riskParamId}-limitAmount`}
                    type="text"
                    placeholder={obj.limitAmount}
                    onChange={tableDataChange}
                    // value={obj.limitAmount}
                    // disabled={!obj.canChange}
                    value={
                      tableChangeObject[`${obj.riskParamId}-limitAmount`]
                        ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                        : obj.limitAmount
                    }
                    style={{ width: '85%', marginRight: '0.5vw' }}
                  />
                )}
              </td>
              <td>
                <input
                  className="inputField"
                  name={`${obj.riskParamId}-L1`}
                  type="text"
                  placeholder={!obj.canChange ? 'NA' : obj.L1}
                  onChange={tableDataChange}
                  // value={obj.L1}
                  disabled={!obj.canChange || isMarketHour()}
                  value={
                    tableChangeObject[`${obj.riskParamId}-L1`]
                      ? tableChangeObject[`${obj.riskParamId}-L1`]
                      : obj.L1
                  }
                />
              </td>
              <td>
                <input
                  className="inputField"
                  name={`${obj.riskParamId}-L2`}
                  type="text"
                  placeholder={!obj.canChange ? 'NA' : obj.L2}
                  onChange={tableDataChange}
                  // value={obj.L2}
                  disabled={!obj.canChange || isMarketHour()}
                  value={
                    tableChangeObject[`${obj.riskParamId}-L2`]
                      ? tableChangeObject[`${obj.riskParamId}-L2`]
                      : obj.L2
                  }
                />
              </td>
              <td>
                <input
                  className="inputField"
                  name={`${obj.riskParamId}-L3`}
                  type="text"
                  placeholder={!obj.canChange ? 'NA' : obj.L3}
                  onChange={tableDataChange}
                  // value={obj.L3}
                  disabled={!obj.canChange || isMarketHour()}
                  value={
                    tableChangeObject[`${obj.riskParamId}-L3`]
                      ? tableChangeObject[`${obj.riskParamId}-L3`]
                      : obj.L3
                  }
                />
              </td>
              <td>
                <input
                  className="inputField"
                  name={`${obj.riskParamId}-L4`}
                  type="text"
                  placeholder={!obj.canChange ? 'NA' : obj.L4}
                  onChange={tableDataChange}
                  // value={obj.L4}
                  disabled={!obj.canChange || isMarketHour()}
                  value={
                    tableChangeObject[`${obj.riskParamId}-L4`]
                      ? tableChangeObject[`${obj.riskParamId}-L4`]
                      : obj.L4
                  }
                />
              </td>
              <td>
                <input
                  className="inputField"
                  name={`${obj.riskParamId}-L5`}
                  type="text"
                  placeholder={!obj.canChange ? 'NA' : obj.L5}
                  onChange={tableDataChange}
                  // value={obj.L5}
                  disabled={!obj.canChange || isMarketHour()}
                  value={
                    tableChangeObject[`${obj.riskParamId}-L5`]
                      ? tableChangeObject[`${obj.riskParamId}-L5`]
                      : obj.L5
                  }
                />
              </td>
              <td>
                <input
                  className="inputField"
                  name={`${obj.riskParamId}-L6`}
                  type="text"
                  placeholder={!obj.canChange ? 'NA' : obj.L6}
                  onChange={tableDataChange}
                  // value={obj.L6}
                  disabled={!obj.canChange || isMarketHour()}
                  value={
                    tableChangeObject[`${obj.riskParamId}-L6`]
                      ? tableChangeObject[`${obj.riskParamId}-L6`]
                      : obj.L6
                  }
                />
              </td>
            </tr>
          ));
      } else {
        if (familyDealerToggle == 'Family') {
          if (selectionHierachyLevel === 1) {
            let displayData = tableDataFormatter(
              apiData[0].tradeProtectionRiskParameters,
              apiData[0].tradeProtectionRiskLimits,
              selectionId,
              1,
              currentTradeType
            );
            return displayData.map((obj) => (
              <tr key={obj.sno} className="riskUtilTr">
                <td>{obj.tradeName}</td>
                <td>
                  <input
                    className="inputFieldValue"
                    name={`${obj.riskParamId}-limitAmount`}
                    type="text"
                    placeholder={obj.limit}
                    onChange={tableDataChange}
                    // value={obj.limit}
                    // disabled={false}
                    value={
                      tableChangeObject[`${obj.riskParamId}-limitAmount`]
                        ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                        : obj.limit
                    }
                  />
                </td>
              </tr>
            ));
          } else if (selectionHierachyLevel === 2) {
            let displayData = tableDataFormatter(
              apiData[0].familyRiskParameters,
              apiData[0].familyRiskLimits,
              selectionId,
              2,
              currentTradeType
            );
            return displayData.map((obj) => (
              <tr key={`${obj.sno}_${obj.riskType}`} className="riskUtilTr">
                <td>{obj.riskType}</td>
                <td>
                  {obj.isPercentage ? (
                    <div className="riskUtilTr">
                      <input
                        className="inputFieldValue"
                        name={`${obj.riskParamId}-limitAmount`}
                        type="text"
                        placeholder={obj.limitAmount}
                        onChange={tableDataChange}
                        // value={obj.limitAmount}
                        // disabled={!obj.canChange}
                        value={
                          tableChangeObject[`${obj.riskParamId}-limitAmount`]
                            ? tableChangeObject[
                                `${obj.riskParamId}-limitAmount`
                              ]
                            : obj.limitAmount
                        }
                        style={{ width: '85%', marginRight: '0.5vw' }}
                      />
                      <span>%</span>
                    </div>
                  ) : (
                    <input
                      className="inputFieldValue"
                      name={`${obj.riskParamId}-limitAmount`}
                      type="text"
                      placeholder={obj.limitAmount}
                      onChange={tableDataChange}
                      // value={obj.limitAmount}
                      // disabled={!obj.canChange}
                      value={
                        tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          : obj.limitAmount
                      }
                      style={{ width: '85%', marginRight: '0.5vw' }}
                    />
                  )}
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L1`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L1}
                    onChange={tableDataChange}
                    // value={obj.L1}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L1`]
                        ? tableChangeObject[`${obj.riskParamId}-L1`]
                        : obj.L1
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L2`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L2}
                    onChange={tableDataChange}
                    // value={obj.L2}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L2`]
                        ? tableChangeObject[`${obj.riskParamId}-L2`]
                        : obj.L2
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L3`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L3}
                    onChange={tableDataChange}
                    // value={obj.L3}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L3`]
                        ? tableChangeObject[`${obj.riskParamId}-L3`]
                        : obj.L3
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L4`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L4}
                    onChange={tableDataChange}
                    // value={obj.L4}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L4`]
                        ? tableChangeObject[`${obj.riskParamId}-L4`]
                        : obj.L4
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L5`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L5}
                    onChange={tableDataChange}
                    // value={obj.L5}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L5`]
                        ? tableChangeObject[`${obj.riskParamId}-L5`]
                        : obj.L5
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L6`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L6}
                    onChange={tableDataChange}
                    // value={obj.L6}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L6`]
                        ? tableChangeObject[`${obj.riskParamId}-L6`]
                        : obj.L6
                    }
                  />
                </td>
              </tr>
            ));
          } else {
            return [];
          }
        } else {
          if (selectionHierachyLevel === 1) {
            return [];
          } else if (selectionHierachyLevel === 2) {
            let displayData = tableDataFormatter(
              apiData[0].dealerRiskParameters,
              apiData[0].dealerRiskLimits,
              selectionId,
              2,
              currentTradeType
            );
            return displayData.map((obj) => (
              <tr key={`${obj.sno}_${obj.riskType}`} className="riskUtilTr">
                <td>{obj.riskType}</td>
                <td>
                  {obj.isPercentage ? (
                    <div className="riskUtilTr">
                      <input
                        className="inputFieldValue"
                        name={`${obj.riskParamId}-limitAmount`}
                        type="text"
                        placeholder={obj.limitAmount}
                        onChange={tableDataChange}
                        // value={obj.limitAmount}
                        // disabled={!obj.canChange}
                        value={
                          tableChangeObject[`${obj.riskParamId}-limitAmount`]
                            ? tableChangeObject[
                                `${obj.riskParamId}-limitAmount`
                              ]
                            : obj.limitAmount
                        }
                        style={{ width: '85%', marginRight: '0.5vw' }}
                      />
                      %
                    </div>
                  ) : (
                    <input
                      className="inputFieldValue"
                      name={`${obj.riskParamId}-limitAmount`}
                      type="text"
                      placeholder={obj.limitAmount}
                      onChange={tableDataChange}
                      // value={obj.limitAmount}
                      // disabled={!obj.canChange}
                      value={
                        tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          ? tableChangeObject[`${obj.riskParamId}-limitAmount`]
                          : obj.limitAmount
                      }
                      style={{ width: '85%', marginRight: '0.5vw' }}
                    />
                  )}
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L1`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L1}
                    onChange={tableDataChange}
                    // value={obj.L1}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L1`]
                        ? tableChangeObject[`${obj.riskParamId}-L1`]
                        : obj.L1
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L2`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L2}
                    onChange={tableDataChange}
                    // value={obj.L2}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L2`]
                        ? tableChangeObject[`${obj.riskParamId}-L2`]
                        : obj.L2
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L3`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L3}
                    onChange={tableDataChange}
                    // value={obj.L3}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L3`]
                        ? tableChangeObject[`${obj.riskParamId}-L3`]
                        : obj.L3
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L4`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L4}
                    onChange={tableDataChange}
                    // value={obj.L4}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L4`]
                        ? tableChangeObject[`${obj.riskParamId}-L4`]
                        : obj.L4
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L5`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L5}
                    onChange={tableDataChange}
                    // value={obj.L5}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L5`]
                        ? tableChangeObject[`${obj.riskParamId}-L5`]
                        : obj.L5
                    }
                  />
                </td>
                <td>
                  <input
                    className="inputField"
                    name={`${obj.riskParamId}-L6`}
                    type="text"
                    placeholder={!obj.canChange ? 'NA' : obj.L6}
                    onChange={tableDataChange}
                    // value={obj.L6}
                    disabled={!obj.canChange || isMarketHour()}
                    value={
                      tableChangeObject[`${obj.riskParamId}-L6`]
                        ? tableChangeObject[`${obj.riskParamId}-L6`]
                        : obj.L6
                    }
                  />
                </td>
              </tr>
            ));
          } else {
            return [];
          }
        }
      }
    }
  }

  const tableDataChange = (evt, id = null, val = null, fldName = null) => {
    let info, tarRiskId, fieldName, valueChanged;
    if (evt) {
      info = evt.target.name.split('-');
      tarRiskId = parseInt(info[0]);
      fieldName = info[1];

      valueChanged = evt.target.value;

      if (false) {
        if (valueChanged === '') {
          valueChanged = '0';
        }

        if (isNaN(valueChanged)) {
          return;
        }

        if (valueChanged && parseInt(valueChanged)) {
          valueChanged = String(parseInt(valueChanged));
        }
      }

      // valueChanged =
      //   evt.target.value.length > 0 ? parseInt(evt.target.value) : '0';

      // if (evt.target.value.length > 0) {
      //   if (evt.target.value[0] === '0') {
      //     evt.target.value = evt.target.value.substring(1);
      //   }
      // }

      let tableChangeObjectCopy = { ...tableChangeObject };
      tableChangeObjectCopy[`${tarRiskId}-${fieldName}`] = valueChanged;

      setTableChangeObject(tableChangeObjectCopy);
    } else {
      tarRiskId = id;
      fieldName = fldName;
      valueChanged = val.toString();
    }

    setHasValuesChanged(true);

    let tableRiskObj = null;
    for (let i = 0; i < tableChangeData.length; i++) {
      if (tableChangeData[i].riskParamId === tarRiskId) {
        tableRiskObj = tableChangeData[i];
        break;
      }
    }

    if (tableRiskObj) {
      tableRiskObj.riskParamValue =
        fieldName === 'limitAmount'
          ? valueChanged
          : tableRiskObj.riskParamValue;
      tableRiskObj.alert =
        fieldName === 'alert' ? valueChanged : tableRiskObj.alert;
      tableRiskObj.warning =
        fieldName === 'warning' ? valueChanged : tableRiskObj.warning;
      tableRiskObj.urgent =
        fieldName === 'urgent' ? valueChanged : tableRiskObj.urgent;
      tableRiskObj.L1 = fieldName === 'L1' ? valueChanged : tableRiskObj.L1;
      tableRiskObj.L2 = fieldName === 'L2' ? valueChanged : tableRiskObj.L2;
      tableRiskObj.L3 = fieldName === 'L3' ? valueChanged : tableRiskObj.L3;
      tableRiskObj.L4 = fieldName === 'L4' ? valueChanged : tableRiskObj.L4;
      tableRiskObj.L5 = fieldName === 'L5' ? valueChanged : tableRiskObj.L5;
      tableRiskObj.L6 = fieldName === 'L6' ? valueChanged : tableRiskObj.L6;
    } else {
      let riskObj = {
        riskParamId: tarRiskId,
        riskParamValue: fieldName === 'limitAmount' ? valueChanged : null,
        dimensionValue: selectionId,
        alert: fieldName === 'alert' ? valueChanged : null,
        warning: fieldName === 'warning' ? valueChanged : null,
        urgent: fieldName === 'urgent' ? valueChanged : null,
        L1: fieldName === 'L1' ? valueChanged : null,
        L2: fieldName === 'L2' ? valueChanged : null,
        L3: fieldName === 'L3' ? valueChanged : null,
        L4: fieldName === 'L4' ? valueChanged : null,
        L5: fieldName === 'L5' ? valueChanged : null,
        L6: fieldName === 'L6' ? valueChanged : null,
      };
      tableChangeData.push(riskObj);
    }

    let temp = [...tableChangeData];
    setTableChangeData(temp);
  };

  // const tableDataChange = (evt) => {
  //   const tempState = { ...displayDataState };
  //   const tempArr = [...tempState.tempHold];
  //   const info = evt.target.name.split('-');
  //   let found = false,
  //     tarRiskId = parseInt(info[0]),
  //     fieldName = info[1],
  //     actualValue = {},
  //     valueChanged =
  //       evt.target.value.length > 0 ? parseInt(evt.target.value) : '';

  //   switch (displayDataState.currentSelection.type) {
  //     case navConst.TRADE_PP:
  //       for (let i = 0; i < tempState.tempHold.length; i++) {
  //         if (tempState.tempHold[i].riskParamId === parseInt(info)) {
  //           tempState.tempHold[i][fieldName] = valueChanged;
  //           found = true;
  //           break;
  //         }
  //       }
  //       if (!found) {
  //         tempState.tempHold.push({
  //           riskParamId: parseInt(info),
  //           [fieldName]: valueChanged,
  //         });
  //       }
  //       break;
  //     case navConst.RISK_PF:
  //       const editSpace =
  //         tempState.tableContent.familyLevel[
  //           displayDataState.currentSelection.familyId
  //         ] || [];
  //       for (let obj of editSpace) {
  //         if (obj.riskParamId === tarRiskId) {
  //           actualValue = obj;
  //           break;
  //         }
  //       }
  //       for (let i = 0; i < tempArr.length; i++) {
  //         if (tempArr[i].riskParamId === tarRiskId) {
  //           found = true;
  //           if (evt.target.value.length >= 0) {
  //             tempArr[i][fieldName] = valueChanged;
  //           } else {
  //             if (actualValue !== {}) {
  //               tempArr[i][fieldName] = parseInt(actualValue[fieldName]);
  //             } else {
  //               tempArr = tempArr.filter(
  //                 (obj) => obj.riskParamId !== tarRiskId
  //               );
  //             }
  //           }
  //         }
  //       }
  //       if (!found) {
  //         tempArr.push({
  //           riskParamId: tarRiskId,
  //           alert: actualValue.alert,
  //           warning: actualValue.warning,
  //           urgent: actualValue.urgent,
  //           limit:
  //             fieldName === 'limit'
  //               ? valueChanged
  //               : (actualValue.limit || '').length > 0
  //               ? parseInt(actualValue.limit)
  //               : 0,
  //           limitAmount:
  //             fieldName === 'limitAmount'
  //               ? valueChanged
  //               : (actualValue.limitAmount || '').length > 0
  //               ? parseInt(actualValue.limitAmount)
  //               : 0,
  //         });
  //       }
  //       tempState.tempHold = tempArr;
  //       break;
  //     case navConst.RISK_PD:
  //       const editDealerSpace = displayDataState.dealerRiskValues.filter(
  //         (dealerRiskVal) => {
  //           if (
  //             dealerRiskVal.dealerId ==
  //             displayDataState.currentSelection.familyId
  //           ) {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         }
  //       );
  //       for (let obj of editDealerSpace) {
  //         if (obj.riskParamId === tarRiskId) {
  //           actualValue = obj;
  //           break;
  //         }
  //       }
  //       for (let i = 0; i < tempArr.length; i++) {
  //         if (tempArr[i].riskParamId === tarRiskId) {
  //           tempArr[i][fieldName] = valueChanged;
  //           found = true;
  //         }
  //       }
  //       if (!found) {
  //         tempArr.push({
  //           riskParamId: tarRiskId,
  //           alert: actualValue.alert,
  //           warning: actualValue.warning,
  //           urgent: actualValue.urgent,
  //           limit:
  //             fieldName === 'limit'
  //               ? valueChanged
  //               : (actualValue.limit || '').length > 0
  //               ? parseInt(actualValue.limit)
  //               : 0,
  //           limitAmount:
  //             fieldName === 'limitAmount'
  //               ? valueChanged
  //               : (actualValue.limitAmount || '').length > 0
  //               ? parseInt(actualValue.limitAmount)
  //               : 0,
  //         });
  //       }
  //       tempState.tempHold = tempArr;
  //       break;
  //     default:
  //       break;
  //   }

  //   setDisplayDataState(tempState);
  // };

  const openFileSelect = () => {
    setShow(true);
  };

  const cancelChanges = () => {
    setTableChangeData([]);
    setTableChangeObject({});
    setAddRiskParamList([]);
    setRemoveRiskParamList([]);
    setHasValuesChanged(false);
  };

  function applyToWholeColumn(commonRiskLimit, level) {
    let displayData = [];

    if (apiData) {
      if (isHFT) {
        if (selectionHierachyLevel === 1) {
          return [];
        } else if (selectionHierachyLevel === 2) {
          displayData = tableDataFormatter(
            apiData[1].clearingAccountRiskParameters,
            apiData[1].clearingAccountRiskLimits,
            selectionId,
            2
          );
        } else if (selectionHierachyLevel === 3) {
          displayData = tableDataFormatter(
            apiData[1].strategyRiskParameters,
            apiData[1].strategyRiskLimits,
            selectionId,
            2
          );
        } else if (selectionHierachyLevel === 4) {
          displayData = tableDataFormatter(
            apiData[1].strategyAccountRiskParameters,
            apiData[1].strategyAccountRiskLimits,
            selectionId,
            2
          );
        } else {
          return [];
        }
      } else {
        if (familyDealerToggle == 'Family') {
          if (selectionHierachyLevel === 1) {
            displayData = tableDataFormatter(
              apiData[0].tradeProtectionRiskParameters,
              apiData[0].tradeProtectionRiskLimits,
              selectionId,
              1
            );
          } else if (selectionHierachyLevel === 2) {
            displayData = tableDataFormatter(
              apiData[0].familyRiskParameters,
              apiData[0].familyRiskLimits,
              selectionId,
              2
            );
          } else {
            return [];
          }
        } else {
          if (selectionHierachyLevel === 1) {
            return [];
          } else if (selectionHierachyLevel === 2) {
            displayData = tableDataFormatter(
              apiData[0].dealerRiskParameters,
              apiData[0].dealerRiskLimits,
              selectionId,
              2
            );
          } else {
            return [];
          }
        }
      }
    }

    let tableChangeObjectCopy = { ...tableChangeObject };

    for (let i = 0; i < displayData.length; i++) {
      if (displayData[i].canChange) {
        tableDataChange(
          null,
          displayData[i].riskParamId,
          commonRiskLimit,
          level
        );
        tableChangeObjectCopy[`${displayData[i].riskParamId}-${level}`] =
          commonRiskLimit;
      }
    }

    setTableChangeObject(tableChangeObjectCopy);
  }

  const CheckTPPSetData = (data) => {
    let tppParamaters = [...apiData[1].tradeProtectionRiskParameters];
    let tppLimits = [...apiData[1].tradeProtectionRiskLimits];
    let interval = [];
    for (let i = 0; i < tppParamaters.length; i++) {
      let limit = null;
      for (let j = 0; j < tppLimits.length; j++) {
        if (
          Number(tppLimits[j].riskParamId) ===
          Number(tppParamaters[i].riskParamId)
        ) {
          limit = tppLimits[j];
          break;
        }
      }

      for (let j = 0; j < data.risk.length; j++) {
        if (
          Number(data.risk[j].riskParamId) ===
          Number(tppParamaters[i].riskParamId)
        ) {
          limit = data.risk[j];
          break;
        }
      }

      let obj = {
        start: 0,
        end: 0,
      };

      if (limit) {
        obj.start = limit.lowerSlabLimit;
        obj.end = limit.upperSlabLimit;
      }
      if (obj.start != 0 || obj.end != 0) {
        interval.push(obj);
      }
    }

    interval.sort(function (x, y) {
      if (x.start > y.start) {
        return 1;
      } else if (x.end > y.end) {
        return -1;
      }
      return 0;
    });

    for (let i = 0; i < interval.length - 1; i++) {
      if (interval[i].end > interval[i + 1].start) {
        return 0;
      }

      if (interval[i].start > interval[i].end) {
        return 0;
      }
    }

    if (
      interval[interval.length - 1].start > interval[interval.length - 1].end
    ) {
      return 0;
    }

    return 1;
  };

  const submitTPPRiskConfig = () => {
    let data = {
      riskDimensionEnum: 'TRADE_PRICE_PROTECTION',
      risk: [],
    };

    let tppLimits = apiData[1].tradeProtectionRiskLimits;

    for (let i = 0; i < tableChangeData.length; i++) {
      let limit = null;
      for (let j = 0; j < tppLimits.length; j++) {
        if (tppLimits[j].riskParamId === tableChangeData[i].riskParamId) {
          limit = tppLimits[j];
          break;
        }
      }

      let obj = {
        riskParamId: Number(tableChangeData[i].riskParamId),
        riskParamValue: tableChangeData[i].limit
          ? Number(tableChangeData[i].limit)
          : limit
          ? Number(limit.riskParamValue)
          : null,
        lowerSlabLimit: tableChangeData[i].from
          ? Number(tableChangeData[i].from)
          : limit
          ? Number(limit.lowerSlabLimit)
          : null,
        upperSlabLimit: tableChangeData[i].to
          ? Number(tableChangeData[i].to)
          : limit
          ? Number(limit.upperSlabLimit)
          : null,
        isPercentage: tableChangeData[i].absPer
          ? tableChangeData[i].absPer === 'Abs'
            ? false
            : true
          : (limit && limit.isPercentage) === 'Abs'
          ? false
          : true,
        dimensionValue: 1,
      };
      data.risk.push(obj);
    }

    return data;
  };

  const submitRiskConfig = async () => {
    if (
      templateSelected.selectedId === -1 &&
      !(
        isHFT &&
        (selectionHierachyLevel === 1 || selectionHierachyLevel === 4)
      ) &&
      !(
        !isHFT &&
        selectionHierachyLevel === 1 &&
        familyDealerToggle === 'Family'
      )
    ) {
      setFeedbackMsg(
        <p>
          <span style={{ color: 'red' }}>{'Error : '}</span>
          {'Please Select a Template'}
        </p>
      );
    } else {
      // Prepare to submit
      let data = {
        riskDimensionEnum: null,
        templateId: templateSelected.selectedId,
        dimensionValue: selectionId,
        risk: tableChangeData,
      };
      if (
        !(isHFT && selectionHierachyLevel === 1) &&
        !(
          !isHFT &&
          selectionHierachyLevel === 1 &&
          familyDealerToggle === 'Family'
        )
      ) {
        // If submit is not TPP
        for (let i = 0; i < removeRiskParamList.length; i++) {
          let riskObj = {
            riskParamId: removeRiskParamList[i],
            riskParamValue: null,
            dimensionValue: selectionId,
            alert: null,
            warning: null,
            urgent: null,
            L1: null,
            L2: null,
            L3: null,
            L4: null,
            L5: null,
            L6: null,
          };
          data.risk.push(riskObj);
        }

        let finalRiskArray = [];
        for (let i = 0; i < data.risk.length; i++) {
          let r = data.risk[i];
          if (
            r.riskParamValue ||
            r.alert ||
            r.warning ||
            r.urgent ||
            r.L1 ||
            r.L2 ||
            r.L3 ||
            r.L4 ||
            r.L5 ||
            r.L6
          ) {
            finalRiskArray.push(r);
          }
        }

        let tempArr = [];

        if (isHFT) {
          if (selectionHierachyLevel === 2) {
            data.riskDimensionEnum = 'CLEARING_ACCOUNT';
            tempArr = [...apiData[1].clearingAccountRiskLimits];
          } else if (selectionHierachyLevel === 3) {
            data.riskDimensionEnum = 'STRATEGY';
            tempArr = [...apiData[1].strategyRiskLimits];
          } else if (selectionHierachyLevel === 4) {
            data.riskDimensionEnum = 'STRATEGY_ACCOUNT';
            tempArr = [...apiData[1].strategyAccountRiskLimits];
          }
        } else {
          if (familyDealerToggle == 'Family') {
            if (selectionHierachyLevel === 1) {
              data.riskDimensionEnum = 'TRADE_PRICE_PROTECTION';
              tempArr = [...apiData[0].tradeProtectionRiskLimits];
            } else if (selectionHierachyLevel === 2) {
              data.riskDimensionEnum = 'FAMILY';
              tempArr = [...apiData[0].familyRiskLimits];
            }
          } else {
            if (selectionHierachyLevel === 2) {
              data.riskDimensionEnum = 'DEALER';
              tempArr = [...apiData[0].dealerRiskLimits];
            }
          }
        }

        for (let i = 0; i < finalRiskArray.length; i++) {
          let found = false;
          for (let j = 0; j < tempArr.length; j++) {
            if (
              Number(tempArr[j].riskParamId) ===
                Number(finalRiskArray[i].riskParamId) &&
              tempArr[j].dimensionValue == finalRiskArray[i].dimensionValue
            ) {
              finalRiskArray[i].riskParamValue = finalRiskArray[i]
                .riskParamValue
                ? finalRiskArray[i].riskParamValue
                : tempArr[j].riskParamValue;
              finalRiskArray[i].alert = finalRiskArray[i].alert
                ? finalRiskArray[i].alert
                : tempArr[j].alert;
              finalRiskArray[i].warning = finalRiskArray[i].warning
                ? finalRiskArray[i].warning
                : tempArr[j].warning;
              finalRiskArray[i].urgent = finalRiskArray[i].urgent
                ? finalRiskArray[i].urgent
                : tempArr[j].urgent;
              finalRiskArray[i].L1 = finalRiskArray[i].L1
                ? finalRiskArray[i].L1
                : tempArr[j].L1;
              finalRiskArray[i].L2 = finalRiskArray[i].L2
                ? finalRiskArray[i].L2
                : tempArr[j].L2;
              finalRiskArray[i].L3 = finalRiskArray[i].L3
                ? finalRiskArray[i].L3
                : tempArr[j].L3;
              finalRiskArray[i].L4 = finalRiskArray[i].L4
                ? finalRiskArray[i].L4
                : tempArr[j].L4;
              finalRiskArray[i].L5 = finalRiskArray[i].L5
                ? finalRiskArray[i].L5
                : tempArr[j].L5;
              finalRiskArray[i].L6 = finalRiskArray[i].L6
                ? finalRiskArray[i].L6
                : tempArr[j].L6;

              found = true;
              break;
            }
          }

          if (!found) {
            finalRiskArray[i].riskParamValue = finalRiskArray[i].riskParamValue
              ? finalRiskArray[i].riskParamValue
              : Number(
                  apiData.riskLevelInfo[1].clearingAccounts.columns[1]
                    .defaultValue
                );
            finalRiskArray[i].alert = finalRiskArray[i].alert
              ? finalRiskArray[i].alert
              : Number(
                  apiData.riskLevelInfo[1].clearingAccounts.columns[2]
                    .defaultValue
                );
            finalRiskArray[i].warning = finalRiskArray[i].warning
              ? finalRiskArray[i].warning
              : Number(
                  apiData.riskLevelInfo[1].clearingAccounts.columns[3]
                    .defaultValue
                );
            finalRiskArray[i].urgent = finalRiskArray[i].urgent
              ? finalRiskArray[i].urgent
              : Number(
                  apiData.riskLevelInfo[1].clearingAccounts.columns[4]
                    .defaultValue
                );
            finalRiskArray[i].L1 = finalRiskArray[i].L1
              ? finalRiskArray[i].L1
              : Number(
                  apiData.riskLevelInfo[0].families.columns[2].defaultValue
                );
            finalRiskArray[i].L2 = finalRiskArray[i].L2
              ? finalRiskArray[i].L2
              : Number(
                  apiData.riskLevelInfo[0].families.columns[3].defaultValue
                );
            finalRiskArray[i].L3 = finalRiskArray[i].L3
              ? finalRiskArray[i].L3
              : Number(
                  apiData.riskLevelInfo[0].families.columns[4].defaultValue
                );
            finalRiskArray[i].L4 = finalRiskArray[i].L4
              ? finalRiskArray[i].L4
              : Number(
                  apiData.riskLevelInfo[0].families.columns[5].defaultValue
                );
            finalRiskArray[i].L5 = finalRiskArray[i].L5
              ? finalRiskArray[i].L5
              : Number(
                  apiData.riskLevelInfo[0].families.columns[6].defaultValue
                );
            finalRiskArray[i].L6 = finalRiskArray[i].L6
              ? finalRiskArray[i].L6
              : Number(
                  apiData.riskLevelInfo[0].families.columns[7].defaultValue
                );
          }
        }

        data.risk = finalRiskArray;
      } else {
        data = submitTPPRiskConfig();
        // let checkResult = CheckTPPSetData(data);  // Interval overlap validator
        let checkResult = 1;

        if (checkResult === 0) {
          setFeedbackMsg(
            <p>
              <span style={{ color: 'red' }}>{'Error : '}</span>
              {'Limit (From and To) are overlapping !'}
            </p>
          );
          return;
        }
      }

      data.deleteRiskActionIds = [];

      for (let i = 0; i < riskParameterFilter.length; i++) {
        if (!riskParameterFilter[i].state && riskParameterFilterDifference[i]) {
          data.deleteRiskActionIds.push(riskParameterFilter[i].id);
        }
      }

      let response = await MakeRequest('/admin/risk/configureV2').post(data);

      if (response.status === 200) {
        // Success
        setFeedbackMsg(
          <span style={{ color: 'green' }}>
            {'Risk Limits updated successfully !'}
          </span>
        );
        setTableChangeData([]);
        setTableChangeObject({});
        setAddRiskParamList([]);
        setRemoveRiskParamList([]);
        setHasValuesChanged(false);
      } else {
        setFeedbackMsg(
          <p>
            <span style={{ color: 'red' }}>{'Error : '}</span>
            {response.data.data.message}
          </p>
        );
      }
    }
  };

  const templateSelect = async (val, templateIdArg = null) => {
    let templateId = templateIdArg;
    if (!templateId) {
      templateId =
        templateAvailable.ids[templateAvailable.dropDownList.indexOf(val)];
    }

    setTemplateSelected({
      selectedName: val,
      selectedId: templateId,
    });
    let templateData = await GetTemplateData(templateId);
    let headerActionObject = [
      {
        title: null,
        config: {},
      },
      {
        title: null,
        config: {},
      },
      {
        title: 'L1',
        config: {
          Alert: false,
          Email: false,
          Reject: false,
          Log: false,
        },
      },
      {
        title: 'L2',
        config: {
          Alert: false,
          Email: false,
          Reject: false,
          Log: false,
        },
      },
      {
        title: 'L3',
        config: {
          Alert: false,
          Email: false,
          Reject: false,
          Log: false,
        },
      },
      {
        title: 'L4',
        config: {
          Alert: false,
          Email: false,
          Reject: false,
          Log: false,
        },
      },
      {
        title: 'L5',
        config: {
          Alert: false,
          Email: false,
          Reject: false,
          Log: false,
        },
      },
      {
        title: 'L6',
        config: {
          Alert: false,
          Email: false,
          Reject: false,
          Log: false,
        },
      },
    ];

    let newHeaderActionObject = [];

    if (headerActionObject && headerActionObject.length > 0) {
      for (let i = 0; i < headerActionObject.length; i++) {
        if (i === 0 || i === 1) {
          newHeaderActionObject.push(headerActionObject[i]);
          continue;
        }

        let temp = {
          title: null,
          config: null,
        };

        let actionName = headerActionObject[i].title;

        temp.title = actionName;

        let actionConfig = {
          Alert: false,
          Email: false,
          Log: false,
          Reject: false,
        };

        if (
          templateData &&
          templateData.actionMappings &&
          templateData.actionMappings.length > 0
        ) {
          let levelAction = null;
          for (let j = 0; j < templateData.actionMappings.length; j++) {
            if (templateData.actionMappings[j].columnName === actionName) {
              levelAction = templateData.actionMappings[j].levelActionInfo;
              break;
            }
          }
          if (levelAction) {
            for (let j = 0; j < levelAction.length; j++) {
              actionConfig[levelAction[j].name] = levelAction[j].enabled;
            }
          }
        }

        temp.config = actionConfig;

        newHeaderActionObject.push(temp);
      }
    }

    console.log(newHeaderActionObject);

    setHeaderActionInfo(newHeaderActionObject);
  };

  const hierachySelect = () => {
    if (!isHFT) {
      if (familyDealerToggle === 'Family') {
        return apiData[0].familyHierachy;
      } else if (familyDealerToggle === 'Dealer') {
        return apiData[0].dealerHierachy;
      }
    } else {
      return apiData[1].AllFamilyHFTonHierachy;
    }
  };

  const addRuleAvail = () => {
    return (
      !(isHFT && selectionHierachyLevel === 1) &&
      !(
        !isHFT &&
        selectionHierachyLevel === 1 &&
        familyDealerToggle === 'Family'
      )
    );
  };

  const [riskPrev, setRiskPrev] = useState();
  const [riskPrevFeedBack, setRiskPrevFeedback] = useState('');

  async function callBackForPopupPrev(action = { action: 0, data: {} }) {
    switch (action.action) {
      case 'SUB':
        const res = await submitExcelprev(action.data);
        if (res) {
          setRiskPrev();
          setShow(false);
          setRiskPrevFeedback(res.data.data.message);
        }
        break;
      case 'EDT':
        setShow(true);
        setRiskPrev();
        break;
      case 'CAN':
        setRiskPrev();
        setShow(false);
        break;
      default:
        break;
    }
  }

  async function submitExcelprev(data) {
    try {
      const res = await MakeRequest('/excel/upload/riskSubmit').post({
        submitData: data,
      });
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  const addTemplateSubmit = async () => {
    // Callback function for Submit  template
    console.log('Submit Template called...');
    let templateData = await GetAllTemplates();

    if (isHFT) {
      if (selectionHierachyLevel === 1) {
      } else if (selectionHierachyLevel === 2) {
        setTemplateAvailable(templateData.selectTemplateCA);
      } else if (selectionHierachyLevel === 3) {
      } else if (selectionHierachyLevel === 4) {
        setTemplateAvailable(templateData.selectTemplateSA);
      } else {
      }
    } else {
      if (familyDealerToggle == 'Family') {
        if (selectionHierachyLevel === 1) {
          setTemplateAvailable(templateData.selectTemplateTPP);
        } else if (selectionHierachyLevel === 2) {
          setTemplateAvailable(templateData.selectTemplateFamily);
        } else {
        }
      } else {
        if (selectionHierachyLevel === 1) {
        } else if (selectionHierachyLevel === 2) {
          setTemplateAvailable(templateData.selectTemplateDealer);
        } else {
        }
      }
    }

    setJunk(Math.random());
  };

  const editTemplateSubmit = async () => {
    // Callback function for Edit template
    console.log('Edit Template called...');
    await addTemplateSubmit();
  };

  return (
    <div className="risk-configV2-container">
      <span></span>
      <div className="header-btn-sub">
        <GenButton
          disabled={process.env.REACT_APP_IS_HFT === 'true'}
          fill={false}
          icon={ExcelIcon}
          title="EXPORT EXCEL"
          extStyle={{ margin: 'auto 2%' }}
          clickAction={async () => {
            const res = await MakeRequest(`/excel/generate/risk`).download(
              'Risk.xlsx'
            );
            console.log(res);
          }}
        />
        <GenButton
          disabled={process.env.REACT_APP_IS_HFT === 'true'}
          fill={false}
          icon={ExcelIcon}
          title="UPLOAD EXCEL"
          extStyle={{ margin: 'auto 0rem' }}
          clickAction={openFileSelect}
        />
        <div style={{ margin: '0 1vw 0 auto' }}>
          {templateData && templateData.manageTemplateData && (
            <TreeMenuGenerator3
              content={templateData.manageTemplateData}
              clickAction={selectTemplateCallback}
              addTemplateSubmit={addTemplateSubmit}
              editTemplateSubmit={editTemplateSubmit}
              config={createTemplateConfig}
              isHFT={isHFT}
              showRightIcon={false}
              toplevelSelect={true}
            />
          )}
        </div>
      </div>

      <WindowContainer
        title={{ type: 0, content: 'Risk Hierarchy' }}
        extStyles={{
          width: '96%',
          height: '95%',
          margin: 'auto',
          borderRadius: '1rem',
        }}
        // contClass="risk-hier-container"
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '1vh 0 1vh 0',
          }}
          className={isHFT && 'toggleDivHFTOn'}
        >
          {!isHFT && (
            <ToggleTab
              options={['Family', 'Dealer']}
              selected={familyDealerToggle}
              onSelect={(val) => setFamilyDealerToggle(val)}
              disabled={false}
              extStyles={{ borderRadius: '5px' }}
              type={1}
            />
          )}
        </div>

        {apiData &&
          (!isHFT ? (
            <>
              {familyDealerToggle === 'Family' ? ( // This is done coz dealers would not update coz of search state persisting
                // TODO -> Find a better solution
                <TreeMenuGenerator
                  content={hierachySelect()}
                  clickAction={hierachyClickAction}
                  showRightIcon={true}
                  toplevelSelect={true}
                />
              ) : (
                <TreeMenuGenerator2 // This is done coz dealers would not update coz of search state persisting
                  content={hierachySelect()}
                  clickAction={hierachyClickAction}
                  showRightIcon={false}
                  toplevelSelect={true}
                />
              )}
            </>
          ) : (
            <TreeMenuGenerator
              content={hierachySelect()}
              clickAction={hierachyClickAction}
              showRightIcon={false}
              toplevelSelect={true}
            />
          ))}
      </WindowContainer>
      <WindowContainer
        title={{ type: 0, content: 'Risk Limit' }}
        extStyles={{
          margin: 'auto',
          width: '98%  ',
          height: '95%',
          borderRadius: '2vh',
        }}
      >
        <div className="risk-config-button-grp">
          <ToggleTab
            options={['Pre Trade', 'Post Trade']}
            selected={tradeType}
            extStyles={{ margin: '0 0 0 1vw', borderRadius: '5px' }}
            disabled={disableTradeType}
            onSelect={prePostToggleClickAction}
          />

          {templateData &&
          !(
            isHFT &&
            (selectionHierachyLevel === 1 || selectionHierachyLevel === 4)
          ) &&
          !(
            !isHFT &&
            selectionHierachyLevel === 1 &&
            familyDealerToggle === 'Family'
          ) ? (
            <div className="btn">
              <DropDown
                options={
                  templateAvailable && templateAvailable.dropDownList
                    ? templateAvailable.dropDownList
                    : []
                }
                selected={templateSelected.selectedName}
                orientation="v"
                onSelect={(val) => {
                  templateSelect(val);
                }}
                extStyles={{ width: '14vw' }}
                ddExtStyles={{ width: '14vw' }}
              />
            </div>
          ) : (
            <></>
          )}

          {addRuleAvail() ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                justifyContent: 'space-between',
                paddingRight: '1vw',
                alignItems: 'center',
                overflow: 'visible',
              }}
            >
              <GenButton
                icon={filterIcon}
                type="multiSelect"
                options={riskParameterFilter}
                enable_search={true}
                extStyle={{
                  icon: { height: '18px', width: '18px' },
                }}
                changeTrig={handleCheckChange}
              />
              <HFTDisplayDropdown />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {(isHFT && selectionHierachyLevel === 1) ||
        (!isHFT &&
          selectionHierachyLevel === 1 &&
          familyDealerToggle === 'Family') ? (
          <TableGenerator
            heads={['Segment', 'From', 'To', '', 'Limit']}
            content={TPPContent()}
            className="tableContainer bodyScrollTable"
          />
        ) : (
          <GenTableExtended
            heads={getHeads().header}
            headExtention={
              templateSelected.selectedId === -1 && headerActionInfo.length > 0
                ? getHeads().headerObjData
                : headerActionInfo
            }
            applyCommonRiskLimitCallback={applyToWholeColumn}
            content={tableDataGenerator()}
            extStyles={{ margin: '0vh 0 0 0' }}
            className="tableContainer bodyScrollTable riskConfigTable"
          />
        )}
        {isMarketHour() &&
          (selectionHierachyLevel == 2 || selectionHierachyLevel == 4) && (
            <div className="noteText">
              Note: Percentage limits can only be updated outside market hours
            </div>
          )}
        {selectionHierachyLevel !== -1 && (
          <div className="submit-container">
            {hasValuesChanged && (
              <GenButton
                type="1"
                title="CANCEL"
                fill={false}
                extStyle={{
                  width: '8vw',
                  height: '5vh',
                  borderRadius: '3px',
                  marginRight: '1vw',
                  marginTop: '2.5vh',
                }}
                clickAction={cancelChanges}
              />
            )}

            <GenButton
              title="SUBMIT"
              extStyle={{
                back: {
                  backgroundColor:
                    false &&
                    ((isHFT && selectionHierachyLevel === 1) ||
                      (!isHFT &&
                        selectionHierachyLevel === 1 &&
                        familyDealerToggle === 'Family'))
                      ? '#d3d3d3'
                      : '#2F78D7',
                  borderRadius: '3px',
                  width: '8vw',
                  height: '5vh',
                  marginRight: '1vw',
                  marginTop: '2.5vh',
                },
                text: { color: '#FFFFFF', fontWeight: '500' },
              }}
              clickAction={submitRiskConfig}
              // disabled={false && isHFT && selectionHierachyLevel === 1} // ! TODO: [Disabled for Prod release]
            />
          </div>
        )}
      </WindowContainer>

      {message.visible && (
        <GenericPopup
          actionButton={async () => {
            setMessage({ ...message, visible: !message.visible });
            if (message.title === 'Success' || true) {
              const updateData = await initialApiDataFetch(false);
              setSuccessUpdDBData(updateData);
              // window.location.reload(true);
            }
          }}
          title={message.title}
          popupDetails={{ note: message.message, nameAccept: 'Check Again' }}
        />
      )}

      <FileUploadComponent
        show={show}
        setShow={setShow}
        screenType={'risk'}
        setSuccessUpload={setIsSuccessUpload}
        callBackFunc={(val) => {
          setShow(false);
          setRiskPrev(val);
        }}
      />
      <ExcelPrevPopup
        state={riskPrev !== undefined}
        data={riskPrev && riskPrev.data}
        callBackFunction={callBackForPopupPrev}
        type={2}
        previewTitle="Preview Risk Configuration"
      />
      <FeedbackPopup
        message={
          riskPrevFeedBack
            ? `${JSON.stringify(
                riskPrevFeedBack
              )}. Do you want to upload again ?`
            : ''
        }
        type="a"
        acceptAction={() => {
          setShow(true);
          setRiskPrevFeedback('');
          setIsSuccessUpload(true);
        }}
        rejectAction={() => {
          setRiskPrevFeedback('');
          setIsSuccessUpload(true);
        }}
      />
      <FeedbackPopup
        message={feedbackMsg}
        type="f"
        acceptAction={() => {
          setFeedbackMsg('');
          setCallApi(true);
        }}
      />
    </div>
  );
};

export default RiskConfigScr;

import { useState } from 'react';
import { SearchIcon } from '../../../assets';
import DropDown from '../dropdown';
import './styles.css';

export default function StrategyAnalyzerSearch({
  category = '',
  setCategory = () => {},
  categories = [],
  search = '',
  setSearch = () => {},
  extClass = '',
}) {
  const [tempSearchVal, setTempSearchVal] = useState(search);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearch(tempSearchVal);
    }
  };
  return (
    <div className={`StrategyAnalyzerSearch-container ${extClass}`}>
      <DropDown
        id="StrategyAnalyzerSearch-dropdown"
        value={category}
        options={categories}
        setValue={setCategory}
        extClass="StrategyAnalyzerSearch-searchDD"
      />
      <input
        className="StrategyAnalyzerSearch-inputBox"
        value={tempSearchVal}
        onChange={(e) => setTempSearchVal(e.target.value)}
        type="search"
        tabIndex={0}
        placeholder={`Search ${category}`}
        onKeyDown={handleKeyDown}
      />
      <div
        className="StrategyAnalyzerSearch-searchContainer"
        onClick={() => setSearch(tempSearchVal)}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <img
          className="StrategyAnalyzerSearch-search-icon"
          src={SearchIcon}
          alt="search-icon"
        />
      </div>
    </div>
  );
}

export default function CacheManager() {
  const memberFunctions = {
    get: (key) => {
      try {
        const response = sessionStorage.getItem(key);
        if (response) {
          return [JSON.parse(response), true];
        } else {
          return [null, false];
        }
      } catch (error) {
        return [null, false];
      }
    },
    set: (key, value) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(value));
        return [null, true];
      } catch (error) {
        return [null, false];
      }
    },
    clearAll: () => {
      sessionStorage.clear();
    },
  };
  return memberFunctions;
}

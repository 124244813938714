import '../../../global.css';
import Styled from 'styled-components';

export const Table = Styled.table`
  width: 100%;
  overflow: visible;
`;

export const TableHeader = Styled.th`
  width: ${(props) => props.width || 'fit-content'};
  background-color: ${(props) => props.backgroundColor || '#f4f4f4'};
  border: ${(props) => props.border || '0.5px solid #d5d5d5'};
  padding: 0.1rem 0.5rem !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
`;

export const TableHeadText = Styled.th`
    font-family: var(--font-family);
    font-size: var(--font-size);
    color: ${(props) => props.textColor || '#212529'};
    font-weight: 600 !important;
`;

export const TableRow = Styled.tr`
  height: 4vh !important;
    &:nth-child(2n){
      background-color: #f4fbff !important;
    }
    overflow: visible;
`;

export const TableDataCell = Styled.td`
  height: 3.6vh !important;
  padding: 0 0.6rem 0 0.6rem !important;
  vertical-align: middle !important;
  background-color: ${(props) => props.backgroundColor || null};
  width: ${(props) => props.width || '3vw'};
  font-family: var(--def-font) !important;
  text-align: left;
  white-space: nowrap;
  font-size: var(--font-size);
  border: 0.5px solid #d5d5d5 !important;
  color: ${(props) => props.fontColor || '#000'};
  overflow: visible;
`;

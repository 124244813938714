import { GenToolTip } from '../../../..';
import './styles.scss';
import InfoIcon from '../../../../../assets/img/info.svg';
import { useRef } from 'react';

export default function SubtitleInfo({ label, info, extStyles }) {
  const containerRef = useRef(null);
  return (
    <div
      className="SubtitleInfo-container"
      ref={containerRef}
      style={extStyles}
    >
      <span className="SubtitleInfo-label">{label}</span>
      <GenToolTip tooltip={info} containerRef={containerRef}>
        <img src={InfoIcon} className="SubtitleInfo-icon" />
      </GenToolTip>
    </div>
  );
}

import { useEffect, useState } from 'react';
import './styles.css';
import BanApiCalls from './api';
import {
  GenericPopup,
  TableGenerator,
  PillButton,
  RemarkButton,
} from '../../../../components';
import { AscIcon, DesIcon } from '../../../../assets';
import BanSecPopup from './popupGen';
import DataModificationFunctions from './dataMorphing';
import FileUploadComponent from '../../../../components/FileUpload/FileUpload';
import { MakeRequest } from '../../../../utils';
import { setFocusOnModalKeyWithClassName } from '../../../../components/sharedComponents/keyPressEventHandler';
import {
  BAN_CONFRIM_AFTER_DELETE,
  BAN_DELETE_CONFIRM,
  UNBAN_CONFRIM_AFTER_DELETE,
  UNBAN_DELETE_CONFIRM,
} from '../../../../utils/constant';
import Popup from '../../../../components/sharedComponents/Popup/Popup';
import UMButtonGroup from '../../../../components/sharedComponents/customComponent/UMButtonGroup';
import { useSelector } from 'react-redux';
import { permissionChecker } from '../../../Ops/redux';
import {
  TableDataCell,
  TableRow,
} from '../../../../components/sharedComponents/GenTable/tableStyledComps';

export default function BanSecurity() {
  const { userPermissions } = useSelector((state) => state.OpsReducer);
  const [permission, setPermission] = useState('view');
  useEffect(() => {
    const res = permissionChecker(
      userPermissions?.menu,
      'User Maintenance/Ban Securities',
      2,
      'Risk'
    );
    setPermission(res > 1 ? 'edit' : 'view');
  }, [userPermissions]);

  const [popData, setPopData] = useState({ state: false, type: -1, data: {} });
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState([]);
  const [api_data, set_api_data] = useState([]);
  const [display_table, set_display_table] = useState([]);
  const [uep_state, set_uep_state] = useState();
  const [isSuccessUpload, setIsSuccessUpload] = useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: 'doubleButtons',
    nameAccept: 'Yes',
    nameDecline: 'No',
    state: false,
    header: '',
    banId: -1,
    isBan: true,
  });

  const [showPopUp, setShowPopUp] = useState(false);
  const [pagesInfo, setPagesInfo] = useState({current:1});
  const [currentData, setCurrentData] = useState([]);
  const entriesOnOnePage = 6;

  useEffect(() => {
    loadInitalData();
  }, []);

  async function loadInitalData() {
    const result = await BanApiCalls.banTableData();
    // * type : 0 - collapsed , 1 - opened , 2 - dropdown
    const feData =
      DataModificationFunctions.change_api_data_to_fe_format(result);
    set_api_data(result);
    setTableData(feData);
  }

  // ? Utility Function
  function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
  }

  // set functionalty for popup
  const actionOnpopup = async (e, type) => {
    if (type == 'CLOSE') {
      setPopup({
        type: '',
        message: '',
        buttons: 'doubleButtons',
        nameAccept: 'Yes',
        nameDecline: 'No',
        state: false,
        header: '',
        banId: -1,
        isBan: true,
      });
      setShowPopUp(false);
    } else {
      if (popup.type == 'deleteConfirm') {
        await delete_ban(popup.banId);
        setPopup({
          type: '',
          message: '',
          buttons: 'doubleButtons',
          nameAccept: 'Yes',
          nameDecline: 'No',
          state: false,
          header: '',
          banId: -1,
          isBan: true,
        });
        setShowPopUp(false);
      }
    }
  };

  function toggleDrop(index, type) {
    let tempData = [...tableData];
    if (type === 0) {
      tempData[index].type = 1;
      insertAt(tempData, index + 1, ...tempData[index].subData);
    } else if (type === 1) {
      tempData[index].type = 0;
      tempData.splice(index + 1, tempData[index].subData.length);
    }
    setTableData(tempData);
  }

  async function delete_ban(id) {
    const res = await BanApiCalls.delete_api_call(id);
    if (res) {
      setPopup({
        ...popup,
        header: popup.isBan
          ? BAN_CONFRIM_AFTER_DELETE(id)
          : UNBAN_CONFRIM_AFTER_DELETE(id),
        buttons: 'singleButton',
      });
      setShowPopUp(true);
    } else {
      setPopup({
        ...popup,
        header: `${
          popup.isBan ? 'BAN' : 'UNBAN'
        } with ID: ${id} was not deleted`,
        buttons: 'singleButton',
      });
      setShowPopUp(true);
    }
    // setPopData({ state: true, type: 0, data: res });
    loadInitalData();
  }

  async function edit_ban(id) {
    const edit_prefil = await BanApiCalls.get_view_data(id);
    setPopData({ state: true, type: 3, data: edit_prefil });
  }

  function tableDataGen() {
    if (!tableData) {
      return [];
    }
    const dataArr = [];
    for (let i = 0; i < tableData.length; i++) {
      const row = tableData[i];
      if (search.length > 0) {
        if (
          (row.info &&
            String(row.info)
              .toLowerCase()
              .includes(String(search).toLocaleLowerCase())) ||
          (row.security &&
            String(row.security)
              .toLowerCase()
              .includes(String(search).toLocaleLowerCase())) ||
          (row.famOrDeal &&
            row.famOrDeal.length &&
            String(row.famOrDeal.map((item) => item.name).join(', '))
              .toLowerCase()
              .includes(String(search).toLowerCase()))
        ) {
          dataArr.push(
            <TableRow>
              <TableDataCell>{i + 1}</TableDataCell>
              <TableDataCell>{row.id}</TableDataCell>
              <TableDataCell>{row.info}</TableDataCell>
              <TableDataCell>{row.security}</TableDataCell>
              <TableDataCell>{row.banType}</TableDataCell>
              <TableDataCell>
                {row.familyType.map((item) => item.name).join(', ') || '-'}
              </TableDataCell>
              <TableDataCell>
                {row.famOrDeal.map((item) => item.name).join(', ')}
              </TableDataCell>
              <TableDataCell>{row.updatedAt.slice(0, 11)}</TableDataCell>
              <TableDataCell fontColor={row.status ? 'green' : 'red'}>
                {row.status ? 'Unbanned' : 'Banned'}
              </TableDataCell>
              <TableDataCell width="8vw">
                <PillButton
                  iconType={0}
                  clickAction={() =>
                    setPopData({
                      state: true,
                      type: 2,
                      data: {
                        ...row,
                        banEn:
                          row.type === 0
                            ? false
                            : row.type === 1
                            ? false
                            : true,
                      },
                    })
                  }
                />
              </TableDataCell>
              <TableDataCell width="8vw">
                <PillButton
                  iconType={1}
                  clickAction={() =>
                    setPopData({ state: true, type: 3, data: row })
                  }
                  disabled={permission === 'edit' ? false : true}
                />
              </TableDataCell>
              <TableDataCell width="8vw">
                <PillButton
                  iconType={2}
                  disabled={permission === 'edit' ? false : true}
                  clickAction={() => {
                    if (!(popup && popup.banId !== -1)) {
                      setPopup({
                        ...popup,
                        banId: row.id,
                        type: 'deleteConfirm',
                        buttons: 'doubleButtons',
                        nameAccept: 'Yes',
                        nameDecline: 'No',
                        header: !row.status
                          ? BAN_DELETE_CONFIRM + ` with id ${row.id} ?`
                          : UNBAN_DELETE_CONFIRM + ` with id ${row.id} ?`,
                        isBan: !row.status,
                      });
                      setShowPopUp(true);
                      setTimeout(() => {
                        setFocusOnModalKeyWithClassName('No');
                      }, 500);
                    }
                  }}
                />
              </TableDataCell>
              <TableDataCell width="8vw">
                <RemarkButton
                  title={
                    row.remarks && row.remarks.length > 0
                      ? row.remarks[0].remark.length > 20
                        ? `${row.remarks[0].remark.slice(0, 20)}...`
                        : row.remarks[0].remark
                      : ''
                  }
                  onClickAction={() =>
                    setPopData({ state: true, type: 4, data: row.remarks })
                  }
                />
              </TableDataCell>
              <TableDataCell width="3vw">
                {row.type !== 2 && (
                  <img
                    src={row.type === 0 ? DesIcon : row.type === 1 && AscIcon}
                    className="icon-bansec"
                    onClick={() => toggleDrop(i, row.type)}
                    alt="sort"
                  />
                )}
              </TableDataCell>
            </TableRow>
          );
        }
      } else {
        dataArr.push(
          <TableRow>
            <TableDataCell width="2vw">{i + 1}</TableDataCell>
            <TableDataCell width="2vw">{row.id}</TableDataCell>
            <TableDataCell width="8vw">
              {row.info.length > 15 ? `${row.info.slice(0, 15)}...` : row.info}
            </TableDataCell>
            <TableDataCell width="8vw">{row.security}</TableDataCell>
            <TableDataCell width="8vw">{row.banType}</TableDataCell>
            <TableDataCell width="8vw">
              {row.familyType.map((item) => item.name).join(', ') || '-'}
            </TableDataCell>
            <TableDataCell>
              {row.famOrDeal.map((item) => item.name).join(', ').length > 15
                ? `${row.famOrDeal
                    .map((item) => item.name)
                    .join(', ')
                    .slice(0, 15)}...`
                : row.famOrDeal.map((item) => item.name).join(', ')}
            </TableDataCell>
            <TableDataCell>{row.updatedAt.slice(0, 11)}</TableDataCell>
            <TableDataCell fontColor={row.status ? 'green' : 'red'}>
              {row.status ? 'Unbanned' : 'Banned'}
            </TableDataCell>
            <TableDataCell width="3vw">
              <PillButton
                iconType={0}
                extStyles={{ margin: 'auto' }}
                clickAction={() =>
                  setPopData({
                    state: true,
                    type: 2,
                    data: {
                      ...row,
                      banEn:
                        row.type === 0 ? false : row.type === 1 ? false : true,
                    },
                  })
                }
              />
            </TableDataCell>
            <TableDataCell width="3vw">
              <PillButton
                iconType={1}
                disabled={permission === 'edit' ? false : true}
                extStyles={{ margin: 'auto' }}
                clickAction={() =>
                  setPopData({ state: true, type: 3, data: row })
                }
              />
            </TableDataCell>
            <TableDataCell width="3vw">
              <PillButton
                iconType={2}
                disabled={permission === 'edit' ? false : true}
                extStyles={{ margin: 'auto' }}
                clickAction={() => {
                  if (!(popup && popup.banId !== -1)) {
                    setPopup({
                      ...popup,
                      banId: row.id,
                      type: 'deleteConfirm',
                      buttons: 'doubleButtons',
                      nameAccept: 'Yes',
                      nameDecline: 'No',
                      header: !row.status
                        ? BAN_DELETE_CONFIRM + ` with id ${row.id} ?`
                        : UNBAN_DELETE_CONFIRM + ` with id ${row.id} ?`,
                      isBan: !row.status,
                    });
                    setShowPopUp(true);
                    setTimeout(() => {
                      setFocusOnModalKeyWithClassName('No');
                    }, 500);
                  }
                }}
              />
            </TableDataCell>
            <TableDataCell>
              <RemarkButton
                title={
                  row.remarks && row.remarks.length > 0 && row.remarks[0].remark
                }
                onClickAction={() =>
                  setPopData({ state: true, type: 4, data: row.remarks })
                }
              />
            </TableDataCell>
            <TableDataCell width="3vw">
              {row.type !== 2 &&
                tableData &&
                tableData[i] &&
                tableData[i].subData &&
                tableData[i].subData.length > 0 && (
                  <img
                    src={row.type === 0 ? DesIcon : row.type === 1 && AscIcon}
                    className="icon-bansec"
                    onClick={() => toggleDrop(i, row.type)}
                    alt="down-icon"
                  />
                )}
            </TableDataCell>
          </TableRow>
        );
      }
    }
    set_display_table(dataArr);
  }

  useEffect(() => tableDataGen(), [tableData, search]);

  // Selective Update on Successfull Dealer Excel upload
  useEffect(() => {
    if (isSuccessUpload === true) {
      try {
        loadInitalData();
      } catch (error) {
        console.log(error);
      }
      setIsSuccessUpload(false);
    }
  }, [isSuccessUpload]);

  useEffect(() => {
    initializeCurrentPageInfo();
  }, [display_table]);

  const initializeCurrentPageInfo = () => {
    handlePageChange(pagesInfo.current);
  };

  const handlePageChange = (id) => {
    updatePagesInfoGivenCurrent(id);
    updateCurrentDataGivenCurrent(id);
  };

  const updatePagesInfoGivenCurrent = (current) => {
    let totalPages = Math.ceil(display_table.length / entriesOnOnePage);
    setPagesInfo({ current: current, total: totalPages });
  };

  const updateCurrentDataGivenCurrent = (current) => {
    let startIndex = entriesOnOnePage * (current - 1);
    let endIndex = Math.min(
      startIndex + entriesOnOnePage,
      display_table.length
    );
    setCurrentData(display_table.slice(startIndex, endIndex));
  };
  return (
    <div className="ban-security-container">
      <UMButtonGroup
        createOnClick={() => setPopData({ state: true, type: 1, data: {} })}
        onSearchChange={setSearch}
        searchVal={search}
        searchPlaceHolder="Search Securities"
        onUploadExec={() => set_uep_state(true)}
        onExportExec={() =>
          MakeRequest('/excel/generate/ban').download('ban.xlsx')
        }
        extStyles={{ margin: '1vh 0 1vh 0' }}
        edit={permission === 'edit' ? true : false}
        enableDeleteAll={true}
      />

      <TableGenerator
        heads={[
          'SNo.',
          'ID',
          'Info',
          'Securities',
          'Ban Type',
          'Family Type',
          'Families/Dealers',
          'Last Modified',
          'Status',
          'View',
          'Edit',
          'Delete',
          'Remarks',
          '',
        ]}
        content={currentData}
        extStyles={{ margin: '1vh 0 1vh 0', overflowX: 'scroll' }}
        className="tableContainer bodyScrollTable"
        pagesInfo={pagesInfo}
        pageChange={handlePageChange}
        showNoOfEntries={true}
        totalEntries={display_table.length}
        entriesPerPage={entriesOnOnePage}
      />
      {showPopUp ? (
        <Popup
          popClass="banPopup"
          actionButton={actionOnpopup}
          popupDetails={popup}
          header={popup.header}
        />
      ) : null}
      <GenericPopup
        state={popData.state}
        toggleState={() => setPopData({ ...popData, state: false })}
        type={1}
      >
        {popup.type !== -1 ? (
          <BanSecPopup
            edit={permission === 'edit' ? true : false}
            type={popData.type}
            data={popData.data}
            edit_ban={edit_ban}
            set_popup={(data) => {
              setPopData(data);
              loadInitalData();
            }}
            setPopup={setPopup}
            setShowPopUp={setShowPopUp}
          />
        ) : null}
      </GenericPopup>
      <FileUploadComponent
        show={uep_state}
        setShow={set_uep_state}
        screenType={'ban'}
        setSuccessUpload={setIsSuccessUpload}
      />
    </div>
  );
}

import React from 'react';
import './styles.css';

export default function ToggleTabs(props) {
  const {
    type = 1,
    options = [],
    selected = '',
    onSelect = (selectedItem) => {},
    extStyles = {},
    disabled = false,
  } = props;

  function tagType() {
    switch (type) {
      case 2:
        return 'ToggleTabs-tab2-';
      default:
        return 'ToggleTabs-tab-';
    }
  }

  function containerType() {
    switch (type) {
      case 2:
        return 'ToggleTabs-container2';
      default:
        return 'ToggleTabs-container';
    }
  }

  return (
    <div className={containerType()} style={extStyles}>
      {options.map((item) => (
        <p
          className={`${tagType()}${
            selected === item ? `act${disabled ? '-disabled' : ''}` : 'ina'
          }`}
          onClick={() => {
            if (!disabled) {
              onSelect(item);
            }
          }}
        >
          {item}
        </p>
      ))}
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { Modal, Container, Col, Row } from 'react-bootstrap';
import '../MarketWatchModal/MarketWatchModal.scss';
import { Helmet } from 'react-helmet';
import { PAGE_TITLES } from '../../../utils/constant';
import moment from 'moment';
import closeSvg from '../../../assets/img/closeSvg.svg';
import { webSocketUrl } from '../../../config/apiConfig';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../../sharedComponents/keyPressEventHandler';
import { useParams } from 'react-router-dom';
var W3CWebSocket = require('websocket').w3cwebsocket;

const OrderBookTicker = (props) => {
  // const handleClose = () => setModal(false); // funtion to close the modal
  const [data, setData] = useState({
    exchange: '',
    security: '',
    orderbook: [],
  });
  const [client, setClient] = useState();
  const clientRef = useRef();
  const [orderDetails, setOrderDetails] = useState(null);
  const {
    exchange: paramExchange,
    userId: paramUserId,
    dataFeedTicker: paramDataFeedTicker,
    esteeId: paramEsteeId,
    securityName: paramSecurityName,
  } = useParams();
  let exchange = window.atob(paramExchange ? paramExchange : '');
  let userId = window.atob(paramUserId ? paramUserId : '');
  let dataFeedTicker = window.atob(
    paramDataFeedTicker ? paramDataFeedTicker : ''
  );
  let esteeId = window.atob(paramEsteeId ? paramEsteeId : '');

  // Connect to webSocket
  useEffect(() => {
    openConnection();
    return () => {
      if (clientRef && clientRef.current) {
        clientRef.current.close();
      }
    };
  }, []);

  // Once connected to webSocket: Subscribe for given security
  useEffect(() => {
    let row = {
      exchange: exchange,
      userId: userId,
      dataFeedTicker: dataFeedTicker,
      esteeId: esteeId,
    };
    fetchOrderBook('SUBSCRIBE', row);
  }, [client]);

  const openConnection = () => {
    let clientObj = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    setClient(clientObj);
  };

  const fetchOrderBook = (action, row) => {
    let userId = localStorage.getItem('customerId');
    if (client) {
      let dataFeedTicker = row.security;
      let esteeId = row.esteeId;
      let exchange = row.exchange;
      client.onerror = function () {
        //setTableLoader(false);
      };
      let eventType = '';
      switch (action) {
        case 'SUBSCRIBE':
          eventType = 'ORDERBOOK_SUBSCRIBE';
          break;
        case 'UNSUBSCRIBE':
          eventType = 'ORDERBOOK_UNSUBSCRIBE';
          break;
        default:
          eventType = 'ORDERBOOK_SUBSCRIBE';
      }
      const data = {
        eventType,
        payload: {
          userId,
          dataFeedTicker,
          esteeId,
          exchange,
        },
      };

      if (client.readyState === client.OPEN) {
        client.send(JSON.stringify(data));
      } else {
        client.onopen = () => {
          if (client.readyState === client.OPEN) {
            client.send(JSON.stringify(data));
          }
        };
      }
      client.onmessage = function (data) {
        let orderData = JSON.parse(data.data);
        if (orderData.status === 200) {
          let netBidQty = 0,
            netAskQty = 0;
          if (
            orderData &&
            orderData.payload &&
            orderData.payload.orderBook &&
            Array.isArray(orderData.payload.orderBook)
          ) {
            orderData.payload.orderBook.map((tick) => {
              if (tick && tick.bidQty) {
                netBidQty += parseInt(tick['bidQty']);
              }
              if (tick && tick.askQty) {
                netAskQty += parseInt(tick['askQty']);
              }
            });
          }
          setOrderDetails({
            ...orderData.payload,
            netBidQty: netBidQty ? netBidQty : 0,
            netAskQty: netAskQty ? netAskQty : 0,
          });
          //setTableLoader(false);
        }
      };
      client.onclose = () => {};
    } else {
      console.log(`client is not connected`);
    }
  };

  return (
    <React.Fragment>
      <Hotkeys
        keyName="esc"
        onKeyDown={(event) => {
          handleEscapeKeyEvent(event);
        }}
      />
      <div className="ticker-popup-container">
        <div className="ticker-popup-title-container">
          <span className="ticker-title">Ticker</span>
        </div>
        <div className="ticker-table-body">
          <table className="ticker-table">
            <tbody>
              <tr className="ticker-tr">
                <td className="ticekr-td">
                  <span className="ticker-titles">Exchange</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">
                    {orderDetails?.exchange ? orderDetails.exchange : ''}
                  </span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">Security</span>
                </td>
                <td className="ticekr-td" colSpan={2}>
                  <span id="ticker-time">{window.atob(paramSecurityName)}</span>
                </td>

                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span id="ticker-time">{moment().format('h:mm:ss A')}</span>
                </td>
                <td className="ticekr-td"></td>
              </tr>
              <tr className="ticker-tr">
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
              </tr>
              <tr className="ticker-tr">
                <td className="ticekr-td">
                  <span className="ticker-titles">BidPx</span>
                </td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span className="ticker-titles">BidQty</span>
                </td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span className="ticker-titles">AskPx</span>
                </td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span className="ticker-titles">AskQty</span>
                </td>
                <td className="ticekr-td"></td>
              </tr>
              {orderDetails?.orderBook?.map((item, index) => {
                return (
                  <tr className="ticker-tr" key={index}>
                    <td className="ticekr-td">
                      <span className="ticker-green">{item?.bidPrice}</span>
                    </td>
                    <td className="ticekr-td"></td>
                    <td className="ticekr-td">
                      <span className="ticker-green">{item?.bidQty}</span>
                    </td>
                    <td className="ticekr-td"></td>
                    <td className="ticekr-td">
                      <span className="ticker-red">{item?.askPrice}</span>
                    </td>
                    <td className="ticekr-td"></td>
                    <td className="ticekr-td">
                      <span className="ticker-red">{item?.askQty}</span>
                    </td>
                    <td className="ticekr-td"></td>
                  </tr>
                );
              })}
              <tr className="ticker-tr">
                <td className="ticekr-td">
                  <span className="ticker-titles">TBuyQty</span>
                </td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span className="ticker-green">
                    {orderDetails?.netBidQty}
                  </span>
                </td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span className="ticker-titles">TSellQty</span>
                </td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td">
                  <span className="ticker-red">{orderDetails?.netAskQty}</span>
                </td>
                <td className="ticekr-td"></td>
              </tr>
              <tr className="ticker-tr">
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
                <td className="ticekr-td"></td>
              </tr>
              <tr className="ticker-tr" style={{ backgroundColor: '#242b41' }}>
                <td className="ticekr-td">
                  <span className="ticker-titles">LTPx</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">
                    {orderDetails?.ltpx ? orderDetails.ltpx : ''}
                  </span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">LTT</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.ltt}</span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">TTQ</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.ttq}</span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">OI</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.oi}</span>
                </td>
              </tr>
              <tr className="ticker-tr" style={{ backgroundColor: '#242b41' }}>
                <td className="ticekr-td">
                  <span className="ticker-titles">LTQty</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.ltq}</span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">ATPx</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.atpx}</span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">TTV(m)</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.ttv}</span>
                </td>
                <td className="ticekr-td">
                  <span className="ticker-titles">VWAP</span>
                </td>
                <td className="ticekr-td">
                  <span id="ticker-time">{orderDetails?.vwap}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderBookTicker;

import { useState } from 'react';
import './styles.css';
import { DropdownIcon } from '../../../../assets';
import { CheckBox, ToggleTab } from '../../../../components';

export default function UMMenuGen(props) {
  const { settings = {}, data = [], changeMenu = (val) => {} } = props;
  const [visible, setVisible] = useState(
    settings.type && settings.type === 1 ? false : true
  );

  function changeData(id, val) {
    const temp = [...data];
    for (let i = 0; i < temp.length; i++) {
      if (id === temp[i].mainSc.screenId) {
        temp[i].mainSc.access = val;
        break;
      }
      for (let j = 0; j < temp[i].subMenu.length; j++) {
        if (temp[i].subMenu[j].screenId === id) {
          temp[i].subMenu[j].access = val;
          break;
        }
      }
    }
    changeMenu(temp);
  }

  function menuGen(extStyles) {
    if (Object.keys(data).length === 0) {
      return;
    }

    return (
      <table className="menue-table-c-1" style={extStyles}>
        <thead className="menue-table-c-2">
          <th className="menue-table-c-3">Menu</th>
          <th className="menue-table-c-3">Sub Menu</th>
          <th className="menue-table-c-3">Access</th>
        </thead>
        <tbody className="menue-table-c-body">
          {data &&
            data.map((item) => {
              let temp = [
                <tr>
                  <td rowSpan={item.subMenu.length} className="menue-table-c-6">
                    {settings.view ? (
                      <span style={{ margin: '0 0 0 1vw' }}>
                        {item.mainSc.title}
                      </span>
                    ) : (
                      <>
                        <CheckBox
                          state={item.mainSc.access >= 1}
                          label={item.mainSc.title}
                          extStyles={{
                            margin: '1vh auto 1vh auto',
                            width: 'fit-content',
                          }}
                          trigger={(val) =>
                            changeData(item.mainSc.screenId, val ? 1 : 0)
                          }
                        />
                        <ToggleTab
                          options={['View', 'Edit']}
                          selected={item.mainSc.access > 1 ? 'Edit' : 'View'}
                          disabled={item.mainSc.access === 0}
                          extStyles={{
                            margin: '1vh auto 1vh auto',
                            width: 'fit-content',
                          }}
                          onSelect={(val) =>
                            changeData(
                              item.mainSc.screenId,
                              val === 'View' ? 1 : 2
                            )
                          }
                        />
                      </>
                    )}
                  </td>
                  <td className="menue-table-c-8">
                    {settings.view ? (
                      <span style={{ margin: '0 0 0 1vw' }}>
                        {item.subMenu[0].title}
                      </span>
                    ) : (
                      <CheckBox
                        state={
                          item.subMenu[0].access >= 1 &&
                          item.mainSc.access !== 0
                        }
                        label={item.subMenu[0].title}
                        extStyles={{ margin: '1vh 0 1vh 1vw' }}
                        trigger={(val) =>
                          changeData(item.subMenu[0].screenId, val ? 1 : 0)
                        }
                        disabled={item.mainSc.access === 0}
                      />
                    )}
                  </td>
                  <td className="menue-table-c-9">
                    {settings.view ? (
                      <span style={{ margin: '0 0 0 1vw' }}>
                        {item.subMenu[0].access >= 1 &&
                        item.subMenu[0].access !== 0
                          ? item.subMenu[0].access === 1
                            ? 'View'
                            : 'Edit'
                          : 'Disabled'}
                      </span>
                    ) : (
                      <ToggleTab
                        options={['View', 'Edit']}
                        selected={item.subMenu[0].access > 1 ? 'Edit' : 'View'}
                        disabled={
                          item.subMenu[0].access === 0 ||
                          item.mainSc.access === 0
                        }
                        extStyles={{ margin: '1vh auto 1vh auto' }}
                        onSelect={(val) =>
                          changeData(
                            item.subMenu[0].screenId,
                            val === 'View' ? 1 : 2
                          )
                        }
                      />
                    )}
                  </td>
                </tr>,
              ];
              for (let i = 1; i < item.subMenu.length; i++) {
                temp.push(
                  <tr>
                    <td className="menue-table-c-8">
                      {settings.view ? (
                        <span style={{ margin: '1vh 0 1vh 1vw' }}>
                          {item.subMenu[i].title}
                        </span>
                      ) : (
                        <CheckBox
                          state={
                            item.subMenu[i].access >= 1 &&
                            item.mainSc.access !== 0
                          }
                          label={item.subMenu[i].title}
                          extStyles={{ margin: '1vh 0 1vh 1vw' }}
                          trigger={(val) =>
                            changeData(item.subMenu[i].screenId, val ? 1 : 0)
                          }
                          disabled={item.mainSc.access === 0}
                        />
                      )}
                    </td>
                    <td className="menue-table-c-9">
                      {settings.view ? (
                        <span style={{ margin: '0 0 0 1vw' }}>
                          {item.subMenu[i].access >= 1 &&
                          item.subMenu[i].access !== 0
                            ? item.subMenu[i].access === 1
                              ? 'View'
                              : 'Edit'
                            : 'Disabled'}
                        </span>
                      ) : (
                        <ToggleTab
                          options={['View', 'Edit']}
                          selected={
                            item.subMenu[i].access > 1 ? 'Edit' : 'View'
                          }
                          disabled={
                            item.subMenu[i].access === 0 ||
                            item.mainSc.access === 0
                          }
                          extStyles={{ margin: '1vh auto 1vh auto' }}
                          onSelect={(val) =>
                            changeData(
                              item.subMenu[i].screenId,
                              val === 'View' ? 1 : 2
                            )
                          }
                        />
                      )}
                    </td>
                  </tr>
                );
              }
              return temp;
            })}
        </tbody>
      </table>
    );
  }

  function wrapperGen() {
    switch (settings.type || 1) {
      case 1:
        return (
          <div style={settings.style || {}} className="user-main-menue-expand">
            <div className="user-main-menue-1">
              <span className="user-main-menue-5">Menu</span>
              <div
                className="user-main-menue-2"
                onClick={() => setVisible(!visible)}
              >
                <img
                  className="user-main-menue-3"
                  src={DropdownIcon}
                  alt="dd"
                />
                <span className="user-main-menue-4">
                  {visible ? 'Collapse' : 'Expand'}
                </span>
              </div>
            </div>
            {visible && menuGen()}
          </div>
        );
      case 2:
        return <div style={settings.style || {}}>{menuGen()}</div>;
      default:
        return;
    }
  }

  return wrapperGen();
}

import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pause from '../../../../assets/img/pause_blue.svg';
import Play from '../../../../assets/img/Group 162788.svg';
import { useDispatch } from 'react-redux';
import Close from '../../../../assets/img/close.svg';
import CloseBlue from '../../../../assets/img/closeBlue.svg';
import { strategyAnalyzerButton } from '../../../../actions/strategyAnalyzerAction';
import pauseDisabled from '../../../../assets/img/pauseDisabled.svg';
import Popup from '../../../sharedComponents/Popup/Popup';
import { handleEnterWithCallbackAndValue } from '../../../sharedComponents/keyPressEventHandler';
import './styles.css';

const ActionButtons = React.memo((props) => {
  const {
    label,
    enablePause,
    enableResume,
    listId,
    enableClose,
    strategyName,
    dataId,
    allowed = true,
  } = props;
  const [loader, setLoader] = React.useState(false);
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    state: false,
  });
  const [buttonReq, setButtonReq] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  let renderButton = <></>;

  useEffect(() => {
    switch (popup.type) {
      case 'pausePop':
        let tempMessage = messageFunc();
        setPopup({ ...popup, message: tempMessage, buttons: 'singleButton' });
        break;
      default:
        break;
    }
  }, [popup.type]);

  const pressEnterCallbackClose = (params) => {
    btnHandler('CLOSE', params);
  };

  const pressEnterCallbackPause = (params) => {
    btnHandler('PAUSE', params);
  };

  const pressEnterCallbackResume = (params) => {
    btnHandler('RESUME', params);
  };

  const actionOnpopup = (e, type) => {
    if (type == 'CLOSE') {
      setErrorMsg('');
      setPopup({ state: false });
    }
  };

  const btnHandler = async (buttonType, params) => {
    try {
      setLoader(true);
      setErrorMsg('');
      let result = await dispatch(
        strategyAnalyzerButton({
          buttonType,
          strategyList: [listId],
        })
      );
      setLoader(false);
      if (
        result &&
        result.message &&
        Object.values(result.message) &&
        Object.values(result.message).length
      ) {
        setErrorMsg(
          Object.values(result.message)
            .map((item) => item.error || '')
            .join('')
        );
        setPopup({ type: 'pausePop', state: true });
      }
      if (
        buttonType === 'PAUSE' ||
        buttonType === 'RESUME' ||
        buttonType === 'CLOSE'
      ) {
        setButtonReq(buttonType.toLowerCase());
      }
    } catch (err) {
      setLoader(false);
    }
  };

  switch (label) {
    case 'PAUSE/RESUME':
      renderButton = (
        <>
          {loader ? (
            <CircularProgress disableShrink size={20} />
          ) : (
            <>
              {!enablePause && enableResume ? (
                <span
                  className="pill-container"
                  onClick={() => allowed && btnHandler('RESUME', {})}
                >
                  <img
                    src={Play}
                    alt="img"
                    tabIndex="0"
                    className="pill-icon"
                    onKeyPress={(event) =>
                      allowed &&
                      handleEnterWithCallbackAndValue(
                        event,
                        pressEnterCallbackResume,
                        {}
                      )
                    }
                  />
                </span>
              ) : !enableResume && enablePause ? (
                <span
                  className="pill-container"
                  onClick={() => allowed && btnHandler('PAUSE', {})}
                >
                  <img
                    src={Pause}
                    alt="img"
                    tabIndex="0"
                    className="pill-icon"
                    onKeyPress={(event) =>
                      allowed &&
                      handleEnterWithCallbackAndValue(
                        event,
                        pressEnterCallbackPause,
                        {}
                      )
                    }
                  />
                </span>
              ) : !enableResume && !enablePause ? (
                <span
                  className="pill-container"
                  onClick={() => allowed && btnHandler('PAUSE', {})}
                >
                  <img src={pauseDisabled} alt="img" className="pill-icon" />
                </span>
              ) : (
                <span
                  className="pill-container"
                  onClick={() => allowed && btnHandler('PAUSE', {})}
                >
                  <img src={pauseDisabled} alt="img" className="pill-icon" />
                </span>
              )}
            </>
          )}
        </>
      );
      break;

    case 'CLOSE':
      let closeButton = enableClose ? CloseBlue : Close;
      renderButton = (
        <div
          onClick={() => {
            if (enableClose && allowed) {
              btnHandler('CLOSE', {});
            }
          }}
        >
          {loader ? (
            <CircularProgress disableShrink size={20} />
          ) : (
            <>
              {!enableClose ? (
                <span className="pill-container">
                  <img src={closeButton} alt="img" className="pill-icon" />
                </span>
              ) : (
                <span className="pill-container">
                  <img
                    src={closeButton}
                    alt="img"
                    className="pill-icon"
                    tabIndex="0"
                    onKeyPress={(event) =>
                      allowed &&
                      handleEnterWithCallbackAndValue(
                        event,
                        pressEnterCallbackClose,
                        {}
                      )
                    }
                  />
                </span>
              )}
            </>
          )}
        </div>
      );
      break;

    default:
      renderButton = <></>;
  }

  const messageFunc = () => {
    let strategyId = [{ id: dataId, name: strategyName }];
    return (
      <>
        <span className="button-req">
          {errorMsg !== ''
            ? errorMsg
            : `${buttonReq} Request sent
                Successfully`}
        </span>
        <p>
          Strategy IDs:{' '}
          {strategyId &&
            strategyId.length &&
            strategyId
              .map((el) => {
                return `${el.id} - ${el.name}`;
              })
              .join(', ')}
        </p>
      </>
    );
  };
  return (
    <>
      {renderButton}
      {popup.state ? (
        <Popup actionButton={actionOnpopup} popupDetails={popup} />
      ) : null}
    </>
  );
});

export default ActionButtons;

import React, { Fragment } from 'react';
import CheckBox from '../check_box';
import CompLabel from '../customComponent/compLabel';
import './styles.css';

export default function RadioGroup(props) {
  const {
    extStyles = {},
    label,
    options = [],
    onSelect = (item, state) => {},
    selected = {},
    disabled = false,
  } = props;
  function triggerOnSelect(item) {
    if (!disabled) {
      onSelect(item);
    }
  }

  return (
    <Fragment>
      {label && (
        <CompLabel label={label} extStyles={extStyles}>
          <div className="radio-group-container">
            {options.map((item) => (
              <CheckBox
                radio={true}
                state={selected[item]}
                trigger={(val) => triggerOnSelect(item)}
                label={item}
                extStyles={{ margin: '0.5vh' }}
              />
            ))}
          </div>
        </CompLabel>
      )}
      {!label && (
        <div className="radio-group-container" style={extStyles}>
          {options.map((item) => (
            <CheckBox
              radio={true}
              state={selected[item]}
              trigger={(val) => triggerOnSelect(item)}
              label={item}
              extStyles={{ margin: '0.5vh' }}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
}

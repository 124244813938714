import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';
import { useDetectOutsideClick } from '../../../../../utils/useDetectOutsideClick';
import UiButton from '../../../../sharedComponents/Button';
import './ViewFamilyModal.scss';
import {
  getAllfamilyType,
  getfamilyDealer,
  updateFamilyForm,
  singlefamilyData,
  resetFamilyData,
  setEditFamily
} from '../../../../../actions/familyAction';
import { useDispatch, useSelector } from 'react-redux';

const ViewFamilyModal = ({ viewModal, setViewModal }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const {
    familyReducer: { familyTypeList, familyDealersList, family },
  } = useSelector((state) => state);

  const handleRemoveEntity = (index) => {
    let form = { ...family };
    if (index > -1) {
      form.entityList.splice(index, 1);
    }
    dispatch(updateFamilyForm(form));
  };

  const inputHandler = (e) => {
    let form = { ...family };
    switch (e.target.name) {
      case 'familyName':
        let familyName = e.target.value;
        form['familyName'] = familyName;
        dispatch(updateFamilyForm(form));
        break;
      case 'familyType':
        let familyType = {
          id: parseInt(e.target.value),
          name:
            familyTypeList &&
            familyTypeList.find((el) => el.id === parseInt(e.target.value))
              ? familyTypeList.find((el) => el.id === parseInt(e.target.value))
                  .name
              : '',
        };
        form['familyType'] = familyType;
        dispatch(updateFamilyForm(form));
        break;
    }
  };

  const entityInputHandler = (e, entityIndex) => {
    let form = { ...family };
    form.entityList[entityIndex][e.target.name] = e.target.value;
    dispatch(updateFamilyForm(form));
  };

  const addEntityHandler = () => {
    let form = { ...family };
    form['entityList'] = [
      ...form.entityList,
      {
        entityName: '',
        entityType: '',
        exchange: '',
        pan: '',
        ucc: '',
        cpCode: '',
        ems: '',
      },
    ];
    dispatch(updateFamilyForm(form));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllfamilyType());
    dispatch(getfamilyDealer());
  }, []);

  const handleDealers = (e, item) => {
    let form = { ...family };
    let setObj = familyDealersList.find((el) => el.id === item.id);
    let checked = e.target.checked;
    if (checked) {
      let newobj = {
        dealerId: setObj.id,
        dealerName: setObj.name,
      };
      form['dealerList'] = [...form.dealerList, newobj];
      dispatch(updateFamilyForm(form));
    } else {
      let filterDealerList = form.dealerList.filter((element) => {
        return element.dealerId !== setObj.id;
      });
      form['dealerList'] = [...filterDealerList];
      dispatch(updateFamilyForm(form));
    }
  };
  const handleClose = () => {
    let clearForm = {
      familyName: '',
      familyType: {
        id: '',
        name: '',
      },
      dealerList: [],
      entityList: [
        {
          entityName: '',
          entityType: '',
          exchange: '',
          segment: '',
          pan: '',
          ucc: '',
          cpCode: '',
          ems: '',
        },
      ],
    };
    dispatch(resetFamilyData(clearForm));
    setViewModal(false);
    dispatch(setEditFamily(false));
  }

  return (
    <div>
      <Modal
        className="view-family-modal"
        show={viewModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>View Family</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup">
            <Form
              className="family-modal-form"
            >
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="form-field">Family ID :</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={5}>{family.familyDisplayId}</Col>
                  <Col></Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="form-field">Family Name :</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={5}>{family.familyName}</Col>
                  <Col></Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="form-field">Family Type :</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={5}>{family.familyType.name}</Col>
                  <Col></Col>
                </Row>
                <div className="group flx-base ca-gap">
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="form-field">Map Dealers:</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xs={5}>
                      {
                      <div className="select ">
                        <div className="errorwrapper" 
                           
                            >
                          <div
                            id="list1"
                            className="dropdown-check-list custom-dropdwon"
                            onClick={() => {
                              setIsActive(true);
                            }}
                          >
                            {family.dealers?.map((el, index) => {
                                  return  <span className="map-Dealer"  >{el}</span>;
                            })}
                          </div>
                        </div>
                      </div>}
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                </div>

                {family.entityList &&
                  family.entityList.length &&
                  family.entityList?.map((entity, entityIndex) => {
                    return (
                      <div className="entities" key={entityIndex}>
                        
                        
                        <div className="main">
                          <p className="entityTooltip">
                            <span className="entityText">
                              Entity: {entityIndex + 1}
                            </span>{' '}
                          </p>
                          <div className="w-100">
                            <Row>
                              <Col>
                                <span className="form-fields heading-title">
                                  Entity Id:
                                </span>
                                <span className="form-fields">
                                  {entity?.entityDisplayId}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={4}>
                                <span className="form-fields heading-title">
                                  Entity Name:
                                </span>
                                <span className="form-fields">
                                  {entity?.entityName}
                                </span>
                              </Col>
                              <Col sm={4}>
                                <span className="form-fields heading-title">
                                  Entity Type:
                                </span>
                                <span className="form-fields">
                                  {entity?.entityType}
                                </span>
                              </Col>
                              <Col sm={4}>
                                <span className="form-fields heading-title">Exchange:</span>
                                <span className="form-fields">
                                  {entity?.exchange}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={4}>
                                <span className="form-fields heading-title">PAN:</span>
                                <span className="form-fields">
                                  {entity?.pan}
                                </span>
                              </Col>
                              <Col sm={4}>
                                <span className="form-fields heading-title">UCC:</span>
                                <span className="form-fields">
                                  {entity?.ucc}
                                </span>
                              </Col>
                              <Col sm={4}>
                                <span className="form-fields heading-title">CP Code:</span>
                                <span className="form-fields">
                                  {entity.cpCode}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={4}>
                                <span className="form-fields heading-title">EMS:</span>
                                <span className="form-fields">
                                  {entity?.ems?.applicationName}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="buttons text-center mt-3 all-buttons">
                  <UiButton
                    className="btn btn-primary col-sm-3 mr-2 close-btn"
                    name="Close"
                    onClickFunction={() => handleClose()}
                  />
                  
                </div>
              </Container>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewFamilyModal;

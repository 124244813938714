import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DraggingDots, HideEye, UnhideEye } from '../../../../assets';
import './styles.css';
import InputField from '../../inputField';

export default function DragDropFunc(props) {
  const {
    content: state = [],
    onChange: setState = () => {},
    extStyles = {},
  } = props;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: '#ffffff',
    width: '100%',
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = reorder(state, result.source.index, result.destination.index);
    setState(items);
  }

  return (
    <div style={{ overflowY: 'auto', ...extStyles }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {state.map((item, index) => (
                <div className="DragDropFunc-draggingRow">
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '3rem',
                          backgroundColor: provided.isDragging
                            ? '#f7f7f7'
                            : '#ffffff',
                          ...getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          ),
                        }}
                      >
                        <img src={DraggingDots} className="dragcomp-i1" />
                      </div>
                    )}
                  </Draggable>
                  <span className="dragcomp-t">{item.title}</span>
                  {item.columnSize !== undefined && (
                    <InputField
                      value={item.columnSize}
                      inputWidth="3rem"
                      extStyles={{ margin: '0 0 0 auto' }}
                      onValueChange={(val) => {
                        const stat1 = [...state];
                        const ind = stat1.findIndex(
                          (item1) => item1.id === item.id
                        );
                        stat1[ind].columnSize = val;
                        setState(stat1);
                      }}
                    />
                  )}
                  <img
                    alt=""
                    src={item.visibility ? UnhideEye : HideEye}
                    className="dragcomp-i2"
                    onClick={() => {
                      const stat1 = [...state];
                      const ind = stat1.findIndex(
                        (item1) => item1.id === item.id
                      );
                      stat1[ind].visibility = !item.visibility;
                      setState(stat1);
                    }}
                  />
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

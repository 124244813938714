import axios from 'axios';
import { url } from '../config/apiConfig';

export const userData = (password) => async (dispatch) => {
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/login/forget/changePassword`,
      data: password,
    });
    if (result) {
      dispatch({
        type: 'RESET_PASS',
        payload: true,
      });
      return {
        status: result.status,
        message: result.data.data.message,
      };
    }
  } catch (err) {
    dispatch({
      type: 'RESET_PASS',
      payload: false,
    });
    return {
      status: err && err.response && err.response.status ? err.response.status : 500,
      message: err.response.data.data.message,
    };
  }
};

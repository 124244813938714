import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import {
  TabNavInner,
  TableGenerator,
  PillButton,
  GenButton,
} from '../../../components';
import { useSelector } from 'react-redux';
import { GenericPopup } from '../../../components';
import ManageGenPopup from '../customComp/ManageConfigPopup';
import { useDispatch } from 'react-redux';
import OpsApiCalls from '../redux/apiCalls';
import NestedDropdown from '../customComp/nestedDropdown/index.jsx';
import EditApplicationSchedulePopup from '../customComp/editApplicationSchedulePopup';

export default function OpsUserHeartBeat(props) {
  const dispatch = useDispatch();

  const [rightSecTab, setRightSecTab] = useState(0);
  const [heartbeatData, setHeartbeatData] = useState({});

  useEffect(() => {
    const apiCall = async () => {
      try {
        const data = await OpsApiCalls.getHeartbeatData();
        setHeartbeatData(data);
      } catch (error) {
        console.log(error);
      }
    };
    //Api call to get heartbeat data
    apiCall();
  }, []);

  return (
    <div className="ops-heartbeat-container">
      <div className="ops-heartbeat-location">
        <NestedDropdown dataReceived={heartbeatData} />
      </div>
      <div className="ops-heartbeat-location-tables">
        <TabNavInner
          tabs={[
            { id: 0, title: 'Application' },
            { id: 1, title: 'Application Schedule' },
          ]}
          curSelect={rightSecTab}
          setSelect={(val) => setRightSecTab(val)}
        />
        {rightSecTab === 1 && <ApplicationSchedule />}
        {rightSecTab === 0 && <Application />}
      </div>
    </div>
  );
}

function ApplicationSchedule(props) {
  const {} = props;
  const [dispTable, setDispTable] = useState([]);
  const [editAppPopup, setEditAppPopup] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [popupData, setPopupData] = useState({
    selectedWeekDays: [],
    startTime: '',
    endTime: '',
  });
  const { applicationSchedule } = useSelector((state) => state.OpsReducer);
  const applicationScheduleTableRef = useRef(null);

  function handleDateFormatConversion(dateString, ampm = false) {
    const date = new Date(dateString);
    if (ampm) {
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    }
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
  }

  function getWeekdays(value) {
    //convert weekday integer to array based on indexes
    const weekDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let selectedWeekdays = [];
    let idx = 5;
    while (value) {
      if (value & 1) {
        selectedWeekdays.push(weekDays[idx]);
      }
      value = value >> 1;
      idx--;
    }
    selectedWeekdays.reverse();
    selectedWeekdays = selectedWeekdays.join(', ');
    return selectedWeekdays;
  }

  function getValue(headerArray, value, index) {
    //value to be displayed on screen
    if (
      headerArray[index] == 'Start Time' ||
      headerArray[index] == 'End Time'
    ) {
      return handleDateFormatConversion(value);
    } else if (headerArray[index] == 'Days of Week') {
      return getWeekdays(value);
    }
    return value;
  }

  useEffect(() => {
    const handleScroll = () => {
      const appTable = applicationScheduleTableRef.current;
      const scrollTop = appTable.scrollTop;
      const scrollHeight = appTable.scrollHeight;
      const clientHeight = appTable.clientHeight;
      const remainingScroll = scrollHeight - scrollTop;

      if (remainingScroll <= clientHeight + 2) {
        loadMoreItems(visibleItems);
      }
    };

    if (applicationScheduleTableRef && applicationScheduleTableRef.current) {
      applicationScheduleTableRef.current.addEventListener(
        'scroll',
        handleScroll
      );
    }

    return () => {
      if (applicationScheduleTableRef && applicationScheduleTableRef.current) {
        applicationScheduleTableRef.current.removeEventListener(
          'scroll',
          handleScroll
        );
      }
    };
  }, [visibleItems]);

  const loadMoreItems = (items) => {
    let len = Math.min(items.length + 100, applicationSchedule.length);
    const item = applicationSchedule.slice(visibleItems.length, len);
    setVisibleItems((prevItems) => prevItems.concat(item));
  };

  const handleEditButtonClick = (curRow) => {
    setEditAppPopup(true);
    let updatedObject = { ...popupData };
    updatedObject.appId = curRow[0];
    updatedObject.startTime = curRow[2];
    updatedObject.endTime = curRow[3];
    updatedObject.selectedWeekDays = curRow[1];
    setPopupData(updatedObject);
  };

  function contentGen(data) {
    const temp = [];
    for (let index = 1; index < data.length; index++) {
      const curRow = data[index];
      temp.push(
        <tr className="app-sch-tr">
          {curRow.map((val, idx) => (
            <td className="app-sch-td">{getValue(data[0], val, idx)}</td>
          ))}
          <td className="app-sch-td">
            <div
              onClick={() => {
                handleEditButtonClick(curRow);
              }}
            >
              <PillButton iconType={1} />{' '}
            </div>
          </td>
        </tr>
      );
    }
    return temp;
  }

  useEffect(() => {
    //initial rendering of items
    if (applicationSchedule && applicationSchedule.length) {
      let len = Math.min(applicationSchedule.length, 100);
      let temp = applicationSchedule.slice(0, len);
      setVisibleItems(temp);
    }
  }, [applicationSchedule]);

  useEffect(() => {
    //set display data using visibleItems
    setDispTable(contentGen(visibleItems));
  }, [visibleItems]);

  return (
    <div className="application-schedule-ops">
      <TableGenerator
        heads={
          applicationSchedule && applicationSchedule.length > 0
            ? [
                applicationSchedule[0].map((val) => (
                  <th className="ops-head-ta-th-td">{val}</th>
                )),
                <th className="ops-head-ta-th-td">{'Edit'}</th>,
              ]
            : []
        }
        extStyles={{
          width: '65vw',
          height: '70vh',
          margin: '1vh auto 1vh auto',
          border: '1px solid #cecece',
          borderRadius: '1vh',
        }}
        version={2}
        content={dispTable}
        tableRef={applicationScheduleTableRef}
      />
      <GenericPopup
        state={editAppPopup}
        type={1}
        toggleState={() => setEditAppPopup(!editAppPopup)}
      >
        <EditApplicationSchedulePopup
          setPopupStatus={setEditAppPopup}
          data={popupData}
        />
      </GenericPopup>
    </div>
  );
}

function Application(props) {
  const { application } = useSelector((state) => state.OpsReducer);
  const [displayTable, setDisplayTable] = useState([]);
  const [manConPopup, setManConPopup] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);

  const applicationTableRef = useRef(null);

  function contentGen(data) {
    const temp = [];
    for (let index = 1; index < data.length; index++) {
      const curRow = data[index];
      temp.push(
        <tr className="app-sch-tr">
          {curRow.map((value) => (
            <td className="app-sch-td">{value}</td>
          ))}
        </tr>
      );
    }
    return temp;
  }

  useEffect(() => {
    const handleScroll = () => {
      const appTable = applicationTableRef.current;
      const scrollTop = appTable.scrollTop;
      const scrollHeight = appTable.scrollHeight;
      const clientHeight = appTable.clientHeight;
      const remainingScroll = scrollHeight - scrollTop;

      if (remainingScroll <= clientHeight + 2) {
        loadMoreItems(visibleItems);
      }
    };

    if (applicationTableRef && applicationTableRef.current) {
      applicationTableRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (applicationTableRef && applicationTableRef.current) {
        applicationTableRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [visibleItems]);

  const loadMoreItems = (items) => {
    let len = Math.min(items.length + 100, application.length);
    const item = application.slice(visibleItems.length, len);
    setVisibleItems((prevItems) => prevItems.concat(item));
  };

  useEffect(() => {
    //initial rendering
    if (application && application.length) {
      let len = Math.min(application.length, 100);
      let temp = application.slice(0, len);
      setVisibleItems(temp);
    }
  }, [application]);

  useEffect(() => {
    //generate table data based on visibleItems
    setDisplayTable(contentGen(visibleItems));
  }, [visibleItems]);

  return (
    <div className="application-ops">
      {/* <GenButton
        fill={false}
        title="MANAGE CONFIGURATION"
        extStyle={{ margin: '1vh 1vw 1vh auto' }}
        clickAction={() => setManConPopup(!manConPopup)}
      /> */}
      <TableGenerator
        heads={
          application && application.length > 0
            ? application[0].map((val) => (
                <th className="ops-head-ta-th-td">{val}</th>
              ))
            : []
        }
        extStyles={{
          width: '65vw',
          height: '60vh',
          margin: '1vh auto 1vh auto',
          border: '1px solid #cecece',
          borderRadius: '1vh',
        }}
        version={2}
        content={displayTable}
        tableRef={applicationTableRef}
      />
      <GenericPopup
        state={manConPopup}
        type={1}
        toggleState={() => setManConPopup(!manConPopup)}
      >
        <ManageGenPopup />
      </GenericPopup>
    </div>
  );
}

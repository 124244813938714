import {
  getMarketColumnArr,
  setMarketColArr,
} from '../components/LiveTrading/MarktWatch/apiCalls';

export function TableOrderReducer(
  state = {
    strategy_analyzer: {
      staticData: [],
      viewData: [],
    },
    order_trades: {
      staticData: [],
      viewData: [],
    },
    win_OT_AOO: {
      staticData: [],
      viewData: [],
    },
    win_OT_OMO: {
      staticData: [],
      viewData: [],
    },
    win_OT_T: {
      staticData: [],
      viewData: [],
    },
    win_SA_T: {
      staticData: [],
      viewData: [],
    },
    win_SA_O: {
      staticData: [],
      viewData: [],
    },
    win_risk_monitoring: {
      staticData: [],
      viewData: [],
    },
    win_risk_monitoring_pre_trade: {
      staticData: [],
      viewData: [],
    },
    net_position: {
      staticData: [],
      viewData: [],
    },
  },
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case 'SET_STRATEGY_ANALYZER_DATA':
      return { ...state, strategy_analyzer: action.payload };
    case 'SET_SA_DYNAMIC_DATA':
      return {
        ...state,
        strategy_analyzer: {
          ...state.strategy_analyzer,
          viewData: action.payload,
        },
      };
    case 'SET_OT_INIT_DATA':
      return { ...state, order_trades: action.payload };
    case 'SET_NET_POSITION':
      return { ...state, net_position: action.payload };
    case 'SET_OT_DYN_DATA':
      return {
        ...state,
        order_trades: { ...state.order_trades, viewData: action.payload },
      };
    case 'win_OT_AOO':
      return { ...state, win_OT_AOO: action.payload };
    case 'win_OT_OMO':
      return { ...state, win_OT_OMO: action.payload };
    case 'win_OT_T':
      return { ...state, win_OT_T: action.payload };
    case 'win_SA_O':
      return { ...state, win_SA_O: action.payload };
    case 'win_SA_T':
      return { ...state, win_SA_T: action.payload };
    case 'win_OT_AOO_wr':
      return {
        ...state,
        win_OT_AOO: { ...state.win_OT_AOO, viewData: action.payload },
      };
    case 'win_OT_OMO_wr':
      return {
        ...state,
        win_OT_OMO: { ...state.win_OT_OMO, viewData: action.payload },
      };
    case 'win_OT_T_wr':
      return {
        ...state,
        win_OT_T: { ...state.win_OT_T, viewData: action.payload },
      };
    case 'win_SA_O_wr':
      return {
        ...state,
        win_SA_O: { ...state.win_SA_O, viewData: action.payload },
      };
    case 'win_SA_T_wr':
      return {
        ...state,
        win_SA_T: { ...state.win_SA_T, viewData: action.payload },
      };
    case 'win_risk_monitoring_wr':
      return {
        ...state,
        win_risk_monitoring: {
          ...state.win_risk_monitoring,
          viewData: action.payload,
        },
      };
    case 'win_risk_monitoring':
      return {
        ...state,
        win_risk_monitoring: action.payload,
      };
    case 'win_risk_monitoring_pre_trade_wr':
      return {
        ...state,
        win_risk_monitoring_pre_trade: {
          ...state.win_risk_monitoring_pre_trade,
          viewData: action.payload,
        },
      };
    case 'win_risk_monitoring_pre_trade':
      return {
        ...state,
        win_risk_monitoring_pre_trade: action.payload,
      };
    case 'UPDATE_NET_POSITIONS':
      return {
        ...state,
        net_position: { ...state.net_position, viewData: action.payload },
      };
    default:
      return state;
  }
}

export const TableOrderChangeActions = {
  set_SA_initial_data: async (dispatch) => {
    const data = await getMarketColumnArr('STRATEGY_ANALYZER');
    dispatch({ type: 'SET_STRATEGY_ANALYZER_DATA', payload: data });
  },
  update_SA_sequence: async (dispatch, data) => {
    await setMarketColArr({
      tableName: 'STRATEGY_ANALYZER',
      dynamicTableSeq: data,
    });
    dispatch({ type: 'SET_SA_DYNAMIC_DATA', payload: data });
  },
  setNetPositionInitData: async (dispatch) => {
    const data = await getMarketColumnArr('NET_POSITION');
    dispatch({ type: 'SET_NET_POSITION', payload: data });
  },
  set_OT_init_data: async (dispatch) => {
    const data = await getMarketColumnArr('OTB_MAIN_VIEW');
    dispatch({ type: 'SET_OT_INIT_DATA', payload: data });
  },
  set_OT_update_data: async (dispatch, data) => {
    await setMarketColArr({
      tableName: 'OTB_MAIN_VIEW',
      dynamicTableSeq: data,
    });
    dispatch({ type: 'SET_OT_DYN_DATA', payload: data });
  },
  set_window_OT_SA_data: async (dispatch, type) => {
    let data;
    switch (type) {
      case 'OT_AOO':
        data = await getMarketColumnArr('ACCOUNT_OPENORDER_OTB');
        dispatch({ type: 'win_OT_AOO', payload: data });
        break;
      case 'OT_OMO':
        data = await getMarketColumnArr('ACCOUNT_MANUALOPENORDER_OTB');
        dispatch({ type: 'win_OT_OMO', payload: data });
        break;
      case 'OT_T':
        data = await getMarketColumnArr('ACCOUNT_TRADEBOOK_OTB');
        dispatch({ type: 'win_OT_T', payload: data });
        break;
      case 'SA_O':
        data = await getMarketColumnArr('RULE_OPENORDER_OTB');
        dispatch({ type: 'win_SA_O', payload: data });
        break;
      case 'SA_T':
        data = await getMarketColumnArr('RULE_TRADEBOOK_OTB');
        dispatch({ type: 'win_SA_T', payload: data });
        break;
      case 'risk_monitoring':
        data = await getMarketColumnArr(`${process.env.REACT_APP_IS_HFT == 'true' ? 'RISK_MONITORING_POST_TRADE' : 'RISK_MONITORING_POST_TRADE_NON_HFT'}`);
        dispatch({ type: 'win_risk_monitoring', payload: data });
        break;
      case 'risk_monitoring_pre_trade':
        data = await getMarketColumnArr('HFT_RISK_MONITORING_PRE_TRADE');
        dispatch({ type: 'win_risk_monitoring_pre_trade', payload: data });
        break;
      default:
        break;
    }
  },
  update_window_OT_SA_data: async (dispatch, type, data) => {
    switch (type) {
      case 'OT_AOO':
        await setMarketColArr({
          tableName: 'ACCOUNT_OPENORDER_OTB',
          dynamicTableSeq: data,
        });
        break;
      case 'OT_OMO':
        await setMarketColArr({
          tableName: 'ACCOUNT_MANUALOPENORDER_OTB',
          dynamicTableSeq: data,
        });

        break;
      case 'OT_T':
        await setMarketColArr({
          tableName: 'ACCOUNT_TRADEBOOK_OTB',
          dynamicTableSeq: data,
        });

        break;
      case 'SA_O':
        await setMarketColArr({
          tableName: 'RULE_OPENORDER_OTB',
          dynamicTableSeq: data,
        });

        break;
      case 'SA_T':
        await setMarketColArr({
          tableName: 'RULE_TRADEBOOK_OTB',
          dynamicTableSeq: data,
        });
        break;
      case 'risk_monitoring':
        await setMarketColArr({
          tableName: `${process.env.REACT_APP_IS_HFT == 'true' ? 'RISK_MONITORING_POST_TRADE' : 'RISK_MONITORING_POST_TRADE_NON_HFT'}`,
          dynamicTableSeq: data,
        });
        break;
      case 'risk_monitoring_pre_trade':
        await setMarketColArr({
          tableName: 'HFT_RISK_MONITORING_PRE_TRADE',
          dynamicTableSeq: data,
        });
        break;
      default:
        break;
    }
    dispatch({ type: `win_${type}_wr`, payload: data });
  },
  updateNetPosition: async (dispatch, data) => {
    await setMarketColArr({
      tableName: 'NET_POSITION',
      dynamicTableSeq: data,
    });
    dispatch({ type: 'UPDATE_NET_POSITIONS', payload: data });
  },
};

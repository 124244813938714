import React, { useEffect, useRef, useState } from 'react';
import PreviewStrategy from '../../../components/strategyBuilder/create/previewStrategy/PreviewStrategy';
import EnterTemplateDetails from '../../../components/strategyBuilder/create/enterTemplateDetails/EnterTemplateDetails';
import SelectSecurities from '../../../components/strategyBuilder/create/selectSecurities/SelectSecurities';
import EntryCondition from '../../../components/strategyBuilder/create/entryCondition/EntryCondition';
import ExitCondition from '../../../components/strategyBuilder/create/exitCondition/ExitCondition';
import SubmitButtons from '../../../components/strategyBuilder/create/submitButtons/SubmitButtons';
import './StrategyBuilder.scss';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import SimpleReactValidator from 'simple-react-validator';
import { clearConstants } from '../../../actions/strategyBuilderAction';
import StrategyAnalyzerFormula from './strategyAnalyzerFormula';

const StrategyBuilder = (props) => {
  const {
    modifyData,
    setAlertModal,
    setEditModal,
    editModalSubmit,
    setEditModalSubmit,
    setSubmitMessageEdit,
    setIsSuccessDelete,
    hideApprove,
    edit = false,
  } = props;
  const validator = useRef(new SimpleReactValidator());
  validator.current.purgeFields();
  const [, setCookie] = useCookies([]);
  const [modalClear, setModalClear] = useState(false);
  const [nameDuplicate, setNameDuplicate] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [, forceUpdate] = React.useState();

  const {
    strategyBuilderReducer: { strategyTemplateForm },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    const el = document.getElementsByTagName('BODY')[0];
    el.classList.add('strategy');
    return () => {
      el.classList.remove('strategy');
      dispatch(clearConstants());
    };
  }, []);

  useEffect(() => {
    setCookie('form', strategyTemplateForm);
  }, [strategyTemplateForm]);

  return (
    <div
      className="dashboard screen_53 same-wid strategy"
      style={{ flexGrow: '1', margin: '1rem 1rem' }}
      id="strtegyBuilder"
    >
      <PreviewStrategy validator={validator} />
      <div className="strat-builder-cont">
        <EnterTemplateDetails
          validator={validator}
          formSubmit={formSubmit}
          setFormSubmit={setFormSubmit}
          modifyData={modifyData}
        />
        <SelectSecurities edit={edit} />
        <EntryCondition
          validator={validator}
          formSubmit={formSubmit}
          setFormSubmit={setFormSubmit}
          modalClear={modalClear}
          setModalClear={setModalClear}
          modifyData={modifyData}
        />
        <ExitCondition
          validator={validator}
          formSubmit={formSubmit}
          setFormSubmit={setFormSubmit}
          modifyData={modifyData}
        />
        <StrategyAnalyzerFormula />
        {!hideApprove && (
          <SubmitButtons
            validator={validator}
            forceUpdate={forceUpdate}
            formSubmit={formSubmit}
            setFormSubmit={setFormSubmit}
            modalClear={modalClear}
            setAlertModal={setAlertModal}
            setModalClear={setModalClear}
            setEditModal={setEditModal}
            editModalSubmit={editModalSubmit}
            setEditModalSubmit={setEditModalSubmit}
            setSubmitMessageEdit={setSubmitMessageEdit}
            nameDuplicate={nameDuplicate}
            setIsSuccessDelete={setIsSuccessDelete}
          />
        )}
      </div>
    </div>
  );
};

export default StrategyBuilder;

import React, { useState, useEffect } from 'react';
import './styles.css';
import {
  GenButton,
  DropDown,
  SearchMultiSelect,
  InputField,
  FeedbackPopup,
} from '../../../components';
import { MakeRequest } from '../../../utils';

export default function DataInitPopup(props) {
  const { closePopup = () => {} } = props;

  const [data, setData] = useState({ strategyName: '', subStrategy: '' });
  const [options, set_options] = useState({
    baseConfig: [],
    strageyName: [],
    strategyConfig: [],
  });
  const [ori_options, set_ori_options] = useState({
    baseConfig: [],
    strategyConfig: [],
  });
  const [dispOptions, setDispOptions] = useState({
    baseConfig: [],
    strategyConfig: [],
    ori_base_conf: [],
    ori_stra_conf: [],
  });

  const [searchParam, setSearchParam] = useState({
    baseConf: '',
    strategyConf: '',
  });

  const [dispMessage, setDispMessage] = useState({ err: false, msg: '' });

  const [conMessage, setConMessage] = useState({ message: '', req: {} });
  const [yesNoPopup, setYesNoPopup] = useState({
    message: '',
    requestPacket: [],
  });

  useEffect(() => {
    let baseChange = dispOptions.baseConfig,
      strateConf = dispOptions.strategyConfig;
    if (options.baseConfig.length > 0) {
      baseChange = (searchParam.baseConf.length > 0
        ? options.baseConfig.filter((item) =>
            item.Parameter.toLowerCase().includes(
              searchParam.baseConf.toLowerCase()
            )
          )
        : options.baseConfig
      ).map((item) => (
        <div className="base-conf-cont">
          <span className="base-conf-cont-label">{item.Parameter}</span>
          <InputField
            value={item.Value ? item.Value : ''}
            orientation="h"
            type="text"
            extStyles={{ margin: '0 1vw 0 auto' }}
            onValueChange={(val) => {
              const temp = { ...options };
              const ind = temp.baseConfig.findIndex(
                (it) => it.Parameter === item.Parameter
              );

              temp.baseConfig[ind].Value = val;
              set_options(temp);
            }}
          />
        </div>
      ));
    }
    if (options.strategyConfig && options.strategyConfig.length > 0) {
      strateConf = (searchParam.strategyConf.length > 0
        ? options.strategyConfig.filter((item) =>
            item.Parameter.toLowerCase().includes(
              searchParam.strategyConf.toLowerCase()
            )
          )
        : options.strategyConfig
      ).map((item) => (
        <div className="base-conf-cont">
          <span className="base-conf-cont-label">{item.Parameter}</span>
          <InputField
            value={item.Value ? item.Value : ''}
            orientation="h"
            type="text"
            extStyles={{ margin: '0 1vw 0 auto' }}
            onValueChange={(val) => {
              const temp = { ...options };
              const ind = temp.strategyConfig.findIndex(
                (it) => it.Parameter === item.Parameter
              );

              temp.strategyConfig[ind].Value = val;
              set_options(temp);
            }}
          />
        </div>
      ));
    }

    setDispOptions({ baseConfig: baseChange, strategyConfig: strateConf });
  }, [options, searchParam]);

  const loadData = async () => {
    const initialData = await getBaseParam();
    const oriOptions = { baseConfig: [], strategyConfig: [] };
    const tempOptions = {
      baseConfig: [],
      strageyName: [],
      strategyConfig: [],
    };
    for (let item of initialData.baseConfigParams) {
      tempOptions.baseConfig.push({ ...item });
      oriOptions.baseConfig.push({ ...item });
    }

    for (let item of initialData.subStrategyList) {
      tempOptions.strageyName.push({ ...item });
    }
    set_options(tempOptions);
    set_ori_options(oriOptions);
    if (data.strategyName) {
      await selectStrategyName(data.strategyName);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const successFunction = async (request) => {
    await loadData();
    setConMessage({ message: '' });
  };

  async function update_config() {
    const baseConfDiff = options.baseConfig.filter(
      (item, index) => item.Value !== ori_options.baseConfig[index].Value
    );
    const stratConfDiff = options.strategyConfig.filter(
      (item, index) => item.Value !== ori_options.strategyConfig[index].Value
    );

    const request = { dataInitParams: [...baseConfDiff, ...stratConfDiff] };
    const result = await updateConfig(request);

    if (result.needConfirmation) {
      setYesNoPopup({
        message: result.confirmationMessage,
        requestPacket: { ...request, isConfirmationProvided: true },
      });
      return;
    }
    setDispMessage(result);
    setConMessage({
      message: 'Config Value has been updated Successfully.',
      req: request,
    });
  }

  async function selectStrategyName(val) {
    const subStr = options.strageyName.filter(
      (item) => item.applicationName === val
    )[0];

    const straConf = await getStrategyParams(subStr.applicationId);
    const temp = [];
    for (let item of straConf) {
      temp.push({ ...item });
    }
    set_options({
      ...options,
      strategyConfig: straConf,
    });
    set_ori_options({ ...ori_options, strategyConfig: temp });
    setData({ strategyName: val, subStrategy: subStr.ssid });
  }

  return (
    <div className="datainit-container">
      <div className="datainit-header">
        <span className="datainit-header-text">Configurations</span>
      </div>
      <div className="datainit-body">
        <div className="datainit-s1">
          <DropDown
            label="Strategy Name"
            options={options.strageyName.map((item) => item.applicationName)}
            selected={data.strategyName}
            onSelect={selectStrategyName}
            extStyles={{ margin: '0 0 0 0' }}
            ddExtStyles={{ width: '35vw' }}
            orientation="v"
          />
          <DropDown
            label="Sub Strategy"
            options={[]}
            selected={data.subStrategy}
            onSelect={() => {}}
            extStyles={{ margin: '0 0 0 0' }}
            disabled={true}
            ddExtStyles={{ width: '35vw' }}
            orientation="v"
          />
        </div>
        <div className="datainit-s2">
          <SearchMultiSelect
            options={dispOptions.baseConfig}
            contStyles={{ height: '46vh', width: '35vw' }}
            label="Base Configuration"
            custom={true}
            searchCallback={(val) => {
              setSearchParam({ ...searchParam, baseConf: val });
            }}
          />
          <SearchMultiSelect
            label="Strategy Configuration"
            options={dispOptions.strategyConfig}
            contStyles={{ height: '46vh', width: '35vw' }}
            custom={true}
            searchCallback={(val) =>
              setSearchParam({ ...searchParam, strategyConf: val })
            }
          />
        </div>
      </div>
      <div className="datainit-footer">
        <GenButton
          fill={true}
          title="UPDATE"
          extStyle={{ margin: '0 1vw 0 auto', width: '8vw' }}
          clickAction={update_config}
        />
        <GenButton
          fill={false}
          title="CLOSE"
          extStyle={{ margin: '0 1vw 0 0', width: '8vw' }}
          clickAction={closePopup}
        />
      </div>
      <FeedbackPopup
        message={conMessage.message}
        type="f"
        acceptAction={() => successFunction(conMessage.req)}
        rejectAction={() => setConMessage({ message: '' })}
      />
      <FeedbackPopup
        message={yesNoPopup.message}
        acceptAction={async () => {
          const request = yesNoPopup.requestPacket;
          const result = await updateConfig(request);

          setDispMessage(result);
          setConMessage({
            message: 'Config Value has been updated Successfully.',
            req: request,
          });
          setYesNoPopup({ message: '', requestPacket: {} });
        }}
        rejectAction={() => {
          setYesNoPopup({ message: '', requestPacket: {} });
        }}
        type="a"
      />
    </div>
  );
}

async function getBaseParam() {
  try {
    const res = await MakeRequest('/admin/dataInitBaseParams').get();

    if (res.status === 200) {
      return res.data.data;
    }
    return {};
  } catch (err) {
    console.log(err);
    return {};
  }
}

async function getStrategyParams(applicationId) {
  try {
    const res = await MakeRequest(
      `/admin/dataInitStrategyParams?applicationId=${applicationId}`
    ).get();

    if (res.status === 200) {
      return res.data.data.strategyConfigParams;
    }
    return {};
  } catch (err) {
    console.log(err);
    return {};
  }
}

async function updateConfig(data) {
  try {
    const res = await MakeRequest('/admin/dataInitSubmit').post(data);

    if (res.status === 200) {
      return { err: false, msg: 'Updated !' };
    } else if (res.status === 400) {
      return {
        err: true,
        msg: res.data.code || res.data.data.message,
        needConfirmation: res.data.data.isConfirmationRequired,
        confirmationMessage: res.data.data.message,
      };
    }
    return {};
  } catch (err) {
    console.log(err);
    return {};
  }
}

import './styles.scss';

export default function Switch({
  setting = {
    label1: 'Buy',
    label2: 'Sell',
  },
  clickAction = () => {},
  selection = 'Buy',
  extStyles = {},
}) {
  return (
    <div className="Switch-SB" style={extStyles}>
      <div
        className="Switch-SB-s"
        style={{
          backgroundColor: selection === setting.label1 ? '#53bf3a' : '#fff',
          cursor: selection !== setting.label1 ? 'pointer' : 'not-allowed',
        }}
        onClick={() => clickAction(setting.label1)}
        onKeyDown={(e) => e.key === 'Enter' && clickAction(setting.label1)}
        tabIndex={0}
      >
        <span
          className="Switch-SB-s1-label"
          style={{
            color:
              selection === setting.label1 ? 'var(--white)' : 'var(--black)',
          }}
        >
          {setting.label1}
        </span>
      </div>
      <div
        className="Switch-SB-s"
        style={{
          backgroundColor: selection === setting.label2 ? '#e44125' : '#fff',
          cursor: selection !== setting.label2 ? 'pointer' : 'not-allowed',
        }}
        onClick={() => clickAction(setting.label2)}
        onKeyDown={(e) => e.key === 'Enter' && clickAction(setting.label2)}
        tabIndex={0}
      >
        <span
          className="Switch-SB-s2-label"
          style={{
            color:
              selection === setting.label2 ? 'var(--white)' : 'var(--black)',
          }}
        >
          {setting.label2}
        </span>
      </div>
    </div>
  );
}

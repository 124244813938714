import './styles.css';
export default function RemarkTable(props) {
  const {
    data = {
      heads: [],
      data: [],
    },
  } = props;
  return (
    <div className="remark-table-container">
      <table className="remark-table">
        <thead className="remark-header">
          {data.heads.map((item) => (
            <th className="remark-header-th">{item}</th>
          ))}
        </thead>
        <tbody>
          {data.data.map((item) => (
            <tr className="remark-table-tr">
              <td className="remark-table-td">{item.date}</td>
              <td className="remark-table-td">{item.userName}</td>
              <td className="remark-table-td">{item.remark}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

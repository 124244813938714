import React from "react"
import './Logout.scss';
import UiButton from "../../sharedComponents/Button";
import Hotkeys from 'react-hot-keys';
import { logout } from '../../../actions/loginAction';
import { useDispatch } from 'react-redux';

export const Logout = ({ setModal }) => {

  const dispatch = useDispatch();

  // function for remove token from the localStorage
  const handleClear = async () => {
    // Logout Action Dispatch
    let userId = localStorage.getItem('customerId');

    await dispatch(logout({ userId: userId }));
    
    localStorage.clear();
    window.location.href = '/';
  }
  // close modal on click on NO button 
  const handlesetModal = () =>{
    setModal(false)
  }
  return (
      <div className="logout-wrapper">
        <Hotkeys
          keyName="esc"
          onKeyDown={(event)=>{setModal(false)}}
          />
        <div className="nonecolor-Alertmodal">
          <div className="popup">
            <div className="create_2 lgoutmodal">
              <div className="modal-body">
                <h1>Logout</h1>
                <p>Are you sure you want to logout?</p>
                <div className="buttons text-center mt-3 all-buttons">
                  <UiButton className="btn btn-primary col-sm-3 mr-2" name="No" onClickFunction={handlesetModal}/>
                  <UiButton className="btn btn-primary col-sm-3" name="Yes" onClickFunction={() => handleClear()}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
export default Logout

import React, { useState, useEffect } from 'react';
import { AddCircle, WhiteDeleteIcon } from '../../../../assets';
import {
  DateTimePicker,
  DropDown,
  GenButton,
  InputField,
  SearchMultiSelect,
  ShowMultiSelect,
  Switch,
} from '../../../../components';
import './styles.css';
import { createDealer, modifyDealer } from './apis';
import {
  DEALER_CREATE_SUCCESS,
  DEALER_MODIFY_SUCCESS,
} from '../../../../utils/constant';
import useSwr, { mutate } from 'swr';
import { MakeRequest, convertDate } from '../../../../utils';
import { updateValue } from '../../../../utils/dotKeyFunctions';

export default function EditForm(props) {
  const {
    edit = false,
    editData = {},
    segments = {},
    setPopupData = () => {},
    updateDealerData,
    setPopup,
    setShowPopUp,
  } = props;

  const [error, set_error] = useState({ state: false, message: '' });
  const [dealerObj, setDealerObj] = useState({
    name: '',
    username: '',
    email: '',
    mobile: '',
    nnfId: '',
    giveUpStatus: true,
    status: true,
    expiry: '',
    segment: '',
    remark: '',
    segments: [{ segment: '', expiry: '', nnfId: '' }],
  });

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      setDealerObj({
        id: editData.id,
        name: editData.dealerName,
        username: editData.userName,
        email: editData.email,
        mobile: editData.mobile,
        nnfId: editData.nnfId,
        giveUpStatus: editData.giveUpStatus,
        status: editData.active,
        isRuleReloadReq: editData.isRuleReloadReq,
        expiry: convertDate(editData.expiry),
        segments: editData.segmentDetails.map((item) => {
          return {
            segment: Object.values(item.segment)[0],
            expiry: item.expiry,
            nnfId: item.nnfId,
          };
        }),
        remark: '',
      });
    }
  }, []);

  const emailRegx = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');

  async function submit_action() {
    let data = { ...dealerObj };
    data.giveUpStatus = data.giveUpStatus ? '1' : '0';
    data.status = data.status ? '1' : '0';

    if (edit) {
      data.expiry =
        typeof data.expiry === 'string'
          ? data.expiry
          : new Date(data.expiry).toISOString().slice(0, 10);

      data['segmentDetails'] = data.segments.map((item) => {
        return {
          ...item,
          segment: Object.entries(segments).filter(
            (seg) => seg[1] === item.segment
          )[0][0],
        };
      });

      data['remarks'] = dealerObj.remark;
      data['templateMapping'] = rawStrategyTemplates.map((item) => {
        return {
          id: item.id,
          isMapped: strategyTemplate.selected[item.key] ? 1 : 0,
        };
      });

      if (!dataValidator(data)) {
        return;
      }
      const res = await modifyDealer(data);
      if (res.status === 200) {
        await updateDealerData('full');
        setPopup({
          dealerId: -1,
          header: DEALER_MODIFY_SUCCESS,
          buttons: 'singleButton',
          type: 'CLOSE',
        });
        setShowPopUp(true);
        setPopupData({
          state: false,
          type: '',
          data: {},
        });
      } else {
        set_error({ state: true, message: res.data.code });
      }
    } else {
      data.remarks = data.remark;
      data['templateMapping'] = rawStrategyTemplates.map((item) => {
        return {
          id: item.id,
          isMapped: strategyTemplate.selected[item.key] ? 1 : 0,
        };
      });
      const segment_map = {};
      for (let [key, val] of Object.entries(segments)) {
        segment_map[val] = key;
      }
      data.segmentDetails = data.segments.map((item) => {
        return { ...item, segment: segment_map[item.segment] };
      });
      if (!dataValidator(data)) {
        return;
      }
      const res = await createDealer(data);

      if (res.status === 200) {
        setPopup({
          deleteId: -1,
          header: DEALER_CREATE_SUCCESS,
          buttons: 'singleButton',
          type: 'CLOSE',
        });
        setShowPopUp(true);
        setPopupData({
          state: false,
          type: '',
          data: {},
        });
        await updateDealerData('full');
      } else {
        set_error({ state: true, message: res.data.code });
      }
    }
  }

  function isAlphaNumeric(inputStr) {
    if (/[^a-zA-Z0-9]/.test(inputStr)) {
      return false;
    }
    return true;
  }

  function dataValidator(data) {
    if (!data.name) {
      set_error({
        state: true,
        message: 'Please enter the name for the dealer',
      });
      return false;
    }
    if (!data.username) {
      set_error({
        state: true,
        message: 'Please enter the username for the dealer',
      });
      return false;
    }
    if (!isAlphaNumeric(data.username)) {
      set_error({
        state: true,
        message:
          'Username should be a alphanmeric value without any spaces or special characters.',
      });
      return false;
    }
    if (!emailRegx.test(data.email)) {
      set_error({ state: true, message: 'Incorrect Email Format' });
      return false;
    }
    if (data.mobile.length !== 10) {
      set_error({ state: true, message: 'Incorrect mobile number' });
      return false;
    } else if (data.remarks.length === 0) {
      set_error({ state: true, message: 'Remark Cannot be empty' });
      return false;
    } else {
      let segments = new Set();
      for (let obj of data.segments) {
        if (!obj.segment) {
          set_error({
            state: true,
            message: `Segment is not selected / empty.`,
          });
          return false;
        }
        if (!obj.nnfId) {
          set_error({
            state: true,
            message: `NNFID for ${obj.segment} is empty / not provided.`,
          });
          return false;
        }
        if (obj.nnfId.length !== 15) {
          set_error({
            state: true,
            message: `NNFID for ${obj.segment} is incorrect.`,
          });
          return false;
        }
        if (!obj.expiry) {
          set_error({
            state: true,
            message: `Expiry for ${obj.segment} is not selected / empty.`,
          });
          return false;
        }
        let tDate = new Date(obj.expiry),
          nowDate = new Date();
        if (tDate.getTime() < nowDate.getTime()) {
          set_error({ state: true, message: 'Date needs to be in Future' });
          return false;
        }
        segments.add(obj.segment);
      }
      if (segments.size !== data.segments.length) {
        set_error({ state: true, message: 'Cannot have Duplicate Segments' });
        return false;
      }
    }
    return true;
  }

  const [
    rawStrategyTemplates,
  ] = ApiCallsForStrategyTemplate.GetStrategyTemplates();

  const [strategyTemplate, setStrategyTemplates] = useState({
    options: [],
    selected: {},
  });

  useEffect(() => {
    if (rawStrategyTemplates || Object.keys(editData).length > 0) {
      let tempTemplate = { ...strategyTemplate };
      if (Object.keys(editData).length > 0) {
        tempTemplate = updateValue(
          tempTemplate,
          'options',
          editData.templateMapping
            .sort((a, b) => b.id - a.id)
            .map((item) => item.key)
        );
        tempTemplate = updateValue(
          tempTemplate,
          'selected',
          Object.fromEntries(
            editData.templateMapping.map((item) => {
              return [item.key, item.isMapped === 1 ? true : false];
            })
          )
        );
      } else {
        tempTemplate = updateValue(
          tempTemplate,
          'options',
          rawStrategyTemplates
            .sort((a, b) => b.id - a.id)
            .map((item) => item.key)
        );
      }

      setStrategyTemplates(tempTemplate);
    }
  }, [rawStrategyTemplates, editData]);

  return (
    <div style={{ width: '60vw', height: '79vh', overflowY: 'hidden' }}>
      <div className="dealer-view-header">
        <span className="Edit-form-title">{`${
          edit ? 'Edit' : 'Create'
        } Dealer`}</span>
      </div>
      <div className="dealer-middle-content" style={{ overflowX: 'hidden' }}>
        <div className="Edit-form-s1">
          <InputField
            label="Dealer Name"
            type="text"
            placeHolder="Enter Dealer Name"
            value={dealerObj.name}
            onValueChange={(val) => {
              setDealerObj({ ...dealerObj, name: val });
            }}
            inputWidth="15vw"
          />
          <InputField
            label="User Name"
            type="text"
            placeHolder="Enter User Name"
            value={dealerObj.username}
            onValueChange={(val) =>
              setDealerObj({ ...dealerObj, username: val })
            }
            inputWidth="15vw"
          />
          <InputField
            label="Email ID"
            type="text"
            placeHolder="Enter Email ID"
            value={dealerObj.email}
            onValueChange={(val) => setDealerObj({ ...dealerObj, email: val })}
            inputWidth="15vw"
            validationFunction={(val) => {
              const tempErr = {
                state: emailRegx.test(val),
                message: !emailRegx.test(val) ? 'Incorrect email' : '',
              };
              set_error({ ...tempErr, state: !tempErr.state });
              return tempErr;
            }}
          />
        </div>
        <InputField
          label="Mobile Number"
          type="text"
          placeHolder="Enter Mobile Number"
          value={dealerObj.mobile}
          onValueChange={(val) => setDealerObj({ ...dealerObj, mobile: val })}
          inputWidth="15vw"
          validationFunction={(val) => {
            const tempErr = {
              state: val.length === 10,
              message: !(val.length === 10) ? 'Incorrect Mobile Number' : '',
            };
            set_error({ ...tempErr, state: !tempErr.state });
            return tempErr;
          }}
          extStyles={{ margin: '1vh 0 1vh 1.5vw' }}
        />
        <div className="dealer-edit-container">
          <SearchMultiSelect
            contStyles={{ width: '15vw' }}
            extStyles={{ margin: 'auto 1vw auto 1.5vw' }}
            label="Map Strategy Template"
            options={strategyTemplate.options}
            selected={strategyTemplate.selected}
            onSelect={(val, state) => {
              setStrategyTemplates({
                ...strategyTemplate,
                selected: { ...strategyTemplate.selected, [val]: state },
              });
            }}
          />
          <ShowMultiSelect
            extStyles={{ margin: '0 0 0 5vw' }}
            label="Mapped Templates"
            data={Object.keys(strategyTemplate.selected).filter(
              (val) => strategyTemplate.selected[val]
            )}
            removeFunc={(val) => {
              setStrategyTemplates({
                ...strategyTemplate,
                selected: { ...strategyTemplate.selected, [val]: false },
              });
            }}
          />
        </div>
        <div className="Edit-form-s2">
          <div className="Edit-form-s2-title">
            <span className="Edit-form-s2-txt">Dealer</span>
            {/* <Switch
              label="Is Rule Reload Required"
              state={dealerObj.isRuleReloadReq}
              onChange={(val) =>
                setDealerObj({ ...dealerObj, isRuleReloadReq: val })
              }
              extStyles={{ margin: 'auto 2vw auto 0' }}
            /> */}
            <Switch
              label="Active"
              state={dealerObj.status}
              onChange={(val) => setDealerObj({ ...dealerObj, status: val })}
              extStyles={{ margin: 'auto 2vw auto 0' }}
            />
            <Switch
              label="Give Up Enabled"
              state={dealerObj.giveUpStatus}
              onChange={(val) =>
                setDealerObj({ ...dealerObj, giveUpStatus: val })
              }
              extStyles={{ margin: 'auto 2vw auto 0px' }}
            />
          </div>
          <div className="Edit-form-s2-cont">
            {dealerObj.segments.map((item, index) => (
              <div className="ban-level-segment">
                <DropDown
                  label="Segment"
                  selected={item.segment}
                  orientation="v"
                  options={Object.values(segments)}
                  onSelect={(val) => {
                    let temp = dealerObj.segments;
                    temp[index].segment = val;
                    setDealerObj({ ...dealerObj, segments: temp });
                  }}
                  extStyles={{ margin: 'auto' }}
                  ddExtStyles={{ width: '10vw' }}
                />
                <InputField
                  label="NNF Code"
                  type="text"
                  placeHolder="Enter NNF code"
                  value={item.nnfId}
                  onValueChange={(val) => {
                    let temp = dealerObj.segments;
                    temp[index].nnfId = val;
                    setDealerObj({ ...dealerObj, segments: temp });
                  }}
                  extStyles={{ margin: '1vw' }}
                  validationFunction={(val) => {
                    const tempErr = {
                      state: val.length === 15,
                      message: val.length === 15 ? '' : 'Invalid NNF code',
                    };
                    set_error({ ...tempErr, state: !tempErr.state });
                    return tempErr;
                  }}
                  inputWidth="9.5vw"
                />
                <DateTimePicker
                  label="Dealer Expiry"
                  value={item.expiry}
                  onValueChange={(val) => {
                    let temp = dealerObj.segments;
                    temp[index].expiry = val;
                    setDealerObj({ ...dealerObj, segments: temp });
                  }}
                  extStyles={{ margin: 'auto' }}
                  validFunc={(val) => {
                    let tDate = new Date(val),
                      nowDate = new Date();
                    if (tDate.getTime() >= nowDate.getTime()) {
                      const tempErr = { state: true, message: '' };
                      set_error({ ...tempErr, state: !tempErr.state });
                      return tempErr;
                    } else {
                      const tempErr = {
                        state: false,
                        message: 'Date Should be in Future',
                      };
                      set_error({ ...tempErr, state: !tempErr.state });
                      return tempErr;
                    }
                  }}
                />
                <span
                  className="delete-segment-icon-cont"
                  onClick={() => {
                    let temp = dealerObj.segments;
                    temp = temp.filter((item, i) => i !== index);
                    setDealerObj({ ...dealerObj, segments: temp });
                  }}
                >
                  <img src={WhiteDeleteIcon} className="delete-segment-icon" />
                </span>
              </div>
            ))}
            <GenButton
              icon={AddCircle}
              title="Add segment"
              fill={false}
              clickAction={() => {
                setDealerObj({
                  ...dealerObj,
                  segments: [
                    ...dealerObj.segments,
                    { segment: '', nnfId: '', expiry: '' },
                  ],
                });
              }}
              extStyle={{ margin: '3.5vh 2vh 1.1vw' }}
            />
          </div>
        </div>
        <InputField
          label="Remarks"
          type="text"
          placeHolder="Enter Remarks"
          value={dealerObj.remark}
          onValueChange={(val) => setDealerObj({ ...dealerObj, remark: val })}
          inputWidth="55.7vw"
          extStyles={{ margin: '2vh 1.5vw 2vh 1.5vw' }}
        />
      </div>
      <div className="dealer-footer">
        {error.state && (
          <span className="dealer-footer-error">{error.message}</span>
        )}
        <GenButton
          fill={true}
          title={edit ? 'SUBMIT' : 'CREATE'}
          extStyle={{ margin: '1vh 1vh 1vh auto' }}
          clickAction={submit_action}
        />
      </div>
    </div>
  );
}

const ApiCallsForStrategyTemplate = {
  GetStrategyTemplates: () => {
    const { data, error, isLoading } = useSwr('/dealer/templates', async () => {
      const response = await MakeRequest('/dealer/templates').get();
      return response.data.data;
    });
    return [data, error, isLoading];
  },
};

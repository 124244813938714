const initialState = {
    data: false,
  }
  const ChangePassword = (state = initialState, action) => {
    switch (action.type) {
      case "CHANGE_PASS":
        return {
          ...state,
          data: action.payload,
        }
        default:
            return state
        }
      }
      
      export default ChangePassword
import MakeRequest from '../../../../utils/apiRequest';

const BanApiCalls = {
  banTableData: async () => {
    try {
      const { data, status } = await MakeRequest('/admin/banTable').get();
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return {};
    }
  },
  create_ban_data: async () => {
    try {
      const result = await MakeRequest('/admin/ban/form').get();
      if (result.status === 200) {
        return result.data.data;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  },
  get_securities: async (exchange, instrumentType) => {
    try {
      const result = await MakeRequest(
        `/admin/ban/form/security?exchange=${exchange}&instrumentType=${instrumentType}`
      ).get();
      if (result.status === 200) {
        return result.data.data;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  },
  get_expiry: async (exchange, instrumentType, symbol) => {
    try {
      let symEnc = encodeURIComponent(symbol);
      const result = await MakeRequest(
        `/admin/ban/form/security?exchange=${exchange}&instrumentType=${instrumentType}&symbol=${symEnc}`
      ).get();
      if (result.status === 200) {
        return result.data.data;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  },
  get_view_data: async (id) => {
    try {
      const { data, status } = await MakeRequest(
        `/admin/banTable?banId=${id}`
      ).get();
      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return {};
    }
  },
  create_ban: async (data) => {
    try {
      const res = await MakeRequest('/admin/ban/create').post(data);
      return res;
    } catch (error) {
      return { status: 400, message: error.message };
    }
  },
  unban_calls: async (obj) => {
    try {
      const { data, status } = await MakeRequest(
        `/admin/ban/familyDealer`
      ).post(obj);
      if (status === 200) {
        return data.data;
      } else {
        return data;
      }
    } catch (error) {
      return {};
    }
  },
  delete_api_call: async (id) => {
    try {
      const res = await MakeRequest(`/admin/ban/delete/${id}`).get();
      if (res.status === 200) {
        return res.data.data;
      } else {
        return res.data;
      }
    } catch (error) {
      return null; // Currently error not display for dealer case
      // return error.response;
    }
  },
  edit_ban_api: async (obj) => {
    try {
      const res = await MakeRequest(`/admin/ban/edit`).post(obj);
      if (res.status === 200) {
        return res;
      } else {
        return res.data;
      }
    } catch (error) {
      return error.response;
    }
  },
};

export default BanApiCalls;

import styled from 'styled-components';
import '../../../../global.css';

const SettingsContainer = styled.div`
  height: 5vh;
  width: 5vh;
  border-radius: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  border: 1px solid #00000000;

  &:hover {
    border: 1px solid #000000;
    transition: all 0.5s;
  }

  &:focus {
    border: 1px solid orange;
  }
`;

const SettingsIcons = styled.img`
  height: 2.6vh;
  width: 2.6vh;
`;

const ParentComp = styled.div`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 6vh;
  height: 6vh;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ChildComp = styled.div`
  height: 5vh;
  width: 90%;
  border: 1px solid #cecece;
  margin: 0.5vh auto 0.5vh auto;
  border-radius: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ParentLeftIcon = styled.img`
  height: 2.5vh;
  width: 2.5vh;
  margin: auto;
`;

const ParentCenterText = styled.span`
  font-family: var(--def-font);
  font-size: 2vh;
  margin: auto auto auto 0vw;
`;

const ManageFormCont = styled.div`
  position: absolute;
  top: 6vh;
  right: 0;
  background-color: white;
  z-index: 999;
  width: 20vw;
  height: 50vh;
  border-radius: 1vh;
  overflow: auto;
  border: 1px solid #cecece;
  ${(props) => props.extStyles}
`;

export {
  ManageFormCont,
  ParentComp,
  ChildComp,
  ParentLeftIcon,
  ParentCenterText,
  SettingsContainer,
  SettingsIcons,
};

import React, { useState, useEffect } from 'react';
import { GenButton, ViewDisplayTable } from '../../../../components';
import './styles.css';
import { EditSolid } from '../../../../assets';

export default function ViewDealer(props) {
  const { data, nav, edit = false } = props;
  const [disp_tables, set_disp_tables] = useState({
    dealerDetail: [],
    segmentDetail: [],
    status: [],
    remark: [],
  });

  useEffect(() => {
    const dealerDetail = [
      prepareEntry('Dealer Name', data.dealerName),
      prepareEntry('Email ID', data.email),
      prepareEntry('Mobile No', data.mobile),
      prepareEntry('User Name', data.userName),
    ];
    const segmentDetail = [];
    for (let segment of data.segmentDetails) {
      segmentDetail.push(
        prepareEntry('Segment', Object.values(segment.segment)[0])
      );
      segmentDetail.push(prepareEntry('NNF Code', segment.nnfId));
      segmentDetail.push(prepareEntry('Expiry', segment.expiry));
    }
    const status = [];
    status.push(prepareEntry('Active', data.active ? 'Yes' : 'No'));
    status.push(
      prepareEntry('Give Up Enabled', data.giveUpStatus ? 'Yes' : 'No')
    );
    status.push(
      prepareEntry(
        'Is Rule Reload Required',
        data.isRuleReloadReq ? 'Yes' : 'No'
      )
    );
    const remark = [
      <span className="dealer-view-data-remark">
        {data.remarks && data.remarks.length > 0 && data.remarks[0].remarks}
      </span>,
    ];
    set_disp_tables({
      ...disp_tables,
      dealerDetail,
      segmentDetail,
      status,
      remark,
    });
  }, []);

  function prepareEntry(key, value) {
    return (
      <div className="dealer-view-td-cont">
        <span className="dealer-view-td-key">{key}</span>
        <span className="dealer-view-td-value">{value}</span>
      </div>
    );
  }

  return (
    <div style={{ width: '70vw', height: '80vh' }}>
      <div className="dealer-view-header">
        <span className="Edit-form-title">View Dealer</span>
        <GenButton
          fill={false}
          title="Edit"
          icon={EditSolid}
          extStyle={{ margin: '1vh 1vh 1vh auto' }}
          clickAction={nav}
          disabled={!edit}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '72vh',
          overflowY: 'scroll',
        }}
      >
        <ViewDisplayTable
          title="Dealer Details"
          content={disp_tables.dealerDetail}
          columns={2}
          extStyles={{ margin: '2vh' }}
        />
        <ViewDisplayTable
          title="Segment Details"
          content={disp_tables.segmentDetail}
          columns={3}
          extStyles={{ margin: '2vh' }}
        />
        <ViewDisplayTable
          title="Status"
          content={disp_tables.status}
          columns={3}
          extStyles={{ margin: '2vh' }}
        />
        <ViewDisplayTable
          title="Remarks"
          content={disp_tables.remark}
          columns={1}
          extStyles={{ margin: '2vh' }}
        />
      </div>
    </div>
  );
}

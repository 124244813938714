import React, { useRef, useEffect, useState } from 'react';
import { ManageFormCont } from './components';
import styled from 'styled-components';
import {
  AddIconGrey,
  BlackArrow,
  Copy_role_Icon,
  DeleteBlueStroke,
  Edit_role_Icon,
  TempSortIcon,
} from '../../../../assets';
import '../../../../global.css';
import { DropDown, GenSearchBox, TabNavInner } from '../../..';
import { useDispatch, useSelector } from 'react-redux';
import { CreateFormulaActions } from '../../../../reducers/createFormulaForm';
import ManageFormulaAPI from './MWFormulaAPIs';
import { fetchValue, updateValue } from '../../../../utils/dotKeyFunctions';

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BackImage = styled.img`
  height: 3vh;
  width: 3vh;
  cursor: pointer;
  margin: 0.5vh auto 0.5vh 1vw;
`;
const Title = styled.span`
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  white-space: nowrap;
  margin-left: 1vw;
`;
const ButtonAdd = styled.div`
  border: 1px solid #cecece;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  border-radius: 0.5vh;
  margin: 0.5vh 1vw 0.5vh auto;
  height: 4vh;
  width: 6vw;
`;
const AddIcon = styled.img`
  height: 2vh;
  width: 2vh;
`;
const AddText = styled.span`
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  white-space: nowrap;
`;
const SecondCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export default function ManageFormula({
  setOpen,
  clickAction,
  extraStyles = {},
  backDisabled = false,
}) {
  const contRef = useRef(null);
  const dispatch = useDispatch();
  const createFormulaPopup = useSelector(
    (state) => state.CreateFormulaFormPopup
  );
  const creFormAction = new CreateFormulaActions(dispatch);

  const [sortSelect, setSortSelect] = useState({
    icon: TempSortIcon,
    options: ['Latest', 'Alphabetical', 'Last Modified'],
    selected: 'Latest',
    extStyles: { margin: '0 1vw 0 0' },
    visible: false,
  });
  const [listAread, setListarea] = useState({
    options: [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Draft' },
    ],
    seletedOpt: 0,
    activeContent: [{ title: 'Loading Values', id: 0 }],
    draftContent: [{ title: 'loading values', id: 0 }],
  });

  // * Populate all formulas upon page change
  useEffect(() => {
    const ApiCall = async () => {
      const data = await ManageFormulaAPI(
        'GET_ALL_FORMULA',
        createFormulaPopup.formData.pageNumber
      );
      const dataArray = fetchValue(data, 'data.data.data');
      const active = [],
        draft = [];
      dataArray.forEach((element) => {
        if (element.stateEnum === 1) {
          active.push({ title: element.name, id: element.id });
        } else if (element.stateEnum === 2) {
          draft.push({ title: element.name, id: element.id });
        }
      });
      let state = { ...listAread };
      state = updateValue(state, 'activeContent', active);
      state = updateValue(state, 'draftContent', draft);
      setListarea(state);
      console.log(data);
    };
    ApiCall();
  }, [createFormulaPopup.formData.pageNumber]);

  useEffect(() => {
    const clickOutside = (e) => {
      if (contRef.current && !contRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [contRef]);

  return (
    <ManageFormCont ref={contRef} extStyles={extraStyles}>
      <TopContainer>
        {!backDisabled && (
          <BackImage src={BlackArrow} onClick={() => clickAction('B')} />
        )}
        <Title>Manage Formula</Title>
        <ButtonAdd onClick={() => creFormAction.openPopup()}>
          <AddIcon src={AddIconGrey} />
          <AddText>Add More</AddText>
        </ButtonAdd>
      </TopContainer>
      <SecondCont>
        <GenSearchBox
          version={2}
          extStyles={{ margin: '0.5vh auto 0.5vh 1vw', width: '12vw' }}
        />
        <DropDown settings={sortSelect} settingModifier={setSortSelect} />
      </SecondCont>
      <ActiveDraftLists settings={listAread} setSettings={setListarea} pageNumber={createFormulaPopup.formData.pageNumber} />
    </ManageFormCont>
  );
}

const ActiDrafCont = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActiveList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActiveOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6vh;
  ${(props) => props.borderBottom && 'border-bottom: 1px solid #cecece;'}

  &:hover {
    background-color: #cecece33;
  }
  &:hover > img.actionIcon {
    visibility: visible;
  }
`;

const ActiveOptionText = styled.span`
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  white-space: nowrap;
  margin-left: 0.5vw;
`;

const ActionIcon = styled.img`
  height: 3vh;
  width: 3vh;
  visibility: hidden;
  cursor: pointer;
  ${(props) => props.styles}
`;

function ActiveDraftLists({
  settings = {
    options: [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Draft' },
    ],
    seletedOpt: 0,
    activeContent: [
      { title: 'Active Title', id: 0 },
      { title: 'Active Title', id: 1 },
      { title: 'Active Title', id: 2 },
      { title: 'Active Title', id: 3 },
    ],
    draftContent: [
      { title: 'Draft Title', id: 0 },
      { title: 'Draft Title', id: 1 },
      { title: 'Draft Title', id: 2 },
      { title: 'Draft Title', id: 3 },
    ],
  },
  setSettings = () => {},
  pageNumber = 1
}) {
  const dispatch = useDispatch();
  const reduxAction = new CreateFormulaActions(dispatch);
  return (
    <ActiDrafCont>
      <TabNavInner
        tabs={settings.options}
        curSelect={settings.seletedOpt}
        setSelect={(val) => setSettings({ ...settings, seletedOpt: val })}
      />
      <ActiveList>
        {(settings.seletedOpt === 0
          ? settings.activeContent
          : settings.draftContent
        ).map((item, index) => (
          <ActiveOption
            borderBottom={
              index !==
              (settings.seletedOpt === 0
                ? settings.activeContent
                : settings.draftContent
              ).length -
                1
            }
          >
            <ActiveOptionText>{item.title}</ActiveOptionText>
            <ActionIcon
              className="actionIcon"
              src={Copy_role_Icon}
              styles={{ margin: '0vw 0.5vw 0vw auto' }}
              onClick={() => reduxAction.showCopyFormula(item.id)}
            />
            <ActionIcon
              className="actionIcon"
              src={Edit_role_Icon}
              styles={{ margin: '0vw 0.5vw 0vw 0.5vw' }}
              onClick={() => reduxAction.showEditFormula(item.id)}
            />
            <ActionIcon
              className="actionIcon"
              src={DeleteBlueStroke}
              styles={{ margin: '0vw 0.5vw 0vw 0.5vw' }}
              onClick={() => reduxAction.deleteFormula(item.id, pageNumber)}
            />
          </ActiveOption>
        ))}
      </ActiveList>
    </ActiDrafCont>
  );
}

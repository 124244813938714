const initialState = {
  webData: [],
  marketData: {},
  fixedMarketData: {},
  searchSecurityName: '',
  updateNameLoader: false,
  allsecurites: [],
  searchSecurities: [],
  securityLoaded: true,
  risks: [],
  updateName: [],
  submitstrategy: [],
  accntName: [],
  pagename: '',
  liveData: [],
  dropdown: [],
  sortingType: '',
  sortColumn: '',
  templateID: '',
  securityNameOptions: [],
  orderBookloaded: false,
  orderTradeData: null,
  netPositionLoaded: false,
  netPositionData: {
    1: [],
    2: [],
    3: [],
  },
  netPositionLength: 50,
  expiresOptions: [[]],
  strikeOptions: [],
  showRule: [],
  pricingLogic: [],
  strategyFetched: false,
  postPerPage: 5,
  pageNumber: 1,
  orderDrillData: null,
  drillLoaded: true,
  ruleOBPostPerPage: 5,
  ruleOBPageNumber: 1,
  ruleOBOrderDrillData: null,
  ruleOBDrillLoaded: true,
  marketSecurity: {
    exchange: '',
    instrument: '',
    symbol: '',
    expiry: '',
    strike: '',
  },
  strategy: {
    name: '',
    description: '',
    exchange: '',
    account: '',
    securityFilter: 'ALL',
    isWrittenEquation: false,
    securities: [
      {
        buySell: '',
        tradingAccountId: '',
        instrument: '',
        securityName: '',
        expiry: '',
        strike: '',
        nickname: '',
        isAdditional: false,
        lotSize: '',
      },
    ],
    constants: [],
    entryLegs: [
      {
        legId: 1,
        condition: [
          {
            type: 'OPERAND',
            trigger: '',
          },
        ],
        position: [
          {
            orientation: 'Fwd',
            direction: 'BUY',
            securityIdx: '',
            security: '',
            pricing: [],
            quantity: [],
            totalQuantityLogic: [],
            orderQty: '',
            totalQty: '',
            lotSize: [],
          },
        ],
        algoExecution: [],
      },
    ],
    exitCondition: {
      condition: [
        {
          type: 'OPERAND',
          trigger: '',
        },
      ],
      action: '',
    },
    isRuleReloadReq: false,
    ssid: '',
  },
};

const liveTradingReducer = (state = initialState, action) => {
  const blueShade = '#067BD8';
  const redShade = '#EA4242';
  let selectedRow = {};

  switch (action.type) {
    case 'MARKET_WATCH':
      return {
        ...state,
        webData: action.payload,
      };
    case 'MARKET_WATCH_UPDATE':
      // Color Logic
      if (state.marketData[action.payload.security]) {
        selectedRow = action.payload;
        // Color Logic
        if (selectedRow.ltpx < state.marketData[action.payload.security].ltpx) {
          selectedRow.ltpxColor = redShade;
        } else if (
          selectedRow.ltpx > state.marketData[action.payload.security].ltpx
        ) {
          selectedRow.ltpxColor = blueShade;
        } else {
          selectedRow.ltpxColor =
            state.marketData[action.payload.security].ltpxColor;
        }
        if (selectedRow.ltq < state.marketData[action.payload.security].ltq) {
          selectedRow.ltqColor = redShade;
        } else if (
          selectedRow.ltq > state.marketData[action.payload.security].ltq
        ) {
          selectedRow.ltqColor = blueShade;
        } else {
          selectedRow.ltqColor =
            state.marketData[action.payload.security].ltqColor;
        }
        if (
          selectedRow.bidPrice <
          state.marketData[action.payload.security].bidPrice
        ) {
          selectedRow.bidPriceColor = redShade;
        } else if (
          selectedRow.bidPrice >
          state.marketData[action.payload.security].bidPrice
        ) {
          selectedRow.bidPriceColor = blueShade;
        } else {
          selectedRow.bidPriceColor =
            state.marketData[action.payload.security].bidPriceColor;
        }
        if (
          selectedRow.askPrice <
          state.marketData[action.payload.security].askPrice
        ) {
          selectedRow.askPriceColor = redShade;
        } else if (
          selectedRow.askPrice >
          state.marketData[action.payload.security].askPrice
        ) {
          selectedRow.askPriceColor = blueShade;
        } else {
          selectedRow.askPriceColor =
            state.marketData[action.payload.security].askPriceColor;
        }
        if (
          selectedRow.bidQty < state.marketData[action.payload.security].bidQty
        ) {
          selectedRow.bidQtyColor = redShade;
        } else if (
          selectedRow.bidQty > state.marketData[action.payload.security].bidQty
        ) {
          selectedRow.bidQtyColor = blueShade;
        } else {
          selectedRow.bidQtyColor =
            state.marketData[action.payload.security].bidQtyColor;
        }
        if (
          selectedRow.askQty < state.marketData[action.payload.security].askQty
        ) {
          selectedRow.askQtyColor = redShade;
        } else if (
          selectedRow.askQty > state.marketData[action.payload.security].askQty
        ) {
          selectedRow.askQtyColor = blueShade;
        } else {
          selectedRow.askQtyColor =
            state.marketData[action.payload.security].askQtyColor;
        }
      } else {
        selectedRow = action.payload;
        selectedRow.ltpxColor = blueShade;
        selectedRow.ltqColor = blueShade;
        selectedRow.bidPriceColor = blueShade;
        selectedRow.askPriceColor = blueShade;
        selectedRow.bidQtyColor = blueShade;
        selectedRow.askQtyColor = blueShade;
      }
      return {
        ...state,
        marketData: {
          ...state.marketData,
          [action.payload.security]: selectedRow,
        },
      };
    case 'MARKET_WATCH_CLEAN':
      return {
        ...state,
        marketData: {},
      };
    case 'FIXED_MARKET_WATCH_UPDATE':
      return {
        ...state,
        fixedMarketData: {
          ...state.fixedMarketData,
          [action.payload.dataFeedTicker]: action.payload,
        },
      };
    case 'UPDATE_SORT_COLUMN':
      return {
        ...state,
        sortingType: action.payload.sortingType,
        sortColumn: action.payload.sortColumn,
      };
    case 'GET_AllSECURITIES':
      let newSecurity =
        action.offset !== 0
          ? [...state.searchSecurities, ...action.searchSecurities]
          : [...action.searchSecurities];
      return {
        ...state,
        searchSecurities: newSecurity,
        searchSecurityName: action.searchSecurityName,
      };
    case 'REMOVE_AllSECURITIES':
      return {
        ...state,
        searchSecurities: [],
        searchSecurityName: action.searchSecurityName,
      };
    case 'SECURITY_LOADED':
      return {
        ...state,
        securityLoaded: action.loader,
      };
    case 'UPDATE_STRATEGY_LIVE_FORM':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'CREATE_STRATEGY':
      let newTest = action.import;
      let newSecurities = [...newTest.securities];
      let entryLegs = newTest.entryLegs?.map((i) => {
        return {
          ...i,
          position: i.position?.map((j) => {
            return {
              orderQty: j.orderQty ? j.orderQty : '',
              totalQty: j.totalQty ? j.totalQty : '',
              pendingQty: j.pendingQty ? j.pendingQty : '',
              tradedQty: j.tradedQty ? j.tradedQty : '',
              direction: j.direction,
              pricing: j.pricing,
              orientation: j.orientation,
              totalQuantityLogic: j.totalQuantityLogic,
              quantity: j.quantity,
              security: j.security,
              securityIdx: j.securityIdx >= 0 ? j.securityIdx : '',
            };
          }),
        };
      });
      newTest['securities'] = newSecurities;
      newTest['entryLegs'] = entryLegs;
      newTest['strategyTemplateId'] = action?.import?.strategyTemplateId ? action?.import?.strategyTemplateId : action.ID;
      // newTest['tradingAccountId'] = action.import.tradingAccountId; //! Account Added at Security Level
      newTest['isRuleReloadReq'] = action.import.isRuleReloadReq;

      return {
        ...state,
        strategy: newTest,
        templateID: newTest['strategyTemplateId'],
        strategyFetched: action.fetched,
      };
    case 'CREATE_DROPDOWN':
      return {
        ...state,
        dropdown: action.payload,
      };
    case 'GET_RISK_PARAMS':
      return {
        ...state,
        risks: action.payload,
      };
    case 'SET_SELECTED_LIVEDATA':
      return {
        ...state,
        liveData: action.payload,
      };
    case 'GET_NAME':
      return {
        ...state,
        pagename: action.payload,
      };
    case 'UPDATE_NAME':
      return {
        ...state,
        updateName: action.payload,
        ID: action.ID,
      };
    case 'UPDATE_NAME_LOADER':
      return {
        ...state,
        updateNameLoader: action.payload,
      };
    case 'GET_TOTALQTY':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'SUBMIT_STRATEGY':
      return {
        ...state,
        submitstrategy: action.payload,
        ID: action.ID,
      };
    case 'GET_ACCOUNTNAME':
      let newForm = state.strategy;
      newForm['userId'] = action.payload.id;
      return {
        ...state,
        accntName: action.payload,
        strategy: newForm,
      };
    case 'UPDATE_CREATE_STRATEGY_FORM':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'RESET_CREATESTRATEGY':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'UPDATE_RULE':
      return {
        ...state,
        showRule: action.payload,
      };
    case 'GET_PRICING':
      let priceTest = action.payload;
      let pricingArr = [];
      pricingArr = priceTest.indicators?.map((el) => {
        return {
          type: 'pricing',
          enumName: el.enumName,
          name: el.displayName,
          data: [],
          args: el.args,
        };
      });

      return {
        ...state,
        pricingLogic: pricingArr,
      };
    case 'UPDATE_STRATEGY_PRICE':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'UPDATE_STRATEGY_DIRECTION':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'SAVE_AS_DRAFT_STRATEGY':
      return {
        ...state,
        strategy: action.payload,
      };
    case 'GET_ORDER':
      return {
        ...state,
        orderTradeData: action.payload,
        orderBookloaded: action.orderBookloaded,
      };
    case 'GET_NET_POSITION':
      if (action && action.payload)
        return {
          ...state,
          netPositionData: {
            ...action.payload,
          },
          netPositionLoaded: action.netPositionLoaded,
          netPositionLength:
            action.netPositionLoaded && Array.isArray(action.netPositionLoaded)
              ? action.netPositionLoaded.length
              : 0,
        };
      else
        return {
          ...state,
        };
    case 'UPDATE_NET_POSITION':
      return {
        ...state,
        netPositionData: {
          ...action.payload,
        },
      };
    case 'UPDATE_POST_PER_PAGE':
      return {
        ...state,
        postPerPage: action.payload,
      };
    case 'UPDATE_PAGENUMBER_ORDER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'GET_ORDER_DRILLDOWN':
      return {
        ...state,
        orderDrillData: action.payload,
        drillLoaded: action.loader,
      };
    case 'RULE_OB_UPDATE_POST_PER_PAGE':
      return {
        ...state,
        ruleOBPostPerPage: action.payload,
      };
    case 'RULE_OB_UPDATE_PAGENUMBER_ORDER':
      return {
        ...state,
        ruleOBPageNumber: action.payload,
      };
    case 'RULE_OB_GET_ORDER_DRILLDOWN':
      return {
        ...state,
        ruleOBOrderDrillData: action.payload,
        ruleOBDrillLoaded: action.loader,
      };
    case 'GET_MARKET_SECURITY':
      return {
        ...state,
        marketSecurity: action.payload,
      };
    case 'UPDATE_BUYSELL_AT_INDEX':
      let tempState = { ...state };
      const { index, value } = action.payload;

      tempState.strategy.securities[index].buySell = value;
      console.log(
        `state updated ${index} -> ${tempState.strategy.securities[index].buySell}`
      );
      return tempState;
    case 'UPDATE_SECURITY_TO_POSITION':
      const tempStat = { ...state };
      tempStat.strategy.entryLegs[0].position = action.payload;
      for (let i = 0; i < tempStat.strategy.entryLegs.length; i++) {
        tempStat.strategy.entryLegs[i].position = [action.payload[i]];
      }
      return tempStat;
    case 'UPDATE_NICKNAMES_TO_SECURITY':
      const tempStateSecurities = { ...state };
      tempStateSecurities.strategy.securities = action.payload;
      return tempStateSecurities;
    case 'UPDATE_AUTOFILL_AT_INDEX':
      const tempState1 = { ...state };
      let securities = tempState1.strategy.securities;
      for (let i = 0; i < securities.length; i++) {
        const security = securities[i];
        switch (security.buySellConstraint) {
          case 'Same in All Legs':
            securities[i].buySell = securities[0].buySell;
            break;
          case 'Alternate Same':
            if (i > 0) {
              securities[i].buySell =
                securities[i - 1].buySell === 'Buy' ? 'Sell' : 'Buy';
            }
            break;
        }
        switch (security.instrumentConstraint) {
          case 'Same in All Legs':
            securities[i].instrument = securities[0].instrument;
            break;
          case 'Alternative (PE/CE)':
            if (i > 0) {
              securities[i].instrument =
                securities[i - 1].instrument === 'Call_Option'
                  ? 'Put_Option'
                  : 'Call_Option';
            }
            break;
        }
        switch (security.symbolConstraint) {
          case 'Same in All Legs':
            securities[i].securityName = securities[0].securityName;
            break;
        }
        if (security.instrument !== 'Equity') {
          switch (security.expiryConstraint) {
            case 'Same in All Legs':
              securities[i].expiry = securities[0].expiry;
              break;
            case 'Alternate Same':
              if (i > 1) {
                securities[i].expiry = securities[i - 2].expiry;
              }
              break;
          }
        } else {
          securities[i].expiry = null;
        }

        if (
          security.instrument !== 'Future' &&
          security.instrument !== 'Equity'
        ) {
          switch (security.strikeConstraint) {
            case 'Same in All Legs':
              securities[i].strike = securities[0].strike;
              break;
            case 'Alternate Same':
              if (i > 1) {
                securities[i].strike = securities[i - 2].strike;
              }
              break;
            case 'Arithmetic Progression':
              if (i > 1) {
                const difference =
                  parseInt(securities[1].strike) -
                  parseInt(securities[0].strike);
                securities[i].strike = (
                  parseInt(securities[i - 1].strike) + difference
                ).toString();
              }
              break;
          }
        } else {
          securities[i].strike = null;
        }
      }
      tempState1.strategy.securities = securities;
      for (let leg = 0; leg < tempState1.strategy.entryLegs.length; leg++) {
        const tempPositions1 = [...tempState1.strategy.entryLegs[leg].position];
        for (let pos = 0; pos < tempPositions1.length; pos++) {
          tempPositions1[pos].security =
            securities[tempPositions1[pos].securityIdx]?.nickname;
        }
        tempState1.strategy.entryLegs[leg].position = tempPositions1;
      }
      return tempState1;
    default:
      return state;
  }
};
export default liveTradingReducer;

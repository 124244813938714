import {
  GET_ALERTDATA,
  GET_ALL_ALERTDATA,
  POST_APPROVAL,
  SET_GROUP_ID,
  UPDATE_TEMPLATE_ID,
  SET_GROUP_DATA_ARRAY,
  SET_APPROVE_REJECT,
  SET_SELECTED_ALERTS,
  SET_ALERT_EXPAND,
  SET_ALERT_EXPAND_NEW,
  SET_ALERT_EXPAND_GROUP,
  UPDATE_IN_TEMPLATE,
  UPDATE_ALERT_TABLE,
  UPDATE_ALERT_LIVE_DATA,
} from '../actions/types';

const initialState = {
  alertTableData: [],
  allAlertsData: [],
  approve: [],
  groupIds: [],
  templateId: '',
  groupedArr: [],
  isApproveReject: false,
  selectedIds: [],
  isExpand: [],
  isExpandNew: {},
  isGroupExpand: [],
  inTemplate: false,
  alertTable: [],
  alertLiveData: {},
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALERTDATA:
      return {
        ...state,
        alertTableData: action.payload,
      };
    case SET_GROUP_DATA_ARRAY:
      return {
        ...state,
        groupedArr: action.payload,
      };
    case GET_ALL_ALERTDATA:
      return {
        ...state,
        allAlertsData: action.payload,
      };
    case POST_APPROVAL:
      return {
        ...state,
        approve: action.payload,
      };
    case UPDATE_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload,
      };

    case SET_GROUP_ID:
      return {
        ...state,
        groupIds: action.payload,
      };
    case SET_APPROVE_REJECT:
      return {
        ...state,
        isApproveReject: action.payload,
      };
    case SET_SELECTED_ALERTS:
      return {
        ...state,
        selectedIds: action.payload,
      };
    case SET_ALERT_EXPAND:
      return {
        ...state,
        isExpand: action.payload,
      };
    case SET_ALERT_EXPAND_NEW:
      return {
        ...state,
        isExpandNew: action.payload,
      };
    case SET_ALERT_EXPAND_GROUP:
      return {
        ...state,
        isGroupExpand: action.payload,
      };
    case UPDATE_IN_TEMPLATE:
      return {
        ...state,
        inTemplate: action.payload,
      };
    case UPDATE_ALERT_TABLE:
      return {
        ...state,
        alertTable: action.payload,
      };
    case UPDATE_ALERT_LIVE_DATA:
      return {
        ...state,
        alertLiveData: action.payload,
      };
    default:
      return state;
  }
};

export default alertReducer;

import './GenericSwitch.css';
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checked from '../../../assets/img/checked.svg';
import Unchecked from '../../../assets/img/unchecked.svg';

const GenericSwitch = (props) => {
  const { title, clickAction, obj, state, extStyle = {} } = props;
  const [checkedState, setCheckedState] = useState(state);

  const handleChange = () => {
    if (state !== undefined) {
      clickAction(obj, !state);
    } else {
      clickAction(obj, !checkedState);
      setCheckedState(!checkedState);
    }
  };

  const handleEnter = (e, callBackFunc = () => {}) => {
    var keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode == '13') {
      callBackFunc();
    }
  };

  return (
    <>
      {props['switchBox'] && (
        <div className="switchContainer2">
          <FormControlLabel
            control={<Switch checked={state} onChange={handleChange} />}
            label={title}
          />
        </div>
      )}
      {props.checkBox && (
        <div
          className="GenericSwitch-container"
          style={extStyle}
          onClick={handleChange}
          onKeyPress={(event) =>
            handleEnter(event, () => {
              handleChange();
            })
          }
          tabIndex="0"
        >
          <img
            src={state ? Checked : Unchecked}
            className="GenericSwitch-icon"
            alt="switchicon"
          />
          <span className="font-l1" style={{ margin: '0rem 0.5rem' }}>
            {title}
          </span>
        </div>
      )}
    </>
  );
};

export default GenericSwitch;

import React from 'react';
const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      {totalPosts <= 8 ? null : (
        <ul className="pagination">
          {pageNumbers.map((number) => {
            return (
              <li key={number} className="page-item">
                <a
                  onClick={() => paginate(number)}
                  className={`${currentPage === number ? 'active' : ''}`}
                >
                  {number}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};

export default Pagination;

import axios from 'axios';
import { url } from '../config/apiConfig';
import { getManualOrderExpiresOptions } from './strategyAnalyzerAction';
import { MakeRequest } from '../utils';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const liveTradingData = (data) => async (dispatch) => {
  dispatch({
    type: 'MARKET_WATCH',
    payload: data,
  });
};
export const updateSortCulmn = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_SORT_COLUMN',
    payload: data,
  });
};

export const updateMarketData = (data) => async (dispatch) => {
  dispatch({
    type: 'MARKET_WATCH_UPDATE',
    payload: data,
  });
};

export const cleanMarketData = () => async (dispatch) => {
  dispatch({
    type: 'MARKET_WATCH_CLEAN',
    payload: {},
  });
};

export const updateFixedMarketData = (data) => async (dispatch) => {
  dispatch({
    type: 'FIXED_MARKET_WATCH_UPDATE',
    payload: data,
  });
};

export const getAllSecurities = (data, newData) => async (dispatch) => {
  const token = localStorage.getItem('token');
  dispatch({
    type: 'SECURITY_LOADED',
    loader: false,
  });
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/getAllSecuritiesV2`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data,
    });

    if (result.data.code === 'SUCCESS') {
      dispatch({
        type: 'GET_AllSECURITIES',
        searchSecurities: result.data.data,
        searchSecurityName: data.searchString,
        offset: data.offset,
      });
      dispatch({
        type: 'SECURITY_LOADED',
        loader: true,
      });
    } else {
      dispatch({
        type: 'REMOVE_AllSECURITIES',
        searchSecurities: [],
        searchSecurityName: '',
      });
      dispatch({
        type: 'SECURITY_LOADED',
        loader: true,
      });
    }
  } catch (error) {
    dispatch({
      type: 'REMOVE_AllSECURITIES',
      searchSecurities: [],
      searchSecurityName: '',
    });
    dispatch({
      type: 'SECURITY_LOADED',
      loader: true,
    });
  }
};

//create strategy dropdown
export const createDropdown = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/strategy/returnForUser`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      if (result.data.data) {
        dispatch({
          type: 'CREATE_DROPDOWN',
          payload: result.data.data,
        });
      } else {
        dispatch({
          type: 'CREATE_DROPDOWN',
          import: '',
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'CREATE_DROPDOWN',
      payload: [],
    });
  }
};

//strategyTemplateId

export const getStrategySecurityNameOptions =
  (data, isInitialCall = false) =>
  async (dispatch, getState) => {
    let { strategy } = getState().liveTradingReducer;
    let templateForm = { ...strategy };
    const token = localStorage.getItem('token');
    templateForm.securities[data.index][data.key] = data.instrument;

    if (data.instrument === 'Equity') {
      templateForm.securities[data.index].expiry = '';
      templateForm.securities[data.index].strike = '';
      templateForm.securities[data.index].lotSize = 1;
    } else {
      try {
        if (!data.isEdit) {
          const result = await MakeRequest('/security/expires', true, true).get(
            {
              strategyTemplateId: templateForm.strategyTemplateId,
              exchange: templateForm.exchange.trim(),
              instrument: templateForm.securities[data.index].instrument,
              security: templateForm.securities[data.index].securityName,
            }
          );
          // const result = await axios({
          //   method: 'GET',
          //   url: `${url}/security/expires`,
          //   params: {
          //     strategyTemplateId: templateForm.strategyTemplateId,
          //     exchange: templateForm.exchange.trim(),
          //     instrument: templateForm.securities[data.index].instrument,
          //     security: templateForm.securities[data.index].securityName,
          //   },
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //   },
          // });
          if (result) {
            dispatch({
              type: 'GET_EXPIRES',
              payload: result.data.data,
              index: data.index,
            });
            // autoselecting 1st value in expiry dropdown & fetching strike list
            dispatch(
              getStrategyStrikesOptions(
                {
                  expiry: result.data.data[0] ? result.data.data[0].expiry : '',
                  index: data.index,
                  key: 'expiry',
                  lotSize: result.data.data[0]
                    ? result.data.data[0].lotSize
                    : '',
                  lotKey: 'lotSize',
                  isEdit: data.isEdit,
                },
                isInitialCall
              )
            );
          }
        } else {
          dispatch(
            getStrategyStrikesOptions(
              {
                expiry: data.expiry ? data.expiry : '',
                index: data.index,
                key: 'expiry',
                lotSize: data.lotSize ? data.lotSize : '',
                lotKey: 'lotSize',
                strike: data.strike ? data.strike : '',
                isEdit: data.isEdit,
              },
              isInitialCall
            )
          );
        }
      } catch (error) {
        dispatch({
          type: 'GET_EXPIRES',
          payload: [],
          index: data.index,
        });
      }
    }
    if (data.instrument === 'Future') {
      templateForm.securities[data.index].strike = '';
    }
    dispatch({
      type: 'UPDATE_STRATEGY_LIVE_FORM',
      payload: templateForm,
    });

    try {
      const result = await axios({
        method: 'GET',
        url: `${url}/security/securities`,
        params: {
          exchange: templateForm.exchange.trim(),
          instrument: data.instrument,
          securityFilter: templateForm.securityFilter,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'GET_SECURITY_NAME',
          payload: result.data.data,
          index: data.index,
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_SECURITY_NAME',
        payload: [],
        index: data.index,
      });
    }
  };

// Select account
export const setStrategySecurityAccountOptions =
  (data) => async (dispatch, getState) => {
    let { strategy } = getState().liveTradingReducer;
    let templateForm = { ...strategy };
    templateForm.securities[data.index][data.key] = data.accountName;

    dispatch({
      type: 'UPDATE_STRATEGY_LIVE_FORM',
      payload: templateForm,
    });
  };

// step 3 Select expiry
export const getStrategyExpiresOptions =
  (data) => async (dispatch, getState) => {
    let { strategy } = getState().liveTradingReducer;
    let templateForm = { ...strategy };
    const token = localStorage.getItem('token');
    templateForm.securities[data.index][data.key] = data.securityName;
    try {
      if (
        !(
          templateForm.entryLegs[0] &&
          templateForm.entryLegs[0].position[0] &&
          (templateForm.entryLegs[0].position[0].securityIdx ||
            templateForm.entryLegs[0].position[0].securityIdx === 0)
        )
      ) {
        throw new Error('Old format of template');
      }
      const result = await MakeRequest('/security/nickname', true, true).get({
        exchange: templateForm.exchange.trim(),
        instrument: templateForm.securities[data.index].instrument,
        security: templateForm.securities[data.index].securityName,
      });
      // const result = await axios({
      //   method: 'GET',
      //   url: `${url}/security/nickname`,
      //   params: {
      //     exchange: templateForm.exchange.trim(),
      //     instrument: templateForm.securities[data.index].instrument,
      //     security: templateForm.securities[data.index].securityName,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      if (
        result &&
        result.data &&
        result.data.code &&
        result.data.code != 'INVALID'
      ) {
        let prevNickname = templateForm.securities[data.index].nickname;
        templateForm.entryLegs.map((a) =>
          a.position.map((b) => {
            if (b.securityIdx === data.index) b.security = result.data.code;
          })
        );
        templateForm.securities[data.index].nickname = result.data.code;
      }
    } catch (e) {
      console.log(e);
    }

    if (templateForm.securities[data.index].instrument === 'Equity') {
      templateForm.securities[data.index]['lotSize'] = 1;
    }
    dispatch({
      type: 'UPDATE_STRATEGY_LIVE_FORM',
      payload: templateForm,
    });
    // dispatch({ type: 'UPDATE_SECURITY_TO_POSITION' });
    try {
      const result = await MakeRequest('/security/expires', true, true).get({
        exchange: templateForm.exchange.trim(),
        instrument: templateForm.securities[data.index].instrument,
        security: data.securityName,
      });
      // const result = await axios({
      //   method: 'GET',
      //   url: `${url}/security/expires`,
      //   params: {
      //     exchange: templateForm.exchange.trim(),
      //     instrument: templateForm.securities[data.index].instrument,
      //     security: data.securityName,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      if (result) {
        dispatch({
          type: 'GET_EXPIRES',
          payload: result.data.data,
          index: data.index,
        });
        // autoselecting 1st value in expiry dropdown & fetching strike list
        // if(data.instrument!="Future") // check to reduce un necessary API call to get strikes
        dispatch(
          getStrategyStrikesOptions({
            expiry: result.data.data[0] ? result.data.data[0].expiry : '',
            index: data.index,
            key: 'expiry',
            lotSize: result.data.data[0] ? result.data.data[0].lotSize : '',
            lotKey: 'lotSize',
          })
        );
      }
    } catch (error) {
      dispatch({
        type: 'GET_EXPIRES',
        payload: [],
        index: data.index,
      });
    }
  };
// step 3 Select striKe
export const getStrategyStrikesOptions =
  (data, isInitialCall = false) =>
  async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    let { strategy } = getState().liveTradingReducer;
    let templateForm = { ...strategy };
    if (!isInitialCall)
      templateForm.securities[data.index][data.key] = data.expiry;
    if (!isInitialCall)
      templateForm.securities[data.index][data.lotKey] = data.lotSize;
    try {
      if (
        !(
          templateForm.entryLegs[0] &&
          templateForm.entryLegs[0].position[0] &&
          (templateForm.entryLegs[0].position[0].securityIdx ||
            templateForm.entryLegs[0].position[0].securityIdx === 0)
        )
      ) {
        throw new Error('Old format of template');
      }
      const result = await MakeRequest('/security/nickname', true, true).get({
        exchange: templateForm.exchange.trim(),
        instrument: templateForm.securities[data.index].instrument,
        security: templateForm.securities[data.index].securityName,
        expiry: templateForm.securities[data.index].expiry || '',
        strike: templateForm.securities[data.index].strike || '',
      });
      // const result = await axios({
      //   method: 'GET',
      //   url: `${url}/security/nickname`,
      //   params: {
      //     exchange: templateForm.exchange.trim(),
      //     instrument: templateForm.securities[data.index].instrument,
      //     security: templateForm.securities[data.index].securityName,
      //     expiry: templateForm.securities[data.index].expiry,
      //     strike: templateForm.securities[data.index].strike,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      if (
        result &&
        result.data &&
        result.data.code &&
        result.data.code != 'INVALID'
      ) {
        let prevNickname = templateForm.securities[data.index].nickname;
        templateForm.entryLegs.map((a) =>
          a.position.map((b) => {
            if (b.securityIdx === data.index) b.security = result.data.code;
          })
        );
        templateForm.securities[data.index].nickname = result.data.code;
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: 'UPDATE_STRATEGY_LIVE_FORM',
      payload: templateForm,
    });
    // dispatch({ type: 'UPDATE_SECURITY_TO_POSITION' });
    try {
      if (!data.isEdit) {
        const result = await MakeRequest('/security/strikes', true, true).get({
          exchange: templateForm.exchange.trim(),
          instrument: templateForm.securities[data.index].instrument,
          security: templateForm.securities[data.index].securityName,
          expiry: data.expiry,
        });
        // const result = await axios({
        //   method: 'GET',
        //   url: `${url}/security/strikes`,
        //   params: {
        //     exchange: templateForm.exchange.trim(),
        //     instrument: templateForm.securities[data.index].instrument,
        //     security: templateForm.securities[data.index].securityName,
        //     expiry: data.expiry,
        //   },
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // });
        if (result) {
          dispatch({
            type: 'GET_STRIKES',
            payload: result.data.data,
            index: data.index,
          });
          // autoselecting 1st value in strike dropdown
          // if (!isInitialCall)
          //   dispatch(
          //     UpdateRow({
          //       value: result.data.data[0]
          //         ? result.data.data[0].toString()
          //         : '',
          //       index: data.index,
          //       key: 'strike',
          //     })
          //   );
        }
      }
    } catch (error) {
      dispatch({
        type: 'GET_STRIKES',
        payload: [],
        index: data.index,
      });
    }
  };

export const getNickname = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm.securities[data.index].strike = data.strike;
  try {
    if (
      !(
        templateForm.entryLegs[0] &&
        templateForm.entryLegs[0].position[0] &&
        (templateForm.entryLegs[0].position[0].securityIdx ||
          templateForm.entryLegs[0].position[0].securityIdx === 0)
      )
    ) {
      throw new Error('Old format of template');
    }
    const result = await MakeRequest('/security/nickname', true, true).get({
      exchange: templateForm.exchange.trim(),
      instrument: templateForm.securities[data.index].instrument,
      security: templateForm.securities[data.index].securityName,
      expiry: templateForm.securities[data.index].expiry || '',
      strike: templateForm.securities[data.index].strike || '',
    });
    // const result = await axios({
    //   method: 'GET',
    //   url: `${url}/security/nickname`,
    //   params: {
    //     exchange: templateForm.exchange.trim(),
    //     instrument: templateForm.securities[data.index].instrument,
    //     security: templateForm.securities[data.index].securityName,
    //     expiry: templateForm.securities[data.index].expiry,
    //     strike: templateForm.securities[data.index].strike,
    //   },
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    if (
      result &&
      result.data &&
      result.data.code &&
      result.data.code != 'INVALID'
    ) {
      let prevNickname = templateForm.securities[data.index].nickname;
      templateForm.entryLegs.map((a) =>
        a.position.map((b) => {
          if (b.securityIdx === data.index) b.security = result.data.code;
        })
      );
      templateForm.securities[data.index].nickname = result.data.code;
    }
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: 'UPDATE_STRATEGY_LIVE_FORM',
    payload: templateForm,
  });
  // dispatch({ type: 'UPDATE_SECURITY_TO_POSITION' });
};

export const UpdateRow = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  try {
    templateForm.securities[data.index][data.key] = data.value;
    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: templateForm,
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_STRATEGY_FORM',
      payload: {},
    });
  }
};

export const createStrategy = (TemplateId, isTemplate) => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    let strategyUrl =
      isTemplate === 'true'
        ? `${url}/strategyTemplate/return/${TemplateId}`
        : `${url}/strategy/return/${TemplateId}`;
    const result = await axios({
      method: 'GET',
      url: strategyUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result) {
      if (result.data.data) {
        dispatch({
          type: 'CREATE_STRATEGY',
          import: result.data.data,
          ID: result.data.data.id ? result.data.data.id : TemplateId,
          fetched: true,
        });
      } else {
        dispatch({
          type: 'CREATE_STRATEGY',
          import: '',
          fetched: false,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'CREATE_STRATEGY',
      import: {
        name: '',
        description: '',
        exchange: '',
        account: '',
        securities: [
          {
            tradingAccountId: '',
            instrument: '',
            securityName: '',
            expiry: '',
            strike: '',
            nickname: '',
            isAdditional: false,
            lotSize: '',
          },
        ],
        constants: [],
        entryLegs: [
          {
            legId: 1,
            condition: [
              {
                type: 'OPERAND',
                trigger: '',
              },
            ],
            position: [
              {
                orientation: 'Fwd',
                direction: 'BUY',
                securityIdx: '',
                security: '',
                pricing: [],
                quantity: [],
                totalQuantityLogic: [],
                orderQty: '',
                totalQty: '',
                lotSize: [],
              },
            ],
            algoExecution: [],
          },
        ],
        exitCondition: {
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          action: '',
        },
      },
      fetched: false,
      isRuleReloadReq: false,
      ssid: '',
    });
  }
};

function syncNicknameToEntryLegPosition(strategy) {
  try {
    const tempStrategy = { ...strategy };
    for (let leg = 0; leg < tempStrategy.entryLegs.length; leg++) {
      for (
        let pos = 0;
        pos < tempStrategy.entryLegs[leg].position.length;
        pos++
      ) {
        tempStrategy.entryLegs[leg].position[pos].security =
          tempStrategy.securities[
            tempStrategy.entryLegs[leg].position[pos].securityIdx
          ].nickname;
      }
    }
    return tempStrategy;
  } catch (error) {
    console.log(error);
    return strategy;
  }
}

//submit create strategy
export const submit = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm = syncNicknameToEntryLegPosition(templateForm);
  templateForm.securities = templateForm.securities.map((item) => ({
    instrumentAllowed: item.instrumentAllowed,
    instrument: item.instrument,
    securityName: item.securityName,
    expiry: item.expiry,
    strike: item.strike,
    nickname: item.nickname,
    isAdditional: item.isAdditional,
    lotSize: item.lotSize,
    tradingAccountId: item.tradingAccountId,
    exchange: item.exchange,
    buySell: item.buySell,
    originalSec: item.originalSec,
  }));
  const token = localStorage.getItem('token');
  const customerId = localStorage.getItem('customerId');
  templateForm['strategyTemplateId'] = data;
  templateForm['userId'] = customerId;
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/strategy/submit`,
      data: templateForm,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      if (result.data.data) {
        dispatch({
          type: 'SUBMIT_STRATEGY',
          payload: result.data.data,
          ID: result.data.data.id,
        });
        return {
          message: result.data.data.message,
          code: 'success',
          status: 'success',
        };
      } else {
        return {
          message: result.data.data.message,
          status: 'error',
        };
      }
    }
  } catch (err) {
    dispatch({
      type: 'SUBMIT_STRATEGY',
      payload: [],
    });
    return {
      message:
        err.response.data.data && err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting some server error',
      status: 'error',
    };
  }
};

//save as draft
export const saveAsDraftStrategy =
  (data, templateID) => async (dispatch, getState) => {
    let { strategy } = getState().liveTradingReducer;
    let templateForm = { ...strategy };
    const token = localStorage.getItem('token');
    templateForm.exitCondition[data?.key] = data?.field;
    const customerId = localStorage.getItem('customerId');
    data['userId'] = customerId;

    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/strategy/saveDraft`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        dispatch({
          type: 'SAVE_AS_DRAFT_STRATEGY',
          payload: templateForm,
        });
        return {
          message: result?.data?.data?.message
            ? result?.data?.data?.message
            : 'Getting some error',
          code: 'success',
          status: 'success',
        };
      }

      return {
        message: result?.data?.data?.message
          ? result?.data?.data?.message
          : 'Getting some error',
        status: 'error',
      };
    } catch (err) {
      dispatch({
        type: 'SAVE_AS_DRAFT_STRATEGY',
        payload: data,
      });
      return {
        message:
          err.response.data.error && err.response.data.error.message
            ? err.response.data.error.message
            : 'Getting some server error',
        status: 'error',
      };
    }
  };

/* Subscribe security on particular page*/
export const subscribeSecurities = (securityData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await axios({
      method: 'POST',
      url: `${url}/security/subscribe`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: securityData,
    });
    if (result) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

/* Un-Subscribe security on particular page*/
export const unsubscribeSecurities = (securityData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await axios({
      method: 'POST',
      url: `${url}/security/unsubscribe`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: securityData,
    });
    if (result) {
      return result.data.data;
    }
  } catch (error) {
    return false;
  }
};
export const riskParams = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/admin/risk/parameters`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_RISK_PARAMS',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_RISK_PARAMS',
      payload: [],
    });
  }
};
export const selectedLiveData = (data) => async (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_LIVEDATA',
    payload: data,
  });
};

//get update name
export const getName = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/pageName`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageNumber: data,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_NAME',
        payload: result.data.data.pageName,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_NAME',
      payload: '',
    });
  }
};

//post update name
export const updateName = (nameData) => async (dispatch) => {
  const token = localStorage.getItem('token');
  dispatch({
    type: 'UPDATE_NAME_LOADER',
    payload: false,
  });
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/security/updatePageName`,
      data: nameData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'UPDATE_NAME',
        payload: true,
        ID: result.data.data.id,
      });
      dispatch({
        type: 'UPDATE_NAME_LOADER',
        payload: true,
      });
    }
  } catch (err) {
    dispatch({
      type: 'UPDATE_NAME',
      errorMessage: err.response.data.message,
    });
    dispatch({
      type: 'UPDATE_NAME_LOADER',
      payload: true,
    });
  }
};

export const getUserExchangeAccount = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/admin/user/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_ACCOUNTNAME',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_ACCOUNTNAME',
      payload: {},
    });
  }
};

export const setCreateStrategyTemplateForm =
  (Form) => async (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_CREATE_STRATEGY_FORM',
      payload: Form,
    });
  };

//clear
export const reset = (data) => async (dispatch) => {
  let templateForm = data;
  dispatch({
    type: 'RESET_CREATESTRATEGY',
    payload: templateForm,
  });
};

export const clearStatus = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CLEAR_STATUS_CREATESTRATEGY',
    payload: data,
  });
};

export const setCondition = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm.entryLegs[data.legIndex][data.key] = data.field;
  dispatch({
    type: 'UPDATE_CREATE_STRATEGY_FORM',
    payload: templateForm,
  });
};

export const updateRule = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_RULE',
    payload: data,
  });
};
export const setStrategyPricing = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm.entryLegs[data.legId].position[data.priceIndex].pricing =
    data.field;
  try {
    dispatch({
      type: 'UPDATE_STRATEGY_PRICE',
      payload: templateForm,
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_STRATEGY_PRICE',
      payload: {},
    });
  }
};

export const setStrategyDirection = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm.entryLegs[data.legId].position[data.priceIndex].direction =
    data.field;
  try {
    dispatch({
      type: 'UPDATE_STRATEGY_DIRECTION',
      payload: templateForm,
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_STRATEGY_DIRECTION',
      payload: {},
    });
  }
};
export const getPricingLogic = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/strategy/pricingFunction`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_PRICING',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_PRICING',
      payload: [],
    });
  }
};

// entry condtion get totalQty
export const getquantityLiveTrading = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm.entryLegs[data.legIndex][data.key][data.positionIndex][
    data.preveiewQty
  ] = data.value;
  dispatch({
    type: 'GET_TOTALQTY',
    payload: templateForm,
  });
};

export const algoEdit = (data) => async (dispatch, getState) => {
  let { strategy } = getState().liveTradingReducer;
  let templateForm = { ...strategy };
  templateForm.entryLegs[data.legIndex].algoExecution[
    data.algoExeIndex
  ].arguements[data.argIndex].argValue = data.value;

  dispatch({
    type: 'UPDATE_CREATE_STRATEGY_FORM',
    payload: templateForm,
  });
};

export const orderTrade =
  (value, periodicUpdate = false) =>
  async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    const { pageNumber, postPerPage } = getState().liveTradingReducer;
    if (!periodicUpdate) {
      dispatch({
        type: 'GET_ORDER',
        payload: null,
        orderBookloaded: false,
      });
    }
    let orderObj = {
      status: null,
      pageNumber: pageNumber,
      recordCount: postPerPage,
      openOrderFlag: 1,
    };
    if (value) {
      orderObj = { ...orderObj, search: value, countFlag: 1 };
    }
    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/orders/orderBook`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: orderObj,
      });
      if (result) {
        dispatch({
          type: 'GET_ORDER',
          payload: result.data.data,
          orderBookloaded: true,
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_ORDER',
        payload: null,
        orderBookloaded: true,
      });
    }
  };

export const netPosition =
  (refreshFlag = '0', value = '') =>
  async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    dispatch({
      type: 'GET_NET_POSITION',
      payload: {
        1: [],
        2: [],
        3: [],
      },
      netPositionLoaded: false,
    });
    let orderObj = {
      refreshFlag: refreshFlag,
    };

    if (value) {
      orderObj = { ...orderObj, search: value };
    }

    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/netPosition/positionBook`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: orderObj,
      });

      if (result) {
        dispatch({
          type: 'GET_NET_POSITION',
          payload: result.data.data.positionData,
          netPositionLoaded: true,
        });

        return {
          success: true,
          message: '',
        };
      }

      return {
        success: false,
        message: '',
      };
    } catch (error) {
      dispatch({
        type: 'GET_NET_POSITION',
        payload: null,
        netPositionLoaded: true,
      });

      return {
        success: false,
        message: error.message,
      };
    }
  };

export const updateNetPosition = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_NET_POSITION',
    payload: data,
  });
};

export const updatePostPerPageOrder = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_POST_PER_PAGE',
    payload: data,
  });
};
export const updatePageNumberOrder = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_PAGENUMBER_ORDER',
    payload: data,
  });
};

export const orderDrillDown =
  (data, statuses) => async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    const { pageNumber, postPerPage } = getState().liveTradingReducer;

    dispatch({
      type: 'GET_ORDER_DRILLDOWN',
      payload: null,
      loader: false,
    });
    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/orders/orderBook/drilldown`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          status: data.type == 0 || data.type == 1 ? null : statuses,
          pageNumber: pageNumber,
          recordCount: postPerPage,
          security: data.security,
          requestType: data.type,
          accountId: data.accountId,
        },
      });

      if (result) {
        dispatch({
          type: 'GET_ORDER_DRILLDOWN',
          payload: result.data.data,
          loader: true,
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_ORDER_DRILLDOWN',
        payload: null,
        loader: true,
      });
    }
  };

export const ruleOBUpdatePostPerPageOrder = (data) => async (dispatch) => {
  dispatch({
    type: 'RULE_OB_UPDATE_POST_PER_PAGE',
    payload: data,
  });
};
export const ruleOBUpdatePageNumberOrder = (data) => async (dispatch) => {
  dispatch({
    type: 'RULE_OB_UPDATE_PAGENUMBER_ORDER',
    payload: data,
  });
};

export const ruleOBOrderDrillDown =
  (data, statuses) => async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    const { ruleOBPostPerPage, ruleOBPageNumber } =
      getState().liveTradingReducer;
    dispatch({
      type: 'RULE_OB_GET_ORDER_DRILLDOWN',
      payload: null,
      loader: false,
    });
    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/orders/rules/openOrderTrades`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          status: data.reqTypeEnum == 0 ? null : statuses,
          pageNumber: ruleOBPageNumber,
          recordCount: ruleOBPostPerPage,
          positionId: data.positionId,
          accountId: data.accountId,
          ruleId: data.ruleId,
          reqTypeEnum: data.reqTypeEnum,
        },
      });

      if (result) {
        dispatch({
          type: 'RULE_OB_GET_ORDER_DRILLDOWN',
          payload: result.data.data,
          loader: true,
        });
      }
    } catch (error) {
      dispatch({
        type: 'RULE_OB_GET_ORDER_DRILLDOWN',
        payload: null,
        loader: true,
      });
    }
  };

export const getManualOrderData = (orderId) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/manualOrder/return/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      let finalForm = {
        exchange: result.data.data.exchange,
        tradingAccountId: result.data.data.tradingAccountId,
        strategyId: result.data.data.id,
        instrument: result.data.data.instrument,
        symbol: result.data.data.symbol,
        expiry: result.data.data.expiry,
        strike: result.data.data.strike,
        direction: result.data.data.direction,
        orderType: result.data.data.orderType,
        quantity: result.data.data.quantity,
        limitPrice: result.data.data.limitPrice
          ? result.data.data.limitPrice
          : '',
        stopLoss:
          result.data.data.stopLoss !== null ? result.data.data.stopLoss : '',
      };
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: finalForm,
      });
      dispatch(getManualOrderExpiresOptions(result.data.data.symbol));
    }
  } catch (error) {
    dispatch({
      type: 'UPDATE_MANUAL_ORDER_FORM',
      payload: {
        exchange: '',
        tradingAccountId: '',
        strategyId: '',
        instrument: '',
        symbol: '',
        expiry: '',
        strike: '',
        direction: '',
        orderType: '',
        quantity: '',
        limitPrice: '',
        stopLoss: '',
      },
    });
  }
};
export const cancelOrders = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}·/manualOrder·/cancel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        manualOrderId: data,
      },
    });
    if (result) {
      return result.data.data;
    }
  } catch (error) {}
};
export const getSecuritySymbols = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/securities`,
      params: {
        exchange: data.exchange.trim(),
        instrument: data.instrument,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: 'GET_MO_SECURITY_NAME',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_MO_SECURITY_NAME',
      payload: [],
    });
  }
};
export const getSecurityExpires = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');

  try {
    const result = await MakeRequest('/security/expires', true, true).get({
      exchange: data.exchange.trim(),
      instrument: data.instrument,
      security: data.symbol,
    });
    // const result = await axios({
    //   method: 'GET',
    //   url: `${url}/security/expires`,
    //   params: {
    //     exchange: data.exchange.trim(),
    //     instrument: data.instrument,
    //     security: data.symbol,
    //   },
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    if (result) {
      dispatch({
        type: 'GET_MO_EXPIRES',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_MO_EXPIRES',
      payload: [],
    });
  }
};
export const getSecurityStrike = (data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  try {
    const result = await MakeRequest('/security/strikes', true, true).get({
      exchange: data.exchange.trim(),
      instrument: data.instrument,
      security: data.symbol,
      expiry: data.expiry,
    });
    // const result = await axios({
    //   method: 'GET',
    //   url: `${url}/security/strikes`,
    //   params: {
    //     exchange: data.exchange.trim(),
    //     instrument: data.instrument,
    //     security: data.symbol,
    //     expiry: data.expiry,
    //   },
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    if (result) {
      dispatch({
        type: 'GET_MO_STRIKES',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_MO_STRIKES',
      payload: [],
    });
  }
};
// get market security

export const getMarketSecurity = (ticker) => async (dispatch, getState) => {
  const token = localStorage.getItem('token');
  let { manualOrderForm } = getState().strategyAnalyzerReducer;
  let form = { ...manualOrderForm };
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/info`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        dataFeedTicker: ticker,
      },
    });
    if (result) {
      form.exchange = result.data.data.exchange;
      form.instrument = result.data.data.instrument;
      form.symbol = result.data.data.symbol;
      form.expiry = result.data.data.expiry;
      form.strike = result.data.data.strike;
      dispatch(getSecuritySymbols(form));
      dispatch(getSecurityExpires(form));
      dispatch(getSecurityStrike(form));
      dispatch({
        type: 'UPDATE_MANUAL_ORDER_FORM',
        payload: form,
      });
      dispatch({
        type: 'GET_MARKET_SECURITY',
        payload: result.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_MARKET_SECURITY',
      payload: [],
    });
  }
};

//cancel manual order
export const cancelManualOrder =
  (manualorderIds) => async (dispatch, getState) => {
    const token = localStorage.getItem('token');
    let data = { manualOrderId: manualorderIds };

    let response = { message: '' };

    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/manualOrder/cancel`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        return (response = {
          message: 'Manual Order Cancellation request sent to strategy',
        });
      }
    } catch (err) {
      return (response = { message: err.message });
    }
    return response;
  };

export function liveTradingActions(dispatch) {
  return {
    updateBuySellAtIndex: (index, state) => {
      dispatch({
        type: 'UPDATE_BUYSELL_AT_INDEX',
        payload: { index: index, value: state },
      });
    },
  };
}

import React, { useState, useEffect } from 'react';
import './styles.css';
import './PreviewStrategy.scss';
import PreviewStrategyModal from './previewStrategyModal/PreviewStrategyModal';
import { BlackViewIcon } from '../../../../assets';

const PreviewStrategy = () => {
  const [modal, setModal] = useState(false);
  const [headerWidth, setHeaderWidth] = useState('');

  useEffect(() => {
    let topHeader = document.getElementsByClassName('dashboard')[0];
    function resize() {
      setHeaderWidth('');
      let topHeader = document.getElementsByClassName('dashboard')[0];
      setHeaderWidth(topHeader.clientWidth);
    }
    setHeaderWidth(topHeader.clientWidth);
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  });
  const handleModal = () => {
    setModal(!modal);
  };
  return (
    <React.Fragment>
      <div className="preview-btn">
        <img src={BlackViewIcon} className="preview-icon"></img>
        <span className="preview-head" onClick={() => handleModal()}>
          Preview
        </span>
      </div>
      {modal ? (
        <PreviewStrategyModal setModal={setModal} modal={modal} />
      ) : null}
    </React.Fragment>
  );
};

export default PreviewStrategy;

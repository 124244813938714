import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import {
  DesIcon,
  LogoutIcon,
  PasswordIcon,
  DataInit,
  Settings,
  QuickShortcutsIcon,
} from '../../../../assets';
import axios from 'axios';
import { url } from '../../../../config/apiConfig';
import { addShortcutAction } from '../../../../reducers/shortcutReducer';
import { useSelector, useDispatch } from 'react-redux';
import { version } from '../../../../../package.json';
import {
  Switch,
  FeedbackPopup,
  GenericPopup,
  GenButton,
} from '../../../../components';
import { MakeRequest } from '../../../../utils';
import { logout } from '../../../../actions/loginAction';
import { useCookies } from 'react-cookie';
import { useCallbackRef } from 'use-callback-ref';
import useKeyPress from '../../../../custom_Hooks/useShortcut';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useSWRImmutable from 'swr/immutable';
import { loginActions } from '../../../../reducers/loginReducer';

export default function LogoutDropdown(props) {
  const { callBack = () => {}, extStyles = {} } = props;
  const dispatch = useDispatch();
  const ddAreaRef = useRef(null);
  const [enableFatalPopup, setEnableFatalPopup] = useState(
    localStorage.getItem('ENABLE_ALERT_FATAL_POPUP')
      ? localStorage.getItem('ENABLE_ALERT_FATAL_POPUP') == 'true'
        ? true
        : false
      : true
  );
  const [persistentPopup, setPersistentPopup] = useState(
    localStorage.getItem('ENABLE_PERSISTENT_POPUP')
      ? localStorage.getItem('ENABLE_PERSISTENT_POPUP') == 'true'
        ? true
        : false
      : true
  );
  const [cookie, setCookie] = useCookies([]);
  const [showDD, setShowDD] = useState(false);
  const [username, setUsername] = useState('U');
  const [popupVisible, setPopupVisible] = useState('');
  const [user, setUserType] = useState('');
  const [filteredShortcuts, setFilteredShortcuts] = useState([]);
  const [availableShortcuts, setAvailableShortcuts] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [sortedDropdown, setSortedDropdown] = useState([]);
  const allShortcuts = [
    'alt+w',
    'alt+e',
    'alt+a',
    'alt+d',
    'alt+f',
    'alt+z',
    'alt+v',
    'alt+b',
    'alt+g',
  ];
  const [popupState, setPopupState] = useState('');

  const {
    liveTradingReducer: { dropdown },
    shortcutReducer,
  } = useSelector((state) => state);

  useEffect(() => {
    if (shortcutReducer.size) {
      const newFilteredMap = new Map(
        Array.from(shortcutReducer.entries()).filter(
          ([key, shortcut]) => shortcut.isRuleShortcut === true
        )
      );
      const usedShortcuts = Array.from(
        shortcutReducer.entries(),
        ([key, shortcut]) => shortcut.keyCombination
      );
      if (usedShortcuts) {
        const remainingShortcuts = allShortcuts.filter(
          (shortcut) => !usedShortcuts.includes(shortcut)
        );
        setAvailableShortcuts(remainingShortcuts);
      }
      setFilteredShortcuts(newFilteredMap);
    } else {
      setFilteredShortcuts(shortcutReducer);
    }
  }, [popupState]);

  const handleDropdownClick = (strategyId) => {
    setOpenDropdownId(strategyId); // Toggle open/close dropdown
  };

  const handleChange = (strategyId, selectedShortcut) => {
    const currentShortcut = filteredShortcuts.get(
      strategyId.toString()
    )?.keyCombination;
    let updatedShortcuts = filteredShortcuts;
    let tempAvailableShortcuts = availableShortcuts;
    if (currentShortcut && currentShortcut === selectedShortcut) {
      return;
    } else if (currentShortcut && currentShortcut != selectedShortcut) {
      // Remove current shortcut from availableShortcuts if it's not the same as selected
      if (selectedShortcut) {
        tempAvailableShortcuts.push(currentShortcut);
        updatedShortcuts.get(strategyId.toString()).keyCombination =
          selectedShortcut;
        tempAvailableShortcuts.filter((sc) => sc !== selectedShortcut);
      } else {
        tempAvailableShortcuts.push(currentShortcut);
        updatedShortcuts.delete(strategyId.toString());
      }
    } else if (selectedShortcut) {
      updatedShortcuts.set(strategyId.toString(), {
        keyCombination: selectedShortcut,
        execOptions: { templateId: null, id: strategyId, isTemplate: true },
        isRuleShortcut: true,
      });
      tempAvailableShortcuts = tempAvailableShortcuts.filter(
        (sc) => sc !== selectedShortcut
      );
    }
    setFilteredShortcuts(updatedShortcuts);
    setAvailableShortcuts(tempAvailableShortcuts);
    setOpenDropdownId(null); // Close dropdown after selection
  };
  const triggerModal = useCallbackRef(false, () => setPopupVisible('LOGOUT'));
  const [licenseData] = API.GetExpiryDate();

  useKeyPress(
    shortcutReducer.get('LOGOUT') &&
      shortcutReducer.get('LOGOUT').keyCombination.split('+'),
    () => {
      triggerModal.current = !triggerModal.current;
    }
  );

  // useEffect(() => {
  //   fetchDropDownList();
  // }, []);

  // const fetchDropDownList = () => {
  //   dispatch(createDropdown());
  // };

  useEffect(() => {
    if (localStorage.getItem('userType') === 'admin') {
      setUserType('admin');
      // setPageSelect('Risk');
    } else {
      setUserType('customer');
      // setPageSelect('Trading');
    }
  }, []);

  function getInitials(str) {
    return str
      .split(' ')
      .filter((word) => word.length > 0)
      .map((word) => word[0].toUpperCase())
      .join('');
  }

  async function getUsername() {
    const data = await MakeRequest('/user/details').get();
    setUsername(data.data.data.name);
  }

  useEffect(() => {
    const sorted = [...dropdown].sort((a, b) => {
      // const aHasShortcut = filteredShortcuts.get(a.id.toString());
      // const bHasShortcut = filteredShortcuts.get(b.id.toString());
      const aName = a.name ? a.name.toLowerCase() : '';
      const bName = b.name ? b.name.toLowerCase() : '';
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
    setSortedDropdown(sorted);
  }, [dropdown, popupState]);

  async function submitShortcutsForUserId() {
    const token = localStorage.getItem('token');
    let shortcutForm = {};
    shortcutForm['shortcuts'] = Array.from(filteredShortcuts.entries()).map(
      ([key, value]) => ({ key, value })
    );
    shortcutForm['userId'] = localStorage.getItem('customerId');
    try {
      const result = await axios({
        method: 'POST',
        url: `${url}/user/updateShortcuts`,
        data: shortcutForm,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        addShortcutAction(dispatch);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // Set cookie if not existing
    if (!localStorage.getItem('ENABLE_ALERT_FATAL_POPUP')) {
      localStorage.setItem('ENABLE_ALERT_FATAL_POPUP', enableFatalPopup);
    }

    const handleClickAway = (e) => {
      if (ddAreaRef.current && !ddAreaRef.current.contains(e.target)) {
        setShowDD(false);
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    getUsername();

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  useEffect(() => {
    if (cookie && !cookie.enableFatalPopup)
      setCookie('enableFatalPopup', enableFatalPopup);
    if (
      (enableFatalPopup && cookie.enableFatalPopup == 'false') ||
      (!enableFatalPopup && cookie.enableFatalPopup == 'true')
    ) {
      setCookie('enableFatalPopup', enableFatalPopup);
    }
    localStorage.setItem('ENABLE_ALERT_FATAL_POPUP', enableFatalPopup);
  }, [enableFatalPopup]);

  useEffect(() => {
    if (cookie && !cookie.persistentPopup)
      setCookie('enableFatalPopup', persistentPopup);
    if (
      (persistentPopup && cookie.persistentPopup == 'false') ||
      (!persistentPopup && cookie.persistentPopup == 'true')
    ) {
      setCookie('enableFatalPopup', persistentPopup);
    }
    localStorage.setItem('ENABLE_PERSISTENT_POPUP', persistentPopup);
  }, [persistentPopup]);

  function handleClick(type) {
    switch (type) {
      case 0:
        setPopupVisible('LOGOUT');
        break;
      case 1:
        callBack('CHANGE_PASSWORD');
        break;
      case 2:
        callBack('DATA_INIT');
        break;
      case 3:
        loginActions.licencePopupState(dispatch, 3);
        loginActions.licenseDaysRemain(dispatch, licenseData?.remainingSubDays);
        break;
      case 4:
        setPopupState('SHORTCUTS');
        break;
      default:
        break;
    }
    setShowDD(false);
  }

  function logoutUser() {
    let userId = localStorage.getItem('customerId');
    dispatch(logout({ userId: userId }));
    localStorage.clear();
    window.location.href = '/';
  }

  return (
    <React.Fragment>
      <div className="loginfo-container" style={extStyles}>
        <div
          className="logout-dropdown-container"
          onClick={() => setShowDD(!showDD)}
        >
          <span className="user-name-initials">{getInitials(username)}</span>
          <img
            src={DesIcon}
            className="logout-dropdown-icon2"
            alt="dropDownIcon"
          />
        </div>
        {showDD && (
          <div className="logout-dd-options" ref={ddAreaRef}>
            <DropDownOption
              label={username}
              extStyles={{ borderTop: '0 solid #000' }}
            />
            <DropDownOption
              leftIcon={LogoutIcon}
              label="Log Out"
              wholeClick={() => handleClick(0)}
            />
            <DropDownOption
              leftIcon={PasswordIcon}
              label="Change Password"
              wholeClick={() => handleClick(1)}
            />
            {!(user === 'admin') && (
              <DropDownOption
                leftIcon={QuickShortcutsIcon}
                label="Shortcuts"
                wholeClick={() => handleClick(4)}
              />
            )}
            {user === 'admin' && (
              <DropDownOption
                leftIcon={DataInit}
                label="Configuration"
                wholeClick={() => handleClick(2)}
              />
            )}
            <DropDownOption
              leftIcon={Settings}
              label="Fatal Popups"
              rightComponent={
                <Switch
                  state={enableFatalPopup}
                  onChange={(val) => setEnableFatalPopup(val)}
                  extStyles={{}}
                />
              }
            />
            <DropDownOption
              leftIcon={Settings}
              label="Persistent Popup"
              rightComponent={
                <Switch
                  state={persistentPopup}
                  onChange={(val) => setPersistentPopup(val)}
                  extStyles={{}}
                />
              }
            />
            <DropDownOption
              leftIcon={PasswordIcon}
              label="License Expires in"
              wholeClick={() => handleClick(3)}
              rightComponent={
                <div
                  style={{
                    height: '100%',
                    width: '75%',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgressbar
                    value={licenseData?.remainingSubDays}
                    maxValue={licenseData?.totalSubDays}
                    text={`${licenseData?.remainingSubDays} Days`}
                    styles={buildStyles({
                      textColor: '#000',
                      pathColor: '#FF846F',
                      trailColor: '#ffffff',
                    })}
                  />
                </div>
              }
            />
            <DropDownOption label={`STrade version : V ${version}`} />
          </div>
        )}
      </div>
      <GenericPopup
        state={popupState.length != 0}
        type={1}
        toggleState={() => {
          setPopupState('');
        }}
      >
        <div className="quickShortcutsPopCont">
          <div className="quickShortcutsPopCont-titleBar">
            <span className="quickShortcutsPopCont-title">
              Configure Shortcuts for Strategies
            </span>
          </div>
          <div className="quickShortcutsPopCont-body">
            <div className="strategy-shortcut-header">
              <span className="header-strategy-name">Strategy Template</span>
              <span className="header-shortcut-key">Shortcut Key</span>
            </div>
            {sortedDropdown.map((strategy, index) => (
              <div key={index} className="strategy-shortcut-entry">
                <span
                  className="strategy-name"
                  style={{ fontSize: '16px', fontWeight: '500' }}
                >
                  {strategy.name}
                </span>
                <select
                  className="shortcut-dropdown"
                  value={
                    filteredShortcuts.get(strategy.id.toString())
                      ?.keyCombination || ''
                  }
                  onChange={(e) => handleChange(strategy.id, e.target.value)}
                  onClick={() => handleDropdownClick(strategy.id)}
                  onFocus={() => handleDropdownClick(strategy.id)}
                >
                  {!filteredShortcuts.get(strategy.id.toString()) && (
                    <option value="">Select Shortcut</option>
                  )}
                  {filteredShortcuts.get(strategy.id.toString()) && (
                    <option
                      value={
                        filteredShortcuts.get(strategy.id.toString())
                          .keyCombination
                      }
                    >
                      {
                        filteredShortcuts.get(strategy.id.toString())
                          .keyCombination
                      }
                    </option>
                  )}
                  {filteredShortcuts.get(strategy.id.toString()) && (
                    <option value="">None</option>
                  )}
                  {openDropdownId === strategy.id && (
                    <React.Fragment>
                      <option disabled>----</option>
                      {availableShortcuts.map((shortcut, idx) => (
                        <option key={idx} value={shortcut}>
                          {shortcut}
                        </option>
                      ))}
                    </React.Fragment>
                  )}
                </select>
              </div>
            ))}
          </div>

          <div className="feedback-popup-bottom-Shortcuts">
            <GenButton
              type="1"
              title="SAVE"
              fill={true}
              extStyle={{ width: '8vw', marginTop: '5px' }}
              clickAction={() => {
                submitShortcutsForUserId();
                setPopupState('');
              }}
            />
            <GenButton
              type="1"
              title="CLOSE"
              fill={false}
              extStyle={{ width: '8vw', marginLeft: '2rem', marginTop: '5px' }}
              clickAction={() => {
                setPopupState('');
              }}
              focus={true}
            />
          </div>
        </div>
      </GenericPopup>
      <FeedbackPopup
        message={
          popupVisible.split(' ')[0] === 'LOGOUT'
            ? 'Are you sure you want to Logout ? '
            : ''
        }
        acceptAction={() => logoutUser()}
        rejectAction={() => setPopupVisible('')}
        type="a"
      />
    </React.Fragment>
  );
}

function DropDownOption(props) {
  const {
    leftIcon,
    label = '',
    rightComponent,
    wholeClick = () => {},
    extStyles = {},
  } = props;

  return (
    <div
      className="drowDownOption-container"
      onClick={() => wholeClick()}
      style={extStyles}
    >
      <div className="drowDownOption-s1">
        {leftIcon && (
          <img alt={label} src={leftIcon} className="drowDownOption-leftIcon" />
        )}
      </div>
      <div className="drowDownOption-s2">
        <span className="font-l1">{label}</span>
      </div>
      <div className="drowDownOption-s3">{rightComponent}</div>
    </div>
  );
}

const API = {
  GetExpiryDate: () => {
    const { data, error, isLoading } = useSWRImmutable(
      'superadmin/license',
      async () => {
        const response = await MakeRequest('/superadmin/license').get();
        return response.data.data;
      }
    );
    return [data, error, isLoading];
  },
};

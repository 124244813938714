import React, { useState } from 'react';
import './styles.css';
import CompLabel from '../customComponent/compLabel';
import { CalenderIcon } from '../../../assets';

export default function DateTimePick(props) {
  const {
    label,
    value,
    onValueChange,
    orient = 'v',
    time = false,
    validFunc = () => {
      return { state: true };
    },
    // Custom Style for CompLabel label tag
    labelStyles={},
    extStyles = {},
    compLabelExtStyles = {}
  } = props;
  const [valid, setValid] = useState({ state: true, message: '' });

  function handleChange(val) {
    const res = validFunc(val);
    if (res.state) {
      setValid({ state: res.state, message: '' });
    } else {
      setValid({ state: res.state, message: res.message });
    }
    onValueChange(val);
  }

  return (
    <div style={extStyles}>
      <CompLabel orientation={orient} label={label} labelStyles={labelStyles} extStyles={compLabelExtStyles}>
        <div className="date-container">
          <input
            type={time ? 'time' : 'date'}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            className="calender-input"
          />
          { <img src={CalenderIcon} className="calender-icon" /> }
        </div>
        {!valid.state && (
          <p className="inputField-error-message">{valid.message}</p>
        )}
      </CompLabel>
    </div>
  );
}

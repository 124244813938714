import { useState, useEffect } from 'react';
import './styles.css';
import {
  InputField,
  DropDown,
  Switch,
  SearchMultiSelect,
  ShowMultiSelect,
  GenButton,
  Button,
} from '../../../../components';
import { AddCircle, WhiteDeleteIcon } from '../../../../assets/index';
import {
  getAllDealers,
  getFamilyType,
  getEntityTypes,
  getExchanges,
  getEms,
  getSegment,
} from '../../../../glb_apis';
import { MakeRequest } from '../../../../utils';

export default function CreateEditFamPopup(props) {
  const {
    closeForm = () => {},
    editData = { famId: -1 },
    feedback = () => {},
  } = props;
  const [ddOptions, setDdOptions] = useState({
    familyType: [],
    dealers: [],
    entityType: [],
    exchanges: [],
    ems: [],
    segment: [],
  });
  const [familyData, setFamilyData] = useState({
    familyId: '',
    familyDisplayId: '',
    familyName: '',
    familyType: '',
    toCreateLegalEntity: true,
    giveupEnabled: true,
    active: true,
    mappedDealers: {},
    isHFT: process.env.REACT_APP_IS_HFT == 'true' ? true : false,
    entity: [
      {
        toCreateAccClearAcc: true,
        active: true,
        // entityId: '',
        entityDisplayId: '',
        entityType: '',
        exchange: '',
        segment: '',
        isNew: true,
        subEntity: [
          {
            entityId: '',
            entityName: '',
            segment: '',
            ucc: '',
            pan: '',
            cpCode: '',
            clearingAccountId: '',
            ems: {},
            isNew: true,
          },
        ],
      },
    ],
    remarks: '',
  });
  const [error, setError] = useState('');
  const [isInternalVer, setIsInternalVer] = useState(
    process.env.REACT_APP_IS_INTERNAL_VERSION === 'true' ? true : false
  );

  async function loadEditData(id) {
    const famData = await getFamilyData(id);
    const famNewData = {
      isHFT: process.env.REACT_APP_IS_HFT === 'true' ? true : false,
      familyId: '',
      familyDisplayId: '',
      familyName: '',
      familyType: '',
      giveupEnabled: false,
      active: false,
      mappedDealers: {},
      entity: [],
      remarks: '',
    };

    famNewData.isHFT = famData.isHFT ? famData.isHFT : false;
    famNewData.familyId = famData.familyId;
    famNewData.familyDisplayId = famData.familyDisplayId;
    famNewData.familyName = famData.familyName;
    famNewData.familyType = famData.familyType;
    famNewData.giveupEnabled = famData.giveUpStatus;
    famNewData.active = famData.active;
    for (let dealer of famData.dealers) {
      famNewData.mappedDealers[dealer] = true;
    }

    for (let [idx, entity] of famData.entities.entries()) {
      let activeStatus = false;
      famNewData.entity.push({
        toCreateAccClearAcc: true,
        active: activeStatus,
        entityDisplayId: entity.entityDisplayId,
        entityType: entity.entityType,
        exchange: entity.exchange,
        isNew: false,
        subEntity: [],
      });
      for (let subEntity of entity.subEntities) {
        activeStatus = subEntity.activeStatus;
        let tempEMSMap = {};
        for (let tempEMS of subEntity.ems) {
          let tempAppName =
            tempEMS && tempEMS.applicationName ? tempEMS.applicationName : '';
          if (tempAppName) {
            tempEMSMap[`${tempEMS.ssid}-${tempAppName}`] = true;
          }
        }
        famNewData.entity[idx].subEntity.push({
          entityId: subEntity.entityId,
          entityName: subEntity.entityName,
          segment: subEntity.segment,
          ucc: subEntity.ucc,
          pan: subEntity.pan,
          cpCode: subEntity.cpCode,
          clearingAccountId: subEntity.clearingAccountId,
          ems: tempEMSMap,
          isNew: false,
        });
      }
      famNewData.entity[idx].active = activeStatus;
    }

    setFamilyData(famNewData);
  }

  useEffect(() => {
    console.log('Is HFT Mode:', process.env.REACT_APP_IS_HFT);
    const apiCalls = async () => {
      const res = await getFamilyType();
      const dealers = await getAllDealers();
      const entityType = await getEntityTypes();
      const exchanges = await getExchanges();
      const segments = await getSegment();
      let ems = await getEms();
      if (ems && Array.isArray(ems)) {
        ems = ems.map((item) => ({ ...item, state: false }));
      }
      setDdOptions({
        ...ddOptions,
        familyType: res,
        dealers: dealers,
        entityType: entityType,
        exchanges: exchanges,
        segment: segments,
        ems: ems,
      });
    };
    apiCalls();

    if (editData !== -1) {
      loadEditData(editData);
    }
  }, []);

  useEffect(() => {
    let tempFamData = { ...familyData };
    if (
      ddOptions.dealers &&
      ddOptions.dealers.length &&
      ddOptions.dealers[0].userName &&
      editData == -1
    ) {
      tempFamData.mappedDealers[ddOptions.dealers[0].userName] = true;
      setFamilyData(tempFamData);
    }
  }, [ddOptions]);

  function validate_request_packet(request) {
    if (request.familyDisplayId.length === 0) {
      setError('Need Family ID');
      return false;
    } else if (request.familyName.length === 0) {
      setError('Need Family name');
      return false;
    } else if (request.familyType.length === 0) {
      setError('Need Family Type');
      return false;
    } else if (
      request.mappedDealers &&
      Object.keys(request.mappedDealers).length === 0
    ) {
      setError('Please map family to atleast one dealer.');
      return false;
    }

    // Entity Validation
    let entitySegmentMap = {};
    let ssidSet = new Set();
    if (request.entity && Array.isArray(request.entity)) {
      for (let entInfo of request.entity) {
        if (entInfo.entityDisplayId === '') {
          setError('Entity Id cannot be empty.');
          return false;
        } else if (entInfo.entityType === '') {
          setError('Entity type cannot be empty.');
          return false;
        } else if (entInfo.exchange === '') {
          setError('Exchange cannot be empty.');
          return false;
        }

        // Fill entityDisplayIdToEntityMap map & basic non empty validation
        if (entitySegmentMap[entInfo.entityDisplayId]) {
          setError(
            `Entity Id should be unique for each entity, entityId ${entInfo.entityDisplayId} repeated`
          );
          return false;
        } else {
          entitySegmentMap[entInfo.entityDisplayId] = new Set();
        }
        for (let subEntityInfo of entInfo.subEntity) {
          if (entInfo.toCreateAccClearAcc && subEntityInfo.segment === '') {
            setError('Segment cannot be empty.');
            return false;
          } else if (
            entInfo.toCreateAccClearAcc &&
            subEntityInfo.entityName === ''
          ) {
            setError('Display name cannot be empty.');
            return false;
          } else if (entInfo.toCreateAccClearAcc && subEntityInfo.ucc === '') {
            setError('UCC cannot be empty.');
            return false;
          } else if (entInfo.toCreateAccClearAcc && subEntityInfo.pan === '') {
            setError('PAN cannot be empty.');
            return false;
          } else if (!request.isHFT && subEntityInfo.ems === '') {
            setError(
              `EMS cannot be empty, please map ems for entity ${entInfo.entityDisplayId}.`
            );
            return false;
          } else if (entInfo.entityType != 'PRO' && !subEntityInfo.cpCode) {
            setError(
              `CP Code cannot be empty for entityType ${entInfo.entityType}.`
            );
            return false;
          }

          // Add ssid in set
          ssidSet.add(subEntityInfo.ems);

          if (
            !entitySegmentMap[entInfo.entityDisplayId].has(
              subEntityInfo.segment
            )
          ) {
            entitySegmentMap[entInfo.entityDisplayId].add(
              subEntityInfo.segment
            );

            if (
              entitySegmentMap[entInfo.entityDisplayId].has('XX') &&
              entitySegmentMap[entInfo.entityDisplayId].size > 1
            ) {
              setError(
                `Entity id ${entInfo.entityDisplayId} has invalid segment mappings, if segment XX is configured only single sub entity can be configured.`
              );
              return false;
            }
          } else {
            setError(
              `Duplicate segment ${subEntityInfo.segment} found for entity id ${entInfo.entityDisplayId}`
            );
            return false;
          }
        }
      }
    }

    return true;
  }

  async function submitAction() {
    if (!validate_request_packet(familyData)) {
      return;
    }
    const request_packet = {
      isHFT: process.env.REACT_APP_IS_HFT == 'true' ? true : false,
      toCreateLegalEntity: true,
      familyDisplayId: '',
      familyName: '',
      familyType: {
        id: -1,
        name: '',
      },
      dealerList: [],
      active: false,
      entities: [],
      entityListLength: '',
      giveUpStatus: true,
      remarks: '',
    };
    request_packet.isHFT = familyData.isHFT;
    request_packet.toCreateLegalEntity = familyData.toCreateLegalEntity;
    request_packet.familyDisplayId = familyData.familyDisplayId;
    request_packet.familyName = familyData.familyName;
    request_packet.active = familyData.active;
    request_packet.giveUpStatus = familyData.giveupEnabled;
    request_packet.familyType = ddOptions.familyType.filter(
      (item) => item.name === familyData.familyType
    )[0];
    request_packet.dealerList = ddOptions.dealers
      .filter((item) => familyData.mappedDealers[item.userName])
      .map((item) => {
        return { id: item.dealerId, name: item.userName };
      });
    request_packet.entities = familyData.entity.map((item) => {
      return {
        toCreateAccClearAcc: item.toCreateAccClearAcc,
        entityDisplayId: item.entityDisplayId,
        entityType: item.entityType,
        exchange: item.exchange,
        subEntities: item.subEntity.map((subItem) => {
          let segmentCode = subItem.segment;
          for (let segCode of Object.keys(ddOptions.segment)) {
            if (ddOptions.segment[[segCode]] === segmentCode) {
              segmentCode = segCode;
              break;
            }
          }
          return {
            entityId: subItem.entityId ? subItem.entityId : null,
            entityName: subItem.entityName,
            segment: segmentCode,
            pan: subItem.pan,
            ucc: subItem.ucc,
            cpCode: subItem.cpCode,
            ems: ddOptions.ems.filter((item1) =>
              subItem.ems[`${item1.ssid}-${item1.applicationName}`]
                ? true
                : false
            ),
            activeStatus: item.active,
            currency: 'INR',
            exchangeAccountName: 'ATP',
            clearingAccountId: subItem.clearingAccountId,
          };
        }),
      };
    });
    request_packet.remarks = familyData.remarks;

    if (editData && editData !== -1) {
      request_packet.familyId = editData;
      const res = await modifyFamilyRequest(request_packet);
      setError(res.message);
      if (res.state) {
        closeForm();
        feedback('Family Modified Successfully');
      }
    } else {
      // * res = {state: true, message: ""}
      const res = await createFamilyRequest(request_packet);
      setError(res.message);
      if (res.state) {
        closeForm();
        feedback('Family Created Successfully');
      }
    }
  }

  return (
    <div className="create-fam-cont">
      <div className="create-fam-title">
        <span className="create-fam-title-text">
          {editData && editData == -1 ? 'Create Family' : 'Edit Family'}
        </span>
      </div>
      <div className="entity-body">
        <div className="create-fam-s1-cont">
          <InputField
            label="Family ID"
            type="text"
            inputWidth="15vw"
            value={familyData.familyDisplayId}
            onValueChange={(val) =>
              setFamilyData({ ...familyData, familyDisplayId: val })
            }
            validationFunction={(val) => {
              return {
                state: val.length > 0,
                message: val.length > 0 ? '' : 'Family ID Cannot be empty',
              };
            }}
          />
          <InputField
            label="Family Name"
            type="text"
            inputWidth="15vw"
            value={familyData.familyName}
            onValueChange={(val) =>
              setFamilyData({ ...familyData, familyName: val })
            }
            validationFunction={(val) => {
              return {
                state: val.length > 0,
                message: val.length > 0 ? '' : 'Family Name Cannot be empty',
              };
            }}
          />
          <DropDown
            label="Family Type"
            orientation="v"
            ddExtStyles={{ width: '10vw' }}
            options={ddOptions.familyType.map((item) => item.name)}
            selected={familyData.familyType}
            onSelect={(val) =>
              setFamilyData({ ...familyData, familyType: val })
            }
          />
          {isInternalVer && (
            <Switch
              label="ToCreateLegalEntity"
              orientation="v"
              state={familyData.toCreateLegalEntity ? true : false}
              onChange={(val) =>
                setFamilyData({ ...familyData, toCreateLegalEntity: val })
              }
              disable={familyData.isHFT || familyData.familyId}
            />
          )}
          <Switch
            label="Give Up Enabled"
            orientation="v"
            state={familyData.isHFT ? true : familyData.giveupEnabled}
            onChange={(val) =>
              setFamilyData({ ...familyData, giveupEnabled: val })
            }
            disable={familyData.isHFT}
          />
          <Switch
            label="Active"
            orientation="v"
            state={familyData.active}
            onChange={(val) => setFamilyData({ ...familyData, active: val })}
          />
        </div>
        {process.env.REACT_APP_IS_HFT !== 'true' ? (
          <div className="create-fam-s2-cont">
            <SearchMultiSelect
              label="Map Dealers"
              options={ddOptions.dealers.map((item) => item.userName)}
              onSelect={(item, state) => {
                let temp = familyData.mappedDealers;
                if (state) {
                  temp[item] = true;
                } else {
                  delete temp[item];
                }
                setFamilyData({ ...familyData, mappedDealers: temp });
              }}
              selected={familyData.mappedDealers}
            />
            <ShowMultiSelect
              label="Mapped Dealers"
              data={Object.keys(familyData.mappedDealers)}
              removeFunc={(val) => {
                let temp = familyData.mappedDealers;
                delete temp[val];
                setFamilyData({ ...familyData, mappedDealers: temp });
              }}
              extStyles={{ margin: '0 0 0 4vw' }}
            />
          </div>
        ) : null}
        {familyData.entity.map((item, index) => (
          <div className="create-fam-entity-container">
            <div className="entity-container-header">
              <span className="entity-title">Entity {index + 1}</span>
              <div className="family-entity-controls">
                {isInternalVer && (
                  <Switch
                    label="To Create Account"
                    orientation="h"
                    state={familyData.entity[index].toCreateAccClearAcc}
                    onChange={(val) => {
                      let tem = { ...familyData };
                      tem.entity[index].toCreateAccClearAcc = val;
                      setFamilyData(tem);
                    }}
                    // disable={!familyData.entity[index].isNew}
                  />
                )}
                <Switch
                  label="Active"
                  orientation="h"
                  state={familyData.entity[index].active}
                  onChange={(val) => {
                    let tem = { ...familyData };
                    tem.entity[index].active = val;
                    setFamilyData(tem);
                  }}
                />
                <span
                  className="delete-family-entity-subentity"
                  style={{ marginLeft: '1vw' }}
                  onClick={() => {
                    let updatedEntity = { ...familyData };
                    if (
                      updatedEntity &&
                      updatedEntity.entity &&
                      updatedEntity.entity.length <= 1
                    ) {
                      setError(
                        'Atleast one entity should be present in family.'
                      );
                      return;
                    }
                    updatedEntity.entity.splice(index, 1);
                    setFamilyData({
                      ...updatedEntity,
                    });
                    setError('');
                  }}
                >
                  <img
                    src={WhiteDeleteIcon}
                    className="delete-family-entity-icon"
                  />
                </span>
              </div>
            </div>
            <div className="entity-cont">
              <InputField
                label="Entity ID"
                type="text"
                inputWidth="10vw"
                value={familyData.entity[index].entityDisplayId}
                onValueChange={(val) => {
                  let tem = { ...familyData };
                  tem.entity[index].entityDisplayId = val;
                  setFamilyData(tem);
                }}
                validationFunction={(val) => {
                  return {
                    state: val.length > 0,
                    message: val.length > 0 ? '' : 'Field cannot be null',
                  };
                }}
                extStyles={{ margin: 'auto' }}
                disabled={!familyData.entity[index].isNew}
              />
              <DropDown
                label="Entity Type"
                orientation="v"
                ddExtStyles={{ width: '10vw' }}
                options={ddOptions.entityType}
                onSelect={(val) => {
                  let tem = { ...familyData };
                  tem.entity[index].entityType = val;
                  if (val === 'PRO') {
                    tem.entity = tem.entity.map((ent) => ({
                      ...ent,
                      subEntity: ent.subEntity.map((subEnt) => ({
                        ...subEnt,
                        cpCode: '',
                      })),
                    }));
                  }
                  setFamilyData(tem);
                }}
                selected={familyData.entity[index].entityType}
                extStyles={{ margin: 'auto' }}
              />
              <DropDown
                label="Exchange"
                orientation="v"
                ddExtStyles={{ width: '10vw' }}
                options={ddOptions.exchanges}
                onSelect={(val) => {
                  let tem = { ...familyData };
                  tem.entity[index].exchange = val;
                  setFamilyData(tem);
                }}
                selected={familyData.entity[index].exchange}
                extStyles={{ margin: 'auto' }}
              />
            </div>
            {familyData.entity[index].subEntity.map((subEntity, index2) => (
              <div className="sub-entity-subcont">
                <div
                  className={
                    familyData.entity[index].toCreateAccClearAcc == true
                      ? 'sub-entity-data-account'
                      : 'sub-entity-data'
                  }
                >
                  {!familyData.entity[index].toCreateAccClearAcc &&
                    isInternalVer && (
                      <InputField
                        label="Account ID (DB)"
                        type="text"
                        inputWidth="10vw"
                        value={
                          familyData.entity[index].subEntity[index2].entityId
                        }
                        onValueChange={(val) => {
                          let tem = { ...familyData };
                          tem.entity[index].subEntity[index2].entityId = val;
                          setFamilyData(tem);
                        }}
                        validationFunction={(val) => {
                          return {
                            state: val.length > 0,
                            message:
                              val.length > 0 ? '' : 'Field cannot be null',
                          };
                        }}
                        extStyles={{ margin: 'auto' }}
                        // disabled={!familyData.entity[index].isNew}
                      />
                    )}
                  {familyData.entity[index].toCreateAccClearAcc && (
                    <DropDown
                      label="Segment"
                      orientation="v"
                      ddExtStyles={{ width: '10vw' }}
                      options={Object.values(ddOptions.segment)}
                      onSelect={(val) => {
                        let tem = { ...familyData };
                        tem.entity[index].subEntity[index2].segment = val;
                        setFamilyData(tem);
                      }}
                      selected={
                        familyData.entity[index].subEntity[index2].segment
                      }
                      extStyles={{ margin: 'auto' }}
                      class_name="family-entity-subentity-seg-grid"
                      disabled={
                        !familyData.entity[index].subEntity[index2].isNew
                      }
                    />
                  )}
                  {familyData.entity[index].toCreateAccClearAcc && (
                    <InputField
                      label="Display Name"
                      type="text"
                      inputWidth="10vw"
                      value={subEntity.entityName}
                      onValueChange={(val) => {
                        let tem = { ...familyData };
                        tem.entity[index].subEntity[index2].entityName = val;
                        setFamilyData(tem);
                      }}
                      validationFunction={(val) => {
                        return {
                          state: val.length > 0,
                          message:
                            val.length > 0 ? '' : 'Field cannot be empty',
                        };
                      }}
                      extStyles={{ margin: 'auto' }}
                    />
                  )}
                  {familyData.entity[index].toCreateAccClearAcc && (
                    <InputField
                      label="UCC"
                      type="text"
                      inputWidth="10vw"
                      value={subEntity.ucc}
                      onValueChange={(val) => {
                        let tem = { ...familyData };
                        tem.entity[index].subEntity[index2].ucc = val;
                        setFamilyData(tem);
                      }}
                      validationFunction={(val) => {
                        return {
                          state: val.length > 0,
                          message:
                            val.length > 0 ? '' : 'Field cannot be empty',
                        };
                      }}
                      extStyles={{ margin: 'auto' }}
                      class_name="family-entity-subentity-ucc-grid"
                    />
                  )}
                  {familyData.entity[index].toCreateAccClearAcc && (
                    <InputField
                      label="PAN"
                      type="text"
                      inputWidth="10vw"
                      value={subEntity.pan}
                      onValueChange={(val) => {
                        let tem = { ...familyData };
                        tem.entity[index].subEntity[index2].pan = val;
                        setFamilyData(tem);
                      }}
                      validationFunction={(val) => {
                        val = String(val).toUpperCase();
                        let panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
                        return {
                          state: typeof val === 'string' && panRegex.test(val),
                          message:
                            typeof val === 'string' && panRegex.test(val)
                              ? ''
                              : 'Invalid PAN number',
                        };
                      }}
                      extStyles={{ margin: 'auto' }}
                      class_name="family-entity-subentity-pan-grid"
                    />
                  )}
                  {familyData.entity[index].toCreateAccClearAcc && (
                    <InputField
                      label="CP Code"
                      type="text"
                      inputWidth="10vw"
                      value={subEntity.cpCode}
                      disabled={familyData.entity[index].entityType === 'PRO'}
                      onValueChange={(val) => {
                        let tem = { ...familyData };
                        tem.entity[index].subEntity[index2].cpCode = val;
                        setFamilyData(tem);
                      }}
                      // validationFunction={(val) => {
                      //   return {
                      //     state: val.length > 0 && familyData.entity[index].entityType !== 'PRO',
                      //     message: val.length > 0 && familyData.entity[index].entityType !== 'PRO' ? '' : 'Field cannot be empty',
                      //   };
                      // }}
                      extStyles={{ margin: 'auto' }}
                      class_name="family-entity-subentity-cp-grid"
                    />
                  )}
                  {process.env.REACT_APP_IS_HFT == 'false' ? (
                    <div className="create-fam-s2-cont">
                      <SearchMultiSelect
                        label="EMS"
                        options={ddOptions.ems
                          .filter(
                            (item) => item.isHFT == familyData.isHFT || true
                          )
                          .map(
                            (item) => `${item.ssid}-${item.applicationName}`
                          )}
                        onSelect={(item, state) => {
                          let updatedEntity = { ...familyData };
                          if (state) {
                            updatedEntity.entity[index].subEntity[index2].ems[
                              item
                            ] = true;
                          } else {
                            if (
                              updatedEntity.entity[index].subEntity[index2]
                                .ems &&
                              updatedEntity.entity[index].subEntity[index2].ems[
                                item
                              ]
                            ) {
                              delete updatedEntity.entity[index].subEntity[
                                index2
                              ].ems[item];
                            }
                          }
                          setFamilyData({ ...updatedEntity });
                        }}
                        selected={
                          familyData.entity[index].subEntity[index2].ems
                        }
                        extStyles={{ maxHeight: '25vh' }}
                        contStyles={{ width: '20vw' }}
                      />
                      {/* <ShowMultiSelect
                        label="Mapped Dealers"
                        data={Object.keys(familyData.mappedDealers)}
                        removeFunc={(val) => {
                          let temp = familyData.mappedDealers;
                          delete temp[val];
                          setFamilyData({ ...familyData, mappedDealers: temp });
                        }}
                        extStyles={{ margin: '0 0 0 4vw' }}
                      /> */}
                    </div>
                  ) : // <DropDown
                  //   label="EMS"
                  //   orientation="v"
                  //   extStyles={{ margin: 'auto' }}
                  //   ddExtStyles={{ width: '10vw' }}
                  //   // type="multiSelect2"
                  //   options={ddOptions.ems
                  //     .filter((item) => item.isHFT == familyData.isHFT)
                  //     .map((item) => item.applicationName)}
                  //   onSelect={(val) => {
                  //     let updatedEntity = { ...familyData };
                  //     updatedEntity.entity[index].subEntity[index2].ems = {
                  //       [val]: true,
                  //     };
                  //     setFamilyData({ ...updatedEntity });
                  //   }}
                  //   selected={
                  //     familyData.entity[index].subEntity[index2].ems
                  //       ? Object.keys(
                  //           familyData.entity[index].subEntity[index2].ems
                  //         )[0]
                  //       : ''
                  //   }
                  //   class_name="family-entity-subentity-ems-grid"
                  // />
                  null}
                  <span
                    className="delete-family-entity-subentity-icon-cont family-entity-subentity-delete-grid"
                    onClick={() => {
                      let updatedEntity = { ...familyData };
                      if (
                        updatedEntity &&
                        updatedEntity.entity[index] &&
                        updatedEntity.entity[index].subEntity &&
                        updatedEntity.entity[index].subEntity.length <= 1
                      ) {
                        setError(
                          'Atleast one UCC, Segment, Pan, CpCode row should be there in entity.'
                        );
                        return;
                      }
                      updatedEntity.entity[index].subEntity.splice(index2, 1);
                      setFamilyData({
                        ...updatedEntity,
                      });
                      setError('');
                    }}
                  >
                    <img
                      src={WhiteDeleteIcon}
                      className="delete-family-entity-icon-1"
                      alt="delete-icon"
                    />
                  </span>
                </div>
              </div>
            ))}

            <Button
              settings={{
                title: 'Add more',
                icon: AddCircle,
                styles: { margin: '1vh 1vw 1vh 1vw' },
              }}
              click={() => {
                let updatedEntity = { ...familyData };
                updatedEntity.entity[index].subEntity.push({
                  entityId: '',
                  entityName: '',
                  segment: '',
                  ucc: '',
                  pan: '',
                  cpCode: '',
                  clearingAccountId: '',
                  ems: {},
                  isNew: true,
                });
                setFamilyData({
                  ...updatedEntity,
                });
              }}
            />
          </div>
        ))}
        <GenButton
          type="1"
          title="Another Entity"
          fill={false}
          icon={AddCircle}
          extStyle={{ margin: '2vw' }}
          clickAction={() => {
            let updatedEntity = { ...familyData };
            updatedEntity.entity.push({
              toCreateAccClearAcc: true,
              active: false,
              entityDisplayId: '',
              entityName: '',
              entityType: '',
              exchange: '',
              segment: '',
              isNew: true,
              subEntity: [
                {
                  entityId: '',
                  segment: '',
                  ucc: '',
                  pan: '',
                  cpCode: '',
                  clearingAccountId: '',
                  ems: {},
                  isNew: true,
                },
              ],
            });
            setFamilyData({
              ...updatedEntity,
            });
          }}
        />
        <div style={{ padding: '0 0 3vh 2vw' }}>
          <InputField
            orientation="v"
            label="Remarks"
            type="text"
            extStyles={{}}
            inputWidth="80vw"
            value={familyData.remarks}
            onValueChange={(val) =>
              setFamilyData({ ...familyData, remarks: val })
            }
          />
        </div>
      </div>

      <div className="entity-button-group">
        {error != '' && JSON.stringify(error)?.length > 0 && (
          <span className="error-message-fam">{JSON.stringify(error)}</span>
        )}
        <GenButton
          type="1"
          title="Submit" // {editData && editData == -1 ? 'Create' : 'Submit'}
          fill={true}
          extStyle={{ margin: 'auto 1vw auto auto' }}
          clickAction={submitAction}
        />
        <GenButton
          type="1"
          title="Close"
          fill={false}
          extStyle={{ margin: '1vw', padding: '0.5vw 1.5vw' }}
          clickAction={closeForm}
        />
      </div>
    </div>
  );
}

async function createFamilyRequest(req) {
  try {
    const res = await MakeRequest('/family/submit').post(req);
    if (res.status === 200) {
      return { state: true, message: '' };
    } else {
      return { state: false, message: res.data.data.message };
    }
  } catch (error) {
    return { state: false, message: '' };
  }
}

async function modifyFamilyRequest(req) {
  try {
    const res = await MakeRequest('/family/modify').post(req);
    if (res.status === 200) {
      return { state: true, message: '' };
    } else {
      return { state: false, message: res.data.data.message };
    }
  } catch (error) {
    return false;
  }
}

async function getFamilyData(id) {
  try {
    const res = await MakeRequest(`/family/singleFamily?familyId=${id}`).get();
    if (res.status === 200) {
      return res.data.data;
    }
    return true;
  } catch (error) {
    return false;
  }
}

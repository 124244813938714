import './styles.scss';

export default function Label({
  label = '',
  children,
  extStyles = {},
  orientation = 'h',
}) {
  return (
    <div
      style={{
        ...extStyles,
        flexDirection: orientation === 'h' ? 'row' : 'column',
        alignItems: orientation === 'h' ? 'center' : 'flex-start',
      }}
      className="adv-label container"
    >
      <span
        className="label"
        style={{ margin: orientation === 'h' ? '0 0.5em 0 0' : '0 0 0.5em 0' }}
      >
        {label}
      </span>
      {children}
    </div>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Image, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import UiSelector from '../../sharedComponents/Dropdowns';
import UiInput from '../../sharedComponents/Input';
import { useSelector, useDispatch } from 'react-redux';
import info from '../../../assets/img/info.svg';
import Toggle from '../../sharedComponents/Toggle';
import Table from '../../sharedComponents/Table/Table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import OrderDetails from './OrderDetailsCreateEditStrategy';
import moment from 'moment';
import './CreateEditStrategyModal.scss';
import {
  strategyExchange,
  UpdateRow,
  editTemplate,
  tradeModifyTemplate,
  strategyInstrumenType,
  setDataExchange,
} from '../../../actions/strategyBuilderAction';
import SimpleSnackbar from '../../sharedComponents/Toast/Toast';
import {
  getStrategySecurityNameOptions,
  getStrategyExpiresOptions,
  getStrategyStrikesOptions,
  getNickname,
  createStrategy,
  getUserExchangeAccount,
  setCreateStrategyTemplateForm,
  submit,
  setCondition,
  getPricingLogic,
  algoEdit,
  saveAsDraftStrategy,
} from '../../../actions/LiveTradingAction';
import { makeStyles } from '@material-ui/core/styles';
import { reset, clearStatus } from '../../../actions/LiveTradingAction';
import SimpleReactValidator from 'simple-react-validator';
import MaterialUIPickers from '../../sharedComponents/TimePicker/TimePicker';
import { apiConfig, webSocketUrl } from '../../../config/apiConfig';
import { useLocation, useParams } from 'react-router-dom';
import {
  CONFRIM_CLEAR,
  DATA_SAVED,
  PAGE_TITLES,
  START_TIME_EPOCH,
  END_TIME_EPOCH,
} from '../../../utils/constant';
import _ from 'lodash';
import Hotkeys from 'react-hot-keys';
import { handleEscapeKeyEvent } from '../../sharedComponents/keyPressEventHandler';
import { FeedbackPopup, GenButton } from '../..';
import { ExcelIcon } from '../../../assets';
import FileUploadComponent from '../../FileUpload/FileUpload';
import {
  getStrategyTemplate,
  getSecurityDataForEsteeId,
} from '../../../glb_apis';
import { DateTimePicker, InputField, DropDown } from '../../../components';
import ExcelPrevPopup from '../../sharedComponents/customComponent/excelPrevPopup';
import { MakeRequest } from '../../../utils';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import useKeyPress from '../../../custom_Hooks/useShortcut';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: "path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z')",
        },
      },
    },
  },
}));

const CreateStrategyModal = (props) => {
  const [buttonLoading, setButtonLoading] = useState({
    submit: false,
    saveAsDraft: false,
  });

  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));
  const [submitMessage, setSubmitMessage] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isTradeModify, setIsTradeModify] = useState(false);
  const classes = useStyles();
  const custId = localStorage.getItem('customerId');
  const location = useLocation();
  const {
    strategyBuilderReducer: {
      instrumentOptions,
      securityNameOptions,
      expiresOptions,
      strikeOptions,
    },

    liveTradingReducer: { strategy, accntName, strategyFetched },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [validateToasetr, setValidateToasetr] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [modalSubmitStrategy, setModalSubmitStrategy] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [instrument, setInstrument] = useState('');
  const [order, setOrder] = useState();
  const [orderDetailsError, setOrderDetailsError] = useState({});
  const [totalQuantity, setTotalQuantity] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [assistTog, setAssistTog] = useState(true);
  const [isRuleReloadFlag, setIsRuleReloadFlag] = useState(false);
  const [modalClear, setModalClear] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState();
  const [saveMsg, setSaveMsg] = useState('');
  const [popup, setPopup] = useState({
    type: '',
    message: '',
    buttons: '',
    isSuccess: false,
    state: false,
  });
  const [quickUpload, setQuickUpload] = useState();
  const [show, setShow] = useState(false);
  const [strategyTem, setStrategyTem] = useState({});
  const [quPostData, setQuPostData] = useState({
    templateId: -1,
    name: '',
    exchange: '',
    tradingAccountId: -1,
    algoExecParams: [],
    specialRule: '',
    isRuleRelodeReq: false,
    isEdit: false,
    id: null,
    ssid: null,
  });
  const [isTemplatePrefilledComplete, setIsTemplatePrefilledComplete] =
    useState(false);
  const [ssidList, setSsidList] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  let securityValue = window.atob(
    queryParams.get('security') ? queryParams.get('security') : ''
  );

  let tableData = [...strategy.securities];
  let newTable = tableData.map((val, key) => (val = { ...val, key }));

  const {
    id: paramId,
    strategyTemplateId: paramTemplateId,
    isTemplate: paramIsTemplate,
  } = useParams();

  let strategyId = window.atob(
    //bycrypt the strategy id
    paramId ? paramId : ''
  );
  let strategyTemplateId = window.atob(
    //bycrypt the strategy template id
    paramTemplateId ? paramTemplateId : ''
  );
  let isTemplate = window.atob(
    //bycrypt the template id
    paramIsTemplate ? paramIsTemplate : ''
  );

  // * excel preview popup information
  const [famPrev, setFamPrev] = useState();
  const [famPrevFeedBack, setFamPrevFeedback] = useState('');

  const [UOClient, setUOClient] = useState();
  const [heartBeatClient, setHeartBeatClient] = useState();

  useEffect(() => {
    if (UOClient === undefined) {
      setUOClient(new W3CWebSocket(`ws://${apiConfig().websocket}`));
      return;
    }

    if (UOClient) {
      UOClient.onopen = () => {
        const requestBody = {
          eventType: 'RULE_EDIT_WINDOW_PING',
          payload: {
            userId: localStorage.getItem('customerId'),
            ruleId: strategyId,
          },
        };
        UOClient.send(JSON.stringify(requestBody));
        const heartBeatManager = setInterval(() => {
          const requestBody = {
            eventType: 'RULE_EDIT_WINDOW_PING',
            payload: {
              userId: localStorage.getItem('customerId'),
              ruleId: strategyId,
            },
          };
          UOClient.send(JSON.stringify(requestBody));
        }, 5000);
        setHeartBeatClient(heartBeatManager);
      };
      UOClient.onclose = () => {
        console.log('Hearbeat Client CLosed');
      };
      UOClient.onerror = (error) => {
        console.log(error);
      };
    }

    return () => {
      if (UOClient) {
        UOClient.close();
        clearInterval(heartBeatClient);
      }
    };
  }, [UOClient]);

  // * Action can be : SUB: submit, EDT: edit, CAN: cancel
  async function callBackForPopupPrev(action = { action: 0, data: {} }) {
    switch (action.action) {
      case 'SUB':
        const res = await submitExcelprev(action.data);
        if (res) {
          setFamPrev();
          setShow(false);
          setFamPrevFeedback(res.data.data.message);
        }
        break;
      case 'EDT':
        setFamPrev();
        break;
      case 'CAN':
        setFamPrev();
        setShow(false);
        break;
    }
  }

  async function submitExcelprev(data) {
    try {
      const res = await MakeRequest('/excel/upload/ruleSubmit').post({
        submitData: data,
      });
      webSocketConnect();
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  // * ends here

  // * In case of market watch shortcut open prefill template with focused security logic
  const loadPrefilledTemplate = async () => {
    try {
      // Fetch security detail from api and fill template with this
      if (securityValue) {
        let secData = await getSecurityDataForEsteeId(securityValue);
        console.log(secData);
        if (secData && secData.data) {
          secData = secData.data;

          // Loop through security data and fill template details
          let tempStratData = { ...strategy };
          if (tempStratData.securities) {
            for (let security of tempStratData.securities) {
              if (security.instrument === 'Equity') {
                if (secData && secData.Underlying_Ticker)
                  security.securityName = secData.Underlying_Ticker;
                if (secData && secData.Lot_Size) security.lotSize = 1;
              } else if (security.instrument === 'Future') {
                if (secData && secData.Underlying_Ticker)
                  security.securityName = secData.Underlying_Ticker;
                if (secData && secData.Expiry_Date && securityValue.length > 16)
                  security.expiry = secData.Expiry_Date;
                if (
                  secData &&
                  secData.Expiry_Date &&
                  secData.Lot_Size &&
                  securityValue.length > 16
                )
                  security.lotSize = secData.Lot_Size;
              } else if (
                security.instrument === 'Call Option' ||
                security.instrument === 'Put Option'
              ) {
                if (secData && secData.Underlying_Ticker)
                  security.securityName = secData.Underlying_Ticker;
                if (secData && secData.Expiry_Date && securityValue.length > 16)
                  security.expiry = secData.Expiry_Date;
                if (
                  secData &&
                  (secData.Strike_Price || secData.Strike_Price === 0) &&
                  securityValue.length > 16
                )
                  security.strike = secData.Strike_Price;
                if (
                  secData &&
                  secData.Expiry_Date &&
                  secData.Lot_Size &&
                  securityValue.length > 16
                )
                  security.lotSize = secData.Lot_Size;
              }
            }

            dispatch(setCreateStrategyTemplateForm(tempStratData));
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  // * end

  // * For Internal use call | Load valid ssid for dealer
  async function getDealerSSIDs() {
    try {
      const res = await MakeRequest(
        `/dealer/getMappedEMS?isHFT=${process.env.REACT_APP_IS_HFT}`
      ).get();
      if (res && res.data && res.data.data && res.data.data.mappedEMS) {
        let mappedSSID = Array.isArray(res.data.data.mappedEMS)
          ? res.data.data.mappedEMS.map((item) => ({
              id: item.ssid,
              name: item.ssid + '-' + item.applicationName,
            }))
          : [];
        setSsidList(mappedSSID);
      }
    } catch (err) {
      // Do nothing | Empty list display
    }
  }
  // * end

  useEffect(() => {
    const loadTemplate = async () => {
      let isTemplateFlag = isTemplate;
      const res = await getStrategyTemplate(strategyId, isTemplateFlag);
      setStrategyTem(res);
      const temp = {
        ...quPostData,
        templateId: isTemplate ? parseInt(strategyId) : res.strategyTemplateId,
        algoExecParams:
          res.entryLegs[0] && res.entryLegs[0].algoExecution[0]
            ? res.entryLegs[0].algoExecution[0].arguements
            : [],
        name: res.name,
        specialRule:
          res.entryLegs[0] &&
          res.entryLegs[0].algoExecution[0] &&
          res.entryLegs[0].algoExecution[0].data
            ? res.entryLegs[0].algoExecution[0].data === 'EA_EXEC_ALGO_TWAP'
              ? 'TWAP'
              : res.entryLegs[0].algoExecution[0].data ===
                'EA_EXEC_ALGO_CF_SPREAD'
              ? 'SPREAD'
              : res.entryLegs[0].algoExecution[0].data === 'EA_EXEC_ALGO_POV'
              ? 'POV'
              : false
            : false,
      };

      temp.exchange = res.exchange;
      temp.isRuleRelodeReq = res.isRuleReloadReq;
      temp.tradingAccountId = res.securities[0].tradingAccountId;
      temp.ssid = res.ssid;
      setQuPostData(temp);
    };
    loadTemplate();
    if (process.env.REACT_APP_IS_INTERNAL_VERSION === 'true') {
      getDealerSSIDs();
    }
  }, []);

  useEffect(() => {
    dispatch(strategyInstrumenType(strategyId));
    dispatch(strategyExchange());
    dispatch(createStrategy(strategyId, isTemplate));
    dispatch(getPricingLogic());
    if (custId) {
      dispatch(getUserExchangeAccount(custId));
    }
    if (location.pathname.includes('edit-strategy')) {
      //if the pathname is for edit strategy analyzer then open the edit strategy window
      setIsEdit(true);
    }
    if (location.pathname.includes('trade-modify-strategy')) {
      //if the pathname is for modify-trade strategy analyzer then open the modify-trade strategy window
      setIsTradeModify(true);
    }
  }, [strategyId, isTemplate, custId, location.pathname]);

  useEffect(() => {
    strategy.entryLegs?.map((entryLeg) => {
      entryLeg.algoExecution?.map((algo) => {
        algo.arguements?.map((argument) => {
          if (argument.argName === START_TIME_EPOCH) {
            setTime(argument);
          } else if (argument.argName === END_TIME_EPOCH) {
            setTime(argument);
          }
        });
      });
    });
    if (strategyFetched && !isTemplatePrefilledComplete) {
      loadPrefilledTemplate();
      setIsTemplatePrefilledComplete(true);
    }
    setIsRuleReloadFlag(strategy.isRuleReloadReq);
  }, [strategy]);

  useEffect(() => {
    if (strategyFetched && strategy.exchange !== '') {
      let secArr = strategy.securities;
      for (let i = 0; i < secArr.length; i++) {
        dispatch(
          getStrategySecurityNameOptions(
            {
              instrument: secArr[i].instrument,
              index: i,
              key: 'instrument',
              isEdit: isEdit,
              lotSize: secArr[i].lotSize ? secArr[i].lotSize : '',
              expiry: secArr[i].expiry ? secArr[i].expiry : '',
              strike: secArr[i].strike ? secArr[i].strike : '',
            },
            true
          )
        );
      }
    }
  }, [strategy.exchange]);

  // Focus on PopUp
  let focusPopUP = () => {
    let element = document.getElementsByClassName('popUpOK');
    if (element && element.length > 0) {
      element = element[0];
    }
    if (element) {
      element.focus();
    }
  };

  // set default popups after modal
  useEffect(() => {
    switch (popup.type) {
      case 'submitStrategy':
        setPopup({
          ...popup,
          message: submitMessage.message,
          buttons: 'singleButton',
        });
        break;
      case 'clear':
        setPopup({
          ...popup,
          message: CONFRIM_CLEAR,
          buttons: 'doubleButtons',
          nameAccept: 'Confirm',
          nameDecline: 'Cancel',
        });
        break;
      case 'saveAsDraft':
        setPopup({
          ...popup,
          message: saveMsg && saveMsg.message ? saveMsg.message : DATA_SAVED,
          buttons: 'singleButton',
        });
        break;
      default:
        break;
    }
  }, [popup.type, submitMessage, saveMsg]);

  // set functionalty for popup
  const actionOnpopup = () => {
    if (popup.type == 'clear') {
      handleClear(true);
    }
    if (popup && popup.isSuccess == true) {
      handleEscapeKeyEvent({});
    }
    setPopup({ state: false });
  };

  const handleClear = (clear) => {
    //clear function
    if (clear) {
      let clearForm = {
        ...strategy,

        exchange: isEdit ? strategy.exchange : '',
        tradingAccountId: isEdit ? strategy.tradingAccountId : '',
        securities: isEdit
          ? strategy.securities
          : strategy.securities.map((data, index) => {
              return {
                instrument: '',
                securityName: '',
                expiry: '',
                strike: '',
                nickname: data.nickname,
                isAdditional: data.isAdditional,
                lotSize: data.lotSize,
              };
            }),
        constants: strategy.constants.map((data) => {
          return {
            name: data.name,
            value: isEdit && data.name.includes('$') ? data.value : '',
          };
        }),
        entryLegs: strategy.entryLegs.map((i) => {
          return {
            ...i,
            position: i.position.map((j) => {
              return {
                orderQty: '',
                totalQty: '',
                direction: j.direction,
                pricing: j.pricing,
                quantity: j.quantity,
                security: j.security,
              };
            }),
            algoExecution: i.algoExecution.map((k) => {
              return {
                ...k,
                arguements: k.arguements.map((l) => {
                  return {
                    argName: l.argName,
                    argType: l.argType,
                    argValue:
                      l.argType === 'security_nickname' ? l.argValue : '',
                  };
                }),
              };
            }),
          };
        }),

        exitCondition: {
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          action: [
            {
              enumName: '',
              args: [],
            },
          ],
        },
      };
      setStartTime(new Date('2014-08-18T09:30:00'));
      setEndtime(new Date('2014-08-18T15:15:00'));
      setModalClear(false);
      dispatch(reset(clearForm));
      dispatch(clearStatus(true));
    }
  };

  const handleNewTemplate = (e, index) => {
    let form = { ...strategy };
    form.constants[index].value = e.target.value;
    dispatch(setCreateStrategyTemplateForm(form));
  };

  const webSocketConnect = () => {
    var client = new W3CWebSocket(`ws:${webSocketUrl}`, 'echo-protocol');
    if (client) {
      let userId = localStorage.getItem('customerId');
      client.onopen = function () {
        const data = {
          eventType: 'NEW_STRATEGY_CREATED',
          payload: { userId: parseInt(userId) },
        };
        client.send(JSON.stringify(data));
        client.close();
      };
    }
  };

  const inputHandlerExchange = (e) => {
    let form = { ...strategy };
    form.exchange = e.target.value;

    const temp = { ...quPostData };
    temp.exchange = e.target.value;
    setQuPostData(temp);

    let securities =
      form.securities &&
      form.securities.map((el) => {
        return {
          ...el,
          securityName: '',
          expiry: '',
          strike: '',
          tradingAccountId: '',
        };
      });
    form.securities = securities;
    dispatch(setCreateStrategyTemplateForm(form));
    dispatch(setDataExchange({ exchange: e.target.value }));
  };

  const inputHandlerSSID = (e) => {
    try {
      let form = { ...strategy };
      form.ssid = e.target.value;
      dispatch(setCreateStrategyTemplateForm(form));
      let tempData = { ...quPostData };
      tempData.ssid = e.target.value;
      setQuPostData(tempData);
    } catch (err) {
      // Do Nothing
    }
  };

  const inputHandlerAccount = (e) => {
    let form = { ...strategy };
    form['tradingAccountId'] = e.target.value;
    let securities =
      form.securities &&
      form.securities.map((el) => {
        return {
          ...el,
          securityName: '',
          expiry: '',
          strike: '',
        };
      });
    form.securities = securities;
    dispatch(setCreateStrategyTemplateForm(form));
  };

  function inputHandler(e) {
    let index =
      this && (this.index || this.index == 0)
        ? this.index
        : e.rowIndexCustom || e.rowIndexCustom == 0
        ? e.rowIndexCustom
        : -1;
    switch (e.target.name) {
      case 'account':
        index = index || e.rowIndexCustom;
        let account = e.target.value;
        if (account && account.includes('-')) {
          account = account.split('-')[0];
        }
        // Set Trading Account for corresponding row
        let form = { ...strategy };
        setQuPostData({ ...quPostData, tradingAccountId: account });
        if (form && form.securities && form.securities.length > index)
          form.securities[index]['tradingAccountId'] = account;
        dispatch(setCreateStrategyTemplateForm(form));
        let instrName = form.securities[index].instrument;
        setInstrument(instrName);
        dispatch(
          getStrategySecurityNameOptions({
            instrument: instrName,
            index,
            key: 'instrument',
          })
        );
        dispatch(UpdateRow({ value: '', index, key: 'securityName' }));
        dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
        dispatch(UpdateRow({ value: '', index, key: 'strike' }));
        break;

      case 'instrument':
        let instr = e.target.value;
        if (index === 0 && assistTog && strategy.isWrittenEquation) {
          for (let i = 0; i < strategy.securities.length; i++) {
            let ind = i;
            setInstrument(e.target.value);
            dispatch(
              getStrategySecurityNameOptions({
                instrument: instr,
                index: ind,
                key: e.target.name,
              })
            );
            if (instr == 'Equity') {
              dispatch(
                UpdateRow({ value: '', index: ind, key: 'securityName' })
              );
              dispatch(UpdateRow({ value: '', index: ind, key: 'expiry' }));
              dispatch(UpdateRow({ value: '', index: ind, key: 'strike' }));
            }
          }
        } else {
          setInstrument(e.target.value);
          dispatch(
            getStrategySecurityNameOptions({
              instrument: instr,
              index,
              key: e.target.name,
            })
          );
          dispatch(UpdateRow({ value: '', index, key: 'securityName' }));
          dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
          dispatch(UpdateRow({ value: '', index, key: 'strike' }));
        }
        break;
      case 'securityName':
        let securityName = e.target.value;
        dispatch(
          getStrategyExpiresOptions({ securityName, index, key: e.target.name })
        );
        break;

      case 'expiry':
        let expiry = e.target.value;
        if (expiry.includes('$')) expiry = expiry.split('$')[0].trim();
        console.log('expiry', expiry);

        if (index === 0 && assistTog && strategy.isWrittenEquation) {
          for (let i = 0; i < strategy.securities.length; i++) {
            let ind = i;
            dispatch(
              getStrategyStrikesOptions({
                expiry,
                index: ind,
                key: e.target.name,
              })
            );

            // if (instr == 'Equity') {
            //   dispatch(UpdateRow({ value: '', index: ind, key: 'securityName' }));
            //   dispatch(UpdateRow({ value: '', index: ind, key: 'expiry' }));
            //   dispatch(UpdateRow({ value: '', index: ind, key: 'strike' }));
            // }
          }
        } else {
          dispatch(
            getStrategyStrikesOptions({ expiry, index, key: e.target.name })
          );
        }
        break;
      case 'strike':
        let strike = e.target.value;
        if (index === 0 && assistTog && strategy.isWrittenEquation) {
          for (let i = 0; i < strategy.securities.length; i++) {
            let ind = i;
            dispatch(getNickname({ strike, index: ind, key: e.target.name }));

            // if (instr == 'Equity') {
            //   dispatch(UpdateRow({ value: '', index: ind, key: 'securityName' }));
            //   dispatch(UpdateRow({ value: '', index: ind, key: 'expiry' }));
            //   dispatch(UpdateRow({ value: '', index: ind, key: 'strike' }));
            // }
          }
        } else {
          dispatch(getNickname({ strike, index, key: e.target.name }));
        }
        break;
      default:
        dispatch(
          UpdateRow({ value: e.target.value, index, key: e.target.name })
        );
    }
  }

  const submitForm = () => {
    const valid = validator.current.allValid() | true;
    if (valid) {
      setValidateToasetr(false);
      handleSubmitStrategy();
    } else {
      setValidateToasetr(true);
      validator.current.showMessages();
      forceUpdate(1);
      window.scrollTo(0, 0);
    }
    setFormSubmit(true);
  };

  const handleSubmitStrategy = async () => {
    setButtonLoading((old) => {
      return { ...old, submit: true };
    });
    let error = {};
    strategy.entryLegs.map((leg, legIndex) => {
      leg.position.map((pos, posIndex) => {
        if (
          parseInt(pos.orderQty) >= 0 &&
          parseInt(pos.totalQty) >= 0 &&
          pos.orderQty > pos.totalQty
        ) {
          error = {
            ...error,
            [`error_${legIndex}${posIndex}`]:
              'orderQty should be less than totalQty',
          };
        }
      });
    });
    if (Object.keys(error).length) {
      setToaster(true);
    } else {
      setToaster(false);
      if (process.env.REACT_APP_IS_INTERNAL_VERSION != 'true') {
        strategy.ssid = null;
      }
      if (isEdit) {
        console.log('templateForm strategy', strategy);
        let submitResult = await dispatch(editTemplate(strategy));
        webSocketConnect();
        setSubmitMessage(submitResult);
        setFormSubmit(false);
        setModalSubmitStrategy(true);
        // isSuccess made true to close the Window in case of Rule edit
        setPopup({
          type: 'submitStrategy',
          state: true,
          isSuccess:
            submitResult && submitResult.status === 'success' ? true : false,
        });
      } else if (isTradeModify) {
        let submitResult = await dispatch(tradeModifyTemplate(strategy));
        webSocketConnect();
        setSubmitMessage(submitResult);
        setFormSubmit(false);
        setModalSubmitStrategy(true);
        // isSuccess made false to not close the Window in case of Trade Modify submit
        setPopup({
          type: 'submitStrategy',
          state: true,
          isSuccess:
            submitResult && submitResult.status === 'success' ? false : false,
        });
      } else {
        let submitResult = await dispatch(submit(strategyId));
        webSocketConnect();
        setSubmitMessage(submitResult);
        setFormSubmit(false);
        setModalSubmitStrategy(true);
        // isSuccess made false to not close the Window in case of Rule submit
        setPopup({
          type: 'submitStrategy',
          state: true,
          isSuccess:
            submitResult && submitResult.status === 'success' ? true : false,
        });
      }
    }
    setOrderDetailsError(error);
    setButtonLoading((old) => {
      return { ...old, submit: false };
    });
  };

  const saveAsDefault = () => {
    handleSaveStrategy();
  };

  const clearFunc = () => {
    setPopup({ type: 'clear', state: true, isSuccess: false });
  };

  const handleSaveStrategy = async () => {
    setButtonLoading((old) => {
      return { ...old, saveAsDraft: true };
    });
    strategy.strategyTemplateId = strategyTemplateId;
    const createDraft = await dispatch(
      saveAsDraftStrategy(strategy, strategyId)
    );
    setModalSave(true);
    // isSuccess made false to not close the Window in case of Rule submit
    setPopup({
      type: 'saveAsDraft',
      state: true,
      isSuccess:
        createDraft && createDraft.status === 'success' ? false : false,
    });
    setSaveMsg(createDraft);
    setButtonLoading((old) => {
      return { ...old, saveAsDraft: false };
    });
  };

  const inputNameHandler = (e) => {
    let form = { ...strategy };
    form[e.target.name] = e.target.value;

    const temp = { ...quPostData };
    temp.name = e.target.value;
    setQuPostData(temp);

    dispatch(setCreateStrategyTemplateForm(form));
  };

  const selectSearch = (inputValue, index) => {
    let securityName = inputValue;
    dispatch(
      getStrategyExpiresOptions({ securityName, index, key: 'securityName' })
    );
    dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
    dispatch(UpdateRow({ value: '', index, key: 'strike' }));
    if (index === 0 && assistTog) {
      for (let i = 1; i < strategy.securities.length; i++) {
        let index = i;
        dispatch(
          getStrategyExpiresOptions({
            securityName,
            index,
            key: 'securityName',
          })
        );
        dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
        dispatch(UpdateRow({ value: '', index, key: 'strike' }));
      }
    }
  };

  const selectAccountSearch = (e) => {
    if (e && e.type && e.type == 'blur') return;
    let index = e.rowIndexCustom;
    let account = e.target.value;
    if (account && account.includes('-')) {
      account = account.split('-')[0];
    }
    // Set Trading Account for corresponding row
    let form = { ...strategy };
    setQuPostData({ ...quPostData, tradingAccountId: account });
    if (form && form.securities && form.securities.length > index)
      form.securities[index]['tradingAccountId'] = account;
    dispatch(setCreateStrategyTemplateForm(form));
    let instrName = form.securities[index].instrument;
    setInstrument(instrName);
    dispatch(
      getStrategySecurityNameOptions({
        instrument: instrName,
        index,
        key: 'instrument',
      })
    );
    dispatch(UpdateRow({ value: '', index, key: 'securityName' }));
    dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
    dispatch(UpdateRow({ value: '', index, key: 'strike' }));
    if (index === 0 && assistTog) {
      for (let i = 0; i < strategy.securities.length; i++) {
        let index = i;
        let account = e.target.value;
        if (account && account.includes('-')) {
          account = account.split('-')[0];
        }
        // Set Trading Account for corresponding row
        let form = { ...strategy };
        setQuPostData({ ...quPostData, tradingAccountId: account });
        if (form && form.securities && form.securities.length > index)
          form.securities[index]['tradingAccountId'] = account;
        dispatch(setCreateStrategyTemplateForm(form));
        // let instrName = form.securities[index].instrument;
        // setInstrument(instrName);
        // dispatch(
        //   getStrategySecurityNameOptions({
        //     instrument: instrName,
        //     index,
        //     key: 'instrument',
        //   })
        //   );
        //   dispatch(UpdateRow({ value: '', index, key: 'securityName' }));
        //   dispatch(UpdateRow({ value: '', index, key: 'expiry' }));
        //   dispatch(UpdateRow({ value: '', index, key: 'strike' }));
      }
    }
  };

  const setTime = (argument) => {
    //set the start and end time
    let value = argument.argValue.split(':');
    let d = new Date();
    if (value.length > 2) {
      d.setHours(value[0], value[1], value[2]);
    }
    if (argument.argName === START_TIME_EPOCH) {
      setStartTime(d);
    } else if (argument.argName === END_TIME_EPOCH) {
      setEndtime(d);
    }
  };

  const algoHandler = (e, legIndex, algoExeIndex, argIndex) => {
    let value = e.target.value;
    dispatch(algoEdit({ legIndex, algoExeIndex, argIndex, value }));
  };

  const handleTimeDetails = (argument, time) => {
    let fieldData = strategy.entryLegs[0]?.algoExecution[0];
    let algoArgument = strategy.entryLegs[0]?.algoExecution[0]?.arguements.map(
      (arg) => {
        if (
          arg.argName === START_TIME_EPOCH &&
          argument.argName === START_TIME_EPOCH
        ) {
          setStartTime(time);
          return {
            ...arg,
            argValue: moment(time).format('HH:mm:ss'),
          };
        } else if (
          arg.argName === END_TIME_EPOCH &&
          argument.argName === END_TIME_EPOCH
        ) {
          setEndtime(time);
          return {
            ...arg,
            argValue: moment(time).format('HH:mm:ss'),
          };
        } else {
          return { ...arg };
        }
      }
    );
    let field = [{ ...fieldData, arguements: algoArgument }];
    dispatch(setCondition({ field, legIndex: 0, key: 'algoExecution' }));
  };

  function timeModifier(time) {
    let res = time.split(':');
    for (let i = 0; i < res.length; i++) {
      if (res[i].length < 2) {
        res[i] = `0${res[i]}`;
      }
    }
    return res.join(':');
  }

  let table_coulumn = [
    {
      lebel: 'Account ID',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            {isEdit ? (
              <UiInput
                name="account"
                type="text"
                id={`editStra_account_${index}`}
                value={
                  filterTradingAccount &&
                  filterTradingAccount.length &&
                  filterTradingAccount.find(
                    (el) =>
                      el.tradingAccountId ===
                      strategy.securities[index].tradingAccountId
                  ) &&
                  strategy.securities[index].tradingAccountId
                  // strategy.securities[index].tradingAccountId
                }
                className="edit-strategy"
                disabled
              />
            ) : isTradeModify ? (
              <Autocomplete
                name="account"
                className={
                  validator.current.message(
                    'account',
                    strategy.securities[index].securityName,
                    'required',
                    {
                      className: 'error_message',
                    }
                  ) && formSubmit
                    ? 'error-input'
                    : ''
                }
                disabled={strategy.exchange ? false : true}
                id={`combo-box-demo create_instrument_${index}`}
                options={
                  filterTradingAccount &&
                  filterTradingAccount?.map((securities) => {
                    return {
                      id: securities.tradingAccountId,
                      label:
                        securities.tradingAccountId +
                        '-' +
                        securities.accountName,
                    };
                  })
                }
                classes={
                  validator.current.message(
                    'account',
                    params.instrument,
                    'required',
                    {
                      className: 'error_message',
                    }
                  ) && formSubmit
                    ? 'error-input'
                    : ''
                }
                // disableClearable={params.securityName ? false : true}
                getOptionLabel={(option) => option.label}
                inputValue={
                  filterTradingAccount &&
                  filterTradingAccount.length &&
                  filterTradingAccount.find(
                    (el) =>
                      parseInt(el.tradingAccountId) ===
                      parseInt(params.tradingAccountId)
                  ) &&
                  filterTradingAccount.find(
                    (el) =>
                      parseInt(el.tradingAccountId) ===
                      parseInt(params.tradingAccountId)
                  ).tradingAccountId
                }
                onInputChange={(event, newInputValue) => {
                  if (event !== null) {
                    event = {
                      ...event,
                      target: {
                        name: 'account',
                        value: newInputValue,
                      },
                      rowIndexCustom: index,
                    };
                    // inputHandler(event);
                    selectAccountSearch(event);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" />
                )}
              />
            ) : (
              <Autocomplete
                name="account"
                className={
                  validator.current.message(
                    'account',
                    strategy.securities[index].securityName,
                    'required',
                    {
                      className: 'error_message',
                    }
                  ) && formSubmit
                    ? 'error-input'
                    : ''
                }
                disabled={strategy.exchange ? false : true}
                id={`combo-box-demo create_instrument_${index}`}
                options={
                  filterTradingAccount &&
                  filterTradingAccount?.map((securities) => {
                    return {
                      id: securities.tradingAccountId,
                      label: securities.tradingAccountId,
                    };
                  })
                }
                classes={
                  validator.current.message(
                    'account',
                    params.instrument,
                    'required',
                    {
                      className: 'error_message',
                    }
                  ) && formSubmit
                    ? 'error-input'
                    : ''
                }
                // disableClearable={params.securityName ? false : true}
                getOptionLabel={(option) => option.label}
                inputValue={
                  filterTradingAccount &&
                  filterTradingAccount.length &&
                  filterTradingAccount.find(
                    (el) => el.tradingAccountId === params.tradingAccountId
                  )
                    ? params.tradingAccountId
                    : params.tradingAccountId
                }
                onInputChange={(event, newInputValue) => {
                  if (event !== null) {
                    event = {
                      ...event,
                      target: {
                        name: 'account',
                        value: newInputValue,
                      },
                      rowIndexCustom: index,
                    };
                    // inputHandler(event);
                    selectAccountSearch(event);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" />
                )}
              />
            )}
            {validator.current.message(
              'account',
              params.instrument,
              'required',
              {
                messages: { default: 'Required!' },
                className: 'error_message',
              }
            )}
          </>
        );
      },
    },
    {
      lebel: 'Account Name',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            <UiInput
              name="accName"
              type="text"
              id={`account_name_${index}`}
              value={
                filterTradingAccount &&
                filterTradingAccount.length &&
                filterTradingAccount.find(
                  (el) =>
                    el.tradingAccountId ===
                    strategy.securities[index].tradingAccountId
                ) &&
                filterTradingAccount.find(
                  (el) =>
                    el.tradingAccountId ===
                    strategy.securities[index].tradingAccountId
                ).accountName
                // strategy.securities[index].tradingAccountId
              }
              className="edit-strategy"
              disabled
            />
          </>
        );
      },
    },
    {
      lebel: 'Instrument',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            {isEdit ? (
              <UiInput
                name="instrument"
                type="text"
                id={`trade_modify_instrument_${index}`}
                value={
                  instrumentOptions &&
                  instrumentOptions.length &&
                  instrumentOptions.find(
                    (el) => el.key === strategy.securities[index].instrument
                  ) &&
                  instrumentOptions.find(
                    (el) => el.key === strategy.securities[index].instrument
                  ).name
                }
                className="edit-strategy"
                disabled
              />
            ) : isTradeModify ? (
              <UiInput
                name="instrument"
                type="text"
                id={`editStra_instrument_${index}`}
                value={
                  instrumentOptions &&
                  instrumentOptions.length &&
                  instrumentOptions.find(
                    (el) => el.key === strategy.securities[index].instrument
                  ) &&
                  instrumentOptions.find(
                    (el) => el.key === strategy.securities[index].instrument
                  ).name
                }
                className="edit-strategy"
                disabled
              />
            ) : (
              <UiSelector
                single
                role="instrument"
                id={`create_instrument_${index}`}
                disable={
                  strategy.securities[index].tradingAccountId &&
                  strategy.exchange
                    ? false || quickUpload
                    : true || quickUpload
                }
                value={params.instrument}
                className={
                  validator.current.message(
                    'instrument',
                    params.instrument,
                    'required',
                    {
                      className: 'error_message',
                    }
                  ) && formSubmit
                    ? 'error-input'
                    : ''
                }
                listData={
                  instrumentOptions &&
                  instrumentOptions?.map((securities) => {
                    return {
                      id: securities.key,
                      label: securities.name,
                    };
                  })
                }
                handlerFunction={inputHandler.bind({ params, index })}
              />
            )}
            {validator.current.message(
              'instrument',
              params.instrument,
              'required',
              {
                messages: { default: 'Required!' },
                className: 'error_message',
              }
            )}
          </>
        );
      },
    },
    {
      lebel: 'Symbol',
      renderHtml: (params) => {
        let index = params.key;
        return (
          <>
            {isEdit ? (
              <UiInput
                name="symbol"
                id={`combo-box-demo editStra_symbol_${index}`}
                type="text"
                className="edit-strategy"
                value={strategy.securities[index].securityName}
                disabled
              />
            ) : isTradeModify ? (
              <UiInput
                name="symbol"
                id={`combo-box-demo trade_modify_symbol_${index}`}
                type="text"
                className="edit-strategy"
                value={strategy.securities[index].securityName}
                disabled
              />
            ) : (
              <Autocomplete
                autoSelect
                autoHighlight
                name="symbol"
                className={
                  validator.current.message(
                    'symbol',
                    strategy.securities[index].securityName,
                    'required',
                    {
                      className: 'error_message',
                    }
                  ) && formSubmit
                    ? 'error-input'
                    : ''
                }
                disabled={
                  strategy.securities[index].tradingAccountId &&
                  strategy.securities[index].instrument &&
                  securityNameOptions &&
                  securityNameOptions?.length &&
                  securityNameOptions[0] != null
                    ? false || quickUpload
                    : true
                }
                id={`combo-box-demo create_instrument_${index}`}
                options={
                  securityNameOptions[index] &&
                  securityNameOptions[index].length > 0
                    ? securityNameOptions[index].map((sym) => {
                        return {
                          id: sym,
                          label: sym,
                        };
                      })
                    : []
                }
                classes={{
                  clearIndicatorDirty: classes.clearIndicator,
                  popupIndicator: classes.popupIndicator,
                }}
                disableClearable={params.securityName ? false : true}
                getOptionLabel={(option) => option.label}
                inputValue={params.securityName ? params.securityName : ''}
                onInputChange={(event, newInputValue) => {
                  if (event && event.type && event.type == 'blur') return;
                  if (event !== null) {
                    selectSearch(newInputValue, index);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" />
                )}
              />
            )}
            {validator.current.message(
              'symbol',
              params.securityName,
              'required',
              {
                messages: { default: 'Required!' },
                className: 'error_message',
              }
            )}
          </>
        );
      },
    },
    {
      lebel: 'Expiry',
      renderHtml: (params) => {
        let index = params.key;
        let expiresDeta =
          expiresOptions[index] &&
          expiresOptions[index].length > 0 &&
          expiresOptions[index]?.map((exp) => {
            return {
              id:
                strategy.securities[index].instrument == 'Spread'
                  ? exp.expiry.split('$')[0].trim()
                  : exp.expiry,
              label:
                strategy.securities[index].instrument == 'Spread'
                  ? exp.expiry
                      .split('$')
                      .map((item) => item.split('T')[0])
                      .join(' # ')
                  : moment(exp.expiry).format('DD MMM YYYY'),
            };
          });
        return (
          <>
            {isEdit ? (
              <UiInput
                name="expires"
                id={`editStra_expires_${index}`}
                value={
                  params.expiry
                    ? expiresDeta
                      ? params.expiry
                      : moment(params.expiry).format('DD MMM YYYY')
                    : ''
                }
                className="edit-strategy"
                disabled
                type="text"
              />
            ) : isTradeModify ? (
              <UiInput
                name="expires"
                id={`trade_modify_expires_${index}`}
                value={
                  params.expiry
                    ? expiresDeta
                      ? params.expiry
                      : moment(params.expiry).format('DD MMM YYYY')
                    : ''
                }
                className="edit-strategy"
                disabled
                type="text"
              />
            ) : (
              <UiSelector
                single
                role="expiry"
                id={`create_expiry_${index}`}
                disable={
                  strategy.securities[index]?.securityName &&
                  strategy.securities[index]?.instrument === 'Equity'
                    ? true
                    : strategy.securities[index].tradingAccountId &&
                      strategy.securities[index].securityName
                    ? false
                    : true
                }
                value={
                  expiresDeta
                    ? params.expiry
                    : moment(params.expiry).format('DD MMM YYYY')
                }
                listData={expiresDeta ? expiresDeta : []}
                handlerFunction={inputHandler.bind({ params, index })}
                className={
                  strategy.securities[index]?.instrument === 'Equity' &&
                  strategy.securities[index]?.securityName
                    ? ''
                    : validator.current.message(
                        'expiry',
                        strategy.securities[index].expiry,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                    ? 'error-input'
                    : ''
                }
              />
            )}
            {validator.current.message(
              'expiry',
              params.instrument,
              'required',
              {
                messages: { default: 'Required!' },
                className: 'error_message',
              }
            )}
          </>
        );
      },
    },
    {
      lebel: 'Strike',
      renderHtml: (params) => {
        let index = params.key;
        let listDeta =
          strikeOptions[index] &&
          strikeOptions[index].length > 0 &&
          strikeOptions[index]?.map((str) => {
            return {
              id: str,
              label: str,
            };
          });
        return (
          <>
            {isEdit ? (
              <UiInput
                name="strike"
                id={`editStra_strike_${index}`}
                value={params.strike ? parseInt(params.strike) : ''}
                type="text"
                className="edit-strategy"
                disabled
              />
            ) : isTradeModify ? (
              <UiInput
                name="strike"
                id={`trade_modify_strike_${index}`}
                value={params.strike ? parseInt(params.strike) : ''}
                type="text"
                className="edit-strategy"
                disabled
              />
            ) : (
              <UiSelector
                single
                role="strike"
                id={`create_strike_${index}`}
                value={parseInt(params.strike)}
                disable={
                  (strategy.securities[index].expiry &&
                    strategy.securities[index]?.instrument === 'Equity') ||
                  strategy.securities[index]?.instrument === 'Future' ||
                  strategy.securities[index]?.instrument === 'Spread'
                    ? true
                    : strategy.securities[index].tradingAccountId &&
                      strategy.securities[index].expiry
                    ? false
                    : true
                }
                listData={listDeta ? listDeta : []}
                handlerFunction={inputHandler.bind({ params, index })}
                className={
                  (strategy.securities[index]?.instrument === 'Equity' &&
                    strategy.securities[index]?.securityName) ||
                  (strategy.securities[index]?.instrument === 'Future' &&
                    strategy.securities[index]?.securityName) ||
                  (strategy.securities[index]?.instrument === 'Spread' &&
                    strategy.securities[index]?.securityName)
                    ? ''
                    : validator.current.message(
                        'exchange',
                        strategy.securities[index].strike,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                    ? 'error-input'
                    : ''
                }
              />
            )}
          </>
        );
      },
    },
  ];

  const handleOnOffRuleReload = (e) => {
    try {
      let form = { ...strategy };
      let temp = { ...quPostData };
      temp.isRuleRelodeReq = e.target.checked;
      setQuPostData(temp);
      form.isRuleReloadReq = e.target.checked;
      dispatch(setCreateStrategyTemplateForm(form));
      setIsRuleReloadFlag(e.target.checked);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleOnOffAssist = (e) => {
    //assist toggle button
    setAssistTog(e.target.checked);
  };

  if (strategy.exchange) strategy.exchange = String(strategy.exchange).trim();
  let filterClearingAccount = strategy.exchange
    ? accntName?.clearingAccountDetails
        ?.filter((value) => value.exchange === strategy.exchange.trim())
        ?.map((value) => value.clearingAccountId)
    : accntName?.clearingAccountDetails;
  let filterTradingAccount = accntName ? accntName.tradingAccountDetails : [];
  filterTradingAccount = filterTradingAccount?.filter(
    (values) => filterClearingAccount.indexOf(values.clearingAccountId) !== -1
  );
  validator.current.purgeFields();

  useKeyPress(['alt', 's'], () => {
    submitForm();
  });
  useKeyPress(['alt', 'c'], () => {
    clearFunc();
  });

  return (
    <div
      className="previewModalRootWrpaer createstretgy"
      style={{ backgroundColor: '#fff' }}
    >
      <Hotkeys
        keyName="esc"
        onKeyDown={(event) => {
          handleEscapeKeyEvent(event);
        }}
      />
      <Helmet>
        <title>{PAGE_TITLES.createEditStrategyPage}</title>
      </Helmet>
      <div className="previewmodal">
        <div className="templateNameWrapper">
          <React.Fragment>
            <Row>
              <Col sm="3">
                <div className="field">
                  <label className="ex-la-cls">Exchange</label>
                  {isEdit ? (
                    <UiInput
                      name="exchange"
                      value={strategy.exchange.trim()}
                      type="text"
                      disabled
                      id="editstretgy_exchange"
                    />
                  ) : isTradeModify ? (
                    <UiInput
                      name="exchange"
                      value={strategy.exchange.trim()}
                      type="text"
                      disabled
                      id="editstretgy_exchange"
                    />
                  ) : (
                    <UiSelector
                      single
                      role="exchange"
                      id="createstretgy_exchange"
                      value={strategy.exchange.trim()}
                      listData={_.uniqBy(
                        accntName.clearingAccountDetails,
                        'exchange'
                      )?.map((el) => {
                        return {
                          id: el.exchange,
                          label: el.exchange,
                        };
                      })}
                      handlerFunction={inputHandlerExchange}
                      className={
                        validator.current.message(
                          'exchange',
                          strategy.exchange,
                          'required',
                          {
                            className: 'error_message',
                          }
                        ) && formSubmit
                          ? 'error-input'
                          : ''
                      }
                    />
                  )}
                </div>
              </Col>
              <Col sm="3"></Col>
              <Col sm="6">
                <div className="field">
                  {' '}
                  <label className="ex-la-cls"> Strategy Name</label>
                  <UiInput
                    name="name"
                    disabled={isTradeModify}
                    value={strategy.name}
                    handlerfunction={inputNameHandler}
                    max={50}
                    id={isEdit ? 'editstretgy_name' : 'createstretgy_name'}
                    className={
                      validator.current.message(
                        'name',
                        strategy.name,
                        'required',
                        {
                          className: 'error_message',
                        }
                      ) && formSubmit
                        ? 'error-input'
                        : ''
                    }
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </div>
        <div className="middleWrapper">
          <div className="sectionHeadings">
            <p className="sec-header">Securities to Buy/Sell</p>
            <div className="toggleRightSection">
              <div className="isRuleReloadReq">
                <p>Is Rule Reload Required</p>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`} className="rule-reload">
                      <div className="tooltipinnerwrapper">
                        <h3>
                          {' '}
                          Enable this toggle to allow reloading of this rule
                          with position in strategy on next day / strategy
                          reinit and be able to use it in future.
                        </h3>
                      </div>
                    </Tooltip>
                  }
                >
                  <Image src={info} className="info" />
                </OverlayTrigger>
                <Toggle
                  id={'isRuleReloadReq'}
                  handleOnOff={handleOnOffRuleReload}
                  showLabel={false}
                  label={isRuleReloadFlag}
                  checked={isRuleReloadFlag}
                />
                {/* <Switch
                  onChange={handleOnOffRuleReload}
                /> */}
              </div>
              {strategyTem &&
                strategyTem.entryLegs &&
                strategyTem.entryLegs[0].algoExecution &&
                strategyTem.entryLegs[0].algoExecution.length > 0 &&
                strategyTem.entryLegs[0].algoExecution[0].data ===
                  'EA_EXEC_ALGO_TWAP' && (
                  <div className="assist">
                    <p>Quick Upload</p>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`} className="rule-reload">
                          <div className="tooltipinnerwrapper">
                            <h3>
                              Enable this toggle to upload TWAP rules in concise
                              excel
                            </h3>
                          </div>
                        </Tooltip>
                      }
                    >
                      <Image src={info} className="info" />
                    </OverlayTrigger>
                    <Toggle
                      id={'createStrategy'}
                      handleOnOff={(e) => {
                        setQuPostData({
                          ...quPostData,
                          specialRule: e.target.checked ? 'TWAP' : '',
                          isEdit: isEdit,
                          id: isEdit ? strategyId : null,
                        });
                        setQuickUpload(e.target.checked);
                      }}
                      label={assistTog}
                      disabled={isTradeModify} // enable it for edit also
                    />
                  </div>
                )}
              <div className="assist">
                <p>Assist</p>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`} className="assit-mode">
                      <div className="tooltipinnerwrapper">
                        <h3>
                          Securities selected here will be added to define
                          following sections:
                        </h3>
                        <p>1) Leg x:Condition</p>
                        <p> 2) Leg x: Position</p>
                        <p className="leftspace"> 2.1) Buy/Sell Security</p>
                        <p className="leftspace"> 2.2) Define Quantity Logic</p>
                        <p className="leftspace"> 2.3) Define Pricing Logic</p>
                        <p> 3) Exit</p>
                        <p>
                          <span>NOTE</span>: Security drop-downs in above
                          sections will be loaded with the security Nick Names
                          defined here
                        </p>
                      </div>
                    </Tooltip>
                  }
                >
                  <Image src={info} className="info" />
                </OverlayTrigger>
                <Toggle
                  id={'createStrategy'}
                  handleOnOff={handleOnOffAssist}
                  label={assistTog}
                  disabled={isEdit || isTradeModify}
                  checked={assistTog}
                />
              </div>
            </div>
          </div>
          <Table
            className={'responsive table-striped'}
            tableCoulumn={table_coulumn}
            showPagination={false}
            TableData={newTable.filter((value) => !value.isAdditional)}
            id="createstrategy_securities"
          ></Table>

          {newTable.filter((value) => value.isAdditional).length > 0 ? (
            <div className="sectionHeadings">
              <h3> Additional Securities to check conditions</h3>
            </div>
          ) : (
            ' '
          )}

          {newTable.filter((value) => value.isAdditional).length > 0 ? (
            <Table
              className={'responsive'}
              showHeader={false}
              tableCoulumn={table_coulumn}
              showPagination={false}
              TableData={newTable.filter((value) => value.isAdditional)}
              id="createstrategy_additional_securities"
            ></Table>
          ) : (
            ''
          )}
          <Row>
            <Col sm="12">
              {!quickUpload && (
                <div className="algo-wrapper-strategy">
                  {strategy.entryLegs?.map((entryLeg, legIndex) => {
                    return (
                      <>
                        {entryLeg.algoExecution?.map((algo, algoExeIndex) => {
                          if (
                            algo.arguements &&
                            algo.arguements.length &&
                            algo.arguements[0].seqNum
                          )
                            algo.arguements?.sort(
                              (a, b) => a.seqNum - b.seqNum
                            );

                          return (
                            <table className="responsive table-striped table">
                              <tr className="int-table-tr">
                                {algo.arguements?.map((argument, argIndex) => {
                                  let rquiredValidation = 'required';
                                  let inputType = 'text';
                                  let dropdownValues = [];
                                  switch (argument.argType) {
                                    case 'int':
                                      rquiredValidation = 'required|integer';
                                      break;
                                    case 'number':
                                      rquiredValidation = 'required|numeric';
                                      break;
                                    case 'enum':
                                      let letaKey = algo.data;
                                      let enumsData = []; //strategy.algoExecution[letaKey];
                                      let enumarray = [];

                                      for (
                                        let i = 0;
                                        i <
                                        strategy.algoExecution.indicators
                                          .length;
                                        i++
                                      ) {
                                        if (
                                          letaKey ===
                                          strategy.algoExecution.indicators[i]
                                            .enumName
                                        ) {
                                          let enumsDataArray =
                                            strategy.algoExecution.indicators[i]
                                              .args;

                                          for (
                                            let k = 0;
                                            k < enumsDataArray.length;
                                            k++
                                          ) {
                                            if (
                                              enumsDataArray[k].enumName ===
                                              argument.argName
                                            ) {
                                              dropdownValues =
                                                enumsDataArray[k].values;
                                              enumarray = enumsDataArray[k]
                                                .values
                                                ? enumsDataArray[k].values.map(
                                                    (val) => val.enumName
                                                  )
                                                : [];
                                            }
                                          }
                                          enumsData = enumarray;
                                        }
                                      }

                                      rquiredValidation =
                                        enumsData.length > 0
                                          ? ['required', { in: enumsData }]
                                          : 'required';
                                      inputType =
                                        rquiredValidation === 'required'
                                          ? 'text'
                                          : 'dropdown';
                                      break;
                                    case 'boolean':
                                      rquiredValidation = 'required|boolean';
                                      break;
                                    default:
                                      rquiredValidation = 'required';
                                      break;
                                  }
                                  return (
                                    <td className="int-table-td">
                                      {argument.argName === START_TIME_EPOCH ||
                                      argument.argName === END_TIME_EPOCH ? (
                                        <>
                                          {argument.description ? (
                                            <OverlayTrigger
                                              key="top"
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-top_algo`}
                                                  className=""
                                                >
                                                  <div className="">
                                                    <p>
                                                      {argument.description
                                                        ? argument.description
                                                        : ''}
                                                    </p>
                                                  </div>
                                                </Tooltip>
                                              }
                                            >
                                              <Form.Label htmlFor="basic-url">
                                                {argument.displayName
                                                  ? argument.displayName
                                                  : argument.argName?.replace(
                                                      'InEpochMS',
                                                      ''
                                                    )}
                                              </Form.Label>
                                            </OverlayTrigger>
                                          ) : (
                                            <Form.Label htmlFor="basic-url">
                                              {argument.displayName
                                                ? argument.displayName
                                                : argument.argName?.replace(
                                                    'InEpochMS',
                                                    ''
                                                  )}
                                            </Form.Label>
                                          )}
                                          <MaterialUIPickers
                                            selectedDate={
                                              argument.argName ===
                                              START_TIME_EPOCH
                                                ? new Date(startTime)
                                                : argument.argName ===
                                                  END_TIME_EPOCH
                                                ? new Date(endTime)
                                                : ''
                                            }
                                            startTime={startTime}
                                            endTime={endTime}
                                            timeType={
                                              argument.argName ===
                                              START_TIME_EPOCH
                                                ? 'startTime'
                                                : argument.argName ===
                                                  END_TIME_EPOCH
                                                ? 'endTime'
                                                : ''
                                            }
                                            setTimeData={(time) =>
                                              handleTimeDetails(
                                                argument,
                                                time,
                                                legIndex,
                                                argIndex
                                              )
                                            }
                                            isReadOnly={isTradeModify}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {inputType === 'dropdown' ? (
                                            <>
                                              {argument.description ? (
                                                <OverlayTrigger
                                                  key="top"
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip
                                                      id={`tooltip-top_algo`}
                                                      className=""
                                                    >
                                                      <div className="">
                                                        <p>
                                                          {argument.description
                                                            ? argument.description
                                                            : ' '}
                                                        </p>
                                                      </div>
                                                    </Tooltip>
                                                  }
                                                >
                                                  <Form.Label htmlFor="basic-url">
                                                    {argument.displayName
                                                      ? argument.displayName
                                                      : argument.argName?.replace(
                                                          'InEpochMS',
                                                          ''
                                                        )}
                                                  </Form.Label>
                                                </OverlayTrigger>
                                              ) : (
                                                <Form.Label htmlFor="basic-url">
                                                  {argument.displayName
                                                    ? argument.displayName
                                                    : argument.argName?.replace(
                                                        'InEpochMS',
                                                        ''
                                                      )}
                                                </Form.Label>
                                              )}
                                              <UiSelector
                                                single
                                                disable={isTradeModify}
                                                role={argument.argName}
                                                id={argument.argName}
                                                value={argument.argValue}
                                                listData={dropdownValues?.map(
                                                  (el, idx) => {
                                                    return {
                                                      id: el.enumName,
                                                      label: el.displayName,
                                                    };
                                                  }
                                                )}
                                                handlerFunction={(e) =>
                                                  algoHandler(
                                                    e,
                                                    legIndex,
                                                    algoExeIndex,
                                                    argIndex
                                                  )
                                                }
                                                className={
                                                  argument.argName ===
                                                  'ContractIdentifier'
                                                    ? 'isDisable'
                                                    : validator.current.message(
                                                        `${argument.argName}`,
                                                        argument.argValue,
                                                        rquiredValidation,
                                                        {
                                                          className:
                                                            'error_message',
                                                        }
                                                      ) && formSubmit
                                                    ? 'error-input'
                                                    : ''
                                                }
                                              />
                                            </>
                                          ) : (
                                            <UiInput
                                              lebel={
                                                argument.displayName
                                                  ? argument.displayName
                                                  : argument.argName
                                              }
                                              disabled={isTradeModify}
                                              type="text"
                                              value={argument.argValue}
                                              id={argument.argName}
                                              className={
                                                argument.argName ===
                                                'ContractIdentifier'
                                                  ? 'isDisable'
                                                  : validator.current.message(
                                                      `${argument.argName}`,
                                                      argument.argValue,
                                                      rquiredValidation,
                                                      {
                                                        className:
                                                          'error_message',
                                                      }
                                                    ) && formSubmit
                                                  ? 'error-input'
                                                  : ''
                                              }
                                              handlerfunction={(e) =>
                                                algoHandler(
                                                  e,
                                                  legIndex,
                                                  algoExeIndex,
                                                  argIndex
                                                )
                                              }
                                              description={
                                                argument.description
                                                  ? argument.description
                                                  : ''
                                              }
                                            />
                                          )}
                                        </>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </table>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              )}
              {quickUpload && (
                <div className="es-argument-container">
                  <DateTimePicker
                    label="Start Time"
                    time={true}
                    value={timeModifier(
                      quPostData.algoExecParams &&
                        quPostData.algoExecParams.filter(
                          (item) => item.argName === START_TIME_EPOCH
                        )[0].argValue
                    )}
                    onValueChange={(val) => {
                      const temp = { ...quPostData };
                      temp.algoExecParams = temp.algoExecParams.map((item) => {
                        if (item.argName === START_TIME_EPOCH) {
                          return { ...item, argValue: val };
                        } else {
                          return item;
                        }
                      });
                      setQuPostData(temp);
                    }}
                    orient="h"
                  />
                  <DateTimePicker
                    label="End Time"
                    time={true}
                    value={
                      quPostData.algoExecParams &&
                      quPostData.algoExecParams.filter(
                        (item) => item.argName === END_TIME_EPOCH
                      )[0].argValue
                    }
                    onValueChange={(val) => {
                      const temp = { ...quPostData };
                      temp.algoExecParams = temp.algoExecParams.map((item) => {
                        if (item.argName === END_TIME_EPOCH) {
                          return { ...item, argValue: val };
                        } else {
                          return item;
                        }
                      });
                      setQuPostData(temp);
                    }}
                    orient="h"
                  />
                  <InputField
                    label="Max price limit percentage"
                    value={
                      quPostData.algoExecParams &&
                      quPostData.algoExecParams.filter(
                        (item) => item.argName === 'MaxPriceLimitPercentage'
                      )[0].argValue
                    }
                    onValueChange={(val) => {
                      const temp = { ...quPostData };

                      temp.algoExecParams = temp.algoExecParams.map((item) => {
                        if (item.argName === 'MaxPriceLimitPercentage') {
                          return { ...item, argValue: val };
                        } else {
                          return item;
                        }
                      });
                      setQuPostData(temp);
                    }}
                    orientation="h"
                  />
                  <DropDown
                    label="Should Randomize Buckets"
                    options={['Yes', 'No']}
                    selected={
                      quPostData.algoExecParams &&
                      quPostData.algoExecParams.filter(
                        (item) => item.argName === 'ShouldRandomizebuckets'
                      )[0].argValue === 'True'
                        ? 'Yes'
                        : 'No'
                    }
                    onSelect={(val) => {
                      const temp = { ...quPostData };
                      temp.algoExecParams = temp.algoExecParams.map((item) => {
                        if (item.argName === 'ShouldRandomizebuckets') {
                          return {
                            ...item,
                            argValue: val === 'No' ? 'False' : 'True',
                          };
                        } else {
                          return item;
                        }
                      });

                      setQuPostData(temp);
                    }}
                  />
                </div>
              )}
            </Col>
            <Col sm="12">
              <div className="newTemplateName">
                {strategy.constants &&
                  strategy.constants.map((list, index) => {
                    const isDoubleDollarField = list.name.includes('$');
                    const isDoubleDollarConstant =
                      isDoubleDollarField && isEdit;
                    return (
                      <div key={list}>
                        <p>{list.name.replace(/\$/g, '')}</p>
                        <UiInput
                          type="text"
                          disabled={isTradeModify || isDoubleDollarConstant}
                          role="price-spread"
                          id={`price-spread_${index}`}
                          name={list.name.replace(/\$/g, '')}
                          value={list.value}
                          newTemplateNameConstants={true}
                          className={validator.current.message(
                            'price-spread',
                            list.value,
                            '',
                            {
                              className: 'error_message',
                            }
                          )}
                          handlerfunction={(e) => handleNewTemplate(e, index)}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="divider"></div>
            </Col>
          </Row>
          {!quickUpload && (
            <Row>
              <Col sm="12">
                <OrderDetails
                  order={order}
                  prevTableData={tableData}
                  orderDetailsError={orderDetailsError}
                  setOrder={setOrder}
                  totalQuantity={totalQuantity}
                  setTotalQuantity={setTotalQuantity}
                  formSubmit={formSubmit}
                  validator={validator}
                  strategyId={strategyTemplateId}
                  isReadOnly={isTradeModify}
                  extData={quPostData}
                  isEdit={isEdit}
                />
              </Col>
            </Row>
          )}
          <div className="createStrategy-bottom-foot">
            {process.env.REACT_APP_IS_INTERNAL_VERSION === 'true' ? (
              <Row>
                <Col
                  sm={3}
                  className="rule-create-align-items-center-container"
                >
                  <span className="rule-create-align-items-center-item">
                    SSID :
                  </span>
                </Col>
                <Col xs={5}>
                  <div className="select ">
                    <UiSelector
                      single
                      role="ssid"
                      id="createstretgy_ssid"
                      value={strategy.ssid}
                      listData={_.uniqBy(ssidList, 'name')?.map((el) => {
                        return {
                          id: el.id,
                          label: el.name,
                        };
                      })}
                      handlerFunction={inputHandlerSSID}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}
            <GenButton
              title="UPLOAD EXCEL"
              icon={ExcelIcon}
              fill={false}
              clickAction={() => setShow(true)}
              extStyle={{ margin: 'auto auto auto 1vw' }}
            />
            <GenButton
              fill={true}
              clickAction={() => submitForm()}
              title="SUBMIT"
              disabled={quickUpload || buttonLoading.submit}
              extStyle={{ margin: 'auto' }}
              loading={buttonLoading.submit}
            />
            {!isTradeModify ? (
              <GenButton
                fill={false}
                clickAction={() => saveAsDefault()}
                title="SAVE AS DEFAULT"
                disabled={quickUpload}
                extStyle={{ margin: 'auto' }}
                loading={buttonLoading.saveAsDraft}
              />
            ) : null}
            {!isTradeModify ? (
              <GenButton
                fill={false}
                clickAction={clearFunc}
                title="CLEAR"
                disabled={quickUpload}
                extStyle={{ margin: 'auto' }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <FileUploadComponent
        show={show}
        setShow={setShow}
        setSuccessUpload={setShow}
        screenType={`rule`}
        quickUpload={quickUpload}
        strategyId={isEdit ? strategyId : strategyTemplateId}
        rulePostBody={{ ...quPostData, quickUpload: quickUpload }}
        callBackFunc={(val) => setFamPrev(val)}
      />

      <FeedbackPopup
        message={
          famPrevFeedBack
            ? `${JSON.stringify(
                famPrevFeedBack
              )}. Do you want to upload again ?`
            : ''
        }
        type="a"
        acceptAction={() => {
          setShow(true);
          setFamPrevFeedback('');
        }}
        rejectAction={() => {
          setFamPrevFeedback('');
        }}
      />
      <ExcelPrevPopup
        state={famPrev !== undefined}
        data={famPrev && famPrev.data}
        callBackFunction={callBackForPopupPrev}
        previewTitle="Preview Stratagies"
      />

      {toaster ? (
        <SimpleSnackbar
          toaster={toaster}
          setToaster={setToaster}
          message={'Order quantity must be smaller than total quantity'}
        />
      ) : null}
      {validateToasetr ? (
        <SimpleSnackbar
          toaster={validateToasetr}
          setToaster={setValidateToasetr}
          message={Object.values(validator.current.getErrorMessages()).find(
            (a) => a != null
          )}
        />
      ) : null}
      {popup.state && (
        <FeedbackPopup
          message={popup.state ? popup.message : ''}
          type={popup.buttons == 'singleButton' ? 'f' : 'a'}
          acceptAction={actionOnpopup}
          rejectAction={() => {
            if (popup && popup.isSuccess == true) {
              handleEscapeKeyEvent({});
            }
            setPopup({ state: false });
          }}
        />
      )}
      {/* {popup.state ? (
        <Popup actionButton={actionOnpopup} popupDetails={popup} />
      ) : null} */}
    </div>
  );
};

export default CreateStrategyModal;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBuilderPositions,
  setConstants,
} from '../../../../../actions/strategyBuilderAction';
import NewDropdown from '../../../../sharedComponents/NewDropdown/NewDropdown';

const LogicalConditions = ({
  id = '',
  legIndex = 0,
  positionIndex = 0,
  pricingLogicFlag = true,
  marketFormData = [],
  style = {},
  value,
  setValue,
  placeholder = 'Add Indicator, Number, Bracket',
}) => {
  const [marketForm, setMarketForm] = useState([]);

  const dispatch = useDispatch();

  const {
    strategyBuilderReducer: {
      strategyTemplateForm,
      pricingQuantOperation,
      pricingCondition,
      isEdit,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (marketFormData && marketFormData.length > 0)
      setMarketForm(marketFormData);
    if (pricingLogicFlag && !isEdit) {
      let updatedPosition = marketForm.map((el, index) => {
        return {
          type: el.type,
          data: el.data,
          arguements: el.arguements,
        };
      });

      dispatch(
        setBuilderPositions({
          updatedPosition,
          legIndex,
          positionIndex,
          key: 'position',
          subKey: 'pricing',
        })
      );
    } else if (pricingLogicFlag && isEdit && marketForm.length > 0) {
      let updatedPosition = marketForm.map((el, index) => {
        return {
          type: el.type,
          data: el.data,
          arguements: el.arguements,
        };
      });

      dispatch(
        setBuilderPositions({
          updatedPosition,
          legIndex,
          positionIndex,
          key: 'position',
          subKey: 'pricing',
        })
      );
    }
    if (
      marketForm[marketForm.length - 1]?.conditionName ===
      'New Template Parameter'
    ) {
      let obj = {
        name: marketForm[marketForm.length - 1].arguements[0].argValue,
        value: null,
      };
      dispatch(setConstants({ obj, legIndex, key: 'constants' }));
    }
  }, [marketForm]);

  useEffect(() => {
    let currPosition =
      strategyTemplateForm.entryLegs[legIndex].position[positionIndex];
    if (
      isEdit &&
      currPosition &&
      currPosition.pricing &&
      currPosition.pricing.length > 0
    ) {
      let quantOperations = pricingQuantOperation.map((el) => {
        return {
          type: el.type,
          displayName: el.displayName,
          data: el.values,
          enumName: el.enumName ? el.enumName : '',
        };
      });
      let quantDropDown = [...pricingCondition, ...quantOperations];
      if (
        currPosition &&
        currPosition.pricing &&
        currPosition.pricing.length > 0
      ) {
        let foundQuant = [];
        for (let i = 0; i < currPosition.pricing.length; i++) {
          for (let j = 0; j < quantDropDown.length; j++) {
            for (let k = 0; k < quantDropDown[j].data.length; k++) {
              if (
                quantDropDown[j].data[k].enumName ===
                  currPosition.pricing[i].data?.trim() ||
                (currPosition.pricing[i].data?.trim() === 'PARAMETER' &&
                  quantDropDown[j].data[k].enumName ===
                    currPosition.pricing[i]?.arguements[0]?.argName)
              ) {
                let finalObj = {
                  ...currPosition.pricing[i],
                  conditionName: quantDropDown[j].data[k].displayName,
                };
                foundQuant.push(finalObj);
              }
            }
          }
        }
        let finalQuant = foundQuant.map((el, index) => {
          return {
            ...el,
            eqIndex: index,
          };
        });

        let eqArr = foundQuant.map((elm, index) => {
          return {
            enumName: elm.data,
            type: elm.type === 'OPERATION' ? 'OPERATOR' : 'OPERAND',
            value: elm.conditionName,
          };
        });

        setMarketForm(finalQuant);
      }
    }
  }, [isEdit]);

  return (
    <div className="sepratewithcross" style={style}>
      <NewDropdown
        id={id}
        listData={pricingCondition}
        placeHolder={placeholder}
        legIndex={legIndex}
        positionIndex={positionIndex}
        componentKey={setValue ? 'strategyanalyzer' : 'pricing'}
        value={value}
        setValue={setValue}
        extStyles={{ width: '100%' }}
      />
    </div>
  );
};

export default LogicalConditions;

import { MakeRequest } from '../utils';

export default function shortcutReducer(state = new Map(), action) {
  switch (action.type) {
    case 'SET_KEY_MAP':
      const tempMap = new Map();
      action.payload?.forEach((element) => {
        tempMap.set(element.name, {
          keyCombination: element.keyComb,
          execOptions: element.execOptions,
          isRuleShortcut: element.isRuleShortcut,
        });
      });
      return tempMap;
    default:
      return state;
  }
}

export async function addShortcutAction(dispatch) {
  try {
    const result = await MakeRequest('/user/userShortcuts').get();
    dispatch({
      type: 'SET_KEY_MAP',
      payload: result.data.data.shortcutData,
    });
  } catch (error) {
    console.log(error);
  }
}

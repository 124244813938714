import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player/file';
import './styles.css';
import { LPHeader, AnimatedButton,AnimatedButton2,GenButton } from '../../components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import Feature1 from '../../assets/img/features-icon-1.svg';
import Feature2 from '../../assets/img/features-icon-2.svg';
import Feature3 from '../../assets/img/features-icon-3.svg';
import Feature4 from '../../assets/img/features-icon-4.svg';
import Bg1Img from '../../assets/img/lgS1bgimg.png';
import EsteeLogo from '../../assets/img/EsteeLogo.png';
import Dots from '../../assets/img/dotsLandingPage.png';
import CupImage from '../../assets/img/cup-img.svg';
import StocksGoUp from '../../assets/img/img2.svg';
import LTPreview from '../../assets/img/livetrading.png';
import EAPreview from '../../assets/img/ExecutionAlgorithm.jpg';
import PBPreview from '../../assets/img/PreBuiltstrategy.png';
import SBPreview from '../../assets/img/StrategyBuilder.png';
import Section5 from '../../assets/img/section4.svg';
import Section5p1 from '../../assets/img/shape2.svg';
import Location from '../../assets/img/locationIcon.svg';
import Email from '../../assets/img/email.svg';
import Phone from '../../assets/img/phone.svg';
import Facebook from '../../assets/img/facebook.svg';
import Twitter from '../../assets/img/twitter.svg';
import Linkdin from '../../assets/img/linkdin.svg';
import Youtube from '../../assets/img/youtube.svg';
import S1Background from '../../assets/img/lp-s1-background.png';
import Video from '../../assets/video/landingPageDemoVideo.mp4';
import { MakeRequest } from '../../utils';
import InfraPageLoad from './tradingInfra';
import stradeVideo from '../../assets/video/Strade.mp4';
import thumbnail from '../../assets/img/Thumbanil@2x.png';
import playButton from '../../assets/img/playBtn.svg';
import { borderRadius } from '@mui/system';

const LandingPage = () => {
  const [feSelect, setFeSelect] = useState(0);
  const selectArr = [
    { id: 0, title: 'Live Trading Web Interface' },
    { id: 1, title: 'Strategy Builder' },
    { id: 2, title: 'Pre-built Strategy Templates' },
    { id: 3, title: 'Execution Algorithms' },
  ];
  const cardFeatures = [
    new CardFormat(
      Feature1,
      'Speed',
      'We offer state -of-the art Strategy Execution with co-location and Tick-By-Tick Market data setup, enabling execution speeds of the order of microseconds.'
    ),
    new CardFormat(
      Feature2,
      'Reliability',
      'Our systems can support scales of over 10MM+ orders per day'
    ),
    new CardFormat(
      Feature3,
      'Customization',
      'Once a user has a trading idea, he/she can build a custom, multiparameter, trading strategy using our Advanced Strategy Builder'
    ),
    new CardFormat(
      Feature4,
      'Reusability',
      'Using Strategy Templates, the user can take Intra-day trade decisions by customizing the template parameters based on the real-time market conditions.'
    ),
  ];

  const handleDownloadAndOpen = () => {
    debugger;
    const fileUrl = 'https://drive.google.com/uc?export=download&id=1u3o34yis0GCr_z5iejvcRntArmIyiQ2u'; // Modified link for direct download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'Strade_brochure.pdf'); // Change the name if you know the file type
    document.body.appendChild(link);
    link.click();
    link.remove();
    // Open the file in a new tab
    //window.open(fileUrl, '_blank');
  };

  const productAreaRef = useRef();
  const tradingInfraRef = useRef();

  // these refs are used by the schedule demo pop up to store form values
  // should be moved to a component for the same
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const companyRef = useRef();
  const jobTitleRef = useRef();
  const messageRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleScheduleDemoClose = () => {
    setShowModal(false);
  };

  const handleScheduleDemoOpen = () => {
    const url = 'https://qualtricsxmkbbh9wbyc.qualtrics.com/jfe/form/SV_822VWpAJFJjaFQG';
      // Open the link in a new tab
    window.open(url, '_blank');
    //setShowModal(true);
  };

  const handleScheduleDemo = async () => {
    try {
      setSubmitted(true);
      const postObject = {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        company: companyRef.current.value,
        jobTitle: jobTitleRef.current.value,
        message: messageRef.current.value,
      };

      let apiResponse = await MakeRequest('/schedule-demo').post(postObject);

      setError(apiResponse.status != 200);
      setAlertMessage(apiResponse.data.data.message);
      setSubmitted(false);
      setOpen(true);
      handleScheduleDemoClose();
    } catch (error) {
      console.log(error);
      setAlertMessage(error.message);
      setSubmitted(false);
      setOpen(true);
    }
  };

  function loadPreview() {
    switch (feSelect) {
      case 0:
        return <img src={LTPreview} className="preview-image" />;
      case 1:
        return <img src={SBPreview} className="preview-image" />;
      case 2:
        return <img src={PBPreview} className="preview-image" />;
      case 3:
        return <img src={EAPreview} className="preview-image" />;
    }
  }

  return (
    <div className="lp-main-container">
      <LPHeader
        productAreaRef={productAreaRef}
        tradingInfraRef={tradingInfraRef}
        handleClick={handleScheduleDemoOpen}
      />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={error ? 'error' : 'info'}
          sx={{ width: '100%', fontFamily: "'Poppins', sans-serif" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className="lp-scroll-area">
        <div className="lp-s1" back>
          <img src={S1Background} className="bgImage-lp-s1" />
          <p className="lp-sec1-title">
            THE FASTEST <span className="orangeText">NO CODE</span>
            <br />
            <span className="orangeText">ALGO TRADING</span> PLATFORM
          </p>
          <p className="lp-sec1-subtitle">
            DESIGNED FOR INSTITUTIONS AND THEIR CLIENTS
          </p>
          <AnimatedButton onClick={handleScheduleDemoOpen} />
          <AnimatedButton2 onClick={handleDownloadAndOpen} />     
          <div className="demoVideoContainer">
            <video
              width="100%"
              height="100%"
              autoPlay
              loop
              muted
              className="video"
            >
              <source src={Video} type="video/mp4" />
              Your Browser does not support videos
            </video>
          </div>
        </div>
        <div className="lp-s2">
          <p className="lp-s2-title">Features & Benefits</p>
          <div className="lp-s2-div1"></div>
          <p className="lp-s2-sub-title">The STrade Advantage</p>
          <div className="lp-s2-card-arrange">
            <div className="lp-s2-card-back-1 align-vertical">
              <img src={cardFeatures[0].icon} className="lp-s2-card-icon" />
              <p className="lp-s2-card-title">{cardFeatures[0].title}</p>
              <p className="lp-s2-card-description">{cardFeatures[0].desc}</p>
            </div>
            <div className="lp-s2-card-back-2 align-vertical">
              <img src={cardFeatures[1].icon} className="lp-s2-card-icon" />
              <p className="lp-s2-card-title">{cardFeatures[1].title}</p>
              <p className="lp-s2-card-description">{cardFeatures[1].desc}</p>
            </div>
            <div className="lp-s2-card-back-3 align-vertical">
              <img src={cardFeatures[2].icon} className="lp-s2-card-icon" />
              <p className="lp-s2-card-title">{cardFeatures[2].title}</p>
              <p className="lp-s2-card-description">{cardFeatures[2].desc}</p>
            </div>
            <div className="lp-s2-card-back-4 align-vertical">
              <img src={cardFeatures[3].icon} className="lp-s2-card-icon" />
              <p className="lp-s2-card-title">{cardFeatures[3].title}</p>
              <p className="lp-s2-card-description">{cardFeatures[3].desc}</p>
            </div>
          </div>
        </div>
        <div className="lp-s3">
          <div className="lp-s3-s1">
            <img src={Dots} className="lp-s3-s1-tr" />
            <img src={Dots} className="lp-s3-s1-bl" />
            <img src={CupImage} alt="CupImage" className="lp-s3-s1-ci" />
            <img src={StocksGoUp} alt="StockGoUp" className="lp-s3-s1-si" />
            <div className="lp-s3-s1-cc">
              <img src={EsteeLogo} className="lp-s3-s1-logo" />
            </div>
          </div>
          <div className="lp-s3-s2">
            <p className="lp-s3-s2-t1">Powered by Estee Advisors</p>
            <span className="lp-s3-s2-div"></span>
            <p className="lp-s3-s2-t2">
              <b>12+ years </b>of HFT technology experience
            </p>
            <p className="lp-s3-s2-t3">
              Estee is a quant-based investment management firm, & a pioneer in
              building algorithmic investment and execution products. It is a
              <b> SEBI-registered</b> Portfolio Manager Service <b>(PMS)</b>{' '}
              provider and a registered <b>broker</b>.
            </p>
            <ul>
              <li className="lp-s3-s2-t4">
                Estee has won the coveted{' '}
                <b>“Institutional Member of the Year 2019”</b> award from
                National Stock Exchange. This award recognizes the best
                performing broker for domestic and foreign Institutional clients
                for the year 2019
              </li>
              <li className="lp-s3-s2-t5">
                I-Alpha is Estee’s flagship market-neutral Arbitrage Fund, one
                of the largest in India. Since its inception in 2009,{' '}
                <b>
                  I-Alpha has not had a single negative return month till date.
                </b>
              </li>
            </ul>
            <a
              href="http://www.esteeadvisors.com"
              className="lp-s3-s2-t6"
              target="_blank"
            >
              Know More
            </a>
          </div>
        </div>
        <div className="lp-s4" ref={productAreaRef}>
          <p className="lp-s4-t1">Product Offerings</p>
          <span className="lp-s4-div"></span>
          <p className="lp-s4-t2">STrade in Action</p>
          <div className="lp-s4-cont">
            <div className="lp-s4-cont-s1">
              {selectArr.map((item) => (
                <div
                  className={
                    item.id === feSelect
                      ? 'lp-s4-cont-selected'
                      : 'lp-s4-cont-nselec'
                  }
                  onClick={() => setFeSelect(item.id)}
                >
                  {item.title}
                </div>
              ))}
            </div>
            <div className="lp-s4-cont-s2">{loadPreview()}</div>
          </div>
        </div>
        <div className="lp-s5" ref={tradingInfraRef}>
          {/* <img src={Section5} className="lp-s5-img" /> */}
          <InfraPageLoad />
        </div>
        <div className="lp-s6">
          <div className="video-source">
            <ReactPlayer
              url={stradeVideo}
              light={thumbnail}
              width="100%"
              height="100%"
              style={{ borderRadius: '30px' }}
              playing
              loop
              controls
              playIcon={<img src={playButton} className="play-icon"></img>}
            />
          </div>
        </div>
        <div className="lp-s7">
          <p className="lp-s7-t1">
            Stock Broker (Member: NSE, BSE, MSEI, <br />
            MCX) vide
            <br /> SEBI Registration Number <br /> INZ000170130
          </p>
          <div className="lp-s7-t123">
            <div className="lp-s7-t2-contact">
              <img src={Email} alt="email" className="contact-icon" />
              <p className="contact-content">strade@esteeadvisors.com</p>
            </div>
            <div className="lp-s7-t2-contact">
              <img src={Phone} alt="email" className="contact-icon" />
              <p className="contact-content">+91 124 463 7700</p>
            </div>
          </div>
          <div className="lp-s7-t6">
            <div className="book-demo" onClick={handleScheduleDemoOpen}>
              Schedule a demo
            </div>
            <div className="social-handles">
              <a href="https://www.facebook.com/EsteeAdvisors" target="_blank">
                <img src={Facebook} className="contact-icons" />
              </a>
              {/* <a href="url" target="_blank">
                <img src={Youtube} className="contact-icons" />
              </a> */}
              <a href="https://www.linkedin.com/company/estee" target="_blank">
                <img src={Linkdin} className="contact-icons" />
              </a>
              <a href="https://twitter.com/EsteeAdvisors" target="_blank">
                <img src={Twitter} className="contact-icons" />
              </a>
            </div>
          </div>
          <div className="lp-s7-t2-cont">
            <img src={Location} />
            <br />
            <p className="lp-s7-t2">Corporate Office</p>
            <p className="lp-s7-t3">
              Estee Advisors Private Limited <br />
              8th Floor, Block I, Vatika Business Park, Sector 49, <br />
              Gurgaon, Haryana -122001 , India
            </p>
          </div>
          <div className="lp-s7-t2-cont">
            <img src={Location} />
            <br />
            <p className="lp-s7-t2">Registered Office</p>
            <p className="lp-s7-t3">
              Estee Advisors Private Limited <br /> PO5-01A, PO5-01B, PO5-01C,
              5th Floor, Tower A, WTC Gift City, <br /> Block 51, Road 5E, Zone
              5, Gity City, Gandhinagar, <br /> Gujarat – 382355
            </p>
          </div>
        </div>
        <div className="lp-s8">
          Estee Advisors Private Limited. All rights reserved.
        </div>
      </div>

      {/* This is for the schedule demo popup and the form, can be moved to a different component later */}
      <Dialog open={showModal} onClose={handleScheduleDemoClose}>
        <DialogTitle>Schedule a Demo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To schedule a demo please enter your details below.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="firstname"
            label="First Name"
            fullWidth
            variant="standard"
            inputRef={firstNameRef}
          />
          <TextField
            margin="dense"
            id="lastname"
            label="Last Name"
            fullWidth
            variant="standard"
            inputRef={lastNameRef}
          />
          <TextField
            required
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            inputRef={emailRef}
          />
          <TextField
            required
            margin="dense"
            id="phone"
            label="Phone"
            fullWidth
            variant="standard"
            inputRef={phoneRef}
          />
          <TextField
            margin="dense"
            id="company"
            label="Company"
            fullWidth
            variant="standard"
            inputRef={companyRef}
          />
          <TextField
            margin="dense"
            id="jobTitle"
            label="Job Title"
            fullWidth
            variant="standard"
            inputRef={jobTitleRef}
          />
          <TextField
            margin="dense"
            id="message"
            label="Message"
            fullWidth
            variant="standard"
            inputRef={messageRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScheduleDemoClose}>Cancel</Button>
          <Button onClick={handleScheduleDemo} disabled={submitted}>
            Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

class CardFormat {
  constructor(icon, title, description) {
    this.icon = icon;
    this.title = title;
    this.desc = description;
  }
}

export default LandingPage;

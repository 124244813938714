import axios from 'axios';
import { url } from '../config/apiConfig';
import {
  GET_SINGLE_FAMILY,
  GET_ALL_FAMILY,
  GET_ALL_FAMILY_TYPE,
  GET_ALL_ENTITY_TYPE,
  GET_FAMILY_DEALER,
  UPDATE_FAMILY_FORM,
  GET_ALL_FAMILY_SEGMENT,
  CREATE_FAMILYDATA,
  GET_ALL_FAMILY_EMS,
  GET_ALL_FAMILY_EXCHANGES,
  SET_EDIT_FAMILY,
} from './types';

export const singlefamilyData = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/singleFamily`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        familyId: id,
      },
    });

    if (result) {
      let entArr = [];
      if (result.data.data && result.data.data.entities) {
        entArr = result.data.data.entities.map((el) => {
          return {
            ...el,
            block: true,
          };
        });
      }
      dispatch({
        type: GET_SINGLE_FAMILY,
        payload: {
          ...result.data.data,
          familyType: {
            id: '',
            name: result.data.data.familyType,
          },
          dealerList:
            result.data.data && result.data.data.dealers
              ? result.data.data.dealers
              : [],
          entityList:
            result.data.data &&
            result.data.data.entities &&
            result.data.data.entities.length
              ? entArr
              : [
                  {
                    entityName: '',
                    entityType: '',
                    exchange: '',
                    segment: '',
                    pan: '',
                    ucc: '',
                    cpCode: '',
                    ems: '',
                  },
                ],
          entityListLength:
            result.data.data && result.data.data.entities
              ? result.data.data.entities.length
              : '',
        },
      });
    } else {
      dispatch({
        type: GET_SINGLE_FAMILY,
        payload: null,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SINGLE_FAMILY,
      payload: {
        familyName: '',
        familyType: {
          id: '',
          name: '',
        },
        dealerList: [],
        entityList: [
          {
            entityName: '',
            entityType: '',
            exchange: '',
            segment: '',
            pan: '',
            ucc: '',
            cpCode: '',
            ems: '',
          },
        ],
        entityListLength: '',
      },
    });
  }
};

export const editFamilyData = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  let finalData = data;
  let entityArr = finalData.entityList.map((li) => {
    return {
      activeStatus: li.activeStatus,
      entityDisplayId: li.entityDisplayId,
      entityName: li.entityName,
      entityId: li.entityId ? li.entityId : 0,
      entityType: li.entityType,
      exchange: li.exchange,
      segment: li.segment,
      pan: li.pan,
      ucc: li.ucc,
      cpCode: li.cpCode,
      ems: li.ems,
      address: li.address ? li.address : '',
      aum: li.aum ? li.aum : '',
      brokerId: li.brokerId ? li.brokerId : '',
      clearingAccountId: li.clearingAccountId ? li.clearingAccountId : '',
      currency: li.currency ? li.currency : '',
      exchangeAccountName: li.exchangeAccountName ? li.exchangeAccountName : '',
      status: li.status ? li.status : '',
    };
  });
  finalData['entityList'] = entityArr;
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/family/modify`,
      data: finalData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch(setEditFamily(false));
      return { message: result.data.data.message, status: 'SUCCESS' };
    }
  } catch (err) {
    /* Don't Clean the prefilled modal
    dispatch({
      type: GET_SINGLE_FAMILY,
      payload: {
        familyName: '',
        familyType: {
          id: '',
          name: '',
        },
        dealerList: [],
        entityList: [
          {
            entityName: '',
            entityType: '',
            exchange: '',
            segment: '',
            pan: '',
            ucc: '',
            cpCode: '',
            ems: '',
          },
        ],
        entityListLength:'',
      },
    });
    */
    return {
      message:
        err.response.data.data && err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting Some Server Error',
      status: 'FAILED',
    };
  }
};

export const resetFamilyData = (data) => async (dispatch) => {
  dispatch({
    type: 'RESET_FAMILY_DATA',
    payload: data,
  });
};

export const allfamilyData = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/allFamilies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_FAMILY,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: GET_ALL_FAMILY,
        payload: [],
      });
    }
    return result.data.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_FAMILY,
      payload: [],
    });
    return false;
  }
};

// gets the family types for the admin
export const getAllfamilyType = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/familyType`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_FAMILY_TYPE,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: GET_ALL_FAMILY_TYPE,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_FAMILY_TYPE,
      payload: [],
    });
  }
};

// gets the entity types for the admin
export const getAllfamilyEntityType = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/entityType`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_ENTITY_TYPE,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: GET_ALL_ENTITY_TYPE,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_ENTITY_TYPE,
      payload: [],
    });
  }
};

export const getfamilyDealer = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/dealer`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_FAMILY_DEALER,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: GET_FAMILY_DEALER,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_FAMILY_DEALER,
      payload: [],
    });
  }
};

// update family form
export const updateFamilyForm = (formValues) => async (dispatch) => {
  dispatch({
    type: UPDATE_FAMILY_FORM,
    payload: formValues,
  });
};

// gets the family segment for the admin
export const getAllfamilySegment = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/segment`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_FAMILY_SEGMENT,
        payload: Object.keys(result.data.data).map((el) => {
          return {
            id: el,
            name: result.data.data[el],
          };
        }),
      });
    } else {
      dispatch({
        type: GET_ALL_FAMILY_SEGMENT,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_FAMILY_SEGMENT,
      payload: [],
    });
  }
};

//submit
export const createFamily = (familyData) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/family/submit`,
      data: familyData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result && result.data) {
      dispatch({
        type: CREATE_FAMILYDATA,
        payload: true,
      });
    }
    return { message: result.data.data.message, status: 'SUCCESS' };
  } catch (err) {
    return {
      message:
        err.response.data.data && err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting Some Server Error',
      status: 'UNSUCCESSFULL',
    };
  }
};

//edit
export const editFamily = (familyData) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const result = await axios({
      method: 'POST',
      url: `${url}/family/modify`,
      data: familyData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return true;
  } catch (err) {
    if (err.response.data.data) {
      dispatch({
        type: CREATE_FAMILYDATA,
        errorMessage: err.response.data.data.message,
      });
    }
    return false;
  }
};

//ems
export const getAllfamilyEms = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/admin/EMS`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_FAMILY_EMS,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: GET_ALL_FAMILY_EMS,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_FAMILY_EMS,
      payload: [],
    });
  }
};

//exchanges
export const getAllfamilyExchanges = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/security/exchanges`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result) {
      dispatch({
        type: GET_ALL_FAMILY_EXCHANGES,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: GET_ALL_FAMILY_EXCHANGES,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_FAMILY_EXCHANGES,
      payload: [],
    });
  }
};

export const deleteFamily = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');

  try {
    const result = await axios({
      method: 'GET',
      url: `${url}/family/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        familyId: id,
      },
    });
    if (result) {
      if (result.status == 200) {
        dispatch(allfamilyData());
      }
      return { message: result.data.data.message, status: 'SUCCESS' };
    }
  } catch (err) {
    return {
      message:
        err.response.data.data && err.response.data.data.message
          ? err.response.data.data.message
          : 'Getting Some Server Error',
      status: 'FAILED',
    };
  }
};

export const setEditFamily = (data) => async (dispatch) => {
  dispatch({
    type: SET_EDIT_FAMILY,
    payload: data,
  });
};

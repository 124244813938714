import React, { useEffect, useState } from 'react';
import TimePicker from '../TimePicker/TimePicker';
import UiInput from '../Input';
import Dropdowns from '../Dropdowns';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
const DynamicField = ({
  fieldProperties,
  displayLabel,
  marketHandler,
  setTimeData,
  selectedDate,
  selectedField,
  numberData,
  staticArg,
  idx,
  fieldName,
  selectedValues,
  quantityLogicFlag,
  startTime,
  endTime,
  timeType,
  fieldId,
  exitCond,
  selectedTrigger,
}) => {
  let { enumName, displayName, type, inputType, argRange, values } =
    fieldProperties;
  if (staticArg) {
    enumName = staticArg;
  }
  const {
    strategyBuilderReducer: { strategyTemplateForm },
  } = useSelector((state) => state);
  const [constantField, setConstantField] = useState({});
  const [selectedValuesData, setSelectedValuesData] = useState(selectedValues);

  useEffect(() => {
    setSelectedValuesData(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if (selectedField === 'Constant') {
      let data = values.find((el) => el.enumName === numberData?.argValue);
      setConstantField(data);
    }
  }, [numberData]);
  return (
    <React.Fragment>
      {displayLabel ? <label>{displayName}</label> : ''}
      {type === 'INPUTBOX' && inputType === 'date_time' && (
        <TimePicker
          selectedDate={selectedDate}
          setTimeData={setTimeData}
          startTime={startTime}
          endTime={endTime}
          timeType={timeType}
          exitCond={exitCond}
        />
      )}
      {type === 'INPUTBOX' &&
        (inputType === 'double' ||
          inputType === 'long' ||
          inputType === 'int') && (
          <Row className="justify-content-md-center ">
            <Col xs={12}>
              <UiInput
                type={inputType === 'long' ? 'number' : inputType}
                name={enumName}
                value={selectedValuesData?.argValue}
                handlerfunction={(e) => {
                  let selected = { ...selectedValuesData };
                  selected['argValue'] = e.target.value;
                  setSelectedValuesData(selected);
                  marketHandler(e, inputType, idx, fieldName);
                }}
                min={argRange[0].min}
                max={argRange[0].max}
                decimalAllowed={
                  selectedTrigger == 'TARGET_PROFIT' || 'TRAILING_STOP_LOSS'
                }
              />
            </Col>
          </Row>
        )}
      {type === 'DROPDOWN' && inputType === 'enum' && (
        <Row className="justify-content-md-center">
          <Col>
            <Dropdowns
              role={enumName}
              listData={values.map((el) => {
                return {
                  id: el.enumName,
                  label: el.displayName,
                };
              })}
              value={selectedValuesData?.argValue}
              single={true}
              handlerFunction={(e) => marketHandler(e, inputType, idx)}
            />
          </Col>
        </Row>
      )}
      {type === 'INPUTBOX' && inputType === 'string' && (
        <UiInput
          type="text"
          value={selectedValuesData?.argValue}
          name={enumName}
          handlerfunction={(e) => {
            let eventData = { ...e };
            let selected = { ...selectedValuesData };
            selected['argValue'] = e.target.value;
            eventData['target']['value'] = e.target.value.trim();
            setSelectedValuesData(selected);
            marketHandler(eventData, inputType);
          }}
        />
      )}
      {type === 'INPUTBOX' && inputType === 'template_param' && (
        <Row className="justify-content-md-center">
          <Col>
            <Dropdowns
              role={enumName}
              listData={strategyTemplateForm.constants?.map((el) => {
                return {
                  id: el.name,
                  label: el.name,
                };
              })}
              value={selectedValuesData?.argValue}
              single={true}
              handlerFunction={(e) => marketHandler(e, inputType)}
            />
          </Col>
        </Row>
      )}
      {/* TODO:: need to replace exact type ="value" */}
      {inputType === 'security_nickname' && quantityLogicFlag ? (
        <Container>
          <Row>
            <div className="w-100">
              <Dropdowns
                role={enumName}
                listData={strategyTemplateForm.securities
                  .filter((value) => !value.isAdditional)
                  ?.map((el) => {
                    return {
                      id: el.nickname,
                      label: el.nickname,
                    };
                  })}
                value={selectedValuesData?.argValue}
                single={true}
                handlerFunction={(e) =>
                  marketHandler(e, inputType, enumName, fieldId)
                }
              />
            </div>
          </Row>
        </Container>
      ) : (
        inputType === 'security_nickname' && (
          <Container>
            <Row>
              <div className="w-100">
                <Dropdowns
                  role={enumName}
                  listData={strategyTemplateForm.securities.map((el) => {
                    return {
                      id: el.nickname,
                      label: el.nickname,
                    };
                  })}
                  value={selectedValuesData?.argValue}
                  single={true}
                  handlerFunction={(e) =>
                    marketHandler(e, inputType, enumName, fieldId)
                  }
                />
              </div>
            </Row>
          </Container>
        )
      )}
    </React.Fragment>
  );
};

export default DynamicField;

import React, { useState } from 'react';
import { FeedbackPopup, GenButton, GenSearchBox } from '../../..';
import { AddCircle, ExcelIcon, DeleteIcon } from '../../../../assets';
import './styles.css';
import { MakeRequest } from '../../../../utils';

export default function UMButtonGroup(props) {
  const {
    createOnClick = () => {},
    onSearchChange = () => {},
    searchVal = '',
    searchPlaceHolder = '',
    onUploadExec = () => {},
    onExportExec = () => {},
    extStyles = {},
    edit = true,
    enableDeleteAll = false,
  } = props;
  const [feedback, setFeedback] = useState(false);
  return (
    <div className="um-buttongroup-container" style={extStyles}>
      <GenButton
        icon={AddCircle}
        fill={false}
        title="CREATE"
        clickAction={createOnClick}
        extStyle={{ margin: '1vh 1vw 1vh 1vw' }}
        disabled={!edit}
      />
      <GenButton
        icon={ExcelIcon}
        fill={false}
        title="UPLOAD EXCEL"
        clickAction={onUploadExec}
        extStyle={{ margin: '1vh 0 1vh 0' }}
        disabled={!edit}
      />
      <GenSearchBox
        version={1}
        value={searchVal}
        extStyles={{ margin: '1vh auto 1vh auto', width: '20vw' }}
        message={searchPlaceHolder}
        changeListener={onSearchChange}
      />
      {enableDeleteAll && (
        <GenButton
          icon={DeleteIcon}
          fill={false}
          title="DELETE ALL"
          clickAction={() => {
            setFeedback(true);
          }}
          extStyle={{ margin: '1vh 1vw 1vh 0' }}
        />
      )}
      <GenButton
        icon={ExcelIcon}
        fill={false}
        title="EXPORT EXCEL"
        clickAction={onExportExec}
        extStyle={{ margin: '1vh 1vw 1vh 0' }}
      />
      <FeedbackPopup
        message={feedback ? 'Are you sure ? you want to delete all Bans.' : ''}
        type="a"
        acceptAction={() => {
          MakeRequest('/admin/ban').delete();
          setFeedback(false);
        }}
        rejectAction={() => {
          setFeedback(false);
        }}
      />
    </div>
  );
}

import React, { Fragment, useRef, useEffect } from 'react';
import moment from 'moment';
import './tblExtention.css';
import ArrowDown from '../../../../assets/img/arrow_down.svg';
import { GenButton } from '../../../../components';

const TblExtention = (props) => {
  const { title, config, applyCommonRiskLimitCallback } = props;

  const [open, setOpen] = React.useState(false);
  const [commonRiskValue, setCommonRiskValue] = React.useState(50);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert('You clicked outside of me!');
          handleClose();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const applyCommonRiskLimit = () => {
    applyCommonRiskLimitCallback(commonRiskValue, title);
    handleClose();
  };

  const UpdateInputValue = (evt) => {
    const val = evt.target.value;
    setCommonRiskValue(val);
  };

  function isMarketHour() {
    try {
      let tradingStartTime = moment('09:15', 'HH:mm');
      let tradingEndTime = moment('15:30', 'HH:mm');
      let curTime = moment();
      if (curTime < tradingStartTime || curTime > tradingEndTime) {
        return false;
      }
      return true;
    } catch (err) {
      console.log(
        `${new Date().toISOString()}: error: Method <isMarketHour> failed with error: ${
          err.message
        }`
      );
      return true;
    }
  }

  return (
    <Fragment>
      <div className="button-div">
        <GenButton
          type="subButton"
          fill={false}
          icon={ArrowDown}
          clickAction={handleToggle}
          params="Units"
        />
      </div>
      {open && (
        <div className="popup-div" ref={wrapperRef}>
          <div className="title-div ">
            <div className="title-left-div">{`${title} Limit`} </div>
            <div className="title-right-div">
              <input
                className="input"
                disabled={isMarketHour()}
                onChange={(evt) => UpdateInputValue(evt)}
                value={commonRiskValue}
              ></input>
              <GenButton
                title="APPLY"
                disabled={isMarketHour()}
                extStyle={{
                  back: {
                    backgroundColor: '#2F78D7',
                    borderRadius: '2px',
                    width: '4vw',
                    height: '4vh',
                    // marginRight: '0.25vw',
                  },
                  text: {
                    color: '#FFFFFF',
                    fontWeight: '300',
                    fontSize: '12px',
                    margin: '0px',
                  },
                }}
                clickAction={applyCommonRiskLimit}
              />
            </div>
          </div>
          <div className="lower-div">
            <div className="risk-object-text-div">
              <span>Risk Limits</span>
            </div>
            <div className="risk-object-div">
              <input
                className="checkbox"
                type="checkbox"
                disabled="true"
                checked={config && config.Alert}
              ></input>
              <span>{` Alert`}</span>
            </div>
            <div className="risk-object-div">
              <input
                className="checkbox"
                type="checkbox"
                disabled="true"
                checked={config && config.Email}
              ></input>
              <span>{` Email`}</span>
            </div>
            {process.env.REACT_APP_IS_HFT != 'true' && (
              <div className="risk-object-div">
                <input
                  className="checkbox"
                  type="checkbox"
                  disabled="true"
                  checked={config && config.Reject}
                ></input>
                <span>{` Reject`}</span>
              </div>
            )}
            {process.env.REACT_APP_IS_HFT == 'true' && (
              <div className="risk-object-div">
                <input
                  className="checkbox"
                  type="checkbox"
                  disabled="true"
                  checked={config && config.Log}
                ></input>
                <span>{` Log`}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TblExtention;

import React from 'react';
import './genTreemenu.css';
import TreeOption from './treeOption';

const TreeMenuGenerator3 = (props) => {
  let { content } = props;
  return (
    <div className="treeMenuGen">
      <div className="displayArea-3">
        {content &&
          (!props['toplevelSelect'] ? (
            content.children.map((item) => (
              <TreeOption
                title={item.title}
                children={item.children}
                depth={1}
                found={item.found}
                clickAction={props['clickAction']}
                data={item}
                showRightIcon={props['showRightIcon']}
                addTemplateSubmit={props['addTemplateSubmit']}
                editTemplateSubmit={props['editTemplateSubmit']}
                config={props['config']}
                isHFT={props['isHFT']}
                templateId={props['templateId']}
              />
            ))
          ) : (
            <TreeOption
              title={content.title}
              children={content.children}
              depth={0}
              found={content.found}
              clickAction={props['clickAction']}
              data={content}
              showRightIcon={props['showRightIcon']}
              addTemplateSubmit={props['addTemplateSubmit']}
              editTemplateSubmit={props['editTemplateSubmit']}
              config={props['config']}
              isHFT={props['isHFT']}
              templateId={props['templateId']}
            />
          ))}
      </div>
    </div>
  );
};

export default TreeMenuGenerator3;

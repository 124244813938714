import SecuritiesValidations from './SecuritiesValidations';

const EntryConditionValidation = (strategyTemplateForm) => {
  let algoExecutionData = strategyTemplateForm.entryLegs[0].algoExecution;
  let positionData = strategyTemplateForm.entryLegs.map((leg) => leg.position);
  // check if algoExecution is selected
  if (algoExecutionData && algoExecutionData.length) {
    return true;
  }
  // else check position validation
  else {
    // todo
    return true;
  }
};

const ValidateStrategyTemplateForm = (strategyTemplateForm, exitToggle) => {
  // submitForm();
  let formValid = true;
  // In IF - Step (1,2) validations
  let formValidOne =
    strategyTemplateForm.name &&
    strategyTemplateForm.description &&
    strategyTemplateForm.algoId &&
    strategyTemplateForm.exchange;
  // Step (3) Select Securities validation
  let formValidTwo = SecuritiesValidations(strategyTemplateForm);
  // Step (4) Validations
  let formValidThree = EntryConditionValidation(strategyTemplateForm);
  // Step (5) Action Validation
  if (exitToggle) {
    formValid = !!strategyTemplateForm.exitCondition.action.enumName;
  }
  return formValid && formValidOne && formValidTwo && formValidThree;
};

export default ValidateStrategyTemplateForm;

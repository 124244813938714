import { useState } from 'react';
import searchImage from '../../../assets/img/icons8-search.svg';
import './genericSearchBox.css';

const GenericSearchBox = (props) => {
  const {
    changeListener = (val) => {},
    value,
    message,
    version = 1,
    extStyles = {},
    ddExtStyles={},
  } = props;

  const [enableFocus, setEnableFocus] = useState(false);

  const changeList = (event) => {
    changeListener(event.target.value);
  };

  function loadContent() {
    switch (version) {
      case 1:
        return (
          <div className="GenericSearchBox-container" style={extStyles}>
            <img
              src={searchImage}
              alt="search"
              className="GenericSearchBox-icon"
            />
            <input
              style={ddExtStyles}
              type="text"
              placeholder={message}
              className="GenericSearchBox-input font-l1"
              onChange={(evt) => changeList(evt)}
            />
          </div>
        );
      case 2:
        return (
          <div
            className={`generic-searchbox-cont${enableFocus ? '-focus' : ''}`}
            style={extStyles}
          >
            <img
              src={searchImage}
              className="generic-searchbox-img"
              alt="Search Icon"
            />
            <input
              type="text"
              placeholder={message}
              value={value}
              className="generic-searchbox-input"
              onChange={(evt) => changeList(evt)}
              onFocus={() => setEnableFocus(true)}
              onBlur={() => setEnableFocus(false)}
            />
          </div>
        );

      default:
        return <></>;
    }
  }

  return loadContent();
};

export default GenericSearchBox;

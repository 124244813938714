import React, { useEffect, useRef, useState } from 'react';
import './NewDropdown.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDown } from '../../../assets';
import { setStrategyTemplateForm } from '../../../actions/strategyBuilderAction';

export default function NewDropdown({
  listData,
  value,
  setValue,
  componentKey,
  legIndex,
  positionIndex,
  placeHolder = '',
  id = '',
  noInput,
  extStyles = { width: '30vw' },
}) {
  const containerRef = useRef(null);
  const inputBoxRef = useRef(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [filteredListData, setFilteredListData] = useState([]);
  const [equation, setEquation] = useState(value);
  const [indicatorActive, setIndicatorActive] = useState('');
  const [indicatorParams, setIndicatorParams] = useState({});
  const [args, setArgs] = useState([]);
  const [constants, setConstants] = useState([]);
  const [error, setError] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const [cursorPos, setCursorPos] = useState(0);
  const [canNest, setCanNest] = useState(false);
  const [combEqu, setCombEqu] = useState('');
  const dispatch = useDispatch();
  const [clickedOutside, setClickedOutside] = useState(true);

  const {
    strategyBuilderReducer: { strategyTemplateForm, isEdit },
  } = useSelector((state) => state);

  function moveFocusToInput() {
    if (!inputBoxRef?.current) {
      return;
    }
    inputBoxRef?.current?.focus();
    inputBoxRef?.current.setSelectionRange(cursorPos, cursorPos);
  }

  function listClickHandler(value) {
    let eq = equation;
    let curr = fetchKeywordAtCursor(eq, cursorPos, isValidChar);
    eq =
      eq.substring(0, curr.start) +
      value.displayName +
      (curr.end < equation.length && equation[curr.end] === '(' ? '' : '(') +
      eq.substring(curr.end);
    setEquation(eq);
    setCursorPos(
      `${eq.substring(0, curr.start) + value.displayName}`.length + 1
    );
    updateHint(value.displayName);
    setIsSearchActive(false);
    setClickedOutside(true);
  }

  function addVariable(data) {
    setEquation((old) => insertWordAtPosition(old, cursorPos, data));
    setCursorPos((old) => old + data.length);
  }

  useEffect(() => {
    console.log(cursorPos);
  }, [cursorPos]);

  function insertWordAtPosition(equation, position, word) {
    if (position < 0 || position > equation.length) {
      throw new Error('Position is out of bounds');
    }
    const before = equation.slice(0, position);
    const after = equation.slice(position);
    return before + word + after;
  }

  function handleCursorPosition(event) {
    const position = event.target.selectionStart;
    setCursorPos(position);
  }

  const updateHint = (indicator) => {
    let validIndicator = false;
    let numIndicators = listData[0].data.length;
    for (let i = 0; i < numIndicators; i++) {
      let listItem = listData[0].data[i];
      if (
        indicator.replace(/\s/g, '').toLowerCase() ==
        listItem?.displayName.replace(/\s/g, '').toLowerCase()
      ) {
        setIndicatorActive(listItem.displayName);
        setArgs(
          listItem.args.sort((a, b) => {
            return a.argSeqNum - b.argSeqNum;
          })
        );
        setCanNest(Object.hasOwn(listItem, 'unaryFlag'));
        validIndicator = true;
        let paramsInfo = {};
        for (let j = 0; j < listItem.args.length; j++) {
          let arg = listData[0].data[i].args[j];
          let info = [];
          if (arg.inputType == 'security_nickname') {
            let numSecurities = strategyTemplateForm.securities.length;
            for (let k = 0; k < numSecurities; k++) {
              let secNickname = strategyTemplateForm.securities[k].nickname;
              if (secNickname != '') {
                info.push(secNickname);
              }
            }
          } else if (arg.inputType == 'enum') {
            for (let k = 0; k < arg.values.length; k++) {
              info.push(arg.values[k].displayName);
            }
          } else {
            info.push(arg.inputType);
          }
          if (info.length == 0) {
            info.push('No Securities Added');
          }
          paramsInfo[arg.displayName] = info;
        }
        paramsInfo['Constants'] = constants;
        setIndicatorParams(paramsInfo);
        break;
      }
    }
    return validIndicator;
  };

  const isValidChar = (ch, flag = false) => {
    return (
      (ch >= 'a' && ch <= 'z') ||
      (ch >= 'A' && ch <= 'Z') ||
      (ch >= '0' && ch <= '9') ||
      (flag && ch == '-') ||
      ch == ' ' ||
      ch == '$' ||
      ch == '_'
    );
  };

  const isValidSearchChar = (ch, flag = false) => {
    return (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z') || ch == ' ';
  };

  const fetchKeywordAtCursor = (eq, idx, check, flag = false) => {
    let i,
      j,
      res = { value: '', start: 0, end: 0, isMethod: false };
    if (idx < eq.length && check(eq[idx], flag)) {
      i = idx;
    } else if (idx > 0 && check(eq[idx - 1], flag)) {
      i = idx - 1;
    }
    for (j = i; j >= 0 && check(eq[j], flag); j--) {
      res.value += eq[j];
    }
    res.value = res.value.trim().split('').reverse().join('');
    res.start = j + 1;
    for (j = i + 1; j < eq.length && check(eq[j], flag); j++) {
      res.value += eq[j];
    }
    res.end = j;
    while (j < eq.length) {
      if (!check(eq[j], flag)) {
        res.isMethod = eq[j] == '(';
        break;
      }
    }
    res.isMethod = res.isMethod && res.value.length > 0;
    res.value = res.value.trim();
    return res;
  };

  const fetchConstants = (eq) => {
    let res = [];

    for (let i = 0; i < combEqu.length; i++) {
      if (i < combEqu.length - 1 && combEqu[i] === '$' && i >= 1) {
        if (combEqu[i - 1] !== '$') {
          res.push(
            fetchKeywordAtCursor(combEqu, i, isValidChar).value.substring(1)
          );
        }
      }
    }
    res = res.length > 0 ? res : ['No Constants Defined'];
    res = [...new Set(res)];
    setConstants(res);
  };

  const isSubsequence = (str1, str2) => {
    let i = 0;
    let j = 0;
    while (i < str1.length) {
      if (j === str2.length) {
        return false;
      }
      if (str1[i] === str2[j]) {
        i++;
      }
      j++;
    }
    return true;
  };

  const filterSearch = (str) => {
    let finalResult = [];
    let filteredResult = [];
    let indicatorList = listData[0].data;
    setIsSearchActive(true);
    setClickedOutside(false);
    for (let j = 0; j < indicatorList.length; j++) {
      let check = isSubsequence(
        str,
        indicatorList[j]?.displayName.replace(/\s/g, '').toLowerCase()
      );
      if (check) {
        finalResult.push(indicatorList[j]);
      }
    }
    if (finalResult) {
      filteredResult = [
        ...filteredResult,
        { name: listData[0].name, data: finalResult },
      ];
    }
    setError(false);
    if (finalResult.length == 0) {
      setIsSearchActive(false);
      setClickedOutside(true);
      setError(true);
    }

    setFilteredListData([...filteredResult]);
  };

  const fetchIndicator = (eq, idx) => {
    let flag = 0;
    let res = { start: -1, active: false };
    let validate = false;
    for (let i = idx - 1; i >= 0; i--) {
      if (eq[i] === '(' && flag === 0) {
        let curr = fetchKeywordAtCursor(eq, i - 1, isValidChar);
        if (curr.isMethod) {
          updateHint(curr.value);
          setIsSearchActive(false);
          setClickedOutside(true);
          res.active = true;
          res.start = i + 1;
          return res;
        }
        setIndicatorActive('');
        return res;
      } else if (eq[i] == '(') {
        if (validate && flag == -1) {
          setError(!handleParamValidation(eq, i + 1));
        }
        flag++;
      } else if (eq[i] == ')') {
        validate = i == idx - 1;
        flag--;
      }
    }
    setIndicatorActive('');
    return res;
  };

  const handleParamValidation = (eq, start) => {
    let argIdx = 0;
    for (let i = start; i < eq.length && eq[i] != ')'; i++) {
      if (argIdx == args.length) {
        return false;
      }
      let curr = fetchKeywordAtCursor(eq, i, isValidChar, true);
      let value = curr.value.replace(/\s/g, '').toLowerCase();
      if (
        curr.end < eq.length &&
        (eq[curr.end] == ',' || eq[curr.end] == ')')
      ) {
        i = eq[curr.end] == ',' ? curr.end : curr.end - 1;
        let valid = false;
        let type = args[argIdx].inputType;
        if (type == 'enum') {
          for (let j = 0; j < args[argIdx].values.length; j++) {
            if (
              value ==
              args[argIdx].values[j].displayName
                .replace(/\s/g, '')
                .toLowerCase()
            ) {
              valid = true;
              break;
            }
          }
        } else if (type == 'security_nickname') {
          let numSecurities = strategyTemplateForm.securities.length;
          for (let k = 0; k < numSecurities; k++) {
            let secNickname = strategyTemplateForm.securities[k].nickname
              .replace(/\s/g, '')
              .toLowerCase();
            if (value == secNickname) {
              valid = true;
              break;
            }
          }
        } else if (type == 'int' || type == 'long' || type == 'double') {
          value = parseFloat(value);
          let l = args[argIdx].argRange[0].min;
          let h = args[argIdx].argRange[0].max;
          valid = value <= h && value >= l;
        } else {
          valid = true;
        }
        if (!valid) {
          return false;
        }
        argIdx++;
        if (eq[curr.end] == ')' && argIdx != args.length) {
          return false;
        }
      }
    }
    return true;
  };

  const checkBracketbalance = (eq) => {
    let flag = 0;
    for (let i = 0; i < eq.length; i++) {
      if (eq[i] == '(') {
        flag++;
      } else if (eq[i] == ')') {
        flag--;
      }
    }
    return flag >= 0;
  };

  const handleSearch = (e) => {
    let eq = e.target.value;
    let idx = e.target.selectionStart;

    setError(!checkBracketbalance(eq));

    let curr = fetchKeywordAtCursor(eq, idx, isValidChar);
    let currVal = curr.value.replace(/\s/g, '').toLowerCase();
    let searchVal = fetchKeywordAtCursor(eq, idx, isValidSearchChar)
      .value.replace(/\s/g, '')
      .toLowerCase();
    let ind = fetchIndicator(eq, idx);
    fetchConstants(eq.replace(/\s/g, ''));
    if (
      searchVal.length > 0 &&
      (!ind.active || curr.isMethod || canNest) &&
      currVal[0] !== '$'
    ) {
      filterSearch(searchVal);
    } else if (ind.active) {
      setError(!handleParamValidation(eq, ind.start));
    } else if (searchVal.length === 0) {
      setIsSearchActive(false);
      setClickedOutside(true);
    }
    setCursorPos(idx);
    handleCursorPosition(e);
    setEquation(eq);
  };

  const handleClick = (e) => {
    handleCursorPosition(e);
    let eq = e.target.value;
    let idx = e.target.selectionStart;
    let curr = fetchKeywordAtCursor(eq, idx, isValidChar);
    setCursorPos(idx);
    if (curr.isMethod) {
      updateHint(curr.value);
      setIsSearchActive(false);
      setClickedOutside(true);
    } else {
      fetchIndicator(eq, idx);
    }
  };

  useEffect(() => {
    let form = { ...strategyTemplateForm };
    switch (componentKey) {
      case 'pricing':
        setEquation(form.entryLegs[legIndex].position[positionIndex].pricing);
        break;
      case 'quantity':
        setEquation(form.entryLegs[legIndex].position[positionIndex].quantity);
        break;
      case 'totalQuantityLogic':
        setEquation(
          form.entryLegs[legIndex].position[positionIndex].totalQuantityLogic
        );
        break;
      case 'entryCondition':
        if (!isEdit) break;
        form.entryLegs[legIndex]?.condition.forEach((el) => {
          if (el.trigger === 'MARKET_TRIGGERED') {
            setEquation(el.marketTriggered);
          }
        });
        break;
      case 'strategyanalyzer':
        setEquation(value);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!equation) return;
    let form = { ...strategyTemplateForm };
    switch (componentKey) {
      case 'entryCondition':
        form.entryLegs[legIndex]?.condition.forEach((el) => {
          if (el.trigger === 'MARKET_TRIGGERED') {
            el.marketTriggered = equation;
          }
        });
        break;
      case 'pricing':
        form.entryLegs[legIndex].position[positionIndex].pricing = equation;
        break;
      case 'quantity':
        form.entryLegs[legIndex].position[positionIndex].quantity = equation;
        break;
      case 'totalQuantityLogic':
        form.entryLegs[legIndex].position[positionIndex].totalQuantityLogic =
          equation;
        break;
      case 'exitCondition':
        form.exitCondition?.condition.forEach((el) => {
          if (el.trigger === 'MARKET_TRIGGERED') {
            el.marketTriggered = equation;
          }
        });
        break;
      case 'strategyanalyzer':
        if (setValue) {
          setValue(equation);
        }
        break;
      default:
        break;
    }
    dispatch(setStrategyTemplateForm(form));
    moveFocusToInput();
  }, [equation]);

  useEffect(() => {
    let form = { ...strategyTemplateForm };
    if (constants[0] !== 'No Constants Defined') {
      form.constants = constants.map((val) => {
        return { name: val };
      });
    } else {
      form.constants = [];
    }
    setIndicatorParams({ ...indicatorParams, Constants: constants });
    dispatch(setStrategyTemplateForm(form));
  }, [constants]);

  useEffect(() => {
    let temp = '';
    for (let i = 0; i < strategyTemplateForm?.entryLegs?.length; i++) {
      for (
        let j = 0;
        j < strategyTemplateForm?.entryLegs[i]?.condition?.length;
        j++
      ) {
        if (
          strategyTemplateForm?.entryLegs[i]?.condition[j]?.marketTriggered
            ?.length > 0
        ) {
          temp +=
            '+' +
            strategyTemplateForm?.entryLegs[i]?.condition[j]?.marketTriggered;
        }
      }
      for (
        let j = 0;
        j < strategyTemplateForm?.entryLegs[i]?.position.length;
        j++
      ) {
        if (
          strategyTemplateForm?.entryLegs[i]?.position[j]?.quantity?.length > 0
        ) {
          temp +=
            '+' + strategyTemplateForm?.entryLegs[i]?.position[j]?.quantity;
        }
        if (
          strategyTemplateForm?.entryLegs[i]?.position[j]?.pricing?.length > 0
        ) {
          temp +=
            '+' + strategyTemplateForm?.entryLegs[i]?.position[j]?.pricing;
        }
        if (
          strategyTemplateForm?.entryLegs[i]?.position[j]?.totalQuantityLogic
            ?.length > 0
        ) {
          temp +=
            '+' +
            strategyTemplateForm?.entryLegs[i]?.position[j]?.totalQuantityLogic;
        }
      }
    }
    for (
      let j = 0;
      j < strategyTemplateForm?.exitCondition?.condition?.length;
      j++
    ) {
      if (
        strategyTemplateForm?.exitCondition?.condition[j]?.marketTriggered
          ?.length > 0
      ) {
        temp +=
          '+' +
          strategyTemplateForm?.exitCondition?.condition[j]?.marketTriggered;
      }
    }

    if (strategyTemplateForm?.analyzerCondition) {
      for (let straAnalyzerFormulaObj of Object.values(
        strategyTemplateForm?.analyzerCondition
      )) {
        temp += `+${straAnalyzerFormulaObj}`;
      }
    }

    setCombEqu(temp);
  }, [strategyTemplateForm]);

  useEffect(() => {
    fetchConstants(combEqu);
  }, [combEqu]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsSearchActive(false);
        setClickedOutside(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchActive]);

  return (
    <div className="multiDropdownNew" ref={containerRef}>
      <input
        ref={inputBoxRef}
        placeholder={placeHolder}
        value={equation}
        className={`finalValueShow ${error ? 'error' : ''}`}
        id={id}
        onChange={noInput ? null : (e) => handleSearch(e)}
        onClick={(e) => handleClick(e)}
        spellCheck="false"
        style={{ width: extStyles?.width }}
        onKeyUp={handleCursorPosition}
      />
      {isSearchActive && !clickedOutside ? (
        <div
          className="toogledropdownNew mainWrapperDropdownNew"
          style={{ width: extStyles?.width }}
        >
          {filteredListData?.map((list, index) => {
            return filteredListData[index].name === 'Indicator' ? (
              <ul className="tab-options">
                {list.data?.map((i, index2) => {
                  return (
                    <li
                      className="tab-option-li"
                      key={index2 + 'secondlist'}
                      onClick={(e) => listClickHandler(i)}
                    >
                      {i.displayName}
                    </li>
                  );
                })}
              </ul>
            ) : null;
          })}
        </div>
      ) : indicatorActive.length > 0 ? (
        <div
          className="toogledropdownNew mainWrapperDropdownNew"
          style={{ width: extStyles?.width }}
        >
          <div
            className="level-one-hint"
            onClick={() => {
              setShowHint(!showHint);
            }}
          >
            <span>
              {indicatorActive +
                '(' +
                Object.keys(indicatorParams).slice(0, -1) +
                ')'}
            </span>
            <span>
              <img src={ArrowDown}></img>
            </span>
          </div>
          {showHint ? (
            <div className="level-two-hint">
              <ul className="param-list">
                {Object.keys(indicatorParams)?.map((val) => {
                  return (
                    <li className="param-cont">
                      <span className="param-name">{val}</span>
                      <div className="param-des">
                        {indicatorParams[val]?.map((val2, idx) => {
                          return (
                            <span
                              onClick={() => {
                                if (val === 'Constants') {
                                  addVariable(`$${val2}`);
                                } else {
                                  addVariable(val2);
                                }
                              }}
                              className="param-des-container"
                            >
                              {val2}
                            </span>
                          );
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import './Condition.scss';
import Dropdowns from '../../../../sharedComponents/Dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateConditions,
  setConditions,
  setConstants,
} from '../../../../../actions/strategyBuilderAction';
import StaticCondition from '../../../../sharedComponents/StaticCondition/StaticCondition';
import moment from 'moment';
import {
  AddCircleIcon,
  DeleteHoverIcon,
  DeleteIcon,
} from '../../../../../assets';
import NewDropdown from '../../../../sharedComponents/NewDropdown/NewDropdown';
import { ButtonAddLeg } from '../../components';

const AddPosition = ({
  showCondition,
  setAddCondition,
  legIndex,
  conditions,
  legs,
  modalClear,
  validator,
  formSubmit,
}) => {
  const {
    strategyBuilderReducer: {
      strategyTemplateForm,
      operation,
      marketCondition,
      marketTriggeredOperators,
      clearStats,
      isEdit,
    },
    alertReducer: { isApproveReject },
  } = useSelector((state) => state);
  const [flag, setFlag] = useState(false);
  const [editFlagIndex, setEditFlagIndex] = useState(null);
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({});
  const [fieldIndex, setFieldIndex] = useState();
  const [fieldValue, setFieldValue] = useState('');
  const [conditionSelect, setConditionSelect] = useState([]);
  const [counter, setCounter] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [startTime, setStartTime] = useState(new Date('2014-08-18T09:30:00'));
  const [endTime, setEndtime] = useState(new Date('2014-08-18T15:15:00'));
  const [selectedDate, setSelectedDate] = useState(
    new Date('2014-08-18T21:11:54')
  );
  const [fields, setFields] = useState([
    {
      type: 'OPERAND',
      trigger: '',
    },
  ]);
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [marketForm, setMarketForm] = useState([]);
  const [equationValues, setEquationValues] = useState([
    { type: 'OPERAND', enumName: '', value: '' },
  ]);

  const marketHandler = (index, data, cancelObj, operation = 'NEW') => {
    let marketFormData = [...marketForm];
    if (data === 'REMOVE') {
      let obj = marketFormData[index];
      if (obj.conditionName === 'New Template Parameter') {
        let removeData = {
          name: obj.arguements[0].argValue,
          value: null,
        };
        dispatch(
          setConstants({
            obj: removeData,
            legIndex,
            key: 'constants',
            remove: true,
          })
        );
      }
      marketFormData[index] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };
      if (
        cancelObj &&
        cancelObj.data == 'PARAMETER' &&
        cancelObj.arguements.length &&
        cancelObj.arguements[0].argName.startsWith('NEW_TEMPLATE')
      ) {
        let tempForm = { ...strategyTemplateForm };
        let conditionIndex = '';
        let argIndex = [];
        let condObj = null;
        let newTriggerCond = [];
        let newPriceArr = [];
        let exitCondIndex = '';
        let newExitCondObj = null;
        let newExitTrigger = [];

        //removing for entry condition
        for (let i = 0; i < strategyTemplateForm.entryLegs.length; i++) {
          //finding market Trigger condition
          condObj = strategyTemplateForm.entryLegs[i].condition.find(
            (trig) => trig.trigger === 'MARKET_TRIGGERED'
          );
          conditionIndex =
            strategyTemplateForm.entryLegs[i].condition.indexOf(condObj);
          // marketTriggered arguements after removing new template
          newTriggerCond = condObj.marketTriggered.map((el, argInd) => {
            if (
              el.data === 'PARAMETER' &&
              el.arguements.length &&
              el.arguements[0].argName.startsWith('EXISTING') &&
              el.arguements[0].argValue === cancelObj.arguements[0].argValue
            ) {
              argIndex.push(argInd);
              return { type: 'CONSTANT', data: '', arguements: [] };
            }
            return el;
          });
          condObj.marketTriggered = newTriggerCond;
          tempForm.entryLegs[i].condition[conditionIndex] = condObj;
          for (let k = 0; k < argIndex.length; k++) {
            marketFormData[argIndex[k]] = {
              ...marketFormData[argIndex[k]],
              data: '',
              arguements: [],
            };
          }

          //finding template parameter in position and pricing
          for (
            let pos = 0;
            pos < strategyTemplateForm.entryLegs[i].position.length;
            pos++
          ) {
            newPriceArr = strategyTemplateForm.entryLegs[i]?.position[
              pos
            ]?.pricing?.map((p) => {
              if (
                p.data === 'PARAMETER' &&
                p.arguements.length &&
                p.arguements[0].argName.startsWith('EXISTING') &&
                p.arguements[0].argValue === cancelObj.arguements[0].argValue
              ) {
                return {
                  type: 'CONSTANT',
                  data: '',
                  arguements: [],
                };
              }
              return p;
            });
            tempForm.entryLegs[i].position[pos].pricing = newPriceArr;
          }
        }

        //removing template in exit condition
        if (
          strategyTemplateForm.exitCondition.condition &&
          strategyTemplateForm.exitCondition.condition.length &&
          Object.keys(strategyTemplateForm.exitCondition.condition[0]).length >
            2
        ) {
          newExitCondObj = strategyTemplateForm.exitCondition.condition.find(
            (ex) => ex.trigger === 'MARKET_TRIGGERED'
          );
          exitCondIndex =
            strategyTemplateForm.exitCondition.condition.indexOf(
              newExitCondObj
            );
          newExitTrigger = newExitCondObj.marketTriggered.map((el) => {
            if (
              el.data === 'PARAMETER' &&
              el.arguements.length &&
              el.arguements[0].argName.startsWith('EXISTING') &&
              el.arguements[0].argValue === cancelObj.arguements[0].argValue
            ) {
              return { type: 'CONSTANT', data: '', arguements: [] };
            }
            return el;
          });
          newExitCondObj.marketTriggered = newExitTrigger;
          tempForm.exitCondition.condition[exitCondIndex] = newExitCondObj;
        }
        dispatch(updateConditions(tempForm));
      }
    } else {
      // adding new data
      if (!marketFormData[index]) {
        marketFormData[index] = data;
        if (data?.conditionName === 'New Template Parameter') {
          let obj = {
            name: data.arguements[0].argValue,
            value: null,
          };
          dispatch(
            setConstants({
              obj,
              legIndex,
              key: 'constants',
              remove: false,
              edit: false,
            })
          );
        }
      } else {
        // editing data
        if (data?.conditionName === 'New Template Parameter') {
          let editIndex;
          if (
            strategyTemplateForm.constants &&
            strategyTemplateForm.constants.length
          ) {
            editIndex = strategyTemplateForm.constants.findIndex(
              (el) => el.name === marketFormData[index]?.arguements[0]?.argValue
            );
          }
          let obj = {
            name: data.arguements[0].argValue,
            value: null,
          };
          if (editIndex > -1) {
            dispatch(
              setConstants({
                obj,
                legIndex,
                key: 'constants',
                remove: false,
                edit: true,
                editIndex,
              })
            );
          } else {
            dispatch(
              setConstants({
                obj,
                legIndex,
                key: 'constants',
                remove: false,
                edit: false,
              })
            );
          }
        }
        marketFormData.splice(index, 1, data);
      }
    }
    setMarketForm(marketFormData);
  };

  const inputHandler = (e, index) => {
    let keyObj = {};
    let newObj = {};
    let defaultObj = {
      type: 'OPERAND',
      trigger: '',
    };
    let val = e.target.value;
    let obj = showCondition.find((el) => el.key === val);

    if (val !== '') {
      if (val === 'timeTriggered') {
        let keys = Object.keys(obj.data.args);
        keys.forEach((el) => (keyObj[el] = obj.data.args[el].value));
        keyObj = {
          startTime: moment(startTime).format('H:mm:ss'),
          endTime: moment(endTime).format('H:mm:ss'),
        };
        newObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${val}`]: keyObj,
        };
        setStartTime(new Date('2014-08-18T09:30:00'));
        setEndtime(new Date('2014-08-18T15:30:00'));
      } else if (val === 'marketTriggered') {
        handleEquationClose();
        keyObj = {
          type: 'OPERAND',
          data: '',
          arguements: [],
        };

        newObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${val}`]: [keyObj],
        };
      }
    }
    if ((fields.length === 1 || index === 0) && !isEdit) {
      let field = fields;

      if (val !== '') {
        field[index] = newObj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      } else {
        field[index] = defaultObj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      }
    } else if (index === 0 && isEdit) {
      let field = legs.condition;

      if (val !== '') {
        field[index] = newObj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      } else {
        field[index] = defaultObj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      }
    } else {
      let newIndex = 0;
      let field;
      if (isEdit) {
        field = legs.condition;
      } else {
        field = fields;
      }
      if (index === 1 || index > 1) {
        newIndex = 2;
        if (val !== '') {
          field[newIndex] = newObj;
          dispatch(setConditions({ field, legIndex, key: e.target.name }));
        } else {
          field[newIndex] = defaultObj;
          dispatch(setConditions({ field, legIndex, key: e.target.name }));
        }
      }
      let final = field.find(
        (elm) => elm.type === 'OPERAND' && elm.trigger === ''
      );
      if (val !== '') {
        if (field.indexOf(final) > -1) {
          field[field.indexOf(final)] = newObj;
        }
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      } else {
        field[2] = defaultObj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      }
    }
    setFieldIndex(index);
    setFieldValue(e.target.value);
    setFlag(!flag);
  };

  const operationHandler = (e, index) => {
    let obj = {
      type: 'OPERATION',
      value: e.target.value,
    };

    if (isEdit) {
      let field = legs.condition;
      if (index === 1) {
        field[index] = obj;
        if (!e.target.value) {
          field[index + 1] = {
            type: 'OPERAND',
            trigger: '',
          };
        }
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      } else {
        let final = field.find(
          (elm) => elm.type === 'OPERATION' && elm.value === ''
        );
        field[field.indexOf(final)] = obj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      }
    } else {
      let field = fields;
      if (index === 1) {
        field[index] = obj;
        if (!e.target.value) {
          field[index + 1] = {
            type: 'OPERAND',
            trigger: '',
          };
        }
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      } else {
        let final = field.find(
          (elm) => elm.type === 'OPERATION' && elm.value === ''
        );
        field[field.indexOf(final)] = obj;
        dispatch(setConditions({ field, legIndex, key: e.target.name }));
      }
    }
  };

  const equationHandler = (data, eqIndex) => {
    let newEquation = [...equationValues];
    if (data === 'REMOVE') {
      let obj = newEquation[eqIndex];
      newEquation[eqIndex] = {
        arguements: [],
        conditionName: '',
        data: '',
        eqIndex: obj.eqIndex,
        type: obj.type,
      };
    } else {
      if (!newEquation[eqIndex]) {
        newEquation[eqIndex] = data;
      } else {
        newEquation.splice(eqIndex, 1, data);
      }
    }
    setEquationValues(newEquation);
  };

  const handleEquationClose = (idx) => {
    let argArr = [];
    let form = { ...strategyTemplateForm };
    if (idx === 1) {
      if (
        form.entryLegs[legIndex].condition[idx + 1].trigger ===
        'MARKET_TRIGGERED'
      ) {
        let eqObj = form.entryLegs[legIndex].condition[idx + 1];
        for (let i = 0; i < eqObj.marketTriggered.length; i++) {
          if (
            eqObj.marketTriggered[i].data === 'PARAMETER' &&
            eqObj.marketTriggered[i].arguements.length &&
            eqObj.marketTriggered[i].arguements[0].argName.startsWith(
              'NEW_TEMPLATE'
            )
          ) {
            argArr.push(eqObj.marketTriggered[i].arguements[0]);
          }
        }
        for (let k = 0; k < argArr.length; k++) {
          form.constants = form.constants.filter(
            (el) => el.name !== argArr[k].argValue
          );
        }
        dispatch(updateConditions(form));
      }
    } else if (idx === 0) {
      if (
        form.entryLegs[legIndex].condition[idx].trigger === 'MARKET_TRIGGERED'
      ) {
        let eqObj = form.entryLegs[legIndex].condition[idx];
        for (let i = 0; i < eqObj.marketTriggered.length; i++) {
          if (
            eqObj.marketTriggered[i].data === 'PARAMETER' &&
            eqObj.marketTriggered[i].arguements.length &&
            eqObj.marketTriggered[i].arguements[0].argName.startsWith(
              'NEW_TEMPLATE'
            )
          ) {
            argArr.push(eqObj.marketTriggered[i].arguements[0]);
          }
        }
        for (let k = 0; k < argArr.length; k++) {
          form.constants = form.constants.filter(
            (el) => el.name !== argArr[k].argValue
          );
        }
        dispatch(updateConditions(form));
      }
    }

    setEquationValues([
      {
        type: 'OPERAND',
        enumName: '',
        value: '',
      },
    ]);
    setMarketForm([]);
  };

  function handleAdd() {
    const ops = {
      type: 'OPERATION',
      value: '',
    };
    const row = {
      type: 'OPERAND',
      trigger: '',
    };
    let form = { ...strategyTemplateForm };

    form.entryLegs[legIndex].condition.push(ops, row);
    dispatch(updateConditions(form));
    if (!conditionSelect.includes((el) => el === fieldValue)) {
      let selectCond = [...conditionSelect];
      selectCond.push(fieldValue);
      setConditionSelect(selectCond);
    }
    setAddCondition(true);
  }

  function handleRemove(index) {
    let argArr = [];
    let form = { ...strategyTemplateForm };
    if (index === 1) {
      let newIdx = index + 1;
      //for removing constants on deleting equation
      if (
        form.entryLegs[legIndex].condition[newIdx].trigger ===
        'MARKET_TRIGGERED'
      ) {
        let eqObj = form.entryLegs[legIndex].condition[newIdx];
        for (let i = 0; i < eqObj.marketTriggered.length; i++) {
          if (
            eqObj.marketTriggered[i].data === 'PARAMETER' &&
            eqObj.marketTriggered[i].arguements.length &&
            eqObj.marketTriggered[i].arguements[0].argName.startsWith(
              'NEW_TEMPLATE'
            )
          ) {
            argArr.push(eqObj.marketTriggered[i].arguements[0]);
          }
        }
      }
      form.entryLegs[legIndex].condition.splice(index, newIdx + 1);
    } else if (index > -1 && form.entryLegs[legIndex].condition.length > 1) {
      form.entryLegs[legIndex].condition.splice(index, 2);
    } else {
      //for removing constants on deleting equation
      if (
        form.entryLegs[legIndex].condition[index].trigger === 'MARKET_TRIGGERED'
      ) {
        let eqObj = form.entryLegs[legIndex].condition[index];
        for (let i = 0; i < eqObj.marketTriggered.length; i++) {
          if (
            eqObj.marketTriggered[i].data === 'PARAMETER' &&
            eqObj.marketTriggered[i].arguements.length &&
            eqObj.marketTriggered[i].arguements[0].argName.startsWith(
              'NEW_TEMPLATE'
            )
          ) {
            argArr.push(eqObj.marketTriggered[i].arguements[0]);
          }
        }
      }

      form.entryLegs[legIndex].condition = [{ type: 'OPERAND', trigger: '' }];
    }
    for (let k = 0; k < argArr.length; k++) {
      form.constants = form.constants.filter(
        (el) => el.name !== argArr[k].argValue
      );
    }
    dispatch(updateConditions(form));
    let newCond = [...conditionSelect];
    newCond.splice(index, 1);
    setConditionSelect(newCond);
  }

  const handleCondition = (trigger, key) => {
    if (trigger) {
      let fieldTriggerArr = trigger.split('_');
      let fieldTriggerValue =
        fieldTriggerArr[0]?.toLowerCase() +
        fieldTriggerArr[1]?.charAt(0) +
        fieldTriggerArr[1]?.slice(1).toLowerCase();
      setFieldIndex(key);
      setFieldValue(fieldTriggerValue);
    } else {
      if (legs.condition[0].trigger) {
        let fieldTriggerArr = legs.condition[0].trigger.split('_');
        let fieldTriggerValue =
          fieldTriggerArr[0]?.toLowerCase() +
          fieldTriggerArr[1]?.charAt(0) +
          fieldTriggerArr[1]?.slice(1).toLowerCase();
        setFieldIndex(0);
        setFieldValue(fieldTriggerValue);
      }
    }
  };

  const setTime = (argument) => {
    let value = argument.startTime.split(':');
    let endValue = argument.endTime.split(':');
    let d = new Date();
    let endDate = new Date();
    if (value.length > 2) {
      d.setHours(value[0], value[1], value[2]);
    }
    if (endValue.length > 2) {
      endDate.setHours(endValue[0], endValue[1], endValue[2]);
      setStartTime(d);
      setEndtime(endDate);
    }
  };

  useEffect(() => {
    if (showCondition) {
      setFiltered(showCondition);
    }
    if (conditionSelect.length > 0) {
      let newArr = filtered.filter(
        (el) => !conditionSelect.find((i) => i === el.key)
      );
      setFiltered(newArr);
    }
  }, [showCondition, conditionSelect]);

  useEffect(() => {
    if (fieldValue) {
      let obj = showCondition.find((el) => el.key === fieldValue);

      let field = [];
      if (isEdit) {
        field = legs.condition;
      } else {
        field = fields;
      }

      let updatedMarketForm = marketForm.map((el, index) => {
        return {
          type: el.type,
          data: el.data,
          arguements: el.arguements,
        };
      });
      let keyObj = {
        type: 'OPERAND',
        trigger: obj.data.enumName,
        [`${fieldValue}`]: [...updatedMarketForm],
      };
      field[fieldIndex == 1 ? 2 : fieldIndex] = keyObj;
      dispatch(setConditions({ field, legIndex, key: 'condition' }));
    }
  }, [marketForm]);

  useEffect(() => {
    if (isEdit) {
      let field = legs.condition;
      if (fieldValue) {
        let obj = showCondition.find((el) => el.key === fieldValue);
        let timeForm = {
          startTime: moment(startTime).format('H:mm:ss'),
          endTime: moment(endTime).format('H:mm:ss'),
        };
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${fieldValue}`]: timeForm,
        };
        field[fieldIndex === 1 ? 2 : fieldIndex] = keyObj;
      }
      if (startTime > endTime) {
      } else {
        dispatch(setConditions({ field, legIndex, key: 'condition' }));
      }
    } else {
      let field = fields;
      if (fieldValue) {
        let obj = showCondition.find((el) => el.key === fieldValue);
        let timeForm = {
          startTime: moment(startTime).format('H:mm:ss'),
          endTime: moment(endTime).format('H:mm:ss'),
        };
        let keyObj = {
          type: 'OPERAND',
          trigger: obj.data.enumName,
          [`${fieldValue}`]: timeForm,
        };
        field[fieldIndex === 1 ? 2 : fieldIndex] = keyObj;
      }
      if (startTime > endTime) {
      } else {
        dispatch(setConditions({ field, legIndex, key: 'condition' }));
      }
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (!modalClear && clearStats) {
      let form = { ...strategyTemplateForm };
      let newEntryLegs = [
        {
          legId: 1,
          condition: [
            {
              type: 'OPERAND',
              trigger: '',
            },
          ],
          position: [
            {
              orientation: 'Fwd',
              direction: 'BUY',
              security: '',
              pricing: [],
              totalQuantityLogic: [],
              quantity: [],
            },
          ],
          algoExecution: [],
        },
      ];
      form.entryLegs = newEntryLegs;
      dispatch(updateConditions(form));
      setConditionSelect([]);
      setFields([
        {
          type: 'OPERAND',
          trigger: '',
        },
      ]);
    }
  }, [modalClear]);

  useEffect(() => {
    if (isEdit) {
      let editMarket = legs.condition.find(
        (el) => el.trigger === 'MARKET_TRIGGERED'
      );
      let newOperationalArr = marketTriggeredOperators.map((opt) => {
        return {
          type: opt.type,
          displayName: opt.displayName,
          data: opt.values,
        };
      });
      let conditionArray = [...marketCondition, ...newOperationalArr];
      if (editMarket && editMarket?.marketTriggered) {
        let foundArray = [];
        for (let i = 0; i < editMarket?.marketTriggered.length; i++) {
          for (let j = 0; j < conditionArray.length; j++) {
            for (let k = 0; k < conditionArray[j].data.length; k++) {
              if (
                conditionArray[j].data[k].enumName ===
                  editMarket.marketTriggered[i].data?.trim() ||
                (editMarket.marketTriggered[i].data?.trim() === 'PARAMETER' &&
                  conditionArray[j].data[k].enumName ===
                    editMarket.marketTriggered[i]?.arguements[0]?.argName)
              ) {
                let finalObj = {
                  ...editMarket?.marketTriggered[i],
                  conditionName: conditionArray[j].data[k].displayName,
                };
                foundArray.push(finalObj);
              }
            }
          }
        }
        let finalEditMarket = foundArray.map((el, index) => {
          return {
            ...el,
            eqIndex: index,
          };
        });
        let eqArr = foundArray.map((elm, index) => {
          return {
            enumName: elm.data,
            type: elm.type === 'OPERATION' ? 'OPERATOR' : 'OPERAND',
            value: elm.conditionName,
          };
        });
        setMarketForm(finalEditMarket);
        setEquationValues(eqArr);
      }
      let timeEdit = legs.condition.find(
        (el) => el.trigger === 'TIME_TRIGGERED'
      );
      if (timeEdit && timeEdit.timeTriggered) {
        setTime(timeEdit.timeTriggered);
      }
    }
  }, [isEdit]);

  return (
    <div
      className={
        isApproveReject
          ? 'approveReject-pointer App entryCondition'
          : 'App entryCondition'
      }
    >
      {legs.condition
        .map((elm, indx) => ({ ...elm, key: indx }))
        .filter((elm) => elm.type !== 'OPERATION')
        .map((field, idx) => {
          let firstTrigger;
          let secondTrigger;
          let firstArr = Object.keys(
            strategyTemplateForm?.entryLegs[legIndex]?.condition[0]
              ? strategyTemplateForm?.entryLegs[legIndex]?.condition[0]
              : {}
          );
          let secondArr = Object.keys(
            strategyTemplateForm?.entryLegs[legIndex]?.condition[2]
              ? strategyTemplateForm?.entryLegs[legIndex]?.condition[2]
              : {}
          );
          if (firstArr.length > 2) {
            firstTrigger = firstArr[2];
          }
          if (secondArr.length > 2) {
            secondTrigger = secondArr[2];
          }
          let first = [];
          let second = [];

          first = showCondition?.map((el, indx) => {
            if (!(el.key.trim() === secondTrigger)) {
              counter.push(idx);
              return {
                id: el.key.trim(),
                label: el.data.displayName.trim(),
              };
            }
          });
          second = showCondition?.map((el, indx) => {
            if (!(el.key.trim() === firstTrigger)) {
              counter.push(idx);
              return {
                id: el.key.trim(),
                label: el.data.displayName.trim(),
              };
            }
          });

          return (
            <div
              key={`${field}`}
              className={`${field.trigger}`}
              onClick={() => handleCondition(field.trigger, field.key)}
            >
              <div className="inputs">
                {idx > 0 && (
                  <div className="ops">
                    <Dropdowns
                      single
                      role="condition"
                      id={`condition ${idx}`}
                      listData={operation?.map((el, indx) => {
                        return {
                          id: el.enumName.trim(),
                          label: el.displayName.trim(),
                        };
                      })}
                      disable={!firstTrigger}
                      value={legs.condition[1]?.value}
                      handlerFunction={operationHandler}
                      idx={idx}
                      className={isApproveReject ? 'approveReject-pointer' : ''}
                    />
                  </div>
                )}
                <div>
                  {idx === 0 && (
                    <div className={`first first_${idx}`}>
                      <Dropdowns
                        single
                        role="condition"
                        listData={first.filter((el) => el)}
                        handlerFunction={inputHandler}
                        value={Object.keys(field)[2]}
                        idx={idx}
                        className={
                          isApproveReject ? 'approveReject-pointer' : ''
                        }
                      />
                    </div>
                  )}
                  {legs.condition[field.key - 1]?.value && (
                    <div className={`first first_${idx}`}>
                      <Dropdowns
                        single
                        role="condition"
                        listData={second.filter((el) => el)}
                        handlerFunction={inputHandler}
                        value={Object.keys(field)[2]}
                        idx={idx}
                        disable={!firstTrigger}
                        className={
                          isApproveReject ? 'approveReject-pointer' : ''
                        }
                      />
                    </div>
                  )}
                </div>
                {field.trigger === 'MARKET_TRIGGERED' ? (
                  <div>
                    <NewDropdown
                      id="CONDITION_NEWDROPDOWN"
                      listData={marketCondition}
                      marketForm={marketForm}
                      editFlagIndex={editFlagIndex}
                      setEditFlagIndex={setEditFlagIndex}
                      setMarketForm={setMarketForm}
                      marketHandler={marketHandler}
                      setInputData={setInputData}
                      setSelectedCondition={setSelectedCondition}
                      placeHolder={'Add Indicator, Number, Bracket'}
                      setEquationValues={equationHandler}
                      legIndex={legIndex}
                      componentKey={'entryCondition'}
                      extStyles={{ width: '50rem' }}
                    />
                  </div>
                ) : field.trigger === 'TIME_TRIGGERED' &&
                  conditions &&
                  conditions.length ? (
                  <div
                    className={
                      isApproveReject
                        ? 'approveReject-pointer condition'
                        : 'condition'
                    }
                  >
                    <StaticCondition
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      inputString={
                        conditions.find(
                          (el) => el.data.enumName === field.trigger
                        ).data.inputString
                      }
                      args={
                        conditions.find(
                          (el) => el.data.enumName === field.trigger
                        ).data.args
                      }
                      inputObject={
                        conditions.find(
                          (el) => el.data.enumName === field.trigger
                        ).data.inputObject
                      }
                      setStartTime={setStartTime}
                      startTime={startTime}
                      setEndtime={setEndtime}
                      endTime={endTime}
                    />
                  </div>
                ) : null}
                <div className="circle">
                  {legs.condition.length > 1 ||
                  Object.keys(
                    strategyTemplateForm?.entryLegs[legIndex]?.condition[0]
                      ? strategyTemplateForm?.entryLegs[legIndex]?.condition[0]
                      : {}
                  ).length > 2 ? (
                    <ButtonAddLeg
                      category="cat3"
                      icon={DeleteIcon}
                      hoverIcon={DeleteHoverIcon}
                      clickAction={() => handleRemove(idx)}
                      extStyles={{ margin: 'auto 0.5rem' }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      <div className="add">
        {legs.condition.length <= 1 && (
          <ButtonAddLeg
            icon={AddCircleIcon}
            category="cat1"
            label="Add More"
            clickAction={() => handleAdd()}
            disable={
              Object.keys(
                strategyTemplateForm?.entryLegs[legIndex]?.condition[0]
                  ? strategyTemplateForm?.entryLegs[legIndex]?.condition[0]
                  : {}
              ).length < 3
            }
          />
        )}
      </div>
    </div>
  );
};

export default AddPosition;

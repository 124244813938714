import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from "../utils/constant"

const empty = value => {
  const val = value ? value.trim() : value
  return !Boolean(val)
}

const username = value => {
  const reUser = /^[A-Za-z0-9.]+$/
  return !empty(value) && reUser.test(value)
}
const length = value => {
  const reUser = /^[A-Za-z0-9.]+$/
  return !empty(value) && value.length <= 50 && reUser.test(value)
}

const password = value => {
  const re = /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&])(?=.{8,100}).*$/
  return !empty(value) && value.length >= 6 && re.test(value)
}

const phone = value => {
  const rePhone = /^[0-9]\d{9}$/
  return !empty(value) && value.length === 10 && rePhone.test(value)
}

const name = value => {
  const reName = /^[A-Za-z]+$/
  return !empty(value) && reName.test(value)
}

const firstName = value => {
  const refirstName = /^[A-Za-z]+$/
  return !empty(value) && refirstName.test(value)
}
const lastName = value => {
  const relastName = /^[A-Za-z]+$/
  return !empty(value) && relastName.test(value)
}
const email = value => {
  const reemail =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return !empty(value) && reemail.test(value)
}
const mobile = value => {
  const remobile = /^[0-9]\d{9}$/
  return !empty(value) && value.length === 10 && remobile.test(value)
}

const roleName = value => {
  return !empty(value)
}
const roleDes = value => {
  return !empty(value)
}
const roleEa = value => {
  return !empty(value)
}
const strategy = value => {
  return !empty(value)
}
const team = value => {
  return !empty(value)
}
const map = value => {
  return !empty(value)
}
export const Validation = {
  empty,
  username,
  password,
  firstName,
  lastName,
  phone,
  name,
  email,
  mobile,
  length,
  roleName,
  roleDes,
  roleEa,
  strategy,
  map,
  team
}

function fetchValue(object, keyString, defaultVal = -1) {
  try {
    const path = keyString.split('.');
    let statement = `object`;
    for (let key of path) {
      statement += `[${checkStr(key) ? `'${key}'` : key}]`;
    }
    const response = eval(statement);
    return response;
  } catch (error) {
    console.log(error);
    return defaultVal;
  }
}

function updateValue(object, keyString, newValue) {
  try {
    const path = keyString.split('.');
    const newObj = { ...object };
    let statement = `newObj`;
    for (let key of path) {
      statement += `[${checkStr(key) ? `'${key}'` : key}]`;
    }
    statement += ' = newValue';
    eval(statement);
    return newObj;
  } catch (error) {
    console.log(error.message);
  }
}

function checkStr(val) {
  return /^-?\d+$/.test(val) ? false : true;
}
export { fetchValue, updateValue };

import React, { useState, useEffect } from 'react';
import './TradingHeader.scss';
import { useSelector } from 'react-redux';
import useKeyPress from '../../../custom_Hooks/useShortcut';
import TabNavInner from '../../sharedComponents/tabNavInner';
import { MakeRequest } from '../../../utils';
import useSWR from 'swr';
import { fetchValue } from '../../../utils/dotKeyFunctions';

function TradingHeaderPermission() {
  const userId = localStorage.getItem('customerId');
  const fetcher = async () => {
    const response = await MakeRequest(`/ops/view?qType=U&itemId=${userId}`).get();
    return [
      fetchValue(response, 'data.data.data.menu.2.subMenu.4'),
      fetchValue(response, 'data.data.data.menu.2.subMenu.5'),
      fetchValue(response, 'data.data.data.menu.2.subMenu.6'),
      fetchValue(response, 'data.data.data.menu.2.subMenu.7'),
    ];
  };

  const { data, error, isLoading } = useSWR(`/ops/view?qType=U&itemId=${userId}`, fetcher);
  return [data, error, isLoading];
}

const TradingHeader = ({ setDashboard }) => {
  const [TradingHeaderPermissionData, error, isLoading] = TradingHeaderPermission();
  const { shortcutReducer } = useSelector((state) => state);

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    if (TradingHeaderPermissionData && TradingHeaderPermissionData.length > 0) {
      const tempTabs = [];
      
      if (TradingHeaderPermissionData[0].access > 0) {
        tempTabs.push({ id: tempTabs.length, title: 'Strategy Analyzer' });
      }
      if (TradingHeaderPermissionData[1].access > 0) {
        tempTabs.push({ id: tempTabs.length, title: 'Risk Viewer' });
      }
      if (TradingHeaderPermissionData[2].access > 0) {
        tempTabs.push({ id: tempTabs.length, title: 'Order / Trades' });
      }
      if (TradingHeaderPermissionData[3].access > 0) {
        tempTabs.push({ id: tempTabs.length, title: 'Net Position' });
      }
      
      const finalTabs = process.env.REACT_APP_IS_HFT === 'true' 
        ? [{ id: 0, title: 'Net Position' }]
        : tempTabs;

      setTabs(finalTabs);
      
      if (finalTabs.length > 0) {
        setActiveTab(finalTabs[0].title);
        dashBoardFunction(finalTabs[0].title);
      } else {
        setActiveTab('');
        dashBoardFunction('');
      }
    } else {
      const defaultTab = process.env.REACT_APP_IS_HFT === 'true'
        ? [{ id: 0, title: 'Net Position' }]
        : [];
      setTabs(defaultTab);
      if (defaultTab.length > 0) {
        setActiveTab(defaultTab[0].title);
        dashBoardFunction(defaultTab[0].title);
      }
    }
  }, [TradingHeaderPermissionData]);

  useEffect(() => {
    switch (activeTab) {
      case 'Strategy Analyzer':
        dashBoardFunction('strategyanalyzer');
        break;
      case 'Risk Viewer':
        dashBoardFunction('riskviewer');
        break;
      case 'Net Position':
        dashBoardFunction('netposition');
        break;
      case 'Order / Trades':
        dashBoardFunction('order/trades');
        break;
      default:
        break;
    }
  }, [activeTab]);

  const dashBoardFunction = (data) => {
    if (data === '') {
      setDashboard('');
    } else {
      const newData = data.replace(/ +/g, '');
      setDashboard(newData);
      localStorage.setItem('header', newData);
    }
  };

  useKeyPress(
    shortcutReducer.get('ORDER_BOOK')?.keyCombination.split('+') && process.env.REACT_APP_IS_HFT !== 'true',
    () => {
      dashBoardFunction('order/trades');
    }
  );

  useKeyPress(
    process.env.REACT_APP_IS_HFT === 'false' &&
    shortcutReducer.get('STRATEGY_ANALYSER')?.keyCombination.split('+') &&
    process.env.REACT_APP_IS_HFT !== 'true',
    () => {
      dashBoardFunction('strategyanalyzer');
    }
  );

  useKeyPress(
    shortcutReducer.get('RISK_VIEWER')?.keyCombination.split('+') && process.env.REACT_APP_IS_HFT !== 'true',
    () => {
      dashBoardFunction('riskviewer');
    }
  );

  useKeyPress(
    shortcutReducer.get('NET_POSITION')?.keyCombination.split('+'),
    () => {
      dashBoardFunction('netposition');
    }
  );

  return (
    <TabNavInner
      tabs={tabs}
      curSelect={tabs.findIndex((tab) => tab.title === activeTab)}
      setSelect={(val) => setActiveTab(tabs.find((tab) => tab.id === val)?.title || '')}
    />
  );
};

export default React.memo(TradingHeader);

import { useState, useEffect, Fragment } from 'react';
import './styles.css';
import { DashboardIcon, Logo } from '../../../../assets';
import {
  StBuActive,
  AnacActive,
  TradActive,
  RiskActive,
  OpsActive,
  StBu_View,
  StBu_Create,
  StBu_Backtest,
  Ana_TradeAna,
  Ana_Report,
  Tra_LiveTrading,
  Tra_Paper,
  Risk_UserMain,
  Risk_Monitor,
  Risk_Config,
} from '../../../../assets';
import { addShortcutAction } from '../../../../reducers/shortcutReducer';
import AlertAndNotificationButton from '../../../header/AlertButton/AlertAndNotificationButton';
import LogoutDropdown from '../logoutDropdown';
import GenericPopup from '../../genPopup';
import DataInitPopup from '../../../../viewScreen/AdminDashboard/datainit';
import { ChangePassword } from '../../../header/ChangePassword/ChangePassword';
import { useCallbackRef } from 'use-callback-ref';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  OpsReducerActions,
  permissionsAnalyzer,
} from '../../../../viewScreen/Ops/redux';
import styled from 'styled-components';
import '../../../../global.css';

function AppHeaderV2() {
  const [passModal, setPassModal] = useState(false);
  const [dataInit, setDataInit] = useState(false);
  const [tabsLayout, setTabsLayout] = useState([]);
  const [tableSet, setTableSet] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { userPermissions } = useSelector((state) => state.OpsReducer);

  useEffect(() => {
    addShortcutAction(dispatch);
    const tempArray = [];
    for (let item of tableSet) {
      tempArray.push(
        <NavMenu
          title={item.title}
          inAcIcon={item.icons.inActive}
          AcIcon={item.icons.active}
          selected={item.routeLoc.includes(location.pathname)}
          onSelect={(val) => item.enableOnSelect && ddCallback(item.title, val)}
          ddMenu={item.dropdown}
          tabCount={item.tabCount}
          callback={(val) => ddCallback(item.title, val)}
        />
      );
    }
    setTabsLayout(tempArray);
  }, [location.pathname, tableSet]);

  useEffect(() => {
    OpsReducerActions.fetchUserPermissions(
      dispatch,
      localStorage.getItem('customerId')
    );
  }, []);

  useEffect(() => {
    const allTabs = [
      {
        title: 'Strategy Builder',
        icons: { active: StBuActive, inActive: StBuActive },
        enableOnSelect: false,
        dropdown: [
          { icon: StBu_View, title: 'View', permissionKey: 'View' },
          { icon: StBu_Create, title: 'Create', permissionKey: 'Create' },
          {
            icon: StBu_Backtest,
            title: 'Backtest',
            permissionKey: [
              'Backtest/Create',
              'Backtest/View',
              'Backtest/Logs',
            ],
          },
        ],
        tabCount: 1,
        routeLoc: ['/strategyBuilder/create', '/strategyBuilder/view'],
        permissionKey: 'Strategy Builder',
        isAdminViewEnable: true,
      },
      {
        title: 'Analytics',
        icons: { active: AnacActive, inActive: AnacActive },
        enableOnSelect: false,
        dropdown: [
          { icon: Ana_TradeAna, title: 'Trade Analytics' },
          { icon: Ana_Report, title: 'Reports' },
        ],
        tabCount: 5,
        routeLoc: [],
        permissionKey: 'Analytics',
        isAdminViewEnable:
          process.env.REACT_APP_IS_HFT === 'true' ? false : true,
      },
      {
        title: 'Trading',
        icons: { active: TradActive, inActive: TradActive },
        enableOnSelect: false,
        dropdown:
          process.env.REACT_APP_IS_HFT === 'true'
            ? [{ icon: Tra_LiveTrading, title: 'Live Trading' }]
            : [
                { icon: Tra_LiveTrading, title: 'Live Trading' },
                { icon: Tra_Paper, title: 'Paper Trading' },
              ],
        tabCount: 1,
        routeLoc: ['/LiveTrading'],
        permissionKey: 'Trading',
        isAdminViewEnable: true,
      },
      {
        title: 'Risk',
        icons: { active: RiskActive, inActive: RiskActive },
        enableOnSelect: false,
        dropdown: [
          {
            icon: Risk_UserMain,
            title: 'User Maintenance',
            permissionKey: 'Risk Monitoring',
          },
          {
            icon: Risk_Config,
            title: 'Risk Configuration',
            permissionKey: 'Risk Configuration',
          },
          {
            icon: Risk_Monitor,
            title: 'Risk Monitoring',
            permissionKey: [
              'User Maintenance/Family Dashboard',
              'User Maintenance/Ban Securities',
              'User Maintenance/Dealer Dashboard',
            ],
          },
        ],
        tabCount: 1,
        routeLoc: ['/riskmonitoring', '/riskconfig', '/risk/userMaintenance'],
        permissionKey: 'Risk',
        isAdminViewEnable: true,
      },
      {
        title: 'Ops',
        icons: { active: OpsActive, inActive: OpsActive },
        enableOnSelect: true,
        dropdown: [],
        tabCount: 1,
        routeLoc: ['/ops'],
        permissionKey: 'Ops',
        isAdminViewEnable: true,
      },
      {
        title: 'Multi Screen',
        icons: { active: DashboardIcon, inActive: DashboardIcon },
        enableOnSelect: true,
        dropdown: [],
        tabCount: 1,
        routeLoc: ['/multiScreen'],
        permissionKey: 'Multi Screen',
        isAdminViewEnable: true,
      },
    ];

    setTableSet(permissionsAnalyzer(userPermissions?.menu, allTabs));
  }, [userPermissions]);

  function handleModalPopups(param) {
    switch (param) {
      case 'CHANGE_PASSWORD':
        setPassModal(true);
        break;
      case 'DATA_INIT':
        setDataInit(true);
        break;
      default:
        break;
    }
  }

  function ddCallback(mainHeader, ddOption) {
    switch (mainHeader) {
      case 'Strategy Builder':
        switch (ddOption) {
          case 'View':
            navigate('strategyBuilder/view');
            break;
          case 'Create':
            navigate('strategyBuilder/create');
            break;
          default:
            break;
        }
        break;
      case 'Analytics':
        switch (ddOption) {
          case 'Trade Analytics':
            break;
          default:
            break;
        }
        break;
      case 'Trading':
        switch (ddOption) {
          case 'Live Trading':
            navigate('LiveTrading');
            break;
          default:
            break;
        }
        break;
      case 'Risk':
        switch (ddOption) {
          case 'User Maintenance':
            navigate('risk/userMaintenance');
            break;
          case 'Risk Configuration':
            navigate('riskconfig');
            break;
          default:
            navigate('riskmonitoring');
            break;
        }
        break;
      case 'Ops':
        navigate('ops');
        break;
      case 'Multi Screen':
        navigate('/multiScreen');
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <div className="app-header-v2-cont">
        <img
          src={Logo}
          className="application-logo"
          tabIndex={1}
          alt="app_logo"
        />
        <div className="app-header-s2">{tabsLayout}</div>
        <div className="app-header-s3">
          <AlertAndNotificationButton />
          <LogoutDropdown callBack={handleModalPopups} />
        </div>
      </div>

      <GenericPopup
        state={dataInit}
        toggleState={() => setDataInit(!dataInit)}
        type={1}
      >
        <DataInitPopup closePopup={() => setDataInit(false)} />
      </GenericPopup>
      <GenericPopup
        state={passModal}
        toggleState={() => setPassModal(false)}
        type={1}
      >
        <ChangePassword
          setPassModal={setPassModal}
          setShowPopUp={setPassModal}
          setPopup={setPassModal}
        />
      </GenericPopup>
    </Fragment>
  );
}

const NavMenuCont = styled.div`
  height: 8vh;
  cursor: pointer;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${(props) => props.style}
`;

const NavMenuButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1vh;
  margin: auto 1vw auto 1vw;
  background-color: ${(props) => (props.active ? '#eff3f7' : '#fff')};

  &:hover {
    background-color: #eff3f7;
  }
`;

const NavMenuIcon = styled.img`
  height: 2vh;
  width: 2vh;
  margin: 0.5vh;
  ${(props) => props.style}
`;

const NavMenuText = styled.span`
  font-family: var(--def-font);
  font-size: var(--font-size);
  white-space: nowrap;
  margin: 0.5vh;
  ${(props) => props.style}
`;

function NavMenu({
  title,
  inAcIcon,
  AcIcon,
  selected = false,
  onSelect = () => {},
  ddMenu = [],
  callback,
  extStyles,
  tabCount = 1,
  disabled = false,
}) {
  const [hover, setHover] = useState(false);
  const [dispTitle, setDisTitle] = useState(-1);
  const ddMenuSelect = useCallbackRef(-1, (newVal) => setDisTitle(newVal));

  function keyDownEventTrigger(e) {
    switch (e.key) {
      case 'Enter':
        if (ddMenuSelect.current !== -1 && !disabled) {
          callback(ddMenu[ddMenuSelect.current].title);
        }
        break;
      case 'ArrowDown':
        const new_pos =
          ddMenuSelect.current === -1
            ? 0
            : ddMenuSelect.current === ddMenu.length - 1
            ? -1
            : ddMenuSelect.current + 1;
        ddMenuSelect.current = new_pos;
        break;
      case 'ArrowUp':
        const new_po2 =
          ddMenuSelect.current === -1 ? -1 : ddMenuSelect.current - 1;
        ddMenuSelect.current = new_po2;
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const element = document.getElementById(`nav-head-${title}`);
    element.addEventListener('mouseenter', () => setHover(!disabled && true));
    element.addEventListener('mouseleave', () => setHover(false));
    element.addEventListener('focus', () => setHover(!disabled && true));
    element.addEventListener('blur', () => {
      setHover(false);
    });
    element.addEventListener('keydown', keyDownEventTrigger);
  }, []);

  return (
    <NavMenuCont
      id={`nav-head-${title}`}
      tabIndex={1}
      onClick={() => onSelect(title)}
    >
      <NavMenuButton active={selected}>
        <NavMenuIcon
          src={hover | selected ? AcIcon : inAcIcon}
          style={{ opacity: disabled ? 0.3 : 1 }}
          alt="hover-icon"
        />
        <NavMenuText style={{ opacity: disabled ? 0.3 : 1 }}>
          {title}
        </NavMenuText>
      </NavMenuButton>
      {/* {selected && <div className="bottom-selection-bn"></div>} */}
      {hover && (
        <div className="top-nav-dropdown">
          {ddMenu.map((item, index) => (
            <div
              onClick={() => !disabled && callback(item.title)}
              className={`top-nav-dropdown-option${
                index === dispTitle ? '-focus' : ''
              }`}
              tabIndex={tabCount + 1 + index}
              id={`${title}-${item.title}`}
            >
              <img
                src={item.icon}
                className="top-nav-ddo-icon"
                alt="context-icon"
              />
              <span className="top-nav-ddo-text">{item.title}</span>
            </div>
          ))}
        </div>
      )}
    </NavMenuCont>
  );
}

export default AppHeaderV2;
